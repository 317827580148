import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Config } from '../../provider/config';
import { DataApi } from '../../provider/dataApi';
import { Local } from '../../provider/local';

import {Router} from "@angular/router";
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {ToastrService} from "ngx-toastr";

export interface ConfirmModel {
  type: "";
  title:string;
  message:any;
  trueText:string;
  falseText:string;
  allData:any;
  content:''
  payVipHistoryPage:any
}

@Component({
  selector: 'app-none-vip-alert',
  templateUrl: './none-vip-alert.component.html',
  styleUrls: ['./none-vip-alert.component.css']
})
export class NoneVipAlertComponent implements OnInit {
  public title = "";
  public message:any;
  public trueText="";
  public falseText = "";
  public type = "custom";
  public content:any;
  public subcontent:any;
  public contentImage:any;
  public payVipHistoryPage:any;
  public subTitle="";
  public isVip = false;
  public config: Config;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    public toastr: ToastrService,
    public dialog: MatDialog,
    public local:Local,
    public dataApi: DataApi,

    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel
  ) {
    this.config = Config.getInstance();//实例化config

    console.log('noneAlert',data)
    this.type=data.type;
    if(!data.type)
    {
      this.type = "custom"
    }
    this.message=data.message;
    this.trueText=data.trueText;
    this.falseText=data.falseText;
    if(data.content)
    {
      this.content = data.content;
    }
    if(this.type=="goBuy" ||this.type=="goMilk_milkround"||this.type=="goCloud")
    {
        this.router.navigate(['home/tool-campus']);
    }
    if(this.type=='collegeblack'){
      return
    }
    if(data.payVipHistoryPage)
    {
      this.payVipHistoryPage = data.payVipHistoryPage;
      this.formatTitle();
    }
    if(this.type=='goBuy'||this.type=='resume_delivery')
    {
      this.isVip = false;
      this.type = "custom"
      // this.content = "即刻查看全网简历";
      this.contentImage = "../../assets/images/n_jianli.png"
    }
    else if(this.type=='goMilk_milkround' ||this.type=='goMilk' )
    {
      //查看双选会
      this.isVip = false;
      this.type = "custom"
      // this.content = "即刻一站报名全网双选会";
      // this.subTitle = "或到各高校就业网逐一报名";
      this.contentImage = "../../assets/images/n_baoming.png"
    }
    else if(this.type=='goCloud')
    {
      //查看宣讲会
      this.isVip = false;
      this.type = "custom"
      this.content = "即刻一站查看全网所有高校";
      this.subTitle = "或到各高校就业网查看";
    }
    else if(this.type=='goCampus')
    {
      //查看宣讲会
      this.isVip = false;
      this.type = "custom"
      // this.content = "即刻一站报名全网宣讲会";
      // this.subTitle = "或到各高校就业网逐一申请";
      this.contentImage = "../../assets/images/n_xuanjianghui.png"

    }
    else if(this.type=='goBuy_downloadResumes')
    {
      //查看宣讲会
      this.isVip = false;
      this.type = "custom"
      this.content = "即刻批量下载简历";
    }
    else if(this.type=='goPublish')
    {
      //查看宣讲会
      this.isVip = false;
      this.type = "custom"
      this.content = "即刻发布职位全网所有高校毕业生可见";
    }
    else if(this.type=='goResume')
    {
      //查看宣讲会
      this.isVip = false;
      this.type = "custom"
      this.content = "即刻查看简历沟通意向";
    }
    else if(this.type=='goBuy_pushPosition'){
      // 发布职位
      this.type = 'signMilk';
      this.content = "您还不是「工作啦」VIP会员，无法享用该校招权益";
    }
    else if(this.type=='goBuy_signMilk'){
      //申请双选会
      this.type = 'signMilk';
      this.content = "您还不是「工作啦」VIP会员，无法享用该校招权益";
      this.subTitle = "或前往各高校就业网免费申请双选会/宣讲会";
    }
    else if(this.type=='goBuy_signAirMilk'){
      // 空中宣讲会
      this.type = 'signMilk';
      this.content = "您还不是「工作啦」VIP会员，无法享用该校招权益";
      this.subTitle = "或前往各高校就业网免费申请双选会/宣讲会";
    }
    else if(this.type=='noneVip_voice_call'){
      // 极速电话联系
      this.type = 'general';
      this.content = "您还不是「工作啦」VIP会员，无法享用该校招权益";
    }
    else if(this.type=='noneVip_sms_bulkinvitation'){
      // 职位推送
      this.type = 'general';
      this.content = "您还不是「工作啦」VIP会员，无法享用该校招权益";
    }
    else if(this.type=='noneVip_setResumeStatus'){
      // 简历 设置 感兴趣
      this.type = 'general';
      this.content = "您还不是「工作啦」VIP会员，无法享用该校招权益";
    }
    else if(this.type=='noneVip_downResume'){
      // 下载简历
      this.type = 'general';
      this.content = "您还不是「工作啦」VIP会员，无法享用该校招权益";
    }
    else if(this.type=='noneVip_resumeChat'){
      // 下载简历
      this.type = 'general';
      this.content = "您还不是「工作啦」VIP会员，无法享用该校招权益";
    }
    else if(this.type=='memberOutPositionStopChip'){
      // 下载简历
      this.type = 'general';
      this.content = "非会员无法发布职位，您发布的职位已暂时帮您下线，成为会员后可重新上线";
    }
    // this.cd.detectChanges();
  }
  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnInit() {
  }
  goRouter(n){
    this.router.navigate(['home/'+n]);
  }
  OnChanges(){

  }
  ngDoCheck(){

  }
  goRouter1(n){
    this.router.navigate(['/'+n]);
  }
  formatTitle(){
    let payVipHistoryPage  = this.payVipHistoryPage;
    let content = "";
    let isVip = this.payVipHistoryPage.isVip;// 是否是VIP
    this.isVip = isVip;
    let page = this.payVipHistoryPage.page; // 页面
    let pro = this.payVipHistoryPage.pro; // 产品
    content = page;
    if(isVip) {
      content = "您的权益不足请补充相关权益";
      this.contentImage = "../../assets/images/n_quanyibuzu.png"
    }
    else {
      if (pro == "职位推送") {
        // content = "精准推广职位信息至学生,精准又省时";
        this.contentImage = "../../assets/images/n_zhiweituisong.png"
      } else if (pro == "极速电话联系") {
        // content =  '即刻购买极速电话联系包与学生'
        // this.subcontent = "电话沟通，方便又高效"
        this.contentImage = "../../assets/images/n_dianhualianxi.png"
      } else if (pro == "职位刷新") {
        // content = "将招聘信息在同类职位中置顶";
        // this.subcontent = "有效提升职位曝光，增强竞争力";
        this.contentImage = "../../assets/images/n_zhiweishuaxin.png"
      } else if (pro == "空宣推广") {
        content = "";
      } else if (pro == "校招活动邀请") {
        content = "";
      } else if (page == "空中宣讲会") {
        // content = "一站申请全网所有高校宣讲会";
        //  this.subTitle = "或到各高校就业网逐一申请"
        this.contentImage = "../../assets/images/n_xuanjianghui.png"
      } else {
        content = "您的权益不足请补充相关权益";
      }
    }
    this.content = content;
    this.type ="custom";
  }
  closeVIP(){
    this.dialog.closeAll()
    this.local.set('isAlertVip','true')
  }
  close(){
    this.dialog.closeAll()
  }
  //展示校招顾问
  showguwenVIP(){
    this.type = 'general_guwen'
    this.local.set('isAlertVip','true')
    let urmPayStatistic = {
      departmentid:this.config.department.accountid,
      category:"not_member"
    }
    this.dataApi.urmPayStatistic(urmPayStatistic).then((res:any)=>{
      console.log('urmPayStatistic',res)
    }).catch((error) => {
    });
  }
  showguwen(){
    this.type = 'general_guwen'
    let urmPayStatistic = {
      departmentid:this.config.department.accountid,
      category:"not_member"
    }
    this.dataApi.urmPayStatistic(urmPayStatistic).then((res:any)=>{
      console.log('urmPayStatistic',res)
    }).catch((error) => {
    });
  }
  //查看权益，跳转到校招工具页面
  gotoQuanyiVIP(){
    this.dialog.closeAll()
    this.router.navigate(['home/tool-campus']);
    this.local.set('isAlertVip','true')

  }
  gotoQuanyi(){
    this.dialog.closeAll()
    this.router.navigate(['home/tool-campus']);
  }
  generalDisMiss(){

  }
  copyText(text){
    if(!text){
      return
    }
    var Url2=text;
    var oInput = document.createElement('input');
    oInput.setAttribute('id', 'cp_hgz_input');
    oInput.value = Url2;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display='none';
    this.toastr.success('复制成功')
    document.getElementById('cp_hgz_input').remove();
  }
}
// import { DialogComponent, DialogService } from 'ngx-bootstrap-modal';
