import { Component, OnInit,Output } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";

@Component({
  selector: 'app-notification-pending',
  templateUrl: './notification-pending.component.html',
  styleUrls: ['./notification-pending.component.css']
})
export class NotificationPendingComponent implements OnInit {
  // 筛选条件
  public model={
    sender_account_type:'',
    done:false,
    offset:0,
    pending:true,
    limit:10
  };
  public taskList=[];
  public config: Config;
  public chatsList:any;//会话列表
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(
    public dataApi: DataApi,
    public opera:Opera,
    public toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.opera.initConfig('notification','notification-pending');
    this.getTaskTodoList();

  }
  chooseStatus(n){
    this.model.sender_account_type = n;
    this.getTaskTodoList()
  }
  getTaskTodoList(){
    this.opera.loading();
    this.dataApi.getTodoList(this.model).then((res:any)=>{
      this.countList=res.count;
      this.pageCount =Math.ceil(res.count/10);
      this.taskList =  res.list;
      this.taskList.map((item,index)=>{
        if(item.content){
          if(NotificationPendingComponent.isJsonString(item.content)){
            item.content = JSON.parse(item.content)
            console.log(index)
          }
        }
      })
      console.log(this.taskList)
      this.opera.loadiss();
    }).catch((error) => {
      this.toastr.error(error);
      this.opera.loadiss();
    });
  }
  static isJsonString(str) {
    try {
      if (typeof JSON.parse(str) == "object") {
        return true;
      }
    } catch(e) {
    }
    return false;
  }
  //处理操作
  goHandle(n){
    if(n.pending){
      this.dataApi.viewTodo(n.taskid).then((res:any)=>{
        //this.toastr.success('已查看');
        this.opera.loadiss();
      }).catch((error) => {
        this.toastr.error(error);
        this.opera.loadiss();
      });
    }

    if(n.alias == 'lacoin_charge_success'){
      this.router.navigate(['home/payHistory']);
    }else if(n.alias == 'talent_delivery'){
      this.router.navigate(['home/position-resume']);
    }else if(n.alias == 'college_audit_passed' || n.alias == 'college_audit_not_passed'){
      this.router.navigate(['home/college-milkround']);
    }else if( n.alias == 'college_audit_position_passed'){
      this.router.navigate(['home/position-manage']);
    }else if(n.alias == 'department_campustalk_apply_wait_to_audit'){
      this.router.navigate(['home/position-campus']);
    }else if(n.alias == 'lacoin_charge_failed'){
      this.router.navigate(['home/buyLaCoin']);
    }else if(n.alias == 'system_notice_milkround_apply_not_passed'|| n.alias == 'milkround_apply_not_passed' || n.alias == 'milkround_invite_department' || n.alias == 'milkround_apply_wait_to_audit' || n.alias == 'department_apply_milkround_confirm' || n.alias == 'milkround_notice_department_by_feedback' || n.alias == 'milkround_notice_department_by_depttype' ){
      this.router.navigate(['home/college-milkdetails'],{
        queryParams:{
          id:n.businessid
        }
      })
    }else{
      this.getTaskTodoList();
      this.toastr.success('消息已查看！');
    }
  }



  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数----');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*10;
      this.getTaskTodoList();//获取列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*10;
        this.getTaskTodoList();//获取列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*10;
        this.getTaskTodoList();//获取列表
      }
    }

  }
}
