<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px'}" [(visible)]="isShow" (onHide)="productmodelHidden()" [modal]=true>
    <div class="productModel">
        <div class="item" *ngIf="productType == 'recharge'">
            <div class="header">
                <div class="bg"><img src="/assets/images/rights/productModelBg02.png"></div>
                <div class="clos" (click)="productmodelHidden()"><img src="/assets/images/rights/productModelClos.png"></div>
                <div class="logo"><img src="/assets/images/rights/productModelLogo.png"></div>
                <div class="main">
                    <div class="icon"><img [src]="productItemData.ico"></div>
                    <div class="text">
                        <div class="name">{{productItemData.name}}</div>
                        <div class="introduce">{{productItemData.intro}}</div>
                    </div>
                </div>
            </div>
            <div class="richText" [innerHtml]="productItemData.pc_description"></div>
            <div class="bttom">
                <div class="tip">
                    <img src="/assets/images/rights/lock.png"> 您暂未开通{{productItemData.name}}权益，请联系校招顾问开通
                </div>
                <div class="save" (click)="save()">立即开通</div>
            </div>
        </div>

        <div class="item active" *ngIf="productType == 'member'">
            <div class="header">
                <div class="bg"><img src="/assets/images/rights/productModelBg01.png"></div>
                <div class="clos" (click)="productmodelHidden()"><img src="/assets/images/rights/productModelClos.png"></div>
                <div class="logo"><img src="/assets/images/rights/productModelLogo.png"></div>
                <div class="main">
                    <div class="icon"><img [src]="productItemData.ico"></div>
                    <div class="text">
                        <div class="name">{{productItemData.name}}</div>
                        <div class="introduce">{{productItemData.intro}}</div>
                    </div>
                </div>
            </div>
            <div class="richText" [innerHtml]="productItemData.pc_description"></div>
            <div class="bttom" *ngIf="isCellMe">
                <div class="tip">
                    <img src="/assets/images/rights/lock.png"> 您暂未开通{{productItemData.name}}权益，请联系校招顾问开通
                </div>
                <div class="save" (click)="save()">立即开通</div>
            </div>
        </div>

        <!--校招顾问联系我-->
        <div class="contactMe" *ngIf="productType =='contactMe'">
            <div class="clos" (click)="productmodelHidden()"><img src="/assets/images/rights/contactMeClos.png"></div>
            <div class="main">
                <div class="title">开通权益</div>
                <div class="h2">请确认公司和联系电话，校招顾问会在第一时间与您联系</div>
                <div class="department">
                    <div class="logo">
                        <img *ngIf="department.logo" [src]="department.logo+'&imageView2/1/w/65/h/65'">
                        <img class="noLogo" *ngIf="!department.logo" src="/assets/images/rights/departmentLogo.png">
                    </div>
                    <div class="right">
                        <div class="name">{{department.name}}</div>
                        <div class="lable">{{department.employer_type_text}}｜{{department.scale_text}}｜{{department.industry_text}}</div>
                    </div>
                </div>
                <div class="form">
                    <div class="item">
                        <div class="lab">联系人</div>
                        <div class="val"><input [(ngModel)]="upData.contactname"></div>
                    </div>
                    <div class="item">
                        <div class="lab">手机号</div>
                        <div class="val"><input [(ngModel)]="upData.contactmobile"></div>
                    </div>
                </div>
                <div class="btn" (click)="contactMeSave()">请校招顾问联系我</div>
            </div>

        </div>

    </div>
</p-dialog>