<!-- 页头部分 -->
<!-- <div class="contentRight-header">企业中心 - 发票 - 电子发票</div> -->
<!-- 主体内容部分 -->
<br>
<div class="homepage-block">
  <div class="college-block">
    <!-- 页头部分 -->
    <div class="college-block-head collegeBlockMargin">
      <!-- <p>发票</p> -->
      <div class="college-tab">
        <em (click)="opera.goNewRouter('company-receipt')"><img src='assets/images/router-old.png'/>返回</em>
        <span class="bold">电子发票</span>
      </div>
    </div>
    <!-- 开电子发票 -->
    <div class="college-block">
      <!-- 页头部分 -->
      <!-- <div class="sign-list">
          <span>成立年份：</span>
          <input type="text" id="establish" [(ngModel)]="testTime" placeholder="请选择成立年份"><img class="calendar-img" src="assets/images/calendar.png" alt="打开日历"/>
      </div> -->
      <!-- 申请部分 *ngIf="myDepartment"-->
      <div class="sign-block">
        <div class="sign-list">
          <span>抬头类型：</span>
          <span class="applyType" [class.active]="model.invoice_title_type=='enterprise_unit'" (click)="applyType('enterprise_unit')">企业单位</span>
          <span class="applyType" [class.active]="model.invoice_title_type=='personal'" (click)="applyType('personal')">个人</span>
        </div>
        <div *ngIf="model.invoice_title_type=='personal'">
          <!-- 姓名： -->
          <div class="sign-list">
            <span><b class="remind-red">*</b>姓名：</span>
            <input type="text" [(ngModel)]="model.invoice_title" placeholder="请输入您的姓名">
          </div>
          <!-- 身份证号： -->
          <div class="sign-list">
            <span><b class="remind-red">*</b>身份证号：</span>
            <input type="text" [(ngModel)]="model.id_number" placeholder="请输入您的身份证账号">
          </div>
          <!-- 发票内容： -->
          <div class="sign-list">
            <span>发票内容：</span>
            <em>信息技术服务费</em>
          </div>
          <!-- 发票金额： -->
          <div class="sign-list">
            <span>发票金额：</span>
            <em>{{amount}}元</em>
          </div>
          <!-- 个人邮箱： -->
          <div class="sign-list">
            <span><b class="remind-red">*</b>个人邮箱：</span>
            <input type="text" [(ngModel)]="model.email" placeholder="请输入您的邮箱">
          </div>
          <!-- 请填写备注 -->
          <div class="sign-list sign-intro">
            <span><b class="remind-red">*</b>备注：</span>
            <textarea [(ngModel)]="model.remark" minlength="50" maxlength="500" placeholder="请填写备注"></textarea>
          </div>
        </div>
        <div *ngIf="model.invoice_title_type=='enterprise_unit'">
          <!-- 姓名： -->
          <div class="sign-list">
            <span><b class="remind-red">*</b>发票抬头：</span>
            <input type="text" [(ngModel)]="model.invoice_title" placeholder="请输入发票抬头">
          </div>
          <!-- 身份证号： -->
          <div class="sign-list">
            <span><b class="remind-red">*</b>税号：</span>
            <input type="text" [(ngModel)]="model.register_no" placeholder="请输入税号">
          </div>
          <!-- 发票内容： -->
          <div class="sign-list">
            <span>发票内容：</span>
            <em>信息技术服务费</em>
          </div>
          <!-- 发票金额： -->
          <div class="sign-list">
            <span>发票金额：</span>
            <em>{{amount}}元</em>
          </div>
          <!-- 个人邮箱： -->
          <div class="sign-list">
            <span><b class="remind-red">*</b>邮箱：</span>
            <input type="text" [(ngModel)]="model.email" placeholder="请输入邮箱">
          </div>
          <!-- 公司地址 -->
          <!-- <div class="sign-list select-list sign-address">
            <span><b class="remind-red">*</b>公司地址：</span>
            <select autocomplete="off" [(ngModel)]="workPlace">
              <option class="place-text" value='' disabled hidden selected="selected">选择公司地址</option>
              <option *ngFor="let address of myDepartment.locations" [value]="address.lid">{{address.region_text}}&nbsp;&nbsp;{{address.address}}</option>
            </select>
            <button (click)="goAddress()">地址管理</button>
          </div> -->
          <div class="sign-list">
            <span><b class="remind-red">*</b>公司地址：</span>
            <input type="text" [(ngModel)]="model.register_address" placeholder="请输入地址">
          </div>

          <!-- 公司电话 -->
          <div class="sign-list">
            <span><b class="remind-red">*</b>公司电话：</span>
            <input type="text" [(ngModel)]="model.register_tel" placeholder="请输入公司电话">
          </div>
          <!-- 开户银行 -->
          <div class="sign-list">
            <span><b class="remind-red">*</b>开户银行：</span>
            <input type="text" [(ngModel)]="model.bank_name" placeholder="请输入开户银行">
          </div>
          <!-- 银行账号 -->
          <div class="sign-list">
            <span><b class="remind-red">*</b>银行账号：</span>
            <input type="text" [(ngModel)]="model.bank_no" placeholder="请输入银行账号">
          </div>
          <!-- 请填写备注 -->
          <div class="sign-list sign-intro">
            <span><b class="remind-red">*</b>备注：</span>
            <textarea [(ngModel)]="model.remark" minlength="50" maxlength="500" placeholder="请填写备注"></textarea>
          </div>
        </div>
        <!-- 保存 -->
        <div class="sign-submit">
          <button class="purple" (click)="goApply()">提交</button>
        </div>
      </div>
    <!-- <div class="applyReceipt">
      <div class="flex_row_left">
        <span>抬头类型：</span>
        <div>
          <span>个人</span>
          <span>企业单位</span>
        </div>
      </div>
      <div class="perBox">
        <p class="flex_row_left">
          <span>姓名：</span>
          <input type="text" placeholder="情输入您的姓名">
        </p>
        <p class="flex_row_left">
          <span>身份证号：</span>
          <input type="text" placeholder="请输入您的身份证账号">
        </p>
        <p class="flex_row_left">
          <span>发票内容：</span>
          <input type="text">
        </p>
        <p class="flex_row_left">
          <span>发票金额：</span>
          <input type="text">
        </p>
        <p class="flex_row_left">
          <span>邮箱：</span>
          <input type="text" placeholder="请填写您的邮箱">
        </p>
        <p class="flex_row_left">
          <span>备注：</span>
          <textarea name="" id="" cols="30" rows="10" placeholder="请填写备注说明"></textarea>
        </p>
      </div>
    </div> -->
    <!-- 分页部分 -->
    <!-- <div class='paging-block' [class.isPageHeight]="countList>0">
      <router-outlet></router-outlet>
      <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
    </div> -->
  </div>
</div>
</div>
