import { Component,EventEmitter, Input, Output,ViewChild,ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataApi,Constants,Config,Local} from '../../provider/index';
declare var window;
@Component({
  selector: 'app-model-company-product',
  templateUrl: './model-company-product.component.html',
  styleUrls: ['./model-company-product.component.css']
})
export class ModelCompanyProductComponent {
  public config: Config;
  public model={
     name:'',
     description:'',
     image_url:'',
     pid:''
  };
  public judge = false;//判断是否是添加操作
  @Input() isShow: false;//是否显示弹窗页面
  public title="";
  constructor(public toastr: ToastrService,
    public local:Local,
    public dataApi: DataApi) { this.config = Config.getInstance();}
    @Output() onVoted = new EventEmitter();
    dismiss(result: any) {
      //console.log("传值检查");
      //console.log(result);
      this.onVoted.emit(result);
    }

    //初始化函数
    initData(data){
      if(data){
        this.title="编辑";
        this.judge=false;
        this.model=data;
        //console.log("检查传参------model-company-product");
        //console.log(data);
      }else{
        this.judge=true;
        this.title="添加";
        this.model={
          name:'',
          description:'',
          image_url:'',
          pid:''
        };
      }
    }

    //保存产品
getProduct(){
  console.log("产品添加成功1");

  //判断企业产品数据是否填写完整
  let filter=/^[0-9a-zA-Z\u4e00-\u9fa5]+$/;
  // let filter2=/^[\u4e00-\u9fa5\（ \）]+$/;
  if(!this.model.name){
    this.toastr.warning('请填写产品名称');
    return;
  }else{
    if(!filter.test(this.model.name)){
      this.toastr.warning('产品名称只能输入汉字、字母和数字');
      return;
    }
  }
  //  let loader = this.tip.presentLoading('保存中...');
   if(this.judge){//--------添加操作
     let  model={
		   name:this.model.name,
		   description:this.model.description,
		   image_url:this.model.image_url
	  };
    this.dataApi.createDepartmentProduct(model).then((res:any)=>{
      //console.log(res);
      // loader.dismiss();
      console.log("产品添加成功1",res);

      this.model.pid=res.value;
      this.toastr.success("产品添加成功");
      this.dismiss(this.model);

    }).catch((error)=>{
      console.log("产品添加成功",error);
      this.toastr.error(error)
      // loader.dismiss();
    })
   }else{//--------编辑操作
     this.dataApi.uploadDepartmentProduct(this.model.pid,this.model).then((res:any)=>{
      //console.log(this.model);
       console.log("产品添加成功2",res);
       this.toastr.success("产品修改成功");
       // loader.dismiss();
      this.dismiss(this.model);
    }).catch((error)=>{
       console.log("产品添加成功",error);
       this.toastr.error(error)

       // loader.dismiss();
    })
  }

}

//删除产品
deleteProduct(){
  this.dataApi.deleteDepartmentProduct(this.model.pid).then((res:any)=>{
    //console.log(res);
      this.toastr.success('产品已删除');
      this.model={
      name:'',
      description:'',
      image_url:'',
      pid:this.model.pid
    };
    this.dismiss('delete');
  })

}


  //企业展示图片
  previewPic(event){
    let imgData  = event.target.files[0]; //获取file对象
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.uploadDepImg(reader.result);
      };

    }
  }

  //上传企业展示图片
  uploadDepImg(data){
      console.log('上传企业展示图片',data)
    let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpg;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
      // let loader = this.tip.presentLoading('图片保存中，请稍等...');
      this.dataApi.uploadDepartmentImg({
        value: imageData
      }).then((res:any)=>{
        // loader.dismiss();
        this.model.image_url=res.value;
      }).catch((error)=>{
        // loader.dismiss();
        this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
      })
  }

}
