import { Component,EventEmitter, Input, Output,ViewChild,ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-model-select-two',
  templateUrl: './model-select-two.component.html',
  styleUrls: ['./model-select-two.component.css']
})
export class ModelSelectTwoComponent {
  @Input() isShow: false;//是否显示弹窗页面
  public title: string;//弹出框标题
  //【必填】当前选中值
  public initValue = "";
  //页面数据数组
  public dataList = [];

  //当前选中的列表值
  public oneActive = {code: "", parentcode: "", text: "", level: ''};
  public twoActive = {code: "", parentcode: "", text: "", level: ''};

  //各级列表数据
  public firstLevel = [];
  public secendLevel = [];

  constructor() { }
  //初始化数据
  initData(data,data1,title){
    this.initValue = data;
    this.dataList = data1;
    this.title = title;
    let parentData = [];//静态化父级数组

    if(this.initValue){
      //编辑的情况
      //(倒序循环)首先循环出来的是第三级
      this.dataList.forEach((val)=>{
        if(val.parentcode == '0') {   
          parentData.push(val);         
        }     
        if(val.code == this.initValue){
          this.twoActive = val;
        }
      })       
      
      this.dataList.forEach((val)=>{
        if(val.code == this.twoActive.parentcode){
          this.oneActive = val;
        }
      })      

      //填充第一、二、三级数据
      this.firstLevel = parentData;
      this.getFirstLevel(this.oneActive);
      //this.getSecendLevel(this.twoActive);

    }else{
      //新建的情况      
      this.dataList.forEach((val) => {     
      if (val.parentcode == '0') {
          parentData.push(val);          
        }     
      });   

      //填充第一、二级数据
      this.firstLevel = parentData;
      this.getFirstLevel(this.firstLevel[0]);

    }
  }


  //点击筛选第一级
  getFirstLevel(item){
    //给选中行赋值
    this.oneActive = item;
    //遍历下一级数据
    this.secendLevel = [];
    if (item) {      
      this.dataList.forEach((val)=>{
        if(val.parentcode === item.code){
          //val.isShow = false;
          this.secendLevel.push(val);
        }
      });
    }    
  }


  //点击筛选第二级
  getSecendLevel(item){
    //给选中行赋值
    this.twoActive = item;
    //console.log(this.oneActive.text+"-"+this.twoActive.text);
    this.dismiss(item);
  }
   
  @Output() onVoted = new EventEmitter();
  dismiss(result: any) {
    //console.log("传值检查");
    //console.log(result);
    this.onVoted.emit(result);
  }

}
