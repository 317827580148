import { Component, OnInit ,Input, Output,ViewChild } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute,Router } from  '@angular/router';
import { ModelStillMilkComponent } from '../model-still-milk/model-still-milk.component';
import { ModelPositionMoreComponent } from '../model-position-more/model-position-more.component';
import {ConfirmsComponent} from "../confirms/confirms.component";
import {regexManager} from "../../provider/regexManager";
import * as moment from 'moment';
declare var window;
declare let laydate;
@Component({
  selector: 'app-college-milk-sign',
  templateUrl: './college-milk-sign.component.html',
  styleUrls: ['./college-milk-sign.component.css']
})
export class CollegeMilkSignComponent implements OnInit {
  public config: Config;
  @ViewChild('child', { static: true }) child: ModelStillMilkComponent;
  @ViewChild('child1', { static: true }) child1: ModelPositionMoreComponent;
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelTwoShow = false;//是否打开弹窗
  public model = {
    contacts:'',//联系人
    attendance:'',//参会人数
    wechat:'',
    phone:'',
    remark:'',//备注
    facultyid:'',//审核院系id
    collegeid:"",
    attachment:[],//附件
    attachment_list:[],
    interview_time:'', // 面试时间
    attendees:[{name:'',phone:''}],//参会人数
    positionids:[],//职位id
    major_list:[],//专业
    is_invite:false,//是否是邀请的企业（邀请指小秘书邀请）
    proxy_document:'',//招聘委托书
    business_licence:'',//企业营业执照
    id_card_front:'',//身份证正面
    register_number:'',//营业执照注册号
    brief:'',//申请简述
    office_phone:'',//固定电话
    school_permit:"",
    authid:null, //资质审核申请记录
    is_alumn:false, // 是否为校友企业
    friendList:[{name:'',major:'',graduation_time:'',position:'',index:0}],
    alumn_infos:[],
    online_interview_start_time:'',
    online_interview_end_time:''
  }
  public is_online_interview = false; //是否开启视频双选会
  public name="";//双选会名称
  public mrid='';//招聘会id
  public applyid='';//招聘会申请id
  public mridList=[];//招聘会id
  public department:any;//公司信息
  public isSend=false;//是否点击发送按钮
  public orgList=[];//院系列表
  public orgList_shenhe=[];//院系列表
  public positionList=[]; //选中职位列表
  public collegeMajorList=[]; //专业列表（校级）
  public audit_mode=false; //是否是院系初审
  public audit_switch = { // 高校开关列表
    department_receipt:'',
    isShowFriend:false,
    air_milkround_alumn_required:false,
    milkround_alumn_required:false
  };
  public secureMaterail:any; //招聘委托书下载链接
  public authcation_office_phone=false;
  public collegeName="";
  public collegeLogo="";//学校logo
  public CollegeIdentityModel = {
    status:10086,
    no_apply_30day:false,
    audit_time:''
  };//高校云合作
  public MilkDetails = null;
  public reason = '';

  public CollegeDetail = null;

  public showTab1=false;
  public showTab2=false;
  public isAir = false;
  public isShowPopup=false;
  public isShowBackSave= false; //返回的时候是否显示返回保存弹窗

  public isZhuXiaoAlert = false;
  public isZhuXiaoAlert_title = "";

  public interview_time_setting = []; // 面试时间申请列表

  public isEducationType = false; //该企业是否是教育行业 ，验证是否上传办学许可证

  // 资质审核记录ID
  public authenticationApplyID = "";

  public milkroundApplyAllowd = true;

  public exceedPositionCount = 0;

  public department_receipt_List = [];

  public isSameNameApply = true;

  public isInterView = true;

  constructor(public toastr: ToastrService,
    public local:Local,
    public opera:Opera,
    private router: Router,
              public formatDate: FormatDate,
              private activatedRoute: ActivatedRoute,
              public regexManager:regexManager,
              public dataApi: DataApi) {
      this.config = Config.getInstance(); //实例化config(必须)
      activatedRoute.queryParams.subscribe(queryParams => {
        if(queryParams.milkname){
          this.name  = queryParams.milkname;
        }
        if(queryParams.popup){
          this.isShowPopup = true;
        }
        if(queryParams.isAir){
          this.isAir = true;
          this.opera.initConfig('nav-activity-air','college-milkround');
        }
      })
    //获取hr信息
    if(this.dataApi.getLocalLoginUser()){
      //console.log("获取公司HR联系电话");
      if(this.dataApi.getLocalLoginUser().mobile){
        this.model.phone = this.dataApi.getLocalLoginUser().mobile;//联系电话
      }
    }
    this.mrid=this.local.get(Constants.mrid);
    this.checkMilkroundSameNameApply()
    //获取上次申请提交数据
    if(this.local.get(Constants.applyid)){
      this.applyid=this.local.get(Constants.applyid);
      this.getPrevMIlkApply();
    }else{
      this.getPositionList();//获取职位数据
    }
  }

  ngOnInit() {
    this.getDepartment();//获取企业信息
    this.getOrgList();//获取院系列表
    this.getCollageDetails();//获取高校信息
    this.model.collegeid =  this.local.get(Constants.collegeid);
    this.getIdentityMaterial();//获取认证上传的材料标准
    this.getCollegeById(this.model.collegeid )//通过高校id获取高校详情
    this.getMilkroundAlumnDepartment(this.model.collegeid )
    console.log("高校云合作",this.config.department);
    this.getUser();
    // this.getCheckDepartmentcooperation('');
    

  }

  innerviewTimeChange(){

  }
  
  checkMilkroundSameNameApply(){

    this.dataApi.checkMilkroundSameNameApply(this.mrid).then((res:any)=>{
      console.log('checkMilkroundSameNameApply',res)
      if(res){
        this.isSameNameApply = res.value
      }
    }).catch((error)=>{
    })

  }


  //添加参会人
  addPosition(){
    for(var i = 0;i<this.model.attendees.length;i++){
      let item = this.model.attendees[i];
      if(!item.name){
        this.toastr.warning(`请先完善成员${i+1}姓名`);
        return;
      }
      if(!item.phone){
        this.toastr.warning(`请先完善成员${i+1}联系电话`);
        return;
      }
    }
    if(this.model.attendees.length>4){
      this.toastr.warning('参会人最多添加5个');
      return;
    }
    let p={name:'',phone:''};
    this.model.attendees.push(p);
  }

  //删除
  delPosition(index){
    if(this.model.attendees.length == 1){
      this.toastr.warning(`至少保留一个参会成员`);
      return;
    }
    this.model.attendees.splice(index, 1);
  }

  //获取双选会详情
  getMilkDetails(){
    if(!this.mrid) {
      return;
    }
    this.getMilkroundApplyAllowd(this.mrid)
    this.opera.loading();
    this.dataApi.getMilkDetails(this.mrid).then((res:any)=>{
       console.log("获取到双选会详情",res,this.CollegeIdentityModel);
      if(res){
       
       this.interview_time_setting = res.interview_time_setting;
       this.name=res.name;
       //限制行业
       if(res.include_industrys && res.include_industrys.length>0){
        let arry = [];
        for(let item of res.include_industrys){
          arry.push(item.text);
        }
        res.include_industrys_text = arry.join("，");
       }
      
       this.MilkDetails = res;
       
       this.checkFriendStaus();
       this.audit_mode=res.audit_mode;
       if(res.facultyid){
         if(res.audit_mode&&res.facultyid!='000000000000000000000000'){
           this.model.facultyid=res.facultyid;
         }
       }
       if(this.MilkDetails.department_receipt_List==null){
         this.MilkDetails.department_receipt_List = [];
       }
        this.MilkDetails.department_receipt_List.map((item)=>{
          item.links = [];
          this.department_receipt_List.push((item))
        })
      let nowDate = new Date();
      let nowTime = nowDate.getTime();  // 当前时间戳

      let applyEndDate = new Date(res.apply_end_time); // 报名结束时间
      let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳

      let  start_timeDate =  new Date(res.start_time); //双选会开始时间
      let  start_timeTime =  start_timeDate.getTime(); //双选会开始时间戳

      //如果申请双选会的时间超过了报名时间 //自动设置为已拒绝状态
      if(nowTime-applyEndTime>0){
        res.isAppEnd=true; // 报名已结束
      }else{
        res.isAppEnd=false; // 报名未结束
      }

      // 双选会是否开始
      if(nowTime-start_timeTime>0){
        res.isStart=true; // 双选会是否开始
      }else{
        res.isStart=false; // 双选会是否开始
      }



      if(res.status=="to_audit") {
         this.showTab1 = false;
         this.showTab2 = false;
       } else if(res.status=='not_passed') {
         this.showTab1 = false;
         this.showTab2 = true;
       }else if(res.status=='passed' && this.isShowPopup){

         this.opera.scrollTop();//页面滚动函数
         this.opera.dialog.open(ConfirmsComponent, {
           data: {
             title: '提示',
             message: `<div class="flex_col_center" style="text-align: center">
        <p>双选会已审核通过</p>
        </div>
        `,
             trueText: '知道了',
             letB: true,
             falseText: ''
           }
         }).afterClosed().subscribe(() => {
           this.opera.scrollTrue();
           this.router.navigate(['home/college-milkdetails']);
         });
       }

       console.log("报名是否截止",res.isAppEnd)
      //报名结束 +双选会审核中/双选会未通过 isStart 双选会开始时间
      //企业资质审核通过时，双选会/宣讲会报名已截止，企业申请的双选会自动失败，并返回失败的原因（双选会展位分配已满，请报名其他双选会/宣讲会，点击进入双选会/宣讲会列表）
      if(res.isStart &&(res.status=="to_audit"||res.status=="not_passed")) {
        if(res.milkround_type == 1){
          res.status = "not_passed";
          res.reason = "双选会展位分配已满，请报名其他双选会";
          this.reason = "双选会展位分配已满，请报名其他双选会";
          this.showTab2 = false;
        }else if(res.milkround_type == 2){
        }
      }

      if(this.CollegeIdentityModel.status==3) {
        if(this.CollegeIdentityModel.no_apply_30day==true)
        {
          this.showTab1 = false;
          this.showTab2 = false;

          let currentdata = new Date();
          let data = new Date(this.CollegeIdentityModel.audit_time);
          data.setDate(data.getDate() + 30);
          if(currentdata>data)
          {
            console.log("用户勾选了30天可以申请",);
            this.showTab1 = true;
            this.showTab2 = false;
            this.CollegeIdentityModel.no_apply_30day = false;
          } else {
            console.log("用户勾选了30天不可以申请",);
          }
        } else {
          this.showTab1 = true;
          this.showTab2 = false;
        }

      }

       if(this.CollegeIdentityModel.status==3||res.status=='not_passed') {
         this.isShowBackSave = true;
       } else {
         this.isShowBackSave = false;
       }
        if(this.CollegeIdentityModel.status==1&&res.status=='passed') {
          this.showTab1 = false;
          this.showTab2 = false;
        }
      }
      this.opera.loadiss();
      //是否视频双选会
      if(res.is_online_interview){
        this.is_online_interview = true;
        let that = this;
        let _start_time = moment(res.start_time).format('YYYY-MM-DD HH:mm:ss');
        let _end_time = moment(res.end_time).format('YYYY-MM-DD HH:mm:ss');
        var ins22 =  laydate.render({
          elem: '#test10'
          ,type: 'datetime'
          ,range: true
          ,ready: function(){
            ins22.hint(`日期可选值设定在 <br> ${_start_time} 到 ${_end_time}`);
          }
          ,done: function(value, date, endDate){
            //moment
            let star = `${date.year}-${date.month}-${date.date} ${date.hours}:${date.minutes}:${date.seconds}`;
            let end = `${endDate.year}-${endDate.month}-${endDate.date} ${endDate.hours}:${endDate.minutes}:${endDate.seconds}`;
            that.model.online_interview_start_time = moment(star).format('YYYY-MM-DD HH:mm:ss');
            that.model.online_interview_end_time = moment(end).format('YYYY-MM-DD HH:mm:ss');
          }
        })
      }

      //申请高校合作时弹出
      if(this.showTab1){
        this.getCheckDepartmentcooperation(res.collegeid);
      }
    }).catch((error)=>{
      // console.log(error);
      this.opera.goChangeRouter('college-resume');
      this.toastr.error(error);
      this.opera.loadiss();
    })
  }

  // 设置面试时间
  setTime(t){
    this.model.interview_time = t;
    console.log(t);
    console.log(this.model.interview_time);
  }

  // 获取高校信息
  getCollageDetails(){
    if(!this.local.get(Constants.collegeid))
    {
      this.toastr.warning('获取高校信息错误')
      return;
    }
    this.opera.loading();
    this.dataApi.getCollageDetails(this.local.get(Constants.collegeid)).then((res:any)=>{
      console.log("获取到高校信息",res);
      this.collegeName = res.name;
      this.collegeLogo = res.logo;

      if(res.audit_switch.faculty_authcation){
        this.getOrgList_shenhe();
      }else{
        this.collegeName = res.name;
        this.collegeLogo = res.logo;

      }
      this.audit_switch = res.audit_switch;
      this.audit_switch.milkround_alumn_required = res.audit_switch.milkround_alumn_required;
      this.audit_switch.air_milkround_alumn_required = res.audit_switch.air_milkround_alumn_required;

      this.checkFriendStaus();
      console.log(this.audit_switch);
      this.opera.loadiss();
    }).catch((error)=>{
      // console.log(error);
      this.opera.goChangeRouter('college-resume');
      this.toastr.error(error);
      this.opera.loadiss();
    })
  }

  //附件图片
  previewMilkPic(event,index){
    console.log('previewMilkPic',event);
    let imgData  = event.target.files[0]; //获取file对象
    if (parseInt(imgData.size)/1024 > 1024*5) {
      this.toastr.warning('该附件大小超出了5M，请重新上传');
      return ;
    }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        event.target.value = "";
        that.uploadDepImg(reader.result,imgData.name,index);
      };
    }
  }

  //上传企业展示图片
  uploadDepImg(data,name,index){
    let imageData = data;
    imageData = this.opera.formatFileData(imageData);
    // console.log('uploadDepImg',name,data)
    let filedata = {
      extension: name,
      filename: name,
      base64: imageData
    }
    this.opera.loading();
    this.dataApi.uploadDepartmentFile(filedata).then((res:any)=>{
      this.opera.loadiss();
      this.department_receipt_List[index].links.push(res.link);
      console.log('uploadDepImg',this.department_receipt_List)
    }).catch((error)=>{
      // loader.dismiss();
      this.opera.loadiss();
      this.toastr.error("上传文件错误");
    })
  }

  //删除附件图片
  deleteImg(img){
    this.model.attachment.forEach((val)=>{
      if(val == img){
        let num = this.model.attachment.indexOf(val);
        this.model.attachment.splice(num,1);
      }
    })
  }

   //获取列表
   getOrgList(){
    if(!this.local.get(Constants.collegeid))
    {
      return;
    }
    this.opera.loading();
    this.dataApi.getCollegePart(this.local.get(Constants.collegeid)).then((res:any)=>{
      console.log("获取院系列表---",res,this.model);
      //console.log(res);
      this.orgList = res;
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
    })
  }

  getOrgList_shenhe(){
    if(!this.local.get(Constants.collegeid)) {
      return;
    }
    this.opera.loading();
    this.dataApi.getCollegePart(this.local.get(Constants.collegeid)).then((res:any)=>{
      //console.log("获取院系列表---");
      //console.log(res);
      this.orgList_shenhe = res;
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
    })
  }

  //获取职位列表数据
  getPositionList(){
      this.getNewList();
  }

  //赋值职位默认值
  positionIdEval(){
    this.positionList=[];
    this.model.positionids=[];
    for(let m of this.config.positionList){
      //大于30天的职位 effect_time
      let tody = moment().format('YYYY-MM-DD');
      let effect_time = moment(m.effect_time).format('YYYY-MM-DD');
      let days = moment(effect_time).diff(moment(tody),'days');
      if(!m.need_perfect && days>30){
        this.positionList.push(m);
        this.model.positionids.push(m.positionid);
      }
    }
  }

  //-------获取线上数据
  getNewList(){
    let model={
      limit:100,
      offset:0,
      status:'release',
    }
    this.dataApi.getPositionList(model).then((res:any)=>{
      console.log("在期职位获取线上数据-----college-resume",res.list);
       //console.log(res);
      if(res) {
        this.config.positionList =  res.list;
        if(this.model.positionids.length>0){
          for(let m of this.config.positionList){
            for(let n of this.model.positionids){
              //大于30天的职位 effect_time
              let tody = moment().format('YYYY-MM-DD');
              let effect_time = moment(n.effect_time).format('YYYY-MM-DD');
              let days = moment(effect_time).diff(moment(tody),'days');
              console.log('大于30天的职位 effect_time')
              console.log(n.name,days)
              if(m.positionid==n && days>30){                
                this.positionList.push(m);
                break;
              }
            }
          }
          //
          if(this.model.positionids.length != this.positionList.length){
            let newPositionids = [];
            for(let n of this.positionList){
              if(this.model.positionids.indexOf(n.positionid)>=0){
                newPositionids.push(n.positionid)
              }
            }
            console.log("resume过期职位",this.model.positionids, this.positionList,newPositionids);
            this.exceedPositionCount = this.model.positionids.length - this.positionList.length;
            this.model.positionids = newPositionids;
          }

          if(this.positionList.length==0){
            this.model.positionids = []
             // this.positionIdEval();//赋值全部职位id
          }else {

          }
        }else{
          this.positionIdEval();//赋值全部职位id
        }
        this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
      }

     }).catch((error) => {
       this.toastr.error(error);
     });
  }

   //选择意向专业
   goMajor(){
    this.modelShow = true;
    this.opera.scrollTop();
    //调用子组件函数
     console.log('选择意向专业',this.model.major_list,this.orgList);
    this.child.initData(this.model.major_list,this.orgList,10);
    // this.choosePosition();
  }

  //接收子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
    if(event){
      this.collegeMajorList=[];
      console.log("接收子组件返回数据");
      console.log(event);
      this.model.major_list = event;
      for(let n of event){
         for(let m of n.majors){
           this.collegeMajorList.push(m);
         }
      }
    }

  }

  //选择职位
  choosePosition(){
    this.modelTwoShow = true;
    this.opera.scrollTop();
    this.child1.initData(this.model.positionids,'isdays');
  }

  ModelTwodismiss(event){
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    if(event){
      // console.log("接收子组件返回数据");
      // console.log(event);
      this.positionList=[];
      this.model.positionids = event;
      for(let m of this.config.positionList){
        for(let n of this.model.positionids){
           if(m.positionid==n){
             this.positionList.push(m);
             break;
           }
        }
      }

    }
  }


  //获取双选会上次申请数据
  getPrevMIlkApply(){
    if(!this.applyid) {
      return;
    }
    this.opera.loading();
    this.dataApi.getPrevMIlkApply(this.applyid).then((res:any)=>{
      console.log("获取上次申请双选会信息",res);
      console.log(res);
      this.name=res.name;
      this.model.contacts=res.contacts;
      this.model.attendance=res.attendance;
      this.model.phone=res.phone;
      this.model.positionids=res.positionids;
      this.model.remark=res.remark;
      this.model.interview_time=res.interview_time;
      if(res.audit_mode&&res.facultyid!='000000000000000000000000'){
        this.audit_mode=true;
        this.model.facultyid=res.facultyid;
      }
      if(res.attachment){
        this.model.attachment=res.attachment;
      }
      //职位
      if(!res.positionids){
        this.model.positionids=[];
      }else{
        this.getPositionList();//获取职位数据
      }
      //专业
      if(res.major_list){
        this.model.major_list=res.major_list;
        for(let n of res.major_list){
          for(let m of n.majors){
            this.collegeMajorList.push(m);
          }
       }
      }else{
        this.model.major_list=[];
      }
      //参会人员
      if(res.attendees){
        this.model.attendees=res.attendees;
      }else{
        this.model.attendees=[];
      }
      this.opera.loadiss();
    }).catch((err)=>{
       this.opera.loadiss();
       this.toastr.error(err);
    })
  }
  //验证联系人格式
  checkContact(){
    let filter=/^[a-zA-Z\u4e00-\u9fa5]+$/;
    if(!filter.test(this.model.contacts)){
      this.toastr.warning("联系人姓名只能输入汉字或字母");
    }
  }

  //验证手机号码格式
  checkPhone(n){
    let phoneModel={
      format_type:1,//0:手机号，1：手机号&固话；2.邮箱
      data:n
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(!res.value){
        this.toastr.warning("电话格式错误");
        return;
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    })
  }
  //验证手机号码格式
  checkPhone1(n){
    let phoneModel={
      format_type:3,//0:手机号，1：手机号&固话；2.邮箱
      data:n
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(!res.value){
        this.toastr.warning("固定电话格式错误");
        return;
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    })
  }

  //提交申请
  getSignModel(){
    //限制行业
    if(this.MilkDetails.include_industrys && this.MilkDetails.include_industrys.length>0){
      let _bol = true;
      for(let item of this.MilkDetails.include_industrys){
        if(item.code == this.department.industry){
          _bol = false;
        }
      }
      if(_bol){
        this.toastr.warning("您的企业行业不属于该双选会限制行业，再看看其他双选会吧！");
        return
      }
    }

    if(!this.isSameNameApply){
      this.toastr.warning("高校不允许同名企业多次报名");
      return
    }
    //console.log(this.model);
    if(!this.milkroundApplyAllowd){
      this.toastr.warning("30天内无法再次申请");
      return;
    }
    
    console.log('提交申请',this.model.friendList,this.isSameNameApply)
    let firiendList = [];
    if(this.model.is_alumn && this.audit_switch.isShowFriend){
      let isFull = true
      this.model.friendList.map((item)=>{
        if(item.name || item.major || item.graduation_time || item.position){
          if(!item.name || !item.major || !item.graduation_time || !item.position){
            isFull = false
          }
        }

        if(item.name && item.major && item.graduation_time &&item.position){
          firiendList.push(item)
        }
      })
      if(!isFull){
        this.toastr.warning("校友企业信息填写完整");
        return;
      }
      if(firiendList.length==0){
        this.toastr.warning("请填写校友企业信息");
        return;
      }
      this.model.alumn_infos = firiendList;
    }
    if(this.isSend){
      this.toastr.warning("您重复点击了，请稍后重试");
      return;
    }

    let filter=/^[a-zA-Z\u4e00-\u9fa5]+$/;
    if(!this.model.contacts){
      this.toastr.warning("请填写联系人");
      this.isSend=false;
      return;
    }else if(!filter.test(this.model.contacts)){
      this.toastr.warning("联系人姓名只能输入汉字或字母");
      this.isSend=false;
      return;
    }

    console.log('this.model',this.model);
    if(this.audit_mode){
      if(!this.model.facultyid){
        this.toastr.warning("审核院系为必选项");
        this.isSend=false;
        return;
      }
    }

    if(!this.model.phone){
      this.toastr.warning("请填写联系电话");
      this.isSend=false;
      return;
    }else{
      if(!this.regexManager.isPhone(this.model.phone)) {
        this.toastr.warning("电话号码格式错误，请重新填写");
        this.isSend=false;
        return;
      }
    }
    let attachment_list = [];
    if(this.department_receipt_List.length>0 && this.MilkDetails.department_receipt_switch){
      let isupload = true;
      this.department_receipt_List.map((item,index)=>{
        if(item.links.length==0){
          isupload = false
        }
        item.links.map((url)=>{
          attachment_list.push(url)
        })
      })
      if(!isupload){
        this.toastr.warning("请按照高校要求上传附件");
        return
      }
      this.model.attachment = attachment_list;
    }


    // 非  空中双选会 不用必选职位
    if(!this.isAir) {
      if(this.model.positionids.length==0){
        this.toastr.warning("请选择招聘职位");
        this.isSend=false;
        return;
      }
    }

    if(this.isAir){
      if(!this.model.wechat){
        this.toastr.warning("请填写微信号");
        this.isSend=false;
        return;
      }
      if(this.model.wechat){
        let filter3 = /^[^\u4e00-\u9fa5]*$/;
        if(!filter3.test(this.model.wechat)){
          this.toastr.warning("请输入正确的微信号");
          this.isSend=false;
          return;
        }
      }
    }

    //线上双选会不传 参会人数 参会人员
    if(this.MilkDetails.milkround_type == 1){
      // if(!this.model.attendance){
      //   this.toastr.warning("请填写参会人数");
      //   this.isSend=false;
      //   return;
      // }

      this.model.attendance = this.model.attendees.length.toString();
  
      //参会人数填写判断
      let aNum=0;//未填写内容数量
      let eNum=0;//填写完善内容数量
      for(let val of this.model.attendees){
        if(val.phone){
          val.phone =this.regexManager.clearSpace(val.phone)
        }
        if(!val.name&&!val.phone){
          // let num = this.model.attendees.indexOf(val);
          // attendNum.push(num);
          aNum++;
          break;
        }else if((!val.name&&val.phone)||(val.name&&!val.phone)){
          this.toastr.warning("请完善参会人信息");
          this.isSend=false;
          return;
        }else if(val.name&&val.phone){
          // val.phone = val.phone.replace(/\s+/g,"");
          if(!this.regexManager.isPhone(val.phone)) {
            this.toastr.warning("电话号码格式错误，请重新填写");
            this.isSend = false;
            return;
          }
          eNum++;
        }
      }
      if(aNum==this.model.attendees.length){
        this.toastr.warning("请填写参会成员");
        this.isSend=false;
        return;
      }else if(eNum!=Number(this.model.attendance)){
        this.toastr.warning("请根据填写的参会人员数量完善参会人员信息");
        this.isSend=false;
        return;
      }
    }else{
      this.model.attendees = [];
    }
    

    // if(this.MilkDetails.department_receipt_switch && this.model.attachment.length <= 0){
    //   this.toastr.warning("请至少上传一张附件!");
    //   this.isSend=false;
    //   return;
    // }
    // console.log("检测删除结果----下标");
    // console.log(this.model.attendees);
    // //删除空白行
    // if(attendNum.length>0){
    //    for(let n of attendNum){
    //     this.model.attendees.splice(n,1);
    //    }
    // }
    // console.log("检测删除结果");
    // console.log(this.model.attendees);

    if(this.authenticationApplyID){
      this.model.authid = this.authenticationApplyID
    }
    this.opera.loading();//加载动画
    this.isSend=true;
    if(this.applyid){
      this.dataApi.updateMilkSign(this.applyid,this.model).then((res:any)=>{
        this.opera.loadiss();//停止加载动画
        this.toastr.success("修改成功");
        this.isSend=false;
        this.router.navigate(['home/position-milk']);//返回我的双选会列表
      }).catch((error)=>{
        //console.log(error);
        this.opera.loadiss();//停止加载动画
        this.toastr.error(error);
        this.isSend=false;
      })
    }else{
      //console.log("双选会报名前检查参数");
      //console.log(this.model);
      this.dataApi.signMilk(this.mrid,this.model).then((res:any)=>{
        //console.log(res);
        this.opera.loadiss();//停止加载动画
        this.toastr.success("报名成功");
        this.isSend=false;
        if(this.MilkDetails.milk == 2){
          this.local.set('isAir',2)
        }else{
          this.local.set('isAir',1)
        }
        this.router.navigate(['home/position-milk']);//返回我的双选会列表
      }).catch((error)=>{
        //console.log(error);
        this.opera.loadiss();//停止加载动画
        this.toastr.error(error);
        this.isSend=false;
      })
    }

  }


  //获取企业信息 industry "0604" 教育机构
  getDepartment(){
    if(this.dataApi.getLocalDepartment()){
      this.department = this.dataApi.getLocalDepartment();
      if(this.department.wechat){
        this.model.wechat = this.department.wechat
      }
      console.log("企业信息本地存储", this.department);
      //console.log(this.dataApi.getLocalDepartment());
    }else{
      this.dataApi.getDepartment().then((res:any)=>{
        //console.log("企业信息获取线上数据");
        //console.log(res);
        if(res) {
          this.department = res;
          this.dataApi.setLocalDepartment(res);
          if(this.department.wechat){
            this.model.wechat = this.department.wechat
          }
        }

      }).catch((error) => {
        //console.log(error);
        this.toastr.error(error);
      });
    }
 }
  //上传图片
  previewPic(event,name){
    console.log(event);
    console.log(name);
    let imgData  = event.target.files[0]; //获取file对象
    if (parseInt(imgData.size)/1024 > 1024*5) {
      this.toastr.warning('该图片大小超出了5M，请重新上传');
      return ;
    }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.uploadPic(reader.result,name);
      };
    }
  }
  //上传到服务器
  uploadPic(data,name){
    let imageData=this.dataApi.formatImage(data);
    this.opera.loading();//加载动画
    this.dataApi.uploadAuthImg({
      value: imageData
    }).then((res:any)=>{
      ////console.log(res);
      if(res){
        if(res.value){//------------上传之后赋值
          this.model[name] = res.value;
        }
      }
      this.opera.loadiss();//停止加载动画
    }).catch((error) => {
      ////console.log(error);
      this.opera.loadiss();//停止加载动画
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    });
  }
  //获取认证上传的材料标准
  getIdentityMaterial(){
    if(!this.model.collegeid) {
      return;
    }
    let model={collegeid:this.model.collegeid};
    this.dataApi.getMaterial('authentication',model).then((res:any)=>{
      console.log("获取认证材料成功");
      console.log(res);
      if(res[0]){
        for(let list of res[0].material_infos){
          if(list.material_field=='proxy_document'){
            this.secureMaterail=list.material_example;
          }
        }
      }
    }).catch((err)=>{
      //console.log("获取认证材料失败");
      //console.log(err);
    })
  }
  //通过高校id获取高校详情
  getCollegeById(id){
    if(!id) {
      return;
    }
    this.opera.loading();
    this.dataApi.getCollageDetails(id).then((res:any)=>{
      console.log("获取到高校信息----",res);
      console.log(res);
      this.collegeName = res.name;
      this.collegeLogo = res.logo;
      
      this.CollegeDetail = res;
      if(res.audit_switch.faculty_authcation){
        // this.model.facultyid=this.local.get(Constants.facultyid);
        this.getOrgList();
        this.singleIdentity();
      }else{
        // if(res.audit_switch.proxy_document){
        //   this.singleIdentity();
        // }
        this.singleIdentity();
      }
      if(res.audit_switch.authcation_office_phone){
        this.authcation_office_phone=true;
        console.log('填写固话');
      }else{
        this.authcation_office_phone=false;
      }
      this.opera.loadiss();
    }).catch((err)=>{
      this.toastr.error(err);
      this.opera.loadiss();
    });
  }
  //单个高校认证信息
  singleIdentity(){
    if(!this.model.collegeid) {
      return;
    }
    this.dataApi.lastCollegeAuthenticationIdentity(this.model.collegeid).then((res:any)=>{
      console.log("获取上次认证信息",res,this.CollegeIdentityModel);
      if(res){
        this.model.business_licence = res.business_license;
        this.model.id_card_front = res.id_card_front;
        this.model.register_number = res.register_number;
        this.CollegeIdentityModel = res;
        // if(res.facultyid){
        //   this.model.facultyid = res.facultyid;
        // }
        if(res.proxy_document){
          this.model.proxy_document = res.proxy_document;
        }
        if(res.brief){
          this.model.brief = res.brief;
        }
        if(res.office_phone){
          this.model.office_phone = res.office_phone;
        }

        if(this.CollegeIdentityModel.status==2||this.CollegeIdentityModel.status==1) {
          this.showTab1 = false;
          this.showTab2 = true;
        } else  if(this.CollegeIdentityModel.status==3) {
          if(this.CollegeIdentityModel.no_apply_30day==true) {
            this.showTab1 = false;
            this.showTab2 = false;
            //判断是否超过30天，如果到了则可以重新申请
            //audit_time
            let currentdata = new Date();
            let data = new Date(this.CollegeIdentityModel.audit_time);
            data.setDate(data.getDate() + 30);
            if(currentdata>data) {
              console.log("用户勾选了30天可以申请",);
              this.showTab1 = true;
              this.showTab2 = false;
            } else {
              console.log("用户勾选了30天不可以申请",);
            }
          } else {
            this.showTab1 = true;
            this.showTab2 = false;
          }

        } else {
          this.showTab1 = true;
          this.showTab2 = false;
          //没有进行高校合作//
          this.getCollegeStatus();
        }

        this.getMilkDetails();//获取双选会详情

      } else {
        console.log("")
        this.CollegeIdentityModel.status = 0;
        this.showTab1 = true;
        this.showTab2 = false;
        this.getCollegeStatus();
        this.getMilkDetails();//获取双选会详情

      }

      this.opera.loadiss();
    }).catch((error)=>{
      ////console.log(error);
      console.log("获取上次认证信息error",error);

      this.opera.loadiss();
    })

    // this.dataApi.checkDepCollegeIdentity(this.model.collegeid).then((res:any)=>{
    //  console.log("checkDepCollegeIdentity",res)
    // }).catch((err)=>{
    //   this.toastr.error(err);
    // })
  }

  uploadDepartmentIdentity(){
    // this.dataApi.checkDepCollegeIdentity(this.model.collegeid).then((res:any)=>{
    //   console.log(res);
    // }).catch((err)=>{
    //   console.log(err);
    // })
    // return;
    // let filter = /^[0-9a-zA-Z]{18}$/;
    if(!this.model.business_licence){
      this.toastr.warning('请上传营业执照图片');
      return;
    }
    if(!this.model.id_card_front){
      this.toastr.warning('请上传身份证正面');
      return;
    }
    if(!this.model.register_number){
      this.toastr.warning('请填写统一社会信用代码');
      return;
    }
    if(this.regexManager.isRegister_number(this.model.register_number)) {
    } else
    {
      this.toastr.warning('统一社会信用代码不规范');
      return;
    }
    // else{
    //   let filterValue=this.model.register_number.trim();//删除左右两端的空格
    //   if(!filter.test(filterValue)){
    //     this.toastr.warning('统一社会信用代码格式错误');
    //     return;
    //   }
    // }
    if(this.CollegeDetail.audit_switch.faculty_authcation){
      if(!this.model.facultyid){
        this.toastr.info("请选择当前申请学院");
        return;
      }
    }
    if(this.authcation_office_phone && !this.model.office_phone){
      this.toastr.warning('请填写办公电话');
      return;
    }
    if(this.secureMaterail){
      if(!this.model.proxy_document){
        this.toastr.info("请上传招聘委托书");
        return;
      }
    }
    // industry "0604" 教育机构//必须上传办学许可证
    if(this.department.industry=='0604'||this.department.industry=='0605'||this.department.industry=='0606')
    {
      if(!this.model.school_permit){
        this.toastr.info("请上传办学许可证");
        return;
      }
    }

    this.opera.loading();//加载动画
    this.dataApi.uploadDepartmentIdentity(this.model).then((res:any)=>{
      this.opera.loadiss();//停止加载动画
      if(res.value){
        this.authenticationApplyID = res.value
      }
      setTimeout(() => {
        this.lastIdentity();//更新企业认证信息本地存储

        // this.CollegeIdentityModel.status=1
        this.toastr.info("资质审核已提交，请申请双选会️","提示")

        this.showTab1 = false;
        // this.showTab2 = true;
        this.getCollegeById(this.model.collegeid )//通过高校id获取高校详情

        //跳转页面
      },600);
    }).catch((error) => {
      ////console.log(error);
      this.toastr.error(error);
      this.opera.loadiss();//停止加载动画
      //跳转页面
      // setTimeout(() => {
      //   this.toastr.warning(error);
      // },600);
    });
  }
  //同步高校认证信息
  lastIdentity() {
    this.opera.loading();
    this.dataApi.lastAuthenticationIdentity().then((res:any) => {
      if (res) {
        this.config.lastCollegeIdentity = res;
        this.dataApi.setLastCollegeIdentity(this.config.lastCollegeIdentity);
      }
      this.opera.loadiss();
    }).catch((error) => {
      ////console.log(error);
      this.opera.loadiss();
    })
  }
  //获取高校认证状态
  getCollegeStatus() {
    // let loader = this.tip.presentLoading('数据加载中...');
    this.dataApi.getPlatformApply().then((res:any) => {
      console.log(res);
      if (res) {
        this.model.business_licence = res.business_licence;
        this.model.id_card_front = res.id_card;
        this.model.register_number = res.register_number;
        console.log("获取企业平台认证信息",res);
      }
    }).catch((error) => {
      console.log("获取企业平台认证信息出错");
      console.log(error);
    })
  }
  //注销账号
  zhuxiaoClick(){
    this.isZhuXiaoAlert = false;
    this.router.navigate(['home/account-logout-l']);//返回我的双选会列表

  }
  getUser(){
    this.dataApi.getUser().then((res:any)=>{
      console.log("线上数据hr信息------------------------aboutsettinguser,res");
      if(res){
        this.config.loginUser=res;
        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
      }
    }).catch((error)=>{
    });
  }

  //获取是否有相同企业申请资质审核
  getCheckDepartmentcooperation(collegeId){
    if(!this.local.get(Constants.collegeid)) {
      return;
    }
    this.dataApi.checkDepartmentcooperation(this.local.get(Constants.collegeid)).then((res:any)=>{
      if(res) {
        let account = "";
        for (let n of res) {
          if(!n.mobile) {
            n.mobile = "未知企业";
          }
          if(account=="") {
            account = this.phoneFormat(n.mobile);
          } else {
            account = account+"," + this.phoneFormat(n.mobile);
          }
        }
        this.isZhuXiaoAlert_title = account;
        if( this.isZhuXiaoAlert_title.length>0) {
          this.isZhuXiaoAlert = true;
        }
      }
    }).catch((error)=>{
      // console.log('checkDepartmentcooperation',error);
    });
  }
  // 关闭注销按钮
  closeZhuXiao(){
    this.isZhuXiaoAlert = false
  }
  phoneFormat(tel){
    if(!tel){
      return ''
    }
    tel = "" + tel;
    var tel1 =tel.replace(tel.substring(3,7), "****")
    return tel1
  }
  formatFileName(url){
    if(!url){
      return '附件'
    }
    try {
      let arr = url.split('/');

      let name = arr[arr.length-1];
      return name
    }catch (e) {
      return '附件'
    }
  }
  deleteFlie(url){
    let newArr = [];
    this.model.attachment.map((item)=>{
      if(item!=url){
        newArr.push(item)
      }
    });
    this.model.attachment = newArr
  }
  //
  checkFriendClick(isFriend){
    this.model.is_alumn = isFriend
  }
  addFriend(){
    this.model.friendList.push({name:'',major:'',graduation_time:'',position:'',index:this.model.friendList.length+1})
  }
  deleteFriend(index){
    console.log('index',index)
    this.model.friendList = this.model.friendList.filter((item,i)=>{
      if(index!=i){
        return item
      }
    })
  }
  checkFriendStaus(){
    if(this.MilkDetails){
      if(this.MilkDetails.milkround_type==1){
        this.audit_switch.isShowFriend = this.audit_switch.milkround_alumn_required
      }else {
        this.audit_switch.isShowFriend = this.audit_switch.air_milkround_alumn_required
      }
    }
  }
  // 获取校友企业
  getMilkroundAlumnDepartment(collegeid){
    let data = {
      collegeid:collegeid
    }
    this.dataApi.getMilkroundAlumnDepartment(data).then((res:any)=>{
      if(res) {
        console.log('checkDepartmentcooperation',res)
        if(res.alumn_infos){
         this.model.friendList =  res.alumn_infos.filter((item)=>{
           item.graduation_time = this.formatDate.formatDate(new Date(item.graduation_time))
            return item
          })
          if(res.alumn_infos.length>0){
            this.model.is_alumn = true
          }
        }
      }
    }).catch((error)=>{
      // console.log('checkDepartmentcooperation',error);
    });
  }

  // 获取是否允许申请双选会 高校开关
  getMilkroundApplyAllowd(mrid){
    let data = {
      mrid:mrid
    }
    this.dataApi.getMilkroundApplyAllowd(data).then((res:any)=>{
      if(res) {
        console.log('getMilkroundApplyAllowd',res)
        this.milkroundApplyAllowd = res.value;
      }
    }).catch((error)=>{
    });
  }

  // 删除自定义上传的附件
  deleteMilkPicClick(index,fileIndex){
    let newArr = [];
    this.department_receipt_List[index].links.map((item,index)=>{
      if(fileIndex!=index){
        newArr.push(item)
      }

    })
    this.department_receipt_List[index].links = newArr;
  }
  download_file_click(url){

  }

}
