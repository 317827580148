import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,CanDeactivate } from '@angular/router';

export interface CanComponentDeactivate {
  canleave: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(component: CanComponentDeactivate): Observable<boolean> | Promise<boolean> | boolean {
    console.log(component);
    return  component.canleave();

  }
}

@Injectable()
export class ConfigService {
  constructor(private http: HttpClient) { }
}