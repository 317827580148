import {Injectable} from '@angular/core';

/**
 * 这里存放全局用到的常量
 * 全是静态成员，请使用 Constants.DRAFTS 直接调用
 */
@Injectable()
export class Constants {

    static accesstoken = "accesstoken"; //tooken
    static accountid = "accountid";    //accountid
    static loginUser = "loginUser";    //用户信息
    static loginName = 'loginName';    //用户登录账号 loginname
    static loginEmail = 'loginEmail';    //用户登录账号 loginname
    static department = 'department';  //企业信息 department
    static lastIdentity = 'lastIdentity';  //企业最后一次认证信息
    static lastCollegeIdentity = 'lastCollegeIdentity';  //企业最后一次高校认证信息
    static chatsMsgList = 'chatsMsgList';//聊天记录
    static chatsList = 'chatsList'; //会话列表
    static positionList = 'positionList';//职位列表
    static chatPosition = 'chatPosition';//首次沟通需要的职位参数
    static chatsInfo = 'chatsInfo'; //沟通页面会话信息参数
    static userInfo = 'userInfo'; //沟通页面会话信息参数(新会话)
    static seat_number = 'seat_number';//沟通页面展位号参数
    static refreshToken = 'refreshToken';//刷新token数据
    static industry='industry';          //行业字典
    static employer_type='employer_type';//企业类别
    static post_type='post_type';       //职位类别
    static major='major';               //专业字典
    static skill='skill';               //技能字典
    static region='region';             //地区字典
    static scale='scale';               //企业规模
    static common='common';            //常用语字典
    static commonJun='commonJun';      //小秘书常用语字典
    static education='education';      //学历字典
    static resumeid='resumeid';      //简历id(防止刷新时报错)
    static collegeid='collegeid';    //高校id(防止刷新时报错)
    static mrid='mrid';             //双选会id(防止刷新时报错)
    static ctid='ctid';             //宣讲会id(防止刷新时报错)
    static positionid='positionid'; //职位id(防止刷新时报错)
    static facultyid='facultyid'; //学院id(防止刷新时报错)
    static articleid='articleid'; //文章id(防止刷新时报错)
    static campusApply='campusApply'; //宣讲会申请数据
    static oldUrl='oldUrl'; //本地存储存入旧的url
    static newUrl='newUrl'; //本地存储存入新的url
    static typeUrl='typeUrl'; //获取链接url中的数据
    static applyid='applyid'; //双选会申请id
    static briefid='briefid'; //招聘简章id
    static briefDetais='briefDetais'; //招聘简章详情
    static byCollege='byCollege'; //就业信息网id
    static byCollegeDetails='byCollegeDetails'; //就业信息网高校信息
    static isByOffer='isByOffer'; //存储面试邀请检测结果
    static loginRs="loginRs"; // 登陆来源
    static orderid="orderid"; // 订单id
    static recharge_category=""; // 单品code
    static recharge_type="";  // 单品一样的区分
    static productInfo="orderid"; // 产品编号
    static activityNoticeItem=""; // 活动通知会议
    static roomid=""; //面试间id
    constructor() {
    }
}
