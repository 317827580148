<div class="home-content">
    <!-- 左侧导航部分 -->
    <div class="left">
        <div class="leftMain">
            <!-- <div class="navLogo"><img src="../../assets/images/nav/navLogo.png"></div> -->
            <ul class="left-fixed">
                <!--<li class="left-head">&lt;!&ndash; (click)="getUrl()"导航 &ndash;&gt;</li>-->
                <!-- <li [class.navCur]="config?.isNavActive == 'home'" class="nav-home"><a routerLink="./homepage"><span></span>人才</a></li> -->
                <li *ngIf='config.is_frozen==false' [class.navCur]="config?.isNavActive == 'workspase'" class="nav-home" (click)="gotoPage('workSpase')">
                    <span class="leftIcon"></span>工作台<b></b>
                </li>

                <li [class.navCur]="config?.isNavActive == 'rights'" class="nav-rights" (click)="gotoPage('rights')">
                    <span class="leftIcon"></span>权益管理<b></b>
                </li>

                <li [class.navCur]="config?.isNavActive == 'position'" class="nav-position" (click)="gotoPage('position-manage')">
                    <span class="leftIcon"></span>职位管理<b></b>
                </li>

                <li *ngIf='config.is_frozen==false' [class.navCur]="config?.isNavActive == 'home'" class="nav-member" (click)="gotoPage('college-resume')">
                    <span class="leftIcon"></span>推荐人才<b></b>
                </li>

                <li *ngIf='config.is_frozen==false' [class.navCur]="config?.isNavActive == 'navSearch'" class="nav-search" (click)="gotoPage('searchPeople')">
                    <span class="leftIcon"></span>搜索人才<b></b>
                </li>

                <li *ngIf='config.is_frozen==false' [class.navCur]="config?.isNavActive == 'nav-resume-manager'" class="nav-resume" (click)="gotoPage('position-resume')">
                    <span class="leftIcon"></span>简历管理<b></b>
                </li>

                <li *ngIf='config.is_frozen==false' [class.navCur]="config?.isNavActive == 'nav-activity'" class="nav-preach" (click)="gotoPage('college-milkround')">
                    <span class="leftIcon"></span>双选会/宣讲会<b></b>
                </li>

                <li *ngIf='config.is_frozen==false' [class.navCur]="config?.isNavActive == 'aircampus'" class="nav-aircamp" (click)="gotoPage('airCampusDetail')">
                    <span class="leftIcon"></span>空中宣讲会<b></b>
                </li>

                <!--      <li *ngIf='config.is_frozen==false' [class.navCur]="config?.isNavActive == 'nav-activity-air'" class="nav-air"  (click)="gotoPage('college-milkround1')"><span></span>空中双选会<b></b></li>-->

                <!-- <li *ngIf='config.is_frozen==false' [class.navCur]="config?.isNavActive == 'college'" class="nav-cloud" (click)="gotoPage('college-campustalk')">
                    <span class="leftIcon"></span>高校云<b></b>
                </li> -->

                <!--<li *ngIf='config.is_frozen==false' [class.navCur]="config?.isNavActive == 'cloud'" class="nav-attestation" (click)="gomPage()"><span></span>免费高校云认证<b></b></li>-->

                <li *ngIf='config.is_frozen==false' [class.navCur]="config?.isNavActive == 'notification'" class="nav-notice" (click)="gotoPage('notification-center')" style="display: none;">
                    <span class="leftIcon"></span>通知中心<b></b>
                    <div *ngIf="config.tasktodo>0" [class.tip]="config.tasktodo > 0">{{config.tasktodo>=100?'99+':config.tasktodo}}</div>
                </li>

                <li *ngIf='config.is_frozen==false' [class.navCur]="config?.isNavActive == 'tool'" class="nav-tool" (click)="gotoPage('tool-my')">
                    <span class="leftIcon"></span>校招工具<b></b>
                </li>
                <li [class.navCur]="config?.isNavActive == 'company'" class="nav-data" (click)="gotoPage('company-details')" style="display: none;">
                    <span class="leftIcon"></span>我的资料<b></b>
                    <img *ngIf='config.department && !config.department.video_link' class="navVideoTip" src="../../assets/images/navVideoTip.png">
                </li>

                <div class="leftBooth" *ngIf="booth" (click)="boothClick()">
                    <img src="{{booth.image_url}}" style="width: 100%;height: 100%" />
                </div>
                <li class="li-block kefuBG" style="height: 260px;width: 100%;display: none;" *ngIf="config.departmentSelf">

                    <div class="kefuBox">
                        <div style="width: 100%;height: 50px;box-sizing: border-box;overflow: hidden;">
                            <img src="{{config.departmentSelf.avatar}}" class="kefu_headPic">
                            <p style="width:calc(100% - 60px);height:22px;margin-left: 50px;font-size: 16px;line-height: 22px;font-weight: bold">
                                {{config.departmentSelf.name}}
                                <img style="width: 14px; height: 14px;  margin-left: 2px;" src="../../assets/images/kefu_hg.png">
                            </p>
                            <div class="kefu_name">专属校招顾问</div>
                        </div>
                        <div style="width: 100%;height: 13px;display: flex;align-items: center;justify-content: left;" (click)="copyText(config.departmentSelf.wx)">
                            <img class="kefu_Icon" src="../../assets/images/kefu_wx_gray.png">
                            <span class="kefu_infoTitle" style="padding:0">微信：</span>
                            <span class="kefu_info" style="padding:0">{{config.departmentSelf.wx}}</span>
                            <img src="../../assets/images/kefu_copy_gray.png" style="width: 13px;height: 13px;margin-bottom:0px;margin-left: 2px" />
                        </div>
                        <div style="width: 100%;height: 13px;display: flex;align-items: center;justify-content: left;margin-top: 7px" (click)="copyText(config.departmentSelf.email)">
                            <img class="kefu_Icon" src="../../assets/images/kefu_email_gray.png">
                            <span class="kefu_infoTitle" style="padding:0">邮箱：</span>
                            <span class="kefu_info" style="padding:0;height: auto;line-height: 13px;word-break: break-all;white-space: pre-wrap !important;">{{config.departmentSelf.email}}</span>
                            <img src="../../assets/images/kefu_copy_gray.png" style="width: 13px;height: 13px;margin-bottom:0px;margin-left: 2px" />
                        </div>
                    </div>
                    <img class="kefu_Code" src="{{config.departmentSelf.qr_code}}" alt="二维码">

                </li>

            </ul>
            <div class="customer-service" *ngIf="config.departmentSelf">
                <div class="component">
                    <div class="logo"><img src="{{config.departmentSelf.avatar}}"></div>
                    <div class="rightText">
                        <div class="name">{{config.departmentSelf.name}}</div>
                        <div class="exclusive">专属校招顾问</div>
                    </div>
                </div>
                <div class="lianxi">
                    <div class="cell">
                        <img src="../../assets/images/v3/kefu_phone.png"> {{config.departmentSelf.mobile}}
                    </div>
                    <div class="cell active">
                        <img src="../../assets/images/v3/kefu_email.png"> {{config.departmentSelf.email}}
                    </div>
                </div>
                <img class="serviceCode" src="{{config.departmentSelf.qr_code}}" alt="二维码">
                <p>微信扫码联系客服</p>
            </div>
        </div>
    </div>

    <!-- 右侧主体部分 -->
    <div class="right">
        <!-- 主体组件部分 -->
        <div class="contentRight-cont" style="min-height: 1150px">
            <router-outlet></router-outlet>
        </div>
        <!-- 尾部 -->
        <!-- <div class="contentRight-foot" *ngIf="config.isNavChildActive!='tool-campus'">
            &nbsp;&nbsp;&nbsp;&nbsp;{{config.websiteRecord}}
        </div> -->

    </div>
    <div class="experience" *ngIf="config.isOpenExperienceMember" id="experienceIcon" (click)="showExModel()">
        <img src="../../assets/images/experienceIcon.png" />
    </div>
</div>


<!--<p-dialog [closeOnEscape]="false"-->
<!--[dismissableMask]="false"-->
<!--[(visible)]="shouWeiChatCode" [width]="300" (onHide)="onHideQrCode()" [height]="340" [minHeight]="340" [modal]=true>-->
<!--<p-header>关注公众号</p-header>-->
<!--<img src={{wxCodeURL}} style="width: 200px;height: 200px;margin-left: 25px" /><br/>-->
<!--<p style="text-align: center;line-height: 15px;margin: 10px 0px">立即扫码关注"校园招聘企业通知中心"， 及时获悉您的高校合作、双选会、宣讲会审核结果通知。</p>-->
<!--</p-dialog>-->


<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isShowPayUserDataOut" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':386,'height':191,'minHeight':191}"
    (onHide)="onHidePayOut()" [modal]=true>
    <div class="payBg">
        <img class="payBg_close" (click)="onHidePayOut()" src="../../assets/images/clone618.png">
        <div class="payBg_title flex_c"><img src="../../assets/images/vip_icon1.png">您的基础会员还有{{memberLastTime}}天到期</div>
        <div class="payBg_btnView flex_c">
            <div (click)="product('','contactMe')">续费</div>
            <div (click)="product('','contactMe')">升级其他套餐</div>
        </div>
    </div>
</p-dialog>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="callSafeUpgradeModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':522,'height':218,'minHeight':218}"
    (onHide)="onHidecallSafeUpgradeModel()" [modal]=true>
    <div style="width: 522px;height: 218px" (click)="onHidecallSafeUpgradeModel()">
        <img (click)="onHidecallSafeUpgradeModel()" style="width: 522px;height: 218px" src="../../assets/images/callSafeUpgrade.png">
    </div>
</p-dialog>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isShowExperienceMember" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':492,'height':630,'minHeight':630}"
    (onHide)="colseExMemberModel()" [modal]=true>
    <div class="experienceBg">
        <img class="experiencetitleImage" src="../../assets/images/extitle.png" />
        <!--<p class="guanzhuChips" *ngIf="config.wxCodeURL">（关注微信公众号及时获取更多福利）</p>-->
        <!--<div class="qrBg flex_c" *ngIf="config.wxCodeURL">-->
        <!--<img   src="{{config.wxCodeURL}}"/>-->
        <!--</div>-->
        <!--<div class="qrBg_none flex_c" *ngIf="!config.wxCodeURL">-->
        <!--</div>-->
        <div class="exItem flex_c" *ngIf="member_trial">
            <div>
                <img src="../../assets/images/ex1.png" />
                <p class="exItemtitle marginTop5">职位发布</p>
                <p class="exItemtitle">15个</p>
            </div>

            <div>
                <img src="../../assets/images/ex2.png" />
                <p class="exItemtitle marginTop5">简历查看</p>
                <p class="exItemtitle">不限量</p>
            </div>


            <div *ngFor="let item of member_trial.product_infos">
                <img src="{{item.ex_ico}}" />
                <p class="exItemtitle marginTop5">{{item.single_name}}</p>
                <p class="exItemtitle">{{item.count}}{{item.unit}}</p>
            </div>
            <!--<div>-->
            <!--<img src="../../assets/images/ex4.png"/>-->
            <!--<p class="exItemtitle marginTop5">职位刷新</p>-->
            <!--<p class="exItemtitle">5次</p>-->
            <!--</div>-->
        </div>
        <div class="exDetail">购买正式会员后可获得：<span class="exDetail2">九大校招聘权益</span>与<span class="exDetail2">五大校招神器</span>
        </div>
        <div class="exDetail exDetail_checkQuanyi" (click)="checkQuanyi()">点击查看「工作啦」VIP 会员详细权益</div>
        <div class="exGive" *ngIf="config.lastIdentity&&config.lastIdentity.status == 2" (click)="getExMember()">立即领取
        </div>
        <div class="exGive_gray" *ngIf="config.lastIdentity&&config.lastIdentity.status != 2">认证通过后领取</div>
        <p class="exGiveBottom" *ngIf="member_trial_vip">会员体验包有效期为{{member_trial_vip.count}}天</p>

    </div>
    <div (click)="colseExMemberModel()" class="closeExMember"></div>
</p-dialog>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>