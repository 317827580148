<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px'}" [(visible)]="isShow" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':modelW[step-1],'height':modelH[step-1],'minHeight':modelH[step-1] }"
    (onHide)="_modelHidden()" [modal]=true>
    <div class="box" *ngIf="step==1">
        <div class="titleView">
            <span>已添加标签</span>
            <span style="color: #3063ff">（{{seleteLabelMax}}/3)</span>
        </div>
        <div class="resumeLabel_view">
            <div *ngFor="let item of allLabels;let i = index">
                <div class="item" *ngIf="item.selete">{{item.name}}<span (click)="allLabelClick(item)">x</span></div>
            </div>
        </div>
        <div class="titleView">
            <span>全部标签</span>
            <span class="guanli" (click)="addLabelStep()">管理</span>
            <img class="guanliIcon" src="/assets/images/v3/address-edit.png" (click)="addLabelStep()">
        </div>
        <div class="allLabel_view">
            <div *ngFor="let n of allLabels;let i = index" (click)="allLabelClick(n)">
                <span class="label_item_none" *ngIf="!n.selete">{{n.name}}</span>
                <span class="label_item" *ngIf="n.selete">{{n.name}}</span>
            </div>
        </div>
        <div class="titleView"><span>备注</span></div>
        <textarea class="beizhu" cols="30" placeholder="添加备注..." rows="4" [(ngModel)]="remark"></textarea>
        <div class="buttomview">
            <div class="btn blueStreamer" (click)="commitaddLabelHttp()">确定</div>
            <div class="btn active" (click)="_modelHidden()">取消</div>
        </div>
    </div>
    <!--标签管理-->
    <div class="box2" *ngIf="step==2">
        <div class="titleView">标签管理
            <img style="float: right;margin-top: 3px" src="/assets/images/rights/contactMeClos.png" (click)="closeStep(1)">
        </div>
        <div class="label_col_Box">
            <div class="label_col" *ngFor="let n of allLabels_edit;let i = index">
                <input class="label_input" maxlength="8" placeholder="请输入1-8个字的自定义标签" [(ngModel)]="n.name" />
                <img style="width: 14px;height: 15px;margin-left: 5px" (click)="deleteLabel(n)" src="../../assets/images/delete_label.png" />
            </div>
        </div>
        <div class="addBtn" (click)="addLabelClick()">
            <img src="/assets/images/v3/pushposition.png"> 新增
        </div>
        <div class="buttomview">
            <div class="btn blueStreamer" (click)="commitAddLabel()">确定</div>
            <div class="btn active" (click)="closeStep(1)">取消</div>
        </div>
    </div>
    <!--删除标签-->
    <div class="box3" *ngIf="step==3">
        <div class="box3_cell1">
            <img src="../../assets/images/delete_red.png" /> 确定要删除此标签吗？
        </div>
        <div class="box3_cell2">
            所有使用了该标签的简历将变为无标签状态
        </div>
        <div class="buttomview">
            <div class="btn active" (click)="closeStep(2)">取消</div>
            <div class="btn blueStreamer" (click)="commitDeleteLabelHTTP()">确定</div>
        </div>
    </div>
</p-dialog>