import { Component, OnInit } from '@angular/core';
import {DataApi, Local, Opera} from "../../provider";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {productManager} from "../../provider/productManager";

@Component({
  selector: 'app-zengzhixieyi-page',
  templateUrl: './zengzhixieyi-page.component.html',
  styleUrls: ['./zengzhixieyi-page.component.css']
})
export class ZengzhixieyiPageComponent implements OnInit {

  constructor(
    public opera: Opera,
    private router: Router,
    public dataApi: DataApi,
    public local: Local,
    public toastr: ToastrService,
    public productManager: productManager
  ) {

  }

  ngOnInit() {
    this.opera.initConfig('tool', 'tool-campus');
  }

}
