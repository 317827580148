<!-- 页头部分 goBack()-->
<app-model-send-msg #sengMsgModel [SengMsgModel]='SengMsgModel' [tconfig]='config' (onVoted)="Modeldismiss($event)" [isShow]="modelShowSengMsg"></app-model-send-msg>
<!--<div class="contentRight-header" style="background-color: transparent;margin-bottom: 0">-->
<!--<img *ngIf="!config.isByCollege" (click)="opera.goPre()" src='assets/images/router-old.png'/>-->
<!--<img *ngIf="config.isByCollege" (click)="opera.goNewRouter('college-details-student')" src='assets/images/router-old.png'/>简历详情</div>-->
<app-resume-label-model #resumeLabelModel [isShow]="addResumeLabelmodelShow" (modelHidden)="addResumeLabelmodeHidden($event)"></app-resume-label-model>
<app-voice-call-model #voiceCallModel [isShow]="isShowVoiceCallModel" (modelHidden)="voiceCallModelHidden($event)">
</app-voice-call-model>

<app-navigation-route #appNavigation></app-navigation-route>

<!-- 主体内容部分 -->
<!-- 新版本 -->
<div class="resumeContainer">
    <!-- 简历 -->
    <div class="main" *ngIf="resumeDetails">
        <div class="center" *ngIf="!isInit">
            <div class="basics">
                <div class="left">
                    <div class="portrait">
                        <img *ngIf="resumeDetails.talent_base.logo" src="{{resumeDetails.talent_base.logo}}">
                        <img *ngIf="!resumeDetails.talent_base.logo&&(resumeDetails.talent_base.gender=='01')" src="assets/images/male.png" />
                        <img *ngIf="!resumeDetails.talent_base.logo&&(resumeDetails.talent_base.gender=='02')" src="assets/images/female.png" />
                        <img *ngIf="!resumeDetails.talent_base.logo&&!resumeDetails.talent_base.gender" src="assets/images/male.png" />
                    </div>
                    <div class="text">
                        <div class="name">{{resumeDetails.talent_base.name | clearSpace}}</div>
                        <div class="state">“{{resumeDetails.talent_base.job_status_text}}”</div>
                        <div class="school" *ngIf="resumeDetails.education_backgrounds">{{resumeDetails.education_backgrounds[0]?.graduation_school}}</div>
                        <div class="text1">{{resumeDetails.talent_base.gender_text}}｜{{resumeDetails.talent_base.age}}岁｜{{resumeDetails.talent_base.address_text ? resumeDetails.talent_base.address_text : '暂无' }} ｜{{resumeDetails.talent_base.education_text ? resumeDetails.talent_base.education_text:
                            '暂无'}}｜{{resumeDetails.talent_base.year ? resumeDetails.talent_base.year +"届": '往届'}}</div>
                    </div>
                </div>
                <div class="right">
                    <div class="_collection">
                        <div class="fn" *ngIf="resumeDetails.isfollow" (click)="shouCang($event)"><img src="/assets/images/v3/collectionIconActive.png">取消收藏</div>
                        <div class="fn" *ngIf="!resumeDetails.isfollow" (click)="shouCang($event)"><img src="/assets/images/v3/collectionIcon.png">收藏</div>
                    </div>
                    <div class="export" (click)="checkExportResume()" *ngIf="resumeDetails?.delivery_info"><img src="/assets/images/v3/downloadIcon.png">导出简历</div>
                </div>
            </div>
            <div class="sexBox">
                <div class="item">
                    <img src="/assets/images/v3/resumedetails01.png"> 生日
                    <span>{{resumeDetails.talent_base.birthdate | date:'yyyy-MM-dd'}}</span>
                </div>
                <div class="item">
                    <img src="/assets/images/v3/resumedetails02.png"> 所在城市
                    <span>{{resumeDetails.talent_base.address_text ? resumeDetails.talent_base.address_text :'暂无'}}</span>
                </div>
                <div class="item" *ngIf="resumeDetails.education_backgrounds">
                    <img src="/assets/images/v3/resumedetails03.png"> 在校时间
                    <span>{{resumeDetails.education_backgrounds[0]?.start_date|date:'yyyy.MM'}} —
                        {{resumeDetails.education_backgrounds[0]?.end_date|date:'yyyy.MM'}}</span>
                </div>
                <div class="item">
                    <img src="/assets/images/v3/resumedetails04.png"> 海外经历
                    <span>{{resumeDetails.talent_base.is_overseas_work?'有':'无'}}</span>
                </div>
                <div class="item">
                    <img src="/assets/images/v3/resumedetails05.png"> 户口所在地
                    <span>{{resumeDetails.talent_base.native_place_text ? resumeDetails.talent_base.native_place_text:'暂无'}}</span>
                </div>
            </div>
            <div class="middleBox">
                <div class="_contact">
                    <div class="item">
                        <img src="/assets/images/v3/resumedetailsPhone.png"> {{resumeDetails?.delivery_info?.feedback_status == 'interested' ? resumeDetails.talent_base.mobile : '******'}}
                    </div>
                    <div class="item">
                        <img src="/assets/images/v3/resumedetailsEmail.png"> {{resumeDetails?.delivery_info?.feedback_status == 'interested' ? resumeDetails.talent_base.email : '******'}}
                    </div>
                </div>
                <!-- 感兴趣、是否合适 -->
                <div class="right01" *ngIf="resumeDetails.delivery_info">
                    <div class="btn01" [class.active]="resumeDetails.delivery_info.feedback_status == 'interested'" (click)="setResumeStatus('interested')">
                        <img *ngIf="resumeDetails.delivery_info.feedback_status == 'interested'" src="/assets/images/v3/like.png">
                        <img *ngIf="resumeDetails.delivery_info.feedback_status != 'interested'" src="/assets/images/v3/likeActive.png"> 感兴趣,查看联系方式
                    </div>

                    <div class="btn02" [class.active]="resumeDetails.delivery_info.feedback_status == 'improper'" (click)="setResumeStatus('improper')">
                        <img *ngIf="resumeDetails.delivery_info.feedback_status == 'improper'" src="/assets/images/v3/cancelIcon.png">
                        <img *ngIf="resumeDetails.delivery_info.feedback_status != 'improper'" src="/assets/images/v3/cancelIconActive.png"> 不合适
                    </div>

                    <div class="btn03 blueStreamer" (click)="goContactChatBeforeSingle()" *ngIf="isMainland_employer"><img src="/assets/images/v3/chatIcon.png">发消息</div>

                </div>
                <!-- 极速电话联系、职位推送 -->
                <div class="right02" *ngIf="!resumeDetails.delivery_info">
                    <div class="btn" (click)="ShowPhoneModel()">
                        <img src="/assets/images/v3/speedIcon.png">
                        <span>极速电话联系</span>
                    </div>
                    <div class="btn active" (click)="alertSengMSG($event)">
                        <img src="/assets/images/v3/pushIcon.png">
                        <span>职位推送</span>
                    </div>
                    <div class="btn03 blueStreamer" (click)="goContactChatBeforeSingle()" *ngIf="isMainland_employer"><img src="/assets/images/v3/chatIcon.png">发消息</div>
                </div>
            </div>
            <div class="middleRight" *ngIf="resumeDetails.delivery_info&&resumeDetails.delivery_info.feedback_status != 'improper'">
                <div class="btn01" [class.active]="resumeDetails.delivery_info.feedback_status == 'invited'" (click)="setResumeStatus('invited')">已邀约</div>
                <div class="btn01" [class.active]="resumeDetails.delivery_info.feedback_status == 'interviewed'" (click)="setResumeStatus('interviewed')">已面试</div>
            </div>

            <!-- 丰富版简历 -->
            <div class="resumeCell" *ngIf="!resumeDetails.is_simple_edition">
                <!-- 求职意向 -->
                <div class="cell" *ngIf="resumeDetails?.job_target || resumeDetails?.pro_skills?.length > 0">
                    <div class="left">求职意向</div>
                    <div class="right">
                        <div class="li">
                            <div class="name">期望职位</div>
                            <div class="val">
                                <span *ngFor="let n of resumeDetails.pro_skills">{{n.text}}</span>
                            </div>
                        </div>
                        <div class="li" *ngIf="resumeDetails.job_target.industry && resumeDetails.job_target.industry.length>0">
                            <div class="name">期望行业</div>
                            <div class="val">
                                <span>{{resumeDetails.job_target.industry}}</span>
                            </div>
                        </div>
                        <div class="botmBox">
                            <div class="item">
                                <div class="name">期望薪资</div>
                                <div class="val fn">{{resumeDetails.job_target ?resumeDetails.job_target.expect_salary_text :'--'}}</div>
                            </div>
                            <div class="item">
                                <div class="name">工作类型</div>
                                <div class="val">{{resumeDetails.job_target ? resumeDetails.job_target.work_nature_text : '--'}}</div>
                            </div>
                            <div class="item">
                                <div class="name">期望工作城市</div>
                                <div class="val">{{resumeDetails.job_target ?resumeDetails.job_target.work_city:'--'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 自我描述 -->
                <div class="cell" *ngIf="resumeDetails?.skills?.length > 0">
                    <div class="left">自我描述</div>
                    <div class="right">
                        <div class="lable">
                            <span *ngFor="let n of resumeDetails.skills">{{n.text}}</span>
                        </div>
                        <div class="desc">{{resumeDetails.self_evaluates}}</div>
                    </div>
                </div>
                <!-- 实习/实践经历 -->
                <div class="cell" *ngIf="resumeDetails.work_experiences?.length > 0">
                    <div class="left">实习/实践经历</div>
                    <div class="right">
                        <div class="model" *ngFor="let workexp of resumeDetails.work_experiences;let index=index">
                            <div class="title">
                                <div class="_lf">
                                    <span>{{ workexp.company_name }}&nbsp;|&nbsp;</span>{{workexp.position}}<span class="fn">{{workexp.salary_text}}</span>
                                </div>
                                <div class="_rt" *ngIf="workexp.end_date && !workexp.isEndDate">{{workexp.start_date|date:'yyyy.MM'}} — {{workexp.end_date|date:'yyyy.MM'}}</div>
                                <div class="_rt" *ngIf="!workexp.end_date || workexp.isEndDate">{{workexp.start_date|date:'yyyy.MM'}} — 至今</div>
                            </div>
                            <div class="desc" *ngIf="workexp.description" [innerHtml]="workexp.description | textformat"></div>
                        </div>
                    </div>
                </div>

                <!-- 教育经历 -->
                <div class="cell" *ngIf="resumeDetails.education_backgrounds?.length > 0">
                    <div class="left">教育经历</div>
                    <div class="right">
                        <div class="model" *ngFor="let edubg of resumeDetails.education_backgrounds;let index=index">
                            <div class="title">
                                <div class="_lf">
                                    <span><img src="/assets/images/v3/schoolIcon.png">{{edubg.graduation_school}}&nbsp;|&nbsp;</span>{{edubg.major_status_text}}<span>&nbsp;|&nbsp;</span>{{edubg.education_text}}
                                </div>
                                <div class="_rt">{{edubg.start_date|date:'yyyy.MM'}} — {{edubg.end_date|date:'yyyy.MM'}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 奖励/证书 -->
                <div class="cell" *ngIf="resumeDetails.certificates?.length > 0">
                    <div class="left">奖励/证书</div>
                    <div class="right">
                        <div class="model" *ngFor="let cer of resumeDetails.certificates;let index=index">
                            <div class="title">
                                <div class="_lf">{{ cer.name }}</div>
                                <div class="_rt">{{cer.get_date|date:'yyyy-MM-dd'}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 在校职务 -->
                <div class="cell" *ngIf="resumeDetails.school_positions?.length > 0">
                    <div class="left">在校职务</div>
                    <div class="right">
                        <div class="model" *ngFor="let item of resumeDetails.school_positions;let index=index">
                            <div class="title">
                                <div class="_lf"><span>{{ item.school_position }}</span> <span *ngIf="item.org_or_project_name"> | {{ item.org_or_project_name }}</span></div>
                                <div class="_rt">{{item.start_date|date:'yyyy.MM.dd'}} — {{item.end_date|date:'yyyy.MM.dd'}}</div>
                            </div>
                            <div class="desc" *ngIf="item.description" [innerHtml]="item.description | textformat"></div>
                        </div>
                    </div>
                </div>

                <!-- 语言能力 -->
                <div class="cell" *ngIf="resumeDetails.languages?.length > 0">
                    <div class="left">语言能力</div>
                    <div class="right">
                        <div class="model" *ngFor="let item of resumeDetails.languages;let index=index">
                            <div class="title">
                                <div class="_lf">
                                    <span>{{item.language_text}}&nbsp;|&nbsp;</span>{{item.level_text}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- 项目经历 -->
                <div class="cell" *ngIf="resumeDetails.project_experiences?.length > 0">
                    <div class="left">项目经历</div>
                    <div class="right">
                        <div class="model" *ngFor="let project of resumeDetails.project_experiences;let _index=index">
                            <div class="title">
                                <div class="_lf">
                                    <span>{{ project.name }}</span>
                                </div>
                                <div class="_rt" *ngIf="project.end_date && !project.isEndDate">{{project.start_date|date:'yyyy.MM'}} — {{project.end_date|date:'yyyy.MM'}}</div>
                                <div class="_rt" *ngIf="!project.end_date || project.isEndDate">{{project.start_date|date:'yyyy.MM'}} — 至今</div>
                            </div>
                            <div class="desc">{{project.post}}</div>
                            <div class="desc" *ngIf="project.description" [innerHtml]="project.description | textformat"></div>
                        </div>
                    </div>
                </div>
                <!-- 作品展示 -->
                <div class="cell" *ngIf="resumeDetails.works?.length > 0">
                    <div class="left">作品展示</div>
                    <div class="right">
                        <div class="model" *ngFor="let item of resumeDetails.works;let _index=index" id="{{'image'+_index}}">
                            <div class="title">
                                <div class="_lf">
                                    <span>{{ item.name }}</span>
                                </div>
                            </div>
                            <div class="link" *ngIf="item.link" (click)="goLink(item.link)">{{item.link}}</div>
                            <div class="desc" *ngIf="item.description" [innerHtml]="item.description | textformat"></div>
                            <div class="imgs">
                                <div class="img" *ngFor="let url of item.image_url | slice:0:3;let num=index">
                                    <img (click)="initDataImg('image'+_index)" attr.data-original="{{url}}" src="{{url}}">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <!-- 简版版简历 -->
            <div class="resumeCell shortEdition" *ngIf="resumeDetails.is_simple_edition">
                <!-- 求职意向 -->
                <div class="cell" *ngIf="resumeDetails?.job_target || resumeDetails?.pro_skills?.length > 0">
                    <div class="left">求职意向</div>
                    <div class="right">
                        <div class="li">
                            <div class="name">期望职位</div>
                            <div class="val">
                                <span *ngFor="let n of resumeDetails.pro_skills">{{n.text}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="education">
                    <div class="title">教育经历</div>
                    <div class="box" *ngFor="let edubg of resumeDetails.education_backgrounds;let index=index">
                        <div class="left">
                            <div class="name">{{edubg.graduation_school}}</div>
                        </div>
                        <div class="right">
                            <img class="rightIcon" src="/assets/images/v3/shortEditionIcon04.png">
                            <div class="item">
                                <div class="icon"><img src="/assets/images/v3/shortEditionIcon01.png"></div>
                                <div class="text">
                                    <div class="timeTitle">在校时间</div>
                                    <div class="time">{{edubg.start_date|date:'yyyy年MM月'}} — {{edubg.end_date|date:'yyyy年MM月'}}</div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="icon"><img src="/assets/images/v3/shortEditionIcon02.png"></div>
                                <div class="text">
                                    <div class="timeTitle">专业</div>
                                    <div class="time">{{edubg.major_status_text}}</div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="icon"><img src="/assets/images/v3/shortEditionIcon03.png"></div>
                                <div class="text">
                                    <div class="timeTitle">学历</div>
                                    <div class="time">{{edubg.education_text}}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="rightMain">
        <!-- 标签 -->
        <div class="labelBox" *ngIf="resumeLabelDetail">
            <div class="center">
                <div class="top" *ngIf="resumeLabelDetail.resume_labels">
                    <div class="tl">添加标签</div>
                    <div class="number">{{resumeLabelDetail.resume_labels.length}}/3</div>
                </div>
                <div class="lbs">
                    <span *ngFor="let key of resumeLabelDetail?.resume_labels">{{key.name}}</span>
                </div>
                <div class="remarks" *ngIf="resumeLabelDetail?.remark">{{resumeLabelDetail?.remark}}</div>
                <div class="btn blueStreamer" (click)="editresumeLabelClick()"><img src="/assets/images/v3/editIcon.png">管理</div>
            </div>
        </div>
        <div class="labelBox" *ngIf="!resumeLabelDetail">
            <div class="center">
                <div>备注：</div>
                <div class="remarks">{{notesModel.remark}}</div>
                <div class="btn blueStreamer" (click)="notesClick()"><img src="/assets/images/v3/editIcon.png">管理</div>
            </div>
        </div>

        <!-- 操作记录 -->
        <div class="record">
            <div class="center">
                <div class="title">操作记录</div>
                <div class="item" *ngFor="let n of ResumeLog">
                    <div class="time">{{n.add_time|date:'yyyy-MM-dd HH:mm:ss'}}</div>
                    <div class="desc">{{n.desc}}</div>
                </div>
                <div style="width: 100%;height: 30px;font-size: 18px;color: #333333">
                    <router-outlet></router-outlet>
                    <app-smail-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)">
                    </app-smail-paging>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- 极速电话联系 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="phoneModel.isShowPhoneModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':592,'height':536,'minHeight':536}"
    [modal]=true>
    <div class="alertPhone_Bg">
        <img style="width: 150px;height: 150px;margin-left: 0px;margin-top: 0px;position: absolute" src="../../assets/images/jisudianhuaBg.png">


        <div style="margin-top: 90px; background-color: white; background-image: url(../../assets/images/jisu_bg.png) " *ngIf="resumeDetails">
            <img style="width: 250px;height: 26px;margin-left: 171px;margin-top: 25px" src="../../assets/images/jisudianhualianxi.png">

            <div class="userCard">
                <div class="userCard_top">
                    <img class="userCard_top_userPic" *ngIf="resumeDetails.talent_base.logo" src="{{resumeDetails.talent_base.logo}}">
                    <img class="userCard_top_userPic" *ngIf="!resumeDetails.talent_base.logo&&resumeDetails.talent_base.gender=='01'" src="../../assets/images/male.png">
                    <img class="userCard_top_userPic" *ngIf="!resumeDetails.talent_base.logo&&resumeDetails.talent_base.gender=='02'" src="../../assets/images/female.png">
                    <div class="userCard_top_v1">
                        <span class="userCard_top_userName">{{resumeDetails.talent_base.name}}</span>
                        <img class="userCard_top_userSex" *ngIf="resumeDetails.talent_base.gender=='01'" src="../../assets/images/mini_res/man.png">
                        <img class="userCard_top_userSex" *ngIf="resumeDetails.talent_base.gender=='02'" src="../../assets/images/mini_res/woman.png">
                    </div>
                    <div class="userCard_top_v2">
                        <span class="userCard_top_userSchool" *ngIf="resumeDetails?.education_backgrounds?.length > 0">
                        {{resumeDetails.education_backgrounds.length>0?resumeDetails.education_backgrounds[0].graduation_school+'
                        | ':''}}
                        {{resumeDetails.education_backgrounds.length>0?resumeDetails.education_backgrounds[0].major_status_text+'
                        | ':''}}
                        {{resumeDetails.education_backgrounds.length>0?resumeDetails.education_backgrounds[0].education_text:''}}
                        </span>
                    </div>
                </div>
                <div class="userCard_bottom">
                    <span *ngFor="let youshi of resumeDetails?.youshiArr | slice:0:4;let i = index; " class="userCard_biaoqian">{{youshi}}</span>
                </div>
            </div>

            <div *ngIf="phoneModel.PhoneModelType==0">
                <div style="width:482px;margin: 30px 0 15px 50px;height: 30px">
                    <img class="alertPhone_icon" src="../../assets/images/product_position.png" />
                    <span class="alertPhone_title">沟通职位</span>
                    <select class="alertPhone_position" [(ngModel)]="phoneModel.positionid">
            <option class="place-text" *ngFor="let a of positionList" [value]="a.positionid">{{a.name}}</option>
          </select>
                </div>
                <div style="width:482px;margin: 15px 0 15px 50px;height: 30px">
                    <img class="alertPhone_icon" src="../../assets/images/product_phone.png" />
                    <!--<span class="alertPhone_title">呼出手机</span>-->
                    <select class="alertPhone_phoneType" [(ngModel)]="phoneModel.getPhoneType">
            <option class="place-text" value='1'>呼出手机</option>
            <option class="place-text" value='2'>呼出座机</option>
          </select>
                    <input class="alertPhone_phoneInput" *ngIf="phoneModel.getPhoneType=='1'" [(ngModel)]="phoneModel.myPhone" oninput="value=value.replace(/[^\d]/g,'')" />

                    <input placeholder="区号" class="alertPhone_telquInput" *ngIf="phoneModel.getPhoneType=='2'" [(ngModel)]="phoneModel.myTel_qu" oninput="value=value.replace(/[^\d]/g,'')" />
                    <span *ngIf="phoneModel.getPhoneType=='2'"> -</span>
                    <input placeholder="座机号" class="alertPhone_phoneInput" *ngIf="phoneModel.getPhoneType=='2'" [(ngModel)]="phoneModel.myTel" oninput="value=value.replace(/[^\d]/g,'')" />
                    <span class="userCard_chips">* 将使用该号码呼出</span>
                </div>
                <button class="jisu_commit" (click)="checkMyPhone()">确认</button>
            </div>

            <div *ngIf="phoneModel.PhoneModelType==1">
                <div class="jisu_phone_v1">
                    <img class="jisu_phone_v1_icon" src="../../assets/images/dunpai.png">
                    <span class="jisu_phone_v1_title">{{phoneModel.NetPhone}}</span>
                    <span class="jisu_phone_v1_title_1" title="工作啦为大学生提供号码保护，已隐藏对方的真实号码。">(此号码为虚拟号 <img
              style="width:9px;height: 9px " src="../../assets/images/department-identity-explainImg.png"> )</span>
                </div>
                <div class="jisu_phone_v2">
                    <span style="color:#999999;font-size: 10px ">*请使用<u>{{phoneModel.getPhoneType=='1'?phoneModel.myPhone:phoneModel.myTel_qu+phoneModel.myTel}}</u><span
              (click)="updataMyPhone()" style="color: #5E4FFF">【点击修改】</span>拔打，通话结束后，虚拟号随即失效。
                    </span>
                </div>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog id="bigPackage" [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':1000,'height':500}" [modal]="true" [showHeader]="false" [(visible)]="isShowBigImg">
    <div style="width: 1000px;height: 500px">
        <div class="layui-carousel" id="test1Btn618">
            <div carousel-item>
                <img class="bannerImage" *ngFor="let n of swiperImgUrl;let i = index;" src="{{n}}" />
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog id="bigPackage" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px'}" [dismissableMask]="true" [style]="{'padding':0,'border':0}" [modal]="true" [showHeader]="false" [(visible)]="showNotes">
    <div class="notesAlert">
        <div class="titleView"><span>备注</span></div>
        <textarea class="beizhu" cols="30" placeholder="添加备注..." rows="4" [(ngModel)]="notesModel.remark"></textarea>
        <div class="buttomview">
            <div class="btn blueStreamer" (click)="notesSave()">确定</div>
            <div class="btn active" (click)="showNotes = false">取消</div>
        </div>
    </div>
</p-dialog>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>