import { Component, OnInit, Input, Output } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
@Component({
  selector: 'app-position-rules',
  templateUrl: './position-rules.component.html',
  styleUrls: ['./position-rules.component.css']
})
export class PositionRulesComponent implements OnInit {
  public config: Config;
   // 筛选条件
   public model={
    name:'',
    offset:0,
    limit:9
  }
  public rulesList = [];//招聘简章列表
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();
    }

  ngOnInit() {
    this.opera.initConfig('position','position-rules');
    this.getPositionList();//获取列表
  }

  //去发布招聘简章
  goPublishRules(){
    this.local.remove(Constants.briefDetais);
    this.local.remove(Constants.briefid);
    this.router.navigateByUrl('/home/position-rules-editor');
  }
  //查看招聘简章
  goRulesDetails(id){
    this.local.set(Constants.briefid,id);
    this.router.navigateByUrl('/home/position-rules-details');
  }
  //修改招聘简章
  goRulesEditor(n){
    this.local.set(Constants.briefDetais,JSON.stringify(n));
    this.router.navigateByUrl('/home/position-rules-editor');
  }
  //删除招聘简章
  deleteRules(id){
    this.opera.loading();
    this.dataApi.deleteRules(id).then((res:any)=>{
      this.toastr.success("删除成功");
      this.getPositionList();//获取列表
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      this.toastr.error("出现错误，请稍后重试");
    })
  }

  //获取招聘简章列表
  getPositionList(){
    this.opera.loading();
    this.dataApi.getRulesList(this.model).then((res:any)=>{
      // console.log("获取招聘简章列表-----");
      // console.log(res);
      this.rulesList =  res.list;
      this.countList=res.count;
      this.pageCount =Math.ceil(res.count/9);
      this.opera.loadiss();
    }).catch((error) => {
      this.toastr.error(error);
      this.opera.loadiss();
    });
  }

  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数----');
    //console.log(index);
    if(index > 0){
        this.pageNumber = index;
        this.model.offset = (this.pageNumber-1)*9;
        this.getPositionList();//获取列表
    }else if(index == -1){//上一页
        if(this.pageNumber == 1){
          this.toastr.warning("当前为第一页");
        }else{
          this.pageNumber = this.pageNumber - 1;
          this.model.offset = (this.pageNumber-1)*9;
          this.getPositionList();//获取列表
        }
    }else if(index == -2){//下一页
        if(this.pageNumber == this.pageCount){
          this.toastr.warning("当前为最后一页");
        }else{
          this.pageNumber = this.pageNumber + 1;
          this.model.offset = (this.pageNumber-1)*9;
          this.getPositionList();//获取列表
        }
    }

  }


}
