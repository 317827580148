
<div class="homepage-block">
  <router-outlet></router-outlet>
  <div class="box">
    <div class="topBg">
      <div class="topRow">
        <img class="topRowIcon"  src="../../assets/images/icon_ladou_v2.png"/>
        <div class="topRowMyLa flex_l">我的啦豆余额:<span style="margin-left: 10px">{{MyLaCoinCount + ladouForzen.ladou}}</span> <div class="mingxi flex_l" (click)="gotoPayHistory()">啦豆明细<img src="../../assets/images/icon_chakan.png" /></div></div>
        <div class="topRowMyLa_desc">“啦豆”是「工作啦」平台虚拟产品，用于购买该平台提供的校招工具，提升招聘效果。</div>
      </div>
    </div>
    <div class="labiBox">
      <div class="labi_title">购买啦豆</div>
      <div class="flex_l zengsong">
        <img src="../../assets/images/icon_hui.png" />
        支付每满<span>¥1000</span>送<span>50</span>啦豆
      </div>
      <div class="labi_title_chips">选择您需要充值的啦豆数量</div>
      <div class="labiListBox">
        <div [ngStyle]="{'background': n.hot ?  'linear-gradient(to right,#FA5941,#FFAC4D)' : '' }" [ngClass]="{labiListBox_item_s:n.selete==true,labiListBox_item:n.selete==false}"  (click)="seleteLaCoinClick(n)"   *ngFor="let n of lalist">
            <div  [ngClass]="{topDiv_hot:n.hot==true,topDiv:n.hot==false}" *ngIf="n.topTitle">
              <span class="topDiv_title" [ngStyle]="{'color': n.hot ?  '#FC7E46' : '#FFFFFF' }" >{{n.topTitle}}</span>
            </div>
            <div class="topDiv2" *ngIf="n.selete">
              <img class="topDiv_icon_s" *ngIf="n.hot"  src="../../assets/images/labicheck_w.png"/>
              <img class="topDiv_icon_s" *ngIf="!n.hot"  src="../../assets/images/labicheck.png"/>
            </div>
          <p [ngStyle]="{'color': n.hot ?  'white' : '#333333' }" class="labiListBox_item_p">{{n.count}}<span *ngIf="n.hot" style="font-size: 12px"> (推荐)</span></p>
        </div>
      </div>
      <div class="line"></div>
    </div>
  </div>
</div>

<div class="bottomCss animated " *ngIf="seleteLaCoin&&config.lastIdentity&&config.lastIdentity.status==2" [ngClass]="{'bounceInUp':seleteLaCoin,'bounceOutDown':!seleteLaCoin}" id="bottom" >
  <div class="bottomContent flex_s" *ngIf="seleteLaCoin">
    <div class="bottomContent_l">
      <div class="bottomContent_l_row bottomSpace flex_l">
        <span class="bottomContent_l_row_title">卡券：</span>
        <select  class="seleteCard labiDetai_cell_r" *ngIf="seleteLaCoin?.cards.length>0" [value]="currentCard" [(ngModel)]="currentCard" (ngModelChange)="seleteCardChange()">
          <option value="">不使用卡劵</option>
          <option value="{{i}}" *ngFor="let n of seleteLaCoin.cards ;let i = index;">{{n.title}}</option>
        </select>
        <span class="noneCard" *ngIf="seleteLaCoin?.cards.length==0">暂无卡劵</span>
      </div>
      <div class="bottomContent_l_row flex_l">
        <span class="bottomContent_l_row_title">赠送啦豆：</span>
        <span class="bottomContent_l_row_value" *ngIf="currentCard!=''">+{{seleteLaCoin.cards[currentCard].gift_amount}}</span>
        <span class="bottomContent_l_row_value" *ngIf="currentCard==''">+{{seleteLaCoin.give}}</span>
      </div>
      <div class="bottomContent_l_row flex_l">
        <span class="bottomContent_l_row_title">到账啦豆：</span>
        <span class="bottomContent_l_row_value" *ngIf="currentCard!=''">{{ seleteLaCoin.count+seleteLaCoin.cards[currentCard].gift_amount}}</span>
        <span class="bottomContent_l_row_value" *ngIf="currentCard==''">{{seleteLaCoin.all}}</span>
      </div>
      <div class="bottomContent_l_row flex_l">
        <span class="bottomContent_l_row_title">实际支付金额：</span>
        <span class="bottomContent_l_row_value jine">¥{{countMoney()}}</span>
      </div>
    </div>
    <div class="bottomContent_r flex_r">
      <button class="buyBtn bordeRadius" (click)="pay()" *ngIf="seleteLaCoin">立即购买</button>
    </div>
  </div>
</div>

<p-dialog  [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="false" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':400,'height':200,'minHeight':200}"  [modal]="true" [showHeader]="false" [(visible)]="showAlertInput">
  <div style="width: 400px;height: 200px;background-color: white;overflow: hidden;">
    <input placeholder="请输入金额" value="{{custCount}}" [(ngModel)]="custCount" oninput = "value=value.replace(/[^\d]/g,'')" style="width: 300px;height: 44px;margin-left: 50px;margin-top: 50px;border: 1px lightgray solid;padding: 0 10px" />
    <button style="width: 120px;height: 40px;float: left;margin-left: 50px;margin-top: 30px;border: 1px lightgray solid;" (click)="cancelAler()">取消</button>
    <button style="width: 120px;height: 40px;float: right;margin-right: 50px;margin-top: 30px;border: 1px lightgray solid;background-color: #5e4fff;color: white" (click)="commitAlert()">确定</button>
  </div>
</p-dialog>
