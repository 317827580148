import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router} from  '@angular/router';
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent{
  constructor( private router: Router,
    private activatedRoute: ActivatedRoute) { }

  goHomepage(){
    this.router.navigateByUrl('home/college-resume');
  }
}
