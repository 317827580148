<!-- 页头部分 -->
<div class="contentRight-header"><img (click)="opera.goChangeRouter('position-rules')" src='assets/images/router-old.png'/>招聘管理 - 招聘简章 - 招聘简章详情</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <div class="college-block" *ngIf="item">
      <!-- 页头部分 -->
      <div class="college-block-head">{{item.name}}</div>
      <!-- 小字部分 -->
      <div class="college-msg">
        <span *ngIf="item.department_name">招聘单位：{{item.department_name}}</span>
        <span *ngIf="item.add_time">发布时间：{{item.add_time | date:"yyyy-MM-dd"}}</span>
      </div>
      <!-- 文章部分 -->
      <div class="college-message" [innerHtml]="opera.assembleHTML(item.content)"></div>
      <!-- 操作按钮部分 -->
      <div class="bottom-buttom">
          <button class="purple" (click)="goPublish()">修改</button>
          <button class="yellow" (click)="goDelete()">删除</button>
      </div>
    </div>
    <div class="college-block">
        <!-- 页头部分 -->
        <div class="college-block-head">审核结果</div>
      <!-- 中间部分 -->
      <!-- 没有内容存在时 -->
      <div *ngIf="countList==0" class="none-block">
          <img src="assets/images/resume-search-fail.png"><br/>
          暂未找到审核结果
      </div>
      <!-- 循环列表 -->
      <div *ngIf="countList>0" class="college-list-cont">
        <ul>
            <li>学校名称</li>
            <li>分类</li>
            <li>性质</li>
            <li>所在城市</li>
            <li>就业办电话</li> 
            <li>审核结果</li>
            <li>操作</li>
        </ul>
               
        <ul class="college-li" *ngFor="let n of collegeList;let i = index;">
            <li (click)="goCollege(n.collegeid)"><span *ngIf='n.college_name'>{{n.college_name}}</span><span *ngIf='!n.college_name'>暂无</span></li>
            <li><span *ngIf='n.school_type_text'>{{n.school_type_text}}</span><span *ngIf='!n.school_type_text'>暂无</span></li>
            <li><span *ngIf='n.school_nature_text'>{{n.school_nature_text}}</span><span *ngIf='!n.school_nature_text'>暂无</span></li>
            <li><span *ngIf='n.region_text'>{{n.region_text}}</span><span *ngIf='!n.region_text'>暂无</span></li> 
            <li><span *ngIf='n.phone'>{{n.phone}}</span><span *ngIf='!n.phone'>暂无</span></li>                   
            <li>
                <span *ngIf="n.audit_status==1">审核中</span>
                <span *ngIf="n.audit_status==2">已通过</span>
                <span *ngIf="n.audit_status==3">未通过</span>
            </li>
            <li>
                <span *ngIf="n.audit_status==3" (click)="showConfirm(n)">查看原因</span>
                <span *ngIf="n.audit_status==3" (click)="goUpdateCollege(n)">重新提交</span>
                <span *ngIf="n.audit_status!=3" class="gray">查看原因</span>
                <span *ngIf="n.audit_status!=3" class="gray">重新提交</span>
            </li> 
        </ul>       
    </div>

    <!-- 分页部分 -->
    <div class='paging-block' [class.isPageHeight]="countList>0">
        <router-outlet></router-outlet>
        <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
    </div>
  </div>
</div>
