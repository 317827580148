<!-- 页头部分 -->
<!-- <div class="contentRight-header">企业中心 - 企业认证</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <div class="college-block">
    <!-- <img class="goBack " (click)="opera.goNewRouter('member-center')" src='assets/images/router-old.png'/> -->
    <div class="productList">
      <p class="flex_row_between buyExplain">
        <img *ngIf="config?.department?.quota?.product_component_info?.length < 1" (click)="opera.goNewRouter('member-center')" src='assets/images/router-old.png'/>
        <img *ngIf="config?.department?.quota?.product_component_info?.length > 0" (click)="opera.goNewRouter('company-status-two')" src='assets/images/router-old.png'/>
        <span class="flex_row_left" routerLink="../../home/company-status-three">
          购买说明
          <img src="../../assets/images/di-text1.png" alt="">
        </span>
      </p>
      <ul class="flex_row_between">
        <li class="proMain proMain1" [class.cur]="cur == 1" (mouseover)="curs(1)">
          <div class="proName">
            <img src="assets/images/pron1.png" alt="">
          </div>
          <div class="flex_col_around priceBlock">
            <div class="priceinfo flex_row_center">
              <sup>￥</sup>
              <em>0</em>
              <span></span>
            </div>
          </div>
          <ul class="contentList">
            <li>招聘信息对合作高校学生可见</li>
            <li>查看合作高校学生</li>
            <li>直聊合作高校学生</li>
            <li>直聊<em>5</em>人/天<span></span></li>
            <li><del>&nbsp;&nbsp;极速短信联系<span style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（学生回复快）</span>&nbsp;&nbsp;</del></li>
            <li><del>&nbsp;&nbsp;面试短信通知<span style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（到面率更高）</span>&nbsp;&nbsp;</del></li>
            <li><del>&nbsp;&nbsp;学生投递后，简历直达邮箱<span  style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（简历好管理）</span>&nbsp;&nbsp;</del></li>
            <li><del>&nbsp;&nbsp;多维度筛选，精准匹配学生<span style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（学生更匹配）</span>&nbsp;&nbsp;</del></li>
            <li><del>&nbsp;&nbsp;学生浏览关注职位可查看<span style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（不错过意向）</span>&nbsp;&nbsp;</del></li>
            <li><del>&nbsp;&nbsp;活动短信通知<span style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（提高到场率）</span>&nbsp;&nbsp;</del></li>
          </ul>
        </li>
        <li class="proMain proMain2" [class.cur]="cur == 2" (mouseover)="curs(2)" (click)="goSearch(998)">
          <div class="proName">
            <img src="assets/images/pron2.png" alt="">
          </div>
          <div class="flex_col_around priceBlock">
            <div class="priceinfo flex_row_center">
              <sup>￥</sup>
              <em>998</em>
              <span>/年</span>
            </div>
            <button class="pron2" (click)="goSearch(998)">立即购买</button>
          </div>
          <ul class="contentList">
            <li>招聘信息全网可见</li>
            <li>查看全网学生</li>
            <li>直聊全网学生</li>
            <li>直聊<em>20</em>人/天<span></span></li>
            <li><del>&nbsp;&nbsp;极速短信联系<span style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（学生回复快）</span>&nbsp;&nbsp;</del></li>
            <li><del>&nbsp;&nbsp;面试短信通知<span style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（到面率更高）</span>&nbsp;&nbsp;</del></li>

            <li><del>学生投递后，简历直达邮箱<span style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（简历好管理）</span></del></li>

            <li>多维度筛选，精准匹配学生<span>（学生更匹配）</span></li>
            <li>学生浏览关注职位可查看<span>（不错过意向）</span></li>

            <li><del>&nbsp;&nbsp;活动短信通知<span style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（提高到场率）</span>&nbsp;&nbsp;</del></li>
          </ul>
        </li>
        <li class="proMain proMain3" [class.cur]="cur == 3" (mouseover)="curs(3)" (click)="goSearch(1998)">
          <div class="proName">
            <img src="assets/images/pron3.png" alt="">
          </div>
          <div class="flex_col_around priceBlock">
            <img class="recommend" src="assets/images/recommend.png" alt="">
            <div class="priceinfo flex_row_center">
              <sup>￥</sup>
              <em>1998</em>
              <span>/年</span>
            </div>
            <button class="pron3" (click)="goSearch(1998)">立即购买</button>
          </div>
          <ul class="contentList">
            <li>招聘信息全网可见<span>（覆盖更广）</span></li>
            <li>查看全网学生<span>（查看更多）</span></li>
            <li>直聊全网学生<span>（选择更多）</span></li>
            <li>直聊<em>30</em>人/天<span>（简历更多）</span></li>
            <li>极速短信联系<em>200</em>人次<span>（学生回复快）</span></li>
            <li>面试短信通知<em>200</em>人次<span>（到面率更高）</span></li>
            <li>学生投递后，简历直达邮箱<span>（简历好管理）</span></li>
            <li>多维度筛选，精准匹配学生<span>（学生更匹配）</span></li>
            <li>学生浏览关注职位可查看<span>（不错过意向）</span></li>
            <!-- <li><div class="proni pron3i flex_col_center"><img src="assets/images/gift.png" alt=""><i>赠送</i></div>100人次<span>（活动到场率更高）</span></li> -->
            <li><div class="proni pron3i flex_col_center"><img src="assets/images/gift.png" alt=""><i>赠送</i></div>
              <div class="flex_col_center zsText">
                <p>活动短信通知<em>200</em>人次</p>
                <p><span>（提高到场率）</span></p>
              </div>
          </ul>
        </li>
        <li class="proMain proMain4" [class.cur]="cur == 4" (mouseover)="curs(4)" (click)="goSearch(3998)">
          <div class="proName">
            <img src="assets/images/pron4.png" alt="">
          </div>
          <div class="flex_col_around priceBlock">
            <div class="priceinfo flex_row_center">
              <sup>￥</sup>
              <em>3998</em>
              <span>/年</span>
            </div>
            <button class="pron4" (click)="goSearch(3998)">立即购买</button>
          </div>
          <ul class="contentList">
            <li>招聘信息全网可见<span>（覆盖更广）</span></li>
            <li>查看全网学生<span>（查看更多）</span></li>
            <li>直聊全网学生<span>（选择更多）</span></li>
            <li>直聊<em>80</em>人/天<span>（简历更多）</span></li>
            <li>极速短信联系<em>500</em>人次<span>（学生回复快）</span></li>
            <li>面试短信通知<em>500</em>人次<span>（到面率更高）</span></li>

            <!-- <li>极速电话联系<em>500</em>人/次<span>（沟通学生快）</span></li> -->
            <!-- <li>批量邀投简历<em>500</em>人/次<span>（收简历更快）</span></li> -->
            <li>学生投递后，简历直达邮箱<span>（简历好管理）</span></li>
            <li>多维度筛选，精准匹配学生<span>（学生更匹配）</span></li>
            <li>学生浏览关注职位可查看<span>（不错过意向）</span></li>
            <li>
              <div class="proni pron4i flex_col_center"><img src="assets/images/gift.png" alt=""><i>赠送</i></div>
              <div class="flex_col_center zsText">
                <p>活动短信通知<em>500</em>人次</p>
                <p><span>（提高到场率）</span></p>
              </div>
            </li>
            <!-- <li><div class="proni pron4i flex_col_center"><img src="assets/images/gift.png" alt=""><i>赠送</i></div>订阅短信提醒一年<span>（校招早知道）</span></li> -->
          </ul>
        </li>
      </ul>
    </div>

    <!--动态获取-->
    <!--<div class="productList">-->
    <!--<ul class="flex_row_between" >-->
      <!--<li class="proMain proMain1" *ngFor="let item of productList;let i = index" [class.cur]="cur ==  i" (mouseover)="curs(i)">-->
        <!--<div class="proName">-->
          <!--<img src="assets/images/pron1.png" alt="">-->
        <!--</div>-->
        <!--<div class="flex_col_around priceBlock">-->
          <!--<div class="priceinfo flex_row_center">-->
            <!--<sup>￥</sup>-->
            <!--<em>0</em>-->
            <!--<span></span>-->
          <!--</div>-->
        <!--</div>-->
        <!--<ul class="contentList">-->
          <!--<li  *ngFor="let item of item.product_infos;let a = index">招聘信息对合作高校学生可见</li>-->
          <!--<li>查看合作高校学生</li>-->
          <!--<li>直聊合作高校学生</li>-->
          <!--<li>直聊<em>5</em>人/天<span></span></li>-->
          <!--<li><del>&nbsp;&nbsp;极速短信联系<span style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（学生回复快）</span>&nbsp;&nbsp;</del></li>-->
          <!--<li><del>&nbsp;&nbsp;面试短信通知<span style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（到面率更高）</span>&nbsp;&nbsp;</del></li>-->
          <!--<li><del>&nbsp;&nbsp;学生投递后，简历直达邮箱<span  style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（简历好管理）</span>&nbsp;&nbsp;</del></li>-->
          <!--<li><del>&nbsp;&nbsp;多维度筛选，精准匹配学生<span style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（学生更匹配）</span>&nbsp;&nbsp;</del></li>-->
          <!--<li><del>&nbsp;&nbsp;学生浏览关注职位可查看<span style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（不错过意向）</span>&nbsp;&nbsp;</del></li>-->
          <!--<li><del>&nbsp;&nbsp;活动短信通知<span style="color: #cccccc;font-size: 12px;font-weight: 500;transition: all .5s">（提高到场率）</span>&nbsp;&nbsp;</del></li>-->
        <!--</ul>-->
      <!--</li>-->
    <!--</ul>-->
    <!--</div>-->


    <div class="consultation flex_row_right">
      <img (click)="opera.goChats('JuniorSecretaryD')" src="assets/images/consultation.png" alt="">
    </div>
  </div>
</div>

