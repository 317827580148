import {Component, EventEmitter, Input, OnInit, Output,ViewChild} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import {Config, Constants, DataApi, Local,Opera} from "../../provider";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
declare var laydate;
@Component({
  selector: 'app-model-send-msg',
  templateUrl: './model-send-msg.component.html',
  styleUrls: ['./model-send-msg.component.css']
})

export class ModelSendMsgComponent implements OnInit {
  public config: Config;

  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true }) 
  productModel: ProductModelComponent;

  @Input() isShow: false; // 是否显示弹窗页面
  @Input() tconfig: any; // 是否显示弹窗页面
  @Input() SengMsgModel = {
    userName:'',          //用户姓名
    userPic:'',           //用户头像
    talentid:'',          //学生ID
    graduation_school:'', //学校
    major_text:'',        //专业
    gender:'',             //性别 01,02
    positionid: '',
    address:''
  };                      //打开单品发送极速短信用户对象
  public phone = '';// 联系方式
  public content = '';
  public content1 = '';
  public sendState = false;
  public sendState1 = false;
  public msgCount = 0;
  public currentMSGModel=null;
  public time='';
  public place = '';// 面试地点
  public pageType = 1;// 面试地点
  public userList = [];// 面试地点
  public phoneModel = {
    jiduCode:'',
    positionPushCode:''
  }
  public resultList = [
    // {name:"张三",success:false,content:"发送失败，用户没有开通手机号"},
    // {name:"例三",success:true,content:"发送成功"},
    // {name:"张大海",success:false,content:"发送失败，用户欠费"},
    // {name:"张小海",success:true,content:"发送成功"},
    // {name:"张三",success:false,content:"发送失败，用户没有开通手机号"},
    // {name:"例三",success:true,content:"发送成功"},
    // {name:"张大海",success:false,content:"发送失败，用户欠费"},
    // {name:"张小海",success:true,content:"发送成功"},
  ];//批量邀请结果


  public MSGTypeList = [
    {title:'交换电话'},
    {title:'邀投简历'},
    {title:'邀请面试'},
  ]
  public currentType = this.MSGTypeList[0].title;
  public positionid = '';
  public sms_bulkinvitation = null;
  public posObj = {
      name:'',
      positionid:''
  };
  public posName = '';
  constructor(
    public toastr: ToastrService,
    private router: Router,
    public dataApi: DataApi,
    private local: Local,
    private opera: Opera

  ){

  }

  ngOnInit() {
    //日历插件初始化
/*    let that = this;
    laydate.render({
      elem: '#noticeDate',
      type: 'datetime',
      format: 'yyyy年MM月dd日HH点mm分',
      done: function(value, date){
        that.time = value;
        let str = that.content1;
        console.log('value',str);
        that.content1 = str.replace('{1}',value);
      }
    })*/
    
  }

  initData(type,userList){
    // this.opera.scrollTop1();
    // this.opera.scrollTop();

    console.log('初始化数据-获取消耗短信数',this.SengMsgModel);
    this.pageType=type;
    this.sendState = false;
    this.content = '';
    this.currentType = '邀投简历';
    this.currentMSGModel=null;
    this.content1 = '';
    this.time  = '';
    this.place  = '';
    this.userList = userList;
    //this.getMSGCount();
    this.positionid = "";
    if(type==1)
    {
      //获取短信模版
      this.getMSGContent( this.currentType);
      this.getPositionList();
      this.getDepartment();
      this.getProductList()
    }
    else
    {
      this.getPositionList();
      //this.positionid='';
    }


  }
  getProductList(){
    //product_category:"recharge",recharge_category:''
    this.dataApi.departmentList({product_category:"recharge"}).then((res:any)=>{
      if(res){
        for (let item of res.list)
        {
          if(item.recharge_category == "voice_call")
          {
            this.phoneModel.jiduCode = item.code;
          }
          if(item.recharge_category == "sms_bulkinvitation")
          {
            this.phoneModel.positionPushCode = item.code;
          }
        }
      }
    }).catch((err)=>{
      console.log(err);
    })
  };
  //获取短信模版
  getMSGContent(type){
    this.dataApi.getMSGContent(type).then((res:any)=>{
      this.getPushPhone();
      console.log('getMSGContent',res);
      this.content = res.text;
      this.content1 = res.text.replace('#talent_name#',this.SengMsgModel.userName);

      this.currentMSGModel = res;
    }).catch((err)=>{
      // console.log('getMSGContenterr',err);
      this.toastr.error(err)
    })

  }

  typeClick(type){
    
   this.currentType = type;
   this.getMSGContent(type);
  }

  /**
   * 关闭按钮，并外抛方法
   */
  @Output() onVoted = new EventEmitter();
  dismiss(result: any) {
    //console.log("传值检查");
    //console.log(result);
    this.isShow  = false;
    this.content = '';
    this.currentType = '';
    this.onVoted.emit(result);
    this.opera.scrollTrue();
  }




  //获取企业信息
  getDepartment(){
    this.dataApi.getcomponentLeft({}).then((res:any)=>{
      let arr = [];
      res.map((item)=>{
        if(item.alias ==  'sms_bulkinvitation')
        {
          this.sms_bulkinvitation = item;
        }
      })
    }).catch((err)=>{
      console.log(err);
    })

  }
  /**
   * 提交发送短信
   * 余额为0跳转到支付页面
   */
  commitSendMsg(){

    if(!this.sms_bulkinvitation || this.sms_bulkinvitation.left == 0)
    {
      this.toastr.error('余额不足');
      let that = this;
      setTimeout(()=>{
        that.isShow  = false;
        that.content = '';
        that.currentType = '';
        that.onVoted.emit('');
        that.local.set("recharge_category_code", JSON.stringify(this.phoneModel.positionPushCode));
        that.router.navigate(['home/buyProduct']);
/*        that.tconfig.recharge_category = 'sms';
        that.tconfig.recharge_type = 2;
        that.local.set(Constants.recharge_category, JSON.stringify('sms'));
        that.local.set(Constants.recharge_type, 2);
        that.router.navigateByUrl('/home/member-product-detail');*/
      }, 2000);
      return;
    }

    if(this.currentMSGModel==null)
    {
      this.toastr.error('该短信模版不存在');
      return;
    }
    if(this.positionid=='')
    {
      this.toastr.error('请选择职位');
      return;
    }
    if(this.phone==''){
      this.toastr.error('请输入HR招聘电话');
      return;
    }
    let data = {
      "accountid": this.SengMsgModel.talentid,
      "name": this.currentMSGModel.seng_tag_name,
      "code": this.currentMSGModel.code,
      "positionid":this.positionid,
      'place':'',
      'time':'',
    }

    if(this.sendState)
    {
      this.toastr.error('请求中');
      return;
    }
    this.sendState=true;
    this.dataApi.batchPositionPush(this.positionid,{phone:this.phone,invite_count:1,talentids:[this.SengMsgModel.talentid]}).then((res:any)=>{
      console.log('sendSuccess',res);
      this.sendState=false;
      this.isShow  = false;
      this.toastr.success('发送成功!');
      this.onVoted.emit(true);
    }).catch((err)=>{
      if(err == '您还没通过的合作申请') {
        this.opera.freeAttestation("通过工作啦认证才能查看全部学生，立即认证！");//提醒认证
      } else {
        this.toastr.error(err);
      }
      this.sendState=false;
      this.isShow  = false;
      this.onVoted.emit('');
    })

    //
    // this.dataApi.sendSingleMSG(data).then((res:any)=>{
    //   console.log('sendSuccess',res);
    //   this.sendState=false;
    //   this.isShow  = false;
    //   this.toastr.success('发送成功!');
    //   this.onVoted.emit('');
    // }).catch((err)=>{
    //   if(err == '您还没通过的合作申请') {
    //     this.opera.freeAttestation("通过工作啦认证才能查看全部学生，立即认证！");//提醒认证
    //   } else {
    //     this.toastr.error(err);
    //   }
    //   this.sendState=false;
    //   this.isShow  = false;
    //   this.onVoted.emit('');
    // })

  }

  /**
   * 小提示
   * @constructor
   */
  AlertChip() {
   // this.toastr.info('极速短信联系极速短信联系极速短信联系极速短信联系极速短信联系极速短信联系极速短信联系','极速短信联系');
  }

  //获取在期职位列表
  getPositionList(){
    // this.tconfig.positionList=this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
    // console.log('this.tconfig.positionList',this.tconfig.positionList);
    // if(this.tconfig.positionList.length==0){
      this.dataApi.getPositionList({limit:100,offset:0,status:'release'}).then((res:any)=>{
        this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
        this.tconfig.positionList=res.list;
        // console.log("  this.config.positionList",  this.tconfig.positionList)
      }).catch((error) => {
        // console.log('  this.config.positionList',error);
      });
    // }
  }

  /**
   * 提交批量邀请投递
   */
  commitYaoQing(){

    if(this.sendState1)
    {
      this.toastr.error('重复请求！');
      return;
    }
    if(this.posObj.positionid=='')
    {
      this.toastr.error('请选择职位');
        return;
    }
    if(this.msgCount==0||this.userList.length>this.msgCount)
    {
      this.toastr.error('余额不足，请充值');
      let that = this;
      setTimeout(()=>{
        that.isShow  = false;
        that.content = '';
        that.currentType = '';
        that.onVoted.emit('');
        that.tconfig.recharge_category = 'sms';
        that.tconfig.recharge_type = 3;
        that.local.set(Constants.recharge_category, JSON.stringify('sms'));
        that.local.set(Constants.recharge_type, 3);
        that.router.navigateByUrl('/home/member-product-detail');
      }, 2000);
      return;
    }


    let data = {
      "talentids":this.userList,
      "positionid": this.posObj.positionid
    }
    this.sendState1 = true;
    // console.log("data",data);
    this.dataApi.sendYaoQingMSG(data).then((res:any)=>{
      this.toastr.success('请求成功!');
      this.sendState1 = false;
      this.pageType = 3;
      console.log("success",res);
      this.resultList =res.invite_result;

      // setTimeout(()=>{
      //   this.isShow  = false;
      //   this.onVoted.emit('');
      // }, 1000);

    }).catch((err)=> {
      console.log("error",err);
      this.toastr.error(err);
      this.sendState1 = false;
    });


  }
  getMSGCount(){
    //this.pageType==1?'sms_quick':'sms_bulkinvitation'
    this.dataApi.getDepartmentProductMSGCount( this.pageType==1?'sms_quick':'sms_bulkinvitation').then((res:any)=>{
      this.msgCount = res.value;
    }).catch((err)=>{

    })
  }
  //获取上次职位推送电话
  getPushPhone(){
    this.dataApi.getPushPhone().then((res:any)=>{
      console.log('获取上次职位推送电话',res.value);
      if(res && res.value){
        this.phone = res.value;
        this.content1 = this.content1.substring(0,this.content1.indexOf('HR电话')+4) + this.phone
      }
    }).catch((err)=>{

    })
  }

  changeinput(phone){
    this.phone = phone;
    this.content1 = this.content1.substring(0,this.content1.indexOf('HR电话')+4) + this.phone;
  }

    //点击产品
    product(alias,type){
      this.isproductShowModel = true;
      this.productModel.initData(alias,type);
    }
  
    productModelHidden(event){    
      this.isproductShowModel = false;
    }

    _positionPush(){
      this.dismiss(false);
      setTimeout(()=>{
        this.router.navigate(['home/position-publish']);
      },100);
    }

}



//Apple

