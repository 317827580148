import {Component, OnInit, Input, Output, ViewChild} from '@angular/core';
import { DataApi,Local,Opera,Constants,Config } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router , NavigationEnd} from  '@angular/router';
import {filter} from "rxjs/operators";
import {ModelSendMsgComponent} from "../model-send-msg/model-send-msg.component";
@Component({
  selector: 'app-chat-look',
  templateUrl: './chat-look.component.html',
  styleUrls: ['./chat-look.component.css']
})
export class ChatLookComponent implements OnInit {

  @ViewChild('sengMsgModel', { static: true }) sengMsgModel: ModelSendMsgComponent;


  public config: Config;
  public keywordList = [];//搜索关键词匹配列表
  public educationList = [];//学历列表
  public majorListOne = [];//一级专业列表
  public majorListTwo = [];//二级专业列表
  public majorOne = '';//专业类别一级
  public resumeList = [];//简历列表
  public countList = 0;//列表数量

  public sendMsgArr = [];//批量摇头的用户列表
  public useBatch: boolean = false;  // 显隐选择按钮
  public selectAll: boolean = false; // 全选是否

  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math
  @Output() modelShowSengMsg = false;//是否打开单品发送极速短信
  @Output() SengMsgModel = {
    userName: '',
    userPic: '',
    talentid: '',
    graduation_school: '',//学校
    major_text: '',//专业
    gender: '',
  };//打开单品发送极速短信用户对象
  public model = {
    major: '',
    education: '',
    position_name: '',
    positionid: '',
    limit: 10,
    offset: 0
  };

  constructor(public dataApi: DataApi,
              public local: Local,
              private router: Router,
              public opera: Opera,
              public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event:NavigationEnd) => {
      if (event.url === '/home/chat-look') {
        /*在这写需要执行初始化的方法*/
        this.opera.initConfig('home','chat-look');
      }
    });
  }

  ngOnInit() {
    this.getDepartment();//获取企业信息
    this.getMajorList();//获取专业列表信息
    this.getEducationList();//获取学历列表信息
  }

  //获取企业信息
  getDepartment() {
    if (this.dataApi.getLocalDepartment()) {
      // console.log("企业信息本地存储");
      // console.log(this.dataApi.getLocalDepartment());
      this.config.department = this.dataApi.getLocalDepartment();
      if (this.config.department.app_login) {
        this.getPositionViewHistory();//简历列表
      }
    } else {
      this.opera.loading();
      this.dataApi.getDepartment().then((res:any) => {
        this.opera.loadiss();
        console.log("企业信息获取线上数据");
        console.log(res);
        this.dataApi.setLocalDepartment(res);
        if (res.app_login) {
          this.getPositionViewHistory();//简历列表
        }
      }).catch((error) => {
        this.opera.loadiss();
        console.log(error);
        this.toastr.error(error);
      });
    }
  }

  //获取分页子组件返回数据
  getChildEvent(index) {
    //console.log('子组件返回分页数-----college-resume');
    //console.log(index);
    if (index > 0) {
      this.pageNumber = index;
      this.model.offset = (this.pageNumber - 1) * 10;
      this.getPositionViewHistory();//获取简历列表
    } else if (index == -1) {//上一页
      if (this.pageNumber == 1) {
        this.toastr.warning("当前为第一页");
      } else {
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber - 1) * 10;
        this.getPositionViewHistory();//获取简历列表

      }
    } else if (index == -2) {//下一页
      if (this.pageNumber == this.pageCount) {
        this.toastr.warning("当前为最后一页");
      } else {
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber - 1) * 10;
        this.getPositionViewHistory();//获取简历列表
      }
    }

  }

  //-----跳转简历详情
  goDetails(n) {
    this.config.isNavActive = 'college';
    this.config.isNavChildActive = 'college-resume';
    this.router.navigate(['home/college-resumedetails'], {
      queryParams: {
        resumeid: n
      }
    });
  }

  //立即沟通
  goChat(n) {
    this.opera.goChat(n, 0);
  }

  //收藏
  goCollect(val) {
    val = this.opera.manageResumeArchived(val);
  }


  //谁看过我
  getPositionViewHistory() {
    this.opera.loading();
    this.dataApi.getPositionViewHistory(this.model).then((res:any) => {
      //console.log("谁看过我列表");
      //console.log(res);
      this.resumeList = res.list;
      this.countList = res.count;
      this.pageCount = Math.ceil(res.count / 10);
      this.opera.loadiss();
    }).catch((error) => {
      console.log(error);
      this.resumeList = [];
      this.opera.loadiss();
      this.toastr.error(error);
    });
  }

  //获取专业列表信息
  getMajorList() {
    if (this.dataApi.getMajorCode()) {
      this.config.major = this.dataApi.getMajorCode();
      this.getMajorListOne();//获取一级专业列表
    } else {
      this.dataApi.getDictDate('major').then((res:any) => {
        //console.log("获取专业列表-----college-resume");
        //console.log(res);
        this.config.major = res;
        this.getMajorListOne();//获取一级专业列表
        this.dataApi.setMajorCode(res);
      }).catch((error) => {
        console.log(error);
      });
    }
  }


  //获取一级专业列表
  getMajorListOne() {
    for (let n of this.config.major) {
      if (n.level == 2) {
        this.majorListOne.push(n);
      }
    }
  }

  //监听majorOne
  majorOneChange() {
    // //console.log("第一级专业值");
    // //console.log(this.majorOne);
    // //console.log("专业列表");
    // //console.log(this.config.major);
    this.majorListTwo = [];
    for (let n of this.config.major) {
      if (n.parentcode == this.majorOne) {
        this.majorListTwo.push(n);
      }
    }
    this.model.major = '';
    // //console.log("第二级专业列表");
    // //console.log(this.majorListTwo);
  }


  //提示先选一级专业
  isShowTwo() {
    if (!this.majorOne) {
      this.toastr.warning("请先选择一级专业");
      return;
    }
  }

//搜索
  goSearch(data) {
    this.model.position_name = data.name;
    this.model.positionid = data.id;
    this.getPositionViewHistory();//简历列表
    this.keywordList = [];
  }

//输入职位名称搜索
  goSearchName() {
    this.model.positionid = '';
    this.getPositionViewHistory();//简历列表
    this.keywordList = [];
  }

  //监听键盘事件
  inputKeyup(event) {
    let that = this;
    var keycode = window.event ? event.keyCode : event.which;
    if (keycode == 13) {
      that.goSearch(that.model.position_name);
    } else {
      that.getItems();
    }
  }

  //筛选函数
  getItems() {
    let filter = /^[a-zA-Z\u4e00-\u9fa5]+$/;
    if (this.model.position_name) {
      if (filter.test(this.model.position_name)) {
        let model = {
          name: this.model.position_name,
          level: 2
        }
        // this.isSearch = true;
        this.dataApi.positionNameMatch(this.model.position_name).then((res:any) => {
          //  //console.log("匹配职位名称");
          //  //console.log(res);
          this.keywordList = res;
        }).catch((err) => {
          //console.log(err);
        });
      } else {
        let n = String(this.model.position_name).length - 1;
        this.model.position_name = this.model.position_name.slice(0, n);
      }
    } else {
      this.keywordList = [];
      //  this.isSearch = false;
    }
  }

  //获取学历列表信息
  getEducationList() {
    if (this.dataApi.getEducationCode()) {
      //console.log("获取学历列表本地存储-----college-resume");
      //console.log(this.dataApi.getEducationCode());
      this.educationList = this.dataApi.getEducationCode();
    } else {
      this.dataApi.getDictDate('education').then((res:any) => {
        //console.log("获取学历列表-----college-resume");
        //console.log(res);
        this.educationList = res;
        this.dataApi.setEducationCode(res);
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  // 弹出下载
  sendTip() {
    this.opera.sendTipss(`<div class="li-block booth booth-app">
    <img src="assets/images/goworklaQRcode.png" alt="广告图"><br/>
    怕错过最新简历<br/>
    <b>扫码下载APP</b><br/>
    <span>随时随地查看校招新消息</span>
</div>`)
  }

  /**
   * 生源速览极速短信弹窗
   */
  alertSendMsg(n) {

    this.sengMsgModel.initData(1, []);
    this.SengMsgModel.userName = n.talent_name;
    this.SengMsgModel.userPic = n.logo == null ? '' : n.logo;
    this.SengMsgModel.graduation_school = n.graduation_school;
    this.SengMsgModel.major_text = n.major_text;
    this.SengMsgModel.talentid = n.talentid;
    this.SengMsgModel.gender = n.gender;
    this.modelShowSengMsg = true;
  }

  SendMsgdismiss(event) {
    this.modelShowSengMsg = false;
    this.sendMsgArr=[];
    this.useBatch=false;
    console.log("Modeldismiss",)
  }

  // 切换批量邀投开关
  useBatchFn() {
    if (this.sendMsgArr.length == 0) {
      this.useBatch = !this.useBatch;
    } else {
      this.singleTips();
    }
  }

  // 活动通知包触发
  singleTips() {
    this.modelShowSengMsg = true;
    this.sengMsgModel.initData(2, this.sendMsgArr);
  }

  // 单个学生选择
  selectStudentFn(n) {
    if (this.sendMsgArr.indexOf(n.talentid) == -1) {
      this.sendMsgArr.push(n.talentid);
    } else {
      this.sendMsgArr.splice(this.sendMsgArr.indexOf(n.talentid), 1);
    }
    console.log(this.sendMsgArr.indexOf(n.talentid));
    this.checkAll();
  }

  // 检测是否全选
  checkAll() {
    for (let i of this.resumeList) {
      if (this.sendMsgArr.indexOf(i.talentid) == -1) {
        this.selectAll = false;
        break;
      } else {
        this.selectAll = true;
      }
    }
    console.log(this.selectAll);
  }
  // 本页全选
  selectAllFn(){
    console.log(this.resumeList);
    if(!this.selectAll){
      for(let i of this.resumeList){
        if(this.sendMsgArr.indexOf(i.talentid) == -1){
          this.sendMsgArr.push(i.talentid);
        }
      }
      this.selectAll = true;
    }else{
      for(let i of this.resumeList){
        this.sendMsgArr.splice(this.sendMsgArr.indexOf(i.talentid),1);
      }
      this.selectAll = false;
    }
    console.log(this.sendMsgArr);
  }
  // 本页反选
  selectRevFn(){
    console.log(this.sendMsgArr);
    for(let i of this.resumeList){
      if(this.sendMsgArr.indexOf(i.talentid) == -1){
        this.sendMsgArr.push(i.talentid);
      }else{
        this.sendMsgArr.splice(this.sendMsgArr.indexOf(i.talentid),1);
      }
    }
    this.checkAll();
  }
}
