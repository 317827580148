import {Injectable, Input} from "@angular/core";
import {Config} from "./config";
import {DataApi} from "./dataApi";
import {Local} from "./local";
import {Router} from "@angular/router";


declare let window;

@Injectable()
export class scrollTool {

  // index 索引为了滚动到当前索引cell偏移量
  // scrollY 页面具体上面的偏移距离


  //简历管理-收到的cell记录索引
  public  position_resume_cell_index = 1;
  public  position_resume_cell_scrollY = 0;

  //推荐人才-推荐人才列表cell记录索引
  public  college_resume_cell_index = 0;
  public  college_resume_cell_scrollY = 0;


  //推荐人才-对我感兴趣列表cell记录索引
  public  chat_collection_cell_index = 0;
  public  chat_collection_cell_scrollY = 0;

  //职位管理列表cell记录索引
  public  position_manage_cell_index = 0;

  //搜索人才
  public  searchPeople_cell_index = 0;
  public  searchPeople_cell_scrollY = 0;

  //双选会列表
  public  college_milkround_cell_scrollY = 0;
  public  college_milkround_Page = 1;


  //高校云 列表
  public  college_campustalk_cell_scrollY = 0;
  public  college_campustalk_Page = 1;

  public config: Config;


  constructor(dataApi:DataApi,
              public local:Local,
              private router: Router) {
    this.config = Config.getInstance();//实例化config

  }
  /** 设置缓存数据 */
  setKeepAliveData(data:{pageKey:string,[x:string]:any}){
    sessionStorage.setItem('keepAliveData',JSON.stringify(data))
  }
  /** 获取缓存数据 */
  getKeepAliveData(){
    let storageVal:any = sessionStorage.getItem('keepAliveData')
    if(storageVal){
      storageVal = JSON.parse(storageVal)
      return storageVal
    }
    return {}
  }
  /** 设置值同时存入本地缓存中 */
  setVal(key:string,val:number){
    this[key] = val
    sessionStorage.setItem(key,val+'')
  }
  /** 获取值或本地缓存值 */
  getVal(key:string){
    let val:number
    let storageVal:string = sessionStorage.getItem(key)
    if(storageVal){
      val = ~~storageVal
    }else{
      val = this[key]
    }
    return val
  }

  //top  上边的边距
  //初始化滚动
  initScrooll_cell(top,index,cellH) {
    // console.log('初始化滚动索引：',index,top);
    window.scrollTo(0, index<=3?0:top+index*cellH);
  }
  //根据 scrollY 进行偏移
  initScrooll_cell_With_scrollY(scrollY) {
        // console.log('根据 scrollY 进行偏移：',scrollY);
        setTimeout(()=>{
          window.scrollTo(0,scrollY);
        },50)
  }
  //初始化偏移量 并置顶
  initZero(){
    // console.log('initZero');
    sessionStorage.removeItem('keepAliveData')
    this.position_resume_cell_index = 1;
    sessionStorage.removeItem('position_resume_cell_index')
    this.position_resume_cell_scrollY = 0;
    sessionStorage.removeItem('position_resume_cell_scrollY')
    this.college_resume_cell_scrollY = 0;
    sessionStorage.removeItem('college_resume_cell_scrollY')
    this.searchPeople_cell_scrollY = 0;
    sessionStorage.removeItem('searchPeople_cell_scrollY')
    this.college_milkround_cell_scrollY = 0;
    sessionStorage.removeItem('college_milkround_cell_scrollY')
    this.college_milkround_Page = 1;
    sessionStorage.removeItem('college_milkround_Page')
    this.college_campustalk_cell_scrollY = 0;
    sessionStorage.removeItem('college_campustalk_cell_scrollY')

  }
  scrollTop(){
    let body = document.getElementsByTagName('body')[0];
    body.setAttribute('style', 'padding-right: 17px;overflow: hidden');
  }


}
