import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import {Router, ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
//新增高校
import { PushCollegeModelComponent } from "../push-college-model/push-college-model.component";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";

declare var layui;
declare let Swiper:any;

@Component({
  selector: 'app-rights',
  templateUrl: './rights.component.html',
  styleUrls: ['./rights.component.css']
})
export class RightsComponent implements OnInit {

  //是否打开弹窗(非会员新增高校)
  @Output() isShowPushCollegeModel = false;
  @ViewChild('pushCollegeModel', { static: true }) 
  pushCollegeModel: PushCollegeModelComponent;

  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true }) 
  productModel: ProductModelComponent;

  public config: Config;
  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
  ) { 
    
    this.config = Config.getInstance();//实例化config
    this.opera.initConfig('rights','rights');
  }
  public isShowVip = false;
  public department:any = null;
  public rechargeList = [];
  public memberList = [];
  public addCollegeList = [];
  public vipExperience = false;
  public vipExperiencedays = 0;
  //首页数据看板的数据
  public kanbanData = {
    updateTime: '',
    value1: '',
    value2: '',
    value3: '',
    value4: '',
  };
  public bannerList = []; //banner

 async ngOnInit(){
    this.getKanbanData();
    //创建轮播实例
    this.opera.vipState((isPay)=>{
      console.log('this.isPay',isPay);
      this.isShowVip =  isPay;
      if(!this.isShowVip){
        this.mycollegeList(); //我已选的高校
        //非会员广告
        this.cdapi_entity('non_member_interests_pc');       
      }else{
       //会员广告
       this.cdapi_entity('member_interests_pc');      
      }
    })
    //获取企业信息
    this.department = await this.dataApi.getLocalDepartment();
    if(this.department && this.department.member_info){
      this.department.member_info.endtime_text = moment(this.department.member_info.endtime).format('YYYY年MM月DD日');
      this.department.member_info.days = moment(this.department.member_info.endtime).diff(moment(), 'days');
    }
    //是否为体验会员
    if(this.department.is_trial_member){
      let trial_endtime = moment(this.department.memberTrail.endtime).format('YYYY-MM-DD HH:mm:ss');
      let nowTime = moment().format('YYYY-MM-DD HH:mm:ss')
      if(moment(nowTime).isBefore(trial_endtime)){
        this.vipExperience = true;
        this.vipExperiencedays = moment(trial_endtime).diff(moment(nowTime),'days');
      }
    }

    
    //获取在售产品组件剩余
    this.onsaleLeft('member');
    this.onsaleLeft('recharge');
  }


  onsaleLeft(type){
    this.dataApi.onsaleLeft({component_type:type}).then((res: any) => {
      if (res) {
        if(type == 'member'){
          this.memberList = res;          
        }
        if(type == 'recharge'){
          this.rechargeList = res;
        }
      }
    }).catch((err) => {
      console.log('获取在售产品组件剩余', err);
    })
  }

  //获取card广告banner
  getCardbanner() {
      let that = this;
      setTimeout(()=>{
        layui.use(['carousel', 'form'], function() {
          var carousel = layui.carousel
            , form = layui.form;
          //建造实例
          carousel.render({
            elem: '#rightText'
            ,width: '100%' //设置容器宽度
            ,arrow: 'always', //始终显示箭头
            height:'320px',
            interval:3000
            //,anim: 'updown' //切换动画方式
          });
          //找到两个按钮，设置样式，因为全局样式又一个Buttom里有一个样式会导致按钮错乱
          let leftbtn =  document.getElementById("lay-type-left");
          let rightbtn =  document.getElementById("lay-type-add");
          if(leftbtn) {
            leftbtn.style.padding ='0';
          }
          if(rightbtn) {
            rightbtn.style.padding ='0';
          }
        })
      },200)
  }

  //看板数据
  getKanbanData() {
    this.dataApi.getKanbanData("").then((res: any) => {
      if (res) {
        this.kanbanData.updateTime = moment().format('YYYY年MM月DD日');
        this.kanbanData.value1 = this.toThousands(res.talent);  //总求职者人数（人）：
        this.kanbanData.value2 = this.toThousands(res.department);
        this.kanbanData.value3 = this.toThousands(res.position);
        this.kanbanData.value4 = this.toThousands(res.intent_count);
      }
    }).catch((err) => {
      console.log('getKanbanData', err);
    })
  }

  // 方法一
  toThousands(num) {
    var result = [], counter = 0;
    num = (num || 0).toString().split('');
    for (var i = num.length - 1; i >= 0; i--) {
      counter++;
      result.unshift(num[i]);
      if (!(counter % 3) && i != 0) { result.unshift(','); }
    }
    return result.join('');
  }

  //新增高校
  pushCollege(){
    this.isShowPushCollegeModel = true;
    this.pushCollegeModel.initData();
  }

  mycollegeList(){
    this.dataApi.mycollegeList().then((res:any)=>{
      if(res){
        this.addCollegeList = res;
      }
    }).catch((err)=>{
      console.log(err);
    })
  }

  cdapi_entity(code){
    this.dataApi.cdapi_entity(code).then((res:any)=>{
      if(res){
        this.bannerList = [];
        res.listJson.forEach(item =>{
          let itt = JSON.parse(item);
          this.bannerList.push(itt);
        })
        if(this.bannerList.length){
          this.getCardbanner()
        }
      }
    }).catch((err)=>{
      console.log(err);
    })
  }

  pushCollegeModelHidden(event){
    this.isShowPushCollegeModel = false;
    this.mycollegeList(); //我已选的高校
  }
  
  productModelHidden(event){
    this.isproductShowModel = false;
  }

  //点击产品
  product(item,type){
    if(type == 'recharge' && item.alias =='post_number'){
      this.router.navigate(['home/position-manage']);
      return
    }
    if(item.left > 0 && type == 'recharge'){
      this.local.set('productUseHistory',item.alias);
      this.local.set('productUseHistoryLeft',item.left);
      this.router.navigate(['home/productUseHistory']);
    }else{
      this.productModel.initData(item.alias,type);
      this.isproductShowModel = true;
    }
  }

  goActivity(){
    this.router.navigate(['home/college-milkround']);
  }

  goPosition(){
    this.router.navigate(['home/position-manage']);
  }

  goResume(){
    this.router.navigate(['home/position-resume']);
  }

  clickBanner(item){
    if(item.link){
      window.open(item.link,'_blank');
    }
  }
}
