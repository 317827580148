<div class="centerBox">

    <div class="liveBanner">
        <div class="secondNav">
            <div class="item active">空中宣讲会<span></span></div>
            <div class="item " (click)="goPastx()">往期空中宣讲会</div>
        </div>
        <img class="banner" (click)="bannerClick()" src="../../assets/images/live_banner.png?v1.1" />
        <!-- 非会员 -->
        <div class="novipTip" *ngIf="!isShowMemberMoudel">
            <div class="text"> 您当前还不是会员，您只能报名3所高校，您要选择更多高校，请加入会员</div>
            <div class="join joinHover" (click)="product('','contactMe')">加入会员</div>
        </div>
    </div>

    <div class="campListBox">
        <div class="campListTitle">当前录播宣讲会</div>
        <div class="campRow flex_l" *ngIf="isHaveAirCampus&& airCampustalk">
            <img class="cellImage" src="{{airCampustalk.video_cover}}" />
            <div class="celltitle">{{airCampustalk.title}}</div>
            <div class="celltime">{{airCampustalk.apply_time | date:'yyyy-MM-dd HH:mm'}} </div>
            <div class="cellState" (click)="gotoAirCampDetail('airCampusDetail',airCampustalk)">查看详情 ></div>
        </div>
        <div class="campRow_none" *ngIf="!isHaveAirCampus">
            <img src="/assets/images/rights/empty.png">
            <p>您的企业暂未报名活动</p>
            <div class="apply blueStreamer" (click)="gotoAirCampDetail('creatAirCampus')">前往申请</div>
        </div>

        <div class="campListTitle" *ngIf="airLiveCampustalkList.length>0">直播宣讲会</div>
        <div class="campRow flex_l" *ngFor="let item of airLiveCampustalkList">
            <img class="cellImage" src="{{item.video_cover}}" />
            <div class="celltitle">{{item.title}} <img *ngIf="item.air_live_type == 4" class="endIcon" src="../../assets/images/sign5.png" /></div>
            <div class="celltime" *ngIf="item.air_live_type != 4 &&item.air_live_type ==1">{{item.prediction_air_live_start_time | date:'yyyy-MM-dd HH:mm'}} </div>
            <div class="celltime" *ngIf="item.air_live_type != 4 &&item.air_live_type!=1">{{item.live_start_time | date:'yyyy-MM-dd HH:mm'}} </div>
            <div class="celltime" *ngIf="item.air_live_type == 4">{{item.live_end_time | date:'yyyy-MM-dd HH:mm'}} </div>
            <div class="cellState" (click)="gotoLiveAirCampDetail(item)">查看详情 ></div>
        </div>
        <div style="background-color: white;height: 60px;padding-right: 15px" *ngIf="airLiveCampustalkList.length>0">
            <div id="resumePage" (show)="airLiveCampustalkList.length>0" style="float: right;margin-right: 0.5%"></div>
        </div>

        <div class="campListTitle" (show)="othertLiveListCount>0">直播带岗活动</div>
        <div class="Live-broadcast">
            <div class="item" *ngFor="let item of othertLiveList">
                <div class="logo"><img src="{{item.video_cover}}" /></div>
                <div class="rightBox">
                    <div class="name" (click)="gotoDetailWithOtherLive(item)">{{item.title}}</div>
                    <div class="state1" *ngIf="item.air_live_type == 4">已结束</div>
                    <div class="state2" *ngIf="item.air_live_type == 4">{{item.live_end_time | date:'yyyy年MM月dd日 HH:mm'}} </div>
                    <div class="state2" *ngIf="item.air_live_type != 4 &&item.air_live_type ==1">{{item.prediction_air_live_start_time | date:'yyyy年MM月dd日 HH:mm'}} 至 {{item.live_start_time | date:'yyyy年MM月dd日 HH:mm'}}</div>
                </div>
            </div>
        </div>
        <div class="pageView" (show)="othertLiveListCount>0">
            <div id="otherLivePage" style="float: right;margin-right: 0.5%"></div>
        </div>

    </div>
</div>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>