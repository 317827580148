import {Injectable,Provider} from '@angular/core';
import {Constants} from "./constants";
import {Api} from "./api";
import {DataApi} from "./dataApi";
/*
 * 对Storage进行二次封装，增加可维护性, 或方便添加我们自己的钩子代码
 */
@Injectable()
export class Local {
    public storage:any;
    constructor(){
      if (!localStorage) {
        throw new Error('Current browser does not support Local Storage');
      }
      this.storage = localStorage;
    }

    public get(key:string):string {
        return this.storage[key] || false;
    }

    public set(key:string, value:any):void {
        this.storage[key] = value;
    }

    public remove(key:string):any {
        this.storage.removeItem(key);
    }


    //获取行业类型字典
    // public getIndustryCode_dic(){
    //   let industry = this.get(Constants.industry)
    //   if(industry)
    //   {
    //     try {
    //       return JSON.parse(industry)
    //     }catch (e) {
    //     }
    //   }
    //   else
    //   {
    //     //获取行业类型字典
    //     this.dataApi.getDictDate('industry').then((res:any)=>{
    //       if(res)
    //       {
    //         this.dataApi.setIndustryCode(res)
    //         return res;
    //       }
    //       else
    //       {
    //         return new Array();
    //       }
    //     }).catch((error)=>{
    //     });
    //   }
    // }


}
