
import {timeout,  catchError, retry,map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { Events } from '../provider/eventService';
import {Config} from './config';
import {Constants} from './constants';
import {Local} from './local';
import { Router } from  '@angular/router';
import { Observable, throwError } from 'rxjs';


import {DataApi} from "./dataApi";
import {Opera} from "./opera";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Encoding} from "tslint/lib/utils";
import {Md5} from "ts-md5";

declare var window;


let isLock = false;
var isRefSuccess = false;

var RefIndex = 0;
var rerTokendata;
/**
 * 对http的二次封装，在程序中用来获取远程数据
 */
@Injectable()
export class Api {
  private config:Config;
  constructor(
    private http: HttpClient,
    public local:Local,
    public events: Events,
    private router: Router
  ) {
    this.init();
  }
  private init() {
    //获取config单例一个实例
    this.config = Config.getInstance();
  }
  //私有的错误处理函数
  private handleError(error: Response | any) {
    console.log("出现错误----------------------------api.ts");
    console.log(error.error);
    //console.log(this.local.get(Constants.accesstoken));

    let errBody = null;

    try {
      errBody = error.error
    }catch (e) {
    }
    if(!errBody) {
      return;
    }

    let errMsg;
    console.log("请求错误catch",errBody,error);
    // 401 无效的凭证 token错误=>转登录
    if(errBody.Errcode=='1006'|| errBody.Errcode=='1018'){
      console.log("发送退出登录广播----------------------------api.ts",errBody);
      //console.log(error);
      //api出现错误，退出登陆
      this.events.publish('user:created'); //发送退出登录选项
      return;
    }
    //当前用户不是账户拥有者
    // if(errBody.Errcode=='1011'){
    //   //api出现错误，退出登陆
    //   this.events.publish('user:created'); //发送退出登录选项
    //   return;
    // }

    //以防万一 无效的凭证
    if(errBody.Errmsg) {
      if(errBody.Errmsg.indexOf('无效的凭证')>=0) {
        console.log("发送退出登录广播1----------------------------api.ts",errBody);
        this.events.publish('user:created'); //发送退出登录选项
        return;
      }

      if(errBody.Errmsg.indexOf('微信 POST 请求发生错误')>=0) {
        errBody.Errmsg = "微信错误";
        return Promise.reject(errBody);
      }
    }

    if(error.status == 409){
      if(errBody.Errcode=='2001'||errBody.Errcode=='2002'||errBody.Errcode=='2005'){
        return Promise.reject(errBody);
      }
    }

    //屏蔽词检测，服务器返回的屏蔽词code未知，只能检测包含检测到屏蔽词 认定为敏感词错误
    if(errBody.Errmsg) {
      if(errBody.Errmsg.indexOf('检测到屏蔽词')>=0) {
        let words = errBody.Errmsg;
        var aPos = words.indexOf('“');
        var bPos = words.indexOf('”');
        var r = words.substr(aPos + 1, bPos - aPos - 1);
        return Promise.reject("经检测: '"+r+"'为屏蔽词,请修改，或咨询专属校招顾问。");
      }
    }

    if(errBody.Errcode=='1042') {
      return Promise.reject('会员账号');
    }

    // 冻结逻辑中报错,暂注释
    // if(error.status == 403){
    //   this.events.publish('user:created'); //发送退出登录选项
    // }

    errMsg = errBody.Errmsg?errBody.Errmsg:errMsg;
    if(errBody.Errcode=='1021'){//账号已被删除
      if(errBody.Errmsg) {
        if(errBody.Errmsg.indexOf('对方账户不存在')>=0|| errBody.Errmsg.indexOf('短信接收人账户信息不存在')>=0) {
          return Promise.reject(errMsg+"");
        }
      }
      this.accountOut();
      // this.events.publish('user:created'); //发送退出登录选项
      return;
    }
    if(errBody.Errcode=='1041'){
      this.events.publish('user:pullblack');
      return Promise.reject(errBody.Errmsg);
    }
    if(errMsg) {
      errMsg = errMsg.replace('请求参数错误:','');
    }
    console.log('Promise',Promise.reject(errMsg+""),errMsg)
    return Promise.reject(errMsg+"");
  }

  //JS加密
  private convertDate = function(date) {
    let res = date.getFullYear() + ',' + (date.getMonth() + 1) + ',' + date.getDate() + ',' + date.getHours() + ',' + date.getMinutes() + ',' + date.getSeconds();
    return res;
  };

  //JS加密
  private encryp (clientId) {
    let CryptoJS = window.CryptoJS;
    let data = clientId + '--' + this.convertDate(new Date());
    let key = CryptoJS.enc.Utf8.parse('607490BE-18CA-43D7-B11A-57E2621B');
    let iv = CryptoJS.enc.Utf8.parse('2D59831C-78AC-4227-B3F3-CE656636');

    let encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  };

  //无参数的get请求
  getNoData(url) {
    return this.http.get(this.config.api + url)
      // .timeout(8000)
      .toPromise()
      .then((res:any) => {
        try {
          if(!res) {
            return;
          }
          return res;
        }catch(e){
        }
      })
      .catch(err => {
        if(err.name=='TimeoutError'){
          let str1=JSON.stringify({Errcode:"100",Errmsg:"网络太慢了，请更换网络后重试"});
          let str={status:100,_body:str1};
          return this.handleError(str);
        }else{
          return this.handleError(err)
        }
      }).catch();
  }

  //get请求
  get(url: string, params ? : any) {

     if (params) {
       let paramsArray = [];
       Object.keys(params).forEach(key => paramsArray.push(key + '=' + encodeURIComponent(params[key])))
       if (url.search(/\?/) === -1) {
         url += '?' + paramsArray.join('&')
       } else {
         url += '&' + paramsArray.join('&')
       }
     }

    return this.get_entity(url,null);
  }

  get_entity(url,token){
    let that = this;
    let _url = url;
    let headers = new HttpHeaders();
    let accesstoken = this.local.get(Constants.accesstoken)

    headers = headers.set('client_id', this.config.clientId);
    if(this.config.current_system) {
      headers = headers.set('current_system', this.config.current_system);
    }

    if(accesstoken){
      headers = headers.set('access_token',token?token:accesstoken);
    }

    if(this.local.get(Constants.accountid) && url!="account_user/department"){
      headers = headers.set('accountid', this.local.get(Constants.accountid));
    }
    // let requestOpts = new RequestOptions({
    //   headers: headers
    // });

    return this.http.get(this.config.api + url, { 'headers': headers }).pipe(
      timeout(20000))
      .toPromise()
      .then((res:any) => {
        try {
          if(token) {
            console.log('刷新token后请求的数据',res,url);
          }
          return res;
        }catch(e){

        }
      })
      .catch(async err => {
        console.log('errerrerrerr',err);
       // console.log('catch',err.toString());

        try {
          let errString = err.toString()
          if(errString.indexOf('Timeout')>=0){
            console.log('请求超时',errString);

            let str1 = JSON.stringify({Errcode: "100", Errmsg: "网络太慢了，请更换网络后重试"});
            let str = {status: 100, _body: str1};
            return this.handleError(str);
          }
        }catch (e) {
        }
        if(err&&err.status==0) {
          console.log('showHTTPErrModelNot',err)
          this.events.publish('showHTTPErrModelNot'); //发送退出登录选项
          return;
        }
        let errBody = null;
        try {
          errBody = err.error
        }catch (e) {
        }
        if(!errBody||!err) {
          return;
        }

        console.log('errBody',errBody);
        let that = this;
        if (errBody.Errcode == '1016' || errBody.Errcode == '1006') {

          let refresh_token = this.local.get('refreshToken');
          if (!refresh_token) {
            that.events.publish('user:created'); //发送退出登录选项
            return;
          }

          RefIndex+=1;
          isLock = true;

          let _resp =  new Promise(function(resolve, reject) {
            setTimeout(function() {
              let new_token_Promise = that.refreshToken(url,null,'get');
              new_token_Promise.then((new_token)=>{

                let shuju = that.get_entity(_url,new_token)
                shuju.then((shuju_res)=>{
                  console.log('获取新的tokensetTimeout',new_token_Promise,new_token,shuju,shuju_res);
                  if(shuju_res) {
                    resolve(shuju);
                  } else {
                    that.events.publish('user:created'); //发送退出登录选项
                    return;
                  }
                }).catch((shuju_err)=>{
                  console.log('获取新的tokensetshuju_err',new_token_Promise,new_token,shuju,shuju_err);
                  reject(shuju_err);
                })
              });

            }, RefIndex*300);
          });
          return _resp;
        }
        else if (err.name == 'TimeoutError') {
          // this.tip.presentToast("网络太慢了，请更换网络后重试");
          let str1 = JSON.stringify({Errcode: "100", Errmsg: "网络太慢了，请更换网络后重试"});
          let str = {status: 100, _body: str1};
          return this.handleError(str);
        }
        else{
          return this.handleError(err)
        }
      });
  }

  //get请求
  downLoadget(url: string, name) {
    return this.dowbLoadget_entity(url,null,name,);
  }

  //批量导出简历
  downLoadgets(url: string, data) {
    return this.dowbLoadPost_entity(url,null,data,);
  }

  dowbLoadget_entity(url,token,name){
    let that = this;
    let _url = url;
    let headers = new HttpHeaders();
    let accesstoken = this.local.get(Constants.accesstoken)

    headers = headers.set('client_id', this.config.clientId);
    if(this.config.current_system) {
      headers = headers.set('current_system', this.config.current_system);
    }

    if(accesstoken){
      headers = headers.set('access_token',token?token:accesstoken);
    }

    if(this.local.get(Constants.accountid) && url!="account_user/department"){
      headers = headers.set('accountid', this.local.get(Constants.accountid));
      headers = headers.set('Content-Type', 'application/json');
    }
    // let requestOpts = new RequestOptions({
    //   headers: headers,
    //   responseType:3
    // },);
    return this.http.get(this.config.api + url, { 'headers': headers,'responseType':'blob'},)
      .toPromise()
      .then((res:any) => {
          console.log('下载',res)
        //let data = res['_body'];
        let data = res;
        var blob = new Blob([data], {
          type: "application/octet-stream"
        });
        let url = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download',  name +'的简历.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return '导出成功'
      })
      .catch(async err => {
      });
  }

  dowbLoadPost_entity(url,token,parm){
    let that = this;
    let _url = url;
    let headers = new HttpHeaders();
    let accesstoken = this.local.get(Constants.accesstoken)

    headers = headers.set('client_id', this.config.clientId);
    if(this.config.current_system) {
      headers = headers.set('current_system', this.config.current_system);
    }

    if(accesstoken){
      headers = headers.set('access_token',accesstoken);
    }
    headers = headers.set('accountid', this.local.get(Constants.accountid));
    if(parm.down_type==0) {
      headers = headers.set('Content-Type', 'application/json;charset=UTF-8');
    } else {
      headers = headers.set('Content-Type', 'application/json');
    }

    // let requestOpts = new RequestOptions({
    //   headers: headers,
    //   responseType:3
    // },);
    console.log('dowbLoadPost_entity',parm,{ 'headers': headers, 'responseType':'arraybuffer'});
    var now= new Date();
    var year=now.getFullYear();
    var month=now.getMonth()+1;
    var day=now.getDate();
    var hour=now.getHours();
    var minute=now.getMinutes();
    var second=now.getSeconds();
    var name=year+"-"+month+"-"+day+"_"+hour+"："+minute+"："+second;

    return this.http.post(this.config.api + url,parm, { 'headers': headers , 'responseType':'arraybuffer'},)
      .toPromise()
      .then((res:any) => {
        let data = res;
        console.log('下载',name,res,data)
        if(parm.down_type==0) {
          var link = document.createElement('a');//a标签下载
          link.href = window.URL.createObjectURL(new Blob([data],{type: 'application/octet-binary;charset=utf-8'}));
          link.download = name+'.zip';
          link.click();
          window.URL.revokeObjectURL(link.href);
          return '导出成功'
        }
        else {
          let url = window.URL.createObjectURL(new Blob([data]));
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download',  name+'.xls');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          return '导出成功'
        }
      })
      .catch(async err => {
        return Promise.reject("导出失败，请稍后重试");
      });
  }

  //post请求
  post(url, params ? : any) {
    let that = this;
    let refresh_token = this.local.get(Constants.refreshToken);
    return this.post_entity(url, params, null)
  }

  //post 请求实体  url
  post_entity( url, params,token){
    let _url = url;
    let _params = params;
    let that = this;
    let headers = new HttpHeaders();
    let accesstoken = this.local.get(Constants.accesstoken);
    if(accesstoken){
      headers = headers.set('access_token',token?token:accesstoken);
    }

    if(this.local.get(Constants.accountid)){
      headers = headers.set('accountid', this.local.get(Constants.accountid));
    }
    headers = headers.set('client_id', this.config.clientId);
    if(this.config.current_system) {
      headers = headers.set('current_system', this.config.current_system);
    }

    // let request = new RequestOptions({
    //   headers: headers
    // });
    return this.http.post(this.config.api + url, params, { 'headers': headers})
      .toPromise()
      .then((res:any) => {
        try {
          if(!res) {
            return;
          }
          return res;
        }catch(e){
        }
      })
      .catch(async err => {
        console.log('错误寻找POST',err,err.status)
        if(err&&err.status==0) {
          console.log('showHTTPErrModelNot',)
          that.events.publish('showHTTPErrModelNot');
          return;
        }

        let errBody  = null;
        try {
          errBody = err.error;
        } catch (d) {
        }

        console.log('errBody_post',err);
        if(!errBody||!err) {
          return;
        }
        if (errBody.Errcode == '1016' || errBody.Errcode == '1006') {

          let refresh_token = this.local.get('refreshToken');
          if (!refresh_token) {
            that.events.publish('user:created'); //发送退出登录选项
            return;
          }

          let _resp =  new Promise(function(resolve, reject) {
            setTimeout(function() {
              let new_token_Promise = that.refreshToken(url,null,'get');
              new_token_Promise.then((new_token)=>{
                let shuju = that.post_entity(_url,_params,new_token)
                shuju.then((shuju_res)=>{
                  console.log('获取新的tokensetTimeout',new_token_Promise,new_token,shuju,shuju_res);
                  if(shuju_res) {
                    resolve(shuju);
                  } else {
                    that.events.publish('user:created'); //发送退出登录选项
                    return;
                  }
                }).catch((shuju_err)=>{
                  reject(shuju_err);
                  console.log('获取新的tokensetshuju_err_post',new_token_Promise,new_token,shuju,shuju_err);
                })
              });
            }, RefIndex*300);
          });
          return _resp;
        }
        else if (err.name == 'TimeoutError') {
          let str1 = JSON.stringify({Errcode: "100", Errmsg: "网络太慢了，请更换网络后重试"});
          let str = {status: 100, _body: str1};
          console.log('catch',err);
          return this.handleError(str);
        }
        else{
          console.log("this.handleError(err)",this.handleError(err))
          return this.handleError(err)
        }
      });
  }

  urm_post(url, params){
    let _url = url;
    let _params = params;
    let that = this;
    let headers = new HttpHeaders();
    let accesstoken = this.local.get(Constants.accesstoken);
    if(accesstoken){
      headers = headers.set('access_token',accesstoken);
    }
    let nowData = new Date();
    let longText = parseInt(nowData.getTime()/1000+'')+'';
    let str =  Md5.hashStr("URM_REQUEST"+longText)+'';
    headers = headers.set('timestamp',longText);
    headers = headers.set('signature',str.toUpperCase());

    if(this.local.get(Constants.accountid)){
      headers = headers.set('accountid', this.local.get(Constants.accountid));
    }
    headers = headers.set('client_id', this.config.clientId);
    if(this.config.current_system) {
      headers = headers.set('current_system', this.config.current_system);
    }

    // let request = new RequestOptions({
    //   headers: headers
    // });
    return this.http.post(this.config.urmApi + url, params, { 'headers': headers})
      .toPromise()
      .then((res:any) => {
        try {
          if(!res) {
            return;
          }
          return res;
        }catch(e){
        }
      })
      .catch(async err => {
        console.log('错误寻找POST',err,err.status)
        if(err&&err.status==0) {
          console.log('showHTTPErrModelNot',)
          that.events.publish('showHTTPErrModelNot'); //发送退出登录选项
          return;
        }
        let errBody  = null;
        try {
          errBody = JSON.parse(err._body);
        } catch (d) {
        }
        console.log('errBody_post',err);
        if(!errBody||!err) {
          return;
        }
        if (errBody.Errcode == '1016' || errBody.Errcode == '1006') {
          let refresh_token = this.local.get('refreshToken');
          if (!refresh_token) {
            that.events.publish('user:created'); //发送退出登录选项
            return;
          }
        }
        else if (err.name == 'TimeoutError') {
          let str1 = JSON.stringify({Errcode: "100", Errmsg: "网络太慢了，请更换网络后重试"});
          let str = {status: 100, _body: str1};
          console.log('catch',err);
          return this.handleError(str);
        }
        else{
          console.log("this.handleError(err)",this.handleError(err));
          return this.handleError(err)
        }
      });
  }

  //视频面试
  room_post( url, params){
    let _url = url;
    let _params = params;
    let that = this;
    let headers = new HttpHeaders();
    let accesstoken = this.local.get(Constants.accesstoken);
    if(accesstoken){
      headers = headers.set('access_token',accesstoken);
    }
    let nowData = new Date();
    let longText = parseInt(nowData.getTime()/1000+'')+'';
    let str =  Md5.hashStr("URM_REQUEST"+longText)+'';
    headers = headers.set('timestamp',longText);
    headers = headers.set('signature',str.toUpperCase());

    if(this.local.get(Constants.accountid)){
      headers = headers.set('accountid', this.local.get(Constants.accountid));
    }
    headers = headers.set('client_id', this.config.clientId);
    if(this.config.current_system) {
      headers = headers.set('current_system', this.config.current_system);
    }

    // let request = new RequestOptions({
    //   headers: headers
    // });
    return this.http.post(this.config.roomApi + url, params, {'headers':headers})
      .toPromise()
      .then((res:any) => {
        console.log('room_post',res);
        try {
          if(!res) {
            return;
          }
          return res;
        }catch(e){
        }
      })
      .catch(async err => {
        console.log('错误寻找POST',err,err.status)
        if(err&&err.status==0) {
          console.log('showHTTPErrModelNot',)
          that.events.publish('showHTTPErrModelNot'); //发送退出登录选项
          return;
        }
        let errBody  = null;
        try {
          errBody = JSON.parse(err._body);
        } catch (d) {
        }
        console.log('errBody_post',err);
        if(!errBody||!err) {
          return;
        }
        if (errBody.Errcode == '1016' || errBody.Errcode == '1006') {
          let refresh_token = this.local.get('refreshToken');
          if (!refresh_token) {
            that.events.publish('user:created'); //发送退出登录选项
            return;
          }
        }
        else if (err.name == 'TimeoutError') {
          let str1 = JSON.stringify({Errcode: "100", Errmsg: "网络太慢了，请更换网络后重试"});
          let str = {status: 100, _body: str1};
          console.log('catch',err);
          return this.handleError(str);
        }
        else{
          console.log("this.handleError(err)",this.handleError(err));
          return this.handleError(err)
        }
      });
  }

  //post请求
  adminPost(url, params ? : any) {

    let headers = new HttpHeaders();
    // if(params instanceof Array){

    // }else{
    //   if(params instanceof Object){
    //     headers = headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    //     //console.log(params);
    //     let paramsArray = [];
    //     Object.keys(params).forEach((key) => {
    //       ////console.log(params[key] instanceof Array);
    //       if(params[key] instanceof Array){
    //         params[key].forEach((val)=>{
    //           if(val instanceof Array){

    //           }else if(val instanceof Object){
    //             Object.keys(val).forEach((keys) => {
    //               paramsArray.push(key+'['+params[key].indexOf(val)+']'+'['+keys+']'+'=' + encodeURIComponent(val[keys]));
    //             });

    //           }else{
    //             paramsArray.push(key+'['+params[key].indexOf(val)+']'+'=' + encodeURIComponent(val));
    //           }

    //         });
    //       }else if(params[key] instanceof Object){
    //         Object.keys(params[key]).forEach((keys) => {
    //           if(params[key][keys] instanceof Array){//判断对象中又包含子数组的情况
    //             params[key][keys].forEach((keys1) => {
    //               paramsArray.push(key+'['+keys+']'+'['+params[key][keys].indexOf(keys1)+']'+'=' + encodeURIComponent(keys1));
    //             })
    //           }else{
    //             paramsArray.push(key+'['+keys+']'+'=' + encodeURIComponent(params[key][keys]));
    //           }

    //         })
    //       }else{
    //         paramsArray.push(key + '=' + encodeURIComponent(params[key]));
    //       }
    //     })
    //     params = paramsArray.join("&");
    //   }
    // }

    headers = headers.set('client_id', this.config.clientId);
    if(this.config.current_system) {
      headers = headers.set('current_system', this.config.current_system);
    }
    if(this.local.get(Constants.accesstoken)){
      headers = headers.set('access_token', this.local.get(Constants.accesstoken));
    }
    if(this.local.get(Constants.accountid)){
      headers = headers.set('accountid', this.local.get(Constants.accountid));
    }

    // let requestOpts = new RequestOptions({
    //   headers: headers
    // });
    return this.http.post(this.config.api + url, params, { 'headers': headers})
      //.timeout(8000)
      .toPromise()
      .then((res:any) => {
        try {
          return res;
        }catch(e){
          ////console.log(e);
        }
      })
      .catch(err => {
        if(err.name=='TimeoutError'){
          let str1=JSON.stringify({Errcode:"100",Errmsg:"网络太慢了，请更换网络后重试"});
          let str={status:100,_body:str1};
          return this.handleError(str);
        }else{
          return this.handleError(err)
        }
      });
  }


  //广告接口
  get_cdapi_entity(url){
    let that = this;
    let _url = url;
    let headers = new HttpHeaders();
    let accesstoken = this.local.get(Constants.accesstoken)

    headers = headers.set('client_id', this.config.clientId);
    if(this.config.current_system) {
      headers = headers.set('current_system', this.config.current_system);
    }

    if(accesstoken){
      headers = headers.set('access_token',accesstoken);
    }

    if(this.local.get(Constants.accountid) && url!="account_user/department"){
      headers = headers.set('accountid', this.local.get(Constants.accountid));
    }

    return this.http.get(this.config.cdapi + url, { 'headers': headers }).toPromise()
      .then((res:any) => {
        try {
          return res;
        }catch(e){

        }
      }).catch(async err => {console.log('errerrerrerr',err);});
  }

  //代理请求
  agentPost(url, params ? : any){
    // if(params instanceof Array){

    // }else{
    //   if(params instanceof Object){
    //     //headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    //     //console.log(params);
    //     let paramsArray = [];
    //     Object.keys(params).forEach((key) => {
    //       //console.log(params[key] instanceof Array);
    //       if(params[key] instanceof Array){
    //         params[key].forEach((val)=>{
    //           paramsArray.push(key+'['+params[key].indexOf(val)+']'+'=' + encodeURIComponent(val));
    //         });
    //       }else if(params[key] instanceof Object){
    //         Object.keys(params[key]).forEach((keys) => {
    //           paramsArray.push(key+'['+keys+']'+'=' + encodeURIComponent(params[key][keys]));
    //         })
    //       }else{
    //         paramsArray.push(key + '=' + encodeURIComponent(params[key]));
    //       }

    //     })
    //     params = paramsArray.join("&");
    //   }
    // }

    let headers = new HttpHeaders()
      // .set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8')
      .set('client_id', this.encryp(this.config.clientId));
    // let requestOpts = new RequestOptions({
    //   headers: headers
    // });

    let options = {
      headers: headers
    }; 
    
    return this.http.post(this.config.agentApi + url, params, options)
      .toPromise()
      .then((res:any) => {
        try{
          if(!res) {
            return;
          }
          return res;
        }catch(e){
        }
      })
      .catch(err => {
        if(err.name=='TimeoutError'){
          let str1=JSON.stringify({Errcode:"100",Errmsg:"网络太慢了，请更换网络后重试"});
          let str={status:100,_body:str1};
          return this.handleError(str);
        }else{
          return this.handleError(err)
        }
      });
  }

  //刷新token
  async refreshToken(_url,params,method) {
    let refresh_token = this.local.get('refreshToken');
    if (refresh_token) {
      let refresh_tokenJosn = JSON.parse(refresh_token);
      rerTokendata = "grant_type=refresh_token&refresh_token="+ refresh_tokenJosn.refresh_token;
      if (this.config.refresh_token) {
        rerTokendata = "grant_type=refresh_token&refresh_token="+ this.config.refresh_token.refresh_token;
      }
      let headersa = new HttpHeaders();
      headersa = headersa.set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
      headersa = headersa.set('client_id', this.encryp(this.config.clientId));
      if(this.config.current_system) {
        headersa = headersa.set('current_system', this.config.current_system);
      }

      //let requestOpts1 = new RequestOptions({headers: headersa});

      console.log('请求日期',new Date().getHours()+"："+new Date().getMinutes()+"："+new Date().getSeconds(),RefIndex,rerTokendata)
      return await this.http.post( this.config.agentApi+'api/oauth2/access_token', rerTokendata,{ 'headers': headersa}).toPromise().then((res:any)=>{
          let responJson =null;
            try {
              responJson =  res;
            }catch(e) {

            }
          if(responJson) {
            isRefSuccess = true;
            console.log('responJson',responJson);
            let refreshToken = {
                refresh_token: responJson.refresh_token,//给全局变量赋值----刷新token
                exprid_time: new Date().getTime() + responJson.expires_in * 1000//token过期时间(毫秒)
            };
            this.local.set(Constants.refreshToken, JSON.stringify(refreshToken));
            this.config.refresh_token = refreshToken;
            rerTokendata = {
                grant_type: 'refresh_token',
                refresh_token: this.config.refresh_token.refresh_token,
              };
              this.local.set(Constants.accesstoken, responJson.access_token);
                this.config.token = responJson.access_token;
              RefIndex = 0;
              isLock = false;
              return this.config.token;
          }
        }).catch((err)=>{
            isLock = false;
            console.log('refreshToken_err',rerTokendata,err,this.local.get(Constants.accesstoken));
            if(isRefSuccess) {
              setTimeout(()=>{
                isRefSuccess = false;
              },10000);
              console.log('isRefSuccess',rerTokendata,err,this.local.get(Constants.accesstoken));
              return this.local.get(Constants.accesstoken)
            } else {
              console.log('发送退出登录选项刷新token')
              this.events.publish('user:created'); //发送退出登录选项
            }
      });
    } else {
      this.events.publish('user:created');
      return;
    }
  }

  //URM检测预购买用户
  urmPayStatistic( params ? : any){
    let url = this.config.urmApi;
    if(this.config.api.indexOf('test')>=0){
      url = "https://urm.test.goworkla.cn"
    }
    let headers = new HttpHeaders();
    let nowData = new Date();
    let longText = parseInt(nowData.getTime()/1000+'')+'';
    let str =  Md5.hashStr("URM_REQUEST"+longText)+'';
    headers = headers.set('timestamp',longText);
    headers = headers.set('signature',str.toUpperCase());
    if(this.config.current_system) {
      headers = headers.set('current_system', this.config.current_system);
    }
    console.log("MD5",Md5.hashStr('longText'))
    if(this.local.get(Constants.accountid)){
      headers = headers.set('accountid', this.local.get(Constants.accountid));
    }
    headers = headers.set('client_id', this.config.clientId);
    // let request = new RequestOptions({
    //   headers: headers
    // });
    return this.http.post(url +"/urmupdate/save", params, { 'headers': headers})
      .toPromise()
      .then((res:any) => {
        console.log('urmPayStatistic',res,this.config.urmApi)
      })
      .catch(async err => {
        console.log('urmPayStatistic',err)
      });
  }

  urmGetData(url,params ? : any){
    let httpurl = this.config.urmApi;
    if(this.config.api.indexOf('test')>=0){
      httpurl = "https://urm.test.goworkla.cn"
    }
    let headers = new HttpHeaders();
    let nowData = new Date();
    let longText = parseInt(nowData.getTime()/1000+'')+'';
    let str =  Md5.hashStr("URM_REQUEST"+longText)+'';
    headers = headers.set('timestamp',longText);
    headers = headers.set('signature',str.toUpperCase());
    if(this.config.current_system) {
      headers = headers.set('current_system', this.config.current_system);
    }
    console.log("MD5",Md5.hashStr('longText'))
    if(this.local.get(Constants.accountid)){
      headers = headers.set('accountid', this.local.get(Constants.accountid));
    }
    headers = headers.set('client_id', this.config.clientId);
    // let request = new RequestOptions({
    //   headers: headers
    // });
    return this.http.get(httpurl +url, { 'headers': headers})
      .toPromise()
      .then((res:any) => {
        console.log('urmPostData', res.data);
          return res.data;
      })
      .catch(async err => {
        console.log('urmPayStatistic',err)
      });
  }

  // 遇到账号不存在检测是自己的账号不存在还是别人的账号不存在
  accountOut(){
    if(this.config.isNavActive=="login") {
      return;
    }
    let headers = new HttpHeaders();
    headers = headers.set('client_id', this.config.clientId);
    if(this.config.current_system) {
      headers = headers.set('current_system', this.config.current_system);
    }

    if(this.local.get(Constants.accesstoken)){
      headers = headers.set('access_token', this.local.get(Constants.accesstoken));
    } else {
      this.events.publish('user:created'); //发送退出登录选项
      return;
    }
    if(this.local.get(Constants.accountid)){
      headers = headers.set('accountid', this.local.get(Constants.accountid));
    }
    // let requestOpts = new RequestOptions({
    //   headers: headers
    // });
    this.http.get(this.config.api + "account_user/department", { 'headers': headers })
      .toPromise()
      .then((res:any) => {})
      .catch(err => {
        try {
          let str =  err._body;
          let json = JSON.parse(str);
          if(json.Errcode =='1021' ||json.Errcode =='1006') {
            this.events.publish('user:created'); //发送退出登录选项
          }
        }catch (e) {
        }
      });
  }



}

