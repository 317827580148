<!-- 页头部分 -->
<!-- <div class="contentRight-header">企业中心 - 企业认证</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">


    <div class="college-block" >
      <!--<div style="width:80%;height: 600px;background-color:white">-->
        <!--<p style="font-size: 40px;height: 50px;text-align: center;padding-top: 200px">会员中心功能更新中，请耐心等待。</p>-->
      <!--</div>-->

      <!-- 介绍 -->
        <div class="college-block">
            <div class="myPowerInfo flex_row_between">
              <div class="flex_row_left">
                <div class="myAvatar">
                  <img [src]="myUser.avatar+'&imageView2/1/w/100/h/100'" alt="">
                </div>
                <div class="flex_col_between myInfo">
                  <div>
                    <p class="flex_row_left">
                      <span>{{myUser.name}}</span>
                      <img src="assets/images/level0.png" *ngIf="member_info?.product_code == 998"  />
                      <img src="assets/images/level1.png" *ngIf="member_info?.product_code == 1998"  />
                      <img src="assets/images/levelv.png" *ngIf="member_info?.product_code == 3998" />
                      <img src="assets/images/level.png" *ngIf="member_info?.first_anniversary"  />
                    </p>
                    <p *ngIf="myDepartment?.common_name">{{myDepartment?.common_name}}</p>
                    <p *ngIf="!myDepartment?.common_name">{{myDepartment?.name}}</p>
                  </div>
                  <!-- <p>有效期至：{{member_info?.endtime | date:'yyyy-MM-dd'}} <span class="xuding">提前续订享优惠</span> </p> -->
                  <p *ngIf="config?.department?.member_info" >有效期至：{{member_info?.endtime | date:'yyyy-MM-dd'}} </p>
                </div>
                <button class="purple" routerLink="../../home/company-record">使用记录</button>
              </div>
              <button class="purple" routerLink="../../home/company-receipt">发票</button>
            </div>
            <!-- 信息展示部分 -->
            <!-- <div class="powerExplainBox">
              <h2>我的权益</h2>
              <ul class="powerExplainList flex_row_left">
                <li class="myPower" *ngFor="let item of product_infos;let i = index">
                  <div class="flex_col_center myPowerDes" *ngIf="item.count > 0">
                    <div class="ico">
                      <img *ngIf="i == 0" src="assets/images/pro1.png" alt="">
                      <img *ngIf="i == 1" src="assets/images/pro2.png" alt="">
                      <img *ngIf="i == 2" src="assets/images/pro3.png" alt="">
                      <img *ngIf="i == 3" src="assets/images/pro4.png" alt="">
                      <img *ngIf="i == 4" src="assets/images/pro5.png" alt="">
                      <img *ngIf="i == 5" src="assets/images/pro6.png" alt="">
                    </div> -->

                    <!-- <div class="ico tico" *ngIf="member_info && member_info?.first_anniversary">
                      <img [src]="item.ico" alt="">
                    </div> -->
                    <!-- <div class="powerMain"> -->

                      <!-- <p class="powerName"><span>{{item.name}}</span></p>

                      <div class="tipsBubble flex_row_center">
                        <img src="assets/images/tipsBubble.png" alt="">
                        <span>剩余50个</span>
                      </div> -->

                      <!-- <p class="powerDesc">{{item.description}}</p> -->
                    <!-- </div> -->


                  <!-- </div>
                </li>
              </ul>
            </div> -->

            <div class="powerExplainBox">
              <h2>我的权益</h2>
              <ul class="powerExplainList flex_row_left">
                <li class="myPower" *ngFor="let item of config?.department?.quota?.product_component_info;let i = index">
                  <div class="flex_col_center myPowerDes">
                    <div class="ico">
                      <img  src={{item.ico}} *ngIf="item.ico" alt="">
                      <img  style="background-color: lightgray"  *ngIf="!item.ico" alt="">
                    </div>
                    <p class="powerName"><span>{{item.name}}</span></p>
                    <!--
                                        "dialogueall" 直聊全网学生
                                            oduct_component_info: Array(9)
                                        0: {single_name: "直聊全网学生", componentid: "5d00c05b70b4080e30649963", total: 365, left: 365, unit: "天", …}
                                        1: {single_name: "直聊30人/天", componentid: "5d00c05b70b4080e30649964", total: 30, left: 30, unit: "人/天", …}
                                        2: {single_name: "极速短信联系200人次", componentid: "5d00c05b70b4080e30649965", total: 200, left: 200, unit: "人次", …}
                                        3: {single_name: "面试短信通知200人次", componentid: "5d00c05b70b4080e30649966", total: 200, left: 200, unit: "人次", …}
                                        4: {single_name: "简历直达邮箱", componentid: "5d00c05b70b4080e30649967", total: 365, left: 365, unit: "天", …}
                                        5: {single_name: "多维度筛选，精准匹配学生", componentid: "5d00c05b70b4080e30649968", total: 365, left: 365, unit: "天", …}
                                        6: {single_name: "学生浏览关注职位可查看", componentid: "5d00c05b70b4080e30649969", total: 365, left: 365, unit: "天", …}
                                        7: {single_name: "活动短信通知200人次", componentid: "5d00c05b70b4080e3064996a", total: 200, left: 194, unit: "人次", …}
                                        8: {single_name: "活动短信通知200人次", componentid: "5d00c05b70b4080e3064996a", total: 200, left: 200, unit: "人次", …}
                                        dialogueall 直聊全网学生
                                        dialogue  直聊学生数量
                                        sms_quick 极速短信联系
                                        sms_Interview 面试短信通知
                                        resume_to_email 简历直达邮箱
                                        resume_screener  多维度筛选
                                        position_view 谁看过我
                                        sms_activity 活动短信通知
                                        sms 活动短信通知
                                        speedtelephone 极速电话联系
                                        bulkinvitation 批量邀投
                                        -->

                    <div class="tipsBubble flex_row_center" *ngIf="item.alias == 'dialogue'">
                      <span>剩余{{config?.department?.quota.dialogue+item.unit}}</span>
                    </div>
                    <div class="tipsBubble flex_row_center" *ngIf="item.alias == 'sms_quick'">
                      <span>剩余{{item.left+item.unit}}</span>
                    </div>
                    <div class="tipsBubble flex_row_center" *ngIf="item.alias == 'interview_notice'">
                      <span>剩余{{item.left+item.unit}}</span>
                    </div>
                    <div class="tipsBubble flex_row_center" *ngIf="item.alias == config.sms_activity">
                      <span>剩余{{item.left+item.unit}}</span>
                    </div>
                    <div class="tipsBubble flex_row_center" *ngIf="item.alias == 'sms'">
                      <span>剩余{{item.left+item.unit}}</span>
                    </div>
                    <div class="tipsBubble flex_row_center" *ngIf="item.alias == config.voice_call">
                      <span>剩余{{item.left+item.unit}}</span>
                    </div>
                    <div class="tipsBubble flex_row_center" *ngIf="item.alias == 'sms_bulkinvitation'">
                      <span>剩余{{item.left+item.unit}}</span>
                    </div>


                    <!-- <p class="powerDesc">{{item.description}}</p> -->
                    <!-- </div> -->
                  </div>
                </li>
              </ul>

            </div>



            <!-- <div class="powerExplainBox">
              <h2>我的权益</h2>
              <ul class="powerExplainList flex_row_between">
                <li class="powerExplainInfo" *ngFor="let item of product_infos;let i = index">
                  <div class="flex_row_left" *ngIf="item.count > 0">
                    <div class="ico" *ngIf="member_info && !member_info?.first_anniversary" >
                      <img *ngIf="i == 0" src="assets/images/pro1.png" alt="">
                      <img *ngIf="i == 1" src="assets/images/pro2.png" alt="">
                      <img *ngIf="i == 2" src="assets/images/pro3.png" alt="">
                      <img *ngIf="i == 3" src="assets/images/pro4.png" alt="">
                      <img *ngIf="i == 4" src="assets/images/pro5.png" alt="">
                      <img *ngIf="i == 5" src="assets/images/pro6.png" alt="">
                    </div>
                    <div class="ico tico" *ngIf="member_info && member_info?.first_anniversary">
                      <img [src]="item.ico" alt="">
                    </div>
                    <div class="flex_col_between powerMain">
                      <p class="powerName"><span>{{item.name}}</span>{{item.intro}}</p>
                      <p class="powerDesc">{{item.description}}</p>
                    </div>
                    <em>{{i+1}}</em>
                  </div>
                </li>
              </ul>
            </div> -->
            <!-- 补充包 -->
            <div class="memberMain">
              <h3 class="memberH3">
                校招补充包
                <span >用多少买多少</span>
              </h3>
              <div class="productBlock swiper-container singleBox">
                <div class="swiper-wrapper flex_row_between" >
                  <div class="swiper-slide productBrief flex_row_left" (click)="goDetail('sms',1)">
                    <img src="assets/images/d1.png" alt="" *ngIf="!config.is618">
                    <img src="assets/images/d1618.png" alt="" *ngIf="config.is618">
                    <div class="productBriefM flex_col_around">
                      <div class="flex_col_left">
                        <h4>活动通知包</h4>
                        <p>{{config.is618?config.d1618:config.d1}}</p>
                      </div>
                      <div class="flex_col_left">
                        <span>&nbsp;</span>
                        <!-- <span>￥99/<em>100人</em></span> -->
                        <button  *ngIf="!config.is618">立即购买</button>
                        <img *ngIf="config.is618" style="width: 112px;height: 42px" src="../../assets/images/618Buy.png">                      </div>
                    </div>
                  </div>

                  <div class="swiper-slide productBrief flex_row_left flex_row_betweenLeft" (click)="goDetail('sms',2)">
                    <img src="assets/images/d3.png" alt="" *ngIf="!config.is618">
                    <img src="assets/images/d3618.png" alt="" *ngIf="config.is618">
                    <div class="productBriefM flex_col_around">
                      <div class="flex_col_left">
                        <h4>极速短信联系</h4>
                        <p>{{config.is618?config.d2618:config.d2}}</p>

                      </div>
                      <div class="flex_col_left">
                        <span>&nbsp;</span>
                        <!-- <span>￥99/<em>100人</em></span> -->
                        <!-- <span>&nbsp;</span> -->
                        <!-- <button>正在开发中</button> -->
                        <button  *ngIf="!config.is618">立即购买</button>
                        <img *ngIf="config.is618" style="width: 112px;height: 42px" src="../../assets/images/618Buy.png">
                      </div>
                    </div>
                  </div>

                  <div class="swiper-slide productBrief flex_row_left flex_row_betweenLeft">
                    <img src="assets/images/d2.png" alt="">
                    <div class="productBriefM  flex_col_around developing">
                      <div class="flex_col_left">
                        <h4>批量邀投包</h4>
                        <p>批量邀请学生投简历，简历直达邮箱</p>
                      </div>
                      <div class="flex_col_left">
                        <!--(click)="goDetail('sms',3)"-->
                        <span>&nbsp;</span>
                        <button>敬请期待</button>
                        <!--<img *ngIf="config.is618" style="width: 112px;height: 42px" src="../../assets/images/618Buy.png">-->
                      </div>
                    </div>
                  </div>

                  <div class="swiper-slide productBrief flex_row_left flex_row_betweenLeft">
                    <img src="assets/images/d4.png" alt="">
                    <div class="productBriefM  flex_col_around developing">
                      <div class="flex_col_left">
                        <h4>极速电话联系</h4>
                        <p>批量邀请学生投简历，简历直达邮箱</p>
                      </div>
                      <div class="flex_col_left">
                        <!--  (click)="goDetail('phone',4)" -->
                        <span>&nbsp;</span>
                        <button>敬请期待</button>
                      </div>
                    </div>
                  </div>


                </div>
                 <div class="swiper-button-prev"></div>
                 <div class="swiper-button-next"></div>
              </div>
              <h3 class="memberH3" *ngIf="!config?.department?.member_info">
                校招会员包
                <span *ngIf="!config.is618">买会员更优惠</span>
                <img *ngIf="config.is618" src="assets/images/618titleBanner2.png" style="margin-top: -5px">
              </h3>
              <div class="productBlock swiper-container memberBox" *ngIf="!config?.department?.member_info">
                <div class="swiper-wrapper" >
                  <div class="swiper-slide productBrief flex_row_left" (click)="goMemberList(998)">
                    <img src="assets/images/m1.png" alt="" *ngIf="!config.is618">
                    <img src="assets/images/m1618.png" alt="" *ngIf="config.is618">
                    <div class="productBriefM flex_col_around">
                      <div class="flex_col_left">
                        <h4>普通会员</h4>
                        <p></p>
                      </div>
                      <div class="flex_col_left">
                        <span>￥998/<em>年</em></span>
                        <button  *ngIf="!config.is618">立即购买</button>
                        <img *ngIf="config.is618" style="width: 112px;height: 42px" src="../../assets/images/618Buy.png">                        </div>
                    </div>
                  </div>
                  <div class="swiper-slide productBrief flex_row_left  flex_row_betweenLeft" (click)="goMemberList(1998)">
                    <img src="assets/images/m2.png" alt="" *ngIf="!config.is618">
                    <img src="assets/images/m2618.png" alt="" *ngIf="config.is618">
                    <div class="productBriefM flex_col_around">
                      <div class="flex_col_left">
                        <h4>高级会员</h4>
                        <p *ngIf="config.is618" style="font-size: 12px" >另加1000元，得价值3000元中原最佳雇主进校园至尊双选展位</p>
                      </div>
                      <div class="flex_col_left">
                        <span>￥1998/<em>年</em></span>
                        <button  *ngIf="!config.is618">立即购买</button>
                        <img *ngIf="config.is618" style="width: 112px;height: 42px" src="../../assets/images/618Buy.png">                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide productBrief flex_row_left flex_row_betweenLeft" (click)="goMemberList(3998)">
                    <img src="assets/images/m3.png" alt="" *ngIf="!config.is618">
                    <img src="assets/images/m3618.png" alt="" *ngIf="config.is618">
                    <div class="productBriefM flex_col_around">
                      <div class="flex_col_left">
                        <h4>VIP会员</h4>
                        <p *ngIf="config.is618" style="font-size: 12px" >免费得价值3000元中原最佳雇主进校园至尊双选展位</p>
                      </div>
                      <div class="flex_col_left">
                        <span>￥3998/<em>年</em></span>
                        <button  *ngIf="!config.is618">立即购买</button>
                        <img *ngIf="config.is618" style="width: 112px;height: 42px" src="../../assets/images/618Buy.png">                      </div>
                    </div>
                  </div>


                </div>
              <!-- <div class="swiper-button-prev"></div> -->
              <!-- <div class="swiper-button-next"></div> -->
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
          </div>
        </div>
    </div>
</div>
