import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {VoiceCallModelComponent} from "../voice-call-model/voice-call-model.component";
import {ModelSendMsgComponent} from "../model-send-msg/model-send-msg.component";
import {productManager} from "../../provider/productManager";
import {QRCodeComponent} from 'angular2-qrcode';
import {NoneVipAlertComponent} from "../none-vip-alert/none-vip-alert.component";
import { MatDialog } from "@angular/material/dialog";

declare let tvp;
declare let layui;
declare let  ActiveXObject;
declare let  VSwf;
declare let  QRCode;

@Component({
  selector: 'app-air-camp-history-detail',
  templateUrl: './air-camp-history-detail.component.html',
  styleUrls: ['./air-camp-history-detail.component.css']
})
export class AirCampHistoryDetailComponent implements OnInit {

  public config: Config;

  @Output() isShowVoiceCallModel = false;//是否打开弹窗(简历收件箱)
  @Output() isShowPositionPushModel = false;//是否打开弹窗(简历收件箱)
  @ViewChild('voiceCallModel', { static: true }) voiceCallModel: VoiceCallModelComponent;
  @ViewChild('sengMsgModel', { static: true }) sengMsgModel: ModelSendMsgComponent;
  @Output() SengMsgModel ={
    userName:'',
    userPic:'',
    talentid:'',
    graduation_school:'',//学校
    major_text:'',//专业
    gender:'',
    address:'',
    positionid:''
  };//打开单品发送极速短信用户对象

  public TJTalentModel = false;
  public TJSchoolModel = false;
  public addNewquestionModel = false;
  public downLineModel = false;
  public yuanxi1 = [];
  public yuanxi2 = [];
  public yuanxi3 = [];
  public  area1 = [];
  public  area2 = [];
  public  area3 = [];
  public airCampustalk:any;
  public currentTab = "1";
  public talentTab = "1";//学生简历内部tab
  public questionTab = "1";//学生简历内部tab
  public isAllSeleteCollege=false; //是否全选高校推荐
  public ApplyCollegeData={
    all:[],
    passed: [],
    to_audit: [],
    not_passed: [],
  };

  //未通过原因
  public reasons = [];

  public TJTalentModelData = {
    collegeid:'',
    collegeids:[],
    majorOne:'',
    majorTwo:'',
    majorList:[],
    education:'',
    educationList:[],
    sex:'',
    areaOne:'',
    areaTwo:'',
    areaList:[],
    resuleCount:0,//用户选择的学生数量
    filtCount:0,//少选出来的数量
    SmsContent:'',
  }
  //推广到学生
  public seleteCollegeids = [];
  public allCollege = [];
  public MyActivityCount = 0;//单品 活动邀请的数量

  //问答
  public questionList = [];
  public AllquestionCount = 0;
  public addNewquestionData={
    question:'',
    answer:'',
    acid:''
  }
  public seleteQuestionList= [];
  public isALlSeletequestion = false;//是否全选问题
  public currentAnswerTalent:any;//当前回复的问题模型

  //学生简历列表
  public resumeList = [];
  public AllresumeCount = 0;

  public shareImageUrl = "";

  public isLiveAir = false;
  //是否显示下线按钮，直播空宣 已结束未下线的
  public isShowdownLineBtn = false

  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public productManager:productManager,
    public dialog: MatDialog,

  ) {
    this.config = Config.getInstance();//实例化config
    this.productManager.init();
  }

  ngOnInit() {
    let that = this;
    this.opera.initConfig('aircampus','');
    let hash = location.hash;
    let arr = hash.split('?')
    let acid = "";
      if(arr.length>1) {
        let urlStr = arr[1];
        let itemAr = urlStr.split('&');
        let openid = '';
        let unionid = '';
        for (let item of itemAr) {
          let dataArr = item.split('=');
          if (dataArr.length >= 2) {
            if (dataArr[0] == 'acid') {
              acid = dataArr[1]
            }
          }
        }
      }
    let airCampusDetailType = this.local.get('airCampusType')
    if(acid){
      this.local.set('air_camp_history_id',acid)
    }
      if(!acid){
        let _acid = this.local.get('air_camp_history_id')
        if(_acid) {
          acid = _acid
        }
      }
    if(airCampusDetailType){
      this.isLiveAir = true;

      this.getAiLiverCampus(acid);
    }else {
      this.getAirCampus(acid);
    }
  }

  /**
   * 获取直播空中宣讲会
   * @param acid
   */
  getAiLiverCampus(acid){
    let that = this;
    let data = {
      acid:acid
    }
    if(!acid){
      this.router.navigateByUrl("home/airCampHistory");
      this.toastr.warning('空中宣讲会不存在')
      return
    }
    this.dataApi.getAirCampustalPublicWithid(acid).then((res:any) => {
      this.opera.loadiss()
      if(res) {
        this.airCampustalk = res;
        if(this.airCampustalk.majors==null) {
          this.airCampustalk.majors = [];
        }
        this.airCampustalk.video_play_count = this.airCampustalk.view_count;
        if(this.airCampustalk.is_online){
          this.isShowdownLineBtn = true
        }
        if(res.audit_status==2) {
          this.getSmsContent();
          //获取单品的余额
          this.getDepartment();
          this.getAirQuestion(1);
          //获取学生简历列表
          this.getAirCampResumeList(true,0);
        }
        if(this.airCampustalk.video_url) {
          let video_url = this.airCampustalk.video_url
          let video_cover = this.airCampustalk.video_cover
          let video_urlArr =  video_url.split("/");
          let lastStr  = video_urlArr[video_urlArr.length-1];
          let newArr =   lastStr.split(".");
          var video = new tvp.VideoInfo(); video.setVid(newArr[0]);
          var player =new tvp.Player();
          player.create({
            width:500,
            height:300,
            video:video,
            autoplay:false,
            modId:"mod_player", onwrite:function(){
              // alert("播放器显示完毕");
              console.log('播放器显示完毕',lastStr,video_urlArr)
            },
            pic: that.airCampustalk.video_cover
          });
          //events
          player.onplaying = function(vid) {
            console.log('onplaying',vid);
            that.dataApi.setVideoPlayCount(that.airCampustalk.acid).then((res:any) => {
            }).catch(() => {})
          }
          player.onended = function(vid) {
            console.log('onended',vid);
          }
        }
      }
    }).catch((e) => {
      console.log('getAirCampustalkWithid',e)
      this.opera.loadiss()
      this.toastr.error('宣讲会不存在')
    })
  }
  getAirCampus(acid){
    let that = this;
    let data = {
      acid:acid
    }
    if(!acid){
      this.router.navigateByUrl("home/airCampHistory");
      this.toastr.warning('空中宣讲会不存在')
      return
    }
    this.dataApi.getAirCampustalkWithid(acid).then((res:any) => {
      this.opera.loadiss()
      if(res) {
        this.airCampustalk = res;
        if(this.airCampustalk.majors==null) {
          this.airCampustalk.majors = [];
        }
        //a30662p0khi
        //e0354z3cqjp
        //video_url
        if(this.airCampustalk.video_url) {
          let video_url = this.airCampustalk.video_url
          let video_cover = this.airCampustalk.video_cover
          let video_urlArr =  video_url.split("/");
          let lastStr  = video_urlArr[video_urlArr.length-1];
          let newArr =   lastStr.split(".");
          var video = new tvp.VideoInfo(); video.setVid(newArr[0]);
          var player =new tvp.Player();
          player.create({
            width:500,
            height:300,
            video:video,
            autoplay:false,
            modId:"mod_player", onwrite:function(){
              // alert("播放器显示完毕");
              console.log('播放器显示完毕',lastStr,video_urlArr)
            },
            pic: that.airCampustalk.video_cover
          });
          //events
          player.onplaying = function(vid) {
            console.log('onplaying',vid);
            that.dataApi.setVideoPlayCount(that.airCampustalk.acid).then((res:any) => {
            }).catch(() => {})
          }
          player.onended = function(vid) {
            console.log('onended',vid);
          }
        }
        if(res.audit_status==2) {
          this.getSmsContent();
          //获取单品的余额
          this.getDepartment();
          this.getAirQuestion(1);
          //获取学生简历列表
          this.getAirCampResumeList(true,0);
          this.getApplyCollegeList();
        }
        //显示拒绝原因
        if(res.audit_status==3&&res.reasons!=null) {
          this.reasons = res.reasons;
        }
      }
    }).catch((e) => {
      this.opera.loadiss()
      this.toastr.error('宣讲会不存在')
    })
  }  creatAirCampus(){
    this.router.navigateByUrl("home/creatAirCampus");
  }
  tjSchoolMode(){
    this.getApplyCollegeList()
    this.TJSchoolModel = true;
  }
  tjTalentMode(){
    this.TJTalentModel= true;
  }
  closeModel(){
    this.TJSchoolModel = false;
    this.TJTalentModel = false;
  }
  tabClick(tab){
    this.currentTab = tab;
  }
  talentTabClick(tab){
    this.talentTab = tab;
    this.getAirCampResumeList(true,0)
  }
  questionTabTabClick(tab){
    this.questionTab = tab;
    this.getAirQuestion(1);
  }
  getAirQuestion(page){
    let that = this;
    let data = {
      acid:this.airCampustalk.acid,
      type:'0',
      isAnswer:this.questionTab=="1"?false:true,
      page:page,
      pageSize:'10',
    }
    this.dataApi.getAirQuestion(data).then((res:any) => {
      if(res) {
        for(let n of res.list) {
          n.selete = false;
        }
        this.questionList = res.list;
        this.AllquestionCount = res.count;
        this.seleteQuestionList=[];
        this.isALlSeletequestion = false;
        layui.use('laypage', function(){
          var laypage = layui.laypage;
          //执行一个laypage实例
          laypage.render({
            elem: 'test1' //注意，这里的 test1 是 ID，不用加 # 号
            ,count: res.count, //数据总数，从服务端得到
            limit:10,
            theme: '#5e4fff',
            layout:['prev', 'page', 'next','skip','count']
            ,jump: function(obj, first){
              //obj包含了当前分页的所有参数，比如：
              console.log("obj.curr",obj.curr); //得到当前页，以便向服务端请求对应页的数据。
              console.log(obj.limit); //得到每页显示的条数
              //首次不执行
              if(!first){
                that.getAirQuestionNonePage(obj.curr)
              }
            }
          });
        });
      }
    }).catch(() => {
    })
  }
  getAirQuestionNonePage(page){
    let data = {
      acid:this.airCampustalk.acid,
      type:'0',
      isAnswer:this.questionTab=="1"?false:true,
      page:page,
      pageSize:'10',
    }
    this.dataApi.getAirQuestion(data).then((res:any) => {
      if(res) {
        for(let n of res.list) {
          n.selete = false;
        }
        this.questionList = res.list;
        this.AllquestionCount = res.count;
        this.seleteQuestionList=[];
        this.isALlSeletequestion = false;
      }
    }).catch(() => {
    })
  }
  getApplyCollegeList(){
    this.dataApi.getApplyCollegeList(this.airCampustalk.acid).then((res:any) => {
      if(res) {
        let arr = [];
        let not_passed = [];
        for (let n of res.all){
          n.selete = false;
          n.audit_status = n.audit_status+'';
          arr.push(n);
        }
        for (let n of res.not_passed){
          n.isApply = true;
          let nowDate = new Date();
          let nowTime = nowDate.getTime();  // 当前时间戳
          let endDate = new Date(n.limit_apply_time); // 报名开始时间
          let endDateTime = endDate.getTime();  // 报名结束时间戳
          if(nowTime-endDateTime>=0){
            console.log('可以申请')
            n.isApply = true;
            n.ApplyStr = "";
          } else {
            n.isApply = false;
            n.ApplyStr = "如需申请，请在"+n.limit_day+"个工作日后提交";
          }
          not_passed.push(n);
        }
        //limit_day
        res.all = arr;
        res.not_passed = not_passed;
        this.ApplyCollegeData = res
      }
    }).catch(() => {
      this.opera.loadiss()
    })
  }
  //单选所有高校推荐
  AllApplyCollegeClick(college){
    if(college.audit_status!='0') {
      return;
    }
    let arr = [];
    for (let n of this.ApplyCollegeData.all){
      if(n.collegeid==college.collegeid) {
        n.selete = !n.selete;
      }
      arr.push(n);
    }
    this.ApplyCollegeData.all = arr;
    console.log('单选所有高校推荐',arr);
  }
  //全选高校推荐
  AllQuanClick(){
    let arr = [];
    this.isAllSeleteCollege = !this.isAllSeleteCollege;
    for (let n of this.ApplyCollegeData.all){
      n.selete = this.isAllSeleteCollege;
      if(n.audit_status!=0) {
        n.selete = false;
      }
      arr.push(n);
    }
    this.ApplyCollegeData.all = arr;
  }
  //获取企业信息
  getDepartment(){
    // this.opera.loading();
    this.dataApi.getcomponentLeft({}).then((res:any)=>{
      res.map((item)=>{
        if(item.alias =='activity_invitation') {
          this.MyActivityCount = item.left;
        }
      })
    }).catch((err)=>{
      console.log(err);
    })
  }
  //立即推荐到高校就业信息网
  tiCollegeClick(){
    console.log(this.ApplyCollegeData.all)
    let collegeids = [];
    for(let n of this.ApplyCollegeData.all) {
      if(n.selete) {
        collegeids.push(n.collegeid)
      }
    }
    if(collegeids.length==0) {
      this.toastr.warning('未选择高校')
      return;
    }
    let data = {
      acid: this.airCampustalk.acid,
      collegeids:collegeids
    };
    this.dataApi.air_campustalk_applyCollege(data).then((res:any)=>{
      this.toastr.success('推广申请成功')
      this.TJSchoolModel = false;
      this.getApplyCollegeList();
    }).catch((error)=>{
      this.toastr.error(error);
    });
  }
  //重新高校到就业信息网
  resetCommitCollege(n){
    let data = {
      acid: this.airCampustalk.acid,
      collegeids:[n.collegeid]
    };
    this.dataApi.air_campustalk_applyCollege(data).then((res:any)=>{
      this.toastr.success('已重新提交')
      this.getApplyCollegeList();
    }).catch((error)=>{
      this.toastr.error(error);
    });
  }
  //添加新的问题弹窗
  addNewquestionModelClick(type,question){
    this.addNewquestionModel = true;
    this.addNewquestionData.question="";
    this.addNewquestionData.answer="";
    this.addNewquestionData.acid = this.airCampustalk.acid;
    if(type=='answer') {
      this.currentAnswerTalent = question;
      this.addNewquestionData.question = question.question;
    }
  }
  addNewquestionBoxHiden(){
    this.addNewquestionModel = false;
    this.currentAnswerTalent ="";
    this.addNewquestionData.question = "";
  }
  //确定添加新的问题
  addNewquestionClick(){
    //如果有回复数据 走 回复逻辑
    if(this.currentAnswerTalent) {
      if(!this.addNewquestionData.answer) {
        return;
      }
      let data = {
        airquestionid:this.currentAnswerTalent.id,
        answer:this.addNewquestionData.answer,
        is_public:true,
      }
      this.dataApi.AnswerTalent(data).then((res:any)=>{//通过tooken获取企业信息
        this.addNewquestionModel = false;
        this.toastr.success('回复成功')
        this.currentAnswerTalent = "";
        this.getAirQuestion(1);
      }).catch((error)=>{
        this.toastr.error(error);
        this.addNewquestionModel = false;
      });
      return;
    }
    if(!this.addNewquestionData.question||!this.addNewquestionData.answer||!this.addNewquestionData.acid) {
      return;
    }
    this.dataApi.creatAirQuestion([this.addNewquestionData]).then((res:any)=>{//通过tooken获取企业信息
      this.addNewquestionModel = false;
      this.toastr.success('添加成功')
      this.getAirQuestion(1);
    }).catch((error)=>{
      this.toastr.error(error);
      this.addNewquestionModel = false;
    });
  }
  questionCellClick(q) {
    //未回答，不走编辑逻辑
    if(this.questionTab=='1') {
      return;
    }
    let newArr = [];
    let seleteArr = [];
    for(let n of this.questionList ) {
      if(n.id==q.id) {
        n.selete =!n.selete;
      }
      if(n.selete) {
        seleteArr.push(n.id);
      }
      newArr.push(n);
    }
    this.seleteQuestionList = seleteArr;
    this.questionList = newArr;
  }
  allSeletequestionClick(){
    let newArr= [];
    let seleteArr = [];
    this.isALlSeletequestion = !this.isALlSeletequestion;
    for(let n of this.questionList ) {
      n.selete = this.isALlSeletequestion
      newArr.push(n);
      if(n.selete) {
        seleteArr.push(n.id)
      }
    }
    this.seleteQuestionList = seleteArr;
    this.questionList = newArr;
  }
  //设置问题是否公开
  questionMenuClick(type){
    // type ：open hidden
    //setQuestionIsPublic
    let data = {
      questionids:this.seleteQuestionList,
      is_public:type=="open"?true:false
    }
    this.dataApi.setQuestionIsPublic(data).then((res:any)=>{
      this.getAirQuestion(1);
      this.toastr.success('设置成功')
    }).catch((err)=>{
      console.log(err);
    })
  }
  getSmsContent(){
    this.dataApi.getSmsMban( '活动邀请-宣讲会').then((res:any)=>{
      if(res) {
        this.TJTalentModelData.SmsContent = res.content;
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  getAirCampResumeList(isFirst,page){
    // talentTab  1全部， 2 投递  3查看
    let offset = page*10;
    let data = {};
    if(this.talentTab=='1') {
      data = {
        offset:offset,
        limit:'12'
      }
    } else if(this.talentTab=='3') {
      data = {
        offset:offset,
        limit:'12',
        is_delivery:true
      }
    }
    else if(this.talentTab=='2') {
      data = {
        offset:offset,
        limit:'12',
        is_view:true
      }
    }
    this.dataApi.getAirCampResumeList(this.airCampustalk.acid,data).then((res:any)=>{
      if(res) {
        this.AllresumeCount = res.count;
        this.resumeList = res.list;
        for (let val of  this.resumeList ) {
          let arr = [];
          val.yixiang = "";
          val.youshiArr = [];
          for (let item1 of val.pro_skills) {
            val.yixiang += item1.text+"    "
            if(item1.contrast_list){
              for (let youshi of item1.contrast_list)
              {
                val.youshiArr.push(youshi.text);
              }
            }
          }
        }

        let that = this;
        layui.use('laypage', function(){
          var laypage = layui.laypage;
          //执行一个laypage实例
          laypage.render({
            elem: 'resumePage' //注意，这里的 test1 是 ID，不用加 # 号
            ,count: res.count, //数据总数，从服务端得到
            limit:12,
            theme: '#5e4fff',
            layout:['prev', 'page', 'next','skip','count']
            ,jump: function(obj, first){
              //obj包含了当前分页的所有参数，比如：
              console.log("obj.curr",obj.curr); //得到当前页，以便向服务端请求对应页的数据。
              console.log(obj.limit); //得到每页显示的条数
              //首次不执行
              if(!first){
                that.getAirCampResumeListPage(obj.curr)
              }
            }
          });
        });
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  getAirCampResumeListPage(page){
    // talentTab  1全部， 2 投递  3查看
    let offset = (page-1)*12;
    let data = {};
    if(this.talentTab=='1') {
      data = {
        offset:offset,
        limit:'12'
      }
    } else if(this.talentTab=='3') {
      data = {
        offset:offset,
        limit:'12',
        is_delivery:true
      }
    } else if(this.talentTab=='2') {
      data = {
        offset:offset,
        limit:'12',
        is_view:true
      }
    }
    this.dataApi.getAirCampResumeList(this.airCampustalk.acid,data).then((res:any)=>{
      if(res) {
        this.AllresumeCount = res.count;
        this.resumeList = res.list;
        for (let val of  this.resumeList ) {
          let arr = [];
          val.yixiang = "";
          val.youshiArr = [];
          for (let item1 of val.pro_skills) {
            val.yixiang += item1.text+"    "
            if(item1.contrast_list){
              for (let youshi of item1.contrast_list) {
                val.youshiArr.push(youshi.text);
              }
            }
          }
        }
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  //极速电话联系
  voiceCallClick(e,n){
    e.stopPropagation();
    if(this.productManager.my_voice_call&&this.productManager.my_voice_call.left<=0) {
      return;
    }
    this.voiceCallModel.initData(n);
    this.isShowVoiceCallModel = true;
  }
  positionPushClick(e,n){
    e.stopPropagation();
    this.sengMsgModel.initData(1,[]);
    this.SengMsgModel.userName = n.talent_name;
    this.SengMsgModel.userPic = n.logo==null?'': n.logo;
    this.SengMsgModel.graduation_school = n.graduate_university;
    this.SengMsgModel.major_text = n.major_text;
    this.SengMsgModel.talentid = n.talentid;
    this.SengMsgModel.gender = n.gender;
    this.SengMsgModel.address = n.address_text;
    this.isShowPositionPushModel =true;
  }
  //极速电话联系弹窗消失
  voiceCallModelHidden(e){
    this.isShowVoiceCallModel = false;
  }
  Modeldismiss(event){
    this.isShowPositionPushModel =false;
    this.config.batchList=[];
  }
  //-----跳转简历详情
  goDetails(n,is_ignore){
    this.dataApi.getPlatformApply().then((res:any)=>{
      if(res) {
        if(res.status == 0){
          this.dialog.open(NoneVipAlertComponent, {
            data: {
              type:'no_audit',
              message:this.config
            }
          }).afterClosed().subscribe((isConfirmed) => {
            this.opera.scrollTrue();
            console.log('isConfirmed',isConfirmed)
            if(isConfirmed){
            }
          });
        }else if(res.status == 1){
          this.dialog.open(NoneVipAlertComponent, {
            data: {
              type:'audit',
              message:this.config
            }
          }).afterClosed().subscribe((isConfirmed) => {
            this.opera.scrollTrue();
            console.log('isConfirmed',isConfirmed)
            if(isConfirmed){
            }
          });
        }else if(res.status == 2){
          if(this.opera.isShowPaySuccessModule()){
            if(is_ignore == false){
              this.router.navigate(['home/college-resumedetails'], {
                queryParams: {
                  resumeid:n.resume_number
                }
              });
            }
          }else{
            this.dialog.open(NoneVipAlertComponent, {
              data: {
                type:'goBuy',
                message:this.config
              }
            }).afterClosed().subscribe((isConfirmed) => {
              this.opera.scrollTrue();
              console.log('isConfirmed',isConfirmed)
              if(isConfirmed){
              }
            });
          }
        }else if(res.status == 3){
          this.dialog.open(NoneVipAlertComponent, {
            data: {
              type:'fail',
              message:this.config
            }
          }).afterClosed().subscribe((isConfirmed) => {
            this.opera.scrollTrue();
            console.log('isConfirmed',isConfirmed)
            if(isConfirmed){
            }
          });
        }
        return;
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  //下线空宣
  downLineClick(){
    this.downLineModel = true;
  }
  //下线宣讲会
  downLineModekClick(isdown){
    if(!isdown) {
      this.downLineModel = false;
      return;
    }
    this.downLineModel = false;
    let data = {
      is_online:false
    }
    this.dataApi.downLine(this.airCampustalk.acid,data).then((res:any)=>{
      console.log("下线",)
      this.toastr.success('下线成功')
      this.router.navigateByUrl("home/airCampHistory");
    }).catch((err)=>{
      console.log(err);
    })
  }

}
