import { Component,EventEmitter, Input, Output,ViewChild,ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataApi,Constants,Config,Local,Opera } from '../../provider/index';
// import { type } from 'os';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from './../confirm/confirm.component';

declare var window;
@Component({
  selector: 'app-model-company-name',
  templateUrl: './model-company-name.component.html',
  styleUrls: ['./model-company-name.component.css']
})
export class ModelCompanyNameComponent {
  public config: Config;  
  public model={
     name:'',
     description:'',
     image_url:'',
     filrImg:'',
     filrImg2:'',
     pid:''
  };
  public Model = {

  }
  public judge = false;//判断是否是添加操作
  @Input() isShow: false;//是否显示弹窗页面
  @Input() type= 1;//是否显示内容
  public title="";
  constructor(
    public toastr: ToastrService,
    public local:Local,
    public opera:Opera,
    public dialog: MatDialog,
    public dataApi: DataApi
  ) { 
    this.config = Config.getInstance();

    console.log(this.type);
    
  }
    @Output() onVoted = new EventEmitter();
    dismiss(result: any) {
      //console.log("传值检查");
      //console.log(result);
      this.onVoted.emit(result);
    }

    //初始化函数
    initData(data){
      if(data){
        this.title="编辑";
        this.judge=false;
        this.model=data;
        //console.log("检查传参------model-company-product");
        //console.log(data);
      }else{
        this.judge=true;
        this.title="添加";
        this.model={
          name:'',
          description:'',
          image_url:'',
          filrImg:'',
          filrImg2:'',
          pid:''
        };
      }
    }

    //保存产品
getProduct(){
  //判断企业产品数据是否填写完整
  let filter=/^[0-9a-zA-Z\u4e00-\u9fa5]+$/;
  // let filter2=/^[\u4e00-\u9fa5\（ \）]+$/;
  if(!this.model.name){
    this.toastr.warning('请填写产品名称');
    return;
  }else{
    if(!filter.test(this.model.name)){
      this.toastr.warning('产品名称只能输入汉字、字母和数字');
      return;
    }
  }
  //  let loader = this.tip.presentLoading('保存中...');
   if(this.judge){//--------添加操作
     let  model={
		   name:this.model.name,
		   description:this.model.description,
		   image_url:this.model.image_url
	  };
    this.dataApi.createDepartmentProduct(model).then((res:any)=>{
      //console.log(res);
      // loader.dismiss();
      this.model.pid=res.value;
      this.toastr.success("产品添加成功");
      this.dismiss(this.model);
    }).catch((err)=>{
      //console.log(err);
      // loader.dismiss();
    })
   }else{//--------编辑操作
     this.dataApi.uploadDepartmentProduct(this.model.pid,this.model).then((res:any)=>{
      //console.log(this.model);
      // loader.dismiss();
      this.toastr.success("产品修改成功");
      this.dismiss(this.model);
    }).catch((err)=>{
      //console.log(err);
      // loader.dismiss();
    })
  }
  
}

//删除产品
deleteProduct(){
  this.dataApi.deleteDepartmentProduct(this.model.pid).then((res:any)=>{
    //console.log(res);
    this.toastr.success('产品已删除');
    this.model={
     name:'',
     description:'',
     image_url:'',
     filrImg:'',
     filrImg2:'',
     pid:this.model.pid
  };
  this.dismiss('delete');
 })

}
    

  //上传图片
  previewPic(event,name){
    console.log(event);
    console.log(name);
    let imgData  = event.target.files[0]; //获取file对象
    if (window.FileReader) {    
      var reader = new FileReader();    
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.uploadPic(reader.result,name);
      };  
    }
  }

  lastname(){
    //获取欲上传的文件路径
    // // var filepath = document.getElementById("file1").value; 
    // //为了避免转义反斜杠出问题，这里将对其进行转换
    // var re = /(\\+)/g; 
    // var filename=filepath.replace(re,"#");
    // //对路径字符串进行剪切截取
    // var one=filename.split("#");
    // //获取数组中最后一个，即文件名
    // var two=one[one.length-1];
    // //再对文件名进行截取，以取得后缀名
    // var three=two.split(".");
    // //获取截取的最后一个字符串，即为后缀名
    // var last=three[three.length-1];
    // console.log(last);
    
    // //添加需要判断的后缀名类型
    // var tp ="jpg,gif,bmp,JPG,GIF,BMP";
    // //返回符合条件的后缀名在字符串中的位置
    // var rs=tp.indexOf(last);
    // //如果返回的结果大于或等于0，说明包含允许上传的文件类型
    // if(rs>=0){
    //     return true;
    //   }else{
    //     alert("您选择的上传文件不是有效的图片文件！");
    //   return false;
    // }
  }





//上传到服务器
uploadPic(data,name){
  let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
  imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
  this.opera.loading();//加载动画
  this.dataApi.uploadAuthImg({
    value: imageData
  }).then((res:any)=>{    
    ////console.log(res);
    if(res){
      if(res.value){//------------上传之后赋值
        this.model[name] = res.value;
      }       
    }
    this.opera.loadiss();//停止加载动画
  }).catch((error) => {
    ////console.log(error);     
    this.opera.loadiss();//停止加载动画
    this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
  }); 
}

  //上传企业展示图片
  uploadDepImg(data){
    let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
    // let loader = this.tip.presentLoading('图片保存中，请稍等...');
    this.dataApi.uploadDepartmentImg({  
      value: imageData
    }).then((res:any)=>{
      // loader.dismiss();
      this.model.image_url=res.value;
    }).catch((error)=>{
      // loader.dismiss();
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    })     
  }


  //验证企业全称
  checkName(){
    if(this.model.name){
      let filter2=/^[a-zA-Z\uff08\uff09\u4e00-\u9fa5]+$/;
    //  console.log("正则表达式-------");
    //  console.log(filter2);
      if(!filter2.test(this.model.name)){
      this.toastr.warning("企业全称只能输入汉字和字母");
      return;
    }
    this.opera.loading();//加载动画 
    this.dataApi.testDepartmentName({name:this.model.name}).then((res:any)=>{
      this.opera.loadiss();//停止加载动画
        //console.log(res);
        if(res.value>0){
          this.opera.scrollTop();//页面滚动函数
        this.dialog.open(ConfirmComponent, {
          data: {
            title: '提示',
              message: '该企业全称已存在，是否继续修改？(修改后可正常使用)',
              trueText:'确定',
              falseText:'取消'
          }
        }).afterClosed().subscribe((data) => {
              //console.log('检测是否存在返回值');
              //console.log(data);
              //  //console.log(typeof(data));
              this.opera.scrollTrue();
              if(!data){
                this.model.name=''
              }
        });
        }
      }).catch((err)=>{
        this.opera.loadiss();//停止加载动画 
      //console.log(err);
      })
    }
  }


  // 企业修改企业名称提交审核(企业 需上传营业执照)
  authenticationUpdateDepartmentname(){
    this.dataApi.testDepartmentName(this.Model).then((res:any)=>{
      console.log(res);
    }).catch((err)=>{
      console.log(err);
    })
  }
  
}
