import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import {payModel} from "../model-pay-code/model-pay-code.component";
import {regexManager} from "../../provider/regexManager";

export interface Model {
  title:string;
  callback:any,
  listData:any,
  invoiceid:'',
  urm_create:any
  item:any
}


@Component({
  selector: 'app-pay-invoice-model',
  templateUrl: './pay-invoice-model.component.html',
  styleUrls: ['./pay-invoice-model.component.css']
})
export class PayInvoiceModelComponent implements OnInit {

  public isShowClose = false;
  public callback:any; //支付回钓

  public item:any
  public model = {
    pay_orderid: [],// 支付记录id
    amount: 0,//发票金额
    invoice_title_type: '',// 发票抬头类型 enterprise_unit 企业单位 personal 个人
    invoice_title: '',// 发票抬头
    id_number: '',// 身份证号
    register_no: '',// 税务登记号
    bank_name: '',// 基本开户银行名称
    bank_no: '',  // 基本开户账号
    register_address: '',// 注册场所地址
    register_tel: '',// 注册固定电话
    email: '',   // 发票接收邮箱
    remark: '',  // 备注
    address: '',
    getInvoiceList: [],  //可开发票列表,
    tax_classification:'' //发票内容
  }
  public invoice_title_typeArr= [{name:'企业单位',value:'enterprise_unit'},{name:'个人',value:'personal'}]
  public tax_classifications= [];
  //开票记录ID，有的话是编辑
  public invoiceid = "";

  public loading = false;
  public urm_create = false;
  public urm_create2 = false;


  public timer = null;
  public timerIndex = 5;
  public isVirty = false;
//获取dom元素
  @ViewChild('msgBox') msgBox: ElementRef;
  constructor(
    public toastr: ToastrService,
    public dataApi: DataApi,
    private router: Router,
    public opera: Opera,
    private renderer: Renderer2,
    public local: Local,
    private activatedRoute: ActivatedRoute,
    public regexManager:regexManager,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Model
  ) {
    this.loading = false;
    console.log('开发票',data)
    //channel_category=='offline'
    if(data.callback)
    {
      this.callback = data.callback;
    }
    if(data.invoiceid)
    {
      this.invoiceid = data.invoiceid;
    }
    // this.urm_create = true;

    this.item = data.item;
    console.log('公对公转账',this.urm_create,data.item.channel_category)

    if(data.item.urm_create && data.item.channel_category=='offline')
    {
      this.urm_create = data.urm_create;

      console.log('公对公转账2',this.urm_create)
      // this.model.invoice_title = "公司名称";
      // this.model.register_no = "123123";
      this.model.invoice_title_type = 'enterprise_unit';
    }
    let count = 0;
    let id_arr = [];
    if(data.listData.length>0)
    {
      data.listData.map((item)=>{
        id_arr.push(item.pay_orderid)
        count+= item.amount_payable
      })
      this.model.amount = count;
      this.model.pay_orderid = id_arr;
    }
    this.getDictClassification();
    this.getInvoiceSettime(this.model.pay_orderid);
  }

  ngOnInit() {
  }
  closeClick(){
    // this.isShowClose = true;
    this.callback(false);

  }
  closeModelClick(index){
    if(index==1)
    {
      this.isShowClose = false;
    }
    else
    {
      this.callback(false);
    }
  }
  getDictClassification(){
    //获取发票内容
    this.dataApi.getDictDate('tax_classification').then((res:any)=>{
      console.log('tax_classification',res)
      if(res)
      {
        this.tax_classifications = res;
        if(res.length>0)
        {
          this.model.tax_classification = res[0].code;
        }
      }
    }).catch((error)=>{});
  }
  //提交
  goApply() {
    if( this.loading)
    {
      this.toastr.warning('网络请求中')
      return;
    }
    if(!this.model.invoice_title_type)
    {
      this.toastr.error('请选择抬头类型');
      return;
    }
    console.log('this.model',this.model)
    if (this.model.pay_orderid.length == 0) {
      this.toastr.error('请选择要开发票的数据');
      return;
    }
    //必填项检查
    // 邮箱
    var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    //电话
    var phone = /^1(3|4|5|6|7|8|9)\d{9}$/;
    var fixedPhone = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;
    //身份证号
    var idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

    if (this.model.pay_orderid.length == 0) {
      this.toastr.warning("请选择支付记录");
      return;
    }

    if (this.model.invoice_title_type == 'personal') {
      if (!this.model.invoice_title) {
        this.toastr.warning("请填写姓名");
        return;
      }
      if (!this.model.id_number) {
        this.toastr.warning("请填写身份证号");
        return;
      } else if (!idcard_patter.test(this.model.id_number)) {
        this.toastr.warning("请填写正确身份证号");
        return;
      }
      if (!this.model.email) {
        this.toastr.warning("请填写邮箱");
        return;
      } else if (!reg.test(this.model.email)) {
        this.toastr.warning("请填写正确的邮箱");
        return;
      }
    } else {
      if (!this.model.invoice_title) {
        this.toastr.warning("请填写发票抬头");
        return;
      }
      if (!this.model.register_no) {
        this.toastr.warning("请填写税号");
        return;
      }
      if (!this.model.email) {
        this.toastr.warning("请填写邮箱");
        return;
      } else if (!reg.test(this.model.email)) {
        console.log('this.model.email',this.model.email);
        this.toastr.warning("请填写正确的邮箱");
        return;
      }
      // if (!this.model.register_address&& !this.urm_create) {
      //   this.toastr.warning("请输入公司地址");
      //   return;
      // }
      // if (!this.model.register_tel&& !this.urm_create) {
      //   this.toastr.warning("请填写公司电话");
      //   return;
      // }

      if (!this.regexManager.isPhoneOrTel(this.model.register_tel)&& !this.urm_create&&this.model.register_tel) {
        this.toastr.warning("电话号码格式错误，请重新填写");
        return;
      }
      // if (!this.model.bank_name&& !this.urm_create) {
      //   this.toastr.warning("请填写开户银行");
      //   return;
      // }
      // if (!this.model.bank_no&& !this.urm_create) {
      //   this.toastr.warning("请填写银行账号");
      //   return;
      // }
    }
    if(!this.model.tax_classification)
    {
      this.toastr.warning("请选择发票内容");
      return;
    }
    console.log('申请开票', this.model);

    this.startTime();

  }
  appHTTP(){
    this.loading = true;

    let str = JSON.stringify(this.model);
    let str2 = str.replace(/\s+/g,"");
    let model = JSON.parse(str2);
    //编辑
    if(this.data.invoiceid)
    {
      this.dataApi.applyInvoiceUpdate(this.data.invoiceid,model).then((res:any)=>{
        console.log('开票成功')
        this.loading = false;
        this.callback(true);

      }).catch((error)=>{
        this.toastr.error(error);
        this.loading = false;
      });
    }
    else
    {
      this.dataApi.applyInvoice(model).then((res:any)=>{
        console.log('开票成功')
        this.loading = false;
        this.callback(true);
      }).catch((error)=>{
        this.loading = false;
        this.toastr.error(error);
      });
    }
  }
  startTime(){
    let that = this;
    if(this.timerIndex<5)
    {
      return;
    }
    this.timerIndex = 5;
    this.isVirty = false;
    this.timer =  setInterval(()=>{
      that.timerIndex-=1;
      if(that.timerIndex==0)
      {
        that.timerIndex = 5;
        clearInterval(that.timer)
        that.isVirty = true;
      }
    },1000)
  }
  //数据填充
  dataFull(){
    let departmentStr = this.local.get('department');
    if(departmentStr)
    {
      let departmentJson = JSON.parse(departmentStr)

    }
  }
  getInvoiceSettime(pay_orderid){
    this.dataApi.getInvoiceSetting(pay_orderid).then((res:any)=>{
      console.log('getInvoiceSettime',res)
      //account_type: null
      // accountid: null
      // add_time: "0001-01-01T00:00:00"
      // bank_name: "564165416541" 开户银行
      // bank_no: "25626126416" 银行账号
      // email: "151445165@qq.com"  //公司邮箱
      // id_number: ""
      // invoice_title: "北京宾果" //发票抬头
      // invoice_title_type: "enterprise_unit"
      // is_defaule: false
      // is_delete: false
      // modify_time: "0001-01-01T00:00:00"
      // register_address: "5415415" 公司地址
      // register_no: "154151541541" 公司税号
      // register_tel: "18852564165" 公司电话
      // remark: null
      // settingid: null
      if(res)
      {
        if(res.bank_name)
        {
          this.model.bank_name = res.bank_name;
        }
        if(res.bank_no)
        {
          this.model.bank_no = res.bank_no;
        }
        if(res.email)
        {
          this.model.email = res.email;
        }
        if(res.invoice_title)
        {
          this.model.invoice_title = res.invoice_title;
        }
        if(res.register_address)
        {
          this.model.register_address = res.register_address;
        }
        if(res.register_no)
        {
          this.model.register_no = res.register_no;
        }
        if(res.register_tel)
        {
          this.model.register_tel = res.register_tel;
        }
        if(!res.invoice_title)
        {
          this.urm_create2 = true;
        }

      }
    }).catch((error)=>{
    });
  }
}
