<!-- 企业发展历程 -->
<!-- 遮罩层公共样式 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px','width':'700px'}" [(visible)]="isShow" (onHide)="dismiss(false)" [modal]=true>
    <div class="model-cont">
        <div class="clos" (click)="dismiss(false)"><img src="/assets/images/rights/contactMeClos.png"></div>
        <div class="model-head clear">
            <div class="left">发展历程</div>
        </div>
        <div class="formBox">
            <div class="module">
                <div class="lable">事件名称<span>（必填）</span></div>
                <div class="val">
                    <input type="text" [(ngModel)]="model.name" maxlength="20" placeholder="请填写事件名称，少于10个字">
                </div>
            </div>
            <div class="module">
                <div class="lable">发生时间<span>（必填）</span></div>
                <div class="val">
                    <input type="text" id="course-time" [(ngModel)]="model.occur_time" placeholder="请选择发生时间">
                </div>
            </div>
            <div class="module">
                <div class="lable">事件网址</div>
                <div class="val">
                    <input type="text" [(ngModel)]="model.link" placeholder="请填写事件相关网址">
                </div>
            </div>
            <div class="module">
                <div class="lable">事件描述</div>
                <div class="textarea">
                    <textarea [(ngModel)]="model.details" minlength="0" maxlength="200" placeholder="请填写事件描述，少于200字"></textarea>
                </div>
            </div>

        </div>
        <div class="select-submit">
            <div class="item active" *ngIf="model.cid" (click)="deleteProgress()">删除</div>
            <div class="item" (click)="getProgress()">保存</div>
        </div>
    </div>
</p-dialog>