<!-- 用户确认操作弹出框 -->
<div class="modal-dialog" *ngIf="type">

    <div *ngIf="type == 'collegeblack'" style="width: 500px;height: 200px;background-color: whitesmoke; margin: 210px auto 0;">
        <div class="blackTitle flex_s"> <span>拉黑提示</span>
            <button [mat-dialog-close] style="width: 16px;height: 16px;padding: 0;border: none;">
        <img (click)="close()" style="width: 16px;height: 16px" src="../../assets/images/close_default.png" />
      </button>
        </div>
        <div class="blackTitleText marginTop20" style="margin-top: 15px">您因违规，已被该高校拉黑，无法申请该高校就业活动，如有疑问请联系高校就业服务中心。</div>
    </div>

    <div *ngIf="type == 'custom'&&!contentImage" class="newModal-content">
        <div class="newModal-content-top" *ngIf="!isVip">
            <div class="newModal_h1 newModal_h1_bottom newModal_h3" style="margin-top: 0" *ngIf="!isVip">成为工作啦会员</div>
            <div class="newModal_h1">{{content}}</div>
            <div class="newModal_h1" style="margin-top: 5px" *ngIf="subcontent">{{subcontent}}</div>
            <div class="newModal_h2" *ngIf="subTitle">{{subTitle}}</div>
            <img src="../../assets/images/pic_huiyuan.png" />
        </div>
        <div class="newModal-content-top" style="height: 266px" *ngIf="isVip">
            <div class="newModal_h1" style="margin-top: 45px">您的权益不足</div>
            <div class="newModal_h1" style="margin-top: 10px">请补充相关权益</div>
            <img style="height: 98px" src="../../assets/images/pic_huiyuan2.png" />
        </div>
        <button [mat-dialog-close] class="newModal_close" *ngIf="!isVip">
      <img src="../../assets/images/icon_guanbi_model.png" />
    </button>
        <button [mat-dialog-close] class="newModal_close" style="bottom: 15px" *ngIf="isVip">
      <img src="../../assets/images/icon_guanbi_model.png" />
    </button>
    </div>

    <div *ngIf="contentImage" class="newModal-content">
        <img src="{{contentImage}}" style="width:290px;height: auto " />
        <button [mat-dialog-close] class="newModal_close" style="bottom: 15px">
      <img src="../../assets/images/icon_guanbi_model.png" />
    </button>
    </div>

    <div class="modal-content" style="margin: 0 auto;  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 510px;
  height:260px;
  background: url(../../assets/images/vip/vip_bg2.png)" *ngIf="type == 'audit'  ">
        <button [mat-dialog-close] style="border: none">
      <img style="position: absolute;top: 10px;right: 10px;width: 16px;height: 16px"
        src="../../assets/images/vip/vip_x.png" />
    </button>
        <div style="display: flex;align-items: center;margin: 10px 0">
            <img src="../../assets/images/vip/vip_time.png" style="width: 20px;height: 20px;margin-right: 2px">
            <span style="font-size: 17px;color: #333333">您当前处于认证中</span>
        </div>
        <div class="card_guwen flex_s" *ngIf="message.departmentSelf">
            <div class="card_guwen_l">
                <img class="card_icon" src="{{message.departmentSelf.avatar}}">
                <div class="card_title1">工作啦校招顾问</div>
                <div class="card_title2">{{message.departmentSelf.name}}</div>
                <div class="card_title3 flex_l" style="margin-top: 19px"><img src="../../assets/images/kefu_wx.png">微信：{{message.departmentSelf.wx}}</div>
                <div class="card_title3 flex_l"><img src="../../assets/images/kefu_email.png">邮箱：{{message.departmentSelf.email}}</div>
            </div>
            <img class="card_guwen_r" src="{{message.departmentSelf.qr_code}}">
        </div>
        <button [mat-dialog-close] style="border: none">
      <div (click)="goRouter1('guide-audit')" style="display: flex;margin-top: 10px;align-items: center">
        <div style="font-size: 13px;color: #999999">查看审核状态</div>
        <img style="width: 12px;height: 12px" src="../../assets/images/vip/vip_r.png" />
      </div>
    </button>
    </div>

    <div class="modal-content" style="margin: 210px auto 0;  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 390px;
  height:191px;
  background: url(../../assets/images/vip/vip_bg2.png);
  background-size: 390px 191px" *ngIf="type == 'fail'">
        <button [mat-dialog-close] style="border: none">
      <img style="position: absolute;top: 10px;right: 10px;width: 16px;height: 16px"
        src="../../assets/images/vip/vip_x.png" />
    </button>
        <div style="display: flex;align-items: center;margin: 20px 0">
            <img src="../../assets/images/vip/vip_fail.png" style="width: 48px;height: 48px;margin-right: 4px">
            <span style="font-size: 19px;color: #333333;font-weight: bold">您的认证信息审核失败</span>
        </div>
        <button [mat-dialog-close] style="border: none">
      <div (click)="goRouter1('guide-audit')" style="display: flex;margin-top: 10px;align-items: center">
        <div
          style="font-size: 15px;color: #ffffff;background: #5E4FFF;text-align: center;line-height: 36px;width: 120px;height: 36px">
          重新认证</div>
      </div>
    </button>
    </div>
    <div class="modal-content" style="margin: 0 auto 0;  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 390px;
  height:191px;
  background: url(../../assets/images/vip/vip_bg2.png);
  background-size: 390px 191px" *ngIf="type == 'no_audit'">
        <button [mat-dialog-close] style="border: none">
      <img style="position: absolute;top: 10px;right: 10px;width: 16px;height: 16px"
        src="../../assets/images/vip/vip_x.png" />
    </button>
        <div style="display: flex;align-items: center;margin: 20px 0">
            <img src="../../assets/images/vip/vip_fail.png" style="width: 48px;height: 48px;margin-right: 4px">
            <span style="font-size: 19px;color: #333333;font-weight: bold">您当前未认证</span>
        </div>
        <button [mat-dialog-close] style="border: none">
      <div (click)="goRouter1('guide-audit')" style="display: flex;margin-top: 10px;align-items: center">
        <div
          style="font-size: 15px;color: #ffffff;background: #5E4FFF;text-align: center;line-height: 36px;width: 120px;height: 36px">
          去认证</div>
      </div>
    </button>
    </div>
    <div class="modal-content" style="margin: 0 auto;  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 390px;
  height:191px;
  background: url(../../assets/images/vip/vip_bg2.png);
  background-size: 390px 191px" *ngIf="type == 'au_timeOut'">
        <button [mat-dialog-close] style="border: none">
      <img style="position: absolute;top: 10px;right: 10px;width: 16px;height: 16px"
        src="../../assets/images/vip/vip_x.png" />
    </button>
        <div style="display: flex;align-items: center;margin: 20px 0">
            <img src="../../assets/images/vip/vip_fail.png" style="width: 48px;height: 48px;margin-right: 4px">
            <span style="font-size: 19px;color: #333333;font-weight: bold">工作啦认证已过期</span>
        </div>
        <button [mat-dialog-close] style="border: none">
      <div (click)="goRouter1('guide-audit')" style="display: flex;margin-top: 10px;align-items: center">
        <div
          style="font-size: 15px;color: #ffffff;background: #5E4FFF;text-align: center;line-height: 36px;width: 120px;height: 36px">
          去认证</div>
      </div>
    </button>
    </div>


    <div class="modal-content" style="margin: 0 auto;  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 460px;
  height:630px;" *ngIf="type == 'general_guwen'">
        <div class="ConnetSuccess">
            <div class="connetSuccess_top">
                <div class="connetSuccess_top_h1">您的信息已经收到</div>
                <div class="connetSuccess_top_h2">您的专属校招顾问会尽快与您取得联系，您也可以主动添加校招顾问进行咨询</div>
            </div>
            <div class="guWenBox" *ngIf="config.departmentSelf">
                <img class="zhuanshu" src="../../assets/images/zhuanshu.png" />
                <div class="flex_l marginTop20">

                    <img class="guwen_head_icon" src="{{config.departmentSelf.avatar}}" />
                    <span class="guwen_Name">{{config.departmentSelf.name}}</span>
                    <img class="guwen_Yello" src="../../assets/images/huangguan.png" />
                </div>
                <div class="guwen_cell flex_l marginTop20" (click)="copyText(config.departmentSelf.wx)">
                    <img class="guwen_cell_icon" src="../../assets/images/guwen_wx.png" />
                    <div class="guwen_cell_title">微信：</div>
                    {{config.departmentSelf.wx}}
                    <img class="guwen_cell_copy" src="../../assets/images/guwen_copy.png" />
                </div>
                <div class="guwen_cell flex_l marginTop8" (click)="copyText(config.departmentSelf.email)">
                    <img class="guwen_cell_icon" src="../../assets/images/guwen_email.png" />
                    <div class="guwen_cell_title">邮箱：</div>
                    {{config.departmentSelf.email}}
                    <img class="guwen_cell_copy" src="../../assets/images/guwen_copy.png" />
                </div>
                <div class="guwen_cell flex_l marginTop8" (click)="copyText(config.departmentSelf.mobile)">
                    <img class="guwen_cell_icon" src="../../assets/images/guwen_phone.png" />
                    <div class="guwen_cell_title">手机号：</div>
                    {{config.departmentSelf.mobile}}
                    <img class="guwen_cell_copy" *ngIf="config.departmentSelf.mobile" src="../../assets/images/guwen_copy.png" />
                </div>
                <img src="{{config.departmentSelf.qr_code}}" class="guwen_cell_code" />
            </div>
        </div>
        <img class="closeCard" (click)="close()" src="../../assets/images/icon_guanbi_model.png" />

    </div>

    <div *ngIf="type == 'general'" class="modal-content newCar">

        <div class="newCar_top_cus">
            <img class="newCar_top_icon" src="../../assets/images/orange_warning.png" />
            <div class="newCar_top_title" *ngIf="!subTitle">{{content}}</div>

            <div class="newCar_top_title1" *ngIf="subTitle">{{content}}</div>
            <div class="newCar_top_subtitle" *ngIf="subTitle">{{subTitle}}</div>

            <img class="clickLink" src="../../assets/images/clickLink_small.png" (click)="showguwen()" />
            <div class="clickLink_text" (click)="gotoQuanyi()">点击查看「工作啦」VIP会员详细权益</div>
        </div>
        <img class="closeCard" (click)="close()" src="../../assets/images/icon_guanbi_model.png" />
    </div>
    <div *ngIf="type == 'signMilk'" class="modal-content camp_Model">
        <div class="camp_bg">
            <!--<img class="newCar_top_icon" src="../../assets/images/orange_warning.png" />-->
            <!--<div class="newCar_top_title" *ngIf="!subTitle">{{content}}</div>-->

            <!--<div class="newCar_top_title1" *ngIf="subTitle">{{content}}</div>-->
            <!--<div class="newCar_top_subtitle" *ngIf="subTitle">{{subTitle}}</div>-->

            <img class="clickLink" src="../../assets/images/clickLink_small.png" (click)="showguwen()" />
            <div class="clickLink_text" (click)="gotoQuanyi()">点击查看「工作啦」VIP会员详细权益</div>
        </div>
        <img class="closeCard" (click)="close()" src="../../assets/images/icon_guanbi_model.png" />
    </div>



    <!--提示成为VIP-->
    <div *ngIf="type == 'general-alert-vip'" class="modal-content newCar">
        <div class="newCar_top">
            <img class="clickLink" src="../../assets/images/clickLink_small.png" (click)="showguwenVIP()" />
            <div class="clickLink_text" (click)="gotoQuanyiVIP()">点击查看「工作啦」VIP会员详细权益</div>
        </div>
        <img class="closeCard" (click)="closeVIP()" src="../../assets/images/icon_guanbi_model.png" />
    </div>

</div>


<!-- <h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content>{{message}}</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>取消</button>
  <button mat-button [mat-dialog-close]="true">确定</button>
</mat-dialog-actions> -->