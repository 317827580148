import { Component, OnInit, Output, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { Config, Constants, DataApi, Local, Opera } from "../../provider";
import { PlatformLocation } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SelectCheckboxComponent } from "../select-checkbox/select-checkbox.component";
import { ModelSelectTwoComponent } from "../model-select-two/model-select-two.component";
import { ModelSendMsgComponent } from "../model-send-msg/model-send-msg.component";
import { MatDialog } from "@angular/material/dialog";
import { filter } from "rxjs/operators";
import { Events } from '../../provider/eventService';
import { scrollTool } from "../../provider/scrollTool";
import { regexManager } from "../../provider/regexManager";
import { NoneVipAlertComponent } from "../none-vip-alert/none-vip-alert.component";
import { productManager } from "../../provider/productManager";
import { VoiceCallModelComponent } from "../voice-call-model/voice-call-model.component";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
import * as moment from 'moment';

declare let window;
@Component({
  selector: 'app-search-people',
  templateUrl: './search-people.component.html',
  styleUrls: ['./search-people.component.css']
})
export class SearchPeopleComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true })
  productModel: ProductModelComponent;

  @ViewChild('initSelect') obj: SelectCheckboxComponent;
  @ViewChild('industry') child: ModelSelectTwoComponent;
  @ViewChild('sengMsgModel') sengMsgModel: ModelSendMsgComponent;


  @Output() isShowVoiceCallModel = false;//是否打开弹窗(简历收件箱)
  @ViewChild('voiceCallModel') voiceCallModel: VoiceCallModelComponent;

  public config: Config;
  public positionList = [];//职位列表
  public keywordList = [];//搜索关键词匹配列表
  public title = "请选择高校";
  public title1 = "请选择专业";
  public isShowResult = false;
  public searchKeywords = '';
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelShowSengMsg = false;//是否打开单品发送极速短信
  @Output() SengMsgModel = {
    userName: '',
    userPic: '',
    talentid: '',
    graduation_school: '',//学校
    major_text: '',//专业
    gender: '',
    address: '',
    positionid: ''
  };//打开单品发送极速短信用户对象

  // 筛选条件--搜索
  public model = {
    positionid: '',
    college_name: '',//学校名称
    gender: '',
    city: '',
    limit: 9,
    offset: 0
  }
  public eachPageCount = 10;//每页显示数量
  public resumeList = [];//简历列表
  public productList = [];//产品列表
  public countList = 0;//列表数量
  public endTime: string;//高级搜索结束时间
  // public searchStatus=false;//判断是否开通高级搜索
  public searchStatus = true;//判断是否开通高级搜索
  public educationList = [];//学历列表
  public scrollTop = 280;
  public positionresumelistindex = 0;
  public val = {
    status: 2
  };
  public mod = {
    city: '',//城市
    school_type: '',//分类
    school_nature: '',//性质
    labels: '',//特色
    status: '',//校招状态
    is_activity: true,
    is_online: true,
    offset: 0,
    limit: 1000
  };
  public searchModel = {
    collegeid: '',//学校id
    positionid: '',//职位id
    offset: 0,
    limit: 10
  }
  public advanceModel = {
    //salary:'',//月薪范围(字典)
    //collegeid:'',
    education: '',//学历要求
    //work_city:'',
    major: '',//专业要求
    provice: '',
    city: '',
    post_type: '',
    post_type_text: '',
    //school_label:'',//高校标签
    gender: '',//性别
    //is_online:'',//是否在线
    native_place: '', //籍贯
    job_status:'01', // 求职状态
    limit: 10,
    offset: 0,
    keywords: '',
    is_curr_session: null,
    year: '',
    positionId: ''
  };
  public majorList = [];
  public majorListOne = [];//一级专业列表
  public majorListTwo = [];//二级专业列表
  public majorOne = '';//专业类别一级`
  public jiebie = '';//专业类别一级
  public collegeList = [];//认证的高校列表
  public collegeCount: 0;//列表总数
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  // public isPsitionList=false;
  public isShowFilter = false;
  public hotCity = [];//热门城市
  public hotArea = [];//热门区
  public hotProvince = [];//热门省
  public HotCityAndProvinceArr = [];//热门省/市合并


  public moreSearch: boolean = true;
  public alertModel: boolean = false;

  public showModel = false;//是否显示高校云认证弹窗烦人

  public useBatch: boolean = false;  // 显隐选择按钮
  public selectAll: boolean = false; // 全选是否
  public totalCoin = 0;//总资源数
  public isShowPushModel = false;//批量职位推送弹窗

  public sms_bulkinvitation = null;
  public voice_call = null;
  public resumeDetails: any;
  public myDepartment: any;//当前登录的企业信息(department)
  public admin = '';

  public phoneModel = {
    myPhone: '', //企业拔打电话的手机
    myTel: '',
    myTel_qu: '',
    isOkmyPhone: false,
    NetPhone: '',
    positionid: '',
    getPhoneType: '1',
    PhoneModelType: 0,
    MyPhoneTime: 0,
    isShowPhoneModel: false,
    jiduCode: '',
    positionPushCode: '',
  }

  public isShowMemberMoudel = true;
  //是否全选
  public isAllSelete = false;
  public downLoadArr = [];
  public pushModels = {
    positionid: "",
    content: '',
  }
  public jiebieList: any = [];
  constructor(
    public dataApi: DataApi,
    public local: Local,
    private router: Router,
    public opera: Opera,
    public events: Events,
    public dialog: MatDialog,
    public scrolltool: scrollTool,
    public regexManager: regexManager,
    public productManager: productManager,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService) {
    this.config = Config.getInstance();  // 实例化config
    this.config.batchList = [];
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {

      activatedRoute.queryParams.subscribe(queryParams => {
        if (queryParams.admin) {
          this.admin = queryParams.admin;
        }
      })
      if (event.url === '/home/searchPeople') {
        console.log("searchpeople", this.scrolltool.searchPeople_cell_scrollY);
        this.opera.initConfig('navSearch', 'searchPeople');
        this.getNewList();//获取职位列表数据

        if (this.local.get(Constants.oldUrl).indexOf('college-resumedetails') >= 0) {
          console.log('-----------', 222)
          this.isShowResult = true;
          this.scrolltool.initScrooll_cell_With_scrollY(this.scrolltool.searchPeople_cell_scrollY)

        } else {
          if (this.local.get(Constants.oldUrl).indexOf('chatSingle') >= 0) {
            this.isShowResult = true;
            this.scrolltool.initScrooll_cell_With_scrollY(this.scrolltool.searchPeople_cell_scrollY)
            return;
          }
          console.log(111)
          this.isShowResult = false;
          //this.opera.scrollTrue();
          this.opera.scrollTop1();

        }
        this.getDepartment();
        //this.initScrooll();

        //this.scrolltool.initScrooll_cell_With_scrollY(this.scrolltool.searchPeople_cell_scrollY);


        //
      }

      //是否携带关键词搜索
      if (this.local.get('searchPeopleText')) {
        let searchPeopleText = this.local.get('searchPeopleText');
        this.advanceModel.keywords = searchPeopleText;
        this.goSearchResult();
      }
    });
  }

  ngOnInit() {
    this.getJiebie();
    if (this.local.get('eachPageCount')) {
      this.eachPageCount = parseInt(this.local.get('eachPageCount'));
    }

    this.searchModel.limit = this.eachPageCount;
    this.advanceModel.limit = this.eachPageCount;
    console.log("this.config.lastIdentity", this.config.lastIdentity);
    this.getCollegeCode();//获取高校字典
    // this.getHotCity();//获取热门城市
    this.getHotProvice();//获取热门省
    // this.getEmployment();//判断是否开通高级搜索
    this.getCollegeList();//获取认证学校
    // this.getSchoolLabel();//获取高校标签
    // this.getIndustry();//获取所属行业
    // this.getEducationList();//获取学历列表信息
    this.getMajorList();//获取专业列表信息
    this.events.subscribe('isShowSearch', (data) => {
      this.isShowResult = data;
      this.advanceModel.education = '';
      this.advanceModel.keywords = '';
      this.advanceModel.native_place = '';
      this.advanceModel.gender = '';
      this.advanceModel.post_type_text = '';
      this.advanceModel.post_type = '';
      this.advanceModel.is_curr_session = '';
      this.opera.scrollTop1();
    });

    //获取产品列表
    this.getProductList();
    this.opera.checkMemberPay();
    this.getMSGContent();
    this.opera.vipState((isPay) => {
      this.isShowMemberMoudel = isPay;
    })

  }

  // 企业获取简历开放日期所在年份
  getJiebie() {
    this.dataApi.getCircles().then((res: any) => {
      if (res.length) {
        let min = Math.min(...res);
        this.jiebieList = res.map(item => {
          return {
            text: item == min ? '往届' : `${item}届`,
            code: item
          }
        })
      }
    }).catch((error) => {
      console.log('企业获取简历开放日期所在年份', error);
    });
  }

  //修改每页显示的条数
  getpageCount(event) {
    this.local.set('eachPageCount', event)
    console.log(event);
    this.searchModel.offset = 0;
    this.searchModel.limit = event;
    this.advanceModel.offset = 0;
    this.advanceModel.limit = event;
    if (this.pageNumber > 1) {
      this.getChildEvent(1);
    } else {
      this.getRecommendMoreList();//获取简历投递列表
    }

  }

  goBack() {
    this.advanceModel.post_type_text = '';
    this.advanceModel.post_type = '';
    this.advanceModel.provice = '';
    this.advanceModel.keywords = '';
    this.advanceModel.is_curr_session = '';
    this.isShowResult = false;
  }

  getCollegeList() {
    // if (this.config.lastIdentity.status != '2') {
    //   this.dataApi.getCollegeList(this.val).then((res:any) => {
    //     console.log("获取高校列表--------------------------------chooseCollegePage");
    //     console.log(res);
    //     this.collegeList = res.list;
    //     this.collegeCount = res.count;
    //   }).catch((err) => {
    //     console.log(err);
    //   })
    // } else {
    this.dataApi.getCollegeList(this.mod).then((res: any) => {
      console.log("获取高校列表--------------------------------ChooseCollegePage");
      console.log(res);
      if (res) {
        this.collegeList = res.list;
        this.collegeList.map((item, index) => {
          item.id = index
        })
      }

      // this.listCount=res.count;
    }).catch((err) => {
      console.log(err);
    })
    // }
  }


  //获取分页子组件返回数据
  getChildEvent(index) {
    console.log('子组件返回分页数-----college-resume', this.pageNumber);
    console.log(index);
    // console.log(this.pageNumber + ' pageNumber');
    // console.log(index + ' index');
    this.downLoadArr = [];
    this.isAllSelete = false;
    if (!this.moreSearch) {
      if (index > 0) {
        this.pageNumber = index;
        this.searchModel.offset = (this.pageNumber - 1) * this.eachPageCount;
        this.getRecommendMoreList();//获取职位推荐简历
      } else if (index == -1) {//上一页
        if (this.pageNumber == 1) {
          this.toastr.warning("当前为第一页");
        } else {
          this.pageNumber = this.pageNumber - 1;
          this.searchModel.offset = (this.pageNumber - 1) * this.eachPageCount;
          this.getRecommendMoreList();//获取职位推荐简历
        }
      } else if (index == -2) {//下一页
        if (this.pageNumber == this.pageCount) {
          this.toastr.warning("当前为最后一页");
        } else {
          this.pageNumber = this.pageNumber + 1;
          this.searchModel.offset = (this.pageNumber - 1) * this.eachPageCount;
          this.getRecommendMoreList();//获取职位推荐简历
        }
      }
    } else {
      if (index > 0) {
        this.pageNumber = index;
        this.advanceModel.offset = (this.pageNumber - 1) * this.eachPageCount;
        this.getRecommendMoreList();//获取职位推荐简历
      } else if (index == -1) {//上一页
        if (this.pageNumber == 1) {
          this.toastr.warning("当前为第一页");
        } else {
          this.pageNumber = this.pageNumber - 1;
          this.advanceModel.offset = (this.pageNumber - 1) * this.eachPageCount;
          this.getRecommendMoreList();//获取职位推荐简历
        }
      } else if (index == -2) {//下一页
        if (this.pageNumber == this.pageCount) {
          this.toastr.warning("当前为最后一页");
        } else {
          this.pageNumber = this.pageNumber + 1;
          this.advanceModel.offset = (this.pageNumber - 1) * this.eachPageCount;
          this.getRecommendMoreList();//获取职位推荐简历
        }
      }
    }


  }

  //获取推荐简历列表 offset自定义
  getRecommendMoreList() {
    this.opera.loading();//加载动画
    if (!this.moreSearch) {
      this.dataApi.getSearch(this.searchModel).then((res: any) => {
        this.opera.loadiss();//停止加载动画

        this.countList = res.count;
        this.pageCount = Math.ceil(res.count / this.eachPageCount);
        this.resumeList = res.list;

        for (let val of this.resumeList) {
          let arr = [];
          if (val.gender == null) {
            val.gender = "01";
          }
          val.yixiang = "";
          val.youshiArr = [];
          for (let item1 of val.pro_skills) {
            val.yixiang += item1.text + "    "
            if (item1.contrast_list) {
              for (let youshi of item1.contrast_list) {
                val.youshiArr.push(youshi.text);
              }
            }

          }
        }
        console.log("resumeList", res.list);
        this.checkAll();
      }).catch((err) => {
        this.opera.loadiss();//停止加载动画
        this.toastr.error(err)

        console.log(err);
      })
    } else {
      this.dataApi.getAdvancedSearch(this.advanceModel).then((res: any) => {
        this.opera.loadiss();//停止加载动画

        this.countList = res.count;
        this.pageCount = Math.ceil(res.count / this.eachPageCount);

        this.resumeList = res.list;

        for (let val of this.resumeList) {
          if(this.admin == 'tianjihr'){
            val.lastActive = this.activeTime(val._refresh_time);
          }
          let arr = [];
          if (val.gender == null) {
            val.gender = "01";
          }
          // val.pro_skills->text: "人力资源/人才服务"
          // contrast_list: [{code: "020205", text: "猎头"}]
          val.yixiang = "";
          val.youshiArr = [];
          for (let item1 of val.pro_skills) {
            val.yixiang += item1.text + "    "
            if (item1.contrast_list) {
              for (let youshi of item1.contrast_list) {
                val.youshiArr.push(youshi.text);
              }
            }
          }
        }
        this.checkAll();
      }).catch((err) => {
        console.log(err, err.Errcode);
        this.toastr.error(err)
        this.opera.loadiss();//停止加载动画
      })
    }
  }


  //获取推荐简历列表 offset=0
  getRecommendList() {
    this.pageNumber = 0;
    this.opera.loading();//加载动画
    if (!this.moreSearch) {
      this.searchModel.offset = 0;
      this.dataApi.getSearch(this.searchModel).then((res: any) => {
        this.opera.loadiss();//停止加载动画

        if (res) {
          this.countList = res.count;
          this.pageCount = Math.ceil(res.count / 10);
          this.resumeList = res.list;
          for (let val of this.resumeList) {
            
            let arr = [];
            val.selete = false;
            val.yixiang = "";
            val.youshiArr = [];
            for (let item1 of val.pro_skills) {
              val.yixiang += item1.text + "    "
              if (item1.contrast_list) {
                for (let youshi of item1.contrast_list) {
                  val.youshiArr.push(youshi.text);
                }
              }
             
            }
          
          }
        }

      }).catch((err) => {
        this.opera.loadiss();//停止加载动画
        console.log(err);
      })
    } else {
      this.advanceModel.offset = 0;
      this.dataApi.getAdvancedSearch(this.advanceModel).then((res: any) => {
        this.opera.loadiss();//停止加载动画

        if (res) {
          this.countList = res.count;
          this.pageCount = Math.ceil(res.count / 10);
          this.resumeList = res.list;
          for (let val of this.resumeList) {
            if(this.admin == 'tianjihr'){
              val.lastActive = this.activeTime(val._refresh_time);
            }
            val.selete = false;
            let arr = [];
            val.yixiang = "";
            val.youshiArr = [];
            for (let item1 of val.pro_skills) {
              val.yixiang += item1.text + "    "
              if (item1.contrast_list) {
                for (let youshi of item1.contrast_list) {
                  val.youshiArr.push(youshi.text);
                }
              }
            }
          }
        }

      }).catch((err) => {
        console.log(err);
        this.opera.loadiss();//停止加载动画
      })

    }
  }

  //获取高校字典
  getCollegeCode() {
    // 获取高校字典数据
    if (this.config.school.length < 1) {
      this.dataApi.getDictDate('school').then((res: any) => {
        // //console.log(res);
        if (res) {
          this.config.school = res;
        }
      }).catch((err) => {
        // this.toastr.error("网络错误,请稍后重试");
        //console.log(err);
      })
    }
  }

  //筛选
  goSearch(text) {
    this.model.college_name = text;
    this.getRecommendList();//获取简历列表
    this.keywordList = [];
  }

  //匹配关键字列表
  getItems() {
    this.keywordList = [];
    if (!this.model.college_name) { return; }
    for (let s of this.config.school) {
      if (s.text.indexOf(this.model.college_name) >= 0) {
        this.keywordList.push(s);
      }
    }
  }

  //监听键盘事件
  inputKeyup(event) {
    let that = this;
    var keycode = window.event ? event.keyCode : event.which;
    if (keycode == 13) {
      that.getRecommendList();
    } else {
      //that.getItems();
    }
  }

  inputKeyup1(event) {
    let that = this;
    var keycode = window.event ? event.keyCode : event.which;
    if (keycode == 13) {
      if (that.advanceModel.keywords == '') {
        that.toastr.warning('请填写关键词！');
        return;
      }
      that.advanceModel.positionId = '';
      that.advanceModel.post_type_text = '';
      that.advanceModel.post_type = '';
      that.advanceModel.education = '';
      that.advanceModel.major = ''
      that.advanceModel.native_place = '';
      that.advanceModel.provice = '';
      that.advanceModel.is_curr_session = '';
      that.advanceModel.gender = '';
      that.advanceModel.year = '';
      this.majorOne = '';
      that.isShowResult = true;
      that.opera.scrollTop1();//页面滚动函数
      that.getRecommendList();
    }
  }

  goSearchResult() {
    this.local.remove('searchPeopleText');
    if (this.advanceModel.keywords == '') {
      this.toastr.warning('请填写关键词！');
      return;
    }
    this.advanceModel.positionId = '';
    this.advanceModel.post_type_text = '';
    this.advanceModel.post_type = '';
    this.advanceModel.education = '';
    this.advanceModel.major = '';
    this.advanceModel.native_place = '';
    this.advanceModel.provice = '';
    this.advanceModel.is_curr_session = '';
    this.advanceModel.gender = '';
    this.advanceModel.year = '';
    this.majorOne = '';
    this.isShowResult = true;
    this.opera.scrollTop1();//页面滚动函数
    this.getRecommendList();
  }

  goSearchResult1(n) {
    this.advanceModel.post_type = n.post_type;
    this.advanceModel.post_type_text = n.post_type_text;
    this.advanceModel.positionId = n.positionid;
    this.advanceModel.keywords = '';
    this.advanceModel.education = '';
    this.advanceModel.major = '';
    this.advanceModel.native_place = '';
    this.advanceModel.provice = '';
    this.advanceModel.is_curr_session = '';
    this.advanceModel.gender = '';
    this.advanceModel.year = '';
    this.majorOne = '';
    this.isShowResult = true;
    this.opera.scrollTop1();//页面滚动函数
    this.getRecommendList();
  }


  //-------获取线上数据
  getNewList() {
    let model = {
      limit: 100,
      offset: 0,
      status: 'release',
    }
    this.opera.loading();
    this.dataApi.getPositionList(model).then((res: any) => {
      console.log("在期职位获取线上数据-----college-resume", res);
      if (res) {
        this.positionList = res.list;
        this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
      }

      this.opera.loadiss();
    }).catch((error) => {
      this.toastr.error(error);
      this.opera.loadiss();
    });
  }

  //-----跳转简历详情
  goDetails(n, is_ignore, index) {
    this.opera._hmtPush("搜索人才", "查看简历", "")
    let that = this;
    if (is_ignore == false) {
      n.isview_resume = true;
      that.scrolltool.searchPeople_cell_index = index;
      that.scrolltool.searchPeople_cell_scrollY = window.top.scrollY;
      console.log('去职位详情', index, window.top.scrollY)
      that.router.navigate(['home/college-resumedetails'], {
        queryParams: {
          resumeid: n.resume_number,
          positionid: this.model.positionid,
          match: n.matching
        }
      });
    }
  }
  clearType() {
    this.advanceModel.post_type_text = '';
    this.advanceModel.post_type = '';
    this.advanceModel.positionId = '';
    this.getRecommendList();
  }
  //立即沟通
  goChat(item, id) {
    if (!this.config.lastIdentity) {
      return;
    }
    this.showModel = false;
    if (this.config.lastIdentity.status == 2) {
      if (!item.apply_college_auth && !item.jm_username && !this.config.department.member_info) {
        this.toastr.warning('该学生暂不支持沟通，立即开通会员')
        this.showModel = false;
        setTimeout(() => {
          this.router.navigateByUrl("home/member-product-list"); // 会员页面
        }, 1500);
      } else {
        this.opera.goChat(item, id);
      }
    } else {
      let collegeList = []
      for (let i = 0; i < this.config.department.auth.length; i++) {
        if (this.config.department.auth[i].status == 2) {
          collegeList.push(this.config.department.auth[i].collegeid)
        }
      }
      if (collegeList.indexOf(item.collegeid) != -1) {
        this.opera.goChat(item, id);
      } else {
        if (this.config.lastIdentity.status == 1) {
          this.toastr.warning('认证审核中')
        } else if (this.config.lastIdentity.status == 3) {
          this.toastr.warning('认证失败，请查看原因并重新提交')
          this.showModel = false;
          setTimeout(() => {
            this.router.navigateByUrl("home/member-product-list"); // 会员页面
          }, 1500)
        } else {
          this.opera.remindCould()
        }
      }
    }
  }

  //收藏
  goCollect(val) {
    val = this.opera.manageResumeArchived(val);
  }



  // 根据学校获取学科
  getMajorList() {
    this.opera.loading();//加载动画
    this.dataApi.getagregationCount({}).then((res: any) => {
      if (res) {
        // console.log('我要的取数据',res.facetgroups);
        this.majorList = res.facetgroups;
        res.facetgroups.map((item) => {
          if (item.level == 1) {
            this.majorListOne.push(item)
          }
        })
        //this.majorListOne = res.facetgroups;
        this.getRecommendList();
      }


    }).catch((err) => {
      console.log(err);
      this.opera.loadiss();
    })
  }

  //获取一级专业列表
  getMajorListOne() {
    for (let n of this.majorList) {
      if (n.level == 2) {
        this.majorListOne.push(n);
      }
    }
  }

  //监听majorOne
  majorOneChange() {
    // this.obj.initSelect();
    this.majorListTwo = [];
    this.majorList.map((item, index) => {
      if (item.level == 2 && item.parentcode == this.majorOne) {
        item.id = index;
        item.name = item.text;
        this.majorListTwo.push(item)
      }
    });
    console.log('majorOneChange', this.majorOne)
    if (!this.majorOne) {
      this.advanceModel.major = '';
    }
    //this.advanceModel.major='';
    this.getRecommendList()
  }

  //职位选择
  positionChange() {
    console.log('advanceModel.positionId', this.advanceModel.positionId);
    if (this.advanceModel.positionId) {
      this.positionList.forEach((item, index) => {
        if (item.positionid == this.advanceModel.positionId) {
          this.advanceModel.post_type = item.post_type;
          this.advanceModel.post_type_text = item.post_type_text;
        }
      })
    } else {
      this.advanceModel.post_type = '';
      this.advanceModel.post_type_text = '';
      this.advanceModel.positionId = '';
    }

    this.getRecommendList();
  }

  // 提示先选择学校
  isShowOne() {
    /*    if(!this.advanceModel.collegeid){
          this.toastr.warning("请先选择筛选高校！");
          return;
        }*/
  }

  // 提示先选一级专业
  isShowTwo() {
    if (!this.majorOne) {
      this.toastr.warning("请先选择学科！");
      return;
    }
  }

  // 云认证
  remindCould(item) {
    console.log("点击了蒙版", this.config.lastIdentity)
    if (this.config.lastIdentity.status == 2) {
      if (!item.apply_college_auth && !item.jm_username && !this.config.department.member_info) {
        this.toastr.warning('该学生暂不支持沟通，立即开通会员')
        this.showModel = false;
        setTimeout(() => {
          this.router.navigateByUrl("home/member-product-list"); // 会员页面
        }, 1500);
      }
    } else {
      this.opera.remindCould();
    }
  }

  /**
   * 点击极速短信联系-弹出发送短信的弹窗
   */
  alertSengMSG(n, event) {
    event.stopPropagation();
    this.opera._hmtPush("搜索人才", "职位推送", "")
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {

        this.productManager.getProductUsableCount(this.config.sms_bulkinvitation, (count, isVip) => {
          if (count == 0 || !isVip) {
            // this.config.payVipHistoryPage = {
            //   page:'搜索人才',
            //   isVip:isVip,
            //   pro:'职位推送'
            // };
            // that.local.set("single_category_code", this.config.ProductListCode.sms_bulkinvitation_code);
            // that.router.navigate(['home/tool-campus']);
            this.product('sms_bulkinvitation', 'recharge');
          } else {
            this.sengMsgModel.initData(1, []);
            this.SengMsgModel.userName = n.talent_name;
            this.SengMsgModel.userPic = n.logo == null ? '' : n.logo;
            this.SengMsgModel.graduation_school = n.graduation_school;
            this.SengMsgModel.major_text = n.major_text;
            this.SengMsgModel.talentid = n.talentid;
            this.SengMsgModel.gender = n.gender;
            this.SengMsgModel.address = n.address_text;
            //this.SengMsgModel.positionid = this.advanceModel.positionid;
            this.modelShowSengMsg = true;
          }
        })

      } else {
        // that.opera.checkMemberAlertWithType("noneVip_sms_bulkinvitation")
        this.product('sms_bulkinvitation', 'recharge');
      }
    })
  }
  Modeldismiss(event) {
    this.modelShowSengMsg = false;
    this.config.batchList = [];
    this.useBatch = false;
    this.getDepartment();
  }

  // 切换批量邀投开关
  useBatchFn() {
    if (this.positionList.length == 0) {
      this.toastr.warning('发布职位后可使用批量职位推送')
      this.router.navigateByUrl('/home/position-manage');// 职位推送
      return
    }
    this.opera.checkMember((isPay) => {
      if (isPay) {
        this.router.navigateByUrl('/home/positionPushs');// 职位推送
      } else {
        //this.opera.checkMemberAlertWithType("noneVip_sms_bulkinvitation")
        this.product('sms_bulkinvitation', 'recharge');
      }
    })
  }

  // 本页全选
  selectAllFn() {
    console.log(this.resumeList);
    if (!this.selectAll) {
      for (let i of this.resumeList) {
        if (this.config.batchList.indexOf(i.talentid) == -1) {
          this.config.batchList.push(i.talentid);
        }
      }
      this.selectAll = true;
    } else {
      for (let i of this.resumeList) {
        this.config.batchList.splice(this.config.batchList.indexOf(i.talentid), 1);
      }
      this.selectAll = false;
    }
    console.log(this.config.batchList);
  }

  // 检测是否全选
  checkAll() {
    for (let i of this.resumeList) {
      if (this.config.batchList.indexOf(i.talentid) == -1) {
        this.selectAll = false;
        break;
      } else {
        this.selectAll = true;
      }
    }
    console.log(this.selectAll);
  }

  // 本页反选
  selectRevFn() {
    console.log(this.config.batchList);
    for (let i of this.resumeList) {
      if (this.config.batchList.indexOf(i.talentid) == -1) {
        this.config.batchList.push(i.talentid);
      } else {
        this.config.batchList.splice(this.config.batchList.indexOf(i.talentid), 1);
      }
    }
    this.checkAll();
  }


  // 活动通知包触发
  singleTips() {
    this.modelShowSengMsg = true;
    this.sengMsgModel.initData(2, this.config.batchList);
  }

  // 7/10 获取热门的省(有学生的省。高级筛选功能 )
  getHotProvice() {
    this.dataApi.getCollegeHotCityWithProvinceAndCity("?dict_type=native_place&parentcode=0").then((res: any) => {
      if (res) {
        this.HotCityAndProvinceArr = res;
        let Province = [];
        for (let index = 0; index < res.length; index++) {
          let item = res[index];
          if (item.parentcode == '0') {
            Province.push(item)
          }
        }
        this.hotProvince = Province;
        // console.log("获取热门城市getPrivince",res,Province);
      }
    }).catch((err) => {
      //console.log(err);
    })
  }
  seleteProvince() {
    console.log("省", this.advanceModel.provice)
    if (this.advanceModel.provice == "") {
      this.advanceModel.native_place = "";
      this.getRecommendList();
    } else {
      this.advanceModel.native_place = this.advanceModel.provice;
      this.getRecommendList();
    }
    let Province = [];
    for (let index = 0; index < this.HotCityAndProvinceArr.length; index++) {
      let item = this.HotCityAndProvinceArr[index];

      if (item.parentcode == this.advanceModel.provice) {
        Province.push(item)
      }

    }
    this.hotCity = Province;
  }
  seleteCity() {
    console.log("市", this.advanceModel.city)
    if (this.advanceModel.city == "") {
      this.advanceModel.native_place = "";
      this.getRecommendList();
    }
    let City = [];
    for (let index = 0; index < this.HotCityAndProvinceArr.length; index++) {
      let item = this.HotCityAndProvinceArr[index];

      if (item.parentcode == this.advanceModel.city) {
        City.push(item)
      }

    }
    this.hotArea = City;
  }

  closeModel() {
    this.showModel = false;
  }

  //显示手机号弹窗
  ShowPhoneModel(n, event) {
    event.stopPropagation();
    this.opera._hmtPush("搜索人才", "极速电话联系", "")
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        this.productManager.getProductUsableCount(this.config.voice_call, (count, isVip) => {
          if (count == 0 || !isVip) {
            // this.config.payVipHistoryPage = {
            //   page:'搜索人才',
            //   isVip:isVip,
            //   pro:'极速电话联系'
            // };
            // //去购买极速电话
            // that.local.set("single_category_code",this.phoneModel.jiduCode);
            // that.router.navigate(['home/tool-campus']);
            this.product('voice_call', 'recharge');
          }
          else {
            if (this.advanceModel.positionId) {
              n.positionid = this.advanceModel.positionId
            } else {
              n.positionid = "";
            }
            this.voiceCallModel.initData(n);
            this.isShowVoiceCallModel = true;
          }
        });
      } else {
        //that.opera.checkMemberAlertWithType("noneVip_voice_call")
        this.product('voice_call', 'recharge');
      }
    })
  }


  //获取企业信息
  getDepartment() {
    this.dataApi.getEmployment().then((res: any) => {//通过tooken获取企业信息
      console.log("企业信息线上数据", res);
      //console.log(res);
      if (res) {
        this.myDepartment = res;
        this.initPhoneData(this.myDepartment);
        this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
      }
    }).catch((error) => {
      this.toastr.error(error);
    });

    this.dataApi.getcomponentLeft({}).then((res: any) => {
      let arr = [];
      if (res) {
        res.map((item) => {
          if (item.alias == 'sms_bulkinvitation') {
            this.sms_bulkinvitation = item;
          }
          if (item.alias == 'voice_call') {
            this.voice_call = item;
          }
        })
      }

    }).catch((err) => {
      console.log(err);
    })
  }
  //处理初始化电话联系quota
  initPhoneData(deparment) {
    //我的电话剩余时间
    this.dataApi.getcomponentLeft({}).then((res: any) => {
      let arr = [];
      res.map((item) => {
        if (item.alias == this.config.voice_call) {
          this.phoneModel.MyPhoneTime = item.left;
        }
      })
    }).catch((err) => {
      console.log(err);
    })
  }
  dissMissPhone() {

  }
  //获取用户信息
  getUser() {

    this.dataApi.getUser().then((res: any) => {
      console.log("线上数据hr信息------------------------aboutsettinguser", res);
      //console.log(res);
      if (res) {

        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
        this.phoneModel.myPhone = res.mobile;
      }
    }).catch((error) => {
      this.toastr.error(error);
    });

  }
  updataMyPhone() {
    this.phoneModel.PhoneModelType = 0;
  }
  goBuy(n) {
    this.opera.checkMember((isPay) => {
      if (isPay) {
        if (n == 'voice_call') {
          this.config.userInitiativePayName = "voice_call"
          this.opera._hmtPush("搜索人才", "极速电话联系购买", "")
        } else {
          this.config.userInitiativePayName = "voice_call"
          this.opera._hmtPush("搜索人才", "职位推送购买", "")
        }
        this.productList.map((item, index) => {
          if (item.recharge_category == n) {
            this.local.set("recharge_category_code", JSON.stringify(item.code));
            // this.router.navigate(['home/buyProduct']);
            this.router.navigate(['home/tool-campus']);
            return
          }
        })
      } else {
        this.opera.checkMemberAlertWithType("noneVip_voice_call")
      }
    })
  }
  getProductList() {
    //product_category:"recharge",recharge_category:''
    this.opera.loading();
    this.dataApi.departmentList({ product_category: "recharge" }).then((res: any) => {
      this.opera.loadiss();
      if (res) {
        this.productList = res.list;
        for (let item of res.list) {
          if (item.recharge_category == "voice_call") {
            this.phoneModel.jiduCode = item.code;
          }
          if (item.recharge_category == "sms_bulkinvitation") {
            this.phoneModel.positionPushCode = item.code;
          }
        }
      }
    }).catch((err) => {
      console.log(err);
      this.opera.loadiss();
    })
  };
  initScrooll() {
    console.log('this.positionresumelistindex', this.positionresumelistindex)
    window.scrollTo(0, this.positionresumelistindex <= 3 ? 0 : this.scrollTop + (120 * this.positionresumelistindex));
  }
  initScroollZero() {
    this.positionresumelistindex = 0;
    window.scrollTo(0, 0);
  }
  AllSeleteClick() {
    this.isAllSelete = !this.isAllSelete;
    let arr = [];
    let idarr = [];
    this.resumeList.map((n) => {
      n.selete = this.isAllSelete;
      arr.push(n);
      if (n.selete) {
        idarr.push(n);
      }
    })
    this.resumeList = arr;
    this.downLoadArr = idarr;
  }
  //点击批量导出
  downLoadChips() {

    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        this.productManager.getProductUsableCount(this.config.sms_bulkinvitation, (count) => {
          if (count < this.downLoadArr.length) {
            this.toastr.warning('余额不足，推荐您购买')
            this.product('sms_bulkinvitation', 'recharge');
          } else {
            console.log('批量推送', this.downLoadArr)
            this.totalCoin = count;
            this.isShowPushModel = true;
          }
        })
      } else {
        this.product('sms_bulkinvitation', 'recharge');
      }
    })
  }
  cellClickDown(item, e) {
    e.stopPropagation();
    let arr = [];
    let isAll = true;
    this.resumeList.map((n) => {
      if (item.talentid == n.talentid) {
        n.selete = !n.selete;
      }
      if (!n.selete) {
        isAll = false;
      }
      arr.push(n);
    });
    let idarr = [];
    this.resumeList.map((e) => {
      if (e.selete) {
        idarr.push(e)
      }
    })
    this.resumeList = arr;
    this.downLoadArr = idarr;
    this.isAllSelete = isAll;
  }
  inviteStuAlert() {
    if (!this.pushModels.positionid) {
      this.toastr.warning('请选择职位')
      return;
    }
    if (this.downLoadArr.length == 0) {
      this.toastr.warning('请选择学生')
      return;
    }
    let arr = [];
    for (let n of this.downLoadArr) {
      arr.push(n.talentid)
    }
    this.dataApi.batchPositionPush(this.pushModels.positionid, { talentids: arr, invite_count: arr.length }).then((res: any) => {

      this.toastr.success('推送成功')
      this.downLoadArr = [];
      this.isAllSelete = false;
      this.isShowPushModel = false;
      let arr = [];
      this.resumeList.map((e) => {
        e.selete = false;
        arr.push(e)
      });
      this.resumeList = arr;
    }).catch((err) => {
      this.toastr.error(err)
    })
  }
  //获取短信模版
  getMSGContent() {
    this.dataApi.getMSGContent("邀投简历").then((res: any) => {
      // console.log('getMSGContent',res);
      this.pushModels.content = res.text;

    }).catch((err) => {
      // console.log('getMSGContenterr',err);
      this.toastr.error(err)
    })

  }
  closePushs() {
    this.isShowPushModel = false;
  }

  //极速电话联系弹窗消失
  voiceCallModelHidden(e) {
    this.isShowVoiceCallModel = false;
  }


  productModelHidden(event) {
    this.isproductShowModel = false;
  }

  //点击产品
  product(alias, type) {
    this.productModel.initData(alias, type);
    this.isproductShowModel = true;
  }

  // 09:35近3天  近一周  近1个月  近3个月  3个月以上
  activeTime(lastTime: string) {
    let l = moment(lastTime).format('YYYY-MM-DD');
    let data =  moment().diff(moment(l),'days');
    if(data <= 3){
      return '近 3 天';
    }
    if(data <= 7){
      return '近 1 周';
    }
    if(data <= 31){
      return '近 1 个月';
    }
    if(data <= 90){
      return '近 3 个月';
    }
    return '3 个月以上';
  }
}
