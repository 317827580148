import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {ModelPayWechatComponent} from "../model-pay-wechat/model-pay-wechat.component";
import {DepartmentSelfAlertComponent} from "../department-self-alert/department-self-alert.component";
import { MatDialog } from "@angular/material/dialog";
declare let window;
declare let pingpp;
declare let layui;
declare let Swiper:any;

@Component({
  selector: 'app-buy-pay-product',
  templateUrl: './buy-pay-product.component.html',
  styleUrls: ['./buy-pay-product.component.css']
})
export class BuyPayProductComponent implements OnInit {
  @ViewChild('payWechat') child: ModelPayWechatComponent;

  public config: Config;
  public  count = 1; //数量
  public sum = 0;
  public payList = [] //支付列表
  public cardList = [];
  public cardList1 = [];

  public productDetail:any;
  public productDetail_cost:0;

  public productPace = 0;
  public model={
    count:0,
    productid:0,
    coupon_receiveid:'',
  }
  public recharge_category = '';
  public recharge_category_code = '';

  public  myLaBi = 0;//我的啦豆
  public  currentCard = '';

  //是否是保险省以下的，如果是，弹窗校招顾问，屏蔽购买
  public  province_sign = "unmark";

  @Output() modelShow = false;//是否打开弹窗
  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public dialog: MatDialog,
  ) {
    this.config = Config.getInstance();//实例化config

  }

  ngOnInit() {
    //
    // if(!this.opera.isShowPaySuccessModule())
    // {
    //   this.router.navigateByUrl("/home/tool-campus");
    //   return;
    // }


    let topNav = this.local.get('nav_tool')
    this.opera.initConfig('tool',topNav);

    this.virtyCard(this.count)
    this.recharge_category_code = this.local.get("recharge_category_code");


    if(this.recharge_category_code)
    {
      this.recharge_category_code =  this.recharge_category_code.replace("\"","");
      this.recharge_category_code =  this.recharge_category_code.replace("\"","");
      console.log(" this.recharge_category_code", this.recharge_category_code);
      // this.getProductInfo();
      this.local.set('single_category_code',this.recharge_category_code)
      this.local.remove('recharge_order');
      this.local.remove('pay_recharge_order');
    }
    this.router.navigateByUrl("/home/tool-campus");
    //获取我的啦豆余额
    // this.getMyLaCoinCount();
    //
    // this.initswiper();
    // this.getDepartment();
  }
  getDepartment(){
    this.dataApi.getDepartment().then((res:any)=>{
      if(res)
      {
        this.province_sign =  res.province_sign;
        console.log('获取企业信息',res)
      }
    }).catch((error) => {
      //this.toastr.error(error);
    });
  }
  countBtnClick(type){
    this.count =parseInt(this.count.toString());
    if(type>0)
    {
      this.count+=1;
    }
    else
    {
      if(this.count==1)
      {
        return;
      }
      this.count-=1;
    }
    this.virtyCard(this.count);
  }

  //接收子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
    if(event){
      this.router.navigateByUrl("home/tool-campus"); // 会员
    }else{
      this.toastr.error("支付失败，请重试");
    }
  }
  //支付
  pay(){


    console.log("recharge_category",this.productDetail)
    if(this.productDetail.recharge_category=="voice_call" && this.province_sign=='lower')
    {
      this.toastr.error('系统暂不支持省分以下的保险公司购买');
      return;
    }

    let sum =  this.sum;
    let that = this;
    this.dataApi.getMyLaCoinCount({}).then((res:any)=>{
      console.log('获取我的啦豆余额',res);
      // if(res){
        this.myLaBi = res;

        if(this.myLaBi==0)
        {
          if(this.province_sign=='lower')
          {
            this.showBaoxianLowerModel();
            return;
          }
          console.log('this.currentCard',this.currentCard)
          this.toastr.error('您的啦豆余额不足');
          this.productDetail.count = this.count;
          this.productDetail.sum =sum;
          this.productDetail.productPace = this.productPace+this.productDetail.product_infos[0].unit;
          this.productDetail.coupon_receiveid = '';
          if(this.currentCard.length>0)
          {
            let card = this.cardList1[parseInt(this.currentCard)];
            this.productDetail.coupon_receiveid = card.receiveid;
          }
          this.local.set('pay_data',JSON.stringify(this.productDetail))
          setTimeout(()=>{
            that.router.navigate(['home/buyProductExtend'],);

          },800)
          return;
        }
        console.log('支付的金额',sum)
        if(sum>this.myLaBi)
        {
          if(this.province_sign=='lower')
          {
            this.showBaoxianLowerModel();
            return;
          }
          this.productDetail.count = this.count;
          this.productDetail.sum =sum;
          this.productDetail.productPace = this.productPace+this.productDetail.product_infos[0].unit;
          this.productDetail.coupon_receiveid = '';
          if(this.currentCard.length>0)
          {
            let card = this.cardList1[parseInt(this.currentCard)];
            this.productDetail.coupon_receiveid = card.receiveid;
          }

          this.local.set('pay_data', JSON.stringify(this.productDetail))
          this.toastr.error('您的啦豆余额不足')

          setTimeout(()=>{
            that.router.navigate(['home/buyProductExtend'],);
          },800)
          return;
        }
        if(this.count==0)
        {
          this.toastr.error('购买数量不能为0');
          return;
        }
        if(this.currentCard.length>0)
        {
          let card = this.cardList1[parseInt(this.currentCard)];
          this.model.coupon_receiveid = card.receiveid;
        }

        layui.use('layer', function(){
          var layer = layui.layer;
          layer.confirm('确定要购买吗？', {
            btn: ['确定','取消'] //按钮
          }, function(){

            that.model.count = that.count;
            that.opera.loading();
            console.log('支付',that.model);
            that.dataApi.buyOrder(that.model).then((res:any)=>{
              console.log('支付结果',res);
              that.opera.loadiss();
              // that.toastr.success('购买成功');
              layer.msg('购买成功', {icon: 1});

              setTimeout(()=>{
                that.router.navigateByUrl('home/tool-campus');
              },1000)

            }).catch((err)=>{
              console.log(err);
              layer.msg(err, {icon: 2});
              that.opera.loadiss();
            })

          }, function(){
            layer.msg('已取消购买', {icon: 2});
          });

        });



      // }
    }).catch((err)=>{
      console.log(err);
    })


  }
  seleteCardChange(){
    // console.log('卡劵选择变化',this.currentCard);
    // this.currentCard = '';
    let item = this.productPace;
    let  All = this.productPace * this.count;
    console.log('卡劵选择变化',this.currentCard,this.cardList1,All);

    if(this.currentCard.length>0)
    {

      let card = this.cardList1[parseInt(this.currentCard)];

      if(All<card.limited_amount)
      {
         // console.log('选择了不符合的卡劵')
        this.toastr.error('卡劵不符合');
        let that = this;
        setTimeout(()=>{
          that.currentCard = '';
          // that.initswiper();

        },500)
      }
    }
  }
  countLabi(){
    let item = this.productPace;
    let  All = this.productDetail_cost * this.count;
    // console.log('实际支付金额',this.currentCard,All,this.cardList1)
    //计算符合的卡劵
    if(this.currentCard.length>0)
    {
      let card = this.cardList1[parseInt(this.currentCard)];
      if(All>=card.limited_amount)
      {
        All =  All-card.gift_amount;
      }
    }
    this.sum = All;
    return All
  }
  youhui(){
    if(this.currentCard.length>0)
    {
       let card =this.cardList1[parseInt(this.currentCard)] ;
      return card.gift_amount
    }
    else
    {
      return 0;
    }
  }
  inputChange(e){
    // console.log('输入框变化',this.count)
    this.virtyCard(e)
  }
  //验证卡劵是否可用。并选择最适合的卡劵
  virtyCard(count){
    let item = this.productPace;
    let  All = this.productDetail_cost * count;

    //是否能用卡券
    let isUse = true;
    if(this.currentCard.length>0)
    {
      let card = this.cardList1[parseInt(this.currentCard)];

      if(All<card.full)
      {
        isUse = false;
      }
    }
    //如果不能用/卡劵置空
    if(!isUse)
    {
      let that = this;
      that.currentCard = ''
    }
    //遍历卡劵当总金额大于卡劵的满减最大额度，选择卡劵
    let newArr = [];
    for (let index=0;index<this.cardList.length;index++)
    {
      let card = this.cardList[index];
      if(card.limited_amount <=All)
      {
        card.space =  (card.gift_amount/card.limited_amount)*1000000;
        newArr.push(card);
      }
    }
    let indexArr = this.bubbleSort(newArr);
    let newindexArr = [];

    for (let index = 0;index<indexArr.length;index++)
    {
      let card = indexArr[index];
      card.index = index;
      newindexArr.push(card);
      if(index==indexArr.length-1)
      {
        this.currentCard = index+'';

        console.log('当前性价比最高的卡劵',card,index)
      }
    }
    console.log('合适的卡劵',newindexArr,this.cardList ,All)
    this.cardList1 = newindexArr;
    if(this.cardList1.length==0)
    {
      this.currentCard = '';
    }

    this.initswiper();

  }
  getProductInfo(){

    this.dataApi.departmentCode(this.recharge_category_code).then((res:any)=>{
      if(res){
        console.log('获取产品详情',res)
        this.productDetail = res;
        this.productDetail_cost =this.productDetail.price;
        this.productPace = res.product_infos[0].count;
        this.model.productid = res.productid;
        // this.getMyCardList(res.code);
        this.recharge_category = res.recharge_category;
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  getMyLaCoinCount(){
    this.dataApi.getMyLaCoinCount({}).then((res:any)=>{
      console.log('获取我的啦豆余额',res);

      if(res){
        this.myLaBi = res;
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  //获取我的卡劵列表
  getMyCardList(code){
    this.dataApi.getMyCardList({coupon_category:'full_cut_coupon',product_code:code}).then((res:any)=>{
      console.log('获取我的卡劵列表',res);
      if(res){
        let arr = [];
        for (let index=0;index< res.list.length;index++)
        {
          let card  = res.list[index];
          card.title = '满'+card.limited_amount+'减'+card.gift_amount;
          card.index = index;
          arr.push(card);
        }
        this.cardList = arr;
        var copyList = JSON.parse(JSON.stringify(arr))
        this.cardList1 = arr;
        this.initswiper();
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  // 冒泡排序
  bubbleSort(arr) {
    var len = arr.length;
    for ( let j = 0; j < len - 1; j++) {
      for ( let i = 0; i < len - 1 - j; i++)
      {
        let a = arr[i];
        let b = arr[i + 1];
        if (a.space> b.space ) {
          let  temp = arr[i];
          arr[i] = arr[i + 1];
          arr[i + 1] = temp;
        }
      }

    }
    console.log("冒泡",arr)
    return arr;
  }
  //购买说明
  buyExplainClick(){
    this.router.navigateByUrl('home/buyExplain');

  }

  initswiper(){

    let that = this;
    let mySwiper = new Swiper('.swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 0,
       freeMode: true,
      // grabCursor: true,
      // centeredSlides : false,
       observer:true,//修改swiper自己或子元素时，自动初始化swiper
       observeParents:false,//修改swiper的父元素时，自动初始化swiper

       watchSlidesVisibility : true,
      // slidesOffsetBefore : 100,

    });

  }
  cardRowClick(n){
    this.currentCard = n.index+"";

    this.seleteCardChange();
  }
  buyLadou(){

    this.dialog.open(DepartmentSelfAlertComponent, {
    }).afterClosed().subscribe((isConfirmed) => {
    });


  }
  //增值服务协议
  zengzhi() {
    this.router.navigateByUrl("home/zengzhi"); // 会员
  }
  showBaoxianLowerModel(){
    console.log('showBaoxianLowerModel');
     this.dialog.open(DepartmentSelfAlertComponent, {
          data: {title:"请联系校招顾问购买",}
        }).afterClosed().subscribe((isConfirmed) => {
        });
  }

}

