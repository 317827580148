import {Injectable} from '@angular/core';
import { Events } from '../provider/eventService';
import { Config } from './config';
import { DataApi } from './dataApi';
import { ToastrService } from 'ngx-toastr';
declare var xiaomiIM;

@Injectable()
export class MyMMessage {
  public config : Config;
  static instance: MyMMessage;
  lastMessageTs : any;
  messageCount = 10;
  constructor(public toastr: ToastrService,
    private dataApi:DataApi,
    public events: Events) {
      MyMMessage.instance = this;
      this.config = Config.getInstance();
  }

  /*
   * IM初始化 注册监听 小米IM登录
   * @param appAccount 登录账号
   */
  init(appAccount : any){
    console.log("登陆mimc账号："+appAccount);

    let serviceParams = {
       "url" : this.config.api + "/api/mimc/token",
       "client_id" : this.config.clientId,
       "access_token" : this.config.token,
       "accountid" : this.config.accountid
    };
     console.log("登陆mimc账号：",serviceParams);

    xiaomiIM.init(appAccount,serviceParams);
    xiaomiIM.addStatusListener(this.statusChangeListener);//登陆结果监听
    xiaomiIM.addReceiveMsgListener(this.receiveMsgListener);//接收消息监听
    xiaomiIM.addServerAckListener(this.serverAckListener);//发送消息监听
    xiaomiIM.addDisconnListener(this.disconnectListener);//断开连接监听
    xiaomiIM.login();
  }


  // 获取对话列表
  getDialogList(){
    xiaomiIM.getDialogList().then(res=>{
      console.log("getDialogList");
      console.log(res);
      let list = new Array();
      if(res.data.length > 0){
          var disMsg = new Base64();
          let dateTime;
          for(let i = 0;i < res.data.length;i++){
              var date = new Date(parseInt(res.data[i].timestamp));
              dateTime = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
              var extraOne=res.data[i].extra?JSON.parse(res.data[i].extra):{"oaid":'',"is_top":false,"unread_count":0,"inappropriate":false,"talent":{gender:'01'}};
              let model = {
                  "toUser" : res.data[i].name,
                  "last_message" : JSON.parse(disMsg.decode(res.data[i].lastMessage.payload)).content,
                  "last_message_type" :res.data[i].lastMessage.bizType,
                  "dateTime" : dateTime,
                  "extra" : JSON.parse(res.data[i].extra),
                  "oaid" :extraOne.oaid,
                  "is_top" :extraOne.is_top,
                  "unread_count" :extraOne.unread_count,
                  "inappropriate" :extraOne.inappropriate,
                  "talent" :extraOne.talent,
              };
              list.push(model);
          }
          //console.log(list);
          // return list;
      }

      MyMMessage.instance.events.publish("MiTo:list",list);
    },(error)=>{
        console.log(error);
    })
  }

  /**
   * 获取历史消息
   * 指的是拉取从指定的时间戳utcToTime(不包含utcToTime)向前limit条的A与B之间的聊天记录。
   * @param toUser 对方用户名
   * @param uctime 时间戳
   */
  async getMessageList(toUser,limit,uctime){
    console.log("获取聊天记录------myMM",toUser,limit,uctime);
    console.log(toUser);
    console.log(uctime);
    let res = await xiaomiIM.getMessageList(toUser,limit,uctime);

    console.log('获取聊天记录解析前',res);

    var list = new Array();
    if(res.code==200){
        if(res.data.messages.length > 0){
            var disMsg = new Base64();
            let content,dateTime;
            for(let i = 0;i < res.data.messages.length;i++){
                try{
                   //消息内容处理
                    content = JSON.parse(disMsg.decode(res.data.messages[i].payload)).content;
                    //发送时间处理
                    var date = new Date(parseInt(res.data.messages[i].ts));
                    let m = (date.getMonth() + 1)>9?(date.getMonth() + 1):'0'+(date.getMonth() + 1);
                    let d = date.getDate()>9?date.getDate():'0'+date.getDate();
                    let h = date.getHours()>9?date.getHours():'0'+date.getHours();
                    let mi = date.getMinutes()>9?date.getMinutes():'0'+date.getMinutes();
                    let s = date.getSeconds()>9?date.getSeconds():'0'+date.getSeconds();
                    dateTime = date.getFullYear() + "-" + m + "-" + d + "T" + h + ":" + mi + ":" + s +'+08:00';
                    //应用内接收消息结构
                    let model = {
                        "type" : res.data.messages[i].bizType,      //消息类型
                        "content" : content,                        //消息内容
                        "from" : res.data.messages[i].fromAccount,  //发消息者
                        "createTime" : dateTime,                    //发送时间
                    };
                    list.push(model);
                }catch(err) {
                    break;
                }

            }
        }else{
            console.log("没有历史消息",res.data.messages);
        }
    }
    console.log("获取到聊天记录myMM",list);
    return list;
  }

  //登录状态监听
  statusChangeListener(bindResult, errType, errReason, errDesc) {
      if (bindResult) {
          console.log("登陆成功--------myMM");
        //   alert("MIMC登陆成功");
        MyMMessage.instance.events.publish("MIMCloginSuccess");

      } else {
        console.log("登陆失败--------myMM");
        console.log("login failed.errReason=" + errReason + ",errDesc=" + errDesc + ",errType=" + errType);
      }
  }

  /**
   * 消息接收监听
   * @param message
   */
  receiveMsgListener(message) {
      console.log("收到新消息myMM");
      console.log(message);
      var date = new Date(parseInt(message.getTimeStamp()));
      let m = (date.getMonth() + 1)>9?(date.getMonth() + 1):'0'+(date.getMonth() + 1);
      let d = date.getDate()>9?date.getDate():'0'+date.getDate();
      let h = date.getHours()>9?date.getHours():'0'+date.getHours();
      let mi = date.getMinutes()>9?date.getMinutes():'0'+date.getMinutes();
      let s = date.getSeconds()>9?date.getSeconds():'0'+date.getSeconds();
      let msgDate = date.getFullYear() + "-" + m + "-" + d + "T" + h + ":" + mi + ":" + s +'+08:00';
      let model = {
          "type" : message.getBizType(),                          //消息类型
          "content" : JSON.parse(message.getPayload()).content,   //消息内容
          "from" : message.getFromAccount(),                      //发送者
          "to"   : message.getToAccount(),                        //接收者
          "createTime" : msgDate                                  //消息时间
      };
      MyMMessage.instance.events.publish("newMM:tabs",model);
  }

  //发送消息后，服务器接收到消息ack的回调
  serverAckListener(packetId, sequence, timeStamp, errMsg) {
    //   console.log("receive msg ack:" + packetId + ",sequence=" + sequence + ",ts=" + timeStamp);
      return;
  }

  //断开连接
  disconnectListener() {
      console.log("MIMC断开连接");
      xiaomiIM.login();
  }

  /**
   * 发送消息
   * @param toUser 对方名称
   * @param message 消息体
   */
  send(toUser,msgType,message){
    return new Promise((resolve, reject) => {
        xiaomiIM.sendMsg(toUser,msgType,message).then(res => {//发送成功
            console.log("mimc消息发送成功：",res,toUser);
            // resolve(res);
        },error=>{//发送失败
            console.log("mimc消息发送失败");
            console.log(error);
            if(error=='user not login'){
              xiaomiIM.login();
              MyMMessage.instance.toastr.error("消息发送失败");
            }
        });
    });
  }

  //更新extra（自定义参数）
  updateExtra(toUser,extra){
      xiaomiIM.updateDialogExtra(toUser,extra).then(res => {
          console.log(res);
      },error=>{//发送失败
          console.log(error);
      });
  }

  //退出
  logout(){
      xiaomiIM.logout();
  }

  //拉黑
  isInBlackList(toUser){
      xiaomiIM.isInBlackList(toUser).then(res => {
          console.log(res);
      },error=>{
          console.log(error);
      });
  }
}
//加密
var Base64 = function() {
  var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  this.encode = function(input) {
      var output = "";
      var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
      var i = 0;
      input = _utf8_encode(input);
      while (i < input.length) {
          chr1 = input.charCodeAt(i++);
          chr2 = input.charCodeAt(i++);
          chr3 = input.charCodeAt(i++);
          enc1 = chr1 >> 2;
          enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
          enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
          enc4 = chr3 & 63;
          if (isNaN(chr2)) {
              enc3 = enc4 = 64;
          } else if (isNaN(chr3)) {
              enc4 = 64;
          }
          output = output +
              _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
              _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
      }
      return output;
  };

  this.decode = function(input) {
      var output = "";
      var chr1, chr2, chr3;
      var enc1, enc2, enc3, enc4;
      var i = 0;
      input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
      while (i < input.length) {
          enc1 = _keyStr.indexOf(input.charAt(i++));
          enc2 = _keyStr.indexOf(input.charAt(i++));
          enc3 = _keyStr.indexOf(input.charAt(i++));
          enc4 = _keyStr.indexOf(input.charAt(i++));
          chr1 = (enc1 << 2) | (enc2 >> 4);
          chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
          chr3 = ((enc3 & 3) << 6) | enc4;
          output = output + String.fromCharCode(chr1);
          if (enc3 != 64) {
              output = output + String.fromCharCode(chr2);
          }
          if (enc4 != 64) {
              output = output + String.fromCharCode(chr3);
          }
      }
      output = _utf8_decode(output);
      return output;
  };

  var _utf8_encode = function(string) {
      string = string.replace(/\r\n/g, "\n");
      var utftext = "";
      for (var n = 0; n < string.length; n++) {
          var c = string.charCodeAt(n);
          if (c < 128) {
              utftext += String.fromCharCode(c);
          } else if ((c > 127) && (c < 2048)) {
              utftext += String.fromCharCode((c >> 6) | 192);
              utftext += String.fromCharCode((c & 63) | 128);
          } else {
              utftext += String.fromCharCode((c >> 12) | 224);
              utftext += String.fromCharCode(((c >> 6) & 63) | 128);
              utftext += String.fromCharCode((c & 63) | 128);
          }

      }
      return utftext;
  };

  var _utf8_decode = function(utftext) {
      var string = "";
      var i = 0;
      var c = 0;
      var c2 = 0,c3 = 0;
      while (i < utftext.length) {
          c = utftext.charCodeAt(i);
          if (c < 128) {
              string += String.fromCharCode(c);
              i++;
          } else if ((c > 191) && (c < 224)) {
              c2 = utftext.charCodeAt(i + 1);
              string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
              i += 2;
          } else {
              c2 = utftext.charCodeAt(i + 1);
              c3 = utftext.charCodeAt(i + 2);
              string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
              i += 3;
          }
      }
      return string;
  }

}
