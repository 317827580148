import { Component, OnInit } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute,Router } from  '@angular/router';
@Component({
  selector: 'app-position-rules-editor',
  templateUrl: './position-rules-editor.component.html',
  styleUrls: ['./position-rules-editor.component.css']
})
export class PositionRulesEditorComponent implements OnInit {
  public config: Config;
  public publishTitle="发布招聘简章";
  public myDepartment:any;//企业信息
  public model={
    name:'',//标题
    content:'',//说明
  }
  public toolConfig: any;//编辑器配置
  public briefDetais:any;//详情
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();
      this.toolConfig= {//编辑器配置
        // toolbars: [
        //   [
        //       'drafts', // 从草稿箱加载
        //       'charts', // 图表
        //   ]
        // ],
        elementPathEnabled: false, //删除元素路径
        initialFrameHeight: 500
      };
    }

  ngOnInit() {
    this.opera.initConfig('position','position-rules');
    if(this.local.get(Constants.briefDetais)){
       this.briefDetais=JSON.parse(this.local.get(Constants.briefDetais));
       this.model.name=this.briefDetais.name;
       this.model.content=this.briefDetais.content;
       this.publishTitle="修改招聘简章";
    }
    this.getDepartment();//获取企业信息
  }
  //获取企业信息
  getDepartment(){
    if(this.dataApi.getLocalDepartment()){
          this.myDepartment = this.dataApi.getLocalDepartment();
        }else{
          this.dataApi.getDepartment().then((res:any)=>{
          this.dataApi.setLocalDepartment(res);
        }).catch((error) => {
          //console.log(error);
          this.toastr.error(error);
      });
      }
  }
  //提交
  goSubmit(){
    console.log(this.model);
    if(!this.model.name){
       this.toastr.warning("招聘简章标题为必填项");
       return;
    }
    if(!this.model.content){
      this.toastr.warning("招聘简章内容为必填项");
      return;
    }
    this.opera.loading();
    if(this.briefDetais){
      this.dataApi.editorRules(this.briefDetais.briefid,this.model).then((res:any)=>{
        this.toastr.success("修改成功");
        this.router.navigateByUrl('/home/position-rules');
        this.local.remove(Constants.briefDetais);
        this.opera.loadiss();
      }).catch((error) => {
        this.toastr.error(error);
        this.opera.loadiss();
      });
    }else{
      this.dataApi.creatRules(this.model).then((res:any)=>{
        this.toastr.success("发布成功");
        this.router.navigateByUrl('/home/position-rules');
        this.opera.loadiss();
      }).catch((error) => {
        this.toastr.error(error);
        this.opera.loadiss();
      });
    }

  }
}
