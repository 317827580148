import {Component, OnInit, Input, Output, ViewChild} from '@angular/core';
import { DataApi,Local,Opera,Constants,Config } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {NavigationEnd, Router} from '@angular/router';
import {ModelSendMsgComponent} from "../model-send-msg/model-send-msg.component";
import {filter} from "rxjs/operators";
import {scrollTool} from "../../provider/scrollTool";
import {NoneVipAlertComponent} from "../none-vip-alert/none-vip-alert.component";
import { MatDialog } from "@angular/material/dialog";
import { Events } from '../../provider/eventService';
import {regexManager} from "../../provider/regexManager";
import {productManager} from "../../provider/productManager";
import {VoiceCallModelComponent} from "../voice-call-model/voice-call-model.component";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
declare let laydate;

@Component({
  selector: 'app-chat-collection',
  templateUrl: './chat-collection.component.html',
  styleUrls: ['./chat-collection.component.css']
})
export class ChatCollectionComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true }) 
  productModel: ProductModelComponent;

  @ViewChild('sengMsgModel', { static: true }) sengMsgModel: ModelSendMsgComponent;
  @Output() isShowVoiceCallModel = false;//是否打开弹窗(简历收件箱)
  @ViewChild('voiceCallModel', { static: true }) voiceCallModel: VoiceCallModelComponent;

  public config: Config;
  public keywordList=[];//搜索关键词匹配列表
  public educationList=[];//学历列表
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  public positionList=[];//二级专业列表
  public majorOne='';//专业类别一级
  public resumeList = [];//简历列表
  public countList = 0;//列表数量
  public sms_bulkinvitation = null;
  public voice_call = null;
  public  productList = [];
  public resumeDetails:any;
  public myDepartment:any;//当前登录的企业信息(department)
  public phoneModel={
    myPhone:'', //企业拔打电话的手机
    myTel:'',
    myTel_qu:'',
    isOkmyPhone:false,
    NetPhone:'',
    positionid:'',
    getPhoneType:'1',
    PhoneModelType: 0,
    MyPhoneTime:0,
    isShowPhoneModel:false,
    jiduCode:'',
    positionPushCode:'',
  }
  public sendMsgArr = [];//批量摇头的用户列表
  public useBatch: boolean = false;  // 显隐选择按钮
  public selectAll: boolean = false; // 全选是否
  public eachPageCount = 10;//每页显示数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math
  @Output() modelShowSengMsg = false;//是否打开单品发送极速短信
  @Output() SengMsgModel ={
    userName:'',
    userPic:'',
    talentid:'',
    graduation_school:'',//学校
    major_text:'',//专业
    gender:'',
    address:''
  };//打开单品发送极速短信用户对象
  public model ={
    major:'',
    education:'',
    position_name:'',
    positionid:'',
    limit:10,
    offset:0
  };
  public islocalModel = false;

  public isShowPositionPushModel = false; // 是否显示一键职位推送弹窗
  public positionPushModel = {
    startTime:'',
    entTime:'',
    seletePosition:null,
    positionid:'',
    department:null,
    invalCount:0,
    content:'',
    allCount:0,
    fuhecount:0,
    phone:'',
  }

  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public dialog: MatDialog,
    public opera:Opera,
    public toastr: ToastrService,
     public scrolltool:scrollTool,
     public events : Events,
     public regexManager:regexManager,
    public productManager:productManager
  ) {
      this.config = Config.getInstance();//实例化config
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event:NavigationEnd) => {
        if (event.url === '/home/chat-collection') {
          /*在这写需要执行初始化的方法*/
          this.opera.initConfig('home','chat-collection');
          console.log('对我感兴趣constructor');
        }
      });
    this.scrolltool.initScrooll_cell_With_scrollY(this.scrolltool.chat_collection_cell_scrollY);
  }

  ngOnInit() {
    if(this.local.get('eachPageCount')){
      this.eachPageCount = parseInt(this.local.get('eachPageCount'));
    }
    this.model.limit = this.eachPageCount;

    
    this.getDepartment();//获取企业信息
    this.getMajorList();//获取专业列表信息
    this.getNewList();//获取职位列表信息
    this.getEducationList();//获取学历列表信息
    this.getProductList();

    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.getPositionViewHistory();//获取简历列表
      } else {
      }
    },false)


    this.getMSGContent()
    this.getMySmsCount()

    let modelStr = this.local.get("chat-collection-model");
    if(modelStr) {
      let that = this;
      let newModel = JSON.parse(modelStr);
      this.islocalModel = true;
      setTimeout(()=>{
        that.model = newModel;
        that.pageNumber =  (that.model.offset/10)+1;
        that.getPositionViewHistory();//获取简历列表
      },250)

    }

    let nowDate = new Date()
    let year = nowDate.getFullYear()
    let getMonth = nowDate.getMonth()+1
    let day = nowDate.getDate()
    this.positionPushModel.entTime = year+"-"+getMonth+'-'+ day
   let timestamp = nowDate.getTime();
    let lastDate = new Date(timestamp - 7 * 24 * 3600 * 1000);
    let getMonth2 = lastDate.getMonth()+1
    let day2 = lastDate.getDate()
    this.positionPushModel.startTime = lastDate.getFullYear()+"-"+getMonth2+'-'+ day2

  }
  gotoBuyPhoneTime(){
    this.phoneModel.isShowPhoneModel = false;
    let that = this;
    setTimeout(()=>{
      //去购买极速电话
      that.local.set("recharge_category_code", JSON.stringify(this.phoneModel.jiduCode));
      that.router.navigate(['home/buyProduct']);
    },600)
  }
  updataMyPhone(){
    this.phoneModel.PhoneModelType = 0;
  }
  //获取企业信息
  getDepartment(){
    this.dataApi.getcomponentLeft({}).then((res:any)=>{
      let arr = [];
      res.map((item)=>{
        if(item.alias ==  'sms_bulkinvitation') {
          this.sms_bulkinvitation = item;
        }
        if(item.alias ==  'voice_call') {
          this.voice_call = item;
        }
        if(item.alias == this.config.voice_call) {
          this.phoneModel.MyPhoneTime = item.left;
        }
      })
    }).catch((err)=>{
      console.log(err);
    })
  }

  goBuy(n){
    this.productList.map((item,index)=>{
      if(item.recharge_category == n){
        this.local.set("recharge_category_code", JSON.stringify(item.code));
        this.router.navigate(['home/buyProduct']);
        return
      }
    })
  }
  getProductList(){
    this.opera.loading();
    this.dataApi.departmentList({product_category:"recharge"}).then((res:any)=>{
      this.opera.loadiss();
      if(res){
        this.productList = res.list;
        for (let item of res.list) {
          if(item.recharge_category == "voice_call") {
            this.phoneModel.jiduCode = item.code;
          }
          if(item.recharge_category == "sms_bulkinvitation") {
            this.phoneModel.positionPushCode = item.code;
          }
        }
      }
    }).catch((err)=>{
      console.log(err);
      this.opera.loadiss();
    })
  };
  //-------获取线上数据
  getNewList(){
    let model={
      limit:1000,
      offset:0,
      status:'release',
    }
    this.opera.loading();
    this.dataApi.getPositionList(model).then((res:any)=>{
      console.log("在期职位获取线上数据-----college-resume",res);
      this.opera.loadiss();
      if(res) {
        this.positionList =  res.list;
      }
    }).catch((error) => {
      this.toastr.error(error);
      this.opera.loadiss();
    });
  }
  //获取分页子组件返回数据
  getChildEvent(index){
    console.log('子组件返回分页数-----college-resume',index,this.pageNumber,this.islocalModel);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*this.eachPageCount;

      this.getPositionViewHistory();//获取简历列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*this.eachPageCount;
        this.getPositionViewHistory();//获取简历列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*this.eachPageCount;
        this.getPositionViewHistory();//获取简历列表
      }
    }
  }

  //修改每页显示的条数
  getpageCount(event){
    this.local.set('eachPageCount',event)
    console.log(event);
    this.model.offset = 0;
    this.model.limit = event;
    if(this.pageNumber>1){
      this.getChildEvent(1);
    }else{
      this.getPositionViewHistory();//获取简历投递列表
    }    
  }

  //-----跳转简历详情
  goDetails(n,index){
    this.local.set("chat-collection-model",JSON.stringify(this.model))
    let that = this;
    that.config.isNavActive='college';
    that.config.isNavChildActive='chat-collection';
    that.scrolltool.chat_collection_cell_index = index;
    that.scrolltool.chat_collection_cell_scrollY = window.top.scrollY;
    that.opera._hmtPush('对我感兴趣','查看简历','')
    that.router.navigate(['home/college-resumedetails'], {
      queryParams: {
        resumeid:n.resume_number
      }
    });
  }

  //立即沟通
  goChat(n){
    this.opera.goChat(n,0);
  }

  //收藏
  goCollect(val){
    val = this.opera.manageResumeArchived(val);
  }

  //对我感兴趣
  getPositionViewHistory(){
    this.opera.loading();
    let that = this;
    this.dataApi.getPositionViewHistory(this.model).then((res:any)=>{
      this.opera.loadiss();
      if(res) {
        this.resumeList =  res.list;
        this.countList = res.count;
        this.pageCount =Math.ceil(res.count/this.eachPageCount);
        that.pageNumber = (this.model.offset/this.eachPageCount)+1
        this.events.publish('rushWorkspaceResumeNot'); //发送刷新首页看板的数据
        // this.local.set("chat-collection-model",JSON.stringify(this.model))
        console.log("对我感兴趣简历列表",res.list,this.model);
      }

    }).catch((error) => {
      //console.log(error);
      this.resumeList = [];
      this.opera.loadiss();
      this.toastr.error(error);
    });
  }

   //获取专业列表信息
   getMajorList(){
    if(this.dataApi.getMajorCode()){
      this.config.major = this.dataApi.getMajorCode();
      this.getMajorListOne();//获取一级专业列表
    }else{
      this.dataApi.getDictDate('major').then((res:any)=>{
        ////console.log("获取专业列表-----college-resume");
        ////console.log(res);
        if(res)
        {
          this.config.major = res;
          this.getMajorListOne();//获取一级专业列表
          this.dataApi.setMajorCode(res);
        }

      }).catch((error)=>{
        //console.log(error);
      });
    }
  }


  //获取一级专业列表
  getMajorListOne(){
    for(let n of this.config.major){
      if(n.level==2){
        this.majorListOne.push(n);
      }
    }
  }

   //监听majorOne
   majorOneChange(){
    // ////console.log("第一级专业值");
    // ////console.log(this.majorOne);
    // ////console.log("专业列表");
    // ////console.log(this.config.major);
    this.majorListTwo=[];
    for(let n of this.config.major){
      if(n.parentcode==this.majorOne){
        this.majorListTwo.push(n);
      }
    }
    this.model.major='';
    // ////console.log("第二级专业列表");
    // ////console.log(this.majorListTwo);
  }


  //提示先选一级专业
isShowTwo(){
  if(!this.majorOne){
    this.toastr.warning("请先选择一级专业");
    return;
  }
 }
  //显示手机号弹窗
  ShowPhoneModel(n,event){
    event.stopPropagation();
    this.opera._hmtPush('对我感兴趣','极速电话联系','')


    let that = this;
    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.productManager.getProductUsableCount(this.config.voice_call,(count,isVip)=>{
          if(count==0||!isVip) {
            // this.config.payVipHistoryPage = {
            //   page:'对我感兴趣',
            //   isVip:isVip,
            //   pro:'极速电话联系'
            // };
            // //去购买极速电话
            // that.local.set("recharge_category_code", JSON.stringify(this.phoneModel.jiduCode));
            // that.router.navigate(['home/buyProduct']);
            this.product('voice_call','recharge');
          } else {
            if(this.model.positionid){
              n.positionid = this.model.positionid
            }else {
              n.positionid = "";
            }
            this.voiceCallModel.initData(n);
            this.isShowVoiceCallModel = true;
          }
        });
      } else {
        //that.opera.checkMemberAlertWithType("noneVip_voice_call")
        this.product('voice_call','recharge');
      }
    })
  }
 //搜索
goSearch(data){
  this.model.position_name=data.name;
  this.model.positionid=data.id;
  this.model.offset = 0;
  this.getPositionViewHistory();//简历列表
  this.keywordList=[];
}
//输入职位名称搜索
goSearchName(){
  this.model.positionid='';
  this.getPositionViewHistory();//简历列表
  this.model.offset = 0;
  this.keywordList=[];
}

  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      that.goSearch(that.model.position_name);
    }else{
      that.getItems();
    }
  }

  //筛选函数
  getItems() {
    let filter=/^[a-zA-Z\u4e00-\u9fa5]+$/;
    if(this.model.position_name){
      if(filter.test(this.model.position_name)){
     let model={
       name:this.model.position_name,
       level:2
     }
      // this.isSearch = true;
      this.dataApi.positionNameMatch(this.model.position_name).then((res:any)=>{

        if(res)
        {
          this.keywordList =res;

        }
     }).catch((err)=>{
       ////console.log(err);
     });
   }else{
     let n=String(this.model.position_name).length-1;
     this.model.position_name=this.model.position_name.slice(0,n);
   }
 }else{
     this.keywordList = [];
    //  this.isSearch = false;
   }
 }

 //获取学历列表信息
 getEducationList(){
  if(this.dataApi.getEducationCode()){
    ////console.log("获取学历列表本地存储-----college-resume");
    ////console.log(this.dataApi.getEducationCode());
    this.educationList = this.dataApi.getEducationCode();
  }else{
    this.dataApi.getDictDate('education').then((res:any)=>{
      ////console.log("获取学历列表-----college-resume");
      ////console.log(res);
      if(res)
      {
        this.educationList = res;
        this.dataApi.setEducationCode(res);
      }

    }).catch((error)=>{
      //console.log(error);
    });
  }
}

// 弹出下载
sendTip(){
  this.opera.sendTipss(`<div class="li-block booth booth-app">
  <img src="assets/images/goworklaQRcode.png" alt="广告图"><br/>
  怕错过最新简历<br/>
  <b>扫码下载APP</b><br/>
  <span>随时随地查看校招新消息</span>
</div>`)
}
  /**
   * 生源速览极速短信弹窗
   */
  alertSendMsg(n,event){
    event.stopPropagation();
    this.opera._hmtPush('对我感兴趣','职位推送','')

    if(n.talent_name==null)
    {
      this.toastr.error('该学生信息不存在');
      return;
    }

    let that = this;


    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.productManager.getProductUsableCount(this.config.sms_bulkinvitation,(count,isVip)=>{
          if(count==0||!isVip) {
            // this.config.payVipHistoryPage = {
            //   page:'对我感兴趣',
            //   isVip:isVip,
            //   pro:'职位推送'
            // };
            // setTimeout(()=>{
            //   //去职位推送
            //   that.local.set("recharge_category_code", JSON.stringify(this.config.ProductListCode.sms_bulkinvitation_code));
            //   that.router.navigate(['home/buyProduct']);
            // },600)
            this.product('sms_bulkinvitation','recharge');
          } else {
            console.log("生源速览极速短信弹窗",n);
            this.sengMsgModel.initData(1,[]);
            this.SengMsgModel.userName = n.talent_name;
            this.SengMsgModel.userPic = n.logo==null?'': n.logo;
            this.SengMsgModel.graduation_school = n.graduation_school;
            this.SengMsgModel.major_text = n.major_text;
            this.SengMsgModel.talentid = n.talentid;
            this.SengMsgModel.gender = n.gender;
            if(n.address_text){
              this.SengMsgModel.address = n.address_text;
            }
            this.modelShowSengMsg =true;
          }
        })
      } else {
        //that.opera.checkMemberAlertWithType("noneVip_sms_bulkinvitation")
        this.product('sms_bulkinvitation','recharge');
      }
    })



  }
  SendMsgdismiss(event){
    this.modelShowSengMsg =false;
    this.sendMsgArr=[];
    this.useBatch=false;
  }
  // 切换批量邀投开关
  useBatchFn() {
    if (this.sendMsgArr.length == 0) {
      this.useBatch = !this.useBatch;
    } else {
      this.singleTips();
    }
  }

  // 活动通知包触发
  singleTips() {
    this.modelShowSengMsg = true;
    this.sengMsgModel.initData(2, this.sendMsgArr);
  }

  // 单个学生选择
  selectStudentFn(n) {
    if (this.sendMsgArr.indexOf(n.talentid) == -1) {
      this.sendMsgArr.push(n.talentid);
    } else {
      this.sendMsgArr.splice(this.sendMsgArr.indexOf(n.talentid), 1);
    }
    console.log(this.sendMsgArr.indexOf(n.talentid));
    this.checkAll();
  }

  // 检测是否全选
  checkAll() {
    for (let i of this.resumeList) {
      if (this.sendMsgArr.indexOf(i.talentid) == -1) {
        this.selectAll = false;
        break;
      } else {
        this.selectAll = true;
      }
    }
    console.log(this.selectAll);
  }
  // 本页全选
  selectAllFn(){
    console.log(this.resumeList);
    if(!this.selectAll){
      for(let i of this.resumeList){
        if(this.sendMsgArr.indexOf(i.talentid) == -1){
          this.sendMsgArr.push(i.talentid);
        }
      }
      this.selectAll = true;
    }else{
      for(let i of this.resumeList){
        this.sendMsgArr.splice(this.sendMsgArr.indexOf(i.talentid),1);
      }
      this.selectAll = false;
    }
    console.log(this.sendMsgArr);
  }
  // 本页反选
  selectRevFn(){
    console.log(this.sendMsgArr);
    for(let i of this.resumeList){
      if(this.sendMsgArr.indexOf(i.talentid) == -1){
        this.sendMsgArr.push(i.talentid);
      }else{
        this.sendMsgArr.splice(this.sendMsgArr.indexOf(i.talentid),1);
      }
    }
    this.checkAll();
  }
  //极速电话联系弹窗消失
  voiceCallModelHidden(e){
    this.isShowVoiceCallModel = false;
  }
  //展示一键职位推送的弹窗
  showPositionPushModelClick(){

    if(this.positionList.length==0){
      this.toastr.warning('发布职位后可使用批量职位推送')
      this.router.navigateByUrl('/home/position-manage');// 职位推送
      return
    }

    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.isShowPositionPushModel= true;
        let that  = this ;
        setTimeout(()=>{
          laydate.render({
            elem:'#start-time',
            done: function(value, date){
              console.log(value,date);
              that.positionPushModel.startTime = value;
              that.countPushStudentHttp();
            }
          })
          laydate.render({
            elem:'#end-time',
            done: function(value, date){
              console.log(value,date);
              that.positionPushModel.entTime = value;
              that.countPushStudentHttp();
            }
          })
        },300)
      } else {
       // this.opera.checkMemberAlertWithType("noneVip_sms_bulkinvitation")
       this.product('sms_bulkinvitation','recharge');
      }
    })


  }
  //筛选职位推送 动态计算 符合条件的人数
  pushSeletePositionChange(){
    console.log(this.config.department)
    if(this.positionPushModel.positionid){
      this.positionList.map((item)=>{
        if(item.positionid==this.positionPushModel.positionid){
          this.positionPushModel.seletePosition = item
        }
      })
    }
    if(this.config.department) {
      this.positionPushModel.department = this.config.department
    }
    this.countPushStudentHttp()
  }
  //计算批量职位推送的人数
  countPushStudentHttp(){
    if(this.positionPushModel.startTime&& this.positionPushModel.entTime){
      let startTimeCount = new Date(this.positionPushModel.startTime)
      let endTimeCount = new Date(this.positionPushModel.entTime)
      if(startTimeCount>endTimeCount){
        console.log('开始时间大于结束时间')
        this.positionPushModel.startTime = ''
        this.positionPushModel.entTime = ''
        this.toastr.warning('时间范围错误')
      }
    }
    let data = {
      positionid:this.positionPushModel.positionid,
      start_time:'',
      end_time:''
    }
    if(this.positionPushModel.startTime&& this.positionPushModel.entTime) {
      data = {
        positionid:this.positionPushModel.positionid,
        start_time:this.positionPushModel.startTime+" 00:00:00",
        end_time:this.positionPushModel.entTime+" 23:59:59",
      }
    }
    this.dataApi.getPositionViewCount(data).then((res:any)=>{
      ////console.log("获取学历列表-----college-resume");
      console.log('getPositionViewHistory',res)
      if(res){
        this.positionPushModel.fuhecount = res.value
        this.positionPushModel.invalCount = res.value
      }
    }).catch((error)=>{
      //console.log(error);
    });
      //

    console.log(this.positionPushModel)
  }
  // 加减 推送的人数
  invalCountClick(type){
    if(type=='delete'){
      if(this.positionPushModel.invalCount==0){
        return
      }
      this.positionPushModel.invalCount-=1
    }else{
      if(this.positionPushModel.invalCount>= this.positionPushModel.fuhecount)
      {
        return
      }
      this.positionPushModel.invalCount+=1
    }
  }
  //获取推送的短信内容
  getMSGContent(){
    this.dataApi.getMSGContent('邀投简历').then((res:any)=>{

      this.positionPushModel.content = res.text;
      this.getPushPhone();
      // if(this.local.get('loginName')){
      //   this.positionPushModel.content= this.positionPushModel.content.replace('{HR招聘电话}',this.local.get('loginName'))
      // }
      if(this.local.get('loginUser')){
        let phone = JSON.parse(this.local.get('loginUser')).mobile
        this.positionPushModel.content= this.positionPushModel.content.replace('{HR招聘电话}',phone)
      }


    }).catch((err)=>{
      this.toastr.error(err)
    })
  }
  // 获取我的短信余量
  getMySmsCount(){
    this.productManager.getProductUsableCount(this.config.sms_bulkinvitation,(count,isVip)=>{
      console.log('我的短信余额：',count)
      this.positionPushModel.allCount = count
    })
  }
  // 立即推送
  pushSms(){
    if(this.positionPushModel.invalCount==0||this.positionPushModel.invalCount < 0) {
      return
    }
    if(!this.positionPushModel.startTime || !this.positionPushModel.entTime){
      this.toastr.warning('请选择时间范围')
      return
    }
    
    if(!this.regexManager.isNumber(this.positionPushModel.invalCount)){
      this.toastr.error('邀请人数错误')
      return
    }
    if(this.positionPushModel.startTime&& this.positionPushModel.entTime){
      let startTimeCount = new Date(this.positionPushModel.startTime)
      let endTimeCount = new Date(this.positionPushModel.entTime)
      if(startTimeCount>endTimeCount){
        console.log('开始时间大于结束时间')
        this.toastr.warning('时间范围错误')
        return
      }
    }
    if(!this.positionPushModel.content) {
      this.toastr.warning('推送短信模版错误')
      return
    }
    if(!this.positionPushModel.positionid) {
      this.toastr.warning('请选择推送职位')
      return
    }
    if(!this.positionPushModel.phone) {
      this.toastr.warning('请输入HR招聘电话')
      return
    }
    if(this.positionPushModel.invalCount>this.positionPushModel.fuhecount){
      this.toastr.warning('邀请的人数不符合条件人数')
      return
    }
    if(this.positionPushModel.invalCount>this.positionPushModel.allCount){
      this.toastr.warning('余量不足')
      return
    }
    console.log('positionPushModel',this.positionPushModel)

    let data = {
      invite_count:this.positionPushModel.invalCount,
      start_time:'',
      end_time:'',
      phone:this.positionPushModel.phone
    }
    if(this.positionPushModel.startTime && this.positionPushModel.entTime) {
      data = {
        invite_count:this.positionPushModel.invalCount,
        start_time:this.positionPushModel.startTime +" 00:00:00",
        end_time:this.positionPushModel.entTime +" 23:59:59",
        phone:this.positionPushModel.phone
      }
    }
      this.dataApi.positionInvitePushs(this.positionPushModel.positionid,data).then((res:any)=>{
      ////console.log("获取学历列表-----college-resume");
      console.log('positionInvitePushs',res)
        this.toastr.success('邀请成功')
        this.getMySmsCount()
        this.getProductList()
        this.countPushStudentHttp()
      }).catch((error)=>{
      //console.log(error);
        if(error){
          this.toastr.error(error)
        }
    });

  }

  //获取上次职位推送电话
  getPushPhone(){
    this.dataApi.getPushPhone().then((res:any)=>{
      console.log('获取上次职位推送电话',res.value);
      this.positionPushModel.phone = res.value;
      this.positionPushModel.content = this.positionPushModel.content.substring(0,this.positionPushModel.content.indexOf('HR电话')+4) + this.positionPushModel.phone
    }).catch((err)=>{

    })
  }
  changeinput(phone){
    this.positionPushModel.phone = phone;
    this.positionPushModel.content = this.positionPushModel.content.substring(0,this.positionPushModel.content.indexOf('HR电话')+4) + this.positionPushModel.phone;
  }

  productModelHidden(event){    
    this.isproductShowModel = false;
  }

  //点击产品
  product(alias,type){
    this.productModel.initData(alias,type);
    this.isproductShowModel = true;
  }


  goRecommend(){
    this.router.navigate(['home/college-resume']);
  }
}
