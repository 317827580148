import { Component, OnInit, Input, Output } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {  ActivatedRoute,Router } from  '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-company-identity',
  templateUrl: './company-identity.component.html',
  styleUrls: ['./company-identity.component.css']
})
export class CompanyIdentityComponent implements OnInit {
  public config: Config;
  // @Output() modelShow = false;//是否打开弹窗(提交认证)
  @Output() collegeid = '';//高校id(提交认证)
  @Output() audit_time = '';//认证审核时间
  // @Output() modelShowStatus = false;//是否打开弹窗(认证状态)
  // @Output() modelShowCollege = true;//是否打开弹窗(高校认证列表)
  // @Output() status = 1;//当前认证状态
  @Output() modelShow = false;//是否打开弹窗
  @Output() collegeName = '';//高校名称
  @Output() collegeIdentity:any;//高校认证信息
  // public type="departrment";//当前导航页
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public collegeList=[];//认证的高校列表
  public model={
    college_name:'',
    status:null,
    offset:0,
    limit:9
  }
  public myDepartment:any;//公司信息
  public modelIndentity={
    collegeid:'',
    business_licence:'',//企业营业执照
    id_card_front:'',//身份证正面
    register_number:''//营业执照注册号
  }
  public listCount:0;//列表总数
  public keywordList=[];//搜索关键词匹配列表
  public status=0;
  constructor(public dataApi: DataApi,
    public local:Local,
    private sanitizer: DomSanitizer,
    private router: Router,
    public opera:Opera,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
      // activatedRoute.queryParams.subscribe(queryParams => {
      //   if(queryParams.type){
      //     this.type=queryParams.type;
      //   }
      //   if(queryParams.id){
      //     this.collegeid=queryParams.id;
      //   }
      // })
      activatedRoute.queryParams.subscribe(queryParams => {
        if(queryParams.status){
          this.status  = queryParams.status;
        }
      });
      this. getCollegeCode();//获取高校字典
      this.getCollegeIdentityList();//获取高校认证列表
    }

  ngOnInit() {
    this.opera.initConfig('college','college-campustalk');
    // if(this.type="departrment"){
    //   this.lastAuthenticationIdentity();//获取企业上次认证信息
    // }
  }
  //查看原因
  goStatus(m){
    // var d = new Date(date);
    // d.setHour(d.getHour()+24);
    // return
    this.collegeName=m.college_name;//赋值学校名称
    this.collegeIdentity=m;
    this.collegeid=m.collegeid;
    this.audit_time=m.audit_time;
    this.modelShow = true;//打开校招申请确认弹窗
    this.opera.scrollTop();//页面滚动函数
  }
  //获取申请校招返回值
  Modeldismiss(event){
    //console.log('子组件返回结果-----college-details');
    //console.log(event);
    this.opera.scrollTrue();
    if(event){
      //跳转认证页面
      this.local.set(Constants.collegeid,this.collegeid);
      this.router.navigateByUrl("/home/company-identity-details");
      this.modelShow = false;
    }else{
      this.modelShow = false;
    }

  }

  formatDate(now) {
    var year=now.getFullYear();
    var month=now.getMonth()+1;
    var date=now.getDate();
    // var hour=now.getHours();
    // var minute=now.getMinutes();
    // var second=now.getSeconds();
    // return year+"-"+month+"-"+date+" "+hour+":"+minute+":"+second;
    return year+"年"+month+"月"+date+"日";
  }

  //去认证
  goIdentity(id,time){
    this.dataApi.checkDepCollegeIdentity(id).then((res:any)=>{
      if(res.can_apply){
        this.local.set(Constants.collegeid,id);
        this.router.navigateByUrl("/home/company-identity-details");
      }else{
        let audit_time = new Date(time); // 审核时间
        let audit = audit_time.getTime();  // 审核时间戳
        if(audit == -62135625943000 || time == ''){
          this.toastr.warning(res.reason);
        }else{
          let newTime = audit + 30*24*60*60*1000;
          this.toastr.warning(`上次申请未通过，30天内不可再次申请，${this.formatDate(new Date(newTime))}才可重新申请！`);
        }
      }
    }).catch((err)=>{
      this.toastr.error(err);
    })
  }

  //获取企业信息
  getDepartment(){
    if(this.dataApi.getLocalDepartment()){
          this.myDepartment = this.dataApi.getLocalDepartment();
          //console.log("企业信息本地存储---------------------------CollegeIdentityPage");
          //console.log(this.dataApi.getLocalDepartment());
        }else{
          this.dataApi.getDepartment().then((res:any)=>{
            //console.log("企业信息获取线上数据---------------------------CollegeIdentityPage");
          //console.log(res);
          this.dataApi.setLocalDepartment(res);
        }).catch((error) => {
          //console.log(error);
          this.toastr.error(error);
      });
      }

  }

  //匹配关键字列表
 getItems(){
   this.keywordList=[];
   if(!this.model.college_name){return;}
   for(let s of this.config.school){
     if(s.text.indexOf(this.model.college_name) >= 0){
       this.keywordList.push(s);
     }
   }
  }
  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      that.goSearch(that.model.college_name);
    }else{
      that.getItems();
    }
  }

  goSearch(text){
    this.model.college_name = text;
    this.getCollegeIdentityList();//获取高校认证列表
    this.keywordList=[];
  }

   //获取高校认证列表
   getCollegeIdentityList(){
    this.opera.loading();
    if(this.status==0){
      this.model.status=null;
    }else{
      this.model.status=this.status;
    }
    this.dataApi.getCollegeIdentityList(this.model).then((res:any)=>{
      // console.log("获取高校认证列表------");
      // console.log(res);
      this.collegeList=res.list;
      this.listCount=res.count;
      this.pageCount =Math.ceil(res.count/9);
      this.opera.loadiss();
    }).catch((err)=>{
      //console.log(err);
      this.opera.loadiss();
    })
  }

  //获取高校字典
  getCollegeCode(){
    // 获取高校字典数据
    if(this.config.school.length<1){
     this.dataApi.getDictDate('school').then((res:any)=>{
       // //console.log(res);
       this.config.school=res;
     }).catch((err)=>{
       // this.toastr.error("网络错误,请稍后重试");
       //console.log(err);
     })
   }
 }

  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----college-campustalk');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getCollegeIdentityList();//获取高校认证列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getCollegeIdentityList();//获取高校认证列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getCollegeIdentityList();//获取高校认证列表
      }
    }

  }

}
