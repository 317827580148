<!-- 职位模板 -->
<!-- 遮罩层公共样式 -->


<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{padding:0,border:0,'border-radius':'6px','width':'600px'}" [(visible)]="isShow" (onHide)="dismiss(false)" [modal]=true>

    <div class="model-cont">
        <div class="clos" (click)="dismiss(false)">
            <img src="/assets/images/rights/contactMeClos.png">
        </div>
        <!-- 上层提示部分 -->
        <div class="model-head">
            <div class="left">请选择职位模板</div>
        </div>

        <!--没有模板时-->
        <div class="none-block-empty" *ngIf="initvalue.length<=0">
            <img class="none-pic" src="/assets/images/rights/empty.png">
            <p>暂时没有职位描述模板</p>
        </div>

        <div class="publish-position-list" *ngFor="let value of initvalue;let index=index">
            <div class="text-box" (click)="closeTemplate(value)">
                <div class="text-block" style="margin-bottom: 10px;font-size: 16px;color: #5d479a;">模板{{index+1}}</div>
                <div class="text-block" [innerHtml]="value | textformat"></div>
            </div>
        </div>
    </div>
</p-dialog>