<!-- 修改手机号 -->
<!-- 遮罩层公共样式 -->
<p-dialog [contentStyle]="{'padding':'0',border:'0','border-radius':'6px'}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]="true"
    [showHeader]="false" [(visible)]="isShow">
    <div class="dialog_close_btn" style="top: 19px;right: 32px;" (click)="dismiss(false)"><img src="/assets/images/rights/contactMeClos.png"></div>
    <div style="width: 621px;height: 370px;background-color: white;overflow: hidden;" >
        <div class="model_title">修改手机号</div>
        <div class="d_f model_cell" style="padding-top: 42px;">
            <div class="model_cell_title">原手机号</div>
            <div class="d_f model_cell_val_box">
                <div class="f_1 model_cell_val">{{userMobile}}</div>
            </div>
        </div>
        <div class="d_f model_cell">
            <div class="model_cell_title">新手机号</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" type="text" [(ngModel)]="model.mobile" oninput="if(value.length>11)value=value.slice(0,11)" placeholder="请输入常用的手机号">
            </div>
        </div>
        <div class="d_f model_cell">
            <div class="model_cell_title">验证码</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" type="text" [(ngModel)]="model.vcode_text" placeholder="请输入短信验证码">
                <div class="custom_btn_plain" (click)="postMobileSmsVcode()" *ngIf="!isSendOver && !countDown">获取验证码</div>
                <div class="custom_btn_plain" (click)="postMobileSmsVcode()" *ngIf="isSendOver && !countDown">重新发送</div>
                <div class="custom_btn_plain" (click)="postMobileSmsVcode()" *ngIf="countDown">{{countDown}}</div>
            </div>
        </div>
        <div class="model_explain">修改完成后，请使用新手机号码登录工作啦。</div>
        <div class="custom_btn_primary" (click)="getMobileChange()">确定</div>
    </div>
</p-dialog>
