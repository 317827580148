<!-- 页头部分 -->
<div class="contentRight-header"><img (click)="opera.goChangeRouter('position-rules')" src='assets/images/router-old.png'/>招聘管理 - 职位管理 - {{publishTitle}}</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <div class="college-block">
    <!-- 页头部分 -->
    <div class="college-block-head">{{publishTitle}}</div>
    <!-- 填写部分 -->
    <div class="sign-block">
      <!-- 标题 -->
      <div class="sign-list">
          <span><b class="remind-red">*</b>标题：</span>
          <input type="text" [(ngModel)]="model.name" maxlength="25" placeholder="请填写标题(25字以内)">
      </div>
      <!-- 招聘单位 -->
      <div class="sign-list" *ngIf="myDepartment">
        <span>招聘单位：</span>
        <div class="selected-text">{{myDepartment.name}}</div>
      </div>
      <!-- 简章内容 -->
      <div class="sign-list sign-editor">
        <span><b class="remind-red">*</b>简章内容：</span>
        <ngx-neditor [(ngModel)]="model.content" #neditor [config]="toolConfig"></ngx-neditor>
      </div>
      <!-- 提交 -->
      <div class="sign-submit">
          <button class="purple" (click)="goSubmit()">发布</button>
       </div>
    </div><!-- 申请部分 -->
  </div>
</div>
