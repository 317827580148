<p-dialog [showHeader]="true" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'width':'840px','height':'600px','border-bottom-right-radius':'6px','border-bottom-left-radius':'6px'}" [(visible)]="isShow" (onHide)="_modelHidden()" [modal]=true>
    <div class="pushModel" *ngIf="addShow">
        <div class="title">选择您在「工作啦-大学生直聘」一站式报名、管理招聘活动的高校<span>{{addCollegeList.length}}/3</span></div>
        <div class="tip">您当前还不是会员，只能在「工作啦-大学生直聘」一站式管理您选择的 3 所高校招聘活动，想要一站式报名、管理更多高校招聘活动，请加入会员</div>
        <div class="tip active">*您也可到各高校就业信息网逐一报名</div>
        <div class="input">
            <img src="/assets/images/rights/search.png">
            <input type="text" placeholder="搜索高校" [(ngModel)]="model.name" (keyup)="inputKeyup($event)">
        </div>
        <div class="downLoadBox">
            <select name="city" class="downLoad" id="collegeType" style="background-color: white" [(ngModel)]="model.school_type" (ngModelChange)="searchClick()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">学校类型</option>
                <option class="place-text" value=''>不限</option>
                <option *ngFor="let city of school_type" [value]="city.code">{{city.text}}</option>
            </select>
        </div>
        <!--高校列表-->
        <div class="collegeList" *ngIf="schoolList.length>0">
            <div class="item" *ngFor="let item of schoolList" (click)="itemClick(item.collegeid,item.prohibit)">
                <div class="choice" [class.active]='item.active'><span></span></div>
                <div class="logo"><img [src]="item.logo"></div>
                <div class="text">
                    <div class="name" [class.active]='item.active'>{{item.name}}</div>
                    <div class="school_type_text"><img src="/assets/images/rights/school_type_text.png">{{item.school_type_text}}</div>
                </div>
            </div>
        </div>
        <div class="addBtn" *ngIf="addCollegeList.length<3" (click)="addBtn()">确定</div>
        <div class="addBtn" *ngIf="addCollegeList.length>=3" (click)="product('','contactMe')">请校招顾问联系我</div>
    </div>
    <div class="addTextBox" *ngIf="!addShow">
        <div class="tip">请谨慎选择，确定过的高校将不能修改</div>
        <div class="bottom">
            <div class="addBtn active" (click)="cancel()">取消</div>
            <div class="addBtn" (click)="addBtnSave()">确定</div>
        </div>

    </div>
</p-dialog>
<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>