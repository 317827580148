<!-- v3.0 -->
<div class="headerReturn">
    <div class="center" (click)="opera.goNewRouter('college-milkround')">
        <img src="assets/images/v3/headerReturnIcon.png">双选会详情
    </div>
</div>
<div class="milkdetailsContainer">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-college-milksign #milkUpdata [number]="milkNumber" [mridList]="mridList" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-milksign>
    <div class="banner" *ngIf='milk'>
        <div class="main">
            <div class="logo">
                <img *ngIf="milk.cover" [src]="milk.logo">
                <img *ngIf="!milk.cover" src="assets/images/none.png">
            </div>
            <div class="text">
                <div class="name">{{milk.name}}</div>
                <div class="btn">
                    <!-- 未开始 -->
                    <div class="item" *ngIf="isApplySign&&!milk.status" (click)="goSign()">我要报名</div>
                    <!--未开始待审核-->
                    <div class="item" *ngIf="isApplySign&&(milk.status=='to_audit')" (click)="goUpdate(milk)">修改申请</div>
                    <!--未开始未通过-->
                    <div class="item" *ngIf="isApplySign&&(milk.status=='not_passed')" (click)="goSign()">再次申请</div>
                    <!--未开始已通过-->
                    <div class="item" *ngIf="isApplySign&&(milk.status=='passed')">已通过（请准备参会）</div>
                    <div class="item" *ngIf="milk_user?.cooperation_status==2&&milk_user.status=='passed'&&milk_user.feedback_status=='join'&&opera.isShowPaySuccessModule1() && milk_user.milkround_type != 2&&milk_user.curr_total_student>=1000&&!milk_user.isEnd" (click)="gotoTongzhi()">通知学生到展位</div>
                    <div class="item color01" (click)="goSpace()">进入会场</div>
                </div>
            </div>
        </div>
    </div>
    <div class="introduce" *ngIf='milk'>
        <div class="center">
            <div class="title">活动简介</div>
            <div class="zt">当前状态
                <span *ngIf="!isMilkStart">即将开始</span>
                <span *ngIf="isMilkStart&&!isMilkEnd">正在进行</span>
                <span *ngIf="isMilkEnd">已经结束</span>
            </div>
            <div class="introduceCont">
                <div class="li"><img src="assets/images/v3/milkdetails_introduce01.png">报名时间： {{milk.apply_start_time | date:"yyyy年MM月dd日 HH:mm" }} 至 {{milk.apply_end_time | date:"yyyy年MM月dd日 HH:mm" }}</div>
                <div class="li"><img src="assets/images/v3/milkdetails_introduce04.png">联系人：{{milk.contacts}}</div>
                <div class="li"><img src="assets/images/v3/milkdetails_introduce02.png">活动时间： {{milk.start_time | date:'yyyy年MM月dd日 HH:mm'}} 至 {{milk.end_time | date:'yyyy年MM月dd日 HH:mm'}}</div>
                <div class="li"><img src="assets/images/v3/milkdetails_introduce05.png">联系电话：{{milk.phone}}</div>
                <div class="li"><img src="assets/images/v3/milkdetails_introduce03.png">举办场地： {{milk.place_name}}</div>
                <div class="li"><img src="assets/images/v3/milkdetails_introduce06.png">联系邮箱：{{milk.email}}</div>
            </div>
            <div class="title02">活动说明<span (click)="downLoadGfile()">下载附件</span></div>
            <div class="college-message" [innerHtml]="opera.assembleHTML(milk.description)"></div>
        </div>
    </div>
</div>

<!-- 主体内容部分 -->
<div class="homepage-block" style="display: none;">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-college-milksign #milkUpdata [number]="milkNumber" [mridList]="mridList" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-milksign>
    <div class="college-block" *ngIf='milk'>
        <div class="img-cover">
            <img *ngIf="milk.cover" [src]="milk.logo">
            <img *ngIf="!milk.cover" src="assets/images/none.png">
            <div class="college-title">{{milk.name}}</div>
            <!-- 操作部分 -->
            <div class="college-opera">
                <!-- 未开始 -->
                <button class='purple' *ngIf="isApplySign&&!milk.status" (click)="goSign()">我要报名</button>
                <!--未开始未报名-->
                <button class='purple' *ngIf="isApplySign&&(milk.status=='to_audit')" (click)="goUpdate(milk)">修改申请</button>
                <!--未开始待审核-->
                <button class="purple" *ngIf="isApplySign&&(milk.status=='not_passed')" (click)="goSign()">再次申请</button>
                <!--未开始未通过-->
                <button class="gray" *ngIf="isApplySign&&(milk.status=='passed')">已通过（请准备参会）</button>
                <!--未开始已通过-->
                <button class="tongzhi" style="background-color: #38D4A9" *ngIf="milk_user?.cooperation_status==2&&milk_user.status=='passed'&&milk_user.feedback_status=='join'&&opera.isShowPaySuccessModule1() && milk_user.milkround_type != 2&&milk_user.curr_total_student>=1000&&!milk_user.isEnd"
                    (click)="gotoTongzhi()">通知学生到展位</button>
                <button class="yellow" (click)="goSpace()">进入会场</button>
            </div>
        </div>
        <div class="college-center">
            <div class="cont-title"><span></span>活动简介</div>
            <div class="cont-txt clear">
                <div class="cont-list" *ngIf='milk.start_time'><span>开始时间&nbsp;&nbsp;&nbsp;</span>{{milk.start_time | date:'yyyy-MM-dd HH:mm'}}</div>
                <div class="cont-list" *ngIf='milk.end_time'><span>结束时间&nbsp;&nbsp;&nbsp;</span>{{milk.end_time | date:'yyyy-MM-dd HH:mm'}}</div>
                <div class="cont-list" *ngIf='milk.apply_start_time && milk.apply_start_time != "" && milk.apply_start_time != "0001-01-01T00:00:00+08:00" && milk.apply_start_time != "0001-01-01T00:00:00"'><span>报名开始&nbsp;&nbsp;&nbsp;</span>{{milk.apply_start_time | date:"yyyy-MM-dd HH:mm" }}</div>
                <div class="cont-list" *ngIf='milk.apply_end_time && milk.apply_end_time != "" && milk.apply_end_time != "0001-01-01T00:00:00+08:00" && milk.apply_end_time != "0001-01-01T00:00:00"'><span>报名结束&nbsp;&nbsp;&nbsp;</span>{{milk.apply_end_time | date:"yyyy-MM-dd HH:mm" }}</div>
                <div class="cont-list" *ngIf='milk.place_name'><span>举办场地&nbsp;&nbsp;&nbsp;</span>{{milk.place_name}}</div>
                <div class="cont-list" *ngIf='milk.contacts'><span>&nbsp;&nbsp;&nbsp;联系人&nbsp;&nbsp;&nbsp;</span>{{milk.contacts}}</div>
                <div class="cont-list" *ngIf='milk.phone'><span>联系电话&nbsp;&nbsp;&nbsp;</span>{{milk.phone}}</div>
                <div class="cont-list" *ngIf='milk.email'><span>联系邮箱&nbsp;&nbsp;&nbsp;</span>{{milk.email}}</div>
                <div class="cont-list" *ngIf="!isMilkStart"><span>当前状态&nbsp;&nbsp;&nbsp;</span>即将开始</div>
                <div class="cont-list" *ngIf="isMilkStart&&!isMilkEnd"><span>当前状态&nbsp;&nbsp;&nbsp;</span>正在进行</div>
                <div class="cont-list" *ngIf="isMilkEnd"><span>当前状态&nbsp;&nbsp;&nbsp;</span>已经结束</div>
            </div>
            <div class="cont-title"><span></span>活动说明<span (click)="downLoadGfile()" style="font-size: 16px;margin-top: -5px;width: 100px;background-color: lightgray;text-align: center;border-radius: 5px;padding-top: 5px">附件下载</span></div>
            <div class="college-message" [innerHtml]="opera.assembleHTML(milk.description)"></div>
        </div>
    </div>
</div>


<!-- 新增高校 -->
<app-push-college-model #pushCollegeModel [isShow]="isShowPushCollegeModel" (modelHidden)="pushCollegeModelHidden($event)"></app-push-college-model>
<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>