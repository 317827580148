<div style="background-color: #ffffff" class="homepage-block">
    <router-outlet></router-outlet>
    <app-model-send-msg #sengMsgModel [SengMsgModel]='SengMsgModel' [tconfig]='config' (onVoted)="Modeldismiss($event)" [isShow]="modelShowSengMsg"></app-model-send-msg>
    <app-voice-call-model #voiceCallModel [isShow]="isShowVoiceCallModel" (modelHidden)="voiceCallModelHidden($event)"></app-voice-call-model>
    <!-- 3.0 -->
    <div class="container">
        <div class="tip" *ngIf="isshow_tel_phone_upgrade_model">
            <div class="content">由于监管要求， 运营商在5月10号上线“虚拟号业务固话拦截系统”，导致固话拨打虚拟号会被拦截限制，我们建议您在呼出的的业务场景中尽量使用手机号码，非常感谢您的理解和支持。</div>
            <img class="closeModel" src="../../assets/images/v3/tel_phone_upgrade_close.png" (click)="closeTelPhoneUpgradeModel()" />
        </div>

        <!-- banner -->
        <div class="layui-carousel" id="test1" *ngIf="bannerList.length>0">
            <div carousel-item>
                <div *ngFor="let n of bannerList;let i = index;" (click)="bannerImageClick(n)">
                    <img class="swiper-slide bannerImage" id={{i}} src="{{n.image_url}}" />
                </div>
            </div>
        </div>

        <!-- 看板 -->
        <div class="kanbanContainer">
            <div class="logo"><img src="/assets/images/rights/kanbanLogo.png"></div>
            <div class="cell">
                <div class="name">校园招聘数据看板</div>
                <div class="time">更新时间：{{kanbanData.updateTime}}</div>
            </div>
            <div class="rightMain">
                <div class="item">
                    <div class="title">求职者人数</div>
                    <div class="number"><span>{{kanbanData.value1}}</span>人</div>
                </div>
                <div class="item">
                    <div class="title">总用人单位</div>
                    <div class="number"><span>{{kanbanData.value2}}</span>家</div>
                </div>
                <div class="item">
                    <div class="title">总发布职位</div>
                    <div class="number"><span>{{kanbanData.value3}}</span>个</div>
                </div>
                <div class="item">
                    <div class="title">总岗位数</div>
                    <div class="number"><span>{{kanbanData.value4}}</span>个</div>
                </div>
            </div>
        </div>

        <!-- 企业信息、6大模块 -->
        <div class="enterpriseMain">
            <div class="left">
                <div class="top" *ngIf="myDepartment">
                    <div class="logo mw_mc">
                        <img *ngIf="myDepartment.logo" [src]="myDepartment.logo">
                        <img *ngIf="!myDepartment.logo" src="/assets/images/rights/departmentLogo.png">
                    </div>
                    <div class="rightName">
                        <div class="name" *ngIf="myDepartment.name">
                            {{myDepartment.name}}
                            <!-- <img title="已认证" *ngIf="config.lastIdentityNavSatus == 2" src="/assets/images/rights/authentication.png"> -->
                            <div class="state authentication mw_mc" *ngIf="config.lastIdentityNavSatus == 2">
                                <span *ngIf="lastModify && (lastModify.status == 0 || lastModify.status == 2)" (click)="goauthentication()">去修改</span>
                                <span *ngIf="lastModify && lastModify.status == 1">修改审核中</span>
                                <span *ngIf="lastModify && lastModify.status == 3" style="color: #FD654A;" (click)="goauthentication()">修改审核未通过</span>
                            </div>
                            <div class="state Failed mw_mc" (click)="gotoRenzheng()" *ngIf="config.lastIdentityNavSatus==3">认证未通过</div>
                            <div class="state authentication mw_mc" (click)="gotoRenzheng()" *ngIf="config.lastIdentityNavSatus==0">立即认证</div>
                            <div class="state authentication mw_mc" *ngIf="config.lastIdentityNavSatus==1">认证中</div>
                        </div>
                        <div class="text">{{myDepartment.employer_type_text}}｜{{myDepartment.scale_text}}｜{{myDepartment.industry_text}}</div>
                    </div>
                </div>
                <div class="perfect">
                    <div class="title">公司完善度</div>
                    <div class="stripBox" *ngIf="myDepartment && myDepartment.level">
                        <div class="strip">
                            <div class="colorCell" [ngStyle]="{'width':myDepartment.level+'%'}"></div>
                        </div>
                        <div class="percentage" *ngIf="myDepartment.level">{{myDepartment.level}}%</div>
                        <div class="goperfect" (click)="goComHome()">去完善<img src="/assets/images/v3/goperfect.png"></div>
                    </div>
                </div>
                <!--会员信息-->
                <div class="vipBox" *ngIf="isShowVip&&myDepartment">
                    <div class="icon">
                        <img src="/assets/images/v3/yellowVip.png">
                    </div>
                    <div class="surplusTime">
                        <div class="surplus1">您的基础版会员有效期 {{myDepartment.member_info.days}}天</div>
                        <div class="surplus2"><img src="/assets/images/v3/time.png">{{myDepartment.member_info.endtime_text}} 到期</div>
                    </div>
                    <div class="btn blueStreamer" (click)="goPublish($event)"><img src="/assets/images/v3/push.png">发布职位</div>
                </div>
                <!--非会员-->
                <div class="vipBox" *ngIf="!isShowVip && !vipExperience">
                    <div class="icon">
                        <img src="/assets/images/v3/yellowVipActive.png">
                    </div>
                    <div class="surplusTime">
                        <div class="surplus3">您还不是工作啦会员</div>
                    </div>
                    <div class="btn blueStreamer" (click)="goPublish($event)"><img src="/assets/images/v3/push.png">发布职位</div>
                </div>
                <!--体验会员-->
                <div class="vipBox" *ngIf="!isShowVip && vipExperience">
                    <div class="icon">
                        <img src="/assets/images/v3/yellowVip.png">
                    </div>
                    <div class="surplusTime">
                        <div class="surplus1">您的会员权益体验限期{{vipExperiencedays}}天</div>
                        <div class="surplus2"><img src="/assets/images/v3/time.png">{{myDepartment.memberTrail.endtime | date:'yyyy-MM-dd HH:mm:ss'}} 到期</div>
                    </div>
                    <div class="btn blueStreamer" (click)="goPublish($event)"><img src="/assets/images/v3/push.png">发布职位</div>
                </div>
            </div>
            <div class="right">
                <div class="list">
                    <div class="item" (click)="goRouter('position-resume','')">
                        <div class="item-name">
                            <div class="name">已收到简历</div>
                        </div>
                        <div class="item-main">
                            <div class="icon"><img src="/assets/images/v3/homeIcon01.png"></div>
                            <div class="right-number">
                                <div class="number">
                                    <div class="_nub">{{workData.delivery.total==0?'0':workData.delivery.total}}</div>
                                    <div class="upIcon" *ngIf="workData.delivery.today > 0"><img src="/assets/images/v3/up.png"></div>
                                </div>
                                <div class="added">新收简历：<span>{{workData.delivery.today}}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="item" (click)="goRouter('position-manage','')">
                        <div class="item-name">
                            <div class="name">在招职位</div>
                            <div class="righttext" (click)="goPublish($event)"><img src="/assets/images/v3/pushposition.png">发布职位</div>
                        </div>
                        <div class="item-main">
                            <div class="icon"><img src="/assets/images/v3/homeIcon02.png"></div>
                            <div class="right-number">
                                <div class="number">
                                    <div class="_nub">{{workData.position.total==0?'0':workData.position.total}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item" (click)="goRouter('chat-collection','0')">
                        <div class="item-name">
                            <div class="name">对我感兴趣</div>
                        </div>
                        <div class="item-main">
                            <div class="icon"><img src="/assets/images/v3/homeIcon03.png"></div>
                            <div class="right-number">
                                <div class="number">
                                    <div class="_nub">{{workData.position_view.total==0 ? config.isMember_welfare?'0':0 :workData.position_view.total}}</div>
                                    <div class="upIcon"><img src="/assets/images/v3/up.png"></div>
                                </div>
                                <div class="added">新增：<span>{{workData.position_view.today}}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="item" (click)="goRouter('college-milkround','')">
                        <div class="item-name">
                            <div class="name">我的双选会</div>
                            <div class="righttext" *ngIf="workData.milkround?.total_offline !=0" (click)="gotoMilkroundList('position-milk','2',$event)">校招活动邀请</div>
                        </div>
                        <div class="item-main">
                            <div class="icon"><img src="/assets/images/v3/homeIcon04.png"></div>
                            <div class="right-number">
                                <div class="number">
                                    <div class="_nub">{{workData.milkround?.total}}</div>
                                </div>
                                <div class="added">新增双选会：<span>{{workData.milkround?.can_apply}}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="item" (click)="goRouter('position-campus','2')">
                        <div class="item-name">
                            <div class="name">我的宣讲会</div>
                        </div>
                        <div class="item-main">
                            <div class="icon"><img src="/assets/images/v3/homeIcon05.png"></div>
                            <div class="right-number">
                                <div class="number">
                                    <div class="_nub">{{workData.campustalk}}</div>
                                </div>
                                <div class="added">新增宣讲会：<span></span></div>
                            </div>
                        </div>
                    </div>
                    <div class="item" (click)="goRouter('notification-center','')">
                        <div class="item-name">
                            <div class="name">未读通知</div>
                        </div>
                        <div class="item-main">
                            <div class="icon"><img src="/assets/images/v3/homeIcon06.png"></div>
                            <div class="right-number">
                                <div class="number">
                                    <div class="_nub">{{config.tasktodo>=100?'99+':config.tasktodo}}</div>
                                    <!-- <div class="upIcon"><img src="/assets/images/v3/up.png"></div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 推荐 -->
        <div class="recommend">
            <div class="title">
                <div class="tj">为您推荐</div>
                <div class="search">
                    <input type="text" placeholder="请输入简历ID、职位、学校、专业等关键词" [(ngModel)]="searchPeopleText" (keyup)="inputKeyup($event)">
                    <div class="icon" (click)="goSearch()"><img src="/assets/images/v3/searchIcon.png"></div>
                </div>
            </div>
            <div class="list">
                <div class="item" (click)="goDetails(n,n.ignore)" *ngFor="let n of recommendList | slice:0:9;let i = index;">
                    <div class="main">
                        <div class="introduce">
                            <div class="logo">
                                <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}" />
                                <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png" />
                                <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png" />
                                <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png" />
                            </div>
                            <div class="text">
                                <div class="name">
                                    {{n.talent_name | clearSpace}}
                                    <span *ngIf="n.new_talent">新用户</span>
                                    <span *ngIf="n.is_called">已联系</span>
                                    <span *ngIf="n.is_push">已推送</span>
                                </div>
                                <div class="college">{{n.graduate_university}}</div>
                                <div class="major">{{n.major_text}} | {{n.education_text}} | {{n.year==0?"往届":n.year+'届'}}</div>
                            </div>
                        </div>
                        <div class="lable">
                            <div *ngIf="n.skills.length>0">
                                <span *ngFor="let skil of n.skills | slice:0:3;let i = index;">{{skil.text}}</span>
                            </div>
                        </div>
                        <div class="bottomBtns">
                            <div class="btn" (click)="ShowPhoneModel(n,$event)">
                                <img src="/assets/images/v3/speedIcon.png" /> <span>极速电话联系</span>
                            </div>
                            <div class="btn active" (click)="alertSengMSG(n,$event)">
                                <img src="/assets/images/v3/pushIcon.png" /> <span>职位推送</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="box" style="display: none;">
        <div class="lBox">

            <div class="screenSmallBox flex_l" *ngIf="perfectDepartment.department||perfectDepartment.user || perfectDepartment.isShowPerfetInfo">
                <img class="screenSmallBox_icon" src="../../assets/images/orange_warning.png">
                <div *ngIf="perfectDepartment.department&&!perfectDepartment.user">请补全您的企业信息，方便学生了解贵公司<span (click)="gotoPerfect()" class="gotoPerfect">去完善 >></span></div>
                <div *ngIf="!perfectDepartment.department&&perfectDepartment.user">请补全您的个人信息，方便学生联系您 <span (click)="gotoPerfect()" class="gotoPerfect">去完善 >></span></div>
                <div *ngIf="perfectDepartment.department&&perfectDepartment.user">请完善您的企业信息及个人信息，方便学生向您投递简历 <span (click)="gotoPerfect()" class="gotoPerfect">去完善 >></span></div>
                <div *ngIf="perfectDepartment.isShowPerfetInfo">请设置是否公开您的联系方式。为了更好地触达学生，建议设为公开. <span (click)="gotoPerfect()" class="gotoPerfect">去完善 >></span></div>
            </div>

            <div class="member_outBox flex_s" *ngIf="isShowPayUserDataOut&&config.lastIdentity?.status == 2">
                <div class="member_outBox_l flex_l" *ngIf="memberLastTime==0"><img src="../../assets/images/memberOut_icon.png">您的工作啦认证已到期；为避免您的使用受到影响请前去认证。</div>
                <div class="member_outBox_l flex_l" *ngIf="memberLastTime!=0"><img src="../../assets/images/memberOut_icon.png">您的工作啦认证还有{{memberLastTime}}天到期；为避免您的使用受到影响请前去认证。
                </div>
                <div class="member_outBox_r flex_r">
                    <div class="member_xufei" (click)="goAudit()">去认证</div>
                </div>
            </div>
            <div class="member_outBox flex_s" *ngIf="isShowAuthState">
                <div class="member_outBox_l flex_l" *ngIf="t_lastIdentity?.status == 1"><img src="../../assets/images/memberOut_icon.png">您的工作啦认证已提交审核。等待审核中</div>
                <div class="member_outBox_l flex_l" *ngIf="t_lastIdentity?.status == 3"><img src="../../assets/images/memberOut_icon.png">您的工作啦认证未通过。</div>
                <div class="member_outBox_r flex_r" *ngIf="t_lastIdentity?.status == 3">
                    <div class="member_xufei" (click)="goAudit()">去认证</div>
                </div>
            </div>

            <!--banner下面的模块-->
            <div class="itemView">
                <div (click)="goRouter('position-manage','')" class="_itemBox">
                    <div class="itemLeft ">
                        <img class="itemIcon" src="../../assets/images/work/item_pos.png" alt="" />
                        <div>
                            <span class="itemTitle">在招职位</span>
                        </div>
                    </div>
                    <div class="itemNum ">{{workData.position.total==0?'0':workData.position.total}}<img *ngIf="workData.position.today > 0" src="../../assets/images/work/arrow.png" /></div>
                </div>
                <div (click)="goRouter('chat-collection','0')" class="_itemBox">
                    <div class="itemLeft">
                        <img class="itemIcon" src="../../assets/images/work/item_view.png" alt="" />
                        <div>
                            <span class="itemTitle">对我感兴趣</span>
                            <p *ngIf="workData.position_view.today" class="itemTag">
                                今天有{{workData.position_view.today}}位人才看过你</p>
                        </div>
                    </div>
                    <div class="itemNum">{{workData.position_view.total==0 ? config.isMember_welfare?'0':0 :workData.position_view.total}}
                        <img *ngIf="workData.position_view.today > 0" src="../../assets/images/work/arrow.png" />
                    </div>
                </div>
                <div (click)="goRouter('position-resume','')" class="_itemBox">
                    <div class="itemLeft">
                        <img class="itemIcon" src="../../assets/images/work/item_rec.png" alt="" />
                        <div>
                            <span class="itemTitle">已收到简历</span>
                            <p *ngIf="workData.delivery.today" class="itemTag">新收到{{workData.delivery.today}}份简历</p>
                        </div>
                    </div>
                    <div class="itemNum">{{workData.delivery.total==0?'0':workData.delivery.total}}<img *ngIf="workData.delivery.today > 0" src="../../assets/images/work/arrow.png" /></div>
                </div>
                <div (click)="goRouter('college-milkround','')" class="_itemBox">
                    <div class="itemLeft">
                        <img class="itemIcon" src="../../assets/images/work/item_mil.png" alt="" />
                        <div>
                            <span class="itemTitle">已通过双选会</span>
                            <p *ngIf="workData.milkround?.can_apply" class="itemTag">
                                可报名双选会新增{{workData.milkround?.can_apply}}场</p>
                        </div>
                    </div>
                    <div class="itemNum">{{workData.milkround?.total}}<img *ngIf="workData.milkround?.today > 0" src="../../assets/images/work/arrow.png" /></div>
                    <div class="gotoCampBtn" *ngIf="workData.milkround?.total_offline !=0" (click)="gotoMilkroundList('position-milk','2',$event)">通知学生到展位 <img src="../../assets/images/red_join.png" /></div>
                </div>
                <div (click)="goRouter('position-campus','2')" class="_itemBox">
                    <div class="itemLeft">
                        <img class="itemIcon" src="../../assets/images/work/item_pos.png" alt="" />
                        <div>
                            <span class="itemTitle">已通过宣讲会</span>
                        </div>
                    </div>
                    <div class="itemNum">{{workData.campustalk}}</div>
                    <div class="gotoCampBtn" *ngIf="workData.campustalk>0">通知学生到场 <img src="../../assets/images/red_join.png" /></div>
                </div>
                <div (click)="goRouter('notification-center','')" class="_itemBox">
                    <div class="itemLeft">
                        <img class="itemIcon" src="../../assets/images/work/notice.png" alt="" />
                        <div>
                            <span class="itemTitle">未读通知</span>
                        </div>
                    </div>
                    <div class="itemNum">{{config.tasktodo>=100?'99+':config.tasktodo}}</div>
                </div>
            </div>

            <div class="title">
                <span style="float: left">为您推荐</span>
            </div>

            <!--推荐职位-->
            <div class="positionView">
                <div (click)="goDetails(n,n.ignore)" class="positionItem" *ngFor="let n of recommendList | slice:0:9;let i = index;">
                    <div class="positionItem_Top">
                        <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}" />
                        <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png" />
                        <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png" />
                        <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png" />
                        <div class="info">
                            <div class="name">{{n.talent_name | clearSpace}}
                                <span class="new_telent" *ngIf="n.new_talent">新用户</span>
                                <span class="new_telent" *ngIf="n.is_called">已联系</span>
                                <span class="new_telent" *ngIf="n.is_push">已推送</span>
                            </div>
                            <div class="school">{{n.graduate_university}}</div>
                        </div>
                    </div>
                    <div class="positionItem_Center">
                        <div class="tag">
                            <div class="item">
                                <img src="../../assets/images/work/major.png" class="icon" />
                                <span>{{n.major_text}}</span>
                            </div>
                            <div class="item">
                                <img src="../../assets/images/work/education.png" class="icon" />
                                <span>{{n.education_text}}</span>
                            </div>
                            <div class="item">
                                <img src="../../assets/images/jiebieIcon.png" class="icon" />
                                <span>{{n.year==0?"往届":n.year+'届'}}</span>
                            </div>
                        </div>
                        <div class="skill">
                            <div *ngFor="let youshi of n.youshiArr | slice:0:4;let i = index;" class="item">{{youshi}}
                            </div>
                        </div>
                    </div>
                    <div class="positionItem_Bottom">
                        <div class="positionItem_Bottom_item bordeRadius" (click)="ShowPhoneModel(n,$event)">电话联系</div>
                        <a title="可以将职位定向推送给该学生" class="positionItem_Bottom_item purple bordeRadius" (click)="alertSengMSG(n,$event)">职位推送</a>
                    </div>
                </div>
            </div>

            <div class="title">
                <span style="float: left">快捷入口</span>
            </div>
            <div class="quickBox">
                <div class="quickItem">
                    <div class="header">
                        <img src="../../assets/images/work/quick_mil.png" /> 双选会/宣讲会
                    </div>
                    <ul>
                        <li (click)="goRouter('college-milkround','')">报名双选会</li>
                        <li (click)="goRouter('position-milk','')">双选会审核状态</li>
                        <li (click)="goRouter('college-campustalk1','')">报名宣讲会</li>
                        <li (click)="goRouter('position-campus','')">宣讲会审核状态</li>
                    </ul>
                </div>
                <div class="quickItem">
                    <div class="header">
                        <img src="../../assets/images/work/quick_pos.png" /> 职位
                    </div>
                    <ul>
                        <li (click)="opera.gotoPositionPush()">发布职位</li>
                        <li (click)="goRouter('position-manage','')">职位管理</li>
                    </ul>
                </div>
                <div class="quickItem">
                    <div class="header">
                        <img src="../../assets/images/work/quick_res.png" /> 简历
                    </div>
                    <ul>
                        <li (click)="goRouter('position-resume','')">我收到的简历</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="rBox" style="display: none;">
            <div class="addPosition">
                <div class="info">
                    <img src="{{companyInfo.logo}}" *ngIf="companyInfo.logo" class="logo" />
                    <img src="../../assets/dep_default.png" *ngIf="!companyInfo.logo" class="logo" />
                    <div class="base">
                        <div class="name">{{companyInfo.common_name}}</div>
                        <div class="tag" *ngIf="companyInfo.strengths">
                            <div *ngFor="let n of companyInfo.strengths">
                                <img *ngIf="n.code == '10'" style="width: 80px;height: 18px" src="../../assets/images/work/henan_top.png" />
                                <img *ngIf="n.code == '11'" style="width: 113px;height: 18px;margin-left: 4px" src="../../assets/images/work/stu_top.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="date">
                    <div *ngIf="companyInfo?.member_info && lastMemberDay>0">会员有效期:{{companyInfo?.member_info?.endtime | date:'yyyy-MM-dd'}}
                    </div>
                    <div *ngIf="companyInfo?.member_info && lastMemberDay==0">会员有效期:已过期</div>
                    <div *ngIf="isShowXuFeiStr">续费有效期:{{isShowXuFeiStr}}</div>
                    <div *ngIf="t_lastIdentity?.status == 2">认证有效期:{{companyInfo.authentication_endtime | date:'yyyy-MM-dd'}}
                    </div>
                    <div *ngIf="t_lastIdentity?.status == 1">认证有效期:认证中</div>
                    <div *ngIf="t_lastIdentity?.status == 0">认证有效期:未认证</div>
                    <div *ngIf="t_lastIdentity?.status == 3">认证有效期:认证失败</div>
                </div>
                <div class="btn pushPosition blueStreamer" (click)="opera.gotoPositionPush()"> <img src="../../assets/images/position/again.png" />发布职位</div>
            </div>

            <div *ngIf="config?.department?.member_info" class="memberBox marginTop15 ">
                <img src="{{config?.memberInfo?.ico}}" alt="" style="width: 100%;height: 140px">
                <div class="date basis">
                    <div class="tip" *ngIf="!config.isMember_trial &&lastMemberDay != -1">
                        您的{{config.isMember_trial?'体验会员':'会员'}}{{lastMemberDay > 0?'还有'+lastMemberDay+'天到期':'已过期'}}
                    </div>
                    <div class="tip" *ngIf="config.isMember_trial">您的体验会员{{lastMemberHour}}</div>
                    <div class="limit">有效期：{{config?.department?.member_info?.begintime | date:'yyyy-MM-dd'}}-{{config?.department?.member_info?.endtime | date:'yyyy-MM-dd'}}</div>
                </div>
            </div>

            <div class="myCar marginTop15" *ngIf="config?.department?.member_info&&config.isShowPaySystem">
                <div class="myLaBibox">
                    <div class="tit">我的啦豆</div>
                    <img (click)="aboutLaBi()" class="icon" src="../../assets/images/work/tip.png" />
                </div>
                <div class="myLaBiinfo">
                    <div class="count"><img src="../../assets/images/labiIcon.png" />{{MyLaCoinCount ? MyLaCoinCount : 0}}
                    </div>
                    <div class="detail" (click)="goRouter('payHistory','')">啦豆明细></div>
                </div>
                <div class="buybtn bordeRadius" (click)="goRouter('buyLaCoin','')">购买啦豆</div>
            </div>

            <div class="myCar1 marginTop15" *ngIf="config.department?.member_info&&config.isShowPaySystem">
                <div class="myLaBibox1" (click)="gotoMyCard()"><span>我的卡券</span><span style="float: right;color: #5e4fff">{{myCardListCount}}张</span></div>
                <div class="cardBg" (click)="gotoMyCard()" *ngFor="let n of myCardList;let i = index;">
                    <div class="carTop">
                        <div class="carTop_l">
                            <span class="carTop_l_Title">{{n.title}}</span><br>
                            <span class="carTop_l_subTitle">{{n.coupon_name}}</span>
                        </div>
                        <div class="carTop_r">
                            <span>{{n.gift_amount}}啦豆</span>
                        </div>
                    </div>
                    <div class="carBottom">
                        有效期：{{n.startTime +' - '+n.endTime}}
                    </div>
                </div>
                <div class="MoreCard" (click)="gotoMyCard()">
                    查看更多卡劵 >
                </div>
            </div>
            <!--右下角banner广告-->
            <div class="vipBox marginTop15">
                <div class="swiper-container" id="swiper2">
                    <div class="swiper-wrapper">
                        <img class="swiper-slide bannerImage" *ngFor="let n of bannerList2;let i = index;" (click)="bannerClick(n)" src="{{n.image_url}}" />
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>

<!-- 分页部分 -->
<div class='paging-block' [class.isPageHeight]="recommendList.length>0">
    <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
</div>



<!--双十一弹窗广告 -->
<p-dialog [contentStyle]="{'padding':'0',border:'0','background':'none'}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="pro_tanchuangModel">
    <div class="flex_c" *ngIf="pro_tanchuang">
        <img style="width: 550px;height: auto;cursor: pointer" (click)="customClick()" src="{{pro_tanchuang.image_url}}" />
    </div>
    <img class="closeModelAc" (click)="closeCustom()" src="../../assets/images/icon_guanbi_model.png" />
</p-dialog>

<p-dialog id="aboutLaCoin" [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="false" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':600,'height':400,'minHeight':400}" [modal]="true" [showHeader]="false"
    [(visible)]="isShowSecurityUpgrade">
    <div class="UpgradeModel">
        <div class="flex_s UpgradeTitle">
            <span>工作啦招聘信息安全升级通知</span>
            <img src="../../assets/images/down_close.png" (click)="upgradeTitleComit()" />
        </div>
        <p class="aboutLaCoinContent" style="margin-top: 25px">亲爱的用户，您好。</p>
        <p class="aboutLaCoinContent">为建设更安全健康的招聘求职环境，保护招聘者和求职者的信息安全，工作啦已对如下功能进行升级。</p>
        <p class="aboutLaCoinContent">1、简历水印功能:当您将简历导出至本地时，简历将以增加企业水印的格式保存，简历不包含“联系”方式信息，您可进入工作啦平台内查看求职者的联系方式。</p>
        <p class="aboutLaCoinContent">2、搜索人才和简历管理新增简历ID查询功能:您可输入学生的简历ID搜索对应的学生进行联系。</p>
        <p class="aboutLaCoinContent">感谢您对工作啦的支持，祝您工作顺利。</p>
        <div class="UpgradeTitleComit" (click)="upgradeTitleComit()">我知道了</div>

    </div>
</p-dialog>

<!--热力值弹窗-->
<!--<p-dialog id="aboutHot" [width]="522" [height]="466" [minHeight]="466" [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"  [modal]="true" [showHeader]="false" [(visible)]="aboutHotValue">-->
<!--<div style="width: 522px;height: 466px;background-color: white;overflow: hidden;">-->
<!--<img style="width: 522px;height: 466px;" src="../../assets/images/work/hot_popup.png" />-->
<!--</div>-->
<!--</p-dialog>-->

<!-- 关注公总号检查 -->
<p-dialog [closeOnEscape]="false" [showHeader]="false" [dismissableMask]="false" [(visible)]="showWeChatCode" [contentStyle]="{'border-radius':'6px','padding':0,'width':478,'height':466,'minHeight':466}" [modal]=true>
    <div class="selteTypeModel">
        <div class="selteTypeModelTitle">
            <img src="/assets/images/rights/weChat.png">微信扫码绑定
        </div>
        <div class="text">请扫码绑定微信公众号，绑定成功后可扫码一键登录，同时可及时获悉您的高校合作、双选会、宣讲会审核结果和获取简历通知</div>
        <div class="wxBorder">
            <img *ngIf="weChatCode" src={{weChatCode}} />
        </div>
        <div class="btn blueStreamer" (click)="closeCheckWeiChat()">我知道</div>
    </div>
</p-dialog>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>
<!-- 认证不通过-->
<p-dialog [contentStyle]="{'padding':'0',border:'0','border-radius':'6px'}" [dismissableMask]="false" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="showAuditModel">
    <div class="dialog_close_btn" style="top: 19px;right: 32px;" (click)="showAuditModel=false"><img src="/assets/images/rights/contactMeClos.png"></div>
    <div style="width: 466px;padding-bottom: 30px;background-color: white;overflow: hidden;">
        <div class="auditModel_title">审核通知</div>
        <div class="d_f jc_c" style="padding-top: 31px;">
            <img class="auditModel_icon" src="/assets/images/v3/positionPushTip.png">
            <div class="auditModel_status">审核未通过</div>
        </div>
        <div class="auditModel_explain">未通过原因：{{auditRemark}}</div>
        <div class="ta_c auditModel_more">如您在认证中遇到问题，可致电：4006611396</div>
        <div class="d_f jc_c" style="padding-top: 11px;">
            <div class="custom_btn_primary" (click)="gotoRenzheng()">重新提交</div>
            <div class="custom_btn_plain" (click)="showAuditModel=false">取消</div>
        </div>
    </div>
</p-dialog>