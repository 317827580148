<!-- 页头部分 -->
<!-- <div class="contentRight-header">企业中心 - 企业认证</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <div class="college-block">
    <!-- <img class="goBack " (click)="opera.goNewRouter('member-center')" src='assets/images/router-old.png'/> -->
    <div class="activityNotice">
      <br>
      <h3>批量邀投包</h3>
      <div class="activityNoticeMain">
        <ul class="activityNoticeUl">
          <li class="flex_row_left">
            <span>邀投职位</span>
            <p>
              <select name="positionid" [(ngModel)]="model.positionid" (ngModelChange)="loh()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择邀投职位</option>
                <option *ngFor="let a of positionList" [value]="a.positionid">{{a.name}}</option>
              </select>
            </p>
          </li>
          <li class="flex_row_left">
            <span>选择院校</span>
            <p>
              <select name="positionid" [(ngModel)]="model.collegeid" (ngModelChange)="switchCollege()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择院校</option>
                <option *ngFor="let a of collegeList" [value]="a.collegeid">{{a.name}}</option>
              </select>
            </p>
          </li>
          <!-- <li class="flex_row_left empb">
            <span class="flex_row_left">推荐通知院校 <img (click)="singleTips()" src="assets/images/singleMark.png" alt=""></span>
            <p class="emp flex_row_left">
              <em *ngFor="let col of collegeRecos;let i = index"><sup (click)="collegeClose(i)" class="flex_row_center">─</sup>{{col}}</em>
              <select name="meetName" [(ngModel)]="collegeReco" (ngModelChange)="collegeAdd()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择学校</option>
                <option value='123'>123</option>
                <option *ngFor="let list  of collegeList" [value]="list.collegeid">{{list.name}}</option>
              </select>
            </p>
          </li> -->
          <li class="flex_row_left empb" *ngIf="collegeMajors.length > 0">
            <span class="flex_row_left">通知专业</span>
            <p class="emp flex_row_left">
              <em *ngFor="let maj of collegeMajors;let i = index"><sup (click)="majorClose(i)" class="flex_row_center">─</sup>{{maj}}</em>
            </p>
          </li>

          <li class="flex_row_left empb">
            <span class="flex_row_left">选择通知专业</span>
            <p class="flex_row_left">
              <select name="major" [(ngModel)]="majorOne" (ngModelChange)="majorOneChange()" autocomplete="off" style="margin-right: 20px;">
                <option class="place-text" value='' disabled hidden selected="selected">选择一级专业</option>
                <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
              </select>
              <select name="majorTwo" [(ngModel)]="majorTwo" (ngModelChange)="majorAdd()" (click)="isShowTwo()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择二级专业</option>
                <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
              </select>
            </p>
          </li>

          <li class="flex_row_left">
            <span>学历</span>
            <p>
              <select name="education" [(ngModel)]="model.education" (ngModelChange)="loh()" autocomplete="off">
                <option class="place-text" value='' selected="selected">不限</option>
                <option value='02'>大专</option>
                <option value='03'>本科</option>
              </select>
            </p>
          </li>

          <!-- <li class="flex_row_left empb">
            <span class="flex_row_left">推荐专业 <img (click)="singleTips()" src="assets/images/singleMark.png" alt=""></span>
            <p class="emp flex_row_left">
              <em *ngFor="let maj of collegeMajors;let i = index"><sup (click)="majorClose(i)" class="flex_row_center">─</sup>{{maj}}</em>
              <select name="meetName" [(ngModel)]="collegeMajor" (ngModelChange)="majorAdd($event)" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择专业</option>
                <option *ngFor="let list of collegeMajors" [value]="list">{{list}}</option>
              </select>
            </p>
          </li> -->
          <li class="flex_row_left">
            <span>性别</span>
            <p>
              <select name="gender" [(ngModel)]="model.gender" (ngModelChange)="loh()" autocomplete="off">
                <option class="place-text" value='' selected="selected">不限</option>
                <option value='01'>男</option>
                <option value='02'>女</option>
              </select>
            </p>
          </li>
        </ul>
      </div>
      <div class="activityNoticeNum">
        <p><span>符合以上条件的学生数：</span><em>{{smsAccordNum}} 未走接口</em></p>
        <p><span>确定你要通知的学生数：</span><input type="number" (blur)="canSendOut()" (focus)="canSendOut()" [(ngModel)]="model.send_count"></p>
      </div>
      <!-- <div class="activityNoticeTip">
        【工作啦】XXX同学你好，【{{config?.department?.name}}】 来【{{activityInfo.college_name}}】校招啦，<span *ngIf="model.mc_type == 1">双选会</span><span *ngIf="model.mc_type == 2">宣讲会</span>现场高薪招聘<span *ngIf="config.positionList.length < 1">人资专员、管培生、会计</span><span *ngIf="config.positionList.length > 0">{{config?.positionList[0]?.post_type_text}}<span *ngIf="config?.positionList[0]?.post_type_text">、</span>{{config?.positionList[1]?.post_type_text}}<span *ngIf="config?.positionList[1]?.post_type_text">、</span>{{config?.positionList[2]?.post_type_text}}</span> 若干。招聘时间：{{activityInfo.start_time | date:"yyyy-MM-dd HH:mm"}}，招聘地点：{{activityInfo.place_name}} {{activityInfo.zoom_name}} {{activityInfo.seat_number}}展位。点击XXX直投简历，联系电话：188XXXXXXXX.
      </div> -->
      <div class="boardTip">
        <p>需消耗<span>{{model.send_count}}</span>人次（当前余量<span>{{canUseSmsNum}}</span>人次）</p>
        <p>如需购买更多，请联系<em style="cursor: pointer;" (click)="opera.goChats('JuniorSecretaryD')">在线客服</em>或者拨打电话<em>400-661-1396</em></p>
      </div>
      <div class="flex_row_right buyBox">
        <button class="purple" *ngIf="canSend" (click)="loh()">立即邀投</button>
      </div>
    </div>
  </div>
</div>
