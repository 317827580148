<!--<div class="contentRight-header">-->
<!--<img (click)="opera.goPre()" src='assets/images/router-old.png'/>-->
<!--校招工具-->
<!--</div>-->
<app-navigation-route></app-navigation-route>
<!-- 主体内容部分 -->
<div class="homepage-block">

    <div class="box">

        <div class="card">
            <div class="left">
                <div class="icon"><img [src]="productItemData.ico"></div>
                <div class="name">{{productItemData.name}}</div>
                <div class="line"></div>
                <div class="balance">
                    <div class="number">{{productUseHistoryLeft}}</div>
                    <div class="unit">余额（{{productItemData.unit}}）</div>
                </div>
            </div>
            <div class="right">
                <div class="btn joinHover" (click)="product(_productType,'contactMe')">购买资源</div>
            </div>
        </div>

        <div class="title">使用记录</div>

        <!-- 搜索 -->
        <div class="filtRow">
            <input type="text" class="seleteTimeInput" placeholder="请选择开始时间" id="start-time" [(ngModel)]="startTime" />
            <input type="text" class="seleteTimeInput" placeholder="请选择结束时间" id="end-time" [(ngModel)]="endTime" />
            <button class="searchBtn blueStreamer" (click)="searchBtnClick()">搜索</button>
        </div>

        <!-- 列表 -->
        <div class="historyBox">
            <table class="layui-table" *ngIf="list.length>0">
                <thead>
                    <tr>
                        <th *ngIf="!isAdvert">时间</th>
                        <th *ngIf="isAdvert">开始时间</th>
                        <th *ngIf="isAdvert">结束时间</th>
                        <th>产品</th>
                        <th>资源消耗</th>
                        <th>资源余量</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let n of list ;let i = index;" [ngClass]="{cellBg1:i%2==0,cellBg2:i%2==1}">
                        <td *ngIf="!isAdvert">{{n.timeStr}}</td>
                        <th *ngIf="isAdvert">{{n.advertStart}}</th>
                        <th *ngIf="isAdvert">{{n.advertEnd}}</th>
                        <td>{{n.recharge_category_text}}</td>
                        <td>{{n.consume_count+''}}</td>
                        <td>{{n.left+''}}</td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="list.length==0" style="width: 100%;text-align: center;background-color: white;padding: 114px 0 300px 0"><img style="width: 320px;height: 162px;" src="../../assets/images/none_userHistory.png">
                <p style="color:#999999;font-size: 16px ">您当前没有使用记录</p>
            </div>
        </div>

        <!-- 翻页 -->
        <div class='paging-block' [class.isPageHeight]="countList>0" *ngIf="pageCount>1">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>

    </div>
</div>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>