<!-- 页头部分 -->
<!-- <div class="contentRight-header">企业中心 - 企业认证</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <div class="college-block">
    <!--<img class="goBack" (click)="opera.goNewRouter('member-product-list')" src='assets/images/router-old.png'/>-->
    <div class="quanyiBox">
      <h3>购买说明</h3>
      <div>
        <p>1、普通会员/高级会员/VIP会员权益有效期为一年：从购买之日起,会员有效期在次年同日取消。例如2019年4月3日购买，在2020年4月3日23:59:59到期。</p>
        <p>2、普通会员/高级会员/VIP会员为虚拟产品，不予以退款。</p>
        <p>3、普通会员/高级会员/VIP会员购买后，暂不提供升级到更高级别会员的功能，请酌情购买。</p>
        <p>4、餐饮娱乐及相关行业中，企业经营范围或招聘人员涵盖KTV，娱乐表演，夜总会，歌舞厅，网络主播，按摩足疗，模特，暂不提供会员服务。</p>
        <p>5、保险企业需提供集团公司/人力资源部出具的招聘委托书购买会员服务，暂不接受非人力资源部员工购买。</p>
        <p>6、建议用户首先进行高校云认证工作，否则因不符合购买条件导致退款，系统将在退款时扣除购买方3%的手续费。</p>
        <p>7、成为会员即表示同意<a routerLink="../../home/company-status-four">《工作啦会员协议》</a>。</p>
      </div>
    </div>
  </div>
</div>
