<div class="top-header">
    <div class="center">
        <span (click)="goBack()" class="backMain flex_l"><img src="/assets/images/v3/headerReturnIcon.png" />返回</span>
    </div>
</div>

<div class="helpbox">
    <h3>账号找回</h3>
    <div class="title">请联系工作啦客服进行账号找回</div>

    <div class="row flex_l">
        <img src="../../assets/images/kefu_wx.png"> 微信：
        <span>goworklavip</span>
    </div>
    <div class="row flex_l">
        <img src="../../assets/images/kefu_email.png"> 邮箱：
        <span>service@gowork.la</span>
    </div>

    <img class="code" src="../../assets/images/find-account.png" />

</div>