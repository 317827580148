import { Component, OnInit,Input, Output,ViewChild } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate} from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute,Router } from  '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModelAddressAddComponent } from '../model-address-add/model-address-add.component';
import { ModelCompanyLabelComponent } from '../model-company-label/model-company-label.component';
import { ModelCompanyProductComponent } from '../model-company-product/model-company-product.component';
import { ModelCompanyCoursesComponent } from '../model-company-courses/model-company-courses.component';
import { ModelCompanyStrengthComponent } from '../model-company-strength/model-company-strength.component';
import { ModelCropperComponent } from '../model-cropper/model-cropper.component';
import {ConfirmComponent} from "../confirm/confirm.component";
import {regexManager} from "../../provider/regexManager";
import {LogoutAccountModelComponent} from "../logout-account-model/logout-account-model.component";
declare let laydate;
declare var window;
@Component({
  selector: 'app-guide-company',
  templateUrl: './guide-company.component.html',
  styleUrls: ['./guide-company.component.css']
})
export class GuideCompanyComponent implements OnInit {
  @ViewChild('address', { static: true }) child: ModelAddressAddComponent;
  @ViewChild('label', { static: true }) child1: ModelCompanyLabelComponent;
  @ViewChild('product', { static: true }) child2: ModelCompanyProductComponent;
  @ViewChild('courses', { static: true }) child3: ModelCompanyCoursesComponent;
  @ViewChild('strength', { static: true }) child4: ModelCompanyStrengthComponent;
  @ViewChild('crop', { static: true }) child5: ModelCropperComponent;
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelTwoShow = false;//是否打开弹窗
  @Output() modelThreeShow = false;//是否打开弹窗
  @Output() modelFourShow = false;//是否打开弹窗
  @Output() modelFiveShow = false;//是否打开弹窗
  @Output() modelSixShow = false;//是否打开弹窗
  public config: Config;
  //企业数据(企业信息,修改资料的时候不用这个传递)
  public myDepartment:any;
  //需要修改的企业资料初始化
  public model = {
    industry:'',//所属行业
    employer_type:'',//企业性质
    region:'',//所在地区
    scale:'',//公司规模
    intro:'',//企业简介
    is_quoted_company:false,//是否是上市公司
    stock_code:'',//股票代码
    strengths:[],//公司实力
    official_account:'',//微信公众号
    website:'',//企业网址
    labels:[],//企业标签
    register_capital:null,//注册资金（万元）
    establish_year:null,//成立年份
    products:[],//产品
    courses:[],//发展历程
    image_urls:[],//照片展示
    video_link:'',//宣传视频链接
    register_capital_unit:'',
    employer_category:'',//企业注册地址，
  };
  //企业当前地址
  public workPlace = '';
  public productid="";//产品id
  public coursesid="";//发展历程id
  public register_capital_type = '万元'
  // public locations=[];
  public guideStep=false;
  constructor(public dataApi: DataApi,
              public local:Local,
              private router: Router,
              public dialog: MatDialog,
              private activatedRoute: ActivatedRoute,
              public opera:Opera,
              public formatDate: FormatDate,
              public regexManager:regexManager,
              public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
    this.config.isGuide=true;

    let that = this;
    let time =  setTimeout(()=>{
      that.config.isGuide=true;
      clearTimeout(time);
    },500)

    if(this.local.get(Constants.byCollegeDetails)){
      this.guideStep=true;
    }
    window.onpopstate = (event) => {
      console.log('onpopstate',event);
      window.location.reload()
    };
  }

  ngOnInit() {
    this.opera.initConfig('login','');
    let that = this;
    laydate.render({
      elem: '#establish1',
      type: 'year',
      done: function(value, date){
        that.model.establish_year = value;
      }
    })
    this.getDepartment();//获取企业信息
    this.getEmployerScale();//获取企业规模
  }

  //添加地址
  goAddAddress(){
    if(!this.myDepartment.locations){
      this.myDepartment.locations=[];
    }
    if(this.myDepartment.locations.length==5){
      this.toastr.warning("企业地址总数不得超过5个");
      return;
    }
    this.modelShow=true;
    this.child.initData("","添加地址");
  }

  //编辑地址
  goAddress(lid){
    this.modelShow=true;
    this.child.initData(lid,"编辑地址");
  }

  //子组件返回数据
  Modeldismiss(event){
    this.modelShow=false;
    if(event){
      //重新赋值地址
      this.dataApi.getDepartment().then((res:any)=>{
        // console.log("企业信息获取线上数据-----保存地址后");
        // console.log(res);
        this.myDepartment.locations=res.locations;
        this.workPlace=res.locations[0].lid;
        this.dataApi.setLocalDepartment(res);
      }).catch((error) => {
        //console.log(error);
        this.toastr.error(error);
      });
    }
  }

  //去企业发展历程
  goCourses(){
    this.coursesid="";
    this.modelFourShow=true;
    this.child3.initData(false);
    this.opera.scrollTop();//页面滚动函数
  }

  //去编辑企业发展历程
  goEditorCourses(n){
    this.coursesid=n.cid;
    this.modelFourShow=true;
    this.child3.initData(n);
    this.opera.scrollTop();//页面滚动函数
  }

  //接收子组件返回数据
  ModelFourdismiss(event){
    this.modelFourShow = false;
    this.opera.scrollTrue();
    if(event){
      //重新赋值企业产品
      if(!this.coursesid){//添加
        this.model.courses.push(event);
      }else{
        if(event=='delete'){//删除
          for(let n of this.model.courses){
            if(n.cid == this.coursesid){
              this.model.courses.splice(this.model.courses.indexOf(n),1);
            }
          }
        }else{//修改
          for(let n of this.model.courses){
            if(n.cid == this.coursesid){
              n = event;
            }
          }
        }
      }
      this.myDepartment.courses=this.model.courses;
      this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
    }
  }

  //去企业产品
  goProduct(){
    this.productid="";
    this.modelThreeShow=true;
    this.child2.initData(false);
    this.opera.scrollTop();//页面滚动函数
  }
  //编辑企业产品
  goEditorProduct(n){
    this.productid=n.pid;
    this.modelThreeShow=true;
    this.child2.initData(n);
    this.opera.scrollTop();//页面滚动函数
  }

  //接收子组件返回数据
  ModelThreedismiss(event){
    this.modelThreeShow = false;
    this.opera.scrollTrue();
    if(event){
      //重新赋值企业产品
      if(!this.productid){//添加
        this.model.products.push(event);
      }else{
        if(event=='delete'){//删除
          for(let n of this.model.products){
            if(n.pid == this.productid){
              this.model.products.splice(this.model.products.indexOf(n),1);
            }
          }
        }else{//修改
          for(let n of this.model.products){
            if(n.pid == this.productid){
              n = event;
            }
          }
        }
      }
      this.myDepartment.products=this.model.products;
      this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
    }
  }

  //上传logo
  previewPic(event) {
    let imgData  = event.target.files[0]; //获取file对象
    if (parseInt(imgData.size)/1024 > 1024*5) {
      this.toastr.warning('该图片大小超出了5M，请重新上传');
      return ;
    }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.imageCropper(reader.result);
      };

    }
  }

  //裁剪插件
  imageCropper(imageData){
    this.modelSixShow=true;
    this.child5.initData(imageData);
    this.opera.scrollTop();//页面滚动函数
  }

//接收子组件返回数据
  ModelSixdismiss(event){
    this.modelSixShow = false;
    this.opera.scrollTrue();
    if(event){
      this.uploadDepLogoImg(event);
    }
  }

//上传
  uploadDepLogoImg(imageData){
    imageData=imageData.replace(/^(data:image)\/png;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
    this.opera.loading();//加载动画
    this.dataApi.uploadDepartmentLogo({value: imageData}).then((res:any)=>{
      this.myDepartment.logo = res.value;
      this.opera.loadiss();//停止加载动画
      this.dataApi.setLocalDepartment(this.myDepartment);
      setTimeout(() => {
        this.toastr.success('上传成功');
      },600);
    }).catch((error)=>{
      this.opera.loadiss();//停止加载动画
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    })
  }

  //企业展示图片
  previewDepartmentPic(event){
    if(this.model.image_urls.length>9){
      this.toastr.warning("企业展示图片最多上传10张");
      return;
    }
    let imgData  = event.target.files[0]; //获取file对象
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.uploadDepImg(reader.result);
      };

    }
  }

  //上传企业展示图片
  uploadDepImg(data){
    let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
    // let loader = this.tip.presentLoading('图片保存中，请稍等...');
    this.dataApi.uploadDepartmentImg({
      value: imageData
    }).then((res:any)=>{
      // loader.dismiss();
      this.model.image_urls.push(res.value);
    }).catch((error)=>{
      // loader.dismiss();
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    })
  }

  //去企业标签
  goLabel(){
    this.modelTwoShow = true;
    this.opera.scrollTop();//页面滚动函数
    let newArray=JSON.parse(JSON.stringify(this.model.labels));
    console.log('企业标签',newArray);
    //调用子组件函数
    this.child1.initData(newArray);
  }

  //接收子组件返回数据
  ModelTwodismiss(event){
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    if(event){
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      //重新赋值企业标签
      this.model.labels=event;
      this.myDepartment.labels=this.model.labels;
      this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
    }
  }

  //去公司实力
  goStrengths(){
    this.modelFiveShow = true;
    this.opera.scrollTop();//页面滚动函数
    let newArray=JSON.parse(JSON.stringify(this.model.strengths));
    //调用子组件函数
    this.child4.initData(newArray);
  }

  //接收子组件返回数据
  ModelFivedismiss(event){
    this.modelFiveShow = false;
    this.opera.scrollTrue();
    if(event){
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      //重新赋值企业标签
      this.model.strengths=event;
      this.myDepartment.strengths=this.model.strengths;
      this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
    }
  }

  //获取企业规模字典
  getEmployerScale(){
    if(this.dataApi.getScaleCode()){
      this.config.scale=this.dataApi.getScaleCode();
    }else{
      //获取企业规模字典
      this.dataApi.getDictDate('scale').then((res:any)=>{
        this.config.scale = res;
        this.dataApi.setScaleCode(res);
      }).catch((error)=>{
        //console.log(error);
      });
    }

  }
  goBackStep(){
    this.local.set('resetOneStep',true);
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '返回上一步后，当前页面填写内容将不会保存。',
        trueText:'继续返回',
        falseText:'取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      if(isConfirmed){
          this.router.navigateByUrl('/guide');
      }
    });
  }
  //保存、更新企业信息
  updateDepartment(){
    let filter=/^[0-9]+(.[0-9]{1,9})?$/;
    //必填项验证
    if(!this.model.employer_type){
      this.toastr.warning("请填写公司性质");
      return;
    }
    if(!this.model.register_capital){
      this.toastr.warning("请填写注册资金");
      return;
    }else if(!filter.test(this.model.register_capital)){
      this.toastr.error("注册资金只能填写数字或两位小数");
      return;
    }
    if(!this.workPlace){
      this.toastr.warning("请填写公司地址");
      return;
    }
    if(!this.model.establish_year){
      this.toastr.warning("请填写成立年份");
      return;
    }
    if(this.model.establish_year)
    {
      if(!this.regexManager.isEstablish_year(this.model.establish_year))
      {
        this.toastr.warning("公司成立年份格式错误");
        this.model.establish_year = "";
        return;
      }
    }
    if(!this.model.scale){
      this.toastr.warning("请填写员工人数");
      return;
    }
    if(!this.model.intro){
      this.toastr.warning("请填写公司简介");
      return;
    }else{
      if (this.model.intro.length<50) {
        this.toastr.warning('公司简介不得少于50字');
        return;
      }
      if (this.model.intro.length>500) {
        this.toastr.warning('公司简介不得多于500字');
        return;
      }
    }
    if(this.model.is_quoted_company&&!this.model.stock_code){
      this.toastr.warning("请填写公司股票代码");
      return;
    }
    this.model.register_capital_unit = this.register_capital_type;
    if(this.register_capital_type=='亿元')
    {
      this.model.register_capital = parseFloat( this.model.register_capital)*10000+"";
    }
    else
    {

    }
    //console.log(this.model);
    //-------更新企业展示图片
    if(this.model.image_urls&&this.model.image_urls.length>0){
      this.dataApi.uploadDepartmentShow(this.model.image_urls).then((res:any)=>{
        this.myDepartment.image_urls = this.model.image_urls;//企业展示
      }).catch((error)=>{
        setTimeout(() => {
          this.toastr.error(error);
        },600);
      });
    }
    //更新、保存企业信息
    this.dataApi.updateDepartment(this.model).then((res:any)=>{
      //-----更新企业信息本地存储
      this.myDepartment.industry = this.model.industry;//所属行业
      this.myDepartment.employer_type = this.model.employer_type;//企业性质
      //  this.myDepartment.region = this.model.region;//所在地区
      this.myDepartment.scale = this.model.scale;//公司规模(员工人数)
      this.myDepartment.website = this.model.website;//企业网址
      this.myDepartment.intro = this.model.intro;//企业简介
      this.myDepartment.is_quoted_company = this.model.is_quoted_company;//是否上市
      this.myDepartment.stock_code = this.model.stock_code;//股票代码
      this.myDepartment.register_capital = this.model.register_capital;//注册资金
      this.myDepartment.establish_year = this.model.establish_year;//成立年份
      this.myDepartment.products = this.model.products;//产品
      this.myDepartment.courses = this.model.courses;//发展历程
      this.myDepartment.video_link = this.model.video_link;//企业视频
      this.myDepartment.official_account = this.model.official_account;//微信公众号
      //更新企业信息本地存储字段
      //公司规模
      if(this.myDepartment.scale){
        for(let n of this.config.scale){
          if(n.value==this.myDepartment.scale){
            this.myDepartment.scale_text=n.text;
          }
        }
      }
      //公司性质
      if(this.myDepartment.employer_type){
        for(let n of this.config.employer_type){
          if(n.value==this.myDepartment.employer_type){
            this.myDepartment.employer_type_text=n.text;
          }
        }
      }
      //所属行业
      if(this.myDepartment.industry){
        for(let n of this.config.industry){
          if(n.code==this.myDepartment.industry){
            this.myDepartment.industry_text=n.text;
          }
        }
      }
      //判断是否填写了成立年份-----------当前页面展示
      if(this.model.establish_year){
        this.model.establish_year= String(this.model.establish_year);
      }else{
        this.model.establish_year = null;
      }
      this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
      setTimeout(() => {
        // if(this.guideStep){
        //   this.toastr.success("保存成功");
        //   this.router.navigateByUrl('identity');
        // }else{
        //   // this.router.navigate(['home/position-publish']);
        //   // this.toastr.warning("当前没有在期职位，请发布职位");
        //   this.router.navigateByUrl('guide-audit');
        //
        // }
        // if(!this.config.userisBindWx){
        //   this.router.navigate(['identity-status']);
        // }else {
          this.router.navigate(['home/workSpase']);
        // }
      },600);
    }).catch((error) => {
      //console.log(error);
      setTimeout(() => {
        this.toastr.error(error);
      },600);
    });
  }

  //初始化当前页面数据
  initDeparment(_department){
    this.myDepartment = _department;
    //当前页面使用不到的值
    if(this.myDepartment.register_capital){//注册资金
      this.model.register_capital = this.myDepartment.register_capital;
    }
    if(this.myDepartment.establish_year){//成立年份
      // this.model.establish_year = String(this.myDepartment.establish_year);
      this.model.establish_year = this.myDepartment.establish_year;
    }
    if(this.myDepartment.products){//产品
      this.model.products = this.myDepartment.products;
    }
    if(this.myDepartment.courses){//发展历程
      this.model.courses = this.myDepartment.courses;
    }
    if(this.myDepartment.image_urls){//展示图片
      this.model.image_urls = this.myDepartment.image_urls;
    }
    if(this.myDepartment.video_link){//视频链接
      this.model.video_link = this.myDepartment.video_link;
    }
    //给当前页面使用到的值赋值,一面对象属性互相影响
    if(this.myDepartment.industry){//所属行业
      this.model.industry = this.myDepartment.industry;
    }
    if(this.myDepartment.employer_type){//企业性质
      this.model.employer_type = this.myDepartment.employer_type;
    }
    // if(this.myDepartment.region){//所在地区
    //   this.model.region = this.myDepartment.region;
    // }

    if(this.myDepartment.scale){//公司规模(员工人数)
      this.model.scale = this.myDepartment.scale;
    }
    if(this.myDepartment.intro){//企业简介
      this.model.intro = this.myDepartment.intro;
    }
    if(this.myDepartment.website){//企业网址
      this.model.website = this.myDepartment.website;
    }
    if(this.myDepartment.labels){//企业标签
      this.model.labels = this.myDepartment.labels;
    }
    if(this.myDepartment.official_account){//微信公众号
      this.model.official_account = this.myDepartment.official_account;
    }
    //企业注册地址
    if(this.myDepartment.employer_category)
    {
      this.model.employer_category = this.myDepartment.employer_category;
    }

    //给公司地址默认赋值
    if(this.myDepartment.locations){
      if(this.myDepartment.locations.length>0){
        let is_default = false;
        this.myDepartment.locations.forEach((val)=>{
          if(val.is_default){
            is_default = true;
            this.workPlace = val.lid;
          }
        });
        if(!is_default){
          this.workPlace = this.myDepartment.locations[0].lid;
        }
      }
    }

  }

  //获取企业信息
  getDepartment(){
    this.dataApi.getDepartment().then((res:any)=>{
      console.log("企业信息获取线上数据");
      console.log(res);
      this.dataApi.setLocalDepartment(res);
      this.initDeparment(res);//初始化页面数据
    }).catch((error) => {
      //console.log(error);
      this.toastr.error(error);
    });
  }

  register_capitalChange(e){
    console.log('e',e,this.model.register_capital)
    if(this.register_capital_type == "亿元")
    {
      if(parseInt(e)>10000)
      {
        setTimeout(()=>{
          this.model.register_capital = "10000";
          this.register_capital_type = "亿元"
        },200)
      }
      return;
    }
    if(e>=10000)
    {
      console.log('e',this.model.register_capital)
      setTimeout(()=>{
        this.model.register_capital =  (parseFloat(e)/10000)+"";
        this.register_capital_type = "亿元"
      },200)
    }
    else
    {
      this.model.register_capital =  (parseInt(e)*10000)+"";
      this.register_capital_type = "万元"
    }
  }
  //注销账号
  logoutAccountClick(){
    this.dialog.open(LogoutAccountModelComponent, {
      data: {}
    }).afterClosed().subscribe((isConfirmed) => {
      console.log('isConfirmed',isConfirmed)
      if(isConfirmed){
      }
    });
  }

}
