<!-- 页头部分 -->
<!-- <div class="contentRight-header">企业中心 - 发票 - 电子发票</div> -->
<!-- 主体内容部分 -->
<br>
<div class="homepage-block">
  <div class="college-block">
    <!-- 页头部分 -->
    <div class="college-block-head collegeBlockMargin flex_row_between">
      <!-- <p>发票</p> -->
      <div class="college-tab">
        <em (click)="opera.goNewRouter('company-receipt')"><img src='assets/images/router-old.png'/>返回</em>
        <span class="bold">电子开票详情</span>
      </div>
      <!-- <span><a href="" >下载发票</a></span> -->
    </div>
    <!-- 开电子发票 -->
    <div class="college-block">
      <div class="sign-block flex_row_left">
          <!-- 发票抬头： -->
          <div class="sign-list">
            <span>发票抬头：</span>
            <em>{{invoice?.invoice_title}}</em>
          </div>
          <!-- 税号: -->
          <div class="sign-list"  *ngIf="invoice?.invoice_title_type=='enterprise_unit'">
            <span>税号：</span>
            <em>{{invoice?.register_no}}</em>
          </div>
          <!-- 发票内容： -->
          <div class="sign-list">
            <span>发票内容：</span>
            <em>信息技术服务费</em>
          </div>
          <!-- 发票金额： -->
          <div class="sign-list">
            <span>发票金额：</span>
            <em>{{invoice?.amount}}元</em>
          </div>
          <!-- 申请时间： -->
          <div class="sign-list">
            <span>申请时间：</span>
            <em>{{invoice?.add_time | date:'yyyy.MM.dd'}}</em>
          </div>
          <!-- 公司地址： -->
          <div class="sign-list" *ngIf="invoice?.invoice_title_type=='enterprise_unit'">
            <span>公司地址：</span>
            <em>{{invoice?.register_address}}</em>
          </div>
          <!-- 公司电话： -->
          <div class="sign-list" *ngIf="invoice?.invoice_title_type=='enterprise_unit'">
            <span>公司地址：</span>
            <em>{{invoice?.register_tel}}</em>
          </div>
          <!-- 开户银行 -->
          <div class="sign-list" *ngIf="invoice?.invoice_title_type=='enterprise_unit'">
            <span>开户银行：</span>
            <em>{{invoice.bank_name}}</em>
          </div>
          <!-- 银行账户 -->
          <div class="sign-list" *ngIf="invoice?.invoice_title_type=='enterprise_unit'">
            <span>银行账户：</span>
            <em>{{invoice.bank_no}}</em>
          </div>
        </div>

        <!-- 个人邮箱： -->
        <div class="sign-list emailB">
          <span><b class="tremind">接收邮箱：</b></span>
          <input type="text" [(ngModel)]="email" placeholder="请输入接收邮箱地址">
          <button class="purple tbutton" (click)="sendByNewEmail()">发送至该邮箱</button>
        </div>
        <!-- 保存 -->
        <div class="sign-submit">
          <button class="purple"><a href="{{invoice.link}}" download="">下载发票</a></button>
        </div>
      </div>
  </div>
</div>
