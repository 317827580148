<!-- 用户确认操作弹出框 -->
<div class="modal-dialog">
  <div  class="modal-content" [ngClass]="{'modal-content':model.invoice_title_type=='enterprise_unit','modal-content2':model.invoice_title_type!='enterprise_unit'}"  *ngIf="!isShowClose">
    <img src="../../assets/images/chips_colse.png"  class="close" (click)="closeClick()"/>

    <div class="cellRow m_top flex_l">
      <span class="cell_title">开票金额：</span>
      <div class="cellRow_r">{{model.amount}}</div>
    </div>
    <div class="cellRow flex_l">
      <span class="cell_title"><span *ngIf="!urm_create">*</span>抬头类型：</span>
      <div class="cellRow_r">
        <select class="downLoad_right"  [(ngModel)]="model.invoice_title_type" *ngIf="!urm_create">
          <option  value="" >请选择抬头类型</option>

          <option *ngFor="let n of invoice_title_typeArr" [value]="n.value" >{{n.name}}</option>
        </select>
        <span class="cellRow_r" *ngIf="urm_create" style="margin-left: 0;">
            企业单位
        </span>
      </div>
    </div>
    <div *ngIf="model.invoice_title_type=='enterprise_unit'" class="flex_l" >
      <div class="cellRow flex_l">
        <span class="cell_title"><span>*</span>发票抬头：</span>
        <div class="cellRow_r">
          <input placeholder="请输入发票抬头"  [disabled]="urm_create && !urm_create2"   type="text" [(ngModel)]="model.invoice_title" />
        </div>
      </div>
      <div class="cellRow flex_l">
        <span class="cell_title"><span>*</span>税号：</span>
        <div class="cellRow_r">
          <input placeholder="请输入税号"  type="text" [(ngModel)]="model.register_no" />
        </div>
      </div>
      <div class="cellRow flex_l">
        <span class="cell_title"><span>*</span>发票内容：</span>
        <div class="cellRow_r">
          <select  class="downLoad_right" [(ngModel)]="model.tax_classification">
            <option value="" disabled>请选择发票内容</option>
            <option  *ngFor="let n of tax_classifications" [value]="n.code">{{n.text}}</option>
          </select>
        </div>
      </div>
      <div class="cellRow flex_l">
        <span class="cell_title"><span>*</span>邮箱：</span>
        <div class="cellRow_r">
          <input placeholder="请输入邮箱"  type="text" [(ngModel)]="model.email" />
        </div>
      </div>
      <span class="emailChips">电子发票将发送至邮箱中，请确认邮箱正确可用</span>
      <div class="cellRow flex_l">
        <span class="cell_title">公司地址：</span>
        <div class="cellRow_r">
          <input placeholder="请输入公司地址" type="text" [(ngModel)]="model.register_address"/>
        </div>
      </div>
      <div class="cellRow flex_l">
        <span class="cell_title">公司电话：</span>
        <div class="cellRow_r">
          <input placeholder="请输入公司电话" type="text" [(ngModel)]="model.register_tel" />
        </div>
      </div>
      <div class="cellRow flex_l">
        <span class="cell_title">开户银行：</span>
        <div class="cellRow_r">
          <input placeholder="请输入开户银行" [(ngModel)]="model.bank_name"  />
        </div>
      </div>
      <div class="cellRow flex_l">
        <span class="cell_title">银行账号：</span>
        <div class="cellRow_r">
          <input placeholder="请输入银行账号" type="text" [(ngModel)]="model.bank_no"  />
        </div>
      </div>
      <!--<div class="cellRow flex_l">-->
        <!--<span class="cell_title"><span *ngIf="!urm_create">*</span>备注：</span>-->
        <!--<div class="cellRow_r">-->
          <!--<textarea  minlength="50" [(ngModel)]="model.remark" maxlength="500" style="height: 120px" placeholder="请填写备注">-->
          <!--</textarea>-->
        <!--</div>-->
      <!--</div>-->
    </div>
    <div *ngIf="model.invoice_title_type=='personal'" class="flex_l" >
      <div class="cellRow flex_l">
        <span class="cell_title"><span>*</span>姓名：</span>
        <div class="cellRow_r">
          <input placeholder="请输入姓名" type="text" [(ngModel)]="model.invoice_title"  />
        </div>
      </div>

      <div class="cellRow flex_l">
        <span class="cell_title"><span>*</span>身份证号：</span>
        <div class="cellRow_r">
          <input placeholder="请输入身份证号" type="text" [(ngModel)]="model.id_number"  />
        </div>
      </div>
      <div class="cellRow flex_l">
        <span class="cell_title"><span>*</span>发票内容：</span>
        <div class="cellRow_r">
          <select class="downLoad_right" [(ngModel)]="model.tax_classification">
            <option value="" disabled>请选择发票内容</option>
            <option  *ngFor="let n of tax_classifications" [value]="n.code">{{n.text}}</option>
          </select>
        </div>
      </div>
      <div class="cellRow flex_l">
        <span class="cell_title"><span>*</span>个人邮箱：</span>
        <div class="cellRow_r">
          <input placeholder="请输入个人邮箱"  type="text" [(ngModel)]="model.email" />
        </div>
      </div>
      <span class="emailChips">电子发票将发送至邮箱中，请确认邮箱正确可用</span>

      <!--<div class="cellRow flex_l">-->
        <!--<span class="cell_title"><span>*</span>备注：</span>-->
        <!--<div class="cellRow_r">-->
          <!--<textarea minlength="50" [(ngModel)]="model.remark" maxlength="500"  style="height: 120px" placeholder="请填写备注"></textarea>-->
        <!--</div>-->
      <!--</div>-->
    </div>

    <button class="commitBtn bordeRadius" [ngClass]="{'commitBtn_time':timerIndex!=5}" *ngIf="!isVirty" (click)="goApply()">{{timerIndex==5?"提交信息":timerIndex+'s'}} </button>
    <button class="commitBtn bordeRadius" *ngIf="isVirty" (click)="appHTTP()">确认提交 </button>
    <span class="emailChips2" *ngIf="isVirty||timerIndex!=5">请核对填写信息是否正确，确保可收到正确的发票</span>

  </div>

  <div  class="modal-content-close animated shake" *ngIf="isShowClose">
    <div class="closeTitle" #msgBox>关闭后再次开票时已输入的内容需重新填，<br/>是否确认关闭？</div>
    <div class="closeBtnView flex_c">
      <div class="fangqi bordeRadius" (click)="closeModelClick(2)">确定</div>
      <div class="jixu bordeRadius" (click)="closeModelClick(1)">取消</div>
    </div>
  </div>

</div>

