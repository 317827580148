<!-- V3.0 -->
<div class="headerReturn">
    <div class="center" *ngIf="!config.isByCollege" (click)="opera.goPre()">
        <img src="assets/images/v3/headerReturnIcon.png">空中双选会会场
    </div>
    <div class="center" *ngIf="config.isByCollege" (click)="opera.goNewRouter('college-details-milk')">
        <img src="assets/images/v3/headerReturnIcon.png">空中双选会会场
    </div>
</div>
<app-model-send-msg #sengMsgModel [SengMsgModel]='SengMsgModel' [tconfig]='config' (onVoted)="Modeldismiss($event)" [isShow]="modelShowSengMsg"></app-model-send-msg>
<app-voice-call-model #voiceCallModel [isShow]="isShowVoiceCallModel" (modelHidden)="voiceCallModelHidden($event)">
</app-voice-call-model>

<!-- 遮罩层部分 -->
<router-outlet></router-outlet>
<div class="informationMain">
    <div class="center">
        <div class="name">{{milk.name}}</div>
        <div class="jieduan">
            <div class="item item01" *ngIf="!isMilkStart">
                <div class="lab">即将开始</div>
                <div class="time"><img src="/assets/images/rights/time01.png">活动结束：{{milk.end_time | date:"yyyy-MM-dd HH:mm"}}</div>
            </div>
            <div class="item item02" *ngIf="isMilkStart && !isMilkEnd">
                <div class="lab">正在进行</div>
                <div class="time"><img src="/assets/images/rights/time02.png">活动结束：{{milk.end_time | date:"yyyy-MM-dd HH:mm"}}</div>
            </div>
            <div class="item item03" *ngIf="isMilkStart && isMilkEnd">
                <div class="lab">已经结束</div>
                <div class="time"><img src="/assets/images/rights/time03.png">活动结束：{{milk.end_time | date:"yyyy-MM-dd HH:mm"}}</div>
            </div>
        </div>
        <div class="school">
            <div class="logo"><img [src]="milk.logo" alt="高校logo"></div>
            <div class="schoolName">{{milk.college_name}}</div>
        </div>
        <div class="contact">
            <div class="item"><img src="/assets/images/v3/milkdetails_introduce04.png">联系人：{{milk.contacts}}</div>
            <div class="item"><img src="/assets/images/v3/milkdetails_introduce06.png">联系邮箱：{{milk.email}}</div>
        </div>
        <div class="nav">
            <div class="item" [class.active]="model.status == 'all'" (click)="selectStu('all')">全部学生<span *ngIf="model.status == 'all'"></span></div>
            <div class="item" [class.active]="model.status == 'is_view'" (click)="selectStu('is_view')">查看过我的学生<span *ngIf="model.status == 'is_view'"></span></div>
            <div class="item" [class.active]="model.status == 'is_delivery'" (click)="selectStu('is_delivery')">投递过我的学生<span *ngIf="model.status == 'is_delivery'"></span></div>
        </div>
    </div>
</div>

<div class="search">
    <select class="seleteCard" name="major" id="college" [(ngModel)]="model.collegeid" (ngModelChange)="getResumeList()" autocomplete="off">
        <option class="place-text" value='' disabled hidden selected="selected">选择学校</option>
        <option class="place-text" value=''>不限</option>
        <option *ngFor="let a of allCollege" [value]="a.collegeid">{{a.name}}</option>
    </select>
    <select class="seleteCard" name="major" id="major" [(ngModel)]="majorOne" (ngModelChange)="majorOneChange()" autocomplete="off">
        <option class="place-text" value='' disabled hidden selected="selected">选择一级专业</option>
        <option class="place-text" value=''>不限</option>
        <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
    </select>
    <select class="seleteCard" name="majorTwo" id="majorTwo" [(ngModel)]="model.major" (ngModelChange)="getResumeList()" (click)="isShowTwo()" autocomplete="off">
        <option class="place-text" value='' disabled hidden selected="selected">选择二级专业</option>
        <option class="place-text" value=''>不限</option>
        <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
    </select>
    <select class="seleteCard" name="education" id="education" [(ngModel)]="model.education" (ngModelChange)="getResumeList()" autocomplete="off">
        <option class="place-text" value=''>不限</option>
        <option *ngFor="let a of educationList" [value]="a.code">{{a.text}}</option>
    </select>
</div>

<div class="resumeLits">
    <div class="center">
        <div class="item" *ngFor="let n of resumeList;let i = index;" (click)="goDetails(n)">
            <div class="main">
                <div class="introduce">
                    <div class="logo">
                        <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/55/h/55'}}" />
                        <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png" />
                        <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png" />
                        <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png" />
                    </div>
                    <div class="text">
                        <div class="name">{{n.talent_name | clearSpace}}</div>
                        <div class="college">{{n.graduate_university}}</div>
                        <div class="major">{{n.major_text}} | {{n.education_text}} | {{n.year==0?"往届":n.year+'届'}}</div>
                    </div>
                </div>
                <div class="lable">
                    <span *ngFor="let youshi of n.youshiArr | slice:0:3;let i = index;">{{youshi}}</span>
                </div>
                <div class="bottomBtns" *ngIf="!n.is_delivery">
                    <div class="btn" (click)="ShowPhoneModel(n,$event)">
                        <img src="/assets/images/v3/speedIcon.png"><span>极速电话联系</span>
                    </div>
                    <div class="btn active" (click)="alertSengMSG(n,$event)">
                        <img src="/assets/images/v3/pushIcon.png"><span>职位推送</span>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>

<!-- 没有内容存在时 -->
<div *ngIf="countList==0" class="none-block">
    <img src="assets/images/resume-search-fail.png"><br /> 暂无相关简历
</div>

<!-- 分页部分 -->
<div class='paging-block' [class.isPageHeight]="countList>0">
    <router-outlet></router-outlet>
    <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
</div>


<div class="bottomBox">
    <div class="barrage-box">
        <div class="barrage-box-l">
            <img src="/assets/images/v3/Participation.png" />
            <div class="leftNam">参会学生<span>(数据实时更新中)</span></div>
        </div>
        <div class="barrage-box-r">
            <div class="bar-scroll">
                <div class="item" *ngFor="let a of arrageList">{{a}}</div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="contentRight-header">
    <img *ngIf="!config.isByCollege" (click)="opera.goPre()" src='assets/images/router-old.png' />
    <img *ngIf="config.isByCollege" (click)="opera.goNewRouter('college-details-milk')" src='assets/images/router-old.png' /> 空中双选会会场
</div> -->

<div class="homepage-block" style="display:none">
    <!-- <app-model-send-msg #sengMsgModel [SengMsgModel]='SengMsgModel' [tconfig]='config' (onVoted)="Modeldismiss($event)" [isShow]="modelShowSengMsg"></app-model-send-msg>
    <app-voice-call-model #voiceCallModel [isShow]="isShowVoiceCallModel" (modelHidden)="voiceCallModelHidden($event)">
    </app-voice-call-model> -->

    <!-- 遮罩层部分 -->
    <!-- <router-outlet></router-outlet> -->
    <div class="college-block" style="margin:20px 0;">
        <!-- 详情模块 -->
        <div class="college-head-details clear" style="padding: 30px 50px;" *ngIf="milk">
            <!-- 左侧文字 -->
            <div class="left">
                <div class="item">
                    <div><img src="../../assets/images/air_name.png" /><span>空中双选会名称：&nbsp;&nbsp;</span>{{milk.name}}<i *ngIf="!milk.name">暂无</i></div>
                </div>
                <div class="item">
                    <div style="width: 360px" *ngIf="!isMilkStart"><img src="../../assets/images/air_time.png" /><span>当前状态：&nbsp;&nbsp;</span>即将开始</div>
                    <div style="width: 360px" *ngIf="isMilkStart"><img src="../../assets/images/air_time.png" /><span>当前状态：&nbsp;&nbsp;</span>{{isMilkEnd?'已经结束':'正在进行'}}
                    </div>
                    <div><img src="../../assets/images/air_time.png" /><span>结束时间：&nbsp;&nbsp;</span>{{milk.end_time | date:"yyyy-MM-dd HH:mm"}}<i *ngIf="!milk.end_time">暂无</i></div>
                </div>
                <div class="space"></div>
                <div class="item">
                    <div style="width: 360px"><img style="width: 44px;height: 44px;border-radius: 50%;margin-right: 4px" src="{{milk.logo}}" /><span>举办学校：&nbsp;&nbsp;</span>{{milk.college_name}}<i *ngIf="!milk.place_name">暂无</i></div>
                    <div><img src="../../assets/images/air_phone.png" /><span>联系人：&nbsp;&nbsp;</span>{{milk.contacts}}<i *ngIf="!milk.contacts">暂无</i></div>
                    <div style="margin-left: 40px"><img src="../../assets/images/air_mail.png" /><span>联系邮箱：&nbsp;&nbsp;</span>{{milk.email}}<i *ngIf="!milk.email">暂无</i></div>
                </div>
            </div>
            <!-- 右侧logo -->
            <div class="right">
                <img style="width: 200px;margin-right: 60px;border-radius:0;margin-top: 20px" [src]="milk.logo" alt="高校logo">
            </div>
        </div>
    </div>


    <div class="college-block">
        <!-- 筛选项 -->
        <div class="college-resume-head clear">
            <div class="left left-box">
                <div class="sel-btn bordeRadius" [class.active]="model.status == 'all'" (click)="selectStu('all')">全部学生
                </div>
                <div class="sel-btn bordeRadius" [class.active]="model.status == 'is_view'" (click)="selectStu('is_view')">查看过我的学生</div>
                <div class="sel-btn bordeRadius" [class.active]="model.status == 'is_delivery'" (click)="selectStu('is_delivery')">投递过我的学生</div>
            </div>
            <div class="right">
                <!-- 选择所在城市[(ngModel)]="" -->
                <select class="seleteCard" name="major" id="college" [(ngModel)]="model.collegeid" (ngModelChange)="getResumeList()" autocomplete="off">
                    <option class="place-text" value='' disabled hidden selected="selected">选择学校</option>
                    <option class="place-text" value=''>不限</option>
                    <option *ngFor="let a of allCollege" [value]="a.collegeid">{{a.name}}</option>
                </select>
                <select class="seleteCard" name="major" id="major" [(ngModel)]="majorOne" (ngModelChange)="majorOneChange()" autocomplete="off">
                    <option class="place-text" value='' disabled hidden selected="selected">选择一级专业</option>
                    <option class="place-text" value=''>不限</option>
                    <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
                </select>
                <select class="seleteCard" name="majorTwo" id="majorTwo" [(ngModel)]="model.major" (ngModelChange)="getResumeList()" (click)="isShowTwo()" autocomplete="off">
                    <option class="place-text" value='' disabled hidden selected="selected">选择二级专业</option>
                    <option class="place-text" value=''>不限</option>
                    <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
                </select>
                <select class="seleteCard" name="education" id="education" [(ngModel)]="model.education" (ngModelChange)="getResumeList()" autocomplete="off">
                    <option class="place-text" value=''>不限</option>
                    <option *ngFor="let a of educationList" [value]="a.code">{{a.text}}</option>
                </select>
            </div>
        </div>
        <!-- 中间部分 -->
        <!-- 没有内容存在时 -->
        <div *ngIf="countList==0" class="none-block">
            <img src="assets/images/resume-search-fail.png"><br /> 暂无相关简历
        </div>
        <!--推荐职位-->
        <div class="positionView">
            <div (click)="goDetails(n)" class="positionItem" *ngFor="let n of resumeList;let i = index;">
                <div class="positionItem_Top">
                    <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}" />
                    <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png" />
                    <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png" />
                    <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png" />
                    <div class="info">
                        <div class="name">{{n.talent_name | clearSpace}}</div>
                        <div class="school">{{n.graduate_university}}</div>
                    </div>
                </div>
                <div class="positionItem_Center">
                    <div class="tag">
                        <div class="item">
                            <img src="../../assets/images/work/major.png" class="icon" />
                            <span>{{n.major_text}}</span>
                        </div>
                        <div class="item">
                            <img src="../../assets/images/work/education.png" class="icon" />
                            <span>{{n.education_text}}</span>
                        </div>
                        <div class="item">
                            <img src="../../assets/images/jiebieIcon.png" class="icon" />
                            <span>{{n.year==0?"往届":n.year+'届'}}</span>
                        </div>
                    </div>
                    <div class="skill">
                        <div *ngFor="let youshi of n.youshiArr | slice:0:4;let i = index;" class="item">{{youshi}}</div>
                    </div>
                </div>
                <div class="positionItem_Bottom">
                    <div *ngIf="!n.is_delivery" class="positionItem_Bottom_item bordeRadius" (click)="ShowPhoneModel(n,$event)">电话联系</div>
                    <a *ngIf="!n.is_delivery" title="可以将职位定向推送给该学生" class="positionItem_Bottom_item purple bordeRadius" (click)="alertSengMSG(n,$event)">职位推送</a>
                    <div *ngIf="n.is_delivery" class="positionItem_Bottom_item1 purple bordeRadius">查看简历</div>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>