<!-- 页头部分 -->
<!-- <div class="contentRight-header">高校云 - 双选会报名</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block ">
    <img (click)="opera.scrollTop1()" src="../../assets/images/go_top.png" style="width: 60px;height: 60px;position: fixed;bottom: 100px;right: 50px;cursor: pointer;z-index: 10" />
    <!-- 遮罩层部分 -->
    <!--<app-model-college-milksign [number]="milkNumber" [mridList]="mridList" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-milksign>-->
    <!-- 新版 -->
    <div class="milkround-container">
        <div class="header-container">
            <div class="secondNav">
                <div class="item active">双选会报名<span></span></div>
                <div class="item" (click)="gocampustalk()">宣讲会报名</div>
            </div>
            <div class="banner">
                <div class="layui-carousel" id="test1" *ngIf="bannerList.length>0">
                    <div carousel-item>
                        <div *ngFor="let n of bannerList;let i = index;">
                            <img class="swiper-slide bannerImage" (click)="bannerClick(n)" id={{i}} src="{{n.image_url}}" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 非会员 -->
            <div class="novipTip" *ngIf="!isShowMemberMoudel">
                <div class="text"> 非会员用户只能在「工作啦-大学生直聘」一站报名、管理 3 所高校招聘活动。（您也可到各高校就业信息网逐一报名）</div>
                <div class="join joinHover" (click)="product('','contactMe')">加入会员</div>
            </div>
        </div>
        <!-- tab  -->
        <div class="tabs">
            <div class="item" [class.purple]="activeName=='02'" (click)="othergetMilkList()">可预约高校<span></span></div>
            <div class="item fn" [class.purple]="activeName=='01'" (click)="mygetMilkList()">我的申请记录<span></span></div>
        </div>
        <div class="more" *ngIf="activeName=='01'" (click)="goSignList()">查看全部申请记录<img src="/assets/images/rights/more.png"></div>
        <!-- 我的申请记录  -->
        <div class="my-college-list" *ngIf="activeName=='01'">
            <div class="item" *ngFor="let n of historyMilkRoundList;let i = index;">
                <div class="box">
                    <div class="logo">
                        <img src="{{n.logo}}">
                    </div>
                    <div class="main">
                        <div class="name" (click)="goDeatils(n)">
                            <span class="session" *ngIf="n.is_welfare">平台活动</span>
                            <span class="sky" *ngIf="n.milkround_type == 2">空中双选会</span> {{n.name}}
                        </div>
                        <div class="ul">
                            <div class="li"><img src="/assets/images/rights/college-list-icon01.png">{{n.college_name}}</div>
                            <div class="li"><img src="/assets/images/rights/college-list-icon02.png">举办日期 {{n.start_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                            <div class="li"><img src="/assets/images/rights/college-list-icon03.png"> {{n.milkround_type == 2?"线上":n.place_name?n.place_name:'暂无'}}
                                <span *ngIf="n.milkround_type == 2 && n.is_online_interview"><img src="/assets/images/rights/video.png">视频双选会</span>
                            </div>
                        </div>
                        <!-- 报名状态  -->
                        <div class="state">
                            <div class="left">
                                <!-- 报名阶段  -->
                                <div class="state-item" *ngIf="n.loadState==2">
                                    <div class="icon">报名阶段</div>
                                    <div class="time"><img src="/assets/images/rights/time01.png">报名截止：{{n.apply_end_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                                </div>
                                <!-- 进行中  -->
                                <div class="state-item conduct" *ngIf="n.loadState==4">
                                    <div class="icon"><img src="/assets/images/rights/conduct-icon.png">进行中</div>
                                    <div class="time"><img src="/assets/images/rights/time02.png">活动结束：{{n.end_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                                </div>
                                <!-- 已结束  -->
                                <div class="state-item end" *ngIf="n.loadState==5">
                                    <div class="icon">已结束</div>
                                    <div class="time"><img src="/assets/images/rights/time03.png">活动结束：{{n.end_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                                </div>
                            </div>
                            <div class="right">
                                <div class="ste01" (click)="downloadPoster(n.applyid)" *ngIf="n.status == 'passed' && !n.isEnd && n.poster_type">下载海报</div>
                                <div class="ste02" (click)="goSign(n)" *ngIf="n.showShenHeBtn">报名审核中</div>
                                <div class="ste03" (click)="goSign(n)" *ngIf="n.showFaileBtn">审核未通过</div>
                                <div class="ste03" *ngIf="n.audit_status == 'revoke'">已撤回申请</div>
                            </div>

                        </div>
                        <!-- 操作区  -->
                        <div class="operation">
                            <div class="interViewconfirm" (click)="okCanhui(n)" *ngIf="n.showSuccessBtn &&n.feedback_status=='no_feedback' && n.milkround_type == 2"><img src="/assets/images/rights/room.png">进入会场</div>
                            <div class="interViewconfirm" (click)="goRoom(n)" *ngIf="n.showSuccessBtn &&n.feedback_status=='no_feedback' && n.milkround_type == 2 && n.is_online_interview"><img src="/assets/images/rights/conference.png">进入面试间</div>

                            <div class="confirm" (click)="okCanhui(n)" *ngIf="n.showSuccessBtn &&n.feedback_status=='no_feedback' && n.milkround_type == 1">确认参会</div>
                            <div class="refuse" *ngIf="n.showSuccessBtn&&n.feedback_status=='no_join'">已拒绝</div>
                            <div class="notice" (click)="activityInvitation(n)" *ngIf="n.showSuccessBtn && n.feedback_status=='join'&&n.curr_total_student>=1000 &&opera.isShowPaySuccessModule1()&&n.milkround_type != 2">通知学生到展位</div>
                            <div class="confirm_yes" (click)="ConfirmMilkroundCommit2(n)" *ngIf="n.showSuccessBtn&&n.feedback_status=='join'&&n.milkround_type != 2">已确认</div>
                            <div class="BoothNo" *ngIf="n.showSuccessBtn&&n.feedback_status=='join'&&n.milkround_type != 2">
                                {{n.seat_number==0?"展位号暂未分配":'展位号：'+n.zone_name_string+n.seat_number}}</div>
                            <div class="withdraw" *ngIf="n.showShenHeBtn" (click)="openRevoke(n.applyid)">撤回申请</div>
                            <div class="interViewconfirm" *ngIf="n.audit_status == 'revoke' && timeBefore(n.apply_end_time)" (click)="goSign(n)">重新申请</div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="emptyImg" *ngIf="historyMilkRoundList.length==0">
                <img src="/assets/images/rights/empty.png">
                <p>您的企业暂未报名活动</p>
            </div>
        </div>
        <!-- 可预约高校  -->
        <div class="other-college-list " *ngIf="activeName=='02'">
            <div class="search">
                <div class="cell">
                    <select class="filtrate_row_select_gray activeBg" style="margin-right: 8px" name="dateSattus" [(ngModel)]="dateSattus" (ngModelChange)="dateSattusSearch($event)" autocomplete="off">
                        <option value='all'>全部举办时间</option>
                        <option value="now">本月</option>
                        <option value="next">下月</option>
                    </select>
                    <select class="filtrate_row_select_gray activeBg" style="margin-right: 8px" name="milkround_type" [(ngModel)]="model.milkround_type" (ngModelChange)="milkround_typeSearch($event)" autocomplete="off">
                        <option value=''>全部举办形式</option>
                        <option value="2">线上</option>
                        <option value="1">线下</option>
                    </select>
                    <select name="city" class="filtrate_row_select_gray activeBg" id="collegeType" [(ngModel)]="model.school_type" (ngModelChange)="searchClick()" autocomplete="off">
                        <!-- <option class="place-text" value='' disabled hidden selected="selected">学校类型</option> -->
                        <option class="place-text" value=''>不限学校类型</option>
                        <option *ngFor="let city of config.school_type" [value]="city.code">{{city.text}}</option>
                    </select>
                </div>
                <div style="display: flex;">
                    <div class="input">
                        <img src="/assets/images/rights/search.png">
                        <input type="text" placeholder="请输入学校名称" [(ngModel)]="model.college_name" (keyup)="inputKeyup($event)">
                    </div>
                    <div class="searchBtn blueStreamer" (click)="othergetMilkList()">搜索</div>
                </div>
            </div>
            <div class="list my-college-list">
                <div class="item" *ngFor="let n of milkRoundList;let i = index;">
                    <div class="box">
                        <img *ngIf="n.is_top" class="top_icon" src="/assets/images/hot_title.png">
                        <div class="logo"><img src="{{n.logo}}"></div>
                        <div class="main">
                            <div class="name" (click)="goDeatils(n)">
                                <span class="session" *ngIf="n.is_welfare">平台活动</span>
                                <span class="sky" *ngIf="n.milkround_type == 2">空中双选会</span> {{n.name}}
                            </div>
                            <div class="ul">
                                <div class="li"><img src="/assets/images/rights/college-list-icon01.png">{{n.college_name}}</div>
                                <div class="li" style="font-weight: 600;color: #666;"><img src="/assets/images/rights/college-list-icon02.png">举办日期 {{n.start_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                                <div class="li" style="font-weight: 600;color: #666;"><img src="/assets/images/rights/college-list-icon03.png">{{n.milkround_type == 2?"线上":n.place_name?n.place_name:'暂无'}}
                                    <span *ngIf="n.milkround_type == 2 && n.is_online_interview"><img src="/assets/images/rights/video.png">视频双选会</span>
                                </div>
                            </div>
                            <!-- 报名状态  -->
                            <div class="state">
                                <div class="left">
                                    <!-- 报名阶段  -->
                                    <div class="state-item" *ngIf="n.loadState==2">
                                        <div class="icon">报名阶段</div>
                                        <div class="time"><img src="/assets/images/rights/time01.png">报名截止：{{n.apply_end_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                                    </div>
                                    <!-- 进行中  -->
                                    <div class="state-item conduct" *ngIf="n.loadState==4">
                                        <div class="icon"><img src="/assets/images/rights/conduct-icon.png">进行中</div>
                                        <div class="time"><img src="/assets/images/rights/time02.png">活动结束：{{n.end_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                                    </div>
                                    <!-- 已结束  -->
                                    <div class="state-item end" *ngIf="n.loadState==5">
                                        <div class="icon">已结束</div>
                                        <div class="time"><img src="/assets/images/rights/time03.png">活动结束：{{n.end_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                                    </div>
                                </div>
                                <div class="right">
                                    <div class="ste04" *ngIf="n.showBaoMingBtn &&(n.loadState != 1 && n.loadState != 5)" (click)="goSign(n)">我要报名</div>
                                    <div class="ste03" (click)="goSign(n)" *ngIf="n.showFaileBtn">审核未通过</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 分页部分 -->
            <div class='paging-block flex_r' [class.isPageHeight]="countList>0">
                <router-outlet></router-outlet>
                <div id="page"></div>
                <!--<app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>-->
            </div>
        </div>
    </div>



    <!-- <div class="con-r" *ngIf="opera.isShowPaySuccessModule1()">
        <div class="con-r-l">
            <img src="../../assets/images/talent/my_phone.png">
            <div class="info">
                <div class="top">校招活动邀请</div>
                <div class="bot">余量（次）：{{activity_invitation ? activity_invitation.left : '0'}}</div>
            </div>
        </div>
        <div (click)="product('','contactMe')" class="con-r-r bordeRadius">立即购买</div>
    </div> -->

</div>

<p-dialog [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':300,'height':532,'minHeight':532}" [modal]="true" [showHeader]="false" [(visible)]="showMilkCode">
    <div style="width: 300px;height: 532px;background-color: white;overflow: hidden;">
        <img (click)="closeModel()" src="{{QrCodeURL}}" style="width: 300px;height: 532px;float: right;margin-top: 0px;margin-right: 0px;background-color: #ffffff">
    </div>
</p-dialog>

<p-dialog [contentStyle]="{'padding':'0',border:0,'width':600}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="showConfirmMilkroundModel">
    <div style="width: 600px;background-color: white;overflow: hidden; padding-bottom: 13px;" *ngIf="currentMilkround">
        <p style="font-size: 23px;text-align: center;margin-top: 40px;">双选会确认函</p>
        <p style="font-size: 20px;width: 300px;margin-left: 150px;text-align: center;margin-top: 30px;">
            恭喜您，您申请的双选会"{{currentMilkround.name}}"已通过高校审核，请确认是否参加</p>
        <button (click)="ConfirmMilkroundCommit('no_join')" style="float: left;margin-left: 120px;width: 100px;background-color: gray;color: white;margin-top: 40px">不参加</button>
        <button (click)="ConfirmMilkroundCommit('join')" style="float: right;margin-right: 120px;width: 100px;background-color: #5e4fff;color: white;margin-top: 40px">参加</button>
    </div>
</p-dialog>

<!-- 新增高校 -->
<app-push-college-model #pushCollegeModel [isShow]="isShowPushCollegeModel" (modelHidden)="pushCollegeModelHidden($event)"></app-push-college-model>
<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>

<!-- 撤回双选会申请 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="revokeModule" [modal]=true>
    <div class="showTopingBox active">
        <div class="closIcon" (click)="revokeModule = false"><img src="/assets/images/v3/alertClos.png"></div>
        <div class="title">
            <div class="tipText">
                <div class="name">撤回报名</div>
            </div>
        </div>
        <div class="p2">确定撤回该双选会报名吗？</div>
        <div class="btns">
            <div class="btn active" (click)="revokeModule = false">取消</div>
            <div class="btn blueStreamer" (click)="downMilk()">确定</div>
        </div>
    </div>
</p-dialog>