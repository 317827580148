import {Component, OnInit, Output} from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-my-card',
  templateUrl: './my-card.component.html',
  styleUrls: ['./my-card.component.css']
})
export class MyCardComponent implements OnInit {

  public config: Config;
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = null;//分页数量Math.ceil(this.countList/this.model.limit)

  public tab = '1';
  public cardList = [];
  public currentCard = null;
  public showAlertDuihuan = false;

  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService
  ) {

  }

  ngOnInit() {
    let topNav = this.local.get('nav_tool')
    this.opera.initConfig('tool',topNav);
    this.getMyCardList(true);

  }
  //获取分页子组件返回数据
  getChildEvent(index){
    console.log('获取分页子组件返回数据',index);
    // this.pageNumber = index;


    if(index > 0){
      this.pageNumber = index;
      this.getMyCardList(false)    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.getMyCardList(false)
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.getMyCardList(false)
      }
    }


  }
  tabClick(type){
    this.tab = type;
    this.cardList = [];
    this.getMyCardList(true)
  }

  //获取我的卡劵列表
  getMyCardList(isRef){
    let status = '';
    if(this.tab=='1')
    {
        status = 'unused';
    }
    else if(this.tab=='2')
    {
      status = 'used';
    }
    else
    {
      status = 'expired';
    }
    let data={
      offset:isRef?0:(this.pageNumber-1)*8,
      limit:8,
      status:status
    }
    this.dataApi.getMyCardList(data).then((res:any)=>{
      console.log('获取我的卡劵列表',res);
      if(res){
        let arr = [];
        for (let index=0;index< res.list.length;index++)
        {
          let card  = res.list[index];
          if(card.coupon_category == "full_cut_coupon")
          {
            card.title = '满'+card.limited_amount+'减'+card.gift_amount;
            card.typeTitle = '满减';
          }
          else if(card.coupon_category == "full_add_coupon")
          {
            //满赠
            card.title = '满'+card.limited_amount+'赠'+card.gift_amount;
            card.typeTitle = '满赠';

          }
          else  if(card.coupon_category == "exchange_coupon")
          {
            card.title = card.gift_amount+'兑换卷';
            card.typeTitle = '兑换';

          }
          card.startTime = this.RiQi(Date.parse(card.effective_date))
          card.endTime = this.RiQi(Date.parse(card.expiry_date))
          arr.push(card);
        }
        console.log('cardList',arr)

        this.cardList = arr;
        this.countList = res.count;
        this.pageCount = Math.ceil(this.countList/8)


      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  //时间戳转时间
  RiQi(sj)
  {
    var now = new Date(sj);
    var   year=now.getFullYear();
    var   month=now.getMonth()+1;
    var   date=now.getDate();
    var   hour=now.getHours();
    var   minute=now.getMinutes();
    var   second=now.getSeconds();
    return   year+"."+month+"."+date;
  }
  //使用卡劵
  userCardClick(card){
    //卡劵已使用/已失效
    if(this.tab=='2'||this.tab=='3')
    {
      return;
    }
    this.currentCard = card;
    console.log('card',card)

    if(card.coupon_category == "full_cut_coupon")
    {
      //满减劵->单品购买
      this.local.set("recharge_category_code", JSON.stringify(card.limited_product));
      // this.router.navigate(['home/buyProduct']);
      this.router.navigate(['home/tool-campus']);

    }
    else if(card.coupon_category == "full_add_coupon")
    {
      console.log('card',card.limited_amount)
      this.local.set("buyLaCoinMaxNumber",JSON.stringify(card));

      //满赠劵->啦豆充值
      this.router.navigate(['home/buyLaCoin']);
    }
    else  if(card.coupon_category == "exchange_coupon")
    {
      //兑换卷
      this.showAlertDuihuan = true
    }
  }
  cancelAler(){
    this.showAlertDuihuan =false;
  }
  commitAlert(){
    this.showAlertDuihuan =false;
    //cardByLabi
    this.dataApi.cardByLabi(this.currentCard.receiveid).then((res:any)=>{
      console.log('兑换啦豆',res)
      this.toastr.success('兑换成功');
      this.getMyCardList(true);
    }).catch((err)=>{
      console.log(err);
    })
  }


}
