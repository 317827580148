import { Component, Input, Output, OnInit, ViewChild } from '@angular/core';
import { DataApi, Local, Constants, Opera, Config, FormatDate } from '../../provider/index';
import { Router } from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ModelSendMsgComponent } from "../model-send-msg/model-send-msg.component";
import { regexManager } from "../../provider/regexManager";
import { activityManager } from "../../provider/activityManager";
import { Events } from '../../provider/eventService';
import { NoneVipAlertComponent } from "../../app/none-vip-alert/none-vip-alert.component";
import { productManager } from "../../provider/productManager";
import { VoiceCallModelComponent } from "../voice-call-model/voice-call-model.component";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
import * as moment from 'moment';
declare var $: any;
declare var layui;
declare var layer;
declare let Swiper: any;
declare let window;
@Component({
  selector: 'app-work-spase',
  templateUrl: './work-spase.component.html',
  styleUrls: ['./work-spase.component.css']
})
export class WorkSpaseComponent implements OnInit {
   //是否打开产品介绍/请校招顾问联系我 弹窗
   @Output() isproductShowModel = false;
   @ViewChild('productModel', { static: true }) 
   productModel: ProductModelComponent;

  @Output() isShowVoiceCallModel = false;//是否打开弹窗(简历收件箱)
  @ViewChild('voiceCallModel', { static: true }) voiceCallModel: VoiceCallModelComponent;

  @ViewChild('sengMsgModel', { static: true }) sengMsgModel: ModelSendMsgComponent;
  @Output() modelShowSengMsg = false;//是否打开单品发送极速短信
  @Output() SengMsgModel = {
    userName: '',
    userPic: '',
    talentid: '',
    graduation_school: '',//学校
    major_text: '',//专业
    gender: '',
    address: '',
    positionid: ''
  };//打开单品发送极速短信用户对象
  public config: Config;
  constructor(
    public local: Local,
    public dataApi: DataApi,
    public opera: Opera,
    public dialog: MatDialog,
    public events: Events,
    private router: Router,
    public toastr: ToastrService,
    public regexManager: regexManager,
    public activityManager: activityManager,
    public productManager: productManager,
  ) { 
    this.config = Config.getInstance();//实例化config
  }
  public bannerList = [];
  public bannerList2 = [];
  public positionList = [];
  public recommendList = [];
  public vipType = '';
  public remainDay: number;
  public MyLaCoinCount = null;
  public aboutLaCoinAlert = false;
  public aboutHotValue = false;
  public productList = [];
  public memberInfo = {};
  public isShowModel = false;
  public isShowAirModel = false;

  public isShowPayUserDataOut = false;
  //是否显示用户认证的状态
  public isShowAuthState = false;

  public memberLastTime = null;
  public hot = {
    score: '',
    update_time: '',
    sort: ''
  }
  public isShowDouble11Model = false;
  public myCardListCount = null;
  public isShowCollegeModel = false;
  public myCardList = [];
  public BasePublicPackageURL = 'http://images.goworkla.cn/emailpage/big-package/index.html';//领取千元大礼包的路径

  public getPublicPackageURL = 'http://images.goworkla.cn/emailpage/big-package/index.html';//领取千元大礼包的路径
  public companyInfo = {
    logo: '',
    common_name: '',
    authentication_endtime: '',
    member_info: null,
    is_certified: false,
    authentication_begintime: '',
    strengths: null,
  };
  public t_lastIdentity = {
    status: -1,
  }
  public workData = {
    position: {
      total: 0,
      today: 0,
    },
    position_view: {
      today: 0,
      total: 0,
    },
    delivery: { today: 0, total: 0 },
    milkround: {
      can_apply: false,
      total: 0,
      today: 0,
      total_offline: 0
    },
    campustalk: null,
    tasktodo: 0
  };
  public phoneModel = {
    myPhone: '', //企业拔打电话的手机
    myTel: '',
    myTel_qu: '',
    isOkmyPhone: false,
    NetPhone: '',
    positionid: '',
    getPhoneType: '1',
    PhoneModelType: 0,
    MyPhoneTime: 0,
    MyPushCount: 0,
    isShowPhoneModel: false,
    jiduCode: '',
    positionPushCode: '',
  }
  public myDepartment: any;//当前登录的企业信息(department)
  public modelPositionList = [];
  public currentUser: any//极速电话联系用， 用户对象

  public shouWeiChatCode = true; //用户关注公众号的弹窗
  public wxCodeURL = '';
  public isShow618Alert = false;

  //首页广告
  public pro_tanchuang: any;
  public pro_tanchuangModel = false;

  // 是否展示安全升级
  public isShowSecurityUpgrade = false;

  //首页数据看板的数据
  public kanbanData = {
    updateTime: '',
    value1: '',
    value2: '',
    value3: '',
    value4: '',
  };

  public perfectDepartment = {
    department: false,
    user: false,
    isShowPerfetInfo: false
  }
  public lastMemberDay = -1;
  public lastMemberHour = '';

  public isShowXuFeiStr = "";

  public isshow_tel_phone_upgrade_model = false;

  public showWeChatCode = false;
  public weChatCode = '';
  public checkWeChatTimer = null;

  public isShowVip = false;
  public vipExperience = false;
  public vipExperiencedays = 0;
  public searchPeopleText='';
  public showAuditModel = false;
  public auditRemark = '';
  public model = {
    offset:0,
    limit:9
  }
  public lastModify = null;
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量

  ngOnInit() {
    this.opera.vipState((isPay)=>{
      this.isShowVip =  isPay;
    })
    window.scrollTo(0, 0);
    let that = this;
    this.opera.initConfig('workspase', 'workSpase');
    this.getComopanyIsBingWeiChat(); //关注公总号
    this.getWorkData();
    this.getRecommendList();
    this.getMyLaCoinCount();
    this.getProductList();
    this.getDepartment();
    this.getPositionList();
    this.getCardbanner();
    // this.getMyCardList(); 卡劵功能
    // this.getPublicPackageList();
    this.getMemberInfo();
    this.getKanbanData();
    // this.getHotValueData(); 热力值功能
    this.opera.checkMemberPay();
    this.virtyMemberTimeOutAlert();
    this.isShowAirModel = !!!JSON.parse(this.local.get('isViewAir'));

    //刷新看板
    this.events.subscribe('rushWorkspaceResumeNot', (data) => {
      that.getKanbanData();
      // that.getWorkData();
    });
    this.getTodoList();

    this.set618Actice();

    this.opera.updateParOrderCount();

    this.dataApi.getPublicPackageList("open_receive=true").then((res: any) => {
      if (res) {
        console.log('getPublicPackageData', res)
      }
    }).catch((err) => { })
    if (this.config.isShowPro_tanchuang) {
      this.getAdvertisingAlert();
    }
    this.swiperInit();
  
    this.newauthentication();
    // 是否展示 工作啦招聘信息安全升级通知
    let _isShowSecurityUpgrade = this.local.get('isShowSecurityUpgrade')
    if (_isShowSecurityUpgrade) {
    } else {
      this.isShowSecurityUpgrade = true
    }
    // 是否展示固话升级
    // if (!this.local.get('telPhoneUpgradeModel')) {
    //   this.isshow_tel_phone_upgrade_model = true;
    // }
    this.getMsgUnViewCount();
  }

   // 获取最后一次修改记录
   newauthentication() {
    this.dataApi.newauthentication().then((res: any) => {
      if (res) {
        this.lastModify = res;
      }
    })
  }

  getMsgUnViewCount(){
    this.dataApi.getMsgUnViewCount().then((res: any) => {
      if (res) {
        this.config.tasktodo = res.value;
      }
    }).catch((error) => {
      console.log('getWorkData', error)
    });
  }

  // 轮播图初始化
  swiperInit() {
    setTimeout(() => {
      layui.use(['carousel', 'form'], function () {
        var carousel = layui.carousel
          , form = layui.form;
        //建造实例
        carousel.render({
          elem: '#test1'
          , width: '100%' //设置容器宽度
          , arrow: 'always', //始终显示箭头
          height: '160px',
          interval: 3000
          //,anim: 'updown' //切换动画方式
        });
        //找到两个按钮，设置样式，因为全局样式又一个Buttom里有一个样式会导致按钮错乱
        let leftbtn = document.getElementById("lay-type-left");
        let rightbtn = document.getElementById("lay-type-add");
        if (leftbtn) {
          leftbtn.style.padding = '0';
        }
        if (rightbtn) {
          rightbtn.style.padding = '0';
        }
      })
    }, 200)
  }

  //统计数据
  getWorkData() {
    this.opera.loading();
    this.dataApi.getWorkSpaceData().then((res: any) => {
      this.opera.loadiss()
      if (res) {
        this.workData = res;
        console.log('getWorkData', this.workData)
        // 如果是非会员 或者是公益会员 将 workData 的数量 置为0
        if (!this.config.department.member_info) {
          this.workData.position_view.today = 0;
          this.workData.position_view.total = 0;

        } else if (this.config.department.member_info.product_code == 'member_welfare') {
          this.workData.position_view.today = 0;
          this.workData.position_view.total = 0;

        }
      }
    }).catch((error) => {
      this.opera.loadiss()
      console.log('getWorkData', error)
      // this.toastr.error(error);
    });
  }

  getDemindTime() {
    console.log('getDemindTime', this.config.department)
    if (this.config.department && this.config.department.member_info) {
      let nowData = new Date();
      //活动截止时间
      let overData = new Date(this.config.department.member_info.endtime);
      let result = (overData.getTime() - nowData.getTime()) / 1000 / 60 / 60 / 24;
      let m = result.toFixed(0);
      console.log('getDemindTime', result, overData.getTime() - nowData.getTime())
      if (result < 0) {
        this.lastMemberDay = 0
        return 0;
      }
      if (this.config.isMember_trial) {
        let result = (overData.getTime() - nowData.getTime()) / 1000
        let data = this.formarHour(result)
        this.lastMemberHour = data.hours + '小时' + data.minutes + "分钟过期";
        if (data.days > 0) {
          this.lastMemberHour = data.days + '天' + data.hours + '小时' + data.minutes + "分钟过期";
        }

        if (data.hours == 0) {
          this.lastMemberHour = data.minutes + "分钟过期"
          if (data.hours == 0 && data.minutes == 0) {
            this.lastMemberHour = "已过期"
          }
        }
      }
      this.lastMemberDay = Number(result.toFixed(result < 1 ? 1 : 0))
      return result.toFixed(0);
    } else {
      this.lastMemberDay = 0
      return 0;
    }
  }

  formarHour(second: number) {
    const days = Math.floor(second / 86400);
    const hours = Math.floor((second % 86400) / 3600);
    const minutes = Math.floor(((second % 86400) % 3600) / 60);
    const seconds = Math.floor(((second % 86400) % 3600) % 60);
    const forMatDate = {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    };
    return forMatDate;
  }

  //页面跳转
  goRouter(n, m) {
    if (n == 'position-manage') {
      this.opera._hmtPush('工作台', '导航模块', '在招职位')
    } else if (n == 'chat-collection') {
      this.opera._hmtPush('工作台', '导航模块', '对我感兴趣')
    } else if (n == 'position-resume') {
      this.opera._hmtPush('工作台', '导航模块', '已收到简历')
    } else if (n == 'college-milkround') {
      this.opera._hmtPush('工作台', '导航模块', '已通过双选会')
    } else if (n == 'position-campus') {
      this.opera._hmtPush('工作台', '导航模块', '已通过宣讲会')
    } else if (n == 'notification-center') {
      this.opera._hmtPush('工作台', '导航模块', '未读通知')
    } else if (n == 'buyLaCoin') {
      this.opera._hmtPush('工作台', '导航模块', '购买啦豆')
    } else if (n == 'position-milk') {
      this.opera._hmtPush('工作台', '快捷入口', '双选会审核状态')
    } else if (n == 'college-campustalk1') {
      this.opera._hmtPush('工作台', '快捷入口', '报名宣讲会')
    } else if (n == 'position-publish') {
      this.opera._hmtPush('工作台', '快捷入口', '发布职位')
    } else if (n == 'position-manage') {
      this.opera._hmtPush('工作台', '快捷入口', '职位管理')
    }
    if (n == 'position-publish') {
      this.local.set(Constants.positionid, '');
    }
    if (m != '') {
      this.router.navigate(['home/' + n], {
        queryParams: {
          status: m
        }
      });
    } else {
      this.router.navigate(['home/' + n]);
    }
  }

  gotoMilkroundList(n, m, e) {
    e.stopPropagation()
    this.router.navigate(['home/' + n], {
      queryParams: {
        status: m
      }
    });
  }

  goAudit() {
    this.router.navigate(['/guide-audit']);
  }

  //验证用户快到期提醒
  virtyMemberTimeOutAlert() {
    this.dataApi.getEmployment().then((res: any) => {
      if (res) {
        console.log('重新提交认证', res)
        let department = res
        this.companyInfo = res;
        if (this.companyInfo.common_name == null || this.companyInfo.common_name == "") {
          this.companyInfo.common_name = res.name
        }
        if (res.apply_renewal) {
          this.dataApi.authenticationPlatformApplyNew().then((res: any) => {
            if (res) {
              console.log('重新提交认证数据', res)
              this.t_lastIdentity = res;
              if (this.t_lastIdentity.status == 1 || this.t_lastIdentity.status == 3) {
                this.isShowAuthState = true;
              }
            }
          }).catch((err) => {
            this.toastr.error(err);
          })
        }else {
          this.dataApi.getPlatformApply().then((res: any) => {
            if (res) {
              this.setAuditStatus(res)
              this.t_lastIdentity = res;
              this.config.lastIdentity = res;
              if (this.config.lastIdentity.status == 2) {
                let nowData = new Date();
                //活动截止时间
                let overData = new Date(department.authentication_endtime);
                // let overData = new Date('2019-12-29 23:59:59');
                console.log('overData', overData)
                //60天到期提醒
                let spaceBe = 60 * 24 * 60 * 60 * 1000;
                if ((overData.getTime() - nowData.getTime()) > spaceBe) {
                } else if (nowData.getTime() > overData.getTime()) {
                  console.log('首页验证工作啦已过期')
                  let long = (overData.getTime() - nowData.getTime()) / 1000 / 60 / 60 / 24;
                  this.memberLastTime = 0;
                  this.isShowPayUserDataOut = true;
                  this.opera.checkMemberAlertWithType("au_timeOut");
                } else {
                  console.log('首页验证工作啦快过期')
                  let long = (overData.getTime() - nowData.getTime()) / 1000 / 60 / 60 / 24;
                  this.memberLastTime = long.toFixed(0);
                  this.isShowPayUserDataOut = true;
                }
              }
            }
          }).catch((err) => {
            this.toastr.error(err);
          })
        }
      }
    }).catch((error) => {

    });
  }

  //获取热力值
  getHotValueData() {
    let departmentid = this.local.get('accountid');
    this.dataApi.getCompanyHotVal(departmentid).then((res: any) => {
      console.log(res)
      if (res) {
        this.hot = res
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  getMemberInfo() {
    this.dataApi.getMemberInfo().then((res: any) => {
      console.log(res)
      if (res) {
        this.config.memberInfo = res
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  //-----跳转简历详情
  goDetails(n, is_ignore) {
    if (is_ignore == false) {
      this.router.navigate(['home/college-resumedetails'], {
        queryParams: { resumeid: n.resume_number }
      });
    }
  }

  goBuy(n) {
    this.productList.map((item, index) => {
      if (item.recharge_category == n) {
        this.local.set("recharge_category_code", JSON.stringify(item.code));
        // this.router.navigate(['home/buyProduct']);
        this.router.navigate(['home/tool-campus']);
        return
      }
    })
  }

  alertSengMSG(n, event) {
    event.stopPropagation();
    this.opera._hmtPush('工作台', "为您推荐", '职位推送')
    let that = this;

    this.opera.checkMember((isPay) => {
      if (isPay) {
        this.productManager.getProductUsableCount(this.config.sms_bulkinvitation, (count, isVip) => {
          if (count == 0 || !isVip) {
            // this.config.payVipHistoryPage = {
            //   page: '工作台',
            //   isVip: isVip,
            //   pro: '职位推送'
            // };
            // that.local.set("single_category_code", this.config.ProductListCode.sms_bulkinvitation_code);
            // that.router.navigate(['home/tool-campus']);
            this.product('sms_bulkinvitation','recharge');
          } else {
            this.sengMsgModel.initData(1, []);
            this.SengMsgModel.userName = n.talent_name;
            this.SengMsgModel.userPic = n.logo == null ? '' : n.logo;
            this.SengMsgModel.graduation_school = n.graduate_university;
            this.SengMsgModel.major_text = n.major_text;
            this.SengMsgModel.talentid = n.talentid;
            this.SengMsgModel.gender = n.gender;
            this.SengMsgModel.address = n.address_text;
            this.modelShowSengMsg = true;
          }
        })
      } else {
       // that.opera.checkMemberAlertWithType("noneVip_sms_bulkinvitation")
        this.product('sms_bulkinvitation','recharge');
      }
    })
  }

  // 职位推荐弹出关闭
  Modeldismiss(event) {
    this.modelShowSengMsg = false;
    this.config.batchList = [];
    console.log('Modeldismiss',event);
  }

  aboutLaBi() {
    this.aboutLaCoinAlert = true;
  }

  aboutHot(event) {
    event.stopPropagation();
    this.aboutHotValue = true;
  }

  //获取推荐列表
  getRecommendList() {
    this.dataApi.getRecommendResumeList(this.model).then((res: any) => {
      console.log('获取推荐列表',res)
      if (res) {
        this.recommendList = res.list;
        this.pageCount = Math.ceil(res.count/9);
        for (let val of this.recommendList) {
          let arr = [];
          val.yixiang = "";
          val.youshiArr = [];          
          val.education_text = this.opera.educationFormat(val.education_text);
          for (let item1 of val.pro_skills) {
            val.yixiang += item1.text + "    "
            if (item1.contrast_list) {
              for (let youshi of item1.contrast_list) {
                val.youshiArr.push(youshi.text);
              }
            }
          }
        }

      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  //时间戳转时间
  RiQi(sj) {
    var now = new Date(sj);
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var date = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    return year + "." + month + "." + date;
  }

  //跳转我的卡券
  gotoMyCard() {
    this.router.navigate(['home/myCard']);
  }

  getProductList() {
    this.opera.loading();
    this.dataApi.departmentList({ product_category: "recharge" }).then((res: any) => {
      this.opera.loadiss();
      if (res) {
        console.log('获取产品列表', res)
        for (let item of res.list) {
          if (item.recharge_category == "voice_call") {
            this.phoneModel.jiduCode = item.code;
          }
          if (item.recharge_category == "sms_bulkinvitation") {
            this.phoneModel.positionPushCode = item.code;
          }
        }
        this.productList = res.list;
      }
    }).catch((err) => {
      console.log(err);
      this.opera.loadiss();
    })
  }

  //获取我的啦豆余额
  getMyLaCoinCount() {
    this.companyInfo = this.dataApi.getLocalDepartment();
    this.dataApi.getMyLaCoinCount({}).then((res: any) => {
      if (res) {
        this.MyLaCoinCount = res;
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  ngOnChanges() { }
  //调用在组件内容初始化之后调用
  ngAfterContentInit() { }

  ngOnDestroy(){
    console.log('ngOnDestroy')
    clearInterval(this.checkWeChatTimer);
  }

  //----------极速电话联系的方法
  //显示手机号弹窗
  ShowPhoneModel(n, event) {
    event.stopPropagation();
    this.opera._hmtPush('工作台', "为您推荐", '电话联系')
    //极速电话联系系统升级
    if (this.config.callSafeUpgrade) {
      this.events.publish('callSafeUpgradeNot');
      return;
    }
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        this.productManager.getProductUsableCount(this.config.voice_call, (count, isVip) => {
          if (count == 0 || !isVip) {
            // this.config.payVipHistoryPage = {
            //   page: '工作台',
            //   isVip: isVip,
            //   pro: '极速电话联系'
            // };
            // that.local.set("single_category_code", this.phoneModel.jiduCode);
            // that.router.navigate(['home/tool-campus']);
            this.product('voice_call','recharge');
          } else {
            this.voiceCallModel.initData(n);
            this.isShowVoiceCallModel = true;
          }
        })
      } else {
       // that.opera.checkMemberAlertWithType("noneVip_voice_call")
       this.product('voice_call','recharge');
      }
    })
  }

  //获取企业信息
  getDepartment() {

    this.dataApi.getEmployment().then((res: any) => {//通过tooken获取企业信息
      console.log('获取企业信息', res)
      if (res) {
        this.myDepartment = res;
        this.config.department = res
        this.getDemindTime()
        if (this.myDepartment.is_certified) {
          let aduitTime = new Date(this.myDepartment.authentication_begintime).getTime() + 60 * 60 * 24 * 30 * 1000;
          let nowTime = new Date().getTime();
          if (!this.local.get('isGetCollageCoupon') && aduitTime > nowTime) {
            this.isShowCollegeModel = true;
          }
        }
        this.initPhoneData(this.myDepartment);
        this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
        //检测企业信息是否完善
        if (!res.intro || !res.common_name || !res.industry || !res.employer_type || !res.register_capital || !res.locations || !res.establish_year || !res.scale || !res.wechat) {
          this.perfectDepartment.department = true;
        }
        //会员有效时长
        if(this.myDepartment && this.myDepartment.member_info){
          this.myDepartment.member_info.endtime_text = moment(this.myDepartment.member_info.endtime).format('YYYY年MM月DD日');
          this.myDepartment.member_info.days = moment(this.myDepartment.member_info.endtime).diff(moment(), 'days');
        }
        //是否为体验会员
        if(this.myDepartment.is_trial_member){
          let trial_endtime = moment(this.myDepartment.memberTrail.endtime).format('YYYY-MM-DD HH:mm:ss');
          let nowTime = moment().format('YYYY-MM-DD HH:mm:ss')
          if(moment(nowTime).isBefore(trial_endtime)){
            this.vipExperience = true;
            this.vipExperiencedays = moment(trial_endtime).diff(moment(nowTime),'days');
          }
        }
        this.getUserperfect();
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  //获取card广告banner
  getCardbanner() {
    let that = this;
    this.dataApi.getCardBannerdata('shouye').then((res: any) => {
      if (res) {
        this.bannerList = res;
        // console.log('获取广告Banner首页',this.bannerList);
        var mySwiper2 = new Swiper('#swiper1', {
          slidesPerView: 'auto',
          spaceBetween: 0,
          freeMode: false,
          grabCursor: true,
          centeredSlides: false,
          observer: true,//修改swiper自己或子元素时，自动初始化swiper
          speed: 500,
          autoplayDisableOnInteraction: false,//表示用户操作swiper之后，是否禁止autoplay。默认为 true：停止。false是播放
          watchSlidesProgress: false,
          observeParents: false,//修改swiper的父元素时，自动初始化swiper
          onSlideChangeEnd: function (swiper) {
            mySwiper2.update();
            mySwiper2.startAutoplay();
          },
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false, // 手动滑动之后依然自动轮播
            waitForTransition: true
          }, // 自动滑动
          on: {
            click(e) {
              console.log('click', e);
              that.opera._hmtPush('工作台', "BANNER", '')
              if (!e.target.id) return;   // e.target.id 主要起传参的作用
              that.bannerClick(that.bannerList[parseInt(e.target.id)])
            }
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: { // 如果需要前进后退按钮
            el: '.swiper-pagination',
          },
        })
      }
    }).catch((err) => {
      console.log(err);
    })

    this.dataApi.getCardBannerdata('gongzuotai').then((res: any) => {
      if (res) {
        console.log('获取广告Banner', res);
        this.bannerList2 = res;
        var mySwiper3 = new Swiper('#swiper2', {
          autoplay: true,//可选选项，自动滑动
          slidesPerView: 'auto',
          spaceBetween: 0,
          freeMode: false,
          grabCursor: true,
          centeredSlides: false,
          observer: true,//修改swiper自己或子元素时，自动初始化swiper
          // loop: true, // 循环模式选项
          speed: 800,
          // autoplayDisableOnInteraction:false,//表示用户操作swiper之后，是否禁止autoplay。默认为 true：停止。false是播放
          watchSlidesProgress: true,
          observeParents: false,//修改swiper的父元素时，自动初始化swiper
          onSlideChangeEnd: function (swiper) {
            mySwiper3.update();
            mySwiper3.startAutoplay();
            // mySwiper2.reLoop();
          },

        })
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  //获取用户信息
  getUserperfect() {
    this.dataApi.getUser().then((res: any) => {
      if (res) {
        if (!res.post || !res.email || !res.gender || !res.name) {
          this.perfectDepartment.user = true;
        }
        if (!this.perfectDepartment.user) {
          if (this.myDepartment.contact_info_is_set == false) {
            this.perfectDepartment.isShowPerfetInfo = false
            this.perfectDepartment.department = false;
            this.perfectDepartment.isShowPerfetInfo = true
          }
        }
        if (this.config.api.indexOf('test') < 0) {
          if (res.wechat_info == null) {
            this.toastr.success('请绑定微信');
            this.router.navigate(['identity-status']);
          } else {
            if (res.wechat_info.unionid == '') {
              this.toastr.success('请绑定微信')
              this.router.navigate(['identity-status']);
            }
          }
        }
        if (res.wechat_info) {
          if (res.wechat_info.unionid) {
            this.config.userisBindWx = true
          }
        }
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  //处理初始化电话联系quota
  initPhoneData(res) {
    if (!res.member_info) {
      return;
    }
    let nowData = new Date();
    //活动截止时间
    let overData = new Date(res.member_info.endtime);
    // let overData = new Date('2019-12-29 23:59:59');
    console.log('overData', overData)
    //60天到期提醒
    let spaceBe = this.config.memberEndTime * 24 * 60 * 60 * 1000;
    if ((overData.getTime() - nowData.getTime()) > spaceBe) {
      console.log('会员未到期');
    } else if (nowData.getTime() > overData.getTime()) {
      console.log('会员已经到期了');
      this.dataApi.checkMemberCheckV2().then((res: any) => {
        console.log('会员快到期了,但是有新的会员', res);
        if (!res.has_member_order) {
        }
        else {
          let data1 = new Date(res.scheduled_execution_time)
          let data = new Date(res.scheduled_execution_time)
          data.setFullYear(data.getFullYear() + 1);
          let getMonth = data.getMonth() + 1 + "";
          if (data.getMonth() + 1 < 10) {
            getMonth = "0" + getMonth;
          }
          this.isShowXuFeiStr = data.getFullYear() + "-" + getMonth + "-" + data.getDate();
        }
      }).catch((error) => {
      });
    } else {
      let count = overData.getTime() - nowData.getTime();
      let long = count / 1000 / 24 / 60 / 60;
      console.log('会员快到期了,不足三个月', count / 1000 / 24 / 60 / 60)
      this.dataApi.checkMemberCheckV2().then((res: any) => {
        console.log('会员快到期了,但是有新的会员', res);
        if (res.has_member_order) {
          let data1 = new Date(res.scheduled_execution_time)
          let data = new Date(res.scheduled_execution_time)
          data.setFullYear(data.getFullYear() + 1);
          let getMonth = data.getMonth() + 1 + "";
          if (data.getMonth() + 1 < 10) {
            getMonth = "0" + getMonth;
          }
          this.isShowXuFeiStr = data.getFullYear() + "-" + getMonth + "-" + data.getDate();
        }
        else {
        }
      }).catch((error) => {
      });
    }
  }

  //获取在期职位列表
  getPositionList() {
    return this.dataApi.getPositionList({
      limit: 100,
      offset: 0,
      status: 'release',
    }).then((res: any) => {
      if (res) {
        if (res.list.length == 0) {
          return;
        }
        for (let val of res.list) {
          let model = { text: '', code: '' };
          val.isFilterActive = false;
          model.code = val.post_type;
          model.text = val.post_type_text;
        }
        this.positionList = res.list;
        this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
      }

    }).catch((error) => {
      //console.log(error);
    });
  }

  //购买电话时长
  gotoBuyPhoneTime() {
    this.phoneModel.isShowPhoneModel = false;
    let that = this;
    setTimeout(() => {
      //去购买极速电话
      that.local.set("recharge_category_code", JSON.stringify(this.phoneModel.jiduCode));
      // that.router.navigate(['home/buyProduct']);
      this.router.navigate(['home/tool-campus']);
    }, 600)
  }

  bannerClick(n) {
    console.log('bannerClick', n)
    if (n.name == "双11钜惠活动") {
      let that = this;
      setTimeout(() => {
        that.isShowDouble11Model = true;
      }, 300);
      return;
    }
    if (n.name == "热力值") {
      window.open('https://optimum.tianjihr.com/2019/bellwellreceived.html', '_blank');
      return;
    }
    if (n.activityid) {
      if (n.name == "1000元校招大礼包") {
        let url = this.BasePublicPackageURL + '?packageid=' + n.activityid;
        window.open(url, '_blank');
        return;
      }
      let data = {
        activityid: n.activityid
      }
      this.dataApi.getBannerCard(n.activityid, data).then((res: any) => {
        this.toastr.success('已领取，请前往校招工具页面使用')
      }).catch((error) => {
        //console.log(error);
        this.toastr.error('不可重复领取')
      })
      return;
    }
    if (n.links) {
      window.open(n.links, '_blank');
      return;
    }
  }

  //检查用户是否关注公众号
  getComopanyIsBingWeiChat(){
    let accountid = this.local.get('accountid');
    this.dataApi.getComopanyIsBingWeiChat(accountid).then((res:any)=>{
      //已绑定
    }).catch((error)=>{
      console.log('未绑定');
      this.showWeChatCode = true;
      this.getCompanyQRCode();
    })
  }

  //获取公司关注公众号
  getCompanyQRCode() {
      let data = { departmentid: this.local.get('accountid')};
      console.log("WeChatCode", data);
      this.dataApi.getWeiChatCode(data).then((res: any) => {
        console.log("weChatQRCodeSuccess", res.value);
        if (res) {
          this.weChatCode = res.value;
          this.changeCode();
        }
      }).catch((err) => {
        console.log("weChatQRCodeErr", err);
      })
  }

  changeCode(){
    let accountid = this.local.get('accountid');
    let that = this;
    this.checkWeChatTimer =  setInterval(()=>{
      that.dataApi.getComopanyIsBingWeiChat(accountid).then((res:any)=>{
        //已绑定
        clearInterval(that.checkWeChatTimer);
        this.showWeChatCode = false;
        this.toastr.success('绑定成功');
      }).catch((error)=>{
        console.log('未绑定');
      })
    },1000)
  }

  closeCheckWeiChat(){
    this.showWeChatCode = false;
    if(this.checkWeChatTimer){
      clearInterval(this.checkWeChatTimer)
    }
  }
  
  getConsumeList() {
    let data = {
      offset: 0,
      limit: 10
    }
    this.dataApi.getConsumeList(data).then((res: any) => {
      // console.log('获取到消费明细',res);
      if (res) {
        if (res.count == 0) {
          this.isShowModel = true;
        }
      }
    }).catch((err) => {
      console.log('获取到消费出错');
      this.toastr.error("获取到消费出错，请稍后重试");
    })
  }

  //验证卡包是否可用
  getPublicPackageList() {
    let data = {
      package_type: '',
      open_receive: true,
      offset: 0,
      limit: 10
    };
    let packageid = "5d82e0119501803fbceb9900";
    let that = this;
    try {
      that.dataApi.checkPackage(packageid).then((res: any) => {
        if (res) {
          console.log('验证验证礼包礼包', res.value)
          if (res.value) {
            that.getPublicPackageURL = that.BasePublicPackageURL + '?packageid=' + packageid;
            that.getConsumeList();
          }
        }
      }).catch((err) => {
        console.log('getPublicPackageList', err);
      })
    } catch (e) {
    }
    this.dataApi.getPublicPackageList("open_receive=true").then((res: any) => {
      if (res) {
        console.log('getPublicPackageData', res)
      }
    }).catch((err) => {
      console.log('getPublicPackageList', err);
    })
  }

  getPublicPackageClick() {
    this.isShowModel = false;
    setTimeout(() => {
      window.open(this.getPublicPackageURL, '_blank');
    }, 600)
  }

  getAirClick() {
    this.isShowAirModel = false;
    this.local.set('isViewAir', true);
    setTimeout(() => {
      window.open('http://air.goworkla.cn/edu/', '_blank');
    }, 300)
  }

  getDouble11Click() {
    this.isShowDouble11Model = false;
    this.local.set('isGetDouble11' + this.local.get('accountid'), true);
    setTimeout(() => {
      this.router.navigate(['home/myCard']);
    }, 300)
  }

  getKanbanData() {
    this.dataApi.getKanbanData("").then((res: any) => {
      if (res) {
        let newData = new Date(res.date)
        let month = newData.getMonth() + 1;
        let day = newData.getDate();
        this.kanbanData.updateTime = newData.getFullYear() + "年" + month + "月" + day + "日";
        this.kanbanData.value1 = this.toThousands(res.talent);  //总求职者人数（人）：
        this.kanbanData.value2 = this.toThousands(res.department);
        this.kanbanData.value3 = this.toThousands(res.position);
        this.kanbanData.value4 = this.toThousands(res.intent_count);
        this.local.set('kanbanData', JSON.stringify(this.kanbanData))
      }
    }).catch((err) => {
      console.log('getKanbanData', err);
    })
  }

  // 方法一
  toThousands(num) {
    var result = [], counter = 0;
    num = (num || 0).toString().split('');
    for (var i = num.length - 1; i >= 0; i--) {
      counter++;
      result.unshift(num[i]);
      if (!(counter % 3) && i != 0) { result.unshift(','); }
    }
    return result.join('');
  }

  chatClick() {
    this.router.navigate(['home/chatSingle']);
  }

  //去完善企业信息
  gotoPerfect() {
    if (this.perfectDepartment.department && !this.perfectDepartment.user) {
      this.router.navigate(['home/company-details']);
    }
    else if (!this.perfectDepartment.department && this.perfectDepartment.user) {
      this.router.navigate(['home/company-account']);
    }
    else {
      if (this.perfectDepartment.isShowPerfetInfo) {

        this.dataApi.departmentSwitchContactinfo({ value: true }).then((res: any) => {
        }).catch((err) => { })
        this.router.navigate(['home/company-account']);
        return;
      }
      this.router.navigate(['home/company-details']);
    }
  }

  getTodoList() {
    this.dataApi.getTodoList({ 'view': false }).then((res: any) => {
      if (res) {
        this.config.NavunReadNum = res.count;
      }
    }).catch((err) => {
    })
  }

  onHideAirpackage() {
    this.local.set('isViewAir', true);
  }

  //2020 年618 活动
  set618Actice() {
    let currentData = new Date();
    let endData = new Date(this.config.endData618);
    if (endData < currentData) {
      // console.log('618活动结束',endData,this.config.endData618)
    }
    else {
      // console.log('618活动未结束')
      this.isShowAirModel = false;
      this.isShow618Alert = true;
    }
  }

  close618Model() {
    this.isShow618Alert = false;
  }

  //极速电话联系弹窗消失
  voiceCallModelHidden(e) {
    this.isShowVoiceCallModel = false;
  }

  //获取首页的广告弹窗
  getAdvertisingAlert() {
    this.dataApi.getCardBannerdata('pro_tanchuang').then((res: any) => {
      if (res) {
        console.log('getAdvertisingAlert', res);
        if (res.length > 0) {
          this.pro_tanchuang = res[0];
          this.pro_tanchuangModel = true;
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  closeCustom() {
    this.config.isShowPro_tanchuang = false;
    this.pro_tanchuangModel = false;
  }

  customClick() {
    this.config.isShowPro_tanchuang = false;
    console.log('customClick', this.pro_tanchuang)
    if (this.pro_tanchuang) {
      if (this.pro_tanchuang.links.indexOf('http') >= 0) {
        window.open(this.pro_tanchuang.links, '_blank');
      }
      else {
        window.open(this.pro_tanchuang.links, '_blank');
      }
    }
  }

  bannerImageClick(e) {
    let that = this;
    console.log('click', e);
    that.opera._hmtPush('工作台', "BANNER", '')
    that.bannerClick(e)
  }

  upgradeTitleComit() {
    this.isShowSecurityUpgrade = false;
    this.local.set('isShowSecurityUpgrade', 'securityUpgradeModel')
  }

  // 关闭固话升级
  closeTelPhoneUpgradeModel() {
    this.isshow_tel_phone_upgrade_model = false
    this.local.set('telPhoneUpgradeModel', 'TelPhoneUpgradeModel')
  }

  productModelHidden(event){    
    this.isproductShowModel = false;
  }

  //点击产品
  product(alias,type){
    this.productModel.initData(alias,type);
    this.isproductShowModel = true;
  }

   //发布职位
   goPublish(event){
    event.stopPropagation();
    this.opera.loading();
    this.dataApi.getEmployment().then((res:any)=>{
      console.log('职位管理',res)
      this.opera.loadiss();
      if(res) {
        this.dataApi.setLocalDepartment(res);
        if(res.quota.position==0){
          this.toastr.warning("今日发布职位数量已达上限");
          return;
        }else{
          this.local.remove(Constants.positionid);
          this.opera.gotoPositionPush();
        }
      }
    }).catch((error) => {
      this.opera.loadiss();
      this.toastr.error(error);
    });
}

  //去公司主页
  goComHome(){
    this.router.navigateByUrl('/home/company-details');
  }

  goSearch(){
    this.local.remove('searchPeopleText');
    this.local.set('searchPeopleText', this.searchPeopleText);
    this.router.navigateByUrl('/home/searchPeople');
  }

  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      if(that.searchPeopleText == ''){
        that.toastr.warning('请填写关键词！');
        return;
      }
      that.goSearch();
    }
  }

  gotoRenzheng(){
    this.router.navigateByUrl('guide-audit');
  }

  goauthentication(){
    this.router.navigateByUrl('authentication');
  }

  setAuditStatus(applyRes){
    // let audit_log = applyRes.audit_log || []
    // let lastAudit = audit_log[~-audit_log.length]
    // if(lastAudit&&!lastAudit.passed){
    //   this.auditRemark = lastAudit.remark;
    //   this.showAuditModel = true
    // }
    if(applyRes.status == 3){
      let audit_log = applyRes.audit_log || []
      let lastAudit = audit_log[~-audit_log.length]
      this.auditRemark = lastAudit.remark;
      this.showAuditModel = true
    }
  }

    //获取分页子组件返回数据
    getChildEvent(index){
      if(index > 0){
        this.pageNumber = index;
        this.model.offset = (this.pageNumber-1)*9;
        this.getRecommendList();//获取列表
      }else if(index == -1){//上一页
        if(this.pageNumber == 1){
          this.toastr.warning("当前为第一页");
        }else{
          this.pageNumber = this.pageNumber - 1;
          this.model.offset = (this.pageNumber-1)*9;
          this.getRecommendList();//获取列表
        }
      }else if(index == -2){//下一页
        if(this.pageNumber == this.pageCount){
          this.toastr.warning("当前为最后一页");
        }else{
          this.pageNumber = this.pageNumber + 1;
          this.model.offset = (this.pageNumber-1)*9;
          this.getRecommendList();//获取列表
        }
      }
    }
}


