import {Component, OnInit, Output, ViewChild} from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import {ModelSelectMoreComponent} from "../model-select-more/model-select-more.component";
import {ModelSelectTwoComponent} from "../model-select-two/model-select-two.component";
import {ModelPositionTemplateComponent} from "../model-position-template/model-position-template.component";
import {ModelStillMoreComponent} from "../model-still-more/model-still-more.component";
import {regexManager} from "../../provider/regexManager";

declare var window;
@Component({
  selector: 'app-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.css']
})
export class IdentityComponent implements OnInit {
  public config: Config;
  @ViewChild('child1') child: ModelStillMoreComponent;
  @ViewChild('child2') child2: ModelSelectMoreComponent;
  @ViewChild('child3') child3: ModelSelectTwoComponent;

  @Output() modelTwoShow = false;//是否打开弹窗
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelThreeShow = false;//是否打开弹窗


  public currentIndex = '';
  public codeURL = '';
  public isGuanZhu = false;
  public accountid='';
  public isHavethree=false;

  public  shouWeiChatCode=false;
  public model={
    collegeid:'',
    facultyid:'',//院系id
    proxy_document:'',//招聘委托书
    business_licence:'',//企业营业执照
    id_card_front:'',//身份证正面
    register_number:'',//营业执照注册号

    post_type:"", //职位雷被
    name:'', //职位名称
    post_typeText:"",
    post_nature:'01',//工作性质
    position_salary:'',//月薪范围(字典)
    education:'',//学历要求
    location:{//工作地址
      region:''//,//区域代码
      //coordinates:[],//坐标
      //address:''//详细地址
    },//工作城市
    locationText:'',//工作地址文字
    intent_count:"",//人数
    responsibility:'',//描述
    majors:[],
    list:[],
    school_permit:"",
    // currentIndex:0
  }


  //薪资数据
  public salaryData = [
    {"code": "01","text": "1K以下"},
    {"code": "02","text": "1K-2k"},
    {"code": "03","text": "2k-4k"},
    {"code": "04","text": "4k-6k"},
    {"code": "05","text": "6k-8k"},
    {"code": "06","text": "8k-10k"},
    {"code": "07","text": "10k-15k"},
    {"code": "08","text": "15k-25k"},
    {"code": "09","text": "25k以上"}
  ];
  public collegeName="";
  public orgList=[];//高校院系列表
  public secureMaterail:any;//招聘委托书下载链接

  constructor(public dataApi: DataApi,
              private router: Router,
              public opera:Opera,
              public local:Local,
              private location: PlatformLocation,
              public regexManager:regexManager,
              public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
    this.config.isGuide=true;
  }


  ngOnInit() {
    if(this.local.get(Constants.collegeid)){
      this.model.collegeid=this.local.get(Constants.collegeid);
      this.getCollegeById(this.model.collegeid);//获取高校信息
      this.getIdentityMaterial();
    }


    for (let a=0;a<3;a++)
    {
      let item = {post_typeText:'',post_type:"",intent_count:'',majors:[],index:a,name:""};
      this.model.list.push(item)
    }
    this.getPositionTypePost();
    this.getMajorList();//专业类别
    // this.getCompanyQRCode();//获取公司的公众号二维码
    this.getDepartDetail();
  }

  //获取公司关注公众号
  getCompanyQRCode(){

    if(this.local.get('accountid')||this.local.get('collegeid'))
    {
      let url = this.config.GetWXCode+this.local.get('accountid')+"&"+this.local.get('collegeid')
      let data = {departmentid:this.local.get('accountid'),collegeid:this.local.get('collegeid')};
      console.log("WeChatCode",data);
      this.dataApi.getWeiChatCode(data).then((res:any)=>{
        console.log("weChatQRCodeSuccess",res.value);
        this.codeURL=res.value;

      }).catch((err)=>{
        console.log("weChatQRCodeErr",err);

      })
    }
  }
  getData(){
    console.log("getData")

  }
  //获取认证上传的材料标准
  getIdentityMaterial(){
    let model={collegeid:this.model.collegeid};
    this.dataApi.getMaterial('authentication',model).then((res:any)=>{
      console.log('获取认证材料成功',res);
      if(res[0]){
        for(let list of res[0].material_infos){
          if(list.material_field=='proxy_document'){
            this.secureMaterail=list.material_example;
          }
        }
      }
    }).catch((err)=>{
      //console.log("获取认证材料失败");
      //console.log(err);
    })
  }

  //通过高校id获取院系列表
  getOrgList(){
    this.opera.loading();
    this.dataApi.getCollegePart(this.model.collegeid).then((res:any)=>{
      console.log("获取院系列表---");
      // console.log(res);
      this.orgList = res;
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
      this.toastr.error("获取院系列表失败，请稍后重试");
    })
  }

  //通过高校id获取高校详情
  getCollegeById(id){
    this.opera.loading();
    this.dataApi.getCollageDetails(id).then((res:any)=>{
      console.log("获取到高校信息----",res);
      //console.log(res);
      this.isHavethree = res.audit_switch.proxy_document
      if(res.audit_switch.faculty_authcation){
        this.getOrgList();
      }else{
        this.collegeName = res.name;
      }
      this.opera.loadiss();
    }).catch((err)=>{
      this.toastr.error(err);
      this.opera.loadiss();
    });
  }

  //提交认证
  uploadDepartmentIdentity(){



    let filter1 = /^[0-9a-zA-Z]{9}$/;
    let filter2 = /^[0-9a-zA-Z]{15}$/;
    let filter3 = /^[0-9a-zA-Z]{18}$/;
    if(!this.model.business_licence){
      this.toastr.warning('请上传营业执照图片');
      return;
    }
    if(!this.model.id_card_front){
      this.toastr.warning('请上传身份证正面');
      return;
    }
    if(!this.model.register_number){
      this.toastr.warning('请填写统一社会信用代码');
      return;
    }
    if(!this.regexManager.isRegister_number(this.model.register_number)){
      this.toastr.warning('统一社会信用代码格式不正确');
      return;
    }
    // else{
    //   let filterValue=this.model.register_number.trim();//删除左右两端的空格
    //   if(!filter.test(filterValue)){
    //     this.toastr.warning('统一社会信用代码格式错误');
    //     return;
    //   }
    // }
    if(!this.collegeName){
      if(!this.model.facultyid && this.orgList.length>0){
        this.toastr.info("请选择当前申请学院");
        return;
      }
    }
    if(this.isHavethree){
      if(!this.model.proxy_document){
        this.toastr.info("请上传招聘委托书");
        return;
      }
    }

    let
      NewList = [];
    for (let index = 0;index<this.model.list.length;index++)
    {
      let item = this.model.list[index];
      // let item = {post_typeText:'',post_type:"",intent_count:'',majors:[],index:a};
      if(item.post_typeText!='')
      {
        item.name=item.post_typeText;
      }
      if(/^[0-9]*$/.test(item.intent_count)==false)
      {
        this.toastr.info("招聘人数错误");
        return;
      }
      console.log("item",item);

      if (item.post_type != '' && item.intent_count != '' && item.majors.length > 0) {
        NewList.push(item);

      } else {
        if(item.post_type != ''|| item.intent_count != ''  || item.majors.length > 0)
        {
          this.toastr.info("发布职位信息填写未完整");
          return;
        }
      }
    }
    if(NewList.length==0)
    {
      this.toastr.info("至少发布一个职位");
      return
    }
    console.log("创建职位this.model",this.model);

    this.opera.loading();//加载动画

    this.dataApi.uploadDepartmentIdentity(this.model).then((res:any)=>{

      this.opera.loadiss();//停止加载动画

      //创建职位//
      this.dataApi.fastCreatePosition(NewList).then((res:any)=>{


        if(this.config.GetWXCode=='http://wx.goworkla.cn/mp/makeercode?sceneStr=') {
          this.shouWeiChatCode=true;
          this.startTimeURL();
        } else {
          this.router.navigateByUrl('home/homepage');
        }


        this.lastAuthenticationIdentity(); //更新企业高校认证信息本地存储

      }).catch((error) => {

      });


    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss();//停止加载动画
      setTimeout(() => {
        this.toastr.warning(error);
      },600);
    });
  }

  //获取上次高校认证信息(线上数据)
  lastAuthenticationIdentity(){
    this.dataApi.lastAuthenticationIdentity().then((res:any)=>{
      //console.log(res);
      if(res){
        this.config.lastCollegeIdentity=res;
        this.dataApi.setLastCollegeIdentity(this.config.lastCollegeIdentity);
      }
    }).catch((error)=>{
      //console.log(error);
    })
  }

//上传图片
  previewPic(event,name){
    console.log(event);
    console.log(name);
    let imgData  = event.target.files[0]; //获取file对象
    if (parseInt(imgData.size)/1024 > 1024*5) {
      this.toastr.warning('该图片大小超出了5M，请重新上传');
      return ;
    }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.uploadPic(reader.result,name);
      };

    }
  }
//上传到服务器
  uploadPic(data,name){
    let imageData=this.dataApi.formatImage(data);
    this.opera.loading();//加载动画
    this.dataApi.uploadAuthImg({
      value: imageData
    }).then((res:any)=>{
      ////console.log(res);
      if(res){
        if(res.value){//------------上传之后赋值
          this.model[name] = res.value;
        }
      }
      this.opera.loadiss();//停止加载动画
    }).catch((error) => {
      ////console.log(error);
      this.opera.loadiss();//停止加载动画
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    });
  }
  //获取职位类别列表
  //接收子组件返回数据
  ModelTwodismiss(event){
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    scrollTo(0,1100);
    if(event){
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      this.model.post_type = event.code;
      this.model.post_typeText = event.text;
      this.model.name = event.text;
      let arr = [];
      for (let index = 0;index<this.model.list.length;index++)
      {
        let item = this.model.list[index];
        if(item.index==this.currentIndex)
        {
          item.post_type= event.code;
          item.post_typeText = event.text;
        }
        arr.push(item);
      }
      this.model.list=arr;

    }
  }
  //去选择职位类别
  goPositionType(item){
    this.currentIndex = item.index;
    scrollTo(0,0);
    // console.log(" this.currentIndex",item,this.currentIndex);
    this.modelTwoShow = true;
    this.opera.scrollTop();//页面滚动
    //调用子组件函数
    this.child2.initData(this.model.post_type,this.config.post_type,'选择职位类别');

  }

  //获取职位类别列表
  getPositionTypePost(){
    if(this.dataApi.getPostTypeCode()){
      this.config.post_type = this.dataApi.getPostTypeCode();
    }else{
      //获取职位类别字典
      this.opera.loading();
      this.dataApi.getDictDate('post_type').then((res:any)=>{
        // //console.log(res);
        this.dataApi.setPostTypeCode(res);
        this.config.post_type = res;
        this.opera.loadiss();
      }).catch((error)=>{
        //console.log(error);
        this.opera.loadiss();
      });
    }
  }


  //选择意向专业
  goMajor(item){
    this.currentIndex = item.index;

    let CurrentArr  = [];
    for (let index = 0;index<this.model.list.length;index++)
    {
      let item = this.model.list[index];
      if(item.index==this.currentIndex)
      {
        CurrentArr = item.majors;
      }

    }
    scrollTo(0,0);
    this.modelShow = true;
    this.opera.scrollTop();//页面滚动
    //调用子组件函数
    console.log("调用子组件函数",CurrentArr,this.config.major.length);
    this.child.initData( Object.assign([],CurrentArr),Object.assign([],this.config.major),5,'选择专业');
  }
  //接收子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
    scrollTo(0,1100);

    if(event){
      // //console.log(event);

      // this.model.majors = event;
      var arr = [];
      for (let index = 0;index<this.model.list.length;index++)
      {
        var item = this.model.list[index];
        if(item.index==this.currentIndex)
        {
          item.majors = Object.assign([],event);
        }
        arr.push(item);
      }
      this.model.list = arr;
      console.log("接收子组件返回数据event",this.model.list,this.currentIndex,event);
    }
  }
  //获取专业列表信息
  getMajorList(){
    if(this.dataApi.getMajorCode()){
      this.config.major = this.dataApi.getMajorCode();
    }else{
      this.opera.loading();
      this.dataApi.getDictDate('major').then((res:any)=>{
        //console.log("获取专业列表-----college-resume");
        //console.log(res);
        this.opera.loadiss();
        this.config.major = res;
        this.dataApi.setMajorCode(res);
      }).catch((error)=>{
        //console.log(error);
        this.opera.loadiss();
      });
    }
  }
  deleteItem(deleteitem){

    if(this.model.list.length==1)
    {
      this.toastr.error("至少发布一个职位");

      return;
    }
    var arr = [];

    for (let index = 0;index<this.model.list.length;index++)
    {
      var item = this.model.list[index];
      if(deleteitem.index!=item.index)
      {
        arr.push(item);
      }
    }
    this.model.list=arr;

  }
  addItem(){

    var arr = [];
    for (let index = 0;index<this.model.list.length;index++)
    {
      var item = this.model.list[index];
      item.index=index;
      arr.push(item);
    }
    let item1 = {post_typeText:'',name:"",post_type:"",intent_count:'',majors:[],index:arr.length+1};
    arr.push(item1);
    this.model.list=arr;
  }

  //开启定时检索
  startTimeURL(){

    if(this.codeURL=='')
    {
      this.toastr.error("获取微信公众号失败");
      return;
    }

    var timer=null;
    var that = this;
    let accountid= this.local.get('accountid');

    timer=setInterval(function()   //开启循环：每秒出现一次提示框
    {
      console.log('检测企业是否绑定微信',accountid)
      //检测企业是否绑定微信
      that.dataApi.getComopanyIsBingWeiChat(accountid).then((res:any)=>{

        that.router.navigateByUrl('home/homepage');
        clearInterval(timer);
        that.shouWeiChatCode=false;
        return;

      }).catch((error)=>{
        console.log('检测企业是否绑定微信erroe',error)
        // clearInterval(timer);
      });

    }.bind(this),1000);

  }


  getDepartDetail(){
    this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
      console.log('getDepartment,',res);
      if(res)
      {

      }

    }).catch((error)=>{

    });
  }



}
