import {Component, OnInit, Output} from '@angular/core';
import {DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {ConfirmsComponent} from "../confirms/confirms.component";
import {regexManager} from "../../provider/regexManager";
import {ModelPayCodeComponent} from "../model-pay-code/model-pay-code.component";
import {PayInvoiceModelComponent} from "../pay-invoice-model/pay-invoice-model.component";
import { MatDialog } from "@angular/material/dialog";

declare var layui;
declare var layer;
@Component({
  selector: 'app-pay-invoice',
  templateUrl: './pay-invoice.component.html',
  styleUrls: ['./pay-invoice.component.css']
})
export class PayInvoiceComponent implements OnInit {
  // 筛选条件
  public paging = {
    offset: 0,
    limit: 9
  };
  public orderModel = {
    skip:0,
    limit:'10',
    can_invoice:'true',
  }

  //记录全选
  public allSelect = false;
  public taskList = [];
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)

  public data: any;
  public myDepartment: any;

  //重新发票对象
  public currentItem:any;

  public isRushList = false;

  public seleteCount = 0;

  public model = {
    paymentid: [],// 支付记录id payorderid
    amount: 0,//发票金额
    invoice_title_type: 'enterprise_unit',// 发票抬头类型 enterprise_unit 企业单位 personal 个人
    invoice_title: '',// 发票抬头
    id_number: '',// 身份证号
    register_no: '',// 税务登记号
    bank_name: '',// 基本开户银行名称
    bank_no: '',  // 基本开户账号
    register_address: '',// 注册场所地址
    register_tel: '',// 注册固定电话
    email: '',   // 发票接收邮箱
    remark: '',  // 备注
    address: '',
    getInvoiceList: [],  //可开发票列表,
    tax_classification:'' //发票内容
  }
  public endInvoiceList = [];  //已开发票列表
  public payment: any; // 申请开票的订单信息
  public workPlace = '';// 企业地址
  public amount: any; // 开票金额
  public checkBoxState = false;
  public tab = '1';
  public list = []
  public tax_classifications= [{code:'asdasd',text:"asdasda"}];

  public orderList = [];
  constructor(
    public opera: Opera,
    public dataApi: DataApi,
    public local: Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public regexManager:regexManager,
    public dialog: MatDialog,

  ) {
  }

  ngOnInit() {
    let topNav = this.local.get('nav_tool')
    this.opera.initConfig('tool', "payInvoice");
    this.invoiceableListFun();
    // this.recordsInvoicesIssued();


    console.log('是否是手机号或者座机',this.regexManager.isPhoneOrTel('+2332215738039217'))
    // console.log('是否是手机号或者座机',this.regexManager.isPhoneOrTel('0371-7632136'))
    // console.log('是否是手机号或者座机',this.regexManager.isPhoneOrTel('4001-550-520'))
    // console.log('是否是手机号或者座机',this.regexManager.isPhoneOrTel('400-123-4449'))
    //
    //
    // console.log('是否是手机号或者座机',this.regexManager.isPhoneOrTel('7632136'))
    // console.log('是否是手机号或者座机',this.regexManager.isPhoneOrTel('157380-39217'))
    // console.log('是否是手机号或者座机',this.regexManager.isPhoneOrTel('03717632136'))

    this.getDictClassification();
    this.getOrderList();
  }

  getOrderList(){
    let that = this;
    //待支付订单
    this.opera.loading();
    this.dataApi.getPaymentOrderPayList(this.orderModel).then((res:any)=>{//通过tooken获取企业信息
      this.opera.loadiss();
      if(res)
      {
        this.countList=res.count;
        this.pageCount =Math.ceil(res.count/10);
        this.orderList = res.list.map((item)=>{
          item.selete = false;
          item.order_state_text = that.formatOrderState(item.order_state);
          item.order_navData = that.formatOrderData(item);
          item.product_package_list = that.formatProduct_package_list(item);
          return item;
        });
        if(this.isRushList)
        {
          this.toastr.success('刷新成功');
          this.isRushList = false;
        }
      }
    }).catch((error)=>{
      this.opera.loadiss();
      this.toastr.error(error);
    });
  }

  tabClick(type) {
    this.tab = type;
    this.paging.offset = 0;
    this.orderModel.skip = 0;
    if (type == '1') {
      this.invoiceableListFun();
    } else {
      this.recordsInvoicesIssued();
      this.currentItem = null;
    }
    this.model.amount = 0;
    this.model.paymentid = [];
  }

  // checkbox按钮
  inputClick(e,n,index) {
    let isAll = true;
    let count = 0;
    this.orderList.map((item,i)=>{
      if(index==i)
      {
        item.selete = !item.selete;
      }
      if(!item.selete)
      {
        isAll = false;
      }
      if(item.selete)
      {
        count+=1;
      }
    });
    this.allSelect = isAll;
    this.seleteCount = count;
  }

  //全选按钮
  electionBtn() {
    let arr = [];
    this.allSelect = !this.allSelect;
    this.orderList.map((item)=>{
      item.selete = this.allSelect;
      return item;
    })
    if(this.allSelect)
    {
      this.seleteCount = 1;
    }
    else
    {
      this.seleteCount = 0;
    }
    console.log(' this.orderList', this.orderList,this.allSelect);
  }

  //可开发票列表
  invoiceableListFun() {
    this.getOrderList();
  }

  //已开发票记录
  recordsInvoicesIssued() {
    this.opera.loading();
    this.dataApi.newInvoiceableList(this.paging).then((res:any) => {
      this.opera.loadiss();
      if (res) {
        console.log('新已开发票列表', res);
        this.countList = res.count;
        this.pageCount = Math.ceil(res.count / 9);
        this.endInvoiceList = res.list;
        let newList =   res.list.map((item)=>{
          if(item.status=='uninvoiced')
          {
            item.invoice_status_text = "开票中"
          }
          else if(item.status=='invoicing')
          {
            item.invoice_status_text = "开票中"
          }
          else if(item.status=='invoiced'){
            item.invoice_status_text = "已开票"
          }
          else if(item.status=='invalid'){
            item.invoice_status_text = "已作废"
          }
          else if(item.status=='error'){
            item.invoice_status_text = "开票出错";
            if(item.fail_reason)
            {
              item.invoice_status_text =  "开票出错("+item.fail_reason+")";
            }
          }
          else if(item.status=='fail'){
            item.invoice_status_text = "开票失败"
          }
          return item;
        });
        if(this.isRushList)
        {
          this.toastr.success('刷新成功');
          this.isRushList = false;
        }
      }
    }).catch((err) => {
      this.toastr.error(err);
      this.opera.loadiss();
    })
  }

  //获取分页子组件返回数据
  getChildEvent(index) {
    if (index > 0) {
      this.pageNumber = index;
      this.paging.offset = (this.pageNumber - 1) * 9;
      this.orderModel.skip = (this.pageNumber - 1) * 9;
      if (this.tab == '1') {
        this.invoiceableListFun();//获取列表
      } else {
        this.recordsInvoicesIssued();//获取列表
      }
    } else if (index == -1) {//上一页
      if (this.pageNumber == 1) {
        this.toastr.warning("当前为第一页");
      } else {
        this.pageNumber = this.pageNumber - 1;
        this.paging.offset = (this.pageNumber - 1) * 9;
        this.orderModel.skip = (this.pageNumber - 1) * 9;
        if (this.tab == '1') {
          this.invoiceableListFun();//获取列表
        } else {
          this.recordsInvoicesIssued();//获取列表
        }
      }
    } else if (index == -2) {//下一页
      if (this.pageNumber == this.pageCount) {
        this.toastr.warning("当前为最后一页");
      } else {
        this.pageNumber = this.pageNumber + 1;
        this.paging.offset = (this.pageNumber - 1) * 9;
        this.orderModel.skip = (this.pageNumber - 1) * 9;
        if (this.tab == '1') {
          this.invoiceableListFun();//获取列表
        } else {
          this.recordsInvoicesIssued();//获取列表
        }
      }
    }
  }

  //获取分页子组件返回数据
  getChildEventTwo(index) {
    console.log('分页', index, this.tab)
    if (index > 0) {
      this.pageNumber = index;
      this.paging.offset = (this.pageNumber - 1) * 9;
      if (this.tab == '1') {
        this.invoiceableListFun();//获取列表
      } else {
        this.recordsInvoicesIssued();//获取列表
      }
    } else if (index == -1) {//上一页
      if (this.pageNumber == 1) {
        this.toastr.warning("当前为第一页");
      } else {
        this.pageNumber = this.pageNumber - 1;
        this.paging.offset = (this.pageNumber - 1) * 9;
        if (this.tab == '1') {
          this.invoiceableListFun();//获取列表
        } else {
          this.recordsInvoicesIssued();//获取列表
        }
      }
    } else if (index == -2) {//下一页
      if (this.pageNumber == this.pageCount) {
        this.toastr.warning("当前为最后一页");
      } else {
        this.pageNumber = this.pageNumber + 1;
        this.paging.offset = (this.pageNumber - 1) * 9;
        if (this.tab == '1') {
          this.invoiceableListFun();//获取列表
        } else {
          this.recordsInvoicesIssued();//获取列表
        }
      }
    }

  }

  // 发票须知
  receiptNotice() {
    this.opera.scrollTop();//页面滚动函数
    this.opera.dialog.open(ConfirmsComponent, {
      data: {
        title: '发票须知',
        message: `<div class="flex_col_left" style="text-align: left">
        <p>1.根据国家税务局规定，增值税电子普通发票的法律效力，基本用途，基本使用规定与纸质增值税普通发票相同。如需纸质报销，可将电子文档进行打印。</p>
        <p>2.电子发票将发送至您填写的邮箱，请注意查收。</p>
        <p>3.您申请的发票类型为增值税电子普通发票。</p>
        <p>4.当前可开发票的记录为2018年1月1日后，可开具对应支付金额。</p>
        <p>5.如对发票信息有疑问，请联系小秘书或拨打电话400-661-1396（9:00-18:00）。</p>
        </div>
        `,
        trueText: '知道了',
        letB: true,
        falseText: ''
      }
    }).afterClosed().subscribe(() => {
      this.opera.scrollTrue();
    });
  }
  //刷新列表
  rushListClick(){
    this.isRushList = true;
    if (this.tab == '1') {
      this.invoiceableListFun();//获取列表
    } else {
      this.recordsInvoicesIssued();//获取列表
    }
  }
  editPay(item){
   console.log('重新开启发票',item)
    this.opera.loading()
    this.dataApi.getInvoiceDetail(item.invoiceid).then((res:any) => {
      if (res) {
        console.log('获取发票信息', res);
        this.currentItem = item;
        this.currentItem.amount_payable = item.amount;
        let dialog =  this.dialog.open(PayInvoiceModelComponent, {
          data:{
            title:"支付",
            listData:[item],
            invoiceid:item.invoiceid,
            urm_create:item.urm_create,
            item:item,
            callback:(res)=>{
              console.log('callback',res)
              this.dialog.closeAll();
              if(res)
              {
                this.getOrderList();
                  this.toastr.success('申请成功')
              }
            },}
        }).afterClosed().subscribe((isConfirmed) => {
        });
      }
      this.opera.loadiss()
    }).catch((err) => {
      this.toastr.error(err);
      this.opera.loadiss()
    })
  }

  getDictClassification(){
    //获取发票内容
    this.dataApi.getDictDate('tax_classification').then((res:any)=>{
      if(res)
      {
        this.tax_classifications = res;
        if(res.length>0)
        {
          this.model.tax_classification = res[0].code;
        }
      }
    }).catch((error)=>{});
  }
  gotoInvoiceModel(n){
    let dialog =  this.dialog.open(PayInvoiceModelComponent,{
      data:{
        title:"支付",
        listData:[n],
        urm_create:n.urm_create,
        item:n,
        callback:(res)=>{
          console.log('callback',res)
          if(res)
          {
            this.toastr.success('申请成功')
          }
          this.dialog.closeAll();
          this.getOrderList();
        },}
    }).afterClosed().subscribe((isConfirmed) => {

    });
  }

  //导航上的时间
  formatOrderState(order_state){
    let state = "";
    switch (order_state) {
      case 'to_pay':
        state = "待付款";
        break;
      case 'canceled':
        state = "已失效";//后端定义 已取消
        break;
      case 'paid':
        state = "已付款";
        break;
      case 'executed':
        state = "已付款";//后端定义 已执行
        break;
      case 'refunding':
        state = "退款中"; //后端定义 已执行
        break;
      case 'refunded':
        state = "已退款";
        break;
      case 'deleted':
        state = "已失效";//后端定义 已删除
        break;
      case 'done':
        state = "已完成";
        break;
      default:
        state = "未知状态";
    }
    return state;
  }
  //导航上的日期
  formatOrderData(order){
    let order_state = order.order_state;
    let data = "";
    // 3 4 支付时间 pay_time
    // 8 完成时间 done_time
    // 7 失效时间 expiration_time
    // 6 退款时间 refund_time
    // 5 无
    // 否则
    // 创建时间 add_time
    if(order_state=='done'||order_state=='paid'||order_state=='executed')
    {
      data = "支付时间：" + this.RiQi(order.pay_time);
    }
    else
    {
      data = "创建时间：" + this.RiQi(order.add_time);
    }
    return data;
  }
  //时间戳转时间
  RiQi(sj) {
    if(!sj)
    {
      return '';
    }
    var now = new Date(sj);
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var date = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    let space = minute<=9?'0':'';
    let space2 = second<=9?'0':'';
    return year + "/" + month + "/" + date+"   " +hour+":"+space+minute +":"+space2+second;
  }
  formatProduct_package_list(item)
  {
    let arr = [];
    if(item.ladou_product_list)
    {
      item.ladou_product_list =  item.ladou_product_list.map((item)=>{
        item.quanyi = "/"
        return item;
      })
      arr = item.ladou_product_list;
    }
    if(item.product_member)
    {
      try {
        arr.push({
          cost:item.product_member.cost,
          count:1,
          product_category:'member',
          product_name:item.product_member.product_name,
          service_period:item.product_member.product_component_infos[0].count,
          total_cost:item.product_member.cost,
          quanyi:item.product_member.product_component_infos[0].count+'天'
        })
      }catch (e) {

      }
    }
    if(item.product_package) {
      item.product_package.map((e)=>{
        let count = 0;
        let unit = "";
        let service_period;
        if(e.product_component_infos)
        {
          count = e.product_component_infos[0].count;
          unit = e.product_component_infos[0].unit;
          e.product_component_infos.map((item)=>{
            if(item.alias=='service_period')
            {
              service_period=item;
            }
          })
        }
        unit =  unit?unit:"";
        arr.push({
          cost:e.cost,
          count:e.count,
          product_category:e.product_category,
          product_name:e.product_name,
          service_period:service_period?service_period.count:'',
          total_cost:e.count * e.cost,
          quanyi:count+unit+'/包',
          product_code:e.product_code
        })
      })
    }
    return arr;
  }

  //合并购买
  hebing(){
    let arr = [];
    this.orderList.map((item)=>{
      if(item.selete)
      {
        arr.push(item);
      }
    });
    if(arr.length==0)
    {
      this.toastr.warning('请选择要合并开票的订单')
      return;
    }
    let dialog =  this.dialog.open(PayInvoiceModelComponent, {
      data:{
        title:"支付",
        listData:arr,
        callback:(res)=>{
          console.log('callback',res)
          this.dialog.closeAll();
          this.getOrderList();
          this.seleteCount = 0;
          this.allSelect = false;
        },}
    }).afterClosed().subscribe((isConfirmed) => {
    });
  }

  myTool(){
    this.router.navigate(['home/tool-my']);
  }

  myOrder(){
    this.router.navigate(['home/paymentorderlist']);
  }

  myInvoice(){
    this.router.navigate(['home/payInvoice']);
  }

  myLadou(){
    this.router.navigate(['home/payHistory']);
  }
}





