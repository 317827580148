import { Component, Input,EventEmitter, Output } from '@angular/core';
import { DataApi,Local,Opera,Constants,Config,FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute,Router } from  '@angular/router';
declare var laydate;
@Component({
  selector: 'app-model-position-hired',
  templateUrl: './model-position-hired.component.html',
  styleUrls: ['./model-position-hired.component.css']
})
export class ModelPositionHiredComponent {
  public config: Config;
  @Input() isShow: false;//是否显示弹窗页面
  public model={
    salary: null,//试用期月薪
    entry_time: null ,//入职时间
    address:'',// 公司地址
    phone: null,//联系电话
    positionid:'',//职位id(未完成面试情况使用)
    remark: ''//备注
    // is_entry:  null,//true 确认入职 false 暂不考虑
  }

  public userJmUsername:any;//人才极光ID
  public completeDate = "";//日期
  public hourAndMinute = "10:00:00";//分钟

  public noticeInfo:any;//面试邀请
  public offer:any;//offer信息
  public isChat=false;//是否是聊天页面弹出
  public interviewid='';//面试邀请ID
  public position_name='';//职位名称
  public isClickSend=false;//防止重复点击
  public isSend=false;//判断是否点击了发送按钮
  public senOffer=false;
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public formatDate: FormatDate,
    public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
    this.getDepartment();//获取企业信息
  }

  @Output() onVoted = new EventEmitter<boolean>();
    dismiss(result: any) {
      //console.log("传值检查");
      //console.log(result);
      this.onVoted.emit(result);
    }

   //初始化签约通知页面
   initData(offer,isChat,id){
    console.log("进入签约通知检查参数");
    console.log(offer);
    console.log(isChat);
    console.log(id);//面试邀请id
    this.isChat=isChat;
    //清空之前选项
    this.isSend=false;
    this.model.salary='';//试用期月薪
    this.model.entry_time= '';//入职时间
    this.model.address='';// 公司地址
    this.model.phone= this.local.get(Constants.loginName);//联系电话
    this.model.remark= '';//备注
    this.model.positionid= '';//职位id
    //判断是否是无已完成面试邀请情况
    if(this.local.get(Constants.isByOffer)){
      this.senOffer=true;
      this.userJmUsername=JSON.parse(this.local.get(Constants.isByOffer)).userJmUsername;//赋值极光号
    }else{
      this.senOffer=false;
    }
    if(offer){//发起过offer
      this.offer=JSON.parse(JSON.stringify(offer));
      this.model.salary=this.offer.salary;//试用期月薪
      this.model.entry_time= this.offer.entry_time;//入职时间
      this.model.address=this.offer.address;// 公司地址
      this.model.phone= this.offer.phone;//联系电话
      this.model.remark= this.offer.remark;//备注
      this.position_name=this.offer.position_name;//职位名称
    }else{//第一次发起offer，填充数据
      this.interviewid=id;
      //填充企业地址
      this.getDepartmentLocation();
      if(!this.senOffer){
        this.getInterview(this.interviewid);
      }
    }

    this.completeDate = this.formatDate.formatDate(this.formatDate.addDays(new Date(),1));
    //日历插件初始化
    let that = this;
    laydate.render({
      elem: '#hiredDate',
      // type: 'year',
      done: function(value, date){
      that.completeDate = value;
      }
    })
    laydate.render({
      elem: '#hiredHour',
      type: 'time',
      done: function(value, date){
      that.hourAndMinute = value;
      }
    })

  }

  //发送录取通知
  sendNotice(){
    this.isClickSend = true;
    this.isSend=true;
    this.model.entry_time = this.completeDate+' '+this.hourAndMinute;
    //console.log(this.model);
    if(!this.model.address||!this.model.entry_time||!this.model.phone||!this.model.remark||!this.model.salary){
       this.toastr.warning("请完善录用通知");
       this.isClickSend = false;
       return;
    }
    this.opera.loading();
    if(this.senOffer){//不存在已完成面试
      if(!this.model.positionid){
        this.toastr.warning("请选择录用职位");
        this.isClickSend = false;
        return;
     }
     this.dataApi.sendOffter(JSON.parse(this.local.get(Constants.isByOffer)).talentid,this.model).then((res:any)=>{
      this.noticeInfo={interviewid:res.value};
      //  this.noticeInfo.interviewid=res.value;
       this.sendHiredSuc();
       this.opera.loadiss();
     }).catch((err)=>{
      this.isClickSend = false;
      this.isSend=false;
      console.log("测试1-----------");
      console.log(err);
      this.toastr.error(err);
      this.opera.loadiss();
     })
    }else{//存在已完成面试
      this.dataApi.sendInterviewOffer(this.noticeInfo.interviewid,this.model).then((res:any)=>{
        //console.log(res);
        this.sendHiredSuc();
        this.opera.loadiss();
      }).catch((err)=>{
        this.isClickSend = false;
        this.isSend=false;
        this.toastr.error(err);
        this.opera.loadiss();
      })
    }

  }

  //录用通知发送成功处理
  sendHiredSuc(){
    this.isClickSend = false;
    this.isSend=false;
    let model ={
         interviewid:this.noticeInfo.interviewid,//面试通知的id
         interview:{id:this.noticeInfo.interviewid,time:this.model.entry_time,location:this.model.address},
         feedback_status:'hired',//修改上个页面的面试状态
         contentText:"已发送录用通知"
    }
    if(this.isChat){
      this.dismiss(model);
    }else{
      this.postMsgServe(model);
    }
    this.toastr.success("录用通知发送成功");
  }

 //将消息提交到服务器和极光
 postMsgServe(_data){
  //-------发消息时更新本地存储
  this.setContactMsg(_data);
  this.jgPostChatMsg(_data);//消息发送到极光,放在这里(和发送到服务器异步执行)
  //------发送新消息时更新本地会话列表存储
  this.getNewChatList();
    //console.log("发送到服务器");
    //console.log(_data);
    let Menu ={
      id:_data.interview.id,
      status:_data.feedback_status,
      time:this.model.entry_time,
      location:this.model.address
    }
   let newMenu = JSON.stringify(Menu);
    //发送到服务器
    return this.dataApi.sendChat({
      jmusername:this.userJmUsername,//对方的极光ID
      message_type:"offer",//消息类型
      content:newMenu//消息内容
    }).then((res:any)=>{
      //console.log(res);
      let model ={
       interviewid:this.noticeInfo.interviewid,//面试通知的id
       interview:this.noticeInfo.interviewid,
       feedback_status:'hired',//修改上个页面的面试状态
       contentText:"已录用"
      }
      this.dismiss(model);
    }).catch((error)=>{
      //console.log(error);
    });
}

//将消息发送到极光
jgPostChatMsg(_model){
  let newMenu;
  let Menu ={
       id:_model.interview.id,
       status:_model.feedback_status,
       time:this.model.entry_time,
       location:this.model.address
  }
  newMenu = JSON.stringify(Menu);
  //发送到极光只需要  对方的极光ID + 消息内容(标题) + 消息内容(对象内容 extras )
  let newModel = {
    username:this.userJmUsername,
    contentText:_model.contentText,
    extras:{
      type:"offer",
      content:newMenu
    }
  }
 //console.log("发送到极光");
 //console.log(newModel);
}


 //更新本地存储
  //更新本地存储----发送新消息时（暂时）
  setContactMsg(model){
    let isNoneTime = false;
    //console.log("获取参数");
    //console.log(model);
    if(this.local.get(Constants.chatsMsgList)){
      this.config.chatsMsgList = this.dataApi.getLocalChatsMsgList();//聊天记录本地存储
      for(let val of this.config.chatsMsgList){
        if(val.userID == this.userJmUsername){
           //console.log("更新本地存储");
           if(val.cont){
              //本地聊天记录超过十条时
              if(val.cont.length>9){
                 //console.log("即将删除本地存储数量");
                 val.cont.splice(0,1);
                }
            }
          //判断是否要显示时间
           let nowDate = new Date();
           let valDate;
           if(val.cont){
             if(val.cont.length>0){
              if(val.cont[val.cont.length-1].send_time.toString()[10]=="T"){
                valDate = new Date(val.cont[val.cont.length-1].send_time+'+08:00');
              }else{
                valDate = new Date(val.cont[val.cont.length-1].send_time);
              }
              let MIN = (nowDate.getTime()-valDate.getTime())/1000;
              if(MIN<300){
                isNoneTime = false;
              }else{
                isNoneTime = true;
              }
              //console.log(MIN);
            }else{
             isNoneTime = true;
            }
          }

          //本地存储数据
          let text ={
              message_type:"offer",
              content:model.interview,
              username:this.config.department.jm_username,//判断消息的发送方
              send_time:nowDate,
              isNoneTime:isNoneTime
            };
          val.cont.push(text);
          //console.log(this.config.chatsMsgList);
          this.dataApi.setLocalChatsMsgList(this.config.chatsMsgList);//更新本地存储
        }
      }
    }
  }


  //更新会话列表本地存储
  getNewChatList(){
    //console.log("会话列表本地存储");
    //console.log(this.config.chatsList);
    for(let val of this.config.chatsList.list){
      if(this.noticeInfo.jm_username==val.jm_username){
        val.talent.interview={id:this.noticeInfo.interviewid,status:'offer'};
      }
    }
  }


  //检测联系电话格式
  checkPhone(){
    if(String(this.model.phone).length==0){
      this.toastr.warning("请填写联系电话");
      return;
   }else{
     let phoneModel={
       format_type:1,//0:手机号，1：手机号&固话；2.邮箱
       data:this.model.phone
     }
     this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
       if(!res.value){
        this.toastr.warning("联系电话格式错误");
        return;
       }
     }).catch((err)=>{
       //console.log(err);
       this.toastr.error(err);
     })
   }
  }

  //获取企业信息
  getDepartment(){
      if(!this.dataApi.getLocalDepartment()){
        this.dataApi.getDepartment().then((res:any)=>{
          this.dataApi.setLocalDepartment(res);
        }).catch((error) => {
          //console.log(error);
          this.toastr.error(error);
        });
      }else{
        this.config.department=this.dataApi.getLocalDepartment();
      }
  }
  //获取面试信息
  getInterview(id){
    this.opera.loading();
    this.dataApi.getInterviewById(id).then((res:any)=>{
        //console.log("获取到面试邀请的详情-------model-position-hired");
        //console.log(res);
        this.userJmUsername=res.jm_username;//赋值极光号
        this.opera.loadiss();
        //赋值
        this.noticeInfo = res;
        this.model.address= this.noticeInfo.location.region_text+','+this.noticeInfo.location.address;
        this.model.phone = this.noticeInfo.phone;
        //面试邀请id赋值
        this.interviewid = this.noticeInfo.interviewid;
        this.position_name=this.noticeInfo.position_name;//职位名称
      }).catch((err)=>{
        console.log(err);
        this.opera.loadiss();
      })
  }

  //获取企业信息(填充企业地址)
  getDepartmentLocation(){
    console.log("填充企业地址----");
    console.log(this.dataApi.getLocalDepartment());
    if(this.dataApi.getLocalDepartment()){
      this.config.department = this.dataApi.getLocalDepartment();
      if(this.config.department.locations){
        let addressDefaul=false;
        for(let val of this.config.department.locations){
          if(val.is_default){
            addressDefaul=true;
            this.model.address =val.lid;//公司地址(第一次发送面试邀请采用公司默认地址)
            return;
          }
        }
        if(!addressDefaul){
          this.model.address = this.config.department.locations[0].lid;//公司地址(第一次发送面试邀请采用公司默认地址)
        }
      }else{
        this.toastr.warning("请添加企业地址");
        this.router.navigateByUrl("home/company-details-address");
      }
      //console.log("企业信息本地存储----position-notice-details");
      //console.log(this.dataApi.getLocalDepartment());
    }else{
      this.opera.loading();
      this.dataApi.getDepartment().then((res:any)=>{
        //console.log("企业信息获取线上数据----position-notice-details");
        //console.log(res);
        this.config.department = res;
        if(this.config.department.locations){
          let addressDefaul=false;
          for(let val of this.config.department.locations){
            if(val.is_default){
              addressDefaul=true;
              this.model.address = val.lid;//公司地址(第一次发送面试邀请采用公司默认地址)
              return;
            }
          }
          if(!addressDefaul){
            this.model.address = this.config.department.locations[0].lid;
          }
        }
        this.dataApi.setLocalDepartment(res);
        this.opera.loadiss();
      }).catch((error) => {
        this.opera.loadiss();
        //console.log(error);
        this.toastr.error(error);
      });
    }
  }

}
