<!--<div class="contentRight-header">-->
<!--<img  (click)="opera.goNewRouter('tool-campus')" src='assets/images/router-old.png'/>校招工具</div>-->
<div class="homepage-block">

    <div class="box">
        <div class="college-block-head" (click)="back()">
            <img style="margin-top: -4px;" src='assets/images/router-old.png' /> 校招活动邀请
        </div>
        <div class="content">
            <div class="Pagetitle">
                <img class="titleIcon" src="../../assets/images/activityInvitationTitle.png" />
            </div>

            <div class="activityCardBg">
                <div class="activityInfoCell marginTop33px">
                    <span class="activityInfoCellTitle">活动类型：</span>
                    <span class="activityInfoCellSubTitle">{{model.activityType}}</span>
                </div>
                <div class="activityInfoCell ">
                    <div class="leftCellBox">
                        <span class="activityInfoCellTitle">活动名称：</span>
                        <span class="activityInfoCellSubTitle">{{model.activityName}}</span>
                    </div>

                    <div class="leftCellBox">
                        <span>活动时间：</span>
                        <span class="activityInfoCellSubTitle">{{model.activityTime}}</span>
                    </div>
                </div>
                <div class="line"></div>
                <div class="activityCardBottom">
                    <div class="leftCellBox2">
                        <img class="collegeIcon" src="{{model.college_logo}}" />
                        <span class="collegeName">举办学校： {{model.college_name}}</span>
                    </div>
                    <span class="activityInfoCellSubTitle1">毕业人数： {{collengeAllCount}}</span>
                </div>
            </div>

            <!--院系-->
            <div class="yuanxiBg">
                <div class="yuanxi_l">
                    <img class="yuanxi_l_icon" src="../../assets/images/positionpush-college.png" />
                    <div class="yuanxi_l_title">高校</div>
                </div>
                <div class="yuanxi_r">
                    <!-- <select class="seleteCard " [value]="filtModel.college" [(ngModel)]="filtModel.college"
                        (ngModelChange)="seletecollegeChange()">
                        <option value="">不限</option>
                        <option value="{{n.collegeid}}" *ngFor="let n of allCollege">{{n.name}}</option>
                    </select> -->

                    <p-multiSelect placeholder="选择高校" [style]="{'width':'574px','height':'45px','border':'1px solid #EEEEEE'}" display="chip" [options]="allCollege" [showToggleAll]="false" [(ngModel)]="collegeids" optionLabel="name" optionValue="collegeid" [selectionLimit]="5"
                        (onChange)="multiSelectonChange()" emptyMessage="暂无数据">
                    </p-multiSelect>
                    <span class="seleteYuanxiCount">{{collegeids.length}}/5</span>
                    <!-- <div class="seleteYuanxiBox" *ngIf="seletecollege.length>0">
                        <span class="yuanxiItem" *ngFor="let n of seletecollege" (click)="collegeClick(n)">
                            {{n.name}}
                        </span>
                    </div> -->
                </div>
            </div>

            <div class="yuanxiBg">
                <div class="yuanxi_l">
                    <img class="yuanxi_l_icon" src="../../assets/images/activityInvitationYuanxi.png" />
                    <div class="yuanxi_l_title">专业</div>
                </div>
                <div class="yuanxi_r">
                    <select class="seleteCard " [value]="filtModel.majorOne" [(ngModel)]="filtModel.majorOne" (ngModelChange)="seleteyuanxiChange('')">
                        <option value="">专业类别</option>
                        <option value="{{n.code}}" *ngFor="let n of yuanxi1">{{n.text}}</option>
                    </select>

                    <select class="seleteCard marginLeft15" [value]="filtModel.majorTwo" [(ngModel)]="filtModel.majorTwo" (ngModelChange)="seleteyuanxiChange2()">
                        <option disabled value="">选择专业</option>
                        <option value="{{n.code}}" *ngFor="let n of yuanxi3">{{n.text}}</option>
                    </select>

                    <span class="seleteYuanxiCount">{{seleteyuanxi.length}}/5</span>
                    <div class="seleteYuanxiBox" *ngIf="seleteyuanxi.length>0">
                        <span class="yuanxiItem" *ngFor="let n of seleteyuanxi" (click)="yuanxiClick(n)">
                            {{getParMajor(n)}}
                        </span>
                    </div>
                </div>
            </div>

            <!--院系-->
            <div class="yuanxiBg">
                <div class="yuanxi_l">
                    <img class="yuanxi_l_icon2" src="../../assets/images/activityInvitationXueli.png" />
                    <div class="yuanxi_l_title">学历</div>
                </div>
                <div class="yuanxi_r">
                    <select class="seleteCard seleteXueliW " [value]="filtModel.education" [(ngModel)]="filtModel.education" (ngModelChange)="smsDepartment()">
                        <option value="">不限</option>
                        <option value='02'>大专</option>
                        <option value='03'>本科</option>
                        <option value='04,07'>硕士及以上</option>
                    </select>
                </div>
            </div>
            <div class="yuanxiBg">
                <div class="yuanxi_l">
                    <img class="yuanxi_l_icon2" style="width: 15px;height: 15px" src="../../assets/images/positionpush-sex.png" />
                    <div class="yuanxi_l_title">性别</div>
                </div>
                <div class="yuanxi_r">
                    <select class="seleteCard seleteXueliW " [value]="filtModel.sex" [(ngModel)]="filtModel.sex" (ngModelChange)="smsDepartment()">
                        <option value="">不限</option>
                        <option value='01'>男</option>
                        <option value='02'>女</option>
                    </select>
                </div>
            </div>
            <div class="yuanxiBg">
                <div class="yuanxi_l">
                    <img class="yuanxi_l_icon" style="width: 16px;height: 18px" src="../../assets/images/positionpush-place.png" />
                    <div class="yuanxi_l_title">籍贯</div>
                </div>
                <div class="yuanxi_r">
                    <select class="seleteCard " [value]="filtModel.areaOne" [(ngModel)]="filtModel.areaOne" (ngModelChange)="seleteareaChange('')">
                        <option value="">请选择所在省份</option>
                        <option value="{{n.code}}" *ngFor="let n of area1">{{n.text}}</option>
                    </select>

                    <select class="seleteCard marginLeft15" [value]="filtModel.areaTwo" [(ngModel)]="filtModel.areaTwo" (ngModelChange)="seleteareaChange2()">
                        <option disabled value="">请选择所在市</option>
                        <option value="{{n.code}}" *ngFor="let n of area3">{{n.text}}</option>
                    </select>


                    <span class="seleteYuanxiCount">{{seletearea.length}}/5</span>
                    <div class="seleteYuanxiBox" *ngIf="seletearea.length>0">
                        <span class="yuanxiItem" *ngFor="let n of seletearea" (click)="areaClick(n)">
                            {{getParArea(n)}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="bottomRow1">
                <span>符合以上条件的学生数：</span>
                <span class="studentCount">{{filtModel.filtCount}}人</span>
                <span class="studentChips">如需邀请更多学生，请适当放宽筛选条件</span>
            </div>
            <div class="bottomRow2">
                <span>确定你要通知的学生数：</span>
                <p (click)="countBtnClick(-1)" class="addBtn marginLeft30">-</p>
                <input value="{{filtModel.count}}" oninput="value=value.replace(/[^\d]/g,'')" (ngModelChange)="inputChange($event)" [(ngModel)]="filtModel.count" class="inputCount" />
                <p (click)="countBtnClick(1)" class="addBtn">+</p>
            </div>

            <div class="smsContent" *ngIf="SmsContent">
                {{SmsContent}}
            </div>
            <div class="commitBg">
                <span class="shengyuLabi">需消耗<span style="color:#FF3C3C ">{{filtModel.count}}</span>资源
                <span *ngIf="(MyActivityCount -filtModel.count)>=0">（消耗后剩余<span
                            style="color:#FF3C3C ">{{MyActivityCount-filtModel.count}}</span>资源）</span>
                <span *ngIf="(MyActivityCount -filtModel.count)<0">（剩余资源不足）</span>
                </span>
                <button class="commitBtnInval" (click)="commitAlert()">立即邀请</button>
                <span class="shengyuLabi chipsBottom"></span>
            </div>
        </div>

    </div>
</div>

<p-dialog id="bigPackage" [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':580,'height':351,'minHeight':350}" [modal]="true" [showHeader]="false"
    [(visible)]="isShowPushModel">
    <div class="big-package">
        <div class="text1">可用{{MyActivityCount ? MyActivityCount: '0'}}次</div>
        <div class="text2">{{filtModel.count}}次</div>
        <div (click)="commit()" class="fre-btn"></div>
    </div>
</p-dialog>

<p-dialog [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':580,'height':351,'minHeight':350}" [modal]="true" [showHeader]="false" [(visible)]="isShowSeat_numberModel">
    <div class="seat-package">
        <img class="seatClose" (click)="closrSeatModel()" src="../../assets/images/close_default.png" />
        <div class="seatDiv">
            <span>请输入您的展位号：</span>
            <input placeholder="请输入您的展位号" [(ngModel)]="seatModel.seat" />
        </div>
        <p class="seatShips">
            请联系学校获取准确的展位号进行填写，展位号填写错误可能导致到场学生无法准确寻找到您的展位</p>
        <div class="seatPush" [ngClass]="{seatPush_live:seatModel.seat,seatPush:!seatModel.seat}" (click)="seatPush()">
            推送
        </div>
    </div>
</p-dialog>