import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { DataApi, Local, Constants, Opera, Config, FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelSelectTwoComponent } from '../model-select-two/model-select-two.component';
import { ModelCompanyLabelComponent } from '../model-company-label/model-company-label.component';
import { ModelCompanyProductComponent } from '../model-company-product/model-company-product.component';
import { ModelCompanyCoursesComponent } from '../model-company-courses/model-company-courses.component';
import { ModelCompanyStrengthComponent } from '../model-company-strength/model-company-strength.component';
import { ModelCropperComponent } from '../model-cropper/model-cropper.component';
import { ModelCompanyNameComponent } from '../model-company-name/model-company-name.component';
import { ConfirmComponent } from '../confirm/confirm.component';
import { regexManager } from "../../provider/regexManager";
import { UserProtocolModelComponent } from "../user-protocol-model/user-protocol-model.component";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
var update;
var tcVod;
var screenshot = true;
declare let laydate;
declare var window;
declare var TcVod;
declare var axios;
@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css']
})
export class CompanyDetailsComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true })
  productModel: ProductModelComponent;

  @ViewChild('industry', { static: true }) child: ModelSelectTwoComponent;
  @ViewChild('label', { static: true }) child1: ModelCompanyLabelComponent;
  @ViewChild('product', { static: true }) child2: ModelCompanyProductComponent;
  @ViewChild('courses', { static: true }) child3: ModelCompanyCoursesComponent;
  @ViewChild('strength', { static: true }) child4: ModelCompanyStrengthComponent;
  @ViewChild('crop', { static: true }) child5: ModelCropperComponent;
  @ViewChild('name') child6: ModelCompanyNameComponent;
  @Output() modelSevenShow = false;//是否打开弹窗
  @Output() modelSevenType = 1;//显示内容调整
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelTwoShow = false;//是否打开弹窗
  @Output() modelThreeShow = false;//是否打开弹窗
  @Output() modelFourShow = false;//是否打开弹窗
  @Output() modelFiveShow = false;//是否打开弹窗
  @Output() modelSixShow = false;//是否打开弹窗
  public config: Config;
  //企业数据(企业信息,修改资料的时候不用这个传递)
  public myDepartment: any;
  //需要修改的企业资料初始化
  public model = {
    industry: '',//所属行业
    employer_type: '',//企业性质
    region: '',//所在地区
    scale: '',//公司规模
    intro: '',//企业简介
    is_quoted_company: false,//是否是上市公司
    stock_code: '',//股票代码
    strengths: [],//公司实力
    official_account: '',//微信公众号
    website: '',//企业网址
    labels: [],//企业标签
    register_capital: null,//注册资金（万元）
    establish_year: null,//成立年份
    products: [],//产品
    courses: [],//发展历程
    image_urls: [],//照片展示
    video_link: '',//企业视频
    video_cover_link: '',//企业视频封面
    register_capital_unit: '',
    employer_category: ''//企业注册地，不可修改

  };
  public employerVip = false;
  //企业当前地址
  public isShowQrcode = false;
  public workPlace = '';
  public register_capital_type = '万元';
  public productid = "";//产品id
  public coursesid = "";//发展历程id
  public CompanyStatus = 2;//公司资质审核状态，默认未2，已通过

  public companyState = '';//1 已完成；2修改姓名+营业执照
  public ShowUpdateModel2 = false;// 修改企业的弹窗
  public ShowUpdateModel3 = false;// 修改企业简称的弹窗

  public getUpdateStateModel: any;

  //修改企业的模型
  public updateComModel = {
    updateType: '',
    department_name: '', //企业名称
    register_number: "",//企业唯一标识
    business_licence_url: '',//营业执照
    change_letter_url: '', //资质变更书
    common_name: "",//企业简称
    business_licence_extension: "",//营业执照扩展名
    business_licence_filename: "",//营业执照文件名称
    change_letter_extension: "",//扩展名,
    change_letter_filename: "",//文件名 ,
    remark: "",//申请原因
  };
  public screenshot = false; //是否需要上传视频封面图
  public UploadImage;
  public videoUrl;
  public signature;
  public isShowVideo = true;
  public isShowVideoModel = false;
  public updateShortComModel = {
    department_name: '', //企业名称
    imageList: [],
    remark: ''
  };


  public whetherlist = [{
          text:'上市公司',
          code:'01'
        },{
          text:'非上市公司',
          code:'02'
        }]
  public is_quoted_company = '02'
  constructor(public dataApi: DataApi,
    public local: Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public opera: Opera,
    public formatDate: FormatDate,
    public dialog: MatDialog,
    public regexManager: regexManager,
    public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {

    update = {
      'api': this.config.api,
      'client_id': this.config.clientId,
      'current_system': this.config.current_system,
      'access_token': this.local.get(Constants.accesstoken),
      'accountid': this.local.get(Constants.accountid)
    }
    this.signature = function getSignature() {
      return axios({
        method: 'GET',
        url: `${update.api}/department/upload/signature`,
        params: {},
        headers: {
          'client_id': update.client_id,
          'current_system': update.current_system,
          'access_token': update.access_token,
          'accountid': update.accountid
        }
      }).then(res => {
        console.log(res.data.value, '初始化获取秘钥');
        return res.data.value
      }).catch(error => {

      });
    };

    this.opera.initConfig('company', 'company-details');
    // this.opera.refreshPage();

    // //console.log("日期插件使用");
    // //console.log(laydate.render({elem: '#test',type: 'year',}));
    this.getIndustry();//获取所属行业
    this.getEmployerType();//获取企业类别
    this.getDepartment();//获取企业信息
    this.getEmployerScale();//获取企业规模
    this.isVip();
    this.getUpdateState();
    if (this.config.is_frozen == true) {
      this.dialog.open(ConfirmComponent, {
        data: {
          title: '冻结提示',
          message: `因账号存在发布虚假招聘信息、以招聘名义招生等违规招聘行为被学生或高校投诉，您的账号已被冻结，目前仅可使用“企业介绍”及“职位发布”功能。7个工作日未解封或未接到工作人员电话，可发申诉信息致service@gowork.la。`,
          trueText: '确定',
          falseText: '取消'
        }
      })
    }
    let that = this;
    laydate.render({
      elem: '#establish',
      type: 'year',
      // showBottom: false,
      trigger: 'mousedown',
      theme: '#5e4fff',
      done: function (value, date) {
        that.model.establish_year = value;
      }
    })


  }

  //删除企业展示图片
  deleteImg(img) {
    this.model.image_urls.forEach((val) => {
      if (val == img) {
        let num = this.model.image_urls.indexOf(val);
        this.model.image_urls.splice(num, 1);
      }
    })
  }


  viewMiniPro() {

    //接口
    this.isShowQrcode = true;
  }
  //去企业发展历程
  goCourses() {
    this.coursesid = "";
    this.modelFourShow = true;
    this.child3.initData(false);
    this.opera.scrollTop();//页面滚动函数
  }
  //去编辑企业发展历程
  goEditorCourses(n) {
    this.coursesid = n.cid;
    this.modelFourShow = true;
    this.child3.initData(n);
    this.opera.scrollTop();//页面滚动函数
  }

  //接收子组件返回数据
  ModelFourdismiss(event) {
    this.modelFourShow = false;
    this.opera.scrollTrue();
    if (event) {
      //重新赋值企业产品
      if (!this.coursesid) {//添加
        this.model.courses.push(event);
      } else {
        if (event == 'delete') {//删除
          for (let n of this.model.courses) {
            if (n.cid == this.coursesid) {
              this.model.courses.splice(this.model.courses.indexOf(n), 1);
            }
          }
        } else {//修改
          for (let n of this.model.courses) {
            if (n.cid == this.coursesid) {
              n = event;
            }
          }
        }
      }
      this.myDepartment.courses = this.model.courses;
      this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
    }
  }

  //去企业产品
  goProduct() {
    this.productid = "";
    this.modelThreeShow = true;
    this.child2.initData(false);
    this.opera.scrollTop();//页面滚动函数
  }

  //编辑企业产品
  goEditorProduct(n) {
    this.productid = n.pid;
    this.modelThreeShow = true;
    this.child2.initData(n);
    this.opera.scrollTop();//页面滚动函数
  }

  //删除产品
  deleteProduct(item){
    console.log(item);
    // this.dataApi.deleteDepartmentProduct(this.model.pid).then((res:any)=>{
    //   //console.log(res);
    //   this.toastr.success('产品已删除');
    //   //this.dismiss('delete');
    // })

  }

  //接收子组件返回数据
  ModelThreedismiss(event) {
    this.modelThreeShow = false;
    this.opera.scrollTrue();
    if (event) {
      //重新赋值企业产品
      if (!this.productid) {//添加
        this.model.products.push(event);
      } else {
        if (event == 'delete') {//删除
          for (let n of this.model.products) {
            if (n.pid == this.productid) {
              this.model.products.splice(this.model.products.indexOf(n), 1);
            }
          }
        } else {//修改
          for (let n of this.model.products) {
            if (n.pid == this.productid) {
              n = event;
            }
          }
        }
      }
      this.myDepartment.products = this.model.products;
      this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
    }
  }

  //上传logo
  previewPic(event) {
    let imgData = event.target.files[0]; //获取file对象
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e: any) {
        that.imageCropper(reader.result);
      };
    }

  }

  //裁剪插件
  imageCropper(imageData) {
    this.modelSixShow = true;
    this.child5.initData(imageData);
    this.opera.scrollTop();//页面滚动函数
  }

  //接收子组件返回数据
  ModelSixdismiss(event) {
    this.modelSixShow = false;
    this.opera.scrollTrue();
    if (event) {
      this.uploadDepLogoImg(event);
    }
  }

  //上传
  uploadDepLogoImg(imageData) {
    imageData = this.dataApi.formatImage(imageData)
    this.opera.loading();//加载动画
    this.dataApi.uploadDepartmentLogo({ value: imageData }).then((res: any) => {
      this.myDepartment.logo = res.value;
      this.opera.loadiss();//停止加载动画
      this.dataApi.setLocalDepartment(this.myDepartment);
      setTimeout(() => {
        this.toastr.success('上传成功');
      }, 600);
    }).catch((error) => {
      this.opera.loadiss();//停止加载动画
      this.toastr.error('上传图片错误');
    })
  }

  //判断是否会员
  isVip() {
    let that = this;
    this.opera.checkMember((isPay) => {
      console.log(isPay);
      that.employerVip = isPay;
      // if(isPay) {
      //   if(isvideo){
      //     document.getElementById("uploadDepartmentvideo").click();
      //   }else{
      //     document.getElementById("uploadDepartmentvideoCover").click();
      //   }
      // }else {
      //   that.opera.checkMemberAlertWithType("goBuy");
      // }
    })
  }
  govieBuy() {
    //this.opera.checkMemberAlertWithType("goBuy");
    this.product('short_video','recharge');
  }


  //企业展示视频
  departmentVideo(event) {
    let files = event.target.files[0]; //获取file对象
    //视频时长不能大于3分钟 180秒
    var url = window.URL.createObjectURL(files);
    var audioElement = new Audio(url);
    var duration;
    let that = this;
    audioElement.addEventListener("loadedmetadata", function (_event) {
      duration = audioElement.duration;
      console.log(duration + 's');
      if (duration > 180) {
        that.toastr.error('视频不能超过3分钟');
        return
      }
      that.upVideo(files);
    });

  }


  async upVideo(files) {
    this.opera.loading();//加载动画
    this.isShowVideo = false;
    let that = this;
    tcVod = new TcVod.default({
      getSignature: this.signature // 前文中所述的获取上传签名的函数
    })
    const uploader = await tcVod.upload({
      mediaFile: files, // 媒体文件（视频或音频或图片），类型为 File
    })
    // 视频上传完成时
    uploader.on('media_upload', function (info) {

    })

    uploader.done().then(function (doneResult) {
      that.opera.loadiss();//停止加载动画
      // deal with doneResult
      console.log('deal with doneResult', doneResult.video.url);
      that.myDepartment.video_link = doneResult.video.url;
      that.isShowVideo = true;
      that.serveVideo();
    }).catch(function (err) {
      that.opera.loadiss();//停止加载动画
      that.isShowVideo = true;
      // deal with error
      console.log('deal with error', err);
    })
  }

  //企业展示视频封面
  departmentVideoCover(event) {
    let files = event.target.files[0]; //获取file对象
    this.upVideoCover(files);
  }

  async upVideoCover(files) {
    this.opera.loading();//加载动画
    let that = this;
    tcVod = new TcVod.default({
      getSignature: this.signature // 前文中所述的获取上传签名的函数
    })
    const uploader = await tcVod.upload({
      mediaFile: files, // 媒体文件（视频或音频或图片），类型为 File
    })
    // 视频上传完成时
    uploader.on('media_upload', function (info) {

    })

    uploader.done().then(function (doneResult) {
      // deal with doneResult
      that.myDepartment.video_cover_link = doneResult.video.url;
      that.opera.loadiss();//停止加载动画
      that.serveVideo();
      console.log('deal with doneResult');
    }).catch(function (err) {
      // deal with error
      that.opera.loadiss();//停止加载动画
      console.log('deal with error', err);
    })
  }

  //企业展示图片
  previewDepartmentPic(event) {
    console.log('企业展示图片');
    if (this.model.image_urls.length > 9) {
      this.toastr.warning("企业展示图片最多上传10张");
      return;
    }
    let imgData = event.target.files[0]; //获取file对象
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e: any) {
        that.uploadDepImg(reader.result);
      };
    }
  }

  //上传企业展示图片
  uploadDepImg(data) {
    let imageData = this.dataApi.formatImage(data);
    // let loader = this.tip.presentLoading('图片保存中，请稍等...');
    this.dataApi.uploadDepartmentImg({
      value: imageData
    }).then((res: any) => {
      // loader.dismiss();
      if (this.ShowUpdateModel3) {
        this.updateShortComModel.imageList.push(res.value)
        return;
      }
      this.model.image_urls.push(res.value);
    }).catch((error) => {
      // loader.dismiss();
      this.toastr.error('上传图片错误');
    })
  }


  // 获取图片
  getPicData(event, name) {
    let imgData = event.target.files[0]; //获取file对象
    let imageName = event.target.files[0].name;
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e: any) {
        if (that.ShowUpdateModel3) {
          that.uploadDepImg(reader.result)
          return;
        }
        that.uploadAuthImg(reader.result, name, imageName);
      };

    }
  }



  // 上传图片
  uploadAuthImg(data, name, imageName) {
    let imageData = this.dataApi.formatImage(data);


    this.dataApi.uploadAuthImg({
      value: imageData
    }).then((res: any) => {
      //文件扩展名的正则表达式
      var patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
      //获得文件扩展名
      var fileExtension = (imageName).match(patternFileExtension);
      // loader.dismiss();
      if (name == "yingyezhizhao") {
        this.updateComModel.business_licence_url = res.value;
        this.updateComModel.business_licence_extension = fileExtension[1];
        this.updateComModel.business_licence_filename = imageName.split('.')[0];
      } else if (name == "biangeng") {
        this.updateComModel.change_letter_url = res.value;
        this.updateComModel.change_letter_extension = fileExtension[1];
        this.updateComModel.change_letter_filename = imageName.split('.')[0];
      }
      else if (name == "updateShortName") {
        this.updateShortComModel.imageList.push(res.value)
      }
    }).catch((error) => {
      // loader.dismiss();
      this.toastr.error('上传图片错误');
    })
  }



  //去企业标签
  goLabel() {
    this.modelTwoShow = true;
    this.opera.scrollTop();//页面滚动函数
    let newArray = JSON.parse(JSON.stringify(this.model.labels));
    //调用子组件函数
    this.child1.initData(newArray);
  }

  //接收子组件返回数据
  ModelTwodismiss(event) {
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    if (event) {
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      //重新赋值企业标签
      this.model.labels = event;
      this.myDepartment.labels = this.model.labels;
      this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
    }
  }

  //去公司实力
  goStrengths() {
    this.modelFiveShow = true;
    this.opera.scrollTop();//页面滚动函数
    let newArray = JSON.parse(JSON.stringify(this.model.strengths));
    console.log(this.model.strengths)
    //调用子组件函数
    this.child4.initData(newArray);
  }

  //接收子组件返回数据
  ModelFivedismiss(event) {
    this.modelFiveShow = false;
    this.opera.scrollTrue();
    if (event) {
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      //重新赋值企业标签
      this.model.strengths = event;
      this.myDepartment.strengths = this.model.strengths;
      this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
    }
  }

  //去地址列表
  goAddress(): void {
    // this.router.navigate(['home/company-details-address']);
    this.router.navigateByUrl("home/company-details-address");
  }
  //选择行业类别
  chooseIndustry() {
    if (this.model.industry) {
      return
    }
    this.opera.scrollTop();//页面滚动函数
    this.modelShow = true;
    //调用子组件函数
    this.child.initData(this.model.industry, this.config.industry, '选择行业类别');
  }
  //接收子组件返回数据
  Modeldismiss(event) {
    this.modelShow = false;
    this.opera.scrollTrue();
    if (event) {
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      this.model.industry = event.code;
    }
  }

  //获取所属行业
  getIndustry() {
    if (this.local.get(Constants.industry)) {
      //console.log('获取行业类型本地存储');
      //console.log(this.dataApi.getIndustryCode());
      this.config.industry = this.dataApi.getIndustryCode();
    } else {
      //获取行业类型字典
      this.dataApi.getDictDate('industry').then((res: any) => {
        //console.log('获取行业类型线上数据');
        //console.log(res);
        this.dataApi.setIndustryCode(res);
      }).catch((error) => {
        //console.log(error);
      });
    }
  }

  //获取企业类别
  getEmployerType() {
    if (this.local.get(Constants.employer_type)) {
      this.config.employer_type = this.dataApi.getEmployerTypeCode();
    } else {
      //获取企业类别字典
      this.dataApi.getDictDate('employer_type').then((res: any) => {
        //console.log('获取企业类别线上数据');
        //console.log(res);
        this.dataApi.setEmployerTypeCode(res);
        this.config.employer_type = res;
      }).catch((error) => {
        //console.log(error);
      });
    }
  }

  //获取企业规模字典
  getEmployerScale() {
    if (this.dataApi.getScaleCode()) {
      this.config.scale = this.dataApi.getScaleCode();
    } else {
      //获取企业规模字典
      this.dataApi.getDictDate('scale').then((res: any) => {
        this.config.scale = res;
        this.dataApi.setScaleCode(res);
      }).catch((error) => {
        //console.log(error);
      });
    }

  }


  //删除企业视频
  delVideo() {

    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '确定删除视频吗？',
        trueText: '确定',
        falseText: '取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.model.video_link = '';
        this.myDepartment.video_link = '';
        this.config.department.video_link = '';
        this.serveVideo();
      }
    });
  }

  delVideoCover() {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '确定删除视频封面吗？',
        trueText: '确定',
        falseText: '取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.myDepartment.video_cover_link = '';
        this.config.department.video_cover_link = '';
        this.model.video_cover_link = '';
        this.serveVideo();
      }
    });
  }

  //初始化当前页面数据
  initDeparment(_department) {
    this.myDepartment = _department;
    //当前页面使用不到的值
    if (this.myDepartment.register_capital) {//注册资金
      this.model.register_capital = this.myDepartment.register_capital;
    }
    if (this.myDepartment.establish_year) {//成立年份
      // this.model.establish_year = String(this.myDepartment.establish_year);
      this.model.establish_year = this.myDepartment.establish_year;
    }
    if (this.myDepartment.products) {//产品
      this.model.products = this.myDepartment.products;
    }
    if (this.myDepartment.courses) {//发展历程
      this.model.courses = this.myDepartment.courses;
    }
    if (this.myDepartment.image_urls) {//展示图片
      this.model.image_urls = this.myDepartment.image_urls;
    }
    if (this.myDepartment.video_link) {//视频链接
      this.model.video_link = this.myDepartment.video_link;
    }
    if (this.myDepartment.video_cover_link) {//视频链接
      this.model.video_cover_link = this.myDepartment.video_cover_link;
    }

    //给当前页面使用到的值赋值,一面对象属性互相影响
    if (this.myDepartment.industry) {//所属行业
      this.model.industry = this.myDepartment.industry;
    }
    if (this.myDepartment.employer_type) {//企业性质
      this.model.employer_type = this.myDepartment.employer_type;
    }
    // if(this.myDepartment.region){//所在地区
    //   this.model.region = this.myDepartment.region;
    // }

    if (this.myDepartment.scale) {//公司规模(员工人数)
      this.model.scale = this.myDepartment.scale;
    }
    if (this.myDepartment.intro) {//企业简介
      this.model.intro = this.myDepartment.intro;
    }
    if (this.myDepartment.website) {//企业网址
      this.model.website = this.myDepartment.website;
    }
    if (this.myDepartment.labels) {//企业标签
      this.model.labels = this.myDepartment.labels;
    }
    if (this.myDepartment.strengths) {//公司实力
      this.model.strengths = this.myDepartment.strengths;
    }
    if (this.myDepartment.is_quoted_company) {//是否上市
      this.model.is_quoted_company = this.myDepartment.is_quoted_company;
      this.is_quoted_company = this.myDepartment.is_quoted_company?'01':'02';
    }
    if (this.myDepartment.stock_code) {//股票代码
      this.model.stock_code = this.myDepartment.stock_code;
    }
    if (this.myDepartment.official_account) {//微信公众号
      this.model.official_account = this.myDepartment.official_account;
    }
    if (this.myDepartment.employer_category) {
      this.model.employer_category = this.myDepartment.employer_category;
    }

    //给公司地址默认赋值
    if (this.myDepartment.locations) {
      if (this.myDepartment.locations.length > 0) {
        let is_default = false;
        this.myDepartment.locations.forEach((val) => {
          if (val.is_default) {
            is_default = true;
            this.workPlace = val.lid;
          }
        });
        if (!is_default) {
          this.workPlace = this.myDepartment.locations[0].lid;
        }
      }
    }

  }

  serveVideo() {
    //保存企业视频.视频封面
    let videoData = {
      video_link: this.myDepartment.video_link,
      video_cover_link: this.myDepartment.video_cover_link
    }
    this.dataApi.upVideo(videoData).then((res: any) => {
      this.toastr.success("操作成功");
    }).catch((error) => {
      setTimeout(() => {
        this.toastr.error(error);
      }, 600);
    });
  }

  //保存、更新企业信息
  updateDepartment() {


    let filter = /^[0-9]+(.[0-9]{1,9})?$/;

    //必填项验证
    if (!this.model.employer_type) {
      this.toastr.warning("请填写公司性质");
      return;
    }
    if (!this.model.register_capital) {
      this.toastr.warning("请填写注册资金");
      return;
    } else if (!filter.test(this.model.register_capital)) {
      this.toastr.error("注册资金只能填写数字或两位小数");
      return;
    }
    if (!this.workPlace) {
      this.toastr.warning("请填写公司地址");
      return;
    }
    if (!this.model.establish_year) {
      this.toastr.warning("请填写成立年份");
      return;
    }
    if (this.model.establish_year) {
      if (!this.regexManager.isEstablish_year(this.model.establish_year)) {
        this.toastr.warning("公司成立年份格式错误");
        this.model.establish_year = "";
        return;
      }
    }
    if (!this.model.scale) {
      this.toastr.warning("请选择员工人数");
      return;
    }
    if (!this.model.intro) {
      this.toastr.warning("请填写公司简介");
      return;
    } else {
      if (this.model.intro.length < 50) {
        this.toastr.warning('公司简介不得少于50字');
        return;
      }
    }
    if (this.model.is_quoted_company && !this.model.stock_code) {
      this.toastr.warning("请填写公司股票代码");
      return;
    }
    this.model.register_capital_unit = this.register_capital_type;
    if (this.register_capital_type == '亿元') {
      this.model.register_capital = parseFloat(this.model.register_capital) * 10000 + "";
      this.register_capital_type = "万元"
    }
    else {

    }
    //console.log(this.model);
    //-------更新企业展示图片
    if (this.model.image_urls && this.model.image_urls.length > 0) {

    }
    if (this.model.image_urls == null) {
      this.model.image_urls = []
    }
    if (this.myDepartment.video_link) {//视频链接
      this.model.video_link = this.myDepartment.video_link;
    }
    if (this.myDepartment.video_cover_link) {//视频链接
      this.model.video_cover_link = this.myDepartment.video_cover_link;
    }
    console.log(this.model, 'this.model');
    this.dataApi.uploadDepartmentShow(this.model.image_urls).then((res: any) => {
      this.myDepartment.image_urls = this.model.image_urls;//企业展示
    }).catch((error) => {
      setTimeout(() => {
        this.toastr.error(error);
      }, 600);
    });

    //保存企业视频.视频封面
    if(this.myDepartment.video_link || this.myDepartment.video_cover_link){
      let videoData = {
        video_link: this.myDepartment.video_link,
        video_cover_link: this.myDepartment.video_cover_link
      }
      this.dataApi.upVideo(videoData).then((res: any) => {
  
      }).catch((error) => {
        setTimeout(() => {
          this.toastr.error(error);
        }, 600);
      });
    }
    
    //更新、保存企业信息
    this.dataApi.updateDepartment(this.model).then((res: any) => {

      //-----更新企业信息本地存储
      this.myDepartment.industry = this.model.industry;//所属行业
      this.myDepartment.employer_type = this.model.employer_type;//企业性质
      //  this.myDepartment.region = this.model.region;//所在地区
      this.myDepartment.scale = this.model.scale;//公司规模(员工人数)
      this.myDepartment.website = this.model.website;//企业网址
      this.myDepartment.intro = this.model.intro;//企业简介
      this.myDepartment.is_quoted_company = this.model.is_quoted_company;//是否上市
      this.myDepartment.stock_code = this.model.stock_code;//股票代码
      this.myDepartment.register_capital = this.model.register_capital;//注册资金
      this.myDepartment.establish_year = this.model.establish_year;//成立年份
      this.myDepartment.products = this.model.products;//产品
      this.myDepartment.courses = this.model.courses;//发展历程
      this.myDepartment.video_link = this.model.video_link;//企业视频
      this.myDepartment.video_cover_link = this.model.video_cover_link;//企业视频
      this.myDepartment.official_account = this.model.official_account;//微信公众号
      //更新企业信息本地存储字段
      //公司规模
      if (this.myDepartment.scale) {
        for (let n of this.config.scale) {
          if (n.value == this.myDepartment.scale) {
            this.myDepartment.scale_text = n.text;
          }
        }
      }
      //公司性质
      if (this.myDepartment.employer_type) {
        for (let n of this.config.employer_type) {
          if (n.value == this.myDepartment.employer_type) {
            this.myDepartment.employer_type_text = n.text;
          }
        }
      }
      //所属行业
      if (this.myDepartment.industry) {
        for (let n of this.config.industry) {
          if (n.code == this.myDepartment.industry) {
            this.myDepartment.industry_text = n.text;
          }
        }
      }
      //判断是否填写了成立年份-----------当前页面展示
      if (this.model.establish_year) {
        this.model.establish_year = String(this.model.establish_year);
      } else {
        this.model.establish_year = null;
      }
      this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
      setTimeout(() => {
        this.toastr.success("保存成功");
        // this.navCtrl.pop();
      }, 600);


    }).catch((error) => {
      //console.log(error);
      setTimeout(() => {
        this.toastr.error(error);
      }, 600);
    });
  }

  //获取企业信息
  getDepartment() {
    this.dataApi.getEmployment().then((res: any) => {
      if (res) {
        if (res.locations == null) {
          res.locations = []
        }
        this.dataApi.setLocalDepartment(res);
        this.initDeparment(res);//初始化页面数据
      }
    }).catch((error) => {
      //console.log(error);
      this.toastr.error(error);
    });
  }

  //选择修改名称的类型
  seleteUpdateNameType() {

    if (!this.getUpdateStateModel) {
      return;
    }
    if (this.getUpdateStateModel.name_status == 1) {
      this.toastr.warning('您的申请正在审核中，请耐心等待')
      return;
    }


    this.ShowUpdateModel2 = true;
    this.updateComModel.change_letter_url = '',
      this.updateComModel.department_name = '',
      this.updateComModel.register_number = '',
      this.updateComModel.business_licence_url = '',//营业执照
      this.updateComModel.common_name = '',//企业简称
      this.updateComModel.business_licence_extension = '',//营业执照扩展名
      this.updateComModel.business_licence_filename = '',//营业执照文件名称
      this.updateComModel.change_letter_extension = '',// 扩展名 ,
      this.updateComModel.change_letter_filename = '',//文件名 ,
      this.updateComModel.remark = ''
  }
  seleteUpdateShortNameType() {
    if (!this.getUpdateStateModel) {
      return;
    }
    if (this.getUpdateStateModel.common_name_status == 1) {
      this.toastr.warning('您的申请正在审核中，请耐心等待')
      return;
    }
    this.ShowUpdateModel3 = true;
  }
  /**
   * 关闭更新企业弹窗按钮
   */
  cloceShowUpdate() {
    this.ShowUpdateModel2 = false;
    this.ShowUpdateModel3 = false;
    this.updateComModel.change_letter_url = '',
      this.updateComModel.department_name = '',
      this.updateComModel.register_number = '',
      this.updateComModel.business_licence_url = '',//营业执照
      this.updateComModel.common_name = '',//企业简称
      this.updateComModel.business_licence_extension = '',//营业执照扩展名
      this.updateComModel.business_licence_filename = '',//营业执照文件名称
      this.updateComModel.change_letter_extension = '',// 扩展名 ,
      this.updateComModel.change_letter_filename = ''//文件名 ,
    this.updateComModel.remark = ''
  }

  /**
   * 更新按钮
   * @param type
   */
  update(type) {
    console.log("update", this.updateComModel);
    if (this.updateComModel.department_name == '') {
      this.toastr.error('请填写公司名');
      return;
    }
    if (this.updateComModel.register_number == '') {
      this.toastr.error('请填写企业统一社会信用代码');
      return;
    }
    if (this.updateComModel.business_licence_url == '') {
      this.toastr.error('请上传营业执照');
      return;
    }
    if (this.updateComModel.change_letter_url == '') {
      this.toastr.error('请上传资质变更授权书');
      return;
    }
    if (this.updateComModel.remark == '') {
      this.toastr.error('请填写备注');
      return;
    }

    this.dataApi.updateDepartmentNameAndPic(this.updateComModel).then((res: any) => {
      console.log("updateDepartmentNameAndPic", res);
      this.ShowUpdateModel2 = false;
      this.toastr.success("修改成功，请等待审核")
      this.getCompanyStatus();
      this.getUpdateState();
    }).catch((err) => {
      console.log("updateDepartmentNameAndPicerr", err);
      this.toastr.error(err);
    })

  }

  getCompanyStatus() {
    let accountid = localStorage.getItem("accountid");
    if (accountid) {
      this.dataApi.getCompanyStatus(accountid).then((res: any) => {
        console.log("getCompanyStatus", res);
        //0', '1', '2', '3', '4']
        if (res) {
          this.CompanyStatus = res.status;
        }
      }).catch((err) => {
        console.log("getCompanyStatus", err);
      })
    }
  }
  register_capitalChange(e) {
    console.log('e', e, this.model.register_capital)
    if (this.register_capital_type == "亿元") {
      if (parseInt(e) > 10000) {
        setTimeout(() => {
          this.model.register_capital = "10000";
          this.register_capital_type = "亿元"
        }, 200)
      }
      return;
    }
    if (e >= 10000) {
      console.log('e', this.model.register_capital)
      setTimeout(() => {
        this.model.register_capital = (parseFloat(e) / 10000) + "";
        this.register_capital_type = "亿元"
      }, 200)
    }
    else {
      this.model.register_capital = (parseInt(e) * 10000) + "";
      this.register_capital_type = "万元"
    }
  }

  establish_yearChange(e) {
    this.regexManager.isEstablish_year(this.model.establish_year)
  }
  deleteShortImage(index) {
    let list = [];
    this.updateShortComModel.imageList.map((item, i) => {
      if (index != i) {
        list.push(item);
      }
    })
    this.updateShortComModel.imageList = list;
  }
  updateshortName() {
    console.log('his.updateShortComModel', this.updateShortComModel);
    if (!this.updateShortComModel.department_name) {
      this.toastr.warning('请输入公司简称')
      return;
    }
    if (this.updateShortComModel.department_name.length > 10) {
      this.toastr.warning('简称名称过长')
    }
    if (this.updateShortComModel.imageList.length == 0) {
      this.toastr.warning('请上传清晰的证明材料图片')

      return;
    }
    if (!this.updateShortComModel.remark) {
      this.toastr.warning('请输入修改原因')
      return;
    }
    let data = {
      "common_name": this.updateShortComModel.department_name,
      "remark": this.updateShortComModel.remark,
      "materials": this.updateShortComModel.imageList
    }

    //updateDepartmentShortName
    this.dataApi.updateDepartmentShortName(data).then((res: any) => {
      this.toastr.success('申请修改成功，请等待审核')
      this.ShowUpdateModel3 = false;
      this.getUpdateState();
    }).catch((err) => {
      this.toastr.error(err);
      console.log("getCompanyStatus", err);
    })

  }
  getUpdateState() {
    //getCompanyUpdateStatus
    this.dataApi.getCompanyUpdateStatus().then((res: any) => {
      console.log('getUpdateState', res)
      // common_name: "呀呀呀呀呀"
      // common_name_status: 1
      // name: null
      // name_status: 0
      this.getUpdateStateModel = res;
    }).catch((err) => {
      console.log("getCompanyStatus", err);
    })
  }
  xieyiClick(index) {
    if (index == 1) {
      window.open("http://images.goworkla.cn/emailpage/payProtocol/index.html", '_blank');
    }
    else {
      this.dialog.open(UserProtocolModelComponent, {
        data: {
          callback: (index) => {
            this.dialog.closeAll();
          }
        }
      }).afterClosed().subscribe((isConfirmed) => {
      });

    }

  }

  productModelHidden(event){
    this.isproductShowModel = false;
  }

  //点击产品
   product(alias,type){
    this.productModel.initData(alias,type);
    this.isproductShowModel = true;
  }

  //去账号管理
  goAccount(){
    this.router.navigateByUrl('/home/company-account');
  }

  listChange(){
    this.model.is_quoted_company = this.is_quoted_company == '01'?true:false;
  }

  // 下载小程序码
  downloadQrcode() {
    let image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function () {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      let a = document.createElement("a"); // 生成一个a元素
      let event = new MouseEvent("click"); // 创建一个单击事件
      a.download = "Qrcode"; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = this.myDepartment.miniprogram_qrcode;
  }
}
