import { Component, OnInit } from '@angular/core';
import { DataApi, Local, Constants, Opera, Config, FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { regexManager } from "../../provider/regexManager";
import { Router } from '@angular/router';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent implements OnInit {
  public auth_model = {
    operator_name: '',//姓名
    id_number: '',//身份证号码
    id_card: '',// 身份证正面
    id_card_back: '', // 身份证反面
    proxy_change_document: '',
    proxy_document: '',
  };
  public lastData = {};
  public proxy_document_example_url:string = '';
  public proxy_change_document_example_url:string = '';
  public remark = '';
  public status = '';
  public config: Config;
  constructor(public dataApi: DataApi,
    public local: Local,
    public toastr: ToastrService,
    public opera: Opera,
    public formatDate: FormatDate,
    public regexManager: regexManager,
    private router: Router,
  ) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.newauthentication();
  }

  // 获取最后一次修改记录
  newauthentication() {
    this.dataApi.newauthentication().then((res: any) => {
      if (res) {
        this.proxy_document_example_url = res.proxy_document_example_url;
        this.proxy_change_document_example_url = res.proxy_change_document_example_url;
        this.remark = res.remark;
        this.status = res.status;
        this.auth_model = {
          operator_name: res.operator_name,//姓名
          id_number: res.id_number,//身份证号码
          id_card: res.id_card,// 身份证正面
          id_card_back: res.id_card_back, // 身份证反面
          proxy_change_document: res.proxy_change_document,
          proxy_document: res.proxy_document,
        }
      }
    })
  }

  // 下载文件
  downfile(link){
    window.open(link,'_blank')
  }

  // 上传图片并赋值
  previewDepartmentPic(event, text) {
    let imgData = event.target.files[0]; //获取file对象
    if (parseInt(imgData.size) / 1024 > 1024 * 5) {
      this.toastr.warning('该图片大小超出了5M，请重新上传');
      return;
    }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e: any) {
        that.uploadDepImg(reader.result, text);
      };

    }
  }

  // 上传图片
  uploadDepImg(data, text) {
    let imageData = this.dataApi.formatImage(data);
    this.opera.loading();
    this.dataApi.uploadAuthImg({ value: imageData }).then((res: any) => {
      this.opera.loadiss();
      this.auth_model[text] = res.value;
    }).catch((error) => {
      this.opera.loadiss();
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    })
  }

  // 提交修改
  commitAuthClick() { 
    if (!this.auth_model.operator_name) {
      this.toastr.warning('请输入姓名');
      return;
    }
    if (!this.auth_model.id_number) {
      this.toastr.warning('请输入身份证号码');
      return;
    }
    if (!this.regexManager.isIdCard(this.auth_model.id_number)) {
      this.toastr.warning("身份证号码格式错误");
      return;
    }
    if (!this.auth_model.id_card) {
      this.toastr.warning('请上传手持身份证');
      return;
    }
    if (!this.auth_model.proxy_change_document) {
      this.toastr.warning('请上传招聘委托变更书图片');
      return;
    }
    // if (!this.auth_model.proxy_document) {
    //   this.toastr.warning('请上传招聘委托书图片');
    //   return;
    // }
    this.dataApi.newPutauthentication(this.auth_model).then((res: any) => {
      if (res) {
        this.toastr.success('您的修改信息已提交，请耐心等待');
        this.router.navigate(['home/workSpase']);
      }
    })
  }

  gotoMain() {
    this.router.navigate(['home/workSpase']);
  }
}
