<div class="modal-dialog">
  <div class="modal-content">
    <div class="cloudBox">
      <div class="cloudB">
        <button class="cloudcha" [mat-dialog-close]="false">
          <img style="width: 20px;" src="assets/images/closeX.png" alt="">
        </button>
        <button class="clouddui" [mat-dialog-close]="true">
          <img   width="400px" src="assets/images/cloud2.png" alt="">
        </button>
      </div>
    </div>
  </div>
</div>
