import { Component, OnInit } from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {productManager} from "../../provider/productManager";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-air-live-explain',
  templateUrl: './air-live-explain.component.html',
  styleUrls: ['./air-live-explain.component.css']
})
export class AirLiveExplainComponent implements OnInit {

  public config: Config;
  public isShowConnetSuccess = false

  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public productManager:productManager,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.config = Config.getInstance();//实例化config

    this.opera.initConfig('aircampus','airCampList');
  }
  start(){
    this.isShowConnetSuccess = true;
    let urmPayStatistic = {
      departmentid:this.config.department.accountid,
      category:"active_live_campustalk"
    }
    this.dataApi.urmPayStatistic(urmPayStatistic).then((res:any)=>{
      console.log('urmPayStatistic',res)
    }).catch((error) => {
    });
  }
  copyText(text){
    if(!text){
      return
    }
    console.log('copy',text)
    var Url2=text;
    var oInput = document.createElement('input');
    oInput.setAttribute('id', 'cp_hgz_input');
    oInput.value = Url2;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display='none';
    this.toastr.success('复制成功')
    document.getElementById('cp_hgz_input').remove();
  }
  dismissConnet(){
      this.isShowConnetSuccess = false
  }


}
