import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { DataApi,Config,Local,Constants,Opera} from '../../provider/index';
declare let Swiper:any;

@Component({
  selector: 'app-member-product-detail',
  templateUrl: './member-product-detail.component.html',
  styleUrls: ['./member-product-detail.component.css']
})
export class MemberProductDetailComponent implements OnInit {
  public config: Config;
  mySwiper;
  public productList = [];
  public productType = ''
  public productInfo;
  public myDepartment;
  public member_info;
  public price; // 金额
  public discount; // 优惠金额
  public data = { // 单品数据
    title:'活动通知包',
    proText:'宣讲会双选会短信邀约，学生到场率翻倍',
    proimg:'assets/images/productImg.png'
  };
  public POSTrecharge_type='';
  constructor(
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService,
    public dataApi: DataApi,
    private local: Local,
  ) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {

    //从极速短信联系穿不过来类型，只能依靠undefined代替极速短信联系
    if(this.config.recharge_type==undefined) {
      this.config.recharge_type=2;
      this.config.recharge_category = 'sms'

      this.config.recharge_category  = this.local.get("recharge_category");
      this.config.recharge_type =parseInt(this.local.get("recharge_type")) ;
      this.config.recharge_category =  this.config.recharge_category.replace("\"","");
      this.config.recharge_category =  this.config.recharge_category.replace("\"","");
      console.log("刷新了页面", this.config.recharge_category,this.config.recharge_type)

    }

    this.recharge_type();
    this.departmentList();
    this.getDepartment();

    this.router.navigate(['home/tool-campus']);

  }

  // 单品包类型
  recharge_type(){
    if(this.config.recharge_type && this.config.recharge_category && this.config.recharge_category == 'sms' && this.config.recharge_type == 1){
      this.data = {
        title:'活动通知包',
        proText:'宣讲会双选会短信邀约，学生到场率翻倍',
        proimg:this.config.is618?'assets/images/productImg618.png':'assets/images/productImg.png'
      }
      this.POSTrecharge_type = 'sms_activity';
    }else if(this.config.recharge_type && this.config.recharge_category && this.config.recharge_category == 'sms' && this.config.recharge_type == 2){
      this.data = {
        title:'极速短信联系',
        proText:'短信及时提醒，极速联系到想招的学生！',
        proimg:this.config.is618?'assets/images/productImg1618.png':'assets/images/productImg1.png'
      }
      this.POSTrecharge_type = 'sms_quick';

    }
    else if(this.config.recharge_type && this.config.recharge_category && this.config.recharge_category == 'sms' && this.config.recharge_type == 3){
      this.data = {
        title:'批量邀投包',
        proText:'短信及时提醒，极速联系到想招的学生！',
        proimg:'assets/images/productImg2.png'
      }
      this.POSTrecharge_type = 'sms_bulkinvitation';

    }
    else if(this.config.recharge_type && this.config.recharge_category && this.config.recharge_category == 'phone' && this.config.recharge_type == 4){
      this.data = {
        title:'极速电话联系',
        proText:'极速电话联系，虚拟号码！',
        proimg:'assets/images/productImg3.png'
      }

      // this.POSTrecharge_type = 'speedtelephone';
      this.POSTrecharge_type = 'voice_call';


    }
　

    //老版短信
  }


  //获取企业信息
  getDepartment(){
    this.dataApi.getDepartment().then((res:any)=>{
      console.log("企业信息获取线上数据",res);
      this.dataApi.setLocalDepartment(res);
      this.myDepartment = res;
      this.member_info = res.member_info;
    }).catch((error) => {
      console.log(error);
      this.toastr.error(error);
    });
  }

  // 去会员列表
  goMemberList(){
    this.router.navigateByUrl('home/tool-campus');
  }

  // 初始化swiper
  mySwiperInit(){
    this.mySwiper = new Swiper('.swiper-container', {
      slidesPerView: 6,
      spaceBetween: 30,
      freeModeMomentumVelocityRatio : .3,
      freeMode: true,
      grabCursor: true,
      slidesOffsetBefore : 0,

      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true,
      // },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  // 获取产品列表信息
  departmentList(){



    this.dataApi.departmentList({product_category:"recharge",recharge_category:this.POSTrecharge_type}).then((res:any)=>{
      if(res){
        this.productList = res.list;
        console.log('productList',this.productList);

        if(this.productList.length==0)
        {
          return;
        }
        this.productInfo = res.list[0];
        if(this.member_info||this.config.department.member_info){

          let  member_info = null;
          if(this.member_info)
          {
            member_info = this.member_info;
          }
          else
          {
            member_info = this.config.department.member_info;
          }
          // console.log('个人会员状态11',member_info,this.config.department.member_info)

          if(member_info!=null)
          {
            this.price = parseFloat(res.list[0].price) * parseFloat(res.list[0].discount)* parseFloat(res.list[0].membership_discount);
            this.discount = parseFloat(res.list[0].price) - this.price;
          }
          else
          {
            //无会员
            this.price = parseFloat(res.list[0].price) * parseFloat(res.list[0].discount);
            this.discount = parseFloat(res.list[0].price) - this.price;
          }

          // this.price = parseFloat(res.list[0].price) * parseFloat(res.list[0].discount) * parseFloat(res.list[0].membership_discount);
          // this.discount = parseFloat(res.list[0].price) - this.price;
        }else{
          console.log('个人会员状态222',this.productInfo,this.config.department)

          this.price = parseFloat(res.list[0].price) * parseFloat(res.list[0].discount);
          this.discount = parseFloat(res.list[0].price) - this.price;
        }
        setTimeout(() => {
          this.mySwiperInit();
        }, 100);
      }
    }).catch((err)=>{
      console.log(err);
    })
  }

  // 切换产品
  productTypes(item,index){
    this.productInfo = item;
    if(this.member_info ||this.config.department.member_info){

      let  member_info = null;
      if(this.member_info)
      {
        member_info = this.member_info;
      }
      else
      {
        member_info = this.config.department.member_info;
      }
      if(member_info!=null)
      {
        this.price = parseFloat(item.price) * parseFloat(item.discount) * parseFloat(item.membership_discount);
        this.discount = parseFloat(item.price) - this.price;
      }
      else
      {
        //无会员
        this.price = parseFloat(item.price) * parseFloat(item.discount);
        this.discount = parseFloat(item.price) - this.price;
      }

    }else{
      this.price = parseFloat(item.price) * parseFloat(item.discount);
      this.discount = parseFloat(item.price) - this.price;
    }
    console.log(this.productInfo);
    this.productType = index;
  }


  // 跳转支付页面
  goSearch(){
    this.config.productCode = this.productInfo['code'];
    this.local.set(Constants.productInfo, JSON.stringify(this.productInfo['code']));
    console.log(this.local.get(Constants.productInfo));
    this.router.navigate(["home/company-pay"]);
  }
}
