<!-- 页头部分 -->
<!-- <div class="contentRight-header">企业中心 - 企业认证</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <div class="college-block">
      <img class="goBack" *ngIf="config?.department?.quota?.product_component_info?.length < 1" (click)="opera.goNewRouter('member-center')" src='assets/images/router-old.png'/>
      <img class="goBack" *ngIf="config?.department?.quota?.product_component_info?.length > 0" (click)="opera.goNewRouter('company-status-two')" src='assets/images/router-old.png'/>
      <div class="productInfo">
        <div class="proImg">
          <img [src]="data.proimg" alt="">
        </div>
        <div class="proMain">
          <div class="proName">
            <h4>{{data.title}}</h4>
            <p>{{data.proText}}</p>
          </div>
          <div class="membeRemind flex_row_left">
            <span>选择需要通知的学生人数</span>
            <em>（会员在享受以下折扣同时再享8折）</em>
            <button class="purple" (click)="goMemberList()" *ngIf="!member_info">开通会员</button>
          </div>
          <div class="discount" >
            <div class="swiper-container">
              <div class="swiper-wrapper disList">
                <div [class.cur]="productType == i" class="swiper-slide flex_col_center" *ngFor="let item of productList;let i = index" (click)="productTypes(item,i)">
                  <img src={{item.ico}} style="width: 120px;height: 130px;position: absolute;z-index:0">

                  <sup *ngIf="productType == i">
                    <img src="assets/images/mesure.png" alt="">
                  </sup>

                  <div *ngIf="item.discount < 1" class="ded">

                    <img src="assets/images/meco.png" alt="">
                    <span>{{item.discount*10}}</span>
                    <em>折</em>
                  </div>
                  <!-- <span>200</span> -->
                  <!-- <span>个学生</span> -->
                  <span style="margin-top: -15px;text-align: center;position: absolute;height: 18px">{{item.name}}</span>
                  <span style="margin-top: 45px;text-align: center;position: absolute;height: 18px">{{item.product_infos[0].count}}{{item.product_infos[0].unit}}</span>

                </div>
              </div>
              <div *ngIf="productList.length > 5" class="swiper-button-prev"></div><!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
              <div *ngIf="productList.length > 5" class="swiper-button-next"></div><!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
            </div>
          </div>
          <!-- <div class="producTips">
            <p>将为你随机抽取200个学生，当前可通知学生为<span>156</span>人，购买之后剩余的可下次使用</p>
          </div> -->
          <div class="productBuyInfo flex_row_between" *ngIf="productList.length>0">
            <span>￥{{price | number:'1.2-4'}}<em *ngIf="discount">/已省{{discount | number:'1.2-4'}}元</em></span>
            <button class="purple" (click)="goSearch()">购买</button>
          </div>
          <p class="flex_row_right buyTips"><span>如需购买更多，请联系<em style="text-decoration: underline;cursor: pointer;" (click)="opera.goChats('JuniorSecretaryD')">在线客服</em>或<em>400-661-1396</em></span></p>
        </div>
      </div>
  </div>
</div>
