import {Component, OnInit, Output} from '@angular/core';
import {DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-advert-list-page',
  templateUrl: './advert-list-page.component.html',
  styleUrls: ['./advert-list-page.component.css']
})
export class AdvertListPageComponent implements OnInit {

  public currentAlias = '';
  public ArrList = [];
  public countList = 0;//列表数量
  public productList = []
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 1;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService
  ) { }

  ngOnInit() {
    this.opera.initConfig('tool','tool-my');
    this.getcomponentLeft()
  }
  getcomponentLeft(){
    this.opera.loading()
    this.dataApi.getcomponentLeft({}).then((res:any)=>{
      this.opera.loadiss();
      //productList
      let arr = [];
      res.map((item)=>{
        if(this.opera.isAddvert(item.alias)) {
          item.old =item.total - item.left
          console.log('isAddvert',this.opera.isAddvert(item.alias))
          if(this.opera.isAddvert(item.alias)){
            item.old = ((item.total - item.left)/7)+'周'
            item.left = (item.left/7)+'周'
          }
        arr.push(item);
        }
      })
      this.productList = arr;
    }).catch((err)=>{
      this.opera.loadiss();
    })
  }
  getList(){
    let str = this.local.get("mySingleProductList");
    if (str) {
      let json = JSON.parse(str)
      this.currentAlias = json.alias;
      this.dataApi.getcomponentList({ skip:(this.pageNumber-1)*10,
        limit:'10',alias:this.currentAlias}).then((res:any)=>{
        let arr = [];
        this.countList = res.count;
        this.pageCount = Math.ceil(this.countList/10)
        res.list.map((item)=>{
          item.ico = json.ico;
          item.startTimeStr = this.RiQi(Date.parse(item.begintime))
          item.endTimeStr = this.RiQi(Date.parse(item.endtime))
          item.old =item.total - item.left
          console.log('isAddvert',this.opera.isAddvert(item.alias))
          if(this.opera.isAddvert(item.alias)){
            item.old = (item.total - item.left)/7
            item.left = item.left/7
          }
          arr.push(item);
        })
        this.ArrList = arr;
      }).catch((err)=>{
        console.log(err);
      })
    }
  }
  //时间戳转时间
  RiQi(sj) {
    var now = new Date(sj);
    var   year=now.getFullYear();
    var   month=now.getMonth()+1;
    var   date=now.getDate();
    var   hour=now.getHours();
    var   minute=now.getMinutes();
    var   second=now.getSeconds();
    return   year+"/"+month+"/"+date;
  }
  //去详情
  gotoDetail(n){
    this.local.set('productUseHistory',n.alias)
    this.router.navigate(['home/productUseHistory']);
  }
  //获取分页子组件返回数据
  getChildEvent(index){
    if(index > 0){
      this.pageNumber = index;
      this.getList();
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.getList();
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.getList();
      }
    }
  }
}
