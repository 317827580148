import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { DataApi,Config,Local,Constants,Opera} from '../../provider/index';
@Component({
  selector: 'app-company-status-four',
  templateUrl: './company-status-four.component.html',
  styleUrls: ['./company-status-four.component.css']
})
export class CompanyStatusFourComponent implements OnInit {
  public config: Config;
  public power:boolean=true;
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    // this.opera.initConfig('member','company-status-one');
    this.opera.initConfig('member','member-center');
    // console.log(this.config.lastIdentity)
  }
  // 选项卡
powerTab(){
  this.power=true;
}
searchTab(){
  this.power=false;
}
// 重新认证
goAuthen(){
  this.router.navigateByUrl("home/company-authentication");//填写企业认证页面
}
// 跳转支付页面
goSearch(){
  this.router.navigate(["home/company-pay"],{
    queryParams:{
      searchMoney:298
    }
  });
}
}
