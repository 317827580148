import { Component, OnInit } from '@angular/core';
import {DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {productManager} from "../../provider/productManager";
declare let layui;
@Component({
  selector: 'app-air-camp-history',
  templateUrl: './air-camp-history.component.html',
  styleUrls: ['./air-camp-history.component.css']
})
export class AirCampHistoryComponent implements OnInit {
  public campList = [];
  public campCount = 0;
  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public productManager:productManager,
  ) { }
  ngOnInit() {
    this.opera.initConfig('aircampus','airCampHistory');
    this.getAircampustalkList(true,1)
  }

  /**
   * 获取空中双选会历史列表
   * @param isFirst 是否是第一次获取数据
   * @param page 分页
   */
  getAircampustalkList(isFirst,page) {
    let data = {
      offset:(page-1)*10,
      limit:10
    }
    this.dataApi.getAircampustalkList(data).then((res:any)=>{//通过tooken获取企业信息
      if(res) {
        this.campCount = res.count
        this.campList= res.list;
        for(let n of this.campList) {
          if(n.apply_time == '0001-01-01T00:00:00+08:00'){
            n.apply_time  =""
          }
          if(n.air_type == 1){
            n.video_cover = n.air_live_images[2].url
          }
          if(n.audit_status==0) {
            n.status = "未提交"
          } else if(n.audit_status==1) {
            n.status = "审核中"
          } else if(n.audit_status==2) {
            n.status = "已通过"
          } else if(n.audit_status==3) {
            n.status = "已拒绝"
          }
        }if(!isFirst) {
          return;
        }
        let that = this;
        layui.use('laypage', function(){
          var laypage = layui.laypage;
          laypage.render({
            elem: 'resumePage' //注意，这里的 test1 是 ID，不用加 # 号
            ,count: res.count, //数据总数，从服务端得到
            limit:12,
            theme: '#5e4fff',
            layout:['prev', 'page', 'next','skip','count']
            ,jump: function(obj, first){
              if(!first){
                that.getAircampustalkList(false,obj.curr)
              }
            }
          });
        });
      }
    }).catch((error)=>{
      this.toastr.error(error);
    });
  }
  gotoDetail(n){
    if(n.air_type == 1){
      this.local.set('airCampusType','liveCampusDetail')
    }else {
      this.local.remove('airCampusType')
    }
    this.router.navigateByUrl("home/airCampHistoryDetail?acid="+n.acid);
  }

  goairCampList(){
    this.router.navigate(['home/airCampList']);
  }
}
