import { Component, OnInit } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import {  ActivatedRoute,Router } from  '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-company-receipt-suc',
  templateUrl: './company-receipt-suc.component.html',
  styleUrls: ['./company-receipt-suc.component.css']
})

export class CompanyReceiptSucComponent implements OnInit {
  public invoiceid='';//发票id
  public invoice={
    paymentid:'',//支付记录id
    invoice_title_type:'personal',//发票抬头类型 enterprise_unit 企业单位 personal 个人
    invoice_title:'',//发票抬头
    register_no:'',//税务登记号
    bank_name:'',//基本开户银行名称
    bank_no:'',//基本开户账号
    register_address:'',//注册场所地址
    register_tel:'',//注册固定电话
    email:'',//发票接收邮箱
    add_time:'',//申请时间
    link:'',//电子发票链接
    amount:'',
    remark:'',//备注
  };//发票详情
  public email="";//邮箱

  constructor(
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.opera.initConfig('member','company-status-one');
    // this.getDepartment();
    this.invoice = JSON.parse(this.local.get('receiptInfo'));
    this.invoiceid = JSON.parse(this.local.get('receiptInfo')).invoiceid;

    this.getInvoiceDetails();//获取发票详情
    console.log(this.invoice);
    console.log(this.invoiceid);
  }

  //去地址列表
  goAddress():void{
    this.router.navigateByUrl("home/company-details-address");
  }


  //根据发票id获取发票详情
  getInvoiceDetails(){
    this.dataApi.getInvoiceDetails(this.invoiceid).then((res:any)=>{
      console.log("发票详情");
      console.log(res);
      this.invoice=res;
    }).catch((err)=>{
      console.log("获取发票详情出错");
      console.log(err);
      this.toastr.error(err);
    })
  }

  // 发送发票邮件
  sendByNewEmail(){
    if(!this.email){
      this.toastr.warning("请填写接收邮箱地址");
      return;
    }else{
      let phoneModel={
        format_type:2,//0:手机号，1：手机号&固话；2.邮箱
        data:this.email
      }
      this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
        if(!res.value){
          this.toastr.warning('工作邮箱格式错误');
        }else{
          this.dataApi.sendByNewEmail(this.invoiceid,this.email).then((res:any)=>{
            this.toastr.success("重新发送成功，请注意查收");
            this.email = '';
          }).catch((err)=>{
            console.log("重新发送失败");
            console.log(err);
            this.toastr.error(err);
          })
        }
      }).catch((err)=>{
        //console.log(err);
        this.toastr.warning('工作邮箱格式错误');
        return
      })
    }
  }
}
