<!-- 页头部分 -->
<!-- <div class="contentRight-header">企业中心 - 网络支付</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <router-outlet></router-outlet>
    <app-model-pay-wechat #payWechat [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-pay-wechat>
    <div class="college-block">
        <!-- 页头部分 -->
        <div class="college-block-head">网络支付</div>
        <!-- 商品介绍 -->
        <div class="college-block">
            <!-- 信息展示部分 -->
            <div class="college-list">
              <h3>确认支付</h3>
              <div class="orderType orderli flex_row_between">
                  <span>名称</span>
                  <span *ngIf="!productMain?.recharge_category">有效期</span>
                  <span>商品金额</span>
              </div>
              <div class="orderInfo orderli flex_row_between">
                <span>{{productMain?.name}}</span>
                <span *ngIf="!productMain?.recharge_category">1年</span>
                <em>￥{{price | number:'1.2-4'}}</em>
              </div>
              <div class="orderNum orderli flex_row_between">
                <span></span>
                <span></span>
                <span class="flex_row_center">
                    订单金额：<em>￥{{price | number:'1.2-4'}}</em>
                </span>
              </div>
              <h3>请选择支付方式</h3>
              <div *ngIf="payList" class="pay-list flex_row_around" >
                <div *ngFor="let pay of payList" class="payPath" (click)="model.channel = pay.alias">
                    <div class="payType flex_col_center {{pay.alias}}" [class.pathActive]="model.channel==pay.alias">
                        <img [src]="pay.ico">
                        <!-- <span>{{pay.name}}</span> -->
                        <span *ngIf="pay.alias == 'alipay_pc_direct'">支付宝</span>
                        <span *ngIf="pay.alias == 'wx_pub_qr'">微信</span>
                    </div>
                    <div class="payName flex_row_center" [class.pathActive]="model.channel==pay.alias">
                        <img *ngIf="model.channel == pay.alias" src="assets/images/payT.png" alt="">
                        <img *ngIf="model.channel != pay.alias" src="assets/images/payN.png" alt="">
                        <span>{{pay.name}}</span>
                    </div>
                </div>
              </div>
            </div>
            <!-- 确认付款 -->
            <div class="sign-submit">
                <button class="purple" (click)="pay()">确认付款</button>
            </div>
        </div>
    </div>
</div>