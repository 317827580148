<div class="content">
    <div class="secondNav">
        <div class="item " (click)="goairCampList()">空中宣讲会</div>
        <div class="item active">往期空中宣讲会<span></span></div>
    </div>
    <div class="title">空中宣讲会往期列表</div>
    <div class="airCell flex_l" *ngFor="let n of campList" (click)="gotoDetail(n)">
        <img class="cellImage" src="{{n.video_cover}}" />
        <div class="celltitle">{{n.title}}</div>
        <div class="celltime" *ngIf="n.apply_time">{{n.apply_time | date:'yyyy-MM-dd HH:mm'}} </div>
        <div class="cellState">已下线</div>
    </div>

    <!-- 空状态 -->
    <div class="none-block-empty" *ngIf="campCount == 0">
        <img src="assets/images/rights/empty.png">
        <p>无往期数据</p>
    </div>
    <div id="resumePage" *ngIf="campCount>0" style="float: right;margin-right: 0.5%"></div>
</div>