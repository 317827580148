import {Injectable} from '@angular/core';

/*
 * 对Storage进行二次封装，增加可维护性, 或方便添加我们自己的钩子代码
 */
@Injectable()
export class FormatDate {
    constructor() {
    }

    //减少小时数(返回JS日期格式)
    cutHours (date, hour) {
      let result = new Date(date);
      result.setHours(result.getHours() - hour);
      return result;
    }

    //追加天数(返回JS日期格式)
    addDays (date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    //追加月数(返回JS日期格式)
    addMonths (date, months) {
      let result = new Date(date);
      result.setMonth(result.getMonth() + months);
      return result;
    }

    //过滤 月/日 为(06日/09月)(前面加0)
    formatTen (num) {
      return num > 9 ? (num + "") : ("0" + num);
    }

    //格式化时间
    formatDate (date) {
      if ((date === '') || (date === undefined)) {
        return;
      }
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      return year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
    }
    formatDateAndTime (date) {
      let currentDate =  new Date(date)
      if (!currentDate) {
        return;
      }
      let year = currentDate.getFullYear();
      let month = currentDate.getMonth() + 1;
      let day = currentDate.getDate();

      let Hour = currentDate.getHours();
      let Minu = currentDate.getMinutes();

      let HourStr = (Hour+'')=='0'?"00":Hour+'';
      let MinuStr = (Minu+'')=='0'?"00":Minu+'';
      if(HourStr.length==1){
        HourStr = '0'+HourStr
      }
      if(MinuStr.length==1){
        MinuStr = '0'+MinuStr
      }
      return year + "-" + this.formatTen(month) + "-" + this.formatTen(day) +"   "+ HourStr+":" + MinuStr;
    }

}
