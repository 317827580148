<!--简历管理=收藏的-->

<div style="background-color: transparent;width: 1380px;min-width: 1380px" class="college-block" *ngIf="isShow">

    <!-- 头部 -->
    <div class="college-resume-head clear">
        <div class="right">
            <!-- 选择所在城市[(ngModel)]="" -->
            <span>筛选</span>
            <select name="major" id="major" [(ngModel)]="majorOne" (ngModelChange)="majorOneChange()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择一级专业</option>
                <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
            </select>
            <select name="majorTwo" id="majorTwo" [(ngModel)]="model.major" (ngModelChange)="getSendResumeList()" (click)="isShowTwo()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择二级专业</option>
                <option class="place-text" value='' selected="selected">不限</option>
                <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
            </select>
            <select name="education" id="education" [(ngModel)]="model.education" (ngModelChange)="getSendResumeList()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择学历</option>
                <option *ngFor="let a of educationList" [value]="a.code">{{a.text}}</option>
            </select>
            <!-- 搜索筛选 -->
            <div class="search-input right">
                <input type="text" placeholder="请输入学校名称" [(ngModel)]="model.keywords" (keyup)="inputKeyup($event)"><span (click)="goSearch(model.keywords)"><img src="assets/images/search.png"></span>
                <!-- 搜索关键词匹配列表 -->
                <div class="keyup-block" *ngIf="keywordList.length>0">
                    <div class="keyup-list" *ngFor="let key of keywordList" (click)="goSearch(key.text)">{{key.text}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 中间部分 -->
    <!-- 没有内容存在时 -->
    <div *ngIf="resumeList.length == 0" class="none-block">
        <img src="assets/images/resume-search-fail.png"><br /> 暂未找到相关简历

    </div>

    <div class="college-resume-cont">
        <div>

            <div class="listCell" *ngFor="let n of resumeList;let i = index;">
                <div class="listCellContent">

                    <div style="width: 45%;height: 100px;">

                        <div style="width: 100%;height: 65px;overflow:hidden;margin-left: 15px" (click)="goDetails(n,i)">

                            <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}" class="headIcon">
                            <img *ngIf="!n.logo&&(n.gender=='01')" class="headIcon" src="assets/images/male.png">
                            <img *ngIf="!n.logo&&(n.gender=='02')" class="headIcon" src="assets/images/female.png">
                            <img *ngIf="!n.logo&&!n.gender" class="headIcon" src="assets/images/male.png">

                            <span style="color: black;margin-left: 15px;line-height:25px;font-size: 17px;font-weight:bold;height: 25px;margin-top: 10px;margin-right: 15px;position: absolute">{{n.talent_name
                                | clearSpace}}
                                <span *ngIf="n.isview_resume" style="color: #999999;font-size: 11px">已查看</span>
                            <span class="new_telent" *ngIf="n.new_talent">新用户</span>
                            </span>
                            <div style="color: gray;margin-left: 76px;margin-top: -18px;margin-right: 15px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-size: 11px">
                                求职意向：{{n.yixiang}}</div>
                        </div>

                        <div style="width: 100%;margin: 10px 15px 10px;height: 20px;display: flex;flex-wrap: wrap;overflow: hidden">
                            <span *ngFor="let youshi of n.skills | slice:0:4;let i = index; " class="biaoqian">{{youshi.text}}</span>
                        </div>

                    </div>
                    <div style="width: 30%;height: 100px;background-color: white;display: flex;flex-direction: column;justify-content: center">
                        <div><img *ngIf='n.graduate_university' src="assets/images/resume_icon1.png" style="width:14px;height: 14px"><span style="font-size:13px;height:20px;margin-left: 5px">{{n.graduate_university}}</span>
                        </div>
                        <div style="margin: 5px 0"><img *ngIf='n.major_text' src="assets/images/resume_icon2.png" style="width:14px;height: 14px;"><span style="margin-left: 5px;font-size:13px;height:20px;">{{n.major_text}}</span></div>
                        <div class="jiebieBottom"><img *ngIf='n.education_text' src="assets/images/resume_icon3.png" style="width:14px;height: 14px;"><span style="margin-left: 5px;font-size:13px;height:20px;">{{n.education_text}}</span></div>
                        <div><img src="../../assets/images/jiebieIcon.png" style="width:14px;height: 14px;"><span style="margin-left: 5px;font-size:13px;height:20px;">{{n.year==0?"往届":n.year+'届'}}</span>
                        </div>


                    </div>
                    <div style="width: 25%;height: 100px;float: left; display: flex; flex-direction: row;align-items: center;justify-content:center;margin-right: 16px">
                        <div *ngIf='n.ignore==false' (click)="goDetails(n,i)" class="view-btn">查看简历</div>
                    </div>
                </div>
            </div>

            <!--           <ul class="resume-li" *ngFor="let n of resumeList;let i = index;">
               <li class="linkLogo" (click)="goDetails(n.resume_number)">
                  &lt;!&ndash; <img *ngIf="n.logo" [src]="n.logo+'&imageView2/1/w/100/h/100'"><img *ngIf="!n.logo" src="assets/images/none.png"> &ndash;&gt;
                  <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}">
                  <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png">
                  <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png">
                  <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png">
               </li>
               <li class="linkName" (click)="goDetails(n.resume_number)"><span *ngIf='n.talent_name'>{{n.talent_name}}</span><span *ngIf='!n.talent_name'>暂无</span></li>
               <li><span *ngIf='n.gender_text'>{{n.gender_text}}</span><span *ngIf='!n.gender_text'>暂无</span></li>
               <li><span *ngIf='n.age'>{{n.age}}</span><span *ngIf='!n.age'>暂无</span></li>
               <li><span *ngIf='n.graduate_university'>{{n.graduate_university}}</span><span *ngIf='!n.graduate_university'>暂无</span></li>
               <li><span *ngIf='n.major_text'>{{n.major_text}}</span><span *ngIf='!n.major_text'>暂无</span></li>
               <li><span *ngIf='n.education_text'>{{n.education_text}}</span><span *ngIf='!n.education_text'>暂无</span></li>
               <li>
                   <span (click)="goDetails(n.resume_number)">查看简历</span>
                   <span (click)="goCollect(n)">{{n.isfollow_reusme?'取消收藏':'收藏'}}</span>
                   &lt;!&ndash; <span>分享</span> &ndash;&gt;
               </li>
           </ul>-->
        </div>
    </div>
    <!-- 分页部分 -->
    <div class='paging-block' [class.isPageHeight]="countList>0">
        <router-outlet></router-outlet>
        <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
    </div>
</div>