import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {payModel} from "../model-pay-code/model-pay-code.component";

export interface Model {
  title:string;
  letBtnTitle:'';
  rightBtnTitle:'';
  callback:any;
}
@Component({
  selector: 'app-pay-chips-model',
  templateUrl: './pay-chips-model.component.html',
  styleUrls: ['./pay-chips-model.component.css']
})
export class PayChipsModelComponent implements OnInit {

  public callback;
  public letBtnTitle= "";
  public rightBtnTitle= "";
  public  title = "";
  constructor(

    @Inject(MAT_DIALOG_DATA) public data: Model
  ) {
    this.callback = data.callback;
    this.letBtnTitle = data.letBtnTitle;
    this.rightBtnTitle = data.rightBtnTitle;
    this.title = data.title;
  }

  ngOnInit() {
  }

  closeModelClick(index){
    this.callback(index);
  }
}
