import {Component, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {Constants, Local} from "../../provider";
import { MatDialog } from "@angular/material/dialog";
import {ConfirmComponent} from "../confirm/confirm.component";

@Component({
  selector: 'app-navigation-route',
  templateUrl: './navigation-route.component.html',
  styleUrls: ['./navigation-route.component.css']
})

 // MainRouteList = ['12','12312'];


export class NavigationRouteComponent implements OnInit {

  public RouteList = [];
  //返回是否显示提示弹窗
  @Input() backVirtyAlery = false;

  constructor(
    private router: Router,
    public local:Local,
    public dialog: MatDialog,

  ) {
    this.RouteList = JSON.parse(this.local.get('routeList')) ;

  }
  //刷新导航
  refNav(){
    let that = this;
    setTimeout(()=>{
      that.RouteList = JSON.parse(this.local.get('routeList')) ;
    },500);
  }

  ngOnInit() {
    this.RouteList = JSON.parse(this.local.get('routeList')) ;

  }


  routeClick(router,index)
  {
    if(this.backVirtyAlery)
    {
      this.dialog.open(ConfirmComponent, {
        data: {
          title: '提示',
          message: '返回上一步后，当前页面填写内容将不会保存',
          trueText:'继续返回',
          falseText:'再等等'
        }
      }).afterClosed().subscribe((isConfirmed) => {
        if(isConfirmed){
          let arr =  this.RouteList.slice(0, index);
          this.router.navigateByUrl(router);
        }
      });
      return;

    }
    let arr =  this.RouteList.slice(0, index);
    this.router.navigateByUrl(router);
  }
  routeTitle(Route) {
    
    let title = "";
    let RouteStr = "";

    let arr =  Route.split("?");
    if(arr.length>1)
    {
      RouteStr = arr[0];
    }
    else
    {
      RouteStr = Route;
    }
    switch (RouteStr) {
      case '/home/tool-campus':
        title = "校招工具";
        break;
      case '/home/tool-my':
        title = "我的工具";
        break;
      case '/home/payHistory':
        title = "购买记录";
        break;
      case '/home/buyProduct':
        title = "购买单品";
        break;
      case '/home/buyLaCoin':
        title = "啦豆充值";
        break;
      case '/home/buyProductExtend':
        title = "购买单品支付";
        break;
      case '/home/workSpase':
        title = "工作台";
        break;
      case '/home/myCard':
        title = "我的卡劵";
        break;
      case '/home/payInvoice':
        title = "电子发票";
        break;
      case '/home/mySingleProductList':
        title = "我的工具详细列表";
        break;
      case '/home/interview':
        title = "视频面试";
        break;
      case '/home/account-logout-l':
        title = "注销账号";
        break;
      case '/home/company-account':
        title = "账号管理";
        break;
      case '/home/college-milkround':
        title = "双选会";
        break;
      case '/home/college-milk-sign':
        title = "双选会报名";
        break;
      case '/home/college-milk-air-space':
        title = "会场";
        break;
      case '/home/accountLogoutHistory':
        title = "注销历史";
        break;
      case '/home/chatSingle':
        title = "聊天";
        break;
      case '/home/college-resumedetails':
        title = "简历详情";
        break;
      case '/home/college-resume':
        title = "推荐人才";
        break;
      case '/home/chat-collection':
        title = "对我感兴趣";
        break;
      case '/home/searchPeople':
        title = "搜索人才";
        break;
      case '/home/position-resume':
        title = "简历管理";
        break;
      case '/home/notification-center':
        title = "通知中心";
        break;
      case '/home/position-resume-like':
        title = "收藏的";
        break;
      case '/home/productUseHistory':
        title = "使用记录";
        break;
      case '/home/position-publish':
        title = "发布职位";
        break;
      case '/home/position-manage':
        title = "职位管理";
        break;
      case '/home/voiceCallRecord':
        title = "极速电话联系记录";
        break;
      case '/home/positionPushRecord':
        title = "职位推送记录";
        break;
      case '/home/activityInviteRecord':
        title = "校招活动邀请记录";
        break;
      case '/home/college-campustalk':
        title = "高校云";
        break;
      case '/home/college-campustalk1':
        title = "宣讲会报名";
        break;
      case '/home/college-details':
        title = "高校详情";
        break;
      case '/home/college-milkdetails':
        title = "双选会详情";
        break;
      case '/home/position-milk':
        title = "双选会报名记录";
        break;
      case '/home/activityInviteRecordDetail':
        title = "详情";
        break;
      case '/home/positionPushs':
        title = "批量职位推送";
        break;
      case '/home/activityInvitation':
        title = "校招活动邀请";
        break;
      case '/home/college-details-student':
        title = "生源速览";
        break;
      case '/home/zengzhi':
        title = "增值服务协议";
        break;
      case '/home/airCampusDetail':
        title = "空中宣讲会";
        break;
      case '/home/airCampHistory':
        title = "往期空中宣讲会";
        break;
      case '/home/airCampHistoryDetail':
        title = "空中宣讲会详情";
        break;
      case '/home/airCampHistoryReord':
        title = "空中宣讲会推广记录";
        break;
      case '/home/paymentorderlist':
        title = "我的订单";
        break;
      case '/home/advertList':
        title = "详细列表";
        break;
      case '/home/airCampList':
        title = "空中宣讲会";
        break;
      case '/home/milkdDay':
        title = "双选会每日汇总";
        break;
      case '/home/rights':
        title = "权益管理";
        break;
    }
    return title;
  }
  saveRoute(){

  }


}


