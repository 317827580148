<div class="select-checkbox-div" [ngClass]="{ 'selectOpen' : isSelectOpen }">
  <div class="select-checkbox-show clear-float" (click)="clickSelect()">
    <div class="show-data">{{ selectedName }}</div>
    <i class="fa-select"></i>
  </div>
  <div class="select-checkbox-content">
    <div class="select-checkbox-list">
      <div class="select-checkbox-item" *ngFor="let item of itemList;let i = index;">
        <!--<div>{{i}}</div>-->
        <div class="input-checkbox-div"><input disabled type="checkbox" [checked]="isCheck(item)"  /></div>
        <div class="item-name" (click)="checkItem($event, item)">{{ item.name }}</div>
      </div>
    </div>
  </div>
</div>
