<!-- 页头部分 -->
<!-- <div class="contentRight-header">企业中心 - 企业认证</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <router-outlet></router-outlet>
    <div class="college-block">

        <div class="guwenBox" *ngIf="config.departmentSelf">
          <div class="guwen_bg" *ngIf="config.departmentSelf">
            <div class="guwen_bg_gray">
              <img class="guwen_info_huizhang" src="../../assets/images/kefu_hui.png">
              <img class="guwen_head" src="{{config.departmentSelf.avatar}}">
              <div class="guwen_infoDiv">
                <ul>
                  <li><img style="width: 120px;height: 20px" src="../../assets/images/guwen_title.png"></li>
                  <li><span class="guwen_info_name">{{config.departmentSelf.name}}</span></li>
                  <li>
                    <img style="width: 17px;height: 17px" src="../../assets/images/kefu_wx.png">
                    <span class="guwen_info_title">微信：</span>
                    <span class="guwen_info_wx">{{config.departmentSelf.wx}}</span>
                  </li>
                  <li style="width: 260px">
                    <img style="width: 17px;height: 17px" src="../../assets/images/kefu_email.png">
                    <span class="guwen_info_title">邮箱：</span>
                    <span class="guwen_info_wx">{{config.departmentSelf.email}}</span>
                  </li>
                </ul>
              </div>
              <img class="guwen_head_code" style="margin-left: 5px;" src="{{config.departmentSelf.qr_code}}">
              <div class="guwen_info_Line"></div>
              <!--<span class="guwen_info_rightText">高校云认证相关问题，请扫码咨询！</span>-->
              <div class="guwen_info_rightText">
                <p >工作啦认证相关问题,</p>
                <p >请扫码咨询！<span>{{config.departmentSelf_ex}}</span></p>
              </div>


            </div>
          </div>
        </div>

        <div class="sucBox">
          <div>
            <img src="../assets/images/AuthSuc01.png" alt="">
          </div>
          <div>
            &nbsp;&nbsp;{{config.sucEndTime}}
          </div>
          <div class="AuthSuc02">
            <img src="../assets/images/AuthSuc02.png" alt="">
          </div>
          <div class="AuthSuc03">
            <img src="../assets/images/AuthSuc04.png" alt="">
          </div>
          <div>
            <img src="../assets/images/AuthSuc03.png" alt="">
          </div>
          <div>
              &nbsp;“工作啦”认证是用人单位在进入工作啦“工作啦”中的高校开展校园招聘之前，平台为了保障大学生求职安全而进行的免费“公益性”平台级审核认证。 用人单位在通过“工作啦”认证后，方可进入“工作啦”连接更多的高校。
          </div>
        </div>
    </div><!--college-block-->
</div><!--homepage-block-->
