import { Component, OnInit } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config } from '../../provider/index';
import {Router} from "@angular/router";

@Component({
  selector: 'app-college-authentication-wait',
  templateUrl: './college-authentication-wait.component.html',
  styleUrls: ['./college-authentication-wait.component.css']
})
export class CollegeAuthenticationWaitComponent implements OnInit {
  constructor(
    private router: Router,
    public opera:Opera,

  ) { }

  ngOnInit() {
    this.opera.initConfig('cloud','company-authentication');
    this.router.navigateByUrl("home/tool-campus");

  }
  //去工具页面
  gotoTool(){
    ///home/tool-campus
    this.router.navigateByUrl('home/tool-campus');

  }
}
