import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataApi, Opera } from "../../provider";
@Component({
  selector: 'app-model-still-more',
  templateUrl: './model-still-more.component.html',
  styleUrls: ['./model-still-more.component.css']
})
export class ModelStillMoreComponent {
  @Input() isShow: false;//是否显示弹窗页面
  public title: string;//弹出框标题
  public dataList = [];//总列表数据
  public initLength: number;//最多可以选择几个
  public initvalue = [];//初始选中值
  public firstLevel = [];//第一级列表
  public secendLevel = []; //二级列表数据
  //【必填】当前选中值
  public initValue = [];//改为数组
  public model = {
    name: '',
    level: 2
  }
  public seaList = [];
  public isShowRes = false;
  // //【非必填】是否为必填(默认为false)
  // public initRequired = false;
  // //【非必填】最度可选项(默认为4个)
  // public initLength = 4;
  // //【非必填】单位(默认为空)
  // public initUnit = ""; 
  //选中项的数组
  public initValueOneArray = [];//第一个字符串数组  
  public initValueTwoArray = [];//第二个字符串数组  
  public initValueTwoDataArray = [];//第二个对象数组  
  //当前选中项的对象
  public oneActive = { code: "", parentcode: "", text: "", level: '' };
  public twoActive = { code: "", parentcode: "", text: "", level: '' };
  constructor(public toastr: ToastrService,
    public dataApi: DataApi,
    public opera: Opera,) {

    // //console.log("执行初始化函数--------------1");
    // this.initData();
  }

  //初始化数据(初始值，列表数据，数量,标题)
  initData(data, data1, number, title) {
    this.isShowRes = false;
    this.model.name = '';
    this.initValue = data;
    this.dataList = data1;
    this.initLength = number;
    this.title = title;
    //console.log("执行init函数");
    //console.log(this.initValue);
    let parentData = [];//静态化父级数组
    //(先清空数组中的元素)
    this.initValueTwoDataArray = [];
    this.initValueOneArray = [];
    this.initValueTwoArray = [];
    if (this.initValue.length > 0) {//编辑的情况
      this.initValueTwoArray = this.initValue;//修改为直接给拿传过来的值给第二级数组赋值
      //遍历
      this.dataList.forEach((val) => {
        //循环父级放在第一个循环中,节约性能
        if (val.level == 1) {
          parentData.push(val);
        }
        //循环选中项的第二级
        if (this.initValueTwoArray.length > 0) {
          if (this.initValueTwoArray.indexOf(val.code) > -1) {
            //遍历出已选项(第二级)
            this.initValueTwoDataArray.push(val);
            //遍历出第一级选中数组
            if (this.initValueOneArray.indexOf(val.parentcode) < 0) {//防止重复(去重)
              this.initValueOneArray.push(val.parentcode);//给第二级字符串数组添加元素
            }
          }
        }

        //遍历出第二级的默认值
        if (this.initValueTwoDataArray.length > 0) {//如果失败则转到新建的情况(待完善)
          if (val.code == this.initValueTwoDataArray[0].code) {
            this.twoActive = val;//初始化赋值
          }
        }

      })

      //遍历
      this.dataList.forEach((val) => {
        // //遍历出第一级的默认值        
        if (val.code == this.twoActive.parentcode) {
          this.oneActive = val;
        }
      })
      //填充第一、二级数据(第三级默认不展示)
      this.firstLevel = parentData;
      this.getFirstLevel(this.oneActive);
      //this.getSecendLevel(this.twoActive);

    } else {
      //新建的情况      
      this.dataList.forEach((val) => {
        if (val.parentcode == '0') {
          parentData.push(val);
        }
      });

      //填充第一、二级数据
      this.firstLevel = parentData;
      this.getFirstLevel(this.firstLevel[0]);

    }
  }
  getSeaMajorLst() {
    if (this.model.name) {
      if (this.title == '选择技能标签') {
        this.opera.loading();//加载动画
        this.dataApi.getSearchListDic('skill', this.model).then((res: any) => {
          this.opera.loadiss();//停止加载动画

          if (res) {
            console.log(res);
            this.isShowRes = true;
            this.seaList = res;
          }
        }).catch((error) => {
          this.opera.loadiss();//停止加载动画
        });
      }else if(this.title == '选择专业'){
        //getmajor_lvel2
        this.opera.loading();//加载动画
        this.dataApi.getmajor_lvel2({text:this.model.name,limit:99}).then((res: any) => {
          this.opera.loadiss();//停止加载动画
          if (res) {
            console.log(res);
            this.isShowRes = true;
            this.seaList = res;
          }
        }).catch((error) => {
          this.opera.loadiss();//停止加载动画
        });
      }
    } else {
      this.isShowRes = false;
    }
  }
  //点击筛选第一级
  getFirstLevel(item) {
    // //console.log("点击选中第一级数据时-----model"); 
    // //console.log(item); 
    // //console.log(this.dataList);  
    //给选中行赋值
    this.oneActive = item;
    //遍历下一级数据
    this.secendLevel = [];
    if (item) {
      for (let val of this.dataList) {
        if (val.parentcode === item.code) {
          val.isShow = false;
          this.secendLevel.push(val);
        }
      }
    }
    // //console.log(this.secendLevel);    
  }

  //点击筛选第二级
  getSecendLevel(e, item) {
    e.stopPropagation();
    //给选中行赋值
    this.twoActive = item;
    //判断增加还是删除
    if (this.initValueTwoArray.indexOf(item.code) < 0) {
      //添加新选中项的情况
      if (this.initValueTwoArray.length >= this.initLength) {
        this.toastr.warning('最多选择' + this.initLength + '项');
      } else {
        //当前不是选中项并且可以赋值的情况
        //第二级数组(列表)
        this.initValueTwoArray.push(item.code);
        //第二级数组(顶部提示)
        this.initValueTwoDataArray.push(item);
        //第一级数组(不是根据当前选中值,而是根据已经点击过第一、第二级赋值)        
        if (this.initValueOneArray.indexOf(this.oneActive.code) < 0) {
          this.initValueOneArray.push(this.oneActive.code);
        }
      }

    } else {
      //删除已有选中项的情况
      //第二级选中数组(列表)
      this.initValueTwoArray.splice(this.initValueTwoArray.indexOf(item.code), 1);
      //第二级选中数组(顶部提示)
      this.initValueTwoDataArray.splice(this.initValueTwoDataArray.indexOf(item), 1);
      //遍历 第二级列表 有没有 第一级当前选中项
      let isOneDelete = true;
      this.initValueTwoDataArray.forEach((val) => {
        if (val.parentcode == this.oneActive.code) {
          isOneDelete = false;
        }
      });
      //第一级选中数组
      if (isOneDelete) {
        this.initValueOneArray.splice(this.initValueOneArray.indexOf(this.oneActive.code), 1);
      }
    }
    this.isShowRes = false;
  }

  @Output() onVoted = new EventEmitter();

  dismiss(result: any) {
    //console.log("传值检查");
    //console.log(result);
    this.onVoted.emit(result);
  }

}
