import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { DataApi, Local, Constants, Config, Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ModelCollegeMilksignComponent } from '../model-college-milksign/model-college-milksign.component';
import { NoneVipAlertComponent } from "../none-vip-alert/none-vip-alert.component";
import { MatDialog } from "@angular/material/dialog";
import * as moment from 'moment';

//新增高校
import { PushCollegeModelComponent } from "../push-college-model/push-college-model.component";
@Component({
  selector: 'app-position-milk',
  templateUrl: './position-milk.component.html',
  styleUrls: ['./position-milk.component.css']
})
export class PositionMilkComponent implements OnInit {
  //是否打开弹窗(非会员新增高校)
  @Output() isShowPushCollegeModel = false;
  @ViewChild('pushCollegeModel', { static: true })
  pushCollegeModel: PushCollegeModelComponent;

  // @ViewChild('milkSign') child: ModelCollegeMilksignComponent;
  public config: Config;
  public keywordList = [];//搜索关键词匹配列表
  public milkRoundList = [];//双选会列表
  public posterUrl = ''; // 双选会海报下载链接
  public showTips: boolean = true; // 进入弹窗

  public QrCodeURL = "";// 通过后可分享的二维码双选会信息

  public showMilkCode = false;
  public showConfirmMilkroundModel = false;
  public currentMilkround = null;

  public addCollegeList = [];
  // 筛选条件
  public model = {
    college_name: '',//学校名称
    status: '',//状态
    offset: 0,
    limit: 9,
    milkround_type: null
  }
  public countList = 0;//列表数量
  public revokeModule = false;
  public revokeApplyid = '';

  @Output() modelShow = false;//是否打开弹窗
  @Output() mridList = [];//招聘会id列表
  @Output() milkNumber = 1;//当前申请的招聘会场次
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(public dataApi: DataApi,
    public local: Local,
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public opera: Opera,
    public toastr: ToastrService) {
    activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.status) {
        this.model.status = queryParams.status;
      }
      if (queryParams.isAir) {
        this.opera.initConfig('nav-activity-air', 'college-milkround');
        this.model.milkround_type = 2;
        this.local.set('isAir', 2)
      } else {
        this.opera.initConfig('nav-activity', 'college-milkround');
        this.model.milkround_type = 1;
        this.local.set('isAir', 1)
      }
      this.model.milkround_type = null;
    })
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    if (JSON.parse(this.local.get('isAir')) == 2) {
      this.opera.initConfig('nav-activity-air', 'college-milkround');
    } else {
      this.opera.initConfig('nav-activity', 'college-milkround');
    }
    this.opera.initConfig('nav-activity', 'college-milkround');

    this.getCollegeCode();//获取高校字典
    this.getMilkList();//获取双选会列表
    this.mycollegeList();
  }

  //修改申请
  goUpdate(n) {
    //console.log('修改申请--------------');
    //console.log(n.applyid);
    this.local.set(Constants.applyid, n.applyid);
    this.router.navigateByUrl('/home/college-milk-sign');
  }
  //进入会场
  goSpace(id) {
    this.router.navigateByUrl('home/college-milk-space');
    this.local.set(Constants.mrid, id);//覆盖本地存储招聘会id
  }

  //去报名（再次申请）
  goSign(n) {

    this.opera.checkCompanyIsBack(n.collegeid, () => {

      //如果是公益双选会，不验证会员
      if (n.is_welfare) {
        //checkAuth

        this.opera.checkAuth((isPay) => {
          this.goMilkSign(n);//报名
        })
        return;
      }
      let that = this;
      this.opera.checkMember((isPay) => {
        if (isPay) {
          that.dataApi.checkDepCollegeIdentity(n.collegeid).then((res: any) => {
            //console.log("获取企业在高校的认证信息-----------------------CampusDetails");
            //console.log(res);
            if (res) {
              if (res.gwl_status == '2') {//通过工作啦认证
                that.goMilkSign(n);//报名
              } else {//未通过工作啦审核
                if (res.is_activity) {//活动高校
                  if (res.count > 0) {//通过1家以上高校校招
                    that.goMilkSign(n);//报名
                  } else {//提示企业认证
                    // this.opera.remindDepartmentIdentity(this.milk.collegeid,this.collegeIdentity,this.navCtrl);
                    that.opera.goIdentityDetails(n.collegeid);
                  }
                } else {//非活动高校
                  if (res.status == '2') {//通过校招
                    that.goMilkSign(n);//报名
                  } else if (res.status == '1') {//校招审核中
                    that.toastr.warning("校招审核中，审核通过后才可报名双选会");
                    // this.opera.remindDepartmentIdentity(this.milk.collegeid,this.collegeIdentity,this.navCtrl);
                  } else {//未提交校招申请
                    // this.opera.remindCollegeIdentity("报名双选会",this.college.collegeid,this.collegeIdentity);
                    that.opera.goIdentityDetails(n.collegeid);
                  }
                }
              }
            } else {
              that.opera.goIdentityDetails(n.collegeid);
            }
          }).catch((err) => {
            that.toastr.error(err);
          })
        } else {
          //that.opera.checkMemberAlertWithType("goMilk")
          //非会员验证是否是合作3所高校
          let finItem = this.addCollegeList.find(item => item.collegeid == n.collegeid);
          if (finItem) {
            if (this.config.isMember_trial) {
              that.opera.checkMemberAlertWithType("goMilk_milkround")
              return;
            }
            this.opera.checkCompanyIsBack(n.collegeid, () => {
              this.goMilkSign(n);//报名
            })
          } else {
            this.pushCollege();
          }
        }
      })
    })
  }

  //去双选会报名页
  goMilkSign(n) {
    if (n.scale <= n.department_participate_count) {
      this.toastr.warning('坐席已满，无法报名！');
      return
    }
    this.local.set(Constants.collegeid, n.collegeid);
    this.local.set(Constants.mrid, n.mrid);
    this.local.remove(Constants.applyid);
    this.router.navigate(['home/college-milk-sign'], {
      queryParams: {
        milkname: n.name
      }
    });
  }


  //去双选会详情
  goDeatils(n) {
    let that = this;
    if (n.is_welfare) {
      this.opera.checkAuth((isPay) => {
        if (n.milkround_type == 2) {
          that.router.navigateByUrl('home/college-milk-air-space');
          that.local.set(Constants.mrid, n.mrid);//覆盖本地存储招聘会id
        } else {
          that.QrCodeURL = "";
          that.showConfirmMilkroundModel = true;
          that.currentMilkround = n
        }
      })
      return;
    }
    this.opera.checkMember((isPay) => {
      if (isPay) {
        if (this.config.isMember_trial) {
          that.opera.checkMemberAlertWithType("goMilk")
          return
        }

        this.router.navigate(['home/college-milkdetails'], {
          queryParams: {
            id: n.mrid
          }
        })
      }
      else {
        that.opera.checkMemberAlertWithType("goMilk")
      }
    })
  }
  //筛选
  goSearch(text) {
    this.model.college_name = text;
    this.getMilkList();//获取双选会列表
    this.keywordList = [];
  }

  //获取高校字典
  getCollegeCode() {
    // 获取高校字典数据
    if (this.config.school.length < 1) {
      this.dataApi.getDictDate('school').then((res: any) => {
        // //console.log(res);
        this.config.school = res;
      }).catch((err) => {
        // this.toastr.error("网络错误,请稍后重试");
        //console.log(err);
      })
    }
  }

  //匹配关键字列表
  getItems() {
    this.keywordList = [];
    if (!this.model.college_name) { return; }
    for (let s of this.config.school) {
      if (s.text.indexOf(this.model.college_name) >= 0) {
        this.keywordList.push(s);
      }
    }
  }
  //监听键盘事件
  inputKeyup(event) {
    let that = this;
    var keycode = window.event ? event.keyCode : event.which;
    if (keycode == 13) {
      that.goSearch(that.model.college_name);
    } else {
      that.getItems();
    }
  }

  //获取双选会列表
  getMilkList() {
    this.opera.loading();
    this.dataApi.getDepartmentSignMilkList(this.model).then((res: any) => {
      //console.log(res);
      this.milkRoundList = res.list;
      this.countList = res.count;
      if (this.showTips) {
        for (let item of this.milkRoundList) {
          let nowDate = new Date();
          let startDate = new Date(item.start_time);
          let endDate = new Date(item.end_time);
          let applyOpDate = new Date(item.apply_start_time); // 报名开始时间
          let applyEndDate = new Date(item.apply_end_time); // 报名结束时间
          let nowTime = nowDate.getTime();  // 当前时间戳
          let startTime = startDate.getTime();  // 开始时间戳
          let endTime = endDate.getTime();  // 结束时间戳
          let applyOpTime = applyOpDate.getTime();  // 报名开始时间戳
          let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳
          if (nowTime - endTime > 0) {
            item.isEnd = true; // 会议已结束
          } else {
            item.isEnd = false; // 会议未结束
          }

          // if(item.audit_status == 'passed' && item.isEnd==false){
          //   //end_time
          //   this.singleTips(item);
          //   this.showTips = false;
          //   break;
          // }
        }
      }
      this.pageCount = Math.ceil(res.count / 9);
      for (let val of this.milkRoundList) {
        let nowDate = new Date();
        let startDate = new Date(val.start_time);
        let endDate = new Date(val.end_time);
        let applyOpDate = new Date(val.apply_start_time); // 报名开始时间
        let applyEndDate = new Date(val.apply_end_time); // 报名结束时间
        let nowTime = nowDate.getTime();  // 当前时间戳
        let startTime = startDate.getTime();  // 开始时间戳
        let endTime = endDate.getTime();  // 结束时间戳
        let applyOpTime = applyOpDate.getTime();  // 报名开始时间戳
        let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳
        val.signOpTime = false; // 未设置报名开始时间
        val.status = val.audit_status;
        if (applyOpTime == -62135625943000 || applyOpTime == -62135625600000 || val.apply_start_time == '0001-01-01T00:00:00' || val.apply_start_time == '0001-01-01T00:00:00+08:00' || val.apply_start_time == '') {
          val.signOpTime = false; // 未设置报名开始时间
        } else {
          val.signOpTime = true;
        }
        if (applyEndTime == -62135625943000 || applyEndTime == -62135625600000 || val.apply_end_time == '0001-01-01T00:00:00' || val.apply_end_time == '0001-01-01T00:00:00+08:00' || val.apply_end_time == '') {
          val.signEdTime = false; // 未设置报名结束时间
        } else {
          val.signEdTime = true;
        }
        if (nowTime - startTime > 0) {
          val.isApplySign = false;
          val.isStart = true; // 会议已开始
        } else {
          val.isStart = false;  // 会议未开始
          val.isApplySign = true;
        }
        //增加是否是活动判断
        if (val.is_activity) {
          if (endTime - nowTime > 0) {
            val.isApplySign = true;
          } else {
            val.isApplySign = false;
          }
        }
        if (nowTime - endTime > 0) {
          val.isEnd = true; // 会议已结束
        } else {
          val.isEnd = false; // 会议未结束
        }
        if (val.signOpTime) { // 有报名时间和报名结束时间
          if (nowTime - applyOpTime > 0) {
            val.isAppStart = true; // 报名已开始
          } else {
            val.isAppStart = false; // 报名未开始
          }
          if (nowTime - applyEndTime > 0) {
            val.isAppEnd = true; // 报名已结束
          } else {
            val.isAppEnd = false; // 报名未结束
          }
        }

        //现在 时间 小于开始时间  = 报名未开始
        if (nowTime < applyOpTime) {
          val.loadState = 1;
        }
        else if (applyOpTime < nowTime && nowTime < applyEndTime) {
          //现在时间大于 报名开始时间 && 现在时间小于报名结束时间
          //报名中
          val.loadState = 2;
        }
        else if (nowTime > applyEndTime) {
          //报名已经截止
          val.loadState = 3;

          if (startTime < nowTime && nowTime < endTime) {
            //现在时间 大于开始时间 && 开始时间小于结束时间 == 进行中
            val.loadState = 4;

          }
          else if (nowTime > endTime) {
            //已经结束
            val.loadState = 5;
          }

        }

        //前面的状态
        // 1 可申请 state =null
        // 2 已申请state != null

        // 1报名已结束 val.isAppEnd=true
        // 正在进行 val.isStart && !val.isEnd
        // 已经结束 val.isEnd=true

        //操作按钮
        // 报名失败  只有认证和报名失败才显示  n.status=='not_passed'|| n.cooperation_status==3

        //报名失败按钮
        if (val.audit_status == 'not_passed' || val.cooperation_status == 3) {
          val.showFaileBtn = true;
          val.FaileBtnText = "报名失败";
        }
        else {
          val.showFaileBtn = false;
          val.FaileBtnText = "";
        }

        //审核中 双选会审核中+合作审核中/合作通过 +报名未截止
        if (val.audit_status == 'to_audit' && !val.isStart && (val.cooperation_status == 1 || val.cooperation_status == 2)) {
          val.showShenHeBtn = true;
          val.showShenHeBtnText = "审核中";
        }
        else if (val.audit_status == "passed" && !val.isStart && val.cooperation_status == 1) {
          val.showShenHeBtn = true;
          val.showShenHeBtnText = "审核中";
        }
        else {
          val.showShenHeBtn = false;
          val.showShenHeBtnText = "";
        }

        //展位号+通知学生   合作通过 + 双选会通过 + 会议未结束 + 会议已开始
        if (val.audit_status == 'passed' && val.cooperation_status == 2) {
          val.showSuccessBtn = true;

        }

        //我要报名  未申请双选会
        if (val.audit_status == null) {
          val.showBaoMingBtn = true;
        }

        //如果已经显示了报名失败的按钮，则强制不显示《我要报名》的按钮
        if (val.showFaileBtn) {
          val.showBaoMingBtn = false;
        }

        //如果已经显示了《审核中》的按钮，则强制不显示《我要报名》的按钮
        if (val.showShenHeBtn) {
          val.showBaoMingBtn = false;
        }

        //合作通过/未申请过合作+ 未报名双选会 +报名未结束
        if (val.audit_status == null && val.cooperation_status == 2 && !val.isAppEnd) {
          val.showBaoMingBtn = true;
        }
        else if (val.audit_status == null && val.cooperation_status == 0 && !val.isAppEnd) {
          val.showBaoMingBtn = true;
        }
        else {
          val.showBaoMingBtn = false;
        }

        //报名开始 +双选会审核中/双选会未通过
        if (val.milkround_type == 1) {
          if (val.isStart && (val.status == "to_audit" || val.status == "not_passed")) {
            val.showBaoMingBtn = false;
            val.showShenHeBtn = false;
            val.showFaileBtn = true;
          }
        }
        if (val.milkround_type == 2) {
          if (val.isStart && (val.status == "to_audit" || val.status == "not_passed")) {
            if (val.status == "to_audit") {
              val.showBaoMingBtn = false;
              val.showShenHeBtn = true;
              val.showFaileBtn = false;
            } else if (val.status == "not_passed") {
              val.showBaoMingBtn = false;
              val.showShenHeBtn = false;
              val.showFaileBtn = true;
            }
            if (val.cooperation_status == 3) {
              val.showBaoMingBtn = false;
              val.showShenHeBtn = false;
              val.showFaileBtn = true;
            }
          }
        }

        //如果合作失败/并且没有申请过双选会=我要报名
        if (!val.status) {
          if (val.cooperation_status == 3) {
            val.showShenHeBtn = false;
            val.showFaileBtn = false;
            val.showBaoMingBtn = true;
          }
        }
        if (val.zone_name == null) {
          val.zone_name = "";
        }
        if (val.seat_number == null) {
          val.zone_name = 0;
        }
        let zhanwei = "";
        if (val.seat_number == 0) {
          if (val.zone_name.length > 0) {
            zhanwei = "展位分区:" + val.zone_name
          }
          else {
            zhanwei = "展位号暂未分配"
          }
        }
        else {
          if (val.zone_name.length > 0) {
            zhanwei = "展位号:" + val.zone_name + "-" + val.seat_number
          }
          else {
            zhanwei = "展位号:" + val.seat_number
          }
        }
        zhanwei = "展位号:" + val.zone_name + "-" + val.seat_number
        if (val.seat_number == 0) {
          zhanwei = "展位号暂未分配"
        }
        val.zhanwei = zhanwei;
        // console.log('');
      }
      console.log("获取双选会列表-申请记录", this.milkRoundList);

      this.opera.loadiss();
    }).catch((err) => {
      //console.log(err);
      this.opera.loadiss();
    })
  }

  //获取分页子组件返回数据
  getChildEvent(index) {
    //console.log('子组件返回分页数-----college-campustalk');
    //console.log(index);
    if (index > 0) {
      this.pageNumber = index;
      this.model.offset = (this.pageNumber - 1) * 9;
      this.getMilkList();//获取列表
    } else if (index == -1) {//上一页
      if (this.pageNumber == 1) {
        this.toastr.warning("当前为第一页");
      } else {
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber - 1) * 9;
        this.getMilkList();//获取列表
      }
    } else if (index == -2) {//下一页
      if (this.pageNumber == this.pageCount) {
        this.toastr.warning("当前为最后一页");
      } else {
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber - 1) * 9;
        this.getMilkList();//获取列表
      }
    }
  }

  // 获取双选会海报下载链接
  downloadPoster(applyid) {
    this.opera.loading();
    this.dataApi.getMilkPoster(applyid).then((res: any) => {
      this.opera.loadiss();
      if (res.value) {
        let b = window.open();
        setTimeout(function () {
          b.location.href = res.value;
        }, 500);
      }
    }).catch((err) => {
      console.log(err);
      this.opera.loadiss();
    })
  }

  downloadIamge(imgsrc, name) {//下载图片地址和图片名
    let image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function () {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      let a = document.createElement("a"); // 生成一个a元素
      let event = new MouseEvent("click"); // 创建一个单击事件
      a.download = name || "photo"; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
    this.opera.loadiss();
    image.src = imgsrc;
  }

  // 活动通知包触发
  singleTips(n) {
    // this.dataApi.getMilkDetails(n.mrid).then((res:any)=>{ //获取双选会详情
    //
    //   // if(res.zoom_name){
    //   //   num = res.zoom_name + res.seat_number.toString()
    //   // }else{
    //   //   num = res.seat_number.toString()
    //   // }
    //   // let message = `<p style="margin:0;">恭喜您！通过了【${res.name}】</p>
    //   // <p style="margin:0;">展位号<em style="font-style: normal;color: #5E4FFF;font-size: 20px;margin-left: 10px;">${num}</em></p>`
    //   // let fn = () =>{
    //   //   this.local.set(Constants.activityNoticeItem,JSON.stringify(n));
    //   //   this.router.navigateByUrl('/home/activity-notice');// 活动通知单品页面
    //   // }
    //   // this.opera.customTips('',message,'通知该校学生到场','',fn,'')
    //   // this.local.set(Constants.activityNoticeItem,JSON.stringify(n));
    //   // this.router.navigateByUrl('/home/activity-notice');// 活动通知单品页面
    //
    //
    // }).catch((error)=>{
    //   //console.log(error);
    // })

    this.opera.checkCompanyIsBack(n.collegeid, () => {
      this.local.set('activityInvitationData', JSON.stringify(n));
      this.local.set('activityInvitationDataType', 'milkround');
      this.router.navigateByUrl('/home/activityInvitation');// 通知学生到场
    })


  }
  /**
   * 确认参会按钮
   * @param n
   */
  // okCanhui(n)
  // {
  //
  //   this.QrCodeURL = "";
  //   let data = {
  //     mrid:n.mrid,
  //     type:"join",
  //   };
  //   this.dataApi.confirmMilkround(data,n.mrid,"join").then((res:any)=>{
  //
  //     console.log("确认参会",res);
  //     if(res.value)
  //     {
  //       n.feedback_status='join';
  //       this.QrCodeURL = res.value;
  //       this.showMilkCode = true;
  //     }
  //
  //   }).catch((err)=>{
  //     console.log("确认参会失败",err);
  //     this.toastr.error(err)
  //
  //   })
  //
  // }

  /**
   * 确认参会按钮
   * @param n
   */
  okCanhui(n) {
    let that = this;
    this.opera.checkCompanyIsBack(n.collegeid, () => {
      //公益双选会
      if (n.is_welfare) {
        this.opera.checkAuth((isPay) => {
          if (n.milkround_type == 2) {
            that.router.navigateByUrl('home/college-milk-air-space');
            that.local.set(Constants.mrid, n.mrid);//覆盖本地存储招聘会id
          } else {
            that.QrCodeURL = "";
            that.showConfirmMilkroundModel = true;
            that.currentMilkround = n
          }
        })
        return;
      }

      this.opera.checkMember((isPay) => {
        if (isPay) {
          if (n.milkround_type == 2) {
            that.router.navigateByUrl('home/college-milk-air-space');
            that.local.set(Constants.mrid, n.mrid);//覆盖本地存储招聘会id
          } else {
            that.QrCodeURL = "";
            that.showConfirmMilkroundModel = true;
            that.currentMilkround = n
          }
        } else {
          that.opera.checkMemberAlertWithType("goMilk_milkround")
        }
      })
    })
  }
  //已经参会
  oldCanhui() {

  }
  //关闭确认参会按钮的二维码
  closeModel() {
    this.QrCodeURL = "";
    this.showMilkCode = false;

  }
  ConfirmMilkroundCommit2(item) {

    console.log('item', item)
    let type = 'join'
    let data = {
      mrid: item.mrid,
      type: type,
    };
    this.dataApi.getMilkroundFeedBackPost(item.mrid).then((res: any) => {


      if (res.value) {
        this.QrCodeURL = res.value;
        this.showMilkCode = true;
      }

    }).catch((err) => {
      console.log("确认参会失败", err);

    })
  }
  //已经参会
  ConfirmMilkroundCommit(type) {
    let data = {
      mrid: this.currentMilkround.mrid,
      type: type,
    };
    this.dataApi.confirmMilkround(data, this.currentMilkround.mrid, type).then((res: any) => {

      console.log("确认参会", res);
      this.showConfirmMilkroundModel = false;
      if (type == "no_join") {
        this.toastr.error("请合理安排时间，如经常不参加双选会将对您企业的信用造成损失")
      }
      else {
        this.toastr.success("已确认参加成功")

      }
      for (let index = 0; index < this.milkRoundList.length; index++) {
        let item = this.milkRoundList[index];
        if (item.mrid == this.currentMilkround.mrid) {
          item.feedback_status = type;
        }
      }

      if (type == "join") {
        this.currentMilkround.feedback_status = 'join';
        this.QrCodeURL = res.value;
        this.showMilkCode = true;
      }

    }).catch((err) => {
      console.log("确认参会失败", err);
      this.toastr.error(err)

    })
  }

  //进入面试间
  goRoom(item) {
    //开始时间提前十分可以入场
    let online_interview_start_time = moment(item.online_interview_start_time).subtract(10, 'm').format();
    //是否开始
    if (!moment(online_interview_start_time).isBefore(new Date())) {
      this.toastr.warning("未到面试间时间");
      return
    }

    //是否结束
    if (!moment(new Date()).isBefore(item.online_interview_end_time)) {
      this.toastr.warning("面试间已到期");
      return
    }

    this.router.navigateByUrl('/home/interview?roomid=' + item.roomid);
    this.local.set('roomid', item.roomid);//覆盖本地存储招聘会id
  }

  //获取合作高校
  mycollegeList() {
    this.dataApi.mycollegeList().then((res: any) => {
      if (res) {
        this.addCollegeList = res;
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  //新增高校
  pushCollege() {
    this.isShowPushCollegeModel = true;
    this.pushCollegeModel.initData();
  }

  pushCollegeModelHidden(event) {
    this.isShowPushCollegeModel = false;
    this.mycollegeList();
  }

   // 撤回
   openRevoke(applyid){
    this.revokeApplyid = applyid;
    this.revokeModule = true;
  }

  downMilk(){
    this.dataApi.revokeMilk(this.revokeApplyid).then((res:any)=>{
      this.getMilkList();
      this.revokeModule = false;
    }).catch((err)=>{
      console.log("downMilk",err);
    });
  }

  timeBefore(endTime){
    return moment(new Date()).isBefore(endTime)
  }

}
