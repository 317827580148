import { Component, OnInit, Output, ViewChild } from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {productManager} from "../../provider/productManager";
import { MatDialog } from "@angular/material/dialog";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
declare let layui;

@Component({
  selector: 'app-air-camp-list',
  templateUrl: './air-camp-list.component.html',
  styleUrls: ['./air-camp-list.component.css']
})
export class AirCampListComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true }) 
  productModel: ProductModelComponent;

  public config: Config;
  public airCampustalk:any;
  public airLiveCampustalkList = [];
  public airLiveCampustalkCount = 0;
  public othertLiveListCount = 0; // 直播待岗总页数
  public airLiveCampustalk:any;

  public isHaveAirCampus = false;
  public isHaveLiveAirCampus = true;
  public othertLiveList = []; // 直播待岗 宣讲会
  public isShowMemberMoudel = true;
  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public productManager:productManager,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.config = Config.getInstance();//实例化config
    this.opera.initConfig('aircampus','airCampList');
    this.opera.vipState((isPay)=>{
      this.isShowMemberMoudel =  isPay;
    })
    this.getAirCampustalk()
    this.getLiveCampustalk();
    this.geOthertLiveList();
  }

  getAirCampustalk(){
    this.dataApi.getAirCampustalk().then((res:any) => {
      if(res) {
        console.log('getAirCampustalk',res);
        this.airCampustalk = res;
        if(this.airCampustalk.majors==null) {
          this.airCampustalk.majors = [];
        }
        this.isHaveAirCampus = true
        if(res.audit_status==0){
          this.isHaveAirCampus = false
        }
      }
    }).catch(() => {
    });
  }
  getLiveCampustalk(){
    this.dataApi.getAircampustalkList({is_online:true,air_type:'live', offset:0,
      limit:10}).then((res:any) => {
      if(res) {
        this.airLiveCampustalkCount = res.count;
        this.airLiveCampustalkList = res.list;
        let that = this;
        layui.use('laypage', function(){
          var laypage = layui.laypage;
          //执行一个laypage实例
          laypage.render({
            elem: 'resumePage' //注意，这里的 test1 是 ID，不用加 # 号
            ,count: res.count, //数据总数，从服务端得到
            limit:10,
            theme: '#5e4fff',
            layout:['prev', 'page', 'next','skip','count']
            ,jump: function(obj, first){
              //obj包含了当前分页的所有参数，比如：
              console.log("obj.curr",obj.curr); //得到当前页，以便向服务端请求对应页的数据。
              console.log(obj.limit); //得到每页显示的条数
              //首次不执行
              if(!first){
                that.getMoreLiveCampustalk(obj.curr)
              }
            }
          });
        });
      }
    }).catch(() => {
    })
  }
  getMoreLiveCampustalk(page){
    page = page-1
    this.dataApi.getAircampustalkList({is_online:true,air_type:'live', offset:page*10,
      limit:10}).then((res:any) => {
      if(res) {
        console.log('getAircampustalkList',res)
        this.airLiveCampustalkCount = res.count;
        this.airLiveCampustalkList = res.list
      }
    }).catch(() => {
    })
  }
  gotoSign(){
    this.router.navigateByUrl("home/creatAirCampus");
  }
  gotoAirCampDetail(page){
    let that = this;
    this.opera.checkMember((isPay)=>{
      //会员
      if(isPay) {
        this.dataApi.getcomponentLeftWithAlias('air_campustalk').then((res:any)=>{
          console.log('air_campustalk',res,page)
          if(res){
            if(res.value>0){
              this.local.set('airCampusType','airCampusDetail')
              if(page == "airCampusDetail"){
                if(this.airCampustalk.audit_status==0){
                  this.router.navigateByUrl("home/creatAirCampus");
                  return
                }
              }
              this.router.navigateByUrl("home/" + page);
            }else {
              // 权益不足
              this.product('air_campustalk','member');

            }
          }
        }).catch((error)=>{})
      }else {
        //非会员 请校招顾问联系我
        this.product('','contactMe');
      }
    })
  }

  gotoLiveAirCampDetail(item){

    this.local.remove('airCampusType');
    this.local.remove('airCampusType_acid');

    // 已经结束了，跳转到历史详情
    if(item.air_live_type == 4) {
      this.local.set('airCampusType','liveCampusDetail')
      this.router.navigateByUrl("home/airCampHistoryDetail?acid="+item.acid);
      return
    }
    this.local.set('airCampusType','liveCampusDetail')
    this.router.navigate(['home/airCampusDetail'],{
      queryParams: {
        live:true,
      }
    });
  }
  bannerClick(){
    this.router.navigateByUrl("home/airCampexplain");
  }
  //获取新功能直播列表
  geOthertLiveList(){
    this.dataApi.getAircampustalkList({is_online:true,airLiveCategory:'2', offset:0, limit:10}).then((res:any) => {
      console.log('geOthertLiveList',res);
      if(res){
        this.othertLiveList = res.list;
        this.othertLiveListCount = res.count;

        let that = this;
        layui.use('laypage', function() {
          var laypage = layui.laypage;
          //执行一个laypage实例
          laypage.render({
            elem: 'otherLivePage' //注意，这里的 test1 是 ID，不用加 # 号
            , count: res.count, //数据总数，从服务端得到
            limit: 10,
            theme: '#5e4fff',
            layout: ['prev', 'page', 'next', 'skip', 'count']
            , jump: function (obj, first) {
              //obj包含了当前分页的所有参数，比如：
              console.log("obj.curr", obj.curr); //得到当前页，以便向服务端请求对应页的数据。
              console.log(obj.limit); //得到每页显示的条数
              //首次不执行
              if (!first) {
                that.geOthertLiveListPage(obj.curr)
              }
            }
          });
        })
      }
    }).catch(() => {
    })
  }

  geOthertLiveListPage(page){
    this.dataApi.getAircampustalkList({is_online:true,airLiveCategory:'2', offset:(page-1)*10, limit:10}).then((res:any) => {
      console.log('geOthertLiveList',res);
      if(res){
        this.othertLiveList = res.list;
        this.othertLiveListCount = res.count;
        let that = this;
        layui.use('laypage', function() {
          var laypage = layui.laypage;
          //执行一个laypage实例
          laypage.render({
            elem: 'resumePage' //注意，这里的 test1 是 ID，不用加 # 号
            , count: res.count, //数据总数，从服务端得到
            limit: 10,
            theme: '#5e4fff',
            layout: ['prev', 'page', 'next', 'skip', 'count']
            , jump: function (obj, first) {
              //obj包含了当前分页的所有参数，比如：
              console.log("obj.curr", obj.curr); //得到当前页，以便向服务端请求对应页的数据。
              console.log(obj.limit); //得到每页显示的条数
              //首次不执行
              if (!first) {
                that.getMoreLiveCampustalk(obj.curr)
              }
            }
          });
        })
      }
    }).catch(() => {
    })
  }
  // 直播带岗 查看详情
  gotoDetailWithOtherLive(item){
    console.log('item',item)
    let accountid = this.local.get('accountid')
    if(item.departmentids.indexOf(accountid)>=0){
      // 跳转到详情
      console.log('跳转到详情')
      // 已经结束了，跳转到历史详情
      if(item.air_live_type == 4) {
        this.local.set('airCampusType','liveCampusDetail');
        this.router.navigateByUrl("home/airCampHistoryDetail?acid="+item.acid);
        return
      }
      this.local.set('airCampusType','liveCampusDetail');
      this.local.set('airCampusType_acid',item.acid);
      this.router.navigate(['home/airCampusDetail'],{
        queryParams: {
          live:true,
        }
      });
    }else {
      let url = "http://air.goworkla.cn/live/index.html?id="+item.acid;
      // url = "http://192.168.0.88:8090/live/index.html?id="+item.acid;
      console.log('跳转到直播会场');
      window.open(url,'_blank');
    }
  }


  //点击产品
  product(alias,type){
    this.productModel.initData(alias,type,true);
    this.isproductShowModel = true;
  }

  productModelHidden(event){    
    this.isproductShowModel = false;
  }

  goPastx(){
    this.router.navigate(['home/airCampHistory']);
  }
}
