<!-- 遮罩层公共样式 -->
<div class="model-block" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>
    <!-- 申请高校合作 -->
    <div class="model-cont" *ngIf="!item">
        <!-- 上层提示部分 -->
        <div class="model-head clear">
            <div class="left">申请高校合作</div>
            <button class="right" (click)="dismiss(false)">×</button>
        </div>
        <!-- 下层主体内容部分 -->
        <div class="model-content">
            <div *ngIf="name" class="model-list clear">
                <div class="left">申请院校</div>
                <div class="left">{{name}}</div>
            </div>
            <div *ngIf="!name" class="model-list clear">
                <div class="left">申请学院</div>
                <div class="left">
                    <select name="COrg" id="COrg" [(ngModel)]="facultyid" autocomplete="off">
            <option class="place-text" value='' disabled selected="selected">请选择院系</option>
            <option *ngFor="let a of orgList" [value]="a.facultyid">{{a.name}}</option>
          </select>
                </div>
            </div>
            <div class="model-list clear">
                <div class="left">合作要求</div>
                <div class="left">
                    1、完善公司基本信息，包括：公司名称、公司简介、发布职位等；<br/> 2、资质审核：提交公司营业执照等相关证件；
                </div>
            </div>
            <div class="model-list clear">
                <div class="left">校招内容</div>
                <div class="left">
                    1、职位招聘：正在招聘的职位将自动进入高校就业中心后台，审核通过后，可在高校就业信息网展示；<br/> 2、品牌展示：用人单位资质经审核后，有机会在高校就业信息网单位品牌栏目展示；
                    <br/> 3、优先资格：经过高校审核的企业，将会有机会优先参加招聘会、宣讲会；
                </div>
            </div>
        </div>
        <!-- 底部操作部分 -->
        <div class="model-foot">
            <button class="purple" (click)="dismiss(true)">知道了,我要提交申请</button>
            <!-- <button  class="purple" (click)="goWork()">工作啦认证</button> -->
        </div>
    </div>

    <!-- 查看申请校招状态 -->
    <div class="model-cont" *ngIf="item">
        <!-- 上层提示部分 -->
        <div class="model-head clear">
            <div class="left">高校合作审核状态</div>
            <button class="right" (click)="dismiss(false)">×</button>
        </div>
        <!-- 下层主体内容部分 -->
        <div class="model-content">
            <!-- 审核中 -->
            <div class="info-list" *ngIf="item.status==1">
                <div class="info-head"><img src="assets/images/indentity-one.png"></div>
                <div class="info-title">正在审核中，请耐心等待...</div>
                <div class="info-text">7个工作日内。</div>
                <br>
                <button class="purple" (click)="goWork()">
                <!-- 成为工作啦会员，每天和100名毕业生主动沟通 -->
                申请其他高校
                <!-- <span>还有每天可以直聊，企业加V等更多权益，了解更多》》</span> -->
            </button>
            </div>
            <!-- 审核成功 -->
            <div class="info-list" *ngIf="item.status==2">
                <div class="info-head"><img src="assets/images/indentity-three.png"></div>
                <div class="info-title">审核已通过</div>
                <!-- <div class="info-text">恭喜您，已通过认证。</div> -->
                <div class="pass-con">
                    <!--<p>我获得的校招权益</p>-->
                    <!--<ul>-->
                    <!--<li>-->
                    <!--<p>申请本校校招活动</p>-->
                    <!--<span>直接在线申请本校宣讲会/双选会</span>-->
                    <!--</li>-->
                    <!--<li>-->
                    <!--<p>查看本校人才简历</p>-->
                    <!--<span>直接查看本校人才简历(部分信息)</span>-->
                    <!--</li>-->
                    <!--<li>-->
                    <!--<p>发布招聘职位</p>-->
                    <!--<span>上线的职位审核后会自动发布至学校就业信息网</span>-->
                    <!--</li>-->
                    <!--<li>-->
                    <!--<p>主动与本校毕业生沟通</p>-->
                    <!--<span>限与本校毕业生主动沟通名额：10人</span>-->
                    <!--</li>-->
                    <!--<li>-->
                    <!--<p>发布招聘简章</p>-->
                    <!--<span>发布的招聘简章审核后自动发布至本校就业信息网</span>-->
                    <!--</li>-->
                    <!--</ul>-->
                    <br>
                    <button class="purple" (click)="goWork()">
                <!-- 成为工作啦会员，每天和100名毕业生主动沟通 -->
                申请其他高校
                <!-- <span>还有每天可以直聊，企业加V等更多权益，了解更多》》</span> -->
            </button>
                </div>
            </div>
            <!-- 审核失败 -->
            <div class="info-list" *ngIf="item.status==3">
                <div class="info-head"><img src="assets/images/indentity-two.png"></div>
                <div class="info-title red">审核未通过</div>
                <div class="info-text info-reason">原因：{{item.remark}}</div>
                <!-- <button class="purple submit" (click)="dismiss(true)">重新申请</button><br/> -->
                <button class="purple submit" (click)="again(true)">重新申请</button><br/>
                <br>
                <button class="purple" (click)="goWork()">
              <!-- 成为工作啦会员，每天和100名毕业生主动沟通 -->
              申请其他高校
              <!-- <span>还有每天可以直聊，企业加V等更多权益，了解更多》》</span> -->
          </button>
            </div>
            <!-- 被拉黑 -->
            <div class="info-list" *ngIf="item.status==5">
                <div class="info-head"><img src="assets/images/indentity-two.png"></div>
                <!-- <div class="info-title red">审核未通过</div> -->
                <div class="info-text info-reason">已被该高校拉入黑名单，暂不可申请</div>
                <!-- <button class="purple submit" (click)="dismiss(true)">重新申请</button><br/> -->
                <!-- <button class="purple submit" (click)="again(true)">重新申请</button><br/> -->
                <br>
                <button class="purple" (click)="goWork()">
              <!-- 成为工作啦会员，每天和100名毕业生主动沟通 -->
              申请其他高校
              <!-- <span>还有每天可以直聊，企业加V等更多权益，了解更多》》</span> -->
          </button>
            </div>
        </div>
    </div>

</div>