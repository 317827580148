import { Component, OnInit, Input, Output,ViewChild } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {  ActivatedRoute,Router } from  '@angular/router';
import { ModelCollegeMilksignComponent } from '../model-college-milksign/model-college-milksign.component';
import {NoneVipAlertComponent} from "../none-vip-alert/none-vip-alert.component";
import { MatDialog } from "@angular/material/dialog";

//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
//新增高校
import { PushCollegeModelComponent } from "../push-college-model/push-college-model.component";

@Component({
  selector: 'app-college-milkdetails',
  templateUrl: './college-milkdetails.component.html',
  styleUrls: ['./college-milkdetails.component.css']
})
export class CollegeMilkdetailsComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true }) 
  productModel: ProductModelComponent;

  //是否打开弹窗(非会员新增高校)
  @Output() isShowPushCollegeModel = false;
  @ViewChild('pushCollegeModel', { static: true }) 
  pushCollegeModel: PushCollegeModelComponent;

  public config: Config;
  // @ViewChild('milkUpdata') child: ModelCollegeMilksignComponent;
  @Output() modelShow = false;//是否打开弹窗
  @Output() milkNumber = 1;//当前申请的招聘会场次
  @Output() mridList=[];//招聘会id列表
  public milk:any;//双选会详情
  public milk_user:any;//双选会详情
  public milkId='';//双选会id
  public isApplySign=true;//是否允许报名（即招聘会是已经开始）
  public isMilkStart=false;//招聘会是否已经开始
  public isMilkEnd=false;//招聘会是否已经结束
  public collegeIdentity:any;//企业当前高校认证数据

  public DownLoadUrl1 ='';//附件1
  public DownLoadUrlArr = []//附件4
  public addCollegeList = []; //合作高校
  constructor(
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService
  ) {
      this.config = Config.getInstance();//实例化config
      activatedRoute.queryParams.subscribe(queryParams => {
        if(queryParams.type){
          this.milkId = queryParams.mrId;
          this.getMilkDetails();//获取招聘会详情
        }else{
          if(queryParams.id){
            this.milkId = queryParams.id;
            this.getMilkDetails();//获取招聘会详情
          }
        }
        if(queryParams.isAir){
          this.opera.initConfig('nav-activity-air','college-milk-air-space');
        }else{
          this.opera.initConfig('nav-activity','college-milkround');
        }
      })
      if(!this.milkId){
        this.milkId = this.local.get(Constants.mrid);
        this.getMilkDetails();//获取招聘会详情
      }
    }

  ngOnInit() {
    this.getNewList();
    this.mycollegeList();
  }

  //接收返回参数
  Modeldismiss(event){
    this.modelShow=false;
    this.opera.scrollTrue();
    if(event){
      this.milk.status='to_audit';
    }
    this.mridList = [];//清空
  }

  //去报名
  goSign(){
    this.opera._hmtPush('双选会','双选会信息','我要报名')


    let that = this;

    //公益双选会
    if(this.milk.is_welfare) {
      //公益双选会，领取公益会员，
      this.dataApi.signMilkgetMemberWelfare(this.milk.mrid).then((res:any)=>{
      }).catch((err)=>{
        console.log(err);
      });
      // this.opera.checkAuth((isPay)=>{
      //   that.goMilkSign();//报名
      // })
      this.goMilkSign();//报名
      return;
    }

    this.opera.checkMember((isPay)=>{
      if(isPay) {
        if(this.config.positionList.length==0&&!this.milk.is_welfare){
          // this.opera.remindPublish("报名双选会");
          this.toastr.error('至少有一个在期职位才可报名双选会')
          return;
        }
        this.opera.checkCompanyIsBack(this.milk.collegeid,()=> {
          that.goMilkSign();//报名
        })
      } else {
        if(this.config.isShowPaySystem){
          that.opera.checkMemberAlertWithType("goMilk_milkround")
        }
        else {
          // console.log('去校招顾问，没有会员')
          // that.opera.checkMemberAlertWithType("goBuy_signMilk")
          // this.router.navigate(['home/tool-campus']);

          //非会员验证是否是合作3所高校
          let finItem = this.addCollegeList.find(item=>item.collegeid == this.milk.collegeid);
          if(finItem){
            this.goMilkSign();//报名
          }else{
            this.pushCollege();
          }
        }
      }
    })

  }

  //去双选会报名页
  goMilkSign(){
    if(this.milk.apply_start_time == '0001-01-01T00:00:00' || this.milk.apply_start_time == '0001-01-01T00:00:00+08:00' || this.milk.apply_start_time == ''){
      this.milk.signAppTime = false; // 未设置报名开始时间
    }else{
      this.milk.signAppTime = true;
    }
    if(this.milk.apply_end_time == '0001-01-01T00:00:00' || this.milk.apply_end_time == '0001-01-01T00:00:00+08:00' || this.milk.apply_end_time == ''){
      this.milk.signEdTime = false; // 未设置报名结束时间
    }else{
      this.milk.signEdTime = true;
    }
    if(this.milk.signAppTime && this.milk.signEdTime){
      let nowDate = new Date();
      let applyOpDate = new Date(this.milk.apply_start_time); // 报名开始时间
      let applyEndDate = new Date(this.milk.apply_end_time); // 报名结束时间
      let endDate = new Date(this.milk.end_time); // 报名结束时间
      let nowTime = nowDate.getTime();  // 当前时间戳
      let applyOpTime = applyOpDate.getTime();  // 报名开始时间戳
      let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳
      let endTime = endDate.getTime();  // 报名结束时间戳
      if(nowTime-applyOpTime<=0){
        this.toastr.warning('报名未开始！');
        return
      }
      if(this.milk.milkround_type!=2)
      {
        if(nowTime-applyEndTime>0){
          this.toastr.warning('报名已结束！');
          return
        }
      }
      else
      {
        //
        if(nowTime-endTime>0){
          this.toastr.warning('报名已结束！');
          return
        }
      }

    }
    if(this.milk.milkround_type == 1) {
      if(this.milk.scale <= this.milk.department_participate_count){
        this.toastr.warning('坐席已满，无法报名！');
        return
      }
    }

    this.local.set(Constants.collegeid,this.milk.collegeid);
    this.local.set(Constants.mrid,this.milkId);
    this.local.remove(Constants.applyid);
    this.router.navigate(['home/college-milk-sign'], {
      queryParams: {
        milkname:this.milk.name
      }
    });
  }

   //修改申请
  goUpdate(data){
     this.local.set(Constants.applyid,data.mcid);
     this.local.set(Constants.collegeid,this.milk.collegeid);
     this.router.navigateByUrl('/home/college-milk-sign');
  }

  //进入会场
  goSpace(){
    console.log('进入会场',this.milk)
    //公益会场不受会员限制
      if(this.milk.is_welfare) {
        if(this.milk.milkround_type == 2){
          this.router.navigateByUrl('home/college-milk-air-space');
        }else{
          this.router.navigateByUrl('home/college-milk-space');
        }
        this.local.set(Constants.mrid,this.milkId);//覆盖本地存储招聘会id
        return
      }
      this.opera.checkMember((isPay)=>{
        if(isPay) {
          if(this.milk.milkround_type == 2){
            this.router.navigateByUrl('home/college-milk-air-space');
          }else{
            this.router.navigateByUrl('home/college-milk-space');
          }
          this.local.set(Constants.mrid,this.milkId);//覆盖本地存储招聘会id
        } else {
          // this.opera.checkMemberAlertWithType("goBuy_signMilk")
          // this.router.navigate(['home/tool-campus']);
          //非会员验证是否是合作3所高校
          let finItem = this.addCollegeList.find(item=>item.collegeid == this.milk.collegeid);
          if(finItem){
            if(this.milk.milkround_type == 2){
              this.router.navigateByUrl('home/college-milk-air-space');
            }else{
              this.router.navigateByUrl('home/college-milk-space');
            }
          }else{
            this.pushCollege();
          }
        }
    })


  }

  //判断企业是否通过高校认证
  checkDepartmentIndentityByCollege(id){
    this.dataApi.checkDepCollegeIdentity(id).then((res:any)=>{
      this.collegeIdentity=res;
    }).catch((err)=>{
      //console.log(err);
    })
  }

   //获取招聘会详情
   getMilkDetails(){
    this.opera.loading();
    this.dataApi.getMilkDetails(this.milkId).then((res:any)=>{
       //console.log(res);
        if(res)
        {
          //cooperation_status 2
          //status"passed"
          //feedback_status join
          console.log("获取到招聘会详情",res);

          if(res.department_receipt_switch ==true)
          {
            this.DownLoadUrl1 = res.department_receipt_url;
          }
          this.milk=JSON.parse(JSON.stringify(res));
          this.getApplyStatus(res.start_time,res.end_time);//根据开始和结束时间判断当前招聘会的状态
          this.milkId = res.mrid;
          this.checkDepartmentIndentityByCollege(this.milk.collegeid);//验证企业是否通过高校认证
          this.local.set(Constants.mrid,this.milkId);
          if(res.attachment_list!=null)
          {
            this.DownLoadUrlArr = res.attachment_list;
          }

        }



       this.opera.loadiss();
    }).catch((error)=>{
      // console.log(error);
      this.opera.goChangeRouter('college-resume');
      this.toastr.error(error);
      this.opera.loadiss();
    })
     //
     this.dataApi.getMilkDetailsWithDepartment(this.milkId).then((res:any)=>{
       //console.log(res);
       this.opera.loadiss();
       if(res) {
         this.milk_user = res;
         console.log("获取到招聘会详情22", res);
         let nowDate = new Date();
         let startDate = new Date(this.milk_user.start_time);
         let endDate = new Date(this.milk_user.end_time);
         let applyOpDate = new Date(this.milk_user.apply_start_time); // 报名开始时间
         let applyEndDate = new Date(this.milk_user.apply_end_time); // 报名结束时间
         let nowTime = nowDate.getTime();  // 当前时间戳
         let startTime = startDate.getTime();  // 开始时间戳
         let endTime = endDate.getTime();  // 结束时间戳
         let applyOpTime = applyOpDate.getTime();  // 报名开始时间戳
         let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳
         if(nowTime-endTime>0){
           this.milk_user.isEnd=true; // 会议已结束
         }else{
           this.milk_user.isEnd=false; // 会议未结束
         }
       }
     }).catch((error)=>{
       // console.log(error);
       this.opera.loadiss();
       this.toastr.error(error);

     })



  }

  //判断招聘会是否允许报名以及是否允许进入会场
  getApplyStatus(data,res){
    let timeNow=new Date().getTime();
    let time=new Date(data).getTime();
    let timeOne=new Date(res).getTime();
    // //console.log(timeNow);
    // //console.log(time);
    // //console.log(timeOne);
    if(timeNow-time>0){
      this.isMilkStart=true;
      this.isApplySign=false;//报名已截止
       if(timeOne-timeNow<0){
          this.isMilkEnd=true;//招聘会已结束
       }
    }else{
      this.isMilkStart=false;
    }
    //增加是否是活动判断
    if(this.milk.milkround_type==2){
      if(timeOne-timeNow>0){
        this.isApplySign=true;
      }else{
        this.isApplySign=false;
      }
    }
  }

  /**
   * 下载附件
   */
  downLoadGfile(){

    if( this.DownLoadUrl1!='' && this.DownLoadUrl1)
    {
      this.DownLoadUrlArr.push({link:this.DownLoadUrl1})
    }
    if(this.DownLoadUrlArr.length==0)
    {
      this.toastr.error('暂无附件');
      return;
    }
    console.log("downLoadGfile", this.DownLoadUrlArr);
    for (let a=0;a<this.DownLoadUrlArr.length;a++)
    {
      let item = this.DownLoadUrlArr[a];
      setTimeout(()=>{
        let item = this.DownLoadUrlArr[a];
        let arr = item.link.split('.');
        if(arr[arr.length-1]=='png'||arr[arr.length-1]=='jpg'||arr[arr.length-1]=='jpeg'||arr[arr.length-1]=='gif'||arr[arr.length-1]=='txt'||arr[arr.length-1]=='pdf')
        {
          window.open(item.link,'_blank')
        }
        else
        {
           window.location.href =item.link;
        }
      },1000*a );
    }
  }

  gotoTongzhi(){
    this.local.set('activityInvitationData', JSON.stringify(this.milk));
    this.local.set('activityInvitationDataType','milkround');
    this.router.navigateByUrl('/home/activityInvitation');// 通知学生到场
  }
  //-------获取线上数据
  getNewList(){
    let model={
      limit:100,
      offset:0,
      status:'release',
    }
    this.dataApi.getPositionList(model).then((res:any)=>{
      if(res) {
        this.config.positionList =  res.list;
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  //点击产品
  product(item,type){
    this.productModel.initData(item.alias,type);
    this.isproductShowModel = true;
  }

  productModelHidden(event){    
    this.isproductShowModel = false;
  }

  //新增高校
  pushCollege(){
    this.isShowPushCollegeModel = true;
    this.pushCollegeModel.initData();
  }

  pushCollegeModelHidden(event){
    this.isShowPushCollegeModel = false;
  }


  //获取合作高校
  mycollegeList(){
    this.dataApi.mycollegeList().then((res:any)=>{
      if(res){
        this.addCollegeList = res;        
      }
    }).catch((err)=>{
      console.log(err);
    })
  }

}
