<!-- 用户确认操作弹出框 -->
<div class="modal-dialog">
  <div class="modal-content">

    <div class="modal-body">
      <div class="modal-header flex_c" *ngIf="!type"> <img src="../../assets/images/hot_title.png" />{{title}}</div>
      <div class="modal-header2 flex_c" *ngIf="type =='member_trial'">{{title}}</div>
      <button class="closeModel" [mat-dialog-close]="true">x</button>
      <div class="centerBox">
        <div class="d_top">
          <img class="d_top_head flex_l" src="{{config.departmentSelf.avatar}}" />
          <img  class="d_top_img flex_l" src="../../assets/images/guwen_title.png"/>
          <span class="flex_l">{{config.departmentSelf.name}}</span>
        </div>
        <div class="d_cell flex_l" style="margin-top: 5px;">
          <img  src="../../assets/images/kefu_wx.png"/>微信：<span>{{config.departmentSelf.wx}}</span>
        </div>
        <div class="d_cell flex_l">
          <img src="../../assets/images/kefu_email.png" />邮箱：<span>{{config.departmentSelf.email}}</span>
        </div>
        <img class="qrcodeImage" src="{{config.departmentSelf.qr_code}}" />


      </div>

      <button class="centerBtn" [mat-dialog-close]="true">我知道了</button>
    </div>

  </div>
</div>
