<!-- 主体内容部分 -->
<div class="homepage-block">
    <div class="company-block">
        <!-- 组件 -->
        <!-- 认证信息提交部分 -->
        <router-outlet></router-outlet>
        <app-model-delivery-resume [isShow]="modelShow" (goAppropriate)="goAppropriate($event)" #deliveryResume></app-model-delivery-resume>
        <app-model-collection-resume [isShow]="modelShowStatus" #deliveryResume_like></app-model-collection-resume>
        <app-model-collection-appro [isShow]="ApproShow"></app-model-collection-appro>
        <app-model-collection-interview [isShow]="InterviewShow"></app-model-collection-interview>
    </div>
</div>