import { Component, OnInit, Input, Output  } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  public config: Config;
  public myDepartment:any;//当前登录的企业信息(department)
  public myUser:any;//当前登录的用户信息(user)
  public nowDate="";//当前年月日
  public nowWeek="";//当前星期
  public nowNoon="";//当前上中下午
  public weekday=["星期日","星期一","星期二","星期三","星期四","星期五","星期六"];
  public positionList = [];//在招职位列表
  public listCount = 0;//页面列表一共的数据------在招职位
  //筛选条件---在线
  public model ={
    limit:100,
    offset:0,
    status:'release',
   };
  public jobProgress={
    cooperation: {total: 0, today: 0, yesterday: 0, stauts: 0},
    delivery: {total: 0, today: 0, yesterday: 0, stauts: 0},
    dialogue: {total: 0, today: 0, yesterday: 0, stauts: 0},
    interview: {total: 0, today: 0, yesterday: 0, stauts: 0},
    milkround: {total: 0, today: 0, yesterday: 0, stauts: 0},
    campustalk:{total: 0, today: 0, yesterday: 0, stauts: 0}
  };//招聘进度

  public collegePart=[];//合作院校
  public backlogList=[];//待办事项
  public countCollege=0;//工作啦平台合作院校数量
  @Output() collegeIdentity:any;//高校认证信息
  @Output() modelShow = false;//是否打开弹窗
  @Output() collegeName = '';//高校名称
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
      this.getDepartment();//获取企业信息
      this.getUser();//获取hr信息
      this.getDate();//获取当前实时时间
      this.getPositionList();//获取职位列表数据
    }
    ngOnInit() {
      setTimeout(() => {
        this.config.isLogin=false;
      });

      this.router.navigate(['home/workSpase']);
    }

   //获取申请校招返回值
   Modeldismiss(event){
    this.opera.scrollTrue();
    if(event){
      //跳转认证页面
      this.local.set(Constants.collegeid,this.local.get(Constants.byCollege));
      this.router.navigateByUrl("/home/company-identity-details");
      this.modelShow = false;
    }else{
      this.modelShow = false;
    }
  }

  //提交校招申请
  goCollegeIdentity(){
    this.modelShow = true;//打开校招申请确认弹窗
    this.opera.scrollTop();//页面滚动函数
  }
    //获取高校列表--offset=0
  getCollegeList(){
    this.opera.loading();
    this.dataApi.getCollegeList({city:'',school_type:'',school_nature:'',labels:'',offset:0,limit:9}).then((res:any)=>{
      if(res) {
        this.countCollege=res.count;
      }
      this.opera.loadiss();
    }).catch((err)=>{
      //console.log(err);
      this.opera.loadiss();
    })
  }

    //合作院校
    getCollegePart(){
      this.opera.loading();
      this.dataApi.collegePart().then((res:any)=>{
        //console.log("合作院校列表");
        //console.log(res);
        if(res)
        {
          this.collegePart=res;
        }

        this.opera.loadiss();
      }).catch((err)=>{
        //console.log(err);
        this.opera.loadiss();
      })
    }

    //跳转页面
    goChild(data){
      this.router.navigateByUrl('/home/'+data);
    }

    //跳转会话页面
    goChat(val){
      //console.log("检查会话信息");
      //console.log(val);
      this.local.remove(Constants.chatPosition);//移除职位参数
      this.local.set(Constants.chatsInfo,JSON.stringify(val));//聊天记录本地存储参数
      this.router.navigate(['home/chat-contact']);
      // this.router.navigate(['home/chat-contact'], {
      //   queryParams: {
      //     is_read:'false',
      //   }
      // });
    }

    //发布职位
    goPublish(){
      this.local.remove(Constants.positionid);
      this.router.navigate(['home/position-publish']);
    }

    //判断当前是否存在会话列表
    checkChatList(){
      this.config.chatsList=this.dataApi.getLocalChatsList();//赋值会话列表本地存储
      let n=0;
      if(!this.config.chatsList){
        this.dataApi.getImDialogueAllList({}).then((res:any)=>{
          //console.log("获取会话列表--------opera");
          //console.log(res);
          if(res)
          {
            this.dataApi.setLocalChatsList(res);//会话列表本地存储
            for(let val of res.list){
              if(val.unread_count&&val.talent){
                // console.log(this.config.unReadNum);
                if(n>9){return;}
                this.backlogList.push(val);
                n++;
              }
            }
          }

          // console.log('待办事项-------');
          // console.log(this.backlogList);
        }).catch((error)=>{
          //console.log(error);
          this.toastr.error(error);
        });
      }else{
        for(let val of this.config.chatsList.list){
           if(val.unread_count&&val.talent){
            if(n>9){return;}
             this.backlogList.push(val);
             n++;
           }
        }
        // console.log('待办事项-------');
        // console.log(this.backlogList);
      }
      setTimeout(() => {
        this.config.unReadNum=n;
      });
    }

    //获取招聘进度
    getJobProgress(){
      this.opera.loading();
      this.dataApi.jobProgress().then((res:any)=>{
        //console.log("获取招聘进度数据-------");
        //console.log(res);
        this.opera.loadiss();

        if(res)
        {
          this.jobProgress=res;
        }

      }).catch((err)=>{
        //console.log(err);
        this.toastr.error(err);
        this.opera.loadiss();
      })
    }

    //获取当前时间并格式化
    getDate(){
      let date=new Date();
      let year=date.getFullYear(); //获取当前年份
      let month=date.getMonth()+1; //获取当前月份
      let da=date.getDate(); //获取当前日
      let week=date.getDay(); //获取当前星期几
      let h=date.getHours(); //获取小时
      let mon=''; //当前月份
      let day=''; //当前日
      if (month >= 1 && month <= 9) {
        mon = "0" + month;
      }else{
        mon = String(month);
      }
      if (da >= 1 && da <= 9) {
        day = "0" + da;
      }else{
        day = String(da);
      }
      this.nowDate=year+'-'+mon+'-'+day;
      this.nowWeek = this.weekday[week];
      if(h >= 5 && h < 12){
        this.nowNoon="上午";
      }else if(h >= 12 && h<= 14){
        this.nowNoon="中午";
      }else if(h >= 14 && h<= 19){
        this.nowNoon="下午";
      }else{
        this.nowNoon="晚上";
      }
      //console.log("获取时间-----homepage");
      //console.log(this.nowDate);
      //console.log(this.nowWeek);
      //console.log(h);
      //console.log(this.nowNoon);
    }

     //获取企业信息
     getDepartment(){
      if(this.dataApi.getLocalDepartment()){
        this.myDepartment = this.dataApi.getLocalDepartment();
        //console.log("企业信息本地存储-----homepage");
        //console.log(this.myDepartment);
      }else{
        this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
        //console.log("企业信息线上数据-----homepage");
        //console.log(res);
        if(res){
          this.myDepartment = res;
          this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
        }
      }).catch((error)=>{
        this.toastr.error(error);
      });
     }

    }

   //获取职位列表数据
   getPositionList(){
    //console.log("在期职位本地存储-----homepage");
    //console.log(this.dataApi.getLocalpositionList());
    if(this.dataApi.getLocalpositionList()){
      this.positionList = this.dataApi.getLocalpositionList();
      this.listCount = this.positionList.length;
    }else{
      this.getNewList();
    }
  }

  //-------获取线上数据
  getNewList(){
   this.dataApi.getPositionList(this.model).then((res:any)=>{
      //console.log("在期职位获取线上数据-----homepage");
      //console.log(res);
     if(res)
     {
       if(res.list.length<11){
         this.positionList =  res.list;
         this.listCount = res.count;
       }else{
         this.positionList = res.list.slice(0,10);
         this.listCount = 10;
       }
       this.dataApi.setLocalpositionList(res.list);
     }

    }).catch((error) => {
      this.toastr.error(error);
    });
  }

    //获取当前登录用户的信息(user信息)
    getUser(){
      this.dataApi.getUser().then((res:any)=>{//通过tooken获取企业信息
        if(res){
          this.myUser = res;
          //console.log("当前用户信息-----homepage");
          //console.log(res);
          this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
        }
      }).catch((error)=>{
        this.toastr.error(error);
      });
    }

}
