import { Component,EventEmitter, Input, Output } from '@angular/core';
import { Config,DataApi } from '../../provider/index';
@Component({
  selector: 'app-model-chat-position-first',
  templateUrl: './model-chat-position-first.component.html',
  styleUrls: ['./model-chat-position-first.component.css']
})
export class ModelChatPositionFirstComponent {
  public config: Config;
  @Input() isShow: false;//是否显示弹窗页面
  @Input() initValue: any;
  constructor(dataApi:DataApi) {
      this.config = Config.getInstance();//实例化config
      this.config.positionList=dataApi.getLocalpositionList();//赋值在期职位列表本地存储
  }
  @Output() onVoted = new EventEmitter();
  dismiss(result: any) {
     //console.log("传值检查");
     //console.log(result);
     this.onVoted.emit(result);
  } 

}
