<app-navigation-route #appNavigation></app-navigation-route>

<div class="chat_box">
    <div id="top" class="topView" *ngIf="resumeDetail">
        <div class="topView_cell1">
            <div class="name">
                <span class="topView_userName">{{UserInfo?.dialogue_user?.name}}</span>
                <img *ngIf="UserInfo?.dialogue_user?.gender=='01'" src="../../assets/images/mini_res/man.png" class="topView_userSex">
                <img *ngIf="UserInfo?.dialogue_user?.gender=='02'" src="../../assets/images/mini_res/woman.png" class="topView_userSex">
                <img *ngIf="UserInfo?.dialogue_user?.gender==''" src="../../assets/images/mini_res/man.png" class="topView_userSex">
            </div>
            <div class="School">
                <span class="topView_School">{{resumeDetail?.education_backgrounds[0]?.graduation_school+" | "}}</span>
                <span class="topView_School">{{resumeDetail?.education_backgrounds[0]?.major_status_text+" | "}}</span>
                <span class="topView_School">{{resumeDetail?.education_backgrounds[0]?.education_text}}</span>
            </div>
        </div>
        <div class="topView_cell2">
            <span class="topView_cell2_yixiang">求职意向：{{yixiang}}</span>
        </div>
    </div>

    <div class="chat-thread" #scrollMe (scroll)="contentScroll($event)">
        <div *ngFor="let msg of chatList;let i = index;">
            <div class="cell_time" *ngIf="msg.showTime&&getTopTime(i)">
                <span style="text-align: center;color: #B5B5B6;font-size:13px;padding: 0px 15px;height: 30px;line-height: 30px">{{getTopTime(i)}}</span>
            </div>
            <div class="cell" *ngIf="msg.from==userJmUsername&&msg.type=='text'">
                <div class="chat_l">
                    <img class="msg_head" *ngIf="UserInfo?.dialogue_user?.avatar" src="{{UserInfo?.dialogue_user?.avatar}}">
                    <img class="msg_head" *ngIf="!UserInfo?.dialogue_user?.avatar" src="/assets/images/chat_default.png">
                </div>
                <div class="chat_r">
                    <span class="msg_text">{{msg.content}}</span>
                </div>
            </div>
            <div class="cell_my" *ngIf="msg.from==department?.jm_username">
                <div class="chat_l_my">
                    <img class="msg_head" *ngIf="department?.logo" src="{{department?.logo}}">
                    <img class="msg_head" *ngIf="!department?.logo" src="/assets/images/chat_default.png">
                </div>
                <div class="chat_r_my">
                    <span class="msg_text_my">{{msg.content}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="chat-inputBG">
        <textarea placeholder="我想说点什么呢" (keyup)="inputKeyup($event)" [(ngModel)]="msgText" class="chat-input"></textarea>
        <button (click)="sengMsg()" class="sengBtn blueStreamer">发送</button>
    </div>

</div>