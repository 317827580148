<!-- 添加企业地址 -->
<!-- 遮罩层公共样式 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px','width':'700px'}" [(visible)]="isShow" (onHide)="dismiss(false)" [modal]=true>
    <div class="model-cont">
        <div class="clos" (click)="dismiss(false)"><img src="/assets/images/rights/contactMeClos.png"></div>
        <div class="model-head">
            <div class="left">{{title}}</div>
        </div>
        <div class="titles">
            <div class="item">省份</div>
            <div class="item">城市</div>
            <div class="item">县区</div>
        </div>
        <div class="address-select">
            <select class="activeBg" name="city-milk" autocomplete="off" [(ngModel)]="cityOne" (ngModelChange)="cityOneChange()">
                <option class="place-text" value='' disabled hidden selected="selected">选择省</option>
                <option *ngFor="let city of listOne" [value]="city.code">{{city.text}}</option>
            </select>
            <select class="activeBg" name="city-milk" autocomplete="off" [(ngModel)]="cityTwo" (click)="isShowTwo()" (ngModelChange)="cityTwoChange()">
                <option class="place-text" value='' disabled hidden selected="selected">选择市</option>
                <option *ngFor="let city of listTwo" [value]="city.code">{{city.text}}</option>
            </select>
            <select class="activeBg" name="city-milk" autocomplete="off" [(ngModel)]="locations.region" (click)="isShowThree()" (ngModelChange)="cityThreeChange()">
                <option class="place-text" value='' disabled hidden selected="selected">选择县/区</option>
                <option *ngFor="let city of listThree" [value]="city.code">{{city.text}}</option>
            </select>
        </div>
        <div class="address-input">
            <div class="title">详细地址</div>
            <input type="text" [(ngModel)]="locations.address" placeholder="请填写公司详细地址">
        </div>
        <div class="submit blueStreamer" (click)="employerUpdatePositionInfo()">保存</div>
    </div>
</p-dialog>