import { Component, OnInit } from '@angular/core';
import {Config, DataApi, Local} from "../../provider";
import {Router} from "@angular/router";

@Component({
  selector: 'app-college-milk-day',
  templateUrl: './college-milk-day.component.html',
  styleUrls: ['./college-milk-day.component.css']
})
export class CollegeMilkDayComponent implements OnInit {

  public config: Config;

  public newlist = [];
  public hotlist = [];
  constructor(
    public dataApi: DataApi,
    public local:Local,
    private router: Router,

  ) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.getMilkList();
  }
  // 每日推荐
  getMilkList(){
    let localDepartment =  this.local.get('department')
    if(!localDepartment){
      return
    }
    let department = JSON.parse(localDepartment);
    console.log('localDepartment',department);
    let data = {
      date:'20210716',
      departmentid:department.accountid,
    };
    this.dataApi.getMilkroundDay(data).then((res:any)=>{
      console.log('getMilkroundDay',res);
      if(res){
        this.newlist = res.newlist;
        this.hotlist = res.hotlist;
      }
    }).catch((error) => {
    });
  }

  //去双选会详情
  goDeatils(item){
    let that = this;
    if(item.milkround_type == 2){
      that.router.navigate(['home/college-milkdetails'],{
        queryParams:{
          id:item.mrid,
          isAir:true
        }
      })
    }else{
      that.router.navigate(['home/college-milkdetails'],{
        queryParams:{
          id:item.mrid
        }
      })
    }

  }

}
