<!-- 引导页面-->
<div class="guide-page">
  <!-- 头部 -->
  <div class="guide-head">
    <ul>
      <li>
        <span><img src="assets/images/guide-ok.png"></span>
        <br/>招聘信息
      </li>
      <li>
        <span><img src="assets/images/guide-ok.png"></span>
        <br/>完善信息
      </li>
      <li>
        <!-- <span><img src="assets/images/guide-ok.png"></span> -->
        <span class="isHeadActive">3</span>
        <br/>上传证件
      </li>
      <li>
        <span>4</span>
        <br/>等待审核
      </li>
    </ul>
  </div>
  <!-- 中间部分 -->
  <div class="info-block">
    <!-- 中间部分 -->
    <!-- <div *ngIf="collegeName" class="identityCollege-name">当前认证高校：{{collegeName}}</div>  -->
    <div *ngIf="collegeName&&orgList.length==0" class="identityCollege-name">当前申请高校：{{collegeName}}</div>
    <div *ngIf="orgList.length>0" class="identityCollege-name">当前申请院系：
      <select name="CIOrg" id="CIOrg" [(ngModel)]="model.facultyid" autocomplete="off">
        <option class="place-text" value='' disabled selected="selected">请选择院系</option>
        <option *ngFor="let a of orgList" [value]="a.facultyid">{{a.name}}</option>
      </select>
    </div>
    <!-- 营业执照 -->
    <div class="info-list info-logo">
      <div class="info-head">第一步&nbsp;&nbsp;营业执照</div>
      <label *ngIf="model.business_licence" class="uploadImg-label department-logo" for="uploadLoginAvatar">
        <img [src]="model.business_licence">
      </label>
      <label *ngIf="!model.business_licence" class="uploadImg-label" for="uploadLoginAvatar">
        <img src="assets/images/add-img.png"><br/>点击上传&nbsp;&nbsp;营业执照
      </label>
      <input class="uploadImg" id="uploadLoginAvatar" (change)="previewPic($event,'business_licence')" type="file" accept="image/jpeg,image/jpg,image/png">
      <ul>
        <li>1. 上传营业执照名称必须与公司全称保持一致</li>
        <li>2. 请提供有效年检期内的《企业法人营业执照》副本</li>
        <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
        <li>4. 工作啦客服将会在 1 个工作日内审核</li>
      </ul>
    </div>
    <!-- 营业执照 -->
    <div class="info-list info-logo">
      <div class="info-head">第二步&nbsp;&nbsp;招聘者身份证</div>
      <label *ngIf="model.id_card_front" class="uploadImg-label department-logo" for="uploadGuideCard">
        <img [src]="model.id_card_front">
      </label>
      <label *ngIf="!model.id_card_front" class="uploadImg-label" for="uploadGuideCard">
        <img src="assets/images/add-img.png"><br/>点击上传手持身份证
      </label>
      <input class="uploadImg" id="uploadGuideCard" (change)="previewPic($event,'id_card_front')" type="file" accept="image/jpeg,image/jpg,image/png">
      <ul>
        <li>1. 上传身份证姓名必须与招聘联系人姓名保持一致</li>
        <li>2. 请提供有效期内的本人手持《身份证》照片</li>
        <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
        <li>4. 工作啦客服将会在 1 个工作日内审核</li>
      </ul>
    </div>
    <!-- 招聘委托书 -->
    <div *ngIf="secureMaterail" class="info-list info-logo">
      <div class="info-head">第三步&nbsp;&nbsp;招聘委托书</div>
      <label *ngIf="model.proxy_document" class="uploadImg-label department-logo" for="uploadProxy">
        <img [src]="model.proxy_document">
      </label>
      <label *ngIf="!model.proxy_document" class="uploadImg-label" for="uploadProxy">
        <img src="assets/images/add-img.png"><br/>点击上传招聘委托书
      </label>
      <input class="uploadImg" id="uploadProxy" (change)="previewPic($event,'proxy_document')" type="file" accept="image/jpeg,image/jpg,image/png">
      <ul>
        <li>1. 点击下载：<a [href]="secureMaterail">《招聘委托书示例》</a></li>
        <li>2. 修改后请加盖公章，截图或拍照上传</li>
      </ul>
    </div>
    <!-- 统一社会信用代码 -->
    <div class="info-list info-number">
      <div *ngIf="!secureMaterail" class="info-head">第三步&nbsp;&nbsp;统一社会信用代码</div>
      <div *ngIf="secureMaterail" class="info-head">第四步&nbsp;&nbsp;统一社会信用代码</div>
      <input type="text" placeholder="请填写营业执照统一社会信用代码" [(ngModel)]="model.register_number" maxlength="18">
      <ul>
        <li>1. 填写的统一社会信用代码必须与营业执照保持一致</li>
        <li>2. 工作啦客服将会在 1 个工作日内审核</li>
      </ul>
    </div>
    <!-- 按钮 -->
    <div class="block-button">
      <button (click)="uploadDepartmentIdentity()">提交</button>
    </div>
  </div>
  <!-- 尾部 -->
  <div class="guide-foot"><span>客服热线：{{config.CustomerServicePhone}}</span>
    &nbsp;&nbsp;&nbsp;&nbsp;{{config.websiteRecord}}</div>
</div>
