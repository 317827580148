<!-- 页头部分 -->
<!-- <div class="contentRight-header">人才 - 对我感兴趣</div> -->
<app-model-send-msg #sengMsgModel [SengMsgModel]='SengMsgModel' [tconfig]='config' (onVoted)="SendMsgdismiss($event)" [isShow]="modelShowSengMsg"></app-model-send-msg>
<app-voice-call-model #voiceCallModel [isShow]="isShowVoiceCallModel" (modelHidden)="voiceCallModelHidden($event)">
</app-voice-call-model>

<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <div class="con-l">
        <div class="secondNav">
            <div class="item " (click)="goRecommend()">推荐人才</div>
            <div class="item active">对我感兴趣<span></span></div>
        </div>
        <div class="resume-block" style="background-color: transparent">
            <!-- 操作部分 <img src="assets/images/select-img.png">-->
            <div class="college-block-opera clear">
                <div class="left flex_l" style="width:100%;">
                    <select style="width:160px;" class="downLoad_right" name="position" id="position" [(ngModel)]="model.positionid" (ngModelChange)="getPositionViewHistory()" autocomplete="off">
                        <option value='' selected="selected">职位不限</option>
                        <option *ngFor="let a of positionList" [value]="a.positionid">{{a.name}}</option>
                    </select>
                </div>
            </div>

            <div class="sel-res">
                <img src="../../assets/images/positionPushs_icon.png" />
                <div class="num">
                    <img src="../../assets/images/positionpush_title.png" />
                    <div>可<span style="color: #F4B517">批量</span>给对您职位感兴趣的求职者发送职位投递邀请</div>
                </div>
                <div class="push blueStreamer" (click)="showPositionPushModelClick()">立即推送</div>
            </div>

            <!-- 没有内容存在时 -->
            <div class="none-block-empty" *ngIf="countList == 0">
                <img src="assets/images/rights/empty.png">
                <p>暂未找到对我感兴趣的简历</p>
            </div>

            <div *ngIf="countList>0" class="college-list-cont">
                <div class="resume-list">
                    <!-- 没有内容存在时 -->
                    <div *ngIf="resumeList.length==0&&!config.department.is_certified" class="none-block">
                        <img src="assets/images/resume-search-fail.png"><br /> 可查看10个意向学生，完成
                        <span class="none-btn" (click)="opera.goplatformIdentity()">企业认证</span>查看全部
                    </div>
                    <div class="resume-list-item" *ngFor="let n of resumeList;let i = index;" (click)="goDetails(n,i)">

                        <div class="main">
                            <div class="introduce">
                                <div class="logo"><img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/55/h/55'}}"></div>
                                <div class="text">
                                    <div class="name">
                                        {{n.talent_name | clearSpace}}
                                        <span *ngIf="n.isview_resume">已查看</span>
                                        <span *ngIf="n.contacted">已电话联系</span>
                                    </div>
                                    <div class="college" *ngIf='n.school'>{{n.school}}</div>
                                    <div class="major">{{n.major_text}} | {{n.education_text}} | {{n.year==0?"往届":n.year+'届'}}</div>
                                </div>
                            </div>
                            <div class="intention">
                                <span>求职意向：</span>{{n.position_name}}
                            </div>
                            <div class="bottom">
                                <div class="time"><img src="/assets/images/rights/time.png">查看时间:{{n.add_time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
                            </div>
                            <div class="rightBtn">
                                <div class="item active" *ngIf='n.ignore==false' (click)="ShowPhoneModel(n,$event)"><img src="/assets/images/v3/speedIcon.png">极速电话联系</div>
                                <div class="item" *ngIf='n.ignore==false' (click)="alertSendMsg(n,$event)" title="可以将职位定向推送给该学生"><img src="/assets/images/v3/pushIcon.png">职位推送</div>
                            </div>
                        </div>
                    </div>


                    <div class="listCell" *ngFor="let n of resumeList;let i = index;" style="display: none;">
                        <img *ngIf="n.isview_resume" style="width: 56px;height: 56px;position: absolute" src="../../assets/images/yichakan.png">

                        <div class="listCellContent" (click)="goDetails(n,i)">
                            <div style="width: 35%;height: 100px;">
                                <div style="width: 100%;height: 65px;overflow:hidden;margin-left: 15px">
                                    <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}" class="headIcon">
                                    <img *ngIf="!n.logo&&(n.gender=='01')" class="headIcon" src="assets/images/male.png">
                                    <img *ngIf="!n.logo&&(n.gender=='02')" class="headIcon" src="assets/images/female.png">
                                    <img *ngIf="!n.logo&&!n.gender" class="headIcon" src="assets/images/male.png">
                                    <span style="color: black;margin-left: 15px;line-height:25px;font-size: 17px;font-weight:bold;height: 25px;margin-top: 10px;margin-right: 15px;position: absolute">{{n.talent_name
                                        | clearSpace}}
                                        <span *ngIf="n.isview_resume" style="color: #999999;font-size: 11px">已查看</span>
                                    <span class="phoneLabel" style="font-weight: normal;" *ngIf="n.contacted">已电话联系</span>
                                    </span>
                                    <div style="color: gray;margin-left: 76px;margin-top: -18px;margin-right: 15px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-size: 12px">
                                        {{'求职意向：'+n.position_name}}</div>
                                </div>
                                <div style="width: 100%;margin: 10px 15px 10px;">
                                    <span *ngFor="let youshi of n.skills | slice:0:4;let i = index; " class="biaoqian">{{youshi.text}}</span>
                                </div>
                            </div>
                            <div style="width: 20%;height: 100px;background-color: white;display: flex;flex-direction: column;justify-content: center">
                                <div><img *ngIf='n.school' src="assets/images/resume_icon1.png" style="width:14px;height: 14px"><span style="font-size:13px;height:20px;margin-left: 5px">{{n.school}}</span></div>
                                <div style="margin: 5px 0"><img *ngIf='n.major_text' src="assets/images/resume_icon2.png" style="width:14px;height: 14px;"><span style="margin-left: 5px;font-size:13px;height:20px;">{{n.major_text}}</span>
                                </div>
                                <div class="jiebieBottom"><img *ngIf='n.education_text' src="assets/images/resume_icon3.png" style="width:14px;height: 14px;"><span style="margin-left: 5px;font-size:13px;height:20px;">{{n.education_text}}</span>
                                </div>
                                <div class="jiebieBottom"><img src="../../assets/images/jiebieIcon.png" style="width:14px;height: 14px;"><span style="margin-left: 5px;font-size:13px;height:20px;">{{n.year==0?"往届":n.year+'届'}}</span>
                                </div>
                            </div>
                            <div style="width: 15%;font-size: 13px;color: #666666">{{n.add_time | date:'yyyy-MM-dd HH:mm:ss'}}
                            </div>
                            <div style="width: 30%;height: 100px;float: left; display: flex; flex-direction: row;align-items: center;justify-content:center;margin-right: 16px">
                                <div *ngIf='n.ignore==false' class="act-btn purple" (click)="ShowPhoneModel(n,$event)">
                                    <img src="../../assets/images/talent/phone.png" />极速电话联系</div>
                                <div *ngIf='n.ignore==false' class="act-btn yellow" (click)="alertSendMsg(n,$event)">
                                    <img src="../../assets/images/talent/pulish.png" />职位推送</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- 分页部分 -->
            <div class='paging-block' [class.isPageHeight]="countList>0">
                <router-outlet></router-outlet>
                <select class="paging-block-filtrate_row_select_gray" placeholder="请选择" [(ngModel)]="eachPageCount" (ngModelChange)="getpageCount($event)" *ngIf="countList>0">
                    <option value="10">10条/页</option>
                    <option value="20">20条/页</option>
                    <option value="50">50条/页</option>
                </select>
                <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)">
                </app-paging>
            </div>
        </div>
    </div>
    <!-- <div class="con-r" *ngIf="opera.isShowPaySuccessModule()">
        <div class="con-r-box">
            <div class="con-r-l">
                <img src="../../assets/images/talent/my_phone.png">
                <div class="info">
                    <div class="top">我的电话联系</div>
                    <div class="bot">余量(分钟):{{voice_call?voice_call.left:0}}</div>
                </div>
            </div>
            <div (click)="product('','contactMe')" class="con-r-r">立即购买</div>
        </div>
        <div class="con-r-box">
            <div class="con-r-l">
                <img src="../../assets/images/talent/my_pulish.png">
                <div class="info">
                    <div class="top">我的职位推送</div>
                    <div class="bot">余量(人次):{{sms_bulkinvitation?sms_bulkinvitation.left:0}}</div>
                </div>
            </div>
            <div (click)="product('','contactMe')" class="con-r-r">立即购买</div>
        </div>
    </div> -->
</div>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'borderRadius': '10px'}" [(visible)]="isShowPositionPushModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]=true>
    <div class="localPostion positionPushsMain">
        <div class="center">
            <div class="iocn">
                <img src="/assets/images/v3/pushsIcon.png">
                <div class="right">
                    <div class="name">批量职位推送</div>
                    <div class="introduce">精准触达，揽获更多精准投递</div>
                </div>
            </div>
            <div class="module">
                <div class="left">日期范围</div>
                <div class="right">
                    <div class="val fn">
                        <input class="dataSeleteInput" type="text" id="start-time" [(ngModel)]="positionPushModel.startTime" (ngModelChange)="pushSeletePositionChange()" />
                        <input class="dataSeleteInput active" type="text" id="end-time" [(ngModel)]="positionPushModel.entTime" (ngModelChange)="pushSeletePositionChange()" />
                    </div>
                </div>
            </div>
            <div class="module">
                <div class="left">推送职位</div>
                <div class="right">
                    <div class="val">
                        <select class="activeBg" name="position" [(ngModel)]="positionPushModel.positionid" (ngModelChange)="pushSeletePositionChange()" autocomplete="off">
                            <option value="" readonly="">请选择邀投职位</option>
                            <option *ngFor="let a of positionList" [value]="a.positionid">{{a.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="module">
                <div class="left">招聘电话</div>
                <div class="right">
                    <div class="val">
                        <input type="text" class="phoneText" [(ngModel)]="positionPushModel.phone" (ngModelChange)="changeinput($event)" placeholder="请输入HR招聘电话" />
                    </div>
                </div>
            </div>
            <!--企业卡片-->
            <div class="module" *ngIf="positionPushModel.seletePosition">
                <div class="left"></div>
                <div class="cardContainer">
                    <div class="__top">
                        <div>
                            <div class="__name">{{positionPushModel.seletePosition.name}}</div>
                            <div class="text">{{positionPushModel.seletePosition.work_place_text}}｜{{positionPushModel.seletePosition.post_nature_text}}</div>
                        </div>
                        <div class="salary">{{positionPushModel.seletePosition.position_salary_text}}</div>
                    </div>
                    <div class="lables">
                        <span *ngFor="let n of positionPushModel.department.employer_strengths;let i = index;">{{n.text}}</span>
                    </div>
                    <div class="bottomBox">
                        <div class="leftBox">
                            <img class="logo" [src]='positionPushModel.department.logo'>
                            <div class="cominfoName">{{positionPushModel.department.name}}</div>
                        </div>
                        <div class="rightText">
                            <span>{{positionPushModel.department.employer_type_text}} | </span>
                            <span>{{positionPushModel.department.scale_text}} | </span>
                            <span>{{positionPushModel.department.industry_text}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="module">
                <div class="left"></div>
                <div class="right">
                    <div class="_number">
                        <div class="accordWidth">
                            <div class="_container">
                                <div class="_name">符合以上条件的学生数</div>
                                <div class="numb"><span>{{positionPushModel.fuhecount}}</span>人</div>
                            </div>
                        </div>
                        <div class="thisTime">
                            <div class="_name">本次推送通知的学生数</div>
                            <div class="_val">
                                <input type="number" [(ngModel)]='positionPushModel.invalCount' min="0" />
                                <span>人次</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--通知内容-->
            <div class="module">
                <div class="left"></div>
                <div class="notice">
                    <div class="content">
                        <div class="_title">通知内容</div>
                        <div class="contentText">{{positionPushModel.content}}</div>
                        <img src="/assets/images/v3/contentText.png">
                    </div>
                    <div class="pushBtn blueStreamer" (click)="pushSms()">立即推送</div>
                    <div class="p">需消耗资源数<span>{{positionPushModel.invalCount}}</span>，(当前剩余资源<span>{{positionPushModel.allCount}}</span>)</div>

                </div>
            </div>

            <div class="module">
                <div class="left"></div>
                <div class="p2">
                    发送时，系统将为您屏蔽“已投递及14天内已推送或其他特殊情况无法推送的学生”。发送任务执行完毕，系统将会在三个工作日内将未推送成功的相应资源数原路退还。
                </div>
            </div>

        </div>


        <div class="localPostion_bottom" style="display:none">
            <img class="aPush_title" src="/assets/images/positionpush_title.png" />
            <div class="aPushRow flex_s" style="margin-top: 34px">
                <div>
                    <span class="aPushRow_title">开始时间：</span><input class="dataSeleteInput" type="text" id="start-time" [(ngModel)]="positionPushModel.startTime" (ngModelChange)="pushSeletePositionChange()" />
                </div>
                <div>
                    <span class="aPushRow_title">结束时间：</span><input class="dataSeleteInput" type="text" id="end-time" [(ngModel)]="positionPushModel.entTime" (ngModelChange)="pushSeletePositionChange()" />
                </div>
            </div>
            <div class="dataChips"><span>*</span>给选择日期范围内查看您职位的学生发送</div>
            <div class="aPushRow flex_l" style="margin-top: 18px">
                <span class="aPushRow_title">推送职位：</span>
                <select class="apush_seletePosition downLoad_right" name="position" [(ngModel)]="positionPushModel.positionid" (ngModelChange)="pushSeletePositionChange()" autocomplete="off">
                    <option *ngFor="let a of positionList" [value]="a.positionid">{{a.name}}</option>
                </select>
            </div>
            <div class="aPushRow flex_l" style="margin-top: 18px">
                <span class="aPushRow_title">招聘电话：</span>
                <input type="text" class="phoneText" [(ngModel)]="positionPushModel.phone" (ngModelChange)="changeinput($event)" placeholder="请输入HR招聘电话" />
            </div>
            <div class="push_positionBox" *ngIf="positionPushModel.seletePosition">
                <div class="push_position_name">
                    {{positionPushModel.seletePosition.name}} <span class="push_position_price">{{positionPushModel.seletePosition.position_salary_text}}</span>
                </div>
                <div style="margin-top: 4px" class="flex_l">
                    <div class="push_position_col">
                        {{positionPushModel.seletePosition.work_place_text}}｜{{positionPushModel.seletePosition.post_nature_text}}
                    </div>
                    <div class="fuli flex_l" *ngIf="positionPushModel.department">
                        <div *ngFor="let n of positionPushModel.department.employer_strengths;let i = index;">{{n.text}}
                        </div>
                    </div>
                </div>
                <div class="position_line"></div>
                <div *ngIf="positionPushModel.department">
                    <img class="companyLogo" src="{{positionPushModel.department.logo}}" />
                    <div class="companyName">{{positionPushModel.department.name}}</div>
                    <div class="companyLabel">{{positionPushModel.department.employer_type_text}} &nbsp;|&nbsp; {{positionPushModel.department.scale_text}}&nbsp;|&nbsp; {{positionPushModel.department.industry_text}}
                    </div>
                </div>
            </div>

            <div class="fuheNum flex_l">符合以上条件的学生数：<span>{{positionPushModel.fuhecount}}人</span></div>
            <div class="studentCountRow flex_l">确定你要通知的学生数：
                <div class="deleteBtn" (click)="invalCountClick('delete')">—</div>
                <input type="number" [(ngModel)]="positionPushModel.invalCount" class="countInput" />
                <div class="deleteBtn" (click)="invalCountClick('addd')">+</div>
            </div>
            <div class="push_smsContent">
                {{positionPushModel.content}}
            </div>
            <div class="push_Btn flex_r">
                <span>预消耗<span class="allCount" style="color: #FF3C3C">{{positionPushModel.invalCount}}</span>资源（剩余资源
                <span class="allCount" style="color: #FF3C3C">{{positionPushModel.allCount}}</span>）</span>
                <div (click)="pushSms()">立即推送</div>
            </div>
            <div class="push_chips_bottom">
                发送时，系统将为您屏蔽“已投递及14天内已推送或其他特殊情况无法推送的学生”。发送任务执行完毕，系统将会在三个工作日内将未推送成功的相应资源数原路退还。
            </div>

        </div>
    </div>
</p-dialog>


<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)">
</app-product-model>