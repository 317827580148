<div style="background-color:transparent" class="college-block">

    <app-voice-call-model #voiceCallModel [isShow]="isShowVoiceCallModel" (modelHidden)="voiceCallModelHidden($event)"></app-voice-call-model>
    <app-model-send-msg #sengMsgModel [SengMsgModel]='SengMsgModel' [tconfig]='config' (onVoted)="Modeldismiss($event)" [isShow]="isShowPositionPushModel"></app-model-send-msg>

    <!-- 新版本 -->
    <div class="resume-container">
        <!-- 头部 -->
        <div class="header">
            <div class="secondNav">
                <div class="item " (click)="nav01()">收到的</div>
                <div class="item " (click)="nav02()">收藏的</div>
                <div class="item active">极速电话联系记录<span></span></div>
                <div class="item" (click)="nav04()">职位推送记录</div>
                <div class="item" (click)="nav05()">校招活动邀请记录</div>
                <div class="item" (click)="nav06()">空中宣讲会推广记录</div>
            </div>

            <div class="banner" style="margin-bottom:0">
                <div class="layui-carousel" id="test1" *ngIf="bannerList.length>0">
                    <div carousel-item>
                        <div *ngFor="let n of bannerList;let i = index;">
                            <img class="swiper-slide bannerImage" (click)="bannerClick(n)" id={{i}} src="{{n.image_url}}" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 非会员 -->
            <div class="novipTip" *ngIf="!isShowMemberMoudel">
                <div class="text"> 非会员收到的简历数量有限，加入会员，接收更多人才简历</div>
                <div class="join joinHover" (click)="product('','contactMe')">加入会员</div>
            </div>
            <div class="search">
                <select class="filtrate_row_select activeBg" [(ngModel)]="model.positionid" (ngModelChange)="getRecommendList()">
                <option value="">全部招聘职位</option>
                <option *ngFor="let a of positionList" [value]="a.positionid">{{a.name}} <span *ngIf="a.position_status == 'delete'">(已删除)</span></option>
              </select>
            </div>
        </div>
        <div class="resume-list" *ngIf="countList>0">

            <div class="listCell" *ngFor="let n of resumeList;let i = index;" id={{i}}>
                <div class="cellTime">{{n.date| date:'yyyy-MM-dd'}}</div>
                <div class="itemBox">
                    <div class="resume-list-item" *ngFor="let item of n.list;" (click)="gotoDetail(item)">
                        <div class="rightBtn">
                            <div class="item active" (click)="voiceCallClick($event,item)"><img src="/assets/images/v3/speedIcon.png">极速电话联系</div>
                            <div class="item" (click)="positionPushClick($event,item)" title="可以将职位定向推送给该学生"><img src="/assets/images/v3/pushIcon.png">职位推送</div>
                        </div>
                        <div class="main">
                            <div class="introduce">
                                <div class="logo"><img *ngIf="item.logo" [src]="item.logo+'&imageView2/1/w/55/h/55'"></div>
                                <div class="text">
                                    <div class="name">{{item.talent_name}}<span *ngIf="item.new_talent">新用户</span></div>
                                    <div class="college" *ngIf='item.graduate_university'>{{item.graduate_university}}</div>
                                    <div class="major">{{item.major_text}} | {{item.education_text}} | {{item.year==0?"往届":item.year+'届'}}</div>
                                </div>
                            </div>
                            <div class="lable">
                                <span *ngFor="let youshi of item.skills | slice:0:4;let i = index;">{{youshi.text}}</span>
                            </div>
                            <div class="intention">
                                <span>求职意向：</span>{{item.yixiang}}
                            </div>
                            <div class="bottom">
                                <div class="bottom-item"><img src="/assets/images/rights/positionicon.png">沟通职位：<span>{{item.call_record.position_name}}</span></div>
                            </div>
                            <div class="bottom">
                                <div class="bottom-item"><img src="/assets/images/rights/time.png">最近通话：{{item.call_record.start_time |date:'yyyy-MM-dd HH:mm:ss'}}</div>
                                <div class="bottom-item" *ngIf="item.call_record.minute>0">通话时长：<span>{{item.call_record.minute}}</span>分钟</div>
                                <div class="bottom-item notconnected" *ngIf="item.call_record.minute == 0"><img src="/assets/images/rights/notconnected.png">未接通</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 空状态 -->
        <div class="none-block-empty" *ngIf="countList == 0">
            <img src="assets/images/rights/empty.png">
            <p>暂未找到记录</p>
        </div>

        <!-- 分页部分 -->
        <div class='paging-block' [class.isPageHeight]="countList>9">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>

    </div>


</div>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>