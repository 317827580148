import {Component, OnInit, Output, ViewChild} from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate } from '../../provider/index';
import {Router} from "@angular/router";
import {PayHistoryComponent} from "../pay-history/pay-history.component";
import {PayInvoiceComponent} from "../pay-invoice/pay-invoice.component";
import {ToastrService} from "ngx-toastr";
import {productManager} from "../../provider/productManager";
import {ModelPayWechatComponent} from "../model-pay-wechat/model-pay-wechat.component";
import {regexManager} from "../../provider/regexManager";
import {DepartmentSelfAlertComponent} from "../department-self-alert/department-self-alert.component";
import { MatDialog } from "@angular/material/dialog";
import {ModelPayCodeComponent} from "../model-pay-code/model-pay-code.component";
import {NoneVipAlertComponent} from "../none-vip-alert/none-vip-alert.component";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";

declare let Swiper:any;
declare var layer;
declare var layui;
declare let window;
declare let pingpp;
@Component({
  selector: 'app-campus-tool',
  templateUrl: './campus-tool.component.html',
  styleUrls: ['./campus-tool.component.css']
})
export class CampusToolComponent implements OnInit {
   //是否打开产品介绍/请校招顾问联系我 弹窗
   @Output() isproductShowModel = false;
   @ViewChild('productModel', { static: true }) 
   productModel: ProductModelComponent;

  public config: Config;
  mySwiper;
  constructor(
    public opera: Opera,
    private router: Router,
    public dataApi: DataApi,
    public local: Local,
    public toastr: ToastrService,
    public productManager: productManager,
    public regexManager:regexManager,
    public dialog: MatDialog,

  ) {
    this.config = Config.getInstance();//实例化config
    this.productManager.init();

  }

  public aboutLaCoinAlert = false; //关于啦豆的弹窗

  public MyLaCoinCount = 0; //我的啦豆余额
  public productList = []; //单品列表

  public productInfo: any; //会员Model

  //是否显示支付宝/微信购买
  public isShowPayType = false;
  @Output() modelShow = false;//是否打开弹窗

  public secureMaterail: any;//保险行业认证材料

  //是否是保险省以下的，如果是，弹窗校招顾问，屏蔽购买
  public  province_sign = "unmark";

  // pay_V2

  //是否打开会员权益图
  public isOpenMemberImage = false;
  //企业信息
  public myDepartment:any;
  //是否显示 会员cell
  public isShowMember_info = false;

  //是否购买会员
  public isPayMember = false;

  public alert_OrderAlertInfoList = [];//费用明细弹窗

  //会员快过期的天数
  public memberOutCount = 365;

  // 啦豆冻结数据模型
  public ladouForzen = {
    ladou:0,
    order:0,
  }

  // 单品 加减 逻辑 连续点击三次提示频繁点击，并3秒后解除点击屏蔽
  public timer = null;
  public clickIndex = 0;
  public isClick = true;

  public isLoading = false;

  public isShowbottom = false;

  public isShowXuFeiStr = "";

  public isMember_trial = false

  // 0 没有会员/公益会员/体验会员/会员过期
  // 1 会员快过期
  // 3 是否是外部 没有会员跳转过来
  // 4 正常进入校招工具，没有会员
  public noneVipUserLevel = 5

  //是否展示联系校招顾问成功弹窗
  public isShowConnetSuccess = false

  public isShowConnetSuccessStep = 1

  //会员九大权益
  public nineList = [];
  // 五大神器
  public fiveList = [];

  ngOnInit() {
    console.log('this.config.departmentSelf',this.config.departmentSelf);
    this.opera.initConfig('tool', 'tool-campus');

    this.opera._hmtPush("校招工具","加载校招工具页面 ",'');

    //获取产品列表
    this.getProductList();

    //获取我的啦豆余额
    this.getMyLaCoinCount();

    //没有付费没有认证的接口逻辑
    this.getProductInfo();

    this.getDepartment();

    //获取平台认证信息并赋值
    this.getApplyPlatformDetails();

    setTimeout(()=>{
      window.scrollTo(0,0);
      document.body.scrollTop=0;
    },800)

    //获取啦豆冻结
    this.getOrderladouForzen();

    this.checkMemberAlertWithTypeCustom();

    //九大权益
    this.setNineTool();
  }
  //获取冻结啦豆
  getOrderladouForzen(){
    this.dataApi.getOrderladouForzen().then((res:any)=>{
      this.ladouForzen.ladou = res.value;
    }).catch((error) => {
      //this.toastr.error(error);
    });
  }

  getDepartment(){
    this.dataApi.getEmployment().then((res:any)=>{
      if(res) {
        this.province_sign =  res.province_sign;
        this.myDepartment = res;
        console.log('获取企业信息',res)
        if(!this.myDepartment.member_info) {
          this.noneVipUserLevel = 0
          this.isShowMember_info = true;
          this.isPayMember = true;
          this.checkShowBottom();
        }
        else {
          let nowData = new Date();
          //活动截止时间
          let overData = new Date(res.member_info.endtime);
          if(this.myDepartment.member_info.product_code == "member_trial") {
            this.noneVipUserLevel = 0
            this.isShowMember_info = true;
            this.isPayMember = true;
            this.checkShowBottom();
            this.isMember_trial = true
            if (nowData.getTime() > overData.getTime()) {
              this.memberOutCount = 0;
            }
            return
          }
          if(this.myDepartment.member_info.product_code == "member_welfare") {
            this.noneVipUserLevel = 0
            return
          }

            // let overData = new Date('2019-12-29 23:59:59');
          console.log('overData', overData)
          //60天到期提醒
          let spaceBe = this.config.memberEndTime * 24 * 60 * 60 * 1000;
          if ((overData.getTime() - nowData.getTime()) > spaceBe) {
            console.log('会员未到期');
            if(this.config.payVipHistoryPage){
              this.noneVipUserLevel = 3
            } else{
              this.noneVipUserLevel = 4
            }

          } else if (nowData.getTime() > overData.getTime()) {
            console.log('会员已经到期了ooo');
            this.memberOutCount = 0;
            this.isPayMember = true;
            this.checkShowBottom();
            this.noneVipUserLevel = 0

            this.dataApi.checkMemberCheckV2().then((res:any)=> {
              // console.log('会员快到期了,但是有新的会员',res);
              if(!res.has_member_order) {
                this.isShowMember_info = true;
                this.noneVipUserLevel = 0
              }
              else {
                // console.log('会员已经到期了2222');
                //会员过期了
                this.noneVipUserLevel = 0
                this.memberOutCount = 365;
                let data1 = new Date(res.scheduled_execution_time)
                let data = new Date(res.scheduled_execution_time)
                data.setFullYear(data.getFullYear()+1) ;
                let getMonth1 = data1.getMonth()+1+"";
                if(data1.getMonth()+1<10) {
                  getMonth1= "0"+getMonth1;
                }
                let getMonth = data.getMonth()+1+"";
                if(data.getMonth()+1<10) {
                  getMonth= "0"+getMonth;
                }
                this.isShowXuFeiStr =data1.getFullYear()+"/"+getMonth1+"/"+data1.getDate()+"-"+data.getFullYear()+"/"+getMonth+"/"+data.getDate();
              }
            }).catch((error) => {
              // console.log('会员已经到期了33333');
            });
          } else {
            let count = overData.getTime() - nowData.getTime();
            let long = count/1000/24/60/60;
            console.log('会员快到期了,不足三个月',count/1000/24/60/60)
            this.dataApi.checkMemberCheckV2().then((res:any)=> {
              console.log('会员快到期了,但是有新的会员',res);
              if(res.has_member_order) {
                let data1 = new Date(res.scheduled_execution_time)
                let data = new Date(res.scheduled_execution_time)
                data.setFullYear(data.getFullYear()+1) ;
                let getMonth1 = data1.getMonth()+1+"";
                if(data1.getMonth()+1<10) {
                   getMonth1= "0"+getMonth1;
                }
                let getMonth = data.getMonth()+1+"";
                 if(data.getMonth()+1<10) {
                   getMonth= "0"+getMonth;
                 }
                 this.memberOutCount = 365;
                this.isShowXuFeiStr =data1.getFullYear()+"/"+getMonth1+"/"+data1.getDate()+"-"+data.getFullYear()+"/"+getMonth+"/"+data.getDate();
              }
              else {
                // 会员快到期提醒
                this.noneVipUserLevel = 1 //续费Vip
                this.memberOutCount = Number(long.toFixed(0));
                this.isShowMember_info = true;
                this.isPayMember = true;
                this.checkShowBottom();
              }
            }).catch((error) => {
              this.noneVipUserLevel = 0
              this.memberOutCount = Number(long.toFixed(0));
              this.isShowMember_info = true;
              this.isPayMember = true;
              this.checkShowBottom();
            });
          }
        }
        setTimeout(()=>{
          this.urmupdate()
        },2000)
      }
    }).catch((error) => {
      //this.toastr.error(error);
    });
  }

  //充值啦豆
  buyLaCoin(type) {
    if(type==1) {
        this.opera._hmtPush("校招工具购买","我的啦豆","啦豆余额");
    }
    else {
       this.opera._hmtPush("校招工具购买","优惠信息","立即充值");
    }
    if(this.config.isShowPaySystem) {
      this.router.navigate(['home/buyLaCoin']);
    }
  }
  //跳转我的卡券
  gotoMyCard() {
    this.router.navigate(['home/myCard']);
  }
  //购买历史
  gotoPayHistory() {
    this.router.navigate(['home/payHistory']);
  }
  //使用记录
  useHistory() {
    this.local.set('productUseHistory', 'all')
    this.router.navigate(['home/productUseHistory']);
  }
  //开发票
  gotoInvoice() {
    this.router.navigate(['home/payInvoice']);
  }
  gotoorderList() {
    this.router.navigate(['home/paymentorderlist']);
  }

  //关于啦豆 ？图标
  aboutLaBi() {
    this.aboutLaCoinAlert = true;
  }

  //获取产品列表
  getProductList() {
    let that = this;
    this.opera.loading();
    this.productManager.getGWLProductList(() => {
      this.opera.loadiss();
      let single_category_code =  this.local.get('single_category_code');
      let hot = null;
      if(single_category_code) {
        let arr = [];
         that.productManager.productList.map((item)=>{
          item.hot = false;
          if(item.code ==single_category_code) {
            hot = item;
            hot.hot = true;
          }
          else {
            //默认hot activity_invitation//voice_call
            if(item.recharge_category == "activity_invitation"||item.recharge_category == "voice_call") {
              item.hot = true;
            }
            arr.push(item);
          }
        });
        if(hot) {
          arr.unshift(hot);
        }
        that.productManager.productList = arr;
      }
      else {
        let arr = [];
        that.productManager.productList.map((item)=>{
          item.hot = false;
          if(item.recharge_category == "activity_invitation"||item.recharge_category == "voice_call") {
            item.hot = true;
          }
          arr.push(item);
        });
        that.productManager.productList = arr;
      }
      for (let n of that.productManager.productList) {
        n.isOpenBottom = false;
        n.count = that.initCount(n.recharge_category,n);
        let arr = [];
        n.product_infos.map((info)=>{
          if(info.single_name != '服务期') {
            arr.push(info);
          }
        })
        n.product_infos = arr;
        if(n.product_infos.length>0) {
          n.quanyi = n.product_infos[0].count+n.product_infos[0].unit+"/包";
        }
        else {
          n.quanyi = "";
        }
        if(n.count>99) {
          n.count = 99;
        }
        if (n.recharge_category == "voice_call") {
          n.long_description = "您可根据企业招聘需求，通过搜索、多维度筛选锁定目标毕业生，使用「极速电话联系」工具直接电话沟通，判断岗位匹配度及求职意向，让校招更直接、精准、高效。";
          n.chips = "*电话不接通，不计费。";
        }
        if (n.recharge_category == "sms_bulkinvitation") {
          n.long_description = "职位发布后，可通过「职位推送」工具，将职位信息精准推广至目标毕业生，吸引更多毕业生了解用人单位及岗位需求，从而主动投递简历。";
          n.chips = "*建议广泛推广，不错过优秀人才。";
        }
        if (n.recharge_category == "activity_invitation") {
          n.long_description = "双选会、宣讲会申请通过后，可通过使用「校招活动邀请」工具，将双选会展位、宣讲会信息等精准推广至目标毕业生，让到场率心中有数。";
          n.chips = "*校招活动举办前一天再次通知，效果更佳。";
        }
        if (n.recharge_category == "position_refresh") {
          n.long_description = "「职位刷新」工具可将招聘信息在全网同类职位中瞬间置顶，让更多精准匹配的毕业生优先看到，有效提升职位曝光，增强招聘竞争力。";
          n.chips = "*定期刷新、多次刷新，效果更好。";
        }
        if (n.recharge_category == "notice_student_sms") {
          n.long_description = "您可通过上传企业宣讲视频的形式发布空中宣讲会，发布后通过「空中宣讲会推广」工具多维度筛选，锁定目标毕业生，将空中宣讲会精准推广至目标人群，增加曝光，精准有效提升企业雇主品牌影响力！";
          n.chips = "*建议广泛推广，不错过优秀人才。";
        }
      }
      console.log('获取产品列表2', that.productManager.productList);
      that.productList = [...that.productManager.productList];
      that.checkShowBottom();
    })
  }

  //获取我的啦豆余额
  getMyLaCoinCount() {
    this.dataApi.getMyLaCoinCount({}).then((res:any) => {
      this.MyLaCoinCount = res;
    }).catch((err) => {
      console.log(err);
    })
  }

  //时间戳转时间
  RiQi(sj) {
    var now = new Date(sj);
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var date = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    return year + "." + month + "." + date;
  }

  getProductInfo() {
    this.dataApi.departmentCode('member_600').then((res:any) => {
      if (res) {
        console.log('获取产品详情', res)
        this.productInfo = res;
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  //购买会员计算啦豆抵用
  ladouDiyong() {
    if (this.MyLaCoinCount == 0) {
      return 0;
    }
    if (this.MyLaCoinCount > this.productInfo.price) {
      return this.productInfo.price;
    } else {
      return this.MyLaCoinCount;
    }
  }

  //计算购买会员的总价
  paycount() {
    if (this.MyLaCoinCount >= this.productInfo.price) {
      return this.productInfo.price + "啦豆";
    } else {
      let cha = this.productInfo.price - this.MyLaCoinCount;
      return "¥" + cha;
    }
  }

  //购买  如果啦豆都的话直接弹窗购买。不够的话显示支付宝/微信购买
  buy() {
    this.opera._hmtPush("校招工具购买","立即购买","");
    if(this.isLoading) {
      this.toastr.warning('网络请求中')
      return;
    }
    if (!this.productInfo) {
      this.toastr.warning('获取产品信息失败，请稍后重试')
      return;
    }
    //省下保险公司不能购买
    if(this.province_sign=='lower') {
      this.showBaoxianLowerModel();
      return;
    }
    let that = this;
    let online_packages = [];
    this.productList.map((item)=>{
      if(item.count>0) {
        online_packages.push({product_code:item.code,count:item.count})
      }
    });

    //计算啦豆余额够不够支付，并调用相对应的创建订单
    let Total = this.countTotalPrices();
    if(Total==0) {
      return;
    }
    console.log('MyLaCoinCount',this.MyLaCoinCount,Total)
    if(this.MyLaCoinCount >= Total) {
      let data = {
        member_product_code:this.isPayMember?"member_600":'',
        online_packages:online_packages,
        balance:Total, //订单的总价
        need_pay:0,
      }
      //paymentV2Creatorder
      console.log('online_packages',data);
      //啦豆足够的订单购买
      // this.opera.loading();
      let newProductList = [];
      that.productList.map((item)=>{
        let data =JSON.parse(JSON.stringify(item));
        let quanyi = null;
         let count = "";
         let unit = "";
        item.product_infos.map((q)=>{
          if(q.single_name =='服务期'){
            quanyi = q
            count = q.count;
            unit = q.unit;
          }
        })
        data.name = item.name+"("+count+unit+"/包)";
        newProductList.push(data)
      });
      if(this.countGiveLa()>0) {
        newProductList.push({count:1,cost: this.countGiveLa(),price:this.countGiveLa(),isGive:true,product_category: "ladou_gift",name:"充值赠送",product_name: "充值赠送",quanyi: "/"});
      }
      this.isLoading = true;
      let successIndex = 0;
      this.dataApi.paymentV2CreatorderByMoney(data).then((res:any) => {
        this.opera.loadiss();
        //config.lastIdentity&&config.lastIdentity.status==2
        if(this.config.lastIdentity.status!=2) {
          that.router.navigateByUrl("home/paymentorderlist"); // 会员
          return;
        }

        this.isLoading  = false;
        console.log('online_packages', res);
          let pay_orderid = res.value;
          let dialog =  this.dialog.open(ModelPayCodeComponent, {
          data:{
            title:"支付",
            buyType:'buyProduct_labi',
            pay_orderid:pay_orderid,
            labiData:{},
            buyProductData:{
              member:that.isPayMember?that.productInfo:'',
              productList:newProductList,
              httpData:data,
            },
            numberData:{
              MyLaCoinCount:that.MyLaCoinCount,
              xiaoji:that.countTotalPrices(),
              ladouyuediyong:that.countLastLaCoin(),
              haixuzhifu:that.countByLaCoin(),
            },
            callback:(res)=>{
              this.dialog.closeAll();
              this.config.isShowPayModel = false;
              this.opera.updateParOrderCount();
              //获取我的啦豆余额
              this.getMyLaCoinCount();
              //获取啦豆冻结
              this.getOrderladouForzen();
              if(res) {
                successIndex+=1;
                if(successIndex==1) {
                  this.buySucess()
                }
              }
            },}
        }).afterClosed().subscribe((isConfirmed) => {
          this.opera.updateParOrderCount();
          //获取我的啦豆余额this.getMyLaCoinCount();//获取啦豆冻结this.getOrderladouForzen();
          if(this.config.payTimer) {
            clearInterval(this.config.payTimer)
          }
        });
      }).catch((err) => {
        console.log(err);
        this.isLoading  = false;
        this.opera.loadiss();
        this.toastr.error(err)
      })
    }
    else {
      let model = {
        member_product_code:this.isPayMember?"member_600":'',
        online_packages:online_packages,
        balance:0,
        need_pay:0,
      };
      this.opera.loading();
      //啦豆足够的订单购买 （啦豆现金混合支付）
      this.isLoading = true;
      this.dataApi.paymentV2CreatorderByMoney(model).then((res:any) => {
        this.isLoading  = false;
        this.opera.loadiss();
        if(this.config.lastIdentity.status!=2) {
          that.router.navigateByUrl("home/paymentorderlist"); // 会员
          return;
        }

        console.log('online_packages', res,model);
        let pay_orderid = res.value;
        let newProductList = [];
        that.productList.map((item)=>{
          let data =JSON.parse(JSON.stringify(item));
          data.name = item.name+"("+item.product_infos[0].count+item.product_infos[0].unit+"/包)";
          newProductList.push(data)
        });
        if(this.countGiveLa()>0) {
          newProductList.push({count:1,cost: this.countGiveLa(),price:this.countGiveLa(),isGive:true,product_category: "ladou_gift",name:"充值赠送",product_name: "充值赠送",quanyi: "/"});
        }
        let successIndex = 0;
        let dialog =  this.dialog.open(ModelPayCodeComponent, {
          data:{
            title:"支付",
            buyType:'buyProduct_code',
            pay_orderid:pay_orderid,
            labiData:{},
            buyProductData:{
              member:that.isPayMember?that.productInfo:'',
              productList:newProductList,
            },
            numberData:{
              MyLaCoinCount:that.MyLaCoinCount,
              xiaoji:that.countTotalPrices(),
              ladouyuediyong:that.countLastLaCoin(),
              haixuzhifu:that.countByLaCoin(),
            },
            callback:(res)=>{
              console.log('callback',res)
              this.dialog.closeAll();
              this.config.isShowPayModel = false;
              this.opera.updateParOrderCount();
              //获取我的啦豆余额
              this.getMyLaCoinCount();
              //获取啦豆冻结
              this.getOrderladouForzen();
              if(res) {
                successIndex+=1;
                if(successIndex==1) {
                  this.buySucess()
                }
              }
            },}
        }).afterClosed().subscribe((isConfirmed) => {
          this.opera.updateParOrderCount();
          //获取我的啦豆余额
          this.getMyLaCoinCount();
          //获取啦豆冻结
          this.getOrderladouForzen();
          if(this.config.payTimer) {
            clearInterval(this.config.payTimer)
          }
        });
      }).catch((err) => {
        this.isLoading  = false;
        this.opera.loadiss();
        this.toastr.error(err)
      })
    }
  }

  showBaoxianLowerModel(){
    this.dialog.open(DepartmentSelfAlertComponent, {
      data: {title:"请联系校招顾问购买",}
    }).afterClosed().subscribe((isConfirmed) => {
    });
  }
  showAlert_Order_info(){
    let arr = [];
    if(this.isPayMember&& this.productInfo) {
      arr.push({name:this.productInfo.name,count:1,price:this.productInfo.price,quanyi:'365天'})
    }
    this.productList.map((item)=>{
      if(item.count>0) {
        arr.push({name:item.name,count:item.count,price:item.price*item.count,quanyi:item.product_infos[0].count+item.product_infos[0].unit+"/包"})
      }
    })
    this.alert_OrderAlertInfoList = arr;
    let talentChC = document.getElementById('alert_Order_info');
    talentChC.style.display = "block";
  }
  dimissAlert_Order_info(){
    let talentChC = document.getElementById('alert_Order_info');
    talentChC.style.display = "none"
  }
  isOpenMemberImageClick(e){
    e.stopPropagation();
    this.opera._hmtPush("校招工具购买","工作啦会员","查看介绍");
    this.isOpenMemberImage =  !this.isOpenMemberImage;
  }
  //打开底部详情
  openDetailBottomInfo(n){
    this.setBaiDu_htm(n.recharge_category,'查看介绍');
    let arr =  this.productList.map((item)=>{
       if(item.recharge_category==n.recharge_category) {
         item.isOpenBottom = !item.isOpenBottom;
         if(item.isOpenBottom) {
           this.isOpenMemberImage = false;
         }
       }
       else {
         item.isOpenBottom = false;
       }
       return item;
    })
  }
  //计算总价
  countTotalPrices(){
    let Total = 0;
    //会员价格
    if(this.isPayMember&&this.productInfo) {
      Total+= this.productInfo.price;
    }
    this.productList.map((item)=>{
      Total += item.price*item.count
    });
    return Total;
  }
   //手动数据购买产品的数量
  addCountInputChange(item){
    if(item.count>99) {
      this.toastr.warning('单次最大可购买99份');
      setTimeout(()=>{
        this.productList= this.productList.map((e)=>{
          if(e.recharge_category == item.recharge_category) {
            e.count = 99;
          }
          return e;
        })
      },300)
    }
    if(item.count<0) {
      setTimeout(()=>{
        this.productList= this.productList.map((e)=>{
          if(e.recharge_category==item.recharge_category) {
            e.count = 0;
          }
          return e;
        })
      },300)
    }
    this.saveLocalCount();
    this.checkShowBottom();
  }
  //增加产品的+-
  addCountBtnChange(type,item){
    if(!this.isClick) {
      return;
    }
    this.setBaiDu_htm(item.recharge_category,type)
    let count  = item.count;
    // console.log('addCountBtnChange',item)
    if(count<=0) {
      count = 0;
    }
    let that = this;
    if((count==0&&type=='delete')) {
      this.clickIndex+=1;
      if(this.clickIndex>3) {
        this.toastr.warning('请不要频繁点击')
        this.clickIndex = 0;
        that.isClick = false;
        //冻结3秒
        this.timer =   setTimeout(()=>{
          that.isClick = true;
        },3000);
        return;
      }
      return;
    }
    else {
      this.clickIndex = 0;
    }

    if(type=='add') {
      if(count==99) {
        this.toastr.warning('单次最大可购买99份');
        return;
      }
      count+=1;
    }
    else
    {
      if(count==0) {
        count = 0;
        return;
      }
      count-=1;
    }
    this.productList= this.productList.map((e)=>{
      if(e.recharge_category==item.recharge_category) {
        e.count = count;
      }
      return e;
    })
    this.saveLocalCount();
    this.checkShowBottom();
  }
  //计算啦豆余额抵用
  countLastLaCoin(){
    let Total = this.countTotalPrices();
    if(Total>this.MyLaCoinCount) {
      return this.MyLaCoinCount;
    }
    else {
      return Total
    }
  }
  //计算我要充值的啦豆
  countByLaCoin(){
    let Total = this.countTotalPrices();
    if(this.MyLaCoinCount==0) {
      return Total;
    }
    if(this.MyLaCoinCount>Total) {
      return 0;
    }
    return Total - this.MyLaCoinCount;
  }
  //计算需要支付的价格
  countPayCount(){
    let Total = this.countTotalPrices();
    if(this.MyLaCoinCount==0) {
      return Total;
    }
    if(this.MyLaCoinCount>Total) {
      return 0;
    }
    return this.MyLaCoinCount-Total;
  }
  //会员续费点击切换
  xufeiStateClick(e){
    if(!this.myDepartment.member_info) {
      return;
    }
    // 体验会员不能切换
    if(this.myDepartment.member_info.product_code =='member_trial') {
      return;
    }
    e.stopPropagation();
    if(this.memberOutCount==0) {
      this.isPayMember = true;
      return;
    }
    this.isPayMember = !this.isPayMember;
    this.checkShowBottom();
  }
  //动态保存本地单品的数量
  saveLocalCount(){
    let data = [];
    this.productList.map((item)=>{
      if(item.count>0) {
        data.push({'recharge_category':item.recharge_category,count:item.count})
      }
    });
    this.local.set('payrecharge',JSON.stringify(data));
  }
  //计算赠送的啦豆
  countGiveLa(){
    let all =  this.countByLaCoin();
    let giveCount =  Math.floor(all/1000);
    return giveCount* 50;
  }
  //初始化需要购买的单品数量，数据缓存的数据读取
  initCount(recharge_category,prod){
    let payrechargeArr = [];
    let payrecharge =  this.local.get('payrecharge');
    if(payrecharge) {
      payrechargeArr = JSON.parse(payrecharge);
    }
    let count = 0;
    payrechargeArr.map((item)=>{
      if(item.recharge_category == recharge_category) {
        count = item.count;
      }
    });
    //pay_recharge
    let pay_recharge_order =  this.local.get('pay_recharge_order');
    //count
    let pay_recharge_order_arr = [];
    if(pay_recharge_order) {
      pay_recharge_order_arr = JSON.parse(pay_recharge_order);
      pay_recharge_order_arr.map((item)=>{
        if(prod.code==item.product_code) {
          count = item.count;
        }
      });
    }
    let single_category_code =  this.local.get('single_category_code');
    if(single_category_code) {
      if(single_category_code==prod.code) {
        this.local.remove("single_category_code");
        return 1;
      }
      else {
        return 0
      }
    }
    return count;
  }
  //获取啦豆  会员 ，清除本地的单品缓存
  buySucess(){
      this.toastr.success('购买成功');
      this.getDepartment();
      this.local.remove('payrecharge')
      this.local.remove('pay_recharge_order')
      this.local.remove('single_category_code')
      this.getProductList();
      this.getDepartmentUpdate();
      let that = this;
      if(this.countGiveLa()>0) {
        that.local.set('showPaySuccess',this.countGiveLa())
      }
      setTimeout(()=>{
        that.router.navigateByUrl("home/tool-my"); // 会员
      },2000)
  }
  gotoidentity(){
    this.router.navigateByUrl('guide-audit');
  }
  //获取平台认证信息并赋值
  getApplyPlatformDetails() {
    this.dataApi.getPlatformApply().then((res:any) => {
      if(res) {
        this.config.lastIdentity =res;
      }
    }).catch((error) => {
      console.log(error);
    })
  }
  gotoOrderManager(e,state){
    e.stopPropagation();
    if(state)
    {
      this.local.set('order_state',state);
    }
    this.opera._hmtPush("校招工具购买","我的啦豆","冻结中的啦豆");
    this.router.navigate(['home/paymentorderlist'],);
  }

  //检测是否显示底部的信息
  checkShowBottom(){
    let isShow = false;
    this.productList.map((item)=>{
      if(item.count>0) {
       isShow= true;
      }
    });
    if(this.isPayMember)
    {
      isShow= true;
    }
    this.isShowbottom = isShow;
    // console.log('isShowbottom',this.isShowbottom)
  }

  getDepartmentUpdate(){
    this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
      //console.log(res);
      if(res){
        this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
      }
    }).catch((error)=>{
    });
  }

  //设置
  setBaiDu_htm(recharge_category,type){
    //voice_call  极速电话联系
    //activity_invitation 校招活动申请
    //sms_bulkinvitation 职位推送
    //position_refresh 职位刷新
    //notice_student_sms 空中宣讲会推广

    if(type =='查看介绍'||type =='delete'||type =='add')
    {
      if(recharge_category =='voice_call'){
          this.opera._hmtPush("校招工具购买","极速电话联系","查看介绍");
      }
      else if(recharge_category =='activity_invitation'){
        this.opera._hmtPush("校招工具购买","校招活动申请 ","查看介绍");
      }
      else if(recharge_category =='sms_bulkinvitation'){
        this.opera._hmtPush("校招工具购买","职位推送 ","查看介绍");
      }
      else if(recharge_category =='position_refresh'){
        this.opera._hmtPush("校招工具购买","职位刷新 ","查看介绍");
      }
      else if(recharge_category =='notice_student_sms'){
        this.opera._hmtPush("校招工具购买","空中宣讲会推广 ","查看介绍");
      }
    }

    if(type =='delete'||type =='add')
    {
      let str =  type=="delete"?"减少数量":"增加数量";
      if(recharge_category =='voice_call'){
        this.opera._hmtPush("校招工具购买","极速电话联系",str);
      }
      else if(recharge_category =='activity_invitation'){
        this.opera._hmtPush("校招工具购买","校招活动申请 ",str);
      }
      else if(recharge_category =='sms_bulkinvitation'){
        this.opera._hmtPush("校招工具购买","职位推送 ",str);
      }
      else if(recharge_category =='position_refresh'){
        this.opera._hmtPush("校招工具购买","职位刷新 ",str);
      }
      else if(recharge_category =='notice_student_sms'){
        this.opera._hmtPush("校招工具购买","空中宣讲会推广 ",str);
      }
    }
  }
  checkMemberAlertWithTypeCustom(){
    let data = JSON.stringify(this.config.payVipHistoryPage)
    console.log('this.config.payVipHistoryPage',this.config.payVipHistoryPage)
    //isVip
    // 1 No_Vip
    //2 Vip  过期
    // 3 Vip + 有 this.config.payVipHistoryPage 权益不足
    // 4 Vip + 无 this.config.payVipHistoryPage 主动购买  请联系您的专属校招顾问进行产品配置
    if(this.config.isShowPaySystem) {
      setTimeout(()=>{
        this.opera.checkMemberAlertWithTypeCustom(JSON.parse(data))
      },1000)
      this.config.payVipHistoryPage = null;
    }
  }
  //展示校招 顾问
  showXiaozhao(){
    this.dialog.open(DepartmentSelfAlertComponent, {
      data:{title:"请联系校招顾问",title2:'asdasda'}
    }).afterClosed().subscribe((isConfirmed) => {
    });
  }
  // urm 统计 ，在非付费版本进行统计
  urmupdate(){
    if(!this.config.isShowPaySystem){
      console.log('urmupdate',this.config.isShowPaySystem,this.noneVipUserLevel,this.config.payVipHistoryPage)
      let category = ''
      if(this.noneVipUserLevel == 0 || this.noneVipUserLevel == 3) {
        category = "not_enough"
      }
      if(this.noneVipUserLevel == 1) {
        //到期会员点击续费
        category = "renew"
      }
      if(this.noneVipUserLevel == 4) {
        category = "active_purchase"
      }
      if(this.config.payVipHistoryPage){
        if(this.config.payVipHistoryPage.isVip){
          if(this.config.payVipHistoryPage.pro == '极速电话联系'){
            category = "passive_purchase_voice_call"
          }else if(this.config.payVipHistoryPage.pro == '职位刷新'){
            category = "passive_purchase_position_refresh"

          }else if(this.config.payVipHistoryPage.pro == '职位推送'){
            category = "passive_purchase_sms_bulkinvitation"

          }else if(this.config.payVipHistoryPage.pro == '校招活动邀请'){
            category = "passive_purchase_activity_invitaion"

          }else if(this.config.payVipHistoryPage.pro == '空宣推广'){
            category = "passive_purchase_notice_student_sms"
          }
        }
      }
      // 主动购买
      if(this.config.userInitiativePayName) {
        // 活动邀请
        if(this.config.userInitiativePayName == "activity_invitation"){
          category = "active_purchase_activity_invitaion"
        }
        // 职位刷新
        if(this.config.userInitiativePayName == "position_refresh"){
          category = "active_purchase_position_refresh"
        }
        //极速电话联系
        if(this.config.userInitiativePayName == "voice_call"){
          category = "active_purchase_voice_call"
        }
        // 职位推送
        if(this.config.userInitiativePayName == "sms_bulkinvitation"){
          category = "active_purchase_sms_bulkinvitation"
        }
      }

      if(category){
        let urmPayStatistic = {
          departmentid:this.config.department.accountid,
          category:category
        }
        this.opera.urmPayStatisticPublic(category)
      }
    }
  }

  //联系校招顾问
  connetXiaoZhao(type){
    this.isShowConnetSuccess = true
    this.isShowConnetSuccessStep = type
    let urmPayStatistic = {
      departmentid:this.config.department.accountid,
      category:"not_member"
    }
    this.dataApi.urmPayStatistic(urmPayStatistic).then((res:any)=>{
      console.log('urmPayStatistic',res)
    }).catch((error) => {
    });
  }
  dismissConnet(){
    this.isShowConnetSuccess = false
  }
  copyText(text){
    if(!text){
      return
    }
    console.log('copy',text)
    var Url2=text;
    var oInput = document.createElement('input');
    oInput.setAttribute('id', 'cp_hgz_input');
    oInput.value = Url2;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display='none';
    this.toastr.success('复制成功')
    document.getElementById('cp_hgz_input').remove();
  }
  setNineTool(){
    this.nineList = [
      {icon:'../../assets/images/member2021/icon_rencaiku.png',title:'开放百万人才库'},
      {icon:'../../assets/images/member2021/icon_fabu.png',title:'发布职位'},
      {icon:'../../assets/images/member2021/icon_tuijian.png',title:'大数据人才推荐'},
      {icon:'../../assets/images/member2021/icon_jianliguanli.png',title:'简历管理'},
      {icon:'../../assets/images/member2021/icon_shenqing.png',title:'一站申请双选会/宣讲会'},
      {icon:'../../assets/images/member2021/icon_fabukongxuan.png',title:'发布空中宣讲会'},
      {icon:'../../assets/images/member2021/icon_tuisong.png',title:'批量职位推送'},
      {icon:'../../assets/images/member2021/icon_huodong.png',title:'平台级校招活动'},
      {icon:'../../assets/images/member2021/icon_pinpai.png',title:'雇主品牌推广'},
      {icon:'../../assets/images/member2021/icon_tixing.png',title:'即时动态提醒'},
      {icon:'../../assets/images/member2021/icon_shuaxin.png',title:'职位刷新'},
      {icon:'../../assets/images/member2021/icon_jidian.png',title:'极速电话联系'},
      {icon:'../../assets/images/member2021/icon_kongxuan.png',title:'空中宣讲会推广'},
      {icon:'../../assets/images/member2021/icon_yaoqing.png',title:'校招活动邀请'},
      ];
  }

  productModelHidden(event){    
    this.isproductShowModel = false;
  }

  //点击产品
  product(alias,type){
    this.productModel.initData(alias,type);
    this.isproductShowModel = true;
  }
  
}
