import { Component, Input, Output,OnInit } from '@angular/core';
import { DataApi,Local,Opera,Constants,Config } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
declare let window;
@Component({
  selector: 'app-model-collection-resume',
  templateUrl: './model-collection-resume.component.html',
  styleUrls: ['./model-collection-resume.component.css']
})
export class ModelCollectionResumeComponent implements OnInit {
  @Input()  isShow: false;//是否显示弹窗页面
  // 筛选条件--搜索
  public model={
    keywords:'',//关键词
    major:'',//专业
    education:'',//学历
    limit:10,
    offset:0,
    status:'viewed'
  }
  public config: Config;
  public educationList=[];//学历列表
  public keywordList=[];//搜索关键词匹配列表
  public searchModel = '';//搜索关键词
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  public majorList = [];
  public majorOne='';//专业类别一级
  public resumeList = [];//简历列表
  public countList = 0;//列表数量

  public scrollTop = 280;
  public positionresumelistinde_like = 0;


  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {
    console.log('22');
    this.getCollegeCode();//获取高校字典
    this.getMajorList();//获取专业列表信息
    this.getEducationList();//获取学历列表信息
    this.getSendResumeList();//获取简历投递列表
  }


   //获取分页子组件返回数据
   getChildEvent(index){
    ////console.log('子组件返回分页数-----college-resume');
    ////console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*10;
      this.getSendResumeList();//获取简历列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*10;
        this.getSendResumeList();//获取简历列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*10;
        this.getSendResumeList();//获取简历列表
      }
    }

  }

  //获取简历列表
  getSendResumeList(){
    this.keywordList=[];
    this.dataApi.getSendResumeList(this.model).then((res:any)=>{
     console.log("获取简历收藏列表",res);
      ////console.log(res);
      if(res)
      {
        this.resumeList = res.list;
        this.countList=res.count;
        this.pageCount =Math.ceil(res.count/10);
        for (let val of  this.resumeList)
        {
          let arr = [];
          val.yixiang = "";
          val.youshiArr = [];
          if(val.pro_skills!=null)
          {
            for (let item1 of val.pro_skills)
            {
              val.yixiang += item1.text+"    "
              if(item1.contrast_list){
                for (let youshi of item1.contrast_list)
                {
                  val.youshiArr.push(youshi.text);
                }
              }

            }
          }

        }


      }

    }).catch((err)=>{
      ////console.log(err);
    })
  }


  //监听majorOne
  majorOneChange(){
    // ////console.log("第一级专业值");
    // ////console.log(this.majorOne);
    // ////console.log("专业列表");
    // ////console.log(this.config.major);
    this.majorListTwo=[];
    for(let n of this.config.major){
      if(n.parentcode==this.majorOne){
        this.majorListTwo.push(n);
      }
    }
    this.model.major='';
    // ////console.log("第二级专业列表");
    // ////console.log(this.majorListTwo);
  }


  //提示先选一级专业
 isShowTwo(){
  if(!this.majorOne){
    this.toastr.warning("请先选择一级专业");
    return;
  }
 }

  //获取学历列表信息
  getEducationList(){
    if(this.dataApi.getEducationCode()){
      ////console.log("获取学历列表本地存储-----college-resume");
      ////console.log(this.dataApi.getEducationCode());
      this.educationList = this.dataApi.getEducationCode();
    }else{
      this.dataApi.getDictDate('education').then((res:any)=>{
        ////console.log("获取学历列表-----college-resume");
        ////console.log(res);
        if(res)
        {
          this.educationList = res;
          this.dataApi.setEducationCode(res);
        }

      }).catch((error)=>{
        ////console.log(error);
      });
    }
  }

  //获取一级专业列表
  getMajorListOne(){
    for(let n of this.config.major){
      if(n.level==2){
        this.majorListOne.push(n);
      }
    }
  }

  //获取专业列表信息
/*  getMajorList(){
    if(this.dataApi.getMajorCode()){
      this.config.major = this.dataApi.getMajorCode();
      this.getMajorListOne();//获取一级专业列表
    }else{
      this.dataApi.getDictDate('major').then((res:any)=>{
        ////console.log("获取专业列表-----college-resume");
        ////console.log(res);
        this.config.major = res;
        this.getMajorListOne();//获取一级专业列表
        this.dataApi.setMajorCode(res);
      }).catch((error)=>{
        ////console.log(error);
      });
    }
  }*/
  getMajorList(){
    this.opera.loading();//加载动画
    this.dataApi.getagregationCount({}).then((res:any)=>{
      if(res)
      {
        // console.log('我要的取数据',res.facetgroups);

        this.majorList = res.facetgroups;
        res.facetgroups.map((item)=>{
          if(item.level == 1){
            this.majorListOne.push(item)
          }
        })
        //this.majorListOne = res.facetgroups;
        this.getMajorListOne();//获取一级专业列表

      }

      // console.log(res);
      // console.log(this.majorList);
    }).catch((err)=>{
      console.log(err);
      this.opera.loadiss();
    })
  }

  //获取高校字典
  getCollegeCode(){
    // 获取高校字典数据
    if(this.config.school.length<1){
     this.dataApi.getDictDate('school').then((res:any)=>{
       // ////console.log(res);
       this.config.school=res;
     }).catch((err)=>{
       // this.toastr.error("网络错误,请稍后重试");
       ////console.log(err);
     })
   }
 }

  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      that.goSearch(that.model.keywords);
    }else{
      that.getItems();
    }
  }

  //匹配高校名称字典
  getItems(){
    this.keywordList=[];
    if(!this.model.keywords){return;}
    for(let s of this.config.school){
      if(s.text.indexOf(this.model.keywords) >= 0){
         this.keywordList.push(s);
      }
    }
  }

  //筛选
  goSearch(text){
    this.model.keywords = text;
    this.getSendResumeList();//获取简历列表
    this.keywordList=[];
  }

  //-----跳转简历详情
  goDetails(n,index){
    if(!n.ignore) {
      this.positionresumelistinde_like = index;
      this.initScrooll();
      this.config.isNavActive = 'college';
      this.config.isNavChildActive = 'college-resume';
      this.router.navigate(['home/college-resumedetails'], {
        queryParams: {
          resumeid: n.resume_number,
          collegeid: n.collegeid,
          // positionid:this.model.positionid
        }
      });
    }

  }

  //收藏
  goCollect(val){
    this.dataApi.manageResumeArchivedDelete(val.resumeid).then((res:any)=>{
      // ////console.log(res);
      let index = this.resumeList.indexOf(val);
      if (index > -1) {
      this.resumeList.splice(index, 1);
      }
      setTimeout(() => {
        this.toastr.success('取消收藏成功');
      },600);
    }).catch((error) => {
      ////console.log(error);
      setTimeout(() => {
        this.toastr.error(error);
      },600);
    });
  }
  initScrooll() {
    console.log('positionresumelistinde_like',this.positionresumelistinde_like)
    window.scrollTo(0,this.scrollTop + (120*this.positionresumelistinde_like));
  }
  shouCang(e,item){
    e.stopPropagation();
    this.goCollect(item)
  }

}
