import { Component, OnInit, Input, Output } from '@angular/core';
import { DataApi,Config,Opera,Local,Constants } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from  '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-college-details-major',
  templateUrl: './college-details-major.component.html',
  styleUrls: ['./college-details-major.component.css']
})
export class CollegeDetailsMajorComponent implements OnInit {
  public config: Config; 
  public isInit = false;//是否初始化成功
  public resumeList = [];//简历列表
  public countList = 0;//列表数量
  // @Output() pageNumber = 1;//当前分页数
  // @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public facultyid="";//学院id
  public college:any;//学院详情
  constructor(public dataApi: DataApi,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public local:Local,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer, 
    public opera:Opera,
    public toastr: ToastrService) { 
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {
    this.opera.initConfig('college','college-campustalk');
    this.facultyid=this.local.get(Constants.facultyid);//赋值高校id
    this.getCollegeById();//获取院系详情
    this.getResumeList();//获取列表
  }
   //获取列表
   getResumeList(){
    this.opera.loading();
    this.dataApi.getCollegeMajor(this.facultyid).then((res:any)=>{
      // console.log("获取院系专业列表---");
      // console.log(res);
      this.resumeList = res;
      this.countList = this.resumeList.length; 
      // this.pageCount =Math.ceil(res.count/9);
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
    })
  }

  //通过院系id获取院系详情
  getCollegeById(){
    this.opera.loading();
    this.dataApi.getCollegeFaculty(this.facultyid).then((res:any)=>{
      //console.log("获取到院系信息---college-details-major");
      //console.log(res);
      this.isInit = true;
      this.college = JSON.parse(JSON.stringify(res));
      this.opera.loadiss();
    }).catch((err)=>{
      this.toastr.error(err);
      this.isInit = true;
      this.opera.loadiss();
    });
  }


}
