import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { DataApi, Local, Constants, Opera, Config, FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModelAddressAddComponent } from '../model-address-add/model-address-add.component';
import { ModelCompanyLabelComponent } from '../model-company-label/model-company-label.component';
import { ModelCompanyProductComponent } from '../model-company-product/model-company-product.component';
import { ModelCompanyCoursesComponent } from '../model-company-courses/model-company-courses.component';
import { ModelCompanyStrengthComponent } from '../model-company-strength/model-company-strength.component';
import { ModelCropperComponent } from '../model-cropper/model-cropper.component';
import { Events } from '../../provider/eventService';
import { ConfirmComponent } from "../confirm/confirm.component";
import { regexManager } from "../../provider/regexManager";
import { LogoutAccountModelComponent } from "../logout-account-model/logout-account-model.component";
declare let laydate;
declare var window;
declare var layui;
declare var layer;
@Component({
  selector: 'app-guide-audit',
  templateUrl: './guide-audit.component.html',
  styleUrls: ['./guide-audit.component.css']
})
export class GuideAuditComponent implements OnInit {
  @ViewChild('address') child: ModelAddressAddComponent;
  @ViewChild('label') child1: ModelCompanyLabelComponent;
  @ViewChild('product') child2: ModelCompanyProductComponent;
  @ViewChild('courses') child3: ModelCompanyCoursesComponent;
  @ViewChild('strength') child4: ModelCompanyStrengthComponent;
  @ViewChild('crop') child5: ModelCropperComponent;
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelTwoShow = false;//是否打开弹窗
  @Output() modelThreeShow = false;//是否打开弹窗
  @Output() modelFourShow = false;//是否打开弹窗
  @Output() modelFiveShow = false;//是否打开弹窗
  @Output() modelSixShow = false;//是否打开弹窗
  public config: Config;
  //企业数据(企业信息,修改资料的时候不用这个传递)
  public myDepartment: any;
  //需要修改的企业资料初始化
  public auth_model = {
    organization_type: '',// 组织机构类型
    department_name: '',//单位名称
    register_number: '',//统一社会信息代码
    business_licence: '',//营业执照
    annex1: '',//附件1
    annex2: '',// 附件2
    operator_name: '',//姓名
    id_number: '',//身份证号码
    id_card: '',// 身份证正面
    id_card_back: '', // 身份证反面
    annex3: '',// 附件3
    annex4: '',// 附件4
    banxue: ""//办学证书
  };
  public materail: any;//认证材料
  public secureMaterail: any;//保险行业认证材料
  public material_current: any; //当前选择的单位类型;
  //企业当前地址
  public workPlace = '';
  public auditStatus = null;
  public productid = "";//产品id
  public coursesid = "";//发展历程id
  public register_capital_type = '万元'
  // public locations=[];
  public guideStep = false;
  public t_lastIdentity = null;

  public showFailChipsModel = false;
  public showFailChipsCommittext = '修改完毕，确定提交(5s)';
  public showFailChipsCommitnumber = 5;
  public showFailChipsCommitnumberInv: any;
  public showFailChipsIsCommit = false;
  public isShowFailModel = true;
  public isMemberOut = false;

  constructor(public dataApi: DataApi,
    public local: Local,
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public opera: Opera,
    public formatDate: FormatDate,
    public events: Events,
    public regexManager: regexManager,
    public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
    this.config.isGuide = true;
    if (this.local.get(Constants.byCollegeDetails)) {
      this.guideStep = true;
    }
    window.onpopstate = (event) => {
      console.log('onpopstate', event);
      window.location.reload()
    };
  }

  ngOnInit() {
    // layui.use('layer', function() {
    //   var layer = layui.layer;
    //   layer.confirm('您是如何看待前端开发？', {
    //     btn: ['重要','奇葩'] //按钮
    //   }, function(){
    //     layer.msg('的确很重要', {icon: 1});
    //   }, function(){
    //     layer.msg('也可以这样', {
    //       time: 20000, //20s后自动关闭
    //       btn: ['明白了', '知道了']
    //     });
    //   });

    // })

    this.opera.initConfig('login', '');
    this.getRecruitmentSelf();
    if (this.dataApi.getLocalDepartment()) {
      let department = this.dataApi.getLocalDepartment();
      this.auth_model.department_name = department.name == null ? "" : department.name;
    }
    this.dataApi.getDepartment().then((res: any) => {
      if (res) {
        console.log('重新提交认证', res)
        if (res.apply_renewal) {
          this.dataApi.authenticationPlatformApplyNew().then((res: any) => {
            if (res) {
              console.log('重新提交认证数据', res)
              this.t_lastIdentity = res;
              this.config.lastIdentity = res;
              this.auditStatus = res.status;
              if (res.operator_name) {
                this.auth_model.operator_name = res.operator_name
              }
              if (res.id_number) {
                this.auth_model.id_number = res.id_number
              }
              if (res.id_card) {
                this.auth_model.id_card = res.id_card
              }
              let endtime = res.authentication_endtime;
              let endData = new Date(endtime);
              let nowData = new Date();

              if (nowData > endData) {
                console.log('会员到期了')
                this.auditStatus = 4;
              }
              let spaceBe = 60 * 24 * 60 * 60 * 1000;
              if ((endData.getTime() - nowData.getTime()) > spaceBe) {
                console.log('续费认证正常')
              }
              else if (nowData.getTime() > endData.getTime()) {
                console.log('续费认证到期')
              }
              else {
                console.log('续费认证不足90天')
                this.auditStatus = 4;
              }

              //isShowAuthState
            }
          }).catch((err) => {
          })
        }
        else {
          this.getPlatformIdentity(res)
        }
      }
    }).catch((err) => {
      this.toastr.error(err);
    })



    this.getOrgType();
    this.getIdentityMaterial();

  }


  //获取企业认证信息
  getPlatformIdentity(department) {
    this.dataApi.getPlatformApply().then((res: any) => {
      console.log("获取企业平台认证信息app", res, department);
      console.log(res);
      if (res) {
        this.auditStatus = res.status;

        //endtime  第一次申请时企业信息的 authentication_endtime 为 0001-01-01T00:00:00+08:00
        let endtime = department.authentication_endtime;
        if (endtime != '0001-01-01T00:00:00+08:00') {
          let endData = new Date(endtime);
          let nowData = new Date();
          if (nowData > endData) {
            console.log('会员到期了')
            this.auditStatus = 4;
          }
          let spaceBe = 60 * 24 * 60 * 60 * 1000;
          if ((endData.getTime() - nowData.getTime()) > spaceBe) {
            console.log('续费认证正常')
          } else if (nowData.getTime() > endData.getTime()) {
            console.log('续费认证到期')
          } else {
            console.log('续费认证不足90天')
            this.auditStatus = 4;
          }
        }
      }
    }).catch((error) => {
      console.log("获取企业平台认证信息出错");
      console.log(error);
    })
  }

  //企业名称的检测--保险
  department_nameChange(e) {

    if (e.indexOf("保险") >= 0) {
      this.auth_model.organization_type = "99";
      for (let n of this.materail) {
        if (n.item_code == '99') {
          this.material_current = n;
        }
      }
      console.log('material_current', this.material_current);
    } else {
      // this.auth_model.organization_type = "";
      // this.material_current = null;
    }
  }
  //上传图片并赋值
  previewDepartmentPic(event, text) {
    let imgData = event.target.files[0]; //获取file对象
    if (parseInt(imgData.size) / 1024 > 1024 * 5) {
      this.toastr.warning('该图片大小超出了5M，请重新上传');
      return;
    }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e: any) {
        that.uploadDepImg(reader.result, text);
      };

    }
  }
  getOrgType() {
    this.dataApi.getorganization_type_dic((res: any) => {
      console.log('组织机构', res)
      this.config.organization_type = res;

    })
  }
  //单位类型变化
  department_organizationChange(e) {
    for (let n of this.materail) {
      if (n.item_code == this.auth_model.organization_type) {
        this.material_current = n;
      }
    }
    console.log('e', this.auth_model.organization_type, this.material_current);

  }
  //获取认证上传的材料标准
  getIdentityMaterial() {
    let model = { collegeid: '' };
    this.dataApi.getMaterial('authentication', model).then((res: any) => {
      console.log("获取认证上传的材料标准", res);
      //console.log(res);
      if (res) {
        this.materail = res;
        for (let list of res) {
          if (list.item_code == '99') {
            this.secureMaterail = list;
          }
        }
        this.getApplyPlatformDetails();

        if (this.auth_model.department_name) {
          this.department_nameChange(this.auth_model.department_name);
        }


      }
    }).catch((err) => {
    })
  }


  //获取平台认证信息并赋值
  getApplyPlatformDetails() {

    this.dataApi.getPlatformApply().then((res: any) => {
      console.log("获取企业平台认证信息", res);
      //console.log(res);
      if (res) {
        this.config.lastIdentity = res;
        // if (res.status == 3) {
        this.auth_model.organization_type = res.organization_type;
        this.auth_model.department_name = res.department_name == null ? "" : res.department_name;
        this.auth_model.register_number = res.register_number;
        this.auth_model.business_licence = res.business_licence;
        this.auth_model.annex1 = res.annex1;
        this.auth_model.annex2 = res.annex2;
        this.auth_model.operator_name = res.operator_name;
        this.auth_model.id_number = res.id_number;
        this.auth_model.id_card = res.id_card;
        this.auth_model.id_card_back = res.id_card_back;
        this.auth_model.annex3 = res.annex3;
        this.auth_model.annex4 = res.annex4;
        for (let n of this.materail) {
          if (n.item_code == this.auth_model.organization_type) {
            this.material_current = n;
          }
        }
        this.department_nameChange(this.auth_model.department_name);
        console.log('material_current被拒绝', this.material_current, this.materail)
        // }
        // if (this.config.department.industry == '0202') {
        //   this.config.lastIdentity.organization_type_text = "企业";
        //   this.config.lastIdentity.organization_type = "01";
        //   this.auth_model.organization_type = '01';
        // }
        this.dataApi.setLastIdentity(this.config.lastIdentity);
      } else {
        this.lastAuthenticationIdentity();
      }
    }).catch((error) => {
      //console.log("获取企业平台认证信息出错");
      console.log(error);
    })

  }
  //获取最后一次申请校招信息
  lastAuthenticationIdentity() {
    this.dataApi.lastAuthenticationIdentity().then((res: any) => {
      console.log("获取最后一次申请校招信息", res);
      this.config.lastCollegeIdentity = res;
      if (res) {
        this.auth_model.business_licence = res.business_license;
        this.auth_model.id_card = res.id_card_front;
        this.auth_model.register_number = res.register_number;
      }
    }).catch((error) => {
      console.log(error);
    })
  }
  //上传图片
  uploadDepImg(data, text) {
    let imageData = this.dataApi.formatImage(data);
    this.opera.loading();
    this.dataApi.uploadAuthImg({ value: imageData }).then((res: any) => {
      this.opera.loadiss();
      this.auth_model[text] = res.value;
    }).catch((error) => {
      this.opera.loadiss();
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    })
  }

  // 下载文件
  downfile(link){
    window.open(link,'_blank')
  }

  //提交认证
  commitAuthClick() {
    // if (!this.isConfirm) {
    //   this.toastr.warning("请查看并同意企业认证协议");
    //   return;
    // }
    // if (this.auth_model.organization_type == '11') {
    //   this.toastr.warning("个体工商户暂未开放工作啦认证");
    //   return;
    // }
    if (!this.auth_model.organization_type) {
      this.toastr.warning("请选择单位类型");

      return;
    }
    if (this.auth_model.department_name == '') {
      this.toastr.warning('请填写公司名称')
      return;
    }
    if (this.auth_model.id_number == '' || this.auth_model.id_number == null) {
      this.toastr.warning('请填写身份证号码')
      return;
    }
    if (this.auth_model.operator_name == '' || this.auth_model.operator_name == null) {
      this.toastr.warning('请填写身份证姓名')
      return;
    }
    if (this.auth_model.id_card == '' || this.auth_model.id_card == null) {
      this.toastr.warning('请上传手持身份证')
      return;
    }
    // if (this.auth_model.id_card_back == '' || this.auth_model.id_card_back == null) {
    //   this.toastr.warning('请上传身份证人像面')
    //   return;
    // }
    if (this.auth_model.register_number) {
      if (this.regexManager.isRegister_number(this.auth_model.register_number)) {
      } else {
        this.toastr.warning('统一社会信用代码不规范');
        return;
      }
    }

    for (let list of this.materail) {
      if (this.auth_model.organization_type == list.item_code) {
        //循环判断统一社会信用代码是否必填
        for (let num of list.field_infos) {
          if (num.field == 'register_number' && num.required) {
            if (!this.auth_model.register_number) {
              this.toastr.warning("请填写统一社会信用代码");
              return;
            }
          }
        }
        // 循环判断图片资料是否必填
        for (let m of list.material_infos) {
          if (m.required) {
            if (!this.auth_model[m.material_field]) {
              this.toastr.warning("请上传" + m.material_name);
              return;
            }
          }
        }
      }
    }
    if (!this.regexManager.isIdCard(this.auth_model.id_number)) {
      this.toastr.warning("身份证号码格式错误");
      return;
    }

    if (this.config.lastIdentity.status == 3) {
      if (!this.showFailChipsIsCommit) {
        this.showFailChipsIsCommit = false;
        this.showFailChipsModel = true;
        this.startTime();
        this.isShowFailModel = false;
        return;
      }

    }

    if (this.config.lastIdentity && this.config.lastIdentity.status == 3) {
      this.dataApi.authenticationUpdatePlatformApply(this.auth_model).then((res: any) => {
        console.log("认证提交成功修改");
        //跳转提交成功页面
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        this.checkIsFirstAuth();
        this.events.publish('updateDepartmentAuthentication');
        // if(!this.config.userisBindWx){
        //   this.router.navigate(['identity-status']);
        // }else {
        //   this.router.navigate(['home/workSpase']);
        // }
        this.toastr.success('您的企业认证信息已提交，请耐心等待')
        this.router.navigate(['home/workSpase']);

      }).catch((err) => {
        console.log("修改认证提交失败", err);
        this.toastr.error(err);
        if (err == '待审核') {
          // this.router.navigate(['identity-status']);
          this.router.navigate(['home/workSpase']);

        }
      })
    } else if (this.t_lastIdentity && this.t_lastIdentity.status == 3) {
      this.dataApi.authenticationUpdatePlatformApply(this.auth_model).then((res: any) => {
        console.log("认证提交成功修改");
        //跳转提交成功页面
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        this.checkIsFirstAuth();
        this.events.publish('updateDepartmentAuthentication');
        // if(!this.config.userisBindWx){
        //   this.router.navigate(['identity-status']);
        // }else {
        //   this.router.navigate(['home/workSpase']);
        // }
        this.toastr.success('您的企业认证信息已提交，请耐心等待')

        this.router.navigate(['home/workSpase']);

      }).catch((err) => {
        console.log("修改认证提交失败", err);
        this.toastr.error(err);
        if (err == '待审核') {
          // this.router.navigate(['identity-status']);
          this.router.navigate(['home/workSpase']);

        }
      })
    } else {
      this.dataApi.authenticationPlatformApply(this.auth_model).then((res: any) => {
        console.log("认证提交成功");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        // if(this.config.lastIdentity.status == 2){
        //   this.dataApi.authenticationPlatformApplyNew().then((res:any) => {
        //     this.config.lastIdentity = res
        //   }).catch((err) => {
        //     this.toastr.error(err);
        //   })
        // }
        this.checkIsFirstAuth();
        this.events.publish('updateDepartmentAuthentication');
        // if(!this.config.userisBindWx){
        //   this.router.navigate(['identity-status']);
        // }else {
        //   this.router.navigate(['home/workSpase']);
        // }
        this.toastr.success('您的企业认证信息已提交，请耐心等待')

        this.router.navigate(['home/workSpase']);

      }).catch((err) => {
        this.toastr.error(err);
        if (err == '待审核') {
          // this.router.navigate(['identity-status']);
          this.router.navigate(['home/workSpase']);

        }
      })
    }
    console.log(this.auth_model)
  }
  //检查是否是第一次认证，且是续费认证
  checkIsFirstAuth() {
    // 如果是续费认证，获取新的lastIdentity
    // apply_renewal
    this.dataApi.getDepartment().then((res: any) => {
      if (res) {
        console.log('重新提交认证', res)
        this.dataApi.getPlatformApply().then((res: any) => {
          if (res) { this.config.lastIdentity = res; }
        }).catch((err) => {
          this.toastr.error(err);
        })
      }
    }).catch((error) => {

    });
  }
  goBackStep() {
    this.local.set('resetOneStep', true);
    // this.dialog.open(ConfirmComponent, {
    //   data: {
    //     title: '提示',
    //     message: '返回上一步后，当前页面填写内容将不会保存。',
    //     trueText:'继续返回',
    //     falseText:'取消'
    //   }
    // }).afterClosed().subscribe((isConfirmed) => {
    //   if(isConfirmed){
    //       this.router.navigateByUrl('/home/workSpase');
    //   }
    // });

    this.router.navigateByUrl('/home/workSpase');

  }
  closeFailModel() {
    this.showFailChipsModel = false;
    this.isShowFailModel = true;
    if (this.showFailChipsCommitnumberInv) {
      clearInterval(this.showFailChipsCommitnumberInv);
    }
    this.showFailChipsCommitnumber = 5;
    this.showFailChipsCommittext = "修改完毕，确定提交(5s)";
    this.showFailChipsIsCommit = false;

  }
  FailChipsCommit() {

    if (this.showFailChipsCommittext != "修改完毕，确定提交") {
      return;
    }
    this.showFailChipsIsCommit = true;
    this.commitAuthClick()
  }
  startTime() {

    let that = this;
    if (this.showFailChipsCommitnumberInv) {
      clearInterval(this.showFailChipsCommitnumberInv);
      that.showFailChipsCommitnumber = 5;

    }
    this.showFailChipsCommitnumberInv = setInterval(() => {
      that.showFailChipsCommitnumber -= 1;
      that.showFailChipsCommittext = "修改完毕，确定提交(" + that.showFailChipsCommitnumber + "s)";
      if (that.showFailChipsCommitnumber == 0) {
        that.showFailChipsCommittext = "修改完毕，确定提交";
        clearInterval(this.showFailChipsCommitnumberInv);
        that.showFailChipsCommitnumber = 5;
      }


    }, 1000)
  }
  closeFailErrModel() {
    this.isShowFailModel = !this.isShowFailModel;
  }
  shenhe_resonClick() {
    this.isShowFailModel = true;

  }
  //注销账号
  logoutAccountClick() {
    this.dialog.open(LogoutAccountModelComponent, {
      data: {}
    }).afterClosed().subscribe((isConfirmed) => {
      console.log('isConfirmed', isConfirmed)
      if (isConfirmed) {
      }
    });
  }
  //获取左侧栏目的校招顾问
  getRecruitmentSelf() {
    this.dataApi.getRecruitmentSelf().then((res: any) => {
      console.log('获取左侧栏目的校招顾问', res);
      if (res) {
        this.config.departmentSelf = res;
        this.local.set('departmentSelf', JSON.stringify(res))
      }
    }).catch((error) => {
    });
  }
  logoOut() {
    this.opera.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '您是否要退出登录？',
        trueText: '确定',
        falseText: '取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      //console.log('检测是否存在返回值');
      //console.log(isConfirmed);
      this.opera.scrollTrue();
      if (isConfirmed) {
        //this.toastr.info("请重新登录");
        this.setRoot();//不重新登录无法接收消息，踢到登录页
      }
    });

  }
  //接收错误信息返回登录
  setRoot() {
    this.dataApi.logout();
    this.router.navigateByUrl("login");
  }
  gotoMain() {
    this.router.navigate(['home/workSpase']);
  }




}
