import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
@Component({
  selector: 'app-push-college-model',
  templateUrl: './push-college-model.component.html',
  styleUrls: ['./push-college-model.component.css']
})


export class PushCollegeModelComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true }) 
  productModel: ProductModelComponent;

  public config: Config;
  constructor(
    public toastr: ToastrService,
    public opera:Opera,
    public dataApi: DataApi,
    public dialog: MatDialog,
  ) { 
    
  }
  @Input() isShow: false;//是否显示弹窗页面
  @Output() modelHidden = new EventEmitter<number>();
   // 筛选条件
   public model = {
    name:'',
    offset:0,
    limit:999,
    school_type:'',
  }
  public school_type = [];
  public schoolList = [];
  public addCollegeList = [];
  public addShow = true;
  ngOnInit(){
    console.log('ngOnInit');
    
  }

  
  initData(){
    this.addShow = true;
    this.model = {
      name:'',
      offset:0,
      limit:999,
      school_type:'',
    }
    this.mycollegeList(); //我已选的高校
    this.getSchoolType(); //高校类型
  }

  _modelHidden(){
    this.modelHidden.emit();
  }

  getSchoolType(){
    this.dataApi.getDictDate('school_type').then((res:any)=>{
      if(res){
        this.school_type = res;
      }
    }).catch((err)=>{
      console.log(err);
    })
  }

  searchClick(){
    this.model.offset = 0;
    console.log('milkround_school_type_search',this.model.school_type);
    this.getCollegeList();

  }

   //监听键盘事件
   inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    console.log('keycode',keycode);
    if(keycode==13){
      that.getCollegeList();
    }
  }

  getCollegeList(){
    this.dataApi.collegeList(this.model).then((res:any)=>{
      if(res){
        this.schoolList = res.list;
        res.list.forEach(element => {
          element.active = false;
          element.prohibit = false;
          this.addCollegeList.forEach(item =>{
            if(element.collegeid == item.collegeid){
              element.active = true;
              element.prohibit = true;
            }
          })
        });
        
      }
    }).catch((err)=>{
      console.log(err);
    })
    
  }

  itemClick(collegeid,prohibit){
    if(prohibit){
      this.toastr.warning('已确定高校不可更改');
      return
    }
    let number = 0;
    for(let item of this.schoolList){
      if(item.active){
        number +=1;
      }
    }
    
    for(let item of this.schoolList){
      if(collegeid == item.collegeid){
        if(!item.active){
          if(number >= 3 ){
            this.toastr.warning('您当前还不是会员，只能一站式管理您选择的 3 所高校招聘活动');
            return
          }
        }
        item.active = !item.active;
      }
    }
    this.toastr.warning('请谨慎选择，确定过的高校将不能修改');
  }

  mycollegeList(){
    this.dataApi.mycollegeList().then((res:any)=>{
      if(res){
        this.addCollegeList = res;
      }
      this.getCollegeList();
    }).catch((err)=>{
      console.log(err);
    })
  }

  addBtn(){
    let arry = [];
    for(let item of this.schoolList){
      if(item.active){
        arry.push(item.collegeid);
       }
    }
    if(arry.length == 0){
      this.toastr.warning('请选择高校');
      return
    }
    this.addShow = false;
  }

  cancel(){
    this.addShow = true;
  }

  addBtnSave(){
        let arry = [];
        for(let item of this.schoolList){
          if(item.active){
            arry.push(item.collegeid);
          }
        }
        this.dataApi.addCollegeList({value:arry}).then((res:any)=>{
          this.toastr.success('保存成功');
          this._modelHidden();
        }).catch((err)=>{
          console.log(err);
        })
  }

  productModelHidden(event){    
    this.isproductShowModel = false;
  }

  //点击产品
  product(alias,type){
    this.productModel.initData(alias,type);
    this.isproductShowModel = true;
  }

}
