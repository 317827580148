<!-- 用户确认操作弹出框 -->
<div class="modal-dialog">
  <div  class="modal-content-close">
    <img class="closeTitle_img" (click)="closeModelClick(3)" src="../../assets/images/chips_colse.png" />
    <div class="closeTitle">{{title}} </div>
    <div class="closeBtnView flex_c">
      <div class="fangqi" *ngIf="letBtnTitle" (click)="closeModelClick(2)">{{letBtnTitle}}</div>
      <div class="jixu" *ngIf="rightBtnTitle" (click)="closeModelClick(1)">{{rightBtnTitle}}</div>
    </div>
  </div>
</div>
