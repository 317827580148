
<div class="formCenter">
  <img class="formBanner" src="../../assets/images/form_top.png" />

  <div class="form_row marginTop40 flex_l" style="margin-top: 40px">
    <div class="form_title"><span class="form_title_Chips">*</span> 用人单位名称：</div>
    <input class="form_input" [(ngModel)]="model.name"/>
  </div>
  <div class="form_row marginTop40 flex_l">
    <div class="form_title"><span class="form_title_Chips">*</span> 用人单位所在地：</div>
    <input class="form_input" [(ngModel)]="model.address" />
  </div>
  <div class="form_row marginTop40 flex_l">
    <div class="form_title"><span class="form_title_Chips">*</span> 用人单位招聘联系人：</div>
    <input class="form_input" [(ngModel)]="model.linkman"/>
  </div>
  <div class="form_row marginTop40 flex_l">
    <div class="form_title"><span class="form_title_Chips">*</span> 招聘联系人职位：</div>
    <input class="form_input" [(ngModel)]="model.position_name"/>
  </div>
  <div class="form_row marginTop40 flex_l">
    <div class="form_title"><span class="form_title_Chips">*</span> 招聘联系人电话：</div>
    <input class="form_input"  [(ngModel)]="model.mobile"/>
  </div>
  <div class="form_row marginTop40 flex_l">
    <div class="form_title" style="position: absolute;top: 0"><span class="form_title_Chips">*</span> 校招需求简述：</div>
    <textarea class="form_textAir"  [(ngModel)]="model.desc"></textarea>
  </div>
  <div class="form_commit" (click)="commit()">提交</div>
</div>
