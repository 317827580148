import { Component, OnInit, Output, ViewChild, ChangeDetectionStrategy, ElementRef, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import { Router } from "@angular/router";
import { Events } from '../../provider/eventService';
import { DataApi, Local, Constants, Config, Opera, MyMMessage } from '../../provider/index';
import TRTC from 'trtc-js-sdk';
import QNRTC,{QNCameraVideoTrackConfig, QNVideoOptimizationMode} from "qnweb-rtc";
import { ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs-compat/operator/timeout';
import { retry } from 'rxjs-compat/operator/retry';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Observable, fromEvent } from 'rxjs';
import { ResumeLabelModelComponent } from "../resume-label-model/resume-label-model.component";
// import * as _ from 'lodash';

//import { InterviewRoom } from '../../assets/js/gwlroom';

const gwlroom = require('../../assets/js/gwlroom.js');

@Component({
  selector: 'app-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.css'],
  //changeDetection : ChangeDetectionStrategy.OnPush
})



export class InterviewComponent implements OnInit {
  @ViewChild('scrollGg') ggmsgContent: ElementRef;
  @ViewChild('scrollsL') sLmsgContent: ElementRef;
  @ViewChild('resumeLabelModel', { static: true }) resumeLabelModel: ResumeLabelModelComponent;
  @Output() addResumeLabelmodelShow = false;//是否打开弹窗
  private config: Config;
  constructor(
    public dataApi: DataApi,
    public events: Events,
    public opera: Opera,
    public local: Local,
    public toastr: ToastrService,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private ngZone: NgZone
  ) {
    this.init();
  }
  private init() {
    //获取config单例一个实例
    this.config = Config.getInstance();
  }
  public remarkText = ""; //简历批注
  public itemMsgList = {
    id: '',
    avatar: '',
    name: '',
    msg: []
  };
  public talentBaseName:'';
  public viewUserId = ""; //正在面试userid
  public roomOver = false; // true 时间到期 ，false 正常
  public roomState = true; // true 恢复， false 暂停
  public onstudentData: any = {}; //点击面试的学生信息
  public LabelList = []; //简历标签
  public wholeList: any = [];
  public roomData: any = {};
  public iscameraLens = 'hr';
  public cameraLens = false;
  public resumeDetails: any = {}; //简历详情
  public spot = false; //消息红点
  public showBox = false;
  public interviewStuts = false; //当前是否在面试中
  public msgList = []; //所有学生对话记录
  public resumeShow = false; //简历
  public chat01 = false; //私聊框显隐
  public itemStrudent: {};
  public ShowLoding = false;
  public msgText = '';
  public personnel_list = [];
  public navShow: string = "wait";
  public openNoticeShow = true; //公告显隐
  public openNoticeList = []; //公告列表
  public ggmsgText: string;
  public UserID: string = "";
  public userSig: string;
  public TRTC;
  private client;
  public localStream;
  public audio: boolean;
  public video: boolean;
  public roomId = '';
  public privateMapKey: string;
  public sdk_appid: number;
  public roomins: any;
  public resumeNumber: any;
  public remoteStream: any;
  public setTimeList = []; //记录1分钟内点击的
  public resumeLabelDetail: any = {
    resume_labels: [],
    remark: ''
  };
  public resumeid: string;

  public ggSearch = {
    Num: 10,
    EndMessageTime: ''
  }

  public slSearch = {
    Num: 10,
    EndMessageTime: '',
    FromUserId: '',
  }

  public Showaccomplish = false;

  public localTracks = [];
  public studentTracks: any;

  ngOnInit() {
    this.opera.initConfig('nav-activity', 'college-milkround');
    let hash = location.hash;
    let querys = hash.substring(hash.indexOf('?') + 1).split('&');
    let result = [];
    for (var i = 0; i < querys.length; i++) {
      var temp = querys[i].split('=');
      if (temp.length < 2) {
        result[temp[0]] = '';
      } else {
        result[temp[0]] = temp[1];
      }
    };
    if (result['roomid']) {
      this.roomId = result['roomid'];
    } else {
      this.roomId = this.local.get('roomid');
    }

    if (this.roomins) {
      this.roomins.UnbindAll();
    }
    this.getRoom(this.roomId);
    window.onbeforeunload = () => {
      console.log(this);
    }
  }

  //获取房间信息
  getRoom(roomId) {
    this.dataApi.getRoom(roomId, null).then((res: any) => {
      console.log('获取房间信息', res);
      this.roomData = res;
      this.UserID = res.owner.id;
      if (res) {
        this.gwlroomInit();
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  //gwl初始化
  async gwlroomInit() {
    let accesstoken = this.local.get(Constants.accesstoken);
    this.roomins = gwlroom.default.InitRoom(this.config.roomsignalrUrl, accesstoken, false, true);

    await this.roomins.start();
    var ret = await this.roomins.Login(this.roomId, true);

    await this.gwlChange();
    console.log(ret);
    await this.roomqueue();
    await this.getlxrContactList();
    //sdk参数
    let __data = await this.roomins.GetChatParam();
    console.log('sdk参数', __data.data);
    this.privateMapKey = __data.data.private_map_key;
    this.userSig = __data.data.user_sig;
    this.sdk_appid = __data.data.sdk_appid;
    this.trtcInit();
  }

  //获取消息联系人列表
  async getlxrContactList() {
    var ret = await this.roomins.GetContactList();
    this.ngZone.run(() => {
      this.msgList = ret.data;
      this.msgList.forEach((item) => {
        item.Unread = 0;
        item.msg = [];
      })
    });
  }

  //点击公告
  async openNotice() {
    this.ggSearch.EndMessageTime = moment().format();
    this.openNoticeList = [];
    await this.GetRoomBroadCastHistory(true);
    this.ngZone.run(() => {
      this.resumeShow = false;
      this.openNoticeShow = true;
      this.chat01 = false;
    });
  }

  //获取公告历史记录
  async GetRoomBroadCastHistory(isRef) {
    var ret = await this.roomins.GetRoomBroadCastHistory(this.ggSearch.Num, this.ggSearch.EndMessageTime);
    let that = this;
    this.ngZone.run(() => {
      this.openNoticeList = [...ret.data, ...this.openNoticeList];
      if (isRef) {
        setTimeout(() => {
          that.scrollTopChange('chatScroll-y02');
        }, 100)
      }
      console.log(this.openNoticeList);
    });
  }

  chatScrolly02Scroll(event) {
    if (this.ggmsgContent.nativeElement.scrollTop == 0) {
      if (this.openNoticeList.length > 0) {
        let itemEventTime = this.openNoticeList[0].eventTime; //获取最后一条时间
        this.ggSearch.EndMessageTime = itemEventTime;
        console.log(moment(itemEventTime).format('YYYY-MM-DD hh:mm:ss'));
        this.GetRoomBroadCastHistory(false);
      }
    }

  }

  //
  async chatScrolly01Scroll() {
    if (this.sLmsgContent.nativeElement.scrollTop == 0) {
      console.log('私聊到顶部');
      let itemEventTime = this.itemMsgList.msg[0].eventTime; //获取最后一条时间
      this.slSearch = {
        Num: 10,
        EndMessageTime: moment(itemEventTime).format(),
        FromUserId: this.itemMsgList.id,
      }
      var ret = await this.roomins.GetPrivateMsgHistory(this.slSearch.FromUserId, this.slSearch.Num, itemEventTime);
      this.ngZone.run(() => {
        ret.data.forEach((item) => {
          item.isme = true;
          if (item.fromUserId == this.itemMsgList.id) {
            item.isme = false;
          }
        })
        this.itemMsgList.msg = [...ret.data, ...this.itemMsgList.msg];
      });
    }
  }

  //gwl监听事件
  gwlChange() {
    let _that = this;

    //公告
    this.roomins.OnPublicMsg = (msg) => {
      _that.ggmsgText = '';
      console.log("收到公告新消息", msg);
      msg.content = msg.message;
      _that.openNoticeList.push(msg);
      this.cdRef.detectChanges();
    };

    //在线用户发生变化
    this.roomins.OnUserLogin = (msg) => {
      console.log("在线用户发生变化！", msg);
      _that.roomqueue();
    };

    this.roomins.OnUserSignIn = (msg) => {
      console.log("新用户签到！");
      _that.roomqueue();
    };

    //学生拒绝面试
    this.roomins.OnChatRefuse = (ret) => {
      console.log(ret);
      _that.ngZone.run(() => {
        _that.ShowLoding = false;
        _that.interviewStuts = false;
        this.personnel_list.forEach(itt => {
          itt.msz = false;
        })
        if (ret.autoRefuse) {
          //学生忙线被动挂断
          _that.toastr.error('该学生正在面试中');
        } else {
          //学生主动挂断
          _that.toastr.error('该学生拒绝面试邀请');
        }
      });

    };

    //学生同意面试
    this.roomins.OnChatAgree = () => {
      if (!this.ShowLoding) {
        return
      }
      _that.ngZone.run(() => {
        _that.ShowLoding = false;
        _that.interviewStuts = true;
        this.switchUser('talent');
      });
    };

    //收到新的私信
    this.roomins.OnMyPrivateMsg = (msg: any) => {
      console.log('收到新的私信', msg);
      let _obj = {
        isme: false, //学生的信息
        content: msg.message, //消息
        avatar: msg.fromUser.avatar,
        eventTime: msg.eventTime //消息发送时间
      }

      //
      if (this.chat01 && this.itemMsgList.id == msg.fromUser.id) {
        this.itemMsgList.msg.push(_obj);
      }
      //已有当前学生
      for (let stu of this.msgList) {
        if (stu.id == msg.fromUser.id) {
          console.log('已有当前学生', this.msgList);
          this.ngZone.run(() => {
            if (!this.chat01 || this.itemMsgList.id != msg.fromUser.id) {
              stu.Unread += 1;
              stu.msg = [];
              stu.msg.push(_obj);
            }

            let that = this;
            setTimeout(() => {
              that.scrollTopChange('chatScroll-y');
            }, 100)

            this.swchmsgList();
          });

          return
        }
      }

      this.ngZone.run(() => {
        console.log('暂无当前学生', msg.fromUser);
        msg.fromUser.msg = [];
        msg.fromUser.Unread = 0;
        msg.fromUser.msg.push(_obj);
        this.msgList.push(msg.fromUser);
        console.log('this.msgList', this.msgList);
        //是否打开
        for (let stu of this.msgList) {
          if (stu.id == msg.fromUser.id) {
            stu.Unread = stu.Unread + 1;
          }
        }

        console.log('暂无当前学生', msg.fromUser.msg);
        this.swchmsgList();
      });
    };

    //用户退出
    this.roomins.OnUserLogout = () => {
      console.log("用户退出");
      _that.roomqueue();
    };

    //学生挂断面试
    this.roomins.OnMyChatBreak = () => {
      _that.ngZone.run(() => {
        _that.interviewStuts = false;
        _that.viewUserId = "";
        _that.ShowLoding = false;
        _that.Showaccomplish = true;
        _that.personnel_list.forEach(itt => {
          itt.msz = false;
        })
        this.switchUser('hr');
      });
    };

    this.roomins.OnServerClose = (msg) => {
      _that.ngZone.run(() => {
        console.log("服务断开", msg);
        _that.toastr.error('房间已断开');
        _that.leaveRoom();
      });

    };

    //时间提醒
    this.roomins.OnRoomLeftTimeRemind = (msg) => {
      console.log('时间提醒');
      _that.timeTip(msg.left_minute);
      //房间到期提醒
      if (msg.left_minute == 0) {
        this.roomins.BreakChat(this.itemStrudent);
        _that.ngZone.run(() => {
          this.interviewStuts = false;
          this.viewUserId = "";
          this.roomOver = true;
          this.leaveRoom();
        });
      }
    };

  }

  //标记已完成面试
  invitation_student_isFinish(item) {
    console.log(item);
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '确定将当前学生标记已完成面试吗？标记后将移出等待列队！',
        trueText: '确定',
        falseText: '取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.roomins.FinishChat(item.queuedUser.user.id);
        this.ngZone.run(() => {
          this.interviewStuts = false;
          this.roomqueue();
        });
      }
    });
  }

  //学生挂断弹窗提示企业是否完成面试
  accomplishSave() {
    console.log(this.onstudentData);
    this.roomins.FinishChat(this.onstudentData.queuedUser.user.id);
    this.ngZone.run(() => {
      this.interviewStuts = false;
      this.Showaccomplish = false;
      this.roomqueue();
    });
  }


  //企业学生未接通（取消邀请） / 面试中 （中断面试）
  async cloceShowUpdate() {
    this.ShowLoding = false;
    this.personnel_list.forEach(itt => {
      itt.msz = false;
    })
    if (this.interviewStuts) {
      //通话中 二次确认
      this.dialog.open(ConfirmComponent, {
        data: {
          title: '提示',
          message: '确定中断面试吗？中断后可再次邀请，不会标记为已完成面试。',
          trueText: '确定',
          falseText: '取消'
        }
      }).afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          let ret = this.roomins.BreakChat(this.itemStrudent);
          console.log('中断面试', ret);
          this.ngZone.run(() => {
            this.client.unsubscribe(this.studentTracks);
            this.viewUserId = "";
            this.interviewStuts = false;
            this.switchUser('hr');
          });
        }
      });
    } else {
      //等待中 直接取消
      this.roomins.BreakChat(this.itemStrudent);
    }

  }

  //面试者排队数据
  async roomqueue() {
    var ret = await this.roomins.GetRoomQueue();
    if (!ret.success) {
      console.warn("获取排队数据失败，原因 %s", ret.errormessage);
      return
    }
    this.ngZone.run(() => {
      console.log(ret, '获取排队数据成功，数据');
      this.personnel_list = [];
      this.wholeList = [];
      // for(let item of ret.data){
      //   if(!item.queuedUser.isFinish && !item.queuedUser.isPassed){
      //     this.personnel_list.push(item);
      //   }
      //   this.wholeList.push(item);
      // }
      this.personnel_list = ret.data.waitQueue;
      this.wholeList = ret.data.resumeQueue;
      for (let item of this.personnel_list) {
        if (item.onlineStatus.isOnline) {
          item.onlineStatus.inRommOnLine = true;
          // break
        }
      }
      // console.log("获取排队数据成功，数据 %o", this.personnel_list);
    });
    //this.cdRef.detectChanges();
  }

  //发起面试 邀请学生进入面试间 ->高亮当前面试者
  async invitation_student(item) {
    // if(!item.onlineStatus.isOnline){
    //   this.toastr.error('该学生不在线');
    //   return
    // }
    
    if (this.interviewStuts) {
      this.toastr.error('请先结束当前面试');
      return
    }
    if (!this.roomState) {
      this.toastr.error('请先恢复面试间');
      return
    }
    this.itemStrudent = item.queuedUser.user.id;
    this.viewUserId = item.queuedUser.user.id;
    let res = await this.roomins.StartChat(this.itemStrudent);
    this.talentBaseName = item.queuedUser.user.name;
    console.log(res);
    if (!res.success) {
      this.ngZone.run(() => {
        this.toastr.error(res.errormessage);
        this.ShowLoding = false;
      });

    } else {
      this.ngZone.run(() => {
        this.ShowLoding = true;
        this.personnel_list.forEach(itt => {
          if (itt.queuedUser.user.id == item.queuedUser.user.id) {
            itt.msz = true;
          }
        })
        this.onstudentData = item;
        console.log(this.onstudentData);
        console.log('hr邀请学生进入面试间', res);
        //显示面试学生的简历
        this.resumeClick(item);
        this.roomqueue();
      });
    }

  }

  //结束当前面试
  async overInterview() {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '确定结束当前学生面试吗？结束后此学生标记已完成，并将移出等待列队！',
        trueText: '确定',
        falseText: '取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.roomins.FinishChat(this.itemStrudent);
        this.ngZone.run(() => {
          this.interviewStuts = false;
          this.viewUserId = "";
          this.personnel_list.forEach(itt => {
            itt.msz = false;
          })
          this.roomqueue();
          this.switchUser('hr');
        });
      }
    });

  }

  //标记已完成面试
  invitation_student_OperationPassed(item) {
    console.log(item);
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '确定将当前学生过号处理吗？',
        trueText: '确定',
        falseText: '取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.roomins.OperationPassed(item.queuedUser.user.id);
        this.ngZone.run(() => {
          this.interviewStuts = false;
          this.personnel_list.forEach(itt => {
            itt.msz = false;
          })
          this.roomqueue();
        });
      }
    });
  }

  //暂停面试 hr断流
  async RoomPauseview() {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '确定暂停面试间吗？',
        trueText: '确定',
        falseText: '取消'
      }
    }).afterClosed().subscribe(async (isConfirmed) => {
      if (isConfirmed) {
        let ret = await this.roomins.RoomPause(false);
        this.ngZone.run(() => {
          if (!ret.success) {
            this.toastr.error(ret.errormessage);
            return
          }
          this.roomState = false;
          this.leaveRoom();
        });
      }
    });
  }

  //恢复面试 hr推流
  recoveryview() {
    this.roomins.RoomPause(true);
    this.roomState = true;
    this.trtcInit();
  }


  trtcInit() {
    this.client = QNRTC.createClient();
    this._addTRTCEvent();
    this.joinRoom();
  }

  //加入房间(七牛)
  async joinRoom() {
    this.client.join(this.privateMapKey).then(() => {
      console.log('进房成功');
      this.starTrtc();
    }).catch(error => {
      console.error('进房失败 ' + error);
    });
  }

  //初始化Trtc
  async starTrtc() {
    this.audio = true;
    this.video = true;
    // 这个函数会返回一组audio track 与 video track
    var audioConfig = {}
    var videoConfig:QNCameraVideoTrackConfig = {
      encoderConfig: '720p',
      optimizationMode: QNVideoOptimizationMode.DETAIL
    }
    this.localTracks = await QNRTC.createMicrophoneAndCameraTracks(audioConfig, videoConfig);
    // 将刚刚的 Track 列表发布到房间中
    await this.client.publish(this.localTracks);
    console.log("publish success!");
    // 获取页面上的一个元素作为播放画面的父元素
    const localElement = document.getElementById("video-department");
    // 遍历本地采集的 Track 对象
    for (const localTrack of this.localTracks) {
      // 如果这是麦克风采集的音频 Track，我们就不播放它。
      if (localTrack.isAudio()) continue;
      // 调用 Track 对象的 play 方法在这个元素下播放视频轨
      localTrack.play(localElement, {
        mirror: true
      });
    }
    $('#video-department').children().css("object-fit",'cover');
  }


  //退房(七牛)
  async leaveRoom() {
    for (const localTrack of this.localTracks) {
      localTrack.destroy();
    }
    await this.client.unpublish(this.localTracks);
    await this.client.leave();
    console.log('退房成功');
    this.audio = true;
    this.video = true;
  }

  // 这里的参数 client 是指刚刚初始化的 QNRTCClient 对象
  async subscribe(client, tracks, userId) {
    if (this.viewUserId != userId) {
      return
    }
    // 传入 Track 对象数组调用订阅方法发起订阅，异步返回成功订阅的 Track 对象。
    this.studentTracks = tracks;
    const remoteTracks = await client.subscribe(tracks);
    console.log('新增轨道', remoteTracks);
    // 选择页面上的一个元素作为父元素，播放远端的音视频轨
    const remoteElement = document.getElementById("video-student");
    // 遍历返回的远端 Track，调用 play 方法完成在页面上的播放
    let ary = [...remoteTracks.videoTracks, ...remoteTracks.audioTracks]
    for (let remoteTrack of ary) {
      remoteTrack.play(remoteElement);
    }
    $('#video-student').children().css("object-fit",'cover');
  }

  // 增加 TRTC 事件监听
  _addTRTCEvent() {
    let _that = this;
    this.client.on("user-published", (userId, tracks) => {
      console.log("事件监听user-published!", userId, tracks);
      _that.subscribe(this.client, tracks, userId)
        .then(() => console.log("subscribe success!"))
        .catch(e => console.error("subscribe error", e));
    });


    // 用户离开房间
    this.client.on('user-left', function (remoteUserID) {
      console.log('user-left', remoteUserID,_that.viewUserId)
      if(remoteUserID != _that.viewUserId){
        return
      }
      _that.ngZone.run(() => {
        _that.interviewStuts = false;
        _that.viewUserId = "";
        _that.ShowLoding = false;
        _that.personnel_list.forEach(itt => {
          itt.msz = false;
        })
        _that.switchUser('hr');
      });
    })
  }

  //打开/关闭摄像头(腾讯)
  async triggerCamera() {
    this.video = !this.video;
    this.localTracks.forEach((track) => {
      if (track.isVideo()) {
        console.log(track);
        track.setMuted(!this.video);
      }
    });
  }

  //打开/关闭麦克风(腾讯)
  async triggerMike() {
    this.audio = !this.audio;
    this.localTracks.forEach((track) => {
      if (track.isAudio()) {
        console.log(track);
        track.setMuted(!this.audio);
      }
    });
  }

  //监听键盘事件
  inputKeyup(event) {
    let that = this;
    var keycode = window.event ? event.keyCode : event.which;
    if (event.ctrlKey && event.keyCode == 13) {
      that.msgText = that.msgText + '\n\r';
      return;
    }
    if (keycode == 13) {
      this.sengMsg();
    }
  }

  async sengMsg() {
    if (this.msgText == '') {
      console.log('空');
      return
    }
    let id = this.itemMsgList.id;
    var ret = await this.roomins.SendPrivateMsg(id, this.msgText);
    if (!ret.success) {
      alert("发送失败,原因" + ret.errormessage);
      return
    } else {
      this.ngZone.run(() => {
        let date = new Date();
        let _obj = {
          isme: true,
          avatar: '',
          content: this.msgText, //消息
          eventTime: date //消息发送时间
        }
        this.msgText = '';
        this.itemMsgList.msg.push(_obj);
      });
    }
    this.scrollTopChange('chatScroll-y');
  }

  swchmsgList() {
    let number = 0;
    this.msgList.forEach(item => {
      if (item.Unread) {
        number = number + item.Unread;
      }
    })
    console.log(number);
    if (number > 0) {
      this.spot = true;
    } else {
      this.spot = false;
    }
  }

  //点击学生私聊
  async msgstudentChat(id) {
    this.itemMsgList = {
      id: '',
      avatar: '',
      name: '',
      msg: []
    }

    this.msgList.forEach((item) => {
      if (item.id == id) {
        item.Unread = 0;
        this.itemMsgList = item;
      }
    })

    //消息列表没有此学生
    if (!this.itemMsgList.id) {
      this.personnel_list.forEach((item) => {
        if (item.queuedUser.user.id == id) {
          item.queuedUser.user.Unread = 0;
          item.queuedUser.user.msg = [];
          this.itemMsgList = item.queuedUser.user;
          this.msgList.push(item.queuedUser.user);
        }
      })
    }

    this.resumeShow = false; //关闭简历
    this.openNoticeShow = false; //关闭公告
    this.chat01 = true;
    //获取聊天记录
    this.slSearch = {
      Num: 10,
      EndMessageTime: moment().format(),
      FromUserId: id,
    }
    var ret = await this.roomins.GetPrivateMsgHistory(this.slSearch.FromUserId, this.slSearch.Num, this.slSearch.EndMessageTime);
    this.ngZone.run(() => {
      ret.data.forEach((item) => {
        item.isme = true;
        if (item.fromUserId == id) {
          item.isme = false;
        }
      })
      this.itemMsgList.msg = ret.data;
      this.swchmsgList();
      let that = this;
      setTimeout(() => {
        that.scrollTopChange('chatScroll-y');
      }, 100)
    });

  }

  //点击查看简历
  resumeClick(item) {
    this.resumeShow = true;
    this.openNoticeShow = false;
    this.chat01 = false;
    this.resumeNumber = item.queuedUser.user.resumeNumber;
    this.getResumeNumView(this.resumeNumber);
  }

  //通过简历编号获取简历详情
  getResumeNumView(resumeNumber) {
    this.remarkText = '';
    let _resumeNumber = resumeNumber;
    if (!_resumeNumber) {
      this.toastr.error("当前简历或已被删除，请刷新后重试");
      this.opera.goChangeRouter('college-resume');
      return;
    }

    this.opera.loading();
    this.dataApi.getResumeNumView({ resume_number: _resumeNumber, is_search: false + "&mrid=" }).then((res: any) => {
      console.log(res);
      this.resumeDetails = this.resumenFormat(res);
      console.log("获取到简历详情------", this.resumeDetails);
      this.opera.loadiss();
      this.resumeid = res.resumeid;
      //简历是否被收藏
      if (res.isfollow) {
        this.getRemarks(this.resumeid);
      } else {
        this.dataApi.manageResumeArchived({
          resumeid: this.resumeid
        }).then((res: any) => {
          this.getRemarks(this.resumeid);
        }).catch((error) => {
          //console.log(error);
          setTimeout(() => {
            this.toastr.error(error);
          }, 600);
        });
      }

    }).catch((error) => {
      console.log(error)
      if (error == '您还没通过的合作申请') {
        this.opera.freeAttestation("通过工作啦认证才能查看全部学生，立即认证！");//提醒认证
      } else {
        if (error.indexOf('简历不开放') >= 0) {
          this.toastr.error('该学生隐藏了自己的简历');
        }
        else if (error.indexOf('简历不存在') >= 0) {
          this.toastr.error('该学生隐藏了自己的简历');
        }
        else if (error.indexOf('用户不存在') >= 0) {
          this.toastr.error('该学生的简历已删除');
        }
        else if (error.indexOf('帐户不存在') >= 0) {
          this.toastr.error('该学生的简历已删除');
        }
        else if (error.indexOf('人才档案不存在或已删除') >= 0) {
          this.toastr.error('该学生的简历已删除');
        }
        else {
          this.toastr.error('啊哦，服务器君忙不过来了');
        }
      }
      this.opera.loadiss();
    });
  }

  //简历格式化
  resumenFormat(res) {
    res.certificates = res.certificates == null ? [] : res.certificates
    res.education_backgrounds = res.education_backgrounds == null ? [] : res.education_backgrounds
    res.project_experiences = res.project_experiences == null ? [] : res.project_experiences
    res.languages = res.languages == null ? [] : res.languages
    res.school_positions = res.school_positions == null ? [] : res.school_positions
    res.work_experiences = res.work_experiences == null ? [] : res.work_experiences
    res.works = res.works == null ? [] : res.works
    res.job_target.industry = res.job_target.industry == null ? [] : res.job_target.industry
    res.job_target.work_city = res.job_target.work_city == null ? [] : res.job_target.work_city
    for (let edu of res.education_backgrounds) {
      edu.end_date_str = moment(edu.end_date).format('yyyy.MM');
      edu.start_date_str = moment(edu.start_date).format('yyyy.MM');
    }
    for (let edu of res.certificates) {
      edu.get_date_str = moment(edu.get_date).format('yyyy.MM');
    }
    for (let edu of res.project_experiences) {
      edu.end_date_str = moment(edu.end_date).format('yyyy.MM');

      edu.start_date_str = moment(edu.start_date).format('yyyy.MM');
    }
    for (let edu of res.work_experiences) {
      edu.end_date_str = moment(edu.end_date).format('yyyy.MM');

      edu.start_date_str = moment(edu.start_date).format('yyyy.MM');
    }
    res.talent_base.birthdate_str = res.talent_base.birthdate == null ? "" : moment(res.talent_base.birthdate).format('yyyy-MM-dd');
    let industrys = "";
    for (let edu of res.job_target.industry) {
      industrys += edu.text + " ";
    }
    res.job_target.industrys = industrys;
    let work_citys = "";
    for (let edu of res.job_target.work_city) {
      work_citys += edu.text + " ";
    }
    res.job_target.work_citys = work_citys;
    if (res.talent_base.logo == null) {
      res.talent_base.logo = "";
    }
    console.log('格式化', res);
    return res
  }

  //获取简历备注
  getRemarks(resumeid) {
    this.dataApi.getResumeLabelDetail(resumeid).then((res: any) => {
      if (res.resumeid) {
        console.log('获取简历备注', res);
        this.resumeLabelDetail = res;
        this.remarkText = res.remark
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  //保存批注
  saveAnnotation() {
    if (!this.remarkText) {
      this.toastr.error('简历批注不能为空');
      return
    }
    let data = {
      resumeid: this.resumeDetails.resumeid,
      remark: this.remarkText
    }
    this.dataApi.manageResumeArchived(data).then((res: any) => {
      console.log(res);
      this.toastr.success('保存成功')
    }).catch((error) => {
      console.log(error)
    });
  }

  //切换用户全屏
  switchUser(item) {
    //当前如果全屏不做改变
    if (this.iscameraLens == item) {
      return
    }
    this.iscameraLens = item;
    this.cameraLens = !this.cameraLens
  }

  gginputKeyup(event) {
    let that = this;
    var keycode = window.event ? event.keyCode : event.which;
    if (event.ctrlKey && event.keyCode == 13) {
      that.msgText = that.msgText + '\n\r';
      return;
    }
    if (keycode == 13) {
      this.sendpublic();
    }
  }

  //发送公告
  async sendpublic() {
    if (this.ggmsgText == '') {
      return
    }
    var ret = await this.roomins.SendMsg(this.ggmsgText);
    if (!ret.success) {
      alert("发送失败,原因" + ret.errormessage);
    } else {
      this.scrollTopChange('chatScroll-y02');
    }
  };

  scrollTopChange(id) {
    var chat = document.getElementById(id);
    if (chat) {
      this.ngZone.run(() => {
        document.getElementById(id).scrollTop = chat.scrollHeight * 2;
      });
    }
  };


  //房间到期提醒
  timeTip(time) {
    this.ngZone.run(() => {
      this.toastr.warning(`本次视频面试时间还剩${time}分钟`);
    });
  }


  //获取企业所有简历标签
  getMyresumeLabelList() {
    let data = {
      offset: '0',
      limit: '40',
    }
    this.dataApi.getMyresumeLabelList(data).then((res: any) => {
      if (res) {
        this.LabelList = res.list;
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  //编辑用户标签的按钮
  editresumeLabelClick() {
    console.log('resumeLabelDetail', this.resumeLabelDetail)
    if (!this.resumeLabelDetail && !this.resumeLabelDetail.resumeid) {
      return;
    }
    this.resumeLabelModel.init(this.resumeLabelDetail);
    this.addResumeLabelmodelShow = true;
  }

  addResumeLabelmodeHidden(resume) {
    let that = this;
    this.addResumeLabelmodelShow = false;
    if (resume) {
      console.log('resume', resume,)
    }
    setTimeout(() => {
      this.getRemarks(this.resumeid);
      that.events.publish('updateResumeLabelNot'); //发送退出登录选项
    }, 1200)
  }

  //页面销毁
  async ngOnDestroy() {
    await this.leaveRoom();
    let ret = await this.roomins.Logout();
    await this.roomins.Close();
    console.log('页面销毁页面销毁页面销毁页面销毁页面销毁', ret);
    // this.localStream.close();
  }

  //退出页面二次确认
  canleave(): Observable<boolean> | boolean {
    return this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '离开后视频将断开，确定离开？',
        trueText: '确定',
        falseText: '取消'
      }
    }).afterClosed()

  }

  //通知不在线学生
  async invitation_student_notice(item) {
    //判断是否重复点击（1分钟内不可重复点击）
    console.log('item', item);
    let id = item.queuedUser.user.id;
    const findCell = this.setTimeList.find(element => element.id == id);
    if (!findCell) {
      let objk = {
        id: item.queuedUser.user.id,
        name: item.queuedUser.user.name,
        time: moment().format(),
      }
      this.setTimeList.push(objk);
    } else {
      //小于1分钟
      if (moment().diff(moment(findCell.time), 'seconds') < 10) {
        this.toastr.error(`【${findCell.name}】1分钟内不可重复通知`);
        return
      } else {
        this.setTimeList.forEach(item => {
          if (item.id == id) {
            item.time = moment().format();
          }
        })
      }
    }
    var ret = await this.roomins.SendRemind(id);
    try {
      if (ret.success) {
        let _that = this;
        this.ngZone.run(() => {
          _that.toastr.success(`【${item.queuedUser.user.name}】的面试通知已发送`)
          _that.roomqueue();
        });
      }
    } catch (err) {
      console.log(err);
    }

  }

}
