<!-- 二级单选 -->
<!-- 遮罩层公共样式 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px','width':'700px'}" [(visible)]="isShow" (onHide)="dismiss(false)" [modal]=true>
    <div class="model-cont">
        <div class="model-head clear">
            <div class="left">{{title}}</div>
            <div class="clos" (click)="dismiss(false)"><img src="/assets/images/rights/contactMeClos.png"></div>
        </div>
        <div class="select-more-block  clear">
            <div id="first-level" class="first-level" style="overflow-y: scroll;">
                <div class="list-block" *ngFor="let item of firstLevel" (click)="getFirstLevel(item)" [class.activeAddress]="item.code==oneActive.code">
                    {{ item.text }}
                </div>
            </div>

            <div #secend id="secend-level" class="secend-level" style="overflow-y: scroll;">
                <div class="list-block" *ngFor="let item of secendLevel" (click)="getSecendLevel(item)" [class.activeAddress]="item.code==twoActive.code">
                    <div class="list-text">{{ item.text }}</div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- <div class="model-block select-block" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>
    <div class="model-cont">
        <div class="model-head clear">
            <div class="left">{{title}}1</div>
            <button class="right" (click)="dismiss(false)">×</button>
        </div>
        <div class="select-more-block  clear">
            <div id="first-level" class="first-level" style="overflow-y: scroll;">
                <div class="list-block" *ngFor="let item of firstLevel" (click)="getFirstLevel(item)" [class.activeAddress]="item.code==oneActive.code">
                    {{ item.text }}
                </div>
            </div>

            <div #secend id="secend-level" class="secend-level" style="overflow-y: scroll;">
                <div class="list-block" *ngFor="let item of secendLevel" (click)="getSecendLevel(item)" [class.activeAddress]="item.code==twoActive.code">
                    <div class="list-text">{{ item.text }}</div>
                </div>
            </div>
        </div>
    </div>
</div> -->