<div class="guide-page" style="background-color: #F7F7F7;overflow: hidden;">
    <router-outlet></router-outlet>
    <app-navigation-route></app-navigation-route>
    <div class="box">
        <div class="d_f jc_sb">
            <div class="custom_tabs" style="margin-left: 20px;">
                <div (click)="tabClick('1')" class="custom_tab" [ngClass]="{active:tab=='1'}">注销当前手机账号</div>
                <div (click)="tabClick('3')" class="custom_tab" [ngClass]="{active:tab=='3'}">注销当前邮箱账号</div>
                <div (click)="tabClick('2')" class="custom_tab" [ngClass]="{active:tab=='2'}">注销公司其他账号</div>
            </div>
            <div class="history" (click)="gotoHistory()"> <img style="width:16px;height: 16px;margin-right: 2px;margin-top: -2px " src="../../assets/images/zhuxiao_icon.png" />注销历史</div>
        </div>
        <!-- <div class="tabBg">
            <div [ngClass]="{tabBg_span_s:tab=='1',tabBg_span:tab!='1'}" (click)="tabClick('1')">注销当前账号</div>
            <div [ngClass]="{tabBg_span_s:tab=='2',tabBg_span:tab!='2'}" (click)="tabClick('2')">注销公司其他账号</div>
            <div class="history" (click)="gotoHistory()"> <img style="width:16px;height: 16px;margin-right: 2px;margin-top: -2px " src="../../assets/images/zhuxiao_icon.png" />注销历史</div>
        </div> -->
        <!--注销自己的账号-->
        <div *ngIf="tab=='1' && zhuxiaoList.length==0">
            <!-- 注销账号 -->
            <div class="d_f ai_fs jc_c account_logout_cell">
                <div class="d_f account_logout_cell_title">注销账号<span class="remind_red">（必填 ）</span></div>
                <div class="f_1 account_logout_cell_inputbox">
                    <div class="account_logout_cell_input">
                        <input class="w_100 input" disabled type="text" [(ngModel)]="model.phone" placeholder="请填写联系电话">
                    </div>
                    <div class="account_logout_cell_explain"></div>
                </div>
            </div>
            <!-- 验证码 -->
            <div class="d_f ai_fs jc_c account_logout_cell">
                <div class="d_f account_logout_cell_title">验证码<span class="remind_red">（必填 ）</span></div>
                <div class="f_1 account_logout_cell_inputbox">
                    <div class="d_f">
                        <div class="f_1 account_logout_cell_input">
                            <input class="w_100 input" type="text" [(ngModel)]="model.sms" placeholder="请填写验证码">
                        </div>
                        <div class="custom_btn_primary sengMsg" style="background-color: #5e4fff;" (click)="sendSmsClick()">{{sendBtnTitle}}</div>
                    </div>
                    <div class="account_logout_cell_explain"></div>
                </div>
            </div>
            <div class="custom_btn_primary commitBtn active" *ngIf="!mobile_actived">确定</div>
            <div class="custom_btn_primary commitBtn" (click)="commit()" *ngIf="mobile_actived">确定</div>
            <p class="mobile_actived" *ngIf="!mobile_actived">手机号未激活，请先激活手机号！</p>
        </div>

        <!--邮箱注销-->
        <div *ngIf="tab=='3' ">
            <!-- 注销邮箱 -->
            <div class="d_f ai_fs jc_c account_logout_cell">
                <div class="d_f account_logout_cell_title">注销账号<span class="remind_red">（必填 ）</span></div>
                <div class="f_1 account_logout_cell_inputbox">
                    <div class="account_logout_cell_input">
                        <input class="w_100 input" disabled type="text" [(ngModel)]="emailModel.email" placeholder="请填写邮箱">
                    </div>
                    <div class="account_logout_cell_explain"></div>
                </div>
            </div>
            <!-- 验证码 -->
            <div class="d_f ai_fs jc_c account_logout_cell">
                <div class="d_f account_logout_cell_title">验证码<span class="remind_red">（必填 ）</span></div>
                <div class="f_1 account_logout_cell_inputbox">
                    <div class="d_f">
                        <div class="f_1 account_logout_cell_input">
                            <input class="w_100 input" type="text" [(ngModel)]="emailModel.vcode_text" placeholder="请填写验证码">
                        </div>
                        <div class="custom_btn_primary sengMsg" style="background-color: #5e4fff;" (click)="sendEmailClick()">{{sendBtnTitle}}</div>
                    </div>
                    <div class="account_logout_cell_explain"></div>
                </div>
            </div>
            <div class="custom_btn_primary commitBtn active" *ngIf="!email_actived">确定</div>
            <div class="custom_btn_primary commitBtn" (click)="commitEml()" *ngIf="email_actived">确定</div>
            <p class="mobile_actived" *ngIf="!email_actived">邮箱未激活，请先激活邮箱！</p>
        </div>

        <!--注销他人的账号并且进行了高校云认证-->
        <div *ngIf="tab=='2'&&is_certified">
            <!-- 公司名称 -->
            <div class="d_f ai_fs jc_c account_logout_cell">
                <div class="d_f account_logout_cell_title">公司名称<span class="remind_red">（必填 ）</span></div>
                <div class="f_1 account_logout_cell_inputbox">
                    <div class="account_logout_cell_input">
                        <input class="w_100 input" disabled type="text" [(ngModel)]="model.department_Name" placeholder="请填写联系电话">
                    </div>
                    <div class="account_logout_cell_explain"></div>
                </div>
            </div>
            <!-- 营业执照 -->
            <div class="d_f ai_fs jc_c account_logout_cell">
                <div class="d_f account_logout_cell_title">营业执照<span class="remind_red">（必填 ）</span></div>
                <div class="f_1 account_logout_cell_inputbox">
                    <label class="account_logout_upload_box" *ngIf="!model.zhizhao" for="uploadDepatmentLogoImg1">
                        <img class="account_logout_upload_addbtn" src="assets/images/v3/companyPush.png">
                    </label>
                    <label class="account_logout_upload_box" *ngIf="model.zhizhao" for="uploadDepatmentLogoImg1">
                        <img class="account_logout_upload_img" [src]="model.zhizhao">
                    </label>
                    <input class="uploadImg" id="uploadDepatmentLogoImg1" (change)="getPicData($event,'zhizhao')" type="file" accept="image/jpeg,image/jpg,image/png">
                    <div class="account_logout_cell_explain"></div>
                </div>
            </div>
            <!-- 社会统一信用代码 -->
            <div class="d_f ai_fs jc_c account_logout_cell">
                <div class="d_f account_logout_cell_title">社会统一信用代码<span class="remind_red">（必填 ）</span></div>
                <div class="f_1 account_logout_cell_inputbox">
                    <div class="account_logout_cell_input">
                        <input class="w_100 input" type="text" [(ngModel)]="model.xinyong" placeholder="请填写社会统一信用代码">
                    </div>
                    <div class="account_logout_cell_explain"></div>
                </div>
            </div>
            <!-- 账号注销声明 -->
            <div class="d_f ai_fs jc_c account_logout_cell">
                <div class="d_f account_logout_cell_title">账号注销声明<span class="remind_red">（必填 ）</span></div>
                <div class="f_1 account_logout_cell_inputbox">
                    <label class="account_logout_upload_box" *ngIf="!model.shuoming" for="uploadDepatmentLogoImg2">
                        <img class="account_logout_upload_addbtn" src="assets/images/v3/companyPush.png">
                    </label>
                    <label class="account_logout_upload_box" *ngIf="model.shuoming" for="uploadDepatmentLogoImg2">
                        <img class="account_logout_upload_img" [src]="model.shuoming">
                    </label>
                    <input class="uploadImg" id="uploadDepatmentLogoImg2" (change)="getPicData($event,'shuoming')" type="file" accept="image/jpeg,image/jpg,image/png">
                    <div class="account_logout_cell_explain"></div>
                </div>
            </div>
            <p style="text-align: center;color: #B1B4BE"><a style="color: #B1B4BE" href="../../assets/「工作啦」账号注销委托书.doc">下载《账号注销声明》</a></p>
        </div>

        <!--注销其他的账号/未进行高校云认证-->
        <div *ngIf="tab=='2'&& !is_certified" style="padding: 60px 0">
            <div *ngIf="config.lastIdentity.status==3">
                <p style="text-align: center;font-size: 18px;color: #666666">认证未通过</p>
                <p style="text-align: center;font-size: 16px;color: red">原因:{{config.lastIdentity.remark}}</p>
                <div class="custom_btn_primary commitDepartment" (click)="gotoAuthentication(3)">去认证</div>
            </div>
            <div *ngIf="config.lastIdentity.status==0">
                <p style="text-align: center;font-size: 18px;color: #666666">您暂未通过认证，暂无权注销公司其他账号。</p>
                <div class="custom_btn_primary commitDepartment" (click)="gotoAuthentication(3)">去认证</div>
            </div>
            <div *ngIf="config.lastIdentity.status==1">
                <p style="text-align: center;font-size: 18px;color: #666666">审核中</p>
                <div class="custom_btn_primary commitDepartment" (click)="gotoAuthentication(1)">去看看</div>
            </div>
        </div>
    </div>

    <div *ngIf="tab=='2'">
        <div *ngIf="is_certified">
            <div *ngFor="let n of zhuxiaoList;let i = index;" class="custom_card hover zhuxiao_cell">
                <img class="zhuxiao_cell_img" src="{{n.logo}}" />
                <ul style="margin-top: 10px;">
                    <li style="font-size:18px;color: #666666;">{{n.name}}</li>
                    <li style="font-size:16px;color: #999999;">账号类型：企业端 &nbsp;&nbsp;&nbsp;&nbsp;手机号：{{n.mobile}} &nbsp;&nbsp;&nbsp;&nbsp;姓名：{{n.linkman}}
                    </li>
                    <li style="font-size:16px;color: #999999;margin-top: 10px"><img style="width:14px;height: 14px;margin-top: -3px;" src="../../assets/images/zhuxiao_icon.png" /> 最后登录时间: {{n.last_online_time | date:'yyyy-MM-dd HH:mm:ss'}}</li>
                </ul>
                <div class="custom_btn_plain" style="float: right;width:160px;height: 40px;margin-top: -60px;margin-right: 20px" (click)="zhuxiaoClick(n)">注销账号</div>
            </div>
        </div>
    </div>

    <div *ngIf="tab=='1'">
        <div *ngFor="let n of zhuxiaoList;let i = index;" class="custom_card hover zhuxiao_cell">
            <img class="zhuxiao_cell_img" src="{{n.logo}}" />
            <ul style="margin-top: 10px;">
                <li style="font-size:18px;color: #666666;">{{n.name}}</li>
                <li style="font-size:16px;color: #999999;">账号类型：企业端 &nbsp;&nbsp;&nbsp;&nbsp;手机号：{{n.mobile}} &nbsp;&nbsp;&nbsp;&nbsp;姓名：{{n.linkman}}
                </li>
                <li style="font-size:16px;color: #999999;margin-top: 10px"><img style="width:14px;height: 14px;margin-top: -3px;" src="../../assets/images/zhuxiao_icon.png" /> 最后登录时间: {{n.last_online_time | date:'yyyy-MM-dd HH:mm:ss'}}</li>
            </ul>
            <div class="custom_btn_plain" style="float: right;width:160px;height: 40px;margin-top: -60px;margin-right: 20px" (click)="zhuxiaoClick(n)">注销账号</div>
        </div>
    </div>
</div>

<!--询问是否注销弹窗-->
<p-dialog [showHeader]="false" [dismissableMask]="false" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isZhuxiaoAlert" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':560,'height':280,'minHeight':280}"
    [modal]=true>
    <div class="system_out_bg">
        <div style="height: 90px;margin: 70px 77px 50px 77px">
            <img class="system_out_diannao" src="../../assets/images/zhuxiao_waring.png" />
            <li style="margin-left: 30px;font-size: 20px;color: #5E4FFF">确定要注销账号吗？</li>
            <li style="margin-left: 30px;margin-top: 5px;"><span style="color: #666666;font-size: 14px;">警告：账号注销后，学生投递过的简历、申请过 的院校合作、报名（通过）的活动等信息记录均
                    将删除，且不可恢复，请谨慎注销！</span></li>
        </div>
        <div style="width: 100px;height: 40px;float: left;margin-left:140px;background-color: #5e4fff;color: white;text-align: center;line-height: 40px" (click)="commit_zhuxiao()">确定</div>
        <div style="width: 100px;height: 40px;float: right;margin-right:140px;background-color: #BCBCBE;color: white ;text-align: center;line-height: 40px" (click)="cancel_zhuxiao();">取消</div>
    </div>
</p-dialog>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isBuyUserAlert" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':500,'height':300,'minHeight':300}"
    [modal]=true>
    <!--15 边距-->
    <div class="system_out_bg_xiaozhao">
        <p class="xiaozhao_title">当前注销账号为付费用户账号，还有未使用的校招服务，如需注销请先联系工作啦客服部</p>
        <div class="guwen_cord" *ngIf="guwen">
            <div style="float: left;width:230px;margin-left: 25px;margin-top: 17px;height: 116px">
                <div style="height: 50px;width:230px;margin-bottom: 15px;">
                    <img style="width: 50px;height: 50px;position: absolute" src="{{guwen.avatar}}" />
                    <li style="margin-left: 60px;"><img style="width: 120px;height: 20px" src="../../assets/images/zhuxiaokefu.png"></li>
                </div>
                <li>
                    <img style="width: 17px;height: 17px" src="../../assets/images/kefu_wx.png">
                    <span class="guwen_info_title">微信：</span>
                    <span class="guwen_info_wx">{{guwen.wx}}</span>
                </li>
                <li style="width: 230px">
                    <img style="width: 17px;height: 17px" src="../../assets/images/kefu_email.png">
                    <span class="guwen_info_title">邮箱：</span>
                    <span class="guwen_info_wx">{{guwen.email}}</span>
                </li>
            </div>
            <div style="float: right;width:116px;height: 116px;margin-top: 17px;margin-right: 25px;">
                <img style="width:116px;height: 116px" src="{{guwen?.qr_code}}">
            </div>
        </div>
    </div>
</p-dialog>