import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { DataApi, Local, Constants, Config, Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmsComponent } from "../confirms/confirms.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { scrollTool } from "../../provider/scrollTool";
import { NoneVipAlertComponent } from "../none-vip-alert/none-vip-alert.component";
import { Events } from '../../provider/eventService';
import { productManager } from "../../provider/productManager";
import * as moment from 'moment';
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
import { retry } from 'rxjs-compat/operator/retry';
import { GalleriaModule } from 'primeng/galleria';
declare let laydate;
let effect_time = '';
var refreshLayui;
declare var layui;
var _carousel = null;
@Component({
  selector: 'app-position-manage',
  templateUrl: './position-manage.component.html',
  styleUrls: ['./position-manage.component.css']
})
export class PositionManageComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true })
  productModel: ProductModelComponent;

  public config: Config;
  public keywordList = [];//搜索关键词匹配列表
  public hotCity = [];//热门城市
  public positionList = [];//职位列表
  public searchModel = '';//搜索关键词
  public position_refresh = null;
  public recharge_category_code = null;
  public isShowPublishModel = false;
  public isShowModel = false;
  public isShowPublishVip = false;
  public positionObj = null;
  public isShowTiming = false;
  public isPosotionListModel = false;
  public dropList = [];
  public upData = {
    positionids: [],
    begin_date: '',
    end_date: '',
    time: '',
    daily_refresh_count: 1,
  }
  public refreshContspot: number = 0;
  public isShowimmediately = false;
  public position_refreshCont = 0;
  public isShowSuces = false;
  public daysList = [];
  public showtimingBox = false;
  public getrefreshinfoTimeList = [];
  public itemDates = null;
  public departmentData = null;
  public orShow = true;
  public rushAllPositionsShow = false;
  public confirm_entrust_tip = false;
  // 筛选条件
  public model = {
    status: 'release',
    name: '',
    offset: 0,
    limit: 9
  }
  public allPositionCount = 0;
  public countList = 0;//列表数量
  public ismodify = false;
  public topingbalance: 0;
  public showToping = false;
  public showTopingTip = false;
  public showTopingTiptext = '';
  public isShowTimes = false;
  public TopNumber = 0;
  public timesData = {
    dateList_notop: [],
    dateList_topped: [],
  };
  //7天置顶参数
  public topingData = {
    positionid: '',
    start_time: '',
    end_time: '',
    name: '',
  }
  public posterShow = false; //职位海报弹窗
  public posterImg = ''; //海报链接
  public posterName = '';
  public posterItem = null;
  public posterNumber = 'p1';
  public loginUser = null;

  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)

  public isShowPushVipIcon = false;
  public isShowMemberMoudel = true;
  public dateList_noref: any = [];
  public dateList_refed: any = [];
  public OfflineShow = false;
  public tipText = '';
  public OfflineItem = null;
  public cars = [{
    url: '/assets/images/v3/poster01.png'
  }, {
    url: '/assets/images/v3/poster02.png'
  }, {
    url: '/assets/images/v3/poster03.png'
  }];
  public startDateL = null;
  public endMin = null;
  constructor(public dataApi: DataApi,
    public local: Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public opera: Opera,
    public dialog: MatDialog,
    public scrolltool: scrollTool,
    public events: Events,
    public productManager: productManager,
    public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
    this.opera.initConfig('position', 'position-manage');

    activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.isShow) {
        this.dataApi.getPlatformApply().then((res: any) => {
          if (res) {
            if (res.status == 0) {

            } else if (res.status == 1) {

            } else if (res.status == 2) {
              this.isShowPublishModel = true
            } else if (res.status == 3) {

            }
          }
        }).catch((error) => {
          console.log(error);
          //this.toastr.error(error);
        });
      }
    })
  }

  ngOnInit() {
    let that = this;
    this.opera.initConfig('position', 'position-manage');
    this.getPositionList();//获取职位列表
    // this.getStopPositionAllList(); //如果有下线的职位 弹出非vip弹窗
    this.getDepartment();
    this.getProductList();

    this.events.subscribe('addPositionSuccess', () => {
      console.log("addPositionSuccess");
      this.getPositionList();//获取职位列表
    });
    setTimeout(() => {
      if (this.config.isNavActive == 'login') { return; }
      that.opera.initConfig('position', 'position-manage');

      console.log('this.config.isNavActive', this.config.isNavActive)
      console.log('this.config.isNavActive', this.config.isNavChildActive)
    }, 800)
    this.events.subscribe('position-push-cancal-scrollTop', () => {
      console.log('position-push-cancal-scrollTop')
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    });
    this.opera.vipState((isPay) => {
      this.isShowMemberMoudel = isPay;
    })
    let department = this.dataApi.getLocalDepartment();

    if (!department) {
      this.isShowPushVipIcon = true
    } else {
      if (!department.member_info) {
        this.isShowPushVipIcon = true
      }
      this.departmentData = department;
    }

  }

  //点击产品
  product(alias, type) {
    this.isproductShowModel = true;
    this.productModel.initData(alias, type);
  }

  productModelHidden(event) {
    this.isproductShowModel = false;
  }

  //搜索
  goSearch(data) {
    this.model.name = data;
    this.getPositionList();//获取职位列表
    this.keywordList = [];
  }

  //监听键盘事件
  inputKeyup(event) {
    let that = this;
    var keycode = window.event ? event.keyCode : event.which;
    if (keycode == 13) {
      that.goSearch(that.model.name);
    } else {
      that.getItems();
    }
  }
  //筛选函数
  getItems() {
    let filter = /^[a-zA-Z\u4e00-\u9fa5]+$/;
    if (this.model.name) {
      if (filter.test(this.model.name)) {
        // this.isSearch = true;
        this.dataApi.positionNameMatch(this.model.name).then((res: any) => {
          //  //console.log("匹配职位名称");
          //  //console.log(res);
          this.keywordList = res;
        }).catch((err) => {
          //console.log(err);
        });
      } else {
        let n = String(this.model.name).length - 1;
        this.model.name = this.model.name.slice(0, n);
      }
    } else {
      this.keywordList = [];
      //  this.isSearch = false;
    }
  }

  //发布职位
  goPublish() {
    this.opera.loading();
    this.dataApi.getEmployment().then((res: any) => {
      console.log('职位管理', res)
      this.opera.loadiss();
      if (res) {
        this.dataApi.setLocalDepartment(res);
        if (res.quota.position == 0) {
          this.toastr.warning("今日发布职位数量已达上限");
          return;
        } else {
          this.local.remove(Constants.positionid);
          this.opera.gotoPositionPush();
        }
      }
    }).catch((error) => {
      this.opera.loadiss();
      this.toastr.error(error);
    });
  }

  goRouter(n) {
    //this.opera.scrollTop();
    this.router.navigate(['home/' + n]);
  }

  //获取企业信息
  getDepartment() {
    this.opera.loading();
    this.dataApi.getcomponentLeft({}).then((res: any) => {
      let arr = [];
      res.map((item) => {
        if (item.alias == 'position_refresh') {
          this.position_refresh = item;
        }
      })
    }).catch((err) => {
      console.log(err);
    })
  }

  //获取产品列表
  getProductList() {
    //product_category:"recharge",recharge_category:''
    this.opera.loading();
    this.dataApi.departmentList({ product_category: "recharge" }).then((res: any) => {
      this.opera.loadiss();
      if (res && res.list != null) {
        console.log('获取产品列表', res)
        for (let item of res.list) {
          if (item.recharge_category == "position_refresh") {
            this.recharge_category_code = item.code
          }
        }
      }
    }).catch((err) => {
      console.log(err);
      this.opera.loadiss();
    })
  }

  goBuy() {
    this.opera._hmtPush("职位管理", "刷新职位购买", "")
    this.config.userInitiativePayName = 'position_refresh';

    this.opera.checkMember((isPay) => {
      if (isPay) {
        if (this.recharge_category_code) {
          if (!this.config.isShowPaySystem) {
            this.config.payVipHistoryPage = null
          }
          this.local.set("recharge_category_code", JSON.stringify(this.recharge_category_code));
          // this.router.navigate(['home/buyProduct']);
          this.router.navigate(['home/tool-campus']);
        } else {
          this.toastr.error('暂无对应产品包');
        }
      } else {
        this.opera.checkMemberAlertWithType("noneVip_voice_call")
      }
    })
  }

  publishSuc() {
    this.isShowPublishModel = false;
  }

  //查看详情
  goDeatils(id) {
    this.local.remove(Constants.positionid);
    this.router.navigate(['home/position-publish'], {
      queryParams: {
        id: id
      }
    });
  }

  //生成海报
  poster(item) {
    this.loginUser = JSON.parse(this.local.get('loginUser'));
    this.posterShow = true;
    this.posterItem = item;
  }

  downloadPoster() {//下载图片地址和图片名
    this.opera.loading();
    this.posterName = this.posterItem.name;
    let name = this.posterName;
    let data = {
      positionid: this.posterItem.positionid,
      poster_type: this.posterNumber
    }
    this.dataApi.getPosterImg(data).then((res: any) => {
      this.opera.loadiss();
      console.log(res.value);
      this.posterImg = res.value;
      let imgsrc = res.value;
      let image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        let a = document.createElement("a"); // 生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    }).catch((error) => {
      this.opera.loadiss();
      this.toastr.error(error);
    });
  }

  closposter() {
    this.posterShow = false;
  }

  //选择状态
  chooseStatus(type) {
    this.model.status = type;
    this.getPositionList();
  }

  //获取职位列表
  getPositionList() {
    // console.log('moment',moment('2018-09-27').format('YYYY-MM-DD'));
    this.opera.loading();
    this.dataApi.getPositionList(this.model).then((res: any) => {
      this.opera.loadiss();
      if (res && res != null) {
        console.log("获取职位列表-----", res.list);
        this.positionList = res.list;
        this.countList = res.count;
        this.allPositionCount = res.count;
        this.pageCount = Math.ceil(res.count / 9);
        if (this.model.status == 'release') {
          //刷新职位列表时更新本地存储
          this.config.positionList = [];
        }
        let nowData = new Date();
        for (let val of res.list) {
          if (this.model.status == 'release') {
            if (val.refresh_info && val.refresh_info.dates.length > 0) {
              let timing = moment(val.refresh_info.dates[0]).format('YYYY-MM-DD');
              let now = moment().format('YYYY-MM-DD');
              if (timing == now) {
                val.refresh_timing_state = 1;
              } else {
                val.refresh_timing_state = 2;
              }
            } else {
              //此职位未设置刷新
              val.refresh_timing_state = 0;
            }
            if (moment(moment(val.refresh_time).format('YYYY-MM-DD HH:mm:ss')).isBefore(moment(val.release_time).format('YYYY-MM-DD HH:mm:ss'))) {
              val.refreshTime = val.release_time;
            } else {
              val.refreshTime = val.refresh_time;
            }
            if (val.position_status == "release") {
              this.config.positionList.push(val);
            }
          }

          val.education_text = this.opera.educationFormat(val.education_text)
          //发布此类职位的用人单位
          val.value_department = 0;
          val.value_talent = 0;
          val.value_ratio = 0;
          val.sign = null;
          val.isEffect = false;
          val.EffectTime = '';
          if (val.majors == null) {
            val.majors = []
          }

          let EffectData = new Date(val.effect_time);
          let spaceTime = (EffectData.getTime() - nowData.getTime()) / 1000;
          let MAXTime = 7 * 60 * 60 * 24;
          let FirstTime = 60 * 60 * 24;
          if (MAXTime > spaceTime) {
            //快到期，不足7天
            val.isEffect = true;
            let spaceTime_int = parseInt(spaceTime + '');
            val.EffectTime = "";
            let day = spaceTime_int / 24 / 60 / 60;
            let xiaoshi = spaceTime_int / 60 / 60;
            let fenzhong = spaceTime_int / 60;
            // console.log('还有几天到期',day+'天',spaceTime_int,xiaoshi+'小时');

            if (MAXTime > spaceTime && spaceTime > FirstTime) {
              //如果大与1天小与7天
              val.EffectTime = "职位还有" + parseInt(day + '') + "天到期";
            } else if (xiaoshi > 1 && xiaoshi < 24) {
              val.EffectTime = "职位还有" + parseInt(xiaoshi + '') + "小时到期";
            }
            else if (fenzhong > 1 && fenzhong <= 60) {
              val.EffectTime = "职位还有" + parseInt(fenzhong + '') + "分钟到期";
            } else {
              val.EffectTime = "职位即将到期";
            }
            if (spaceTime_int <= 0) {
              val.EffectTime = "职位已到期";
            }
          }
          this.dataApi.getKanbanData(val.post_type).then((res: any) => {
            if (res) {
              val.value_department = this.toThousands(res.department);
              val.value_talent = this.toThousands(res.talent);
              val.value_ratio = res.ratio;
              val.sign = res.sign;
            }
          }).catch((err) => {
            // console.log('getKanbanData',err);
          })
        }
        console.log("获取职位列表-----", res.list);
        this.dataApi.setLocalpositionList(this.config.positionList);//在期职位列表本地存储
      }


    }).catch((error) => {
      this.toastr.error(error);
      this.opera.loadiss();
    });
  }

  // 获取账号所有的已下线的职位
  getStopPositionAllList() {
    this.dataApi.getPositionList({ status: 'stop' }).then((res: any) => {
      console.log('getStopPositionAllList', res)
      if (res) {
        // 如果没有会员 并且发布过职位 提示 职位下线通知
        if (!this.config.department.member_info && res.count > 0) {
          this.opera.checkMemberAlertWithType("memberOutPositionStopChip")
        } else if (this.config.department.member_info && res.count > 0) {
          // 用户信息有会员/并且不是公益会员，但是已经到期
          let nowData = new Date();
          if (this.config.department.member_info && this.config.department.member_info.product_code != 'member_welfare') {
            let endtime = new Date(this.config.department.member_info.endtime);
            if (nowData >= endtime) {
              // 用户会员过期，提示  职位下线通知
              this.opera.checkMemberAlertWithType("memberOutPositionStopChip")
            }
          }
        }
      }
    }).catch((error) => {

    });
  }

  //职位下线
  goExpired(n) {
    this.OfflineShow = true;
    this.tipText = '您确认要下线该职位吗？';
    this.OfflineItem = n;
    //刷新和置顶同时存在
    if ((n.refresh_timing_state == 2 || n.refresh_timing_state == 1) && (n.top_state == 2 || n.top_state == 1)) {
      this.tipText = '该职位正在执行的刷新任务和置顶任务，您确认要下线该职位吗？';
    }
    //置顶中
    if (n.top_state == 2 || n.top_state == 1) {
      this.tipText = '该职位正在执行的置顶任务，您确认要下线该职位吗？';
    }
    //刷新中
    if (n.refresh_timing_state == 2 || n.refresh_timing_state == 1) {
      this.tipText = '该职位正在执行的刷新任务，您确认要下线该职位吗？';
    }
  }

  downPosition() {
    this.dataApi.managePositionExpired(this.OfflineItem.positionid).then((res: any) => {
      //console.log(res);
      setTimeout(() => {
        this.OfflineShow = false;
        this.toastr.success('下线成功');
      }, 600);
      this.opera.setPositionListByExpired(this.OfflineItem.positionid);//更新在期职位本地存储
      this.getPositionList();
    }).catch((error) => {
      //console.log(error);
      setTimeout(() => {
        this.toastr.error(error);
      }, 600);
    });
  }


  //职位下线
  goDelete(n) {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: this.positionList.length == 1 ? '当前只有一个职位，删除后将无法为你匹配学生' : '删除职位后将不再匹配对位学生，确定删除吗？',
        trueText: '确定',
        falseText: '取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      //console.log(isConfirmed,'点击确定');
      if (isConfirmed) {
        this.dataApi.deletePosition(n.positionid).then((res: any) => {
          //console.log(res);
          setTimeout(() => {
            this.toastr.success('删除成功');
          }, 600);
          this.opera.setPositionListByDelete(n.positionid);//更新在期职位本地存储
          this.getPositionList();
        }).catch((error) => {
          //console.log(error);
          setTimeout(() => {
            this.toastr.error(error);
          }, 600);
        });
      }
    });
  }


  //职位重发检查剩余可发布职位数量
  goResend(n) {
    // let that = this;
    // this.opera.checkMember((isPay)=>{
    //   if(isPay) {
    //     this.goResendPosition(n);
    //   } else {
    //     that.opera.checkMemberAlertWithType("goBuy_pushPosition")
    //   }
    // },true,true)
    this.goResendPosition(n);
  }

  goPopup(n) {
    let vipExperience = false;
    if (this.departmentData.is_trial_member) {
      let trial_endtime = moment(this.departmentData.memberTrail.endtime).format('YYYY-MM-DD HH:mm:ss');
      let nowTime = moment().format('YYYY-MM-DD HH:mm:ss')
      if (moment(nowTime).isBefore(trial_endtime)) {
        vipExperience = true;
      }
    }
    this.opera._hmtPush("职位管理", "刷新职位", "")
    if (vipExperience) {
      this.positionObj = n;
      this.isShowModel = true
      return
    }
    let that = this;
    this.productManager.getProductUsableCount(this.config.position_refresh, (count, isVip) => {
      if (count == 0 || !isVip) {
        this.product('position_refresh', 'recharge');
      } else {
        this.positionObj = n;
        this.isShowModel = true
      }
    })
  }

  //职位刷新
  goRefresh() {
    this.opera.loading();
    this.dataApi.managePositionRefresh(this.positionObj.positionid).then((res: any) => {
      //console.log(res);
      this.opera.loadiss();
      setTimeout(() => {
        this.toastr.success('刷新成功');
      }, 600);
      this.getPositionList();
      this.getDepartment();
      this.isShowModel = false
    }).catch((error) => {
      this.opera.loadiss();
      this.isShowModel = false;
      setTimeout(() => {
        if (error.Errcode == '409') {//配额超限
          this.toastr.warning(error.Errmsg);
        } else if (error.Errcode == '2005') {
          this.toastr.warning(error.Errmsg);
        }
        else {
          this.toastr.error(error);
        }
      }, 600);
    });
  }
  clostopping() {
    this.showToping = false;
    this.isShowTimes = false;
    this.showTopingTip = false;
    this.showtimingBox = false;
    this.OfflineShow = false;
  }

  //职位置顶
  topping(item, ismodify) {
    this.TopNumber = 0;
    this.productManager.getProductUsableCount(this.config.position_top, (count, isVip) => {
      if (count == 0 || !isVip) {
        this.product('position_top', 'recharge');
      } else {
        this.topingbalance = count;
        if (!ismodify) {
          this.ismodify = false;
          this.topingData = {
            positionid: '',
            start_time: '',
            end_time: '',
            name: '',
          }
        } else {
          if (this.endMin) {
            this.endMin.destory();
            this.endMin = null;
          }
          this.ismodify = true;
          this.topingData.start_time = moment(item.top_start_time).format('YYYY-MM-DD');
          this.topingData.end_time = moment(item.top_end_time).format('YYYY-MM-DD');
          this.TopNumberFun();
        }

        this.topingData.positionid = item.positionid;
        this.topingData.name = item.name;
        this.showToping = true;

        let that = this;
        let tady = moment().format('YYYY-MM-DD');
        setTimeout(() => {
          //日期范围
          laydate.render({
            elem: '#test61'
            , trigger: 'click'
            , theme: '#4342FF'
            , min: tady
            , done: function (value, date, endDate) {
              if (date && date.year) {
                that.topingData.start_time = `${date.year}-${date.month}-${date.date}`;
                that.endminFun(that.topingData.start_time, date.year, date.month, date.date);
                that.TopNumberFun();
              } else {
                that.topingData.start_time = '';
                that.TopNumber = 0;
              }
            }
          });
          that.endMin = laydate.render({
            elem: '#test62'
            , trigger: 'click'
            , theme: '#4342FF'
            , min: tady
            , done: function (value, date, endDate) {
              if (date && date.year) {
                that.topingData.end_time = `${date.year}-${date.month}-${date.date}`;
                that.TopNumberFun();
              } else {
                that.topingData.end_time = '';
                that.TopNumber = 0;
              }
            }
          });
        }, 200)
      }
    })

  }

  TopNumberFun() {
    if (this.topingData.end_time && this.topingData.start_time) {
      this.TopNumber = moment(this.topingData.end_time).diff(this.topingData.start_time, 'days') + 1;
    }
  }

  endminFun(star, YYYY, MM, DD) {
    console.log('endminFun', star);
    if (this.topingData.end_time == '' || moment(this.topingData.end_time).isBefore(star)) {
      this.topingData.end_time = '';
      console.log(this.endMin.config);
      this.endMin.config.min = {
        year: YYYY,
        month: MM - 1, // 注意：这里的月份是从0开始的
        date: DD,
      };
    }
  }

  //确认/修改置顶
  toppingClick() {
    if (!this.topingData.start_time) {
      this.toastr.warning('请选择开始日期');
      return
    }
    let data = {
      start_time: this.topingData.start_time,
      end_time: this.topingData.end_time
    }
    if (this.ismodify) {
      this.dataApi.modifytoppingPosition(this.topingData.positionid, data).then((res: any) => {
        if (res.success) {
          this.showToping = false;
          this.toastr.success('修改置顶成功');
          this.getPositionList();
        } else {
          this.showTopingTip = true;
          this.showTopingTiptext = res.reaseon;
        }
      }).catch((error) => {
        this.showToping = false;
        this.toastr.error(error);
      });
    } else {
      this.dataApi.toppingPosition(this.topingData.positionid, data).then((res: any) => {
        if (res.success) {
          this.showToping = false;
          this.toastr.success('置顶成功');
          this.getPositionList();
        } else {
          this.showTopingTip = true;
          this.showTopingTiptext = res.reaseon;
        }
      }).catch((error) => {
        this.showToping = false;
        this.toastr.error(error);
      });
    }

  }

  //获取置顶中 已置顶和未置顶日期
  openTime(item) {
    this.isShowTimes = true;
    this.dataApi.gettoppingPosition(item.positionid).then((res: any) => {
      if (res) {
        this.timesData = res;
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }


  //职位重发
  goResendPosition(n) {
    this.opera.loading();
    this.dataApi.managePositionResend(n.positionid).then((res: any) => {
      //console.log(res);
      this.opera.loadiss();
      setTimeout(() => {
        this.toastr.success('重发成功');
      }, 600);
      this.isShowPublishModel = true;
      this.opera.setPositionListByRelease();//更新在期职位本地存储
      this.getPositionList();
    }).catch((error) => {
      this.opera.loadiss();
      setTimeout(() => {
        if (error.Errcode == '2005') {//配额超限
          this.toastr.warning('在线职位数量已达上限');
        } else {
          this.toastr.error(error);
        }
      }, 600);
    });
  }

  //获取分页子组件返回数据
  getChildEvent(index) {
    //console.log('子组件返回分页数----');
    //console.log(index);
    if (index > 0) {
      this.pageNumber = index;
      this.model.offset = (this.pageNumber - 1) * 9;
      this.getPositionList();//获取列表
    } else if (index == -1) {//上一页
      if (this.pageNumber == 1) {
        this.toastr.warning("当前为第一页");
      } else {
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber - 1) * 9;
        this.getPositionList();//获取列表
      }
    } else if (index == -2) {//下一页
      if (this.pageNumber == this.pageCount) {
        this.toastr.warning("当前为最后一页");
      } else {
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber - 1) * 9;
        this.getPositionList();//获取列表
      }
    }
  }

  //职位投递的人数点击
  cellLikeClick(n) {
    console.log('职位投递的人数点击', n);
    this.router.navigate(['home/position-resume'], {
      queryParams: {
        positionid: n.positionid
      }
    });
  }

  // 方法一
  toThousands(num) {
    var result = [], counter = 0;
    num = (num || 0).toString().split('');
    for (var i = num.length - 1; i >= 0; i--) {
      counter++;
      result.unshift(num[i]);
      if (!(counter % 3) && i != 0) { result.unshift(','); }
    }
    return result.join('');
  }

  openrushAllPositions() {
    if (this.allPositionCount == 0) {
      this.toastr.warning('职位不能为空');
      return
    }
    this.rushAllPositionsShow = true;
  }

  //一键刷新所有职位
  rushAllPositions() {
    this.opera._hmtPush("职位管理", "一键刷新职位", "");
    let that = this;
    this.productManager.getProductUsableCount(this.config.position_refresh, (count, isVip) => {
      if (count == 0 || !isVip) {
        this.product('position_refresh', 'recharge');
      } else if (this.allPositionCount > count) {
        this.product('position_refresh', 'recharge');
      } else {
        that.opera.loading();
        that.dataApi.positionRefreshs().then((res: any) => {
          that.toastr.success('刷新成功')
          that.opera.loadiss();
          that.getPositionList();//获取列表
          that.getDepartment();
          that.rushAllPositionsShow = false;
        }).catch((error) => {
          that.opera.loadiss();
          that.toastr.error(error);
        });
      }
    })
  }

  //获取企业刷新余额
  getcomponentLeft() {
    this.dataApi.getcomponentLeft({ alias: 'position_refresh' }).then((res: any) => {
      if (res) {
        res.map((item) => {
          if (item.alias == 'position_refresh') {
            this.position_refreshCont = item.left;
          }
        })
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  //自动刷新弹出
  rushAllPosition() {
    this.upData = {
      positionids: [],
      begin_date: '',
      end_date: '',
      time: '',
      daily_refresh_count: 1,
    }
    //是否为体验会员
    let vipExperience = false;
    if (this.departmentData.is_trial_member) {
      let trial_endtime = moment(this.departmentData.memberTrail.endtime).format('YYYY-MM-DD HH:mm:ss');
      let nowTime = moment().format('YYYY-MM-DD HH:mm:ss')
      if (moment(nowTime).isBefore(trial_endtime)) {
        vipExperience = true;
      }
    }
    if (vipExperience || this.isShowMemberMoudel) {
      this.getcomponentLeft();
      this.isShowTiming = true;
      this.isShowSuces = false;
      let that = this;
      this.dataApi.getWithfieldPosition().then((res: any) => {
        if (res) {
          console.log('document.getElementById');
          this.orShow = true;
          // document.getElementById('textClear').innerHTML = '';
          // document.getElementById('textClear').innerHTML = '<input type="text" style="width:100%" id="test60" placeholder="请选择起止日期时间">';
          // let arry = [];
          // let nowDate = moment();
          // res.forEach(item => {
          //   item.day = moment(item.effect_time).diff(nowDate, 'day');
          //   arry.push(item.day);
          //   item.active = true;
          // });
          // let day = Math.min(...arry);
          // effect_time = '';
          // let tady = '';
          // res.forEach(item => {
          //   if (day == item.day) {
          //     effect_time = moment(item.effect_time).format('YYYY-MM-DD');
          //     tady = moment().format('YYYY-MM-DD');
          //   }
          // });
          // console.log('effect_time', effect_time);
          // setTimeout(() => {
          //   //日期范围
          //   refreshLayui = laydate.render({
          //     elem: '#test60'
          //     , theme: '#3063FF'
          //     , range: true
          //     , value: ''
          //     , min: tady
          //     , max: effect_time
          //     , done: function (value, date, endDate) {
          //       let star = `${date.year}-${date.month}-${date.date}`;
          //       let end = `${endDate.year}-${endDate.month}-${endDate.date}`;
          //       that.upData.begin_date = moment(star).format('YYYY-MM-DD');
          //       that.upData.end_date = moment(end).format('YYYY-MM-DD');
          //       that.refreshCont();
          //     }
          //   });
          //   //开始时间
          //   laydate.render({
          //     elem: '#test4'
          //     , theme: '#3063FF'
          //     , type: 'time'
          //     , done: function (value, date, endDate) {
          //       let time = `${date.year}-${date.month}-${date.date} ${date.hours}:${date.minutes}:${date.seconds}`;
          //       that.upData.time = moment(time).format('HH:mm:ss');
          //       that.refreshCont();
          //     }
          //   });
          // }, 200)
          this.layuiTime(res);
          this.dropList = res;

        }
      }).catch((error) => {
        console.log(error);
      });
    } else {
      this.product('position_refresh', 'recharge');
    }

  }

  layuiTime(list) {
    let that = this;
    document.getElementById('textClear').innerHTML = '';
    document.getElementById('textClear').innerHTML = '<input type="text" style="width:100%" id="test60" placeholder="请选择起止日期时间">';
    let arry = [];
    let nowDate = moment();
    list.forEach(item => {
      item.day = moment(item.effect_time).diff(nowDate, 'day');
      arry.push(item.day);
      item.active = true;
    });
    let day = Math.min(...arry);
    effect_time = '';
    let tady = '';
    list.forEach(item => {
      if (day == item.day) {
        effect_time = moment(item.effect_time).format('YYYY-MM-DD');
        tady = moment().format('YYYY-MM-DD');
      }
    });
    console.log('effect_time', effect_time);
    setTimeout(() => {
      //日期范围
      refreshLayui = laydate.render({
        elem: '#test60'
        , trigger: 'click'
        , theme: '#3063FF'
        , range: true
        , value: ''
        , min: tady
        , max: effect_time
        , done: function (value, date, endDate) {
          let star = `${date.year}-${date.month}-${date.date}`;
          let end = `${endDate.year}-${endDate.month}-${endDate.date}`;
          that.upData.begin_date = moment(star).format('YYYY-MM-DD');
          that.upData.end_date = moment(end).format('YYYY-MM-DD');
          that.refreshCont();
        }
      });
      //开始时间
      laydate.render({
        elem: '#test4'
        , trigger: 'click'
        , theme: '#3063FF'
        , type: 'time'
        , done: function (value, date, endDate) {
          let time = `${date.year}-${date.month}-${date.date} ${date.hours}:${date.minutes}:${date.seconds}`;
          that.upData.time = moment(time).format('HH:mm:ss');
          that.refreshCont();
        }
      });
    }, 200)
  }

  drop_down() {
    this.isPosotionListModel = true;
    this.refreshCont();
  }

  closeisShowTiming() {
    this.isShowTiming = false;
  }

  //计算刷新次数
  refreshCont() {
    if (this.upData.daily_refresh_count && this.upData.begin_date && this.upData.end_date && this.dropList.length > 0) {
      let days = moment(this.upData.end_date).diff(moment(this.upData.begin_date), 'days');
      if (days && Number(days) || days == 0) {
        //天数
        days = Number(days);
        //职位数量
        let _num = 0;
        for (let iit of this.dropList) {
          if (iit.active) {
            _num += 1;
          }
        }
        if (this.upData.begin_date == moment().format('YYYY-MM-DD')) {
          //开始时间为当日，计算当日可刷新次数
          if (this.upData.time) {
            this.refreshContspot = days * this.upData.daily_refresh_count * _num;
            let _number = this.differenceTime();
            this.refreshContspot = this.refreshContspot + _number;
          }
        } else {
          this.refreshContspot = (days + 1) * this.upData.daily_refresh_count * _num;
        }
      } else {
        this.refreshContspot = 0;
      }
    } else {
      this.refreshContspot = 0;
    }
    if (this.refreshContspot > this.position_refreshCont) {
      this.isShowimmediately = true;
    } else {
      this.isShowimmediately = false;
    }
  }

  differenceTime() {

    if (this.upData.daily_refresh_count > 0) {
      let now = moment().format('YYYY-MM-DD HH:mm:ss');
      let hour = moment().format('YYYY-MM-DD') + ` ${this.upData.time}`; //开始时间

      //从开始时间推算结束时间
      let contTime = moment(hour).add(2 * this.upData.daily_refresh_count, 'hours').format('YYYY-MM-DD HH:mm:ss'); //结束时间      
      //职位数量
      let _num = 0;
      for (let iit of this.dropList) {
        if (iit.active) {
          _num += 1;
        }
      }
      //当前时间小于开始时间 正常计算
      if (moment(moment().format('YYYY-MM-DD HH:mm:ss')).isBefore(moment(hour).format('YYYY-MM-DD HH:mm:ss'))) {
        console.log('当前时间小于开始时间 正常计算');
        let _number = this.upData.daily_refresh_count * _num;
        return _number
      } else {
        if (moment(now).isBefore(contTime)) {
          console.log(moment(contTime).diff(now, 'minute'));
          // let _number = Math.abs(moment(contTime).diff(now,'minute'))/2;
          let _number = Math.trunc(moment(contTime).diff(now, 'minute') / 60 / 2);
          // _number = _number>=1?_number+1:_number
          console.log(_number);

          if (this.upData.daily_refresh_count < _number) {
            _number = this.upData.daily_refresh_count * _num;
          } else {
            _number = _number * _num;
          }
          return _number
        } else {
          return 0
        }
      }
    }
  }

  liClick(item) {
    this.dropList.forEach(itt => {
      if (itt.positionid == item.positionid) {
        item.active = !item.active
      }
    })
  }

  //删除定时职位
  delClick(item, e) {
    e.stopPropagation();
    this.dropList.forEach(itt => {
      if (itt.positionid == item.positionid) {
        itt.active = false;
      }
    })
    this.refreshCont();
  }


  choiceClick(item) {
    let arry = [];
    this.dropList.forEach(itt => {
      if (itt.positionid == item.positionid) {
        itt.active = !itt.active;
      }
      if (itt.active) {
        arry.push(itt);
      }
    })
    this.calculationDate(arry);
    this.refreshCont();
  }


  orBtn() {
    let arry = [];
    this.dropList.forEach(itt => {
      itt.active = !this.orShow;
      if (itt.active) {
        arry.push(itt);
      }
    })

    this.orShow = !this.orShow;
    if (this.orShow) {
      this.calculationDate(arry);
    }
    this.refreshCont();
  }

  calculationDate(list) {
    this.upData.begin_date = '';
    this.upData.end_date = '';
    this.layuiTime(list);
    // let arry = [];
    // let nowDate = moment();
    // list.forEach(item => {
    //   item.day = moment(item.effect_time).diff(nowDate, 'day');
    //   arry.push(item.day);
    //   item.active = true;
    // });
    // let day = Math.min(...arry);
    // let tady = '';
    // list.forEach(item => {
    //   if (day == item.day) {
    //     effect_time = moment(item.effect_time).format('YYYY-MM-DD');
    //     tady = moment().format('YYYY-MM-DD');
    //   }
    // });

    // (document.getElementById('test60') as HTMLInputElement).value = '';
    // refreshLayui.config.max = {
    //   date: moment(effect_time).format('DD'),
    //   hours: 0,
    //   minutes: 0,
    //   month: moment(effect_time).subtract(1, 'month').startOf('month').format('MM'),
    //   seconds: 0,
    //   year: moment(effect_time).format('YYYY')
    // }

  }

  //查看职位刷新详情
  showTiming(item, e) {

    e.stopPropagation();
    this.dateList_noref = [];
    this.dateList_refed = [];
    this.dataApi.getallrecords(item.positionid).then((res: any) => {
      if (res) {
        console.log(res);
        let { dateList_noref, dateList_refed } = this.getDateList(res.map(v => v.refresh_time));
        console.log('dateList_noref', dateList_noref);
        console.log('dateList_refed', dateList_refed);
        this.dateList_noref = dateList_noref;
        this.dateList_refed = dateList_refed;
        this.showtimingBox = true;
      }
    }).catch((err) => {
      console.log(err);
    })


    // let dates = item.refresh_info.dates;
    // this.getrefreshinfoTimeList = [];
    // this.itemDates = item.refresh_info;
    // for(let item of dates){
    //   let now = moment().format('YYYY-MM-DD');
    //   item = moment(item).format('YYYY-MM-DD');
    //   let objk = {
    //     time:item,
    //     state:''
    //   };
    //   if(now == item){
    //     objk.state = '1'; //已刷新
    //   }else{
    //     objk.state = '2'; //待刷新
    //   }
    //   this.getrefreshinfoTimeList.push(objk);
    // }



  }


  getDateList(list) {
    let dateList_noref = {}
    let dateList_refed = {}
    let now = moment()
    list.forEach(v => {
      let item = moment(v)
      let itemDateStr = item.format('MM月DD日')
      if (moment(v).diff(now) > 0) {
        dateList_noref[itemDateStr] = (dateList_noref[itemDateStr] || 0) + 1
      } else {
        dateList_refed[itemDateStr] = (dateList_refed[itemDateStr] || 0) + 1
      }
    })
    dateList_noref = Object.keys(dateList_noref).map(v => ({ date: v, count: dateList_noref[v] }))
    dateList_refed = Object.keys(dateList_refed).map(v => ({ date: v, count: dateList_refed[v] }))
    return { dateList_noref, dateList_refed }
  }

  okSave() {
    this.isPosotionListModel = false;
    this.refreshCont();
  }

  immediately() {
    this.upData.positionids = [];
    this.dropList.forEach(item => {
      if (item.active) {
        this.upData.positionids.push(item.positionid);
      }
    })
    if (this.upData.positionids.length == 0) {
      this.toastr.warning('请选择职位');
      return
    }
    if (!this.upData.begin_date && !this.upData.end_date) {
      this.toastr.warning('请选择刷新日期');
      return
    }
    if (!this.upData.time) {
      this.toastr.warning('请选择刷新开始时间');
      return
    }
    if (!this.upData.daily_refresh_count || this.upData.daily_refresh_count <= 0) {
      this.toastr.warning('请输入每日刷新次数');
      return
    }
    if (this.refreshContspot == 0) {
      this.toastr.warning('请选择有效刷新时间段');
      return
    }
    this.confirm_entrust_tip = true;

  }

  //二次确认委托刷新
  entrustSave() {
    this.dataApi.set_auto_refresh(this.upData).then((res: any) => {
      if (res.value && res.value > 0) {
        this.enumerateDaysBetweenDates(this.upData.begin_date, this.upData.end_date);
        this.getcomponentLeft();
        this.confirm_entrust_tip = false;
        this.isShowSuces = true;
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  enumerateDaysBetweenDates(startDate, endDate) {
    this.daysList = [];
    const start = moment(startDate);
    const end = moment(endDate);
    const day = end.diff(start, "days");
    this.daysList.push(start.format("YYYY-MM-DD"));
    for (let i = 1; i <= day; i++) {
      this.daysList.push(start.add(1, "days").format("YYYY-MM-DD"));
    }
  }

  hidenTimeing() {
    this.getPositionList();
    this.getDepartment();
  }

  onPageChange(e) {
    console.log(e.page);
    this.posterNumber = `p${e.page + 1}`
  }

}
