<!--购买单品扩展页面 购买单品不够的页面-->
<!--<div class="contentRight-header">-->
  <!--<img (click)="opera.goNewRouter('buyProduct')" src='assets/images/router-old.png'/>-->
  <!--校招工具</div>-->

<app-navigation-route></app-navigation-route>

<app-model-pay-wechat #payWechat [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-pay-wechat>

<!-- 主体内容部分 -->
<div class="homepage-block">

  <div class="box">

    <div class="_title">确认支付</div>

    <div class="tabViewBg" *ngIf="paydata">
      <table class="layui-table">
        <colgroup>
          <col width="150">
          <col width="200">
          <col>
        </colgroup>
        <thead>
        <tr>
          <th>购买产品</th>
          <th>购买数量</th>
          <th>支付啦豆</th>
          <th>啦豆余额</th>
          <th>还需充值</th>
          <th>实际支付</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{paydata?.recharge_category_text}}</td>
          <td>{{paydata?.count+'*'+paydata?.productPace}}</td>
          <td>{{paydata?.sum}}</td>
          <td>{{MyLaCoinCount}}</td>
          <td>{{paydata?.sum-MyLaCoinCount}}</td>
          <td>¥{{paydata?.sum-MyLaCoinCount}}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="jinERow">
      <span class="jinERow_title">订单金额：</span>
      <span class="jinERow_count">¥{{paydata?.sum-MyLaCoinCount}}</span>
    </div>

    <div class="_title payTop">请选择支付方式</div>

    <div class="flex_row_around payTop">
      <div *ngFor="let pay of payList" class="payPath" (click)="model.channel = pay.alias">
        <div class="payType flex_col_center {{pay.alias}}" [class.pathActive]="model.channel==pay.alias">
          <img [src]="pay.ico">
          <!-- <span>{{pay.name}}</span> -->
          <span *ngIf="pay.alias == 'alipay_pc_direct'">支付宝</span>
          <span *ngIf="pay.alias == 'wx_pub_qr'">微信</span>
        </div>
        <div class="payName flex_row_center" [class.pathActive]="model.channel==pay.alias">
          <img *ngIf="model.channel == pay.alias" src="assets/images/payT.png" alt="">
          <img *ngIf="model.channel != pay.alias" src="assets/images/payN.png" alt="">
          <span>{{pay.name}}</span>
        </div>
      </div>
    </div>

    <button class="buyBtn" (click)="pay()">确认支付</button>


  </div>

</div>
