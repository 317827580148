import {Component, OnInit, Output} from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ModelPayCodeComponent} from "../model-pay-code/model-pay-code.component";
import {PayChipsModelComponent} from "../pay-chips-model/pay-chips-model.component";
import {DepartmentSelfAlertComponent} from "../department-self-alert/department-self-alert.component";

@Component({
  selector: 'app-payment-order-list',
  templateUrl: './payment-order-list.component.html',
  styleUrls: ['./payment-order-list.component.css']
})
export class PaymentOrderListComponent implements OnInit {

  public config: Config;

  public payList = [];
  public currentNav = "";
  public MyLaCoinCount = 0; //我的啦豆余额


  public model = {
    skip:0,
    limit:'10',
    order_state:'',
  }
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)

  public  loading = false;
  public auditStatus = 0;

  constructor(
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public opera:Opera,
    public toastr: ToastrService
  ) {
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {
    let order_state = this.local.get('order_state');
    if(order_state)
    {
      this.model.order_state = order_state;
      this.local.remove('order_state')
      this.currentNav = order_state;
    }
    this.opera.initConfig('tool',"paymentorderlist");
    this.getOrderList();
    this.getMyLaCoinCount();
    this.getDepartment();
    this.getApplyPlatformDetails();
    this.getPlatformIdentity();
  }
  getOrderList(){
    let that = this;
    this.opera.loading();

    let data = {
      skip:this.model.skip,
      limit:this.model.limit,
      order_state:this.model.order_state,
    }

    if(this.model.order_state=='canceled')
    {
      data.order_state = "canceled,refunded,refunding"
    }
    if(this.model.order_state=='done')
    {
      data.order_state = "done,executed,paid"
    }
    //待支付订单
    this.dataApi.getPaymentOrderPayList(data).then((res:any)=>{//通过tooken获取企业信息
      this.opera.loadiss()
      if(res)
      {
        this.countList=res.count;
        this.pageCount =Math.ceil(res.count/10);
        this.payList = res.list.map((item)=>{
          let order_state =  item.order_state ;
          item.isOpen = false;
          item.order_state_text = that.formatOrderState(item.order_state,item);
          item.order_navData = that.formatOrderData(item);
          item.isShowLookDetail = true;
          //是否显示 查看详情
          if(item.order_state=='to_pay' ||item.order_category== 'recharge')
          {
            item.isShowLookDetail = false;
            item.isOpen = true;
          }
          item.isHaveProduct = false;
          item.isHavelabi = false;
          item.product_package_list = that.formatProduct_package_list(item);
          let total_cost = 0;
          item.product_package_list = item.product_package_list.map((n)=>{
            n.isGive = false;
            n.subTitle  = "";
            n.isShowBottomData = true;
            total_cost+=n.total_cost;
            if((n.product_category=='ladou'||n.product_category=='ladou_gift')&& !item.isHavelabi)
            {
              item.isHavelabi = true;
            }
            //adou_gift
            if(n.product_category=='recharge'&& !item.isHaveProduct)
            {
              item.isHaveProduct = true;
            }
            if(n.product_category=='member'&& !item.isHaveProduct)
            {
              item.isHaveProduct = true;
            }
            if(n.product_category=='ladou_gift'|| n.product_category=='ladou_gift_coupon')
            {
              n.isShowBottomData = false;
              n.isGive = true;
              n.subTitle = n.product_category=='ladou_gift'?"（充值赠送）":"（卡劵赠送）"
            }
            //如果是啦豆赠送 充值送的啦豆数量。是。1个 2400 ，还是24个100
            if(n.product_category=='ladou_gift')
            {
              n.count = 1;
            }
            n.unit ='';
            if(n.product_category=='member')
            {
              n.unit ='啦豆/年';
            }
            else if(n.product_category=='recharge')
            {
              n.unit ='啦豆/包';
            }

            return n;
          });
          // console.log('product_package_list',item.product_package_list,)
          //计算 显示应付金额，实付
          let dataArr = ['paid','executed','refunding','refunded','deleted','done'];
          if(dataArr.indexOf(item.order_state)>= 0)
          {
            let all  = item.amount_forzen + item.amount_payable;
            //已支付  总金额
            item.item_r_title = "已支付：¥"+ item.amount_payable;
            if(item.amount_payable=='0')
            {
              item.item_r_title =  "已支付："+item.amount_forzen+"啦豆";
            }
          }
          else
          {
           // 需支付 金额
            item.item_r_title = "需支付：¥"+ item.amount_payable
          }

          //计算合计
          item.totalText = '';
          item.totalText2 = '';

          let arr1 = ['paid','executed','refunding','refunded','done'];
          let arr2 = ['to_pay','canceled','deleted'];
          //订单类型
          //URM 计算URM订单是否有折扣
          item.zhekou = "0";
          item.total_cost = total_cost;
          if(item.urm_create)
          {
            if(total_cost!=item.amount_payable)
            {
              //有折扣
              let float_zhekou =  item.amount_payable/total_cost;
              item.zhekou = parseInt(float_zhekou*100+"");
              item.totalText ="合计："+item.amount_payable +"  折扣："+item.float_zhekou+"折"+'   需支付：';
              item.totalText2 ="¥" +item.amount_payable;
              if(order_state=="canceled")
              {
                item.totalText ="合计："+item.amount_payable +"  折扣："+item.float_zhekou+"折"+'   需支付：¥'+item.amount_payable;;
                item.totalText2 = "";
              }
            }
            else
            {
              //没有折扣
              item.totalText ="合计："+ item.amount_payable +'   需支付：';
              item.totalText2 ="¥" +item.amount_payable;
              if(order_state=="canceled")
              {
                item.totalText ="合计："+ item.amount_payable +'   需支付：'+"¥" +item.amount_payable;
                item.totalText2 = "";
              }

            }
          }
          else
          {
            //啦豆
            if(item.order_category=='recharge')
            {
              if(arr1.indexOf(order_state)>=0)
              {
                item.totalText ="合计："+ "已支付：¥"+item.amount_payable;
              }
              else
              {
                item.totalText ="合计："+ "需支付：";
                item.totalText2 ="¥" +item.amount_payable;
                if(order_state=="canceled")
                {
                  item.totalText ="合计："+ item.amount_payable +'   需支付：'+"¥" +item.amount_payable;
                  item.totalText2 = "";
                }

              }
            }
            else
            {
              let all = +item.amount_payable+item.amount_forzen;
             // order_category == 1,3,4 产品兑换
              if(arr1.indexOf(order_state)>=0)
              {
                if(item.order_category=='buy')
                {
                 //现金购买
                  item.totalText ="合计："+ "  ￥"+all+"(啦豆)，啦豆抵用："+item.amount_forzen+" 已支付金额："+item.amount_payable;
                }
                else {
                  //啦豆兑换
                  item.totalText ="合计："+ "  ￥"+all+"(啦豆)，啦豆抵用："+item.amount_forzen;
                }
              }
              else
              {
                if(item.order_category=='buy') {
                  //现金购买
                  item.totalText ="合计："+ "  ￥"+all+"啦豆"+"，啦豆抵用："+item.amount_forzen+"，还需支付啦豆 "+item.amount_payable+"，啦豆余额不足，需支付剩余金额：";
                  item.totalText2 ="¥" +item.amount_payable;
                  if(order_state=="canceled") {
                    item.totalText ="合计："+ "  ￥"+all+"啦豆"+"，啦豆抵用："+item.amount_forzen+"，还需支付啦豆 "+item.amount_payable+"，啦豆余额不足，需支付剩余金额：¥" +item.amount_payable;
                    item.totalText2 = "";
                  }
                }
                else
                {
                  //啦豆兑换
                  item.totalText ="合计："+ "  ￥"+all+"啦豆";
                  // item.totalText ="合计："+  '<span>'+"asdasdasdasdasd"+'</span>';
                }
              }
            }
          }
          return item;
        })
      }
    }).catch((error)=>{
      this.toastr.error(error);
      this.opera.loadiss()
    });
  }
  navClick(index){
    this.currentNav = index;
    this.model.skip = 0;
    this.model.order_state = index;
    if(this.model.order_state =='')
    {
      this.opera._hmtPush("校招工具购买","我的订单","全部订单");
    }
    else if(this.model.order_state =='to_pay'){
      this.opera._hmtPush("校招工具购买","我的订单","待支付订单");
    }
    else if(this.model.order_state =='executed'){
      this.opera._hmtPush("校招工具购买","我的订单","已支付订单");
    }
    else if(this.model.order_state =='done'){
      this.opera._hmtPush("校招工具购买","我的订单","已完成订单");
    }
    else if(this.model.order_state =='canceled'){
      this.opera._hmtPush("校招工具购买","我的订单","已失效订单");
    }
    this.getOrderList();
  }
  //导航上的时间
  formatOrderState(order_state,order){
    let state = "";
    switch (order_state) {
      case 'to_pay':
        state = "待付款";
        break;
      case "canceled":
        state = "已失效";//后端定义 已取消
        break;
      case "paid":
        state = "已付款";
        state = "已完成  （已付款）";
        if(order.scheduled_execution_time)
        {
          state = "已完成  生效时间："+ this.RiQi(order.scheduled_execution_time);
        }
        break;
      case "executed":
        state = "已执行";//后端定义 已执行
        state = "已完成  （已生效）";
        if(order.scheduled_execution_time)
        {
          let nowData = new Date();
          let scheduled_execution_time = new Date(order.scheduled_execution_time);
          if(nowData.getTime()>scheduled_execution_time.getTime())
          {
            state = "已完成 （已生效）";
          }
          else
          {
            console.log('已经生效了-没有生效')
            state = "已完成  生效时间："+ this.RiQi(order.scheduled_execution_time);
          }
        }
        break;
      case "refunding":
        state = "退款中"; //后端定义
        break;
      case "refunded":
        state = "已退款";
        break;
      case "deleted":
        state = "已失效";//后端定义 已删除
        break;
      case "done":
        // state = "已完成";
        state = "已完成  （已生效）";
        break;
      default:
        state = "未知状态";
    }
    return state;
  }
  //导航上的日期
  formatOrderData(order){
    let order_state = order.order_state;
    let data = "";
    // 3 4 支付时间 pay_time
    // 8 完成时间 done_time
    // 7 失效时间 expiration_time
    // 6 退款时间 refund_time
    // 5 无
    // 否则
    // 创建时间 add_time
    if(order_state=="paid"||order_state=="executed")
    {
      data = "支付时间：" + this.RiQi(order.pay_time);
    }
    else if(order_state=="done"){
      data = "完成时间：" + this.RiQi(order.done_time);
    }
    else if(order_state=="deleted"){
      data = "失效时间：" + this.RiQi(order.expiration_time);
    }
    else if(order_state=="refunded"){
      data = "退款时间：" + this.RiQi(order.refund_time);
    }
    else if(order_state=="refunding"){
      data = "";
    }
    else if(order_state=="to_pay"){
      data = "失效时间：" + this.RiQi(order.expiration_time);
    }
    else
    {
      data = "创建时间：" + this.RiQi(order.add_time);
    }
    return data;
  }
  //处理 数据
  formatProduct_package_list(item)
  {
    console.log('formatProduct_package_list',item)
    let arr = [];
    if(item.ladou_product_list)
    {
      item.ladou_product_list =  item.ladou_product_list.map((item)=>{
        item.quanyi = "/";
        return item;
      });
      arr = item.ladou_product_list;
    }
    if(item.product_member)
    {
      try {

        let Newarr = item.product_member.product_component_infos;
        let service_period = null;
        if(Newarr){
          Newarr.map((item) =>{
            if(item.alias=='service_period'){
              service_period = item
            }
          })
        }
        if(service_period){
          let count = service_period.count+''
          arr.push({
            cost:item.product_member.price,
            count:1,
            product_category:'member',
            product_name:item.product_member.product_name,
            service_period:service_period.count,
            total_cost:item.product_member.price,
            quanyi:count+ service_period.unit
          })
        }


      }catch (e) {
        console.log('????????',e)
      }
    }


    if(item.product_package) {
      item.product_package.map((e)=>{

        if(e.product_component_infos.length==0)
        {
          e.product_component_infos = [{count:'',unit:'',alias:''}]
        }
          let count = 0;
          let unit = "";
          let service_period;
          let isAddvert = false
          if(e.product_component_infos)
          {
            count = e.product_component_infos[0].count;
            unit = e.product_component_infos[0].unit;

            e.product_component_infos.map((item)=>{
              if(item.alias=='service_period')
              {
                service_period=item;
              }
              else
              {
                count = item.count;
                unit = item.unit
              }
              if(this.opera.isAddvert(item.alias)){
                count = item.count/7;
                unit ='周'
                isAddvert = true
              }
            })
          }
          let quanyi =  count+unit+'/包';
          if(isAddvert){
            quanyi = count+unit
          }
          arr.push({
            cost:e.price,
            count:e.count,
            product_category:e.product_category,
            product_name:e.product_name,
            service_period:service_period?service_period.count:'',
            total_cost:e.count * e.price,
            quanyi:quanyi,
            product_code:e.product_code
          })
      })
    }
    return arr;
  }
  //打开关闭详情按钮
  openDetail(n,index){

    this.opera._hmtPush("我的订单","查看详情","")
    this.payList = this.payList.map((item,i)=>{
      if(i==index)
      {
        item.isOpen =  ! item.isOpen;
      }
      return item;
    })
  }
  //获取分页子组件返回数据
  getChildEvent(index){
    if(index > 0){
      this.pageNumber = index;
      this.model.skip = (this.pageNumber-1)*10;
      this.getOrderList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.skip = (this.pageNumber-1)*10;
        this.getOrderList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.skip = (this.pageNumber-1)*10;
        this.getOrderList();//获取简历投递列表
      }
    }

  }
  //时间戳转时间
  RiQi(sj) {
    if(!sj)
    {
      return '';
    }
    var now = new Date(sj);
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var date = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    let space = minute<=9?'0':'';
    let space2 = second<=9?'0':'';
    return year + "/" + month + "/" + date+"   " +hour+":"+space+minute+":" +space2+second;
  }
  //取消订单
  cancelOrder(order){
    let dialog =  this.dialog.open(PayChipsModelComponent, {
      data:{
        title:"是否取消订单",
        letBtnTitle:'取消订单',
        rightBtnTitle:'我再想想',
        callback:(res)=>{
          // console.log('callback',res)
          this.dialog.closeAll();
          this.opera.updateParOrderCount();
          if(res==2)
          {
            this.opera._hmtPush("我的订单","取消订单","取消订单")
            //放弃付款
            this.cancelOrderHttp(order);
          }
          else if(res==3)
          {
            this.opera._hmtPush("我的订单","取消订单","关闭")

          }
          else
          {
            this.opera._hmtPush("我的订单","取消订单","我再想想")
            // this.continueOrder(order);
          }
        },}
    }).afterClosed().subscribe((isConfirmed) => {
    });
  }
  cancelOrderHttp(order){
    //paymentV2orderCancel
    //pay_orderid
    this.dataApi.paymentV2orderCancel(order.pay_orderid).then((res:any)=>{//通过tooken获取企业信息
      // console.log('cancelOrderHttp',order,res);
      this.paySuccessCallback();
      this.toastr.success('已取消本次付款')
    }).catch((error)=>{
      this.toastr.error(error);
    });
  }
  //继续付款
  continueOrder(order){
    console.log('继续付款',order)

    this.opera._hmtPush("我的订单","立即付款",order.urm_create?"URM订单":"线上订单");

    if(order.order_category=='recharge')
    {
      this.buylaBi(order.pay_orderid,order);
    }
    else
    {
      if(order.order_category=='exchange') {
        this.buyProductWithLabi(order.pay_orderid,order)
      }
      else
      {
        //必须走扫码支付流程
        this.buyProductWithCode(order.pay_orderid,order)
      }
    }
  }
  //购买啦豆
  buylaBi(pay_orderid,order){
    let that = this;
    let giveCount =  Math.floor(order.count/1000);
    let seleteLaCoin = {count:order.count,give:giveCount*100}
    if(order.zhekou!='0')
    {
      let give = 0;
      order.ladou_product_list.map((n)=>{
        if(n.product_category =='ladou_gift')
        {
          give = n.total_cost;
        }
      });
      seleteLaCoin = {count:order.count,give:give}
    }

    this.config.isShowPayModel = true;
    //coupon_receiveid
    let coupon_receiveid;
    if(order.coupon_receiveid)
    {
      coupon_receiveid = {
        title:'满赠卡券',
        gift_amount:order.gift_count
      }
    }
    let isSuccessIndex = 0;
    let dialog =  this.dialog.open(ModelPayCodeComponent, {
      data:{
        title:"支付",
        buyType:'labi',
        pay_orderid:pay_orderid,
        isOrderPage:true,
        labiData:{
          laCoin:seleteLaCoin,
          currentCard:coupon_receiveid?coupon_receiveid:"",
        },
        order:order,
        callback:(res)=>{
          this.dialog.closeAll();
          this.config.isShowPayModel = false;
          if(res)
          {
            isSuccessIndex+=1;
            if(isSuccessIndex==1)
            {
              this.toastr.success('购买成功')
            }
          }
          this.paySuccessCallback();
        },}
    }).afterClosed().subscribe((isConfirmed) => {
      this.opera.updateParOrderCount();
      if(this.config.payTimer)
      {
        clearInterval(this.config.payTimer)
      }
    });
  }
  //购买单品,扫码支付
  buyProductWithCode(pay_orderid,order){
    let that = this;
    console.log('购买单品,扫码支付',pay_orderid,order,order.product_package_list)
    let productList = [];
    order.product_package_list.map((item)=>{
      if(item.product_category=='member')
      {
        return;
      }
      productList.push({
        name:item.product_name,
        time:'365天',
        price:item.product_category=='ladou_gift'?item.total_cost :item.cost,
        type:'product',
        count:item.count,
        give:false,
        product_category:item.product_category,
        quanyi:item.quanyi
      })
    });

    let isSuccessIndex = 0;
    let dialog =  this.dialog.open(ModelPayCodeComponent, {
      data:{
        title:"支付",
        buyType:'buyProduct_code',
        pay_orderid:pay_orderid,
        labiData:{},
        isOrderPage:true,
        buyProductData:{
          member:order.product_member?{name:order.product_member.product_name,price:order.product_member.price}:'',
          productList:productList,
        },
        numberData:{
          MyLaCoinCount:0,
          xiaoji:order.zhekou=='0'?order.amount_payable+order.amount_forzen:order.total_cost,
          ladouyuediyong:order.amount_forzen,
          haixuzhifu:order.amount_payable,
          zhekou:order.zhekou,
        },
        order:order,
        callback:(res)=>{
          // console.log('callback',res,isSuccessIndex)
          this.dialog.closeAll();
          this.config.isShowPayModel = false;
          if(res)
          {
            isSuccessIndex+=1;
            if(isSuccessIndex==1) {
              if(order.urm_create)
              {
                this.opera._hmtPush("支付成功","二维码支付成功","URM订单");
              }
              this.toastr.success('付款成功')
            }
          }
          this.paySuccessCallback();

        },}
    }).afterClosed().subscribe((isConfirmed) => {
      this.opera.updateParOrderCount();

      if(this.config.payTimer)
      {
        clearInterval(this.config.payTimer)
      }
    });

  }
  //购买单品,扫码支付
  buyProductWithLabi(pay_orderid,order){
    let that = this;
    console.log('购买单品,扫码支付',pay_orderid,order,order.product_package_list)
    let productList = [];
    let online_packages = [];
    order.product_package_list.map((item)=>{
      if(item.product_category=='member')
      {
        return;
      }
      productList.push({
        name:item.product_name,
        time:'365天',
        price:item.product_category=='ladou_gift'?item.total_cost :item.cost,
        type:'product',
        count:item.count,
        give:false,
        product_category:item.product_category,
        quanyi:item.quanyi
      });
      online_packages.push({product_code:item.product_code,count:item.count})
    });

    let data = {
      member_product_code:order.product_member?"member_600":'',
      online_packages:online_packages,
      balance:order.amount_payable+order.amount_forzen, //订单的总价
      need_pay:0,
    }

    let isSuccessIndex = 0
    let dialog =  this.dialog.open(ModelPayCodeComponent, {
      data:{
        title:"支付",
        buyType:'buyProduct_labi',
        pay_orderid:pay_orderid,
        labiData:{},
        isOrderPage:true,
        buyProductData:{
          member:order.product_member?{name:order.product_member.product_name,price:order.product_member.price}:'',
          productList:productList,
          httpData:data,
        },
        numberData:{
          MyLaCoinCount:0,
          xiaoji:order.amount_payable+order.amount_forzen,
          ladouyuediyong:order.amount_forzen,
          haixuzhifu:order.amount_payable,
        },
        order:order,
        callback:(res)=>{
          this.dialog.closeAll();
          this.config.isShowPayModel = false;
          if(res)
          {
            isSuccessIndex+=1;
            if(isSuccessIndex==1) {
              this.toastr.success('付款成功')
            }
          }
          this.paySuccessCallback();
        },}
    }).afterClosed().subscribe((isConfirmed) => {
      this.opera.updateParOrderCount();

      if(this.config.payTimer)
      {
        clearInterval(this.config.payTimer)
      }
    });
  }

  //再来一单
  zailaiPay(order){
    console.log('zailaiPay',order)
    this.opera._hmtPush("我的订单","再次提交","")
    if(this.auditStatus!=2)
    {
      this.kefuClick();
      return;
    }
    if(order.order_category=='recharge')
    {
      //再来一单啦豆
      //count
      if(this.loading)
      {
         this.toastr.warning('网络请求中')
        return;
      }
      this.loading = true;
      this.opera.loading();
      this.dataApi.paymentRechargeCreate({
        count:order.count,
        coupon_receiveid:''}).then((res:any)=>{
        console.log('新支付系统',res);
        this.opera.loadiss();
        this.loading = false;

        if(res)
        {
          // if(this.config.isShowPayModel)
          // {
          //   return;
          // }
          let pay_orderid = res.value;
          this.buylaBi(pay_orderid,order);
        }
      }).catch((err)=>{
        console.log(err);
        this.loading = false;

        this.config.isShowPayModel = false;
        this.toastr.error(err)
        this.opera.loadiss();
      })

    }
    else
    {
      // 再来一单单品购买 //amount_payable
      if(this.MyLaCoinCount>= order.amount_payable)
      {
        console.log('再来一单啦豆购买',order);
        let online_packages= [];
        order.product_package_list.map((item)=>{
          if(item.product_category =='recharge')
          {
            online_packages.push({product_code:item.product_code,count:item.count})
          }
        });
        let str = JSON.stringify(online_packages);
        this.local.remove('payrecharge');
        this.local.set('pay_recharge_order',str);
        this.router.navigateByUrl('/home/tool-campus');
      }
      else
      {

        let online_packages= [];
        order.product_package_list.map((item)=>{
          if(item.product_category =='recharge')
          {
            online_packages.push({product_code:item.product_code,count:item.count})
          }
        });
        let str = JSON.stringify(online_packages);
        this.local.remove('payrecharge');
        this.local.set('pay_recharge_order',str);
        this.router.navigateByUrl('/home/tool-campus');

      }
    }
  }
  //获取我的啦豆余额
  getMyLaCoinCount() {
    this.dataApi.getMyLaCoinCount({}).then((res:any) => {
      this.MyLaCoinCount = res;
    }).catch((err) => {
      console.log(err);
    })
  }
  //getDepartment
  getDepartment(){
    this.dataApi.getEmployment().then((res:any) => {
      this.config.department = res;
    }).catch((err) => {
      console.log(err);
    })
  }

  //支付成功回调刷新数据
  paySuccessCallback(){
    this.getOrderList();
    this.getMyLaCoinCount();
    this.getDepartment();
    this.opera.updateParOrderCount();
  }
  //获取平台认证信息并赋值
  getApplyPlatformDetails() {
    this.dataApi.getPlatformApply().then((res:any) => {
      console.log("获取企业平台认证信息", res);
      if(res)
      {
        this.config.lastIdentity =res;
      }
    }).catch((error) => {
      console.log(error);
    })
  }
  gotoidentity(){
    this.router.navigateByUrl('guide-audit');
  }
  //获取企业认证信息
  getPlatformIdentity(){
    this.dataApi.getPlatformApply().then((res:any)=>{
      if(res){
        this.auditStatus = res.status;
      }
    }).catch((error)=>{
      console.log("获取企业平台认证信息出错");
      console.log(error);
    })
  }
  kefuClick(){

    this.dialog.open(DepartmentSelfAlertComponent, {
      data:{title:'联系客服完成审核后即可购买'}
    }).afterClosed().subscribe((isConfirmed) => {
    });
  }

  myTool(){
    this.router.navigate(['home/tool-my']);
  }

  myOrder(){
    this.router.navigate(['home/paymentorderlist']);
  }

  myInvoice(){
    this.router.navigate(['home/payInvoice']);
  }

  myLadou(){
    this.router.navigate(['home/payHistory']);
  }

}
