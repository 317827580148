import { Component, OnInit,Output } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import {activityManager} from "../../provider/activityManager";

@Component({
  selector: 'app-hotvalue-rank',
  templateUrl: './hotvalue-rank.component.html',
  styleUrls: ['./hotvalue-rank.component.css']
})
export class HotValueComponent implements OnInit {
  // 筛选条件

  public comList=[];
  public productList = [];
  public config: Config;
  public myDepartment:any;
  public isShowQrcode = false;
  public chatsList:any;//会话列表
  public countList = 0;//列表数量
  constructor(
    public dataApi: DataApi,
    public opera:Opera,
    public local:Local,
    public toastr: ToastrService,
    private router: Router,
    public activityManager:activityManager,

  ) { }

  ngOnInit() {
    if(!this.activityManager.isShowHotValue())
    {
      this.router.navigateByUrl('home/workSpase');
    }
    this.opera.initConfig('hot','');
    this.getProductList();
    this.getDepartment();
    this.getHotValueData();
  }

  //获取热力值
  getHotValueData(){
    this.opera.loading();
    this.dataApi.getCompany100({limit:100}).then((res:any)=>{
      console.log(res);
      this.opera.loadiss();
      if(res)
      {
        this.comList = res
      }

    }).catch((error) => {
      this.opera.loadiss();
      this.toastr.error(error);
    });
  }
  //获取企业信息
  getDepartment(){
    if(this.dataApi.getLocalDepartment()){
      this.myDepartment = this.dataApi.getLocalDepartment();
    }else{
      this.dataApi.getDepartment().then((res:any)=>{
        //console.log("企业信息获取线上数据");
        //console.log(res);
        if(res)
        {
          this.dataApi.setLocalDepartment(res);
        }

      }).catch((error) => {
        //console.log(error);
        this.toastr.error(error);
      });
    }

  }
  getProductList(){
    //product_category:"recharge",recharge_category:''
    this.opera.loading();
    this.dataApi.departmentList({product_category:"recharge"}).then((res:any)=>{
      this.opera.loadiss();
      if(res){
        console.log('获取产品列表',res)
        this.productList = res.list;
      }
    }).catch((err)=>{
      console.log(err);
      this.opera.loadiss();
    })
  }
  goWork(){
    this.router.navigate(['home/workSpase']);
  }

  viewMiniPro(){

    //接口
    this.isShowQrcode = true;
  }

  goBuy(n){
    this.productList.map((item,index)=>{
      if(item.recharge_category == n){
        this.local.set("recharge_category_code", JSON.stringify(item.code));
        this.router.navigate(['home/buyProduct']);
        return
      }
    })
  }



}
