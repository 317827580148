import { Component, OnInit,Output} from '@angular/core';
import {DataApi, Local, Constants, Opera, Config, FormatDate, MyMMessage} from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Events } from '../../provider/eventService';
import {Router} from "@angular/router";
import {NoneVipAlertComponent} from "../none-vip-alert/none-vip-alert.component";
import { MatDialog } from "@angular/material/dialog";

declare var layui;
@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.css']
})
export class NotificationCenterComponent implements OnInit {
  // 筛选条件
  public config: Config;
  public model={
    sender_account_type:'',
    offset:0,
    limit:10
  };
  public taskList=[];
  public chatsList:any;//会话列表
  public countList = 0;//列表数量
  public msgList = [];//收到的即时消息聊天记录

  public laypage;
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(
    public dataApi: DataApi,
    public opera:Opera,
    public local:Local,
    public dialog: MatDialog,
    public toastr: ToastrService,
    private router: Router,
    public events : Events,
  ) {
    this.config = Config.getInstance();  // 实例化config
    //接收到更新会话列表的广播

    this.opera.initConfig('notification','notification-center');
    this.events.subscribe('chatTo:contact', (data) => {
      if(data) {
        this.config.chatsList=data;
        this.chatsList=data.list;
      }
    });
  }

  ngOnInit() {
    this.config.msgList= [];
    let that = this;
    layui.use('laypage', function(){
      that.laypage = layui.laypage;
      console.log('this.laypage1',  that.laypage)
      that.getTaskTodoList();
    });
    //this.getNewChatList();
    this.initEvents();
    this.dataApi.getMsgUnViewCount().then((res: any) => {
      if (res) {
        this.config.tasktodo = res.value;
      }
    }).catch((error) => {
      console.log('getWorkData', error)
    });
  }
  
  chooseStatus(n){
    let that = this;
    if(n == ""){
      that.opera._hmtPush('通知中心','全部','')
    }else if(n == "college"){
      that.opera._hmtPush('通知中心','高校','')
    }else if(n == "talent"){
      that.opera._hmtPush('通知中心','学生','')
    }else if(n == "system"){
      that.opera._hmtPush('通知中心','系统','')
    }
    this.model.sender_account_type = n;
    this.pageNumber = 1;
    this.model.offset = 0;
    this.getTaskTodoList()
  }

  getNewChatList(){
    this.dataApi.getImDialogueAllList({}).then((res:any)=>{
      if(res) {
        this.dataApi.setLocalChatsList(res);//会话列表本地存储
        this.chatsList = res.list;
      }
    }).catch((error)=>{
      this.toastr.error(error);
    });
  }
  //去沟通页面
  goContactChat(n){

    //sender
    let getChatImdata = {
      accountid:n.sender
    }
    console.log('去沟通页面',n);
    this.dataApi.getChatIm(getChatImdata).then((res:any)=> {
      if(res) {
        console.log('去沟通页面',res,n);
        let department = this.local.get('department');
        let departmentJSON =JSON.parse(department);
        let J_userName = "";
        if(res.jm_username) {
          J_userName =res.jm_username;
        }
        console.log('去沟通页面-拿到对方ID',J_userName);
        if(J_userName.length!=0) {
          this.local.set('userJmUsername',J_userName);
          this.local.set('chat_back_router','notification-center')
          MyMMessage.instance.events.publish("notificationClick");
          this.router.navigateByUrl('/home/chatSingle');
          // this.toastr.error('您好！因系统数据维护，7月10日24:00点前该功能暂不可使用。您可以使用“极速电话联系”直接联系该学生。')
        }
      }
    }).catch((error) => {
      console.log('去沟通页面',error);
      if(error.Errmsg) {
        this.toastr.error(error.Errmsg);
      } else {
        this.toastr.error(error);
      }
      this.opera.loadiss();
    });
  }

  getTaskTodoList(){
    let that = this;
    this.opera.loading();
    this.dataApi.getTodoList(this.model).then((res:any)=>{
      this.opera.loadiss();
      if(res) {
        this.countList=res.count;
        this.pageCount =Math.ceil(res.count/10);
        this.taskList =  res.list;
        this.taskList.map((item,index)=>{
          if(item.content){
            if(NotificationCenterComponent.isJsonString(item.content)){
              item.content = JSON.parse(item.content)
              // console.log(index)
            }
          }
          item.titleColor = item.alias == 'milkround_apply_not_passed'
            || item.alias == 'college_audit_not_passed'
            || item.alias == 'college_audit_campustalk_not_passed'
            || item.alias == 'system_notice_department_campustalk_not_passed'
            || item.alias == 'college_audit_position_not_passed'
            || item.alias == 'system_notice_milkround_apply_not_passed'
            || item.alias == 'authentication_not_passed';
        })
        console.log(this.taskList)


        console.log('this.laypage',this.laypage)

        this.laypage.render({
          elem: 'page' //注意，这里的 test1 是 ID，不用加 # 号
          ,limit:9,theme:'#5e4fff'
          ,curr:this.pageNumber
          ,count: res.count //数据总数，从服务端得到
          ,jump: function(obj, first){
            //obj包含了当前分页的所有参数，比如：
            console.log(obj.curr); //得到当前页，以便向服务端请求对应页的数据。
            //首次不执行
            that.pageNumber = obj.curr;
            that.model.offset = (that.pageNumber-1)*9;
            if(!first){
              that.getTaskTodoList()
              //do something
            }
          }
        });

      }
    }).catch((error) => {
      this.toastr.error(error);
      this.opera.loadiss();
    });
  }
  static isJsonString(str) {
    try {
      if (typeof JSON.parse(str) == "object") {
        return true;
      }
    } catch(e) {
    }
    return false;
  }
  //处理操作
  goView(n){
    if(!n.view){
      this.dataApi.viewTodo(n.taskid).then((res:any)=>{
        //this.toastr.success('已查看');
        n.view = true;
        this.events.publish('isReadNotice',false);
        // this.opera.loadiss();
      }).catch((error) => {
        // this.toastr.error(error);
        // this.opera.loadiss();
      });
    }
  }
  gotoDay(){
    this.router.navigate(['home/milkdDay'],{
      queryParams: {
      }
    });
  }

  async goHandle(n){
    
    console.log('goHandle',n,this.opera.isShowPaySuccessModule());
    // 如果消息未读设置为已读
    if(!n.view){
      await this.dataApi.viewTodo(n.taskid).then((res:any)=>{
        n.view = true;
        this.events.publish('isReadNotice',false);
      }).catch((error) => {
      });
    }

    let ret:any = await this.dataApi.getMsgUnViewCount();
    if(ret){
      console.log('this.config.tasktodo',ret);
      this.config.tasktodo = ret.value;
    }

    this.opera._hmtPush('通知中心','查看详情','')
    let alias = n.alias;
    let that = this;
    // if(!n.pending) {
    //   return;
    // }
    console.log('匹配跳转')
    switch (alias) {
      //企业申请双选会确认参会回执（会员）
      case "department_apply_milkround_confirm_member":{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //企业双选会报名后进入审核队列
      case "milkround_apply_wait_to_audit_member" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //企业申请双选会审核未通过
      case "milkround_apply_not_passed_member" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //企业资质审核未通过
      case "college_audit_not_passed_member" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //双选会展位通知（会员）
      case "college_set_milkround_seat_number_member" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //企业申请双选会二次确认（会员）
      case "department_apply_milkround_two_confirm_member" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //高校发双选会通知企业（会员）
      case "milkround_invite_department" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //双选会修改通知企业（会员）
      case "auto_milkround_modify_notice_department_member" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //M端双选会通知全网企业（会员）
      case "milkround_notice_department_by_manage_member" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //M端双选会通知全网企业（非会员）
      case "milkround_notice_department_by_manage_not_member" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      case "milkround_notice_department_by_manage" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      } 
      //M端公益双选会通知全网企业
      case "milkround_notice_department_by_manage_member_welfare" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //企业资质审核未通过(公益双选会)
      case "college_audit_not_passed_member_welfare" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //企业申请双选会审核未通过（公益双选会）
      case "milkround_apply_not_passed_member_welfare" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //企业申请双选会确认参会回执（公益双选会）
      case "department_apply_milkround_confirm_member_welfare" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //双选会展位通知（公益双选会）
      case "college_set_milkround_seat_number_member_welfare" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //企业申请双选会二次确认（非会员）
      case "department_apply_milkround_two_confirm_not_member" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      // 双选会展位通知（非会员）
      case "college_set_milkround_seat_number_not_member" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //企业申请双选会确认参会回执（非会员）
      case "department_apply_milkround_confirm_not_member" :{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      case "milkround_apply_not_passed":{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      case "milkround_enterprise_notice":{
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });
      }  
      //线下双选会开始的前一天发送
      case "milkround_notice_department_member_by_manage_Offline_member" : {
        // 跳转到双选会详情
        that.router.navigate(['home/college-milkdetails'],{
          queryParams: {
            id:n.businessid,
          }
        });

        break;
      }
      //新版双选会通知-每日汇总
      case "milkround_daily_timing_push": {
        console.log('新版双选会通知')
        that.router.navigate(['home/college-milkround']);
        break;
      }
      //宣讲会审核通过（会员）
      case "college_audit_campustalk_passed_member" :
      // 宣讲会审核通过（非会员）
      case "college_audit_campustalk_passed_not_member" :
      // 宣讲会审核未通过，邀请企业重新报名
      case "college_audit_campustalk_not_passed_member" :
      //企业宣讲会预约后进入审核队列
      case "department_campustalk_apply_wait_to_audit_member": {
        // 去双选会详情页面
        this.local.set("ctid", n.businessid);
        this.router.navigate(['home/college-details-preach']);
        break;
      }
      // 高校空中宣讲会审核通过
      case "college_audit_aircampustalk_passed_member" :
      // 高校空中宣讲会审核未通过
      case "college_audit_aircampustalk_not_passed_member" :
      // 空中宣讲会审核通过
      case "air_campustalk_passed_member" :
      //空中宣讲会审核不通过
      case "air_campustalk_not_passed_member": {
        // 空中宣讲会页面
        this.router.navigate(['home/airCampusDetail']);
        break;
      }
      //人才发送留言通知企业
      case "talent_leave_message_to_department_member": {
        // 去直聊
        this.goContactChat(n);
        break;
      }
      //人才投递简历通知企业
      case "talent_delivery_member": {
        // 去简历详情页面
        this.dataApi.getDeliveryRecord(n.businessid).then((res:any)=>{
          if(res){
            this.router.navigate(['home/college-resumedetails'], {queryParams: {resumeid:res.resume_number}});
          }else {
            this.router.navigate(['home/position-resume']);
          }
        }).catch((error) => {
          this.toastr.error(error);
          this.router.navigate(['home/position-resume']);
        });

        break;
      }
      //企业职位被查看
      case "department_position_be_viewed_member":{
        //对我感兴趣页面
        that.router.navigate(['home/chat-collection']);
        break;
      }
      // 不活跃用户匹配人才推送
      case "talents_recommendation_member":{
        //简历列表
        this.router.navigate(['home/position-resume']);
        break;
      }
      // （新）没有在期职位
      case "member_expired_notice":
      // （新）职位过期
      case "post_expired_member":
      // （新）会员超过1个月未登录，有在期职位
      case "one_month_overdue_member":
      //职位到期提醒
      case "position_expiration_notice_member":{
        //职位列表页面
        this.router.navigate(['home/position-manage']);
        break;
      }
      //云认证未通过通知
      case "authentication_not_passed_member":{
        // 去认证页面
        this.goCertification();
        break;
      }
      //（新）校招活动邀请剩余不足100次
      case "event_invitation_less_than_100_times_member":
      //（新）空宣推广剩余不足100次
      case "air_publicity_promotion_less_than_100_times_member":
      //（新）职位刷新剩余不足100次
      case "position_refresh_less_than_100_times_member":
      //（新）职位推送剩余不足100人
      case "position_push_less_than_100_times_member":
      //（新）极速电话联系剩余不足100分钟
      case "speed_phone_call_less_than_100_minutes_member":{
        //我的工具
        this.router.navigate(['home/tool-campus']);
        break;
      }
      //高校发双选会通知企业（非会员）
      case "milkround_invite_department_not_member":
      //云认证已通过通知
      case "authentication_passed_member":
      //（新）会员到期前15天
      case "membership_expiration_15_days_before_member":
      //（新）会员到期前当天
      case "membership_expiration_date_member":
      //（新）会员到期后15天
      case "membership_expiration_15_days_after_member":
      //（新）在高校就业网发布职位-非会员
      case "university_employment_network_posts_member":
      //（新）会员到期前3个月
      case "membership_expiration_three_months_before_member":{
          // 不做跳转 刷新列表
        this.getTaskTodoList();
        break;
      }
      default:{
        console.log('switch-----default');
        // 刷新列表
        this.getTaskTodoList();
      }
    }

    // 旧通知，废弃日期 2021/7/26

    // if(alias == "college_audit_aircampustalk_not_not_passed") {
    //   //高校空中宣讲会审核未通过 ==> 去空中宣讲会详情页面
    //   this.checkUserPayStateAndauthenticationStateAlert(()=>{
    //     // this.local.set(Constants.ctid,n.businessid);
    //     this.router.navigate(['home/airCampusDetail']);
    //   });
    // }
    // else if(alias == "college_audit_aircampustalk_passed") {
    //   //高校空中宣讲会审核通过 ==> 去空中宣讲会详情页面
    //   this.checkUserPayStateAndauthenticationStateAlert(()=>{
    //     this.router.navigate(['home/airCampusDetail']);
    //   });
    // }
    // else if(alias == "air_campustalk_passed") {
    //   //空中宣讲会审核通过 ==> 去空中宣讲会详情页面
    //   this.checkUserPayStateAndauthenticationStateAlert(()=>{
    //     this.router.navigate(['home/airCampusDetail']);
    //   });
    // } else if(alias == "air_campustalk_not_passed") {
    //   //空中宣讲会审核不通过 ==> 去空中宣讲会详情页面
    //   this.checkUserPayStateAndauthenticationStateAlert(()=>{
    //     this.router.navigate(['home/airCampusDetail']);
    //   });
    // } else if(alias =="department_apply_milkround_two_confirm") {
    //   //企业申请双选会二次确认 ==> 去双选会详情页面
    //   this.checkUserPayStateAndauthenticationStateAlert(()=>{
    //     //去双选会详情页面
    //     that.router.navigate(['home/college-milkdetails'],{queryParams: {id:n.businessid,}});
    //   });
    // } else if(alias == "auto_campustalk_modify_notice_department_member") {
    //   //宣讲会修改通知 ==> 去宣讲会详情页面
    //   this.checkUserPayStateAndauthenticationStateAlert(()=>{
    //     //去双选会详情页面
    //     this.local.set("ctid", n.businessid);
    //     this.router.navigate(['home/college-details-preach']);
    //   });
    // } else if(alias =="department_view_resume") {
    //   //谁看过我 == >对我感兴趣页面 home/chat-collection
    //   that.router.navigate(['home/chat-collection']);
    // } else if(alias == "department_position_be_viewed") {
    //   //企业职位被查看 == >对我感兴趣页面 home/chat-collection
    //   that.router.navigate(['home/chat-collection']);
    // }
    // if(n.alias == 'lacoin_charge_success'){
    //   this.router.navigate(['home/tool-campus']);
    // }else if(n.alias == 'product_charge_success' || n.alias == 'product_charge_failed'){
    //   if(n.alias == 'product_charge_success'){
    //     this.router.navigate(['home/college-resume']);
    //   }else{
    //     this.router.navigate(['home/tool-campus']);
    //   }
    // } else if(n.alias == 'talent_delivery'){
    //   this.dataApi.getDeliveryRecord(n.businessid).then((res:any)=>{
    //     if(res){
    //       this.opera.checkMember((isPay)=>{
    //         if(isPay) {
    //           this.router.navigate(['home/college-resumedetails'], {queryParams: {resumeid:res.resume_number}});
    //         } else {
    //           //getcollegePublic
    //           //不是会员。检测学生高校是否有就业信息网，没有的话，提示购买会员。有的话可以查看简历
    //           // that.opera.checkMemberAlertWithType("goBuy")
    //           if(res.collegeid==null||res.collegeid=="") {
    //             this.router.navigate(['home/college-resumedetails'], {queryParams: {resumeid:res.resume_number}});
    //           } else {
    //             this.dataApi.getcollegePublic(res.collegeid).then((res:any)=>{
    //               this.opera.loadiss();
    //               if(res) {
    //                 console.log('getcollegePublic',res.audit_switch);
    //                 if(res.audit_switch.is_gwladd) {
    //                   that.opera.checkMemberAlertWithType("resume_delivery")
    //                 } else {
    //                   this.router.navigate(['home/college-resumedetails'], {queryParams: {resumeid:res.resume_number}});
    //                 }
    //               }
    //             }).catch((error) => {
    //               console.log(error);
    //             });
    //           }
    //         }
    //       })
    //     }
    //   }).catch((error) => {
    //     this.toastr.error(error);
    //   });
    // }else if(n.alias == 'college_audit_not_passed' || n.alias == 'college_audit_passed'){
    //   if(n.alias == 'college_audit_not_passed'){
    //     this.router.navigate(['home/company-identity'],{queryParams: {status:3,}});
    //   }else if(n.alias == 'college_audit_passed'){
    //     this.router.navigate(['home/company-identity'],{queryParams: {status:2,}});
    //   }
    // }else if( n.alias == 'college_audit_position_passed' || n.alias == 'college_audit_position_not_passed'){
    //   if(n.alias == 'college_audit_position_not_passed'){
    //     this.router.navigate(['home/position-publish']);
    //   } else{
    //     this.router.navigate(['home/position-manage']);
    //   }
    // }else if(n.alias == 'department_campustalk_apply_wait_to_audit' ||n.alias == 'college_audit_campustalk_passed' || n.alias == 'college_audit_campustalk_not_passed' || n.alias =='system_notice_department_campustalk_not_passed'){
    //   if(n.alias == 'college_audit_campustalk_passed'){
    //     this.local.set("ctid", n.businessid);
    //     this.router.navigate(['home/college-details-preach']);
    //   }else if(n.alias == 'college_audit_campustalk_not_passed'){
    //     this.router.navigate(['home/position-campus']);
    //   }else if(n.alias == 'department_campustalk_apply_wait_to_audit'){
    //     this.router.navigate(['home/position-campus']);
    //   }else if(n.alias == 'system_notice_department_campustalk_not_passed'){
    //     this.router.navigate(['home/college-resume']);
    //   }else{
    //     this.local.set("ctid", n.businessid);
    //     this.router.navigate(['home/college-details-preach']);
    //   }
    // }else if(n.alias == 'lacoin_charge_failed'){
    //   this.router.navigate(['home/buyLaCoin']);
    // }else if(n.alias == 'talent_leave_message_to_department'){
    //   this.goContactChat(n);
    //   //this.router.navigate(['home/chat-contact']);
    // }else if(n.alias == 'authentication_not_passed' || n.alias == 'authentication_passed'){
    //     this.goCertification();
    // } else if(n.alias == 'product_charge_failed'){
    //   this.local.set("recharge_category_code", JSON.stringify(n.businessid));
    //   this.router.navigate(['home/tool-campus']);
    // }else if(n.alias == 'milkround_apply_wait_to_audit' || n.alias == 'department_apply_milkround_confirm' ||n.alias == 'milkround_apply_not_passed' || n.alias == 'milkround_notice_department_by_depttype' || n.alias == 'milkround_notice_department_by_feedback' || n.alias == 'milkround_invite_department' || n.alias == 'system_notice_milkround_apply_not_passed' || n.alias == 'milkround_notice_department_member_by_manage' || n.alias == 'milkround_secondary_notice_department' || n.alias == 'auto_milkround_modify_notice_department_member'){
    //
    //   if(n.alias == 'milkround_invite_department' || n.alias == 'milkround_notice_department_member_by_manage' || n.alias == 'milkround_secondary_notice_department' || n.alias == 'auto_milkround_modify_notice_department_member'|| n.alias == 'milkround_notice_department_by_depttype'){
    //     if(n.alias == 'milkround_notice_department_member_by_manage' || n.alias == 'milkround_secondary_notice_department' || n.alias == 'auto_milkround_modify_notice_department_member' || n.alias == 'milkround_notice_department_by_depttype'){
    //       let that = this;
    //       this.opera.checkMember((isPay)=>{
    //         if(that.config.isMember_trial) {
    //           this.dataApi.getMilkDetails(n.businessid).then((res:any)=>{
    //             if(res) {
    //               if(res.is_welfare) {
    //                 that.router.navigate(['home/college-milkdetails'],{
    //                   queryParams: {
    //                     id:n.businessid,
    //                   }
    //                 });
    //               } else {
    //                 that.opera.checkMemberAlertWithType("goMilk")
    //                 that.router.navigate(['home/tool-campus']);
    //               }
    //             }
    //           }).catch((error)=>{
    //             this.toastr.error(error);
    //             this.opera.loadiss();
    //           })
    //           return;
    //         }
    //         if(isPay) {
    //           that.router.navigate(['home/college-milkdetails'],{
    //             queryParams: {
    //               id:n.businessid,
    //             }
    //           });
    //         } else {
    //           that.opera.checkMemberAlertWithType("goMilk")
    //         }
    //       })
    //     }else{
    //       this.router.navigate(['home/college-milkdetails'],{
    //         queryParams: {
    //           id:n.businessid,
    //         }
    //       });
    //     }
    //   }else if(n.alias == 'milkround_apply_wait_to_audit' ||n.alias == 'milkround_apply_not_passed'){
    //     this.local.set("mrid", n.businessid);
    //     this.router.navigate(['home/college-milk-sign'],{
    //       queryParams: {
    //         popup:true,
    //       }
    //     });
    //   }else if(n.alias == 'department_apply_milkround_confirm'){
    //     //双选会申请成功/已确认
    //     // this.router.navigate(['home/position-milk']);
    //     this.router.navigate(['home/college-milkdetails'],{
    //       queryParams:{
    //         id:n.businessid
    //       }
    //     })
    //   }else if(n.alias == 'system_notice_milkround_apply_not_passed'){
    //     this.router.navigate(['home/college-resume']);
    //   } else{
    //     let that = this;
    //     this.opera.checkMember((isPay)=>{
    //       if(that.config.isMember_trial) {
    //         that.opera.checkMemberAlertWithType("goBuy")
    //         that.router.navigate(['home/tool-campus']);
    //         return;
    //       }
    //       if(isPay) {
    //         that.router.navigate(['home/college-milkdetails'],{queryParams: {id:n.businessid,}});
    //       } else {
    //         that.opera.checkMemberAlertWithType("goBuy")
    //       }
    //     })
    //   }
    // }else if(n.alias == 'department_balance_remind_system_notice' || n.alias == 'system_give_coupon_receive_for_balance_not_enough' || n.alias == 'system_give_coupon_receive_for_nologin30day' || n.alias == 'system_remind_coupon_3day_expired'){
    //   this.router.navigate(['home/myCard']);
    // } else if(n.alias =='position_expiration_notice') {
    //   console.log('职位过期')
    //   that.router.navigate(['home/position-manage'],);
    //   this.opera.initConfig('position','position-manage');
    // } else{
    //   this.getTaskTodoList();
    // }
  }

  // 去工作啦认证页面
  goCertification(){
    this.router.navigateByUrl('/guide-audit');
  }
  //获取分页子组件返回数据
  getChildEvent(index){
    console.log('子组件返回分页数----',index);
    // console.log(1);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*10;
      this.getTaskTodoList();//获取列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*10;
        console.log(2);
        this.getTaskTodoList();//获取列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*10;
        console.log(3);
        this.getTaskTodoList();//获取列表
      }
    }

  }

  //初始化监听消息通知
  initEvents (){
    //收到新消息广播
    this.events.subscribe('newMM:tabs', (data) => {
      // console.log('通知页面收到消息新消息',this.config.msgList)
    })
  }
  formatMsgTime(str) {
    let data = new Date(str);
    let getMinutes = data.getMinutes()+'';
    if(Number(getMinutes)<=9) {
      getMinutes = "0"+getMinutes;
    }
    return data.getMonth()+1 +"月"+data.getDate()+'日'+data.getHours()+":"+getMinutes;
  }
  //点击M消息，并删除当前点击的列表
  msgLick(n){
    if(!n.from) {
      return;
    }
    this.local.set('userJmUsername',n.from)
    let arr = [];
    for (let msg of this.config.msgList) {
      if(n.from !=msg.from) {
        arr.push(msg)
      }
    }
    this.config.msgList = arr;
    MyMMessage.instance.events.publish("notificationClick");
    this.local.set('chat_back_router','notification-center')
    this.router.navigateByUrl('/home/chatSingle');
  }
  //检测用户的是否认证，付费
  checkUserPayStateAndauthenticationStateAlert(callBack){
    this.dataApi.getPlatformApply().then((res:any)=>{
      console.log('checkUserPayStateAndauthenticationStateAlert',this.config,NoneVipAlertComponent)
      if(res)
      {
        if(res.status == 0){
          this.dialog.open(NoneVipAlertComponent, {
            data: {
              type:'no_audit',
              message:this.config
            }
          }).afterClosed().subscribe((isConfirmed) => {
            this.opera.scrollTrue();
            console.log('isConfirmed',isConfirmed)
            if(isConfirmed){
            }
          });
        }else if(res.status == 1){
          this.dialog.open(NoneVipAlertComponent, {
            data: {
              type:'audit',
              message:this.config
            }
          }).afterClosed().subscribe((isConfirmed) => {
            this.opera.scrollTrue();
            console.log('isConfirmed',isConfirmed)
            if(isConfirmed){
            }
          });
        }else if(res.status == 2){
          if(this.opera.isShowPaySuccessModule()){
            callBack();
          }else{
            this.dialog.open(NoneVipAlertComponent, {
              data: {
                type:'goMilk',
                message:this.config
              }
            }).afterClosed().subscribe((isConfirmed) => {
              this.opera.scrollTrue();
              console.log('isConfirmed',isConfirmed)
              if(isConfirmed){
              }
            });
          }
        }else if(res.status == 3){
          this.dialog.open(NoneVipAlertComponent, {
            data: {
              type:'fail',
              message:this.config
            }
          }).afterClosed().subscribe((isConfirmed) => {
            this.opera.scrollTrue();
            console.log('isConfirmed',isConfirmed)
            if(isConfirmed){
            }
          });
        }
        return;
      }
    }).catch((error) => {
      console.log(error);
      //this.toastr.error(error);
      return;
    });
  }

  //设置全部消息为已读
 async setAllMsgView(){
    this.opera.loading();
    this.dataApi.setMsgAllView().then((res:any)=>{
      this.opera.loadiss();
      this.toastr.success('成功设为已读');
      this.getTaskTodoList();
      this.events.publish('isReadNotice',false);
      this.dataApi.getMsgUnViewCount().then((ret:any)=>{
        if(ret){
          console.log('this.config.tasktodo',ret);
          this.config.tasktodo = ret.value;
        }
      });
    }).catch((error) => {
      this.opera.loadiss();
       this.toastr.error(error);
    });
  }

}
