<!-- 页头部分 -->
<div class="contentRight-header"> 招聘管理 - 我的宣讲会 - 宣讲会详情</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <div class="gobackbtn" (click)="opera.goPre()">
    <img src='assets/images/router-old.png'/>
    <span>返回</span>
  </div>
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <!-- <app-model-college-milksign #milkUpdata [number]="milkNumber" [mridList]="mridList" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-milksign> -->
  <div class="college-block" *ngIf='data'>
      <div class="img-cover">
          <img *ngIf="data.cover" [src]="data.cover">
          <img *ngIf="!data.cover" src="assets/images/none.png">
          <div class="college-title">{{data.name}}</div>
        <button class="tongzhi" *ngIf="data.cooperation_status==2&&data.audit_status=='passed' &&data.curr_total_student>=1000&& opera.isShowPaySuccessModule1()&&!data.isEnd" (click)="gotoTongzhi()">通知学生到场</button>
      </div>
      <div class="college-center">
          <div class="cont-title"><span></span>宣讲会详情</div>
          <div class="cont-txt clear">
              <div class="cont-list" *ngIf='data.name'><span>宣讲会主题&nbsp;&nbsp;&nbsp;</span>{{data.name}}</div>
              <div class="cont-list" *ngIf='data.start_time'><span>&nbsp;&nbsp;&nbsp;开始时间&nbsp;&nbsp;&nbsp;</span>{{data.start_time | date:'yyyy-MM-dd HH:mm'}}</div>
              <div class="cont-list" *ngIf='data.end_time'><span>&nbsp;&nbsp;&nbsp;结束时间&nbsp;&nbsp;&nbsp;</span>{{data.end_time | date:'yyyy-MM-dd HH:mm'}}</div>
              <div class="cont-list" *ngIf='data.place_name'><span>&nbsp;&nbsp;&nbsp;举办场地&nbsp;&nbsp;&nbsp;</span>{{data.place_name}}</div>
              <div class="cont-list" *ngIf='data.contact_name'><span>高校负责人&nbsp;&nbsp;&nbsp;</span>{{data.contact_name}}<span *ngIf='data.contact_phone'>-</span>{{data.contact_phone}}</div>
              <div class="cont-list" *ngIf='data.audit_status=="to_certificate"'><span>&nbsp;&nbsp;&nbsp;审核结果&nbsp;&nbsp;&nbsp;</span>待审核</div>
              <div class="cont-list" *ngIf='data.audit_status=="passed"'><span>&nbsp;&nbsp;&nbsp;审核结果&nbsp;&nbsp;&nbsp;</span>通过</div>
              <div class="cont-list" *ngIf='data.audit_status=="to_audit"'><span>&nbsp;&nbsp;&nbsp;审核结果&nbsp;&nbsp;&nbsp;</span>审核中</div>
              <div class="cont-list" *ngIf='data.audit_status=="not_passed"'><span>&nbsp;&nbsp;&nbsp;审核结果&nbsp;&nbsp;&nbsp;</span>未通过</div>
              <button *ngIf='data.audit_status=="not_passed"' class="secBtn" (click)="goSign(data.collegeid)">再次申请</button>
              <!-- <button class="secBtn" (click)="goSign(data.collegeid)">再次申请</button> -->
          </div>
          <div class="tBclock"></div>
      </div>
  </div>
</div>
