import {Component, EventEmitter, Input, OnInit, Output,ViewChild} from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {productManager} from "../../provider/productManager";
import {regexManager} from "../../provider/regexManager";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
@Component({
  selector: 'app-voice-call-model',
  templateUrl: './voice-call-model.component.html',
  styleUrls: ['./voice-call-model.component.css']
})
export class VoiceCallModelComponent implements OnInit {
  public config: Config;
 //是否打开产品介绍/请校招顾问联系我 弹窗
 @Output() isproductShowModel = false;
 @ViewChild('productModel', { static: true }) 
 productModel: ProductModelComponent;
  constructor(public dataApi: DataApi,
              public local:Local,
              private sanitizer: DomSanitizer,
              private router: Router,
              public opera:Opera,
              private activatedRoute: ActivatedRoute,
              public toastr: ToastrService,
              public regexManager:regexManager,
              public productManager:productManager
    ) {
    this.config = Config.getInstance();//实例化config

  }

  @Input()  isShow: false;//是否显示弹窗页面
  @Input() resumeDetails:any;
  @Output() modelHidden = new EventEmitter<number>();
  public phoneModel={
    myPhone:'', //企业拔打电话的手机
    myTel:'',
    myTel_qu:'',
    isOkmyPhone:false,
    NetPhone:'',
    positionid:'',
    getPhoneType:'1',
    PhoneModelType: 0,
    MyPhoneTime:0,
    isShowPhoneModel:false,
    jiduCode:'',
    positionPushCode:'',
  }

  public currentPostion = null
  public  positionList = [];
  public myDepartment:any;//当前登录的企业信息(department)

  public timer = null;
  public timeIndex = 120;
  public isTimeOut = false;

  public smsContent = ""
  public smsCount = 0
  public sendSmsing = false
  public phone = ''
  ngOnInit() {
    //获取职位
    this.getNewList();
    
  }
  initData(resumeDetail){
    console.log('resumeDetail',resumeDetail);
    this.resumeDetails = resumeDetail;
    this.productManager.init();
    this.getDepartment();
    this.phoneModel.PhoneModelType=0;
    this.getMSGContent()
    this.getSmsCount()
    if(resumeDetail.positionid){
      this.phoneModel.positionid = resumeDetail.positionid;
    }else {
      this.phoneModel.positionid = "";
    }
  }
  _modelHidden(){
    if(this.timer) {
      clearInterval(this.timer);
    }
    this.modelHidden.emit();
  }
  //-------获取线上数据
  getNewList(){
    let model={
      limit:100,
      offset:0,
      status:'release',
    }
    this.dataApi.getPositionList(model).then((res:any)=>{
      console.log("获取职位",res);
      if(res)
      {
        this.positionList =  res.list;
        this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }
  //确定拔打的手机//获取网络虚拟好
  checkMyPhone(){
    console.log("resumeDetails",this.resumeDetails,this.productManager.my_voice_call)
    if(this.productManager.my_voice_call)
    {
      if(this.productManager.my_voice_call.left<=0)
      {
        this._modelHidden();
        let that = this;
        setTimeout(()=>{
          //去购买极速电话
          that.local.set("recharge_category_code", JSON.stringify(this.productManager.voice_call_code));
          // that.router.navigate(['home/buyProduct']);
          this.router.navigate(['home/tool-campus']);
        },600)

        return;
      }
    }
    if(this.phoneModel.myPhone==''&&this.phoneModel.getPhoneType=='1')
    {
      this.toastr.warning("请输入手机号")
      return;
    }
    if(!this.regexManager.isPhone(this.phoneModel.myPhone))
    {
      this.toastr.warning("手机号格式错误，请重新填写")
      return;
    }
    if(this.resumeDetails.talentid.length==0)
    {
      this.toastr.warning("获取用户信息失败")
      return;
    }
    if(this.phoneModel.positionid=='')
    {
      this.toastr.warning("请选择职位");
      return;
    }
    let data = {
      mobile:this.phoneModel.getPhoneType=='1'?this.phoneModel.myPhone:'',
      talentid:this.resumeDetails.talentid,
      contact_type:this.phoneModel.getPhoneType=='1'?'mobile':'tel',
      tel:this.phoneModel.getPhoneType=='1'?'':this.phoneModel.myTel_qu+''+this.phoneModel.myTel,
      positionid:this.phoneModel.positionid
    };
    // this.phoneModel.PhoneModelType = 1;
    // this.phoneModel.NetPhone = '999999999'
    this.dataApi.GetVirtualNumber(data).then((res:any)=>{
      console.log("GetVirtualNumber",res);
      if(res)
      {
        this.isTimeOut = false;
        this.startTime();
        this.phoneModel.isOkmyPhone = true;
        this.phoneModel.PhoneModelType = 1;
        this.phoneModel.NetPhone = res.value;
        this.myDepartment.virtualnumber_mobile = this.phoneModel.myPhone;
        this.dataApi.setLocalDepartment(this.myDepartment);//将企业信息存到本地、全局变量
      }


    }).catch((err)=>{
      console.log("GetVirtualNumber",err);
      if(err == "您还没通过的合作申请") {
        this.opera.freeAttestation("通过工作啦认证才能查看全部学生，立即认证！");//提醒认证
      } else {
        this.toastr.error(err);
      }
      this.phoneModel.isShowPhoneModel = false;

    })
  }
  //获取企业信息
  getDepartment(){
    this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
      console.log("企业信息线上数据",res);
      //console.log(res);
      if(res){
        this.myDepartment = res;

        if(this.myDepartment.virtualnumber!=null)
        {
          if(this.myDepartment.virtualnumber.contact_type=='tel')
          {
            this.phoneModel.getPhoneType = '1';
            // let qu =this.myDepartment.virtualnumber.tel.substring(0,4)
            // let tel =this.myDepartment.virtualnumber.tel.substring(4,this.myDepartment.virtualnumber.tel.length)
            // this.phoneModel.myTel_qu = qu;
            // this.phoneModel.myTel = tel;
          }
          else
          {
            this.phoneModel.myPhone =  this.myDepartment.virtualnumber.mobile;
            this.phoneModel.getPhoneType = '1';
          }
        }
        else
        {
          this.getUser();
        }

      }
    }).catch((error)=>{
      this.toastr.error(error);
    });
  }
  //获取用户信息
  getUser(){
    this.dataApi.getUser().then((res:any)=>{
      if(res){

        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
        this.phoneModel.myPhone =res.mobile;
      }
    }).catch((error)=>{
      this.toastr.error(error);
    });

  }
  updataMyPhone(){
    this.phoneModel.PhoneModelType = 0;
  }
  startTime(){
    let that = this;
    if(this.timer) {
      clearInterval(this.timer)
    }
    that.timeIndex = 120;
    this.timer = setInterval(()=>{
      that.timeIndex-=1;
      if(that.timeIndex==0) {
        that.isTimeOut = true;
        clearInterval(this.timer)
      }
    },1000)
  }
  seletePositionChange(){
    console.log('seletePositionChange',this.phoneModel.positionid)
    this.positionList.map((item)=>{
      if(item.positionid ==this.phoneModel.positionid) {
        this.currentPostion = item
      }
    })
  }
  //获取短信模版
  getMSGContent(){
    this.dataApi.getMSGContent("邀投简历").then((res:any)=>{
      // console.log('getMSGContent',res);
      this.smsContent = res.text.replace('#talent_name#',this.resumeDetails.talent_name);
      this.getPushPhone();
    }).catch((err)=>{
      // console.log('getMSGContenterr',err);
      this.toastr.error(err)
    })
  }
  getSmsCount(){
    this.productManager.getProductUsableCount(this.config.sms_bulkinvitation,(count,isVip)=>{

      this.smsCount = count
      console.log('getSmsCount',count)
    })
  }
  pushSms(){
    if(this.smsCount == 0) {
      this.toastr.error('当前账户短信余量不足')
      return;
    }
    if(this.sendSmsing){
      this.toastr.success('请求中');
      return
    }
    this.sendSmsing = true
    let data = {
      "talentids":[this.resumeDetails.talentid],
      "positionid": this.phoneModel.positionid
    }
    // this.dataApi.batchPositionPush(this.phoneModel.positionid,{invite_count:1,talentids:[this.resumeDetails.talentid]}).then((res:any)=>{

      this.dataApi.batchPositionPush(this.phoneModel.positionid,{invite_count:1,phone:this.phone,talentids:[this.resumeDetails.talentid]}).then((res:any)=>{
      this.toastr.success('推送成功!');
      this.sendSmsing = false
      this.getSmsCount()
    }).catch((err)=> {
      console.log("error",err);
      this.toastr.error(err);
      this.sendSmsing = false

    });
  }

  //获取上次职位推送电话
  getPushPhone(){
    this.dataApi.getPushPhone().then((res:any)=>{
      console.log('获取上次职位推送电话',res.value);
      this.phone = res.value;
      this.smsContent = this.smsContent.substring(0,this.smsContent.indexOf('HR电话')+4) + this.phone
    }).catch((err)=>{

    })
  }
  changeinput(phone){
    this.phone = phone;
    this.smsContent = this.smsContent.substring(0,this.smsContent.indexOf('HR电话')+4) + this.phone;
  }

  //点击产品
  product(alias,type){
    this.isproductShowModel = true;
    this.productModel.initData(alias,type);
  }

  productModelHidden(event){    
    this.isproductShowModel = false;
  }

  _positionPush(){
    this._modelHidden();
    setTimeout(()=>{
      this.router.navigate(['home/position-publish']);
    },100);
  }
}
