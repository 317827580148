import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { DataApi, Config, Local, Constants, Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { productManager } from "../../provider/productManager";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
declare let layui;
declare let Swiper: any;
@Component({
  selector: 'app-college-resumedetails',
  templateUrl: './position-pushs.component.html',
  styleUrls: ['./position-pushs.component.css']
})
export class PositionPushsComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true })
  productModel: ProductModelComponent;

  public config: Config;
  public isInit = true;//是否初始化成功
  public isIgnore = false;//是否忽略学生意向
  public checkedJob = '';//初始化选中职位id
  public position = {
    post_type: null,
    positionid: null,
    name: null
  };//初始化选中职位
  public stucount = 0;//初始化发送学生个数
  public stuMatchCount = 0;//初始化符合学生个数
  public jobinfo = [];//职位信息
  public cominfo;//企业信息
  public totalCoin = 0;//总资源数
  public needCoin = 0;//所需资源数
  public leftCoin = 0;//剩余资源数
  public allCollege = [];//全部高校
  public allMajor = [];//全部专业
  public allEducation = [];//学历字典
  public allSex = [];//全部性别
  public allArea = [];//全部地域
  public areaListLevel1 = [];//全部地域1
  public areaListLevel2 = [];//全部地域2
  public college = '';//选中高校
  public collegeList = [];//选中高校
  public collegeShowList = [];//选中高校展示列表
  public majorList = [];//选中专业
  public majorLevel1List = [];//备选专业展示列表
  public majorLevel1 = '';//选中专业分类
  public majorLevel2List = [];//备选专业展示列表
  public majorLevel2 = '';//选中专业分类
  public majorLevel2ShowList = [];//选中专业展示列表
  public education = '';//选中学历
  public checkedSex = '';//选中性别
  public year = '';
  public area1 = '';//选中户口所在地
  public area2 = '';//选中户口所在地
  public areaList = [];//选中户口所在地
  public areaShowListLevel2 = [];//选中户口所在地
  public areaShowList = [];//选中地域展示列表
  public phoneModel = {
    jiduCode: '',
    positionPushCode: ''
  }
  public phoneText = '151XXXXXXX';//选中地域展示列表
  public sendMsg = `【工作啦】{学生姓名}，${JSON.parse(localStorage.getItem('department')).name}对你的简历很感兴趣，邀您投递{职位名称}。详情点击{链接} HR电话：${this.phoneText}。`;//初始化发送信息

  public isShowPushModel = false;
  public isLoading = false;
  public jiebieList: any = [];
  constructor(
    public dataApi: DataApi,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public opera: Opera,
    public local: Local,
    public dialog: MatDialog,
    public toastr: ToastrService,
    public productManager: productManager,

  ) {
    this.config = Config.getInstance(); // 实例化config

  };

  ngOnInit() {
    this.getJiebie();
    let hash = location.hash;
    this.getJobList();
    this.getCom();
    this.getAllCollege();
    this.getAreaList();
    this.getProductList();
    this.getMajorInfo();
    this.getPositionPushInfo();
    // if(this.local.get('loginName')){
    //   this.sendMsg = this.sendMsg.replace('151XXXXXXX',this.local.get('loginName'))
    // }
    if (this.local.get('loginUser')) {
      this.getPushPhone();
      // let phone = JSON.parse(this.local.get('loginUser')).mobile;
      // this.phoneText = phone;
      // this.sendMsg = `【工作啦】{学生姓名}，${JSON.parse(localStorage.getItem('department')).name}对你的简历很感兴趣，邀您投递{职位名称}。详情点击{链接} HR电话：${this.phoneText}。`
      // this.sendMsg = this.sendMsg.replace('151XXXXXXX',phone)
    }
  }

  // 企业获取简历开放日期所在年份
  getJiebie() {
    this.dataApi.getCircles().then((res: any) => {
      if (res.length) {
        let min = Math.min(...res);
        this.jiebieList = res.map(item => {
          return {
            text: item == min ? '往届' : `${item}届`,
            code: item
          }
        })
      }
    }).catch((error) => {
      console.log('企业获取简历开放日期所在年份', error);
    });
  }

  productModelHidden(event) {
    this.isproductShowModel = false;
  }

  //点击产品
  product(alias, type) {
    this.productModel.initData(alias, type);
    this.isproductShowModel = true;
  }

  minus() {
    if (this.stucount > 0) {
      this.stucount -= 1;
      this.checkNum()
    }
  }

  add() {
    this.stucount += 1;
    this.checkNum()
  }
  //检查用户输入
  checkNum() {
    if (this.stucount < 0) {
      this.stucount = 0
    } else if (this.stucount > this.stuMatchCount) {
      this.toastr.info('您可以调整筛选条件，增加匹配结果');
      this.stucount = this.stuMatchCount
    }
  }
  getProductList() {
    //product_category:"recharge",recharge_category:''
    this.dataApi.departmentList({ product_category: "recharge" }).then((res: any) => {
      if (res) {
        for (let item of res.list) {
          if (item.recharge_category == "voice_call") {
            this.phoneModel.jiduCode = item.code;
          }
          if (item.recharge_category == "sms_bulkinvitation") {
            this.phoneModel.positionPushCode = item.code;
          }
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  };
  //改变学生意向选择
  changeIgnore(sign) {
    this.isIgnore = sign
    this.getPositionPushInfo()
  }
  //获取选中职位信息
  getPosition() {
    this.position = null;
    for (let i = 0; i < this.jobinfo.length; i++) {
      if (this.jobinfo[i].positionid == this.checkedJob) {
        this.position = this.jobinfo[i]
        console.log(this.position)
        this.sendMsg = `【工作啦】{学生姓名}，${JSON.parse(localStorage.getItem('department')).name}对你的简历很感兴趣，邀您投递{职位名称}。详情点击{链接} HR电话：${this.phoneText}。`;

      }
    }
    if (this.local.get('loginUser')) {
      let phone = JSON.parse(this.local.get('loginUser')).mobile;
      this.phoneText = phone;
      // this.sendMsg = this.sendMsg.replace('151XXXXXXX',phone)
    }
    this.getPositionPushInfo()
  }
  //职位列表
  getJobList() {
    this.dataApi.getPositionList({
      status: 'release',
      name: '',
      offset: 0,
      limit: 999
    }).then((res: any) => {
      this.jobinfo = res.list
      console.log(this.jobinfo)
      for (let i = 0; i < this.jobinfo.length; i++) {
        if (this.jobinfo[i].position_status != 'release') {
          this.jobinfo.splice(i, 1)
          i--;
        }
      }
    });
  }
  //企业信息
  getCom() {
    this.dataApi.getEmployment().then((res: any) => {
      this.cominfo = res
    })
    this.productManager.getProductUsableCount(this.config.sms_bulkinvitation, (count) => {
      this.totalCoin = count;
    })
  }
  //区域字典
  getAreaList() {
    this.dataApi.getRegionCode_dic((res: any) => {
      this.allArea = res
      for (let i of this.allArea) {
        if (i.level === 1) {
          this.areaListLevel1.push(i)
        } else if (i.level === 2) {
          this.areaListLevel2.push(i)
        }
      }
      let arr = [];
      for (let n of this.areaListLevel1) {
        let item = {
          code: n.code,
          level: 2,
          order: 0,
          parentcode: n.code,
          parenttext: null,
          relation_code: null,
          relation_desc: "",
          text: n.text + '-全省',
        };
        arr.push(item);
      }
      console.log('全省', this.areaListLevel1, this.areaListLevel2, arr);
      this.areaListLevel2 = [...arr, ...this.areaListLevel2]
    })
  }

  changeAreaLevelList2() {
    this.areaShowListLevel2 = []
    for (let i of this.areaListLevel2) {
      if (i.parentcode === this.area1) {
        this.areaShowListLevel2.push(i)
      }
    }
    this.area2 = "";
    //this.area1

  }
  //添加区域
  addArea($event) {
    if (this.areaList.length < 5 && this.areaList.indexOf(this.area2) == -1) {
      for (let col of this.areaListLevel2) {
        if (col.code === this.area2 && this.area2 != '') {
          this.areaShowList.push(col)
          this.areaList.push(this.area2)
          // $event.target.value=''
        }
      }
      this.getPositionPushInfo()
    } else {
      this.toastr.info('最多选中5个地域且不能重复');
      $event.target.value = ''
      return false;
    }
  }
  //获取全部高校
  getAllCollege() {
    this.dataApi.getcollegeWithYear(this.year).then((res: any) => {
      console.log('getcollegeWithYear', res);
      if (res) {
        this.allCollege = res.list;
      }
    }).catch((err) => {
    })
  }
  //添加高校
  addCollege($event) {
    console.log($event);
    if (this.collegeList.length < 5 && this.collegeList.indexOf(this.college) == -1) {
      for (let col of this.allCollege) {
        if (col.collegeid === this.college) {
          this.collegeList.push(this.college)
          this.collegeShowList.push({ 'name': col.name, 'count': col.total_student })
          $event.target.value = ''
          //majorLevel1
        }
      }
      this.getPositionPushInfo()
    } else {
      this.toastr.info('最多选中5所高校且不能重复');
      $event.target.value = ''
      return false;
    }
  }
  multiSelectonChange($event) {
    console.log($event);
    this.getPositionPushInfo()
  }

  //获取选中高校专业信息
  getMajorInfo() {
    this.dataApi.getPositionPushList({
      'post_type': this.position.post_type
      , 'studentIntention_ignore': this.isIgnore
    })
      .then((res: any) => {
        this.allMajor = res.facetgroups;
        this.stuMatchCount = res.count;
        this.stucount = res.count;
        this.majorLevel1List = [];
        for (let major1 of res.facetgroups) {
          if (major1.code.length == 2) {
            this.majorLevel1List.push(major1)
          }
        }
        let allArr = [];
        for (let n of this.majorLevel1List) {
          let data = {
            code: n.code,
            count: n.count,
            level: 2,
            parentcode: n.code,
            parentcode_text: n.text,
            text: "全部专业",
          }
          allArr.push(data);
        }


        this.allMajor = [...allArr, ...this.allMajor]
      }).catch((err) => {
      })
  }
  //获取匹配数量
  getPositionPushInfo() {
    this.dataApi.getPositionPushCountList({
      "collegeids": this.collegeList,
      'majors': this.majorList,
      'education': this.education,
      'gender': this.checkedSex,
      'native_places': this.areaList,
      'post_type': this.position.post_type ? this.position.post_type : null,
      'studentIntention_ignore': this.isIgnore,
      'year': this.year,
      positions: this.checkedJob ? [this.checkedJob] : []
    })
      .then((res: any) => {
        this.stuMatchCount = res.value;
        this.stucount = res.value;
        this.checkNum();
      }).catch((err) => {
      })
  }
  //界别的数据改变
  getPositionPushInfoJieBieChange() {
    this.collegeList = [];
    this.collegeShowList = [];
    this.college = "";
    //getcollegeWithYear
    this.dataApi.getcollegeWithYear(this.year).then((res: any) => {
      console.log('getcollegeWithYear', res);
      if (res) {
        this.allCollege = res.list;
      }
    }).catch((err) => {
    })
    this.getPositionPushInfo();
  }

  getMajorLevel2List() {
    this.majorLevel2List = []
    for (let major2 of this.allMajor) {
      if (this.majorLevel1 === major2.parentcode) {
        this.majorLevel2List.push(major2)
      }
    }
    this.majorLevel2 = "";
  }
  //添加专业
  addMajor($event) {
    //majorLevel2
    if (this.majorList.length < 10 && this.majorList.indexOf(this.majorLevel2) == -1) {
      for (let maj of this.majorLevel2List) {
        if (maj.code === this.majorLevel2) {
          this.majorList.push(this.majorLevel2)
          this.majorLevel2ShowList.push(maj)
          // $event.target.value=''
          this.getPositionPushInfo()
        }
      }
    } else {
      $event.target.value = ''
      this.toastr.info('最多选中同一学科门类下的10个不同专业');
    }
    console.log('添加专业', this.majorLevel2, this.majorLevel2List)
  }
  //删除高校
  deleteCollege(idx) {
    this.collegeList.splice(idx, 1)
    this.collegeShowList.splice(idx, 1)
    // this.majorLevel1=''
    // this.majorLevel2=''
    // this.majorList=[]
    // this.majorLevel2ShowList=[]
    this.getPositionPushInfo()
  }
  //删除专业
  deleteMajor(idx) {
    this.majorList.splice(idx, 1)
    this.majorLevel2ShowList.splice(idx, 1)
    this.getPositionPushInfo()

    if (this.majorLevel2ShowList.length == 0) {
      this.majorLevel2 = '';
    }
  }

  //删除区域
  deleteArea(idx) {
    this.areaList.splice(idx, 1)
    this.areaShowList.splice(idx, 1)
    this.getPositionPushInfo();
    if (this.areaShowList.length == 0) {
      this.area2 = '';
    }
  }


  inviteStuAlert() {

    if (!this.checkedJob) {
      this.toastr.info('请选择您要邀请的职位');
      return;
    }
    if (!this.phoneText) {
      this.toastr.info('请输入HR招聘电话');
      return;
    }
    // 如果选择了 按学生求职意向 就要必选专业才能推送
    if (!this.isIgnore) {
      if (this.majorList.length == 0) {
        this.toastr.info('请选择专业');
        return;
      }
    }
    if (!this.year) {
      this.toastr.info('请选择届别');
      return;
    }
    let that = this;
    this.productManager.getProductUsableCount(this.config.sms_bulkinvitation, (count, isVip) => {
      this.totalCoin = count;
      if (count == 0 || !isVip) {
        // this.config.payVipHistoryPage = {
        //   page: '批量职位推送',
        //   isVip: isVip,
        //   pro: '职位推送'
        // };
        // that.local.set("single_category_code", this.config.ProductListCode.sms_bulkinvitation_code);
        // that.router.navigate(['home/tool-campus']);
        that.product('sms_bulkinvitation', 'recharge');
        return;
      }

      if (this.stuMatchCount <= 0) {
        this.toastr.info('符合条件的学生数必须大于0');
      } else if (this.stucount > this.totalCoin) {
        // this.config.payVipHistoryPage = {
        //   page: '批量职位推送',
        //   isVip: isVip,
        //   pro: '职位推送'
        // };
        // that.local.set("single_category_code", this.config.ProductListCode.sms_bulkinvitation_code);
        // that.router.navigate(['home/tool-campus']);
        that.product('sms_bulkinvitation', 'recharge');
      } else {
        if (!this.position.positionid) {
          this.toastr.info('请选择推送的职位');
          return;
        }
        this.isShowPushModel = true;
      }
    })
  }

  //发送邀请
  inviteStu() {
    this.isShowPushModel = false;
    if (this.stuMatchCount <= 0) {
      this.toastr.info('符合条件的学生数必须大于0');
    } else if (this.stucount > this.totalCoin) {
      this.toastr.info('剩余资源不足！请前往"校招工具"购买职位推送资源或调整邀请学生数量');
      this.local.set("recharge_category_code", JSON.stringify(this.phoneModel.positionPushCode));
      // this.router.navigate(['home/buyProduct']);
      this.router.navigate(['home/tool-campus']);
    } else {
      if (!this.position.positionid) {
        this.toastr.info('请选择推送的职位');
        return;
      }
      if (this.isLoading) {
        this.toastr.info('网络请求中,请勿重复点击');
        return;
      }

      this.isLoading = true;
      let that = this;
      that.dataApi.batchPositionPush(that.position.positionid, {
        "collegeids": that.collegeList, "phone": that.phoneText,
        'majors': that.majorList, 'education': that.education, 'gender': that.checkedSex,
        'native_places': that.areaList, 'invite_count': that.stucount, 'studentIntention_ignore': that.isIgnore, 'post_type':
          that.position.post_type, 'year': that.year
      })
        .then((res: any) => {
          that.toastr.success('职位信息已推送！');
          that.getCom();
          this.isLoading = false

        }).catch((err) => {
          console.log('err', err)
          that.toastr.error(err);

          this.isLoading = false
        })
    }
  }

  getparentcode_textAndmajor_text(major) {
    let parent = null;
    for (let n of this.majorLevel1List) {
      if (n.code == major.parentcode) {
        parent = n;
      }
    }
    if (parent) {
      return parent.text + " - " + major.text;
    }
    return major.text;
  }

  changeinput(event) {
    this.phoneText = event;
    this.sendMsg = `【工作啦】{学生姓名}，${JSON.parse(localStorage.getItem('department')).name}对你的简历很感兴趣，邀您投递{职位名称}。详情点击{链接} HR电话：${this.phoneText}。`
  }

  //获取上次职位推送电话
  getPushPhone() {
    this.dataApi.getPushPhone().then((res: any) => {
      console.log('获取上次职位推送电话', res.value);
      if (res && res.value) {
        this.phoneText = res.value;
        this.sendMsg = `【工作啦】{学生姓名}，${JSON.parse(localStorage.getItem('department')).name}对你的简历很感兴趣，邀您投递{职位名称}。详情点击{链接} HR电话：${this.phoneText}。`
      }
    }).catch((err) => {

    })
  }
}
