import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import { DataApi,Local,Constants,Config,Opera,MyMMessage } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { Router,ActivatedRoute } from  '@angular/router';
import QRCode from 'qrcode'
import { MatDialog } from "@angular/material/dialog";
import {UserProtocolModelComponent} from "../user-protocol-model/user-protocol-model.component";
declare var WxLogin: any;
declare let wx: any;
declare var layui;
declare var layer;
declare var Swiper:any;
import {regexManager} from "../../provider/regexManager";
import { arrayify } from 'tslint/lib/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public config: Config;
  public countDown:any;//倒计时
  public isSendOver = false;//是否发送过验证码
  public isSendIng = false;//是否为正在发送中
  public clearButton = false;//是否显示清空按钮
  public clearCode = false;//是否显示清空验证码
  public loginName: string;//手机号/账号
  public vcodeText: string;//验证码
  public vcodeImg:any;//图片验证码（用户填写）
  public imgCode:any;//图形验证码
  public imgCodeId:any;//图形验证码id
  public isRememberAccount=false;//是否选中记住账号
  public bannerListImg:any=[]; //登录广告轮播图
  public showWXCode = false;
  public showBindWXFrom = false; //是否显示微信绑定页面
  public unionid = '';

  key = '';
  setQr;
  getQrLogin;
  qrLogin: boolean = false;

  public isTest = false;

  public isRememberAccount1=false;//是否选中记住邮箱账号
  public loginType='phone';//登陆方式phone/email
  public loginEmail='';//登录邮箱
  public loginEmailPsw='';//登录邮箱密码
  public  updateLoginEmailPsw = false;
  public seePswType2='password';
  public seePswType3='password';
  public updatePswData={
    oldPsw:'',
    newPsw:'',
    okPsw:'',
  }
  public kanbanData =
      {
        updateTime:'',
        value1:"0",
        value2:"0",
        value3:"0",
        value4:"0",
        value5:"0",
        value6:"0",
      };
  public bannerList = ['1','3','4'];
  public mySwiper:any;

  public isNewUser = false;
  public isNewUserAgree = false;

  //轮播器
  public carousel_ins = null;

  public loginSource = 'b_pro';
  public wopenkey = "";

  constructor(
    public dataApi: DataApi,
    public local:Local,
    public opera:Opera,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public mimc:MyMMessage,
    private location: Location,
    public toastr: ToastrService,
    private el:ElementRef,
    private renderer2: Renderer2,
    public dialog: MatDialog,
    public regexManager:regexManager,
  ) {
    this.config = Config.getInstance();//实例化config
    this.config.isLogin=true;
  }

  ngOnInit() {
    this.getCardbanner();
    // console.log('isEstablish_year',this.regexManager.isEstablish_year('2021'));
    // console.log('isEstablish_year',this.regexManager.isEstablish_year('2021'));
    // console.log('isEstablish_year',this.regexManager.isEstablish_year('2019'));
    // console.log('isEstablish_year',this.regexManager.isEstablish_year('1999'));
    // console.log('isEstablish_year',this.regexManager.isEstablish_year('3999'));

    this.config.isNavActive = 'login';

    let hash = location.hash;
    //初始化体验会员
    this.config.isMember_trial = false;

    let code = ""
    var docWidth = document.body.clientWidth;
    this.config.isScreenSmallBox = docWidth < 1680 ? true : false;

    this.getKanbanData();
    this.bro();
    

    // 获取验证码
    this.getCode();

    //初始化赋值
    if(this.local.get(Constants.loginName)){
      this.loginName=this.local.get(Constants.loginName);
      this.isRememberAccount = true;
    }

    let list = this.dataApi.getRegionCode_dic((data)=>{
    });

    console.log("location.hash;",location.hash,hash)

    let isWxLogin = false;
    let arr = hash.split('?')
    let accountid = "";

    if(arr.length>1) {
      let urlStr = arr[1];
      let itemAr = urlStr.split('&');
      let openid = '';
      let unionid = '';
      let auth_code = "";
      let errorcode = "";
      let wopenkey = "";

      for (let item of itemAr) {
        let dataArr = item.split('=');
        if(dataArr.length>=2) {
          if(dataArr[0]=='openid') {
            openid = dataArr[1];
            isWxLogin = true
          }
          if(dataArr[0]=='unionid') {
            unionid = dataArr[1];
            isWxLogin = true
          }
          if(dataArr[0]=='code') {
            code = dataArr[1]
          }
          if(dataArr[0]=='errorcode') {
            errorcode = dataArr[1]
          }
          if(dataArr[0]=='auth_code') {
            auth_code = dataArr[1]
          }
          if(dataArr[0]=='wopenkey') {
            wopenkey = dataArr[1];
            this.wopenkey = wopenkey
          }
          if(dataArr[0]=='accountid') {
            accountid = dataArr[1];
          }


        }
      }


      if(auth_code.length>0) {
        this.unionid = auth_code;
        this.weichatLogin(auth_code);
        return;
      }else if(errorcode.length>0){
          this.toastr.error('当前微信未绑定账户');
          this.loginType = 'phone'
      }
      if(wopenkey){
        this.toastr.error('当前微信未绑定账户');
        this.loginType = 'phone'
      }
      if(code && !isWxLogin){
        console.log('自动登录',code)
        this.codeGetToken(code)
        return
      }
    } else {
      this.showBindWXFrom = false;//是否显示微信绑定页面
    }

    this.opera.loadiss();

    let path = this.location.path();
    let token = '?token=';
    let accid = '&accountid=';
    if(path.includes(token) && path.includes(accid)){
      clearInterval(this.setQr);
      clearInterval(this.getQrLogin);
    }
    if(path.includes(token)&&(
      path.includes('airCampusDetail')||
      path.includes('guide')||
      path.includes('college-milkdetails')||
      path.includes('college-campustalk1')
    )) {
      this.local.remove(Constants.accesstoken)
      this.local.remove(Constants.accountid)
      if(accountid){
        this.local.set('accountid',accountid)
      }
      this.gotoPage(path)
      return
    }
    this.opera.initConfig('login','');

    this.opera.fromEmployer();

    this.config.childNavs = []
    //判断是否是登录状态
    if(this.local.get(Constants.accesstoken)&&this.local.get(Constants.accountid)){
      if(this.local.get(Constants.typeUrl)){
        this.router.navigateByUrl(this.local.get(Constants.typeUrl));
        this.local.remove(Constants.typeUrl);
      }else{
        this.router.navigateByUrl('home/workSpase');
      }
    }
    this.checkChrome();

    setTimeout(()=>{
    // console.log('initWeiChatLogin')
      // this.initWeiChatLogin()
    },1000)

  }

  /**
   * 获取图形验证码
   */
  getCode(){
    this.dataApi.getImgCode().then((res:any)=>{
      this.imgCode='data:image/png;base64,'+res.vcode_image;//验证码图片base64
      this.imgCodeId=res.vcodeid;
    }).catch((err)=>{
    })
  }
  /**
   * 发送验证码(+验证手机号是否可用)
   */
  postMobileSmsVcode(){
    if(!this.loginName || this.isSendIng){
      return;
    }
    if(!this.vcodeImg){
      this.toastr.error("请填写图片验证码");
      return;
    }
    this.isSendIng = true;
    let filter = /^[0-9]{11}$/;
    let _params = {
      "mobile": this.loginName,
      "sms_type": "login",
      "vcode_verify": {
        "vcodeid": this.imgCodeId,
        "vcode_text":this.vcodeImg
      }
    };
    this.dataApi.checkMobile(this.loginName).then((res:any)=>{
      if(res.value==0) {
        this.isNewUser = true
      }
      this.isNewUser = true
    }).catch((err)=>{})
    if (filter.test(this.loginName)) {
      this.opera.loading();
      this.dataApi.postMobileSmsVcode(_params).then((res:any)=>{
        // this.hideClearButton();
        this.opera.loadiss();
        this.toastr.success('验证码已发送，请注意查收');
        let count = 60;
        this.countDown = count + 's';
        this.isSendOver = true;
        let timer = setInterval(()=>{
          count -= 1;
          this.countDown = count + 's';
          if (count === 0) {
            clearInterval(timer);
            this.countDown = '';
            this.isSendIng = false;
          }
        }, 1000);
      }).catch((error)=>{
        this.opera.loadiss();
        this.toastr.error(error)
        this.isSendIng = false;
      });
    }else{
      this.toastr.error('手机号码格式不正确');
      this.loginName='';
      this.isSendIng = false;
    }
  };

  /**
   * 通过手机验证码登录
   */
  postMobileSmsCode(){
    if(this.loginType=='email') {
      this.emailLogin();
      return;
    }
    if (!this.loginName) {
      this.toastr.error('手机号码不能为空');
        return;
    }
    if (!this.vcodeText) {
      this.toastr.error('验证码不能为空');
        return;
    }else if(String(this.vcodeText).length<4){
      let n=4-String(this.vcodeText).length;
      let textNum="0000";
      this.vcodeText=textNum.substring(0,n)+this.vcodeText;
    }
    //判断用户是否选中保存账户
    if(this.isRememberAccount){
      this.dataApi.setLocalLoginName(this.loginName);//存储账号到本地(接口完善后再放到请求成功的回调中去)
    }else{
      this.local.remove(Constants.loginName);
    }
    let loginSource;
    if(this.local.get(Constants.loginRs)){
       loginSource=this.local.get(Constants.loginRs);
    }else{
       loginSource='b_pro';
    }
    if(this.isNewUser&&!this.isNewUserAgree) {
      this.gotoUserXieyi('login');
      return;
    }
    let model = {
        "mobile": this.loginName,
        "vcode": {
          "mobile": this.loginName,
          "vcode_text": this.vcodeText
        },
        "register_source": this.loginSource,//注册来源
        "createip": "",
        "project_source": '',//项目来源
        "login_source": this.loginSource,//登陆来源
        "wopenkey":this.wopenkey
    };
    this.opera.loading();
    //通过账号和验证码获取token
    this.dataApi.postMobileSmsCode(model).then((res:any)=>{
      //console.log(res);
      //loader.dismiss();
      if(res){
        //通过code换取tooken
        this.dataApi.postCodeTradeToken({
          "grant_type": "authorization_code",
          "code": res.value
        }).then( async (res)=>{
            this.opera.loadiss();
            if(res){
              clearInterval(this.setQr);
              clearInterval(this.getQrLogin);
              //console.log("token数据------------login");
              //console.log(res);
              let refreshToken ={
                refresh_token:res.refresh_token,//给全局变量赋值----刷新token
                exprid_time:new Date().getTime()+res.expires_in*1000//token过期时间(毫秒)
              }
              this.local.set('loginName', this.loginName);

              this.dataApi.setRefreshToken(refreshToken);
              this.dataApi.setLocalToken(res.access_token);
              this.local.set(Constants.accesstoken,res.access_token);
              this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
                  //console.log(res);
                  if(res){
                    //--------------------------------------企业信息存在（跳转首页）
                    this.dataApi.setLocalAccountId(res.accountid);//将accounid存到本地、全局变量
                    this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
                    this.mimc.init(res.jm_username);//登陆mimc
                    this.getUser();
                    this.local.remove(Constants.loginRs);//清除本地注册来源存储
                    if(this.local.get(Constants.typeUrl) && this.local.get(Constants.typeUrl) != 'home/homepage'){
                      console.log("登录到指定url");
                      console.log(this.local.get(Constants.typeUrl));
                      this.router.navigateByUrl(this.local.get(Constants.typeUrl));
                      this.local.remove(Constants.typeUrl);
                    }else{
                      if(res.is_frozen==true){
                        this.config.is_frozen=true
                        this.router.navigateByUrl('home/company-details');
                      }else{
                        this.dataApi.getPlatformApply().then((res:any)=>{
                          if(res){
                            if(res.status == 3 || res.status == 0){
                              //2021 7/14 去除注册工作啦认证
                              // this.router.navigateByUrl('guide-audit');
                              this.router.navigateByUrl('home/workSpase');

                            }else{
                              this.config.is_frozen=false
                              this.router.navigateByUrl('home/workSpase');
                            }
                          }
                        }).catch((error) => {
                          this.opera.loadiss();
                          console.log(error);
                        });
                      }
                    }
                  }else{//-------------------------------------企业信息不存在（进入引导）
                    //console.log('去引导');
                    this.opera.loadiss();
                    setTimeout(() => {
                      this.toastr.info('请完善基本信息');
                    },600);
                    this.router.navigateByUrl('guide');
                  }
              }).catch((error)=>{
                //console.log(error);
                this.opera.loadiss();
                setTimeout(() => {
                  // this.toastr.error(error);
                },600);
              });
            }else{
              this.opera.loadiss();
              setTimeout(() => {
                this.toastr.error('验证码错误，或已过期');
              },600);
            }
        }).catch((error)=>{
          //console.log(error);
          this.opera.loadiss();
          setTimeout(() => {
            this.toastr.error('验证码错误，或已过期');
          },600);
        });
      }else{
        this.opera.loadiss();
        setTimeout(() => {
          this.toastr.error('验证码错误，或已过期');
        },600);
      }
    }).catch((error) => {
      console.log('postMobileSmsCode',error);
      this.opera.loadiss();
      setTimeout(() => {
        this.toastr.error(error);
      },600);
    });

  }

  /**
   * 获取用户信息
   */
  getUser(){
    this.dataApi.getUser().then((res:any)=>{
      if(res){
        console.log('getUser',res);
        this.config.loginUser = res;
        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
        if(res.wechat_info!=null){
          if(res.wechat_info.unionid){
            this.config.userisBindWx = true
          }
        }
      }
    }).catch((error)=>{
    });
  }
  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      that.postMobileSmsCode();
    }
  }
  /**
   * 登录方式切换
   * @param type == phone/email
   */
  changeLoginType(type){
    if(type==this.loginType) {return;}
    this.vcodeImg = '';//清空手机验证码输入框
    this.getCode();// 重新获取验证码
    this.loginType=type;
    if(this.loginType == 'scan'){
      this.initWeiChatLogin()
    }
  }
  /**
   * 跳转找回密码
   */
  gotoFindPsw(){
    this.router.navigateByUrl('app-find-psw');
  }
  /**
   * 邮箱登录网络请求
   */
  emailLogin(){
    if(this.loginEmail==''||this.loginEmailPsw==''||this.vcodeImg=='') {
      this.toastr.error('信息不完整');
      return;
    }
    let loginSource;
    if(this.local.get(Constants.loginRs)){
      loginSource=this.local.get(Constants.loginRs);
    }else{
      loginSource='web';
    }
    let data = {
      login_type:'email',
      login_email:this.loginEmail,
      password:this.loginEmailPsw,
      register_source:this.loginSource,
      login_source: this.loginSource,//登陆来源
    };
    if(this.isRememberAccount1){
      this.dataApi.setLocalLoginEmail(this.loginEmail);//存储账号到本地(接口完善后再放到请求成功的回调中去)
    }else{
      this.local.remove(Constants.loginEmail);
    }
    //验证图片验证码
    if(!this.vcodeImg){
      this.toastr.error("请填写图片验证码");
    }
    let checkPicCode ={
      "vcodeid": this.imgCodeId,
      "vcode_text": this.vcodeImg
    }
    let phoneModel={
      format_type:2,// 0:手机号，1：手机号&固话；2.邮箱
      data:this.loginEmail
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(res.value){
        this.dataApi.emailLoginCheckPicCode(checkPicCode).then((res:any)=>{
          // 图片验证成功
          this.dataApi.accountLogin(data).then((res:any)=>{
            console.log("邮箱登录",res);
            if(res.value) {
              this.codeGetToken(res.value)
            }
            else {
              this.toastr.error("邮箱登录失败")
            }
          }).catch((err)=>{
            console.log("邮箱登录失败",err);
            this.toastr.error(err);
          })
        }).catch((err)=>{
          console.log("图片验证失败",err);
          this.toastr.error(err);
        })
      } else {
        this.toastr.warning('邮箱格式错误');
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error('邮箱格式错误');
      return;
    })
  }
  /**
   * 登录方式，拿code换token
   * @param code
   */
  codeGetToken(code){
    this.dataApi.postCodeTradeToken({
      "grant_type": "authorization_code",
      "code": code
    }).then( async (res)=>{
      if(res){
        //console.log(res);
        let refreshToken ={
          refresh_token:res.refresh_token,//给全局变量赋值----刷新token
          exprid_time:new Date().getTime()+res.expires_in*1000//token过期时间(毫秒)
        };
        this.dataApi.setRefreshToken(refreshToken);
        this.dataApi.setLocalToken(res.access_token);
        this.local.set(Constants.accesstoken,res.access_token);
        this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
          if(res){
            //--------------------------------------企业信息存在（跳转首页）
            this.dataApi.setLocalAccountId(res.accountid);//将accounid存到本地、全局变量
            this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
            this.mimc.init(res.jm_username);//登陆mimc
            this.local.remove(Constants.loginRs);//清除本地注册来源存储
            this.getUser();
            if(this.local.get(Constants.typeUrl) && this.local.get(Constants.typeUrl) != 'home/homepage'){
              console.log("登录到指定url");
              console.log(this.local.get(Constants.typeUrl));
              this.router.navigateByUrl(this.local.get(Constants.typeUrl));
              this.local.remove(Constants.typeUrl);
            }else{
              if(res.is_frozen==true){
                this.config.is_frozen=true
                this.router.navigateByUrl('home/company-details');
              }else{
                this.config.is_frozen=false
                this.router.navigateByUrl('home/workSpase');
              }
            }

          }else{//-------------------------------------企业信息不存在（进入引导）
            //console.log('去引导');
            setTimeout(() => {
              this.toastr.info('请完善基本信息');
            },600);
            this.router.navigateByUrl('guide');
          }

        }).catch((error)=>{
          //console.log(error);
          setTimeout(() => {
            //this.toastr.error(error+',错误码:L006'+error);
          },600);
        });

      }else{
        setTimeout(() => {
          this.toastr.error('验证码错误，或已过期,错误码:L002');
        },600);
      }
    }).catch((error)=>{
      //console.log(error);
      setTimeout(() => {
        this.toastr.error('验证码错误，或已过期,错误码:L003,'+error);
      },600);
    });
  }
  isPsw(psw){
    var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,25}$/;
    return reg.test(psw)
  }
  changeSeePsw(index,type){
    if(index==2) {
      this.seePswType2 = type;
    }
    if(index==3) {
      this.seePswType3 = type;
    }
  }
  savePsw(){
    if(this.updatePswData.newPsw==''||this.updatePswData.okPsw==''){
      this.toastr.error("密码为空！");
      return;
    }
    if(this.updatePswData.newPsw.length<6) {
      this.toastr.error("密码不能小于6位！");
      return;
    }
    if(!this.isPsw(this.updatePswData.newPsw)) {
      this.toastr.error('密码格式错误');
      return;
    }
    if(this.updatePswData.newPsw!=this.updatePswData.okPsw) {
      this.toastr.error("两次密码不一致");
      return;
    }

    let data = {
      oldpassword:'Asd123456',
      newpassword:this.updatePswData.newPsw,
    };

    this.dataApi.virtyPassword(this.updatePswData.newPsw).then((res:any)=>{
        this.dataApi.changePsw(data).then((res:any)=>{
        console.log("修改成功",res);
        this.toastr.success('修改成功！');
        this.router.navigateByUrl('home/workSpase');
        this.updateLoginEmailPsw = false;
      }).catch((err)=>{
        console.log("修改邮箱失败",err);
        this.toastr.error(err);return;
      })
    }).catch((err)=>{
      console.log("修改邮箱失败",err);
      this.toastr.error('密码安全度较低，请重新填写')
    })
  }

  /**
   * 初始化微信扫码登录
   *
   .impowerBox .qrcode {width: 180px;}
   .impowerBox .title {display: none;}
   .impowerBox .info {width: 180px;}
   .status_icon {display: none}
   .impowerBox .status {text-align: center;}
   .impowerBox .status_txt p { color: black !important;}
   .impowerBox .icon38_msg {display: none}
   */
  initWeiChatLogin(){
    this.showWXCode = true;
    let appid = 'wx5050e06cd7e3ac48';
    let api = '';
    let wxUrl = '';
    //http://wx.goworkla.cn/Open/AuthorizeLogin?t=t&online=false&redirect_uri=
    if(this.config.api=='https://webapi.goworkla.cn'  || this.config.api=='https://webapinet6.goworkla.cn') {
      api = 'https://employer.goworkla.cn/#/login';
      wxUrl = 'http://wx.goworkla.cn/Open/AuthorizeLogin?t=d&redirect_uri='
    } else if(this.config.api=='https://webapi.test.goworkla.cn' || this.config.api=='https://webapi2.test.goworkla.cn') {
      api = 'http://employer.test.goworkla.cn/#/login';
      wxUrl = 'http://wx.goworkla.cn/Open/AuthorizeLogin?t=d&online=false&redirect_uri='
    } else {
      // 本地暂无配置微信扫码登录
    }

    api = this.urlencode(api);
    let redirect_uri = wxUrl+api;
    redirect_uri =  this.urlencode(redirect_uri);
    console.log('urlencode',redirect_uri)
    setTimeout(()=>{
      var obj = new WxLogin({
        self_redirect:false,
        id:"login_container",
        appid: appid,
        scope: "snsapi_login",
        redirect_uri: redirect_uri,
        state: "",
        style: "white",
        href:"data:text/css;base64,Ci5pbXBvd2VyQm94IC5xcmNvZGUge3dpZHRoOiAxODBweDt9Ci5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9Ci5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMTgwcHg7fQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9Ci5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9Ci5pbXBvd2VyQm94IC5zdGF0dXNfdHh0IHAgeyBjb2xvcjogYmxhY2sgIWltcG9ydGFudDt9Ci5pbXBvd2VyQm94IC5pY29uMzhfbXNnIHtkaXNwbGF5OiBub25lfQ=="
      });
    },300)
  }
  urlencode (str) {
    str = (str + '').toString();
    return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').
    replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
  }

  /**
   * 微信扫码后回掉 获取unionid 登录
   * @param unionid
   */
  weichatLogin(unionid) {
    this.codeGetToken(unionid)

    // let model = {
    //   unionid:unionid,
    //   login_type: 'wechat_open',//登陆来源
    //   account_type:'department',
    //   login_source:this.loginSource,
    // };
    // this.dataApi.accountLogin(model).then((res:any)=>{
    //   //通过tooken获取企业信息
    //   if(res) {
    //     this.codeGetToken(res.value)
    //   }
    // }).catch((error)=>{
    //   this.toastr.error(error)
    //   this.loginType = 'phone'
    //   if(error=='未找到开放平台绑定的用户') {
    //     setTimeout(() => {},600);
    //   }
    // });
  }
  wxBack(){
    this.showBindWXFrom = false;
    this.loginType = 'phone'
  }
  bingWXClick(){
    //unionid 丢失
    if(this.unionid.length==0) {
      this.toastr.warning('请重新扫码登录绑定')
      this.showBindWXFrom = false;
      return;
    }
    if(this.loginName.length==0||this.vcodeImg.length==0||this.vcodeText.length==0) {
      this.toastr.warning('信息填写完整')
      return;
    }
    let data = {
      unionid:this.unionid,
      mobile_verify:{
        mobile:this.loginName,
        vcode_text:this.vcodeText
      }
    };
    this.dataApi.userBindWeChat(data).then((res:any)=>{//通过tooken获取企业信息
      if(res) {
        this.codeGetToken(res.value)
      }
    }).catch((error)=>{
      this.toastr.error(error)
      this.showBindWXFrom = false
    });
  }
    //获取登录的看板
  getKanbanData(){
    this.dataApi.getKanbanDataWithLogin().then((res:any)=>{
      if(res){
        let newData  = new Date(res.date)
        let month = newData.getMonth()+1;
        let day = newData.getDate();
        this.kanbanData.updateTime = newData.getFullYear()+"年" +month+"月"+day+"日";
        this.kanbanData.value1 = this.toThousands(res.college);
        this.kanbanData.value2 = this.toThousands(res.talent);
        this.kanbanData.value3 = this.toThousands(res.department);
        this.kanbanData.value4 = this.toThousands(res.position);
        this.kanbanData.value5 = this.toThousands(res.intent_count);
        this.kanbanData.value6 = res.ratio;

        let value2 =  document.getElementById('value2');
        if(value2){
          value2.innerText = this.kanbanData.value2
        }
        let value3 =  document.getElementById('value3');
        if(value3){
          value3.innerText = this.kanbanData.value3
        }
        let value4 =  document.getElementById('value4');
        if(value4){
          value4.innerText = this.kanbanData.value4
        }
        let value5 =  document.getElementById('value5');
        if(value5){
          value5.innerText = this.kanbanData.value5
        }
      }
    }).catch((err)=>{
    })
  }

  /**
   * 数字格式化
   * @param num
   */
  toThousands(num) {
    var result = [ ], counter = 0;
    num = (num || 0).toString().split('');
    for (var i = num.length - 1; i >= 0; i--) {
      counter++;
      result.unshift(num[i]);
      if (!(counter % 3) && i != 0) { result.unshift(','); }
    }
    return result.join('');
  }

  bro() {
    // 谷歌内核
    console.log('浏览器信息',navigator)
    if(navigator.userAgent) {
      if(navigator.userAgent.indexOf("Chrome")>=0) {
        return;
      }
      this.config.chrome_core = true;
    }
    //各种浏览器的userAgent收集
    //https://blog.csdn.net/m0_37499059/article/details/79003731
  }
  accountLogout(){
    this.config.isNavActive = 'logout';
    this.router.navigateByUrl('/account-logout');
  }

  /**
   * 初始化登录的轮播图
   */
  initSwiper(){
    let that = this;
    setTimeout(()=>{
      layui.use('carousel', function(){
        var carousel = layui.carousel;
        //建造实例
        if(!that.carousel_ins){
          that.carousel_ins = carousel.render({
            elem: '#test1'
            ,width: '645px' //设置容器宽度
            ,height:'414px'
            ,arrow: 'none' //始终显示箭头
            ,interval:3000
            ,autoplay:true,
            indicator:'inside',
            //,anim: 'updown' //切换动画方式
          });
          //找到两个按钮，设置样式，因为全局样式又一个Buttom里有一个样式会导致按钮错乱
          let leftbtn =  document.getElementById("lay-type-left");
          let rightbtn =  document.getElementById("lay-type-add");
          if(leftbtn) {
            leftbtn.style.padding ='0';
          }
          if(rightbtn) {
            rightbtn.style.padding ='0';
          }
        }
      });
    },120)
  }
  ngAfterViewInit(){
  }
  goto_gwl_activity_school(){
    window.open(this.config.gwl_activity_School_url,'__blank')
  }
  goto_gwl(url){
    if(url){
      window.open(url,'__blank')
    }
  }
  goto_gwl_air(){
    window.open('http://air.goworkla.cn/index.html','__blank')
  }
  goto_gwl_air1(){
    window.open('http://air.goworkla.cn/job/','__blank')
  }
  goto_gwl_serve(){
    window.open('https://www.wenjuan.com/s/NZjaM3q/','__blank')
  }

  checkChrome(){
    var ua = navigator.userAgent.toLocaleLowerCase();
    var browserType = null;
    if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
      browserType = "IE";
    } else if (ua.match(/firefox/) != null) {
      browserType = "Firefox";
    } else if (ua.match(/chrome/) != null) {
      browserType = "Chrome";
    } else if (ua.match(/ubrowser/) != null) {
      browserType = "UC";
    } else if (ua.match(/opera/) != null) {
      browserType = "欧朋";
    } else if (ua.match(/bidubrowser/) != null) {
      browserType = "Baidu";
    } else if (ua.match(/metasr/) != null) {
      browserType = "搜狗";
    } else if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
      browserType = "QQ";
    } else if (ua.match(/maxthon/) != null) {
      browserType = "遨游";
    } else if (ua.match(/chrome/) != null) {
      var is360 = this._mime("type", "application/vnd.chromium.remoting-viewer");
      if (is360) {
        browserType = '360';
      }
    } else if (ua.match(/safari/) != null) {
      browserType = "Safari";
    }
    console.log("browserType",browserType,ua) ;
  }
  _mime(option, value) {
    var mimeTypes = navigator.mimeTypes;
    for (var mt in mimeTypes) {
      if (mimeTypes[mt][option] == value) {
        return true;
      }
    }
    return false;
  }
  //关闭屏幕分辨率小的弹窗
  closescreenModel(){
    this.config.isScreenSmallBox = false;
  }

  /**
   * 用户协议
   * @param type
   */
  gotoUserXieyi(type){
    this.dialog.open(UserProtocolModelComponent, {
      data:{
        type:type,
        callback:(index)=>{
        this.dialog.closeAll();
        console.log('用户关闭了弹窗',index)
          if(index==2) {
            this.isNewUserAgree = true;
            if(type!="none") {
              this.postMobileSmsCode();
            }
          }
      }}
    }).afterClosed().subscribe((isConfirmed) => {
    });
  }
  gotoPage(url){
    console.log('gotoPage',url)
    let token = '?token=';
    let _mrid = 'mrid=';
    let access_token = url.substr(url.indexOf(token)+7,32);
    let mrid = url.substr(url.indexOf(_mrid)+5,24);
    this.opera.loading()
    console.log("mridmridmridmrid",url.indexOf(_mrid),url)
    console.log('gotoPage',url,access_token)
    this.dataApi.setLocalToken(access_token);
    this.local.set(Constants.accesstoken,access_token);
    setTimeout(()=>{
      console.log('开始获取企业信息',this.local.get(Constants.accesstoken))
      this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
      console.log('gotoPagegetDepartment',res,mrid)
      this.opera.loadiss()
      if(res){
        this.dataApi.setLocalAccountId(res.accountid);//将accounid存到本地、全局变量
        this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
        this.mimc.init(res.jm_username);//登陆mimc
        this.local.remove(Constants.loginRs);//清除本地注册来源存储
        this.getUser();
        if(url.includes('airCampusDetail')) {
          this.router.navigateByUrl('home/airCampusDetail');
        }
        if(url.includes('guide')) {
          this.router.navigateByUrl('guide');
        }
        if(url.includes('college-milkdetails')) {
          this.router.navigate(['home/college-milkdetails'],{
            queryParams:{
              id:mrid,
            }
          })
        }
        if(url.includes('college-campustalk1')) {
          this.router.navigateByUrl('home/college-campustalk1');
        }
      }else {
        setTimeout(() => {
          // this.toastr.info('请完善基本信息');
        },600);
        this.router.navigateByUrl('guide');
      }
    }).catch((error)=>{
      //console.log(error);
      this.opera.loadiss()
      setTimeout(() => {
        //this.toastr.error(error+',错误码:L006'+error);
      },600);
    });
    },400)
  }

   //获取card广告banner
   getCardbanner() {
    this.dataApi.getCardBannerdata('pc_employer_login').then((res:any) => {
      if (res) {
        this.bannerListImg = res;
        this.initSwiper();
      }
    }).catch((err) => {
      console.log(err);
    })
  }

}
