import { Component, OnInit, Input, Output,ViewChild } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import {  ActivatedRoute,Router } from  '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmsComponent } from '../confirms/confirms.component';


@Component({
  selector: 'app-company-receipt',
  templateUrl: './company-receipt.component.html',
  styleUrls: ['./company-receipt.component.css']
})
export class CompanyReceiptComponent implements OnInit {
  public config: Config;
  public countList = 0;//列表数量
  public listCount=0;//列表数据总数量
  public dataList=[];//可开票列表数据
  public dataList1=[];//已开票列表数据
  public type = 1; // 发票类型

  // 筛选条件
  public model={
    offset:0,
    limit:20
  }
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService
  ) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.opera.initConfig('member','company-status-one');
    this.getInvoiceList();
  }

  // 获取发票列表
  getInvoiceList(){
    this.opera.loading();
    this.dataApi.getInvoiceList(this.model).then((res:any)=>{
      console.log(res);
      if(res){
        this.countList = res.count - res.list2.length;
        this.dataList = res.list1;//可开票列表
        this.dataList1 = res.list2;//已开票列表
        this.listCount = res.count;
        console.log(this.dataList);
      }
      this.pageCount =Math.ceil(res.count/20);
      this.opera.loadiss();
    }).catch((err)=>{
      console.log(err);
      this.opera.goChangeRouter('college-resume');
      this.toastr.error(err);
      this.opera.loadiss();
    })
  }

  // 切换有无开票
  receiptTab(type){
    this.type = type;
    this.pageNumber = 1;
  }

  // 去开发票
  goApply(data){
    if(data.invoice_status=='uninvoiced'){
      this.local.set('receiptInfo',JSON.stringify(data));
      this.router.navigateByUrl("/home/company-receipt-send");
    }else{
      this.toastr.warning("开票中，请稍候");
      return;
    }
  }

  // 开票详情
  goSuc(data){
    if(data.invoice_status=='invoiced'){
      this.local.set('receiptInfo',JSON.stringify(data));
      this.router.navigateByUrl("/home/company-receipt-suc");
    }else{
      this.toastr.warning("未开票或以作废");
      return;
    }
  }


  // 获取分页子组件返回数据
  getChildEvent(index){
    // console.log('子组件返回分页数-----college-campustalk');
    // console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*20;
      this.getInvoiceList();//获取列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*20;
        this.getInvoiceList();//获取列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*20;
        this.getInvoiceList();//获取列表
      }
    }
  }



  // 发票须知
  receiptNotice(){
    this.opera.scrollTop();//页面滚动函数
    this.opera.dialog.open(ConfirmsComponent, {
      data: {
        title: '发票须知',
        message: `<div class="flex_col_left" style="text-align: left">
        <p>1.根据国家税务局规定，增值税电子普通发票的法律效力，基本用途，基本使用规定与纸质增值税普通发票相同。如需纸质报销，可将电子文档进行打印。</p>
        <p>2.电子发票将发送至您填写的邮箱，请注意查收。</p>
        <p>3.您申请的发票类型为增值税电子普通发票。</p>
        <p>4.当前可开发票的记录为2018年1月1日后，可开具对应支付金额。</p>
        <p>5.如对发票信息有疑问，请拨打电话400-661-1396（9:00-18:00）。</p>
        </div>
        `,
        trueText:'知道了',
        letB:true,
        falseText:''
      }
    }).afterClosed().subscribe(() => {
        this.opera.scrollTrue();
    });
  }


}
