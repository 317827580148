import { Component,EventEmitter, Input, Output,ViewChild,ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataApi,Constants,Config,Local} from '../../provider/index';
declare var BMap;
@Component({
  selector: 'app-model-address-add',
  templateUrl: './model-address-add.component.html',
  styleUrls: ['./model-address-add.component.css']
})
export class ModelAddressAddComponent {
  public config: Config;
  @Input() isShow: false;//是否显示弹窗页面
  public title: string;//弹出框标题
  public department:any;//获取企业信息
  public listOne=[];//地区字典一级列表
  public listTwo=[];//地区字典二级列表
  public listThree=[];//地区字典三级列表
  public cityOne="";//省
  public cityTwo="";//市
  public locations = {
    address: "",
    coordinates:[],
    lid: "",
    ltype: 0,
    region: "",
    region_text: "",
    is_default:false
  }
  public lid = '';
  constructor(public toastr: ToastrService,
    public local:Local,
    public dataApi: DataApi) {
      this.config = Config.getInstance();

    }

  //初始化
  initData(data,title){
    //console.log("检查参数----------model-address-add");
    //console.log(data);
    //console.log(title);
    this.listOne=[];//地区字典一级列表
    this.listTwo=[];//地区字典二级列表
    this.listThree=[];//地区字典三级列表
    this.cityOne="";//省
    this.cityTwo="";//市
    this.locations = {
      address: "",
      coordinates:[],
      lid: "",
      ltype: 0,
      region: "",
      region_text: "",
      is_default:false
    }
    this.title=title;
    this.lid=data;
    this.getRegionList();//获取地区字典
  }

  //编辑时赋值
  editorData(){
    //console.log("编辑时检查函数执行");
    //console.log(this.config.department.locations);
    for(let val of this.config.department.locations){
      //console.log("编辑时检查函数执行-----1");
      //console.log(val);
      if(val.lid==this.lid){
        this.locations=val;
        this.cityOne=this.locations.region.slice(0,2);
        this.cityTwo=this.locations.region.slice(0,4);
        //console.log("编辑时获取到对应的企业地址");
        //console.log(this.cityOne);
        //console.log(this.cityTwo);
        //获取地区二级字典
        for(let n of this.config.region){
          if(n.parentcode==this.cityOne){
            this.listTwo.push(n);
          }
        }
        //获取地区三级字典
        for(let n of this.config.region){
          if(n.parentcode==this.cityTwo){
            this.listThree.push(n);
          }
        }
      }
    }
  }

  //获取地区字典
  getRegionList(){
    if(this.dataApi.getRegionCode()){
      this.config.region = this.dataApi.getRegionCode();
      if(this.lid){
        this.editorData();//编辑时赋值
      }
      this.getRegionListOne();
    }else{
      //获取地区字典
      this.dataApi.getDictDate('region').then((res:any)=>{
        this.config.region = res;
        if(this.lid){
          this.editorData();//编辑时赋值
        }
        this.dataApi.setRegionCode(res);
        this.getRegionListOne();
      }).catch((error)=>{
        //console.log(error);
      });
    }
  }

  //获取地区字典一级列表
  getRegionListOne(){
    for(let val of this.config.region){
      if(val.level==1){
        this.listOne.push(val);
      }
    }
  }
  //提示先选择一级字典
  isShowTwo(){
    if(!this.cityOne){
      this.toastr.warning("请先选择省");
      return;
    }
   }
  //提示先选择二级字典
  isShowThree(){
    if(!this.cityTwo){
      this.toastr.warning("请先选择市");
      return;
    }
   }

 //监听省
 cityOneChange(){
    // //console.log("第一级专业值");
    this.cityTwo='';
    this.locations.region="";
    this.listTwo=[];
    this.listThree=[];
    for(let n of this.config.region){
      if(n.parentcode==this.cityOne){
        this.listTwo.push(n);
      }
    }
}
//监听市
cityTwoChange(){
  // //console.log("第一级专业值");
  // this.cityTwo='';
  this.locations.region="";
  // this.listTwo=[];
  this.listThree=[];
  for(let n of this.config.region){
    if(n.parentcode==this.cityTwo){
      this.listThree.push(n);
    }
    if(n.code==this.cityTwo){
     // this.searchRegion(n.text);
    }
  }
}

//监听县/区
cityThreeChange(){
  for(let n of this.config.region){
    if(n.code==this.locations.region){
      this.locations.region_text=n.text;
    }
  }
}


  //保存修改地址
  employerUpdatePositionInfo(){
    if(!this.locations.region){
      this.toastr.warning('所在城市为必填项');
      return
    }
    if(!this.locations.address){
      this.toastr.warning('详细地址为必填项');
      return
    }
    // if(!this.locations.coordinates){
    //     // this.toastr.error('地址坐标查询失败，请稍后重试');
    //     this.searchRegion(this.locations.region_text);//第一次查询失败，重新查询一次
    //     return
    // }
    // let loader = this.tip.presentLoading('');
    if(this.lid){//----编辑地址
       this.dataApi.employerUpdatePositionInfo(this.locations.lid,this.locations).then((res:any)=>{
        //  //console.log(res);
        //  this.viewCtrl.dismiss(this.locations);
        //  loader.dismiss();
        this.dismiss(true);
      }).catch((error) => {
         this.toastr.error(error)

         // loader.dismiss();
        this.dismiss(false);
      });
    }else{//--------创建地址
      this.dataApi.employerCreatePositionInfo(this.locations).then((res:any)=>{
        this.locations.lid = res.value;
        this.dismiss(true);
        // this.events.publish('user:department');//发送重新获取企业信息的广播
        // this.viewCtrl.dismiss(this.locations);
        // loader.dismiss();
     }).catch((error) => {
       this.dismiss(false);
       this.toastr.error(error)
      //  loader.dismiss();
     });
  }

  }

  // 根据关键字搜索坐标(只对市级别进行搜索)
  searchRegion(result) {
    // let loader = this.tip.presentLoading('坐标查询中...');
    let that = this;
     ////console.log('触发');
     let map = new BMap.Map('bMap');
     let local = new BMap.LocalSearch(map, { //智能搜索
       onSearchComplete: function() {
          // loader.dismiss();
         ////console.log(local.getResults().getPoi(0));
         if (local.getResults().getPoi(0)) {
           let coordinate = local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
           that.locations.coordinates = [coordinate.lng, coordinate.lat];
           ////console.log(coordinate);
          //  that.toastr.success("坐标查询成功");
           //console.log("坐标查询成功");
         }else{
           // that.toastr.error("坐标查询失败,请稍后重试");

         }
       }
     });
     local.search(result);
   }


  //获取企业信息
  getDepartment(){
    if(this.dataApi.getLocalDepartment()){
      this.department = this.dataApi.getLocalDepartment();
      //console.log("企业信息本地存储");
      //console.log(this.dataApi.getLocalDepartment());
    }else{
      this.dataApi.getDepartment().then((res:any)=>{
        //console.log("企业信息获取线上数据");
        //console.log(res);
        this.department = res;
        this.dataApi.setLocalDepartment(res);
      }).catch((error) => {
        //console.log(error);
        this.toastr.error(error);
      });
    }

}
  @Output() onVoted = new EventEmitter();
  dismiss(result: any) {
    //console.log("传值检查");
    //console.log(result);
    this.onVoted.emit(result);
  }

}
