import { Component, OnInit,Input,Output,ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from  '@angular/router';
import { DataApi,Config,Local,Constants,Opera} from '../../provider/index';
import { ModelPayWechatComponent } from '../model-pay-wechat/model-pay-wechat.component';
declare let window;
declare let pingpp;
@Component({
  selector: 'app-company-pay',
  templateUrl: './company-pay.component.html',
  styleUrls: ['./company-pay.component.css']
})
export class CompanyPayComponent implements OnInit {
  @ViewChild('payWechat', { static: true }) child: ModelPayWechatComponent;
  public payPath='';//支付渠道
  // public pingpp=require('pingpp-js');
  // public config: Config;
  public model={
    channel:'alipay_pc_direct', //支付渠道alipay_pc_direct:支付宝pc wx_pub_qr:微信扫码
    product_code:'' // 产品代码
  }
  public value={
    channel:'alipay_pc_direct', //支付渠道
    product_code:'002'
  };

  productMain = {
    name:'',
    price:'',
    recharge_category:''
  };

  public config: Config;
  public price; // 金额
  public discount; // 优惠金额
  public pageTitle="充值";
  public payList:any;//支付渠道列表
  public product_name='';//产品名称
  public paySearch=false;
  @Output() modelShow = false;//是否打开弹窗
  constructor(
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService
  ) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.opera.initConfig('member','member-center');
    this.getPayChannel();//获取支付渠道
    if(this.config.productCode){
      this.model.product_code = this.config.productCode+'';
      //此处解决BUG，订单ID保存到本地的时候会变成""1111"",多了双引号，这里替换双信号解决获取产品找不到
      this.model.product_code = this.model.product_code.replace(/\"/g, "");
      this.getProductInfo();
    }else{
      this.model.product_code = this.local.get(Constants.productInfo)+'';
      //此处解决BUG，订单ID保存到本地的时候会变成""1111"",多了双引号，这里替换双信号解决获取产品找不到
      this.model.product_code = this.model.product_code.replace(/\"/g, "");
      this.getProductInfo();
    }
    console.log(this.local.get(Constants.productInfo));
    this.getProductInfo();

  }

  // 获取产品详情
  getProductInfo(){
    this.dataApi.getProductInfo(this.model.product_code).then((res:any)=>{
      console.log('getProductInfo',res,this.model.product_code);

      if(res){
        this.productMain = res;
        if(res.discount || res.membership_discount){
          if(this.config.department.member_info){
            this.price = parseFloat(res.price) * parseFloat(res.discount) * parseFloat(res.membership_discount);
            this.discount = parseFloat(res.price) - this.price;
          }else{
            this.price = parseFloat(res.price) * parseFloat(res.discount);
            this.discount = parseFloat(res.price) - this.price;
          }
        }
        else
        {
          this.price = parseFloat(res.real_price);
        }
      }
      else
      {
        this.toastr.error('该产品不存在')
      }
    }).catch((err)=>{
      console.log(err);
    })
  }

  //接收子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
    if(event){
      this.router.navigateByUrl("home/company-status-two"); // 会员
    }else{
      this.toastr.error("支付失败，请重试");
    }
  }

  //支付
  pay(){
    this.value.channel=this.model.channel;
    // if(!this.paySearch){
    if(!this.model.channel){
      this.toastr.warning("请选择支付渠道");
      return;
    }
    this.opera.loading();
    console.log('postPayCharge',this.model);

    this.dataApi.postPayCharge(this.model).then((res:any)=>{
      // console.log("支付charge 企业认证");
      console.log('postPayCharge',res);
      this.opera.loadiss();
      if(this.model.channel=='wx_pub_qr'){
        this.opera.scrollTop();
        this.modelShow = true;
        this.child.initData(res);
        return;
      }
      let that = this;
      pingpp.createPayment(res, function(result,error){
        console.log('调用支付-----pc');
        console.log(result);
        console.log(error);
        if(result=='success'){
          console.log('成功');
          that.router.navigateByUrl("home/company-status-two"); // 会员
        }else{
          that.toastr.error('支付失败，请重试');
        }
      });
    }).catch((err)=>{
      console.log(err);
      this.opera.loadiss();
    })
  }

  //获取支付渠道
  getPayChannel(){
    this.dataApi.getPayChannel().then((res:any)=>{
      if(res){
        console.log('获取到支付渠道');
        console.log(res);
        this.payList=res;
        this.model.channel=res[0].alias;
      }
    }).catch((err)=>{
      console.log('获取支付渠道出错');
      console.log(err);
      this.toastr.error("获取支付渠道出错，请稍后重试");
    })
  }

  //获取订单详情
  // getOrderDetails(){
  //   this.opera.loading();
  //   this.dataApi.getOrderDetails(this.model.orderid).then((res:any)=>{
  //     this.opera.loadiss();
  //     console.log('获取到订单详情');
  //       console.log(res);
  //     if(res){
  //       this.model.amount=res.amount_payable;
  //       this.product_name=res.product_name;
  //     }
  //   }).catch((err)=>{
  //     this.opera.loadiss();
  //     console.log('获取订单详情出错');
  //     console.log(err);
  //     this.toastr.error("获取订单详情出错，请稍后重试");
  //     // this.navCtrl.popToRoot();
  //   })
  // }
  // 高级搜索开通
  // goSearchIdentity(){
  //   if(!this.model.channel){
  //     this.toastr.warning("请选择支付渠道");
  //     return;
  //   }
  //   this.opera.loading();
  //   this.dataApi.postPaySearch(this.value).then((res:any)=>{
  //     console.log("支付charge高级搜索");
  //     console.log(res);
  //     this.opera.loadiss();
  //     if(this.model.channel=='wx_pub_qr'){
  //       this.opera.scrollTop();
  //       this.modelShow = true;
  //       this.child.initData(res);
  //       return;
  //     }
  //     let that = this;
  //     pingpp.createPayment(res, function(result,error){
  //       console.log('调用支付-----pc');
  //       console.log(result);
  //       console.log(error);
  //       if(result=='success'){
  //         // that.router.navigateByUrl("home/company-status-one");//审核中
  //       }else{
  //         that.toastr.error('支付失败，请重试');
  //       }
  //     });
  //   }).catch((err)=>{
  //     console.log(err);
  //     this.opera.loadiss();
  //   })
  // }
}
