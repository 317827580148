import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { DataApi, Local, Constants, Opera, Config } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { scrollTool } from "../../provider/scrollTool";
import { NoneVipAlertComponent } from "../none-vip-alert/none-vip-alert.component";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
//新增高校
import { PushCollegeModelComponent } from "../push-college-model/push-college-model.component";

declare var layui;
@Component({
  selector: 'app-college-campustalk',
  templateUrl: './college-campustalk.component.html',
  styleUrls: ['./college-campustalk.component.css']
})
export class CollegeCampustalkComponent implements OnInit {

  //是否打开弹窗(非会员新增高校)
  @Output() isShowPushCollegeModel = false;
  @ViewChild('pushCollegeModel', { static: true })
  pushCollegeModel: PushCollegeModelComponent;

  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true })
  productModel: ProductModelComponent;

  public config: Config;
  public hotCity = [];//热门城市
  public collegeList = [];//高校列表
  public productList = [];
  // 筛选条件--搜索
  public model = {
    city: '',//城市
    school_type: '',//分类
    school_nature: '',//性质
    labels: '',//特色
    status: '',//校招状态
    offset: 0,
    limit: 20
  }
  public laypage;
  public myDepartment: any;//公司信息
  public modelIndentity = {
    collegeid: '',
    business_licence: '',//企业营业执照
    id_card_front: '',//身份证正面
    register_number: ''//营业执照注册号
  }
  public collegeid = "";//存储选中学校id
  public countList = 0;//列表数量
  public activity_invitation = null;
  @Output() modelShow = false;//是否打开弹窗
  @Output() collegeName = '';//高校名称
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public isShowFilter = false;
  @Output() orgList = [];//院系列表
  meeting: boolean = false  // 宣讲会与否
  collegeIdentity: any;

  public campusListHistory = [];

  public isShowJoinCerModel = false; // 是否展示参会凭证弹窗
  public JoinCerModel = null; //展示参会凭证弹窗
  public addCollegeList = [];

  public revokeModule = false;
  public revokeApplyid = '';

  constructor(public dataApi: DataApi,
    public scrolltool: scrollTool,
    public local: Local,
    private router: Router,
    public opera: Opera,
    public dialog: MatDialog,
    public location: Location,
    public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
    this.scrolltool.initScrooll_cell_With_scrollY(this.scrolltool.getVal('college_campustalk_cell_scrollY'))

  }

  ngOnInit() {
    console.log(location.hash);
    this.mycollegeList();

    let that = this
    layui.use('laypage', function () {
      that.laypage = layui.laypage;
    });
    let hash = location.hash;
    if (hash.indexOf('college-campustalk1') != -1) {
      this.opera.initConfig('nav-activity', 'college-campustalk1');
      this.meeting = true;
      this.getMyCampustalk1History();
    } else {
      this.opera.initConfig('college', 'college-campustalk');
      this.meeting = false;
    }
    this.getHotCity();//获取热门城市
    this.getSchoolType();//获取高校性质
    this.getSchoolNature();//获取高校分类
    this.getSchoolLabel();//获取高校特色
    // this.getCollegeList();//获取高校列表
    this.getDepartment();//获取企业信息
    this.getProductList();

    console.log('this.scrolltool.college_campustalk_Page', this.scrolltool.college_campustalk_Page)
    if (this.scrolltool.getVal('college_campustalk_Page')) {
      this.opera.loading();
      this.pageNumber = this.scrolltool.getVal('college_campustalk_Page');
      this.model.offset = (this.pageNumber - 1) * 20;
      this.getCollegeMoreList();//获取列表
    }
    else {

    }
  }

  productModelHidden(event) {
    this.isproductShowModel = false;
  }

  //点击产品
  product(item, type) {
    console.log(item);
    if (item.left > 0) {
      this.local.set('productUseHistory', item.alias);
      this.local.set('productUseHistoryLeft', item.left);
      this.router.navigate(['home/productUseHistory']);
    } else {
      this.productModel.initData(item.alias, type);
      this.isproductShowModel = true;
    }
  }

  //申请宣讲会
  goSigin(n) {

    this.scrolltool.setVal('college_campustalk_Page', this.pageNumber);
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        if (this.config.isMember_trial) {
          if (this.config.isShowPaySystem) {
            that.opera.checkMemberAlertWithType("goCampus")
          }
          return;
        }
        this.opera.checkCompanyIsBack(n.collegeid, () => {
          that.local.set(Constants.collegeid, n.collegeid);
          that.local.remove(Constants.campusApply)
          that.router.navigate(['home/college-campus-sign'], {
            queryParams: {
              college: JSON.stringify(n)
            }
          });
        })
      } else {
        //that.opera.checkMemberAlertWithType("goBuy_signMilk")
         //非会员验证是否是合作3所高校
         let finItem = this.addCollegeList.find(item => item.collegeid == n.collegeid);
         if (finItem) {
           this.opera.checkCompanyIsBack(n.collegeid, () => {
            that.local.set(Constants.collegeid, n.collegeid);
            that.local.remove(Constants.campusApply)
            that.router.navigate(['home/college-campus-sign'], {
              queryParams: {
                college: JSON.stringify(n)
              }
            });
          })
         } else {
           this.pushCollege();
         }
      }
    })

  }

  //订阅高校公众号
  getCollegeAccount(college) {
    this.opera.CollegeRead(college);
  }

  //打开申请校招页面
  gocollegeIdentity(n) {
    if (!n.is_online) {
      this.toastr.warning('该高校正在部署上线中，可选择其他高校进行校招');
      return;
    }
    this.opera.scrollTop();
    this.collegeid = n.collegeid;
    if (n.audit_switch.faculty_authcation) {//是否打开院系认证审核开关
      this.collegeName = '';
      this.getOrgList();
    } else {
      this.collegeName = n.name;
      this.orgList = [];
      this.modelShow = true;
    }
  }

  //获取列表
  getOrgList() {
    this.opera.loading();
    this.dataApi.getCollegePart(this.collegeid).then((res: any) => {
      // console.log("获取院系列表---");
      // console.log(res);
      this.orgList = res;
      this.opera.loadiss();
      this.modelShow = true;
    }).catch((err) => {
      this.opera.loadiss();
      //console.log(err);
      this.toastr.error("获取院系列表失败，请稍后重试");
    })
  }

  //获取申请校招返回值
  Modeldismiss(event) {
    //console.log('子组件返回结果-----college-details');
    //console.log(event);
    this.modelShow = false;
    this.opera.scrollTrue();
    if (event) {
      if (event === true) {
        this.local.remove(Constants.facultyid);
      } else {
        this.local.set(Constants.facultyid, event);
      }
      //跳转认证页面
      this.local.set(Constants.collegeid, this.collegeid);
      this.router.navigateByUrl("/home/company-identity-details");
    }
  }

  //获取企业信息
  getDepartment() {
    this.dataApi.getcomponentLeft({}).then((res: any) => {
      res.map((item) => {
        if (item.alias == 'activity_invitation') {
          this.activity_invitation = item;
        }
      })
    }).catch((err) => {
      console.log(err);
    })

  }

  getProductList() {
    //product_category:"recharge",recharge_category:''
    this.opera.loading();
    this.dataApi.departmentList({ product_category: "recharge" }).then((res: any) => {
      this.opera.loadiss();
      if (res) {
        console.log('获取产品列表', res)
        this.productList = res.list;
      }
    }).catch((err) => {
      console.log(err);
      this.opera.loadiss();
    })
  }

  goBuy(n) {
    this.config.userInitiativePayName = n
    if (!this.config.isShowPaySystem) {
      this.config.payVipHistoryPage = null
    }

    this.opera.checkMember((isPay) => {
      if (isPay) {
        this.productList.map((item, index) => {
          if (item.recharge_category == n) {
            this.local.set("recharge_category_code", JSON.stringify(item.code));
            // this.router.navigate(['home/buyProduct']);
            this.router.navigate(['home/tool-campus']);
            return
          }
        })
      } else {
        this.opera.checkMemberAlertWithType("goBuy_signMilk")
      }
    })
  }

  //跳转高校详情页面
  goDetails(n) {
    if (!n.is_online) {
      this.toastr.warning('该高校正在部署上线中，可选择其他高校进行校招');
      return;
    }
    this.scrolltool.setVal('college_campustalk_cell_scrollY', window.top.scrollY);
    this.scrolltool.setVal('college_campustalk_Page', this.pageNumber);
    
    this.config.isNavActive = 'college';
    this.config.isNavChildActive = 'college-campustalk';
    this.local.set(Constants.collegeid, n.collegeid);

    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        if (this.config.isMember_trial) {
          that.opera.checkMemberAlertWithType("goCloud")
          return;
        }
        this.router.navigateByUrl("home/college-details-campus");
      } else {
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == n.collegeid);
        if (finItem) {
          this.router.navigateByUrl("home/college-details-campus");
        } else {
          this.pushCollege();
        }
        //that.opera.checkMemberAlertWithType("goBuy_signMilk")
      }
    })
  }

  // 双选会预约
  goSignMeeting(n) {

    console.log('goSignMeeting', n);;
    if (n.status == 2) {
      if (!n.campustalk_switch) {
        this.toastr.warning("当前高校未开启宣讲会");
        return;
      } else {
        if (this.config.positionList.length == 0) {
          this.opera.remindPublish("申请宣讲会");
          return;
        }
        this.local.set(Constants.collegeid, n.collegeid);
        this.router.navigate(['home/college-campus-sign']);
      }
    } else if (n.status == 1) {
      this.toastr.warning('与该高校的合作申请正在审核中')
    } else if (n.status == 3) {
      this.toastr.warning('与该高校的合作申请未通过，您可再次申请合作')
    } else {
      this.toastr.warning('通过与该校的合作申请后才可预约该校宣讲会')
    }
  }


  //获取分页子组件返回数据
  getChildEvent(index) {
    console.log('子组件返回分页数-----college-campustalk', index, this.pageNumber);
    if (this.pageNumber == index) {
      return;
    }
    //console.log(index);
    if (index > 0) {
      this.pageNumber = index;
      this.model.offset = (this.pageNumber - 1) * 20;
      this.getCollegeMoreList();//获取列表
    } else if (index == -1) {//上一页
      if (this.pageNumber == 1) {
        this.toastr.warning("当前为第一页");
      } else {
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber - 1) * 20;
        this.getCollegeMoreList();//获取列表
      }
    } else if (index == -2) {//下一页
      if (this.pageNumber == this.pageCount) {
        this.toastr.warning("当前为最后一页");
      } else {
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber - 1) * 20;
        this.getCollegeMoreList();//获取列表
      }
    }

  }

  //获取高校列表--offset=0
  getCollegeList() {
    this.model.offset = 0
    this.getCollegeMoreList()
  }
  //获取高校列表--offset自定义
  getCollegeMoreList() {
    let that = this;
    this.opera.loading();
    this.dataApi.getCollegeList(this.model).then((res: any) => {
      console.log("获取高校列表-----college-campustalk", this.model);
      //console.log(res);
      let old = this.countList
      this.collegeList = res.list;
      this.countList = res.count;
      this.pageCount = Math.ceil(res.count / 20);
      this.opera.loadiss();

      //执行一个laypage实例
      that.laypage.render({
        elem: 'test1' //注意，这里的 test1 是 ID，不用加 # 号
        , limit: 20, theme: '#5e4fff'
        , curr: that.pageNumber
        , count: res.count //数据总数，从服务端得到
        , jump: function (obj, first) {
          //obj包含了当前分页的所有参数，比如：
          console.log(obj.curr); //得到当前页，以便向服务端请求对应页的数据。
          //首次不执行
          that.pageNumber = obj.curr;
          that.model.offset = (that.pageNumber - 1) * 20;
          if (!first) {
            that.getCollegeMoreList()
            //do something
          }
        }
      });

    }).catch((err) => {
      //console.log(err);
      this.opera.loadiss();
    })
  }
  // 高校云认证
  remindCould() {
    this.opera.remindCould()
  }

  //去我合作列表
  goSignList() {
    let that = this;
    that.router.navigateByUrl("/home/company-identity");
    // this.opera.checkMember((isPay) => {
    //   if (isPay) {
    //     that.router.navigateByUrl("/home/company-identity");
    //   } else {
    //     that.opera.checkMemberAlertWithType("goCampus")
    //   }
    // })
  }

  //去我合作列表
  goMeetList() {
    this.router.navigateByUrl("/home/position-campus");
  }

  //获取热门城市
  getHotCity() {
    if (this.hotCity.length < 1) {
      this.dataApi.getCollegeHotCity().then((res: any) => {
        if (res) {
          this.hotCity = res;
        }
      }).catch((err) => {
      })
    }
  }
  //获取高校性质school_type
  getSchoolType() {
    this.dataApi.getDictDate('school_type').then((res: any) => {
      if (res) {
        this.config.school_type = res;
      }
    }).catch((err) => {
      //console.log(err);
    })
  }
  //获取高校分类school_nature
  getSchoolNature() {
    this.dataApi.getDictDate('school_nature').then((res: any) => {
      if (res) {
        this.config.school_nature = res;
      }
    }).catch((err) => {
    })
  }
  //获取高校特色school_label
  getSchoolLabel() {
    this.dataApi.getDictDate('school_label').then((res: any) => {
      this.config.school_label = res;
    }).catch((err) => {
      //console.log(err);
    })
  }
  //1
  gotoPage(type, n) {

    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        if (this.config.isMember_trial) {
          that.router.navigateByUrl("home/tool-campus");
          if (this.config.isShowPaySystem) {
            that.opera.checkMemberAlertWithType("goCloud")
          }
          return
        }

        that.local.set(Constants.collegeid, n.collegeid);
        if (type == 1) {
          //this.router.navigateByUrl("/home/college-milkround");
          console.log(n)
          that.router.navigate(['home/college-milkround'], {
            queryParams: {
              collegeid: n.collegeid,
              collegename: n.name
            }
          });
        } else if (type == 2) {
          //this.router.navigateByUrl("/home/college-campustalk1");
          that.router.navigateByUrl("home/college-details-campus");

        } else {
          this.scrolltool.setVal('college_campustalk_cell_scrollY', window.top.scrollY);
          this.scrolltool.setVal('college_campustalk_Page', this.pageNumber);
          that.router.navigateByUrl("home/college-details");
        }
      } else {
        //that.opera.checkMemberAlertWithType("goBuy_signMilk")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == n.collegeid);
        if (finItem) {
          that.local.set(Constants.collegeid, n.collegeid);
        if (type == 1) {
          //this.router.navigateByUrl("/home/college-milkround");
          console.log(n)
          that.router.navigate(['home/college-milkround'], {
            queryParams: {
              collegeid: n.collegeid,
              collegename: n.name
            }
          });
        } else if (type == 2) {
          //this.router.navigateByUrl("/home/college-campustalk1");
          that.router.navigateByUrl("home/college-details-campus");

        } else {
          that.router.navigateByUrl("home/college-details");
        }
        } else {
          this.pushCollege();
        }

      }
    })
  }

  getMyCampustalk1History() {
    this.dataApi.getMyCampustalk1History().then((res: any) => {
      console.log('getMyCampustalk1History', res);
      if (res) {
        this.campusListHistory = res;
        for (let val of this.campusListHistory) {
          //宣讲会预约审核中 + 合作审核中/合作成功 = 审核中
          if (val.audit_status == 'to_audit') {
            val.btnState = 1;
          }
          //显示预约成功
          if (val.audit_status == 'pass') {
            val.btnState = 2;
          }

          //预约失败  预约失败/合作失败
          if (val.audit_status == 'not_passed') {
            val.btnState = 3;
          }
          val.fail = false;

          if (val.audit_status == 'not_passed' || val.cooperation_status == '3') {
            val.fail = true;
          }
          //end_time
          val.isEnd = false
          let nowDate = new Date()
          let endDate = new Date(val.end_time)
          if (nowDate > endDate) {
            val.isEnd = true
          }

        }
      }
    }).catch((err) => {
      console.log("getMyCampustalk1History", err);
    })

  }
  // 查看宣讲会详情
  goPreach(ctid) {
    this.scrolltool.setVal('college_campustalk_Page', this.pageNumber);

    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.local.set(Constants.ctid, ctid);
        that.router.navigate(['home/college-details-preach']);
      }
      else {
        that.opera.checkMemberAlertWithType("goCampus")
      }
    })
  }

  //修改宣讲会申请
  goUpdata(collegeid, n, e) {
    this.scrolltool.setVal('college_campustalk_Page', this.pageNumber);

    e.stopPropagation()
    let that = this;
    this.opera.checkCompanyIsBack(collegeid, () => {
      this.opera.checkMember((isPay) => {
        if (isPay) {
          this.local.set(Constants.collegeid, collegeid);
          this.local.set(Constants.campusApply, JSON.stringify(n));
          this.router.navigate(['home/college-campus-sign']);
        } else {
          that.opera.checkMemberAlertWithType("goCampus")
        }
      })
    })
  }
  // 活动通知包触发
  singleTips(n) {
    this.scrolltool.setVal('college_campustalk_Page', this.pageNumber);
    this.opera.checkCompanyIsBack(n.collegeid, () => {
      //新的 活动通知页面
      this.local.set('activityInvitationData', JSON.stringify(n));
      this.local.set('activityInvitationDataType', 'campustalk');
      this.router.navigateByUrl('/home/activityInvitation');// 活动通知单品页面
    })
  }
  showJoinCerModel(e, camp) {
    e.stopPropagation();
    let data = {
      mcid: camp.ctid
    }
    this.dataApi.getCampustalkJoginCerInfo(data).then((res: any) => {
      if (res) {
        console.log('getCampustalkJoginCerInfo', res)
        this.JoinCerModel = res;
        console.log('showJoinCerModel', camp)
        this.isShowJoinCerModel = true
      } else {
        this.toastr.error('获取凭证失败')
      }
    }).catch(() => {
      this.toastr.error('获取凭证失败')
    })

  }

   //获取合作高校
   mycollegeList() {
    this.dataApi.mycollegeList().then((res: any) => {
      if (res) {
        this.addCollegeList = res;
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  //新增高校
  pushCollege() {
    this.isShowPushCollegeModel = true;
    this.pushCollegeModel.initData();
  }

  pushCollegeModelHidden(event) {
    this.isShowPushCollegeModel = false;
    this.mycollegeList();
  }

  goMilkround(){
    this.router.navigate(['home/college-milkround']);
  }

  // 撤回
  openRevoke(e,applyid){
    e.stopPropagation();
    this.revokeApplyid = applyid;
    this.revokeModule = true;
  }

  downMilk(){
    this.dataApi.revokeCampus(this.revokeApplyid).then((res:any)=>{
      this.getMyCampustalk1History();
      this.revokeModule = false;
    }).catch((err)=>{
      console.log("downMilk",err);
    });
  }

}
