import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {ModelPayWechatComponent} from "../model-pay-wechat/model-pay-wechat.component";
import {NoneVipAlertComponent} from "../none-vip-alert/none-vip-alert.component";
import { MatDialog } from "@angular/material/dialog";
import {DepartmentSelfAlertComponent} from "../department-self-alert/department-self-alert.component";
import {ModelPayCodeComponent} from "../model-pay-code/model-pay-code.component";
import {PayChipsModelComponent} from "../pay-chips-model/pay-chips-model.component";
declare let window;
declare let pingpp;
declare let Swiper:any;
@Component({
  selector: 'app-buy-la-coin',
  templateUrl: './buy-la-coin.component.html',
  styleUrls: ['./buy-la-coin.component.css']
})
export class BuyLaCoinComponent implements OnInit {
  @ViewChild('payWechat') child: ModelPayWechatComponent;
  //啦豆列表 count 啦豆数量  selete 是否选中 hot 是否有性价比高的卡劵 give 满减额 all 总啦豆数量（包含赠送）
  //cards 符合当前啦豆 的卡劵集合
  //hotCard 性价比高的卡劵 index：在cards 的索引（废弃） receiveid 性价比高的卡劵的ID
  public lalist = [
    {count:1000,selete:false,index:1,hot:false,topTitle:'赠送50啦豆',give:50,all:1050,cards:[],hotCard:{index:'0',receiveid:''}},
    {count:2000,selete:false,index:2,hot:false,topTitle:'赠送100啦豆',give:100,all:2100,cards:[],hotCard:{receiveid:''}},
    {count:3000,selete:false,index:3,hot:false,topTitle:'赠送150啦豆',give:150,all:3150,cards:[],hotCard:{receiveid:''}},
    {count:5000,selete:false,index:4,hot:false,topTitle:'赠送250啦豆',give:250,all:5250,cards:[],hotCard:{receiveid:''}},
    {count:8000,selete:false,index:5,hot:false,topTitle:'赠送400啦豆',give:400,all:8400,cards:[],hotCard:{receiveid:''}},
    {count:10000,selete:false,index:6,hot:false,topTitle:'赠送500啦豆',give:500,all:1500,cards:[],hotCard:{receiveid:''}},
    {count:20000,selete:false,index:7,hot:false,topTitle:'赠送1000啦豆',give:1000,all:21000,cards:[],hotCard:{receiveid:''}},
    {count:'其他',selete:false,index:8,hot:false},
  ];//啦豆列表

  //服务器返回的总的卡劵列表
  public cardList = [];
  public config: Config;

  //当前选择的卡劵索引  在cards的索引值
  public  currentCard = '';
  //自定义充值金额
  public  custCount = 0;

  //选择的当前的啦币对象
  public seleteLaCoin = null;

  //支付方式列表 （支付宝or微信）
  public payList = [] //支付列表

  //选择的的支付方式
  public model={
    channel:'alipay_pc_direct', //支付渠道alipay_pc_direct:支付宝pc wx_pub_qr:微信扫码
    count:'0', // 产品代码
    coupon_reveiceid:''
  }

  //是否是保险省以下的，如果是，弹窗校招顾问，屏蔽购买
  public  province_sign = "unmark";

  public MyLaCoinCount  = 0;

  @Output() modelShow = false;//是否打开弹窗
  //自定义购买啦豆的弹窗
  public  showAlertInput = false;

  public isShowbottom = false;

  // 啦豆冻结数据模型
  public ladouForzen = {
    ladou:0,
    order:0,
  }
  public loading = false;
  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public dialog: MatDialog,
  ) {
    this.config = Config.getInstance();//实例化config

  }

  ngOnInit() {
    let topNav = this.local.get('nav_tool')
    this.opera.initConfig('tool',"buyLaCoin");
    this.opera._hmtPush("校招工具","加载我的啦豆页面 ",'');

    //获取支付方式列表
    this.getPayChannel();

    //获取我的卡劵列表
    this.getMyCardList();

    this.getDepartment();

    this.getMyLaCoinCount();
    this.getOrderladouForzen();
    if(!this.config.isShowPaySystem){
      this.gotoPayHistory()
    }
  }

  getDepartment(){
    this.dataApi.getDepartment().then((res:any)=>{
      if(res)
      {
       this.province_sign =  res.province_sign;
        console.log('获取企业信息',res)
      }
    }).catch((error) => {
      //this.toastr.error(error);
    });
  }


  //获取支付渠道
  getPayChannel(){
    this.dataApi.getPayChannel().then((res:any)=>{
      if(res){
        console.log(res);
        this.payList=res;
        this.model.channel=res[0].alias;
      }
    }).catch((err)=>{
      console.log(err);
      this.toastr.error("获取支付渠道出错，请稍后重试");
    })
  }
  //接收支付子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
    if(event){
      this.router.navigateByUrl("home/tool-campus"); // 会员
    }else{
      this.toastr.error("支付失败，请重试");
    }
  }
  //支付
  pay(){
    if(this.loading)
    {
      this.toastr.warning('网络请求中')
      return;
    }
    this.opera._hmtPush("我的啦豆","充值金额","立即购买")

    let that = this;
    if(this.province_sign=='lower')
    {
      this.showBaoxianLowerModel();
      return;
    }

    if(!this.seleteLaCoin)
    {
      this.toastr.warning("请选择充值金额");
    }
    this.model.count = this.seleteLaCoin.count;

    if(!this.model.count){
      this.toastr.warning("请选择充值金额");
      return;
    }
    console.log('postPayCharge',this.currentCard,this.seleteLaCoin);
    let currentCard = "";
    if(this.currentCard!='')
    {
      let card = this.seleteLaCoin.cards[this.currentCard];
      console.log('使用的卡劵',card)
      this.model.coupon_reveiceid = card.receiveid;
      currentCard = card;
    }
    this.opera.loading();

    this.loading = true;
    this.dataApi.paymentRechargeCreate({
      count:this.model.count,
      coupon_receiveid:this.model.coupon_reveiceid}).then((res:any)=>{
      console.log('新支付系统',res,this.config.isShowPayModel);
      this.opera.loadiss();
      this.loading = false;
      if(res)
      {
        if(this.config.lastIdentity.status!=2)
        {
          that.router.navigateByUrl("home/paymentorderlist"); // 会员
          return;
        }
        let pay_orderid = res.value;
        this.config.isShowPayModel = true;
        let alertGive = this.seleteLaCoin.give+"";
        let dialog =  this.dialog.open(ModelPayCodeComponent, {
          data:{
            title:"支付",
            buyType:'labi',
            pay_orderid:pay_orderid,
            labiData:{
              laCoin:this.seleteLaCoin,
              currentCard:currentCard,
            },
            callback:(res)=>{
              console.log('callback',res,currentCard)
              this.dialog.closeAll();
              this.config.isShowPayModel = false;
              //获取我的卡劵列表
              this.getMyCardList();
              this.opera.updateParOrderCount();

              if(res)
              {
                if(!currentCard)
                {
                  this.paySuccess(alertGive);
                }
                this.toastr.success('购买成功')
                setTimeout(()=>{
                  // that.getMyLaCoinCount();
                  that.router.navigateByUrl("home/tool-campus"); // 会员

                },1000)
              }

            },}
        }).afterClosed().subscribe((isConfirmed) => {
          console.log("afterClosed",isConfirmed);
          this.opera.updateParOrderCount();

          if(this.config.payTimer) {
            clearInterval(this.config.payTimer)
          }

        });

      }
    }).catch((err)=>{
      console.log(err);
      this.loading = false;

      this.config.isShowPayModel = false;
      this.toastr.error(err)
      this.opera.loadiss();
    })
  }

  //选择的啦豆的点击方法
  seleteLaCoinClick(n){

    //选择的index 8 自定义金额  遍历前面的所有啦豆 然后清空选中状态
    if(n.index==8) {
      this.opera._hmtPush("我的啦豆","充值金额","其他")

      this.seleteLaCoin = null;
      let newArr  =  [];
      for(let item of this.lalist) {
        item.selete = n.index==item.index?true:false;
        newArr.push(item)
      }
      this.lalist = newArr;
      this.dialog.open(DepartmentSelfAlertComponent, {
      }).afterClosed().subscribe((isConfirmed) => {
      });
      return;
    }

    this.opera._hmtPush("我的啦豆","充值金额",n.count)

    //遍历啦豆列表，改变选中状态 然后 如果有性价比高的卡劵，找到索引，赋值给 currentCard
    let newArr  =  [];
    for(let item of this.lalist)
    {
      item.selete = n.index==item.index?true:false;
      if(item.hot)
      {
        for(let cardIndex=0;cardIndex<item.cards.length;cardIndex++) {
          let c = item.cards[cardIndex];
          if (c.receiveid == item.hotCard.receiveid) {
            this.currentCard = cardIndex + '';
          }
        }
      }
      newArr.push(item)
    }
    this.lalist = newArr;

    this.seleteLaCoin = n;
    //custCount 赋值为0，避免之前选择了并输入自定义金额 又选择其他规定的金额，清空自定义啦豆数量，避免混淆
    this.custCount = 0;

    this.model.count = n.count;

    // console.log('seleteLaCoin',this.seleteLaCoin);
    //检测选择的啦豆有没有热门卡劵
    if(this.seleteLaCoin.hot)
    {
      //当前选择的啦币有hot 卡劵，根据hot卡劵的ID 遍历啦豆cards 找到对应的卡劵的索引。并赋值currentCard
      //currentCard 当前选择的啦豆的卡劵的索引
        for(let cardIndex=0;cardIndex<this.seleteLaCoin.cards.length;cardIndex++) {
          let c = this.seleteLaCoin.cards[cardIndex];
          if (c.receiveid == this.seleteLaCoin.hotCard.receiveid) {
            this.currentCard = cardIndex + '';
          }
        }
      // console.log('有热门卡劵', this.seleteLaCoin, this.currentCard)
    }
    else
    {
      //没有热门卡劵
      if(this.seleteLaCoin.cards.length>0) {
        //拿到选择的啦豆的卡劵列表最后一条的索引，作为默认选择的卡劵
        let cardslength = this.seleteLaCoin.cards.length - 1;
        this.currentCard = cardslength.toString();
        //将默认的选择的卡劵取出来，判断一下这个卡劵的性价比，性价比不如系统送的，就将选择的卡劵置空
        let maxcards = this.seleteLaCoin.cards[cardslength];
        // console.log('没有热门卡劵',this.seleteLaCoin)
        //所有卡劵不如系统赠送
        if (maxcards.gift_amount < this.seleteLaCoin.give) {
          // console.log('所有卡劵不如系统赠送')
          this.currentCard = '';
        }
      }
      else
      {
        //没有卡劵
        this.currentCard = '';
      }

    }

  }
  seleteCardChange(){
    if(!this.seleteLaCoin)
    {
      return;
    }

    // this.currentCard
    // console.log('seleteCardChange',this.seleteLaCoin.count,this.seleteLaCoin)

  }
  //计算总价
  countMoney(){
    let count = 0;
    if(this.seleteLaCoin)
    {
      count = this.seleteLaCoin.count;
    }
    return count;
  }
  cancelAler(){
    this.showAlertInput = false;
  }

  commitAlert(){
    this.showAlertInput = false;

    if(this.custCount>500000)
    {
      this.toastr.warning('购买啦豆单次支付金额超限，请分批购买');
      return;
    }

    let cardList = [];
    for(let index = 0;index<this.cardList.length;index++)
    {
      let card =  this.cardList[index];
      if(card.limited_amount <= this.custCount)
      {
        cardList.push(card);
      }
    }

    for (let index = 0;index<cardList.length;index++)
    {
      let card =  cardList[index];
      card.index = index;
    }
    //如果没有卡劵/不使用卡劵
    this.currentCard = '';
    let give = 0;

    if(this.custCount>=1000)
    {
      let result = this.custCount - this.custCount % 1000;
      give = (result/1000)*100;
      // console.log("赠送的",give)
    }

    //自定义购买啦豆对象
    this.seleteLaCoin = {count:parseInt(this.custCount.toString()),selete:false,index:8,hot:false,topTitle:'',give:give,all:parseInt(this.custCount.toString())+give,cards:cardList}
    // console.log('custCount',this.seleteLaCoin)
  }
  //获取我的卡劵列表
  getMyCardList(){
    // offset:isRef?0:(this.pageNumber-1)*8,
    //       limit:8,
    this.dataApi.getMyCardList({product_code:'lacoin',status:'unused',sort:'limited_amount',coupon_category:'full_add_coupon',offset:0,limit:1000}).then((res:any)=>{
      // console.log('获取我的卡劵列表',res);
      if(res){
        let arr = [];

        for (let item of this.lalist)
        {
          let cardArr = [];
          //title 卡劵的自定义title，
          // space 优惠卷的性价比  （赠送金额/满减限额）*1000000 ， *1000000 使比例变成正整数，便于比较大小，避免小数做比较不精确
          for (let index = 0;index<res.list.length;index++)
          {
            let card  = res.list[index];
            // card.index = index;
            card.title='满'+card.limited_amount+'赠送'+card.gift_amount;
            //找到当前 金额的所有卡劵
            if(card.limited_amount <= item.count){
              //优惠卷比例
              card.space =  (card.gift_amount/card.limited_amount)*1000000;
              cardArr.push(card);
               // console.log('金额的',card.limited_amount+"卡劵",card)
            }
          }


          //冒泡排序 排序卡劵的性价比进行冒泡排序
          let indexArr = this.bubbleSort(cardArr);
          let indexArr1 = [];
          for (let index = 0;index<indexArr.length;index++)
          {
            let item1 = indexArr[index];
            item1.index = index;
            // console.log('排序后的item',item1)
            indexArr1.push(item1)
          }
          // console.log('排序后的index',indexArr1)

          //冒泡排序卡券  最后一张卡劵差额最大
          item.cards =indexArr1;
          for (let card of item.cards)
          {
            if(card.gift_amount>item.give)
            {
              item.hot = true;
              item.hotCard = card;
              item.topTitle = '满'+card.limited_amount+'赠送'+card.gift_amount
              // console.log('热门卡劵',item,item.cards)
            }
          }
          arr.push(item);
        }
        this.lalist = arr;

        //cardList 格式化卡劵列表，
        var copyList = JSON.parse(JSON.stringify(res.list))
        let allCards = [];
        for (let index = 0;index<copyList.length;index++)
        {
          let card  = copyList[index];
          card.index = index;
          card.title='满'+card.limited_amount+'赠送'+card.gift_amount;
          allCards.push(card)
        }
        this.cardList = allCards;
        this.initLa(this.lalist);
        //buyLaCoinMaxNumber
        // console.log('卡劵格式化',this.cardList)
        // console.log('啦币种类',this.lalist)
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  // 冒泡排序 排序卡劵的性价比进行冒泡排序
   bubbleSort(arr) {
    var len = arr.length;
     for ( let j = 0; j < len - 1; j++) {
       for ( let i = 0; i < len - 1 - j; i++)
       {
         let a = arr[i];
         let b = arr[i + 1];
         if (a.space> b.space ) {
           let  temp = arr[i];
           arr[i] = arr[i + 1];
           arr[i + 1] = temp;
         }
       }
     }
    return arr;
  }

  //购买说明
  buyExplainClick(){
    this.router.navigateByUrl('home/buyExplain');
  }
  //初始化啦豆的选择
  // 根据卡劵页面点击过来的
  initLa(laArr){
    //buyLaCoinMaxNumber 满赠的卡劵的界限
    let buyLaCoinMaxNumber = this.local.get('buyLaCoinMaxNumber');
    if(!buyLaCoinMaxNumber)
    {
      //没有默认选择
      let newArr  =  [];
      let data = null;
      for(let item of this.lalist)
      {
        if(item.count==5000)
        {
          data = item;
        }
      }
      if(data)
      {
        this.seleteLaCoinClick(data);
      }
      return;
    }
    this.local.remove('buyLaCoinMaxNumber');
    let buyLaCoinMaxNumber_json = JSON.parse(buyLaCoinMaxNumber);
    let MaxNumber =  parseInt(buyLaCoinMaxNumber_json.limited_amount);
    let n = null;
    //只找到第一个符合条件的
    for (let item of laArr)
    {
      if(item.count>=MaxNumber)
      {
        n = item;
        break;
      }
    }
    console.log('buyLaCoinMaxNumber',buyLaCoinMaxNumber_json,n)
    //receiveid: "5ee992e809b8e81af406b64e"
    let seleteCard_index = null;
    //模拟点击当前符合啦豆的点击事件
    if(n)
    {

      for (let index=0;index<n.cards.length;index++)
      {
        let car = n.cards[index];
       if(buyLaCoinMaxNumber_json.receiveid==car.receiveid)
       {
         seleteCard_index = index;
         break;
       }
      }

      let newArr  =  [];
      for(let item of this.lalist)
      {
        item.selete = n.index==item.index?true:false;
        this.currentCard = seleteCard_index;
        newArr.push(item)
      }
      this.lalist = newArr;

      this.seleteLaCoin = n;

      this.model.count = n.count;
      //seleteLaCoin
      //currentCard
      console.log('seleteLaCoin----------',this.currentCard,this.currentCard,seleteCard_index,this.lalist);

    }
    else
    {

    }
  }
  //增值服务协议
  zengzhi() {
    this.router.navigateByUrl("home/zengzhi"); // 会员
  }
  showBaoxianLowerModel(){
    this.dialog.open(DepartmentSelfAlertComponent, {
      data:{title:"请联系校招顾问购买",title2:'asdasda'}
    }).afterClosed().subscribe((isConfirmed) => {
    });
  }
  //获取我的啦豆余额
  getMyLaCoinCount() {
    this.dataApi.getMyLaCoinCount({}).then((res:any) => {
      // console.log('获取我的啦豆余额',res);
      if (res) {
        this.MyLaCoinCount = res;
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  //购买历史
  gotoPayHistory() {
    this.router.navigate(['home/payHistory']);
  }
  gotoidentity(){
    this.router.navigateByUrl('guide-audit');
  }
  paySuccess(give){
    let dialog =  this.dialog.open(PayChipsModelComponent, {
      data:{
        title:"赠送您的"+give+"啦豆已到账，您可使用豆啦购买任意产品包",
        rightBtnTitle:'确定',
        callback:(res)=>{
          console.log('callback',res)
          this.dialog.closeAll();
          this.opera.updateParOrderCount();


        },}
    }).afterClosed().subscribe((isConfirmed) => {
    });
  }
  getOrderladouForzen(){
    this.dataApi.getOrderladouForzen().then((res:any)=>{
      console.log('getOrderladouForzen',res)
      this.ladouForzen.ladou = res.value;
    }).catch((error) => {
      //this.toastr.error(error);
    });
  }


}
