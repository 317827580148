import {Component, OnInit, Output} from '@angular/core';
import {DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-pay-history',
  templateUrl: './pay-history.component.html',
  styleUrls: ['./pay-history.component.css']
})
export class PayHistoryComponent implements OnInit {

  public  list = [
    ];
  public countList = 40;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 1;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService
  ) { }


  ngOnInit() {
    let topNav = this.local.get('nav_tool')
    this.opera.initConfig('tool','buyLaCoin');
    this.getConsumeList();

  }
  //获取分页子组件返回数据
  getChildEvent(index){
    // this.pageNumber = index;

    if(index > 0){
      this.pageNumber = index;
      this.getConsumeList();
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.getConsumeList();
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.getConsumeList();
      }
    }
  }
  getConsumeList(){
    let data ={
      skip:(this.pageNumber-1)*10,
      limit:10
    }
    //getLaPayList
    this.dataApi.getLaPayList(data).then((res:any)=>{
      if(res){
        console.log('获取到消费getLaPayList',res);
        let newArr = [];
        for (let item of  res.list) {
          item.useTime = this.RiQi(item.add_time);
          newArr.push(item);
        }
        this.list =newArr;
        this.countList = res.count;
        this.pageCount = Math.ceil(this.countList/data.limit)
      }
    }).catch((err)=>{
      console.log('获取到消费出错');
      this.toastr.error("获取到消费出错，请稍后重试");
    })
  }
  //时间戳转时间
  RiQi(dataStr) {
    var now = new Date(Date.parse(dataStr));
    var   year=now.getFullYear();
    var   month=now.getMonth()+1;
    var   date=now.getDate();
    var   hour=now.getHours();
    var   minute=now.getMinutes();
    var   second=now.getSeconds();
    let  minuteStr = "";
    if(minute<=9) {
      minuteStr = "0"+minute;
    } else {
      minuteStr = minute.toString();
    }
    return   year+"-"+month+"-"+date +'  '+hour+':'+minuteStr;
  }

  //再次购买
  gotoPay(n){
    this.local.set('order_state','done');
    this.router.navigate(['home/paymentorderlist']);
  }

  myTool(){
    this.router.navigate(['home/tool-my']);
  }

  myOrder(){
    this.router.navigate(['home/paymentorderlist']);
  }

  myInvoice(){
    this.router.navigate(['home/payInvoice']);
  }

  myLadou(){
    this.router.navigate(['home/payHistory']);
  }

}
