import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-product-model',
  templateUrl: './product-model.component.html',
  styleUrls: ['./product-model.component.css']
})
export class ProductModelComponent implements OnInit {
  public config: Config;
  constructor(
    public toastr: ToastrService,
    public opera:Opera,
    public dataApi: DataApi,
  ) { 
    
  }
  public isCellMe = false;
  public department:any;
  public productType:String;
  public productItemData = {};
  public alias = '';
  public upData = {
    category:'',
    departmentid:'',
    contactname:'',  //联系人姓名
    contactmobile:'', //联系人电话
  }

  @Input() isShow: false;//是否显示弹窗页面
  @Output() modelHidden = new EventEmitter<number>();

  ngOnInit(){
  }

 async initData(alias,type,isShowBuy?:boolean){
    this.productType = type;
    this.alias = alias; //产品别名
    if(type == 'contactMe' && !alias){
      this.upData.category = 'join_member';
    }else{
      this.upData.category = alias;
    }
    if(alias){
      this.productcomponent(alias);
    }
    let that = this;
    this.opera.checkMember((isPay)=>{
      if(isPay) {
        that.isCellMe = false;
      }else{
        that.isCellMe = true;
      }
      if(isShowBuy){
        that.isCellMe = isShowBuy;
      }
    })
    this.getLocalDepartment();
  }

  productcomponent(alias){
    this.dataApi.productcomponent(alias).then((res: any) => {
      if (res) {
        console.log(res);
        this.productItemData = res;
      }
    }).catch((err) => {
      console.log('productcomponent', err);
    })
  }

  //获取企业信息
  getLocalDepartment(){
    this.department = this.dataApi.getLocalDepartment();
    this.upData.contactname = this.department.linkman;
    this.upData.contactmobile = this.department.contact_info_list.mobile.value;
    this.upData.departmentid = this.department.accountid;
    console.log(this.department);
  } 

  //请校招顾问联系我
  contactMeSave(){
    this.dataApi.contactMeSave(this.upData).then((res: any) => {
      if (res) {
        let that = this;
        that.toastr.success('已通知校招顾问，校招顾问会在第一时间与您联系'); 
        setTimeout(()=>{                 
          that.productmodelHidden();
        },300);
      }
    }).catch((err) => {
      console.log('请校招顾问联系我', err);
    })
  }


  save(){
    this.productType = 'contactMe';
  }


  productmodelHidden(){
    this.modelHidden.emit();
  }

}
