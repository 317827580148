
<div class="homepage-block">
  <div class="box">
    <div class="college-block-head">
      购买说明
    </div>






  </div>
</div>
