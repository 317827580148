import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Events, Subscription } from '../../provider/eventService';
import { DataApi, Config, Local, Constants, Opera, MyMMessage } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import '../../assets/js/viewer.min';
import { ModelSendMsgComponent } from "../model-send-msg/model-send-msg.component";
import { regexManager } from "../../provider/regexManager";
import { ResumeLabelModelComponent } from "../resume-label-model/resume-label-model.component";
import { productManager } from "../../provider/productManager";
import { VoiceCallModelComponent } from "../voice-call-model/voice-call-model.component";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
declare var layui;
declare var Viewer;
declare let window;
@Component({
  selector: 'app-college-resumedetails',
  templateUrl: './college-resumedetails.component.html',
  styleUrls: ['./college-resumedetails.component.css']
})
export class CollegeResumedetailsComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true })
  productModel: ProductModelComponent;

  @Output() isShowVoiceCallModel = false;//是否打开弹窗(简历收件箱)
  @Output() isShowPositionPushModel = false;//是否打开弹窗(简历收件箱)
  @ViewChild('voiceCallModel', { static: true }) voiceCallModel: VoiceCallModelComponent;
  @ViewChild('sengMsgModel', { static: true }) sengMsgModel: ModelSendMsgComponent;
  @ViewChild('resumeLabelModel', { static: true }) resumeLabelModel: ResumeLabelModelComponent;
  @Output() addResumeLabelmodelShow = false;//是否打开弹窗
  @Output() modelShowSengMsg = false;//是否打开单品发送极速短信
  @Output() SengMsgModel = {
    userName: '',
    userPic: '',
    talentid: '',
    graduation_school: '',//学校
    major_text: '',//专业
    gender: '',
    address: '',
    positionid: ''
  };//打开单品发送极速短信用户对象
  public config: Config;
  public isInit = true;//是否初始化成功
  public isCanFollow = false;//防止重复点击收藏按钮
  public isSearch = false;//是否从简历搜索列表进入
  public isShowContact = false;//是否从简历搜索列表进入

  private chatevent: Subscription;

  //简历详情数据
  public resumeDetails = {
    talent_base: { name: '', accountid: '', personal_avater: '', collegeid: '', native_place_text: '', collegename: '', logo: '', major_text: '', address_text: '', gender: "" },
    job_target: {
      work_city: '',
      industry: '',
      career: '',
      address_text: '',
      education_text: '',
      work_experience_text: '',
      self_evaluates: ''
    },
    skills: [],
    delivery_info: {
      feedback_status: '',
      deliveryid: ''
    },
    education_backgrounds: [],
    work_experiences: [],
    project_experiences: [],
    Training_Experience: [],
    works: [],
    iscaninvite: false,
    is_buy: false,
    isfollow: false,
    jm_username: '',
    resumeid: '',
    resume_number: '',
    graduate_university: '',
    collegeid: '',
    pro_skills: [],
    youshiArr: [],
  };
  public resumeLabelDetail: any;
  public item: any;//接收的人才数据(上一个页面传递过来的少量人才数据)
  public match = 0;
  public positionDataList = [];//初始化在期职位类别列表
  public positionList = [];//初始化在期职位列表
  public positionDataListActive = [];//初始化在期职位列表(可供选择的职位名称)

  public careerTypeString = [];//期望职位的字符串数组

  public chatsList: any;//会话列表
  public myDepartment: any;//当前登录的企业信息(department)
  public swiperImgUrl = [];
  public userJmUsername = '';//对方的极光ID

  public isFirst = true;//是否是第一次沟通
  public isShowBigImg = false;
  public workexp = [];//工作经验
  public workexpCopy = [1, 2, 3, 4];//工作经验
  public works = [];//作品展示
  public project = [];//项目比赛
  public pro_skills = [];
  public isHideWorkexp = true;
  public isHideWorks = true;
  public isHideProject = true;
  // public pro_skills=[];//通用技能
  public seat_number = '';//展位号
  public positionid = '';
  private collegeid = '';
  public isBack = true;
  public ResumeLog = [];

  //是否是大陆企业
  public isMainland_employer = true;

  public phoneModel = {
    myPhone: '', //企业拔打电话的手机
    myTel: '',
    myTel_qu: '',
    isOkmyPhone: false,
    NetPhone: '',
    positionid: '',
    getPhoneType: '1',
    PhoneModelType: 0,
    MyPhoneTime: 0,
    MyPushCount: 0,
    isShowPhoneModel: false,
    jiduCode: '',
    positionPushCode: '',
  };
  public countList = 0;//列表数量
  public showNotes = false;
  public notesModel = {
    resumeid:'',
    remark:'',
  }
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public logmodel = {
    limit: 10,
    offset: 0
  };

  public setResumeStatusLoading = false;

  public mrid = "";

  constructor(
    public dataApi: DataApi,
    public events: Events,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public opera: Opera,
    public local: Local,
    public dialog: MatDialog,
    public regexManager: regexManager,
    public productManager: productManager,
    public toastr: ToastrService,
  ) {
    this.config = Config.getInstance();//实例化config
    this.getImDialogueList();//获取会话列表
    this.lastAuthenticationIdentity();//---获取上次认证信息
    //接收到更新会话列表的广播
    this.chatevent = this.events.subscribe('chatTo:contact', (data) => {
      this.config.chatsList = data;
      // //console.log('会话列表更新');
      this.chatsList = data.list;
    });
    activatedRoute.queryParams.subscribe(queryParams => {
      console.log('简历详情', queryParams)
      if (queryParams.item) {
        this.item = queryParams.item;
      }

      if (queryParams.match) {
        this.match = queryParams.match;
      }

      if (queryParams.positionid) {
        this.positionid = queryParams.positionid;
        //console.log("获取到选中职位id-----------------college-resumedetails");
        //console.log(this.positionid);
      }

      if (queryParams.seat_number) {
        this.seat_number = queryParams.seat_number;
      }
      if (queryParams.isBack + "") {
        this.isBack = queryParams.isBack;
      }
      if (queryParams.mrid) {
        this.mrid = queryParams.mrid;
      }

      if (queryParams.resumeid) {
        console.log(queryParams.resumeid);
        this.resumeDetails.resume_number = queryParams.resumeid;
        this.collegeid = queryParams.collegeid;
        this.getResumeNumView(queryParams.resumeid);//获取人才详情
        this.local.set(Constants.resumeid, queryParams.resumeid);
      }

    });

    if (this.positionList.length == 0) {
      this.getPositionList();//获取在期职位列表
    }

    if (!this.resumeDetails.resume_number) {
      //----通过简历编号获取简历详情
      this.getResumeNumView(this.local.get(Constants.resumeid));//获取人才详情
    }

    //获取企业信息
    this.getDepartment();

  }

  ngOnInit() {
    let hash = location.hash;
    let arr = hash.split('/')
    console.log('location.hash', arr);

    if (this.local.get(Constants.oldUrl) == '/home/position-resume'
      || this.local.get(Constants.oldUrl) == '/home/position-resume-like'
      || this.local.get(Constants.oldUrl) == '/home/voiceCallRecord'
      || this.local.get(Constants.oldUrl) == '/home/positionPushRecord'
      || this.local.get(Constants.oldUrl) == '/home/activityInviteRecordDetail'
    ) {
      this.opera.initConfig('nav-resume-manager', '');
    } else {

    }
    //this.opera.initConfig(arr[1],'college-resume');
    this.getUser();
    this.getProductList();
    this.opera.scrollTrue();
    this.opera.scrollTop1();
  }
  //获取在期职位列表
  getPositionList() {
    //console.log("在期职位列表获取线上数据--college-resumeDetails");
    return this.dataApi.getPositionList({
      limit: 100,
      offset: 0,
      status: 'release',
    }).then((res: any) => {
      if (res) {
        if (res.list.length == 0) {
          return;
        }
        for (let val of res.list) {
          let model = { text: '', code: '' };
          val.isFilterActive = false;
          model.code = val.post_type;
          model.text = val.post_type_text;
          this.positionDataList.push(model);
        }
        this.positionList = res.list;
        this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
      }

    }).catch((error) => {
      //console.log(error);
    });
  }


  //反馈状态
  setResumeStatus(status) {
    this.opera._hmtPush("简历管理", "查看联系方式", "")

    if (!this.resumeDetails.delivery_info) {
      return;
    }
    if (this.setResumeStatusLoading) {
      this.toastr.warning('网络请求中')
      return;
    }

    // this.opera.checkMember((isPay)=>{
    //   if(isPay) {
    //     this.setResumeStatusLoading = true;
    //     this.dataApi.setSendResumeStatus(this.resumeDetails.delivery_info.deliveryid,status).then((res:any)=>{
    //       this.toastr.success("标记完成");
    //       this.isShowContact = true;
    //       this.resumeDetails.delivery_info.feedback_status = status;
    //       this.getResumeNumView(this.resumeDetails.resume_number)
    //       this.getResumeLog()
    //       this.setResumeStatusLoading = false;
    //     }).catch((err)=>{
    //       this.setResumeStatusLoading = false;
    //     })
    //   } else {
    //     this.opera.checkMemberAlertWithType("noneVip_setResumeStatus")
    //   }
    // })

    // 只要有投递记录，不用判断任何会员权限，彭真2021-3-31
    this.dataApi.setSendResumeStatus(this.resumeDetails.delivery_info.deliveryid, status).then((res: any) => {
      this.toastr.success("标记完成");
      this.isShowContact = true;
      this.resumeDetails.delivery_info.feedback_status = status;
      this.getResumeNumView(this.resumeDetails.resume_number)
      this.getResumeLog()
      this.setResumeStatusLoading = false;
    }).catch((err) => {
      this.setResumeStatusLoading = false;
    })

  }
  initDataImg(e) {
    var viewer = new Viewer(document.getElementById(e));
  }

  alertSengMSG(event) {
    event.stopPropagation();
    let that = this;

    this.opera.checkMember((isPay) => {
      if (isPay) {
        this.productManager.getProductUsableCount(this.config.sms_bulkinvitation, (count, isVip) => {
          if (count == 0 || !isVip) {
            // this.config.payVipHistoryPage = {
            //   page:"简历详情",
            //   isVip:isVip,
            //   pro:'职位推送'
            // };
            // that.local.set("single_category_code", this.config.ProductListCode.sms_bulkinvitation_code);
            // that.router.navigate(['home/tool-campus']);
            this.product('sms_bulkinvitation', 'recharge');
          } else {
            try {
              that.SengMsgModel.graduation_school = "";
              that.SengMsgModel.major_text = "";
              that.SengMsgModel.address = "";
              if (this.resumeDetails.education_backgrounds && this.resumeDetails.education_backgrounds.length > 0) {
                that.SengMsgModel.graduation_school = this.resumeDetails.education_backgrounds[0].graduation_school;
                that.SengMsgModel.major_text = this.resumeDetails.education_backgrounds[0].major_status_text;
                that.SengMsgModel.address = this.resumeDetails.talent_base.address_text;
              }
            } catch (e) { }
            that.sengMsgModel.initData(1, []);
            that.SengMsgModel.userName = that.resumeDetails.talent_base.name;
            that.SengMsgModel.userPic = that.resumeDetails.talent_base.logo ? that.resumeDetails.talent_base.logo : '';
            that.SengMsgModel.talentid = that.resumeDetails.talent_base.accountid;
            that.SengMsgModel.gender = that.resumeDetails.talent_base.gender;
            that.modelShowSengMsg = true;
          }
        })
      } else {
        //this.opera.checkMemberAlertWithType("noneVip_sms_bulkinvitation")
        this.product('sms_bulkinvitation', 'recharge');
      }
    })
  }
  Modeldismiss(event) {
    this.modelShowSengMsg = false;
    this.config.batchList = [];
    this.getResumeLog()
  }
  //获取企业信息
  getDepartment() {
    this.dataApi.getDepartment().then((res: any) => {//通过tooken获取企业信息
      if (res) {
        this.myDepartment = res;
        this.initPhoneData(this.myDepartment);
        if (this.myDepartment.employer_category != '0101') {
          this.isMainland_employer = false;
        }
        if (this.myDepartment.virtualnumber != null) {
          if (this.myDepartment.virtualnumber.contact_type == 'tel') {
            this.phoneModel.getPhoneType = '2';
            let qu = this.myDepartment.virtualnumber.tel.substring(0, 4)
            let tel = this.myDepartment.virtualnumber.tel.substring(4, this.myDepartment.virtualnumber.tel.length)
            this.phoneModel.myTel_qu = qu;
            this.phoneModel.myTel = tel;
          } else {
            this.phoneModel.myPhone = this.myDepartment.virtualnumber.mobile;
            this.phoneModel.getPhoneType = '1';
          }
        } else {
          this.getUser();
        }
        this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  //获取会话列表
  getImDialogueList() {
    //console.log(this.config.chatsList);
    if (this.config.chatsList && this.config.chatsList.list) {
      this.chatsList = this.config.chatsList.list;
      for (let val of this.chatsList) {
        if (val.dialogue_user.resume_number == this.resumeDetails.resume_number) {
          this.isFirst = false;
          return;
        }
      }
    } else {
      this.getNewChatList();
    }
  }
  goLink(url) {
    window.open(url, '_blank')
  }
  //----更新会话列表(线上数据)
  getNewChatList() {

    this.dataApi.getImDialogueAllList({}).then((res: any) => {
      if (res) {
        this.dataApi.setLocalChatsList(res);//会话列表本地存储
        this.chatsList = res.list;
        for (let val of this.chatsList) {
          if (val.dialogue_user.resume_number == this.resumeDetails.resume_number) {
            this.isFirst = false;
            return;
          }
        }
      }

    }).catch((error) => {
      //console.log(error);
      this.toastr.error(error);
    });
  }

  //去沟通页面前携带参数处理(初次沟通的情况)
  goContactChatHandle() {
    //console.log("去沟通页面之前检查职位参数(新会话)-------college-resume-details");
    //console.log(this.positionList);
    let userInfo = {
      personal_avater: this.resumeDetails.talent_base.personal_avater,
      resume_number: this.resumeDetails.resume_number,
      resumeid: this.resumeDetails.resumeid,
      talent_name: this.resumeDetails.talent_base.name,
      talentid: this.resumeDetails.talent_base.accountid,
      jm_username: this.resumeDetails.jm_username,
    }
    let position;
    if (this.positionid) {
      for (let n of this.positionList) {
        if (this.positionid == n.positionid) {
          position = n;
        }
      }
    } else {
      position = this.positionList[0];
    }
    //检查是否有在期职位
    if (this.positionList.length > 0) {
      //展位号
      if (this.seat_number) {
        this.local.set(Constants.seat_number, JSON.stringify(this.seat_number));//展位号
      }
      // this.local.set(Constants.chatPosition,position);//职位参数
      this.local.set(Constants.chatPosition, JSON.stringify(position));//职位参数
      this.local.set(Constants.userInfo, JSON.stringify(userInfo));//聊天记录本地存储参数
      this.router.navigate(['home/chat-contact']);
    } else {
      this.opera.remindPublish('在线直聊');
    }
  }
  //通过简历编号获取简历详情
  getResumeNumView(_resumeNumber) {
    if (!_resumeNumber) {
      this.toastr.error("当前简历或已被删除，请刷新后重试");
      this.opera.goChangeRouter('college-resume');
      return;
    }
    if (!this.resumeDetails.jm_username) {
      this.opera.loading();
      this.dataApi.getResumeNumView({ resume_number: _resumeNumber, is_search: this.isSearch + "&mrid=" + this.mrid }).then((res: any) => {
        this.resumeDetails = this.resumeHandle(res);
        console.log("获取到简历详情------", this.resumeDetails);
        this.notesModel.resumeid = this.resumeDetails.resumeid;
        this.getNotes();
        this.setDeliveryRead();
        this.getResumeLabelDetail(this.resumeDetails.resumeid, false);
        this.getResumeLog();
        let youshiArr = [];
        if (this.resumeDetails.pro_skills) {
          for (let item1 of this.resumeDetails.pro_skills) {
            if (item1.contrast_list) {
              for (let youshi of item1.contrast_list) {
                youshiArr.push(youshi.text);
              }
            }
          }
        }
        this.resumeDetails.youshiArr = youshiArr;
        this.getImDialogueList();//---更新会话列表
        this.isInit = false;
        //获取对方的极光账号
        if (this.resumeDetails.jm_username) {
          this.userJmUsername = this.resumeDetails.jm_username;
        }
        window.scrollTo(0, 0);
        this.opera.loadiss();
      }).catch((error) => {
        console.log(error)
        this.isInit = false;
        if (error == '您还没通过的合作申请') {
          this.opera.freeAttestation("通过工作啦认证才能查看全部学生，立即认证！");//提醒认证
        } else {
          if (error.indexOf('简历不开放') >= 0) {
            this.toastr.error('该学生隐藏了自己的简历');
          }
          else if (error.indexOf('简历不存在') >= 0) {
            this.toastr.error('该学生隐藏了自己的简历');
          }
          else if (error.indexOf('用户不存在') >= 0) {
            this.toastr.error('该学生的简历已删除');
          }
          else if (error.indexOf('帐户不存在') >= 0) {
            this.toastr.error('该学生的简历已删除');
          }
          else if (error.indexOf('人才档案不存在或已删除') >= 0) {
            this.toastr.error('该学生的简历已删除');
          }
          else {
            this.toastr.error('啊哦，服务器君忙不过来了');
          }
        }
        this.opera.loadiss();
      });
    } else {
      this.getImDialogueList();//---更新会话列表
    }
  }

  //获取上次认证信息
  lastAuthenticationIdentity() {
    if ((!this.config.lastIdentity) || (this.config.lastIdentity.status != 2)) {
      this.dataApi.getPlatformApply().then((res: any) => {
        //console.log(res);
        if (res) {
          if (res.status == 2) {
            this.config.department.is_certified = true;
            this.dataApi.setLocalDepartment(this.config.department);
          }
          this.config.lastIdentity = res;
          this.dataApi.setLastIdentity(this.config.lastIdentity);
        }
      }).catch((error) => {
        //console.log(error);
      })
    }
  }

  //处理获取到的数据
  resumeHandle(res) {
    //判断工作经历年份是否为至今
    if (res.work_experiences) {
      if (res.work_experiences.length > 0) {
        res.work_experiences.forEach((work) => {
          var endDate = new Date(work.end_date);
          if (endDate.getFullYear() == 2100) {
            work.isEndDate = true;
          }
          this.workexp.push(work);
        });
      }
      //-----------截取工作经验
      // if(res.work_experiences.length>2){
      //   res.work_experiences=[];
      //   res.work_experiences.push(this.workexp[0]);
      //   res.work_experiences.push(this.workexp[1]);
      // }
    }

    //作品展示
    if (res.works) {
      this.works = res.works;
      // if(res.works.length>0){
      //   res.works.forEach((n)=>{
      //     this.works.push(n);
      //   })
      // }
    }

    //通用技能
    // if(res.pro_skills){
    //    if(res.pro_skills.length>0){
    //     res.pro_skills.forEach((skills)=>{
    //       if(skills.contrast_list){
    //         if(skills.contrast_list.length>0){
    //           skills.contrast_list.forEach((skill)=>{
    //              this.pro_skills.push(skill.text);
    //            })
    //         }
    //       }
    //     })
    //    }
    // }

    //判断项目经验年份是否为至今
    if (res.project_experiences) {
      if (res.project_experiences.length > 0) {
        res.project_experiences.forEach((project) => {
          var endDate = new Date(project.end_date);
          if (endDate.getFullYear() == 2100) {
            project.isEndDate = true;
          }
          this.project.push(project);
        });
      }
    }
    //判断培训经历时间是否为至今
    if (res.Training_Experience) {
      if (res.Training_Experience.length > 0) {
        res.Training_Experience.forEach((project) => {
          var endDate = new Date(project.end_date);
          if (endDate.getFullYear() == 2100) {
            project.isEndDate = true;
          }
          this.project.push(project);
        });
      }
      //-----------截取项目经验
      // if(res.Training_Experience.length>1){
      //   res.Training_Experience=[];
      //   res.Training_Experience.push(this.project[0]);
      // }
    }

    //职位方向
    if (res.job_target) {
      if (res.job_target.career) {
        res.job_target.career.forEach((val) => {
          if (val.code) {
            this.careerTypeString.push(val.code);
          }
        });

      }
      res.job_target.work_city = this.splitObjectArrayToString(res.job_target.work_city, 'text', '，');
      res.job_target.industry = this.splitObjectArrayToString(res.job_target.industry, 'text', '，');
      res.job_target.career = this.splitObjectArrayToString(res.job_target.career, 'text', '，');
    }
    return res;
  }

  // 分割数组对象,获取数组各个对象中的某个对象的
  splitObjectArrayToString(objs, eleName, splitText) {
    let result = '';
    if (objs) {
      for (var i = 0, length = objs.length; i < length; i++) {
        if (i === (length - 1)) {
          splitText = '';
        }
        result += objs[i][eleName] + splitText;
      }
    }
    // //console.log(result);
    return result;
  };



  //收藏简历
  manageResumeArchived() {
    if (this.isCanFollow) {//防止重复点击
      return;
    }
    if (!this.resumeDetails.isfollow) {
      this.isCanFollow = true;
      console.log('收藏简历',this.notesModel.remark);
      this.dataApi.manageResumeArchived({
        resumeid: this.resumeDetails.resumeid
      }).then((res: any) => {
        //console.log(res);
        setTimeout(() => {
          this.resumeDetails.isfollow = true;
          this.isCanFollow = false;
          this.isShowContact = true;
          this.toastr.success('收藏成功');
        }, 600);

      }).catch((error) => {
        //console.log(error);
        setTimeout(() => {
          this.toastr.error(error);
          this.isCanFollow = false;
        }, 600);

      });
    } else {
      this.isCanFollow = true;
      this.dataApi.manageResumeArchivedDelete(this.resumeDetails.resumeid).then((res: any) => {
        //console.log(res);
        setTimeout(() => {
          this.isShowContact = true;
          this.toastr.success('取消收藏成功');
          this.resumeDetails.isfollow = false;
          this.isCanFollow = false;
        }, 600);

      }).catch((error) => {

        setTimeout(() => {
          this.toastr.error(error);
          this.isCanFollow = false;
        }, 600);

      });
    }
  }

  //试图销毁时,取消事件订阅
  ngOnDestory() {
    this.chatevent.unsubscribe();//更新本地会话存储,判断是否沟通过
  }

  //----------极速电话联系的方法
  //显示手机号弹窗
  ShowPhoneModel() {
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        this.productManager.getProductUsableCount(this.config.voice_call, (count, isVip) => {
          if (count == 0 || !isVip) {
            // this.config.payVipHistoryPage = {
            //   page:'简历详情',
            //   isVip:isVip,
            //   pro:'极速电话联系'
            // };
            // that.local.set("single_category_code",this.config.ProductListCode.voice_call_code);
            // that.router.navigate(['home/tool-campus']);
            this.product('voice_call', 'recharge');
          } else {
            try {
              this.resumeDetails['logo'] = this.resumeDetails.talent_base.logo;
              this.resumeDetails['gender'] = this.resumeDetails.talent_base.gender;
              this.resumeDetails['talent_name'] = this.resumeDetails.talent_base.name;
              this.resumeDetails['graduation_school'] = "";
              this.resumeDetails['major_status_text'] = "";
              this.resumeDetails['education_text'] = "";
              this.resumeDetails['talentid'] = this.resumeDetails.talent_base.accountid;
              if (this.resumeDetails.education_backgrounds.length > 0) {
                this.resumeDetails['graduation_school'] = this.resumeDetails.education_backgrounds[0].graduation_school;
                this.resumeDetails['major_text'] = this.resumeDetails.education_backgrounds[0].major_status_text;
                this.resumeDetails['education_text'] = this.resumeDetails.education_backgrounds[0].education_text;
              }
            } catch (e) {
            }
            this.voiceCallModel.initData(this.resumeDetails);
            this.isShowVoiceCallModel = true;
          }
        })
      } else {
        // that.opera.checkMemberAlertWithType("noneVip_voice_call")
        this.product('voice_call', 'recharge');
      }
    })
  }

  //获取用户信息
  getUser() {
    this.dataApi.getUser().then((res: any) => {
      if (res) {
        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
        this.phoneModel.myPhone = res.mobile;
        console.log('获取用户信息', res)
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  //处理初始化电话联系quota
  initPhoneData(deparment) {

    this.productManager.getProductUsableCount(this.config.voice_call, (count) => {
      this.phoneModel.MyPhoneTime = count;
    });
    this.productManager.getProductUsableCount(this.config.sms_bulkinvitation, (count) => {
      this.phoneModel.MyPushCount = count;
    });
  }
  updataMyPhone() {
    this.phoneModel.PhoneModelType = 0;
  }

  getProductList() {
    //product_category:"recharge",recharge_category:''
    this.opera.loading();
    this.dataApi.departmentList({ product_category: "recharge" }).then((res: any) => {
      this.opera.loadiss();
      if (res) {
        console.log('产品', res.list)

        for (let item of res.list) {
          if (item.recharge_category == "voice_call") {
            this.phoneModel.jiduCode = item.code;
          }
          if (item.recharge_category == "sms_bulkinvitation") {
            this.phoneModel.positionPushCode = item.code;
          }
        }
        console.log('极速电话联系', this.phoneModel.jiduCode)
      }
    }).catch((err) => {
      console.log(err);
      this.opera.loadiss();
    })



  }
  //确定拔打的手机//获取网络虚拟好
  checkMyPhone() {
    console.log("resumeDetails", this.resumeDetails)
    if (this.phoneModel.myPhone == '' && this.phoneModel.getPhoneType == '1') {
      this.toastr.warning("请输入手机号")
      return;
    }
    if (this.phoneModel.getPhoneType == '2') {
      if (this.phoneModel.myTel_qu == '') {
        this.toastr.warning("请输入座机区号")
        return;
      }
      if (this.phoneModel.myTel == '') {
        this.toastr.warning("请输入座机号码")
        return;
      }
    } else {
      if (!this.regexManager.isPhone(this.phoneModel.myPhone)) {
        this.toastr.warning("手机号格式错误，请重新填写")
        return;
      }
    }


    if (this.phoneModel.positionid == '') {
      this.toastr.warning("请选择职位")
      return;
    }
    let data = {
      mobile: this.phoneModel.getPhoneType == '1' ? this.phoneModel.myPhone : '',
      talentid: this.resumeDetails.talent_base.accountid,
      contact_type: this.phoneModel.getPhoneType == '1' ? 'mobile' : 'tel',
      tel: this.phoneModel.getPhoneType == '1' ? '' : this.phoneModel.myTel_qu + '' + this.phoneModel.myTel,
      positionid: this.phoneModel.positionid
    };
    // this.phoneModel.PhoneModelType = 1;
    // this.phoneModel.NetPhone = '999999999'
    this.dataApi.GetVirtualNumber(data).then((res: any) => {
      console.log("GetVirtualNumber", res);
      this.phoneModel.isOkmyPhone = true;
      this.phoneModel.PhoneModelType = 1;
      this.phoneModel.NetPhone = res.value;
      this.myDepartment.virtualnumber_mobile = this.phoneModel.myPhone;
      this.dataApi.setLocalDepartment(this.myDepartment);//将企业信息存到本地、全局变量

    }).catch((err) => {
      console.log("GetVirtualNumber", err);
      this.toastr.error(err)
      this.phoneModel.isShowPhoneModel = false;
    })

  }
  //购买电话时长
  gotoBuyPhoneTime() {
    this.phoneModel.isShowPhoneModel = false;
    let that = this;
    setTimeout(() => {
      //去购买极速电话
      that.local.set("recharge_category_code", JSON.stringify(this.phoneModel.jiduCode));
      // that.router.navigate(['home/buyProduct']);
      this.router.navigate(['home/tool-campus']);
    }, 600)
  }
  //单聊页面
  goContactChatBeforeSingle() {
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        console.log('剩余聊天次数', this.myDepartment.quota.dialogue);
        if (this.myDepartment.quota.dialogue <= 0) {
          this.toastr.warning('今天沟通已达上限，休息一下，明天继续吧');
          return
        }
        console.log("GetVirtualNumber", this.resumeDetails);
        if (this.resumeDetails.jm_username) {//判断是否需要扣除资源(会话权限,与下面的遍历可能有冲突,暂时不影响,后续优化)
          console.log("不需要获取极光资源------------------------homedetails", this.resumeDetails.jm_username);
          // console.log("保存用户聊天",this.resumeDetails.jm_username)
          this.local.set('userJmUsername', this.resumeDetails.jm_username)
          this.local.set('oldUrl', 'college-resumedetails')
          // console.log("保存用户聊天2",this.local.get('userJmUsername'))
          setTimeout(() => {
            MyMMessage.instance.events.publish("notificationClick");
            that.router.navigateByUrl('/home/chatSingle');
          }, 400)
        } else {
          if (!this.resumeDetails.talent_base.accountid) {
            this.toastr.error('获取简历信息失败')
            return
          }
          // if(!this.resumeDetails.talent_base.collegeid){
          //   this.toastr.error('获取简历信息失败')
          //   return
          // }
          this.dataApi.getChatIm({
            accountid: this.resumeDetails.talent_base.accountid,
            content: this.resumeDetails.talent_base.collegeid
          }).then((res: any) => {
            this.resumeDetails.jm_username = res.jm_username;
            console.log("不需要获取极光资源------------------------homedetails", this.resumeDetails.jm_username);
            this.local.set('userJmUsername', this.resumeDetails.jm_username)

            this.local.set('chat_back_router', 'college-resumedetails')
            this.local.set('oldUrl', 'college-resumedetails')
            setTimeout(() => {
              MyMMessage.instance.events.publish("notificationClick");
              that.router.navigateByUrl('/home/chatSingle');
            }, 400)

          }).catch((error: any) => {
            if (error.Errcode == '2002') {//未通过认证
              this.dataApi.checkDepCollegeIdentity(this.resumeDetails.talent_base.collegeid).then((res: any) => {
                //console.log("检测认证信息------");
                //console.log(res);
                if (res) {
                  if (res.status == 1) {
                    this.toastr.warning("您在" + this.resumeDetails.education_backgrounds[0].graduation_school + '的校招申请正在审核中，暂无法使用此功能');
                  } else {
                    this.opera.remindCollegeIdentity("与学生直聊", this.resumeDetails.talent_base.collegeid, res);//提醒认证
                  }
                } else {
                  this.opera.remindCollegeIdentity("与学生直聊", this.resumeDetails.talent_base.collegeid, res);//提醒认证
                }
              }).catch((err) => {
                this.toastr.error(err);
              })
            } else if (error.Errcode == '2001') {//50次配额超限
              this.opera.goCallChatEidentity();
            } else if (error.Errcode == '2005') {//100次配额超限
              this.toastr.warning('今天沟通已达上限，休息一下，明天继续吧');
            } else {
              this.toastr.error(error);
            }
          });
        }
      } else {
        // that.opera.checkMemberAlertWithType("noneVip_resumeChat")
        this.product('', 'contactMe');
      }
    })
  }

  //编辑用户标签的按钮
  editresumeLabelClick() {
    if (!this.resumeLabelDetail) {
      return;
    }
    console.log('resumeLabelDetail', this.resumeLabelDetail)
    this.resumeLabelModel.init(this.resumeLabelDetail);
    this.addResumeLabelmodelShow = true;
  }
  addResumeLabelmodeHidden(resume) {
    let that = this;
    this.addResumeLabelmodelShow = false;
    if (resume) {
      console.log('resume', resume,)
    }
    setTimeout(() => {
      this.getResumeLabelDetail(this.resumeDetails.resumeid, false);
      that.events.publish('updateResumeLabelNot'); //发送退出登录选项
    }, 1200)
  }

  getResumeLabelDetail(id, isShow) {
    this.dataApi.getResumeLabelDetail(id).then((res: any) => {
      console.log("getResumeLabelDetail", res);
      if (res) {
        this.resumeLabelDetail = res;
        if (this.resumeLabelDetail.lable == null) {
          this.resumeLabelDetail.lable = [];
        }
        if (isShow) {
          console.log('resumeLabelDetail', this.resumeLabelDetail)
          this.resumeLabelModel.init(this.resumeLabelDetail);
          this.addResumeLabelmodelShow = true;
        }
      }
    }).catch((err) => {
      console.log("getResumeLabelDetail", err);

    })
  }
  //获取简历的日志
  getResumeLog() {
    if (!this.resumeDetails) {
      return;
    }
    this.dataApi.getResumeLog(this.resumeDetails.resumeid, this.logmodel).then((res: any) => {
      console.log("getResumeLog", res);
      if (res) {
        this.ResumeLog = res.list;
        this.countList = res.count;
        this.pageCount = Math.ceil(res.count / 10);
      }
    }).catch((err) => {
      console.log("getResumeLog", err);
    })

  }
  shouCang(e) {
    e.stopPropagation();
    this.goCollect(this.resumeDetails)
  }
  //收藏
  goCollect(val) {
    let data = val;
    let that = this;
    data.isfollow_reusme = data.isfollow;
    console.log('简历详情收藏', data)
    data.isfollow = !data.isfollow

    if (!data.isfollow) {
      this.resumeLabelDetail = null;
    }
    val = this.opera.manageResumeArchived(data, () => {
      console.log('执行标签弹窗', data);
      setTimeout(() => {
        this.getResumeLabelDetail(this.resumeDetails.resumeid, true);
      }, 1200)
    });
    this.getNotes();
    setTimeout(() => {
      that.events.publish('updateResumeShouCangNot');
    }, 1200);

  }

  //获取分页子组件返回数据
  getChildEvent(index) {
    if (index > 0) {
      this.pageNumber = index;
      this.logmodel.offset = (this.pageNumber - 1) * 10;
      this.getResumeLog();//获取简历投递列表
    } else if (index == -1) {//上一页
      if (this.pageNumber == 1) {
        this.toastr.warning("当前为第一页");
      } else {
        this.pageNumber = this.pageNumber - 1;
        this.logmodel.offset = (this.pageNumber - 1) * 10;
        this.getResumeLog();//获取简历投递列表
      }
    } else if (index == -2) {//下一页
      if (this.pageNumber == this.pageCount) {
        this.toastr.warning("当前为最后一页");
      } else {
        this.pageNumber = this.pageNumber + 1;
        this.logmodel.offset = (this.pageNumber - 1) * 10;
        this.getResumeLog();//获取简历投递列表
      }
    }

  }
  //检查用户认证状态，认证通过+会员可以导出学历且学生是否投递，
  checkExportResume() {
    this.opera._hmtPush("简历管理", "导出简历", "")
    let that = this;
    if (this.resumeDetails.is_buy) {
      that.ExportResume();
    } else {
      this.opera.checkMember((isPay) => {
        console.log('checkExportResume', isPay);
        if (isPay) {
          that.ExportResume();
        }
        else {
          that.opera.checkMemberAlertWithType("noneVip_downResume")
        }
      })
    }
    //that.ExportResume();

  }

  //导出简历
  ExportResume() {
    this.dataApi.downLoadResume(this.resumeDetails.resume_number, this.resumeDetails.talent_base.name).then((res: any) => {
      if (res) {
        console.log('导出简历', res);
        this.toastr.success('导出成功，请到 浏览器-下载 查看')
      }
    }).catch((error) => {
      console.log(error);
      this.toastr.error('导出失败')
    });
  }
  //极速电话联系弹窗消失
  voiceCallModelHidden(e) {
    this.isShowVoiceCallModel = false;
  }
  //设置投递简历已读
  setDeliveryRead() {
    console.log('setDeliveryRead', this.resumeDetails.delivery_info)
    if (this.resumeDetails.delivery_info) {
      this.dataApi.setDeliveryRead(this.resumeDetails.delivery_info.deliveryid).then((res: any) => {
      }).catch((error) => { });
    }
  }

  productModelHidden(event) {
    this.isproductShowModel = false;
  }

  //点击产品
  product(alias, type) {
    this.productModel.initData(alias, type);
    this.isproductShowModel = true;
  };

  notesClick(){
    this.showNotes = true;
  };

  getNotes(){
    this.dataApi.getNotes(this.notesModel.resumeid).then((res: any) => {
      this.notesModel.remark = res.remark?res.remark:'';
    }).catch((err) => {
      console.log("notesClick", err);
    });
  };

  notesSave(){
    if (!this.notesModel.remark) {
      this.toastr.warning("请输入备注");
      return;
    };
    this.dataApi.upNotes(this.notesModel).then((res: any) => {
      this.showNotes = false;
    }).catch((err) => {
      console.log("notesClick", err);
    });
  };
}


