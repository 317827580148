<!-- 更换职位 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
    <div class="black-bg"></div>
    <div class="model-cont">
        <!-- 上层提示部分 -->
        <div class="model-head clear">
            <div class="left">请选择沟通职位 </div>
          <img (click)="dismiss(null)" style="width: 25px;height: 25px;float: right;margin-top: 13px"  src="../../assets/images/closeX.png">
        </div>
        <div class="position-list-cont">
          <button *ngFor="let item of config.positionList" (click)="dismiss(item)">
            <div class="position-list-block">
              <div class="up-block">
                <div class="left-block">{{item.name}}</div>
                <div class="right-block"><span class="urgent-color" *ngIf="item.is_urgent">急聘</span></div>
              </div>
              <div class="down-block">
                <div class="left-block">
                  {{item.position_salary_text}}&nbsp;&nbsp;{{item.work_place_text}}&nbsp;&nbsp;{{item.work_experience_text}}&nbsp;&nbsp;{{item.education_text}}
                  <br/>更新时间&nbsp;&nbsp;{{item.refresh_time | date:"yyyy.MM.dd"}}
                </div>
              </div>
            </div>
          </button>
        </div>
        <!-- 企业常见问题 -->
        <!-- <div *ngIf="title!='常见问题'" class="div-common">
            <div  *ngFor="let p of config.positionList" (click)="dismiss(p)" class="div-list">{{p.name}}</div>
        </div>   -->
    </div>
  </div>
