<!--收藏的-->

<div class="homepage-block">
    <div style="background-color:transparent" class="college-block">
        <router-outlet></router-outlet>
        <!-- 头部 -->
        <app-resume-label-model #resumeLabelModel [isShow]="addResumeLabelmodelShow" (modelHidden)="addResumeLabelmodeHidden($event)"></app-resume-label-model>
        <!-- 新版本 -->
        <div class="resume-container">
            <!-- 头部 -->
            <div class="header">
                <div class="secondNav">
                    <div class="item " (click)="nav01()">收到的</div>
                    <div class="item active">收藏的<span></span></div>
                    <div class="item" (click)="nav03()">极速电话联系记录</div>
                    <div class="item" (click)="nav04()">职位推送记录</div>
                    <div class="item" (click)="nav05()">校招活动邀请记录</div>
                    <div class="item" (click)="nav06()">空中宣讲会推广记录</div>
                </div>
                <div class="banner" style="margin-bottom:0">
                    <div class="layui-carousel" id="test1" *ngIf="bannerList.length>0">
                        <div carousel-item>
                            <div *ngFor="let n of bannerList;let i = index;">
                                <img class="swiper-slide bannerImage" (click)="bannerClick(n)" id={{i}} src="{{n.image_url}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 非会员 -->
                <div class="novipTip" *ngIf="!isShowMemberMoudel">
                    <div class="text"> 非会员收到的简历数量有限，加入会员，接收更多人才简历</div>
                    <div class="join joinHover" (click)="product('','contactMe')">加入会员</div>
                </div>
                <div class="search">
                    <select name="position" class="filtrate_row_select activeBg" id="position" [(ngModel)]="model.lable" (ngModelChange)="getSendResumeList()" autocomplete="off">
                        <option value=''>全部标签</option>
                        <option *ngFor="let a of LabelList" [value]="a.lableid">{{a.name}}</option>
                    </select>
                </div>
            </div>
            <div class="resume-list" *ngIf="countList>0">
                <div class="resume-list-item" *ngFor="let n of resumeList;let i = index;" (click)="goDetails(n)">
                    <div class="main">
                        <div class="introduce">
                            <div class="logo"><img *ngIf="n.logo" [src]="n.logo+'&imageView2/1/w/55/h/55'"></div>
                            <div class="text">
                                <div class="name">{{n.talent_name | clearSpace}}<span *ngIf="n.feedback_status == 'viewed' || n.feedback_status == 'interested' || n.feedback_status == 'improper'">已查看</span></div>
                                <div class="college" *ngIf='n.graduate_university'>{{n.graduate_university}}</div>
                                <div class="major">{{n.major_text}} | {{n.education_text}} | {{n.year==0?"往届":n.year+'届'}}</div>
                            </div>
                        </div>
                        <div class="lable">
                            <span *ngFor="let youshi of n.skills | slice:0:4;let i = index;">{{youshi.text}}</span>
                        </div>
                        <div class="intention">
                            <span>求职意向：</span>{{n.yixiang}}
                        </div>
                        <div class="bottom">
                            <span class="lable">备注</span>
                            <span class="label" *ngFor="let l of n.resume_labels;let i = index;">{{l.name}}</span>
                            <div class="push" (click)="editLabelClick($event,n)"><img src="/assets/images/rights/pushlabel.png">编辑</div>
                        </div>
                    </div>
                    <!-- 收藏 -->
                    <div class="collection">
                        <img (click)="shouCang($event,n)" *ngIf="n.isfollow_reusme" src="/assets/images/rights/Collection.png">
                        <img (click)="shouCang($event,n)" *ngIf="!n.isfollow_reusme" src="/assets/images/rights/noCollection.png">
                        <span (click)="shouCang($event,n)" style="color: #9A9A9A;font-size: 12px;cursor: pointer;margin-top: 5px">{{n.isfollow_reusme?'已收藏':'收藏'}}</span>
                    </div>
                </div>
            </div>
            <!-- 空状态 -->
            <div class="none-block-empty" *ngIf="resumeList.length == 0">
                <img src="assets/images/rights/empty.png">
                <p>暂未找到相关简历</p>
            </div>
            <!-- 分页部分 -->
            <div class='paging-block' *ngIf="countList>0">
                <router-outlet></router-outlet>
                <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
            </div>
        </div>



    </div>
</div>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>