<!-- v3.0 -->
<div class="schoolCloud">
    <app-model-college-identity [name]="collegeName" [orgList]="orgList" [item]="collegeIdentity" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-identity>
    <app-model-send-msg #sengMsgModel [SengMsgModel]='SengMsgModel' [tconfig]='config' (onVoted)="SendMsgdismiss($event)" [isShow]="modelShowSengMsg"></app-model-send-msg>
    <app-voice-call-model #voiceCallModel [isShow]="isShowVoiceCallModel" (modelHidden)="voiceCallModelHidden($event)">
    </app-voice-call-model>
    <div class="schoolHeader">
        <div class="center">
            <div class="goback" (click)="opera.goNewRouter('college-campustalk1')"><img src="assets/images/v3/headerReturnIcon.png">高校详情</div>
            <div class="top">
                <div class="logo">
                    <img *ngIf="college.logo" [src]="college.logo">
                    <img *ngIf="!college.logo" src='assets/images/none.png' />
                </div>
                <div class="text">
                    <div class="name">{{college.name}}</div>
                    <div class="time">{{college.school_type_text}}｜{{college.school_nature_text}}｜{{college.establish_time | date:"yyyy年MM月dd日"}}创办</div>
                    <div class="list">
                        <div class="item">学校代码：{{college.code?college.code:'暂无'}}</div>
                        <div class="item">联系邮箱：{{college.email?college.email:'暂无'}}</div>
                        <div class="item">学校地址：{{college.region_text?college.region_text:'暂无'}}</div>
                        <div class="item">就业电话：{{college.work_info.office_tel?college.work_info.office_tel:'暂无'}}</div>
                    </div>
                </div>
            </div>
            <div class="navList">
                <div class="item" (click)="opera.goNewRouter('college-details')">高校简介</div>
                <div class="item" (click)="goOrg()">教学机构</div>
                <div class="item " (click)="goMilk()">双选会</div>
                <div class="item" (click)="goCampus()">宣讲会</div>
                <div class="item active" (click)="goStudent()">生源速览<span></span></div>
                <div class="item" (click)="goNews()">新闻公告</div>
            </div>
        </div>

    </div>
    <div class="search">
        <select class="seleteCard" name="major" id="major" [(ngModel)]="model.majorOne" (ngModelChange)="majorOneChange()" autocomplete="off">
          <option class="place-text" value='' disabled hidden selected="selected">选择一级专业</option>
          <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
        </select>
        <select class="seleteCard" name="majorTwo" id="majorTwo" [(ngModel)]="model.major" (ngModelChange)="getResumeList()" *ngIf="model.majorOne" (click)="isShowTwo()" autocomplete="off">
          <option class="place-text" value='' disabled hidden selected="selected">选择二级专业</option>
          <option class="place-text" value='9999999' selected="selected">不限</option>
          <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
        </select>
        <select class="seleteCard" name="education" id="education" [(ngModel)]="model.education" (ngModelChange)="getResumeList()" autocomplete="off">
          <option class="place-text" value='' disabled hidden selected="selected">选择学历</option>
          <option class="place-text" value=''>不限</option>
          <option *ngFor="let a of educationList" [value]="a.code">{{a.text}}</option>
        </select>
    </div>
    <div class="resume-list">
        <div class="resume-list-item" *ngFor="let n of resumeList;let i = index;">
            <div class="main">
                <div class="collection" (click)="goCollect(n,$event)">
                    <img *ngIf="!n.isfollow_reusme" src="/assets/images/rights/noCollection.png">
                    <img *ngIf="n.isfollow_reusme" src="/assets/images/rights/Collection.png"> {{n.isfollow_reusme?'取消收藏':'收藏'}}
                </div>
                <div class="introduce">
                    <div class="logo">
                        <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/55/h/55'}}" />
                        <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png" />
                        <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png" />
                        <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png" />
                    </div>
                    <div class="text">
                        <div class="name">{{n.talent_name | clearSpace}}</div>
                        <div class="college">{{n.graduate_university}}</div>
                        <div class="major">{{n.major_text}} | {{n.education_text}} | {{n.year==0?"往届":n.year+'届'}}</div>
                    </div>
                </div>
                <div class="lable">
                    <span *ngFor="let skill of n.skills | slice:0:3;let i = index;">{{skill.text}}</span>
                </div>
                <div class="bottomBtns" *ngIf="!n.is_delivery">
                    <div class="btn" (click)="ShowPhoneModel(n,$event)">
                        <img src="/assets/images/v3/speedIcon.png"><span>极速电话联系</span>
                    </div>
                    <div class="btn active" (click)="alertSendMsg(n,$event)">
                        <img src="/assets/images/v3/pushIcon.png"><span>职位推送</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 没有内容存在时 -->
    <div *ngIf="countList==0" class="none-block">
        <img src="/assets/images/rights/empty.png">
        <p>暂未找到生源信息，请稍后重试</p>
    </div>
    <!-- 分页部分 -->
    <div class='paging-block' [class.isPageHeight]="countList>0">
        <router-outlet></router-outlet>
        <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
    </div>
</div>
<!-- 新增高校 -->
<app-push-college-model #pushCollegeModel [isShow]="isShowPushCollegeModel" (modelHidden)="pushCollegeModelHidden($event)"></app-push-college-model>
<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>

<!-- 页头部分 -->
<!-- <div class="contentRight-header"><img (click)="opera.goNewRouter('college-details')" src='assets/images/router-old.png'/>高校云<span *ngIf="!config.isByCollege"></span> - 学校详情 - 生源速览</div> -->
<!-- 主体内容部分 -->
<div class="homepage-block" style="display: none;">


    <div class="college-block" style="margin-bottom:20px;">
        <!-- 页头部分 -->
        <div class="college-block-head goBackHeader"><img class="goBack" (click)="opera.goNewRouter('college-details')" src='assets/images/router-old.png' />生源速览</div>
        <!-- <div class="college-block-head">
            生源速览
        </div> -->
        <!-- 详情模块 -->
        <div class="college-head-details clear" *ngIf="college">
            <!-- 左侧文字 -->
            <ul class="left">
                <li><span>学校名称：</span>{{college.name}}<i *ngIf="!college.name">暂无</i></li>
                <li><span>创办时间：</span>{{college.establish_time | date:"yyyy-MM-dd"}}<i *ngIf="!college.establish_time">暂无</i></li>
                <li><span>学校性质：</span>{{college.school_nature_text}}<i *ngIf="!college.school_nature_text">暂无</i></li>
                <li><span>联系邮箱：</span>{{college.email}}<i *ngIf="!college.email">暂无</i></li>
                <li><span>学校代码：</span>{{college.code}}<i *ngIf="!college.code">暂无</i></li>
                <li><span>所在城市：</span>{{college.region_text}}<i *ngIf="!college.region_text">暂无</i></li>
                <!-- <li><span>就业电话：</span>{{college.phone}}</li> -->
            </ul>
            <!-- 右侧logo -->
            <div class="right">
                <img *ngIf="college.logo" [src]="college.logo" alt="高校logo">
                <img *ngIf="!college.logo" src="assets/images/none.png" alt="高校logo">
            </div>
        </div>
    </div>
    <div class="college-block">
        <!-- 头部 -->
        <div class="college-resume-filter">



            <!-- 选择专业 -->
            <span>专业</span>
            <select name="major" id="major" [(ngModel)]="model.majorOne" (ngModelChange)="majorOneChange()" autocomplete="off">
            <option class="place-text" value='' disabled hidden selected="selected">选择一级专业</option>
            <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
        </select>
            <select name="majorTwo" id="majorTwo" [(ngModel)]="model.major" (ngModelChange)="getResumeList()" *ngIf="model.majorOne" (click)="isShowTwo()" autocomplete="off">
          <option class="place-text" value='' disabled hidden selected="selected">选择二级专业</option>
          <option class="place-text" value='9999999' selected="selected">不限</option>
          <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
        </select>
            <!-- 选择学历 -->
            <span>&nbsp;&nbsp;&nbsp;&nbsp;学历</span>
            <select name="education" id="education" [(ngModel)]="model.education" (ngModelChange)="getResumeList()" autocomplete="off">
            <option class="place-text" value='' disabled hidden selected="selected">选择学历</option>
            <option class="place-text" value=''>不限</option>
            <option *ngFor="let a of educationList" [value]="a.code">{{a.text}}</option>
        </select>
        </div>
        <div class="flex_row_left">
            <!--<button class="batchBtn" [class.purple1]="sendMsgArr.length>0" (click)="useBatchFn()">批量邀投简历</button>-->
            <!-- <button class="purple batchBtn" (click)="useBatchFn()">批量邀投简历</button> -->
        </div>
        <!-- 中间部分 -->
        <!-- 没有内容存在时 -->
        <div *ngIf="countList==0" class="none-block">
            <!--<p class="none_tip">申请本校合作通过，才可查看生源信息</p>-->
            <!--<span class="none_btn" (click)="goCollegeIdentity()">立即申请合作</span><br/>-->
            <img src="assets/images/resume-search-fail.png"><br/> 暂未找到生源信息，请稍后重试
        </div>
        <div *ngIf="countList>0" class="college-resume-cont">
            <ul>
                <li>头像</li>
                <li>姓名</li>
                <li>性别</li>
                <li>年龄</li>
                <li>学校</li>
                <li>专业</li>
                <li>学历</li>
                <li>技能标签</li>
                <!-- <li>匹配度</li>
            <li>在线状态</li> -->
                <li>操作</li>
            </ul>
            <div>
                <ul class="resume-li" *ngFor="let n of resumeList;let i = index;">
                    <li>
                        <input class="studentSelect" (click)="selectStudentFn(n)" [class.cur]="useBatch" [checked]="sendMsgArr.indexOf(n.talentid) != -1" type="checkbox" name="" id="">

                        <!-- <img *ngIf="n.logo" [src]="n.logo+'&imageView2/1/w/100/h/100'"><img *ngIf="!n.logo" src="assets/images/none.png"> -->
                        <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}">
                        <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png">
                        <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png">
                        <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png">
                    </li>
                    <li><span *ngIf='n.talent_name'>{{n.talent_name}}</span><span *ngIf='!n.talent_name'>暂无</span></li>
                    <li><span *ngIf='n.gender_text'>{{n.gender_text}}</span><span *ngIf='!n.gender_text'>暂无</span></li>
                    <li><span *ngIf='n.age'>{{n.age}}</span><span *ngIf='!n.age'>暂无</span></li>
                    <li><span *ngIf='n.graduate_university'>{{n.graduate_university}}</span><span *ngIf='!n.graduate_university'>暂无</span></li>
                    <li><span *ngIf='n.major_text'>{{n.major_text}}</span><span *ngIf='!n.major_text'>暂无</span></li>
                    <li><span *ngIf='n.education_text'>{{n.education_text}}</span><span *ngIf='!n.education_text'>暂无</span></li>
                    <li>
                        <span *ngIf='n.skills.length>0'>
                        <i *ngFor="let skill of n.skills">{{skill.text}}</i>
                    </span>
                        <span *ngIf='n.skills.length==0'>暂无</span>
                    </li>
                    <!-- <li><span *ngIf='n.matching'>{{n.matching}}%</span><span *ngIf='!n.matching'>暂无</span></li>
                <li><span *ngIf='n.last_online_time'>{{n.last_online_time | onlineTime}}</span><span *ngIf='!n.last_online_time'>暂无</span></li> -->
                    <li>
                        <span (click)="alertSendMsg(n)" *ngIf="opera.isShowPaySuccessModule1()">职位推送</span>

                        <span (click)="goDetails(n)">查看简历</span>
                        <!-- <span (click)="goChat(n,modelPosition.positionid)">立即沟通</span> -->
                        <!--<span (click)="goChat(n,'')">{{n.jm_username?'继续沟通':'在线直聊'}}</span>-->
                        <span (click)="goCollect(n)">{{n.isfollow_reusme?'取消收藏':'收藏'}}</span>
                        <!-- <span>分享</span> -->
                    </li>
                </ul>
            </div>
        </div>

    </div>
</div>