import { Component, OnInit, Input, Output } from '@angular/core';
import { DataApi,Config,Opera,Local,Constants } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from  '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
@Component({
  selector: 'app-position-rules-details',
  templateUrl: './position-rules-details.component.html',
  styleUrls: ['./position-rules-details.component.css']
})
export class PositionRulesDetailsComponent implements OnInit {
  public config: Config;
  public item:any;//文章详情
  public briefid='';//文章id
  public model={
    offset:0,
    limit:9
  }
  public collegeList = [];//高校列表
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(public dataApi: DataApi,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public local:Local,
    public opera:Opera,
    public dialog: MatDialog,
    public toastr: ToastrService) { 
      this.config = Config.getInstance();
    }

  ngOnInit() {
    this.opera.initConfig('position','position-rules');
    this.briefid=this.local.get(Constants.briefid);
    this.getRulesDetails();//获取详情
    this.getCollegeList();//获取学校列表
  }
  // 查看原因
  showConfirm(n) {
    this.opera.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '未通过原因',
        message: n.remark,
        trueText:'重新提交',
        falseText:'知道了'
      }
    }).afterClosed().subscribe((isConfirmed) => {
          //console.log('检测是否存在返回值');
          //console.log(isConfirmed);
          this.opera.scrollTrue();
          if(isConfirmed){
            this.goUpdateCollege(n);
          }
    });
  }
  //重新提交
  goUpdateCollege(n){
    this.opera.loading();
    console.log("重新提交");
    console.log(n);
    let model={
      briefid:this.briefid,
      collegeid:n.collegeid
    }
    this.dataApi.editorCollegeRules(model).then((res:any)=>{
      this.toastr.success("提交成功");
      this.getCollegeList();//获取学校列表
      this.opera.loadiss();  
    }).catch((err)=>{
      this.opera.loadiss(); 
      this.toastr.error("出现错误，请稍后重试");
    })
  }
  //修改
  goPublish(){
    this.local.set(Constants.briefDetais,JSON.stringify(this.item));
    this.router.navigateByUrl('/home/position-rules-editor');
  }
  //删除
  goDelete(){
    this.opera.loading();
    this.dataApi.deleteRules(this.briefid).then((res:any)=>{
      this.toastr.success("删除成功");
      this.router.navigateByUrl('/home/position-rules');
      this.opera.loadiss();  
    }).catch((err)=>{
      this.opera.loadiss(); 
      this.toastr.error("出现错误，请稍后重试");
    })
  } 
  //去高校主页
  goCollege(id){
    this.local.set(Constants.collegeid,id);
    this.router.navigateByUrl('/home/college-details');
  }
  //获取招聘简章详情
  getRulesDetails(){
    this.opera.loading();
    this.dataApi.getRulesDetails(this.briefid).then((res:any)=>{
      // console.log("获取招聘简章详情--");
      // console.log(res);
      this.item = res;
      this.opera.loadiss();  
    }).catch((err)=>{
      this.opera.loadiss(); 
      this.toastr.error("出现错误，请稍后重试");
      // this.navCtrl.pop();
    })
  }
  //获取学校列表
  getCollegeList(){
    this.opera.loading();
    this.dataApi.getRulesCollegeList(this.briefid,this.model).then((res:any)=>{
      // console.log("获取学校列表--");
      // console.log(res);
      this.collegeList = res.list;
      this.countList=res.count;
      this.pageCount =Math.ceil(res.count/9);
      this.opera.loadiss();  
    }).catch((err)=>{
      this.opera.loadiss(); 
      this.toastr.error("出现错误，请稍后重试");
      // this.navCtrl.pop();
    })
  }

  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数----');
    //console.log(index);
    if(index > 0){
        this.pageNumber = index;
        this.model.offset = (this.pageNumber-1)*9;
        this.getCollegeList();//获取列表
    }else if(index == -1){//上一页
        if(this.pageNumber == 1){
          this.toastr.warning("当前为第一页");
        }else{
          this.pageNumber = this.pageNumber - 1;
          this.model.offset = (this.pageNumber-1)*9;
          this.getCollegeList();//获取列表
        }
    }else if(index == -2){//下一页
        if(this.pageNumber == this.pageCount){
          this.toastr.warning("当前为最后一页");
        }else{
          this.pageNumber = this.pageNumber + 1;
          this.model.offset = (this.pageNumber-1)*9;
          this.getCollegeList();//获取列表
        }
    }
      
  }

  

}
