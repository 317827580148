<!--<ng-template  #customLoadingTemplate >-->
<!--<div class="custom-class-loading flex_c">-->
<!--<h3 >-->
<!--载入中...-->
<!--</h3 >-->
<!--</div >-->
<!--</ng-template >-->


<!-- 页头部分 -->
<ngx-loading [show]="config.loading"></ngx-loading>

<div class="custom-class-loading flex_c" *ngIf="config.loading">
    <h3>载入中...</h3>
</div>

<div class="top-header" *ngIf="config.isNavActive != 'login'" [class.nav_login_top]="config?.isNavActive == 'login'">
    <div class="_center">
        <div class="left">
            <!-- <img style="width: 200px;height: 40px;margin-left: 20px" src="../assets/images/left_logo.png"> -->
            <div class="navLogo"><img src="/assets/images/nav/navLogo.png"></div>
        </div>
        <!-- <div class="flex_row_left nav_top" *ngIf="config.childNavs.length > 0 && config?.isNavActive != 'login'">
            <span *ngFor="let nav of config?.childNavs" [class.navOn]="config?.isNavChildActive == nav.nav" (click)="goPage(nav.nav)">{{nav.text}}</span>
        </div> -->
        <!-- 登陆前 -->
        <div *ngIf="config.isLogin||config.isGuide" class="right login-right"><img src="assets/images/head-phone.png" />客服热线：400-661-1396</div>
        <!-- 登陆后 -->
        <ul *ngIf="!config.isLogin && !config.isGuide" class="right header-right">
            <li class="left" *ngIf="config.lastIdentityNavSatus==3" style="display: none;">
                <span (click)="gotoRenzheng()" class="nav_renz btnHover">审核未通过,点击查看</span>
            </li>
            <li class="left" *ngIf="config.lastIdentityNavSatus==0" style="display: none;">
                <span (click)="gotoRenzheng()" class="nav_renz btnHover">立即认证</span>
            </li>

            <li class="left orderUnPay" style="display: flex;align-items: center;margin-right: 15px;height: 50px;display: none;" *ngIf="config.isNavActive != 'login'&& config.is_frozen==false">
                <div class="navOrder">
                    <span class="orderCount animated swing" *ngIf="config.ordertoPayCount>0">{{config.ordertoPayCount}}</span>
                </div>
                <div class="orderList">
                    <div (click)="orderClick(1)" style="border-bottom: 1px rgba(0, 0, 0, 0.09) solid">订单 <span class="orderList_red" *ngIf="config.ordertoPayCount>0">{{config.ordertoPayCount}}</span></div>
                    <div (click)="orderClick(2)">发票</div>
                </div>
            </li>
            <li class="left">
                <div class="comHome" (click)="goNotification()">通知<span *ngIf="config.tasktodo">{{config.tasktodo>=100?'99+':config.tasktodo}}</span></div>
            </li>
            <li class="left">
                <div class="comHome" (click)="goComHome()">公司主页</div>
            </li>
            <li class="left customerService" *ngIf="config?.departmentSelf?.avatar">
                <div class="comHome">专属校招顾问</div>
                <div class="carShow">
                    <div class="user">
                        <div class="portrait"><img src="{{config.departmentSelf.avatar}}"></div>
                        <div>
                            <div class="name">{{config.departmentSelf.name}}</div>
                            <div class="text">专属校招顾问</div>
                        </div>
                    </div>
                    <div class="cell"><img src="/assets/images/v3/header-mobile.png">{{config.departmentSelf.mobile}}</div>
                    <div class="cell"><img src="/assets/images/v3/header-mail.png">{{config.departmentSelf.email}}</div>
                    <div class="serviceCode"><img src="{{config.departmentSelf.qr_code}}"></div>
                    <p>微信扫码联系客服</p>
                    <!-- <img class="left_right_bottom" src="/assets/images/v3/left_right_bottom.png"> -->
                </div>
            </li>
            <li class="left user-left" style="display: flex;align-items: center" *ngIf="config.isNavActive != 'login'">

                <img class="avatar-img" *ngIf="config?.loginUser?.avatar" src="{{config.loginUser?.avatar}}" />
                <span *ngIf="config?.loginUser" class="name">{{config.loginUser.name}}</span>
                <img class="header-down" src="assets/images/down-gray.png" />
                <!-- hover展示选项卡 -->
                <div class="user-block">
                    <div class="user-list" (click)="goAccount()"><img class="headerIcon" src="/assets/images/v3/headerIcon01.png">个人中心</div>
                    <div class="user-list">
                        <a id="addFavorite" rel="sidebar" (click)="addFavorite('www.baidu.com','加入收藏')"><img class="headerIcon" src="/assets/images/v3/headerIcon02.png">加入收藏</a>
                    </div>
                    <div class="user-list" (click)="goLogin()"><img class="headerIcon" src="/assets/images/v3/headerIcon03.png">登录退出</div>
                </div>
            </li>

        </ul>
    </div>


</div>

<div class="app-content">
    <router-outlet></router-outlet>
</div>