<!-- 页头部分 -->
<!-- <div class="contentRight-header">消息提醒 - 在线直聊</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <router-outlet></router-outlet>
  <app-model-chat-common  [isShow]="modelShow" [title]="commonText" (onVoted)="Modeldismiss($event)"></app-model-chat-common>
  <app-model-chat-position  [isShow]="modelTwoShow" [initValue]="position" (onVoted)="ModelTwodismiss($event)"></app-model-chat-position>
  <app-model-chat-position-first [isShow]="modelFirstChatShow" [initValue]="position" (onVoted)="modelFirstChatDismiss($event)"></app-model-chat-position-first>
  <app-model-chat-report  [isShow]="modelFourShow" (onVoted)="ModelFourdismiss($event)"></app-model-chat-report>
  <app-model-position-notice #notice  [isShow]="modelThreeShow" (onVoted)="ModelThreedismiss($event)"></app-model-position-notice>
  <app-model-position-hired #hiredChat  [isShow]="modelFiveShow" (onVoted)="ModelFivedismiss($event)"></app-model-position-hired>

  <app-model-send-msg #sengMsgModel [SengMsgModel] = 'SengMsgModel' [tconfig] = 'config' (onVoted)="SendMsgdismiss($event)"  [isShow]="modelShowSengMsg"></app-model-send-msg>

  <div class="container">
    <div class="gobackbtn" (click)="opera.goPre()">
      <img src='assets/images/router-old.png'/>
      <span>返回</span>
    </div>
    <!-- 左边部分（会话列表） -->
    <div class="left left-block-all">
      <div class="left-head">
        <div class="left-head-title"> 联系人</div>
        <div class="left-head-fiflter">
          <!-- 类别 -->
          <select [(ngModel)]="listModel.account_type" (ngModelChange)="getChatFiflterList()" autocomplete="off">
            <option class="place-text" value='' disabled hidden selected="selected">选择类别</option>
            <option class="place-text" value='' selected="selected">全部</option>
            <option  value="talent">学生</option>
            <option  value="official_account">高校</option>
            <!-- <option  value="">小秘书</option> -->
          </select>
          <!-- 职位 -->
          <select [(ngModel)]="listModel.positionid" (ngModelChange)="getChatFiflterList()" autocomplete="off">
              <option class="place-text" value='' disabled hidden selected="selected">选择职位</option>
              <option class="place-text" value='' selected="selected">全部</option>
              <option *ngFor="let a of config.positionList" [value]="a.positionid">{{a.name}}</option>
          </select>
            <!-- 状态 -->
          <select [(ngModel)]="isRead" (ngModelChange)="getByRead()" autocomplete="off">
              <option class="place-text" value='' disabled hidden selected="selected">选择状态</option>
              <option class="place-text" value='' selected="selected">全部</option>
              <option value='false'>未读</option>
              <option value='true'>已读</option>
          </select>
        </div>
      </div>
      <!-- 不存在聊天记录 -->
      <ul class="left-ul" *ngIf="config.chatsList.list.length==0">
        <div class="none-block">
          <img src="assets/images/resume-search-fail.png"><br/>
          暂未找到相关会话
        </div>
      </ul>
      <!-- 存在会话记录 -->
      <ul class="left-ul" *ngIf="config.chatsList.list.length>0">
        <li [class.chatlist-active]="chat.isGoChat"  *ngFor="let chat of config.chatsList.list" (click)="goContactChat(chat)">
          <div class="chatList-left" *ngIf="chat.dialogue_user">
              <img *ngIf="chat.dialogue_user.logo" [src]="chat.dialogue_user.logo+'&imageView2/1/w/100/h/100'">
            <img *ngIf="!chat.dialogue_user.logo && chat.dialogue_user.gender=='02'" src="assets/images/female.png">
            <img *ngIf="!chat.dialogue_user.logo && chat.dialogue_user.gender !='02'" src="assets/images/male.png">

              <span *ngIf="chat.unread_count > 0"></span><!--{{chat.unread_count}}-->
          </div>
          <div class="chatList-right" *ngIf="chat.dialogue_user">
             <div class="chatlist-rightTop">
               <div class="left">{{chat.dialogue_user.name}}</div>
               <div class="right">{{chat.last_message_time | contimetransform}}</div>
             </div>
             <div [class.topActive]="chat.is_top" class="chatlist-rightBot" *ngIf="chat.last_message_type == 'text' || chat.last_message_type == 'ask_for_resume'">{{chat.last_message}}</div>
             <div class="chatlist-rightBot" *ngIf="chat.last_message_type == 'resume'">简历消息</div>
             <div class="chatlist-rightBot" *ngIf="chat.last_message_type == 'wechat'">微信号</div>
             <div class="chatlist-rightBot" *ngIf="chat.last_message_type == 'position'">职位消息</div>
             <div class="chatlist-rightBot" *ngIf="chat.last_message_type == 'invitation'">面试邀请</div>
             <div class="chatlist-rightBot" *ngIf="chat.last_message_type == 'reply'">面试邀请回复</div>
             <div class="chatlist-rightBot" *ngIf="chat.last_message_type == 'talent'">投递职位消息</div>
             <div class="chatlist-rightBot" *ngIf="chat.last_message_type == 'image'">图片消息</div>
             <div class="chatlist-rightBot" *ngIf="chat.last_message_type == 'multitext'">通知消息</div>
             <div class="chatlist-rightBot" *ngIf="chat.last_message_type == 'offer'">录用通知</div>
             <div class="chatlist-rightBot" *ngIf="chat.last_message_type == 'offer_reply'">录用通知回复</div>
             <div class="chatlist-rightBot" *ngIf="chat.last_message_type == 'shield'">屏蔽消息</div>
             <div class="chatlist-rightBot" *ngIf="chat.last_message_type == 'inappropriate'">不合适消息</div>
             <img *ngIf="chat.is_top" src="assets/images/contact-top.png">
          </div>
        </li>
      </ul>
    </div>
    <!-- 右边部分（对话框） -->
    <div class="right">
      <!-- 聊天模块页头部分 -->
      <div class="chat-name" *ngIf="userInfo">
        <div class="left">
            <div class="chatList-left" (click)="goResumeDetails(userInfo.resume_number)" style="cursor: pointer">
                <img *ngIf="userInfo.logo" src="{{userInfo.logo+'&imageView2/1/w/100/h/100'}}">
              <img *ngIf="!userInfo.logo &&userInfo.gender=='02'" src="assets/images/female.png">
              <img *ngIf="!userInfo.logo &&userInfo.gender!='02'" src="assets/images/male.png">
            </div>
            <div class="chatList-title">
              <div class="chatList-name">{{userInfo.name}}</div>
              <div class="chatList-text" *ngIf="this.chatsInfo&&(chatsInfo.dialogue_account_type=='talent')">
                  <span>{{userInfo.graduate_university}}</span>
                  <span>{{userInfo.major_text}}</span>
              </div>
            </div>

        </div>
        <div *ngIf="chatsInfo&&(chatsInfo.dialogue_account_type=='talent')" class="right" (mouseenter)="isShowManage=true" (mouseleave)="isShowManage=false">
          <img src="assets/images/contact-more.png">
          <!-- 弹出框 -->
          <div *ngIf="isShowManage" class="resume-modal-cont">
              <div class="modal-cont">
                <div class="text-block">
                    <div class="modal-identity" (click)="goResumeDetails(userInfo.resume_number)">
                        <img src="assets/images/contact-look.png">
                        <span>查看简历</span>
                    </div>
                  <div class="modal-identity" (click)="setIMtop()">
                    <img src="assets/images/contact-top.png">
                    <span>{{chatsInfo.is_top?'取消置顶':'置顶当前对话'}}</span>
                  </div>
                  <!-- <div class="modal-identity" (click)="goDetails()">
                  <img src="assets/images/contact-glasses.png">
                    <span>查看资料</span>
                  </div> -->
                  <div class="modal-identity" (click)="setIMinappropriate()">
                    <img src="assets/images/contact-sign.png">
                    <span>	{{chatsInfo.inappropriate?'取消不合适':'标记为不合适'}}</span>
                  </div>
                  <div class="modal-identity" (click)="setIMshield()">
                    <img src="assets/images/contact-shield.png">
                    <span>{{chatsInfo.is_active_shield?'取消屏蔽':'屏蔽消息'}}</span>
                  </div>
                  <div class="modal-identity" (click)="chooseMsgImg()">
                    <img src="assets/images/contact-report.png">
                    <span> 举报</span>
                  </div>
                </div>
              </div>
          </div><!-- 弹出框结束 -->
        </div>

      </div>
      <!-- 消息部分（没消息） -->
      <div class="none-cont body-height" *ngIf="!chatsInfo && !modelFirstChatShow">
         请从左侧选择联系人开始聊天
      </div>
      <!-- 不合适消息 -->
      <div *ngIf="chatsInfo">
          <div *ngIf="chatsInfo.inappropriate" class="contact-remind">
              <img src="assets/images/contact-sign2.png">
               已标记为不合适，将不再提醒TA的消息
              <span style="color: #b765ff;cursor: pointer;" (click)="setIMinappropriate()">撤回标记</span>
          </div>
      </div>
      <!-- 消息部分（没有消息） -->
      <div class="chat-body body-height" *ngIf="userInfo&&msgList.length==0">
        <div class="none-block">
          <img src="assets/images/resume-search-fail.png"><br/>
          暂未找到聊天记录
        </div>
      </div>
      <!-- 消息部分(有消息) -->
      <div #scrollMe (scroll)="contentScroll($event)" *ngIf="msgList.length>0" class="chat-body" [class.hide-height]="chatsInfo?.is_active_shield && chatsInfo?.inappropriate" [class.body-height]="!chatsInfo?.is_active_shield && !chatsInfo?.inappropriate&&(chatsInfo?.dialogue_account_type=='talent' ||userJmUsername=='JuniorSecretaryD')" [class.body-shield]="!chatsInfo?.is_active_shield&&(chatsInfo?.dialogue_account_type=='talent')" [class.body-inappropriate]="!chatsInfo?.inappropriate&&(chatsInfo?.dialogue_account_type=='talent')">
        <div *ngFor="let msg of msgList">
          <!-- 时间提醒 -->
          <div *ngIf="msg.isNoneTime" class="time-remind"><span>{{msg.createTime | timetransform}}</span></div>
          <!-- 职位信息 -->
          <div *ngIf="msg.type=='position'" >
            <div class="position-details"><b></b>您正在沟通如下职位<b></b></div>
            <div class="position-list-block" (click)="goPositionDetails(msg.content)">
              <div class="up-block">
                <div class="left-block">
                  <div class="name-text">{{msg.content.name}}
                    <span *ngIf="msg.content.position_salary_text">{{msg.content.position_salary_text}}</span>
                    <span *ngIf="!msg.content.position_salary_text">面议</span>
                  </div>
                  <div class="info-text">
                    <span *ngIf="msg.content.work_place_text"><img src="assets/images/position-address.png">{{msg.content.work_place_text}}</span>
                    <span *ngIf="msg.content.post_nature_text"><img src="assets/images/position-work.png">{{msg.content.post_nature_text}}</span>
                    <span *ngIf="msg.content.education_text"><img src="assets/images/position-aducation.png">{{msg.content.education_text}}</span>
                  </div>
                </div>
                <div class="right-block position-color">沟通中</div>
              </div>
            </div>
          </div>
          <!-- offer信息 -->
          <div *ngIf="msg.type=='offer'" class="chat-cont" [class.my-chat-cont]="msg.from==myDepartment.jm_username">
            <div class="user-pic" *ngIf="msg.from==myDepartment.jm_username">
              <img *ngIf="myUser.avatar" src="{{myUser.avatar+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!myUser.avatar" src="assets/images/male.png"/>
            </div>
            <div class="user-text">
              <img src="assets/images/contact-hired.png">
              <span>录用通知</span>
              <br *ngIf="msg.content.time"/>
              <span *ngIf="msg.content.time">{{msg.content.time | date:"yyyy-MM-dd HH:mm"}}</span>
              <br *ngIf="msg.content.location"/>
              <span *ngIf="msg.content.location">{{msg.content.location}}</span>
            </div>
          </div>
          <!-- 多媒体消息-->
          <div *ngIf="msg.type=='multitext'" class="chat-cont"  [class.my-chat-cont]="msg.from==myDepartment.jm_username">
            <div class="user-pic" *ngIf="msg.from!=myDepartment.jm_username">
              <img *ngIf="userInfo.logo" src="{{userInfo.logo+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!userInfo.logo" src="assets/images/male.png"/>
            </div>
            <div (click)="goMultitext(msg)" style="cursor: pointer;max-width:300px;width: 60%;" class="user-text">
              <img *ngIf="msg.content.img_url" style="height:auto;width: 100%;display: block;" [src]="msg.content.img_url">
              <!--<img *ngIf="!msg.content.img_url&&msg.content.message_type=='milkround'" src="assets/images/milkround-bg.png" alt="双选会">-->
              <p *ngIf="msg.content.title&&msg.content.message_type!='milkround'">{{msg.content.title}}</p>
              <span *ngIf="msg.content.content">{{msg.content.content}}</span>
              <span *ngIf="!msg.content.message_type&&msg.content">{{msg.content}}</span>
            </div>
          </div>
          <!-- 索要简历 -->
          <div *ngIf="msg.type=='ask_for_resume'" class="chat-cont" [class.my-chat-cont]="msg.from==myDepartment.jm_username">
            <div class="user-pic" *ngIf="msg.from!=myDepartment.jm_username">
              <img *ngIf="userInfo.logo" src="{{userInfo.logo+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!userInfo.logo" src="assets/images/male.png"/>
            </div>
            <div class="user-pic" *ngIf="msg.from==myDepartment.jm_username">
              <img *ngIf="myUser.avatar" src="{{myUser.avatar+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!myUser.avatar" src="assets/images/male.png"/>
            </div>
            <div class="user-text">{{msg.content}}</div>
            <!--<div *ngIf="config.department.member_info || (config.lastIdentity.status == 2 && isAdoptC)" class="time-msg"><span class="time-span">还在等学生回复，快试试<a (click)="sengMsgAlert()" style="color: #5d479a" >【极速短信联系】</a></span></div>-->
          </div>
          <!-- 文字对话 -->
          <div *ngIf="msg.type=='text'" class="chat-cont" [class.my-chat-cont]="msg.from==myDepartment.jm_username">
            <div class="user-pic" *ngIf="msg.from!=myDepartment.jm_username">
              <img *ngIf="userInfo.logo" src="{{userInfo.logo+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!userInfo.logo" src="assets/images/male.png"/>
            </div>
            <div class="user-pic" *ngIf="msg.from==myDepartment.jm_username">
              <img *ngIf="myUser.avatar" src="{{myUser.avatar+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!myUser.avatar" src="assets/images/male.png"/>
            </div>
            <div class="user-text">{{msg.content}}</div>
           <br/>
          </div>

          <!-- 图片对话 -->
          <div *ngIf="msg.type=='image'" class="chat-cont" [class.my-chat-cont]="msg.from==myDepartment.jm_username">
            <div class="user-pic" *ngIf="msg.from!=myDepartment.jm_username">
              <img *ngIf="userInfo.logo" src="{{userInfo.logo+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!userInfo.logo" src="assets/images/male.png"/>
            </div>
            <div class="user-pic" *ngIf="msg.from==myDepartment.jm_username">
              <img *ngIf="myUser.avatar" src="{{myUser.avatar+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!myUser.avatar" src="assets/images/male.png"/>
            </div>
            <div class="user-text"><img style="width:100%;" [src]="msg.content"></div>
          </div>

          <!-- 双选会确认 -->
          <div *ngIf="msg.type=='milkround_feedback'" class="chat-cont" [class.my-chat-cont]="msg.from==myDepartment.jm_username">
            <div class="user-pic" *ngIf="msg.from!=myDepartment.jm_username">
              <img *ngIf="userInfo.logo" src="{{userInfo.logo+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!userInfo.logo" src="assets/images/male.png"/>
            </div>
            <div class="user-pic" *ngIf="msg.from==myDepartment.jm_username">
              <img *ngIf="myUser.avatar" src="{{myUser.avatar+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!myUser.avatar" src="assets/images/male.png"/>
            </div>
            <div class="milkRoundBox" >
              <p style="width: 100%;text-align: center;margin-top: 30px;font-size: 18px">双选会确认函</p>
              <p style="margin-left: 15px;margin-right:15px;text-align: center;margin-top: 20px;">{{msg.content.content}}</p>
              <div style="border-bottom: 1px #ccc solid;margin-left: 15px;margin-right: 15px;height: 3px;margin-top: 60px"></div>
              <p *ngIf="msg.state==0" (click)="ConfirmMilkroundCommit('join',msg)" style="width: 100px;line-height: 30px;margin-top: 15px;background-color: #5d479a;color: white;text-align: center;float: left;margin-left: 80px">参加</p>
              <p *ngIf="msg.state==0" (click)="ConfirmMilkroundCommit('no_join',msg)" style="width: 100px;line-height: 30px;margin-top: 15px;background-color: gray;color: white;text-align: center;float: right;margin-right: 80px">不参加</p>
              <p *ngIf="msg.state==1" style="width: 100px;line-height: 30px;margin-top: 15px;background-color: #5d479a;color: white;text-align: center;float: right;margin-right: 150px">已确认参加</p>
              <p *ngIf="msg.state==2" style="width: 100px;line-height: 30px;margin-top: 15px;background-color: #5d479a;color: white;text-align: center;float: right;margin-right: 150px">已拒绝参加</p>
            </div>
          </div>

          <!-- 微信信息 -->
          <div *ngIf="msg.type=='wechat'" class="chat-cont" [class.my-chat-cont]="msg.from==myDepartment.jm_username">
            <div class="user-pic" *ngIf="msg.from!=myDepartment.jm_username">
              <img *ngIf="userInfo.logo" src="{{userInfo.logo+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!userInfo.logo" src="assets/images/male.png"/>
            </div>
            <div class="user-pic" *ngIf="msg.from==myDepartment.jm_username">
              <img *ngIf="myUser.avatar" src="{{myUser.avatar+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!myUser.avatar" src="assets/images/male.png"/>
            </div>
            <div class="user-text"><img src="assets/images/wechat.png">这是我的微信号：{{msg.content}}</div>
          </div>

          <!-- 邀请面试 -->
          <div *ngIf="msg.type=='invitation'" class="chat-cont" [class.my-chat-cont]="msg.from==myDepartment.jm_username">
            <div class="user-pic" *ngIf="msg.from!=myDepartment.jm_username">
              <img *ngIf="userInfo.logo" src="{{userInfo.logo+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!userInfo.logo" src="assets/images/male.png"/>
            </div>
            <div class="user-pic" *ngIf="msg.from==myDepartment.jm_username">
              <img *ngIf="myUser.avatar" src="{{myUser.avatar+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!myUser.avatar" src="assets/images/male.png"/>
            </div>
            <div class="user-text invit-text" style="cursor: pointer;" (click)="goModalResumeNotice()">
              <div>
                <!-- <div class="left-invit"></div>
                <div class="right-invit"></div> -->
                <img src="assets/images/contact-interview.png" />
                <span *ngIf="msg.content.status=='to_be_accepted'">面试邀请</span>
                <span *ngIf="msg.content.status=='department_canceled'">面试邀请已取消</span>
                <span *ngIf="msg.content.status=='improper'">设置为不合适</span>
                <span *ngIf="msg.content.status=='hired'">面试已录用</span>
                <span *ngIf="msg.content.status=='miss'">设置为对方爽约</span>
                <span *ngIf="msg.content.status=='handle_interview'">设置为待处理</span>
                <br *ngIf="msg.content.time"/>
                <span *ngIf="msg.content.time">{{msg.content.time | date:"yyyy-MM-dd HH:mm"}}</span>
                <br *ngIf="msg.content.location"/>
                <span *ngIf="msg.content.location">{{msg.content.location}}</span>
              </div>
            </div>
          </div>

          <!--投递职位信息-->
          <div *ngIf="msg.type=='talent'" (click)="goTanlentDetails(msg.content)">
            <div class="position-details"> TA的简历</div>
            <div class="position-list-block">
              <div class="up-block">
                <div class="left-block">
                  <div class="name-text">{{msg.content.name}}
                    <span *ngIf="msg.content.expect_salary_text">{{msg.content.expect_salary_text}}</span>
                    <span *ngIf="!msg.content.expect_salary_text">面议</span>
                  </div>
                  <div class="info-text">
                    <span *ngIf="msg.content.major_status_text">{{msg.content.major_status_text}}&nbsp;&nbsp;</span>
                    <span *ngIf="msg.content.education_text">{{msg.content.education_text}}</span>
                    <br/>
                    <span *ngIf="msg.content.school">{{msg.content.school}}</span>
                    <span *ngIf="!msg.content.school">未知</span>
                  </div>
                </div>
                <div class="right-block">
                  <img *ngIf="userInfo.logo" src="{{userInfo.logo+'&imageView2/1/w/100/h/100'}}"/>
                  <img *ngIf="!userInfo.logo" src="assets/images/male.png"/>
                </div>
              </div>
            </div>
          </div>

          <!-- 人才反馈消息 -->
          <div *ngIf="msg.type=='reply'" class="chat-cont" [class.my-chat-cont]="msg.from==myDepartment.jm_username">
            <!-- 用户存储 -->
            <div class="user-pic" *ngIf="msg.from!=myDepartment.jm_username">
              <img *ngIf="userInfo.logo" src="{{userInfo.logo+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!userInfo.logo" src="assets/images/male.png"/>
            </div>
            <div class="user-pic" *ngIf="msg.from==myDepartment.jm_username">
              <img *ngIf="myUser.avatar" src="{{myUser.avatar+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!myUser.avatar" src="assets/images/male.png"/>
            </div>
            <!-- 消息内容 -->
            <div class="user-text" *ngIf="msg.content=='0'">
              <div>
                <span>拒绝了面试</span>
                <img style="margin: 0 0 5px 3px;" src="assets/images/contact-refuse.png">
              </div>
            </div>
            <div class="user-text" *ngIf="msg.content=='1'">
              <div>
                <span>接受了面试</span>
                <img style="margin: 0 0 5px 3px;" src="assets/images/contact-sure.png">
              </div>
            </div>
            <div class="user-text" *ngIf="msg.content=='2'">
              <div>
                <span>取消了面试</span>
                <img style="margin: 0 0 5px 3px;" src="assets/images/contact-cancel.png">
              </div>
            </div>
          </div>
          <!-- 录用通知反馈消息 -->
          <div *ngIf="msg.type=='offer_reply'" class="chat-cont" [class.my-chat-cont]="msg.from==myDepartment.jm_username">
            <!-- 用户存储 -->
            <div class="user-pic" *ngIf="msg.from!=myDepartment.jm_username">
              <img *ngIf="userInfo.logo" src="{{userInfo.logo+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!userInfo.logo" src="assets/images/male.png"/>
            </div>
            <div class="user-pic" *ngIf="msg.from==myDepartment.jm_username">
              <img *ngIf="myUser.avatar" src="{{myUser.avatar+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!myUser.avatar" src="assets/images/male.png"/>
            </div>
            <!-- 录用通知反馈 -->
            <div class="user-text" *ngIf="msg.content=='true'">
              <div>
                <span>对方接受了录用通知</span>
                <img style="margin: 0 0 5px 3px;" src="assets/images/contact-sure.png">
              </div>
            </div>
            <div class="user-text" *ngIf="msg.content=='false'">
              <div class="invitation-interview">
                <span>对方拒绝了录用通知</span>
                <img style="margin: 0 0 5px 3px;" src="assets/images/contact-refuse.png">
              </div>
            </div>
          </div>

          <!-- 简历消息 -->
          <div *ngIf="msg.type=='resume'" class="chat-cont" [class.my-chat-cont]="msg.from==myDepartment.jm_username" >
            <div class="user-pic" *ngIf="msg.from!=myDepartment.jm_username">
              <img *ngIf="userInfo.logo" src="{{userInfo.logo+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!userInfo.logo" src="assets/images/male.png"/>
            </div>
            <div class="user-pic" *ngIf="msg.from==myDepartment.jm_username">
              <img *ngIf="myUser.avatar" src="{{myUser.avatar+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!myUser.avatar" src="assets/images/male.png"/>
            </div>
            <div class="user-text" style="cursor: pointer;" (click)="goResumeDetails(msg.content)">
              <div>
                <span>这是我的简历，请您查看</span>
                <img style="margin: 0 0 5px 3px;" src="assets/images/contact-resume.png">
              </div>
            </div>
            <div class="resumeTips" *ngIf="!config.department.member_info" (click)="throwMail()">开通默认投邮箱？简历不在错过</div>
            <div class="resumeTips" *ngIf="config.department.member_info && resumt_to_email">简历已同步到邮箱</div>
          </div>

                  <!-- 外链 -->
        <div *ngIf="msg.type=='web_url'" class="chat-cont" [class.my-chat-cont]="msg.from==config.department.jm_username">
          <div class="user-pic" (click)="goTalentDetails(ResumeNumberId)" *ngIf="msg.from!=config.department.jm_username">
              <!-- <img *ngIf="userInfo.logo" src="{{userInfo.logo+'&imageView2/1/w/100/h/100'}}"/>
              <img *ngIf="!userInfo.logo" src="assets/images/male.png"/> -->
              <img *ngIf="userInfo.logo" src="{{userInfo.logo+'&imageView2/1/w/100/h/100'}}">
              <img *ngIf="!userInfo.logo&&(userInfo.gender=='01')" src="assets/images/male.png">
              <img *ngIf="!userInfo.logo&&(userInfo.gender=='02')" src="assets/images/female.png">
              <img *ngIf="!userInfo.logo&&!userInfo.gender" src="assets/images/male.png">
          </div>
          <div class="user-pic" *ngIf="msg.from==config.department.jm_username">
              <img *ngIf="config.loginUser.avatar" [src]="config.loginUser.avatar+'&imageView2/1/w/100/h/100'">
              <img *ngIf="!config.loginUser.avatar&&(config.loginUser.gender=='01')" src="assets/images/male.png">
              <img *ngIf="!config.loginUser.avatar&&(config.loginUser.gender=='02')" src="assets/images/female.png">
              <img *ngIf="!config.loginUser.avatar&&!config.loginUser.gender" src="assets/images/male.png">
          </div>
          <!--<div class="user-web_url" (click)="openOutUrl(msg.content.url)">{{msg?.content?.title}}</div>-->
        </div>

          <!-- 消息提示 -->
          <div *ngIf="msg.type=='message_tips'" class="time-msgs"><span class="time-span">{{msg.content}}</span></div>

          <!--屏蔽消息-->
          <div *ngIf="msg.type=='shield'">
            <div *ngIf="msg.from!=config.department.jm_username" style="text-align: center;height:30px;line-height: 30px;color:#999;">
              <span *ngIf="msg.content=='true'">对方已屏蔽此对话</span>
              <span *ngIf="msg.content=='false'">对方已打开此对话</span>
            </div>
            <div *ngIf="msg.from==config.department.jm_username" style="text-align: center;height:30px;line-height: 30px;color:#999;">
              <span *ngIf="msg.content=='true'">您已屏蔽此对话</span>
              <span *ngIf="msg.content=='false'">您已打开此对话</span>
            </div>
          </div><!--屏蔽消息-->
        </div><!--循环消息列表-->
      </div><!--chat-body-->

      <!-- 主动屏蔽 -->
      <div *ngIf="chatsInfo">
        <div *ngIf="chatsInfo.is_active_shield" class="contact-shield">该对话已被屏蔽
          <span (click)="setIMshield()">点击取消</span>
        </div>
      </div>
      <!-- 底部操作框(学生及小秘书) -->
      <div *ngIf="(chatsInfo&&chatsInfo.dialogue_account_type=='talent')||(userJmUsername=='JuniorSecretaryD')" class="chat-foot">
         <div class="chat-foot-icon">
            <!-- 常用语 -->
            <button (click)="goModalCommonLanguage()"><img title="常用语" src="assets/images/contact-common.png"></button>
            <!-- 发送图片 -->
            <button>
              <label style="cursor: pointer;" class="uploadImg-label" for="uploadChatImg"><img title="发送图片" src="assets/images/contact-picture.png"></label>
              <input class="uploadImg" id="uploadChatImg" (change)="previewPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
            </button>
            <!-- 微信号 -->
            <button (click)="sendMsgWechat()"><img title="发送微信号" src="assets/images/contact-wechat.png"></button>
            <!-- 职位 -->
            <button *ngIf="userJmUsername!='JuniorSecretaryD'" (click)="goModalPositionChoose()"><img title="更换职位" src="assets/images/contact-position.png"></button>
            <!-- 面试邀请 -->
            <button *ngIf="userJmUsername!='JuniorSecretaryD'" (click)="goModalResumeNotice()"><img title="面试邀请" src="assets/images/contact-notice.png"></button>
            <!-- 索要简历 -->
            <button *ngIf="userJmUsername!='JuniorSecretaryD'" (click)="autoSendMsg()"><img title="索要简历" src="assets/images/contact-ask.png"></button>
            <!-- 录用通知 -->
            <button *ngIf="userJmUsername!='JuniorSecretaryD'" (click)="SendOfferMsg()"><img title="录用通知" src="assets/images/contact-offer.png"></button>
            <!-- 简历直达 -->
            <button *ngIf="userJmUsername!='JuniorSecretaryD'" (click)="MemberObtainPage()"><img title="简历直达" src="assets/images/contact-email.png"></button>
         </div>
         <textarea name="chat" id="chat" [(ngModel)]="contentMsg" (keyup)="inputKeyup($event)" placeholder="请输入要发送的内容..."></textarea>
         <div class="chat-foot-enter">
             Enter 发送，Ctrl+Enter 换行
            <button  (click)="sendMsg(contentMsg)">发送</button>
         </div>
      </div><!-- right -->
   </div><!-- container -->
</div>
