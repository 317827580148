import { Component, OnInit } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate } from '../../provider/index';
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {productManager} from "../../provider/productManager";
import {PayChipsModelComponent} from "../pay-chips-model/pay-chips-model.component";
import { MatDialog } from "@angular/material/dialog";
declare var layer;
declare var layui;
declare let Swiper:any;
@Component({
  selector: 'app-my-tool',
  templateUrl: './my-tool.component.html',
  styleUrls: ['./my-tool.component.css']
})
export class MyToolComponent implements OnInit {
  mySwiper;
  public  productList = [];

  public config: Config;


  //单品对象  优化=>被全局单品数据
  public  activity_invitation = null;
  public  sms_bulkinvitation = null;
  public  position_refresh = null;
  public  voice_call = null;
  public  notice_student_sms = null;


  public myCardList=[];
  public MyLaCoinCount = 0;
  public myCardListCount = 0;
  public aboutLaCoinAlert = false; //关于啦豆的弹窗
  public  bannerList = [];

  public isShowAdvert = false
  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public productManager:productManager,
  public dialog: MatDialog,

  ) {
    this.config = Config.getInstance();//实例化config
    this.productManager.init();
  }

  ngOnInit() {
    this.opera.initConfig('tool','tool-my');

    this.getMyLaCoinCount();
    this.getDepartment();
    this.getMyCardList();
    this.getCardbanner();
    this.mySwiperInit();

    this.getcomponentLeft();
    // // 获取账户下的产品不见列表
    // getcomponentList(data){
    //   return this.api.get("/payment/V2/account/component/list",data);
    // }
    // //获取可用产品组件
    // getcomponentLeft(data){
    //   return this.api.get("/payment/V2/account/component/left",data);
    // }

    setTimeout(()=>{
      window.scrollTo(0,0);
      document.body.scrollTop=0;
    },300)

    let showPaySuccess = this.local.get('showPaySuccess');
    if(showPaySuccess)
    {
      this.local.remove('showPaySuccess');
      this.paySuccess(showPaySuccess);
      //showPaySuccess
    }
  }
  getcomponentLeft(){
    this.opera.loading()
    this.dataApi.getcomponentLeft({}).then((res:any)=>{
      console.log('getcomponentLeft',res);
      this.opera.loadiss();
      //productList
      let arr = [];
      // 空中宣讲会权限，发布职位数量限制 排除
      let noneAlise = ['air_campustalk','post_number']
      res.map((item)=>{
        if(item.alias!="member_year") {
          if (this.opera.isAddvert(item.alias)) {
            this.isShowAdvert = true
          } else {
            if(noneAlise.indexOf(item.alias)<0){
              arr.push(item);
            }
          }
        }
      })
      this.productList = arr;


    }).catch((err)=>{
      console.log(err);
      this.opera.loadiss();
    })
  }

  //充值啦豆
  buyLaCoin(){
    this.router.navigate(['home/buyLaCoin']);
  }
  //购买单品
  buyBtnClick(n){
    // this.router.navigate(['home/buyProduct']);
    this.router.navigate(['home/tool-campus']);
  }
  //跳转我的卡券
  gotoMyCard(){
    this.router.navigate(['home/myCard']);
  }
  //购买历史
  gotoPayHistory(){
    this.router.navigate(['home/payHistory']);
  }
  //使用记录
  useHistory(){
    this.local.set('productUseHistory','all')
    this.router.navigate(['home/productUseHistory']);
  }
  //开发票
  gotoInvoice(){
    this.router.navigate(['home/payInvoice']);
  }
  //获取我的啦豆的余额
  getMyLaCoinCount(){
    this.dataApi.getMyLaCoinCount({}).then((res:any)=>{
      // console.log('获取我的啦豆余额',res);
      if(res){
        this.MyLaCoinCount = res;
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  //获取企业信息
  getDepartment(){
    let that = this;
    // this.productManager.getDepartmentProduct(()=>{
    //   that.activity_invitation =that.productManager.my_activity_invitation;
    //   that.sms_bulkinvitation = that.productManager.my_sms_bulkinvitation;
    //   that.position_refresh = that.productManager.my_position_refresh;
    //   that.voice_call = that.productManager.my_voice_call;
    //   that.notice_student_sms = that.productManager.notice_student_sms;
    //
    // })
    this.dataApi.getcomponentLeft({}).then((res:any)=>{
      console.log('getcomponentLeft',res);
      let arr = [];
      res.map((item)=>{
        if(item.alias=='activity_invitation')
        {
          that.activity_invitation =item;
        }
        if(item.alias=='sms_bulkinvitation')
        {
          that.sms_bulkinvitation =item;
        }
        if(item.alias=='position_refresh')
        {
          that.position_refresh =item;
        }
        if(item.alias=='voice_call')
        {
          that.voice_call =item;
        }
        if(item.alias=='notice_student_sms')
        {
          that.notice_student_sms =item;
        }


      })
    }).catch((err)=>{
      console.log(err);
    })



  }
  //跳转到详情页面
  gotoDetail(n){
    if(!n){
      this.router.navigate(['home/advertList']);
      return
    }
    console.log('n',n);
    this.local.set("mySingleProductList", JSON.stringify(n));
    this.router.navigate(['home/mySingleProductList']);
  }
  //获取我的卡劵列表
  getMyCardList(){
    this.dataApi.getMyCardList({status:'unused'}).then((res:any)=>{
      // console.log('获取我的卡劵列表',res);
      if(res){
        let arr = [];
        this.myCardListCount = res.count;
        for (let index=0;index< res.list.length;index++)
        {
          let card  = res.list[index];
          if(card.coupon_category == "full_cut_coupon")
          {
            card.title = '满'+card.limited_amount+'减'+card.gift_amount;
          }
          else if(card.coupon_category == "full_add_coupon")
          {
            //满赠
            card.title = '满'+card.limited_amount+'赠'+card.gift_amount;
          }
          else  if(card.coupon_category == "exchange_coupon")
          {
            card.title = card.gift_amount+'啦豆兑换卷';
          }
          card.startTime = this.RiQi(Date.parse(card.effective_date))
          card.endTime = this.RiQi(Date.parse(card.expiry_date))
          if(index<=2)
          {
            arr.push(card);
          }
        }
        this.myCardList = arr;
      }
    }).catch((err)=>{
      console.log(err);
    })
  }

  //格式化卡劵上的日期时间戳转时间
  RiQi(sj)
  {
    var now = new Date(sj);
    var   year=now.getFullYear();
    var   month=now.getMonth()+1;
    var   date=now.getDate();
    var   hour=now.getHours();
    var   minute=now.getMinutes();
    var   second=now.getSeconds();
    return   year+"."+month+"."+date;
  }

  //右栏的单品点击事件，余额为0跳转到购买页面
  rightProductClick(type){
    
    if(!this.config.isShowPaySystem) {
      this.config.payVipHistoryPage = null
    }
    //code
    if(type=='voice_call')
    {
      //我的极速电话
      // console.log('rightProductClick',this.voice_call);
      if(!this.voice_call)
      {
        this.local.set("recharge_category_code", JSON.stringify(this.productManager.voice_call_code));
        // this.router.navigate(['home/buyProduct']);
        this.router.navigate(['home/tool-campus']);
        return;
      }
      if(this.voice_call.left==0)
      {
        // console.log('我的极速电话余额不足')
        this.local.set("recharge_category_code", JSON.stringify(this.productManager.voice_call_code));
        // this.router.navigate(['home/buyProduct']);
        this.router.navigate(['home/tool-campus']);
      }
      else
      {
        this.local.set("mySingleProductList", JSON.stringify(this.voice_call));
        this.router.navigate(['home/mySingleProductList']);
      }
    }
    else if(type=='sms_bulkinvitation')
    {
      //职位推送
       console.log('rightProductClick',this.sms_bulkinvitation);
      if(!this.sms_bulkinvitation)
      {
        this.local.set("recharge_category_code", JSON.stringify(this.productManager.sms_bulkinvitation_code));
        // this.router.navigate(['home/buyProduct']);
        this.router.navigate(['home/tool-campus']);
        return;
      }
      if(this.sms_bulkinvitation.left==0)
      {
        // console.log('我的极速电话余额不足')
        this.local.set("recharge_category_code", JSON.stringify(this.productManager.sms_bulkinvitation_code));
        // this.router.navigate(['home/buyProduct']);
        this.router.navigate(['home/tool-campus']);
      }
      else
      {
        this.local.set("mySingleProductList", JSON.stringify(this.sms_bulkinvitation));
        this.router.navigate(['home/mySingleProductList']);
      }
    }
    else if(type=='position_refresh')
    {
      //职位刷新
      console.log('rightProductClick',this.position_refresh);
      if(!this.position_refresh)
      {
        this.local.set("recharge_category_code", JSON.stringify(this.productManager.position_refresh_code));
        // this.router.navigate(['home/buyProduct']);
        this.router.navigate(['home/tool-campus']);
        return;
      }
      if(this.position_refresh.left==0)
      {
        // console.log('我的极速电话余额不足')
        this.local.set("recharge_category_code", JSON.stringify(this.productManager.position_refresh_code));
        // this.router.navigate(['home/buyProduct']);
        this.router.navigate(['home/tool-campus']);
      }
      else
      {
        this.local.set("mySingleProductList", JSON.stringify(this.position_refresh));
        this.router.navigate(['home/mySingleProductList']);
      }
    }
    else if(type=='activity_invitation')
    {
      //校招活动申请
      console.log('rightProductClick',this.activity_invitation);
      if(!this.activity_invitation)
      {
        this.local.set("recharge_category_code", JSON.stringify(this.productManager.activity_invitation_code));
        // this.router.navigate(['home/buyProduct']);
        this.router.navigate(['home/tool-campus']);
        return;
      }
      if(this.activity_invitation.left==0)
      {
        // console.log('我的极速电话余额不足')
        this.local.set("recharge_category_code", JSON.stringify(this.productManager.activity_invitation_code));
        // this.router.navigate(['home/buyProduct']);
        this.router.navigate(['home/tool-campus']);
      }
      else
      {
        this.local.set("mySingleProductList", JSON.stringify(this.activity_invitation));
        this.router.navigate(['home/mySingleProductList']);
      }
    }
    else if(type=='notice_student_sms')
    {
      //校招活动申请
      console.log('rightProductClick',this.notice_student_sms);
      if(!this.notice_student_sms)
      {
        this.local.set("recharge_category_code", JSON.stringify(this.productManager.notice_student_sms_code));
        // this.router.navigate(['home/buyProduct']);
        this.router.navigate(['home/tool-campus']);
        return;
      }
      if(this.notice_student_sms.left==0)
      {
        // console.log('我的极速电话余额不足')
        this.local.set("recharge_category_code", JSON.stringify(this.productManager.notice_student_sms_code));
        // this.router.navigate(['home/buyProduct']);
        this.router.navigate(['home/tool-campus']);
      }
      else
      {
        this.local.set("mySingleProductList", JSON.stringify(this.notice_student_sms));
        this.router.navigate(['home/mySingleProductList']);
      }
    }

    else if(type == 'zhiwei')
    {
      this.router.navigate(['home/position-manage']);
      this.opera.initConfig('position','position-manage');
    }
  }



  //关于啦豆 ？图标
  aboutLaBi(){
    this.aboutLaCoinAlert = true;
  }
  //组件相应的视图初始化之后
  ngAfterViewInit(){


  }

  //获取card广告banner
  getCardbanner(){
    let that = this;
    this.dataApi.getCardBannerdata('kaquan').then((res:any)=>{
      if(res){
        this.bannerList = res
      }
    }).catch((err)=>{
      console.log(err);
    })

  }
  // 初始化swiper
  mySwiperInit(){
    let that = this;
    var mySwiper = new Swiper('.swiper-container', {
      autoplay: true,//可选选项，自动滑动
    })
  }
  rightBannerClick(){
    window.open(this.config.gwl_activity_School_url,'__blank')
  }

  paySuccess(give){
    let dialog =  this.dialog.open(PayChipsModelComponent, {
      data:{
        title:"赠送您的"+give+"啦豆已到账，您可使用啦豆购买任意产品包",
        rightBtnTitle:'确定',
        callback:(res)=>{
          console.log('callback',res)
          this.dialog.closeAll();
          this.getcomponentLeft();


        },}
    }).afterClosed().subscribe((isConfirmed) => {
    });
  }

  myOrder(){
    this.router.navigate(['home/paymentorderlist']);
  }

  myInvoice(){
    this.router.navigate(['home/payInvoice']);
  }

  myLadou(){
    this.router.navigate(['home/payHistory']);
  }

}
