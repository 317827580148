<!-- 页头部分 -->
<div class="contentRight-header" *ngIf="isShowBackSave"><img (click)="opera.goChangeRouter('college-milkdetails')" src='assets/images/router-old.png' />高校云 - 双选会报名</div>
<div class="contentRight-header" *ngIf="!isShowBackSave"><img (click)="opera.goNewRouter('college-milkdetails')" src='assets/images/router-old.png' />高校云 - 双选会报名</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-still-milk #child [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-still-milk>
    <app-model-position-more #child1 [isShow]="modelTwoShow" [initValue]="model.positionids" (onVoted)="ModelTwodismiss($event)"></app-model-position-more>

    <div class="college-block" style="width: 1050px;margin: 0 auto">
        <!-- 限制行业 -->
        <div class="hyTip" *ngIf="MilkDetails && MilkDetails.include_industrys_text">
            温馨提示：本场双选会限制行业（{{MilkDetails.include_industrys_text}}）
        </div>
        <!-- 页头部分 -->
        <!-- <div class="guwen_bg" *ngIf="config.departmentSelf">
            <div class="guwen_bg_gray">
                <img class="guwen_info_huizhang" src="../../assets/images/kefu_hui.png">

                <img class="guwen_head" src="{{config.departmentSelf.avatar}}">
                <div class="guwen_infoDiv">
                    <ul>
                        <li><img style="width: 120px;height: 20px" src="../../assets/images/guwen_title.png"></li>
                        <li><span class="guwen_info_name">{{config.departmentSelf.name}}</span></li>
                        <li>
                            <img style="width: 17px;height: 17px" src="../../assets/images/kefu_wx.png">
                            <span class="guwen_info_title">微信：</span>
                            <span class="guwen_info_wx">{{config.departmentSelf.wx}}</span>
                        </li>
                        <li style="width: 260px">
                            <img style="width: 17px;height: 17px" src="../../assets/images/kefu_email.png">
                            <span class="guwen_info_title">邮箱：</span>
                            <span class="guwen_info_wx">{{config.departmentSelf.email}}</span>
                        </li>
                    </ul>
                </div>
                <img class="guwen_head_code" style="margin-left: 5px;" src="{{config.departmentSelf.qr_code}}">
                <div class="guwen_info_Line"></div>
                <div class="guwen_info_rightText">
                    <p>双选会/宣讲会申请相关问题,</p>
                    <p>请扫码咨询！<span>{{config.departmentSelf_ex}}</span></p>
                </div>

            </div>
        </div> -->

        <!-- 新版 -->
        <div>
            <!--//顶部状态-->
            <div style="width: 100%;margin-top: 30px;padding: 70px 0 20px; ">
                <div style="width: 25%;height: 60px;text-align: center;float: left">
                    <div style="width: 170px;height: 60px;;margin-right: 15px;float: right;display: flex;">
                        <!--           <div style="width: 50px;height: 50px;background-color: #5e4fff;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel.status==0">1</div>
    <div style="width: 50px;height: 50px;background-color: #30c742;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel.status==1">1</div>
    <div style="width: 40px;height: 40px;background-color: #30c742;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel.status==2">1</div>
    <div style="width: 40px;height: 40px;background-color: #fa5d5e;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel.status==3">1</div>-->
                        <img style="width: 30px;height: 30px;" src="../../assets/images/signup/au_ing.png" *ngIf="CollegeIdentityModel.status==0" />
                        <img style="width: 30px;height: 30px;" src="../../assets/images/signup/au_shenqing.png" *ngIf="CollegeIdentityModel.status==1" />
                        <img style="width: 30px;height: 30px;" src="../../assets/images/signup/au_success.png" *ngIf="CollegeIdentityModel.status==2" />
                        <img style="width: 30px;height: 30px;" src="../../assets/images/signup/au_fail.png" *ngIf="CollegeIdentityModel.status==3" />
                        <img style="width: 30px;height: 30px;" src="../../assets/images/signup/au_fail.png" *ngIf="CollegeIdentityModel.status==4" />
                        <img style="width: 30px;height: 30px;" src="../../assets/images/signup/au_fail.png" *ngIf="CollegeIdentityModel.status==5" />

                        <div>
                            <div *ngIf="showTab1" style="width: 72px;
                            font-size: 18px;
                            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                            font-weight: bold;
                            height: 20px;
                            line-height: 7px;
                            margin-left: 14px;
                            margin-top: 5px;
                            color: #333333;
                            ">
                                提交资质</div>
                            <div *ngIf="showTab2" style="width: 72px;
                            font-size: 18px;
                            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                            font-weight: bold;
                            height: 20px;
                            line-height: 7px;
                            margin-left: 14px;                            
                            margin-top: 5px;
                            color: #5E4FFF;
                            ">
                                提交资质</div>

                            <div class="statuBorder_success" style="width: 72px;
                            font-size: 12px;
                            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                            font-weight: bold;
                            height: 20px;
                            line-height: 7px;
                            margin-left: 14px;
                            margin-top: 5px;" *ngIf="CollegeIdentityModel.status==2">已通过</div>
                            <div class="statuBorder_ing" style="width: 72px;
                                font-size: 12px;
                                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                                font-weight: bold;
                                height: 20px;
                                line-height: 7px;
                                margin-left: 14px;
                                margin-top: 5px;" *ngIf="CollegeIdentityModel.status==1">审核中</div>
                            <div class="statuBorder_auing" style="width: 72px;
                            font-size: 12px;
                            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                            font-weight: bold;
                            height: 20px;
                            line-height: 7px;
                            margin-left: 14px;
                            margin-top: 5px;" *ngIf="CollegeIdentityModel.status==0">申请中</div>
                            <div class="statuBorder_fail" style="
                                width: 80px;
                                font-size: 12px;
                                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                                font-weight: bold;
                                height: 20px;
                                line-height: 7px;
                                margin-left: 14px;
                                margin-top: 5px;" *ngIf="CollegeIdentityModel.status==3">审核失败</div>
                            <div class="statuBorder_fail" style="width: 72px;
                            font-size: 12px;
                            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                            font-weight: bold;
                            height: 20px;
                            line-height: 7px;
                            margin-left: 10px;
                            margin-top: 5px;;
                                margin-top: 5px;" *ngIf="CollegeIdentityModel.status==4">未合作</div>
                            <div class="statuBorder_fail" style="width: 72px;
                            font-size: 12px;
                            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                            font-weight: bold;
                            height: 20px;
                            line-height: 7px;
                            margin-left: 14px;
                            margin-top: 5px;" *ngIf="CollegeIdentityModel.status==5">已拉黑</div>

                            <div style="height: 20px;line-height: 14px;margin-top: 15px;color: #BBBBBB;font-size: 13px;width: 900px;" *ngIf="CollegeIdentityModel.status==3">{{'原因：'+CollegeIdentityModel.remark}}</div>
                            <!-- <div style="height: 20px;line-height: 14px;margin-top: 15px;color: #5e4fff;font-size: 12px;width: 120px;cursor: pointer;" *ngIf="CollegeIdentityModel.status==3 && CollegeIdentityModel.no_apply_30day" (click)="opera.goChangeRouter('college-milkround')">
                            申请其他双选会 >>
                        </div> -->
                        </div>
                    </div>
                </div>

                <div *ngIf="showTab1" style="width: 80px;
                    height: 2px;
                    background: #D8D8D8;
                    border-radius: 2px;float: left;margin-top: 14px;">
                </div>
                <div *ngIf="!showTab1" style="width: 80px;
                height: 2px;
                background: linear-gradient(270deg, #5851FF 0%, #3F59FF 100%);
                border-radius: 2px;float: left;margin-top: 14px;">
                </div>
                <div style="width:  22%;height: 60px;text-align: center;float: left" *ngIf="MilkDetails">
                    <div style="width: 170px;height: 60px;margin-left: 15px;float: right;display: flex;">
                        <!--        <div style="width: 40px;height: 40px;background-color: gray;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px">2</div>-->
                        <img style="width: 30px;height: 30px;" *ngIf="MilkDetails!=null && MilkDetails.status==null &&(CollegeIdentityModel.status==0||CollegeIdentityModel.status==3)" src="../../assets/images/signup/milk_none.png" />
                        <img style="width: 30px;height: 30px;" *ngIf="MilkDetails!=null && MilkDetails.status==null && (CollegeIdentityModel.status==1||CollegeIdentityModel.status==2)" src="../../assets/images/signup/milk_suc.png" />
                        <img style="width: 30px;height: 30px;" *ngIf="MilkDetails.status=='to_audit'" src="../../assets/images/signup/milk_ing.png" />
                        <img style="width: 30px;height: 30px;" *ngIf="MilkDetails.status=='not_passed'" src="../../assets/images/signup/milk_fail.png" />
                        <img style="width: 30px;height: 30px;" *ngIf="MilkDetails.status=='passed'" src="../../assets/images/signup/milk_success.png" />

                        <div>
                            <div *ngIf="showTab1" style="width: 90px;
                        font-size: 18px;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        font-weight: bold;
                        height: 20px;
                        line-height: 7px;
                        margin-left: 14px;
                        margin-top: 5px;
                        color: #333333;">
                                报名双选会</div>
                            <div *ngIf="showTab2" style="width: 90px;
                        font-size: 18px;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        font-weight: bold;
                        height: 20px;
                        line-height: 7px;
                        margin-left: 14px;                            
                        margin-top: 5px;
                        color: #5E4FFF;">
                                报名双选会</div>

                            <div class="statuBorder_ing" style="width: 80px;
                        font-size: 12px;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        font-weight: bold;
                        height: 20px;
                        line-height: 7px;
                        margin-left: 14px;
                        margin-top: 5px;" *ngIf="MilkDetails!=null &&MilkDetails.status=='to_audit'">等待审核</div>
                            <div class="statuBorder_none" style="width: 80px;
                            font-size: 12px;
                            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                            font-weight: bold;
                            height: 20px;
                            line-height: 7px;
                            margin-left: 14px;
                            margin-top: 5px;" *ngIf="MilkDetails!=null && MilkDetails.status==null &&(CollegeIdentityModel.status==0||CollegeIdentityModel.status==3)">
                                未申请</div>
                            <div class="statuBorder_auing" style="width: 80px;
                        font-size: 12px;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        font-weight: bold;
                        height: 20px;
                        line-height: 7px;
                        margin-left: 14px;
                        margin-top: 5px;" *ngIf="MilkDetails!=null && MilkDetails.status==null && (CollegeIdentityModel.status==1||CollegeIdentityModel.status==2)">
                                申请中</div>
                            <div class="statuBorder_fail" style="width: 80px;
                        font-size: 12px;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        font-weight: bold;
                        height: 20px;
                        line-height: 7px;
                        margin-left: 14px;
                        margin-top: 5px;" *ngIf="MilkDetails!=null &&MilkDetails.status=='not_passed'">报名失败</div>
                            <div class="statuBorder_success" style="width: 80px;
                        font-size: 12px;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        font-weight: bold;
                        height: 20px;
                        line-height: 7px;
                        margin-left: 14px;
                        margin-top: 5px;" *ngIf="MilkDetails!=null &&MilkDetails.status=='passed'">报名成功</div>

                            <div (click)="opera.goChangeRouter('college-milkround')" style="width: 220px;
                            font-size: 12px;
                            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                            font-weight: bold;
                            height: 20px;
                            line-height: 7px;
                            margin-left: 14px;
                            margin-top: 5px;color:#fa5d5e" *ngIf="MilkDetails!=null &&MilkDetails.status=='not_passed'">{{MilkDetails.reason}}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div style="clear: both;"></div>
            <div (click)="opera.goChangeRouter('college-milkround')" style="width: 140px;height: 40px;margin-top: 5px;color: white;background-color: #5e4fff;text-align: center;padding:8px 15px;margin-left: 46%;margin-bottom: 15px" *ngIf="(CollegeIdentityModel.status==3 && CollegeIdentityModel.no_apply_30day)||reason=='双选会展位分配已满，请报名其他双选会'">
                申请其他双选会</div>
            <div style="width: 140px;height: 20px;margin-top: 5px;color: white;background-color: white;text-align: center;padding:8px 15px;margin-left: 46%;margin-bottom: 15px;border-radius: 5px" *ngIf="CollegeIdentityModel.status==3 && CollegeIdentityModel.no_apply_30day"></div>

            <div>

            </div>
            <!--//高校合作步骤-->

            <div class="sign-block" *ngIf="showTab1" style="padding-bottom: 30px;">
                <div>
                    <div style="display: flex;margin-left: 20px">
                        <img style="width:50px;height: 50px;margin-right: 5px;" src="{{collegeLogo}}" alt="">
                        <div style="margin-top: 4px;">
                            <span style="width: 56px;
                                height: 19px;
                                font-size: 14px;
                                font-family: MicrosoftYaHei;
                                color: #798F95;
                                line-height: 19px;">申请高校</span>
                            <p style="
                                height: 21px;
                                font-size: 16px;
                                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                                font-weight: bold;
                                color: #111111;
                                line-height: 21px;">{{collegeName}}</p>
                        </div>
                    </div>
                    <div *ngIf="collegeName&&orgList_shenhe.length==0" class="sign-list">
                        <span>当前申请高校：</span><span style="text-align: left;width: 300px !important">{{collegeName}}</span>
                    </div>
                    <div class="sign-list" *ngIf="orgList_shenhe.length>0">
                        <span>当前申请院系：</span>
                        <select name="CIOrg" id="CIOrg" [(ngModel)]="model.facultyid" autocomplete="off">
                            <option class="place-text" value='' disabled selected="selected">请选择院系</option>
                            <option *ngFor="let a of orgList" [value]="a.facultyid">{{a.name}}</option>
                        </select>
                    </div>
                    <div style="display: flex;margin-top: 20px;margin-left: 26px;">
                        <img style="width: 18px;height: 18px;margin-right: 5px;" src="../../assets/images/remindIcon.png" alt="">
                        <span style="width: 182px;
                        height: 19px;
                        font-size: 14px;
                        font-family: MicrosoftYaHei;
                        color: #ADB8BB;
                        line-height: 19px;
                        ">以下为高校要求，请认真填写</span>
                    </div>

                </div>
                <!-- 营业执照 -->
                <div class="info-list" style="margin-left: 26px">
                    <div class="info-head info-head-new">上传营业执照/企业资质<span>（必填）</span></div>
                    <label *ngIf="model.business_licence" for="uploadLoginAvatar">
                        <img [src]="model.business_licence" style="width: 180px;height:180px;">
                    </label>
                    <label *ngIf="!model.business_licence" for="uploadLoginAvatar">
                        <img src="../../assets/images/business_license.png" style="width: 180px;height:180px;">
                    </label>
                    <input class="uploadImg" id="uploadLoginAvatar" (change)="previewPic($event,'business_licence')" type="file" accept="image/jpeg,image/jpg,image/png">
                    <ul>
                        <li>1. 上传营业执照名称必须与公司全称保持一致</li>
                        <li>2. 请提供有效年检期内的《企业法人营业执照》副本</li>
                        <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                        <li>4. 高校就业老师将会在 1 -3个工作日内审核</li>
                        <li>5. 企业资质是指：有的公司没有营业执照，可以根据<br> &nbsp;&nbsp;&nbsp;&nbsp;不同行业上传不同的证件；如：基金会等公益组织，
                            <br> &nbsp;&nbsp;&nbsp;&nbsp;上传法人登记证书等。
                        </li>
                    </ul>
                    <div style="margin-top: 10px;height: 20px;color: #999999;font-size: 12px;width: 185px;text-align: center" for="uploadLoginAvatar">上传营业执照/企业资质</div>
                </div>


                <!--办学许可证 企业为教育行业 -->
                <div class="info-list" style="margin-left: 26px" *ngIf="department?.industry=='0606'||department?.industry=='0604'||department?.industry=='0605'">
                    <!-- <div class="info-list" style="margin-left: 26px;"> -->
                    <div class="info-head info-head-new" style="width: 180px;">上传办学许可证<span>（必填）</span></div>
                    <label *ngIf="model.school_permit" for="school_permit">
                        <img [src]="model.school_permit" style="width: 180px;height:180px;">
                    </label>
                    <label *ngIf="!model.school_permit" for="school_permit">
                        <img src="assets/images/business_license.png">
                    </label>
                    <input class="uploadImg" id="school_permit" (change)="previewPic($event,'school_permit')" type="file" accept="image/jpeg,image/jpg,image/png">
                    <div style="margin-top: 10px;height: 20px;color: #999999;font-size: 12px;width: 185px;text-align: center" for="school_permit">办学许可证</div>
                </div>

                <!-- 营业执照 -->
                <div class="info-list" style="margin-left: 26px">
                    <div class="info-head info-head-new" style="width: 256px;">上传招聘者手持身份证照片<span>（必填）</span></div>
                    <label *ngIf="model.id_card_front" for="uploadGuideCard">
                        <img [src]="model.id_card_front" style="width: 180px;height:180px;">
                    </label>
                    <label *ngIf="!model.id_card_front" for="uploadGuideCard">
                        <img src="../../assets/images/business_license.png" style="width: 180px;height:180px;">
                    </label>
                    <input class="uploadImg" id="uploadGuideCard" (change)="previewPic($event,'id_card_front')" type="file" accept="image/jpeg,image/jpg,image/png">
                    <ul>
                        <li>1. 上传身份证姓名必须与招聘联系人姓名保持一致</li>
                        <li>2. 请提供有效期内的本人手持《身份证》照片</li>
                        <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                        <li>4. 高校就业老师将会在 1 -3个工作日内审核</li>
                    </ul>
                    <div *ngIf="model.id_card_front" style="margin-top: 10px;height: 20px;color: #999999;font-size: 12px;width: 185px;text-align: center" for="uploadGuideCard">上传招聘者手持身份证照片</div>
                </div>

                <!-- 招聘委托书 -->
                <div *ngIf="secureMaterail" class="info-list" style="margin-left: 26px">
                    <div class="info-head info-head-new" style="width: 190px;">上传招聘委托书<span>（必填）</span></div>
                    <label *ngIf="model.proxy_document" for="uploadProxy">
                        <img [src]="model.proxy_document" style="width: 180px;height:180px;">
                    </label>
                    <label *ngIf="!model.proxy_document" for="uploadProxy">
                        <img src="assets/images/business_license.png" style="width: 180px;height:180px;">
                    </label>
                    <input class="uploadImg" id="uploadProxy" (change)="previewPic($event,'proxy_document')" type="file" accept="image/jpeg,image/jpg,image/png">
                    <ul>
                        <li>1. 点击下载：<a [href]="secureMaterail">《招聘委托书示例》</a></li>
                        <li (click)="opera.copySecureMaterailClick(secureMaterail)" style="font-size: 12px">如遇到下载有问题，请点击
                            <span style="color: #487BAF;cursor: pointer">复制</span> 后打开新浏览器页面地址栏粘贴下载
                        </li>
                        <li>2. 修改后请加盖公章，截图或拍照上传</li>
                    </ul>
                </div>
                <!-- 统一社会信用代码 -->
                <div class="info-list info-number" style="margin-left: 26px;">
                    <div class="info-head  info-head-new" style="width: 195px;">统一社会信用代码<span>（必填）</span></div>
                    <input style="margin-left:0px;" type="text" placeholder="请填写营业执照统一社会信用代码" [(ngModel)]="model.register_number" maxlength="18">
                    <ul style="margin-left: 24px">
                        <li>1. 填写的统一社会信用代码必须与营业执照保持一致</li>
                        <li>2. 高校就业老师将会在 1 -3个工作日内审核</li>
                    </ul>
                </div>
                <!-- 办公电话 -->
                <!-- <div class="info-list info-number" style="margin-left: 26px;"> -->
                <div class="info-list info-number" *ngIf="authcation_office_phone" style="margin-left: 26px;">
                    <div class="info-head  info-head-new" style="width: 130px;">办公电话<span>（必填）</span></div>
                    <input style="margin-left:0px;" type="text" [(ngModel)]="model.office_phone" (blur)="checkPhone1(model.office_phone)" placeholder="请填写固定电话，如0371-66668888" class="info-phone">
                    <!-- <b *ngIf="!model.office_phone"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b> -->
                </div>
                <!-- 申请简述 -->
                <div class="info-list" style="margin-left: 26px;">
                    <div class="info-head info-head-new" style="width: 65px;">申请简述</div>
                    <!-- <div *ngIf="secureMaterail&&!authcation_office_phone" class="info-head">第五步&nbsp;&nbsp;申请简述</div>
                    <div *ngIf="!secureMaterail&&authcation_office_phone" class="info-head">第五步&nbsp;&nbsp;申请简述</div>
                    <div *ngIf="secureMaterail&&authcation_office_phone" class="info-head">第六步&nbsp;&nbsp;申请简述</div> -->
                    <div class="list-brief">
                        <textarea style="
                        padding: 15px;
                        width: 901px;
                        height: 178px;
                        background: #FBFBFB;
                        border-radius: 6px;
                        border: 1px solid #D3D3D3;" placeholder="简述申请校招的目的（最多100字）" scrollHeight [(ngModel)]="model.brief" minlength="0" maxlength="100">{{model.brief}}</textarea>
                    </div>
                </div>
                <!-- 按钮 -->
                <div style="margin-top: 30px;" class="sign-submit1 sign-submit1-new" (click)="uploadDepartmentIdentity()">下一步</div>
            </div>

            <!-- 申请部分 -->
            <div class="sign-block" *ngIf="showTab2" style="padding-bottom: 30px">
                <div style="display: flex;margin-top: 20px;margin-left: 26px;">
                    <img style="width: 18px;height: 18px;margin-right: 5px;" src="../../assets/images/remindIcon.png" alt="">
                    <span style="
                    height: 19px;
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    color: #ADB8BB;
                    line-height: 19px;
                    ">*您报名双选会后，请您及时留意双选会举办信息，</span>
                </div>
                <!-- 双选会名称 -->
                <div class="sign-list sign-list-new">
                    <span style="display: inline;">双选会名称：</span>
                    <p>{{name}}</p>
                    <!-- <input type="text" [(ngModel)]="name" disabled="disabled"> -->
                </div>
                <!-- 单位名称 -->
                <div class="sign-list sign-list-new" *ngIf="department">
                    <span style="display: inline;">单位名称：</span>
                    <p>{{department.name}}</p>
                    <!-- <input type="text" [(ngModel)]="department.name" disabled="disabled"> -->
                </div>
                <!-- 审核院系 -->
                <div class="sign-list sign-skill sign-list-new" *ngIf="audit_mode">
                    <span>审核院系<b class="remind-red">（必填）</b></span>
                    <select name="milkFacultyid" id="milkFacultyid" [(ngModel)]="model.facultyid" autocomplete="off">
                        <option class="place-text" value='' disabled selected="selected">请选择院系</option>
                        <option *ngFor="let a of orgList" [value]="a.facultyid">{{a.name}}</option>
                    </select>
                    <b *ngIf="isSend&&!model.facultyid"><img src="assets/images/guide-false.png"
                            alt="必填项提示">&nbsp;&nbsp;必填项</b>
                </div>
                <!-- 意向专业 -->
                <div class="sign-list sign-skill sign-list-new sign-list-inp" *ngIf="orgList.length>0">
                    <span>意向专业<b class="remind-red">（必填）</b></span>

                    <input *ngIf="collegeMajorList.length==0" type="text" placeholder="不限" />
                    <!-- <img *ngIf="collegeMajorList.length==0" class="calendar-img calendar-new" (click)="goMajor()"
                        src="../../assets/images/position/plus-circle.png" alt="添加专业" /> -->
                    <div *ngIf="collegeMajorList.length > 0" class="selected-text1 selected-text1-new">
                        <div class="selected-text-item" *ngFor="let m of collegeMajorList" (click)="goMajor()">
                            {{m.text}} x</div>
                    </div>
                    <button class="calendar-new" (click)="goMajor()">添加</button>
                </div>
                <!-- 招聘职位 -->
                <div class="sign-list sign-skill sign-list-new sign-list-inp">
                    <span>招聘职位<b class="remind-red">（必填）</b></span>
                    <input *ngIf="model.positionids.length==0" type="text" placeholder="不限" />
                    <!-- <img class="calendar-img"
                        (click)="choosePosition()" src="../../assets/images/position/plus-circle.png" alt="添加专业" /> -->
                    <div *ngIf="model.positionids.length > 0" class="selected-text1 selected-text1-new">
                        <div class="selected-text-item" *ngFor="let m of positionList" (click)="choosePosition()">
                            {{m.name}}</div>
                    </div>
                    <button class="calendar-new" (click)="choosePosition()">添加</button>
                </div>
                <div style="margin-left: 300px;font-size: 12px;color: #aaaaaa" *ngIf="exceedPositionCount>0">
                    有{{exceedPositionCount}}个职位已经过期或已下架</div>

                <!-- 联系人 -->
                <div class="sign-list sign-list-new">
                    <span>联系人<b class="remind-red">（必填）</b></span><input type="text" [(ngModel)]="model.contacts" maxlength="8" placeholder="请填写联系人" (blur)="checkContact()">
                    <b *ngIf="isSend&&!model.contacts"><img src="assets/images/guide-false.png"
                            alt="必填项提示">&nbsp;&nbsp;必填项</b>
                </div>
                <!-- 联系电话 -->
                <div class="sign-list sign-list-new">
                    <span>联系电话<b class="remind-red">（必填）</b></span><input type="number" [(ngModel)]="model.phone" (blur)="checkPhone(model.phone)" placeholder="请填写联系电话">
                    <b *ngIf="isSend&&!model.phone"><img src="assets/images/guide-false.png"
                            alt="必填项提示">&nbsp;&nbsp;必填项</b>
                </div>
                <!-- 微信号码 -->
                <div class="sign-list sign-list-new" *ngIf="isAir">
                    <span>微信号<b class="remind-red">（必填）</b></span><input [(ngModel)]="model.wechat" placeholder="空中双选会微信号以此处为准">
                    <b>用于学生投递简历后添加您进行线上面试</b>
                    <b *ngIf="isSend&&!model.wechat"><img src="assets/images/guide-false.png"
                            alt="必填项提示">&nbsp;&nbsp;必填项</b>
                </div>
                <!--参会人数 -->
                <div class="sign-list sign-list-new" style="display: none;">
                    <span>参会人数<b class="remind-red">（必填）</b></span><input type="number" [(ngModel)]="model.attendance" oninput="if(value.length>1)value=value.slice(0,1)" placeholder="请填写参会人数(限5人)">
                    <b *ngIf="isSend&&!model.attendance"><img src="assets/images/guide-false.png"
                            alt="必填项提示">&nbsp;&nbsp;必填项</b>
                </div>
                <!--参会成员 -->
                <div class="sign-list sign-editor sign-list-new" *ngIf="MilkDetails && MilkDetails.milkround_type == 1">
                    <span>参会成员<b class="remind-red">（必填）</b></span>
                    <div class="path-block">
                        <div class="path-list" *ngFor="let p of model.attendees;let i =index;">
                            <span style="width: 72px !important;">成员{{i+1}}：</span>
                            <input class="double-input" style="width: 200px" type="text" placeholder="请填写成员姓名(8字以内)" maxlength="8" [(ngModel)]="p.name">
                            <input class="double-input" style="width: 114px" type="text" placeholder="请填写联系电话" onkeyup="this.value=this.value.replace(/\s+/g,'')" [(ngModel)]="p.phone" (blur)="checkPhone(p.phone)">
                            <!--删除成员-->
                            <img (click)="delPosition(i)" class="close_default" src="/assets/images/close_default.png">
                        </div>
                        <div class="path-add path-add-chip">联系电话必须为手机号</div>
                        <div class="path-add flex_c" (click)="addPosition()"><img src="../../assets/images/position/plus-circle.png">增加</div>
                    </div>
                </div>
                <!--视频间时间段 -->
                <div class="sign-list sign-list-new" [class.showactive]='!is_online_interview' style="display: none;">
                    <span>视频间时间段<b class="remind-red">（必填）</b></span>
                    <input type="text" placeholder="请选择视频间时间段" id="test10" (change)="innerviewTimeChange()" />
                    <p class="tipText">tip：视频面试时间在当天的， 请点击活动日期2次再选择具体时间。</p>
                </div>

                <div class="sign-list sign-editor" *ngIf="audit_switch.isShowFriend">
                    <span>是否为校友企业<b class="remind-red">（必填）</b></span>
                    <div class="path-block">
                        <div class="flex_l">
                            <div class="isFriendCom" [ngClass]="{'isFriendColor':model.is_alumn,'isNoneFriendColor':!model.is_alumn}" (click)="checkFriendClick(true)">是</div>
                            <div class="isFriendCom" [ngClass]="{' isNoneFriendColor':model.is_alumn,'isFriendColor':!model.is_alumn}" (click)="checkFriendClick(false)">否</div>
                        </div>
                    </div>
                </div>

                <div style="margin:30px auto;" *ngIf="model.is_alumn && audit_switch.isShowFriend">
                    <div style="height: 50px;margin-left: 110px" class="flex_l friendList" *ngFor="let friend of model.friendList;let i =index;">
                        <div class="item">
                            校友名称:
                            <input type="text" placeholder="请填写校友名称" [(ngModel)]="friend.name">
                        </div>
                        <div class="item">
                            校友专业:
                            <input type="text" placeholder="请填写校友专业" [(ngModel)]="friend.major">
                        </div>
                        <div class="item">
                            毕业时间:
                            <input type="date" style="width: 140px !important;" [(ngModel)]="friend.graduation_time">
                        </div>
                        <div class="item">
                            公司职位:
                            <input type="text" style="width: 80px!important;" placeholder="在公司职位" [(ngModel)]="friend.position">
                        </div>
                        <div class="deleteFriend" (click)="deleteFriend(i)">删除</div>
                    </div>

                    <div class="path-add flex_c" (click)="addFriend()"><img src="../../assets/images/position/plus-circle.png">增加</div>
                </div>

                <!-- 面试时间 -->
                <div class="sign-list sign-editor" *ngIf="interview_time_setting?.length > 0">
                    <span class="timeSpan">面试时间申请：</span>
                    <div class="path-block timeBox">
                        <span *ngFor="let t of interview_time_setting;let i =index;" [class.cur]="model.interview_time == t" (click)="setTime(t)">{{t}}</span>
                    </div>
                </div>
                <!--备注 -->
                <div class="sign-list sign-intro">
                    <span style="display: inline;">备注：</span>
                    <textarea style="width: 775px;
                        height: 193px;
                        background: #FBFBFB;
                        border-radius: 6px;
                        border: 1px solid #D3D3D3;font-size: 14px;
                        font-family: MicrosoftYaHei;
                        color: #9BACB0;" [(ngModel)]="model.remark" minlength="0" maxlength="500" placeholder="如需要高校给予现场支持，请在此填写。（如提供面试场地）"></textarea>
                </div>
                <!--附件 -->
                <!--<div class="sign-list sign-skill enclosure" >-->
                <!--<span ><b class="remind-red" *ngIf="MilkDetails?.department_receipt_switch">*</b>附件：</span>-->
                <!--<div class="company-imglist clear" style="width: 500px">-->
                <!--<div class="left img-list" *ngIf="model.attachment.length < 3">-->
                <!--<label class="uploadImg-label" for="uploadMilkImg">-->
                <!--<img style="margin: 36px 0 30px 0" src="assets/images/add-img.png" />&lt;!&ndash;<br/>点击添加图片&ndash;&gt;-->
                <!--</label>-->
                <!--<input class="uploadImg" id="uploadMilkImg" (change)="previewMilkPic($event)" type="file" accept="multiple">-->
                <!--</div>-->
                <!--<a *ngIf="MilkDetails?.department_receipt_url" class="left downloadBlock" href="{{MilkDetails?.department_receipt_url}}" target = "_blank" >下载附件模板</a>-->
                <!--</div>-->
                <!--<br/><s style="font-size: 11px;color: #CCCCCC;padding-left: 0"><span style="color: red">*</span>请下载附件模板，按高校要求填写、盖章并上传附件，最多3个附件，大小不超过5M</s>-->
                <!--<div class="flex_l fileRow" style="margin-left: 150px" *ngFor="let img of model?.attachment;let num=index">-->
                <!--<div class="flex_l "> 附件{{num+1}}: &nbsp;  {{formatFileName(img)}} <span class="fileRow-delete" (click)="deleteFlie(img)">删除</span></div>-->
                <!--</div>-->
                <!--</div>-->

                <div class="sign-list sign-skill enclosure" *ngIf="MilkDetails?.department_receipt_switch && department_receipt_List.length>0">
                    <span><b class="remind-red">*</b>附件：</span>
                    <div *ngFor="let f of department_receipt_List;let i =index;" class="fileBox">
                        <div class="filetitle">附件{{i+1}}: {{f.name}}</div>
                        <div class="flex_l">
                            <label [for]="'uploadMilkImg'+i" class="uploadLabel">
                                点击上传
                            </label>
                            <a class="download_file" [href]="f.department_receipt_url" *ngIf="f.department_receipt_url">下载模版</a>
                            <input class="uploadImg" [id]="'uploadMilkImg'+i" (change)="previewMilkPic($event,i)" type="file" accept="multiple">
                        </div>
                        <div *ngIf="f.links.length>0">
                            <div class="downloaded" *ngFor="let linkModel of f.links;let a = index;"> 已上传： {{formatFileName(linkModel)}} <img (click)="deleteMilkPicClick(i,a)" src="../../assets/images/close_default.png" class="delete" /></div>
                        </div>
                    </div>
                    <s style="font-size: 11px;color: #CCCCCC;padding-left: 0"><span
                            style="color: red">*</span>请下载附件模板，按高校要求填写、盖章并上传附件，大小不超过5M</s>
                </div>

                <!-- 提交 -->
                <div class="sign-submit1 sign-submit1-new" (click)="getSignModel()">提交申请</div>

            </div>
        </div>

    </div>
</div>

<div class="rightPbox" *ngIf="is_online_interview">
    <!--操作指南-->
    <a class="guide" href="https://activity.goworkla.cn/guide/index.html?v1.0" target="_blank">
        <img src="assets/images/InterviewRoom/guide.png">
        <p>操作指南</p>
    </a>
    <!--设备检测-->
    <a class="testing" href="https://webdemo.agora.io/agora_webrtc_troubleshooting/" target="_blank">
        <img src="assets/images/InterviewRoom/testing.png">
        <p>设备检测</p>
    </a>
</div>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isZhuXiaoAlert" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':560,'height':280,'minHeight':280}"
    [modal]=true>
    <div class="system_out_bg">
        <div style="margin: 70px 77px;margin-bottom: 0px">
            <img class="system_out_diannao" src="../../assets/images/zhuxiao_waring.png" />
            <li style="margin-left: 30px;margin-top: 5px;"><span style="color: #666666;font-size: 20px;">当前有相同的企业已经申请本场双选会/宣讲会,申请账号为：<span style="color: #5E4FFF;    white-space: normal;
    word-break: break-all;">{{isZhuXiaoAlert_title}}</span>，您可以选择申请其他双选会/宣讲会或继续申请</span>
            </li>
            <div class="continue_commit_alert" (click)="closeZhuXiao()">继续申请</div>
        </div>
        <p style="text-align: center;color: #BBBBBB;font-size: 16px;margin-top: 15px">若此账号未使用，您可以<span style="font-size: 16px;color: #5E4FFF;text-decoration:underline;cursor: pointer" (click)="zhuxiaoClick()">注销账号</span></p>
    </div>
</p-dialog>