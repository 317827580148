import { Component, OnInit, Output,ViewChild } from '@angular/core';
import { DataApi, Local, Opera } from "../../provider";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";

declare let laydate;
@Component({
  selector: 'app-product-use-history',
  templateUrl: './product-use-history.component.html',
  styleUrls: ['./product-use-history.component.css']
})
export class ProductUseHistoryComponent implements OnInit {

  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true }) 
  productModel: ProductModelComponent;

  public list = []
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 1;//分页数量Math.ceil(this.countList/this.model.limit)

  public productType = 'all';
  public endTime = '';
  public startTime = '';
  public model = {
    recharge_category: '',//产品包类型
    log_time: '',
    offset: 0,
    limit: 9,
    start_time: '',
    end_time: '',
  };
  public isAdvert = false;
  public productItemData: any;
  public productUseHistoryLeft:any;
  public _productType:any;
  constructor(
    public opera: Opera,
    public dataApi: DataApi,
    public local: Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService
  ) { }

  ngOnInit() {
    let topNav = this.local.get('nav_tool')
    this.opera.initConfig('tool', topNav);

    let productType = this.local.get('productUseHistory');
    this._productType = this.local.get('productUseHistory');
    this.productUseHistoryLeft = this.local.get('productUseHistoryLeft');
    let that = this;
    laydate.render({
      elem: '#start-time',
      done: function (value, date) {
        that.startTime = value;
      }
    })
    laydate.render({
      elem: '#end-time',
      done: function (value, date) {
        that.endTime = value;
      }
    })

    if (productType) {
      this.productcomponent(productType);
      if (this.opera.isAddvert(productType)) {
        this.isAdvert = true
      }
      this.productType = productType;
      this.productType = this.productType.replace("\"", "");
      this.productType = this.productType.replace("\"", "");
      this.getLogList(true);
    } else {
      this.getLogList(true);
    }
  }

  getLogList(isRef) {
    this.model.offset = isRef ? 0 : (this.pageNumber - 1) * this.model.limit;
    this.model.recharge_category = this.productType == 'all' ? '' : this.productType;
    this.model.start_time = this.startTime;
    this.model.end_time = this.endTime;
    this.dataApi.getRecordList(this.model).then((res: any) => {
      console.log("获取使用记录", res, this.model)
      if (res) {
        let newArr = [];
        for (let item of res.list) {
          item.timeStr = this.RiQi(item.log_time);
          if (this.isAdvert) {
            item.advertStart = this.RiQi(item.advert_start_time);
            item.advertEnd = this.RiQi(item.advert_end_time);
            item.consume_count = (item.consume_count / 7) + '周'
            item.left = (item.left / 7) + '周'
            if (item.left == 0) {
              item.left = "0周"
            }
          }
          newArr.push(item);
        }
        this.list = newArr;
        this.countList = res.count;
        this.pageCount = Math.ceil(res.count / 9);
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  productcomponent(alias) {
    this.dataApi.productcomponent(alias).then((res: any) => {
      if (res) {
        console.log(res);
        this.productItemData = res;
      }
    }).catch((err) => {
      console.log('productcomponent', err);
    })
  }

  //获取分页子组件返回数据
  getChildEvent(index) {
    if (index > 0) {
      this.pageNumber = index;
      this.getLogList(false);
    } else if (index == -1) {//上一页
      console.log('获取分页子组件返回数据', this.pageNumber);

      if (this.pageNumber == 1) {
        this.toastr.warning("当前为第一页");
      } else {
        this.pageNumber = this.pageNumber - 1;
        this.getLogList(false);
      }
    } else if (index == -2) {//下一页
      if (this.pageNumber == this.pageCount) {
        this.toastr.warning("当前为最后一页");
      } else {
        this.pageNumber = this.pageNumber + 1;
        this.getLogList(false);
      }
    }

  }

  //时间戳转时间
  RiQi(dataStr) {
    if (dataStr == null) {
      return ""
    }
    var now = new Date(Date.parse(dataStr));
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var date = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    return year + "/" + month + "/" + date;
  }

  //搜索按钮
  searchBtnClick() {
    if (this.startTime == '') {
      this.toastr.warning('请选择开始日期')
      return;
    }
    if (this.endTime == '') {
      this.toastr.warning('请选择结束日期')
      return;
    }
    this.getLogList(true);
  }



  productModelHidden(event){    
    this.isproductShowModel = false;
  }

  //点击产品
  product(item,type){
    this.productModel.initData(item,type);
    this.isproductShowModel = true;
  }
}
