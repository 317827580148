<!-- 页头部分 -->
<!-- <div class="contentRight-header"><img (click)="opera.goChangeRouter('college-campustalk')" src='assets/images/router-old.png'/>宣讲会预约</div> -->

<div class="headerReturn">
    <div class="center" *ngIf="isShowBackSave" (click)="opera.goChangeRouter('college-campustalk1')">
        <img src="/assets/images/v3/headerReturnIcon.png">申请宣讲会
    </div>
    <div class="center" *ngIf="!isShowBackSave" (click)="opera.goChangeRouter('college-campustalk1')">
        <img src="/assets/images/v3/headerReturnIcon.png">申请宣讲会
    </div>
</div>

<!-- 主体内容部分 -->
<div class="homepage-block">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-still-milk #child [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-still-milk>
    <app-model-position-more #child1 [isShow]="modelTwoShow" [initValue]="model.positionids" (onVoted)="ModelTwodismiss($event)"></app-model-position-more>
    <div class="college-block">
        <div class="zhuxiao_waring" (click)="isShowCampChipsWithCollege=true" *ngIf="isHaveCampChipsWithCollege&&!isShowCampChipsWithCollege">
            <img src="../../assets/images/zhuxiao_waring.png" />
        </div>
        <div style="padding:0 92px;height: 140px;margin-top: 30px;">
            <div style="width: 13%;height: 120px;text-align: center;float: left">
                <div style="width: 100px;height: 120px;;margin-right: 15px;float: right">
                    <!--        <div style="width: 40px;height: 40px;background-color: #5e4fff;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel.status==1||CollegeIdentityModel.status==0">1</div>
            <div style="width: 40px;height: 40px;background-color: #30c742;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel.status==2">1</div>
            <div style="width: 40px;height: 40px;background-color: #fa5d5e;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel.status==3">1</div>-->
                    <img style="width: 52px;height: 52px;" src="../../assets/images/signup/au_ing.png" *ngIf="CollegeIdentityModel.status==0" />
                    <img style="width: 52px;height: 52px;" src="../../assets/images/signup/au_shenqing.png" *ngIf="CollegeIdentityModel.status==1" />
                    <img style="width: 52px;height: 52px;" src="../../assets/images/signup/au_success.png" *ngIf="CollegeIdentityModel.status==2" />
                    <img style="width: 52px;height: 52px;" src="../../assets/images/signup/au_fail.png" *ngIf="CollegeIdentityModel.status==3" />
                    <img style="width: 52px;height: 52px;" src="../../assets/images/signup/au_fail.png" *ngIf="CollegeIdentityModel.status==5" />

                    <div style="width: 100px;height: 20px;margin-top: 10px;font-size: 15px;color: #000000;font-weight: bold">
                        资质审核</div>

                    <div class="statuBorder_auing" style="margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="CollegeIdentityModel.status==0">申请中</div>
                    <div class="statuBorder_ing" style="margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="CollegeIdentityModel.status==1">审核中</div>
                    <div class="statuBorder_success" style="margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="CollegeIdentityModel.status==2">已通过</div>
                    <div class="statuBorder_fail" style="margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="CollegeIdentityModel.status==3">已拒绝</div>
                    <div class="statuBorder_fail" style="margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="CollegeIdentityModel.status==5">已被拉黑</div>
                </div>

            </div>
            <div class="_line"></div>
            <div style="width: 13%;height: 120px;text-align: center;float: left">
                <div style="width: 100px;height: 60px;margin-left: 15px;float: left">
                    <!--<div style="width: 40px;height: 40px;background-color: gray;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf=" !applyDetails">2</div>-->
                    <img style="width: 52px;height: 52px;" *ngIf="!applyDetails &&(CollegeIdentityModel.status==0||CollegeIdentityModel.status==3||CollegeIdentityModel.status==5)" src="../../assets/images/signup/milk_none.png" />
                    <img style="width: 52px;height: 52px;" *ngIf="!applyDetails &&(CollegeIdentityModel.status==1||CollegeIdentityModel.status==2)" src="../../assets/images/signup/milk_suc.png" />

                    <img style="width: 52px;height: 52px;" *ngIf="applyDetails&&applyDetails.audit_status=='to_audit'" src="../../assets/images/signup/milk_ing.png" />
                    <img style="width: 52px;height: 52px;" *ngIf="applyDetails&&applyDetails.audit_status=='passed'" src="../../assets/images/signup/milk_success.png" />
                    <img style="width: 52px;height: 52px;" *ngIf="applyDetails&&applyDetails.audit_status=='not_passed'" src="../../assets/images/signup/milk_fail.png" />

                    <!--            <div style="width: 40px;height: 40px;background-color: #5e4fff;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="applyDetails&&applyDetails.audit_status=='to_audit'">2</div>
            <div style="width: 40px;height: 40px;background-color: #30c742;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="applyDetails&&applyDetails.audit_status=='passed'">2</div>
            <div style="width: 40px;height: 40px;background-color: #fa5d5e;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="applyDetails&&applyDetails.audit_status=='not_passed'">2</div>-->
                    <div style="margin-top: 10px; color: #000000;font-size: 15px;font-weight: bold;">宣讲会预约</div>
                    <div class="statuBorder_none" style="margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="!applyDetails &&(CollegeIdentityModel.status==0||CollegeIdentityModel.status==3||CollegeIdentityModel.status==5)">
                        未申请</div>
                    <div class="statuBorder_auing" style="margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="!applyDetails &&(CollegeIdentityModel.status==1||CollegeIdentityModel.status==2)">申请中
                    </div>

                    <div class="statuBorder_ing" style="margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="applyDetails&&applyDetails.audit_status=='to_audit'">审核中</div>
                    <div class="statuBorder_success" style="margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="applyDetails&&applyDetails.audit_status=='passed'">已通过</div>
                    <div class="statuBorder_fail" style="margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="applyDetails&&applyDetails.audit_status=='not_passed'">已拒绝</div>

                </div>
            </div>
        </div>
        <p class="errorText" *ngIf="CollegeIdentityModel.status==3">{{'原因：'+CollegeIdentityModel.remark}}</p>
        <p class="errorText" *ngIf="applyDetails&&applyDetails.audit_status=='not_passed'">{{'原因：'+applyDetails.reason}}
        </p>
        <p class="other" (click)="gotoOthercampustalk()" *ngIf="CollegeIdentityModel.status==3 && CollegeIdentityModel.no_apply_30day">申请其他宣讲会</p>
        <div class="modify" (click)="commitUpdateAlert()" *ngIf="isEdit">修改申请信息</div>

        <!--//高校合作步骤-->
        <div class="qualifications">
            <!--资质审核-->
            <div class="main" *ngIf="showTab1">
                <div class="school">
                    <div class="logo"><img [src]="CollegeDetail.logo"></div>
                    <div class="rt">
                        <div class="text">申请高校</div>
                        <div class="name">{{collegeName}}</div>
                    </div>
                </div>

                <div class="tipsText"><img src="/assets/images/v3/positionPushTip.png">以下为高校要求，请认真填写</div>

                <!-- 当前申请院系 -->
                <div class="module" *ngIf="orgList.length>0">
                    <div class="title">当前申请院系<span>（必填）</span></div>
                    <div class="centerB">
                        <div class="select">
                            <select class="activeBg" name="CIOrg" id="CIOrg" [(ngModel)]="model.facultyid" autocomplete="off">
                                <option class="place-text" value='' disabled selected="selected">请选择院系</option>
                                <option *ngFor="let a of orgList" [value]="a.facultyid">{{a.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <!--上传营业执照/企业资质-->
                <div class="module">
                    <div class="title">上传营业执照/企业资质<span>（必填）</span></div>
                    <div class="centerB">
                        <div class="upBox">
                            <div class="box">
                                <label class="uploadImg-label" for="uploadLoginAvatar">
                                    <img class="_img" *ngIf="model.business_licence" [src]="model.business_licence">
                                    <img class="upImg" *ngIf="!model.business_licence"
                                        src="/assets/images/v3/pushposition.png">
                                </label>
                                <input class="uploadImg" id="uploadLoginAvatar" (change)="CompanypreviewPic($event,'business_licence')" type="file" accept="image/jpeg,image/jpg,image/png">
                            </div>
                            <p>上传营业执照/企业资质</p>
                        </div>
                        <div class="rightTip">
                            <ul>
                                <li>1. 上传营业执照名称必须与公司全称保持一致</li>
                                <li>2. 请提供有效年检期内的《企业法人营业执照》副本</li>
                                <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                                <li>4. 高校就业老师将会在 1 -3个工作日内审核</li>
                                <li>5. 企业资质是指：有的公司没有营业执照，可以根据<br> &nbsp;&nbsp;&nbsp;&nbsp;不同行业上传不同的证件；如：基金会等公益组织，
                                    <br> &nbsp;&nbsp;&nbsp;&nbsp;上传法人登记证书等。
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!--上传办学许可证-->
                <div class="module" *ngIf="myDepartment?.industry=='0606'||myDepartment?.industry=='0604'||myDepartment?.industry=='0605'">
                    <div class="title">上传办学许可证<span>（必填）</span></div>
                    <div class="centerB">
                        <div class="upBox">
                            <div class="box">
                                <label class="uploadImg-label" for="uploadLoginbanXue">
                                    <img class="_img" *ngIf="model.school_permit" [src]="model.school_permit">
                                    <img class="upImg" *ngIf="!model.school_permit"
                                        src="/assets/images/v3/pushposition.png">
                                </label>
                                <input class="uploadImg" id="uploadLoginbanXue" (change)="CompanypreviewPic($event,'school_permit')" type="file" accept="image/jpeg,image/jpg,image/png">
                            </div>
                            <p>上传办学许可证</p>
                        </div>
                    </div>
                </div>

                <!--上传招聘者手持身份证照片-->
                <div class="module">
                    <div class="title">上传招聘者手持身份证照片<span>（必填）</span></div>
                    <div class="centerB">
                        <div class="upBox">
                            <div class="box">

                                <label class="uploadImg-label" for="uploadGuideCard">
                                    <img class="_img" *ngIf="model.id_card_front" [src]="model.id_card_front">
                                    <img class="upImg" *ngIf="!model.id_card_front"
                                        src="/assets/images/v3/pushposition.png">
                                </label>
                                <input class="uploadImg" id="uploadGuideCard" (change)="CompanypreviewPic($event,'id_card_front')" type="file" accept="image/jpeg,image/jpg,image/png">
                            </div>
                            <p>上传招聘者手持身份证照片</p>
                        </div>
                        <div class="rightTip">
                            <ul>
                                <li>1. 上传身份证姓名必须与招聘联系人姓名保持一致</li>
                                <li>2. 请提供有效期内的本人手持《身份证》照片</li>
                                <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                                <li>4. 高校就业老师将会在 1 -3个工作日内审核</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- 上传招聘委托书 -->
                <div class="module" *ngIf="secureMaterail">
                    <div class="title">上传招聘委托书<span>（必填）</span></div>
                    <div class="centerB">
                        <div class="upBox">
                            <div class="box">
                                <label class="uploadImg-label" for="uploadProxy">
                                    <img class="_img" *ngIf="model.proxy_document" [src]="model.proxy_document">
                                    <img class="upImg" *ngIf="!model.proxy_document"
                                        src="/assets/images/v3/pushposition.png">
                                </label>
                                <input class="uploadImg" id="uploadProxy" (change)="CompanypreviewPic($event,'proxy_document')" type="file" accept="image/jpeg,image/jpg,image/png">
                            </div>
                            <p>上传招聘委托书照片</p>
                        </div>
                        <div class="rightTip">
                            <ul>
                                <li>1. 点击下载：<a [href]="secureMaterail">《招聘委托书示例》</a></li>
                                <li (click)="opera.copySecureMaterailClick(secureMaterail)" style="font-size: 12px">
                                    如遇到下载有问题，请点击
                                    <span style="color: #3063FF;cursor: pointer">复制</span> 后打开新浏览器页面地址栏粘贴下载
                                </li>
                                <li>2. 修改后请加盖公章，截图或拍照上传</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- 统一社会信用代码 -->
                <div class="module">
                    <div class="title">统一社会信用代码<span>（必填）</span></div>
                    <div class="centerB active">
                        <div class="input">
                            <input type="text" placeholder="请填写营业执照统一社会信用代码" [(ngModel)]="model.register_number" maxlength="18">
                        </div>
                        <div class="rightTip">
                            <ul>
                                <li>1. 填写的统一社会信用代码必须与营业执照保持一致</li>
                                <li>2. 高校就业老师将会在 1 -3个工作日内审核</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- 办公电话 -->
                <div class="module" *ngIf="authcation_office_phone">
                    <div class="title">办公电话<span>（必填）</span></div>
                    <div class="centerB active">
                        <div class="input">
                            <input type="text" [(ngModel)]="model.office_phone" (blur)="checkPhone(model.office_phone)" placeholder="请填写固话">
                        </div>
                    </div>
                </div>

                <!-- 申请简述 -->
                <div class="module">
                    <div class="title">申请简述</div>
                    <div class="centerB">
                        <div class="textarea">
                            <textarea placeholder="请简述申请校招的目的(最多100字)" scrollHeight [(ngModel)]="model.brief" minlength="0" maxlength="100">{{model.brief}}</textarea>
                        </div>
                    </div>
                </div>
                <div class="submit1 blueStreamer" (click)="uploadDepartmentIdentity()">下一步</div>
            </div>
            <!--宣讲会申请-->
            <div class="main" [hidden]="!showTab2">
                <div class="PreachTtime">预约宣讲会</div>
                <div class="school" *ngIf="college">
                    <div class="logo"><img [src]="CollegeDetail.logo"></div>
                    <div class="rt">
                        <div class="name">{{collegeName}}</div>
                    </div>
                </div>
                <!--单位名称-->
                <div class="module active fn" *ngIf="myDepartment">
                    <div class="_left">单位名称</div>
                    <div class="_right">
                        <div class="text">{{myDepartment.name}}</div>
                    </div>
                </div>

                <!--宣讲主题-->
                <div class="module active">
                    <div class="_left">宣讲主题<span>（必填）</span></div>
                    <div class="_right">
                        <div class="val">
                            <input type="text" [(ngModel)]="model.name" maxlength="25" placeholder="请填写宣讲主题" (blur)="checkName()">
                        </div>
                    </div>
                </div>

                <!--开始时间-->
                <div class="module active">
                    <div class="_left">开始时间<span>（必填）</span></div>
                    <div class="_right">
                        <div class="val">
                            <input type="text" id='start-time' placeholder="请选择开始时间" [(ngModel)]="model.start_time" />
                        </div>
                        <div class="sanjiao"><img src="/assets/images/v3/cardDown02.png"></div>
                    </div>
                </div>

                <!--结束时间-->
                <div class="module active">
                    <div class="_left">结束时间<span>（必填）</span></div>
                    <div class="_right">
                        <div class="val">
                            <input type="text" id='end-time' placeholder="请选择结束时间" value="{{model.end_time}}" [(ngModel)]="model.end_time" />
                        </div>
                        <div class="sanjiao"><img src="/assets/images/v3/cardDown02.png"></div>
                    </div>
                </div>

                <!--场地规模-->
                <div class="module active">
                    <div class="_left">场地规模<span>（必填）</span></div>
                    <div class="_right">
                        <div class="val">
                            <input type="number" [(ngModel)]="model.scale" maxlength="10" (blur)="checkNum()" placeholder="请填写期望规模">
                        </div>
                        <div class="danwei">人</div>
                    </div>
                </div>

                <!-- 选择校区 -->
                <div class="module active" *ngIf="campusList.length>0">
                    <div class="_left">选择校区<span>（必填）</span></div>
                    <div class="_right">
                        <div class="val">
                            <select class="activeBg" name="campusList" id="campusList" [(ngModel)]="model.campusid" autocomplete="off" (ngModelChange)="getCampusOrgList()">
                                <option class="place-text" value='' disabled selected="selected">请选择校区</option>
                                <option *ngFor="let a of campusList" [value]="a.campusid">{{a.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <!--宣讲范围-->
                <div class="module active">
                    <div class="_left">宣讲范围<span>（必填）</span></div>
                    <div class="_right">
                        <div class="btns">
                            <div class="btn" [class.purple]="model.campustalk_type=='college'" (click)="model.campustalk_type='college'">校级宣讲会</div>
                            <div class="btn" [class.purple]="model.campustalk_type=='faculty'" (click)="model.campustalk_type='faculty'">院级宣讲会</div>
                        </div>
                    </div>
                </div>

                <!--申请院系(校级)-->
                <div class="module active" *ngIf="model.campustalk_type=='college'">
                    <div class="_left">申请院系<span>（必填）</span></div>
                    <div class="_right cell" *ngIf="orgList.length == 0">
                        <div class="btns">
                            <div class="btn purple">不限</div>
                        </div>
                    </div>
                    <div class="_right cell" *ngIf="orgList.length > 0">
                        <div class="item" (click)="goMajor()" *ngFor="let m of model.major_list">{{m.faculty_name}}
                        </div>
                        <div class="push" (click)="goMajor()"><img src="/assets/images/v3/pushposition.png">添加</div>
                    </div>
                </div>

                <!--意向专业(校级)-->
                <div class="module active" *ngIf="model.campustalk_type=='college'">
                    <div class="_left">意向专业<span>（必填）</span></div>
                    <div class="_right cell" *ngIf="orgList.length == 0">
                        <div class="btns">
                            <div class="btn purple">不限</div>
                        </div>
                    </div>
                    <div class="_right cell" *ngIf="orgList.length > 0">
                        <div class="item" (click)="goMajor()" *ngFor="let m of collegeMajorList">{{m.text}}</div>
                        <div class="push" (click)="goMajor()"><img src="/assets/images/v3/pushposition.png">添加</div>
                    </div>
                </div>

                <!--申请院系(院级)-->
                <div class="module active" *ngIf="model.campustalk_type=='faculty'">
                    <div class="_left">申请院系<span>（必填）</span></div>
                    <div class="_right cell" *ngIf="orgList.length == 0">
                        <div>学校未开启院级宣讲会申请</div>
                    </div>
                    <div class="_right" (click)="checkOrgList()" *ngIf="orgList.length >0">
                        <div class="val">
                            <select class="activeBg" name="position" id="position" [(ngModel)]="model.facultyid" autocomplete="off" (ngModelChange)="newMajor()">
                                <option class="place-text" value='' disabled selected="selected">请选择院系</option>
                                <option *ngFor="let a of orgList" [value]="a.facultyid">{{a.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <!--意向专业(院级)-->
                <div class="module active" *ngIf="model.campustalk_type=='faculty'">
                    <div class="_left">意向专业<span>（必填）</span></div>
                    <div class="_right cell">
                        <div class="btns" *ngIf="majorList.length == 0">
                            <div class="btn purple">不限</div>
                        </div>
                        <div class="btns" *ngIf="majorList.length>0">
                            <div class="btn" [class.purple]="m.isActive" (click)="newMajorList(m)" *ngFor="let m of majorList">{{m.name}}</div>
                        </div>
                    </div>
                </div>

                <!--招聘职位-->
                <div class="module active" *ngIf="model.campustalk_type=='college'">
                    <div class="_left">招聘职位<span>（必填）</span></div>
                    <div class="_right cell">
                        <div class="item" (click)="choosePosition()" *ngFor="let m of positionList">{{m.name}}</div>
                        <div class="push" (click)="choosePosition()"><img src="/assets/images/v3/pushposition.png">添加
                        </div>
                    </div>
                </div>

                <!--联系人-->
                <div class="module active">
                    <div class="_left">联系人<span>（必填）</span></div>
                    <div class="_right">
                        <div class="val">
                            <input type="text" [(ngModel)]="model.contacts" maxlength="8" placeholder="请填写联系人" (blur)="checkContact()">
                        </div>
                    </div>
                </div>

                <!--联系电话-->
                <div class="module active">
                    <div class="_left">联系电话<span>（必填）</span></div>
                    <div class="_right">
                        <div class="val">
                            <input type="number" [(ngModel)]="model.phone" (blur)="checkPhone()" placeholder="请填写联系电话">
                        </div>
                    </div>
                </div>

                <!--企业参会人数-->
                <div class="module active">
                    <div class="_left">企业参会人数<span>（必填）</span></div>
                    <div class="_right">
                        <div class="val">
                            <input type="number" [(ngModel)]="model.attendance" oninput="if(value.length>2)value=value.slice(0,2)" placeholder="请填写参会人数">
                        </div>
                    </div>
                </div>

                <!--上传活动封面-->
                <div class="module active">
                    <div class="_left">上传活动封面</div>
                    <div class="_right">
                        <div class="upImgbox">
                            <div class="box">
                                <label class="uploadImg-label" for="uploadCampusImg">
                                    <img class="_img" *ngIf="model.cover" [src]="model.cover">
                                    <img class="upImg" *ngIf="!model.cover" src="/assets/images/v3/pushposition.png">
                                </label>
                                <input class="uploadImg" id="uploadCampusImg" (change)="previewPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                            </div>
                            <p>点击上传</p>
                            <p>建议宽高1242*650PX</p>
                        </div>
                    </div>
                </div>

                <!--上传附件-->
                <div class="module active" *ngIf="audit_switch.department_receipt">
                    <div class="_left">上传附件<span>（必填）</span></div>
                    <div class="_right">
                        <div class="upImgbox active">
                            <div class="box" *ngIf="model?.attachment?.length < 3">
                                <label class="uploadImg-label" for="uploadMilkImg">
                                    <img class="upImg" src="/assets/images/v3/pushposition.png">
                                </label>
                                <input class="uploadImg" id="uploadMilkImg" (change)="previewMilkPic($event)" type="file" accept="multiple">
                            </div>
                            <div class="textLisr">
                                <a class="fn" *ngIf="audit_switch.department_receipt_url" href="{{audit_switch.department_receipt_url}}" download="单位招聘委托书.docx"><img src="/assets/images/v3/downIcon.png">下载模版</a>
                                <p>请下载附件模版，按照高校要求填写，盖章并上传附件，最多3个附件，大小不超过5M。</p>
                            </div>
                        </div>
                        <div class="attachmentList">
                            <div class="item" *ngFor="let img of model?.attachment;let num=index">
                                <div class="formatFileName"> 附件{{num+1}}: &nbsp; {{formatFileName(img)}} <span class="delete" (click)="deleteFlie(img)">删除</span></div>
                            </div>
                        </div>

                    </div>
                </div>

                <!--宣讲会介绍-->
                <div class="module active">
                    <div class="_left">宣讲会介绍<span>（必填）</span></div>
                    <div class="_right">
                        <ngx-neditor [(ngModel)]="model.intro" #neditor [config]="toolConfig"></ngx-neditor>
                    </div>
                </div>

                <div class="submit1 blueStreamer" (click)="goSign()">提交</div>

            </div>
        </div>

        <div class="sign-block" *ngIf="showTab1" style="padding-bottom: 30px;display: none;">

            <div class="sign-list">
                <span>当前申请高校：</span><span style="text-align: left;width: 300px !important">{{collegeName}}</span>
            </div>
            <div class="sign-list" *ngIf="orgList.length>0">
                <span>当前申请院系：</span>
                <select name="CIOrg" id="CIOrg" [(ngModel)]="model.facultyid" autocomplete="off">
                    <option class="place-text" value='' disabled selected="selected">请选择院系</option>
                    <option *ngFor="let a of orgList" [value]="a.facultyid">{{a.name}}</option>
                </select>
            </div>
            <!--<div *ngIf="orgList.length>0" class="identityCollege-name">当前申请院系：-->
            <!--<select name="CIOrg" id="CIOrg" [(ngModel)]="model.facultyid" autocomplete="off">-->
            <!--<option class="place-text" value='' disabled selected="selected">请选择院系</option>-->
            <!--<option *ngFor="let a of orgList" [value]="a.facultyid">{{a.name}}</option>-->
            <!--</select>-->
            <!--</div>-->
            <div class="tipsText">
                以下为高校要求，请认真填写
            </div>
            <!-- 营业执照 -->
            <div class="info-list info-logo">
                <div class="info-head">第一步&nbsp;&nbsp;营业执照/企业资质</div>
                <label *ngIf="model.business_licence" class="uploadImg-label department-logo" for="uploadLoginAvatar">
                    <img [src]="model.business_licence" style="width: 180px;height:180px;">
                </label>
                <label *ngIf="!model.business_licence" class="uploadImg-label" for="uploadLoginAvatar">
                    <img src="assets/images/add-img.png"><br />点击上传&nbsp;&nbsp;营业执照/企业资质
                </label>
                <input class="uploadImg" id="uploadLoginAvatar" (change)="CompanypreviewPic($event,'business_licence')" type="file" accept="image/jpeg,image/jpg,image/png">
                <ul>
                    <li>1. 上传营业执照名称必须与公司全称保持一致</li>
                    <li>2. 请提供有效年检期内的《企业法人营业执照》副本</li>
                    <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                    <li>4. 高校就业老师将会在 1 -3个工作日内审核</li>
                    <li>5. 企业资质是指：有的公司没有营业执照，可以根据<br> &nbsp;&nbsp;&nbsp;&nbsp;不同行业上传不同的证件；如：基金会等公益组织，
                        <br> &nbsp;&nbsp;&nbsp;&nbsp;上传法人登记证书等。
                    </li>

                </ul>
                <div *ngIf="model.business_licence" style="margin-top: 0px;height: 20px;margin-left:158px;color: #999999;font-size: 12px;width: 320px;text-align: center" for="uploadLoginAvatar">修改&nbsp;&nbsp;营业执照/企业资质</div>
            </div>

            <!--办学许可证-->
            <div class="info-list info-logo" style="margin-top: 15px" *ngIf="myDepartment?.industry=='0606'||myDepartment?.industry=='0604'||myDepartment?.industry=='0605'">
                <label *ngIf="model.school_permit" class="uploadImg-label department-logo" for="uploadLoginbanXue">
                    <img [src]="model.school_permit" style="width: 180px;height:180px;">
                </label>
                <label *ngIf="!model.school_permit" class="uploadImg-label" for="uploadLoginbanXue">
                    <img src="assets/images/add-img.png"><br />点击上传&nbsp;&nbsp;办学许可证
                </label>
                <input class="uploadImg" id="uploadLoginbanXue" (change)="CompanypreviewPic($event,'school_permit')" type="file" accept="image/jpeg,image/jpg,image/png">
                <div *ngIf="model.school_permit" style="margin-top: 0px;height: 20px;margin-left:158px;color: #999999;font-size: 12px;width: 320px;text-align: center" for="uploadLoginbanXue">修改&nbsp;&nbsp;办学许可证</div>
            </div>


            <!-- 招聘者身份证 -->
            <div class="info-list info-logo">
                <div class="info-head">第二步&nbsp;&nbsp;招聘者身份证</div>
                <label *ngIf="model.id_card_front" class="uploadImg-label department-logo" for="uploadGuideCard">
                    <img [src]="model.id_card_front" style="width: 180px;height:180px;">
                </label>
                <label *ngIf="!model.id_card_front" class="uploadImg-label" for="uploadGuideCard">
                    <img src="assets/images/add-img.png"><br />点击上传手持身份证
                </label>
                <input class="uploadImg" id="uploadGuideCard" (change)="CompanypreviewPic($event,'id_card_front')" type="file" accept="image/jpeg,image/jpg,image/png">
                <ul>
                    <li>1. 上传身份证姓名必须与招聘联系人姓名保持一致</li>
                    <li>2. 请提供有效期内的本人手持《身份证》照片</li>
                    <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                    <li>4. 高校就业老师将会在 1 -3个工作日内审核</li>
                </ul>
                <div *ngIf="model.id_card_front" style="margin-top: 0px;height: 20px;margin-left:158px;color: #999999;font-size: 12px;width: 320px;text-align: center" for="uploadGuideCard">修改&nbsp;&nbsp;手持身份证</div>

            </div>

            <!-- 招聘委托书 -->
            <div *ngIf="secureMaterail" class="info-list info-logo">
                <div class="info-head">第三步&nbsp;&nbsp;招聘委托书</div>
                <label *ngIf="model.proxy_document" class="uploadImg-label department-logo" for="uploadProxy">
                    <img [src]="model.proxy_document" style="width: 180px;height:180px;">
                </label>
                <label *ngIf="!model.proxy_document" class="uploadImg-label" for="uploadProxy">
                    <img src="assets/images/add-img.png"><br />点击上传招聘委托书
                </label>
                <input class="uploadImg" id="uploadProxy" (change)="CompanypreviewPic($event,'proxy_document')" type="file" accept="image/jpeg,image/jpg,image/png">
                <ul>
                    <li>1. 点击下载：<a [href]="secureMaterail">《招聘委托书示例》</a></li>
                    <li (click)="opera.copySecureMaterailClick(secureMaterail)" style="font-size: 12px">如遇到下载有问题，请点击
                        <span style="color: #487BAF;cursor: pointer">复制</span> 后打开新浏览器页面地址栏粘贴下载
                    </li>
                    <li>2. 修改后请加盖公章，截图或拍照上传</li>
                </ul>
            </div>

            <!-- 统一社会信用代码 -->
            <div class="info-list info-number">
                <div *ngIf="!secureMaterail" class="info-head">第三步&nbsp;&nbsp;统一社会信用代码</div>
                <div *ngIf="secureMaterail" class="info-head">第四步&nbsp;&nbsp;统一社会信用代码</div>
                <input style="margin-left: 158px" type="text" placeholder="请填写营业执照统一社会信用代码" [(ngModel)]="model.register_number" maxlength="18">
                <ul style="margin-left: 24px">
                    <li>1. 填写的统一社会信用代码必须与营业执照保持一致</li>
                    <li>2. 高校就业老师将会在 1 -3个工作日内审核</li>
                </ul>
            </div>

            <!-- 办公电话 -->
            <div class="info-lis" *ngIf="authcation_office_phone">
                <div *ngIf="!secureMaterail" class="info-head">第四步&nbsp;&nbsp;办公电话</div>
                <div *ngIf="secureMaterail" class="info-head">第五步&nbsp;&nbsp;办公电话</div>
                <input type="text" [(ngModel)]="model.office_phone" (blur)="checkPhone(model.office_phone)" placeholder="请填写固话" class="info-phone">
                <!-- <b *ngIf="!model.office_phone"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b> -->
            </div>
            <!-- 申请简述 -->
            <div class="info-list">
                <div *ngIf="!secureMaterail&&!authcation_office_phone" class="info-head">第四步&nbsp;&nbsp;申请简述</div>
                <div *ngIf="secureMaterail&&!authcation_office_phone" class="info-head">第五步&nbsp;&nbsp;申请简述</div>
                <div *ngIf="!secureMaterail&&authcation_office_phone" class="info-head">第五步&nbsp;&nbsp;申请简述</div>
                <div *ngIf="secureMaterail&&authcation_office_phone" class="info-head">第六步&nbsp;&nbsp;申请简述</div>
                <div class="list-brief">
                    <textarea style="width: 616px;margin-left: 158px" placeholder="请简述申请校招的目的(最多100字)" scrollHeight [(ngModel)]="model.brief" minlength="0" maxlength="100">{{model.brief}}</textarea>
                </div>
            </div>

            <!-- 按钮 -->
            <div style="margin-top: 30px" class="sign-submit1" (click)="uploadDepartmentIdentity()">下一步</div>
        </div>

    </div>

</div>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px'}" [(visible)]="isZhuXiaoAlert" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':560,'height':320,'minHeight':320}"
    [modal]=true>
    <div class="system_out_bg">
        <div style="margin: 70px 77px;margin-bottom: 0px">
            <img class="system_out_diannao" src="../../assets/images/zhuxiao_waring.png" />
            <li style="margin-left: 30px;margin-top: 5px;"><span style="color: #666666;font-size: 20px;">当前有相同的企业已经申请本场双选会/宣讲会,申请账号为：<span style="color: #5E4FFF;    white-space: normal;
    word-break: break-all;">{{isZhuXiaoAlert_title}}</span>，您可以选择申请其他双选会/宣讲会或继续申请</span>
            </li>
            <div class="continue_commit_alert" (click)="closeZhuXiao()">继续申请</div>
        </div>
        <p style="text-align: center;color: #BBBBBB;font-size: 16px;margin-top: 15px">若此账号未使用，您可以<span style="font-size: 16px;color: #5E4FFF;text-decoration:underline;cursor: pointer" (click)="zhuxiaoClick()">注销账号</span></p>
    </div>
</p-dialog>


<p-dialog [closeOnEscape]="false" [showHeader]="false" [dismissableMask]="false" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px'}" [(visible)]="isShowCampChipsWithCollege" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':600,'height':450,'minHeight':450}"
    [modal]=true>
    <div class="signCampCollegeChips">
        <div class="signCampCollegeChips_title">高校提示</div>
        <div class="signCollegeChips_Content" [innerHTML]="isHaveCampChipsWithContent">

        </div>
        <div class="signCollegeChips_Btn blueStreamer" *ngIf="ChipsWithCollegeBtn" (click)="collegeChipsClick()">
            {{ChipsWithCollegeBtn}}</div>
    </div>
</p-dialog>