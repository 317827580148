<div class="homepage-block">
    <div class="rights">

        <!-- 头部 -->
        <div class="banner" *ngIf="bannerList && bannerList.length>0">
            <div class="layui-carousel" id="rightText">
                <div carousel-item>
                    <div *ngFor="let n of bannerList; let i = index;" (click)="clickBanner(n)" [ngClass]="{c_p:n.link}">
                        <img class="swiper-slide bannerImage" id={{i}} src="{{n.img_url}}" />
                    </div>
                </div>
            </div>
        </div>

        <!-- 数据看板 -->
        <div class="kanbanContainer">
            <div class="logo"><img src="/assets/images/rights/kanbanLogo.png"></div>
            <div class="cell">
                <div class="name">校园招聘数据看板</div>
                <div class="time">更新时间：{{kanbanData.updateTime}}</div>
            </div>
            <div class="rightMain">
                <div class="item">
                    <div class="title">求职者人数</div>
                    <div class="number"><span>{{kanbanData.value1}}</span>人</div>
                </div>
                <div class="item">
                    <div class="title">总用人单位</div>
                    <div class="number"><span>{{kanbanData.value2}}</span>家</div>
                </div>
                <div class="item">
                    <div class="title">总发布职位</div>
                    <div class="number"><span>{{kanbanData.value3}}</span>个</div>
                </div>
                <div class="item">
                    <div class="title">总岗位数</div>
                    <div class="number"><span>{{kanbanData.value4}}</span>个</div>
                </div>
            </div>
        </div>

        <!-- 会员 -->
        <div class="isVip" *ngIf="isShowVip">
            <div class="container">
                <div class="vipMain" *ngIf="department">
                    <div class="top">
                        <div class="logo">
                            <img *ngIf="department.logo" [src]="department.logo+'&imageView2/1/w/80/h/80'">
                            <img class="noLogo" *ngIf="!department.logo" src="/assets/images/rights/departmentLogo.png">
                        </div>
                        <div class="rt">
                            <div class="name">{{department.name}}<img *ngIf="config.lastIdentityNavSatus == 2" src="/assets/images/rights/authentication.png"></div>
                            <div class="lables">{{department.employer_type_text}}｜{{department.scale_text}}｜{{department.industry_text}}</div>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="diamonds"><img src="/assets/images/rights/diamonds.png"></div>
                        <div class="centerBox">
                            <div class="effectiveTime" *ngIf="department.member_info.days">您的基础版会员有效期 {{department.member_info.days}}天</div>
                            <div class="time" *ngIf="department.member_info.endtime_text"><img src="/assets/images/rights/vipTime.png">{{department.member_info.endtime_text}} 到期</div>
                        </div>
                        <div class="btn" (click)="product('','contactMe')">续费会员</div>
                    </div>
                </div>
                <div class="vipSome">
                    <div class="list">
                        <div class="first">
                            <img src="/assets/images/rights/vieRights.png">
                            <div class="text">
                                <div class="_title">会员权益</div>
                                <div class="_tip">查看与管理您的各项会员权益</div>
                            </div>
                        </div>
                        <div class="item" *ngFor="let item of rechargeList" (click)="product(item,'recharge')">
                            <img [src]="item.ico">
                            <div class="text">
                                <div class="_title">{{item.left}}</div>
                                <div class="_tip">{{item.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rightsList">
                <div class="item" *ngFor="let item of memberList" (click)="product(item,'member')">
                    <div class="icon"><img [src]="item.ico"></div>
                    <div class="rightText">
                        <div class="name">{{item.name}}</div>
                        <div class="text">{{item.intro}}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 非会员 -->
        <div class="noVip" *ngIf="!isShowVip">
            <div class="container">
                <div class="left">
                    <div class="card">
                        <div class="top" *ngIf="department">
                            <div class="logo">
                                <img *ngIf="department.logo" [src]="department.logo+'&imageView2/1/w/80/h/80'">
                                <img class="noLogo" *ngIf="!department.logo" src="/assets/images/rights/departmentLogo.png">
                            </div>
                            <div class="text">
                                <div class="name" *ngIf="department?.name">{{department.name}}<img *ngIf="config.lastIdentityNavSatus == 2" src="/assets/images/rights/authentication.png"></div>
                                <div class="lables">{{department.employer_type_text}}｜{{department.scale_text}}｜{{department.industry_text}}</div>
                            </div>
                        </div>
                        <!--非会员-->
                        <div class="bottom" *ngIf="!vipExperience">
                            <div class="tip"><img src="/assets/images/rights/novieRights.png">您还不是工作啦会员</div>
                            <div class="join" (click)="product('','contactMe')">加入会员</div>
                        </div>
                        <!--体验会员-->
                        <div class="bottom active" *ngIf="vipExperience">
                            <div class="diamonds"><img src="/assets/images/rights/diamonds.png"></div>
                            <div class="centerBox">
                                <div class="effectiveTime" *ngIf="vipExperiencedays">您的会员权益体验限期{{vipExperiencedays}}天</div>
                                <div class="time" *ngIf="department.memberTrail.endtime"><img src="/assets/images/rights/vipTime.png">{{department.memberTrail.endtime | date:'yyyy-MM-dd HH:mm:ss'}} 到期</div>
                            </div>
                            <div class="btn" (click)="product('','contactMe')">开通会员</div>
                        </div>
                    </div>
                    <!-- 会员特权 -->
                    <div class="privilege">
                        <div class="bg"><img src="/assets/images/rights/rightBottom.png"></div>
                        <div class="_lf">
                            <div class="img"><img src="/assets/images/rights/privilege.png"></div>
                            <div class="_bm">
                                <img src="/assets/images/rights/miniLogo.png"> 会员特权
                            </div>
                        </div>
                        <div class="_rt">
                            <div class="li">
                                <div class="ii"><span></span></div>
                                根据招聘需求，为您量身定制招聘套餐
                            </div>
                            <div class="li">
                                <div class="ii"><span></span></div>
                                一对一专属服务，为您解决您校招难题
                            </div>
                            <div class="li">
                                <div class="ii"><span></span></div>
                                咨询最新市场活动，获取优惠报价
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="title">用户权益</div>
                    <div class="rightCard">
                        <!-- 左边 -->
                        <div class="colleges" style="display: none;">
                            <div class="_title">
                                <div class="mycolleges">我的高校</div>
                                <div class="number">{{addCollegeList.length}}/3</div>
                            </div>
                            <div class="tip">非会员可以与3所高校进行合作</div>

                            <!-- 未选择 -->
                            <div class="colleges-have" (click)="pushCollege()" *ngIf="addCollegeList.length == 0">
                                <div class="box">
                                    <img src="/assets/images/rights/push.png"> 添加高校
                                </div>
                            </div>
                            <!-- 已选择 -->
                            <div class="colleges-push" *ngIf="addCollegeList.length > 0">
                                <div class="item" *ngFor="let item of addCollegeList">{{item.college_name}}</div>
                                <div class="pushBtn" *ngIf="addCollegeList.length <= 2" (click)="pushCollege()">
                                    <img src="/assets/images/rights/push.png"> 添加高校
                                </div>
                                <div class="becomeVip" (click)="product('','contactMe')" *ngIf="addCollegeList.length == 3">加入会员，添加更多高校</div>
                            </div>
                        </div>
                        <!-- 右边 -->
                        <div class="Administration" style="padding-top: 36px;">
                            <div class="item" (click)="goActivity()">
                                <img src="/assets/images/rights/rightsIcon01.png">
                                <div class="text">
                                    <div class="name">校招活动管理</div>
                                    <div class="_ts">校招不必逐校申请，鼠标点击报名即可</div>
                                </div>
                            </div>
                            <div class="item" (click)="goPosition()">
                                <img src="/assets/images/rights/rightsIcon02.png">
                                <div class="text">
                                    <div class="name">职位管理<span>{{department?.positioncount}}/15</span></div>
                                    <div class="_ts">校招不必线下跑，线上发职位就好</div>
                                </div>
                            </div>
                            <div class="item" (click)="goResume()">
                                <img src="/assets/images/rights/rightsIcon03.png">
                                <div class="text">
                                    <div class="name">简历管理</div>
                                    <div class="_ts">收到的简历一站管理，无须逐校留意收取</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 会员权益 -->
            <div class="noVip-main">
                <div class="title">会员权益</div>
                <div class="main">
                    <div class="topList">
                        <div class="item" *ngFor="let item of rechargeList" (click)="product(item,'recharge')">
                            <div class="icon"><img [src]="item.ico"></div>
                            <div class="rightBox">
                                <div class="name" *ngIf="vipExperience && item.alias =='position_refresh'">
                                    {{item.name}}
                                </div>
                                <div class="name" *ngIf="vipExperience && item.alias =='post_number'">
                                    {{item.name}}
                                </div>
                                <div class="name" *ngIf="vipExperience && item.alias !='position_refresh' && item.alias !='post_number'">
                                    <img src="/assets/images/rights/lock.png"> {{item.name}}
                                </div>
                                <div class="name" *ngIf="!vipExperience"><img src="/assets/images/rights/lock.png">{{item.name}}</div>
                                <div class="text">{{item.intro}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="bottomList">
                        <div class="item">
                            <div *ngFor="let item of memberList;let i = index" (click)="product(item,'member')">
                                <div class="cell" *ngIf="i<3">
                                    <div class="icon"><img [src]="item.ico"></div>
                                    <div class="rightBox">
                                        <div class="name"><img src="/assets/images/rights/lock.png">{{item.name}}</div>
                                        <div class="text">{{item.intro}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div *ngFor="let item of memberList;let i = index" (click)="product(item,'member')">
                                <div class="cell" *ngIf="i>2 && i<6">
                                    <div class="icon"><img [src]="item.ico"></div>
                                    <div class="rightBox">
                                        <div class="name"><img src="/assets/images/rights/lock.png">{{item.name}}</div>
                                        <div class="text">{{item.intro}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div *ngFor="let item of memberList;let i = index" (click)="product(item,'member')">
                                <div class="cell" *ngIf="i>5 && i<9">
                                    <div class="icon"><img [src]="item.ico"></div>
                                    <div class="rightBox">
                                        <div class="name"><img src="/assets/images/rights/lock.png">{{item.name}}</div>
                                        <div class="text">{{item.intro}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div *ngFor="let item of memberList;let i = index" (click)="product(item,'member')">
                                <div class="cell" *ngIf="i>8 && i<12">
                                    <div class="icon"><img [src]="item.ico"></div>
                                    <div class="rightBox">
                                        <div class="name"><img src="/assets/images/rights/lock.png">{{item.name}}</div>
                                        <div class="text">{{item.intro}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fixedBox">
                <div class="_logo"><img src="/assets/images/rights/diamonds.png"></div>
                <div class="btn" (click)="product('','contactMe')">加入会员</div>
            </div>
        </div>


        <!-- 新增高校 -->
        <app-push-college-model #pushCollegeModel [isShow]="isShowPushCollegeModel" (modelHidden)="pushCollegeModelHidden($event)"></app-push-college-model>
        <!-- 产品介绍/请校招顾问联系我 -->
        <app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>

    </div>
</div>