import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {productManager} from "../../provider/productManager";
import {ResumeLabelModelComponent} from "../resume-label-model/resume-label-model.component";
import {VoiceCallModelComponent} from "../voice-call-model/voice-call-model.component";
import {ModelSendMsgComponent} from "../model-send-msg/model-send-msg.component";
import {NoneVipAlertComponent} from "../none-vip-alert/none-vip-alert.component";
import { MatDialog } from '@angular/material/dialog';
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";

declare let Swiper:any;
declare var layui;

@Component({
  selector: 'app-voice-call-record',
  templateUrl: './voice-call-record.component.html',
  styleUrls: ['./voice-call-record.component.css']
})
export class VoiceCallRecordComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true }) 
  productModel: ProductModelComponent;

  public config: Config;
  constructor(
    public dataApi: DataApi,
    public local:Local,
    private sanitizer: DomSanitizer,
    private router: Router,
    public dialog: MatDialog,
    public opera:Opera,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public productManager:productManager
  ) {
    this.config = Config.getInstance();//实例化config
    this.productManager.init();
  }


  public resumeList = [];
  public isOpenMore = false;
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  public majorOne='';//专业类别一级
  public positionList=[];//职位列表
  public educationList=[];//学历列表
  public bannerList = [];
  public majorList = [];
  public isShowMemberMoudel = true;
  public model={
    keywords:'',//关键词
    major:'',//专业
    education:'',//学历
    status:'',//状态
    positionid:'',//职位id
    limit:10,
    offset:0
  };
  @Output() SengMsgModel ={
    userName:'',
    userPic:'',
    talentid:'',
    graduation_school:'',//学校
    major_text:'',//专业
    gender:'',
    address:'',
    positionid:''
  };//打开单品发送极速短信用户对象

  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)

  @Output() isShowVoiceCallModel = false;//是否打开弹窗(简历收件箱)
  @Output() isShowPositionPushModel = false;//是否打开弹窗(简历收件箱)
  @ViewChild('voiceCallModel', { static: true }) voiceCallModel: VoiceCallModelComponent;
  @ViewChild('sengMsgModel', { static: true }) sengMsgModel: ModelSendMsgComponent;

  public phoneModel={
    jiduCode:'',
    positionPushCode:'',
  }


  ngOnInit() {
    this.opera.initConfig('nav-resume-manager','voiceCallRecord');
    this.opera.vipState((isPay)=>{
      this.isShowMemberMoudel =  isPay;
    })
    this.voiceList();
    this.getCardbanner();
    this.getPositionList();
    this.getMajorList();
    this.getEducationList();
    
  }
  
  //点击产品
  product(item,type){
    this.productModel.initData(item.alias,type);
    this.isproductShowModel = true;
  }

  productModelHidden(event){    
    this.isproductShowModel = false;
  }

  changeStatus(status){
    this.model.status = status;
  }
  changeOpenMore(){
    this.isOpenMore = !this.isOpenMore;
  }
  //监听majorOne
  majorOneChange(){
    this.majorListTwo=[];
    for(let n of this.config.major){
      if(n.parentcode==this.majorOne){
        this.majorListTwo.push(n);
      }
    }
    this.model.major='';
  }

  getRecommendList(){
    this.model.offset = 0;
    this.voiceList();
  }
  //获取简历投递列表 参数非必填
  voiceList(){
    let that = this;


    this.dataApi.getCallrecord(this.model).then((res:any)=>{
      if(res)
      {
        this.resumeList = res.list;
        this.countList=res.count;
        this.pageCount =Math.ceil(res.count/10);
        console.log('极速电话联系记录',this.resumeList);

        for(let n of this.resumeList)
        {
          for(let val of n.list)
          {
            if(val.pro_skills==null)
            {
              val.pro_skills = [];
            }
            let arr = [];
            val.yixiang = "";
            val.youshiArr = [];
            for (let item1 of val.pro_skills)
            {
              val.yixiang += item1.text+"    "
              if(item1.contrast_list){
                for (let youshi of item1.contrast_list)
                {
                  val.youshiArr.push(youshi.text);
                }
              }
            }

          }
        }

        console.log('极速电话联系记录',this.resumeList);

      }
    }).catch((err)=>{

    })
  }
  //提示先选一级专业
  isShowTwo(){
    if(!this.majorOne){
      this.toastr.warning("请先选择一级专业");
      return;
    }
  }
  voiceCallClick(e,n){
    e.stopPropagation();


    let that = this;
    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.productManager.getProductUsableCount(this.config.voice_call,(count,isVip)=>{
          if(count==0||!isVip)
          {
            this.config.payVipHistoryPage = {
              page:'极速电话联系记录',
              isVip:isVip,
              pro:'极速电话联系'
            };
            that.local.set("single_category_code",this.config.ProductListCode.voice_call_code);
            that.router.navigate(['home/tool-campus']);
          }
          else
          {
            this.voiceCallModel.initData(n);
            this.isShowVoiceCallModel = true;
          }
        })
      } else {
        that.opera.checkMemberAlertWithType("noneVip_voice_call")
      }
    })
  }

  positionPushClick(e,n){
    e.stopPropagation();
    let that = this;
    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.productManager.getProductUsableCount(this.config.sms_bulkinvitation,(count,isVip)=>{
          if(count==0||!isVip) {
            this.config.payVipHistoryPage = {
              page:'极速电话联系记录',
              isVip:isVip,
              pro:'职位推送'
            };
            that.local.set("single_category_code", this.config.ProductListCode.sms_bulkinvitation_code);
            that.router.navigate(['home/tool-campus']);
          } else {
            this.sengMsgModel.initData(1,[]);
            this.SengMsgModel.userName = n.talent_name;
            this.SengMsgModel.userPic = n.logo==null?'': n.logo;
            this.SengMsgModel.graduation_school = n.graduate_university;
            this.SengMsgModel.major_text = n.major_text;
            this.SengMsgModel.talentid = n.talentid;
            this.SengMsgModel.gender = n.gender;
            this.SengMsgModel.address = n.address_text;
            this.isShowPositionPushModel =true;
          }
        })
      } else {
        that.opera.checkMemberAlertWithType("noneVip_voice_call")
      }
    })
  }
  //极速电话联系弹窗消失
  voiceCallModelHidden(e){
    this.isShowVoiceCallModel = false;
  }
  Modeldismiss(event){
    this.isShowPositionPushModel =false;
    this.config.batchList=[];
  }
  //获取card广告banner
  getCardbanner() {

    let that = this;
    this.dataApi.getCardBannerdata('jianli').then((res:any) => {
      if (res) {
        console.log('获取广告Banner', res);
        this.bannerList = res;
        setTimeout(()=>{
          layui.use(['carousel', 'form'], function() {
            var carousel = layui.carousel
              , form = layui.form;
            //建造实例
            carousel.render({
              elem: '#test1'
              ,width: '100%' //设置容器宽度
              ,arrow: 'always', //始终显示箭头
              height:'160px',
              interval:3000
              //,anim: 'updown' //切换动画方式
            });
            //找到两个按钮，设置样式，因为全局样式又一个Buttom里有一个样式会导致按钮错乱
            let leftbtn =  document.getElementById("lay-type-left");
            let rightbtn =  document.getElementById("lay-type-add");
            if(leftbtn) {
              leftbtn.style.padding ='0';
            }
            if(rightbtn) {
              rightbtn.style.padding ='0';
            }
          })
        },200)
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  bannerClick(n) {
    console.log('bannerClick',n)
    if(n.links)
    {
      window.open(n.links,'_blank');
      return;
    }
  }
  //我要的取数据
  getMajorList(){
    this.opera.loading();//加载动画
    //getagregationCount
    this.dataApi.getagregationCount({}).then((res:any)=>{
      // console.log('我要的取数据',res.facetgroups);
      this.opera.loadiss();
      if(res)
      {
        this.majorList = res.facetgroups;
        res.facetgroups.map((item)=>{
          if(item.level == 1){
            this.majorListOne.push(item)
          }
        })
        //this.majorListOne = res.facetgroups;
         this.getMajorList_major();//获取一级专业列表
      }

      // console.log(res);
      // console.log(this.majorList);
    }).catch((err)=>{
      console.log(err);
      this.opera.loadiss();
    })
  }
  //获取专业列表信息
  getMajorList_major(){
    if(this.dataApi.getMajorCode()){
      this.config.major = this.dataApi.getMajorCode();
      for(let n of this.config.major){
        if(n.level==2){
          this.majorListOne.push(n);
        }
      }
    }else{
      this.dataApi.getDictDate('major').then((res:any)=>{
        if(res)
        {
          this.config.major = res;
          for(let n of this.config.major){
            if(n.level==2){
              this.majorListOne.push(n);
            }
          }
        }

      }).catch((error)=>{
        //console.log(error);
      });
    }
  }
  //获取一级专业列表
  getMajorListOne(){

  }
  getPositionList(){
    let model={
      limit:1000,
      offset:0,
      status:'',
    }
    this.dataApi.getPositionList(model).then((res:any)=>{
      if(res)
      {
        this.positionList = new Array();
        let deleteArr = [];
        let Arr = [];
        for(let item of res.list)
        {
          if( item.position_status == 'delete')
          {
            deleteArr.push(item);
          }
          else
          {
            Arr.push(item);
          }
        }
        let newArr = [...Arr,...deleteArr];
        this.positionList =  newArr;
        this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }
  //获取学历列表信息
  getEducationList(){
      this.dataApi.getDictDate('education').then((res:any)=>{
        if(res)
        {
          this.educationList = res;
          this.dataApi.setEducationCode(res);
        }
      }).catch((error)=>{
        //console.log(error);
      });
    }
  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----college-resume');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*10;
      this.voiceList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*10;
        this.voiceList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*10;
        this.voiceList();//获取简历投递列表
      }
    }

  }
  //去简历详情
  gotoDetail(n){

    let that = this;
    that.config.isNavActive='college';
    that.config.isNavChildActive='college-resume';
    that.router.navigate(['home/college-resumedetails'], {
      queryParams: {
        resumeid:n.resume_number,
      }
    });
  }
  setResumeStatus(id,status){
    this.dataApi.setSendResumeStatus(id,status).then((res:any)=>{
      // this.toastr.success("状态修改成功");
      // for(let n of this.resumeList){
      //   if(n.deliveryid==id){
      //     n.feedback_status=status;
      //   }
      // }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    })
  }

  nav01(){
    this.router.navigate(['home/position-resume']);
  }

  nav02(){
    this.router.navigate(['home/position-resume-like']);
  }

  nav03(){
    this.router.navigate(['home/voiceCallRecord']);
  }

  nav04(){
    this.router.navigate(['home/positionPushRecord']);
  }

  nav05(){
    this.router.navigate(['home/activityInviteRecord']);
  }

  nav06(){
    this.router.navigate(['home/airCampHistoryReord']);
  }
}
