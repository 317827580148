import { Component, OnInit, Input, Output,ViewChild } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import {  ActivatedRoute,Router } from  '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmsComponent } from '../confirms/confirms.component';
declare let laydate;


@Component({
  selector: 'app-company-record',
  templateUrl: './company-record.component.html',
  styleUrls: ['./company-record.component.css']
})
export class CompanyRecordComponent implements OnInit {
  public config: Config;
  public countList = 0;//列表数量
  public recordList=[];//使用记录列表数据
  public sendTime = ''; // 发送日期

  // 筛选条件
  public model={
    recharge_category:'',//产品包类型
    log_time:'',
    offset:0,
    limit:9
  };
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService
  ) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.opera.initConfig('member','company-status-one');
    let that = this;
    laydate.render({
      elem:'#send-time',
      // type: 'datetime',
      done: function(value){
        that.sendTime=value;
      }
    });
    this.getRecordListData();
  }

  //获取职位列表
  getRecordListData(){
    this.opera.loading();
    if(this.sendTime != ''){
      let hour=new Date().getHours();
      let minute=new Date().getMinutes();
      let second=new Date().getSeconds();
      let hour1,minute1,second1;
      if(hour<10){
        hour1='0'+hour;
      }else{
        hour1=String(hour);
      }
      if(minute<10){
        minute1='0'+minute;
      }else{
        minute1=String(minute);
      }
      if(second<10){
        second1='0'+second;
      }else{
        second1=String(second);
      }
      this.model.log_time=this.sendTime+'T'+hour1+':'+minute1+':'+second1+'+08:00';
    }
    this.dataApi.getRecordList(this.model).then((res:any)=>{
      this.recordList =  res.list;
      this.countList = res.count;
      this.pageCount =Math.ceil(res.count/9);
      this.opera.loadiss();
    }).catch((error) => {
      this.toastr.error(error);
      this.opera.loadiss();
    });
  }
  reset(){
    this.model.recharge_category = '';
    this.sendTime = '';
    this.model.log_time = '';
    this.getRecordListData()
  }
  chooseType(type){
    this.model.recharge_category = type;
  }
  // 获取分页子组件返回数据
  getChildEvent(index){
    // console.log('子组件返回分页数-----college-campustalk');
    // console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getRecordListData();//获取列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getRecordListData();//获取列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getRecordListData();//获取列表
      }
    }
  }

}
