import { Component, OnInit } from '@angular/core';
import {DataApi, Local, Opera} from "../../provider";
import {Router} from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {scrollTool} from "../../provider/scrollTool";
import {regexManager} from "../../provider/regexManager";
import {productManager} from "../../provider/productManager";

@Component({
  selector: 'app-collect-form',
  templateUrl: './collect-form.component.html',
  styleUrls: ['./collect-form.component.css']
})
export class CollectFormComponent implements OnInit {

  public model = {
    departmentid:'',
    name:'',
    region:'',
    address:'',
    linkman:'',
    position_name:'',
    mobile:'',
    desc:'',
  }

  constructor(
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public dialog: MatDialog,
    public opera:Opera,
    public toastr: ToastrService,
    public scrolltool:scrollTool,
    public regexManager:regexManager,
    public productManager:productManager
  ) { }

  ngOnInit() {
    let departmentStr =  this.local.get('department')
    if(departmentStr){
      let data = JSON.parse(departmentStr)
      console.log('departmentStr',data)
      this.model.departmentid = data.accountid
      // this.model.name = data.name
    }
  }
  commit(){
    if(!this.model.departmentid){
      this.toastr.warning('请求错误')
      return
    }
    if(!this.model.name){
      this.toastr.warning('请输入用人单位名称')
      return
    }
    if(!this.model.address){
      this.toastr.warning('请输入用人单位所在地')
      return
    }
    if(!this.model.linkman){
      this.toastr.warning('请输入用人单位招聘联系人')
      return
    }
    if(!this.model.position_name){
      this.toastr.warning('请输入招聘联系人职位')
      return
    }
    if(!this.model.mobile){
      this.toastr.warning('请输入招聘联系人电话')
      return
    }
    if(!this.model.desc){
      this.toastr.warning('请输入校招需求简述')
      return
    }
    //interesteddepartmentSavethis
    this.opera.loading();

    this.dataApi.interesteddepartmentSave(this.model).then((res:any)=>{
      console.log("企业信息获取线上数据---------------------------CollegeIdentityPage");
      this.opera.loadiss()
        this.toastr.success('您的需求我们已经收到')
    }).catch((error) => {
      //console.log(error);
      this.toastr.error(error);
      this.opera.loadiss()

    });
  }

}
