<app-navigation-route #appNavigation ></app-navigation-route>
<div class="homepage-block">

  <div class="content">


  <div class="noneDate" *ngIf="newlist.length==0">暂无推荐</div>

  <div class="college-list-cont" *ngIf="newlist.length>0">
    <!--//新版列表-->
    <div class="listCell" *ngFor="let n of newlist;let i = index;">
      <div style="width: 45%;cursor: pointer;display: flex;align-items: center;overflow: hidden" (click)="goDeatils(n)">
        <img style="width: 50px;height: 50px;border-radius: 50%;margin-left: 30px" src="{{n.logo}}" />
        <div>
          <div style="display: flex;align-items: center">
            <div style="margin-left: 15px;font-size: 17px;color:#333333;  overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width: 220px;font-weight: bold">{{n.name}}</div>
            <span class="gongyi" *ngIf="n.is_welfare">专场</span>
            <img class="sign-img uglyImg" *ngIf="n.loadState==1" src="../../assets/images/sign1.png" />
            <img class="sign-img" *ngIf="n.loadState==2" src="../../assets/images/sign2.png" />
            <img class="sign-img" *ngIf="n.loadState==4" src="../../assets/images/sign4.png" />
            <img class="sign-img" *ngIf="n.loadState==5" src="../../assets/images/sign5.png" />
          </div>
          <p class="milkRoundAddressAndTime">
            <img src="../../assets/images/addressIcon.png"  style="width: 12px;height: 16px;margin-top: -3px">
            {{(n.milkround_type == 2?"线上":n.place_name?n.place_name:'暂无')+"   举办时间："}}{{n.start_time| date:"yyyy-MM-dd HH:mm"}}
          </p>
        </div>
      </div>
      <div style="width: 17%;min-width: 175px" (click)="goDeatils(n)">
        <div style="margin-top: 4px;color: #999999;font-size: 13px;text-overflow: ellipsis;white-space: nowrap;">{{"举办学校:"}}   {{n.college_name}}</div>
      </div>
      <div style="width: 38%;">
        <div class="cellButton baoming bordeRadius" *ngIf="n.showBaoMingBtn &&(n.loadState != 1 && n.loadState != 5)" (click)="goSign(n)">我要报名</div>
        <div class="cellButton bordeRadius" (click)="okCanhui(n)" *ngIf="n.showSuccessBtn &&n.feedback_status=='no_feedback'">{{n.milkround_type == 2?'进入会场':'确认参会'}}</div>
        <div class="cellButton bordeRadius"  (click)="ConfirmMilkroundCommit2(n)" *ngIf="n.showSuccessBtn&&n.feedback_status=='join'&&n.milkround_type != 2">已确认</div>
        <div class="cellButton shibai bordeRadius"  *ngIf="n.showSuccessBtn&&n.feedback_status=='no_join'">已拒绝</div>
        <div class="cellButtonYellow zhanwei bordeRadius" *ngIf="n.showSuccessBtn&&n.feedback_status=='join'&&n.milkround_type != 2">{{n.seat_number==0?"展位号暂未分配":'展位号：'+n.zone_name_string+n.seat_number}}</div>
        <div class="cellButton tongzhi bordeRadius" (click)="activityInvitation(n)" *ngIf="n.showSuccessBtn&&n.feedback_status=='join'&&n.curr_total_student>=1000 &&opera.isShowPaySuccessModule1()&&n.milkround_type != 2">通知学生到展位</div>
        <div class="cellButton shenhe bordeRadius" *ngIf="n.showShenHeBtn" (click)="goSign(n)">审核中</div>
        <div class="cellButton shibai bordeRadius" *ngIf="n.showFaileBtn" (click)="goSign(n)">报名失败</div>
        <button class="cellButton bordeRadius" (click)="downloadPoster(n.applyid)" *ngIf="n.status == 'passed' && !n.isEnd && n.poster_type">下载海报</button>
        <img class="air-icon" src="../../assets/images/air_icon.png"  *ngIf="n.milkround_type == 2">
      </div>
    </div>
  </div>
  </div>
</div>
