import { Component, OnInit, Input, Output,ViewChild } from '@angular/core';
import { DataApi,Config,Opera,Local,Constants } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from  '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
//新增高校
import { PushCollegeModelComponent } from "../push-college-model/push-college-model.component";
@Component({
  selector: 'app-college-details-milk',
  templateUrl: './college-details-milk.component.html',
  styleUrls: ['./college-details-milk.component.css']
})
export class CollegeDetailsMilkComponent implements OnInit {
  public config: Config;
  public isInit = false;//是否初始化成功
  public posterUrl = ''; // 双选会海报下载链接
  @Output() modelShow = false;//是否打开弹窗
  @Output() milkNumber = 1;//当前申请的招聘会场次
  public model={
    collegeid:'',
    college_name:'',
    offset:0,
    limit:9
  }
  public milkRoundList = [];//简历列表
  public countList = 0;//列表数量
  @Output() mridList=[];//招聘会id列表
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public collegeid="";//高校id
  public college:any;//高校详情
  public collegeIdentity:any;//企业当前高校认证数据
  showTips:boolean = true; // 是否弹窗
  //高校信息
  public addCollegeList = [];

  //是否打开弹窗(非会员新增高校)
  @Output() isShowPushCollegeModel = false;
  @ViewChild('pushCollegeModel', { static: true })
  pushCollegeModel: PushCollegeModelComponent;
  constructor(public dataApi: DataApi,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public local:Local,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {
    this.opera.initConfig('college','college-campustalk');
    this.collegeid=this.local.get(Constants.collegeid);//赋值高校id
    this.checkDepartmentIndentityByCollege(this.collegeid);//检查高校认证信息
    this.model.collegeid=this.collegeid;
    this.getCollegeById();//获取高校详情
    this.getResumeList();//获取列表
  }

  //接收返回参数
  Modeldismiss(event){
    this.modelShow=false;
    if(event){
      for(let n of this.milkRoundList){
         for(let m of this.mridList){
           if(n.mrid == m){
             n.status='to_audit';
           }
         }
      }
    }
    this.mridList = [];//清空
  }

  //去报名
  goSign(n){
console.log('去报名',n,this.config.lastIdentity.status);

    if(n.cooperation_status == 2){
      this.goMilkSign(n);//报名
    } else if(this.config.lastIdentity.status == 2){
      this.goMilkSign(n);//报名
    }else if(this.config.lastIdentity.status == 1){
      this.dataApi.checkDepCollegeIdentity(n.collegeid).then((res:any)=>{
        if(res)
        {
          if(res.status=='2')
          {
            this.goMilkSign(n);//报名
          }
          else
          {
            this.toastr.warning('认证审核中')
          }
        }
        else
        {
          this.toastr.warning('认证审核中')
        }
      }).catch((err)=>{
        this.toastr.warning('认证审核中')
      })

    }else if(this.config.lastIdentity.status == 3){
      this.dataApi.checkDepCollegeIdentity(n.collegeid).then((res:any)=>{
        if(res)
        {
          if(res.status=='2')
          {
            this.goMilkSign(n);//报名
          }
          else
          {
            this.toastr.warning('认证失败，请查看原因并重新提交')
            this.router.navigateByUrl('/home/company-authentication');//审核失败页面
          }
        }
        else
        {
          this.toastr.warning('认证失败，请查看原因并重新提交')
          this.router.navigateByUrl('/home/company-authentication');//审核失败页面
        }
      }).catch((err)=>{
        this.toastr.warning('认证失败，请查看原因并重新提交')
        this.router.navigateByUrl('/home/company-authentication');//审核失败页面
      })

    }else{
      this.opera.remindCould();
    }

  }

  //去双选会报名页
  goMilkSign(n){
    if(n.scale <= n.department_participate_count){
      this.toastr.warning('坐席已满，无法报名！');
      return
    }
    this.local.set(Constants.collegeid,n.collegeid);
    this.local.set(Constants.mrid,n.mrid);
    this.local.remove(Constants.applyid);
    this.router.navigate(['home/college-milk-sign'], {
      queryParams: {
        milkname:n.name
      }
    });
  }


  //进入会场
  goSpace(id){//招聘会会场无需认证
    this.router.navigateByUrl('home/college-milk-space');
    this.local.set(Constants.mrid,id);//覆盖本地存储招聘会id
  }

  //判断企业是否通过高校认证
  checkDepartmentIndentityByCollege(id){
    this.opera.loading();
    this.dataApi.checkDepCollegeIdentity(id).then((res:any)=>{
      //console.log("获取企业在高校的认证信息-----------------------CampusDetails");
      //console.log(res);
      this.collegeIdentity=res;
      this.opera.loadiss();
    }).catch((err)=>{
      //console.log(err);
      this.opera.loadiss();
    })
  }

  //去双选会详情
  goDeatils(id){
    this.router.navigate(['home/college-milkdetails'],{
      queryParams:{
        id:id
      }
    })

  }


  //获取列表
  getResumeList(){
    this.opera.loading();
    this.dataApi.getMilkRoundList(this.model).then((res:any)=>{
      //console.log("获取高校双选会列表---");
      //console.log(res);
      this.milkRoundList=res.list;
      if(this.showTips){
        for(let item of this.milkRoundList){
          console.log(item);
          if(item.status == 'passed'){
            //this.singleTips(item);
            this.showTips = false;
            break;
          }
        }
      }
      for(let val of this.milkRoundList){
        let nowDate = new Date();
        let startDate = new Date(val.start_time);
        let endDate = new Date(val.end_time);
        let applyOpDate = new Date(val.apply_start_time); // 报名开始时间
        let applyEndDate = new Date(val.apply_end_time); // 报名结束时间
        let nowTime = nowDate.getTime();  // 当前时间戳
        let startTime = startDate.getTime();  // 开始时间戳
        let endTime = endDate.getTime();  // 结束时间戳
        let applyOpTime = applyOpDate.getTime();  // 报名开始时间戳
        let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳
        val.signOpTime = false;
        if(applyOpTime == -62135625943000 || applyOpTime == -62135625600000 || val.apply_start_time == '0001-01-01T00:00:00' || val.apply_start_time == '0001-01-01T00:00:00+08:00' || val.apply_start_time == ''){
          val.signOpTime = false; // 未设置报名开始时间
        }else{
          val.signOpTime = true;
        }
        if(applyEndTime == -62135625943000 || applyEndTime == -62135625600000 || val.apply_end_time == '0001-01-01T00:00:00' || val.apply_end_time == '0001-01-01T00:00:00+08:00' || val.apply_end_time == ''){
          val.signEdTime = false; // 未设置报名结束时间
        }else{
          val.signEdTime = true;
        }
        if(nowTime-startTime>0){
          val.isApplySign=false;
          val.isStart=true; // 会议已开始
        }else{
          val.isStart=false;  // 会议未开始
          val.isApplySign=true;
        }
        //增加是否是活动判断
        if(val.is_activity){
          if(endTime-nowTime>0){
            val.isApplySign=true;
          }else{
            val.isApplySign=false;
          }
        }
        if(nowTime-endTime>0){
          val.isEnd=true; // 会议已结束
        }else{
          val.isEnd=false; // 会议未结束
        }
        if(val.signOpTime){ // 有报名时间和报名结束时间
          if(nowTime-applyOpTime>0){
            val.isAppStart=true; // 报名已开始
          }else{
            val.isAppStart=false; // 报名未开始
          }
          if(nowTime-applyEndTime>0){
            val.isAppEnd=true; // 报名已结束
          }else{
            val.isAppEnd=false; // 报名未结束
          }
        }
      }
      this.countList=res.count;
      this.pageCount =Math.ceil(res.count/9);
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
    })
  }
  //通过高校id获取高校详情
  getCollegeById(){
    this.opera.loading();
    this.dataApi.getCollageDetails(this.collegeid).then((res:any)=>{
      //console.log("获取到高校信息---");
      //console.log(res);
      this.isInit = true;
      this.college = JSON.parse(JSON.stringify(res));
      this.opera.loadiss();
    }).catch((err)=>{
      this.toastr.error(err);
      this.isInit = true;
      this.opera.loadiss();
    });
  }
  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getResumeList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }
  }

  // 获取双选会海报下载链接
  downloadPoster(applyid){
    this.opera.loading();
    this.dataApi.getMilkPoster(applyid).then((res:any)=>{
      if(res.value){
        let b = window.open();
        setTimeout(function(){
          b.location.href = res.value;
        }, 500);
        this.opera.loadiss();
      }
    }).catch((err)=>{
      console.log(err);
      this.opera.loadiss();
    })
  }

  downloadIamge(imgsrc, name) {//下载图片地址和图片名
    let image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function() {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      let a = document.createElement("a"); // 生成一个a元素
      let event = new MouseEvent("click"); // 创建一个单击事件
      a.download = name || "photo"; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = imgsrc;
  }


  //获取合作高校
  mycollegeList() {
    this.dataApi.mycollegeList().then((res: any) => {
      if (res) {
        this.addCollegeList = res;
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  //去教学机构
  goOrg() {
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.local.set(Constants.collegeid, that.college.collegeid);
        that.router.navigateByUrl("home/college-details-org");
      }else {
        //that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.local.set(Constants.collegeid, that.college.collegeid);
          that.router.navigateByUrl("home/college-details-org");
        } else {
          this.pushCollege();
        }
      }
    })

  }
  //去双选会
  goMilk() {

    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        this.router.navigateByUrl("home/college-details-milk");
      } else {
        // that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          this.router.navigateByUrl("home/college-details-milk");
        } else {
          this.pushCollege();
        }
      }
    })

  }
  //去宣讲会
  goCampus() {
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.router.navigateByUrl("home/college-details-campus");
      } else {
        //that.opera.checkMemberAlertWithType("goCampus")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.router.navigateByUrl("home/college-details-campus");
        } else {
          this.pushCollege();
        }
      }
    })
  }
  //去新闻公告
  goNews() {

    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.router.navigateByUrl("home/college-details-news");
      }else {
        //that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.router.navigateByUrl("home/college-details-news");
        } else {
          this.pushCollege();
        }
      }
    })
  }

  //去生源速览
  goStudent() {
    this.local.remove('college-details-student-model');
    this.local.remove('college-details-student-modelp');
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.router.navigateByUrl("home/college-details-student");
      } else {
        //that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.router.navigateByUrl("home/college-details-student");
        } else {
          this.pushCollege();
        }
      }
    })
  }

   //新增高校
  pushCollege() {
    this.isShowPushCollegeModel = true;
    this.pushCollegeModel.initData();
  }

  pushCollegeModelHidden(event) {
    this.isShowPushCollegeModel = false;
    this.mycollegeList();
  }
}
