<app-navigation-route></app-navigation-route>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <div class="container">
    <div class="box">
      <div class="cell" *ngFor="let n of productList">
        <img class="icon" src="{{n.ico}}"/>
        <div class="rightView2" (click)="gotoDetail(n)">
          查看详情
        </div>
        <div class="rightView">
          <div class="titleTopView">
            <span class="title">{{n.name}}</span>
            <span class="yiyong">已用：{{n.old}}</span>
            <span class="yiyong">余额：{{n.left}}</span>
          </div>
        </div>
      </div>
      <div class='paging-block' [class.isPageHeight]="countList>0">
        <router-outlet></router-outlet>
        <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
      </div>
    </div>
  </div>
</div>
