<!-- 职位推送、批量邀投简历 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border':'0','background':'none','borderRadius': '10px'}" [(visible)]="isShow" [style]="{'padding':0,'border':0,'background':'none','box-shadow':'unset','width':592,'height':750,'minHeight':750}"
    (onHide)="dismiss(false)" [modal]=true>
    <div class="model-Box pushMain">
        <!-- 职位推送 -->
        <div class="model-cont" *ngIf="pageType==1">
            <div class="clos" (click)="dismiss(false)"><img src="/assets/images/v3/speedClos.png"></div>
            <!-- 上层提示部分 -->
            <div class="_title">
                <div class="icon"><img src="/assets/images/v3/positionPush.png"></div>
                <div class="rightBox">
                    <div class="name">职位推送</div>
                    <div class="text">精准触达，揽获更多精准投递</div>
                </div>
            </div>
            <div class="personnel">
                <div class="logo">
                    <img *ngIf="SengMsgModel.userPic==''&& SengMsgModel.gender=='01'" src="assets/images/male.png" class="headImage">
                    <img *ngIf="SengMsgModel.userPic=='' && SengMsgModel.gender=='02'" src="assets/images/female.png" class="headImage">
                    <img *ngIf="SengMsgModel.userPic=='' && SengMsgModel.gender==null" src="assets/images/male.png" class="headImage">
                    <img *ngIf="SengMsgModel.userPic=='' && SengMsgModel.gender==''" src="assets/images/male.png" class="headImage">
                    <img *ngIf="SengMsgModel.userPic!=''" [src]="SengMsgModel.userPic" class="headImage">
                </div>
                <div class="rightBox">
                    <div class="name">{{SengMsgModel.userName}}</div>
                    <div class="text">
                        <span>{{SengMsgModel.graduation_school}}</span>
                        <span *ngIf="SengMsgModel.major_text">{{' | ' +SengMsgModel.major_text}}</span>
                        <span *ngIf="SengMsgModel.address">{{' | ' +SengMsgModel.address}}</span>
                    </div>
                </div>
            </div>
            <div class="model-form">
                <div class="modelBox active">
                    <div class="lable">沟通职位</div>
                    <div class="val">
                        <select class="downLoad_right" name="city" lay-verify="required" [(ngModel)]="positionid">
                            <option value="" readonly="">请选择职位</option>
                            <option  *ngFor="let x of tconfig.positionList" value={{x.positionid}}>{{x.name}}</option>
                        </select>
                        <div class="_positionPush" *ngIf="tconfig.positionList.length == 0">暂无职位<span (click)="_positionPush()">去发布职位</span></div>
                    </div>
                </div>
                <div class="modelBox">
                    <div class="lable">招聘电话</div>
                    <div class="val">
                        <input type="text" [(ngModel)]="phone" (ngModelChange)="changeinput($event)" placeholder="请输入HR招聘电话" />
                    </div>
                </div>
            </div>
            <div class="smsContent">
                <div class="tip">推送示例</div>
                <div class="main">{{content1}}</div>
            </div>
            <div class="btn blueStreamer" (click)="commitSendMsg()">推送</div>
            <p class="p3" (click)="product('sms_bulkinvitation','contactMe')">购买资源</p>
            <div class="bottomText">
                <div class="p1">需消耗<span>1</span>次资源(当前余量<span>{{sms_bulkinvitation ? sms_bulkinvitation.left : 0}}</span>)</div>
                <div class="p1">1-2日后，您可在【简历管理】-【职位推送记录】中查看推送记录。</div>
            </div>
        </div>

        <div class="model-cont" *ngIf="pageType==2">
            <!-- 上层提示部分 -->
            <div class="model-head clear">
                <div class="left">批量邀投简历</div>
                <button class="right" (click)="dismiss(false)">×</button>
            </div>
            <!-- 中间内容部分 -->
            <div class="model-content marginTop80">
                <div class="layui-input-block">
                    <span style="float: left;margin-top:5px">选择职位：</span>
                    <select name="city" lay-verify="required" style="width: 300px;height: 40px;float: left;font-size: 16px" [(ngModel)]="positionid">
            <option value="" readonly="">请选择邀投职位</option>
            <option  *ngFor="let x of tconfig.positionList" value={{x.positionid}}>{{x.name}}</option>
            </select>
                </div>


                <div class="bottomDiv marginTop30">
                    <!-- <span  class="bottomSpan1">{{'需消耗1次短信（当前余量'+msgCount+'人次）'}}<img (click)="AlertChip()" src="../../assets/images/yiwen.png" style="width: 18px;height: 18px" ></span> -->
                    <span class="bottomSpan1">{{"需消耗"+userList.length+"条短信（当前余量"+msgCount+'条）'}}</span>
                    <span class="bottomSpan2" (click)="commitYaoQing()">邀请投递</span>
                </div>

            </div>
        </div>

        <div class="model-cont" *ngIf="pageType==3">
            <!-- 上层提示部分 -->
            <div class="model-head clear">
                <div class="left">批量邀投简历</div>
                <button class="right" (click)="dismiss(false)">×</button>
            </div>
            <!-- 中间内容部分 -->
            <div class="model-content marginTop80">
                <table class="layui-table">
                    <colgroup>
                        <col width="120">
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th style="text-align: center">姓名</th>
                            <th style="text-align: center">批量邀投发送状态</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor=" let item of resultList" style="height: 30px">
                            <td>{{item.user_name}}</td>
                            <td *ngIf="item.is_success" style="text-align: left;padding-top: 5px;padding-bottom: 5px"><span class="layui-badge-dot layui-bg-green"></span>{{'&nbsp;&nbsp;'+item.msg}}</td>
                            <td *ngIf="!item.is_success" style="text-align: left;padding-top: 5px;padding-bottom: 5px"><span class="layui-badge-dot"></span>{{'&nbsp;&nbsp;'+item.msg}}</td>
                        </tr>
                    </tbody>
                </table>

            </div>

        </div>
    </div>

</p-dialog>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>
<!-- 遮罩层公共样式 -->
<!-- <div class="model-block select-block" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>

    

</div> -->