import { Injectable } from '@angular/core';
import { Events } from '../provider/eventService';;
import { Api } from "./api";
import { Config } from "./config";
import { Local } from "./local";
import { Constants } from "./constants";

// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/toPromise';

/**
 * 这里用来封装一些与数据请求相关的业务逻辑
 * 当程序规模增大时，需要分离此文件
 * 1.业务上的对外http数据请求接口，统一在此处
 * 2.某些从localstorage获取数据的接口
 */
@Injectable()
export class DataApi {
    private config: Config;
    constructor(private api: Api, private local: Local, public events: Events) {
        //获取单例config
        this.config = Config.getInstance();
    }

    ///////////////////////////////////////////////////////////////////////登录相关接口
    //获取验证码(手机pc登陆)
    postMobileSmsVcode(data) {
        return this.api.agentPost('/topsystem/smsvcode', data);
    }
    //获取验证码（修改手机号）
    postMobileSmsVcodeApp(data) {
        return this.api.agentPost('/topsystem/smsvcode/app', data);
    }
    //获取图片验证码
    getImgCode() {
        return this.api.get('/topsystem/vcode')
    }
    //通过手机验证码获取code
    postMobileSmsCode(data) {
        return this.api.agentPost('/user/mobile/sms', data);
    }
    //通过code换tooken
    postCodeTradeToken(data) {
        return this.api.agentPost('/api/oauth2/access_token', data);
    }
    ///验证手机号码、固话、邮箱
    phoneVerification(data) {
        return this.api.post('/topsystem/formatvalidation', data);
    }

    ///////////////////////////////////////////////////////////////////招聘简章相关接口
    //创建招聘简章
    creatRules(data) {
        return this.api.post('/position_brief/create', data)
    }
    //招聘简章列表
    getRulesList(data) {
        return this.api.get('/position_brief', data)
    }
    //修改招聘简章
    editorRules(id, data) {
        return this.api.post('/position_brief/' + id + '/update', data)
    }
    //重新向高校提交
    editorCollegeRules(data) {
        return this.api.post('/position_brief/' + data.briefid + '/college/' + data.collegeid + '/update')
    }
    //删除招聘简章
    deleteRules(id) {
        return this.api.post('/position_brief/' + id + '/delete')
    }
    //获取招聘简章详情
    getRulesDetails(data) {
        return this.api.get('/position_brief/' + data)
    }
    //获取招聘简章审核高校列表
    getRulesCollegeList(id, data) {
        return this.api.get('/position_brief/' + id + '/college/list', data)
    }
    ////////////////////////////////////////////////////////////////首页相关接口
    //招聘进度
    jobProgress() {
        return this.api.get('/statistics/department/progress');
    }
    //合作院校
    collegePart() {
        return this.api.get('/statistics/department/cooperation');
    }
    ///////////////////////////////////////////////////////////////////////当前登录的用户(user信息)
    //获取登录用户信息
    getUser() {
        return this.api.get('/user');
    }
    //修改登录用户信息
    updateUser(data) {
        return this.api.post('/user/update', data);
    }

    //用户修改手机号
    userChangeMobile(data) {
        return this.api.post('/user/mobile/change', data);
    }

    ///////////////////////////////////////////////////////////////////////企业信息接口
    //新企业完善信息(引导)
    setOneStep(data) {
        return this.api.post('/department/onestep', data);
    }
    //重新企业完善信息(引导)
    resetOneStep(data) {
      return this.api.post('/department/perfect', data);
    }
    //--------引导页检测企业全称是否重复
    testDepartmentName(data) {
        return this.api.get('/department/name/count', data)
    }
    //上传头像(登录用户的头像,不是logo)更新管理员头像(base64)
    updateUserAvatar(data) {
        return this.api.post('/user/update/avatar/app', data);
    }
    //获取企业信息
  getDepartment() {
    return this.api.get('/account_user/department');
  }
    //保存/更新 企业基本信息
    updateDepartment(data) {
        return this.api.post('/department/update', data)
    }
    //上传企业logo
    uploadDepartmentLogo(data) {
        return this.api.post('/department/logo/app', data)
    }
    //保存企业标签
    uploadDepartmentLable(data) {
        return this.api.post('/department/lable', data)
    }
    //保存公司实力标签
    uploadDepartmentStrength(data) {
        return this.api.post('/department/strength', data)
    }
    //添加企业产品
    createDepartmentProduct(data) {
        return this.api.post('/department/product/create', data)
    }
    //修改企业产品
    uploadDepartmentProduct(_pid, data) {
        return this.api.post('/department/product/' + _pid + '/update', data)
    }
    //删除企业产品
    deleteDepartmentProduct(_pid) {
        return this.api.post('/department/product/' + _pid + '/delete')
    }
    //上传企业相关图片
    uploadDepartmentImg(data) {
      return this.api.post('/department/uploadimage/app', data)
    }
    uploadDepartmentFile(data) {
      return this.api.post('/department/uploadfile', data)
    }
    //设置企业展示
    uploadDepartmentShow(data) {
        return this.api.post('/department/show', data)
    }

    //添加企业发展历程
    createDepartmentProgress(data) {
        return this.api.post('/department/course/create', data)
    }
    //修改企业发展历程
    uploadDepartmentProgress(_pid, data) {
        return this.api.post('/department/course/' + _pid + '/update', data)
    }
    //删除企业发展历程
    deleteDepartmentProgress(_pid) {
        return this.api.post('/department/course/' + _pid + '/delete')
    }
    //企业申请平台认证
    authenticationPlatformApply(data) {
        return this.api.post('/authentication/platform/apply', data)
    }
    //获取企业平台认证信息(新)
    authenticationPlatformApplyNew() {
      return this.api.get('/authentication/platform/apply/renewal')
    }
    //企业修改平台认证
    authenticationUpdatePlatformApply(data) {
        return this.api.post('/authentication/platform/apply/update', data)
    }

    //获取企业平台认证信息
    getPlatformApply() {
        return this.api.get('/authentication/platform/apply')
    }
    //获取会员信息(新)
    getMemberInfo() {
      return this.api.get('/department/member/info')
    }
    //企业申请校招
    uploadDepartmentIdentity(data) {
        return this.api.post('/authentication/apply', data)
    }
    //获取企业上次认证信息
    lastAuthenticationIdentity() {
        return this.api.get('/authentication/lastapply')
    }
    //获取企业上次认证信息(高校)
    lastCollegeAuthenticationIdentity(data) {
        return this.api.get('/authentication/' + data + '/lastapply');
    }

    //上传企业认证相关图片
    uploadAuthImg(data) {
        return this.api.post('/department/uploadauthimage/app', data)
    }
    //企业获取认证的高校列表
    getCollegeIdentityList(data) {
        return this.api.get('/authentication/department/college/list', data);
    }
    // 获取企业信息
    getEmployment() {
        return this.api.get('/department')
    }

    //更新企业的名称和营业执照
    updateDepartmentNameAndPic(data) {
      return this.api.post('/authentication/platform/apply/businesslicence/update', data);
    }
    //更新企业的简称
    updateDepartmentShortName(data) {
      return this.api.post('/authentication/platform/apply/commonname/update', data);
    }

    //获取企业申请认证信息
    getCompanyStatus(data){
      return this.api.get('/department/' + data + '/apply');
    }
    //获取申请修改简称的状态
    getCompanyUpdateStatus(){
      return this.api.get('/authentication/platform/apply/commonname/get');
    }

    /////////////////////////////////////////////////////////////////////支付相关接口

    postPayCharge(data){
        return this.api.post('/payment/charge',data);
    }
    //充值啦豆
    buyLaCoin(data) {
      return this.api.post('/payment/lacoin/charge',data);
    }
    //获取我的啦豆余额
    getMyLaCoinCount(data)
    {
      return this.api.get('/consume/balance',data);
    }
    //获取我的优惠卷列表
    getMyCardList(data)
    {
      return this.api.get('/coupon/department/list',data);
    }
    //检查微信扫码付款状态
    getPayStatus(chargeid) {
      return this.api.get('/payment/' + chargeid + '/chackcharge');
    }
    getPayStatusNew(chargeid) {
      return this.api.get('/payment/V2/order/' + chargeid + '/pay/check');
    }
    //根据订单编号获取需支付金额
    getPayAmount(orderid) {
        return this.api.get('/consume/calculate/' + orderid + '/pay');
    }
    //订单付款
    payOrderById(orderid) {
        return this.api.post('/order/' + orderid + '/paid');
    }

    //获取材料标准（认证:authentication 双选会申请:milkround 宣讲会申请:campustalk）
    getMaterial(material, data) {
        return this.api.get('/material/standard/' + material + '/public', data);
    }
    //获取材料标准（认证:authentication 双选会申请:milkround 宣讲会申请:campustalk）
    getWeiChatCode(data) {
      return this.api.post('/wechat/department/makeercode', data);
    }
    //注册快速发布职位
    fastCreatePosition(data) {
      return this.api.post('/position/create/fast', data);
    }
    ///////////////////////////////////////////////////////////////////////职位相关接口
    //发布职位
    createPosition(data) {
        return this.api.post('/position/create', data);
    }
    //修改职位
    updatePosition(_positionid, data) {
        return this.api.post('/position/' + _positionid + '/update', data);
    }
    //获取该部门下在招职位的职位类别
    getPostTypeList() {
        return this.api.get('/position/post_type')
    }

    //根据所属行业获得推荐职位类别
    getPositionPostType() {
        return this.api.post('/position/industry/post_type')
    }
    //获取某字典下code对应其他字典的结果
    getRecommendList(data) {
        return this.api.get('/dict/contrast/' + data.source_dict_type + '/' + data.source_code + '/' + data.result_dict_type)
    }

    //获取工作台数据
    getWorkSpaceData() {
      return this.api.get('/statistics/department/workbench')
    }
    //获取推荐列表
    getRecommendResumeList(data) {
      return this.api.get('/resume/recommendation',data)
    }
    //获取企业热力值
    getCompanyHotVal(id) {
      return this.api.get('/activity/ccbea2019/'+id+'/score')
    }
    //获取企业前100名
    getCompany100(data) {
      return this.api.get('/activity/ccbea2019/score/list',data)
    }
    //获取职位列表
    getPositionList(data) {
        return this.api.get('/position', data)
    }

    //下拉获取职位列表
    getDropDownPositionList(data) {
      return this.api.get('/position/namelist', data)
    }
    //获取代办事项列表
    getTodoList(data) {
      return this.api.get('/tasktodo/department/list', data)
    }
    //查阅待办事项
    viewTodo(id) {
      return this.api.post('/tasktodo/'+id+'/view')
    }
    doneTodo(id) {
      return this.api.post('/tasktodo/'+id+'/done')
    }
    //获取单品使用记录
    getRecordList(data) {
      return this.api.get('/productlog/department/list', data)
    }
    //删除职位
    deletePosition(_positionid) {
      return this.api.post('/position/' + _positionid + '/delete')
    }
    //检查是否为敏感词
    isSensitive(data) {
      return this.api.post('/sensitive_word/department/check/result',data)
    }
    //职位下线
    managePositionExpired(_positionid) {
        return this.api.post('/position/' + _positionid + '/expired')
    }
    //职位重发
    managePositionResend(_positionid) {
        return this.api.post('/position/' + _positionid + '/online')
    }
    //职位刷新
    managePositionRefresh(_positionid) {
      return this.api.post('/position/' + _positionid + '/refresh')
    }
    //根据职位positionid获取职位详情
    getPostDetails(_positionid) {
        return this.api.get('/position/' + _positionid)
    }

    //根据职位类别获取推荐职位描述GET /position/{post_type}/post_desc
    getPositionDescription(post_type) {
        return this.api.get('/position/' + post_type + '/post_desc')
    }

    //匹配企业所有职位
    getPositionMatchName(data) {
        return this.api.post('/position/name/matching', data);
    }

    /////////////////////////////////////////////////////////////////////// 职位实时状态相关接口
    //职位浏览记录(谁看过我)
    getPositionViewHistory(data) {
        return this.api.get('/position/view/history', data)
    }
    //职位收藏记录(谁收藏了我)
    getPositionCollectionHistory(data) {
        return this.api.get('/position/collection/history', data)
    }
    //匹配职位名称
    positionNameMatch(data) {
        return this.api.post('/position/name/matching?name=' + data);
    }

    ///////////////////////////////////////////////////////////////////////人才简历相关接口
    //获取简历列表(推荐的简历)
    getResumeList(data) {
        return this.api.get('/resume/recommendation', data);
    }
    //根据条件搜索简历
    getSearchResumeList(data) {
        return this.api.get('/resume/searcher', data);
    }
    // 获取指定高校专业代码对应的系统专业代码
    getDictMajor(collegeid){
        return this.api.get('/dict/'+collegeid+'/major');
    }
    getDictMajor2(collegeid){
      return this.api.get('/department/college/'+collegeid+'/faculty/dict');
    }
    getZhuanYe(collegeid){
      return this.api.get('/major/'+collegeid+'/list/public');
    }
    getDiscipline(){
      return this.api.get('/resume/search/agregation');
    }
    //普通搜索D
    getSearch(data){
        return this.api.get('/resume/search',data);
    }
    //条件搜索简历
    getFuzzySearch(data){
      return this.api.get('/resume/fuzzy/query',data);
    }
    //高级搜索
    getAdvancedSearch(data){
        return this.api.get('/resume/search/advanced',data);
    }
    //获取简历详情(通过简历编号)
    getResumeNumView(_resumeNumber, data = {}) {//给data赋初始值为一个空对象
        return this.api.get('/resume/' + _resumeNumber.resume_number + '/view?is_search=' + _resumeNumber.is_search, data);
    }
    //获取简历详情（通过简历ID）/resume/senior/{resumeid}
    getResumeIdView(_resumeid) {
        return this.api.get('/resume/' + _resumeid);
    }
    //收藏简历
    manageResumeArchived(data) {
        return this.api.post('/resume/archived/create', data);
    }
    //取消简历收藏
    manageResumeArchivedDelete(resumeid) {
        return this.api.post('/resume/archived/' + resumeid + '/delete');
    }
    //收藏简历列表
    getResumeArchivedList(data) {
        return this.api.get('/resume/archived/searcher', data);
    }
    //投递简历列表
    getTypesSendResumeList(data){
        return this.api.get('/delivery/searcher',data);
    }

    // tab 简历数量分类统计
    getResumeListCount(){
      return this.api.get('/delivery/searcher/count');
  }

    getDeliveryRecord(id){
      return this.api.get('/delivery/'+id);
    }
    //获取分享简历key
    getResumeShareKey(resumeid, channels) {
        return this.api.get('/resume/' + resumeid + '/department/share/' + channels + '/key');
    }
    //投递简历列表
    getSendResumeList(data) {
        return this.api.get('/delivery/searcher', data);
    }
    ///投递反馈delivery/{deliveryid}/feedback
    setSendResumeStatus(id, data) {
        return this.api.post('/delivery/' + id + '/feedback?feedback_status=' + data);
    }
    ///////////////////////////////////////////////////////////////////////面试通知相关接口
    //面试日程列表
    getNoticeList(data) {
        return this.api.get('/interview/department/list', data);
    }
    //发送面试通知
    sendNotice(data) {
        return this.api.post('/interview/send', data);
    }
    //企业取消面试通知
    cancelNotice(_interviewid) {
        return this.api.post('/interview/' + _interviewid + '/cancel');
    }
    //企业修改面试邀请状态/interview/{interviewid}/deprtment/feedback
    UpdateInterview(_interviewid, data) {
        return this.api.post('/interview/' + _interviewid + '/deprtment/feedback?feedback_status=' + data);
    }

    ///////////////////////////////////////////////////////////////////////IM相关接口
    //获取对方的极光ID(扣除资源)
    getImInit() {
        return this.api.get('/im/auth_payload');
    }
    //获取对方的极光ID(扣除资源)
    getChatIm(data) {
        return this.api.post('/im/chat/check', data);
    }
    //获取会话列表(分页)
    getImDialogueList(data) {
        return this.api.get('/im/dialogue/list', data);
    }
    //获取会话列表(不分页)
    getImDialogueAllList(data) {
        return this.api.get('/im/dialogue/alllist', data);
    }
    //获取聊天记录(根绝当前会话的ID)
    getChatList(_dlid, data) {
        return this.api.get('/im/chat/' + _dlid + '/list', data);
    }

    //根据对方极光ID获取对话记录信息
    getByJmChatList(data) {
        return this.api.get('/im/dialogue/' + data);
    }
    //获取聊天记录(根据极光用户名)()
    getJmChatList(username, data) {
        return this.api.get('/im/chat/jm/' + username + '/list', data);
    }
    //发送消息
    sendChat(data) {
        return this.api.post('/im/chat/create', data);
    }
    //聊天图片上传
    uploadChatImg(data) {
        return this.api.post('/im/image/upload', data);
    }
    //设置当前聊天记录为已读
    dlidRead(_dlid) {
        return this.api.post('/im/chat/' + _dlid + '/read', { dlid: _dlid });
    }

    //获取招聘会详情（单个）
    getMilkDetails(mrid) {
        return this.api.get('/milkround/' + mrid + '/public');
    }

    getMilkDetailsWithDepartment(mrid) {
      return this.api.get('/milkround/' + mrid + '/department');
    }

    //申请参加招聘会
    signMilk(mrid, data) {
        return this.api.post('/milkround/' + mrid + '/apply/department', data);
    }
    //进入招聘会-----获取简历列表
    getMilkResumeList(mrid, data) {
        return this.api.get('/milkround/' + mrid + '/live/department', data);
    }

    //获取招聘会弹幕信息
    getMilkBarrage(mrid) {
        return this.api.get('/milkround/' + mrid + '/live/barrage');
    }

    // 企业获取双选会海报
    getMilkPoster(applyid){
        return this.api.get('/milkround/apply/department/' + applyid + '/poster');
    }

    //企业获取开放宣讲会申请高校列表
    getCampustalkList(data) {
        return this.api.get('/campustalk/open/list', data);
    }

    //企业申请宣讲会
    getCampustalkSign(collegeId, data) {
        return this.api.post('/campustalk/' + collegeId + '/apply', data);
    }
    //获取宣讲会详情（单个）
    getCampustalk(ctid) {
        return this.api.get('/campustalk/' + ctid + '/public');
    }
    //企业获取企业申请过的宣讲会和招聘会列表
    getMilkTalkList(data) {
        return this.api.get('/mc/apply/list/department', data);
    }
    //获取当前高校的宣讲会申请记录/campustalk/{collegeid}/apply/list
    getCampustalkByCollegeid(collegeid, data) {
        return this.api.get('/campustalk/' + collegeid + '/apply/list', data);
    }

    ////////////////////////////////////////////////////////////////////////高校相关
    // 获取高校列表
    getCollegeList(data) {
        return this.api.get('/college/department/list', data)
    }
    //获取高校信息
    getCollageDetails(collegeid) {
        return this.api.get('/college/' + collegeid);
    }
    //订阅高校公众号（根据高校ID）
    getCollegeAccount(collegeid) {
        return this.api.post('/im/official_account/' + collegeid + '/csubscribe');
    }
    //取消订阅高校公众号（根据高校ID）
    unCollegeAccount(collegeid) {
        return this.api.post('/im/official_account/' + collegeid + '/uncsubscribe');
    }
    //获取高校招聘会列表
    getMilkRoundList(data) {
        return this.api.get('/milkround/list/public', data);
    }
    //获取高校公众号菜单
    getCollegeMenu(accountid) {
        return this.api.get('/im/official_account/' + accountid + '/menu');
    }

    //获取高校就业信息文章列表
    getCollegeJobList(data) {
        return this.api.get('/article/list/public', data);
    }
    //获取高校就业信息文章详情
    getCollegeJobDetails(articleid) {
        return this.api.get('/article/n' + articleid + '/public');
    }
    //获取高校院系列表
    getCollegePart(collegeid) {
        return this.api.get('/college/' + collegeid + '/faculty/list/public');
    }
     //获取高校院系列表(校区联动筛选)
     getCollegeCampusPart(collegeid,data){
        return this.api.get('/college/'+collegeid+'/faculty/list/public',data);
    }
    //获取院系的专业列表
    getCollegeMajor(facultyid) {
        return this.api.get('/major/' + facultyid + '/list/public');
    }
    //获取院系详情
    getCollegeFaculty(facultyid) {
        return this.api.get('/college/faculty/' + facultyid + '/public')
    }
    //根据编码获取高校信息
    getCollegeByCode(code) {
        return this.api.get('/college/code/' + code);
    }
    //获取校区列表
    getCollegeCampusList(collegeid){
        return this.api.get('/campus/'+collegeid+'/list/public')
    }
    //获取文章详情
    getArticleDetails(id) {
        return this.api.get('/article/' + id + '/public');
    }
    //获取双选会列表（所有双选会）
    getDepartmentMilkList(data) {
        return this.api.get('/milkround/department/list', data)
    }
    //获取双选会列表 我可以操作的
    getDepartmentMilkListWithMy(data) {
      return this.api.get('/milkround/department/not_apply/list', data)
    }
    //获取置顶双选会
    getDepartmentMilkOneTop(data) {
      return this.api.get('/milkround/top/one/public', data)
    }
  //获取企业申请的双选会列表
    getDepartmentSignMilkList(data) {
        return this.api.get('/milkround/apply/list/department', data)
    }
    //获取企业申请的宣讲会列表
    getDepartmentSignCampusList(data) {
        return this.api.get('/campustalk/apply/list', data);
    }
    //修改双选会报名申请
    updateMilkSign(id, data) {
        return this.api.post('/milkround/apply/department/' + id + '/update', data)
    }
    //修改宣讲会报名申请
    updateCampusSign(id, data) {
        return this.api.post('/campustalk/apply/' + id + '/update', data)
    }
    //企业获取宣讲会详情
    getCampusInfo(id) {
        return this.api.get('/campustalk/apply/' + id)
    }
    //获取上次申请双选会填写数据
    getPrevMIlkApply(applyid) {
        return this.api.get('/milkround/apply/department/' + applyid)
    }
    //检测高校认证信息
    checkDepCollegeIdentity(collegeid) {
        return this.api.get('/authentication/' + collegeid + '/check')
    }
    //获取职位推送高校信息
    getPositionPushCountList(param) {
        return this.api.post('/resume/major/agregation/query',param)
    }
    //获取职位推送数量信息
    getPositionPushList(param) {
        return this.api.post('/resume/major/agregation',param)
    }
    //批量职位推送
    batchPositionPush(positionid,param) {
        return this.api.post('/position/'+positionid+'/invite/talents',param)
    }
    //获取上次职位推送电话
    getPushPhone() {
        return this.api.get('/positionpush/phone')
    }
    ///////////////////////////////////////////////////////////////////////IM会话操作接口
    //置顶当前会话
    setIMtop(_dlid) {
        return this.api.post('/im/dialogue/' + _dlid + '/top', { dlid: _dlid });
    }
    //聊天会话设置为不合适
    setIMinappropriate(_dlid) {
        return this.api.post('/im/dialogue/' + _dlid + '/inappropriate', { dlid: _dlid });
    }
    //屏蔽聊天会话
    setIMshield(_dlid) {
        return this.api.post('/im/dialogue/' + _dlid + '/shield', { dlid: _dlid });
    }
    //举报聊天会话
    setIMtipoff(_dlid, data) {
        return this.api.post('/im/dialogue/' + _dlid + '/tip_off?reason=' + data.reason, data);
    }
    //获取面试信息
    getInterviewById(interviewid) {
        return this.api.get('/interview/department/' + interviewid);
    }
    //发送面试offer
    sendInterviewOffer(interviewid, data) {
        return this.api.post('/interview/send/offer?interviewid=' + interviewid, data);
    }

    ///////////////////////////////////////////////////////////////////////字典接口
    //获取字典数据
    getDictDate(_dicttype) {
        return this.api.get('/dict/' + _dicttype);
    }
    //获取父字典的子字典列表
    getChildDictDate(_dicttype, data) {
        return this.api.get('/dict/' + _dicttype + '/' + data);
    }
    //根据名称模糊匹配返回字典
    getSearchListDic(_dicttype, data) {
        return this.api.get('/dict/' + _dicttype + '/matching?name=' + data.name + '&level=' + data.level);
    }

    //根据国标专业3级匹配 返回工作啦专业二级
    getmajor_lvel2(data) {
        return this.api.get('/dict/contrast/major_gb_level3/major_lvel2/match',data);
    }
   
    ///////////////////////////////////////////////////////////////////////企业位置相关接口
    //获取公司位置信息
    employerPositionInfo() {
        return this.api.get('/department/location/position')
    }
    //修改公司位置信息
    employerUpdatePositionInfo(_lid, data) {
        return this.api.post('/department/location/' + _lid + '/update', data)
    }
    //新增公司位置信息（企业信息）
    employerCreatePositionInfo(data) {
        return this.api.post('/department/location/create', data)
    }
    //删除公司位置信息
    employerDeletePositionInfo(_lid) {
        return this.api.post('/department/location/' + _lid + '/delete')
    }
    //将所选公司地址地址设为默认
    employerDefaultPositionInfo(_lid) {
        return this.api.post('/department/location/' + _lid + '/default')
    }
    //高校热门城市
    getCollegeHotCity() {
        return this.api.get('/college/hot/city')
    }
    //高校热门城市
    getCollegeHotCityWithProvince(data) {
      return this.api.get('/college/hot/city'+data)
    }
    //高校热门省
    getCollegeHotProvince() {
      return this.api.get('/college/hot/province')
    }

    //高校热门城市
    getCollegeHotCityWithProvinceAndCity(data) {
      return this.api.get('/resume/search/agregation'+data)
    }
    ///////////////////////////////////////////////////////////////////////签约中心
    //企业发起的签约信息列表
    getHiredDepartmentList(data) {
        return this.api.get('/signup/department/initiate/list', data)
    }
    //企业收到的签约信息列表
    getHiredTalentList(data) {
        return this.api.get('/signup/department/receipt/list', data)
    }
    //检测是否有已完成的面试
    checkInterviewEnd(talentid) {
        return this.api.post('/interview/send/' + talentid + '/check');
    }
    //未完成面试邀请时发送录用通知
    sendOffter(talentid, data) {
        return this.api.post('/offer/send/' + talentid + '/offer', data);
    }
    ///////////////////////////////////////////////////////////////////////广告
    getBoothList() {
        return this.api.get('/booth/client/pcemployer/list/public')
    }
    //////////////////////////////////////////////////////////////////////////发票
    // 获取可开票支付列表（企业）
    getInvoiceList(data){
        return this.api.get('/payment/invoice/list', data);
    }
    //申请发票
    applyInvoice(data){
      return this.api.post('/invoice/apply',data);
    }
    //修改申请发票
    applyInvoiceUpdate(invoiceid,data){
      return this.api.post('/invoice/'+invoiceid+'/update',data);
    }
    //获取开票信息
    getInvoiceDetails(invoiceid){
        return this.api.get('/invoice/'+invoiceid)
    }
    //重新发送发票
    sendByNewEmail(invoiceid,email){
        return this.api.post('/invoice/'+invoiceid+'/email/'+email+'/resend')
    }
    //检查企业是否绑定微信
    getComopanyIsBingWeiChat(data) {
      return this.api.get('/wechat/department/' + data + '/bind/check');
    }

    //////////////////////////////////////////////////////////////////////////邮箱登录
    //邮箱登录
    accountLogin(data) {
        return this.api.agentPost('/user/login', data);
    }
    //邮箱登录验证图片验证码
    emailLoginCheckPicCode(data){
        return this.api.post('/topsystem/vcode/verify', data);
    }
    // 修改密码
    changePsw(data){
      return this.api.post('/user/password/change', data);
    }
    //发送邮箱验证码
    sendEmail(data){
        return this.api.post('/user/active/active_email', data);
    }
    //验证邮箱
    virtyEmail(data){
        return this.api.post('/user/active/email', data);
    }

    findPswCheckEmailCode(data){
        return this.api.agentPost('/user/emailvcode/reset/check', data);
    }

    findPswResetPsw(data){
        return this.api.agentPost('/user/password/resetbyemail', data);
    }

    findPswSendEmail(data){
      return this.api.agentPost('/user/password/reset_email', data);
    }
    findPswSendEmailapp(data){
      return this.api.agentPost('/user/password/reset_email/app', data);
    }


    checkMemberCheck(){
      return this.api.get('/order/member/check');
    }
    checkMemberCheckV2(){
      return this.api.get('/payment/V2/order/product/member/check');
    }
    //获取高校公共接口
    getcollegePublic(collegeid){
      return this.api.get('/college/'+collegeid+'/public');
    }


    //////////////////////////////////////////////////////////////////////////会员
    //获取会员权益
    getProductList() {
        return this.api.get('/product/list/public');
    }

    getProductInfo(code){
      return this.api.get('/product/department/'+ code +'/code');
    }

    // 单品列表
    departmentList(data){
      return this.api.get('/product/department/list/', data);
    }
    //获取单品的剩余量
    departmentProductLeft(product_type){
      //department/{product_type}/product_type/left
      return this.api.get('/department/'+product_type+'/product_type/left', );
    }

    // 单品详情
    departmentCode(code){
        return this.api.get('/product/department/'+ code +'/code');
    }

    // 获取单品余量
    getDeproductSurplus(type){
        return this.api.get('/department/'+ type +'/product_type/left');
    }

    // 通知包单品 符合条件通知人数
    smsDepartment(data){
        return this.api.post('/mc/sms/department/', data);
    }

    // 单品 活动通知包使用 废弃
    smsSendDepartment(data){
        return this.api.post('/mc/sms/send/department/', data);
    }
    // 新单品 活动通知包使用
    smsSendDepartment2(data){
      return this.api.post('/mc/department/activity/invite/talent', data);
    }

    // 单品通知模板
    getSmsMban(name){
        return this.api.get('/im/chat/' + name + '/sms');
    }

    //获取订单详情
    getOrderDetails(orderid) {
        return this.api.get('/order/' + orderid);
    }
    //获取支付渠道 android ios pc wap wx
    getPayChannel() {
        return this.api.get('/payment/pc/channel');
    }

    //获取企业产品配额余量
    getDepartmentProductMSGCount(type) {
      return this.api.get('/department/'+type+'/product_type/left');
    }
    //获取短信模版内通，极速短信通知模版
    getMSGContent(type) {
      return this.api.get('/im/chat/'+type+'/sms');
    }
    //发送单品极端短信联系 废弃，改成批量
    sendSingleMSG(data) {
      return this.api.post('/im/chat/sms/send',data);
    }
    //批量邀请职位投递
    sendYaoQingMSG(data) {
      return this.api.post('/position/invite/talents',data);
    }

    //获取网络虚拟号吗
    GetVirtualNumber(data) {
      return this.api.post('/virtualnumber/GetVirtualNumber',data);
    }

    //招聘会反馈确认
    confirmMilkround(data,mrid,type) {
      return this.api.post('/milkround/' + mrid + '/' + type + '/feedback/confirm', data);
    }
    //验证招聘会是否二次确认
    virtyMilkroundFeedBack(mrid) {
        return this.api.get('/milkround/'+mrid+'/feedback/confirm_status');
    }
    //获取双选会返回结果
    getMilkroundFeedBackPost(mrid) {
      return this.api.get('/milkround/'+mrid+'/joinposter');
    }

    //获取消费明细
    getConsumeList(data) {
      return this.api.get('/consume/list/',data);
    }
    //购买订单
    buyOrder(data) {
      return this.api.post('/order/create/', data);
    }
    //购买单品时啦豆不足的接口
    buyOrderByLabi(data) {
      return this.api.post('/payment/product/charge', data);
    }
    //卡劵兑换啦豆
    cardByLabi(receiveid) {
      return this.api.post('/coupon/receive/'+receiveid+'/recharge',);
    }

    //活动通知获取专业
    getagregationCount(data){
      return this.api.post('/resume/major/agregation', data);
    }
    //活动通知获取符合专业的人数
    getagregationquery(data){
      return this.api.post('/resume/major/agregation/query', data);
    }
    //获取卡劵的广告banner
    getCardBannerdata(alias){
      return this.api.get('/booth/manage/list/public?alias='+alias);
    }

  //可开发票列表
  invoiceableList(status,data) {
    return this.api.get('/payment/invoice/'+status+'/list',data);
  }
  //可开发票列表
  newInvoiceableList(data) {
    return this.api.get('/invoice/list',data);
  }
  //获取开发票信息
  getInvoiceDetail(invoiceid) {
    return this.api.get('/invoice/'+invoiceid);
  }

  //已开发票列表
  endInvoiceableList(data) {
    return this.api.get('/invoice/list',data);
  }

  //获取发票设置
  getInvoiceSetting(pay_orderid){
    return this.api.get('/invoice/setting?pay_orderid='+pay_orderid);
  }
  //用户绑定微信
  userBindWeChat(data) {
    return this.api.agentPost('/user/bind/wechat',data);
  }
  //领取首页的Banner卡劵
  getBannerCard(packageid,data) {
    return this.api.post('/coupon/package/'+packageid+'/receive',data);
  }

  getPublicPackageList(data){
    return this.api.get('/coupon/package/list/public?'+data);
  }
  //检验卡包是否能用
  checkPackage(packageid){
    return this.api.get('/coupon/package/'+packageid+'/check');
  }
  //获取首页看板的数据
  getKanbanData(postType){
    return this.api.get('/statistics/department/supplydemand?post_type='+postType);
  }
  //获取登录看板的数据
  getKanbanDataWithLogin(){
    return this.api.get('/statistics/department/supplydemand/public');
  }
  //接口错误
  //http://webapi2.test.goworkla.cn//im/dialogue/T_GISEG2QK/info
  getChatUserInfo(name){
    return this.api.get("/im/dialogue/" + name +'/info');
  }
  getresumeDetailWithNumber(resume_number){
    return this.api.get("/resume/" + resume_number +'/view');
  }

  //获取校招顾问二维码联系方式
  getRecruitmentSelf(){
    let _departmentStr  = this.local.get('department')
    if(_departmentStr){
      let depart = JSON.parse(_departmentStr);
      return this.api.urmGetData('/recruitmentconsultant/bydepartmentid?departmentid='+depart.accountid,{})
    }
    // return this.api.get("recruitment_consultant/department/self");
  }

  //通过手机号获取企业账户信息 注销当前用户时使用
  getdDepartmentWithAccountOut(data){
    return this.api.post("/account/department/get",data);
  }
  //注销当前手机账号
  accountOutCurrent(data){
    return this.api.post("/account/department/getbymobile",data);
  }

  //注销当前邮箱账号
  accountOutEmail(data){
    return this.api.post("/account/department/getbyemail",data);
  }

  //注销公司其他a账号
  accountOutOther(data){
    return this.api.post("/account/department/other/logout",data);
  }
  //注销公司其他a账号获取列表
  getAccountOutOtherList(register_number){
    return this.api.get("/account/department/other/get");
  }

  //根据企业numberid 获取企业详情
  getDepartmenyDetailWithNumber(number){
    return this.api.get("/department/"+number,);
  }
  //获取注销历史
  getAccountlogoutHistoryList(){
    return this.api.get("/account/department/logout/list",);

  }
  //检测企业是否
  checkDepartmentcooperation(collegeid) {
    return this.api.get("/account/department/check/"+collegeid+"/cooperation",);
  }
  getGuWebByName(name) {
    return this.api.get("/recruitment_consultant/department/byname?name="+name,);
  }
  virtyPassword(password) {
    return this.api.get("/user/password/check?password="+password,);
  }
  //用户设置是否被推荐
  setDepartmentTuiJian(data) {
    return this.api.post("/department/switch/display_mobile",data);
  }

  getMyMilkroundHistory() {
    return this.api.get("/milkround/apply/list/underway/department");
  }
  getMyCampustalk1History() {
    return this.api.get("/campustalk/apply/list/underway",);
  }

  //批量创建标签
  creatResumeLabelBatch(data) {
    return this.api.post("/resume/label/batchcreate",data);
  }

  //批量更新标签
  updateResumeLabelBatch(data) {
    return this.api.post("/resume/label/batchupdate",data);
  }
  //获取我的所有标签
  getMyresumeLabelList(data) {
    return this.api.get("/resume/lable",data);
  }
  //删除标签
  deleteResumeLabel(data) {
    return this.api.post("/resume/lable/"+data+"/delete");
  }
  //获取简历被收藏后的标签/备注
  getResumeLabelDetail(resumeid) {
    return this.api.get("/resume/archived/"+resumeid);
  }
  //获取简历的操作日志
  getResumeLog(resumeid,data) {
    return this.api.get("/resume_log/list/"+resumeid,data);
  }
  //获取极速电话联系记录
  getCallrecord(data) {
    return this.api.get("/resume/callrecord/searcher",data);
  }
  //获取职位推送记录
  getPositionPushrecord(data) {
    return this.api.get("/resume/positioninvite/searcher",data);
  }
  //获取活动邀请记录
  getActivityrecord(data) {
    return this.api.get("/mc/activity/invite/record",data);
  }
  //获取活动邀请记录详细列表
  getActivityrecordDetail(recordid,data) {
    return this.api.get("/mc/activity/invite/record/"+recordid+"/list",data);
  }

  //验证本地缓存的字典的版本
  virtyDicVersion(){
    return this.api.get("/dict/version?dict_type=all");
  }

  //检查用户是否是付费用户
  checkMemberPay(){
    return this.api.get("/authentication/platform/member/check");
  }
  checkMemberPayWithId(chargeid){
    return this.api.get("/authentication/platform/member/check?chargeid="+chargeid);
  }
  //验证密码格式是否正确
  checkPassword(Password){
    return this.api.get("/user/password/check?password="+Password);
  }

  //空中宣讲会相关 开始
  //获取企业的最新有效宣讲会
  getAirCampustalk(){
    return this.api.get("/air_campustalk/get");
  }
  getAirCampustalkLive(){
    return this.api.get("/air_campustalk/GetAirLiveCampustalk");
  }

  //获取空中宣讲会详情
  getAirCampustalkWithid(acid) {
    return this.api.get("/air_campustalk/"+acid);
  }
  //获取空中宣讲会详情
  getAirCampustalPublicWithid(acid) {
    return this.api.get("/air_campustalk/public/" + acid);
  }
  //提交空中宣讲会
  commitCampustalk(data) {
    return this.api.post("/air_campustalk/submit/manage",data);
  }
  //更新空中宣讲会
  updateCampustalk(data) {
    return this.api.post("/air_campustalk/update",data);
  }
  //创建问题
  creatAirQuestion(data) {
    return this.api.post("/api/AirQuestion/CompanySelfQuestion",data);
  }
  //更新问题
  updateAirQuestion(data) {
    return this.api.post("/api/AirQuestion/CompanyEditSelfQuestion",data);
  }
  deleteAirQuestion(airquestionid) {
    return this.api.post("/api/AirQuestion/CompanyDeleteTalentQuestion/"+airquestionid+"/Delete");
  }
  //回复学生问题
  AnswerTalent(data) {
    return this.api.post("/api/AirQuestion/CompanyAnswerTalentQuestion",data);
  }
  //获取问题
  getAirQuestion(data) {
    return this.api.get("/api/AirQuestion/GetALLQuestionList",data);
  }
  //获取企业申请的高校信息
  getApplyCollegeList(data){
    return this.api.get("/air_campustalk_apply/department/list/"+data);
  }

  //推广到高校
  air_campustalk_applyCollege(data) {
    return this.api.post("/air_campustalk_apply/department/apply_bulk",data);
  }
  //推广到学生
  air_campustalk_applyTalent(data) {
    return this.api.post("/air_campustalk/activity/invite/talent",data);
  }
  //设置问题是否公开
  setQuestionIsPublic(data){
    return this.api.post("/api/AirQuestion/CompanySetTalentQuestionIsPublic",data);
  }
  //获取空中宣讲会学生列表
  getAirCampResumeList(id,data){
    return this.api.post("/air_campustalk/"+id+"/resume/list",data);
  }
  //增加视频的播放量
  setVideoPlayCount(id){
    return this.api.post("/air_campustalk/increase/video_play_count/"+id);
  }
  //获取宣讲会列表，往期列表使用
  getAircampustalkList(data){
    return this.api.get("/air_campustalk/list",data);
  }
  //获取宣讲会分享图片
  getAircampustalkImage(id,data){
    return this.api.get("/air_campustalk/public/mp_image/"+id,data);
  }
  //空中宣讲会下线
  downLine(id,data){
    return this.api.post("/air_campustalk/online/switch/"+id,data);
  }
  //空中宣讲会相关API 结束

  //下载简历
  downLoadResume(resumeid,name){
    return this.api.downLoadget("/resume/"+resumeid+"/download",name);
  }
  //批量导出简历
  downLoadResumes(data){
    return this.api.downLoadgets("/resume/download2",data);
  }
  downLoadResumes_test(){
    return this.api.downLoadget("/agreement_record/department/bulk_downloadagreement?recordids=5e8be03f70b3fc7570818d44","");
  }
  //一键刷新所有职位
  positionRefreshs(){
    return this.api.post("/position/refresh_all");
  }
  //用户绑定邮箱并设置密码
  userBindEmailSetPsw(data){
    return this.api.post("/user/active/email/setpwd",data);
  }
  //取消注册
  cancelRegister(data){
    return this.api.post("/account/register/cancel",data);
  }

  //获取未读的消息数
  getMsgUnViewCount(){
    return this.api.get("/tasktodo/unview/count");
  }

  //正阳测试购买统计
  urmPayStatistic(data){
    return this.api.urmPayStatistic(data);
  }

  //这是消息全部已读
  setMsgAllView(){
    return this.api.post("/tasktodo/viewall");
  }

  //--------- 新支付系统API 开始 ------------

  //创建啦豆充值支付订单api
  paymentRechargeCreate(data){
    return this.api.post("/payment/V2/order/lacoin/recharge/create",data);
  }

  //产品订单金额试算返回支付方案api
  paymentV2orderTrial(data){
    return this.api.post("/payment/V2/order/online_product/lacoin/trial",data);
  }
  //获取支付订单列表api
  getPaymentOrderPayList(data){
    return this.api.get("/payment/V2/order/pay/list",data);
  }
  //获取产品订单列表api
  // getPaymentOrderproductList(){
  //   return this.api.get("/payment/V2/order/product/list");
  // }

  //订单付款api
  paymentOrderPayGetCharge(pay_orderid,data){
    return this.api.post("/payment/V2/order/pay/"+pay_orderid+"/payment",data);
  }

  //获取单个支付订单api
  getPaymentOrderPayByOrderid(pay_orderid){
    return this.api.get("/payment/V2/order/pay/"+pay_orderid);
  }
  //用户取消支付订单
  paymentV2orderCancel(pay_orderid){
    return this.api.post("/payment/V2/order/pay/"+pay_orderid+"/cancel");
  }
  //创建在线产品订单并完成兑换api 创建订单
  paymentV2Creatorder(data){
    return this.api.post("/payment/V2/order/online_product/lacoin/exchange",data);
  }
  paymentV2CreatorderV2(pay_orderid){
    return this.api.post("/payment/V2/order/online_product/"+pay_orderid+"/lacoin/exchange");
  }

  //创建在线产品订单与支付订单（啦豆现金混合支付）
  paymentV2CreatorderByMoney(data){
    return this.api.post("/payment/V2/order/online_product/create",data);
  }

  //获取啦豆消费明细
  getLaPayList(data){
    return this.api.get("/payment/V2/transaction/list",data);
  }
  // 获取账户下的产品不见列表
  getcomponentList(data){
    return this.api.get("/payment/V2/account/component/list",data);
  }
  //获取可用产品组件
  getcomponentLeft(data){
    return this.api.get("/payment/V2/account/component/left",data);
  }
  getcomponentLeftWithAlias(Alias){
    return this.api.get("/payment/V2/account/component/"+Alias+"/left");
  }
  //获取啦豆的冻结数量
  getOrderladouForzen(){
    return this.api.get('/payment/V2/order/pay/ladou/forzen');
  }

  //--------- 新支付系统API 结束 ------------


  //减压手机号是否可用
  checkMobile(phone){
    return this.api.get('/user/mobile/count?mobile='+phone);
  }

  //user/mobile/count

  getcollegeWithYear(year){
    return this.api.get('/college/n'+year+'/list');
  }

  //检查是够存在体验会员订单
  checkTrial(){
    return this.api.get('/payment/V2/account/check/member_trial_order/exsit');
  }

  //检查高校是否把企业拉黑
  checkCollegeBlack(collegeid){
    return this.api.get('/blacklist/department/'+collegeid+'/check');
  }
  //获取见习资质审核状态
  getAuthenticationProbation(){
    return this.api.get('/authentication/probation/apply');
  }
  //见习资质 申请
  postAuthenticationProbation(data){
    return this.api.post('/authentication/probation/apply',data);
  }
  //见习资质 更新
  postAuthenticationProbationUpdate(data){
    return this.api.post('/authentication/probation/apply/update',data);
  }

  //解绑微信登录
  unBindWeChat(data){
    return this.api.post('/wechat/department/unbind',data);
  }

  //设置投递简历已读
  setDeliveryRead(deliveryid){
    return this.api.post("/delivery/"+deliveryid+"/read");
  }
  //根据职位查看记录发起一键推送
  positionInvitePushs(positionid,data){
    return this.api.post("/position/view/"+positionid+"/invite/talents",data);
  }
  //保存
  interesteddepartmentSave(data){
    return this.api.urm_post("/interesteddepartment/save",data);
  }
  //检测是否有相同的企业申请同一场双选会
  checkDepartmentSignMilk(mrid) {
    return this.api.get('/milkround/'+mrid+'/apply/check')
  }

  //企业工作台去设置企业公开信息标记为true
  departmentSwitchContactinfo(data){
    return this.api.post("/department/switch/contactinfo",data);
  }
  setDepartmentSwitchContactinfo(data){
    return this.api.post("/department/contactinfo",data);
  }
  //获取申请宣讲会的提示
  getCampApplyTips(collegeid) {
    return this.api.get('/campustalk/apply/'+ collegeid +'/tips')
  }

  //获取对我感兴趣的 筛选数量 一键职位推送使用
  getPositionViewCount(data) {
    return this.api.get('/position/view/getCount',data)
  }
  //企业申请公益宣讲会领取公益会员
  signMilkgetMemberWelfare(id){
    return this.api.post("/milkround/"+id+"/apply/member/welfare");
  }

  // 获取高校的合作企业列表
  getMilkroundAlumnDepartment(data) {
    return this.api.get('/milkround_alumn/department/info',data)
  }
  //获取宣讲会参会凭证
  getCampustalkJoginCerInfo(data) {
    return this.api.get('/campustalk/join_certificate',data)
  }

  // 获取是否允许申请宣讲会
  getCampustalkApplyAllowd(data) {
    return this.api.get('/campustalk/apply/allowd',data)
  }
  // 获取是否允许申请双选会
  getMilkroundApplyAllowd(data) {
    return this.api.get('/milkround/apply/allowd',data)
  }


  //检查是否有相同企业申请同一场双选会
  checkMilkroundSameNameApply(mrid) {
    return this.api.get('/milkround/'+mrid+'/same/check')
  }
  getMilkroundDay(data) {
    return this.api.get('/milkround/push/list',data)
  }


  //视频面试
  getRoom(roomid,data){
    return this.api.room_post(`/room/${roomid}`,data);
  }
  

  //活动列表下拉
  getMridList(){
    return this.api.get(`/milkround/apply/namelist/department`);
  }


  //获取简历备注
  getNotes(resumeid){
    return this.api.get(`/resume/remark/${resumeid}`);
  }

  //新增修改简历备注
  upNotes(data){
    return this.api.post(`/resume/remark/update`,data);
  }

  //非会员收到的简历个数
  resumeNumber(){
    return this.api.get(`/delivery/nonmember/count`);
  }


  //milkround/apply/list/underway/department

  //GET /account/department/check/{collegeid}/cooperation



  ///////////////////////////////////////////////////////////////////////操作接口


  ///////////////////////////////////////////////////////////////////////产品订单接口


    ///////////////////////////////////////////////////////////////////////意见反馈


    ///////////////////////////////////////////////////////////////////////上传接口

    //企业视频上传获取秘钥
    getVideoKey() {
      return this.api.get('/department/upload/signature');
    }

    //企业视频上传
    upVideo(data) {
      return this.api.post('/department/video',data);
    }

    ///////////////////////////////////////////////////////////////////////置顶职位
    toppingPosition(positionid,data) {
      return this.api.post(`/position/${positionid}/top`,data);
    }
    gettoppingPosition(positionid) {
      return this.api.get(`/position/${positionid}/GetDate`);
    }

    modifytoppingPosition(positionid,data){
      return this.api.post(`/position/${positionid}/update/top`,data);
    }

    newauthentication(){
      return this.api.get(`/authentication/platform/apply/change/operater/last`);
    }

    newPutauthentication(data){
      return this.api.post(`/authentication/platform/apply/change/operater`,data);
    }
    ///////////////////////////////////////////////////////////////////////账户管理


    ///////////////////////////////////////////////////////////////////////测试接口


    //获取在售产品组件剩余
    onsaleLeft(data) {
      return this.api.get('/payment/V2/account/component/onsale/left',data);
    }

    //高校列表（公共）
    collegeList(data) {
      return this.api.get('/college/list/public',data);
    }

    //高校列表（公共）
    mycollegeList() {
      return this.api.get('/department/college/add/list');
    }

    //非会员新增高校
    addCollegeList(data) {
      return this.api.post('/department/college/add',data);
    }

    //非会员新增高校
    cdapi_entity(code) {
      return this.api.get_cdapi_entity('/api/CdPublication/GetList_P?code='+code);
    }

    //产品详情介绍
    productcomponent(alias) {
      return this.api.get(`/productcomponent/${alias}/public`);
    }

    //urm 请校招顾问联系我
    // contactMeSave(data){
    //   return this.api.urm_post("/urmupdate/save",data);
    // }
    contactMeSave(data){
      return this.api.urm_post("/urmupdate/stay/save",data);
    }

    //获取定时刷新 职位下拉列表 
    getWithfieldPosition(){
      return this.api.get(`/position/namelist/withfield`);
    }

    //设置定时刷新 
    set_auto_refresh(data){
      return this.api.post('/position/auto_refresh/set',data);
    }

    //获取定时刷新详情 
    getrefreshinfo(positionid){
      return this.api.get(`/position/auto_refresh/${positionid}/refreshinfo`);
    }

    //
    getallrecords(positionid){
      return this.api.get(`/position/auto_refresh/${positionid}/allrecords`);
    }

    //领取体验会员
    exchangeCode(code){
      return this.api.get(`/member/trial/exchange/${code}`);
    }

    //获取海报
    getPosterImg(data){
      return this.api.get(`/position/${data.positionid}/generater/${data.poster_type}/share_poster`);
    }

    //企业获取简历开放日期所在年份
    getCircles(){
      return this.api.get(`/resume/open_date/year`);
    }


    // 撤销双选会报名
    revokeMilk(applyid){
      return this.api.post(`/milkround/apply/department/${applyid}/revoke`)
    }

    // 撤销宣讲会报名
    revokeCampus(applyid){
      return this.api.post(`/campustalk/apply/${applyid}/revoke`)
    }

    //local(本地存储接口)————————————————————————————————————————————————————————
    //设置tooken
    setLocalToken(token: string) {
        this.local.set(Constants.accesstoken, token);
        this.config.token = token;
    }
    getLocalToken() {
        return this.local.get(Constants.accesstoken);
    }
    //设置accountid
    setLocalAccountId(accountid: string) {
        this.local.set(Constants.accountid, accountid);
        this.config.accountid = accountid;
    }
    getLocalAccountId() {
        return this.local.get(Constants.accountid);
    }
    //设置登录的手机号
    setLocalLoginName(_data) {
        this.local.set(Constants.loginName, _data);
        this.config.loginName = _data;
    }
    //设置登录的邮箱号
    setLocalLoginEmail(_data) {
        this.local.set(Constants.loginEmail, _data);
        this.config.loginEmail = _data;
    }
    getLocalLoginName(): any {
        return this.local.get(Constants.loginName);
    }
    //刷新token数据本地存储
    setRefreshToken(_data) {
        this.local.set(Constants.refreshToken, JSON.stringify(_data));
        this.config.refresh_token = _data;
    }
    getRefreshToken() {
        return JSON.parse(this.local.get(Constants.refreshToken));
    }
    //设置本地当前登录用户的信息(user信息)
    setLocalLoginUser(_data) {
        this.local.set(Constants.loginUser, JSON.stringify(_data));
        this.config.loginUser = _data;
    }
    getLocalLoginUser() {
      try {
        return JSON.parse(this.local.get(Constants.loginUser));
      }catch(e)
      {
      }
    }
    //设置企业信息
    setLocalDepartment(_data) {
        this.local.set(Constants.department, JSON.stringify(_data));
        this.config.department = _data;
    }
    getLocalDepartment() {
      try {
        return JSON.parse(this.local.get(Constants.department));
      }catch(e)
      {
      }
    }

    //聊天记录本地存储
    setLocalChatsMsgList(_data) {
        this.local.set(Constants.chatsMsgList, JSON.stringify(_data));
        this.config.chatsMsgList = _data;
    }
    //聊天记录本地存储
    getLocalChatsMsgList() {
        return JSON.parse(this.local.get(Constants.chatsMsgList));
    }
    //会话列表本地存储
    setLocalChatsList(_data) {
        this.local.set(Constants.chatsList, JSON.stringify(_data));
        this.config.chatsList = _data;
    }
    //会话列表本地存储
    getLocalChatsList() {
        return JSON.parse(this.local.get(Constants.chatsList));
    }
    //职位列表本地存储
    setLocalpositionList(_data) {
        this.local.set(Constants.positionList, JSON.stringify(_data));
        this.config.positionList = _data;
    }
    //职位列表本地存储
    getLocalpositionList() {
        return JSON.parse(this.local.get(Constants.positionList));
    }
    //---------企业最后一次认证信息本地存储
    setLastIdentity(_data) {
        this.local.set(Constants.lastIdentity, JSON.stringify(_data));
        this.config.lastIdentity = _data;
    }
    getLastIdentity() {
        return JSON.parse(this.local.get(Constants.lastIdentity));
    }
    //---------企业最后一次高校认证信息本地存储
    setLastCollegeIdentity(_data) {
        this.local.set(Constants.lastCollegeIdentity, JSON.stringify(_data));
        this.config.lastCollegeIdentity = _data;
    }
    getLastCollegeIdentity() {
        return JSON.parse(this.local.get(Constants.lastCollegeIdentity));
    }
    //--------行业字典本地存储
    setIndustryCode(_data) {
        this.local.set(Constants.industry, JSON.stringify(_data));
        this.config.industry = _data;
    }
    getIndustryCode() {
        return JSON.parse(this.local.get(Constants.industry));
    }
    //--------行业字典本地存储
    setEmployerTypeCode(_data) {
        this.local.set(Constants.employer_type, JSON.stringify(_data));
        this.config.employer_type = _data;
    }
    getEmployerTypeCode() {
        return JSON.parse(this.local.get(Constants.employer_type));
    }
    //--------职位类别字典本地存储
    setPostTypeCode(_data) {
        this.local.set(Constants.post_type, JSON.stringify(_data));
        this.config.post_type = _data;
    }
    getPostTypeCode() {
        return JSON.parse(this.local.get(Constants.post_type));
    }
    //--------专业字典本地存储
    setMajorCode(_data) {
        this.local.set(Constants.major, JSON.stringify(_data));
        this.config.major = _data;
    }
    getMajorCode() {
        return JSON.parse(this.local.get(Constants.major));
    }
    //--------技能字典本地存储
    setSkillCode(_data) {
        this.local.set(Constants.skill, JSON.stringify(_data));
        this.config.skill = _data;
    }
    getSkillCode() {
        return JSON.parse(this.local.get(Constants.skill));
    }
    //--------地区字典本地存储
    setRegionCode(_data) {
        this.local.set(Constants.region, JSON.stringify(_data));
        this.config.region = _data;
    }
    getRegionCode() {
        return JSON.parse(this.local.get(Constants.region));
    }

    //--------企业规模字典本地存储
    setScaleCode(_data) {
        this.local.set(Constants.scale, JSON.stringify(_data));
        this.config.scale = _data;
    }
    getScaleCode() {
        return JSON.parse(this.local.get(Constants.scale));
    }
    //--------学历字典本地存储
    setEducationCode(_data) {
        this.local.set(Constants.education, JSON.stringify(_data));
        this.config.education = _data;
    }
    getEducationCode() {
        return JSON.parse(this.local.get(Constants.education));
    }
    //--------常用语字典本地存储
    setCommonCode(_data) {
        this.local.set(Constants.common, JSON.stringify(_data));
        this.config.common = _data;
    }
    getCommonCode() {
        return JSON.parse(this.local.get(Constants.common));
    }
    //--------小秘书常用语字典本地存储
    setCommonJunCode(_data) {
        this.local.set(Constants.commonJun, JSON.stringify(_data));
        this.config.commonJun = _data;
    }
    getCommonJunCode() {
        return JSON.parse(this.local.get(Constants.commonJun));
    }

      //------
      //获取地区字典本地存储
      getRegionCode_dic(callback){
        let region = this.local.get(Constants.region);
        if(region||region==undefined) {
          try {
            callback(JSON.parse(region))
          }catch(e) {
            this.getDictDate('region').then((res:any)=>{
              if(res) {this.local.set(Constants.region, JSON.stringify(res));callback(res);}
            }).catch((error)=>{
              callback([]);
            });
          }
        }
        else {
          this.getDictDate('region').then((res:any)=>{
            if(res) {this.local.set(Constants.region, JSON.stringify(res));callback(res);}
          }).catch((error)=>{
            callback([]);
          });
        }
      }
    getorganization_type_dic(callback){
      let region = this.local.get('organization_type');
      if(region||region==undefined) {
        try {
          callback(JSON.parse(region))
        }catch(e) {
          this.getDictDate('organization_type').then((res:any)=>{
            if(res) {this.local.set('organization_type', JSON.stringify(res));callback(res);}
          }).catch((error)=>{
            callback([]);
          });
        }
      }
      else {
        this.getDictDate('organization_type').then((res:any)=>{
          if(res) {this.local.set('organization_type', JSON.stringify(res));callback(res);}
        }).catch((error)=>{
          callback([]);
        });
      }
    }


      //-----



    //退出登录(清空缓存)
    logout() {
        //清除本地存储缓存
        this.local.remove(Constants.accesstoken);//tooken
        this.local.remove(Constants.accountid);//accountid
        this.local.remove(Constants.loginUser);//用户信息
        // this.local.remove(Constants.loginName);//用户登录账号
        this.local.remove(Constants.department);//企业信息
        this.local.remove(Constants.lastIdentity);//企业最后一次认证信息
        this.local.remove(Constants.chatsMsgList);//聊天记录
        this.local.remove(Constants.chatsList);//会话列表
        this.local.remove(Constants.positionList);//职位列表
        this.local.remove(Constants.chatPosition);//首次沟通需要的职位参数
        this.local.remove(Constants.chatsInfo);//沟通页面会话信息参数
        this.local.remove(Constants.userInfo);//沟通页面会话信息参数(新会话)
        this.local.remove(Constants.seat_number);//沟通页面展位号参数
        this.local.remove(Constants.refreshToken);//刷新token数据
        this.local.remove(Constants.industry);//行业字典
        this.local.remove(Constants.employer_type);//企业类别
        this.local.remove(Constants.post_type);//职位类别
        this.local.remove(Constants.major);//专业字典
        this.local.remove(Constants.skill);//技能字典
        this.local.remove(Constants.region);//地区字典
        this.local.remove(Constants.scale);//企业规模
        this.local.remove(Constants.common);//常用语字典
        this.local.remove(Constants.commonJun);//小秘书常用语字典
        this.local.remove(Constants.education);//学历字典
        // this.local.remove(Constants.resumeid);//简历id(防止刷新时报错)
        // this.local.remove(Constants.collegeid);//高校id(防止刷新时报错)
        // this.local.remove(Constants.mrid);//双选会id(防止刷新时报错)
        this.local.remove(Constants.positionid);//职位id(防止刷新时报错)
        this.local.remove(Constants.facultyid);//学院id(防止刷新时报错)
        this.local.remove(Constants.articleid);//文章id(防止刷新时报错)
        this.local.remove(Constants.campusApply);//宣讲会申请数据
        this.local.remove(Constants.oldUrl);//旧路由
        this.local.remove(Constants.newUrl);//新路由
        this.local.remove(Constants.briefDetais);//招聘简章详情
        this.local.remove(Constants.briefid);//招聘简章id
        this.local.remove(Constants.loginRs);//登陆来源参数
        this.local.remove(Constants.byCollege);//就业信息网id
        this.local.remove(Constants.byCollegeDetails);//就业信息网高校信息
        this.local.remove('routeList');//就业信息网高校信息
        this.local.remove('resetOneStep');//就业信息网高校信息
        this.local.remove('isShowMemberTimeOut')//用户会员快到期的弹窗
        this.local.remove('memberPayid')//用户购买会员产品的支付id，检测是否付费成功的时候用
        this.local.remove('isCloseScreenSmallBox')//屏幕小的弹窗状态随登录
        this.local.remove('payrecharge')//校招工具默认购买单品的count

        this.local.remove('college-details-student-model'); //生源速览页面参数
        this.local.remove('college-details-student-modelp');//生源速览页面参数分页
        this.local.remove('chat-collection-model');//对我感兴趣页面参数分页

        this.local.remove('isAlertVip');//提示成为会员弹窗标识


        //清除全局变量
        this.config.token = '';
        this.config.accountid = '';
        this.config.loginUser = null;
        this.config.department = null;
        this.config.chatsMsgList = [];
        this.config.refresh_token = null;
        this.config.lastIdentity = null;
        this.config.industry = [];
        this.config.post_type = [];
        this.config.major = [];
        this.config.skill = [];
        this.config.region = [];
        this.config.common = [];
        this.config.commonJun = [];
        //下面只有全局变量
        this.config.positionList = [];
        this.config.chatsList = [];
        this.config.school_type = [];
        this.config.school_nature = [];
        this.config.school_label = [];
        this.config.school = [];
        this.config.label = []; //企业标签字典
        this.config.isMember_welfare = false
        //console.log('token、loginuser已经清除，退出成功');
        //取消events事件监听
      
      //TODO 这里时间取消订阅语义不明！！！
        // this.events.unsubscribe('user:notification');//取消订阅账号被踢下线事件
        // this.events.unsubscribe('user:created');//取消订阅api.ts通知退出登陆事件
        // this.events.unsubscribe('newMessage:tabs');//取消订阅tabs页面接受到了新消息广播事件
        // this.events.unsubscribe('tabsTo:chat');//取消订阅会话页面接受到了新消息广播事件
        // this.events.unsubscribe('chatTo:contact');//取消订阅更新简历详情页面状态事件
        // this.events.unsubscribe('noticeTo:contact');//取消订阅发送面试邀请后更新会话页面的广播
        // this.events.unsubscribe('rushWorkspaceResumeNot');//删除 对我感兴趣 页面对工作台刷新看板的数据广播
        // this.events.unsubscribe('isReadNotice');//删除 刷新侧栏的通知中心消息数

    }

    getNoDepardmentID(){
        return '请从高校就业信息网登录';
    }
    formatImage(imageData)
    {
      imageData=imageData.replace(/^(data:image)\/png;base64,/g,'');
      imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
      imageData=imageData.replace(/^(data:image)\/JPEG;base64,/g,'');
      imageData=imageData.replace(/^(data:image)\/jpg;base64,/g,'');
      imageData=imageData.replace(/^(data:image)\/JPG;base64,/g,'');
      imageData=imageData.replace(/^(data:image)\/PNG;base64,/g,'');
      imageData=imageData.replace(/^(data:image)\/gif;base64,/g,'');
      return  imageData
    }
  formatFile(fileData) {
    fileData=fileData.replace(/^(data:application)\/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,/g,'');
    fileData=fileData.replace(/^(data:application)\/msword;base64,/g,'');
    fileData=fileData.replace(/^(data:application)\/pdf;base64,/g,'');

    return fileData
  }

}
// export function logout_export() {
//   this.logout();
// }

