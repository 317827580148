import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {VoiceCallModelComponent} from "../voice-call-model/voice-call-model.component";
import {ModelSendMsgComponent} from "../model-send-msg/model-send-msg.component";
import {productManager} from "../../provider/productManager";
import {QRCodeComponent} from 'angular2-qrcode';
import {NoneVipAlertComponent} from "../none-vip-alert/none-vip-alert.component";
import { MatDialog } from "@angular/material/dialog";
import * as signalR from "@aspnet/signalr"; // 重点
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";

import {FormatDate } from '../../provider/index';
// import * as signalR  from "../../assets/js/signalr"
var player = null;

declare let tvp;
declare let Txp;
declare let layui;
declare let  ActiveXObject;
declare let  VSwf;
declare let  QRCode;
declare let  Txplayer;
@Component({
  selector: 'app-air-campus-detail',
  templateUrl: './air-campus-detail.component.html',
  styleUrls: ['./air-campus-detail.component.css']
})
export class AirCampusDetailComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true }) 
  productModel: ProductModelComponent;

  public config: Config;

  @Output() isShowVoiceCallModel = false;//是否打开弹窗(简历收件箱)
  @Output() isShowPositionPushModel = false;//是否打开弹窗(简历收件箱)
  @ViewChild('voiceCallModel', { static: true }) voiceCallModel: VoiceCallModelComponent;
  @ViewChild('sengMsgModel', { static: true }) sengMsgModel: ModelSendMsgComponent;
  @Output() SengMsgModel ={
    userName:'',
    userPic:'',
    talentid:'',
    graduation_school:'',//学校
    major_text:'',//专业
    gender:'',
    address:'',
    positionid:''
  };//打开单品发送极速短信用户对象

  public TJTalentModel = false;
  public TJSchoolModel = false;
  public addNewquestionModel = false;
  public downLineModel = false;
  public schoolList = [1,2,3,5,6,77,8,9,1,4343,332,2];
  public yuanxi1 = [];
  public yuanxi2 = [];
  public yuanxi3 = [];
  public  area1 = [];
  public  area2 = [];
  public  area3 = [];
  public airCampustalk:any;
  public currentTab = "1";
  public talentTab = "1";//学生简历内部tab
  public questionTab = "1";//学生简历内部tab
  public isAllSeleteCollege=false; //是否全选高校推荐
  public ApplyCollegeData={
    all:[],
    passed: [],
    to_audit: [],
    not_passed: [],
  };

  //未通过原因
  public reasons = [];
  public TJTalentModelData = {
    collegeid:'',
    collegeids:[],
    majorOne:'',
    majorTwo:'',
    majorList:[],
    education:'',
    educationList:[],
    sex:'',
    areaOne:'',
    areaTwo:'',
    areaList:[],
    resuleCount:0,//用户选择的学生数量
    filtCount:0,//少选出来的数量
    SmsContent:'',
  }
  //推广到学生
  public seleteCollegeids = [];
  public allCollege = [];
  public MyActivityCount = 0;//单品 活动邀请的数量

  //问答
  public questionList = [];
  public AllquestionCount = 0;
  public addNewquestionData={
    question:'',
    answer:'',
    acid:''
  }
  public seleteQuestionList= [];
  public isALlSeletequestion = false;//是否全选问题
  public currentAnswerTalent:any;//当前回复的问题模型

  //学生简历列表
  public resumeList = [];
  public AllresumeCount = 0;

  public shareImageUrl = "";
  public isLiveAir = false;
  public connection:any;
  public liveState = {
    state:1,
    content:'',
    title:'',
    cover:''
  }
  public startTime;
  public isShowDaiGangPosition = false; // 是否展示直播带岗的职位
  public descFold = true

  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public productManager:productManager,
    public dialog: MatDialog,
    public formatDate:FormatDate
  ) {
    this.config = Config.getInstance();//实例化config
    this.productManager.init();
      activatedRoute.queryParams.subscribe(queryParams => {
        console.log('queryParams',queryParams)
      })
    }

  ngOnInit() {

    let that = this;
    this.opera.initConfig('aircampus','airCampList');
    let airCampusDetailType = this.local.get('airCampusType')
    let airCampusType_acid = this.local.get('airCampusType_acid')
    console.log('airCampusType_acid',airCampusType_acid,airCampusDetailType)

    if(airCampusDetailType){
      if(airCampusDetailType == 'airCampusDetail') {
        this.getAirCampDetail()
      }else if(airCampusDetailType == 'liveCampusDetail'&&airCampusType_acid){
        this.isLiveAir = true;
        console.log('通过id获取直播',airCampusType_acid)
        this.getLiveAirCampDetailWithId();
      } else {
        this.isLiveAir = true;
        this.getLiveAirCampDetail()
      }
    }else {
      this.getAirCampDetail()
    }

  }
  getAirCampDetail(){
    let that = this;
    this.dataApi.getAirCampustalk().then((res:any) => {
      if(res) {
        this.airCampustalk = res;
        if(this.airCampustalk.majors==null) {
          this.airCampustalk.majors = [];
        }
        if(res.audit_status==0){
          this.router.navigateByUrl("home/creatAirCampus");
        }
        if(this.airCampustalk.video_url) {
          let video_url = this.airCampustalk.video_url
          let video_cover = this.airCampustalk.video_cover
          let video_urlArr =  video_url.split("/");
          let lastStr  = video_urlArr[video_urlArr.length-1];
          let newArr =   lastStr.split(".");
          //var video = new tvp.VideoInfo();
          //video.setVid(newArr[0]);
          var player =  new Txp.Player({
            containerId: 'mod_player',
            width:420,
            height:222,
            vid: newArr[0]
          });
          player.on('playStateChange', function(callback){
            console.log('playStateChange',callback);
            if(callback.state == 1){
              that.dataApi.setVideoPlayCount(that.airCampustalk.acid).then((res:any) => {}).catch(() => {})
            }
          });
         
          // var player =new tvp.Player();
          // player.create({
          //   width:500,
          //   height:300,
          //   video:video,
          //   autoplay:false,
          //   modId:"mod_player",
          //   pic: that.airCampustalk.video_cover,
          //   onwrite:function(){
          //     // alert("播放器显示完毕");
          //     console.log('播放器显示完毕',video,player)
          //   },
          // });
          // //events
          // player.onplaying = function(vid) {
          //   console.log('onplaying',vid);
          //   that.dataApi.setVideoPlayCount(that.airCampustalk.acid).then((res:any) => {}).catch(() => {})
          // }
          // player.onended = function(vid) {
          //   console.log('onended',vid);
          // }
        }
        if(res.audit_status==2) {
          this.getSmsContent();
          //获取单品的余额
          this.getDepartment();
          this.getAllCollege();
          this.getMajorList();
          this.getEducationList();
          this.getAreaList();
          this.getAirQuestion(1);
          //获取学生简历列表
          this.getAirCampResumeList(true,0);
          this.getShareImage();
          this.getApplyCollegeList();
        }
        //显示拒绝原因
        if(res.audit_status==3&&res.reasons!=null) {
          this.reasons = res.reasons;
        }
      }
    }).catch(() => {
    })
  }
  getLiveAirCampDetail(){
    this.dataApi.getAirCampustalkLive().then((res:any) => {
      if(res) {
        console.log('getLiveAirCampDetail', res);
        this.airCampustalk = res;
        this.airCampustalk.majors = []
        this.airCampustalk.positions = []
        this.liveState.content = '预计开始时间：'+ this.formatDate.formatDateAndTime(this.airCampustalk.live_start_time)
        if(this.airCampustalk.air_live_type == 1){
          this.liveState.content = '预计开始时间：'+ this.formatDate.formatDateAndTime(this.airCampustalk.prediction_air_live_start_time)
        }
        let startDate = new Date(this.airCampustalk.live_start_time)
        let endDate = new Date(this.airCampustalk.live_end_time)
        let currentDate = new Date()
        this.airCampustalk.video_play_count = this.airCampustalk.view_count;
        if(this.startTime){
          clearInterval(this.startTime)
        }
        this.formatAir_live_type();
        this.initWebConnect();
        this.getAirQuestion(1);
        this.getAirCampResumeList(true,0);
        //air_live_type
        // 1 未开始
        // 2 进行中
        // 3 中断
        // 4 已结束
        //直播未开始
      }
      }).catch(() => {
    })
  }
  getLiveAirCampDetailWithId(){
    let airCampusType_acid = this.local.get('airCampusType_acid')
    let that  = this;
    this.dataApi.getAirCampustalPublicWithid(airCampusType_acid).then((res:any) => {
      if(res) {
        this.airCampustalk = res;
        if(this.airCampustalk.majors==null) {
          this.airCampustalk.majors = [];
        }
        console.log('getLiveAirCampDetailWithId',this.airCampustalk)
        this.airCampustalk.video_play_count = this.airCampustalk.view_count;

        this.airCampustalk.positions.map((item)=>{
          item.name = item.department_name +" - "+ item.name
        })
        this.isShowDaiGangPosition = true;

        if(res.audit_status==2) {
          this.getSmsContent();
          //获取单品的余额
          this.getDepartment();
          this.getAirQuestion(1);
          //获取学生简历列表
          this.getAirCampResumeList(true,0);
        }
        if(this.airCampustalk.video_url) {
          let video_url = this.airCampustalk.video_url
          let video_cover = this.airCampustalk.video_cover
          let video_urlArr =  video_url.split("/");
          let lastStr  = video_urlArr[video_urlArr.length-1];
          let newArr =   lastStr.split(".");
          var video = new tvp.VideoInfo(); video.setVid(newArr[0]);
          // var player =new tvp.Player();
          var player =  new Txp.Player({
            containerId: 'mod_player',
            width:500,
            height:300,
            vid: newArr[0]
          });

          player.on('playStateChange', function(callback){
            console.log('playStateChange',callback);
            if(callback.state == 1){
              that.dataApi.setVideoPlayCount(that.airCampustalk.acid).then((res:any) => {}).catch(() => {})
            }
          });

          // player.create({
          //   width:500,
          //   height:300,
          //   video:video,
          //   autoplay:false,
          //   modId:"mod_player", onwrite:function(){
          //     // alert("播放器显示完毕");
          //     console.log('播放器显示完毕',lastStr,video_urlArr)
          //   },
          //   pic: that.airCampustalk.video_cover
          // });
          // //events
          // player.onplaying = function(vid) {
          //   console.log('onplaying',vid);
          //   that.dataApi.setVideoPlayCount(that.airCampustalk.acid).then((res:any) => {
          //   }).catch(() => {})
          // }
          // player.onended = function(vid) {
          //   console.log('onended',vid);
          // }


          that.dataApi.setVideoPlayCount(that.airCampustalk.acid).then((res:any) => {
          }).catch(() => {})
        }
        this.formatAir_live_type();
        this.initWebConnect();
        this.getAirQuestion(1);
        this.getAirCampResumeList(true,0);

      }
    }).catch(() => {
    })
  }
  creatAirCampus(){
    this.router.navigateByUrl("home/creatAirCampus");
  }
  tjSchoolMode(){
    this.getApplyCollegeList()
    this.TJSchoolModel = true;
  }
  tjTalentMode(){
    this.smsDepartment();
    this.TJTalentModel= true;
  }
  closeModel(){
    this.TJSchoolModel = false;
    this.TJTalentModel = false;
  }
  //获取全部高校
  getAllCollege() {
    this.dataApi.getCollegeList({limit: 999}).then((res:any) => {
      if(res) {
        let arr = [];
        for (let n of res.list) {
          if(n.total_student>=1000) {
            arr.push(n);
          }
        }
        this.allCollege = arr;
      }
    }).catch(() => {
    })
  }
  //获取专业列表信息
  getMajorList(){
    let data  = {
      collegeids:''
    }
    this.dataApi.getagregationCount(data).then((res:any)=>{
      if(res) {
        let arr = [];
        let arr2 = [];
        for (let n of res.facetgroups) {
          if(n.level==1) {
            arr.push(n);
          } else {
            arr2.push(n);
          }
        }
        this.yuanxi1 = arr;
        this.yuanxi2 = arr2;
        let allArr = [];
        for (let n of this.yuanxi1) {
          let data = {
            code: n.code,
            count: n.count,
            level: 2,
            parentcode: n.code,
            parentcode_text:  n.text,
            text: "全部专业",
          }
          allArr.push(data);
        }
        this.yuanxi2 = [...allArr,...this.yuanxi2]
      }
    }).catch((error)=>{
      this.opera.loadiss();
    });
  }

  seletecollegeChange(){
    if(this.seleteCollegeids.length>=5) {
      this.toastr.warning("最多选择5所高校")
      return;
    }
    for(let n of this.seleteCollegeids) {
      if(n.collegeid == this.TJTalentModelData.collegeid) {
        this.toastr.warning("该高校已选择")
        return;
      }
    }
    for (let n of this.allCollege) {
      if(n.collegeid == this.TJTalentModelData.collegeid) {
        this.seleteCollegeids.push(n);
      }
    }
    this.smsDepartment();
  }
  deleteCollegeChange(id){
    this.TJTalentModelData.collegeid = "";
    let arr = [];
    for(let n of this.seleteCollegeids) {
      if(n.collegeid != id) {
        arr.push(n);
      }
    }
    this.seleteCollegeids = arr;
    this.smsDepartment();
  }
  //选择院系
  seleteyuanxiChange(){
    this.TJTalentModelData.majorTwo = '';
    let yuanxi3 = [];
    for (let yuanxi of this.yuanxi2) {
      if(yuanxi.parentcode == this.TJTalentModelData.majorOne) {
        yuanxi3.push(yuanxi)
      }
    }
    this.yuanxi3 = yuanxi3;
  }
  seleteyuanxiChange2(){
    if(this.TJTalentModelData.majorList.length>=5) {
      this.toastr.error('最多选择5个专业')
      return;
    }
    for (let n of this.TJTalentModelData.majorList) {
      if(n.code == this.TJTalentModelData.majorTwo) {
        return;
      }
    }
    for (let n of this.yuanxi3) {
      if(n.code == this.TJTalentModelData.majorTwo) {
        this.TJTalentModelData.majorList.push(n);
        break;
      }
    }
    this.smsDepartment();
  }
  deletemajorChange(code){
    if(this.TJTalentModelData.majorTwo ==code) {
      this.TJTalentModelData.majorTwo = "";
    }
    let arr = [];
    for(let n of this.TJTalentModelData.majorList) {
      if(n.code != code){
        arr.push(n)
      }
    }
    this.TJTalentModelData.majorList = arr;
    this.smsDepartment();
  }
  //获取学历列表信息
  getEducationList(){
    if(this.dataApi.getEducationCode()){
      this.config.education = this.dataApi.getEducationCode();
    }else{
      //获取学历列表
      this.dataApi.getDictDate('education').then((res:any)=>{
        this.dataApi.setEducationCode(res);
        this.opera.loadiss();
      }).catch((error)=>{
        this.opera.loadiss();
      });
    }
  }
  //区域字典
  getAreaList(){
    this.dataApi.getDictDate('region').then((res:any)=>{
      if(res){
        for(let i of res){
          if(i.level===1){
            this.area1.push(i)
          }else if(i.level===2){
            this.area2.push(i)
          }
        }
        let arr = [];
        for (let n of this.area1) {
          let item = {
            code: n.code,
            level: 2,
            order: 0,
            parentcode: n.code,
            parenttext: null,
            relation_code: null,
            relation_desc: "",
            text: n.text+'-全省',
          };
          arr.push(item);
        }
        this.area2 = [...arr,...this.area2]
      }
    })
  }
  seleteSexChange(){
    this.smsDepartment()
  }
  seleteEducationChange(){
    if(this.TJTalentModelData.educationList.length>=5) {
      this.toastr.error('最多选择5个学历')
      return;
    }
    for (let n of this.TJTalentModelData.educationList) {
      if(n.code == this.TJTalentModelData.education) {
        return;
      }
    }
    for (let n of this.config.education) {
      if(n.code == this.TJTalentModelData.education) {
        this.TJTalentModelData.educationList.push(n);
        break;
      }
    }
    this.smsDepartment();
  }
  deleteEducationChange(code){
    let newArr =[];
    if(code == this.TJTalentModelData.education) {
      this.TJTalentModelData.education = "";
    }
    for (let n of this.TJTalentModelData.educationList) {
      if(n.code != code) {
        newArr.push(n);
      }
    }
    this.TJTalentModelData.educationList = newArr;
    this.smsDepartment();
  }

  seleteareaChange(){
    this.TJTalentModelData.areaTwo = '';
    let area3 = [];
    for (let area of this.area2) {
      if(area.parentcode == this.TJTalentModelData.areaOne) {
        area3.push(area)
      }
    }
    this.area3 = area3;
  }
  seleteareaChange2(){
    if(this.TJTalentModelData.areaList.length>=5) {
      this.toastr.error('最多选择5个地区')
      return;
    }
    for (let n of this.TJTalentModelData.areaList) {
      if(n.code == this.TJTalentModelData.areaTwo) {
        return;
      }
    }
    for (let n of this.area3) {
      if(n.code == this.TJTalentModelData.areaTwo) {
        this.TJTalentModelData.areaList.push(n);
        break;
      }
    }
    this.smsDepartment()
  }
  deleteAareaChange(code){
    let newArr =[];
    if(code == this.TJTalentModelData.areaTwo) {
      this.TJTalentModelData.areaTwo = "";
    }
    for (let n of this.TJTalentModelData.areaList) {
      if(n.code != code) {
        newArr.push(n);
      }
    }
    this.TJTalentModelData.areaList = newArr;
    this.smsDepartment()
  }
  resuleCountClick(type){
    if(type=="+") {
      this.TJTalentModelData.resuleCount+=1;
    }
    else {
      if(this.TJTalentModelData.resuleCount==0) {
        return;
      }
      this.TJTalentModelData.resuleCount-=1;
    }
  }
  //下线空宣
  downLineClick(){
    this.downLineModel = true;
  }
  //编辑空宣
  editAirCampClick(){
    this.router.navigateByUrl("home/creatAirCampus");
  }
  //分享空宣
  shareAirCampClick(){
    if(!this.shareImageUrl) {
      let data =  {
        acid:this.airCampustalk.acid
      }
      this.dataApi.getAircampustalkImage(this.airCampustalk.acid,data).then((res:any)=>{
        if(res) {
          this.shareImageUrl = res;
          this.toastr.warning('网络繁忙，请稍后再试')
        }
      }).catch((error)=>{
        this.toastr.warning(error);
      });
      return;
    }
    let that  = this;
    let text = "<p style='text-align: center;margin-top: 20px;margin-bottom: 15px' class='qrcode_t'>手机扫码分享</p> <div style='margin-left: 20px;margin-top: 20px' class='qrcode' id='qrcode'></div>";
    layui.use(['layer'], function() {
      var layer = layui.layer;
      layer.open({
        type: 1,
        title: false, //不显示标题
        area: ['240px', '280px'], //宽高
        content: text
      });
      setTimeout(()=>{
        let qrcode1 = document.getElementById("qrcode");
        console.log("qrcode",qrcode1)
        var qrcode = new QRCode('qrcode', {
          text:that.shareImageUrl,
          width: 200,
          height: 200,
          typeNumber:-1,
          colorDark : '#000000',
          colorLight : '#ffffff',
        });
      },300)
    })
  }
  tabClick(tab){
    this.currentTab = tab;
  }
  talentTabClick(tab){
    this.talentTab = tab;
    this.getAirCampResumeList(true,0)
  }
  questionTabTabClick(tab){
    this.questionTab = tab;
    this.getAirQuestion(1);
  }

  getAirQuestion(page){
    let that = this;
    let data = {
      acid:this.airCampustalk.acid,
      type:'0',
      isAnswer:this.questionTab=="1"?false:true,
      page:page,
      pageSize:'10',
    }
    this.dataApi.getAirQuestion(data).then((res:any) => {
      if(res) {
        console.log('getAirQuestion',res,data)
        for(let n of res.list) {
          n.selete = false;
        }
        this.questionList = res.list;
        this.AllquestionCount = res.count;
        this.seleteQuestionList=[];
        this.isALlSeletequestion = false;

        layui.use('laypage', function(){
          var laypage = layui.laypage;
          //执行一个laypage实例
          laypage.render({
            elem: 'test1' //注意，这里的 test1 是 ID，不用加 # 号
            ,count: res.count, //数据总数，从服务端得到
            limit:10,
            theme: '#5e4fff',
            layout:['prev', 'page', 'next','skip','count']
            ,jump: function(obj, first){
              //obj包含了当前分页的所有参数，比如：
              that.getAirQuestionNonePage(obj.curr)
              //首次不执行
              if(!first){
              }
            }
          });
        });
      }
    }).catch(() => {
    })
  }
  getAirQuestionNonePage(page){
    let data = {
      acid:this.airCampustalk.acid,
      type:'0',
      isAnswer:this.questionTab=="1"?false:true,
      page:page,
      pageSize:'10',
    }
    this.dataApi.getAirQuestion(data).then((res:any) => {
      if(res) {
        for(let n of res.list) {
          n.selete = false;
        }
        this.questionList = res.list;
        this.AllquestionCount = res.count;
        this.seleteQuestionList=[];
        this.isALlSeletequestion = false;
      }
    }).catch(() => {
    })
  }
  getApplyCollegeList(){
    this.dataApi.getApplyCollegeList(this.airCampustalk.acid).then((res:any) => {
      if(res) {
        let arr = [];
        for (let n of res.all){
          n.selete = false;
          n.audit_status = n.audit_status+'';
          arr.push(n);
        }
        let not_passed = [];
        for (let n of res.not_passed){
          n.isApply = true;
          let nowDate = new Date();
          let nowTime = nowDate.getTime();  // 当前时间戳
          let endDate = new Date(n.limit_apply_time); // 报名开始时间
          let endDateTime = endDate.getTime();  // 报名结束时间戳
          if(nowTime-endDateTime>=0){
            console.log('可以申请')
            n.isApply = true;
            n.ApplyStr = "";
          } else {
            n.isApply = false;
            n.ApplyStr = "如需申请，请在"+n.limit_day+"个工作日后提交";
          }
          not_passed.push(n);
        }
        res.all = arr;
        res.not_passed = not_passed;
        this.ApplyCollegeData = res;
      }
    }).catch(() => {
    })
  }
  //单选所有高校推荐
  AllApplyCollegeClick(college){
    if(college.audit_status!='0') {
      return;
    }
    let arr = [];
    for (let n of this.ApplyCollegeData.all){
      if(n.collegeid==college.collegeid) {
        n.selete = !n.selete;
      }
      arr.push(n);
    }
    this.ApplyCollegeData.all = arr;
  }
  //全选高校推荐
  AllQuanClick(){
    let arr = [];
    this.isAllSeleteCollege = !this.isAllSeleteCollege;
    for (let n of this.ApplyCollegeData.all){
      n.selete = this.isAllSeleteCollege;
      if(n.audit_status!=0) {
        n.selete = false;
      }
      arr.push(n);
    }
    this.ApplyCollegeData.all = arr;
  }
  //获取符合推荐到学生的人数
  smsDepartment(){
    let collegeids= [];
    let majors = [];
    let native_places = [];
    let educations = "";
    for (let n of this.seleteCollegeids) {
      collegeids.push(n.collegeid);
    }
    for (let n of this.TJTalentModelData.majorList) {
      majors.push(n.code);
    }
    for (let n of this.TJTalentModelData.areaList) {
      native_places.push(n.code);
    }
    for (let n of this.TJTalentModelData.educationList) {
      educations=educations+","+n.code
    }
    let model = {
      education:educations, // 学历
      gender:this.TJTalentModelData.sex, // 学历
      activityid:this.airCampustalk.acid,  // 会议id
      mc_type:2,  // 活动类型
      majors:majors, // 院系集合
      collegeids:collegeids ,  // 学校Id
      native_places:native_places
    }
    this.dataApi.getagregationquery(model).then((res:any)=>{
      if(res) {
        console.log("获取符合单品通知包使用的人数",model,res.value)
        this.TJTalentModelData.filtCount = res.value;
        this.TJTalentModelData.resuleCount = res.value;
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  //推荐的学生参数
  goTJTalent(){
    if(this.TJTalentModelData.resuleCount==0) {
      return;
    }
    if((this.MyActivityCount-this.TJTalentModelData.resuleCount)<0) {
      this.product('notice_student_sms','recharge');
      // this.TJTalentModel = false;
      // let that = this;
      // this.config.payVipHistoryPage = {
      //   page:'空中宣讲会',
      //   isVip:false,
      //   pro:'空宣推广'
      // };
      // that.local.set("single_category_code", this.config.ProductListCode.notice_student_sms_code);
      // that.router.navigate(['home/tool-campus']);
      return;
    }
    let that = this;
    layui.use('layer', function(){
      var layer = layui.layer;
      layer.confirm('确定要发送吗', {
        btn: ['确定','取消'] //按钮
      }, function(){
        //执行活动邀请HTTP
        let collegeids= [];
        let majors = [];
        let native_places = [];
        let educations = "";
        for (let n of that.seleteCollegeids) {
          collegeids.push(n.collegeid);
        }
        for (let n of that.TJTalentModelData.majorList) {
          majors.push(n.code);
        }
        for (let n of that.TJTalentModelData.areaList) {
          native_places.push(n.code);
        }
        for (let n of that.TJTalentModelData.educationList) {
          educations=educations+","+n.code
        }
        let model = {
          education:educations, // 学历
          gender:that.TJTalentModelData.sex, // 学历
          activityid:that.airCampustalk.acid,  // 会议id
          mc_type:"air_campustalk",  // 活动类型
          majors:majors, // 院系集合
          collegeids:collegeids ,  // 学校Id
          native_places:native_places,
          invite_count:that.TJTalentModelData.resuleCount
        }
        that.dataApi.air_campustalk_applyTalent(model).then((res:any)=>{
          layer.msg('已发送', {icon: 1});
          that.TJTalentModel = false;
          //获取单品的余额
          that.getDepartment();
        }).catch((err)=>{
          that.toastr.error(err)
          console.log('推荐的学生参数',err);
        })
      }, function(){
        layer.msg('已取消发送', {icon: 2});
      });
    });
  }
  //获取企业信息
  getDepartment(){
    this.dataApi.getcomponentLeft({}).then((res:any)=>{
      res.map((item)=>{
        if(item.alias ==  'notice_student_sms') {
          this.MyActivityCount = item.left;
        }
      })
    }).catch((err)=>{
      console.log(err);
    })
  }
  //立即推荐到高校就业信息网
  tiCollegeClick(){
    let collegeids = [];
    for(let n of this.ApplyCollegeData.all) {
      if(n.selete) {
        collegeids.push(n.collegeid)
      }
    }
    if(collegeids.length==0) {
      this.toastr.warning('未选择高校')
      return;
    }
    let data = {
      acid: this.airCampustalk.acid,
      collegeids:collegeids
    }
    this.dataApi.air_campustalk_applyCollege(data).then((res:any)=>{//通过tooken获取企业信息
      this.toastr.success('推广申请成功')
      this.TJSchoolModel = false;
      this.getApplyCollegeList();
    }).catch((error)=>{
      this.toastr.error(error);
    });
  }
  //重新高校到就业信息网
  resetCommitCollege(n){
    let data = {
      acid: this.airCampustalk.acid,
      collegeids:[n.collegeid]
    }
    this.dataApi.air_campustalk_applyCollege(data).then((res:any)=>{//通过tooken获取企业信息
      this.toastr.success('已重新提交')
      this.getApplyCollegeList();
    }).catch((error)=>{
      this.toastr.error(error);
    });
  }
  //添加新的问题弹窗
  addNewquestionModelClick(type,question){
    this.addNewquestionModel = true;
    this.addNewquestionData.question="";
    this.addNewquestionData.answer="";
    this.addNewquestionData.acid = this.airCampustalk.acid;
    if(type=='answer') {
      this.currentAnswerTalent = question;
      this.addNewquestionData.question = question.question;
    }
    console.log(this.currentAnswerTalent)
  }
  addNewquestionBoxHiden(){
    this.addNewquestionModel = false;
    this.currentAnswerTalent =""
    this.addNewquestionData.question = "";
  }
  //确定添加新的问题
  addNewquestionClick(){
    //如果有回复数据 走 回复逻辑
    if(this.currentAnswerTalent) {
      if(!this.addNewquestionData.answer) {
        return;
      }
      let data = {
        airquestionid:this.currentAnswerTalent.id,
        answer:this.addNewquestionData.answer,
        is_public:true,
      }
      this.dataApi.AnswerTalent(data).then((res:any)=>{//通过tooken获取企业信息
        this.addNewquestionModel = false;
        this.toastr.success('回复成功')
        this.currentAnswerTalent = "";
        this.getAirQuestion(1);
      }).catch((error)=>{
        this.toastr.error(error);
        this.addNewquestionModel = false;
      });
      return;
    }
    if(!this.addNewquestionData.question||!this.addNewquestionData.answer||!this.addNewquestionData.acid) {
      return;
    }
    this.dataApi.creatAirQuestion([this.addNewquestionData]).then((res:any)=>{//通过tooken获取企业信息
      console.log('creatAirQuestion',res);
      this.addNewquestionModel = false;
      this.toastr.success('添加成功')
      this.getAirQuestion(1);
    }).catch((error)=>{
      this.toastr.error(error);
      this.addNewquestionModel = false;
    });
  }
  questionCellClick(q) {
    //未回答，不走编辑逻辑
    if(this.questionTab=='1') {
      return;
    }
    let newArr = [];
    let seleteArr = [];
    for(let n of this.questionList ) {
      if(n.id==q.id) {
        n.selete =!n.selete;
      }
      if(n.selete) {
        seleteArr.push(n.id);
      }
      newArr.push(n);
    }
    this.seleteQuestionList = seleteArr;
    this.questionList = newArr;
  }
  allSeletequestionClick(){
    let newArr= [];
    let seleteArr = [];
    this.isALlSeletequestion = !this.isALlSeletequestion;
    for(let n of this.questionList ) {
      n.selete = this.isALlSeletequestion
      newArr.push(n);
      if(n.selete) {
        seleteArr.push(n.id)
      }
    }
    this.seleteQuestionList = seleteArr;
    this.questionList = newArr;
  }
  //设置问题是否公开
  questionMenuClick(type){
    let data = {
      questionids:this.seleteQuestionList,
      is_public:type=="open"?true:false
    }
    this.dataApi.setQuestionIsPublic(data).then((res:any)=>{
      this.getAirQuestion(1);
      this.toastr.success('设置成功')
    }).catch((err)=>{
      console.log(err);
    })
  }
  getSmsContent(){
    this.dataApi.getSmsMban( '空中宣讲会企业推广').then((res:any)=>{
      console.log("短信模版",res.content)
      if(res) {
        this.TJTalentModelData.SmsContent = res.content;
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  getAirCampResumeList(isFirst,page){
    // talentTab  1全部， 2 查看  3投递
    let offset = page*10;
    let data = {};
    if(this.talentTab=='1') {
      data = {
        offset:offset,
        limit:'12'
      }
    }
    else if(this.talentTab=='3') {
      data = {
        offset:offset,
        limit:'12',
        is_delivery:true
      }
    }
    else if(this.talentTab=='2') {
      data = {
        offset:offset,
        limit:'12',
        is_view:true
      }
    }
    this.dataApi.getAirCampResumeList(this.airCampustalk.acid,data).then((res:any)=>{
      if(res) {
        this.AllresumeCount = res.count;
        this.resumeList = res.list;
        for (let val of  this.resumeList ) {
          let arr = [];
          val.yixiang = "";
          val.youshiArr = [];
          val.education_text =this.opera.educationFormat(val.education_text)
          for (let item1 of val.pro_skills) {
            val.yixiang += item1.text+"    "
            if(item1.contrast_list){
              for (let youshi of item1.contrast_list) {
                val.youshiArr.push(youshi.text);
              }
            }
          }
        }
        let that = this;
        layui.use('laypage', function(){
          var laypage = layui.laypage;
          //执行一个laypage实例
          laypage.render({
            elem: 'resumePage' //注意，这里的 test1 是 ID，不用加 # 号
            ,count: res.count, //数据总数，从服务端得到
            limit:12,
            theme: '#5e4fff',
            layout:['prev', 'page', 'next','skip','count']
            ,jump: function(obj, first){
              if(!first){
                console.log('学生简历首次不执行');
                that.getAirCampResumeLisPage(obj.curr)
              }
            }
          });
        });
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  getAirCampResumeLisPage(page){
    // talentTab  1全部， 2 查看  3投递
    let offset = (page-1)*12;;
    let data = {};
    if(this.talentTab=='1') {
      data = {
        offset:offset,
        limit:'12'
      }
    }
    else if(this.talentTab=='3') {
      data = {
        offset:offset,
        limit:'12',
        is_delivery:true
      }
    }
    else if(this.talentTab=='2') {
      data = {
        offset:offset,
        limit:'12',
        is_view:true
      }
    }
    this.dataApi.getAirCampResumeList(this.airCampustalk.acid,data).then((res:any)=>{
      if(res) {
        this.AllresumeCount = res.count;
        this.resumeList = res.list;
        for (let val of  this.resumeList ) {
          let arr = [];
          val.yixiang = "";
          val.youshiArr = [];
          for (let item1 of val.pro_skills) {
            val.yixiang += item1.text+"    "
            if(item1.contrast_list){
              for (let youshi of item1.contrast_list) {
                val.youshiArr.push(youshi.text);
              }
            }
          }
        }
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  //下线宣讲会
  downLineModekClick(isdown){
    if(!isdown) {
      this.downLineModel = false;
      return;
    }
    this.downLineModel = false;
    let data = {
      is_online:false
    }
    this.dataApi.downLine(this.airCampustalk.acid,data).then((res:any)=>{
      console.log("下线",)
      this.toastr.success('下线成功')
      this.router.navigateByUrl("home/airCampHistory");
    }).catch((err)=>{
      console.log(err);
    })
  }
  checkFlash(){
      var hasFlash = 0;　　　　 //是否安装了flash
      var flashVersion = 0;　　 //flash版本
      if(document.all) {
        var swf = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
        if(swf) {
          hasFlash = 1;
          VSwf = swf.GetVariable("$version");
          flashVersion = parseInt(VSwf.split(" ")[1].split(",")[0]);
        }
      } else {
        if(navigator.plugins && navigator.plugins.length > 0) {
          var swf = navigator.plugins["Shockwave Flash"];
          if(swf) {
            hasFlash = 1;
            var words = swf.description.split(" ");
            for(var i = 0; i < words.length; ++i) {
              if(isNaN(parseInt(words[i]))){
                continue;
              }
              flashVersion = parseInt(words[i]);
            }
          }
        }
      }
    if(hasFlash){
      console.log("您安装了flash,当前flash版本为: " + flashVersion + ".x");
    }else {
      console.log("您没有安装flash");
    }
  }
  //极速电话联系
  voiceCallClick(e,n){
    e.stopPropagation();
    let that = this;
    this.productManager.getProductUsableCount(this.config.voice_call,(count,isVip)=>{
      if(count==0||!isVip) {
        this.config.payVipHistoryPage = {
          page:'空中宣讲会',
          isVip:isVip,
          pro:'极速电话联系'
        };
        that.local.set("single_category_code",this.config.ProductListCode.voice_call_code);
        that.router.navigate(['home/tool-campus']);
      }
      else {
        this.voiceCallModel.initData(n);
        this.isShowVoiceCallModel = true;
      }
    })
  }
  positionPushClick(e,n){
    e.stopPropagation();
    let that  = this;
    this.productManager.getProductUsableCount(this.config.sms_bulkinvitation,(count,isVip)=>{
      if(count==0||!isVip) {
        this.config.payVipHistoryPage = {
          page:"空中宣讲会",
          isVip:isVip,
          pro:'职位推送'
        };
        that.local.set("single_category_code", this.config.ProductListCode.sms_bulkinvitation_code);
        that.router.navigate(['home/tool-campus']);
      }
      else {
        this.sengMsgModel.initData(1,[]);
        this.SengMsgModel.userName = n.talent_name;
        this.SengMsgModel.userPic = n.logo==null?'': n.logo;
        this.SengMsgModel.graduation_school = n.graduate_university;
        this.SengMsgModel.major_text = n.major_text;
        this.SengMsgModel.talentid = n.talentid;
        this.SengMsgModel.gender = n.gender;
        this.SengMsgModel.address = n.address_text;
        this.isShowPositionPushModel =true;
      }
    })
  }
  //极速电话联系弹窗消失
  voiceCallModelHidden(e){
    this.isShowVoiceCallModel = false;
  }
  Modeldismiss(event){
    this.isShowPositionPushModel =false;
    this.config.batchList=[];
  }
  getShareImage(){
    let data =  {
      acid:this.airCampustalk.acid
    }
    this.dataApi.getAircampustalkImage(this.airCampustalk.acid,data).then((res:any)=>{
      if(res) {
        this.shareImageUrl = res
      }
    }).catch((error)=>{
      this.opera.loadiss();
    });
  }
//-----跳转简历详情
  goDetails(n,is_ignore){
    this.dataApi.getPlatformApply().then((res:any)=>{
      if(res) {
        if(res.status == 0){
          this.dialog.open(NoneVipAlertComponent, {
            data: {
              type:'no_audit',
              message:this.config
            }
          }).afterClosed().subscribe((isConfirmed) => {
            this.opera.scrollTrue();
            console.log('isConfirmed',isConfirmed)
            if(isConfirmed){
            }
          });
        }else if(res.status == 1){
          this.dialog.open(NoneVipAlertComponent, {
            data: {
              type:'audit',
              message:this.config
            }
          }).afterClosed().subscribe((isConfirmed) => {
            this.opera.scrollTrue();
            console.log('isConfirmed',isConfirmed)
            if(isConfirmed){
            }
          });
        }else if(res.status == 2){
          if(this.opera.isShowPaySuccessModule()){
            if(is_ignore == false){
              this.router.navigate(['home/college-resumedetails'], {
                queryParams: {
                  resumeid:n.resume_number
                }
              });
            }
          }else{
            this.dialog.open(NoneVipAlertComponent, {
              data: {
                type:'goBuy',
                message:this.config
              }
            }).afterClosed().subscribe((isConfirmed) => {
              this.opera.scrollTrue();
              console.log('isConfirmed',isConfirmed)
              if(isConfirmed){
              }
            });
          }
        }else if(res.status == 3){
          this.dialog.open(NoneVipAlertComponent, {
            data: {
              type:'fail',
              message:this.config
            }
          }).afterClosed().subscribe((isConfirmed) => {
            this.opera.scrollTrue();
            console.log('isConfirmed',isConfirmed)
            if(isConfirmed){
            }
          });
        }
        return;
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  initWebConnect(){
    let that = this;
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)
      .withUrl(this.config.liveSocket, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => that.airCampustalk.live_model.liveid
      })
      .build();
    connection.start().then(function () {
      console.log('connection成功');
    }).catch(function (err) {
      console.log('connection2',connection);
      if(err){
        return console.error(err.toString());
      }
    });

    if(connection){
      connection.on("live_status", function (message) {
        //服务端发过来的消息
        console.log('ReceiveMessage',message);
        if(message){
          if(message.liveid == that.airCampustalk.live_model.liveid){
            that.airCampustalk.air_live_type =message.status;
            that.formatAir_live_type();
          }
        }
      });
    }
  }
  startLiveTime(){
    let that = this;
    let currentData = new Date();
    console.log('startLiveTime',this.airCampustalk)
    if(this.airCampustalk.live_start_time){
      let startTime =  new Date(this.airCampustalk.live_start_time);
      let longTime = (currentData.getTime() - startTime.getTime());
      longTime =  parseInt(longTime+'');
      this.startTime  = setInterval(()=>{
        longTime +=1000;
        let time = that.formatDateHour(longTime/1000)
        that.liveState.content = "已进行："+ (time<=0?'':time)
      },1000)
    }
  }
  ngOnDestroy(){
    console.log('ngOnDestroy')
    if(this.startTime){
      clearInterval(this.startTime)
    }
  }
  formatDateHour(mss) {
    let  DateTimes = null;
    let days = parseInt((mss / ( 60 * 60 * 24))+'');
    let hours = parseInt(((mss % ( 60 * 60 * 24)) / (60 * 60))+'');
    let minutes = parseInt(((mss % ( 60 * 60)) /60)+'');
    let seconds =parseInt( (mss % 60)+'');
    if(days>0){
      DateTimes= days + "天" + hours + "小时" + minutes + "分钟"
        + seconds + "秒";
    }else if(hours>0){
      DateTimes=hours + "小时" + minutes + "分钟"
        + seconds + "秒";
    }else if(minutes>0){
      DateTimes=minutes + "分钟"
        + seconds + "秒";
    }else{
      if(seconds<=0){
        seconds = 0
      }
      DateTimes=seconds + "秒";
    }
    return DateTimes;
  }
  formatAir_live_type(){
    if(this.airCampustalk.air_live_type == 1) {
      console.log('直播未开始');
      this.liveState.state = 1;
      this.liveState.title = "专属直播宣讲即将开始，请认真准备";
      this.liveState.cover = this.airCampustalk.air_live_images[0].url
    }
    if(this.airCampustalk.air_live_type == 2) {
      console.log('直播进行中');
      this.liveState.state = 2;
      this.liveState.title = "直播宣讲中 ...";
      this.liveState.content = "直播宣讲中";
      this.liveState.cover = '../../assets/images/live_state_liveing.png'
      this.startLiveTime();
    }
    if(this.airCampustalk.air_live_type == 3) {
      console.log('直播已中断');
      this.liveState.state = 3;
      this.liveState.title = "直播宣讲已中断";
      this.liveState.content = "直播宣讲已中断";
      this.liveState.cover = this.airCampustalk.air_live_images[1].url;
      if(this.startTime){
          clearInterval(this.startTime)
          this.liveState.content = "直播宣讲已中断";
      }
    }
    if(this.airCampustalk.air_live_type == 4) {
      this.liveState.content = this.airCampustalk.video_play_count + "人观看直播";
      this.liveState.state = 4;
      this.liveState.title = "直播宣讲已结束，回放视频上传中……";
      this.liveState.cover = this.airCampustalk.air_live_images[2].url;
      console.log('直播已结束')
    }
  }

  //点击产品
  product(alias,type){
    this.productModel.initData(alias,type);
    this.isproductShowModel = true;
  }

  productModelHidden(event){    
    this.isproductShowModel = false;
  }

}
