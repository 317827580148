<!-- V3.0-->
<div class="positionPushs">
    <div class="headerReturn">
        <div class="center">
            <div *ngIf="!config.isByCollege" (click)="opera.goPre()">
                <img src="/assets/images/v3/headerReturnIcon.png"> 推荐人才
            </div>
            <div *ngIf="config.isByCollege" (click)="opera.goNewRouter('college-details-student')">
                <img src="/assets/images/v3/headerReturnIcon.png"> 推荐人才
            </div>
        </div>
    </div>
    <div class="positionPushsMain">
        <div class="center">
            <div class="iocn">
                <img src="/assets/images/v3/pushsIcon.png">
                <div class="right">
                    <div class="name">批量职位推送</div>
                    <div class="introduce">精准触达，揽获更多精准投递</div>
                </div>
            </div>

            <div class="module">
                <div class="left"><span style="color: red;">*</span>请选择您要邀请的职位</div>
                <div class="right">
                    <div class="val">
                        <select class="activeBg" [(ngModel)]='checkedJob' (change)="getPosition()">
                            <option value="" disabled>请选择职位</option>
                            <option *ngFor='let position of jobinfo' value={{position.positionid}}>{{position.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="module">
                <div class="left"><span style="color: red;">*</span>请确定您的联系电话号</div>
                <div class="right">
                    <div class="val">
                        <input type="text" class="phoneText" [(ngModel)]="phoneText" (ngModelChange)="changeinput($event)" placeholder="请输入HR招聘电话" />
                    </div>
                </div>
            </div>

            <!--企业卡片-->
            <div class="module" *ngIf="position && position.post_type!=null">
                <div class="left"></div>
                <div class="cardContainer">
                    <div class="__top">
                        <div>
                            <div class="__name">{{position.name}}</div>
                            <div class="text">{{cominfo.employer_type_text}} | {{cominfo.scale_text}} | {{cominfo.industry_text}}
                            </div>
                        </div>
                        <div class="salary">{{position.position_salary_text}}</div>
                    </div>
                    <div class="lables">
                        <span *ngFor="let tips of cominfo.labels">{{tips.text}}</span>
                    </div>
                    <div class="bottomBox">
                        <div class="leftBox">
                            <img class="logo" [src]='cominfo.logo'>
                            <div class="cominfoName">{{cominfo.name}}</div>
                        </div>
                        <div class="rightText">
                            <span>{{cominfo.employer_type_text}} | </span>
                            <span>{{cominfo.scale_text}} | </span>
                            <span>{{cominfo.industry_text}}</span>
                        </div>
                    </div>
                </div>

            </div>

            <!--推送意向-->
            <div class="module">
                <div class="left"></div>
                <div class="intentionPush">
                    <div class="_title">按学生求职意向推送</div>
                    <div class="_tip">系统默认按学生求职意向筛选，将您的职位推送到更合适的学生 您可根据需要忽略学生求职意向。</div>
                    <div class="btns">
                        <div class="btn" [class.active]='!isIgnore' (click)="changeIgnore(false)">按求职意向</div>
                        <div class="btn" [class.active]='isIgnore' (click)="changeIgnore(true)">忽略求职意向</div>
                    </div>
                </div>
            </div>

            <!--届别-->
            <div class="module">
                <div class="left"><span style="color: red;">*</span>届别</div>
                <div class="right">
                    <div class="val">
                        <select name="gender" class="activeBg" [(ngModel)]="year" (change)="getPositionPushInfoJieBieChange()">
                            <option value=''>请选择届别</option>
                            <option *ngFor="let city of jiebieList" [value]="city.code">{{city.text}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <!--高校-->
            <div class="module" style="display: none;">
                <div class="left">高校</div>
                <div class="right">
                    <div class="val">
                        <select name="" class="activeBg" [(ngModel)]='college' (change)='addCollege($event)'>
                            <option value="">点击添加高校</option>
                            <option *ngFor='let col of allCollege' value={{col.collegeid}}>{{col.name}}</option>
                        </select>
                        <div class="limit">({{collegeList.length}}/5)</div>
                    </div>
                    <div *ngIf='collegeShowList.length>0' class="selecetCollege">
                        <span *ngFor='let college of collegeShowList;let i = index'>{{college.name}}
                            <img (click)=deleteCollege(i) src="/assets/images/v3/speedClos.png">
                        </span>
                    </div>
                </div>
            </div>

            <div class="module" *ngIf="year">
                <div class="left">高校</div>
                <div class="right">
                    <div class="val" style="background: none;border: none;">
                        <p-multiSelect placeholder="点击添加高校" [style]="{'width':'100%','height':'45px','background':'#FBFBFB','border':'1px solid #D3D3D3'}" display="chip" [options]="allCollege" [showToggleAll]="false" [(ngModel)]="collegeList" optionLabel="name" optionValue="collegeid"
                            [selectionLimit]="5" (onChange)="multiSelectonChange($event)" emptyMessage="暂无数据">
                        </p-multiSelect>
                        <div class="limit">({{collegeList.length}}/5)</div>
                    </div>
                </div>
            </div>

            <!--专业-->
            <div class="module">
                <div class="left"><span style="color: red;" *ngIf="!isIgnore">*</span>专业</div>
                <div class="right">
                    <div class="val fn">
                        <select class="activeBg" name="" (change)=getMajorLevel2List() [(ngModel)]='majorLevel1'>
                            <option value="">请选择学科门类</option>
                            <option *ngFor='let major1 of majorLevel1List' value={{major1.code}}>{{major1.text}}
                            </option>
                        </select>
                        <select class="activeBg active" name="" [(ngModel)]='majorLevel2' (change)='addMajor($event)'>
                            <option value="" disabled>点击添加专业类别</option>
                            <option *ngFor='let major2 of majorLevel2List' value={{major2.code}}>{{major2.text}}
                            </option>
                        </select>
                        <div class="limit">({{majorList.length}}/10)</div>
                    </div>
                    <div *ngIf='majorLevel2ShowList.length>0' class="selecetCollege">
                        <span *ngFor='let major of majorLevel2ShowList;let i = index'>{{getparentcode_textAndmajor_text(major)}}
                            <img (click)=deleteMajor(i) src="/assets/images/v3/speedClos.png">
                        </span>
                    </div>
                </div>
            </div>

            <!--学历-->
            <div class="module">
                <div class="left">学历</div>
                <div class="right">
                    <div class="val">
                        <select name="" class="activeBg" [(ngModel)]='education' (change)="getPositionPushInfo()">
                            <option value="">不限</option>
                            <option value="03">本科</option>
                            <option value="02">大专</option>
                        </select>
                    </div>
                </div>
            </div>

            <!--性别-->
            <div class="module">
                <div class="left">性别</div>
                <div class="right">
                    <div class="val">
                        <select name="gender" class="activeBg" [(ngModel)]="checkedSex" (change)="getPositionPushInfo()">
                            <option value=''>不限</option>
                            <option value='01'>男</option>
                            <option value='02'>女</option>
                        </select>
                    </div>
                </div>
            </div>

            <!--户口所在地-->
            <div class="module">
                <div class="left">户口所在地</div>
                <div class="right">
                    <div class="val fn">
                        <select class="activeBg" name="" [(ngModel)]="area1" (change)="changeAreaLevelList2()">
                            <option value="">请选择所在省份</option>
                            <option value={{level1.code}} *ngFor='let level1 of areaListLevel1'>{{level1.text}}</option>
                        </select>
                        <select class="activeBg active" name="" [(ngModel)]="area2" (change)='addArea($event)'>
                            <option value="" disabled>请选择所在市</option>
                            <option value={{level2.code}} *ngFor='let level2 of areaShowListLevel2'>{{level2.text}}
                            </option>
                        </select>
                        <div class="limit">({{areaList.length}}/5)</div>
                    </div>
                    <div *ngIf='areaShowList.length>0' class="selecetCollege">
                        <span *ngFor='let area of areaShowList;let i = index'>{{area.text}}
                            <img (click)=deleteArea(i) src="/assets/images/v3/speedClos.png">
                        </span>
                    </div>
                </div>
            </div>


            <div class="module">
                <div class="left"></div>
                <div class="right">
                    <div class="_number">
                        <div class="accordWidth">
                            <div class="_container">
                                <div class="_name">符合以上条件的学生数</div>
                                <div class="numb"><span>{{stuMatchCount}}</span>人</div>
                                <div class="text">如需邀请更多学生，请适当放宽筛选条件</div>
                            </div>
                        </div>
                        <div class="thisTime">
                            <div class="_name">本次推送通知的学生数</div>
                            <div class="_val">
                                <input type="number" (change)="checkNum()" [(ngModel)]='stucount' min="0" />
                                <span>人次</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--通知内容-->
            <div class="module">
                <div class="left"></div>
                <div class="notice">
                    <div class="content">
                        <div class="_title">通知内容</div>
                        <div class="contentText">{{sendMsg}}</div>
                        <img src="/assets/images/v3/contentText.png">
                    </div>
                    <div class="pushBtn blueStreamer" (click)="inviteStuAlert()">立即推送</div>
                    <div class="p">需消耗资源数<span>{{stucount}}</span>，(当前剩余资源<span>{{totalCoin}}</span>)</div>

                </div>
            </div>

            <div class="module">
                <div class="left"></div>
                <div class="p2">
                    发送时，系统将为您屏蔽“已投递及14天内已推送或其他特殊情况无法推送的学生”。发送任务执行完毕，系统将会在三个工作日内将未推送成功的相应资源数原路退还。
                </div>
            </div>


        </div>
    </div>

</div>


<!-- 主体内容部分 -->
<div class="homepage-block" style="display: none;">
    <div class="container" style="display: flex;justify-content: center">
        <div>
            <div class="selectJob">
                <span>请选择您要邀请的职位：</span>
                <select name="" class="downLoad_right" [(ngModel)]='checkedJob' (change)="getPosition()">
                    <option value="" disabled>请选择职位</option>
                    <option *ngFor='let position of jobinfo' value={{position.positionid}}>{{position.name}}</option>
                </select>
            </div>
            <div class="selectJob">
                <span>请确定您的联系电话号：</span>
                <input type="text" class="phoneText" [(ngModel)]="phoneText" (ngModelChange)="changeinput($event)" placeholder="请输入HR招聘电话" />
            </div>

            <div class="jobTips" *ngIf="position.post_type!=null">
                <div class="topTips">
                    <span class="jobname">{{position.name}}</span>
                    <span class="salary">{{position.position_salary_text}}</span>
                    <br>
                    <button *ngFor="let tips of cominfo.labels" class="comtips">{{tips.text}}</button>

                </div>
                <div class="botTips">
                    <img [src]='cominfo.logo'>
                    <div class="cominfo">
                        <span class="comname">
                            {{cominfo.name}}
                        </span>
                        <br>
                        <span class="comtype">
                            <span>{{cominfo.employer_type_text}} | </span>
                        <span>{{cominfo.scale_text}} | </span>
                        <span>{{cominfo.industry_text}}</span>
                        </span>
                    </div>
                </div>
            </div>

            <div>
                <label for="1"><input class="isIgnore" (click)="changeIgnore(false)" type="radio" name="a"
                        checked="checked" /> 按学生求职意向 </label>
                <span *ngIf='position&&isIgnore==false'>: {{position.name}}</span>
                <label for="1"> <input class="isIgnore" (click)="changeIgnore(true)" type="radio" name="a" /> 忽略学生求职意向
                </label>
                <br>
                <span class='remark'>系统默认按学生求职意向筛选，将您的职位推送到更合适的学生 您可根据需要忽略学生求职意向。</span>
            </div>
            <div class='stuType'>

                <span><img style="width: 16px;height: 16px;margin-top: -3px;" src="../../assets/images/sectors.png">
                    届别</span>
                <select name="gender" class="downLoad_right" [(ngModel)]="year" (change)="getPositionPushInfoJieBieChange()">
                    <option value=''>请选择届别</option>
                    <option *ngFor="let city of jiebieList" [value]="city.code">{{city.text}}</option>
                </select>
                <br>
                <span><img src="../../assets/images/positionpush-college.png"> 高校</span>
                <select name="" class="downLoad_right" [(ngModel)]='college' (change)='addCollege($event)'>
                    <option value="">点击添加高校</option>
                    <option *ngFor='let col of allCollege' value={{col.collegeid}}>{{col.name}}</option>
                </select> {{collegeList.length}}/5
                <br>
                <div *ngIf='collegeShowList.length>0' class="selecetCollege">
                    <span *ngFor='let college of collegeShowList;let i = index'>{{college.name}}
                        <span class="red" (click)=deleteCollege(i)> × </span>
                    </span>
                </div>
                <span><img src="../../assets/images/positionpush-major.png"> 专业 <span *ngIf="!isIgnore"
                        style="color: red">*</span></span>
                <select class="shortSlc downLoad_right" name="" (change)=getMajorLevel2List() [(ngModel)]='majorLevel1'>
                    <option value="">请选择学科门类</option>
                    <option *ngFor='let major1 of majorLevel1List' value={{major1.code}}>{{major1.text}}</option>
                </select>
                <select class="shortSlc downLoad_right" name="" [(ngModel)]='majorLevel2' (change)='addMajor($event)'>
                    <option value="" disabled>点击添加专业类别</option>
                    <option *ngFor='let major2 of majorLevel2List' value={{major2.code}}>{{major2.text}}</option>
                </select> {{majorList.length}}/10
                <br>
                <div *ngIf='majorLevel2ShowList.length>0' class="selecetCollege">
                    <span *ngFor='let major of majorLevel2ShowList;let i = index'>{{getparentcode_textAndmajor_text(major)}}
                        <span class="red" (click)=deleteMajor(i)> × </span>
                    </span>
                </div>
                <span><img src="../../assets/images/positionpush-education.png"> 学历</span>
                <select name="" class="downLoad_right" [(ngModel)]='education' (change)="getPositionPushInfo()">
                    <option value="">不限</option>
                    <option value="03">本科</option>
                    <option value="02">大专</option>
                    <!-- <option value="01">中专</option> -->
                    <!-- <option *ngFor='let edu of allEducation' value={{edu.code}} >{{edu.text}}</option> -->
                </select>
                <br>
                <span><img src="../../assets/images/positionpush-sex.png"> 性别</span>
                <select name="gender" class="downLoad_right" [(ngModel)]="checkedSex" (change)="getPositionPushInfo()">
                    <option value=''>不限</option>
                    <option value='01'>男</option>
                    <option value='02'>女</option>
                </select>
                <!--<br>-->
                <!--<span><img style="width: 16px;height: 16px" src="../../assets/images/sectors.png" > 届别</span>-->
                <!--<select name="gender" class="downLoad_right" [(ngModel)]="year" (change)="getPositionPushInfo()" >-->
                <!--<option  *ngFor="let city of jiebieList" [value]="city.code">{{city.text}}</option>-->
                <!--</select>-->
                <br>

                <span><img src="../../assets/images/positionpush-place.png"> 户口所在地</span>
                <select class="miniSlc downLoad_right" name="" [(ngModel)]="area1" (change)="changeAreaLevelList2()">
                    <option value="">请选择所在省份</option>
                    <option value={{level1.code}} *ngFor='let level1 of areaListLevel1'>{{level1.text}}</option>
                </select>
                <select class="miniSlc downLoad_right" name="" [(ngModel)]="area2" (change)='addArea($event)'>
                    <option value="" disabled>请选择所在市</option>
                    <option value={{level2.code}} *ngFor='let level2 of areaShowListLevel2'>{{level2.text}}</option>
                </select> {{areaList.length}}/5
                <!-- <select class="miniSlc" name="" [(ngModel)]="area3" (change)='addArea($event)' >
          <option value="">请选择所在县/区</option>
          <option value={{level3.code}} *ngFor='let level3 of areaShowListLevel3'>{{level3.text}}</option>
        </select> -->
                <br>
                <div *ngIf='areaShowList.length>0' class="selecetCollege">
                    <span *ngFor='let area of areaShowList;let i = index'>{{area.text}}
                        <span class="red" (click)=deleteArea(i)> × </span>
                    </span>
                </div>
            </div>

            <div class="sendContent">
                <span>符合以上条件的学生数：</span>
                <span class='stucount'>{{stuMatchCount}}人 </span>
                <span class="remark"> 如需邀请更多学生，请适当放宽筛选条件</span>
                <br>
                <span>确定你要通知的学生数：</span>
                <button type="button" (click)='minus()'>-</button>
                <input type="number" (change)=checkNum() id="stucount" [(ngModel)]='stucount' min="0" />
                <button type="button" (click)='add()'>+</button>
                <br>
                <textarea id="sendMsg" [ngModel]='sendMsg' readonly="readonly">

      </textarea>
            </div>
            <div class="sendBox">
                <div id="">
                    <span>
                        需消耗资源数<span class="red">{{stucount}}</span>，(现剩余资源<span class="red">{{totalCoin}}</span>)
                    </span>
                </div>
                <div>
                    <button id="sendBtn" class="bordeRadius blueStreamer" (click)="inviteStuAlert()">
                        立即推送
                    </button>
                </div>
                <div>
                    <!--发送时，系统将为您屏蔽“已投递及14天内已推送的学生”。发送任务执行完毕，系统会在三个工作日内将相应资源数原路退还。-->
                    发送时，系统将为您屏蔽“已投递及14天内已推送或其他特殊情况无法推送的学生”。发送任务执行完毕，系统将会在三个工作日内将未推送成功的相应资源数原路退还。
                </div>
            </div>
        </div>


    </div>
    <!-- </div> -->
</div>

<p-dialog id="bigPackage" [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':580,'height':351,'minHeight':351}" [modal]="true" [showHeader]="false"
    [(visible)]="isShowPushModel">
    <div class="big-package">
        <div class="text1">可用{{totalCoin ? totalCoin: '0'}}次</div>
        <div class="text2">{{stucount}}次</div>
        <div (click)="inviteStu()" class="fre-btn"></div>
        <div class="bottomChips">1-2日后，您可在【简历管理】-【职位推送记录】中查看推送记录。</div>
    </div>
</p-dialog>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>