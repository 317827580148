import { Component, OnInit } from '@angular/core';
import {Config} from "../../provider";

@Component({
  selector: 'app-gu-wen-card-view',
  templateUrl: './gu-wen-card-view.component.html',
  styleUrls: ['./gu-wen-card-view.component.css']
})
export class GuWenCardViewComponent implements OnInit {

  public config: Config;

  constructor(

  ) {
    this.config = Config.getInstance();//实例化config
    this.config.isNavActive = 'login'

    console.log('config.departmentSelf',this.config.departmentSelf)
  }

  ngOnInit() {
  }

}
