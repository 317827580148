<!--//工作啦用户协议及隐私政策-->

<!-- 用户确认操作弹出框 -->

<div class="modal-dialog">

    <div class="modal-content">

        <div class="titleRow flex_s">

            <span>工作啦用户协议及隐私政策</span>

            <img (click)="close(1)" src="/assets/images/rights/contactMeClos.png" />

        </div>

        <div class="contentRow" [ngClass]="{contentRow2:type=='none'}">

            <p class="title">「工作啦」用户服务协议及隐私说明（2022年1月更新）</p>

            <p class="paragraph strong">尊敬的用户：</p>

            <p class="paragraph">

                您好！欢迎您注册并使用「工作啦」，「工作啦」是河南英才归来科技有限公司旗下运营的提供互联网招聘服务的专业平台。《「工作啦」用户服务协议》系河南英才归来科技有限公司与平台用户之间达成的，用以明确双方权利义务关系的规范性文件，以便用户能够更加全面地了解并使用平台提供的专业服务。

            </p>

            <p class="paragraph underline">

                在注册「工作啦」之前，用户应当认真仔细地阅读本协议的全部内容，尤其是涉及可能属于限制或免除有关责任的条款内容，本公司就此类条款采取黑体加粗和下划线的方式予以醒目标注，以便能够引起用户的特别注意。当用户按照页面提示填写注册信息，选择点击“阅读并同意接受用户协议”后，即成为「工作啦」的注册用户。同时也表明了用户已经仔细阅读过本协议的全部内容，理解并接受《「工作啦」用户服务协议》、《个人信息保护说明》、《「工作啦」信息发布规则》（附后）及其他「工作啦」制定的规范文件等。

            </p>

            <p class="paragraph underline">

                不同意本协议的用户，请勿注册使用「工作啦」提供的任何服务。对协议内容（尤其涉及限免责任条款）有任何疑惑或问题的用户，可与「工作啦」平台的工作人员联系，平台将会对用户提出的问题做出详细说明和解释。

            </p>

            <p class="paragraph underline">

                公司有权对该协议进行不定期修改。不同意修改后新协议的用户，有权停止使用「工作啦」平台服务。用户继续登录使用或以其他方式使用「工作啦」服务的行为，视为其已经仔细阅读过本协议的全部内容，理解并接受修改后的新协议。</p>

            <p class="paragraph">

                「工作啦」通过国际互联网为用户提供网络服务，包括在线及离线相关业务。为使用网络服务，用户应自行配备进入国际互联网所必需的设备设施，包括计算机、数据机或其它存取装置，并自行支付登陆国际互联网所需要的费用。</p>

            <p class="paragraph underline">

                「工作啦」会在提供网络服务时，会对部分网络服务收取一定费用。针对此问题，「工作啦」将会在相关页面作出明确提示。若用户拒绝支付该费用，将不能使用相关网络服务。付费业务将在本协议基础上另行制定服务条款。用户应当认真阅读，用户购买付费业务的行为视为接受有关付费业务的服务条款。「工作啦」的付费服务及免费服务均有使用期限。期限结束后服务终止。使用期限不可中止、中断或延长。

            </p>

            <p class="paragraph empty"></p>

            <p class="title1">第一部分 用户服务协议</p>

            <p class="paragraph strong">一、协议主体和范围</p>

            <p class="paragraph strong">1、协议主体</p>

            <p class="paragraph">（1）河南英才归来科技有限公司属于本协议的合法有效主体，其公司旗下运营的「工作啦」招聘服务平台负责具体向用户提供招聘服务。</p>

            <p class="paragraph">（2）求职者以及招聘者在成功注册「工作啦」账号后，将成为本协议的合法有效主体，属于本协议所称的“用户”。不注册仅作为访客使用本平台部分资讯信息的，亦应当遵守本协议。</p>

            <p class="paragraph strong">2、协议范围</p>

            <p class="paragraph">（1）本协议适用于河南英才归来科技有限公司在本协议项下提供的其所属的已有或将有的各种移动客户端、平台和媒介，如「工作啦」网页端、移动客户端、微博、微信公众号、微信小程序等。</p>

            <p class="paragraph underline">

                （2）因用户账号关联其个人信息以及平台商业信息，故仅限用户本人使用其注册账号。未经「工作啦」事先书面同意，用户不得将其注册账号擅自出租、出借、出售、转让给第三方，不得直接或间接授权第三方使用或获取其注册账号内信息。</p>

            <p class="paragraph underline">若用户选择使用「工作啦」提供的其他服务，本公司制定的其他相关协议可能构成本协议的补充协议，补充协议属于本协议的一部分，具有同等法律效力。用户可在相关服务页面查阅其协议内容。

            </p>

            <p class="paragraph strong">二、账号注册与使用 </p>

            <p class="paragraph strong">1、注册资料及资格</p>

            <p class="paragraph">

                用户注册「工作啦」时，应当提供合法、真实、有效的注册资料。资料变动的，应当及时更新。自然人应当提供本人合法、真实、有效的最新个人信息，保证年满18周岁，属于完全民事行为能力人。法人或非法人组织应当提供加盖公章的营业执照复印件、事业单位法人登记证书、民办非企业单位证书、组织机构代码证等能够证明其属于合法主体资格的证明材料以及招聘负责人的身份证信息等。

            </p>

            <p class="paragraph strong">2、账号使用</p>

            <p class="paragraph underline">

                如有证据证明或本公司有合理理由相信用户存在不当注册或使用「工作啦」账号的情形，平台将对其账号采取相应限制措施，暂时或永久限制或禁止该用户登陆或使用账号或平台服务。造成本公司或第三方损失的，用户承担赔偿责任。</p>

            <p class="paragraph strong">3、账号安全</p>

            <p class="paragraph">用户应当选择保密性强且安全级别高的密码并妥善管理保存。<span class="underline">用户应当对其在账号内的行为负责，如通过平台上传或下载各类信息或资料、购买商品或服务、浏览信息、披露信息等。</span></p>

            <p class="paragraph underline">

                请勿轻信任何索要密码、验证码或财产的信息，切勿将上述信息提供给任意第三方，不从事任何可能使用户名和密码存在泄露风险的行为。建议用户尽量不要在第三方应用中使用与「工作啦」平台账号相同的密码。用户请求平台提供帮助的，平台会在合理合法范围内尽全力协助用户依法维权。

            </p>

            <p class="paragraph strong">三、平台服务</p>

            <p class="paragraph strong">1、招聘服务</p>

            <p class="paragraph">用户不得违反我国法律规定及公司制定并公布的各类规范用户行为的规则文件，不得实施任何影响「工作啦」提供服务的行为，不得损害国家利益、社会公共利益以及他人合法权益。具体如下：</p>

            <p class="paragraph">（1）在平台上发布虚假、错误、不准确或不完整的本人简历资料及其他信息，发布他人的简历资料，为他人寻找工作机会等；</p>

            <p class="paragraph">（2）在平台上发布骚扰性、中伤性、辱骂性、恐吓性、伤害性、淫秽或其他违背公序良俗的信息；</p>

            <p class="paragraph">（3）在平台上发布不符合「工作啦」服务性质和服务目的的各类信息，如鸡汤、段子、水贴等；</p>

            <p class="paragraph">（4）在平台上发布与其职位名称及描述不相符合的信息；</p>

            <p class="paragraph">（5）在平台上发布虚假信息，包括不真实的公司信息、薪资、职称、个人简历、职位信息等；</p>

            <p class="paragraph">（6）未经本公司许可，非法使用插件、外挂或其他手段私自接入本平台及相关服务系统的；</p>

            <p class="paragraph">（7）仿冒、伪造、混淆他人账号昵称、头像及功能介绍发布招聘信息，或以他人名义实施招聘活动；</p>

            <p class="paragraph">（8）为他人或任何第三方提供自动登录或接入到「工作啦」平台，代办或协助代办平台的身份认证服务，或非法提供或售卖平台身份认证所需相关材料和凭据等；</p>

            <p class="paragraph">（9）利用「工作啦」可能存在的漏洞恶意充值啦豆、非法获取校招工具以及其他平台虚拟产品或服务；</p>

            <p class="paragraph">（10）在第三方平台（如淘宝、微信、闲鱼等）售卖啦豆、校招工具等虚拟产品或服务；</p>

            <p class="paragraph">（11）通过第三方平台或渠道购买平台啦豆、校招工具等虚拟产品或服务（本公司指定的合法授权平台或渠道除外）；</p>

            <p class="paragraph">（12）发布侵犯第三方商业秘密或知识产权的信息，或实施侵犯他人商业机密或知识产权的行为；</p>

            <p class="paragraph">（13）未经平台同意，将从平台获取的服务信息用于除自身招聘外的其他目的，或以各种手段将信息泄露给第三方；</p>

            <p class="paragraph">（14）扣押应聘者居民身份证或其他证件、以担保或其他名义收取应聘者财物，或采取各种手段牟取不正当利益等；</p>

            <p class="paragraph">（15）在平台上以招聘的名义进行招生或发布非简历信息或以简历形式变相发布信息，如广告、营销信息、销售信息、传销、直销、通知或意见等；</p>

            <p class="paragraph">（16）擅自打印、复制、转发或以其他方式使用应聘者的个人信息或招聘者的商业信息；</p>

            <p class="paragraph">（17）擅自删除或修改他人或公司的信息；</p>

            <p class="paragraph">（18）擅自向已公布信息的个人或公司发送电子邮件、打电话、寄信或以其他方式接触；</p>

            <p class="paragraph">（19）利用「工作啦」从事非法活动或将「工作啦」用于非法目的；</p>

            <p class="paragraph">（20）以非法方式使用「工作啦」平台的服务；</p>

            <p class="paragraph">（21）发布或实施任何侵犯国家利益、社会公共利益及他人或第三方合法权益的；</p>

            <p class="paragraph">（22）其他违反我国法律规定或本公司发布的用以规范用户行为的规范文件的行为；</p>

            <p class="paragraph underline">

                针对上述行为，「工作啦」有权对该用户账号采取相应措施，如加入黑名单、将其投诉事实公之于众、暂时或永久封禁其账号及服务、修改或删除账号或其他信息等。造成平台损失的（包括经济或名誉损失），由用户承担赔偿责任。</p>

            <p class="paragraph strong">2、信息推送</p>

            <p class="paragraph">为向用户提供更加全面便利的服务，平台会通过系统消息、弹窗、电子邮件或页面公告、短信等方式向用户推送信息。</p>

            <p class="paragraph underline">

                平台保证按照相关法律法规的规定履行合理的审慎审核义务，但限于当时的互联网技术及人力的局限性，平台无法保证推送的信息能够准确可靠、正确、完整、有效或不存在误导，用户接收信息时应当辨别真伪并谨慎选择。</p>

            <p class="paragraph">「工作啦」还会将用户信息扩大至关联公司旗下运营的「天基人才网」，因此用户可能还会收到「天基人才网」以及其注册用户向其推送的信息。</p>

            <p class="paragraph strong">3、安全义务</p>

            <p class="paragraph">

                「工作啦」将采取一定的技术必要措施，保障平台的网络运行稳定以及网络安全，防范网络违法犯罪活动的发生。同时，用户也应当遵守我国相关法律法规的规定，遵守「工作啦」及本公司的相关规则规范文件，不得从事违法犯罪活动，不得实施影响平台网络稳定运行及网络安全的行为，包括：

            </p>

            <p class="paragraph">

                （1）违反网络安全相关规定，从事危害计算机信息网络安全的活动。如实施破坏或意图破坏「工作啦」的网络安全保护措施，对平台网络服务及相关软、硬件设施设备进行破解、破坏、删除、修改或增加；对计算机信息网络中存储或传输的数据和应用程序进行删除、修改或增加的，包括通过超载手段、邮件炸弹或摧毁等手段，干涉对用户及网络的服务；非法窃取他人口令、非法入侵他人计算机信息系统，包括接触未经许可的数据或进入未经许可的服务器、账号、邮箱或许可第三方接入未经「工作啦」同意的由「工作啦」发布、输送、传播的数据、简历或其他信息等，（包括使用蜘蛛程序、爬虫程序、拟人程序等非真实用户或避开、破坏技术措施等非正常浏览的手段、方式、读取、复制、转存、获取数据和信息内容的手段)；故意制作、传播计算机病毒等破坏性程序的；其他危害计算机信息网络安全的活动；

            </p>

            <p class="paragraph">

                （2）使用平台服务危害国家安全、破坏政治稳定、泄露国家秘密，侵犯国家、社会、集体利益和他人合法权益、从事违法犯罪活动；设立用于实施诈骗、传授犯罪方法、制作或者销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组；</p>

            <p class="paragraph">

                （3）发布禁止性信息：违反国家法律法规禁止性规定的；政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或教唆犯罪的；煽动民族仇恨、民族歧视，破坏民族团结的；欺诈、虚假、不准确或存在误导性的；侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；违背当地风俗习惯的；其他违背社会公共利益或违背社会主义核心价值观的或依据「工作啦」相关规定不适合在本平台发布的；

            </p>

            <p class="paragraph">（4）从事其他违反我国法律规定或其他平台安全规范规则的行为；</p>

            <p class="paragraph strong">四、知识产权</p>

            <p class="paragraph">

                「工作啦」在平台中提供的内容、图表、版式设计等所呈现出的全部或部分可单独作为作品保护的内容(包括网页、文字、图片、图像、照片、地图、图标、音频、视频、图表、色彩、版面设计、电子文档、数据资料等)和经本公司创作、整理或以其他方式(如人工智能)生成内容的所有知识产权(包括版权、商标权、专利权、商业秘密等)及相关权益，以及开发、运营和维护本平台运营的各种源代码、执行程序、系统和数据均受到中国知识产权法律法规保护。除非另有声明，以上信息和内容的知识产权归本公司所有。不会因用户的使用行为而发生权利转移。未经平台事先书面同意，任何人不得擅自使用(包括复制、传播、展示、镜像、上传、下载、转载、使用、修改、出租、链接、汇编、发表、抓取、通过任何机器人、爬虫、蜘蛛等自动程序、脚本、软件或设备进行操作等)，不得修改、改编、翻译本公司及「工作啦」所使用的软件、技术、材料等，不得创作与本公司及「工作啦」平台相关的作品或产品，不得利用本公司及「工作啦」平台的产品或服务向第三方提供产品或服务，不得通过反向工程、反编译、反汇编或其他类似行为获得「工作啦」平台的源代码。

            </p>

            <p class="paragraph underline">

                当发现用户存在上述侵权行为时，本公司将采取相应措施并追究其法律责任。非经事先书面许可，「工作啦」的内容和信息不得在本平台页面范围外展示或用作其他非商业用途。经书面同意许可后，用户可以在许可范围内依法合理使用或复制转载，但同时应当注明信息资料的来源及权利归属。

            </p>

            <p class="paragraph">

                用户应当保证其在「工作啦」上传、提交、存储或发布的信息(如文字、图片、视听资料等)均已经过合法授权或属于正当权利人。因违反此约定产生的任何侵权责任（包括知识产权侵权）均由用户自行承担。因此造成本公司及平台损失的，用户应当向本公司承担赔偿责任。

            </p>

            <p class="paragraph underline">

                用户在「工作啦」上传或张贴的信息（如照片、文字、企业Logo、面试经历及心得评价等信息），视为用户授权本公司及关联公司可以免费、非独家的使用。本平台有权基于展示、传播及推广的目的，对该信息合理使用、复制、修改或出版等。该使用权的有效期自本公司收到用户书面正式通知后截止。用户同意并授权平台在其宣传资料中免费使用其公司Logo及其他相关品牌信息。因上传或张贴的用户内容导致第三方向本公司提出侵权或索赔的，由用户承担全部责任。第三方对用户在平台上公开使用区域上传张贴的内容进行复制、修改、编辑、传播所导致的任何后果和责任，由用户向行为人主张责任。平台对此不承担任何责任，可以在法律范围内提供协助。

            </p>

            <p class="paragraph strong">五、不可抗力及其他免责事由</p>

            <p class="paragraph strong">1、不可抗力</p>

            <p class="paragraph underline">

                不可抗力是指不能预见、不能避免且无法克服的客观情况。包括政府行为、自然灾害、战争、恐怖事件、罢工、黑客攻击、传染病疫情、计算机病毒侵入或发作、计算机系统遭到破坏、网络设备维护、网络连接故障、电脑通讯或其他系统故障、电力故障、法律政策变动、政府命令、政府管制、第三方原因导致迟延或不能服务，信息及数据延误、停滞或错误等。出现不可抗力情形时，平台将尽全力恢复或修复，但用户产生的有关损失，本公司在法律范围内免责。

            </p>

            <p class="paragraph strong">2、其他免责事由</p>

            <p class="paragraph">（1）用户对其在平台上发布的个人简历、材料、文件及信息的真实性、准确性、完整性和合法性负全部责任；</p>

            <p class="paragraph">（2）平台不对用户的线下行为负责，不对用户因招聘、面试、签约等产生的任何问题负责；</p>

            <p class="paragraph">（3）平台不对用户的个人简历及信息是否被特定招聘主体浏览以及是否达到一定浏览数量作出保证或承诺；</p>

            <p class="paragraph">

                （4）为向用户提供便利，招聘者用户或者求职者用户发布的信息中可能包含外部链接，由于外部链接所指向的网页内容并非由「工作啦」实际控制，故平台不对其内容的合法性、准确性、安全性及完整性作出任何保证或承诺。也不代表平台对其链接内容认可，用户在点击外部链接前应先查阅链接提供方的相关说明及提示信息，理性谨慎地自主决定是否点击进入或使用。因用户不慎点击或使用上述链接跳转至其他网站或因使用其他网络运营商提供的服务造成用户个人信息泄露或产生其他损失及法律后果的，平台不对此承担任何责任，由用户自己负责；

            </p>

            <p class="paragraph">

                （5）「工作啦」将会不断提升自身服务质量和服务水平，为用户提供更加优质的服务。非经「工作啦」专门作出书面声明，「工作啦」不会对平台上的任何信息、资料内容、产品或服务作出任何形式的声明、担保或承诺，如品质、稳定、正确、及时、完整、连贯、安全等方面；

            </p>

            <p class="paragraph">

                （6）在任何情况下，平台不对任何间接性的、惩罚性的、突发性的任何损害(如利润或利息损失、经营中断、资料灭失损失等)承担责任，无论其因何原因或以何形式发生，平台仅在法律允许范围内承担不超过用户向平台已支付费用的总额内的责任；</p>

            <p class="paragraph">（7）为完善产品功能、改善用户体验、保障服务安全，「工作啦」会不定期对自身产品或服务进行优化升级更新。用户应当配合平台的更新升级活动，否则将无法正常使用；</p>

            <p class="paragraph">（8）由于技术局限性，平台不保证网页端或移动客户端等软件与其他软、硬件或系统完全兼容。出现上述情况时，用户可向平台工作人员寻求帮助，获得技术支持。若仍无法解决，用户可选择卸载停止使用平台服务；

            </p>

            <p class="paragraph">

                （9）「工作啦」会按照法律规定的要求尽到合理审慎审核义务，但限于互联网技术及人力局限性，平台不保证其服务及用户上传发布的全部信息均完全准确、完整、可靠、有效或不存在误导，除法律另有规定或双方另有约定外，用户应当仔细甄别判断接触到的各类信息资料，并对其行为产生的潜在风险自行负责；

            </p>

            <p class="paragraph">（10）「工作啦」不对其负责组织、策划或发起的任何商业或非商业活动的最终效果向用户作出保证或承诺。用户在活动期间的行为由用户自己负责；</p>

            <p class="paragraph">（11）针对用户的投诉，「工作啦」会在全面认真核查后做出相应处理，但平台不保证一定能满足投诉者的要求。平台有权决定是否向被投诉者公开投诉内容；</p>

            <p class="paragraph">（12）平台基于现有技术条件和设施，在自身能力范围内向用户提供服务。为确保服务连贯性和安全性，基于整体运营的需要，在必要情形下，平台有权依法修改、中断、中止或终止其服务；</p>

            <p class="paragraph">

                （13）针对用户违反或涉嫌违反我国法律规定或本协议约定的行为，「工作啦」有权采取警告、限期改正、删除、屏蔽或断开链接、限制、暂停、关闭或封禁其账号或服务、禁止其重新注册等措施。有权对用户涉嫌违法犯罪的有关资料信息作为证据予以封存，并依法向相关政府部门报告，用户应当对其行为产生的后果自行承担法律责任。因此导致第三方投诉或索赔的,用户自行承担相应法律责任。本公司及关联公司、公司管理人员及员工因此受到第三方追责或索赔，或被国家机关处罚的，由用户赔偿本公司全部损失。

            </p>

            <p class="paragraph strong">六、协议修改与终止</p>

            <p class="paragraph strong">1、协议修改</p>

            <p class="paragraph underline">

                平台有权基于我国政策以及法律法规的变化、相关监管要求、平台自身经营管理需要等情况，不定期修改本协议以及其他已公布的本公司用户规则。用户应当定期查阅本协议的具体内容，若有重大变更，本平台将通过有效方式发布或发送相关条款的更改信息。不同意修改后协议的用户，可停止使用本平台服务，联系平台工作人员注销账号。在更新协议后，用户继续使用本平台的，视为已同意新修改的协议内容。

            </p>

            <p class="paragraph strong">2、协议终止</p>

            <p class="paragraph">出现下列情形之一，本用户协议终止：</p>

            <p class="paragraph">（1）用户依据协议约定，自行停止或注销账号的；</p>

            <p class="paragraph">（2）平台依据法律或协议约定，停止或注销用户账号的；</p>

            <p class="paragraph underline">

                协议终止后，平台将不再提供任何服务或履行任何义务，同时平台将按照法律规定，自行妥善处理账号内全部信息资料。用户主动注销账号的，平台将在核验身份并比对成功后，及时按照用户意愿和要求注销账号。账号注销后无法恢复，届时用户将自动放弃平台内账号下的全部权益，平台不负有帮助用户找回账号内信息的义务和责任，建议用户注销账号前应当谨慎考虑并提前备份好账号内的相关重要信息和数据资料。

            </p>

            <p class="paragraph strong">七、通知</p>

            <p class="paragraph underline">

                为确保招聘安全，用户在注册「工作啦」账号接受平台服务时，应当向「工作啦」提供真实、准确且有效的联系方式，并保证实时更新，以便平台能够核实并确认其真实身份，能够与用户畅通联系并对其作出及时有效的通知。用户违反此约定产生的任何法律责任，均由其自行承担。

            </p>

            <p class="paragraph strong">八、管辖及其他</p>

            <p class="paragraph">双方在协议履行的过程中产生的任何争议，均由双方协商解决。协商不成的，任何一方均可向本公司所在地的人民法院提起诉讼。</p>

            <p class="paragraph empty"></p>

            <p class="paragraph empty"></p>

            <p class="paragraph empty"></p>

            <p class="paragraph title1">第二部分《个人信息保护说明》</p>

            <p class="paragraph empty"></p>

            <p class="paragraph">目录</p>

            <p class="paragraph">一、如何收集和使用用户个人信息</p>

            <p class="paragraph">二、如何使用Cookie和其他同类追踪技术</p>

            <p class="paragraph">三、如何共享、转让、公开披露用户个人信息</p>

            <p class="paragraph">四、用户如何管理个人信息</p>

            <p class="paragraph">五、如何保护您的个人信息</p>

            <p class="paragraph">六、如何保存您的个人信息</p>

            <p class="paragraph">七、如何修改或变更本说明</p>

            <p class="paragraph">八、如何联系我们</p>

            <p class="paragraph">九、如何解决争议</p>

            <p class="paragraph title1">前言</p>

            <p class="paragraph">为了保护用户的个人信息权益，规范平台对用户的个人信息处理活动，促进平台对用户个人信息的合理利用，公司依据我国法律法规及相关政策规定制定《个人信息保护说明》（以下简称“说明”）。</p>

            <p class="paragraph">本说明所称“<span class="strong">个人信息</span>”限于以电子或者其他方式记录的与已识别或者可识别的自然人用户有关的各种信息，不包括经过匿名化处理后的信息。</p>

            <p class="paragraph">个人信息的“<span class="strong">处理</span>”是指对个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。</p>

            <p class="paragraph">

                「工作啦」将本着合法、正当、必要和诚信原则，基于更好的为用户提供全面、优质的服务以及保障平台网络安全的目的处理用户个人信息。同时，本说明也将对用户个人信息的处理规则，目的、方式及范围予以明示。<span class="strong">因此，在使用「工作啦」之前，用户应当仔细阅读并正确理解说明的全部内容，选择是否同意本说明内容以及决定是否使用平台提供的产品或服务。当用户选择“同意”时，代表其已阅读并接受本说明的全部内容。在本《个人信息保护说明》更新后，用户继续使用「工作啦」服务的，视为已同意并接受更新后的《个人信息保护说明》。平台会对本说明中的重要内容采取“加粗”方式予以特别提示，以便引起用户足够注意。</span>

            </p>

            <p class="paragraph empty"></p>

            <p class="paragraph strong">一、如何收集和使用用户个人信息</p>

            <p class="paragraph">

                在不违反我国法律规定的前提下，平台仅会在本说明的信息处理目的范围内，收集、使用用户的个人信息，平台不会收集与平台服务无关的用户个人信息，不会因用户不同意收集其非必要个人信息，而拒绝用户使用本平台的基本功能服务。如果「工作啦」将用户的个人信息用于本说明的目的范围外或本说明未载明的其他用途，或基于其他特定目的收集、使用用户的个人信息，平台会以合理方式提前告知用户，并在收集、使用前再次征求用户的单独同意。<span class="strong">注意，当用户未登录使用「工作啦」时，平台不会向用户提供任何个性化推荐服务。</span>

            </p>

            <p class="paragraph strong">1、注册账号</p>

            <p class="paragraph">

                当用户注册「工作啦」平台账号时，需要向平台提供其本人的手机号码，平台会向用户提供的手机号码发送短信验证码，以验证其注册的手机号码有效。根据我国法律法规以及出于安全保障目的，若用户不提供手机号码进行核验，将会导致注册失败，进而无法使用平台的相关功能。

            </p>

            <p class="paragraph">用户忘记登录账号时，在找回其账号时，需向「工作啦」平台客服提供其注册时所用的企业名称，平台客服会提供用户账号找回的方法，帮助用户重新登录使用平台服务。</p>

            <p class="paragraph">

                为促进就业，平台可能会与政府部门或大型产业园区等合作，开展联合招聘活动，此时招聘者用户需要填写其招聘企业名称、企业所在城市和招聘者本人手机号码及真实姓名，并注册「工作啦」平台账号。注意，当平台开展联合活动时，可能会应合作方的要求，向其提供用户的个人信息。

            </p>

            <p class="paragraph strong">2、认证账号</p>

            <p class="paragraph">

                基于我国相关法律规定，用户需要配合平台进行实名制认证。用户在注册账户时，应当向平台提供真实个人信息，以便平台能够核验其真实身份。其中，招聘者用户还应当结合自身单位性质的不同向平台提交相关营业资质证件、统一社会信用代码证等资料，其工作人员应当提交真实有效的身份信息，如手持身份证等证明资料，用以证明其身份合法，能够代表其所属单位进行相关招聘活动。待平台审核通过后，用户方可从事相关招聘活动。

            </p>

            <p class="paragraph empty"></p>

            <p class="paragraph empty"></p>

            <p class="paragraph strong">3、发布职位</p>

            <p class="paragraph">

                招聘者用户可通过平台“弹窗“选择是否同意平台收集其地理位置信息（如所在地区、城市、住所地址等）。若用户需要使用平台的职位信息发布功能以及查看、搜索基于地理位置信息的职位信息，用户需要选择同意平台收集其地理位置信息。平台只会将该信息用于向用户提供岗位地址选择、帐号安全检测及推荐可能感兴趣的求职者用户或职位等用途。拒绝提供地理位置信息不会影响用户正常使用平台的其他功能。

            </p>

            <p class="paragraph strong">4、创建简历</p>

            <p class="paragraph">求职者用户成功注册账号后，可根据个人实际情况，在线填写个人简历，所填内容具体如下：</p>

            <p class="paragraph">（1）基本信息：头像、姓名、性别、目前工作状态、出生日期、工作时间年限、户口所在地、目前居住城市、政治面貌、联系方式；</p>

            <p class="paragraph">（2）求职意向：期望职位、期望行业、期望工作城市、期望薪资待遇；</p>

            <p class="paragraph">（3）教育经历：学历、学校、所学专业、教育经历；</p>

            <p class="paragraph">（4）工作经历：公司名称、职位名称、职位类别、技能标签、工作年限、薪资待遇水平、工作描述、业绩描述、选择是否对该公司隐藏个人信息；</p>

            <p class="paragraph">（5）语言能力：语种、熟练程度；</p>

            <p class="paragraph">（6）专业技能：技能名称、技能等级；</p>

            <p class="paragraph">（7）证书获得：证书名称、证书编号、证书等级、证书获得时间等；</p>

            <p class="paragraph">（8）附件信息，如上传的各类文件资料等；</p>

            <p class="paragraph">（9）自我评价信息；</p>

            <p class="paragraph">（10）意向单位要求的其他信息；</p>

            <p class="paragraph strong">当应聘者向招聘者投递其个人简历或将个人简历在平台上公开时，或招聘者对用户的个人简历查询、浏览或下载时，「工作啦」将收集用户的个人简历信息。</p>

            <p class="paragraph strong">5、账号安全</p>

            <p class="paragraph">

                为保障「工作啦」平台及其服务安全，保障用户的人身财产安全，使平台能够做到准确识别并预防各类违法犯罪活动及违反平台规则的行为的发生，平台将会收集并使用用户的账号信息、设备信息、软件信息以及从平台的关联公司和合作伙伴获取的有关用户的合法授权信息等（详细内容如下），以便平台能够判断用户的账号风险等级、验证用户的真实身份、检测平台的潜在风险等。针对各类风险，平台将根据实际情况采取必要处置措施，上述所涉信息具体内容如下：

            </p>

            <p class="paragraph">（1）设备信息，如各类设备标识符、MAC地址、硬件序列号、SIM卡、生成ID等；「工作啦」无法禁止其接入的第三方SDK获取用户的设备标识符以及设备位置等；</p>

            <p class="paragraph">（2）系统信息，如设备品牌、设备型号、屏幕尺寸、手机样式、手机名、操作系统等；</p>

            <p class="paragraph">（3）应用信息，如用户已安装应用、应用来源、应用崩溃情况、性能数据等；</p>

            <p class="paragraph">（4）位置信息，如经用户授权的GPS位置信息。</p>

            <p class="paragraph">（5）网络信息，如用户设备属性和状态信息(包括IP地址、运营商信息)、蓝牙、Wi-Fi信息及网络状态等；</p>

            <p class="paragraph">在用户账号使用存在异常时，平台会收集并使用用户的真实姓名、身份证号，人脸信息，以验证用户的真实身份。</p>

            <p class="paragraph strong">6、浏览招聘信息及个人简历</p>

            <p class="paragraph">

                为用户能够快速找到其所需职位或求职者相关信息，平台会收集用户使用平台服务期间的设施设备信息，如设备名称、设备型号、设备识别码、操作系统和应用程序版本、分辨率、服务提供商网络ID（PLMN）等，以便向用户提供最佳的信息展示。用户也可通过平台的搜索功能筛选其所需信息。同时，平台还会保留用户的相关搜索信息，以便能够使用户重复输入或为用户展示与其搜索相关的招聘信息。

            </p>

            <p class="paragraph">

                用户可在平台的隐私设置中将其个人简历隐藏。隐藏后，招聘者用户将无法浏览该用户已隐藏的个人简历信息。注意，隐藏状态下，若用户主动查看其职位岗位时，则该职位岗位所属招聘者用户仍可看到该名用户的个人简历信息。</p>

            <p class="paragraph strong">7、账号推送</p>

            <p class="paragraph">

                「工作啦」在提供服务过程中，会根据用户所填信息以及平台的相关推荐算法，向用户推荐合适的岗位信息。平台通过与用户的个人简历、行为记录(如用户浏览记录、收藏记录、投递、点击、招聘者查看权限、屏蔽企业、登录退出时间等)、位置信息与平台采集的用户设备ID或系统生成的用户ID进行关联，在经过数据分析后，会通过多种方式向用户推送信息。平台还会结合用户注册时提供的手机号码、电子邮箱等联系方式向用户推送用户可能会感兴趣的各类信息以及平台最新消息或通知等。

            </p>

            <p class="paragraph strong">8、增值服务</p>

            <p class="paragraph">

                用户可自行支付购买或通过第三方支付机构支付购买平台的增值产品或服务（如校招工具、啦豆等）。在此过程中，平台仅会收集与用户支付购买产品或服务相关的支付或订单信息（如支付明细、订单号、交易时间、交易金额等），用以留存平台交易记录和向用户出示用户交易凭证。平台不会将此类信息用作其他用途。

            </p>

            <p class="paragraph strong">9、线上沟通</p>

            <p class="paragraph">

                「工作啦」提供极速电话联系服务，以便招聘者用户能够通过拨打虚拟号码与求职者用户进行沟通交流。双方通过平台线上沟通功能进行交流时，需要授权平台获取双方的麦克风、存储、相机等设备权限，以便平台能够记录双方在此过程中发送或接收的各类文字、图片和音视频等信息。在双方发生纠纷时，平台会将所收集记录的信息作为证据为双方提供解决纠纷的帮助和便利。

            </p>

            <p class="paragraph strong">10、改进产品或服务</p>

            <p class="paragraph">

                「工作啦」会收集并使用用户IP地址、按钮点击频率和平台使用时间、平台页面使用频率和时间、某些关键配置选项值、崩溃日志、错误日志等涉及平台服务的各类信息，用于统计平台用户数量、分析平台使用状况、网页升级改版、浏览器兼容检测、排查崩溃原因、减少崩溃情况等用途。

            </p>

            <p class="paragraph strong">11、客服功能</p>

            <p class="paragraph">

                平台会通过用户留存在平台上的各类联系方式直接与用户进行沟通。当平台发现用户的账号存在异常时，可能会向用户发送警示通知或安全提醒等信息。在接受用户咨询或反馈时，平台工作人员还会使用用户的账号信息查验并核对用户的真实身份。</p>

            <p class="paragraph strong">12、其他</p>

            <p class="paragraph strong">（1）当用户同意并接受平台关联方或合作方提供的服务时，即表明用户同意平台可根据自身业务或合作需要，自关联方或合作方处收集并使用用户的个人信息。</p>

            <p class="paragraph">（2）为向用户提供个性、精准和便捷的服务，或改善提高平台自身服务水平和质量，平台会对用户的个人信息进行综合统计、分析加工。但在收集使用前，平台会对用户个人信息进行脱敏、去标识化及匿名化处理。

            </p>

            <p class="paragraph">（3）其他符合我国法律法规及政策规定的、无需经过用户同意的情形，平台可以收集并使用用户的个人信息。</p>

            <p class="paragraph strong">二、如何使用Cookie和其他同类追踪技术</p>

            <p class="paragraph">

                Cookie是一种数据存储手段，是用户在登录平台或浏览网络内容时，网站服务器存放在用户电脑、移动电话或其他终端设备内的小型文件，通常包含标识符、站点名称及一些号码及字符。当用户再次访问时，网站可通过Cookie识别用户浏览器的一项技术。Cookie会存储用户的一些偏好或其他信息。

            </p>

            <p class="paragraph">

                当用户使用「工作啦」时，平台会通过Cookies或同类技术收集用户的设备型号、操作系统、设备标识符、登录IP地址信息，以及缓存用户浏览信息、点击信息，以查看用户的网络环境，以及在用户再次访问时，通过Cookie技术识别用户身份。用户可通过更改其浏览器的相关设置，使其浏览器不接受本网站的Cookie，但此操作会影响用户使用本平台的部分功能。

            </p>

            <p class="paragraph strong">三、如何共享、转让、公开披露用户个人信息</p>

            <p class="paragraph strong">1、共享</p>

            <p class="paragraph">

                「工作啦」不与平台外的其他公司、组织或个人共享用户个人信息。下列情形，平台会在取得用户单独同意后，向其他个人信息处理者提供用户的个人信息，同时会向用户告知其信息接收方的名称或姓名、联系方式、处理目的、处理方式和个人信息的种类：

            </p>

            <p class="paragraph">

                （1）为提高用户的求职成功率，平台可能会与第三方合作，将用户个人信息共享至第三方合作平台，以吸引更多的招聘者与用户取得联系，如「工作啦」会将用户个人信息与本公司的关联公司运营的「天基人才网」或其他第三方合作平台共享，用户可能会接收到来自「天基人才网」的用户发出的信息。

            </p>

            <p class="paragraph">（2）「工作啦」的某些服务若需由第三方服务商提供时，在用户购买该产品或服务后，平台可能会在对其个人信息进行去标识化或匿名化处理后，在必要范围内，与第三方服务商共享。</p>

            <p class="paragraph">（3）用户为在校学生的，其所在学校有权在法律范围内获取其个人信息，平台可能会与用户所在学校共享其个人信息。</p>

            <p class="paragraph">（4）本公司组织的为用户推荐或提供工作机会的业务活动等。</p>

            <p class="paragraph">

                平台只会共享其提供服务所必须的用户个人信息。合作方为数据控制方的，则其应当以自己名义取得用户的单独同意以及处理用户个人信息。合作方可能存在其单独制定的隐私政策和用户服务协议等规则，建议用户阅读并遵守。<span class="strong">用户拒绝平台合作方在提供服务时收集的为其提供服务所必需的个人信息，将可能导致用户无法通过「工作啦」平台来接受合作方所提供的相关服务。</span>

            </p>

            <p class="paragraph strong">注意，本平台中可能包含第三方网站链接。对于在「工作啦」内的链接或涉及的第三方产品或服务，因非本平台所提供，故不受本说明约束。用户应当自行查看第三方的相关规定或政策。</p>

            <p class="paragraph">服务结束后，平台会将用户个人信息销毁，不会将其用于除自身招聘外的其他目的，或以其他方式泄露、授权或变相授权给第三方使用。</p>

            <p class="paragraph strong">2、转让</p>

            <p class="paragraph">

                原则上平台不会将用户个人信息向其他公司、组织或个人转让。因合并、分立、解散、被宣告破产等原因需要转移用户个人信息的，平台会向用户告知接收方名称或姓名以及联系方式。接收方将继续履行本平台的义务。接收方如果变更原先的处理目的、处理方式的，将会重新取得用户个人同意。

            </p>

            <p class="paragraph strong">3、公开披露</p>

            <p class="paragraph">

                非经用户单独同意，平台不会将用户的个人信息公开披露。平台会在合理范围内处理用户自行公开或其他合法公开的个人信息，但用户明确表示拒绝的除外。如果已公开的个人信息对用户个人权益有重大影响的，平台仍会征得用户的个人同意。</p>

            <p class="paragraph strong">四、用户如何管理个人信息</p>

            <p class="paragraph strong">1、访问或更正个人信息</p>

            <p class="paragraph">

                用户有权随时登录访问其账号，查看其个人信息。也可通过点击平台的「简历」、「我的」、「隐私设置」、「公司主页」及「账号管理」等平台功能区对其信息进行更正修改。<span class="strong">注意，已通过认证的招聘者用户无法对其姓名再次更正。</span></p>

            <p class="paragraph">当用户发现其个人信息不准确或不完整时，也可请求平台对其信息予以更正。平台会在验证查实用户的真实身份后，对其个人信息予以更正修改。</p>

            <p class="paragraph strong">2、删除个人信息</p>

            <p class="paragraph">下列情形，若平台未删除用户的个人信息时，个人用户有权请求平台予以删除：</p>

            <p class="paragraph">（一）处理目的已实现、无法实现或者为实现处理目的不再必要的；</p>

            <p class="paragraph">（二）平台停止提供产品或服务，或者个人信息保存期限已届满的；</p>

            <p class="paragraph">（三）用户撤回同意的；</p>

            <p class="paragraph">（四）平台违反法律、行政法规或违反约定处理用户个人信息的；</p>

            <p class="paragraph">（五）法律、行政法规规定的其他情形；</p>

            <p class="paragraph strong">3、撤回用户授权或同意</p>

            <p class="paragraph">（1）改变或撤回敏感个人信息权限。用户可在平台系统中关闭其地理位置、通讯录、摄像头、麦克风等权限。</p>

            <p class="paragraph">（2）拒绝平台推送或营销信息。用户可通过设置关闭“推送通知”，通过回复“TD”等退订我们的营销短信。</p>

            <p class="paragraph strong">

                平台的某些特定业务或服务需要用户的个人信息才可完成，当用户撤回同意或授权后，平台无法继续为用户提供其撤回同意或授权所对应的业务或服务，也不再处理用户相应个人信息。但用户撤回同意或授权的决定，不会影响此前基于用户同意或授权而处理的个人信息。

            </p>

            <p class="paragraph strong">4、注销用户账号</p>

            <p class="paragraph">除非法律法规另有规定或本说明另有约定，用户可通过平台的：“<span class="strong">公司主页</span>“>”<span class="strong">账号管理</span>“>”<span class="strong">注销账号</span>”进行操作。</p>

            <p class="paragraph">

                用户账号被注销后，除根据法律法规规定的平台需要留存的个人信息外，平台会删除用户个人信息或进行匿名化信息处理。账号注销后，平台将不再为用户提供相应产品或服务，不再收集用户的个人信息，建议用户谨慎考虑。</p>

            <p class="paragraph strong">5、回应用户请求</p>

            <p class="paragraph">为保障平台安全或用户个人信息安全，平台会在验证用户真实身份后，再处理用户的响应请求。因此，需要用户提出书面申请，并提供相关身份证明或授权证明资料。</p>

            <p class="paragraph">

                对于用户的合理请求，平台原则上不会收取任何费用。对于一定时期内多次重复、超出合理限度的请求，平台将根据具体情况收取一定成本费用。对于需要过多技术手段（如需要开发新系统或从根本上改变现行惯例）、或存在其他显著困难的请求，或可能对他人合法权益带来风险以及非常不切实际（如涉及备份磁带上存放的信息）的用户请求，平台可能会拒绝。

            </p>

            <p class="paragraph strong">五、如何保护您的个人信息</p>

            <p class="paragraph strong">1、信息安全技术措施</p>

            <p class="paragraph">（1）在信息安全传输方面，平台采用SSL加密传输技术，保护数据传输安全，防止数据遭到恶意攻击。对敏感信息采取加密算法，以保证安全。</p>

            <p class="paragraph">

                （2）在信息安全控制方面，实施数据权限控制机制，采取多重身份认证技术，对处理个人信息的行为进行监控，避免数据被违规访问和未授权使用。对数据生命周期监控，防止个人信息遭遇未授权访问、公开披露、使用、修改、人为或意外损坏或丢失等。

            </p>

            <p class="paragraph strong">2、信息安全组织和管理措施</p>

            <p class="paragraph">（1）平台指定信息保护负责人，负责对信息处理活动及采取的保护措施等监督。</p>

            <p class="paragraph">

                （2）建立信息安全使用制度，保障个人信息处理合法依规。对访问用户个人信息的工作人员，要求其必须经过信息保护负责人或授权人员的审批，同时记录其访问情况。平台也会采取必要技术措施，避免其他有关人员违法访问、修改、复制、下载用户个人信息。

            </p>

            <p class="paragraph">（3）组织员工参加安全与隐私保护培训，要求其完成规定考核评定，加强员工的个人信息保护意识和保护能力。</p>

            <p class="paragraph strong">3、第三方合作安全信息保护</p>

            <p class="paragraph">（1）平台从第三方收集个人信息前，会要求第三方对其用户的个人信息来源合法性提供相关书面证明材料。</p>

            <p class="paragraph">（2）平台与招聘者用户或其他合作伙伴共享个人信息前，均会要求其签署个人信息安全协议，并接受本平台的审核、监督和审计。</p>

            <p class="paragraph strong">4、安全事件处置</p>

            <p class="paragraph">

                当发生安全事件时，平台会启动应急预案，采取相应补救措施，记录事件内容，并依照《国家网络安全事件应急预案》等国家规定及时上报。当用户的个人权益可能遭受到严重损害时（如敏感个人信息泄露），平台会及时通过相关软件、邮件、短信或电话等方式提醒并通知用户，并将安全事件的基本情况和影响以及平台采取的相应措施一并告知用户。如遇无法逐一告知的情形时，平台会采取合理方式以公告形式告知用户。

            </p>

            <p class="paragraph">平台会不定期更新并发布安全风险、个人信息安全影响评估报告等，用户可通过平台相关公告获取相应文件。</p>

            <p class="paragraph">当发现个人信息泄露时，用户应当立即联系平台的工作人员，以便平台能够及时采取措施保护用户的个人信息安全。</p>

            <p class="paragraph strong">六、如何保存您的个人信息</p>

            <p class="paragraph strong">1、保存期限</p>

            <p class="paragraph">除法律、行政法规另有规定外，「工作啦」对个人信息的保存期限为实现个人信息处理目的所必要的最短时间，即用户使用「工作啦」的产品及服务期间。</p>

            <p class="paragraph">用户注销帐户或平台结束服务后以及用户主动删除个人信息时，平台将按照《中华人民共和国电子商务法》、《中华人民共和国网络安全法》等法律法规所规定的期限保存用户的个人信息。</p>

            <p class="paragraph strong">2、保存地域</p>

            <p class="paragraph">用户的个人信息均储存于中华人民共和国境内。若平台的部分产品或服务涉及跨境，需要向境外传输用户的个人信息时，平台会严格按照法律法规的规定执行，保证用户的个人信息安全。</p>

            <p class="paragraph strong">七、如何修改或变更本说明</p>

            <p class="paragraph">

                「工作啦」会根据实际情况的变化对说明的具体内容调整，进行更新或修改。并在平台上对变更或修改内容进行公示。非经用户同意，平台不会减损用户在本说明项下所享有的权利。当涉及下列重大、实质性的修改或变更时，平台会通过推送系统消息、电子邮件、短信或平台公告等方式通知用户：

            </p>

            <p class="paragraph">1、平台服务模式发生重大变化，如处理个人信息的目的、类型、以及个人信息的使用方式等；</p>

            <p class="paragraph">2、用户个人信息处理的相关权利内容及行使方式发生重大变化；</p>

            <p class="paragraph">3、平台所有权、股权结构及公司组织架构等发生重大变化，如业务调整、并购收购、破产重整等；</p>

            <p class="paragraph">4、个人信息共享、转让或公开披露的主要对象发生变化；</p>

            <p class="paragraph">5、平台负责个人信息安全的责任人员或部门、联络方式及投诉渠道发生变化；</p>

            <p class="paragraph">6、个人信息安全影响评估报告显示存在高风险的；</p>

            <p class="paragraph">

                用户应当仔细阅读变更后的说明内容。不同意本说明的修改、变更或对更新后说明持有异议的用户，可选择停止使用本平台服务或注销账号。若用户继续使用平台产品或服务，表示用户同意修改、变更后的《个人信息保护说明》。</p>

            <p class="paragraph strong">八、如何联系我们</p>

            <p class="paragraph">

                平台设置了专门的个人信息保护部门及人员，如果对本说明及平台有任何疑问、意见或建议，请通过service@gowork.la与我们取得联系。一般情况下，平台将会在15个工作日内对用户的请求予以答复。</p>

            <p class="paragraph strong">九、如何解决争议</p>

            <p class="paragraph">本说明适用中华人民共和国法律。与本说明有关的任何争议，由双方协商解决。协商不成的，任何一方均可向本公司所在地的人民法院提起诉讼。</p>

            <p class="paragraph empty"></p>

            <p class="paragraph empty"></p>

            <p class="paragraph empty"></p>

            <p class="paragraph empty"></p>

            <p class="paragraph empty"></p>

            <p class="title">「工作啦」信息发布规则</p>

            <p class="paragraph strong">一、前言</p>

            <p class="paragraph">尊敬的用户：</p>

            <p class="paragraph">感谢您使用「工作啦」！为打造一个合法、安全、公平、真实、准确、效率的招聘求职信息服务平台，我们对「工作啦」平台信息发布制定了基本的规则。</p>

            <p class="paragraph">

                本《「工作啦」信息发布规则》（以下简称“本规则”）系您（指个人或组织）与河南英才归来科技有限公司订立的《「工作啦」用户服务协议及隐私说明》的有效组成部分，具有同等法律约束力，与《「工作啦」用户服务协议及隐私说明》共同构成您与「工作啦」合作的法律文件。

            </p>

            <p class="paragraph">您在使用「工作啦」填写并发布企业信息、职位信息之前，请务必审慎阅读、充分理解本规则。您使用「工作啦」信息发布服务的行为，即表示您已阅读并同意本规则所涉全部内容。</p>

            <p class="paragraph">「工作啦」有权根据法律法规、政策及产品需求更新本规则，请您定期访问并查看最新规则。如您继续使用「工作啦」服务，即表示您接受经修订后的规则。</p>

            <p class="paragraph strong">二、企业介绍信息发布规则</p>

            <p class="paragraph">您应当确保填写的企业相关信息合法、真实、准确，您发布的所有文字、图片、视频等内容，都不得含有任何违法、政治敏感、不符合公序良俗、侵犯他人权益的内容。</p>

            <p class="paragraph">在您填写或修改已有的企业相关信息时，应当遵守的具体规则包括但不限于：</p>

            <p class="paragraph">1.企业名称品牌名称、LOGO应当真实有效；品牌名称、LOGO应当与该企业主体具有一致性，或有使用权；品牌名称不得与其他知名品牌名称相同或近似；</p>

            <p class="paragraph">2.公司LOGO应当与公司经营过程中实际使用的LOGO一致，且与其他品牌的使用在先的LOGO不相同或近似；</p>

            <p class="paragraph">3.公司经营地址应当真实有效，如发生变化应当及时更新；</p>

            <p class="paragraph">4.公司网址应当真实有效，如发生变化应当及时更新；</p>

            <p class="paragraph">5.在公司介绍主页上，不得上传与公司无关的图片或涉及联系方式的内容；</p>

            <p class="paragraph">6.公司介绍的文字内容应当真实、准确、完整，便于求职者理解，不得发布与公司介绍无关的文字内容；</p>

            <p class="paragraph">7.公司介绍中包含的经营内容应当与公司的经营范围存在一致性；</p>

            <p class="paragraph">8.产品介绍应当真实、准确、完整，不得将他人产品或产品介绍挪为己用，相关产品应符合公司的经营范围要求；</p>

            <p class="paragraph">9.提供的高管或个人图片应当真实可辨，不得将他人的照片或无关的图片作为高管照片或个人照片；</p>

            <p class="paragraph">10.您发布的信息中不得包含任何其他个人的隐私信息或其他公司的商业信息。</p>

            <p class="paragraph strong">三、招聘信息发布规则</p>

            <p class="paragraph">

                您所发布或更新的招聘信息不得包含以下内容，否则存在无法通过审核的风险。即便通过审核，经「工作啦」发现或用户投诉后核实无误的，我们有权随时将您发布的职位采取部分/全部下线处理。情节严重的，将对您的账号进行冻结/拉黑处理，且不予退款、该企业不再予以合作。

            </p>

            <p class="paragraph strong">发布的招聘职位中包含违法及违法倾向的信息，包括不限于：</p>

            <p class="paragraph">1.涉及色情、淫秽的招聘内容，如包养、卖淫、坐台、陪酒、包房公主；涉及色情服务或隐晦描述的涉黄倾向信息的；</p>

            <p class="paragraph">

                2.涉及组织、招引他人参加赌博/博彩、提供赌场、涉及赌具，具有获取钱财目的等内容；涉及招聘需在赌场、赌博网站等非法机构工作的招聘内容；涉及境外博彩类公司的招聘内容；涉及招聘从事网络发行、销售彩票工作的招聘内容；</p>

            <p class="paragraph">3.涉及走私、贩卖、运输、制造毒品等内容；</p>

            <p class="paragraph">4.涉及使用武力或言语进行人身攻击、引导或引诱他人施暴等内容；</p>

            <p class="paragraph">5.涉及本国或外国政府要员、国内外敏感政治事件等内容；涉及国家重大项目工程等内容；</p>

            <p class="paragraph">6.涉及邪教、黑社会、恐怖武装等非法组织的信息；含有血腥、诡异、变态等使人感受惊吓或不适的内容；</p>

            <p class="paragraph">7.涉及非法占有为目的，用虚构事实或者隐瞒真相的方法，骗取公私财物的行为等内容，如发布信用卡套现、黄牛、刷单、刷钻、补单、虚拟货币交易、点赞等内容；</p>

            <p class="paragraph">8.涉及个人或企业资质、证书等挂靠内容；</p>

            <p class="paragraph">9.涉及发展组织、发展下线人员等传销内容；</p>

            <p class="paragraph">10.涉及发布代替他人怀孕生产或受育、买卖卵子、捐精等代孕代育内容；</p>

            <p class="paragraph">11.涉及发布歧视他人的信息，如学历歧视、性别歧视、婚育歧视、地域歧视、户籍歧视、肤色歧视、国籍歧视、种族歧视、宗教歧视、健康歧视、年龄歧视等歧视性内容；</p>

            <p class="paragraph">12.涉及含人身攻击、侵害他人或第三方权益内容；</p>

            <p class="paragraph">13.涉及违反相关法律法规、政策规定的内容；</p>

            <p class="paragraph">14.涉及发布招聘雇佣兵等职位信息。</p>

            <p class="paragraph strong">发布的招聘职位中包含涉嫌违规及高风险的信息，包括但不限于</p>

            <p class="paragraph">15.以招聘名义进行招生培训的；</p>

            <p class="paragraph">16.发布的招聘信息与实际所招聘情况不符的；</p>

            <p class="paragraph">17.发布大量相同职位描述或雷同职位描述情节严重等「工作啦」有理由认为属于非正常招聘的；</p>

            <p class="paragraph">18.在相同地点发布大量相同职位情节严重等「工作啦」有理由认为属于非正常招聘的； </p>

            <p class="paragraph">19.发布大量不同工作地点职位情节严重等「工作啦」有理由认为属于非正常招聘的；</p>

            <p class="paragraph">20.发布包含不真实的HR身份信息的；</p>

            <p class="paragraph">21.发布包含不真实的公司信息的；</p>

            <p class="paragraph">

                22.发布包含不真实的招聘信息，如职位名称、职位类别、职位福利、职位亮点、技术要求、职位描述、招聘人数、工作性质、工作地址、公司地址、学历、岗位职责、任职要求、薪资水平、福利待遇、标签等职位必填或非必填项部分的文字内容；</p>

            <p class="paragraph">23.发布无经验交易员、分析师、理财师、程序员、编程师等类型职位；</p>

            <p class="paragraph">24.发布涉及需要去境外工作的职位；</p>

            <p class="paragraph">25.发布涉及收取求职者费用，如套路贷、培训贷、培训收费、介绍费、体检费、保证金、押金等类型职位；</p>

            <p class="paragraph">26.发布不提供实际工作岗位的招聘信息，如兼职打字员、手机兼职、手工加工、优惠券推广、POS机推广、游戏代练、陪练等职位；</p>

            <p class="paragraph">27.发布非招聘信息，如招商加盟代理、培训、招生、广告推广、产品售卖/推广、寻求合作、融资入股等信息；</p>

            <p class="paragraph">28.发布跟组演员、跟组化妆师、练习生、歌手、模特类招聘信息；</p>

            <p class="paragraph">29.发布娱乐会所服务类招聘信息，如夜总会领班、KTV服务员、酒保等招聘信息；</p>

            <p class="paragraph">30.发布远洋捕捞船员、普工类招聘信息；</p>

            <p class="paragraph">31.发布包含直销信息等内容；</p>

            <p class="paragraph">32.发布一岗多职的招聘信息；</p>

            <p class="paragraph">33.发布的职位名称包含职位名称以外的信息；</p>

            <p class="paragraph">34.发布的职位信息中包含联系方式，如微信、QQ、电话、邮箱、传真号、公众号、二维码等及其演绎形式；</p>

            <p class="paragraph">35.发布包含特殊字符、彩色字体等内容；</p>

            <p class="paragraph">36.发布包含段子、水贴等与招聘无关的内容；</p>

            <p class="paragraph">37.个体工商户发布非营业执照注册地城市职位的招聘内容；</p>

            <p class="paragraph">38.发布涉及招聘他人从事代考、代写的内容；</p>

            <p class="paragraph">39.发布的信息或行为涉及向求职者或其他用户索取个人信息、隐私信息的。38. 发布的信息被多位用户举报的；</p>

            <p class="paragraph">40.发布的信息或行为涉及引流、兼职推广或为其他平台拉用户等发布涉及异地培训/实习的保安/安检的招聘信息；</p>

            <p class="paragraph">41.发布涉及无资质的违规货车司机、网约车司机、外卖/送餐骑手的招聘信息；</p>

            <p class="paragraph">42.发布的信息或行为涉及非雇主寻找雇员的招聘信息，即帮其他企业招聘。</p>

            <p class="paragraph strong">四、除外情形 </p>

            <p class="paragraph">即便您发布、更新的招聘信息符合规定，即不存在违反本规则第二条、第三条限制情形的，但如您存在如下情形，「工作啦」仍有权根据实际情况自行决定停止对您提供服务或下线您发布的职位信息。</p>

            <p class="paragraph">1.在国家企业信用信息公示系统中的登记状态为注销/吊销状态的公司；</p>

            <p class="paragraph">2.在国家企业信用信息公示系统中被列入经营异常名录，情节严重的公司；</p>

            <p class="paragraph">3.在国家企业信用信息公示系统中查询不到注册信息的公司；</p>

            <p class="paragraph">4.已被法院判定为破产或正在进行破产申请流程的公司；</p>

            <p class="paragraph">5.媒体或网络等渠道中查询到涉嫌违法、违规或违反「工作啦」平台规则等行为的公司。</p>

            <p class="paragraph strong">五、违规处罚规则</p>

            <p class="paragraph">如您违反本规则，「工作啦」可以依据与您签署的线下、线上等相关协议、规则直接采取相关措施，并视情节严重程度选择是否通知用户。</p>

            <p class="paragraph strong">六、释义</p>

            <p class="paragraph">1.职位下线：企业发布的职位停止发布，企业及求职者无法在「工作啦」上查询到该职位； </p>

            <p class="paragraph">2.账号冻结：账号可以登陆，产品无法使用，企业发布的职位全部下线；</p>

            <p class="paragraph">3.账号拉黑：账号无法登陆，产品无法使用，企业发布的职位全部下线。</p>

        </div>

        <div class="bottomRow flex_c" *ngIf="type =='login'">

            <div (click)="close(2)">同意并继续</div>

        </div>

    </div>

</div>