<!-- 页头部分 -->
<div class="contentRight-header">招聘管理 - 面试管理</div>
<!-- 主体内容部分 -->
<div class="homepage-block"> 
        <router-outlet></router-outlet>
        <app-model-position-notice #noticeList  [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-position-notice>
        <app-model-position-hired #hiredList  [isShow]="modelTwoShow" (onVoted)="ModelTwodismiss($event)"></app-model-position-hired>
        <div class="college-block">
                <!-- 头部 -->
               <div class="college-resume-head clear">
                   <div class="search-position left">
                       <!-- 按发布的职位筛选 -->
                       <span>在期职位&nbsp;&nbsp;&nbsp;&nbsp;</span>
                       <select name="position" id="position" [(ngModel)]="model.positionid" (ngModelChange)="getResumeList()" autocomplete="off">
                           <option value=''>全部</option>
                           <option *ngFor="let a of positionList" [value]="a.positionid">{{a.name}}</option>
                       </select>
                   </div>
                   <!-- 更多筛选 -->
                   <div class="search-more right" (click)="isShowFilter=!isShowFilter" [class.show-filter]="isShowFilter">更多条件筛选<img src="assets/images/down-gray.png"></div>
                   <!-- 搜索筛选 -->
                   <div class="search-input right">
                       <input type="text" placeholder="请输入学校名称" [(ngModel)]="model.college_name" (keyup)="inputKeyup($event)"><span (click)="goSearch(model.college_name)"><img src="assets/images/search.png"></span>
                       <!-- 搜索关键词匹配列表 -->
                       <div class="keyup-block" *ngIf="keywordList.length>0">
                           <div class="keyup-list" *ngFor = "let key of keywordList" (click)="goSearch(key.text)">{{key.text}}</div>
                       </div>
                   </div>
                   <!-- <span>&nbsp;&nbsp;&nbsp;&nbsp;其他条件筛选&nbsp;&nbsp;&nbsp;&nbsp;</span> -->
               </div>
               <div *ngIf="isShowFilter" class="college-resume-filter">
                   <!-- 选择学校 -->
                   <!-- <span>学校</span>
                   <select name="college" id="college" [(ngModel)]="model.collegeid" (ngModelChange)="getResumeList()" autocomplete="off">
                       <option class="place-text" value='' disabled hidden selected="selected">选择学校</option>
                       <option *ngFor="let a of schoolList" [value]="a.collegeid">{{a.name}}</option>
                   </select> -->
                    <!-- 面试状态 -->
                    <span>面试状态</span>
                    <select [(ngModel)]="model.status" (ngModelChange)="getResumeList()" autocomplete="off">
                        <option class="place-text" value='' disabled hidden selected="selected">选择面试状态</option>
                        <option value=0>未开始</option>
                        <option value=1>已结束</option>
                    </select>
                     <!-- 面试阶段 -->
                     <span>&nbsp;&nbsp;&nbsp;&nbsp;面试阶段</span>
                     <select [(ngModel)]="model.stage" (ngModelChange)="getResumeList()" autocomplete="off">
                         <option class="place-text" value='' disabled hidden selected="selected">选择面试阶段</option>
                         <option value="first">初试</option>
                         <option value="reexamine">复试</option>
                     </select>
                     <!-- 面试结果 -->
                     <span>&nbsp;&nbsp;&nbsp;&nbsp;面试结果</span>
                     <select [(ngModel)]="model.feedback_status" (ngModelChange)="getResumeList()" autocomplete="off">
                         <option class="place-text" value='' disabled hidden selected="selected">选择面试结果</option>
                         <option value="to_be_accepted">待接受</option>
                         <option value="rejected">已拒绝</option>
                         <option value="accept">已接受</option>
                         <option value="talent_canceled">人才取消</option>
                         <option value="department_canceled">企业取消</option>
                         <option value="expired">过期</option>
                         <option value="handle_interview">处理面试</option>
                         <option value="hired">已录用</option>
                         <option value="improper">不合适</option>
                         <option value="miss">人才爽约</option>
                     </select>
                   <!-- 重置 -->
                   <!-- <button (click)="goInit()">重置</button> -->
               </div>
               <!-- 中间部分 -->
               <!-- 没有内容存在时 -->
               <div *ngIf="countList==0" class="none-block">
                   <img src="assets/images/resume-search-fail.png"><br/>
                   暂未找到相关面试邀请
             
               </div>
               <div *ngIf="countList>0" class="college-resume-cont">
                   <ul>
                       <li>头像</li>
                       <li>姓名</li>
                       <li>学校</li>
                       <li>专业</li>
                       <li>学历</li>
                       <li>应聘职位</li>
                       <li>匹配度</li>
                       <li>面试阶段</li>
                       <li>处理结果</li>                      
                       <li>操作</li>
                   </ul>
                   <div>
                       <ul class="resume-li" *ngFor="let n of resumeList;let i = index;">
                           <li>
                               <!-- <img *ngIf="n.avater" [src]="n.avater+'&imageView2/1/w/100/h/100'"><img *ngIf="!n.avater" src="assets/images/none.png"> -->
                               <img *ngIf="n.avater" src="{{n.avater+'&imageView2/1/w/100/h/100'}}">
                               <img *ngIf="!n.avater&&(n.gender=='01')" src="assets/images/male.png">
                               <img *ngIf="!n.avater&&(n.gender=='02')" src="assets/images/female.png">
                               <img *ngIf="!n.avater&&!n.gender" src="assets/images/male.png">
                           </li>
                           <li><span *ngIf='n.talent_name'>{{n.talent_name}}</span><span *ngIf='!n.talent_name'>暂无</span></li>
                           <li><span *ngIf='n.talent&&n.talent.graduate_university'>{{n.talent.graduate_university}}</span><span *ngIf='!n.talent||!n.talent.graduate_university'>暂无</span></li>
                           <li><span *ngIf='n.talent&&n.talent.major_text'>{{n.talent.major_text}}</span><span *ngIf='!n.talent||!n.talent.major_text'>暂无</span></li>
                           <li><span *ngIf='n.talent&&n.talent.education_text'>{{n.talent.education_text}}</span><span *ngIf='!n.talent||!n.talent.education_text'>暂无</span></li>
                           <li>
                               <span *ngIf='n.position_name'>{{n.position_name}}</span>
                               <span *ngIf='!n.position_name'>暂无</span>
                           </li>
                           <li><span *ngIf='n.match'>{{n.match}}%</span><span *ngIf='!n.match'>暂无</span></li>
                           <li>
                             <span *ngIf="n.stage=='first'||!n.stage">初试</span>
                             <span *ngIf="n.stage=='reexamine'">复试</span>
                           </li>
                           <li>
                             <span *ngIf="n.feedback_status" [class.red]="n.feedback_status=='handle_interview'">{{n.feedback_status | noticetransform}}</span>
                             <span *ngIf='!n.feedback_status'>暂无</span>
                           </li>
                           <li>
                              <span *ngIf="n.talent" (click)="goDetails(n.talent.resume_number)">查看简历</span>
                              <span *ngIf="!n.talent" (click)="goDetails('')">查看简历</span>
                              <span (click)="goChat(n,model.positionid)">继续沟通</span>
                              <span (click)="goNotice(n.interviewid,n.talentid)">面试详情</span>
                              <!-- <span>分享</span> -->
                           </li> 
                       </ul>
                   </div>
               </div>
               <!-- 分页部分 -->
               <div class='paging-block' [class.isPageHeight]="countList>0">
                  <router-outlet></router-outlet>
                  <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
               </div>
             </div>  
</div>
