import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {DataApi, Opera} from "../../provider";
import {Router} from "@angular/router";


export interface model {
  title:string;
  callback:any,
  type:string
}
@Component({
  selector: 'app-user-protocol-model',
  templateUrl: './user-protocol-model.component.html',
  styleUrls: ['./user-protocol-model.component.css']
})
export class UserProtocolModelComponent implements OnInit {

  public callback:any;
  public type = 'login';
  constructor(
    public toastr: ToastrService,
    public dataApi: DataApi,
    private router: Router,
    public opera: Opera,
    @Inject(MAT_DIALOG_DATA) public data: model
  ) {
    if(data.callback)
    {
      this.callback = data.callback;
    }
    if(data.type)
    {
      this.type = data.type;
    }

  }

  ngOnInit() {


  }
  close(index)
  {
    this.callback(index);
  }

}
