<div class="centerBox">

  <div class="centerEx">
    <p class="exTitle">「工作啦」直播宣讲会</p>
    <div class="exRowTitle flex_l"> <div class="titleLine"></div>什么是「工作啦」直播宣讲会？</div>
    <div class="exRowdesc">
      「工作啦」直播宣讲会是工作啦为企业HR打造的线上实时互动直播宣讲平台，通过直播的方式实现远程宣讲、在线答疑，目标毕业生在观看直播时可以实时沟通、一键投递简历等；宣讲视频可在直播结束后回放，供「工作啦」平台近100万毕业生随时观看。
    </div>
    <div class="exRowTitle flex_l"> <div class="titleLine"></div>为什么选择「工作啦」直播宣讲会？</div>
    <img src="../../assets/images/liveEx1.png" style="margin-bottom: 92px" />
    <div class="exRowTitle flex_l"> <div class="titleLine"></div>如何开启「工作啦」直播宣讲会？</div>
    <img src="../../assets/images/liveEx2.png" style="width: 100%" />

    <div class="exbtn flex_c" (click)="start()">
      点击联系您的校招顾问 开启直播宣讲
      <img src="../../assets/images/liveEx_click.png" />
    </div>
  </div>


</div>

<p-dialog  [closeOnEscape]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isShowConnetSuccess" [modal]=true [showHeader]="false" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':460,'height':530,'minHeight':530}">
  <div class="ConnetSuccess" (click)="dismissConnet()">

    <div class="connetSuccess_top" >
      <div class="connetSuccess_top_h1">您的信息已经收到</div>
      <div class="connetSuccess_top_h2">您的专属校招顾问会尽快与您取得联系，</div>
      <div class="connetSuccess_top_h2">您也可以主动添加校招顾问进行咨询，</div>
    </div>
    <div class="guWenBox" *ngIf="config.departmentSelf">
      <img class="zhuanshu" src="../../assets/images/zhuanshu.png" />
      <div class="flex_l marginTop20">

        <img class="guwen_head_icon"  src="{{config.departmentSelf.avatar}}"/>
        <span class="guwen_Name">{{config.departmentSelf.name}}</span>
        <img class="guwen_Yello" src="../../assets/images/huangguan.png"/>
      </div>
      <div class="guwen_cell flex_l marginTop20" (click)="copyText(config.departmentSelf.wx)">
        <img class="guwen_cell_icon" src="../../assets/images/guwen_wx.png" />
        <div class="guwen_cell_title">微信：</div>
        {{config.departmentSelf.wx}}
        <img class="guwen_cell_copy" src="../../assets/images/guwen_copy.png" />
      </div>
      <div class="guwen_cell flex_l marginTop8" (click)="copyText(config.departmentSelf.email)">
        <img class="guwen_cell_icon" src="../../assets/images/guwen_email.png" />
        <div class="guwen_cell_title">邮箱：</div>
        {{config.departmentSelf.email}}
        <img class="guwen_cell_copy" src="../../assets/images/guwen_copy.png" />
      </div>
      <div class="guwen_cell flex_l marginTop8" (click)="copyText(config.departmentSelf.mobile)">
        <img class="guwen_cell_icon" src="../../assets/images/guwen_phone.png" />
        <div class="guwen_cell_title">手机号：</div>
        {{config.departmentSelf.mobile}}
        <img class="guwen_cell_copy" *ngIf="config.departmentSelf.mobile" src="../../assets/images/guwen_copy.png" />
      </div>
      <img src="{{config.departmentSelf.qr_code}}"  class="guwen_cell_code" />

    </div>
  </div>

</p-dialog>
