import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataApi, Local, Opera} from "../../provider";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {productManager} from "../../provider/productManager";

@Component({
  selector: 'app-resume-label-model',
  templateUrl: './resume-label-model.component.html',
  styleUrls: ['./resume-label-model.component.css']
})
export class ResumeLabelModelComponent implements OnInit {

  constructor(
    public dataApi: DataApi,
    public local:Local,
    private sanitizer: DomSanitizer,
    private router: Router,
    public opera:Opera,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public productManager:productManager
  ) { }

  @Input()  isShow: false;//是否显示弹窗页面
  @Output() modelHidden = new EventEmitter<number>();

  public resumeDetail:any;
  public resumeLabelArr = [1,2,3];
  public allLabels = [];
  public allLabels_edit =  [];
  //1 简历添加标签，2 创建标签，3删除标签
  public step = 1;
  public modelW = [546,400,400];
  public modelH = [536,260,200];
  public remark = "";
  public currentDeleteLabel:any;
  public seleteLabelMax = 0;

  ngOnInit() {

  }
  _modelHidden(){
    this.modelHidden.emit(this.resumeDetail);
  }
  init(resumeDetail){
    this.step = 1;
    console.log('resumeDetail',resumeDetail);
    this.remark = resumeDetail.remark
    this.resumeDetail = resumeDetail;
    this.getMyresumeLabelList();
    this.getNotes();
  }

  //添加标签
  addLabelStep(){
    this.step = 2;
    this.allLabels_edit = this.allLabels;
  }
  closeStep(s){
    if(s==1) {
      //关闭了添加标签的步骤，要删除未保存的标签
      let newArr = [];
      for(let n of this.allLabels) {
        if(n.lableid) {
          newArr.push(n);
        }
      }
      this.allLabels = this.rushIndex(newArr);
    }
    this.getMyresumeLabelList();
    this.step = s;
  }
  addLabelClick(){
    if(this.allLabels_edit.length>=25) {
      this.toastr.error('最多添加25个标签');
      return;
    }
    let newArr = this.allLabels_edit;
    newArr.push({name:'',index:newArr.length})
    this.allLabels_edit = newArr;
    this.modelH = [536,260+this.allLabels_edit.length*40,200];
  }
  deleteLabel(item){
    console.log('deleteLabel',item,this.allLabels_edit);
    let newArr = [];
    //网络删除
    if(item.lableid) {
      this.currentDeleteLabel = item;
      this.step=3;
      return;
    }
    for(let n of this.allLabels_edit) {
      if(n.index!=item.index&&n.name!='') {
        newArr.push(n);
      }
    }
    this.allLabels_edit = this.rushIndex(newArr);
  }

  //提交创建标签的按钮
  commitAddLabel(){
    let nameArr = [];
    let newArr = [];
    let updateArr = [];
    for (let n of this.allLabels_edit) {
      if(n.name) {
        if(n.lableid) {
          updateArr.push(n);
        } else {
          newArr.push(n);
        }
        nameArr.push(n.name);
      }
    }
    let nary=nameArr.slice().sort();
    for(let i=0;i<nameArr.length;i++){
      let item1 = nary[i];
      let item2 = nary[i+1];
      if (item1==item2){
        this.toastr.warning('重复标签：'+item1);
        return;
      }
    }
    this.dataApi.creatResumeLabelBatch(newArr).then((res:any)=>{
      this.step=1;
      this.getMyresumeLabelList();
    }).catch((err)=>{
    })
    this.dataApi.updateResumeLabelBatch(updateArr).then((res:any)=>{
      this.getMyresumeLabelList();
    }).catch((err)=>{
    })
  }
  //刷新数组的index；
  rushIndex(arr){
    let newArr = [];
    for (let n = 0;n<arr.length;n++) {
      let item = arr[n];
      item.index = n;
      newArr.push(item);
    }
    return newArr;
  }
  getMyresumeLabelList(){
    let data = {
      offset:'0',
      limit:'40',
    }
    this.dataApi.getMyresumeLabelList(data).then((res:any)=>{
      if(res) {
        let lableArr = this.resumeDetail.lable==null?[]:this.resumeDetail.lable
        let newArr = [];
        for (let n of res.list) {
          let isHave = false;
          for (let text of lableArr) {
            if(n.lableid==text) {
              isHave = true;
              break;
            }
          }
          n.selete = isHave;
          newArr.push(n);
        }
        this.allLabels = newArr;
        this.checkSeleteLabelMax();
      }
    }).catch((err)=>{
    })
  }
  allLabelClick(n){
    if(!n.selete&&this.seleteLabelMax>=3) {
      this.toastr.warning('最多添加三个标签')
      return;
    }
    n.selete = !n.selete;
    this.checkSeleteLabelMax();
  }
  //提交标签接口
  commitaddLabelHttp(){
    let textArr = [];
    for (let n of this.allLabels) {
      if(n.selete) {
        textArr.push(n.lableid);
      }
    }
    let data = {
      resumeid:this.resumeDetail.resumeid,
      level:'0',
      lable:textArr,
      remark:this.remark,
      source:'',
      is_save_remark:true
    }
    this.dataApi.manageResumeArchived(data).then((res:any)=>{
      console.log('manageResumeArchived',res);
      this.resumeDetail.lable = textArr;
      this.resumeDetail.remark =this.remark;
      this.toastr.success('添加标签成功');
      this.modelHidden.emit(this.resumeDetail);
    }).catch((err)=>{
      console.log('getMyresumeLabelList',err);
    })
  }
  checkSeleteLabelMax(){
    let index = 0;
    for (let n of this.allLabels) {
      if(n.selete) {
        index+=1;
      }
    }
    this.seleteLabelMax = index;
  }

  commitDeleteLabelHTTP(){
    if(!this.currentDeleteLabel) {
      this.toastr.error('删除失败，稍后重试')
      return;
    }
    let data = {
      lableid:this.currentDeleteLabel.lableid
    }
    this.dataApi.deleteResumeLabel(this.currentDeleteLabel.lableid).then((res:any)=>{
      console.log('manageResumeArchived',res);
      this.toastr.success('删除成功');
      this.step = 1;
      this.getMyresumeLabelList();
    }).catch((err)=>{
      console.log('getMyresumeLabelList',err);
    })
  }

  getNotes(){
    this.dataApi.getNotes(this.resumeDetail.resumeid).then((res: any) => {
      this.remark = res.remark?res.remark:'';
      console.log('getNotes',this.remark);
    }).catch((err) => {
      console.log("notesClick", err);
    });
  };
}
