import { Component,EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-model-still-milk',
  templateUrl: './model-still-milk.component.html',
  styleUrls: ['./model-still-milk.component.css']
})
export class ModelStillMilkComponent{
  @Input() isShow: false;//是否显示弹窗页面
  public dataList=[];//总列表数据
  public initLength:number;//最多可以选择几个
  public initvalue = [];//初始选中值
  public secendLevel = []; //二级列表数据
  public facultyid='';//当前学院id
  public faculty=[];//当前选中项一级
  public major=[];//当前选中项2级
  constructor(public toastr: ToastrService) {}

  //初始化数据(初始值，列表数据，数量)
  initData(data,data1,number){
    // console.log('执行初始化函数');
    // console.log(data);
    // console.log(data1);
    this.faculty=[];//当前选中项一级
    this.major=[];//当前选中项二级
    this.initvalue = JSON.parse(JSON.stringify(data));
    this.dataList = JSON.parse(JSON.stringify(data1));
    this.initLength = number;
    //判断是否有选中值
    if(this.initvalue.length>0){
      //赋值第一二级选中项
      for(let n of this.initvalue){
        this.faculty.push(n.facultyid);
        if(n.majors){
          for(let m of n.majors){
            this.major.push(m);
          }
        }else{
          n.majors=[];
        } 
      }
      this.facultyid=this.faculty[0];//赋值院系id
      //界面展示处理
      for(let val of this.dataList){
         if(val.facultyid==this.facultyid){
          this.getFirstLevel(val);
          return;
         }
      }
    }

  }
  
  //点击筛选第一级
  getFirstLevel(item){ 
    //填充第二级
    this.secendLevel=[];
    if(item.major_list.length>0){
      for(let m of item.major_list){
        this.secendLevel.push({code:m.code,text:m.name,majorid:m.majorid});
      }
    }
    this.facultyid=item.facultyid;
    //遍历出当前默认值
    for(let val of this.secendLevel){
       for(let m of this.major){
          if(val.majorid==m.majorid){
            let num=this.major.indexOf(m);
            this.major.splice(num,1);
            this.major.push(val);
            break;
          }
       }
    }
  }

  //点击筛选第二级
  getSecendLevel(e,item){
    // e.stopPropagation();
    // console.log("选中第二级");
    // console.log(item);
    // console.log(this.initvalue);
    //判断当前是否没有选中项
    if(this.major.length==0){//当前没有选中项
       this.major.push(item);//添加二级选中项（标记选中状态）
       this.faculty.push(this.facultyid);//添加一级选中项（标记选中状态）
       //变更初始值------添加操作
       this.addValue(item);
      //  if(this.facultyid){//是否已选中院系
      //    //添加一级选中项
      //    let m=0
      //    for(let val of this.faculty){
      //      if(val==this.facultyid){
      //        let num=this.faculty.indexOf(val);
      //        if(this.initvalue[num].majors){
      //         this.initvalue[num].majors.push(item);
      //        }else{
      //          this.initvalue[num].majors=[item]
      //        }
      //        break;
      //      }else{
      //       m++;
      //      }
      //    }
      //  }else{
      //   //变更初始值------添加操作
      //   this.addValue(item);
      //  } 
    }else{//当前已存在选中项
      //判断添加or删除
      let n=0;
      let dn=-1;
      for(let m of this.major){
         if(m.majorid==item.majorid){
           dn=this.major.indexOf(m);//已存在该项，删除操作 
         }else{
           n++;
         }
      }
      if(n==this.major.length){//-------添加操作
        if(this.major.length<this.initLength){//----数量限制
          this.major.push(item);//添加二级选中项
          //添加一级选中项
          let m=0
          for(let val of this.faculty){
            if(val==this.facultyid){
              let num=this.faculty.indexOf(val);
              this.initvalue[num].majors.push(item);
              break;
            }else{
             m++;
            }
          }
          if(m==this.faculty.length){
            this.faculty.push(this.facultyid);
            //变更初始值------添加操作
            this.addValue(item);
          }
        }else{
          this.toastr.warning("最多添加"+this.initLength+'项');
        }
      }else{//------------------删除操作
        console.log("删除操作------");
        this.major.splice(dn,1);//修改二级选中项
        this.delectValue(item);
      }
    }
  }
 //删除（修改一级选中项及最终选中项的值）
  delectValue(item){
    let fn=-1;
    let sn=-1;
    for(let m of this.initvalue){
      for(let n of m.majors){//循环遍历出筛选项
        if(n.majorid==item.majorid){
          console.log("遍历出删除项-----");
          console.log(n);
          fn=this.initvalue.indexOf(m);
          sn=m.majors.indexOf(n);
          //遍历出删除项的id及下标
          if(m.majors.length==1){//当前院系下仅选中一项
            this.initvalue.splice(fn,1);
            this.faculty.splice(fn,1);
          }else{//当前院系下选中多项
            this.initvalue[fn].majors.splice(sn,1);
          }
          return;
        }
      }
    }
    

  }

   //变更初始值------添加操作
  addValue(item){
    for(let m of this.dataList){
      if(m.facultyid==this.facultyid){
        let model={
         facultyid:m.facultyid,
         faculty_name:m.name,
         majors:[item]
        }
         this.initvalue.push(model);
      }
    }
    // console.log("初始值变更完成");
    // console.log(this.initvalue);
  }

  @Output() onVoted = new EventEmitter();

  dismiss(result: any) {
    //console.log("传值检查");
    //console.log(result);
    this.onVoted.emit(result);
  }

}
