<!-- 企业认证协议 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
  <div class="black-bg" (click)="dismiss(false)"></div>
  <div class="model-cont">
    <!-- 上层提示部分 -->
    <div class="model-head clear">
        <div class="left">高校云认证协议</div>
        <button class="right" (click)="dismiss(false)">×</button>
    </div>
    <div class="agreen">
      「工作啦」高校云认证协议<br>
      欢迎您使用「工作啦」高校云认证服务<br>
      为使用「工作啦」高校云认证服务（简称为：本服务），您应当阅读并遵守《高效云认证服务协议》（简称为：本协议）。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。 
      <br>除非您已阅读并接受本协议所有条款，否则您无权使用本服务。您购买或使用「工作啦」软件内的任何收费服务，即视为您已阅读并同意本协议的约束。
      <br>一、协议条款的确认和接受
      <br>1.1在用户决定使用相关高校云认证服务之前，请仔细阅读本协议条款。用户必须在完全同意以下条款的前提下，才能进行购买程序。「工作啦」将根据以下协议条款为用户提供高校云认证服务。用户在享受「工作啦」高校云认证服务时必须完全、严格遵守本协议条款。
      <br>1.2协议适用主体范围
      <br>「工作啦」提供的网络服务采用免费与收费结合的方式，用户须先成为「工作啦」普通注册 用户之后，经过免费高校云认证服务之后，才能继续购买其他服务。在开始高校云认证之前，请用户首先同意并接受以下服务条款。
      <br>1.3本服务内容
      <br>本协议为您与「工作啦」《用户协议及隐私说明》的补充协议，是其不可分割的组成部分， 与其构成统一整体。本协议中未约定事项以《用户协议及隐私说明》中的约定为准。本 协议与前述协议存在冲突的，以本协议为准。
      <br>本协议内容同时包括「工作啦」可能不断发布的关于本服务的相关协议、业务规则等内容。 上述内容一经正式发布，即为本协议不可分割的组成部分，用户同样应当遵守。
      <br>1.4「工作啦」根据互联网的发展和中华人民共和国有关法律、法规的变化，不断地完善服务质量并依此修改服务条款。「工作啦」有权就本协议随时更新，并在软件内或通过您留下的联系方式通知您。用户的权利以及义务的表述、购买程序及收费标准，均以最新的服务条款为准。用户如果不同意该条款的修改并不影响已发生责任及义务的承担；如果用户在条款 变更后继续访问或使用「工作啦」，则视为用户已经接受对本协议的修改。
      <br>二、服务说明
      <br>高校云认证服务，指按照「工作啦」指定标准认证成功之后，用户可以享有「工作啦」平台提供的企业高校云认证标识、职位权限、沟通权限等功能服务，简称为：本服务。「工作啦」可能会根据不同的企业或组织类型，要求用人单位提供不同的对应的高校云认证资质，目前，「工作啦」提供企业高校云认证的增值服务。同时，「工作啦」也可能会根据用户的需求、产品及服务类型的变化等，对现有高校云认证服务种类进行调整以及不断推出新的增值服务种类。具体增值服务种类及服务内容及费用标准以相关服务页面公布、实际提供的内容为准。用户可以自行根据需要选择相应服务。 用户可通过登录「工作啦」，免费查询其消费服务的详情（包括其购买的高校云认证服务名称、服务权益、购买时间、即时服务状态等）。
      <br>三、服务说明及开通
      <br>3.1服务说明：
      <br>本服务是「工作啦」为注册用户提供免费的高校云认证服务。
      <br>3.2服务开通 
      <br>3.2.1重要约定：
      <br>用户认证一旦成功，「工作啦」将不提供任何自行更改、修正服务。如需更改、修正认证资质，请联系「工作啦」在线客服。
      <br>3.2.2－非经「工作啦」许可，用户不得以以下方式开通服务。 
      <br>（1）以营利、经营等非个人使用的目的为自己或他人开通本服务；
      <br>（2）通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式为自己 或他人开通本服务；
      <br>（3）通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本服务；
      <br>（4）通过非「工作啦」指定的方式为自己或他人开通本服务；
      <br>（5）通过侵犯「工作啦」或他人合法权益的方式为自己或他人开通本服务，如通过第三方平台由他人代为开通；
      <br>（6）通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本服务。
      <br>3.3服务期限
      <br>本服务中高校云认证可能存在相关期限，用户可以登陆「工作啦」“我的”-“企业高校云认证”界面显示有效期限。
      <br>四、用户权利及义务
      <br>4.1重要约定：不得将账号提供给第三方操作。因违反前述约定导致用户隐私泄露的，用户应当自行承担相应后果；如给「工作啦」造成损失的，「工作啦」可以要求用户承担违约责任并赔偿损失。
      <br>4.2用户同意不会采用收费或免费的方式，全部或部分地在任何公开场合展示用户购买或免费获得的增值服务内容，除非用户的上述行为不会构成侵权。
      <br>4.3用户在使用本服务时不得利用本服务从事以下行为，包括但不限于：
      <br>（1）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、 社会公德以及侮辱、诽谤、淫秽、暴力的内容；
      <br>（2）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
      <br>（3）虚构事实、隐瞒真相以误导、欺骗他人；
      <br>（4）发表、传送、传播广告、宣传、促销信息及垃圾信息；
      <br>（5）通过任何方式搜集本服务中其他用户的用户名、电子邮件等相关信息，并以发送垃圾邮件、连锁邮件、垃圾短信、即时消息等方式干扰、骚扰其他用户；
      <br>（6）从事其他违反法律法规、政策及公序良俗、社会公德等的行为。
      <br>4.5对自己行为负责
      <br>用户充分了解并同意，用户必须为自己注册帐号下的一切行为负责，包括但不限于用户所发 表的任何内容以及由此产生的任何后果。用户应对本服务中的内容自行加以判断，并承担因 使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。 若因用户违反「工作啦」《用户协议及隐私说明》或本协议内容，「工作啦」因此冻结账号及 终止账号使用资格者，不得因此要求「工作啦」作任何补偿或赔偿。
      <br>4.6用户应自行负责妥善且正确地保管、使用、维护其在「工作啦」申请取得的账户及账户 密码。用户应对其账户密码采取必要和有效的保密措施。非「工作啦」原因致使用户账户密 码泄漏以及因用户保管、使用、维护不当造成损失的，「工作啦」无须承担与此有关的任何 责任。
      <br>4.7会员用户使用本服务仅限用于自身员工招聘，承诺不利用本服务从事其他事宜，包括但不限于为其他第三方代为招聘，收集求职者数据、简历用作他用，以自身招聘为名招收学员或办理贷款，使用平台服务从事公司业务经营活动，利用平台服务中聊天沟通等功能进行广告营销等情形。
      <br>五、「工作啦」的权利义务
      <br>5.1 保护用户信息是「工作啦」的一项基本原则，「工作啦」将会采取合理的措施保护用户所提交的一切资料及信息。除法律法规定及用户与「工作啦」约定的情形外，「工作啦」不会向其他第三方公开、透露上述资料及信息。
      <br>5.2 「工作啦」有权根据用户提交的资料及信息，确定帐号资质审核结果，生成认证及会员标识及会员信息，开通相应的高级功能及高级权限。 
      <br>5.3用户同意「工作啦」可在本网站页面或者与本网站有关的市场宣传、推广材料上公开使用用户的公开信息，包括但不限于名称、商号、商标、标语等。「工作啦」承诺上述信息不用于与本网站服务无关的事项上。
      <br>六、变更及免责条款
      <br>6.1「工作啦」有权随时修改本协议的任何条款，一旦本协议的内容发生变动，「工作啦」将会在网站上公布修改之后的协议内容，若用户不同意上述修改，则可以选择停止使用「工作啦」服务。「工作啦」也可选择通过其他适当方式（比如系统通知）向用户通知修改内容。如果不同意「工作啦」对本协议相关条款所做的修改，用户有权停止使用「工作啦」服务。如果用户继续使用「工作啦」服务，则视为用户接受「工作啦」对本协议相关条款所做的修 改。
      <br>6.2用户充分了解并同意，由于互联网服务的特殊性，「工作啦」可能会按照相关法规、双 方约定或在其他必要时，中止或终止向用户提供本服务，届时，「工作啦」会依法保护用户 的合法权益。
      <br>6.3「工作啦」作为信息服务与交流平台，所有职位信息、找工作信息及平台交流信息均由用户自行上传、发布。「工作啦」对用户的真实资信状况、用户发布信息的真实性、合法性、准确性等不作保证。「工作啦」不对简历的内容做实质性审查，不保证简历的真实、完整、准确；「工作啦」不对用户实际经营状况做任何实质审查，不对用户的资信状况做出保证。因上述原因给第三方造成的损失，「工作啦」不承担责任。
      <br>七、服务的中断和终止
      <br>7.1不可抗力和意外事件：因发生不可抗拒的事由，如政府禁令、现行生效的适用法律或法规的变更、火灾、地震、动乱、战争、停电、通讯线路中断、黑客攻击、计算机病毒侵入或发作、电信部门技术调整、政府管制而造成网站的暂时性关闭等任何影响网络正常运营的不可预见、不可避免、不可克服和不可控制的事件（“不可抗力事件”），以及他人蓄意破坏、正常的系统维护、系统升级，或者因网络拥塞而导致「工作啦」不能访问等意外事件而造成的「工作啦」所提供的信息及数据的延误、停滞或错误，「工作啦」将尽快通知用户，但不承担由此对用户造成的任何损失并不退还任何费用。不可抗力和意外事件消失后，「工作啦」将立即恢复本服务。
      <br>7.2如用户严重违反本服务条款中的内容，「工作啦」有权在不通知用户的情况下立即终止 用户已购买的部分或所有服务，以及暂时或永久取消用户的「工作啦」账户和使用权限，但不退还任何已缴纳的服务费用。
      <br>7.3「工作啦」未行使或延迟行使其在本协议项下的权利并不构成对这些权利的放弃，而单 一或部分行使其在本协议项下的任何权利并不排斥其任何其它权利的行使。「工作啦」随时 有权要求用户继续履行义务并承担相应的违约责任。
      <br>八、违约责任
      <br>8.1如因「工作啦」违反有关法律、法规或本协议项下的任何条款而给用户造成损失，「工 作啦」同意承担由此造成的损害赔偿责任。但「工作啦」的赔偿责任亦以用户就使用本服务所支付「工作啦」的价款为限。
      <br>8.2用户若违反协议或相关法律法规，导致「工作啦」、或其关系企业、受雇人、及其它相关履行辅助人因此而受到损害或支出费用（包括但不限于由法律诉讼、行政程序 等所支出的诉讼费用、律师费用、实际遭受损失的费用等），用户应负担损害赔偿责任。
      <br>8.3对第三方损害的处理
      <br>用户有违反本协议任何行为，导致任何第三方损害的，用户应当独立承担责任；「工作啦」 因此遭受损失的，用户也应当一并赔偿。
      <br>九、其他条款
      <br>9.1本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。如本服务条款与中华人民共和国法律相抵触时，则该等条款将按法律规定重新修订，而其它条款则依旧有效并具有法律效力。
      <br>9.2如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向「工作啦」运营商河南英才归来科技有限公司所在地的人民法院提起诉讼。
      <br>9.3本协议项下所有的通知均可通过软件内通知、电子邮件、短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。
    </div>
  </div>
</div>