<!-- 企业产品 -->
<!-- 遮罩层公共样式 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px','width':'700px'}" [(visible)]="isShow" (onHide)="dismiss(false)" [modal]=true>
    <div class="model-cont">
        <div class="clos" (click)="dismiss(false)"><img src="/assets/images/rights/contactMeClos.png"></div>
        <div class="model-head clear">
            <div class="left">{{title}}企业产品</div>
        </div>
        <div class="formBox">
            <div class="module">
                <div class="lable">产品名称<span>（必填）</span></div>
                <div class="val">
                    <input type="text" [(ngModel)]="model.name" maxlength="10" placeholder="请输入产品名称，少于10个字">
                </div>
            </div>
            <div class="module">
                <div class="lable">产品介绍</div>
                <div class="textarea">
                    <textarea placeholder="请输入产品介绍，少于200字" [(ngModel)]="model.description" maxlength="200"></textarea>
                </div>
            </div>
            <div class="upBox">
                <div class="item">
                    <div class="title">产品图片<span>{{model.image_url?'1':'0'}}/1</span></div>
                    <div class="box">
                        <label>
                            <img class="pushImg" *ngIf="!model.image_url" src="/assets/images/v3/pushposition.png">
                            <img class="_img" *ngIf="model.image_url"  [src]="model.image_url">
                            <input class="uploadImg" id="uploadProductImg" (change)="previewPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                        </label>
                    </div>
                </div>
            </div>

        </div>
        <div class="select-submit">
            <div class="item active" *ngIf="model.pid" (click)="deleteProduct()">删除</div>
            <div class="item" (click)="getProduct()">保存</div>
        </div>
    </div>
</p-dialog>