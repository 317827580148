<!-- 公司名称 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block nameBlock" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>
    <div class="model-cont">
        <!-- 上层提示部分 -->
        <div class="model-head clear">
            <!-- <div class="left" *ngIf="type == 2">修改公司名称</div> -->
            <!-- <div class="left" *ngIf="type == 1">修改公司名称及执照</div> -->
            <button class="right" (click)="dismiss(false)">×</button>
        </div>
        <!-- 中间内容部分 -->
        <div class="model-content" *ngIf="type == 1">
            <!-- 公司名称 -->
            <br>
            <div class="sign-list">
                <span>公司名称：</span><input type="text" [(ngModel)]="model.name" maxlength="40" placeholder="请填写公司全称" (blur)="checkName()">
            </div>
            <br>
            <div class="sign-list sign-img">
                <span>营业执照：</span>
                <div class="sign-label-block">
                    <label *ngIf="model.filrImg" class="uploadImg-label department-logo" for="uploadFileImg">
                        <img [src]="model.filrImg+'?imageView2/1/w/240/h/180'">
                    </label>
                    <label *ngIf="!model.filrImg" class="uploadImg-label" for="uploadFileImg">
                        <img src="assets/images/add-img.png"><br/>点击上传图片
                    </label>
                    <input class="uploadImg" id="uploadFileImg" (change)="previewPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                </div>
            </div>
            <div class="sign-list sign-img">
                <span>资质变更授权书：</span>
                <div class="sign-label-block">
                    <label *ngIf="model.filrImg2" class="uploadImg-label department-logo" for="uploadFileImg2">
                        <img [src]="model.filrImg2+'?imageView2/1/w/240/h/180'">
                    </label>
                    <label *ngIf="!model.filrImg2" class="uploadImg-label" for="uploadFileImg2">
                        <img src="assets/images/add-img.png"><br/>点击上传图片
                    </label>
                    <input class="uploadImg" id="uploadFileImg2" (change)="previewPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                </div>
            </div>
        </div>

        <div class="model-content" *ngIf="type == 2">
            <!-- 公司名称 -->
            <br>
            <div class="sign-list">
                <span>公司名称：</span><input type="text" [(ngModel)]="model.name" maxlength="40" placeholder="请填写公司全称" (blur)="checkName()">
            </div>
            <div class="sign-list">
                <span>公司简称：</span><input type="text" [(ngModel)]="model.name" maxlength="10" placeholder="请填写公司简称" (blur)="checkName()">
            </div>
            <br>
            <div class="sign-list sign-img">
                <span>营业执照：</span>
                <div class="sign-label-block">
                    <label *ngIf="model.filrImg" class="uploadImg-label department-logo" for="uploadFileImg">
                        <img [src]="model.filrImg+'?imageView2/1/w/240/h/180'">
                    </label>
                    <label *ngIf="!model.filrImg" class="uploadImg-label" for="uploadFileImg">
                        <img src="assets/images/add-img.png"><br/>点击上传图片
                    </label>
                    <input class="uploadImg" id="uploadFileImg" (change)="previewPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                </div>
            </div>
            <div class="sign-list sign-img">
                <span>资质变更授权书：</span>
                <div class="sign-label-block">
                    <label *ngIf="model.filrImg2" class="uploadImg-label department-logo" for="uploadFileImg2">
                        <img [src]="model.filrImg2+'?imageView2/1/w/240/h/180'">
                    </label>
                    <label *ngIf="!model.filrImg2" class="uploadImg-label" for="uploadFileImg2">
                        <img src="assets/images/add-img.png"><br/>点击上传图片
                    </label>
                    <input class="uploadImg" id="uploadFileImg2" (change)="previewPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                </div>
            </div>
        </div>
        <!-- 底部按钮 -->
        <div class="select-submit" ><button class="purple" (click)="getProduct()">提交</button></div>
        <div class="select-submit" ><button class="purple" (click)="getProduct()">提交</button></div>
    </div>
</div>
