import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Events } from '../../provider/eventService';
import {Config, DataApi, Local, MyMMessage, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ModelPositionNoticeComponent} from "../model-position-notice/model-position-notice.component";
import {NavigationRouteComponent} from "../navigation-route/navigation-route.component";

declare let window;
@Component({
  selector: 'app-chat-single',
  templateUrl: './chat-single.component.html',
  styleUrls: ['./chat-single.component.css']
})
export class ChatSingleComponent implements OnInit {

  @ViewChild('scrollMe', { static: true }) msgContent: ElementRef;
  @ViewChild('appNavigation', { static: true }) appNavigation:NavigationRouteComponent;

  public el: HTMLElement;
  public config: Config;

  public  chatList = [];
  public msgText='';
  public department:any;
  public nowData:any;
  public userJmUsername  = "";//对方的ID
  public UserInfo:any;//对方的信息
  public resumeDetail:any;
  public yixiang = ""; //聊天对象的求职意向

  constructor(
      public events : Events,
      public dataApi: DataApi,
      public local: Local,
      private element:ElementRef,
      public render:Renderer2,
      public cd: ChangeDetectorRef,
      private router: Router,
      private location: Location,
      private activatedRoute: ActivatedRoute,
      public opera:Opera,
      public dialog: MatDialog,
      public mimc:MyMMessage,
      public toastr: ToastrService
  ) {
    this.userJmUsername = this.local.get('userJmUsername');
    console.log('constructor-chat');
    if(!this.userJmUsername)
    {
      this.toastr.error('获取聊天用户失败')
    }
  }


  ngOnInit() {
    window.scrollTo( 0,0);
    //初始化监听
    this.initEvents();
    //初始化自己的信息
    this.init();
    //获取聊天对象的用户信息
    this.getChatUserInfo();
    //获取聊天的历史记录
    this.getMessageHistoryList();


  }
  ngAfterViewInit()
  {
    console.log('ngAfterViewInit')
    this.cd.detectChanges();//刷新数据
    this.scrollBottom();
    this.appNavigation.refNav();
  }
  initEvents(){

    let that = this;
    this.events.subscribe('MIMCloginSuccess', (data) => {
      console.log('MIMC登录成功')
      that.getMessageHistoryList();
    });

    //加载聊天信息，判断通知来的用户和当前聊天对象是否是同一个人，不是同一个人的话重新加载聊天记录
    this.events.subscribe('notificationClick', (data) => {
      let name= this.local.get('userJmUsername');
      console.log('notificationClick',name,  this.userJmUsername)
      this.appNavigation.refNav();
      setTimeout(()=>{
        window.scrollTo(0,0);
      },400)
      if(name!=this.userJmUsername)
      {
        this.chatList = [];
        this.userJmUsername = name;
        this.getChatUserInfo();
        this.getMessageHistoryList();
      }
    });

    //收到新消息广播
    this.events.subscribe('newMM:tabs', (data) => {
      console.log('单聊页面收到消息',data,this.userJmUsername);
      if(data)
      {
        this.userJmUsername = this.local.get('userJmUsername');
        if(data.from!=this.userJmUsername)
        {
          return;
        }
        console.log(data);
        let arr = this.chatList;
        arr.push(data);
        this.chatList = arr;
        this.formatMsgList(this.chatList);
        this.cd.detectChanges();//刷新数据
        this.scrollBottom();
        this.getChatUserInfo();
      }
    })
  }

  //初始化自己的信息
  init(){
    this.dataApi.getDepartment().then((res:any)=>{
      if(res){
        console.log("企业信息",res);
        this.department = res;
      }
    }).catch((error)=>{});
  }

  //页面滚动-废弃
  scrollClick(){
    this.msgContent.nativeElement.scroll(0,this.msgContent.nativeElement.scrollTop +120);
  }

  //滚动加载聊天记录事件
  async contentScroll(event){
    if(this.msgContent.nativeElement.scrollTop<=30)
    {
      this.getMessageHistoryListWithTop();
    }
  }

  //滚动到底部
  scrollBottom(){
    this.msgContent.nativeElement.scrollTop = this.msgContent.nativeElement.scrollHeight;
  }
  //发送消息
  sengMsg(){
    console.log(this.msgText);
    if(this.msgText=="")
    {
      return;
    }
    //发送小米消息
    this.mimc.send(this.userJmUsername,'text',this.msgText);
    let newDate = new Date()
    let data = {
      content: this.msgText,
      createTime: new Date(),
      from: this.department.jm_username,
      type: "text",
      showTime:false,
    }
    this.msgText = "";
    this.chatList.push(data);
    this.formatMsgList(this.chatList);
    this.cd.detectChanges();//刷新数据
    this.scrollBottom();
  }


  //获取mimc 聊天记录
  async getMessageHistoryList(){
    this.nowData = String((new Date()).valueOf());
    let data = await this.mimc.getMessageList(this.userJmUsername,10,this.nowData);
    console.log('getMessageHistoryList',data,this.userJmUsername)
    console.log(data);
    if(data.length>0){
      this.nowData = String((new Date(data[0].createTime)).valueOf());
      this.chatList = data;
      this.formatMsgList(this.chatList);
      this.cd.detectChanges();//刷新数据
      this.scrollBottom();
    }
  }
  //获取mimc 聊天记录 上滑加载
  async getMessageHistoryListWithTop(){
    let nowData;
    if(this.chatList.length>0)
    {
      nowData = new Date(this.chatList[0].createTime).valueOf();
      console.log('下拉加载',nowData);
    }
    else
    {
      nowData = new Date().valueOf();
    }
    let data = await this.mimc.getMessageList(this.userJmUsername,10,nowData);
    console.log(data);
    if(data.length>0){
      this.nowData = String((new Date(data[0].createTime)).valueOf());
      this.chatList = [...data,...this.chatList];
      if(data.length<10){
        this.toastr.info("聊天记录加载完毕");
      }
    }
  }

  //获取聊天对象信息
  getChatUserInfo(){
    this.dataApi.getChatUserInfo(this.userJmUsername).then((res:any)=>{
      if(res){
        console.log('聊天对象信息',res)
        this.UserInfo = res;
        this.getUserDetail(this.UserInfo)
      }
    }).catch((error)=>{});
  }
  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(event.ctrlKey && event.keyCode==13) {
      that.msgText =that.msgText +'\n\r';
      return;
    }
    if(keycode==13){
      that.sengMsg();
    }
  }

  //格式化聊天记录
  formatMsgList(list){
    let arr = []
    for(let index=0;index<list.length;index++)
    {
      if(index>0)
      {
        let nowData1 = new Date(this.chatList[index-1].createTime).valueOf();
        let nowData2 = new Date(this.chatList[index].createTime).valueOf();
        let cha= (nowData2-nowData1)/1000;
        //2019-09-27 11:17:22
        //2019-09-27 11:17:35
        //13000
        if(cha<=300)
        {
          this.chatList[index].showTime = false;
        }
        if(cha>300)
        {
          //显示日期
          this.chatList[index].showTime = true;
        }
      }
    }
    if(this.chatList.length>0)
    {
      this.chatList[0].showTime = true;
    }
  }

  getTopTime(index)
  {
    if(index==this.chatList.length)
    {
      return false;
    }
    let mgs = this.chatList[index];
    return new Date(mgs.createTime).toLocaleTimeString()
  }
  //获取聊天对象的简历详情
  getUserDetail(n){
    console.log('current_accountid',n.current_accountid)
    this.dataApi.getresumeDetailWithNumber(n.dialogue_user.resume_number).then((res:any)=>{
      if(res){
        console.log('简历详情',res)
        this.resumeDetail = res;
        let str = "";
        for (let n of res.pro_skills)
        {
          if(res.pro_skills.length==1)
          {
            str += n.text;
            break;
          }
          str += n.text+" | ";
        }
        this.yixiang = str;

      }
    }).catch((error)=>{});
  }
}
