<div class="homepage-block">
    <div class="custom_card notification_center_tab_card">
        <div class="custom_tabs">
            <div (click)="chooseStatus('')" class="custom_tab" [ngClass]="{active:model.sender_account_type==''}">全部
            </div>
            <div (click)="chooseStatus('college')" class="custom_tab"
                [ngClass]="{active:model.sender_account_type=='college'}">就业活动通知</div>
            <div (click)="chooseStatus('talent')" class="custom_tab"
                [ngClass]="{active:model.sender_account_type=='talent'}">简历通知</div>
            <div (click)="chooseStatus('system')" class="custom_tab"
                [ngClass]="{active:model.sender_account_type=='system'}">系统通知</div>
        </div>
    </div>
    <div class="custom_btn_plain notification_center_clear_btn" (click)="setAllMsgView()"><img src="../../assets/images/v3/brush.png">清除未读</div>
    <div style="width: 100%;">

        <div style="width: 100%;height: 44px;margin: 5px 0;background-color: white"
            *ngFor="let n of config.msgList;let i = index;" (click)="msgLick(n)">
            <span style="line-height: 44px;height: 44px;background-color: white;margin-left: 15px;float: left">
                您有一条新消息
            </span>
            <span style="line-height: 44px;height: 44px;background-color: white;margin-right: 15px;float: right">
                {{ formatMsgTime(n.createTime)}}
            </span>
        </div>
    </div>


    <div class="task-list">
        <div class="custom_card hover c_p d_f ai_fs notification_center_list_card" *ngFor="let n of taskList;let i = index;" [class.grey]="n.view"
            (click)="goHandle(n)">
            <img class="list_img" *ngIf="n.sender_account_type == 'college'" src="../../assets/images/v3/tag.png">
            <img class="list_img" *ngIf="n.sender_account_type == 'system'" src="../../assets/images/v3/bell.png">
            <img class="list_img" *ngIf="n.sender_account_type == 'talent'" src="../../assets/images/v3/file.png">
            <div class="f_1">
                <div class="d_f list_title" [ngClass]="{textRed:n.titleColor,unview:!n.view}">{{n.content.first}}</div>
                <div class="list_date">{{n.add_time | timetransform}}</div>
                <div class="list_content">
                    <div *ngFor="let item of n.content | keyvalue">
                        <span *ngIf="item.key != 'first' && item.key != 'remark'">{{item.key+' : '+item.value}}</span>
                        <span *ngIf="item.key == 'remark'">{{item.value}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 100%;height: 500px;background-color: white;font-size: 18px" class="flex_c" *ngIf="countList==0">
        暂无通知
    </div>
    <!-- 分页部分 -->
    <div class='paging-block flex_r' [class.isPageHeight]="countList>0">
        <router-outlet></router-outlet>
        <!--<app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>-->
        <div id="page"></div>

    </div>
</div>