import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {productManager} from "../../provider/productManager";
import {VoiceCallModelComponent} from "../voice-call-model/voice-call-model.component";
import {ModelSendMsgComponent} from "../model-send-msg/model-send-msg.component";

@Component({
  selector: 'app-activity-invite-record-detail',
  templateUrl: './activity-invite-record-detail.component.html',
  styleUrls: ['./activity-invite-record-detail.component.css']
})
export class ActivityInviteRecordDetailComponent implements OnInit {
  public config: Config;
  constructor(public dataApi: DataApi,
              public local:Local,
              private sanitizer: DomSanitizer,
              private router: Router,
              public opera:Opera,
              public productManager:productManager,
              private activatedRoute: ActivatedRoute,
              public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
    this.productManager.init();
  }

  public activityRecordid = '';
  public activityRecordid_Name = '';
  public model = {
    offset:0,
    limit:10,
  }
  public list = [];
  @Output() SengMsgModel ={
    userName:'',
    userPic:'',
    talentid:'',
    graduation_school:'',//学校
    major_text:'',//专业
    gender:'',
    address:'',
    positionid:''
  };//打开单品发送极速短信用户对象

  @Output() isShowVoiceCallModel = false;//是否打开弹窗(简历收件箱)
  @Output() isShowPositionPushModel = false;//是否打开弹窗(简历收件箱)
  @ViewChild('voiceCallModel', { static: true }) voiceCallModel: VoiceCallModelComponent;
  @ViewChild('sengMsgModel', { static: true }) sengMsgModel: ModelSendMsgComponent;
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)

  ngOnInit() {
    this.opera.initConfig('nav-resume-manager','activityInviteRecord');
    let id =   this.local.get('activityRecordid');
    if(id) {
      this.activityRecordid = id;
      this.getList();
    }
    let activityRecordid_Name =   this.local.get('activityRecordid_Name');
    if(activityRecordid_Name) {
      this.activityRecordid_Name = activityRecordid_Name;
    }
    let activityRecordid_Nav =   this.local.get('activityRecordid_Nav');
    if(activityRecordid_Nav) {
      this.opera.initConfig('nav-resume-manager',activityRecordid_Nav);
    }
  }
  getList(){
    this.dataApi.getActivityrecordDetail(this.activityRecordid,this.model).then((res:any) => {
      if (res) {
        this.list  = res.list;
        this.countList=res.count;
        this.pageCount =Math.ceil(res.count/10);
        for (let val of  this.list ) {
          if(val.lable==null) {
            val.lable=[];
          }
          if( val.gender==null) {
            val.gender="01";
          }
          let arr = [];
          val.yixiang = "";
          val.youshiArr = [];
          if(val.pro_skills!=null) {
            for (let item1 of val.pro_skills) {
              val.yixiang += item1.text+"    "
              if(item1.contrast_list){
                for (let youshi of item1.contrast_list) {
                  val.youshiArr.push(youshi.text);
                }
              }
            }
          }
        }
      }
    }).catch((err) => {
    })
  }
  voiceCallClick(e,n){
    e.stopPropagation();

    let that = this;

    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.productManager.getProductUsableCount(this.config.voice_call,(count,isVip)=>{
          if(count==0||!isVip) {
            let activityRecordid_Nav =   this.local.get('activityRecordid_Nav');
            //airCampHistoryReord
            this.config.payVipHistoryPage = {
              page:activityRecordid_Nav=="airCampHistoryReord"?"空中宣讲会推广记录":'校招活动邀请记录',
              isVip:isVip,
              pro:'极速电话联系'
            };
            that.local.set("single_category_code",this.config.ProductListCode.voice_call_code);
            that.router.navigate(['home/tool-campus']);
          } else {
            this.voiceCallModel.initData(n);
            this.isShowVoiceCallModel = true;
          }
        })
      } else {
        that.opera.checkMemberAlertWithType("noneVip_voice_call")
      }
    })
  }
  positionPushClick(e,n){
    e.stopPropagation();
    let that = this;

    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.productManager.getProductUsableCount(this.config.sms_bulkinvitation,(count,isVip)=>{
          if(count==0||!isVip) {
            let activityRecordid_Nav =   this.local.get('activityRecordid_Nav');
            this.config.payVipHistoryPage = {
              page:activityRecordid_Nav=="airCampHistoryReord"?"空中宣讲会推广记录":'校招活动邀请记录',
              isVip:isVip,
              pro:'职位推送'
            };
            that.local.set("single_category_code", this.config.ProductListCode.sms_bulkinvitation_code);
            that.router.navigate(['home/tool-campus']);
          } else {
            this.sengMsgModel.initData(1,[]);
            this.SengMsgModel.userName = n.talent_name;
            this.SengMsgModel.userPic = n.logo==null?'': n.logo;
            this.SengMsgModel.graduation_school = n.graduate_university;
            this.SengMsgModel.major_text = n.major_text;
            this.SengMsgModel.talentid = n.talentid;
            this.SengMsgModel.gender = n.gender;
            this.SengMsgModel.address = n.address_text;
            this.isShowPositionPushModel =true;
          }
        })
      } else {
        that.opera.checkMemberAlertWithType("noneVip_sms_bulkinvitation")
      }
    })
  }
  //获取分页子组件返回数据
  getChildEvent(index){
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*10;
      this.getList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*10;
        this.getList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*10;
        this.getList();//获取简历投递列表
      }
    }
  }
  //极速电话联系弹窗消失
  voiceCallModelHidden(e){
    this.isShowVoiceCallModel = false;
  }
  Modeldismiss(event){
    this.isShowPositionPushModel =false;
    this.config.batchList=[];
  }
  //去简历详情
  gotoDetail(n){
    let that = this;
    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.config.isNavActive='college';
        this.config.isNavChildActive='college-resume';
        this.router.navigate(['home/college-resumedetails'], {
          queryParams: {
            resumeid:n.resume_number,
          }
        });
      } else {
        that.opera.checkMemberAlertWithType("goBuy")
      }
    })
  }
}
