import {Component, Input, Output, OnInit, OnDestroy,ViewChild} from '@angular/core';
import { DataApi,Local,Opera,Constants,Config } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {NavigationEnd, Router} from '@angular/router';
import {NoneVipAlertComponent} from "../none-vip-alert/none-vip-alert.component";
import { MatDialog } from "@angular/material/dialog";
import { Events } from '../../provider/eventService';
import {ModelSendMsgComponent} from "../model-send-msg/model-send-msg.component";
import {regexManager} from "../../provider/regexManager";
import {filter} from "rxjs/operators";
import {productManager} from "../../provider/productManager";
import {VoiceCallModelComponent} from "../voice-call-model/voice-call-model.component";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
@Component({
  selector: 'app-college-milk-air-space',
  templateUrl: './college-milk-air-space.component.html',
  styleUrls: ['./college-milk-air-space.component.css']
})
export class CollegeMilkAirSpaceComponent implements OnInit {

  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true }) 
  productModel: ProductModelComponent;

  @Output() isShowVoiceCallModel = false;//是否打开弹窗(简历收件箱)
  @ViewChild('voiceCallModel', { static: true }) voiceCallModel: VoiceCallModelComponent;

  @ViewChild('sengMsgModel', { static: true }) sengMsgModel: ModelSendMsgComponent;
  @Output() modelShowSengMsg = false;//是否打开单品发送极速短信
  @Output() SengMsgModel ={
    userName:'',
    userPic:'',
    talentid:'',
    graduation_school:'',//学校
    major_text:'',//专业
    gender:'',
    address:'',
    positionid:''
  };//打开单品发送极速短信用户对象
  @Input()  isShow: boolean;//是否显示弹窗页面
  // 筛选条件--搜索
  public model={
    online:false,
    is_delivery:false,
    is_view:false,
    major:'',//专业
    education:'',//学历
    collegeid:'',
    offset:0,
    limit:12,
    status:'all'
  }
  public phoneModel={
    myPhone:'', //企业拔打电话的手机
    myTel:'',
    myTel_qu:'',
    isOkmyPhone:false,
    NetPhone:'',
    positionid:'',
    getPhoneType:'1',
    PhoneModelType: 0,
    MyPhoneTime:0,
    MyPushCount:0,
    isShowPhoneModel:false,
    jiduCode:'',
    positionPushCode:'',
  }
  public currentUser:any//极速电话联系用， 用户对象
  public Interval1:any;
  public Interval2:any;
  public config: Config;
  public allCollege = [];
  public educationList=[];//学历列表
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  public majorOne='';//专业类别一级
  public positionList=[];
  public resumeList = [];//简历列表
  public countList = 0;//列表数量
  public arrageList = [];//弹幕 学生进场信息
  public myDepartment:any;//当前登录的企业信息(department)
  public milkId='';
  public milk:any;//双选会详情
  public isMilkEnd=false;//双选会是否已经结束
  public isMilkStart=false;//双选会是否已经开始
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public dialog: MatDialog,
    public regexManager:regexManager,
    public events : Events,
    public productManager: productManager,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event:NavigationEnd) => {
        if (event.url === '/home/college-milk-air-space') {
          this.opera.initConfig('nav-activity-air','college-milk-air-space');
          this.milkId = this.local.get(Constants.mrid);
          this.getMilkDetails();//获取双选会详情
          this.getMajorList();//获取专业列表信息
          this.getDepartment();
          this.getPositionList();
          this.getEducationList();//获取学历列表信息
          this.getResumeList();//获取简历列表
          this.getMilkBarrage();//获取弹幕列表
          this.getAllCollege();
          this.getProductList();
        }
      });
    }

  ngOnInit() {
    console.log('this.model.major',this.model.major);
    this.opera.initConfig('nav-activity-air','college-milk-air-space');
/*    this.Interval1 = setInterval(()=>{
      this.getMilkBarrage();//每隔20s获取一次弹幕列表
    },20000)

    this.Interval2 = setInterval(()=>{
      this.getChildEvent(-2);
    },5000)*/
  }
  ngOnDestroy(){
    clearInterval(this.Interval1)
    clearInterval(this.Interval2)
  }
  //-----跳转简历详情
  goDetails(n){
    console.log(n,this.milk);
    if(this.milk.status&&this.milk.status=='passed'){
      this.router.navigate(['home/college-resumedetails'], {
        queryParams: {
          resumeid:n.resume_number,
          positionid:'',
          seat_number:n.seat_number,
          mrid:this.model.status=='is_delivery'?this.milk.mrid:""
        }
      });
    }else{
      this.toastr.warning("很抱歉，您未通过当前双选会的审核，暂时无法使用此功能");
    }
 }
  selectStu(action){
      if(action == this.model.status) {

      }else{
        this.model.status = action;
        if(action == 'all'){
          this.model.is_view = false;
          this.model.is_delivery = false
        }else if(action == 'is_view'){
          this.model.is_view = true;
          this.model.is_delivery = false
        }else if(action == 'is_delivery'){
          this.model.is_view = false;
          this.model.is_delivery = true
        }
        this.getResumeList();
      }
  }
 //收藏
 goCollect(val){
  this.opera.manageResumeArchived(val);
}

  //获取双选会详情
  getMilkDetails(){
    this.dataApi.getMilkDetails(this.milkId).then((res:any)=>{
      this.milk=res;
      let timeNow=new Date().getTime();
      let time=new Date(this.milk.start_time).getTime();
      let timeOne=new Date(this.milk.end_time).getTime();
      if(timeNow-time>0){
        this.isMilkStart=true;
        console.log('获取双选会详情',res,timeOne-timeNow);
        if(timeOne-timeNow<0){
          this.isMilkEnd = true;//双选会已结束
        }else {
          this.isMilkEnd = false;//双选会已结束
        }
      }else{
        this.isMilkStart=false;
      }
   }).catch((error)=>{
     //console.log(error);
   })
  }
  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----college-resume');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*12;
      this.getResumeList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*12;
        this.getResumeList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        //this.toastr.warning("当前为最后一页");
        if(this.Interval2){
          clearInterval(this.Interval2)
        }
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*12;
        this.getResumeList();//获取简历投递列表
      }
    }

  }
  //获取全部高校
  getAllCollege(){
    this.dataApi.getCollegeList({limit:999}).then((res:any)=>{
      let arr = [];
      for (let n of res.list)
      {
          arr.push(n);
      }
      this.allCollege = arr;
    }).catch((err)=>{
    })
  }
  //获取列表
  getResumeList(){
    this.opera.loading();
    this.dataApi.getMilkResumeList(this.milkId,this.model).then((res:any)=>{
      //console.log("进入会场获取简历列表---");
      //console.log(res);
      this.resumeList = res.list;
      for (let val of  this.resumeList)
      {
        let arr = [];
        // val.pro_skills->text: "人力资源/人才服务"
        // contrast_list: [{code: "020205", text: "猎头"}]
        val.yixiang = "";
        val.youshiArr = [];
        for (let item1 of val.pro_skills)
        {
          val.yixiang += item1.text+"    "
          if(item1.contrast_list){
            for (let youshi of item1.contrast_list)
            {
              val.youshiArr.push(youshi.text);
            }
          }
        }
      }
      this.countList=res.count;
      this.pageCount =Math.ceil(res.count/12);
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
    })
  }
  //获取弹幕列表
  getMilkBarrage(){
    this.dataApi.getMilkBarrage(this.milkId).then((res:any)=>{
      this.arrageList = res;
    }).catch((err)=>{
    })
  }
  //
  goUrl(){
    // window.open('http://air.goworkla.cn/index.html','_blank');
    window.open('http://air.goworkla.cn/company/','_blank');
  }

  //监听majorOne
  majorOneChange(){
    // //console.log("第一级专业值");
    // //console.log(this.majorOne);
    // //console.log("专业列表");
    // //console.log(this.config.major);
    this.majorListTwo=[];
    this.model.major='';
    for(let n of this.config.major){
      if(n.parentcode==this.majorOne){
        this.majorListTwo.push(n);
      }
    }
    this.model.major='';
    // //console.log("第二级专业列表");
    // //console.log(this.majorListTwo);
  }


  //提示先选一级专业
 isShowTwo(){
  if(!this.majorOne){
    this.toastr.warning("请先选择一级专业");
    return;
  }
 }

  //获取学历列表信息
  getEducationList(){
    if(this.dataApi.getEducationCode()){
      //console.log("获取学历列表本地存储-----college-resume");
      //console.log(this.dataApi.getEducationCode());
      this.educationList = this.dataApi.getEducationCode();
    }else{
      this.dataApi.getDictDate('education').then((res:any)=>{
        //console.log("获取学历列表-----college-resume");
        //console.log(res);
        this.educationList = res;
        this.dataApi.setEducationCode(res);
      }).catch((error)=>{
        //console.log(error);
      });
    }
  }

  //获取一级专业列表
  getMajorListOne(){
    for(let n of this.config.major){
      if(n.level==2){
        this.majorListOne.push(n);
      }
    }
  }

  //获取专业列表信息
  getMajorList(){
    if(this.dataApi.getMajorCode()){
      this.config.major = this.dataApi.getMajorCode();
      this.getMajorListOne();//获取一级专业列表
    }else{
      this.dataApi.getDictDate('major').then((res:any)=>{
        //console.log("获取专业列表-----college-resume");
        //console.log(res);
        this.config.major = res;
        this.getMajorListOne();//获取一级专业列表
        this.dataApi.setMajorCode(res);
      }).catch((error)=>{
        //console.log(error);
      });
    }
  }

  //确定拔打的手机//获取网络虚拟好
  checkMyPhone(){

    if(this.phoneModel.myPhone==''&&this.phoneModel.getPhoneType=='1')
    {
      this.toastr.warning("请输入手机号")
      return;
    }
    if(this.phoneModel.getPhoneType=='2')
    {
      if(this.phoneModel.myTel_qu=='')
      {
        this.toastr.warning("请输入座机区号")
        return;
      }
      if(this.phoneModel.myTel=='')
      {
        this.toastr.warning("请输入座机号码")
        return;
      }
    }
    else
    {
      if(!this.regexManager.isPhone(this.phoneModel.myPhone))
      {
        this.toastr.warning("手机号格式错误，请重新填写")
        return;
      }
    }

    if(this.phoneModel.positionid=='')
    {
      this.toastr.warning("请选择职位")
      return;
    }
    let data = {
      mobile:this.phoneModel.getPhoneType=='1'?this.phoneModel.myPhone:'',
      talentid:this.currentUser.talentid,
      contact_type:this.phoneModel.getPhoneType=='1'?'mobile':'tel',
      tel:this.phoneModel.getPhoneType=='1'?'':this.phoneModel.myTel_qu+''+this.phoneModel.myTel,
      positionid:this.phoneModel.positionid
    };
    // this.phoneModel.PhoneModelType = 1;
    // this.phoneModel.NetPhone = '999999999'
    this.dataApi.GetVirtualNumber(data).then((res:any)=>{
      console.log("GetVirtualNumber",res);
      if(res)
      {
        this.phoneModel.isOkmyPhone = true;
        this.phoneModel.PhoneModelType = 1;
        this.phoneModel.NetPhone = res.value;
        this.myDepartment.virtualnumber_mobile = this.phoneModel.myPhone;
        this.dataApi.setLocalDepartment(this.myDepartment);//将企业信息存到本地、全局变量
      }


    }).catch((err)=>{
      console.log("GetVirtualNumber",err);
      if(err == "您还没通过的合作申请") {
        this.opera.freeAttestation("通过工作啦认证才能查看全部学生，立即认证！");//提醒认证
      } else {
        this.toastr.error(err);
      }
      this.phoneModel.isShowPhoneModel = false;
    })

  }


  //获取在期职位列表
  getPositionList() {
    //console.log("在期职位列表获取线上数据--college-resumeDetails");
    return this.dataApi.getPositionList({
      limit: 100,
      offset: 0,
      status: 'release',
    }).then((res:any) => {
      if(res)
      {
        if (res.list.length == 0) {
          return;
        }
        for (let val of res.list) {
          let model = {text: '', code: ''};
          val.isFilterActive = false;
          model.code = val.post_type;
          model.text = val.post_type_text;
        }
        this.positionList = res.list;
        this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
      }

    }).catch((error) => {
      //console.log(error);
    });

  }
  //获取用户信息
  getUser(){
    this.dataApi.getUser().then((res:any)=>{
      if(res){
        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
        this.phoneModel.myPhone =res.mobile;
        console.log('获取用户信息',res)
      }
    }).catch((error)=>{
      this.toastr.error(error);
    });
  }
  //获取企业信息
  getDepartment(){

    this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息

      if(res){
        this.myDepartment = res;
        if(this.myDepartment.is_certified){
          let aduitTime = new Date(this.myDepartment.authentication_begintime).getTime() + 60*60*24*30*1000;
          let nowTime = new Date().getTime();
          // console.log(new Date(this.myDepartment.authentication_begintime),new Date(),aduitTime,new Date(this.myDepartment.authentication_begintime).getTime(),nowTime,2222);
        }
        this.initPhoneData(this.myDepartment);

        if(this.myDepartment.virtualnumber!=null)
        {
          if(this.myDepartment.virtualnumber.contact_type=='tel')
          {
            this.phoneModel.getPhoneType = '2';
            let qu =this.myDepartment.virtualnumber.tel.substring(0,4)
            let tel =this.myDepartment.virtualnumber.tel.substring(4,this.myDepartment.virtualnumber.tel.length)
            this.phoneModel.myTel_qu = qu;
            this.phoneModel.myTel = tel;
          }
          else
          {
            this.phoneModel.myPhone =  this.myDepartment.virtualnumber.mobile;
            this.phoneModel.getPhoneType = '1';
          }
        }
        else
        {
          this.getUser();
        }
        this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
      }
    }).catch((error)=>{
      this.toastr.error(error);
    });


  }
  //显示手机号弹窗
  ShowPhoneModel(n,event){
    event.stopPropagation();
    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.productManager.getProductUsableCount(this.config.voice_call,(count,isVip)=>{
          if(count==0||!isVip) {
            this.product('voice_call','recharge');
          } else {
            this.voiceCallModel.initData(n);
            this.isShowVoiceCallModel = true;
          }
        })
      } else {
        this.product('voice_call','recharge');
      }
    })
  }

  //处理初始化电话联系quota
  initPhoneData(deparment){
    this.productManager.getProductUsableCount(this.config.voice_call,(count)=>{
      this.phoneModel.MyPhoneTime = count;
    });
    this.productManager.getProductUsableCount(this.config.sms_bulkinvitation,(count)=>{
      this.phoneModel.MyPushCount = count;
    });
  }
  alertSengMSG(n,event){
    event.stopPropagation();
    let that  = this;

    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.productManager.getProductUsableCount(this.config.sms_bulkinvitation,(count,isVip)=>{
          if(count==0||!isVip) {
            // let activityRecordid_Nav =   this.local.get('activityRecordid_Nav');
            // this.config.payVipHistoryPage = {
            //   page:"空中双选会会场",
            //   isVip:isVip,
            //   pro:'职位推送'
            // };
            // that.local.set("single_category_code", this.config.ProductListCode.sms_bulkinvitation_code);
            // that.router.navigate(['home/tool-campus']);
            this.product('sms_bulkinvitation','recharge');
          } else {
            this.sengMsgModel.initData(1,[]);
            this.SengMsgModel.userName = n.talent_name;
            this.SengMsgModel.userPic = n.logo==null?'': n.logo;
            this.SengMsgModel.graduation_school = n.graduate_university;
            this.SengMsgModel.major_text = n.major_text;
            this.SengMsgModel.talentid = n.talentid;
            this.SengMsgModel.gender = n.gender;
            this.SengMsgModel.address = n.address_text;
            this.modelShowSengMsg =true;
          }
        })
      } else {
        //that.opera.checkMemberAlertWithType("noneVip_sms_bulkinvitation")
        this.product('sms_bulkinvitation','recharge');
      }
    })
  }

  Modeldismiss(event){
    this.modelShowSengMsg =false;
    this.config.batchList=[];
  }
  updataMyPhone(){
    this.phoneModel.PhoneModelType = 0;
  }
  getProductList(){
    //product_category:"recharge",recharge_category:''
    this.opera.loading();
    this.dataApi.departmentList({product_category:"recharge"}).then((res:any)=>{
      this.opera.loadiss();
      if(res){
        console.log('获取产品列表',res)
        for (let item of res.list)
        {
          if(item.recharge_category == "voice_call")
          {
            this.phoneModel.jiduCode = item.code;
          }
          if(item.recharge_category == "sms_bulkinvitation")
          {
            this.phoneModel.positionPushCode = item.code;
          }
        }
        // this.productList = res.list;
      }
    }).catch((err)=>{
      console.log(err);
      this.opera.loadiss();
    })
  }
  //极速电话联系弹窗消失
  voiceCallModelHidden(e){
    this.isShowVoiceCallModel = false;
  }

  productModelHidden(event){    
    this.isproductShowModel = false;
  }

  //点击产品
  product(alias,type){
    this.productModel.initData(alias,type);
    this.isproductShowModel = true;
  }

}
