<div class="college-block">

    <app-voice-call-model #voiceCallModel [isShow]="isShowVoiceCallModel" (modelHidden)="voiceCallModelHidden($event)"></app-voice-call-model>
    <app-model-send-msg #sengMsgModel [SengMsgModel]='SengMsgModel' [tconfig]='config' (onVoted)="Modeldismiss($event)" [isShow]="isShowPositionPushModel"></app-model-send-msg>

    <div class="headerReturn">
        <app-navigation-route></app-navigation-route>
    </div>
    <div class="activityRecordid_Name">{{activityRecordid_Name}}</div>

    <div class="resume-list">

        <div class="resume-list-item" *ngFor="let n of list;let i = index;" id={{i}} (click)="gotoDetail(n)">
            <div class="rightBtn">
                <div class="item active" (click)="voiceCallClick($event,n)"><img src="/assets/images/v3/speedIcon.png">极速电话联系</div>
                <div class="item" (click)="positionPushClick($event,n)" title="可以将职位定向推送给该学生"><img src="/assets/images/v3/pushIcon.png">职位推送</div>
            </div>
            <div class="main">
                <div class="introduce">
                    <div class="logo">
                        <img *ngIf="n.logo" class="headPic" src="{{n.logo}}">
                        <img *ngIf="!n.logo&&n.gender=='01'" class="headPic" src="../../assets/images/male.png">
                        <img *ngIf="!n.logo&&n.gender=='02'" class="headPic" src="../../assets/images/female.png">
                    </div>
                    <div class="text">
                        <div class="name">
                            {{n.talent_name}}
                            <span *ngIf="n.isview_resume">已查看</span>
                            <span class="new_telent" *ngIf="n.new_talent">新用户</span>
                            <span class="new_telent" *ngIf="n.is_called">已联系</span>
                            <span class="new_telent" *ngIf="n.is_push">已推送</span>
                        </div>
                        <div class="college">{{n.graduation_school}}</div>
                        <div class="major">{{n.major_text}} | {{n.education_text}} | {{n.year==0?"往届":n.year+'届'}}</div>
                    </div>

                </div>
                <div class="lable">
                    <span *ngFor="let youshi of n.youshiArr | slice:0:4;let i = index;">{{youshi}}</span>
                </div>
                <div class="intention">
                    <span>求职意向：</span>{{n.yixiang}}
                </div>
                <div class="bottom">
                    <div class="bottom-item">推送活动<span>{{activityRecordid_Name}}</span></div>
                    <div class="bottom-item active">
                        <img src="/assets/images/rights/time.png">推送时间：{{n.invite_time|date:'yyyy-MM-dd HH:mm'}}
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="company-block" style="display:none">
        <div style="width: 100%;height: 44px;line-height: 44px;color: #333333;font-size: 20px">{{activityRecordid_Name}}</div>
        <div class="tableView">
            <div style="overflow: hidden" *ngFor="let n of list;let i = index;" id={{i}} (click)="gotoDetail(n)">
                <div class="listCellContent">
                    <div class="row_col1 row_height">
                        <div class="row_col1_userInfo">
                            <img *ngIf="n.logo" class="headPic" src="{{n.logo}}">
                            <img *ngIf="!n.logo&&n.gender=='01'" class="headPic" src="../../assets/images/male.png">
                            <img *ngIf="!n.logo&&n.gender=='02'" class="headPic" src="../../assets/images/female.png">
                            <ul>
                                <li class="userName">{{n.talent_name}}</li>
                                <li><span class="zhiwei">求职意向：{{n.yixiang}}</span></li>
                            </ul>
                        </div>
                        <div style="width: 100%;height: 25px">
                            <span class="youshi" *ngFor="let youshi of n.skills | slice:0:4;">{{youshi.text}}</span>
                        </div>
                    </div>
                    <div class="row_col2 row_height">
                        <ul class="collegeInfo">
                            <li><img src="../../assets/images/record_list1.png">{{n.graduation_school}}</li>
                            <li><img src="../../assets/images/record_list2.png">{{n.major_text}}</li>
                            <li><img src="../../assets/images/record_list3.png">{{n.education_text}}</li>
                            <li><img src="../../assets/images/jiebieIcon.png">{{n.year==0?"往届":n.year+'届'}}</li>
                        </ul>
                    </div>

                    <div class="row_col4 row_height">
                        <a (click)="voiceCallClick($event,n)" class="act-btn purple bordeRadius"><img src="../../assets/images/talent/phone.png" />极速电话联系</a>
                        <a (click)="positionPushClick($event,n)" title="可以将职位定向推送给该学生" class="act-btn yellow bordeRadius"><img src="../../assets/images/talent/pulish.png" />职位推送</a>
                    </div>

                    <div class="row_col3 row_height">
                        <img style="width:27px;height:100px;position: absolute" src="../../assets/images/record_line.png">
                        <ul class="collegeInfo callText">
                            <li>活动名称： {{activityRecordid_Name}}</li>
                            <li>推送时间：{{n.invite_time|date:'yyyy-MM-dd HH:mm'}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class='paging-block' [class.isPageHeight]="countList>9">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>

    </div>
</div>