import { Component, OnInit } from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

declare let layui;
declare let Swiper:any;
@Component({
  selector: 'app-activity-invitation',
  templateUrl: './activity-invitation.component.html',
  styleUrls: ['./activity-invitation.component.css']
})
export class ActivityInvitationComponent implements OnInit {

  //区分双选会宣讲会
  public type = '';
  public model = {
        activityName:'',
        activityType:'',
        activityTime:'',
        activityId:'',
        college_name:'',
        college_id: '',
        college_logo:'',
        seat_number: '暂未分配'
  };
  public filtModel = {
    education:'',
    filtCount:0,
    count:0,
    college:'',
    majorOne:'',
    majorTwo:'',
    areaOne:'',
    areaTwo:'',
    is_curr_session:'',
    sex:''
  }
  public currentYuanxi = '';
  public MyActivityCount = 0;
  public SmsContent = '';

  time = false;
  public sending = false;
  public sendTime = 0;
  public allCollege = [];
  public yuanxi1 = [];
  public yuanxi2 = [];
  public yuanxi3 = [];
  public area1 = [];
  public area2 = [];
  public area3 = [];
  public seleteyuanxi = [];
  public seletecollege = [];
  public seletearea = [];
  public collengeAllCount = 0;
  public departmentDetail:any;
  public config: Config;

  public isShowPushModel = false;

  public isShowSeat_numberModel = false
  public seatModel = {
    seat:''
  }
  public currentSeat = '0'
  public collegeids = [];
  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService
  ) {

    this.config = Config.getInstance();  // 实例化config
  }

  ngOnInit() {
    let  activityInvitationData:any;
    activityInvitationData = this.local.get('activityInvitationData');
    activityInvitationData = JSON.parse(activityInvitationData);
    if(activityInvitationData) {
     this.type  = this.local.get('activityInvitationDataType');
     this.type =  this.type.replace("\"","");
     this.type =  this.type.replace("\"","");
     if(this.type == 'milkround') {
       console.log('双选会')
       this.model.activityId = activityInvitationData.mrid;
       this.model.activityName = activityInvitationData.name;
       this.model.activityType = '双选会';
       this.model.activityTime =  this.RiQi(activityInvitationData.start_time)
       this.model.college_name = activityInvitationData.college_name;
       this.model.college_id = activityInvitationData.collegeid;
       this.model.college_logo = activityInvitationData.logo;
       this.getCurrentSeat(activityInvitationData.mrid)
     } else {
       console.log('宣讲会')
       this.model.activityId = activityInvitationData.ctid;
       this.model.activityName = activityInvitationData.name;
       this.model.activityType = '宣讲会';
       this.model.activityTime =  this.RiQi(activityInvitationData.start_time);
       this.model.college_name = activityInvitationData.college_name;
       this.model.college_id = activityInvitationData.collegeid;
       this.model.college_logo = activityInvitationData.logo;
     }
     this.opera.initConfig('nav-activity',this.type == 'milkround'?'college-milkround':'college-campustalk1');
     console.log('activityInvitationData',activityInvitationData);
     this.getDepartment();
     this.getAllCollege();
     this.getMajorList();
     this.getAreaList();
   }
  }
  //时间戳转时间
  RiQi(sj) {
    var now = new Date(sj);
    var year=now.getFullYear();
    var month=now.getMonth()+1;
    var date=now.getDate();
    var hour=now.getHours()+'';
    var minute=now.getMinutes()+'';
    var second=now.getSeconds()+'';
    if(minute+''=='0') {
      minute = "00";
    }
    if(second+''=='0') {
      second = "00";
    }
    return   year+"年"+month+"月"+date +'日' +hour+':'+minute;
  }

  seletecollegeChange(){
    if(this.seletecollege.length>=5) {
      this.toastr.error('最多选择5个学校')
      return;
    }
    for (let n of this.seletecollege) {
      if(n.collegeid == this.filtModel.college) {
        return;
      }
    }
    for (let item of this.allCollege) {
      if(item.collegeid == this.filtModel.college) {
        this.seletecollege.push(item)
      }
    }
    this.smsDepartment();
  }
  multiSelectonChange(){
    this.smsDepartment();
  };
  //选择院系
  seleteyuanxiChange(n){
    this.filtModel.majorTwo = '';
    if(this.filtModel.majorOne=='') {
      this.seleteyuanxi = [];
      this.filtModel.majorTwo = '';
      this.yuanxi3 = [];
      this.smsDepartment();
      return;
    }
    let yuanxi3 = [];
    for (let yuanxi of this.yuanxi2) {
      if(yuanxi.parentcode == this.filtModel.majorOne) {
        yuanxi3.push(yuanxi)
      }
    }
    this.yuanxi3 = yuanxi3;
  }
  seleteareaChange(n){
    this.filtModel.areaTwo = '';
    if(this.filtModel.areaOne=='') {
      this.seletearea = [];
      this.filtModel.areaTwo = '';
      this.area3 = [];
      this.smsDepartment();
      return;
    }
    let area3 = [];
    for (let area of this.area2) {
      if(area.parentcode == this.filtModel.areaOne) {
        area3.push(area)
      }
    }
    this.area3 = area3;
  }
  //获取全部高校
  getAllCollege(){
    this.dataApi.getCollegeList({limit:999}).then((res:any)=>{
      let arr = [];
      for (let n of res.list) {
        if(n.total_student>=1000) {
          arr.push(n);
        }
      }
      this.allCollege = arr;
      if(this.model.college_id){
        for(let item of this.allCollege){
          if(this.model.college_id == item.collegeid){
            this.seletecollege.push(item);
          }
        }
      }
      this.smsDepartment();
    }).catch((err)=>{
    })
  }
  getParMajor(n){
    for(let item of this.yuanxi1){
      if(n.parentcode == item.code){
        return item.text+'-'+n.text
      }
    }
  }
  getParArea(n){
    for(let item of this.area1){
      if(n.parentcode == item.code){
        return item.text+'-'+n.text
      }
    }
  }
  seleteyuanxiChange2(){
    if(this.seleteyuanxi.length>=5) {
      this.toastr.error('最多选择5个院系')
      return;
    }
    for (let n of this.seleteyuanxi) {
      if(n.code == this.filtModel.majorTwo) {
        return;
      }
    }
    for (let n of this.yuanxi3) {
      if(n.code == this.filtModel.majorTwo) {
        this.seleteyuanxi.push(n);
        break;
      }
    }
    this.smsDepartment();
  }
  seleteareaChange2(){
    if(this.seletearea.length>=5) {
      this.toastr.error('最多选择5个地区')
      return;
    }
    for (let n of this.seletearea) {
      if(n.code == this.filtModel.areaTwo) {
        return;
      }
    }
    for (let n of this.area3) {
      if(n.code == this.filtModel.areaTwo) {
        this.seletearea.push(n);
        break;
      }
    }
    this.smsDepartment();
  }
  countBtnClick(type) {
    if(this.filtModel.count>=this.filtModel.filtCount &&type>0) {
      this.toastr.error('通知的学生大于符合条件的学生')
      this.filtModel.count = this.filtModel.filtCount
      return;
    }
    this.filtModel.count = parseInt(this.filtModel.count.toString())
    if(type>0) {
      this.filtModel.count+=1;
    } else {
      if(this.filtModel.count<=1) {
        return;
      }
      this.filtModel.count-=1;
    }
  }
  inputChange(e){
    console.log('e',e,this.filtModel.count)
    if(parseInt(e.toString())>this.filtModel.filtCount) {
      let that = this;
      this.toastr.error('通知的学生大于符合条件的学生')
      setTimeout(()=>{
        that.filtModel.count = that.filtModel.filtCount
      },400);
    }
  }
  //获取我的啦豆余额
//获取企业信息
  getDepartment(){
    this.opera.loading();
    this.dataApi.getDepartment().then((res:any)=> {
      this.opera.loadiss();
      if(res) {
        this.departmentDetail = res;
        this.getSmsContent();
      }
    }).catch((error) => {
      this.opera.loadiss();
      console.log(error);
      this.toastr.error(error);
    });
    this.dataApi.getcomponentLeft({}).then((res:any)=>{
      let arr = [];
      res.map((item)=>{
        if(item.alias == 'activity_invitation')
        {
          this.MyActivityCount = item.left;
        }
      })
    }).catch((err)=>{
      console.log(err);
    })
  }
  // 获取符合单品通知包使用的人数
  smsDepartment(){
    let facultyids = [];
    for(let n of this.seleteyuanxi) {
      facultyids.push(n.code);
    }
    let native_places = [];
    for(let n of this.seletearea) {
      native_places.push(n.code);
    }
    let model = {
      education:this.filtModel.education, // 学历
      gender:this.filtModel.sex, // 学历
      activityid:this.model.activityId,  // 会议id
      mc_type:this.type=='milkround'?1:2,  // 活动类型
      majors:facultyids, // 院系集合
      collegeids:this.collegeids ,  // 学校Id
      native_places:native_places
    }
    this.dataApi.getagregationquery(model).then((res:any)=>{
      if(res) {
        this.filtModel.filtCount = res.value;
        this.filtModel.count =  res.value;
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  yuanxiClick(data) {
    let arr = [];
    for (let n of this.seleteyuanxi) {
      if(n.code!=data.code) {
        arr.push(n);
      }
    }
    this.seleteyuanxi = arr;
    if(arr.length==0) {
      this.filtModel.majorTwo = '';
      this.filtModel.majorOne = '';
      this.yuanxi3 = [];
    }
    this.smsDepartment();
  }
  collegeClick(data) {
    let arr = [];
    for (let n of this.seletecollege) {
      if(n.collegeid!=data.collegeid) {
        arr.push(n);
      }
    }
    this.seletecollege = arr;
    if(arr.length==0) {
      this.filtModel.college = '';
    }
    this.smsDepartment();
  }
  areaClick(data) {
    let arr = [];
    for (let n of this.seletearea) {
      if(n.code!=data.code) {
        arr.push(n);
      }
    }
    this.seletearea = arr;
    if(arr.length==0) {
      this.filtModel.areaTwo = '';
      this.filtModel.areaOne = '';
      this.area3 = [];
    }
    this.smsDepartment();
  }
  commitAlert(){
    // if(this.type == 'milkround'){
    //   if(this.currentSeat == '0') {
    //     this.isShowSeat_numberModel = true
    //     return
    //   }
    // }
    this.isShowPushModel = true;
    this.getDepartment();
  }
  commit(){
    this.isShowPushModel = false;
    if((this.MyActivityCount-this.filtModel.count)<0) {
      this.opera._hmtPush('双选会','校招活动邀请余量','购买')
      let that = this;
      this.config.payVipHistoryPage = {
        page:'校招活动邀请',
        isVip:false,
        pro:'校招活动邀请'
      };
      that.local.set("single_category_code", this.config.ProductListCode.activity_invitation_code);
      that.router.navigate(['home/tool-campus']);
      return;
    }
    if(this.sending) {
      this.toastr.warning('正在发送中');
      return;
    }
    if(this.sendTime!=0) {
      this.toastr.warning('60秒不能重复发送通知');
      return;
    }
    if(this.filtModel.count==0||this.filtModel.filtCount==0) {
      this.toastr.error('通知的学生数量不能为0')
      return;
    }
    let facultyids = [];
    for(let n of this.seleteyuanxi) {
      facultyids.push(n.code);
    }
    let native_places = [];
    for(let n of this.seletearea) {
      native_places.push(n.code);
    }
    if(this.collegeids.length==0) {
      this.toastr.error('请选择高校')
      return;
    }
    let model = {
      education:this.filtModel.education, // 学历
      activityid:this.model.activityId,  // 会议id
      majors:facultyids, // 院系集合
      collegeids:this.collegeids,    // 学校Id
      invite_count:this.filtModel.count,      // 发送数量 默认全发
      mc_type:this.type,
      native_places:native_places,
      gender:this.filtModel.sex,
      seat_number:this.currentSeat
    }
    // if(this.seatModel.seat){
    //   this.currentSeat = this.seatModel.seat
    // }
    this.sending = true;
    let _that = this;
    _that.dataApi.smsSendDepartment2(model).then((res:any)=>{
      _that.toastr.success('活动通知发送成功');
      _that.sendTime = 1;
      _that.sending = false;
      let timename = setInterval(()=>{
        _that.sendTime+=1;
        if(_that.sendTime==60) {
          _that.sendTime=0;
          clearInterval(timename)
        }
      },1000);
      _that.getDepartment();
    }).catch((err)=>{
      console.log(err);
      _that.toastr.error(err)
      _that.sending = false;
    })
  }
  //获取模版内容
  getSmsContent(){
    this.dataApi.getSmsMban( this.type=='milkround'?'活动邀请-双选会':'活动邀请-宣讲会').then((res:any)=>{
      console.log("短信模版",res.content,this.departmentDetail)
      if(res) {
        this.SmsContent = res.content;
        if(this.departmentDetail) {
          this.SmsContent = this.SmsContent.replace('{公司名称}',this.departmentDetail.name)
          this.SmsContent = this.SmsContent.replace('{举办时间}',this.model.activityTime)
          console.log('this.departmentDetail.seat_number',this.departmentDetail.seat_number);
          this.SmsContent = this.SmsContent.replace('{展位号}',this.currentSeat)
        }
      }
    }).catch((err)=>{
      console.log(err);
    })
  }

  //区域字典
  getAreaList(){
    this.dataApi.getDictDate('region').then((res:any)=>{
      if(res){
        for(let i of res){
          if(i.level===1){
            this.area1.push(i)
          }else if(i.level===2){
            this.area2.push(i)
          }
        }
        let arr = [];
        for (let n of this.area1) {
          let item = {
            code: n.code,
            level: 2,
            order: 0,
            parentcode: n.code,
            parenttext: null,
            relation_code: null,
            relation_desc: "",
            text: n.text+'-全省',
          };
          arr.push(item);
        }
        this.area2 = [...arr,...this.area2]
      }
    })
  }
  //获取专业列表信息
  getMajorList(){
    this.opera.loading();
     let data  = {
       collegeids:''
     }
    this.dataApi.getagregationCount(data).then((res:any)=>{
          if(res) {
            this.collengeAllCount = res.count;
            let arr = [];
            let arr2 = [];
            for (let n of res.facetgroups) {
              if(n.level==1) {
                arr.push(n);
              } else {
                  arr2.push(n);
              }
            }
            this.yuanxi1 = arr;
            this.yuanxi2 = arr2;
            let allArr = [];
            for (let n of this.yuanxi1) {
              let data = {
                code: n.code,
                count: n.count,
                level: 2,
                parentcode: n.code,
                parentcode_text:  n.text,
                text: "全部专业",
              }
              allArr.push(data);
            }
            this.yuanxi2 = [...allArr,...this.yuanxi2]
          }
    }).catch((error)=>{
      //console.log(error);
      this.opera.loadiss();
    });

  }
  back(){
    if(this.type=='milkround') {
      this.opera.goNewRouter('college-milkround')
    } else {
      this.opera.goNewRouter('position-campus')
    }
  }
  //推送展位号
  seatPush(){
    if(!this.seatModel.seat){
      return
    }
    var reg=/^[0-9a-zA-Z]{1,}$/;
    if(!reg.test(this.seatModel.seat)){
      this.toastr.warning('展位号不规范,展位号由数字或字母组成')
      return
    }
    this.isShowSeat_numberModel = false
    this.isShowPushModel = true;
    this.getDepartment();
  }
  closrSeatModel(){
    this.isShowSeat_numberModel = false
  }
  // 获取当前双选会的展位号
  getCurrentSeat(mrid) {
    this.dataApi.getMilkDetailsWithDepartment(mrid).then((res:any) => {
      if (res) {
        console.log("获取到招聘会详情22", res);
        if (res.seat_number) {
          this.currentSeat = res.seat_number + ''
        }else{
          this.currentSeat = '暂未分配'
        }
      }
    }).catch((error) => {
      this.opera.loadiss();
    })
  }

}
