import { Component, OnInit } from '@angular/core';
import {DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-buy-explain',
  templateUrl: './buy-explain.component.html',
  styleUrls: ['./buy-explain.component.css']
})
export class BuyExplainComponent implements OnInit {

  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService
  ) { }

  ngOnInit() {
    let topNav = this.local.get('nav_tool')
    this.opera.initConfig('tool',topNav);
  }

}
