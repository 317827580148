<div class="homepage-block">
    <router-outlet></router-outlet>
    <app-model-still-more #child1 [isShow]="modelMajorShow" (onVoted)="Modeldismiss($event)"></app-model-still-more>

    <div class="headerReturn">
        <div class="center" (click)="opera.goPre()">
            <img src="/assets/images/v3/headerReturnIcon.png">空中宣讲会
        </div>
    </div>

    <div class="airCampusBox">
        <div class="top">
            <div class="logo"><img src="/assets/images/v3/airCampusIcon.png"></div>
            <div class="rt">
                <div class="name">空中宣讲会</div>
                <div class="text">宣讲不用逐校跑，线上一站精准搞</div>
            </div>
        </div>
        <!--单位名称-->
        <div class="module active fn">
            <div class="_left">单位名称</div>
            <div class="_right">
                <div class="text">{{model.department_name}}</div>
            </div>
        </div>

        <!--宣讲主题-->
        <div class="module active">
            <div class="_left">宣讲主题</div>
            <div class="_right">
                <div class="val">
                    <input placeholder="请输入宣讲主题" [disabled]="isDisabled" [ngClass]="{row_err_border:model.title.err!='',row_success_border:model.title.err==''}" [(ngModel)]="model.title.value" (ngModelChange)="inputChangeWithOpen($event,'title')" />
                </div>
            </div>
        </div>

        <!--宣讲会介绍-->
        <div class="module">
            <div class="_left">宣讲会介绍</div>
            <div class="_right">
                <div class="textarea">
                    <textarea placeholder="请输入宣讲会介绍" [disabled]="isDisabled" [(ngModel)]="model.desc.value" (ngModelChange)="inputChangeWithOpen($event,'desc')" [ngClass]="{row_err_border:model.desc.err!='',row_success_border:model.desc.err==''}"></textarea>
                </div>
            </div>
        </div>

        <div class="module">
            <div class="_left">招聘职位</div>
            <div class="_right cell">
                <div class="item active" [ngClass]="{positionBox_item:n.selete,positionBox_item_none:!n.selete}" (click)="positionClick(n)" *ngFor="let n of positionList;let i = index;">{{n.name}}</div>
                <div class="push" (click)="creatPositionClick()"><img src="/assets/images/v3/pushposition.png">添加</div>
            </div>
        </div>

        <!--意向专业-->
        <div class="module active">
            <div class="_left">意向专业</div>
            <div class="_right cell">
                <div class="item" (click)="goMajor()" *ngFor="let n of model.majors;let i = index;">{{n | codetransform:config.major}}</div>
                <div class="push" (click)="goMajor()"><img src="/assets/images/v3/pushposition.png">添加</div>
            </div>
        </div>

        <!--视频链接-->
        <div class="module active">
            <div class="_left">视频链接</div>
            <div class="_right">
                <div class="val">
                    <input [disabled]="isDisabled" placeholder="请上传视频并粘贴视频url地址" [(ngModel)]="model.video_url.value" (ngModelChange)="inputChangeWithOpen($event,'video_url')" />
                </div>
                <div class="requiredText" (click)="gowoUploadChip()"><img src="/assets/images/v3/video_url.png">如何上传视频链接</div>
            </div>
        </div>

        <!--上传活动封面-->
        <div class="module active">
            <div class="_left">上传活动封面</div>
            <div class="_right">
                <div class="upImgbox">
                    <div class="box">
                        <label class="uploadImg-label" for="uploadLoginAvatar">
                          <img class="_img" *ngIf="model.video_cover.value" [src]="model.video_cover.value">
                          <img class="upImg" *ngIf="!model.video_cover.value" src="/assets/images/v3/pushposition.png">
                      </label>
                        <input class="uploadImg" [disabled]="isDisabled" id="uploadLoginAvatar" (change)="previewPic($event)" type="file" accept="image/jpeg,image/jpg,image/png" />
                    </div>
                    <p>点击上传</p>
                    <p>建议宽高500*300PX</p>
                </div>
            </div>
        </div>

        <!--设置常见问题-->
        <div class="module active">
            <div class="_left">设置常见问题</div>
            <div class="_right">
                <div class="problem" *ngFor="let n of model.QuestionList;let i = index;">
                    <div class="val">
                        <input [(ngModel)]="n.question" [disabled]="isDisabled" (ngModelChange)="inputProblem($event,n)" placeholder="请输入问题名称" />
                        <img *ngIf="i!=0" (click)="deleteProblem(n,i)" title="删除问题" class="delete" src="/assets/images/v3/delete_line.png">
                    </div>
                    <div class="textarea">
                        <textarea [(ngModel)]="n.answer" [disabled]="isDisabled" placeholder="请输入问题答案"></textarea>
                    </div>
                </div>
                <div class="addProblem" (click)="addProblem()"><img src="/assets/images/v3/pushposition.png">添加问题</div>
            </div>
        </div>

        <div class="contacttitle">
            <div class="name">联系方式</div>
            <div class="tip">请核对完善确认您的联系方式，以便学生能够在投递简历之后，与您咨询沟通！</div>
        </div>

        <!-- 联系电话 -->
        <div class="module b">
            <div class="_left">联系电话</div>
            <div class="_right">
                <div class="val">
                    <input placeholder="请填写联系电话" [(ngModel)]="model.mobile.value" (ngModelChange)="inputChangeWithOpen($event,'mobile')" />
                </div>
            </div>
            <div class="rightBtn">
                <div class="item" (click)="changeOpen('mobile',true)" [ngClass]="{openPhone_d_s:model.mobile.open,openPhone_d:!model.mobile.open}">
                    <div class="choice">
                        <span></span>
                    </div>
                    公开
                </div>
                <div class="item" [ngClass]="{openPhone_d:model.mobile.open,openPhone_d_s:!model.mobile.open}" (click)="changeOpen('mobile',false)">
                    <div class="choice">
                        <span></span>
                    </div>
                    不公开
                </div>
            </div>
        </div>

        <!-- 企业电话 -->
        <div class="module b">
            <div class="_left">企业电话</div>
            <div class="_right">
                <div class="val">
                    <input placeholder="请填写企业电话" [(ngModel)]="model.phone.value" (ngModelChange)="inputChangeWithOpen($event,'phone')" />
                </div>
            </div>
            <div class="rightBtn">
                <div class="item" (click)="changeOpen('phone',true)" [ngClass]="{openPhone_d_s:model.phone.open,openPhone_d:!model.phone.open}">
                    <div class="choice">
                        <span></span>
                    </div>
                    公开
                </div>
                <div class="item" (click)="changeOpen('phone',false)" [ngClass]="{openPhone_d:model.phone.open,openPhone_d_s:!model.phone.open}">
                    <div class="choice">
                        <span></span>
                    </div>
                    不公开
                </div>
            </div>
        </div>

        <!-- 微信号 -->
        <div class="module b">
            <div class="_left">微信号</div>
            <div class="_right">
                <div class="val">
                    <input placeholder="请填写微信号" [(ngModel)]="model.wx.value" (ngModelChange)="inputChangeWithOpen($event,'wx')" />
                </div>
            </div>
            <div class="rightBtn">
                <div class="item" (click)="changeOpen('wx',true)" [ngClass]="{openPhone_d_s:model.wx.open,openPhone_d:!model.wx.open}">
                    <div class="choice">
                        <span></span>
                    </div>
                    公开
                </div>
                <div class="item" (click)="changeOpen('wx',false)" [ngClass]="{openPhone_d:model.wx.open,openPhone_d_s:!model.wx.open}">
                    <div class="choice">
                        <span></span>
                    </div>
                    不公开
                </div>
            </div>
        </div>

        <!-- 邮箱 -->
        <div class="module b">
            <div class="_left">邮箱</div>
            <div class="_right">
                <div class="val">
                    <input placeholder="请填写邮箱" [(ngModel)]="model.email.value" (ngModelChange)="inputChangeWithOpen($event,'email')" />
                </div>
            </div>
            <div class="rightBtn">
                <div class="item" (click)="changeOpen('email',true)" [ngClass]="{openPhone_d_s:model.email.open,openPhone_d:!model.email.open}">
                    <div class="choice">
                        <span></span>
                    </div>
                    公开
                </div>
                <div class="item" (click)="changeOpen('email',false)" [ngClass]="{openPhone_d:model.email.open,openPhone_d_s:!model.email.open}">
                    <div class="choice">
                        <span></span>
                    </div>
                    不公开
                </div>
            </div>
        </div>


        <div class="blueStreamer commit" (click)="commitBtnClick()">提交</div>
        <div class=" commitChips">提交成功后，会在1-3个工作日审核</div>


    </div>



    <div class="box" style="display: none;">

        <div class="Row flex_l">
            <div class="Row_l flex_r">宣讲主题：</div>
            <input class="l_input" placeholder="请输入宣讲主题" [disabled]="isDisabled" [ngClass]="{row_err_border:model.title.err!='',row_success_border:model.title.err==''}" [(ngModel)]="model.title.value" (ngModelChange)="inputChangeWithOpen($event,'title')" />
            <div class="row_err" *ngIf="model.title.err!=''">{{model.title.err}}</div>
        </div>

        <div class="Row flex_l">
            <div class="Row_l flex_r">单位名称：</div>
            <input class="l_input" placeholder="请输入单位名称" disabled [(ngModel)]="model.department_name" />
        </div>

        <div class="Row flex_l">
            <div class="Row_l flex_r">宣讲会介绍：</div>
            <textarea class="textAirInput" placeholder="请输入宣讲会介绍" [disabled]="isDisabled" [(ngModel)]="model.desc.value" (ngModelChange)="inputChangeWithOpen($event,'desc')" [ngClass]="{row_err_border:model.desc.err!='',row_success_border:model.desc.err==''}"></textarea>
            <div class="row_err" *ngIf="model.desc.err!=''">{{model.desc.err}}</div>
        </div>

        <div class="Row flex_l">
            <div class="Row_l flex_r">招聘职位：</div>
            <div class="Row_r ">
                <div class="flex_l positionBox">
                    <div class="" [ngClass]="{positionBox_item:n.selete,positionBox_item_none:!n.selete}" (click)="positionClick(n)" *ngFor="let n of positionList;let i = index;">{{n.name}}</div>
                    <div class="creatPosition flex_c" (click)="creatPositionClick()"><img src="../../assets/images/addAirCamp.png">添加职位</div>
                </div>
            </div>
            <div class="row_err" *ngIf="modelErr.positionErr!=''">{{modelErr.positionErr}}</div>
        </div>

        <div class="Row flex_l">
            <div class="Row_l flex_r">意向专业：</div>
            <div class="Row_r ">
                <div class="flex_l positionBox">
                    <div class="yixiang_item" (click)="goMajor()" *ngFor="let n of model.majors;let i = index;">{{n | codetransform:config.major}}</div>
                    <div class="creatPosition flex_c" (click)="goMajor()"><img src="../../assets/images/addAirCamp.png">添加意向</div>
                </div>
            </div>
            <div class="row_err" *ngIf="modelErr.major!=''">{{modelErr.major}}</div>

        </div>

        <div class="Row flex_l">
            <div class="Row_l flex_r"><img (click)="gowoUploadChip()" src="../../assets/images/airInfo.png">请上传视频链接：</div>
            <input class="s_input" [disabled]="isDisabled" placeholder="请上传视频并粘贴视频url地址" [(ngModel)]="model.video_url.value" (ngModelChange)="inputChangeWithOpen($event,'video_url')" />
            <div class="Row_chips" (click)="gowoUploadChip()"><span style="color: red">*</span>&nbsp;如何上传视频链接</div>
            <div class="Row_chips" style="color: #999999">请上传https为开头的腾讯视频链接</div>

            <div class="row_err" *ngIf="model.video_url.err!=''">{{model.video_url.err}}</div>

        </div>

        <div class="Row flex_l">
            <div class="Row_l flex_r">请上传视频封面：</div>
            <div class="Row_r">
                <label class="r_seleteImage_image" for="uploadLoginAvatar">
                  <img *ngIf="!model.video_cover.value" class="r_seleteImage_image" src="../../assets/images/addVideoCover.png" />
                  <img *ngIf="model.video_cover.value" class="r_seleteImage_image" src="{{model.video_cover.value}}" />
                </label>
                <input class="r_seleteImage" [disabled]="isDisabled" id="uploadLoginAvatar" (change)="previewPic($event)" type="file" accept="image/jpeg,image/jpg,image/png" />
            </div>
            <div class="Row_chips" style="color: #999999">建议上传 宽高500*300px封面图</div>

            <div class="row_err" *ngIf="model.video_cover.err!=''">{{model.video_cover.err}}</div>
        </div>

        <div class="Row flex_l">
            <div class="Row_l flex_r">请设置常见问题：</div>
            <div class="Row_r flex_l" *ngFor="let n of model.QuestionList;let i = index;">
                <input class="q_input" [(ngModel)]="n.question" [disabled]="isDisabled" (ngModelChange)="inputProblem($event,n)" placeholder="请输入问题名称" />
                <div *ngIf="i==0" class="addQution flex_c" (click)="addProblem()"><img src="../../assets/images/addAirCamp.png" />添加</div>
                <div *ngIf="i!=0" class="addQution flex_c" (click)="deleteProblem(n,i)"><img src="../../assets/images/delete_pro.png" />删除</div>
                <textarea class="q_input_wenda" [(ngModel)]="n.answer" [disabled]="isDisabled" placeholder="请输入问题答案"></textarea>
            </div>
        </div>


        <div style="font-size: 12px;color: #999999;text-align: center;margin-bottom: 15px">请核对完善确认您的联系方式，以便学生能够在投递简历之后，与您咨询沟通！
        </div>

        <div class="Row flex_l">
            <div class="Row_l flex_r">联系电话：</div>
            <input class="s_input" placeholder="请填写联系电话" [(ngModel)]="model.mobile.value" (ngModelChange)="inputChangeWithOpen($event,'mobile')" />
            <div class="flex_l">
                <div class="flex_l openPhone" (click)="changeOpen('mobile',true)"><span [ngClass]="{openPhone_d_s:model.mobile.open,openPhone_d:!model.mobile.open}"></span>公开</div>
                <div class="flex_l openPhone" (click)="changeOpen('mobile',false)"><span [ngClass]="{openPhone_d:model.mobile.open,openPhone_d_s:!model.mobile.open}"></span>不公开</div>
            </div>
            <div class="row_err" *ngIf="model.mobile.err!=''">{{model.mobile.err}}</div>
        </div>

        <div class="Row flex_l">
            <div class="Row_l flex_r">企业电话：</div>
            <input class="s_input" placeholder="请填写企业电话" [(ngModel)]="model.phone.value" (ngModelChange)="inputChangeWithOpen($event,'phone')" />
            <div class="flex_l">
                <div class="flex_l openPhone" (click)="changeOpen('phone',true)"><span [ngClass]="{openPhone_d_s:model.phone.open,openPhone_d:!model.phone.open}"></span>公开</div>
                <div class="flex_l openPhone" (click)="changeOpen('phone',false)"><span [ngClass]="{openPhone_d:model.phone.open,openPhone_d_s:!model.phone.open}"></span>不公开</div>
            </div>
            <div class="row_err" *ngIf="model.phone.err!=''">{{model.phone.err}}</div>
        </div>

        <div class="Row flex_l">
            <div class="Row_l flex_r">微信号：</div>
            <input class="s_input" placeholder="请填写微信号" [(ngModel)]="model.wx.value" (ngModelChange)="inputChangeWithOpen($event,'wx')" />
            <div class="flex_l">
                <div class="flex_l openPhone" (click)="changeOpen('wx',true)"><span [ngClass]="{openPhone_d_s:model.wx.open,openPhone_d:!model.wx.open}"></span>公开</div>
                <div class="flex_l openPhone" (click)="changeOpen('wx',false)"><span [ngClass]="{openPhone_d:model.wx.open,openPhone_d_s:!model.wx.open}"></span>不公开</div>
            </div>
            <div class="row_err" *ngIf="model.wx.err!=''">{{model.wx.err}}</div>
        </div>

        <div class="Row flex_l">
            <div class="Row_l flex_r">邮箱：</div>
            <input class="s_input" placeholder="请填写邮箱" [(ngModel)]="model.email.value" (ngModelChange)="inputChangeWithOpen($event,'email')" />
            <div class="flex_l">
                <div class="flex_l openPhone" (click)="changeOpen('email',true)"><span [ngClass]="{openPhone_d_s:model.email.open,openPhone_d:!model.email.open}"></span>公开</div>
                <div class="flex_l openPhone" (click)="changeOpen('email',false)"><span [ngClass]="{openPhone_d:model.email.open,openPhone_d_s:!model.email.open}"></span>不公开</div>
            </div>
            <div class="row_err" *ngIf="model.email.err!=''">{{model.email.err}}</div>
        </div>

        <div class="flex_c commit" (click)="commitBtnClick()">提交</div>
        <div class="flex_c commitChips"><span style="color: red">*</span>&nbsp;提交成功后，会在1-3个工作日审核</div>
        <!--<div class="flex_c commitChips" style="color: #5E4FFF;  margin-bottom: 60px;cursor: pointer">免责声明</div>-->

    </div>

</div>

<!--免责声明-->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px'}" [(visible)]="mianzeModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':800,'height':700,'minHeight':700}"
    [modal]=true>
    <div class="mianzeBox">
        <div class="mianzeBox_title">免责声明</div>
        <div class="mianze_h1">您正在使用的是「工作啦」校园招聘空中双选会模块——宣讲视频上传功能。 您所上传的宣讲视频将以空中宣讲会的形式在高校毕业生面前进行展示，请您务必注意宣讲视频的真实性、完整性、合法性。</div>
        <div class="mianze_h1" style="margin-top: 16px">在使用此功能之前，请您仔细阅读以下内容。</div>
        <div class="mianze_h2 flex_l" style="margin-top: 4px"><img src="../../assets/images/info_red.png" />如您使用此功能，即表明您已同意接受以下条款与条件的约束。如您不同意下列条款与条件，请勿使用此功</div>
        <div class="mianze_h3">依据《「工作啦」用户协议及隐私说明》，企业用户在使用本服务时不得利用本服务从事以下行为，包括但不限于： <br/> （1）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容，以及任何侵犯个人和社会大众隐私安全的信息； <br/> （2）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容； <br/> （3）虚构事实、隐瞒真相以误导、欺骗他人，发布任何不完整的、不准确的、虚假的、不合理的、无效的、或有误导性的信息；
            <br/> （4）发布任何与正常招聘目的无关的信息，或不是企业会员自身或未经他人合法授权的招聘相关信息； <br/> （5）发布任何以推销产品为目的的推介、宣传、链接、广告等信息； <br/> （6）通过任何方式搜集本服务中其他用户的用户名、电子邮件等相关信息，并以发送垃圾邮件、连锁邮件、垃圾短信、即时消息等方式干扰、骚扰其他用户； <br/> （7）发布含有性别歧视、地域歧视或健康歧视等违反劳动法律法规的招聘内容或其他内容； <br/> （8）从事其他违反法律法规、政策及公序良俗、社会公德等的行为。如您违反有关法律、法规或本协议项下的任何条款而给「工作啦」及其关联方或任何其他第三人造成损失，您将承担所有由此产生的损害赔偿责任，「工作啦」对此不承担任何连带责任。同时「工作啦」将有权对您的账号进行暂停、停用或封号处理，情节严重者将被依法追究刑事责任。
        </div>
        <div class="flex_c mianzebtn">
            <div (click)="commitMianzeBtnClick(false)">拒绝</div>
            <div style="background-color: #5E4FFF" (click)="commitMianzeBtnClick(true)">同意，提交审核</div>
        </div>
    </div>
</p-dialog>