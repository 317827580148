import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { DataApi,Config,Opera,Local,Constants } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from  '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
//新增高校
import { PushCollegeModelComponent } from "../push-college-model/push-college-model.component";

@Component({
  selector: 'app-college-details-org',
  templateUrl: './college-details-org.component.html',
  styleUrls: ['./college-details-org.component.css']
})
export class CollegeDetailsOrgComponent implements OnInit {
  public config: Config; 
  public isInit = false;//是否初始化成功
  public resumeList = [];//简历列表
  public countList = 0;//列表数量
  // @Output() pageNumber = 1;//当前分页数
  // @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public collegeid="";//高校id
  public college:any;//高校详情
  //高校信息
  public addCollegeList = [];
  //是否打开弹窗(非会员新增高校)
  @Output() isShowPushCollegeModel = false;
  @ViewChild('pushCollegeModel', { static: true })
  pushCollegeModel: PushCollegeModelComponent;

  constructor(public dataApi: DataApi,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public local:Local,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer, 
    public opera:Opera,
    public toastr: ToastrService) { 
      this.config = Config.getInstance();//实例化config
    }
    

  ngOnInit() {
    this.opera.initConfig('college','college-campustalk');
    this.collegeid=this.local.get(Constants.collegeid);//赋值高校id
    this.getCollegeById();//获取高校详情
    this.getResumeList();//获取列表
    this.mycollegeList();
  }
  //查看院系详情
  goDetails(n){
    this.local.set(Constants.facultyid,n.facultyid);
    this.router.navigateByUrl("home/college-details-major");
  }
  //获取列表
  getResumeList(){
    this.opera.loading();
    this.dataApi.getCollegePart(this.collegeid).then((res:any)=>{
      //console.log("获取院系列表---");
      //console.log(res);
      this.resumeList = res;
      this.countList=this.resumeList.length; 
      // this.pageCount =Math.ceil(res.count/9);
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
    })
  }
  //通过高校id获取高校详情
  getCollegeById(){
    this.opera.loading();
    this.dataApi.getCollageDetails(this.collegeid).then((res:any)=>{
      //console.log("获取到高校信息--------------------------CollegeHome");
      //console.log(res);
      this.isInit = true;
      this.college = JSON.parse(JSON.stringify(res));
      this.opera.loadiss();
    }).catch((err)=>{
      this.toastr.error(err);
      this.isInit = true;
      this.opera.loadiss();
    });
  }
  // //获取分页子组件返回数据
  // getChildEvent(index){
  //   //console.log('子组件返回分页数-----college-resume');
  //   //console.log(index);
  //   if(index > 0){
  //     this.pageNumber = index;
  //     this.model.offset = (this.pageNumber-1)*9;
  //     this.getResumeList();//获取简历投递列表
  //   }else if(index == -1){//上一页
  //     if(this.pageNumber == 1){
  //       this.toastr.warning("当前为第一页");
  //     }else{
  //       this.pageNumber = this.pageNumber - 1;
  //       this.model.offset = (this.pageNumber-1)*9;
  //       this.getResumeList();//获取简历投递列表
  //     }
  //   }else if(index == -2){//下一页
  //     if(this.pageNumber == this.pageCount){
  //       this.toastr.warning("当前为最后一页");
  //     }else{
  //       this.pageNumber = this.pageNumber + 1;
  //       this.model.offset = (this.pageNumber-1)*9;
  //       this.getResumeList();//获取简历投递列表
  //     }
  //   }
    
  // }


   //去教学机构
   goOrg() {
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.local.set(Constants.collegeid, that.college.collegeid);
        that.router.navigateByUrl("home/college-details-org");
      }else {
        //that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.local.set(Constants.collegeid, that.college.collegeid);
          that.router.navigateByUrl("home/college-details-org");
        } else {
          this.pushCollege();
        }
      }
    })

  }
  //去双选会
  goMilk() {

    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        this.router.navigateByUrl("home/college-details-milk");
      } else {
        // that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          this.router.navigateByUrl("home/college-details-milk");
        } else {
          this.pushCollege();
        }
      }
    })

  }
  //去宣讲会
  goCampus() {
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.router.navigateByUrl("home/college-details-campus");
      } else {
        //that.opera.checkMemberAlertWithType("goCampus")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.router.navigateByUrl("home/college-details-campus");
        } else {
          this.pushCollege();
        }
      }
    })
  }
  //去新闻公告
  goNews() {

    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.router.navigateByUrl("home/college-details-news");
      }else {
        //that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.router.navigateByUrl("home/college-details-news");
        } else {
          this.pushCollege();
        }
      }
    })
  }

  //去生源速览
  goStudent() {
    this.local.remove('college-details-student-model');
    this.local.remove('college-details-student-modelp');
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.router.navigateByUrl("home/college-details-student");
      } else {
        //that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.router.navigateByUrl("home/college-details-student");
        } else {
          this.pushCollege();
        }
      }
    })
  }
  //获取合作高校
  mycollegeList() {
    this.dataApi.mycollegeList().then((res: any) => {
      if (res) {
        this.addCollegeList = res;
      }
    }).catch((err) => {
      console.log(err);
    })
  }

    //新增高校
  pushCollege() {
      this.isShowPushCollegeModel = true;
      this.pushCollegeModel.initData();
  }
  
  pushCollegeModelHidden(event) {
      this.isShowPushCollegeModel = false;
      this.mycollegeList();
  }
}
