import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { DataApi,Config,Local,Constants,Opera} from '../../provider/index';


@Component({
  selector: 'app-member-product-list',
  templateUrl: './member-product-list.component.html',
  styleUrls: ['./member-product-list.component.css']
})
export class MemberProductListComponent implements OnInit {
  public config: Config;
  cur:number = 3;
  public productList=[];
  constructor(
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService,
    private local: Local,
    public dataApi: DataApi
  ) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.opera.initConfig('member','member-center');
    if(this.config.gotoVipListType+'' =='998')
    {
      this.cur = 2;
    }
    else if(this.config.gotoVipListType+'' =='1998')
    {
      this.cur = 3;

    }
    else if(this.config.gotoVipListType+'' =='3998')
    {
      this.cur = 4;

    }
    this.departmentList();

    this.router.navigate(['home/tool-campus']);

  }


  curs(cur){
    if(this.cur != cur){
      this.cur = cur;
      console.log('this.cur',this.cur);
    }
  }

  // 跳转支付页面
  goSearch(code){
    this.config.productCode = code;
    this.local.set(Constants.productInfo, JSON.stringify(code));
    console.log(this.local.get(Constants.productInfo));
    this.router.navigate(["home/company-pay"]);
  }
  departmentList(){

    this.dataApi.departmentList({product_category:"member"}).then((res:any)=>{
      if(res) {
        console.log('productList', res);
        this.productList =res.list;
      }

    }).catch((err)=>{
      console.log(err);
    })
  }
}
