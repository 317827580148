<!-- 用户确认操作弹出框 -->
<div class="modal-dialog">
    <div *ngIf="type == 'goBuy'" class="modal-content" style="  margin: 180px auto 0;
  width: 429px;
  height:467px;
  background: url(../../assets/images/vip/no_vip_alert.png) 100% 100% no-repeat;">
        <button [mat-dialog-close] style="border: none">
      <img  style="position: absolute;top: 69px;right: 55px;width: 16px;height: 16px" src="../../assets/images/vip/vip_x.png"  />
    </button>
        <div style="text-align: center;margin-top: 86px;font-size: 19px;color: #C9B189">成为基础会员<br>即可查看全网简历</div>
        <button style="width: 148px;height: 39px;position: absolute;left: 50%;margin-left:-74px;top: 213px;border: none" type="button" class="btn btn-default" (click)="goRouter('tool-campus')" [mat-dialog-close]></button>
        <button [mat-dialog-close] style="border: none">
      <div (click) = "goRouter('tool-campus')"  style="position: absolute;left: 50%;margin-left: -56px;bottom: 179px;width: 112px;height: 19px"></div>
    </button>
    </div>
    <div *ngIf="type == 'goMilk'" class="modal-content" style="  margin: 210px auto 0;
  width: 429px;
  height:467px;
  background: url(../../assets/images/vip/no_vip_alert1.png) 100% 100% no-repeat;">
        <button [mat-dialog-close] style="border: none">
      <img  style="position: absolute;top: 69px;right: 55px;width: 16px;height: 16px" src="../../assets/images/vip/vip_x.png"  />
    </button>
        <div style="text-align: center;margin-top: 86px;font-size: 19px;color: #C9B189">成为基础会员<br>一站申请全网所有高校双选会</div>
        <button style="width: 148px;height: 39px;position: absolute;left: 50%;margin-left:-74px;top: 202px;border: none" type="button" class="btn btn-default" (click)="goRouter('tool-campus')" [mat-dialog-close]></button>
        <div style="font-size: 14px;margin-top: 5px;color: #C9B189;text-align: center;position: absolute;top: 270px;left: 120px;">或到各高校就业信息网逐一申请</div>
        <button [mat-dialog-close] style="border: none">
      <div (click) = "goRouter('tool-campus')"  style="position: absolute;left: 50%;margin-left: -56px;bottom: 204px;width: 112px;height: 19px"></div>
    </button>
    </div>
    <div *ngIf="type == 'goCloud'" class="modal-content" style="  margin: 210px auto 0;
  width: 429px;
  height:467px;
  background: url(../../assets/images/vip/no_vip_alert.png) 100% 100% no-repeat;">
        <button [mat-dialog-close] style="border: none">
      <img  style="position: absolute;top: 69px;right: 55px;width: 16px;height: 16px" src="../../assets/images/vip/vip_x.png"  />


    </button>
        <div style="text-align: center;margin-top: 86px;font-size: 19px;color: #C9B189">成为基础会员<br>一站申请全网所有高校</div>
        <button style="width: 148px;height: 39px;position: absolute;left: 50%;margin-left:-74px;top: 213px;border: none" type="button" class="btn btn-default" (click)="goRouter('tool-campus')" [mat-dialog-close]></button>
        <button [mat-dialog-close] style="border: none">
      <div (click) = "goRouter('tool-campus')"  style="position: absolute;left: 50%;margin-left: -56px;bottom: 179px;width: 112px;height: 19px"></div>
    </button>
    </div>
    <div *ngIf="type == 'goCampus'" class="modal-content" style="  margin: 210px auto 0;
  width: 429px;
  height:467px;
  background: url(../../assets/images/vip/no_vip_alert1.png) 100% 100% no-repeat;">
        <button [mat-dialog-close] style="border: none">
      <img  style="position: absolute;top: 69px;right: 55px;width: 16px;height: 16px" src="../../assets/images/vip/vip_x.png"  />
    </button>
        <div style="text-align: center;margin-top: 86px;font-size: 19px;color: #C9B189">成为基础会员<br>一站申请全网所有高校宣讲会</div>
        <button style="width: 148px;height: 39px;position: absolute;left: 50%;margin-left:-74px;top: 202px;border: none" type="button" class="btn btn-default" (click)="goRouter('tool-campus')" [mat-dialog-close]></button>
        <div style="font-size: 14px;margin-top: 5px;color: #C9B189;text-align: center;position: absolute;top: 270px;left: 120px;">或到各高校就业信息网逐一申请</div>
        <button [mat-dialog-close] style="border: none">
      <div (click) = "goRouter('tool-campus')"  style="position: absolute;left: 50%;margin-left: -56px;bottom: 204px;width: 112px;height: 19px"></div>
    </button>
    </div>
    <div *ngIf="type == 'goPublish'" class="modal-content" style="  margin: 210px auto 0;
  width: 429px;
  height:467px;
  background: url(../../assets/images/vip/no_vip_alert.png) 100% 100% no-repeat;">
        <button [mat-dialog-close] style="border: none">
      <img  style="position: absolute;top: 69px;right: 55px;width: 16px;height: 16px" src="../../assets/images/vip/vip_x.png"  />
    </button>
        <div style="text-align: center;margin-top: 86px;font-size: 19px;color: #C9B189">成为基础会员<br>发布职位全网所有高校毕业生可见</div>
        <button style="width: 148px;height: 39px;position: absolute;left: 50%;margin-left:-74px;top: 213px;border: none" type="button" class="btn btn-default" (click)="goRouter('tool-campus')" [mat-dialog-close]></button>
        <button [mat-dialog-close] style="border: none">
      <div (click) = "goRouter('tool-campus')"  style="position: absolute;left: 50%;margin-left: -56px;bottom: 179px;width: 112px;height: 19px"></div>
    </button>
    </div>
    <div *ngIf="type == 'goResume'" class="modal-content" style="  margin: 210px auto 0;
  width: 429px;
  height:467px;
  background: url(../../assets/images/vip/no_vip_alert.png) 100% 100% no-repeat;">
        <button [mat-dialog-close] style="border: none">
      <img  style="position: absolute;top: 69px;right: 55px;width: 16px;height: 16px" src="../../assets/images/vip/vip_x.png"  />
    </button>
        <div style="text-align: center;margin-top: 86px;font-size: 19px;color: #C9B189">成为基础会员<br>即刻查看简历沟通意向</div>
        <button style="width: 148px;height: 39px;position: absolute;left: 50%;margin-left:-74px;top: 213px;border: none" type="button" class="btn btn-default" (click)="goRouter('tool-campus')" [mat-dialog-close]></button>
        <button [mat-dialog-close] style="border: none">
      <div (click) = "goRouter('tool-campus')"  style="position: absolute;left: 50%;margin-left: -56px;bottom: 179px;width: 112px;height: 19px"></div>
    </button>
    </div>
    <div class="modal-content" style="margin: 0 auto;  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 510px;
  height:260px;
  background: url(../../assets/images/vip/vip_bg2.png)" *ngIf="type == 'audit' && message.departmentSelf">
        <button [mat-dialog-close] style="border: none">
      <img  style="position: absolute;top: 10px;right: 10px;width: 16px;height: 16px" src="../../assets/images/vip/vip_x.png"  />
    </button>
        <div style="display: flex;align-items: center;margin: 10px 0">
            <img src="../../assets/images/vip/vip_time.png" style="width: 20px;height: 20px;margin-right: 2px">
            <span style="font-size: 17px;color: #333333">您当前处于认证中</span>
        </div>
        <div class="card_guwen flex_s">
            <div class="card_guwen_l">
                <img class="card_icon" src="{{message.departmentSelf.avatar}}">
                <div class="card_title1">工作啦校招顾问</div>
                <div class="card_title2">{{message.departmentSelf.name}}</div>

                <div class="card_title3 flex_l" style="margin-top: 19px"><img src="../../assets/images/kefu_wx.png">微信：{{message.departmentSelf.wx}}</div>
                <div class="card_title3 flex_l"><img src="../../assets/images/kefu_email.png">邮箱：{{message.departmentSelf.email}}</div>

            </div>
            <img class="card_guwen_r" src="{{message.departmentSelf.qr_code}}">
        </div>
        <button [mat-dialog-close] style="border: none">
      <div  (click) = "goRouter1('guide-audit')" style="display: flex;margin-top: 10px;align-items: center">
        <div style="font-size: 13px;color: #999999">查看审核状态</div>
        <img style="width: 12px;height: 12px" src="../../assets/images/vip/vip_r.png" />
      </div>
    </button>
    </div>
    <div class="modal-content" style="margin: 210px auto 0;  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 390px;
  height:191px;
  background: url(../../assets/images/vip/vip_bg2.png);
  background-size: 390px 191px" *ngIf="type == 'fail'">
        <button [mat-dialog-close] style="border: none">
      <img  style="position: absolute;top: 10px;right: 10px;width: 16px;height: 16px" src="../../assets/images/vip/vip_x.png"  />
    </button>
        <div style="display: flex;align-items: center;margin: 20px 0">
            <img src="../../assets/images/vip/vip_fail.png" style="width: 48px;height: 48px;margin-right: 4px">
            <span style="font-size: 19px;color: #333333;font-weight: bold">您的认证信息审核失败</span>
        </div>
        <button [mat-dialog-close] style="border: none">
      <div  (click) = "goRouter1('guide-audit')" style="display: flex;margin-top: 10px;align-items: center">
        <div style="font-size: 15px;color: #ffffff;background: #5E4FFF;text-align: center;line-height: 36px;width: 120px;height: 36px">重新认证</div>
      </div>
    </button>
    </div>
    <div class="modal-content" style="margin: 210px auto 0;  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 390px;
  height:191px;
  background: url(../../assets/images/vip/vip_bg2.png);
  background-size: 390px 191px" *ngIf="type == 'no_audit'">
        <button [mat-dialog-close] style="border: none">
      <img  style="position: absolute;top: 10px;right: 10px;width: 16px;height: 16px" src="../../assets/images/vip/vip_x.png"  />
    </button>
        <div style="display: flex;align-items: center;margin: 20px 0">
            <img src="../../assets/images/vip/vip_fail.png" style="width: 48px;height: 48px;margin-right: 4px">
            <span style="font-size: 19px;color: #333333;font-weight: bold">您当前未认证</span>
        </div>
        <button [mat-dialog-close] style="border: none">
        <div  (click) = "goRouter1('guide-audit')" style="display: flex;margin-top: 10px;align-items: center">
        <div style="font-size: 15px;color: #ffffff;background: #5E4FFF;text-align: center;line-height: 36px;width: 120px;height: 36px">去认证</div>
      </div>
    </button>
    </div>
</div>