<!-- 页头部分 -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <div class="college-block">
    <!-- 页头部分 -->
    <!-- <div class="college-block-head">会员中心</div> -->
    <!-- <div class="college-block-head collegeBlockMargin">
      <div class="college-tab">
        <span (click)="powerTab()" [class.bold]="power">企业认证权益</span>
      </div>
    </div> -->
    <!-- 介绍 -->
    <div class="college-block">
      <div class="powerCardBox flex_row_between">
        <div>
          <img (click)="productDefs(1)" [class.cur]="productDef == 1" src="../../assets/images/member-1.png" alt="">
        </div>
        <div>
          <img (click)="productDefs(0)" [class.cur]="productDef == 0" src="../../assets/images/member-2.png" alt="">
        </div>
        <div>
          <img (click)="productDefs(2)" [class.cur]="productDef == 2" src="../../assets/images/member-3.png" alt="">
        </div>
      </div>
      <div class="powerExplainBox">
        <h2>VIP会员权益</h2>
        <ul class="powerExplainList flex_row_between">
          <li class="powerExplainInfo" *ngFor="let item of productInfo?.product_infos;let i = index">
            <div class="flex_row_left" *ngIf="item.count > 0">
              <div class="ico">
                <img *ngIf="i == 0" src="../../assets/images/pro1.png" alt="">
                <img *ngIf="i == 1" src="../../assets/images/pro2.png" alt="">
                <img *ngIf="i == 2" src="../../assets/images/pro3.png" alt="">
                <img *ngIf="i == 3" src="../../assets/images/pro4.png" alt="">
                <img *ngIf="i == 4" src="../../assets/images/pro5.png" alt="">
                <img *ngIf="i == 5" src="../../assets/images/pro6.png" alt="">
              </div>
              <div class="flex_col_between powerMain">
                <p class="powerName"><span>{{item.name}}</span>{{item.intro}}</p>
                <p class="powerDesc">{{item.description}}</p>
              </div>
              <em>{{i+1}}</em>
            </div>
          </li>
        </ul>
      </div>
      <p class="flex_row_center shuom" routerLink="../../home/company-status-three">
        购买说明
        <img src="../../assets/images/di-text1.png" alt="">
      </p>
      <div class="sumitBtn" (click)="goSearch()">
        立即开通
      </div>
      <br>
    </div>
    <!-- 人才搜索权益 -->
    <!-- <div *ngIf="!power">
      <h3 class="power-title powerTitle"><img src="assets/images/college-status-six.png" alt="">我的搜索权益</h3>
      <div *ngIf="!config.searchStatus">
        <div class="powerBlock">
          <p class="powerState">当前状态：<span>未认证</span><button (click)="goSearch()">立即开通高级搜索</button></p>
        </div>
        <h3 class="power-title powerTitle"><img src="assets/images/college-status-two.png" alt="">人才搜索权益对比</h3>
        <div class="powerBlock powers flex_row_left">
          <div class="ordinaryPower flex_col_center">
            <h3>普通搜索</h3>
            <p class="powerMoney"><sup>￥</sup><span>0</span></p>
            <button class="have">已获得</button>
            <p class="transitionLine">————————————————</p>
            <p><em></em>按学校搜索</p>
            <p><em></em>按在招职位搜索</p>
            <del>&nbsp;按学校标签搜索&nbsp;</del>
            <del>&nbsp;按全部职位搜索&nbsp;</del>
            <del>&nbsp;按专业搜索&nbsp;</del>
            <del>&nbsp;按学历搜索&nbsp;</del>
            <del>&nbsp;按界别搜索&nbsp;</del>
            <del>&nbsp;按性别搜索&nbsp;</del>
            <del>&nbsp;按期望工作城市搜索&nbsp;</del>
          </div>
          <div class="vipPower flex_col_center">
            <img src="assets/images/college-status-five.png" alt="">
            <h3>高级搜索</h3>
            <p class="powerMoney"><sup>￥</sup><span class="redCol">298</span>/90天</p>
            <button class="goBuy" (click)="goSearch()">立即认证</button>
            <p class="transitionLine">————————————————</p>
            <p><em></em>按学校搜索</p>
            <p><em></em>按在招职位搜索</p>
            <p><em></em>按学校标签搜索</p>
            <p><em></em>按全部职位搜索</p>
            <p><em></em>按专业搜索</p>
            <p><em></em>按学历搜索</p>
            <p><em></em>按界别搜索</p>
            <p><em></em>按性别搜索</p>
            <p><em></em>按期望工作城市搜索</p>
          </div>
        </div>
      </div>
      <div class="college-list collegeList" *ngIf="config.searchStatus">
        <div class="college-message collegeMessage clear">
          <div class="left">当前状态：</div>
          <div class="left">开通</div>
        </div>
        <div class="college-message collegeMessage clear" *ngIf="config.searchendTime">
          <div class="left">认证有效期至：</div>
          <div class="left">{{config.searchendTime | date:'yyyy.MM.dd'}}</div>
        </div>
        <div class="rightsCard">
          <p><i>————————</i><span>多维度搜索人才</span><i>————————</i></p>
          <span>已开通</span>
          <ul class="flex_row_around">
            <li><em></em>按学校搜索</li>
            <li><em></em>按专业搜索</li>
            <li><em></em>按期望工作城市搜索</li>
            <li><em style="margin-left: 1rem;"></em>按在招职位搜索</li>
            <li><em></em>按学历搜索</li>
            <li><em></em>按期望薪资搜索</li>
            <li><em></em>按学校标签搜索</li>
            <li><em></em>按界别搜索</li>
            <li><em></em>按是否在线搜索</li>
            <li><em></em>按全部职位搜索</li>
            <li><em></em>按性别搜索</li>
            <li>&nbsp;</li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</div>
