import { Component, OnInit } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { DataApi,Local,Constants,Config } from '../../provider/index';
import { Router } from  '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-identity-status',
  templateUrl: './identity-status.component.html',
  styleUrls: ['./identity-status.component.css']
})
export class IdentityStatusComponent implements OnInit {

  public config: Config;
  public weChatCode:'';
  public checkWeChatTimer = null;
  public checkWeChatIndex = 60;
  public isShowRefresh = false

  constructor( private router: Router,
    private location: PlatformLocation,
    public dataApi: DataApi,
    public local:Local,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
      this.config.isGuide=true;
    }

  ngOnInit() {
    this.showBindWeiChatClick()
  }
  //进入后台
  goHome(){
    this.router.navigate(['home/position-publish']);
  }
  showBindWeiChatClick(){
      console.log('展示二维码绑定微信')
      if(this.local.get('department')) {
        let department = this.local.get('department');
        let departmentJSON = JSON.parse(department);
        let departmentid = departmentJSON.accountid;
        let data = {departmentid: departmentid};
        //getWeiChatCode
        this.dataApi.getWeiChatCode(data).then((res:any)=>{
          if(res){
            this.weChatCode = res.value;
            this.startTime();
          }
          console.log(res);
        }).catch((error)=>{
          //console.log(error);
          this.toastr.error('获取失败，请稍后再试');
        });
      }
  }
  startTime(){
    let accountid= this.local.get('accountid');
    this.checkWeChatIndex = 60
    if(this.checkWeChatTimer){
      clearInterval(this.checkWeChatTimer)
    }
    this.isShowRefresh = false
    this.checkWeChatTimer =  setInterval(()=>{
      this.checkWeChatIndex-=1;
      if(this.checkWeChatIndex <=0){
        this.checkWeChatIndex = 61;
        this.isShowRefresh = true;
        clearInterval(this.checkWeChatTimer)
      }
      this.dataApi.getComopanyIsBingWeiChat(accountid).then((res:any)=>{
        console.log('已绑定')
        this.checkWeChatIndex = 60
        if(this.checkWeChatTimer){
          clearInterval(this.checkWeChatTimer)
        }
        this.router.navigateByUrl('home/homepage');
        this.config.userisBindWx = true
        this.toastr.success('绑定成功')
      }).catch((error)=>{
        console.log('未绑定',this.checkWeChatIndex)
      })
    },1000)
  }
  testjump(){
    this.router.navigateByUrl('home/homepage');

  }
}
