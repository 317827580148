import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-position-push-model',
  templateUrl: './position-push-model.component.html',
  styleUrls: ['./position-push-model.component.css']
})
export class PositionPushModelComponent implements OnInit {

  constructor() { }

  @Input()  isShow: false;//是否显示弹窗页面
  @Input() resumeDetails:any;
  @Output() modelHidden = new EventEmitter<number>();


  ngOnInit() {

  }

  _modelHidden(){
    this.modelHidden.emit()

  }


}
