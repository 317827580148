<!--//commPic-->
<div *ngIf="!isShowResult" style="padding-top: 20px" class="homepage-block2">
    <div class="search-box">
        <div class="searchPeopleLogo"><img src="/assets/images/v3/searchPeopleLogo.png"></div>
        <div class="searchPeopleTip"> 「工作啦-大学生直聘」，在线直聘大学生！ </div>
        <div class="searchPeopleTip"> 超200w+精准人才库，覆盖13大学科，2000+专业 </div>
        <div class="searchBox">
            <input type="text" placeholder="输入关键词，精准连接超180万大学生人才" [(ngModel)]="advanceModel.keywords" (keyup)="inputKeyup1($event)">
            <div class="icon" (click)="goSearchResult()"><img src="/assets/images/v3/searchIcon.png"></div>
        </div>
        <div class="tip">*可输入简历ID、职位、学校、专业等关键词</div>
    </div>
    <div class="postion-box" *ngIf="positionList.length > 0">
        <div class="title">按在招职位搜索</div>
        <!--    <div  class="title" *ngIf="positionList.length == 0" class="title">您暂未发布职位</div>-->
        <div class="postion-box-li" (click)="goSearchResult1(n)" *ngFor="let n of positionList;let i = index;">
            <div class="left">
                <div class="name">{{n.name}}</div>
                <div class="text">{{n.work_place_text}} &nbsp; | &nbsp; {{n.post_nature_text}} &nbsp; |&nbsp; {{n.education_text}} &nbsp; | &nbsp; {{n.intent_count}}人</div>
            </div>
            <div class="info">{{n.position_salary_text}}</div>
        </div>
    </div>
</div>

<!-- 搜索简历列表 -->
<div class="searchMain" *ngIf="isShowResult">
    <img class="scrollTop1" (click)="opera.scrollTop1()" src="/assets/images/go_top.png" style="width: 60px;height: 60px;position: fixed;bottom: 100px;right: 50px;cursor: pointer;z-index: 9;" />
    <app-model-send-msg #sengMsgModel [SengMsgModel]='SengMsgModel' [tconfig]='config' (onVoted)="Modeldismiss($event)" [isShow]="modelShowSengMsg"></app-model-send-msg>
    <app-voice-call-model #voiceCallModel [isShow]="isShowVoiceCallModel" (modelHidden)="voiceCallModelHidden($event)"></app-voice-call-model>
    <!-- 搜索 -->
    <div class="topMain">
        <div class="_center">
            <div class="for1">
                <div class="_left">
                    <div class="input">
                        <input type="text" placeholder="简历ID、职位、学校、专业等关键词" [(ngModel)]="advanceModel.keywords" (keyup)="inputKeyup($event)">
                    </div>
                    <div class="inputBtn blueStreamer" (click)="getRecommendList()"><img src="/assets/images/v3/searchIcon.png"></div>
                </div>
                <div class="_right">
                    <div class="cell">
                        <select class="seleteCard" name="position" id="position" placeholder="全部在招职位" [(ngModel)]="advanceModel.positionId" (ngModelChange)="positionChange()">
                            <option class="place-text" value='' selected="selected">全部在招职位</option>
                            <option *ngFor="let a of positionList" [value]="a.positionid">{{a.name}}</option>
                        </select>
                    </div>
                </div>
                <!-- <div (click)="clearType()" *ngIf="advanceModel.post_type_text != ''" class="type-tag blueStreamer">{{advanceModel.post_type_text}}&nbsp; X</div> -->
            </div>
            <div class="for2">
                <div class="cell">
                    <select class="seleteCard" name="city-milk" autocomplete="off" [(ngModel)]="advanceModel.gender" (ngModelChange)="getRecommendList()">
                        <option class="place-text" value='' disabled hidden selected="selected">选择性别</option>
                        <option class="place-text" value='' selected="selected">不限性别</option>
                        <option value='01'>男性</option>
                        <option value='02'>女性</option>
                    </select>
                </div>
                <div class="cell">
                    <select class="seleteCard" name="major" id="major" [(ngModel)]="majorOne" (ngModelChange)="majorOneChange()" (click)="isShowOne()" autocomplete="off">
                        <option class="place-text" value='' disabled hidden selected="selected">选择学科</option>
                        <option class="place-text" value='' selected="selected">不限学科</option>
                        <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
                    </select>
                    <select class="seleteCard" name="majorTwo" id="majorTwo" [(ngModel)]="advanceModel.major" (ngModelChange)="getRecommendList()" (click)="isShowTwo()" autocomplete="off">-->
                        <option class="place-text" value='' hidden selected="selected">选择专业</option>
                        <option class="place-text" value=''  selected="selected">不限专业</option>
                        <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
                    </select>
                </div>
                <div class="cell">
                    <select class="seleteCard" name="education" id="education" [(ngModel)]="advanceModel.education" (ngModelChange)="getRecommendList()" autocomplete="off">
                        <option class="place-text" value='' disabled hidden selected="selected">选择学历</option>
                        <option class="place-text" value='' selected="selected">不限学历</option>
                        <option value='02'>大专</option>
                        <option value='03'>本科</option>
                        <option value='04,05,06,07,08'>硕士及以上</option>
                    </select>
                </div>
                <div class="cell">
                    <select class="seleteCard" name="city-milk" id="city-milk1" autocomplete="off" [(ngModel)]="advanceModel.provice" (ngModelChange)="seleteProvince()">
                        <option class="place-text" value='' disabled hidden selected="selected">选择省份</option>
                        <option class="place-text" value='' selected="selected">不限省份</option>
                        <option *ngFor="let Province of hotProvince" [value]="Province.code">{{Province.text}}</option>
                    </select>

                    <select class="seleteCard" name="city-milk" autocomplete="off" [(ngModel)]="advanceModel.native_place" (ngModelChange)="getRecommendList()">
                        <option class="place-text" value='' disabled hidden selected="selected">选择城市</option>
                        <option class="place-text" [value]='advanceModel.provice' selected="selected">不限</option>
                        <option *ngFor="let city of hotCity" [value]="city.code">{{city.text}}</option>
                    </select>
                </div>
                <div class="cell">
                    <select class="seleteCard" name="city-milk" autocomplete="off" [(ngModel)]="advanceModel.year" (ngModelChange)="getRecommendList()">
                        <option class="place-text" value='' disabled hidden selected="selected">选择届别</option>
                        <option value=''>不限届别</option>
                        <option class="place-text" *ngFor="let city of jiebieList" [value]="city.code">
                            {{city.text}}</option>
                    </select>
                </div>
                <div class="cell" style="margin-top: 13px;">
                    <select class="seleteCard" [(ngModel)]="advanceModel.job_status" (ngModelChange)="getRecommendList()">
                        <option class="place-text" value='' disabled hidden selected="selected">选择求职状态</option>
                        <option value=''>不限状态</option>
                        <option value='01'>正在找工作</option>
                        <option value='02'>在校生,可参加实习</option>
                        <option value='03'>已找到工作</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <!-- 批量推送 -->
    <div class="sel-res">
        <img src="/assets/images/positionPushs_icon.png" />
        <div class="num">
            <img src="/assets/images/positionpush_title.png" />
            <div>符合您筛选条件的人数为：<span style="color: #F4B517">{{countList>=2000?"2000+":countList}}</span> 人</div>
        </div>
        <div class="push blueStreamer" (click)="useBatchFn()">立即推送</div>
    </div>
    <!-- 全选/批量推送 -->
    <div class="orBox">
        <div class="or" (click)="AllSeleteClick()">
            <div class="choice" [class.active]='isAllSelete'><span *ngIf="isAllSelete"></span></div>全选
        </div>
        <div class="downResume" *ngIf="downLoadArr.length == 0">
            <img src="/assets/images/v3/searchPeoplePushIcon.png"> 批量职位推送
        </div>
        <div class="downResume active" (click)="downLoadChips()" *ngIf="downLoadArr.length>0">
            <img src="/assets/images/v3/searchPeoplePushIcon.png"> 批量职位推送
        </div>

    </div>
    <!-- 简历列表 -->
    <div class="resume-list" *ngIf="countList>0">
        <div class="item" *ngFor="let n of resumeList;let i = index;" (click)="goDetails(n,n.ignore,i)">
            <div class="choice" [class.active]='n.selete' (click)="cellClickDown(n,$event)"><span *ngIf="n.selete"></span></div>
            <div class="main">
                <div class="rightBtn">
                    <div class="_btn active" (click)="ShowPhoneModel(n,$event)">
                        <img src="/assets/images/v3/speedIcon.png"> 极速电话联系
                    </div>
                    <div class="_btn" (click)="alertSengMSG(n,$event)">
                        <img src="/assets/images/v3/pushIcon.png"> 职位推送
                    </div>
                </div>
                <div class="introduce">
                    <div class="logo">
                        <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}">
                        <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png">
                        <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png">
                        <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png">
                    </div>
                    <div class="text">
                        <div class="name">{{n.talent_name | clearSpace}}
                            <span *ngIf="n.isview_resume">已查看</span>
                            <span *ngIf="n.new_talent">新用户</span>
                            <span *ngIf="n.is_called">已联系</span>
                            <span *ngIf="n.is_push">已推送</span>
                        </div>
                        <div class="college">{{n.graduate_university}}</div>
                        <div class="major">{{n.major_text}} | {{n.education_text}} | {{n.year==0?"往届":n.year+'届'}}</div>
                    </div>
                </div>
                <div class="lable">
                    <span *ngFor="let youshi of n.skills | slice:0:4;let i = index;">{{youshi.text}}</span>
                </div>
                <div class="intention">
                    <span>求职意向：</span>{{n.yixiang}}
                </div>
                <div class="lastActive" *ngIf="admin == 'tianjihr'" title="{{n._refresh_time | date:'yyyy-MM-dd' }}">{{n.lastActive}}</div>
            </div>

        </div>
    </div>
    <!-- 空状态 -->
    <div *ngIf="countList==0" class="none-block">
        <img src="/assets/images/rights/empty.png"><br /> 暂未查询到结果
    </div>

    <!-- 分页部分 flex_row_between -->
    <div class="bottomBox">
        <div class="flex_row_left selsectBox" *ngIf="useBatch">
            <div class="seslectAll flex_row_left">
                <button class="purple" (click)="selectAllFn()">全选本页</button>
            </div>
            <div class="selsectReverse flex_row_left">
                <button class="purple" (click)="selectRevFn()">反选本页</button>
            </div>
        </div>
        <div>&nbsp;</div>
        <div class='paging-block' [class.isPageHeight]="countList>0">
            <router-outlet></router-outlet>
            <select class="paging-block-filtrate_row_select_gray" placeholder="请选择" [(ngModel)]="eachPageCount" (ngModelChange)="getpageCount($event)">
                <option value="10">10条/页</option>
                <option value="20">20条/页</option>
                <option value="50">50条/页</option>
            </select>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)">
            </app-paging>

        </div>
    </div>

</div>

<!--职位推送-->
<p-dialog id="bigPackage" [contentStyle]="{'padding':'0',border:0,'borderRadius': '10px'}" [dismissableMask]="true" [modal]="true" [showHeader]="false" [(visible)]="isShowPushModel">
    <div class="big-package">
        <div class="batchPush">
            <div class="clos" (click)="closePushs()"><img src="/assets/images/v3/speedClos.png"></div>

            <!-- 上层提示部分 -->
            <div class="_title">
                <div class="icon"><img src="/assets/images/v3/positionPush.png"></div>
                <div class="rightBox">
                    <div class="name">职位推送</div>
                    <div class="text">精准触达，揽获更多精准投递</div>
                </div>
            </div>
            <div class="logos">
                <div class="item" *ngFor="let n of downLoadArr">
                    <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}" />
                    <img *ngIf="!n.logo&&n.gender=='01'" src="assets/images/male.png" />
                    <img *ngIf="!n.logo&&n.gender=='02'" src="assets/images/female.png" />
                </div>
            </div>
            <div class="module">
                <div class="left">推送职位</div>
                <div class="right">
                    <div class="val">
                        <select class="activeBg" name="city" lay-verify="required" [(ngModel)]="pushModels.positionid">
                            <option value="" readonly="">请选择职位</option>
                            <option *ngFor="let x of positionList" value={{x.positionid}}>{{x.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="content">
                    <div class="_title">推送示例</div>
                    <div class="contentText">{{pushModels.content}}</div>
                    <img src="/assets/images/v3/contentText.png">
                </div>
                <div class="pushBtn blueStreamer" (click)="inviteStuAlert()">立即推送</div>
                <p>需消耗资源数<span>{{downLoadArr.length}}</span>，(当前剩余资源<span>{{totalCoin}}</span>)</p>
                <p>1-2日后，您可在【简历管理】-【职位推送记录】中查看推送记录。</p>
            </div>
            <p class="p2" (click)="product('sms_bulkinvitation','contactMe')">购买资源</p>
        </div>


        <!-- <div class="pushHead flex_l">请确定要推送的职位：
            <select name="city" lay-verify="required" style="width: 250px;height: 34px;font-size: 13px" [(ngModel)]="pushModels.positionid">
                <option value="" readonly="">请选择邀投职位</option>
                <option *ngFor="let x of positionList" value={{x.positionid}}>{{x.name}}</option>
            </select>
            <img (click)="closePushs()" class="headArrClose" src="../../assets/images/close_default.png" />
        </div>
        <div class="headArr flex_l">
            <div *ngFor="let n of downLoadArr">
                <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}" />
                <img *ngIf="!n.logo&&n.gender=='01'" src="assets/images/male.png" />
                <img *ngIf="!n.logo&&n.gender=='02'" src="assets/images/female.png" />
            </div>
        </div>
        <div class="pushSmsContent">
            {{pushModels.content}}
        </div>
        <div class="Pushbottom flex_s">
            <span> 需消耗{{downLoadArr.length}}次资源（当前余量{{totalCoin}}）</span>
            <div class="PushbottomBtn" (click)="inviteStuAlert()">推送</div>
        </div>
        <div class="bottomChips">1-2日后，您可在【简历管理】-【职位推送记录】中查看推送记录。</div> -->
    </div>
</p-dialog>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>