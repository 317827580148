import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
//新增高校
import { PushCollegeModelComponent } from "../push-college-model/push-college-model.component";
@Component({
  selector: 'app-college-details-campus',
  templateUrl: './college-details-campus.component.html',
  styleUrls: ['./college-details-campus.component.css']
})
export class CollegeDetailsCampusComponent implements OnInit {
  public config: Config;
  public keywordList=[];//搜索关键词匹配列表
  public campusList = [];//宣讲会列表
   // 筛选条件
   public model={
    offset:0,
    limit:9
  }
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public myDepartment:any;//公司信息
  public collegeid="";//高校id
  public college:any;//高校详情
  public isInit=false;
  public is_apply = true;
  public collegeIdentity:any;//企业当前高校认证数据

  showTips:boolean = true; // 是否弹窗
  //高校信息
  public addCollegeList = [];
  //是否打开弹窗(非会员新增高校)
  @Output() isShowPushCollegeModel = false;
  @ViewChild('pushCollegeModel', { static: true })
  pushCollegeModel: PushCollegeModelComponent;
  
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
  }


  ngOnInit() {
    this.opera.initConfig('college','college-campustalk');
    this.collegeid=this.local.get(Constants.collegeid);//赋值高校id
    if(this.collegeid)
    {
      this.checkDepartmentIndentityByCollege();//检测企业认证信息
      this.getDepartment();//获取企业信息
      this.getResumeList();//获取宣讲会列表
      this.getCollegeById();//获取高校详情
    }
  }

  //通过高校id获取高校详情
  getCollegeById(){
    // this.opera.loading();
    if(!this.collegeid)
    {
      return;
    }
    this.dataApi.getCollageDetails(this.collegeid).then((res:any)=>{
      //console.log(res);
      if(res)
      {
        this.isInit = true;
        this.college = JSON.parse(JSON.stringify(res));
        console.log("获取到高校信息---", this.college);
      }


      // this.opera.loadiss();
    }).catch((err)=>{
      this.toastr.error(err);
      this.isInit = true;
      // this.opera.loadiss();
    });
  }

  //获取企业信息
  getDepartment(){
    if(this.dataApi.getLocalDepartment()){
          this.myDepartment = this.dataApi.getLocalDepartment();
          //console.log("企业信息本地存储---------------------------CollegeIdentityPage");
          //console.log(this.dataApi.getLocalDepartment());
        }else{
          this.dataApi.getDepartment().then((res:any)=>{
            //console.log("企业信息获取线上数据---------------------------CollegeIdentityPage");
          //console.log(res);
            if(res)
            {
              this.dataApi.setLocalDepartment(res);

            }
        }).catch((error) => {
          //console.log(error);
          this.toastr.error(error);
      });
    }

  }

  //修改宣讲会申请
  goUpdata(collegeid,n){
    this.opera.checkCompanyIsBack(collegeid,()=> {
      //console.log("修改宣讲会申请-------");
      this.local.set(Constants.collegeid,collegeid);
      this.local.set(Constants.campusApply,JSON.stringify(n));
      this.router.navigate(['home/college-campus-sign']);
    })

  }

  // 查看宣讲会详情
  goPreach(ctid){
    this.local.set(Constants.ctid,ctid);
    this.router.navigate(['home/college-details-preach']);
  }




  //申请宣讲会(再次申请)
  goSign(){
    if(this.config.positionList.length==0){
      this.opera.remindPublish("申请宣讲会");
      return;
    }
    console.log("获取企业在高校的认证信息-----------------------CampusDetails");
    console.log(this.collegeIdentity);

    this.opera.checkCompanyIsBack(this.college.collegeid,()=> {
      if(this.collegeIdentity){
        if(this.collegeIdentity.status=='2'){//通过校招
          this.local.remove(Constants.campusApply);
          this.router.navigate(['home/college-campus-sign']);
        }else if(this.collegeIdentity.status=='1'){//校招审核中
          this.toastr.warning("高校合作审核中，审核通过后才可预约宣讲会");
        }else{
          this.opera.remindCollegeIdentity("预约宣讲会",this.college.collegeid,this.collegeIdentity);
        }
      }else{//没有申请认证记录
        this.opera.remindCollegeIdentity("预约宣讲会",this.college.collegeid,this.collegeIdentity);
      }
    })
  }

  //判断状态
  statusSign(){
    if(!this.college.campustalk_switch){
       this.toastr.warning("当前高校未开启宣讲会");
       return;
    }
    this.opera.checkCompanyIsBack(this.collegeid,()=> {

      console.log('this.college',this.college);
      console.log('lastIdentity',this.config.lastIdentity);
      //n  高校信息
      let n = this.college;
      if(this.config.lastIdentity.status == 2){

        // console.log("申请宣讲会",n.collegeid);
        this.local.set(Constants.collegeid,this.collegeid);
        this.local.remove(Constants.campusApply)
        this.router.navigate(['home/college-campus-sign'], {
          queryParams: {
            college:JSON.stringify(n)
          }
        });

      }else if(this.config.lastIdentity.status == 1){
        this.dataApi.checkDepCollegeIdentity(this.collegeid).then((res:any)=>{
          if(res)
          {
            console.log('高校合作',res)

            if(res.status=='2')
            {
              console.log("申请宣讲会",this.collegeid);
              this.local.set(Constants.collegeid,this.collegeid);
              this.local.remove(Constants.campusApply)
              this.router.navigate(['home/college-campus-sign'], {
                queryParams: {
                  college:JSON.stringify(this.collegeid)
                }
              });
            }
            else
            {
              this.toastr.warning('认证审核中')
            }
          }
          else
          {
            this.toastr.warning('认证审核中')
          }
        }).catch((err)=>{
          this.toastr.warning('认证审核中')
        })

      }else if(this.config.lastIdentity.status == 3){
        this.dataApi.checkDepCollegeIdentity(this.collegeid).then((res:any)=>{
          console.log('高校合作',res)
          if(res)
          {
            if(res.status=='2')
            {
              // console.log("申请宣讲会",n.collegeid);
              this.local.set(Constants.collegeid,this.collegeid);
              this.local.remove(Constants.campusApply)
              this.router.navigate(['home/college-campus-sign'], {
                queryParams: {
                  college:JSON.stringify(n)
                }
              });
            }
            else
            {
              this.toastr.warning('认证失败，请查看原因并重新提交')
              this.router.navigateByUrl('/home/company-authentication');//审核失败页面
            }
          }
          else
          {
            this.toastr.warning('认证失败，请查看原因并重新提交')
            this.router.navigateByUrl('/home/company-authentication');//审核失败页面
          }
        }).catch((err)=>{
          this.toastr.warning('认证失败，请查看原因并重新提交')
          this.router.navigateByUrl('/home/company-authentication');//审核失败页面
        })

      }else{
       // this.opera.remindCould();
        this.local.set(Constants.collegeid,this.collegeid);
              this.local.remove(Constants.campusApply)
              this.router.navigate(['home/college-campus-sign'], {
                queryParams: {
                  college:JSON.stringify(this.collegeid)
                }
              });
      }
    })
  }

  //判断企业是否通过高校认证
  checkDepartmentIndentityByCollege(){
    this.dataApi.checkDepCollegeIdentity(this.collegeid).then((res:any)=>{
      console.log("检测认证信息-----------------------CampusDetails");
      if(res)
      {
        console.log(res);
        this.collegeIdentity = res;
      }

    }).catch((err)=>{
      //console.log(err);
    })
  }

  //获取宣讲会申请记录
  getResumeList(){
    // this.opera.loading();
    this.dataApi.getCampustalkByCollegeid(this.collegeid,this.model).then((res:any)=>{
      //console.log("获取宣讲会申请记录-----");
      //console.log(res);
      if(res)
      {
        this.campusList=res.list;
        if(this.showTips){
          for(let item of this.campusList){
            console.log(item);
            if(item.audit_status == 'passed'){
              //this.singleTips(item);
              this.showTips = false;
              break;
            }
          }
        }
        this.countList=res.count;
        this.pageCount =Math.ceil(res.count/9);
        for(let val of this.campusList){//当前有在审核的宣讲会暂不允许申请新的宣讲会
          if(val.audit_status=='to_audit'){
            this.is_apply =false;
            return;
          }
        }
      }

      // this.opera.loadiss();
    }).catch((err)=>{
      //console.log(err);
      // this.opera.loadiss();
    })
  }

  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getResumeList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }
  }

  /**
   * 下载附件
   */
  downLoadField(){
    console.log("downLoadField",this.college)
    if(this.college.audit_switch.department_receipt_url!=null&&this.college.audit_switch.department_receipt_url!='')
    {
      // window.location.href =this.college.audit_switch.department_receipt_url;
      window.open(this.college.audit_switch.department_receipt_url,'_blank')
    }
    else {
      this.toastr.error('附件不存在');
    }
  }

  //获取合作高校
  mycollegeList() {
    this.dataApi.mycollegeList().then((res: any) => {
      if (res) {
        this.addCollegeList = res;
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  //去教学机构
  goOrg() {
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.local.set(Constants.collegeid, that.college.collegeid);
        that.router.navigateByUrl("home/college-details-org");
      }else {
        //that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.local.set(Constants.collegeid, that.college.collegeid);
          that.router.navigateByUrl("home/college-details-org");
        } else {
          this.pushCollege();
        }
      }
    })

  }
  //去双选会
  goMilk() {

    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        this.router.navigateByUrl("home/college-details-milk");
      } else {
        // that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          this.router.navigateByUrl("home/college-details-milk");
        } else {
          this.pushCollege();
        }
      }
    })

  }
  //去宣讲会
  goCampus() {
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.router.navigateByUrl("home/college-details-campus");
      } else {
        //that.opera.checkMemberAlertWithType("goCampus")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.router.navigateByUrl("home/college-details-campus");
        } else {
          this.pushCollege();
        }
      }
    })
  }
  //去新闻公告
  goNews() {

    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.router.navigateByUrl("home/college-details-news");
      }else {
        //that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.router.navigateByUrl("home/college-details-news");
        } else {
          this.pushCollege();
        }
      }
    })
  }

  //去生源速览
  goStudent() {
    this.local.remove('college-details-student-model');
    this.local.remove('college-details-student-modelp');
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.router.navigateByUrl("home/college-details-student");
      } else {
        //that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.router.navigateByUrl("home/college-details-student");
        } else {
          this.pushCollege();
        }
      }
    })
  }

   //新增高校
  pushCollege() {
    this.isShowPushCollegeModel = true;
    this.pushCollegeModel.initData();
  }

  pushCollegeModelHidden(event) {
    this.isShowPushCollegeModel = false;
    this.mycollegeList();
  }

}
