<div class="topNav">
    <div class="center">
        <div class="flex_l">
            <span (click)="gotoMain()" class="backMain flex_l"><img
                    src="/assets/images/v3/headerReturnIcon.png" />返回首页</span>
        </div>
    </div>
</div>

<div class="info-block">
    <div class="d_f ai_fs no_pass_box" *ngIf="status && status == '3' && remark">
        <img class="no_pass_icon" src="/assets/images/v3/positionPushTip.png" />
        <div>
            <div class="no_pass_title">审核未通过</div>
            <div class="no_pass_cause">未通过原因：<span>{{remark}}</span></div>
            <div class="no_pass_explain">如果您在认证中遇到问题，可致电：4006611396</div>
        </div>
    </div>
    <div class="main">
        <div class="step_title">修改认证信息</div>
        <div class="step_cell flex_l marginTop24">
            <span class="step_cell_title">姓名</span>
            <input class="step_cell_input" [(ngModel)]="auth_model.operator_name" placeholder="请输入姓名">
        </div>

        <div class="chip_cell">请确保和身份证姓名一致</div>
        <div class="step_cell flex_l marginTop24">
            <span class="step_cell_title">身份证号码</span>
            <input class="step_cell_input" [(ngModel)]="auth_model.id_number" placeholder="请输入身份证号码">
        </div>

        <!-- 身份证 -->
        <div class="step_cell_long flex_l marginTop24" style="position: relative;height: 290px;">
            <div class="step_cell_title_long_new">
                手持身份证上传
            </div>
            <div class="uploadBox fn">
                <!-- 国徽面 -->
                <div class="uploadBox_l fn">
                    <label style="cursor: pointer" class="uploadImg-label" for="id_card" *ngIf="!auth_model.id_card">
                        <img class="uploadBox_image uploadBox_image_new" src="assets/images/business_license.png"><br />
                        <div class="uploadBox_title">请上传手持身份证</div>
                    </label>
                    <label style="cursor: pointer" class="uploadImg-label" for="id_card" *ngIf="auth_model.id_card">
                        <img class="uploadBox_image uploadBox_image_new" [src]="auth_model.id_card"><br />
                        <div class="uploadBox_title">修改手持身份证</div>
                    </label>
                    <input class="uploadImg" id="id_card" (change)="previewDepartmentPic($event,'id_card')" type="file" accept="image/jpeg,image/jpg,image/png">
                </div>
                <!-- 反面 -->
                <!-- <div class="uploadBox_l fn">
                    <label style="cursor: pointer" class="uploadImg-label" for="id_card_back" *ngIf="!auth_model.id_card_back">
                        <img class="uploadBox_image uploadBox_image_new" src="assets/images/business_license.png"><br />
                        <div class="uploadBox_title">请上传身份证人像面</div>
                    </label>
                    <label style="cursor: pointer" class="uploadImg-label" for="id_card_back" *ngIf="auth_model.id_card_back">
                        <img class="uploadBox_image uploadBox_image_new" [src]="auth_model.id_card_back"><br />
                        <div class="uploadBox_title">修改身份证人像面</div>
                    </label>
                    <input class="uploadImg" id="id_card_back" (change)="previewDepartmentPic($event,'id_card_back')" type="file" accept="image/jpeg,image/jpg,image/png">
                </div> -->

                <div class="uploadBox_r fn">
                    <div class="uploadBox_r_text uploadBox_r_text_new">
                        <span>1.上传身份证照片需与填写的招聘人姓名、身份证号码保持一致;</span> <br />
                        <span>2.上传身份证照片的身份证信息需清晰可见:</span> <br />
                        <span>3.请提供真实有效的身份证照片;</span> <br />
                        <span>4.上传身份证照片不可标记与工作啦无关的水印或备注;</span><br />
                        <span>5.图片上传格式:PNG，JPG格式,文件不能超过5M;</span> <br />
                        <span>6.证件仅用于审核,不会向第三方透露,请放心上传!</span>
                    </div>
                </div>
            </div>

        </div>

        <!-- 招聘委托变更书 -->
        <div class="step_cell_long flex_l marginTop24" style="position: relative;height: 290px;">
            <div class="step_cell_title_long_new">
                招聘委托变更书<span (click)="downfile(proxy_change_document_example_url)">点击下载《招聘委托变更书》</span>
            </div>
            <div class="uploadBox ">
                <div class="uploadBox_l" style="width: 210px;">
                    <label style="cursor: pointer" class="uploadImg-label" for="proxy_change_document" *ngIf="!auth_model.proxy_change_document">
                        <img class="uploadBox_image uploadBox_image_new" src="assets/images/business_license.png"><br />
                        <div class="uploadBox_title">请上传清晰的招聘委托变更书图片</div>
                    </label>
                    <label style="cursor: pointer" class="uploadImg-label" for="proxy_change_document" *ngIf="auth_model.proxy_change_document">
                        <img class="uploadBox_image uploadBox_image_new" [src]="auth_model.proxy_change_document"><br />
                        <div class="uploadBox_title">修改招聘委托变更书图片</div>
                    </label>
                    <input class="uploadImg" id="proxy_change_document" (change)="previewDepartmentPic($event,'proxy_change_document')" type="file" accept="image/jpeg,image/jpg,image/png">
                </div>
            </div>
        </div>

        <!-- 招聘委托书 -->
        <div class="step_cell_long flex_l marginTop24" style="position: relative;height: 290px; display: none;">
            <div class="step_cell_title_long_new">
                招聘委托书<span (click)="downfile(proxy_document_example_url)">点击下载《招聘委托书》</span>
            </div>
            <div class="uploadBox ">
                <div class="uploadBox_l" style="width: 210px;">
                    <label style="cursor: pointer" class="uploadImg-label" for="proxy_document" *ngIf="!auth_model.proxy_document">
                        <img class="uploadBox_image uploadBox_image_new" src="assets/images/business_license.png"><br />
                        <div class="uploadBox_title">请上传清晰的招聘委托书图片</div>
                    </label>
                    <label style="cursor: pointer" class="uploadImg-label" for="proxy_document" *ngIf="auth_model.proxy_document">
                        <img class="uploadBox_image uploadBox_image_new" [src]="auth_model.proxy_document"><br />
                        <div class="uploadBox_title">修改招聘委托书图片</div>
                    </label>
                    <input class="uploadImg" id="proxy_document" (change)="previewDepartmentPic($event,'proxy_document')" type="file" accept="image/jpeg,image/jpg,image/png">
                </div>
            </div>
        </div>

        <div class="commit_auth flex_c" id="commit_auth_new" (click)="commitAuthClick()">提交</div>
    </div>


</div>
<!-- 尾部 -->
<div class="guide-foot">
    <span>客服热线：{{config.CustomerServicePhone}}</span> &nbsp;&nbsp;&nbsp;&nbsp;{{config.websiteRecord}}
</div>