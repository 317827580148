import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-select-checkbox',
  templateUrl: './select-checkbox.component.html',
  styleUrls: ['./select-checkbox.component.css']
})
export class SelectCheckboxComponent implements OnInit {
  @Input() itemList: Array<any>;
  @Input() title: string;
  //@Input() maxCount: number;
  @Output() selListOut = new EventEmitter();
  public selected: Array<any>;
  public selectName: Array<any>;
  public selectItemList: Array<any>;
  public selectedName: string;
  public isSelectOpen: boolean;
  public maxCount = 5;
  constructor() { }

  ngOnInit() {
    this.selected = [];
    this.selectName = [];
    this.selectItemList = [];
    this.isSelectOpen = false;
    this.selectedName = this.title;
    let thisT = this;
    document.addEventListener("click", (e) =>{
      var target = $(e.target);
      if(target.closest('.select-checkbox-div').length == 0){
        thisT.isSelectOpen = false;
      }
    })
  }
  // 点击了显示框( 是否打开下拉框 )
  clickSelect(){
    this.isSelectOpen = !this.isSelectOpen;
  }
  // 点击整块div执行选择
  checkItem(e, item){
    const ele = e.target;
    const checkbox = ele.previousElementSibling.firstElementChild;
    const action = (checkbox.checked ? 'remove' : 'add');
    console.log(222,this.selectItemList)
    if(!checkbox.checked){
      console.log(111)
      if(this.selectItemList.length >= this.maxCount){
        return;
      }
    }
    this.updateSelected(action, item);
  }
  //   点击input时执行
  clickItem(e, item) {
    let isAdd = true;
    const checkbox = e.target;
    const action = (checkbox.checked ? 'add' : 'remove');
    if(checkbox.checked){
      console.log(111)
      if(this.selectItemList.length >= this.maxCount){
        isAdd = false;
        console.log(2222)
        return;
      }
    }
    if(isAdd){
      console.log(3333)
      this.updateSelected(action, item);
    }
  }
  // 用来判断input 的checked
  isCheck(item) {
    return this.selected.findIndex(value => value == item.id) >= 0;
  }
  initSelect(){
    console.log(111)
    this.selectItemList = [];
    this.selectName = [];
    this.selected = [];
    this.selectedName = this.title;
  }
  //  执行增加、删除
  private updateSelected(action, item) {
    if (action == 'add' && this.selected.findIndex(value => value == item.id) == -1) {
      this.selected.push(item.id);
      this.selectName.push(item.name);
      this.selectItemList.push(item);
    }
    if (action == 'remove' && this.selected.findIndex(value => value == item.id) != -1) {
      this.selectItemList.splice(this.selected.findIndex(value => value == item.id), 1);
      this.selectName.splice(this.selected.findIndex(value => value == item.id), 1);
      this.selected.splice(this.selected.findIndex(value => value == item.id), 1);
    }
    if(this.selectName.length === 0){
      this.selectedName = this.title;
    }else{
      this.selectedName = this.selectName.join(",");
    }
    this.selListOut.emit(this.selectItemList);
  }
}
