<!--<div class="contentRight-header">-->
<!--<img (click)="opera.goPre()" src='assets/images/router-old.png'/>-->
<!--校招工具-->
<!--</div>-->

<!--<app-navigation-route></app-navigation-route>-->

<!-- 主体内容部分 -->
<div class="homepage-block">
    <div class="secondNav">
        <div class="item " (click)="myTool()">我的工具</div>
        <div class="item " (click)="myOrder()">我的订单</div>
        <div class="item active">我的发票<span></span></div>
        <div class="item" (click)="myLadou()">啦豆明细</div>
    </div>

    <div class="box">
        <div class="d_f tabBg">
            <div class="f_1 custom_tabs">
                <div (click)="tabClick('1')" class="custom_tab" [ngClass]="{active:tab=='1'}">电子发票</div>
                <div (click)="tabClick('2')" class="custom_tab" [ngClass]="{active:tab=='2'}">已开票记录</div>
            </div>
            <!-- <div [ngClass]="{tabBg_span_s:tab=='1',tabBg_span:tab!='1'}" (click)="tabClick('1')">电子发票</div>
            <div [ngClass]="{tabBg_span_s:tab=='2',tabBg_span:tab!='2'}" (click)="tabClick('2')">已开票记录</div> -->
            <div class="tabBg_r" (click)="receiptNotice()">发票须知</div>
            <div class="tabBg_r flex_c" (click)="rushListClick()"> <img class="rush_pay_icon" src="../../assets/images/rush_pay.png">刷新</div>
        </div>


        <div class="college-block" *ngIf="tab=='1'">
            <!--<div class="flex_r allSeleteRow">-->
            <!--<div class="flex_r" style="cursor: pointer" >-->
            <!--<img src="../../assets/images/downresume_none.png" (click)="electionBtn()" *ngIf="!allSelect" />-->
            <!--<img src="../../assets/images/downresume_selete.png" (click)="electionBtn()" *ngIf="allSelect" />-->
            <!--<span [ngClass]="{'allText':allSelect,'allText_none':!allSelect}" (click)="electionBtn()">全选</span>-->
            <!--<span style="margin-left: 15px" [ngClass]="{'allText':seleteCount>0,'allText_none':seleteCount<=0}" (click)="hebing()">合并开票</span>-->
            <!--</div>-->
            <!--</div>-->
            <div class="historyBox">
                <div class="invoiceCell" *ngFor="let n of orderList ;let i = index;" (click)="inputClick($event,n,i)">
                    <!--<img class="cellSelete" *ngIf="n.selete" src="../../assets/images/mesure.png" />-->
                    <div class="d_f jc_sb invoiceHead">
                        <div class="d_f">
                            <span class="invoiceHead_state">{{n.order_state_text}}</span>
                            <span *ngIf="n.pay_orderid">订单号：{{n.pay_orderid}}</span>
                        </div>
                        <span>{{n.order_navData}}</span>
                        <!-- <div class="flex_r">
                            支付金额：
                            <div class="invoice_price">{{n.amount_payable}}</div>
                        </div> -->
                    </div>
                    <div class="d_f invoiceBottom">
                        <div class="f_1 d_f fw_w invoiceBottom_l">
                            <div *ngFor="let item of n.product_package_list">{{item.product_name}}</div>
                        </div>
                        <div class="invoice_price">支付金额：<span>￥{{n.amount_payable}}</span></div>
                        <div class="custom_btn_plain invoiceBottomBtn" (click)="gotoInvoiceModel(n)"><img src="../../assets/images/v3/notice.png">申请开票</div>
                    </div>
                </div>
                <!-- 分页部分 -->
                <div class='paging-block' [class.isPageHeight]="countList>0">
                    <router-outlet></router-outlet>
                    <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
                </div>
            </div>
            <!-- 空状态 -->
            <div class="none-block-empty" *ngIf="orderList.length == 0">
                <img src="assets/images/rights/empty.png">
                <p>暂无开票记录</p>
            </div>
        </div>

        <!--已开票记录-->
        <div class="historyBox borderNone" *ngIf="tab=='2'">
            <table class="layui-table" *ngIf="endInvoiceList.length>0">
                <thead>
                    <tr>
                        <th>购买商品</th>
                        <th>支付金额</th>
                        <th>申请时间</th>
                        <th>开票状态</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let b of endInvoiceList">
                        <td>{{b.item_name}}</td>
                        <td>{{b.amount}}</td>
                        <td>{{b.add_time | date:'yyyy-MM-dd HH:mm'}}</td>
                        <td>{{b.invoice_status_text}}</td>
                        <td><span style="color: #5e4fff" *ngIf="b.status=='error'" (click)="editPay(b)">重新提交</span></td>
                    </tr>
                </tbody>
            </table>
            <!-- 分页部分 -->
            <div class='paging-block' [class.isPageHeight]="countList>0">
                <router-outlet></router-outlet>
                <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEventTwo($event)"></app-paging>
            </div>
            <!-- 空状态 -->
            <div class="none-block-empty" *ngIf="endInvoiceList.length == 0">
                <img src="assets/images/rights/empty.png">
                <p>暂无已开票记录</p>
            </div>
        </div>

    </div>
</div>