<!-- 引导页面-->
<div class="page_guide">
  <div class="contentRight-header flex_l" (click)="goBackStep()"><img style="margin-top: -2px;"
      src='assets/images/router-old.png' />返回</div>
  <router-outlet></router-outlet>
  <app-model-address-add #address [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-address-add>
  <app-model-company-label #label [isShow]="modelTwoShow" (onVoted)="ModelTwodismiss($event)"></app-model-company-label>
  <app-model-company-product #product [isShow]="modelThreeShow"
    (onVoted)="ModelThreedismiss($event)"></app-model-company-product>
  <app-model-company-courses #courses [isShow]="modelFourShow"
    (onVoted)="ModelFourdismiss($event)"></app-model-company-courses>
  <app-model-company-strength #strength [isShow]="modelFiveShow"
    (onVoted)="ModelFivedismiss($event)"></app-model-company-strength>
  <app-model-cropper #crop [isShow]="modelSixShow" (onVoted)="ModelSixdismiss($event)"></app-model-cropper>
  <div class="d_f page_guide_head">
    <img class="page_guide_head_img" src="assets/images/v3/loginLogo.png">
    <div class="page_guide_head_text">招大学生，就用「工作啦」</div>
  </div>
  <div class="page_guide_main">
    <div class="d_f page_guide_step">
      <img class="page_guide_step_img" src="assets/images/v3/index2.png">
      <div class="page_guide_step_text">完善企业信息</div>
    </div>
    <div *ngIf="myDepartment" class="page_guide_upload" style="padding-top: 50px;">
      <div class="page_guide_upload_title">上传企业LOGO</div>
      <label class="page_guide_upload_box" *ngIf="!myDepartment.logo" for="uploadDepatmentLogoImg">
        <img class="page_guide_upload_addbtn" src="assets/images/v3/companyPush.png">
      </label>
      <label class="page_guide_upload_box" *ngIf="myDepartment.logo" for="uploadDepatmentLogoImg">
        <img class="page_guide_upload_img" [src]="myDepartment.logo">
      </label>
      <input class="uploadImg" id="uploadDepatmentLogoImg" (change)="previewPic($event)" type="file"
        accept="image/jpeg,image/jpg,image/png">
      <div class="page_guide_upload_explain">点击上传公司LOGO</div>
      <div class="page_guide_upload_explain">像素：200*200px,格式：jpg,png</div>
    </div>
    <!-- 注册资金 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">注册资金<span class="remind_red">（必填 ）</span></div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="d_f">
          <div class="f_1 page_guide_cell_input">
            <input class="w_100 input" type="number" (ngModelChange)="register_capitalChange($event)"
              [(ngModel)]="model.register_capital" maxlength="40" autofocus="autofocus" placeholder="请填写公司注册资金">
          </div>
          <div class="page_guide_cell_input icon" style="width: 112px;margin-left: 10px">
            <select class="w_100 input" [(ngModel)]="register_capital_type" autocomplete="off">
              <option value="万元">万元</option>
              <option value="亿元">亿元</option>
            </select>
          </div>
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
    </div>
    <!-- 公司地址 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">公司地址<span class="remind_red">（必填 ）</span></div>
      <div *ngIf="workPlace" class="f_1 page_guide_cell_inputbox" style="margin-right: 20px;">
        <div class="page_guide_cell_input icon">
          <select class="w_100 input" autocomplete="off" [(ngModel)]="workPlace">
            <option class="place-text" value='' disabled hidden selected="selected">选择公司地址</option>
            <option *ngFor="let address of myDepartment.locations" [value]="address.lid">
              {{address.region_text}}&nbsp;&nbsp;{{address.address}}</option>
          </select>
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
      <div class="d_f jc_sb page_guide_cell_btnbox">
        <button class="page_guide_cell_addbtn" (click)="goAddAddress()">添加公司地址</button>
      </div>
    </div>
    <!-- 成立年份 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">成立年份<span class="remind_red">（必填 ）</span></div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input suffix" data-suffix="年">
          <input class="w_100 input" type="number" [(ngModel)]="model.establish_year" placeholder="请输入成立年份">
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
    </div>
    <!-- 员工人数 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">员工人数</div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input icon">
          <select class="w_100 input" [class]="!model.scale?'placholder':''" autocomplete="off"
            [(ngModel)]="model.scale">
            <option class="place-text" value='' disabled hidden selected="selected">选择员工人数</option>
            <option *ngFor="let scale of config.scale" [value]="scale.code">{{scale.text}}</option>
          </select>
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
    </div>
    <!-- 公司简介 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">公司简介<span class="remind_red">（必填 ）</span></div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input">
          <textarea class="w_100 input" style="height: 193px;" [(ngModel)]="model.intro" minlength="50" maxlength="500"
            placeholder="请填写公司简介（50-500字）"></textarea>
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
    </div>
    <!-- 是否上市 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">是否上市</div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input icon">
          <select class="w_100 input" autocomplete="off" [(ngModel)]="model.is_quoted_company">
            <option class="place-text" value='' disabled hidden selected="selected">选择是否上市</option>
            <option [value]="true">上市公司</option>
            <option [value]="false">非上市公司</option>
          </select>
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
    </div>
    <!-- 股票代码 -->
    <div *ngIf="model.is_quoted_company" class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">股票代码</div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input">
          <input class="w_100 input" type="text" [(ngModel)]="model.stock_code" placeholder="请填写公司股票代码">
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
    </div>
    <!-- 公司实力 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">公司实力</div>
      <div *ngIf="model.strengths.length" class="f_1 page_guide_cell_inputbox" style="margin-right: 20px;">
        <div class="fw_w page_guide_cell_input">
          <div class="label-item purple" *ngFor="let label of model.strengths">
            <div class="label-text">{{label.text}}</div>
          </div>
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
      <div class="d_f jc_sb page_guide_cell_btnbox">
        <button class="page_guide_cell_addbtn" (click)="goStrengths()">添加公司实力</button>
      </div>
    </div>
    <!-- 福利待遇 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">福利待遇</div>
      <div *ngIf="model.labels.length" class="f_1 page_guide_cell_inputbox" style="margin-right: 20px;">
        <div class="fw_w page_guide_cell_input">
          <div class="label-item purple" *ngFor="let label of model.labels">
            <div class="label-text">{{label.text}}</div>
          </div>
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
      <div class="d_f jc_sb page_guide_cell_btnbox">
        <button class="page_guide_cell_addbtn" (click)="goLabel()">添加福利待遇</button>
      </div>
    </div>
    <!-- 公司网址 -->
    <div *ngIf="model.is_quoted_company" class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">公司网址</div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input">
          <input class="w_100 input" type="text" [(ngModel)]="model.website" placeholder="请填写公司网址">
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
    </div>
    <!--按钮-->
    <div class="ta_c">
      <button class="page_guide_button" (click)="updateDepartment()">提交</button>
    </div>
  </div>
  <!-- 尾部 -->
  <div class="d_f page_guide_footer">
    <div class="f_1 page_guide_footer_logout" (click)="logoutAccountClick()">注销账号</div>
    <div class="d_f jc_sb" style="width:1100px">
      <div class="page_guide_footer_service">客服热线：{{config.CustomerServicePhone}}</div>
      <div class="page_guide_footer_record">{{config.websiteRecord}}</div>
    </div>
    <div class="f_1"></div>
  </div>
</div>