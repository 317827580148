import {Component, Inject, OnInit} from '@angular/core';
import {Config, DataApi, Local} from "../../provider";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {ConfirmModel} from "../none-vip-alert/none-vip-alert.component";
import {payModel} from "../model-pay-code/model-pay-code.component";


export interface titleModel {
  title:string;
  type:''
}


@Component({
  selector: 'app-department-self-alert',
  templateUrl: './department-self-alert.component.html',
  styleUrls: ['./department-self-alert.component.css']
})
export class DepartmentSelfAlertComponent implements OnInit {

  public config: Config;

  public title = "更多优惠，请联系校招顾问";
  public type = ""
  constructor(
    public local:Local,
    public dataApi: DataApi,
    @Inject(MAT_DIALOG_DATA) public data: titleModel
  ) {
    console.log('DepartmentSelfAlertComponent',data);
    this.config = Config.getInstance();//实例化config
    if(data)
    {
      if(data.title)
      {
        this.title = data.title;
      }
      if(data.type)
      {
        this.type = data.type;
      }

    }

  }

  ngOnInit() {
    if(this.local.get('departmentSelf'))
    {
      this.config.departmentSelf = JSON.parse(this.local.get('departmentSelf'))
      console.log('获取左侧栏目的校招顾问',this.config.departmentSelf);
    }
    else
    {

      this.dataApi.getRecruitmentSelf().then((res:any)=>{
        console.log('获取左侧栏目的校招顾问',res);
        if(res)
        {
          this.config.departmentSelf =res;
          this.local.set('departmentSelf',JSON.stringify(res))
        }
      }).catch((error) => {
        console.error(error);
      });

    }


  }

}
