import { Component, OnInit,Output,ViewChild } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import { ModelCollegeMilksignComponent } from '../model-college-milksign/model-college-milksign.component';
@Component({
  selector: 'app-college-details-preach',
  templateUrl: './college-details-preach.component.html',
  styleUrls: ['./college-details-preach.component.css']
})
export class CollegeDetailsPreachComponent implements OnInit {
  public config: Config;
  // @ViewChild('milkUpdata') child: ModelCollegeMilksignComponent;
  @Output() modelShow = false;//是否打开弹窗
  @Output() milkNumber = 1;//当前申请的招聘会场次
  @Output() mridList=[];//招聘会id列表
  public data:any;// 宣讲会详情
  public ctid:any; // 宣讲会id
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService,
              private activatedRoute: ActivatedRoute,

  ) {
    this.config = Config.getInstance();//实例化config
    activatedRoute.queryParams.subscribe(queryParams => {
      console.log('activatedRoute',queryParams)
      if(queryParams.ctid) {
        this.local.set('ctid',queryParams.ctid)
      }
    })
  }
  ngOnInit() {
    this.opera.initConfig('college','position-campus');
    this.getCampusInfo();
  }

  // 企业获取宣讲会详情
  getCampusInfo(){
    this.opera.loading();
    this.dataApi.getCampusInfo(this.local.get(Constants.ctid)).then((res:any)=>{
      if(res)
      {
        console.log('企业获取宣讲会详情',res);
        //cooperation_status 2
        //audit_status "passed"
        this.opera.loadiss();

        this.data = res;
        this.data.isEnd = false
        let nowDate = new Date()
        let endDate = new Date( this.data.end_time)
        if(nowDate>endDate){
          this.data.isEnd = true
        }
        console.log(this.data);
      }

    }).catch((error)=>{
      this.toastr.error(error);
      this.opera.loadiss();
    })
  }


    //申请宣讲会(再次申请)
    goSign(collegeid){
      if(this.config.positionList.length==0){
        this.opera.remindPublish("申请宣讲会");
        return;
      }
      this.opera.checkCompanyIsBack(collegeid,()=> {

          this.dataApi.checkDepCollegeIdentity(collegeid).then((res:any)=>{
          //console.log("获取企业在高校的认证信息-----------------------CampusDetails");
          //console.log(res);
          if(res){
            if(res.gwl_status=='2'){//通过工作啦认证
              this.local.set(Constants.collegeid,collegeid);
              this.local.remove(Constants.campusApply);
              this.router.navigate(['home/college-campus-sign']);
            }else{//未通过工作啦审核
              if(res.status=='2'){//通过校招
                this.local.set(Constants.collegeid,collegeid);
                this.local.remove(Constants.campusApply);
                this.router.navigate(['home/college-campus-sign']);
              }else if(res.status=='1'){//校招审核中
                this.toastr.warning("高校合作审核中，审核通过后才可预约宣讲会");
              }else{//未提交校招申请
                this.opera.remindCollegeIdentity("预约宣讲会",collegeid,res);
              }
            }
          }else{
            this.opera.remindCollegeIdentity("预约宣讲会",collegeid,res);
          }
        }).catch((err)=>{
          //console.log(err);
          this.toastr.error(err);
        })
      })
    }

    gotoTongzhi(){
      this.local.set('activityInvitationData',JSON.stringify(this.data));
      this.local.set('activityInvitationDataType','campustalk');
      this.router.navigateByUrl('/home/activityInvitation');// 活动通知单品页面
    }
}
