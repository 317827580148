<app-resume-label-model #resumeLabelModel [isShow]="addResumeLabelmodelShow" (modelHidden)="addResumeLabelmodeHidden($event)">
</app-resume-label-model>
<div class="audio-conference">
    <app-navigation-route></app-navigation-route>
    <div class="topTip">在此期间请不要关闭此页面及摄像头，保持视频在线可吸引更多人才投递。</div>
    <!--左侧-->
    <div class="interviewMain">
        <div class="top" [class.active]='!roomState || roomOver'>
            <div class="roomStateBg" *ngIf="!roomState"><img src="assets/images/InterviewRoom/suspend.png"></div>
            <div class="roomStateBg" *ngIf="roomOver"><img src="assets/images/InterviewRoom/roomOver.png"></div>
            <!--视频-->
            <!-- <div class="department" [class.active]='!cameraLens' id="video-department" (click)="switchUser('hr')"></div>
            <div class="student" [class.active]='cameraLens' id="video-student" (click)="switchUser('talent')"></div> -->
            <div class="studentQn" [class.active]='cameraLens' id="video-student">
                <div class="jiaobiao" *ngIf="talentBaseName">
                    <img src="assets/images/InterviewRoom/jiaobiao.png">
                    <span class="jiaobiaoName">{{talentBaseName}}</span>
                </div>
            </div>
            <div class="departmentQn" [class.active]='!cameraLens' id="video-department"></div>
            <!--操控区-->
            <div class="operation">
                <div class="btn-list-item" *ngIf="roomState">
                    <div class="btn-item" (click)="triggerMike()">
                        <img *ngIf="audio" src="assets/images/InterviewRoom/mike.png">
                        <img *ngIf="!audio" src="assets/images/InterviewRoom/mikeActive.png"> {{audio?'关闭':'开启'}}麦克风
                    </div>
                    <div class="btn-item" (click)="triggerCamera()">
                        <img *ngIf="video" src="assets/images/InterviewRoom/camera.png">
                        <img *ngIf="!video" src="assets/images/InterviewRoom/cameraActive.png"> {{video?'关闭':'开启'}}摄像头
                    </div>
                </div>
                <div class="mianshi_Btn blue blueStreamer" *ngIf="!interviewStuts && !roomState" (click)="recoveryview()">恢复面试间</div>
                <div class="mianshi_Btn yellow" *ngIf="!interviewStuts && roomState" (click)="RoomPauseview()">暂停面试间</div>
                <div class="mianshi_Btn" *ngIf="interviewStuts" (click)="cloceShowUpdate()">中断面试</div>
                <div class="mianshi_Btn" *ngIf="interviewStuts" (click)="overInterview()">结束面试</div>

            </div>
        </div>
        <!--人才列表/消息列表-->
        <div class="bottom-list">
            <div class="navigation">
                <div class="navigation-item" [class.ishidden]='navShow =="wait"' (click)="navShow='wait'">待面试<span></span></div>
                <div class="navigation-item" [class.ishidden]='navShow =="whole"' (click)="navShow='whole'">收到简历<span></span></div>
                <div class="navigation-item" [class.ishidden]='navShow =="news"' (click)="navShow='news'">消息列表
                    <div class="spot" *ngIf="spot"></div>
                    <span></span>
                </div>
                <!--公告-->
                <div class="noticeRightIcon" title="发送公告" (click)="openNotice()">发送公告</div>
            </div>
            <!--待面试-->
            <div class="personnel-list" *ngIf="navShow =='wait'">
                <div class="personnel-item" *ngFor="let item of personnel_list;let i = index">
                    <div *ngIf="personnel_list.length>0">
                        <div class="lef" (click)="resumeClick(item)">
                            <div class="avatar">
                                <img [src]="item.queuedUser.user.avatar">
                                <span [class.active]='item.onlineStatus.isOnline'></span>
                            </div>
                            <div class="name" [class.active]='item.msz'>
                                <div>{{item.queuedUser.user.name}}</div>
                                <div class="post-box">面试岗位：<b>{{item.queuedUser.position_name}}</b></div>
                            </div>

                        </div>
                        <div class="rightbtn">
                            <!-- <div class="personnel-item-btn" *ngIf="item.onlineStatus.inRommOnLine && !interviewStuts" (click)="invitation_student_OperationPassed(item)">
                                <img style="width: 14px;" src="assets/images/contact-refuse.png">过号处理
                            </div> -->
                            <div class="personnel-item-btn" *ngIf="!item.onlineStatus.inRommOnLine && item.queuedUser.notice_count<3" title="通知用户上线({{item.queuedUser.notice_count}}/3)" (click)="invitation_student_notice(item)">
                                <img style="width: 20px;" src="assets/images/InterviewRoom/notice.png">通知上线
                            </div>
                            <div class="personnel-item-btn" *ngIf="item.onlineStatus.inRommOnLine && !interviewStuts" (click)="invitation_student_isFinish(item)">
                                <img style="width: 20px;" src="assets/images/InterviewRoom/isFinish.png">完成面试
                            </div>
                            <div class="personnel-item-btn" (click)="msgstudentChat(item.queuedUser.user.id)"><img class="famsg" src="assets/images/InterviewRoom/famsg.png">发送消息</div>
                            <div class="personnel-item-btn" *ngIf="item.onlineStatus.inRommOnLine && !roomOver" (click)="invitation_student(item)">
                                <img src="assets/images/InterviewRoom/room__start.png">开始面试
                            </div>
                        </div>
                    </div>
                </div>
                <div class="empty" *ngIf="personnel_list.length == 0">
                    <img src="assets/images/rights/empty.png">
                    <p>暂无等待学生</p>
                </div>

            </div>
            <!--收到简历-->
            <div class="personnel-list" *ngIf="navShow =='whole'">
                <div class="personnel-item " *ngFor="let item of wholeList;let i = index">
                    <div class="fn" *ngIf="wholeList.length>0">
                        <div class="lef" (click)="resumeClick(item)">
                            <div class="avatar"><img [src]="item.queuedUser.user.avatar"></div>
                            <div class="nameBox">
                                <div class="name">{{item.queuedUser.user.name}}</div>
                                <div class="post-box">面试岗位：<b>{{item.queuedUser.position_name}}</b></div>
                            </div>
                        </div>
                        <div class="isFinish" *ngIf="item.queuedUser.isFinish">(已面试)</div>
                    </div>
                </div>
                <div class="empty" *ngIf="wholeList.length == 0">
                    <img src="assets/images/rights/empty.png">
                    <p>暂无收到简历</p>
                </div>
            </div>
            <!--消息列表-->
            <div class="personnel-list" *ngIf="navShow =='news'">
                <div *ngFor="let item of msgList;let i = index">
                    <div *ngIf="msgList.length>0">
                        <div class="personnel-item active" (click)="msgstudentChat(item.id)">
                            <div class="lef">
                                <div class="avatar"><img [src]="item.avatar"></div>
                                <div class="name">{{item.name}}<span *ngIf="item.Unread && item.Unread>0">{{item.Unread}}</span></div>
                                <!-- <div class="lastMsg">{{item.lastMsg}}</div> -->
                                <!-- <div class="lastTime">{{item.lastTime|date:'yyyy/MM/dd HH:mm:ss'}}</div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="empty" *ngIf="msgList.length == 0">
                    <img src="assets/images/rights/empty.png">
                    <p>暂无消息</p>
                </div>

            </div>

        </div>

    </div>
    <!--右侧-->
    <div class="right__container">
        <!--面试者简历-->
        <div class="main __resume" *ngIf="resumeShow">
            <!-- <div class="_top">
                <img src="assets/images/InterviewRoom/resume.png">
                <span>简历</span>
            </div> -->
            <div class="scroll-y">
                <div class="resumeCell">
                    <div class="_n">
                        <img class="logo" *ngIf="resumeDetails.talent_base && resumeDetails.talent_base.logo" [src]="resumeDetails.talent_base.logo">
                        <img class="logo" *ngIf="resumeDetails.talent_base && !resumeDetails.talent_base.logo" src="assets/images/InterviewRoom/photo.png">
                    </div>
                    <div class="_r">
                        <div class="name" *ngIf="resumeDetails.talent_base && resumeDetails.talent_base.name">{{resumeDetails.talent_base.name}}</div>
                        <div class="text" *ngIf="resumeDetails.talent_base">
                            {{resumeDetails.talent_base.gender == '01'?'男':'女'}} | {{resumeDetails.talent_base.address_text?resumeDetails.talent_base.address_text:'暂无'}} | {{resumeDetails.talent_base.education_text ? resumeDetails.talent_base.education_text:'暂无'}} | {{resumeDetails.talent_base.year
                            ? resumeDetails.talent_base.year +"届": '往届'}}
                        </div>
                    </div>
                </div>
                <div class="contact">
                    <div class="item" *ngIf="resumeDetails.talent_base && resumeDetails.talent_base.mobile"><img src="assets/images/InterviewRoom/Telephone.png">{{resumeDetails.talent_base.mobile}}</div>
                    <div class="item active" *ngIf="resumeDetails.talent_base && resumeDetails.talent_base.email"><img src="assets/images/InterviewRoom/mailbox.png">{{resumeDetails.talent_base.email}}</div>
                </div>

                <!--求职意向-->
                <div class="_title" *ngIf="resumeDetails.pro_skills && resumeDetails.pro_skills.length>0">求职意向</div>
                <div class="module educationCell" *ngIf="resumeDetails.pro_skills && resumeDetails.pro_skills.length>0">
                    <div class="_item" *ngFor="let item of resumeDetails.pro_skills;let i=index">
                        <div class="university">
                            <div class="name">{{item.text}}</div>
                        </div>
                        <div class="li" *ngIf="resumeDetails.job_target && resumeDetails.job_target.expect_salary_text">
                            {{resumeDetails.job_target.expect_salary_text}}
                        </div>
                        <div class="li" *ngIf="resumeDetails.job_target && resumeDetails.job_target.work_citys || resumeDetails.job_target.work_nature_text">
                            {{resumeDetails.job_target.work_citys}} <span *ngIf="resumeDetails.job_target && resumeDetails.job_target.work_nature_text">| {{resumeDetails.job_target.work_nature_text}}</span>
                        </div>
                        <div class="li" *ngIf="resumeDetails.job_target && resumeDetails.job_target.industrys">
                            {{resumeDetails.job_target.industrys}}
                        </div>
                    </div>
                </div>


                <!--教育经历-->
                <div class="_title" *ngIf="resumeDetails.education_backgrounds && resumeDetails.education_backgrounds.length>0">教育经历</div>
                <div class="module educationCell" *ngIf="resumeDetails.education_backgrounds && resumeDetails.education_backgrounds.length>0">
                    <div class="_item" *ngFor="let item of resumeDetails.education_backgrounds;let i=index">
                        <div class="university">
                            <div class="name">{{item.graduation_school}}</div>
                            <div class="time">{{ item.start_date_str+' - '+item.end_date_str }}</div>
                        </div>
                        <div class="li">
                            {{item.major_status_text}}
                        </div>
                        <div class="li">
                            {{item.education_text}}
                        </div>
                    </div>
                </div>

                <!--自我描述-->
                <div class="_title" *ngIf="resumeDetails.skills && resumeDetails.skills.length>0">自我描述</div>
                <div class="module descriptionBox" *ngIf="resumeDetails.skills && resumeDetails.skills.length>0">
                    <div class="l_descriptionBox">
                        <div class="l_descriptionBox_labels">
                            <div *ngFor="let item of resumeDetails.skills;let i=index" class="l_descriptionBox_labels_item">{{item.text}}</div>
                        </div>
                        <span class="l_descriptionBox_text">{{resumeDetails.self_evaluates==null?"":resumeDetails.self_evaluates}}</span>
                    </div>
                </div>
                <!--实践经历-->
                <div class="_title" *ngIf="resumeDetails.work_experiences && resumeDetails.work_experiences.length>0">实践经历</div>
                <div class="module educationCell" *ngIf="resumeDetails.work_experiences && resumeDetails.work_experiences.length>0">
                    <div class="_item" *ngFor="let item of resumeDetails.work_experiences;let i=index">
                        <div class="university">
                            <div class="name">{{item.company_name}}</div>
                            <div class="time">{{item.start_date_str+' - '+item.end_date_str }}</div>
                        </div>
                        <div class="university">
                            <div class="name">{{item.position}} {{item.salary_text}}</div>
                        </div>
                        <div class="li">
                            <span>{{item.description}}</span>
                        </div>
                    </div>
                </div>
                <!--奖励证书-->
                <div class="_title" *ngIf="resumeDetails.certificates && resumeDetails.certificates.length>0">奖励证书</div>
                <div class="module educationCell" *ngIf="resumeDetails.certificates && resumeDetails.certificates.length>0">
                    <div class="_item" *ngFor="let item of resumeDetails.certificates;let i=index">
                        <div class="university">
                            <div class="name">{{item.name}}</div>
                            <div class="time">{{item.get_date_str}}</div>
                        </div>
                    </div>
                </div>
                <!--在校职位-->
                <div class="_title" *ngIf="resumeDetails.school_positions && resumeDetails.school_positions.length>0">在校职位</div>
                <div class="module educationCell" *ngIf="resumeDetails.school_positions && resumeDetails.school_positions.length>0">
                    <div class="_item" *ngFor="let item of resumeDetails.school_positions;let i=index">
                        <div class="university">
                            <div class="name">{{item.school_position}}</div>
                        </div>
                    </div>
                </div>
                <!--作品展示-->
                <div class="_title" *ngIf="resumeDetails.works && resumeDetails.works.length>0">作品展示</div>
                <div class="module educationCell" *ngIf="resumeDetails.works && resumeDetails.works.length>0">
                    <div class="_item" *ngFor="let item of resumeDetails.works;let i=index">
                        <div class="university">
                            <div class="name">{{item.name}}</div>
                        </div>
                        <div class="university">
                            <div class="name" *ngIf="item.link">{{item.link}}</div>
                        </div>
                        <div class="li">
                            <span>{{item.description}}</span>
                        </div>
                        <div class="image_url" *ngFor="let src of item.image_url">
                            <img [src]="src">
                        </div>
                    </div>
                </div>
                <!--语言能力-->
                <div class="_title" *ngIf="resumeDetails.languages && resumeDetails.languages.length>0">语言能力</div>
                <div class="module educationCell" *ngIf="resumeDetails.languages && resumeDetails.languages.length>0">
                    <div class="_item" *ngFor="let item of resumeDetails.languages;let i=index">
                        <div class="university">
                            <div class="name">{{item.language_text }} {{item.level_text}}</div>
                        </div>
                    </div>
                </div>
                <!--项目经历-->
                <div class="_title" *ngIf="resumeDetails.project_experiences && resumeDetails.project_experiences.length>0">项目经历</div>
                <div class="module educationCell" *ngIf="resumeDetails.project_experiences && resumeDetails.project_experiences.length>0">
                    <div class="_item" *ngFor="let item of resumeDetails.project_experiences;let i=index">
                        <div class="university">
                            <div class="name">{{item.name}}</div>
                            <div class="time">{{item.start_date_str}} - {{item.end_date_str}}</div>
                        </div>
                        <div class="li">
                            <span>{{item.description}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!--简历批注-->
            <div class="Annotation">
                <div class="heder">
                    简历批注<span></span>
                    <img (click)="editresumeLabelClick()" class="editLabel" src="/assets/images/edit_label.png">
                </div>
                <div class="labs" *ngIf="resumeLabelDetail.resume_labels && resumeLabelDetail.resume_labels.length>0">
                    <span *ngFor="let item of resumeLabelDetail.resume_labels">{{item.name}}</span>
                </div>
                <div class="Annotation-inputBG">
                    <div class="Annotation-input" *ngIf="resumeLabelDetail.remark">{{resumeLabelDetail.remark}}</div>
                    <!-- <textarea placeholder="请输入简历批注" [(ngModel)]="remarkText" class="Annotation-input"></textarea>
                    <button (click)="saveAnnotation()" class="sengBtn">保存</button> -->
                </div>
            </div>
        </div>
        <!--聊天窗口-->
        <div class="main __chat" *ngIf="chat01">
            <div class="_top">
                <img [src]="itemMsgList.avatar">
                <span>{{itemMsgList.name}}</span>
            </div>
            <div class="scroll-y" id="chatScroll-y" #scrollsL (scroll)="chatScrolly01Scroll($event)">
                <!-- 自己的消息框 -->
                <div class="cell" *ngFor="let item of itemMsgList.msg">
                    <div *ngIf="item.isme">
                        <div class="msg_time_r">{{item.eventTime|date:'yyyy/MM/dd HH:mm:ss'}}</div>
                        <div class="msgContent_row">
                            <div class="msg_lView_my">
                                <img class="otherUserPic" [src]="roomData.owner.avatar">
                            </div>
                            <div class="msg_RView_my">
                                <div class="msgContent_span_my">{{item.content}}</div>
                            </div>
                        </div>
                    </div>

                    <!-- 聊天的对象的消息框 -->
                    <div *ngIf="!item.isme">
                        <div class="msg_time">{{item.eventTime|date:'yyyy/MM/dd HH:mm:ss'}}</div>
                        <div class="msgContent_row">
                            <div class="msg_lView">
                                <img class="otherUserPic" [src]="itemMsgList.avatar">
                            </div>
                            <div class="msg_RView">
                                <div class="msgContent_span">{{item.content}}</div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
            <div class="chat-inputBG">
                <textarea placeholder="我想说点什么呢（Enter可快捷发送）" (keyup)="inputKeyup($event)" [(ngModel)]="msgText" class="chat-input"></textarea>
                <button (click)="sengMsg()" class="sengBtn blueStreamer">发送</button>
            </div>
        </div>
        <!--公告-->
        <div class="main __chat" *ngIf="openNoticeShow">
            <div class="_top"><span>公告列表</span></div>
            <div class="scroll-y" id="chatScroll-y02" #scrollGg (scroll)="chatScrolly02Scroll($event)">
                <!-- 自己的消息框 -->
                <div class="cell" *ngFor="let item of openNoticeList">
                    <div class="msg_time_r">{{item.eventTime|date:'yyyy/MM/dd HH:mm:ss'}}</div>
                    <div class="msgContent_row">
                        <div class="msg_lView_my">
                            <img class="otherUserPic" [src]="roomData.owner.avatar">
                        </div>
                        <div class="msg_RView_my">
                            <div class="msgContent_span_my">{{item.content}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat-inputBG">
                <textarea placeholder="我想说点什么呢（Enter可快捷发送）" (keyup)="gginputKeyup($event)" [(ngModel)]="ggmsgText" class="chat-input"></textarea>
                <button (click)="sendpublic()" class="sengBtn blueStreamer">发送</button>
            </div>
        </div>
    </div>
</div>

<div class="rightPbox">
    <!--操作指南-->
    <a class="guide" href="https://activity.goworkla.cn/guide/index.html?v1.0" target="_blank">
        <img src="assets/images/InterviewRoom/guide.png">
        <p>操作指南</p>
    </a>
    <!--设备检测-->
    <a class="testing" href="https://webdemo.agora.io/agora_webrtc_troubleshooting/" target="_blank">
        <img src="assets/images/InterviewRoom/testing.png">
        <p>设备检测</p>
    </a>
</div>

<!--等待弹窗-->
<p-dialog [closeOnEscape]="false" [closable]="false" [(visible)]="ShowLoding" [style]="{'width':400,'height':400,'minHeight':400}" [modal]=true>
    <div class="ShowLoding" style="width: 400px;height:300px">
        <div class="ShowLoding_model">
            <div class="ShowLoding_Mloader">
                <div class="_dot"></div>
                <div class="_dot"></div>
                <div class="_dot"></div>
                <div class="_dot"></div>
                <div class="_dot"></div>
            </div>
        </div>
        <p *ngIf="onstudentData.queuedUser && onstudentData.queuedUser.user && onstudentData.queuedUser.user.name">等待学生【{{onstudentData.queuedUser.user.name}}】接听....</p>
        <button class="layui-btn upodateBtn" (click)="cloceShowUpdate()">取消邀请</button>
    </div>
</p-dialog>

<p-dialog [contentStyle]="{'padding':'0','border-radius':'10px'}" [dismissableMask]="true" [modal]="true" [showHeader]="false" [(visible)]="Showaccomplish">
    <div class="accomplish">
        <div class="closIcon" (click)="Showaccomplish = false"><img src="/assets/images/v3/alertClos.png"></div>
        <div class="title">
            <div class="tipText">
                <div class="name">是否完成面试</div>
                <div class="text">学生已挂断面试，是否将当前学生标记已完成面试？标记后将移出等待列队！ </div>
            </div>
        </div>
        <div class="btns">
            <div class="btn blueStreamer" (click)="accomplishSave()">完成</div>
            <div class="btn active" (click)="Showaccomplish = false">取消</div>
        </div>
    </div>
</p-dialog>