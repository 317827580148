<!-- v3.0 -->
<div class="schoolCloud">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <div class="schoolHeader">
        <div class="center">
            <div class="goback" (click)="opera.goNewRouter('college-campustalk1')"><img src="assets/images/v3/headerReturnIcon.png">高校详情</div>
            <div class="top">
                <div class="logo">
                    <img *ngIf="college.logo" [src]="college.logo">
                    <img *ngIf="!college.logo" src='assets/images/none.png' />
                </div>
                <div class="text">
                    <div class="name">{{college.name}}</div>
                    <div class="time">{{college.school_type_text}}｜{{college.school_nature_text}}｜{{college.establish_time | date:"yyyy年MM月dd日"}}创办</div>
                    <div class="list">
                        <div class="item">学校代码：{{college.code?college.code:'暂无'}}</div>
                        <div class="item">联系邮箱：{{college.email?college.email:'暂无'}}</div>
                        <div class="item">学校地址：{{college.region_text?college.region_text:'暂无'}}</div>
                        <div class="item">就业电话：{{college.work_info.office_tel?college.work_info.office_tel:'暂无'}}</div>
                    </div>
                </div>
            </div>
            <div class="navList">
                <div class="item" (click)="opera.goNewRouter('college-details')">高校简介</div>
                <div class="item" (click)="goOrg()">教学机构</div>
                <div class="item" (click)="goMilk()">双选会</div>
                <div class="item active" (click)="goCampus()">宣讲会<span></span></div>
                <div class="item" (click)="goStudent()">生源速览</div>
                <div class="item" (click)="goNews()">新闻公告</div>
            </div>
        </div>

    </div>
    <div class="Publicity">
        <div class="center">
            <div class="btns">
                <div class="item" (click)="statusSign()">申请宣讲会</div>
                <div class="down" *ngIf="college.audit_switch.department_receipt_url" (click)="downLoadField()">下载参会回执</div>
            </div>
            <div class="college-list-cont">
                <ul class="title">
                    <li>学校名称</li>
                    <li>联系电话</li>
                    <li>宣讲主题</li>
                    <li>活动时间</li>
                    <li>举办地点</li>
                    <li>审核结果</li>
                    <li>操作</li>
                </ul>
                <!-- 没有内容存在时 -->
                <div *ngIf="countList==0" class="none-block">
                    <img src="/assets/images/rights/empty.png">
                    <p>暂未申请当前学校的宣讲会</p>
                </div>
                <div>
                    <ul class="college-li" *ngFor="let n of campusList;let i = index;">
                        <li><span *ngIf='n.college_name'>{{n.college_name}}</span><span *ngIf='!n.college_name'>暂无</span></li>
                        <li><span *ngIf='n.phone'>{{n.phone}}</span><span *ngIf='!n.phone'>暂无</span></li>
                        <li><span *ngIf='n.name'>{{n.name}}</span><span *ngIf='!n.name'>暂无</span></li>
                        <li>
                            <span *ngIf='n.start_time'>{{n.start_time | date:"yyyy.MM.dd HH:mm" }}-</span>
                            <span *ngIf='!n.start_time'>暂无</span>
                            <span *ngIf='n.end_time'>{{n.end_time | date:"yyyy.MM.dd HH:mm"}}</span>
                            <span *ngIf='!n.end_time'>暂无</span>
                        </li>
                        <li><span *ngIf='n.place_name'>{{n.place_name}}</span><span *ngIf='!n.place_name'>暂无</span></li>
                        <li>
                            <span *ngIf="!n.audit_status">未报名</span>
                            <span *ngIf="n.audit_status=='to_audit'">审核中</span>
                            <span *ngIf="n.audit_status=='passed'">已通过</span>
                            <span *ngIf="n.audit_status=='not_passed'">未通过</span>
                        </li>
                        <li>
                            <span *ngIf="n.audit_status=='to_audit'" (click)="goUpdata(n.collegeid,n)">修改</span>
                            <span *ngIf="n.audit_status=='not_passed'" (click)="goSign()">再次申请</span>
                            <!-- <span class="snotice" (click)="singleTips(n)" *ngIf="n.audit_status == 'passed'"><em>立即通知该校学生</em></span>-->
                            <i>|</i>
                            <span *ngIf="n.audit_status" (click)="goPreach(n.ctid)">详情</span>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
        <!-- 分页部分 -->
        <div class='paging-block' [class.isPageHeight]="countList>0">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>
    </div>
</div>

<!-- 页头部分 -->
<!-- <div class="contentRight-header"><img (click)="opera.goNewRouter('college-details')" src='assets/images/router-old.png'/>高校云<span *ngIf="!config.isByCollege"></span> - 学校详情 - 宣讲会列表</div> -->

<!-- 主体内容部分 -->
<div class="homepage-block" style="display: none;">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <div class="college-block" style="margin-bottom:20px;">
        <!-- 页头部分 -->
        <div class="college-block-head goBackHeader"><img class="goBack" (click)="opera. goPre()" src='assets/images/router-old.png' />宣讲会列表</div>
        <!-- 详情模块 -->
        <div class="college-head-details clear" *ngIf="college">
            <!-- 左侧文字 -->
            <ul class="left">
                <li><span>学校名称：</span>{{college.name}}<i *ngIf="!college.name">暂无</i></li>
                <li><span>创办时间：</span>{{college.establish_time | date:"yyyy-MM-dd"}}<i *ngIf="!college.establish_time">暂无</i></li>
                <li><span>学校性质：</span>{{college.school_nature_text}}<i *ngIf="!college.school_nature_text">暂无</i></li>
                <li><span>联系邮箱：</span>{{college.email}}<i *ngIf="!college.email">暂无</i></li>
                <li><span>学校代码：</span>{{college.code}}<i *ngIf="!college.code">暂无</i></li>
                <li><span>所在城市：</span>{{college.region_text}}<i *ngIf="!college.region_text">暂无</i></li>
                <li>
                    <button class="gray" [class.purple]="(is_apply)&&(college.campustalk_switch)" (click)="statusSign()">申请宣讲会</button>
                    <a *ngIf="college.audit_switch.department_receipt_url" (click)="downLoadField()" style="color: #6977eb;font-size: 15px;margin-left: 15px;width: 100px;height: 40px"><u>下载参会回执</u></a>
                </li>
                <!-- <li><span>就业电话：</span>{{college.phone}}</li> -->
            </ul>
            <!-- 右侧logo -->
            <div class="right">
                <img *ngIf="college.logo" [src]="college.logo" alt="高校logo">
                <img *ngIf="!college.logo" src="assets/images/none.png" alt="高校logo">
            </div>
        </div>
    </div>
    <div class="college-block">
        <!-- 中间部分 -->
        <!-- 没有内容存在时 -->
        <div *ngIf="countList==0" class="none-block">
            <img src="assets/images/resume-search-fail.png"><br/> 暂未申请当前学校的宣讲会
        </div>
        <!-- 循环列表 -->
        <div class="college-list-cont" *ngIf="countList>0">
            <ul>
                <li>学校名称</li>
                <li>联系电话</li>
                <li>宣讲主题</li>
                <li>开始时间</li>
                <li>结束时间</li>
                <li>举办地点</li>
                <li>审核结果</li>
                <li>操作</li>
            </ul>
            <!-- *ngFor="let n of collegeList;let i = index;" -->

            <ul class="college-li" *ngFor="let n of campusList;let i = index;">
                <li><span *ngIf='n.college_name'>{{n.college_name}}</span><span *ngIf='!n.college_name'>暂无</span></li>
                <li><span *ngIf='n.phone'>{{n.phone}}</span><span *ngIf='!n.phone'>暂无</span></li>
                <li><span *ngIf='n.name'>{{n.name}}</span><span *ngIf='!n.name'>暂无</span></li>
                <li><span *ngIf='n.start_time'>{{n.start_time | date:"yyyy-MM-dd HH:mm" }}</span><span *ngIf='!n.start_time'>暂无</span></li>
                <li><span *ngIf='n.end_time'>{{n.end_time | date:"yyyy-MM-dd HH:mm"}}</span><span *ngIf='!n.end_time'>暂无</span></li>
                <li><span *ngIf='n.place_name'>{{n.place_name}}</span><span *ngIf='!n.place_name'>暂无</span></li>
                <li>
                    <span *ngIf="!n.audit_status">未报名</span>
                    <span *ngIf="n.audit_status=='to_audit'">审核中</span>
                    <span *ngIf="n.audit_status=='passed'">已通过</span>
                    <span *ngIf="n.audit_status=='not_passed'">未通过</span>
                </li>
                <!-- <li>
              <span *ngIf="n.audit_status=='to_audit'"  (click)="goUpdata(n)">修改</span>
              <span *ngIf="is_apply" (click)="goSign()">再次申请</span>
              <span class="snotice" (click)="singleTips(n)" *ngIf="n.audit_status == 'passed'"><em>立即通知该校学生</em></span> -->
                <!-- <span *ngIf="!is_apply" class="gray">再次申请</span> -->
                <!-- <span (click)="goDeatils(n.mrid)">修改</span>
              <span *ngIf="n.audit_status=='not_passed'" (click)="goSign(n.collegeid)">再次申请</span> -->
                <!-- <i>|</i>
              <span *ngIf="n.audit_status" (click)="goPreach()">详情</span>
          </li>  -->
                <li>
                    <span *ngIf="n.audit_status=='to_audit'" (click)="goUpdata(n.collegeid,n)">修改</span>
                    <span *ngIf="n.audit_status=='not_passed'" (click)="goSign()">再次申请</span>
                    <!-- <span class="snotice" (click)="singleTips(n)" *ngIf="n.audit_status == 'passed'"><em>立即通知该校学生</em></span>-->
                    <i>|</i>
                    <span *ngIf="n.audit_status" (click)="goPreach(n.ctid)">详情</span>
                </li>
            </ul>

        </div>
        <!-- 操作部分 -->
        <!-- <div class="college-opera" *ngIf="college">
     <button *ngIf="(is_apply)&&(college.campustalk_switch)" class="purple" (click)="goSign()">申请宣讲会</button>
  </div> -->
        <!-- 分页部分 -->
        <div class='paging-block' [class.isPageHeight]="countList>0">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>
    </div>
</div>

<!-- 新增高校 -->
<app-push-college-model #pushCollegeModel [isShow]="isShowPushCollegeModel" (modelHidden)="pushCollegeModelHidden($event)"></app-push-college-model>