<br>
<!-- 主体内容部分 -->
<div class="homepage-block" style="background-color:  #f3f5f6">
    <router-outlet></router-outlet>
    <div class="college-block" style="background-color:  #f3f5f6">
        <div>

            <div *ngIf="noneVipUserLevel==0" class="centerBox_default">
                <div class="guideBox">
                    <div class="guideH1">
                        <div class="guideH2">「工作啦」VIP会员</div>
                        <div class="guideH3">「专属校招特权 极速提升校招效率</div>
                    </div>
                    <div class="guideH4 flex_c" (click)="product('','contactMe')"> <img src="../../assets/images/member2021/click.png" />点击按钮 专属校招顾问为您开通会员</div>
                    <div>
                        <img class="nineBox_icon" src="../../assets/images/huiyuantequan.png">

                        <div class="guideItemBox flex_c">
                            <div *ngFor="let n of nineList;let i = index;" class="nineBox">
                                <img class="quanyi_item-icon" src="{{n.icon}}" />
                                <div class="nineBox_title">{{n.title}}</div>
                            </div>
                        </div>
                    </div>

                    <img class="nineBox_icon" src="../../assets/images/quanyiduibi.png">
                    <img class="quanyu20210127" src="../../assets/images/member_quanxian.png">
                    <div class="quanyi-bottom flex_s">
                        <div class="flex_l quanyi-bottom_titlebox">
                            <img class="quanyi-bottom_image" src="../../assets/images/memberIcon20210127.png">
                            <span class="quanyi-bottom_title">「工作啦」会员</span>
                            <span class="quanyi-bottom_subtitle">专享校招特权 极速提升校招效率</span>
                        </div>
                        <div class="getFangan" (click)="product('','contactMe')">开通会员 <img src="../../assets/images/member_right.png" />
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="noneVipUserLevel==4 || noneVipUserLevel==1 || noneVipUserLevel==3" class="centerBox2" style="background-color: rgba(0,0,0,0);height: 1100px">
                <div class="qyCard">
                    <!--*ngIf="noneVipUserLevel==3"-->
                    <div class="quanyibuzu" *ngIf="noneVipUserLevel==3">
                        <div class="flex_c "><img src="../../assets/images/quanyi_buzu.png" class="quanyi_icon_center" /> 权益不足</div>
                        <div>请及时联系您的专属校招顾问</div>
                    </div>
                    <div *ngIf="noneVipUserLevel==1" class="quanyiguoqi">
                        <div class="flex_c"><img src="../../assets/images/quanyi_daoqi.png" class="quanyi_icon_center" /> 您的会员即将到期
                        </div>
                        <div>请及时联系您的专属校招顾问配置</div>
                    </div>
                    <div *ngIf="noneVipUserLevel==4">
                        <div class="peizhi">请联系您的专属校招顾问进行产品配置</div>
                    </div>
                    <div class="ladouCount" *ngIf="(MyLaCoinCount+ladouForzen.ladou)>0">
                        剩余啦豆数量：{{MyLaCoinCount+ladouForzen.ladou}}
                    </div>

                    <img class="zhuanshu" src="../../assets/images/zhuanshu.png" />
                    <div class="flex_l marginTop20">
                        <img class="guwen_head_icon" *ngIf="config.departmentSelf && config.departmentSelf.avatar" src="{{config.departmentSelf.avatar}}" />
                        <span class="guwen_Name" *ngIf="config.departmentSelf &&  config.departmentSelf.name">{{config.departmentSelf.name}}</span>
                        <img class="guwen_Yello" src="../../assets/images/huangguan.png" />
                    </div>
                    <div class="guwen_cell flex_l marginTop20" (click)="copyText(config.departmentSelf.wx)" *ngIf="config.departmentSelf && config.departmentSelf.wx">
                        <img class="guwen_cell_icon" src="../../assets/images/guwen_wx.png" />
                        <div class="guwen_cell_title">微信：</div>
                        {{config.departmentSelf.wx}}
                        <img class="guwen_cell_copy" src="../../assets/images/guwen_copy.png" />
                    </div>
                    <div class="guwen_cell flex_l marginTop8" (click)="copyText(config.departmentSelf.email)" *ngIf="config.departmentSelf && config.departmentSelf.email">
                        <img class="guwen_cell_icon" src="../../assets/images/guwen_email.png" />
                        <div class="guwen_cell_title">邮箱：</div>
                        {{config.departmentSelf.email}}
                        <img class="guwen_cell_copy" src="../../assets/images/guwen_copy.png" />
                    </div>
                    <div class="guwen_cell flex_l marginTop8" (click)="copyText(config.departmentSelf.mobile)" *ngIf="config.departmentSelf && config.departmentSelf.mobile">
                        <img class="guwen_cell_icon" src="../../assets/images/guwen_phone.png" />
                        <div class="guwen_cell_title">手机号：</div>
                        {{config.departmentSelf.mobile}}
                        <img class="guwen_cell_copy" *ngIf="config.departmentSelf && config.departmentSelf.mobile" src="../../assets/images/guwen_copy.png" />
                    </div>
                    <img *ngIf="config.departmentSelf && config.departmentSelf.qr_code" src="{{config.departmentSelf.qr_code}}" class="guwen_cell_code" />
                </div>
                <!--请联系您的专属校招顾问进行产品配置-->

            </div>
        </div>


        <!--<div class="centerBox" *ngIf="(MyLaCoinCount+ladouForzen.ladou)>0">-->
        <!--&lt;!&ndash;//顶部三个模块&ndash;&gt;-->
        <!--<div class="flex_s" *ngIf="myDepartment">-->
        <!--<div class="topItem flex_c" *ngIf="myDepartment.member_info">-->
        <!--<img class="topItem_l" src="../../assets/images/icon_huiyuan.png" />-->
        <!--<div class="topItem_r">-->
        <!--<div class="topItem_r_h1 vipText">{{isMember_trial?'工作啦体验会员':'工作啦会员'}}</div>-->
        <!--<div class="topItem_r_h2" *ngIf="memberOutCount!=0">有效期：{{productManager.member_info.begintime|date:'yyyy/MM/dd'}}-{{productManager.member_info.endtime|date:'yyyy/MM/dd'}}</div>-->
        <!--<div class="topItem_r_h2" *ngIf="memberOutCount==0">有效期：已过期</div>-->
        <!--<div class="topItem_r_h3" *ngIf="memberOutCount<=60 && !config.isMember_trial">{{memberOutCount==0?'续费后可解锁使用会员权益':'有效期剩余'+ memberOutCount+"天，为不影响使用请提前续费"}} </div>-->
        <!--<div class="topItem_r_h4" *ngIf="isShowXuFeiStr">已续期：<span class="topItem_r_h5">{{isShowXuFeiStr}}</span></div>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="topItem flex_c" [ngClass]="{'topItem':myDepartment.member_info,'topItem2':!myDepartment.member_info}" (click)="buyLaCoin(1)">-->
        <!--<img class="topItem_l" src="../../assets/images/icon_ladou_v2.png" />-->
        <!--<div class="topItem_r">-->
        <!--<div class="topItem_r_h1 flex_s"><div class="b_font">我的啦豆</div><span class="labi_last flex_r">啦豆余额:<span class="labi_last_count">{{MyLaCoinCount+ladouForzen.ladou}}</span></span></div>-->
        <!--<div class="topItem_r_h2 labi_last_red" *ngIf="ladouForzen.ladou>0" (click)="gotoOrderManager($event,'to_pay')">您有{{ladouForzen.ladou}}啦豆被冻结 <img class="labi_last_r_icon"  src="../../assets/images/icon_gengduo.png"/></div>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="topItem flex_c" [ngClass]="{'topItem':myDepartment.member_info,'topItem2':!myDepartment.member_info}"  (click)="gotoOrderManager($event,'')">-->
        <!--<img class="topItem_l" src="../../assets/images/icon_dingdan.png" />-->
        <!--<div class="topItem_r">-->
        <!--<div class="topItem_r_h1 flex_s"><div class="b_font flex_l">我的订单<div class="order_dian" *ngIf="config.ordertoPayCount>0"></div></div></div>-->
        <!--<div class="topItem_r_h2 labi_last_red" *ngIf="config.ordertoPayCount>0" (click)="gotoOrderManager($event,'to_pay')">您有{{config.ordertoPayCount}}笔待支付订单 <img class="labi_last_r_icon"  src="../../assets/images/icon_gengduo.png"/></div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--&lt;!&ndash;产品列表&ndash;&gt;-->
        <!--<div class="productListBox">-->
        <!--<div class="productListBox_title">校招工具商城</div>-->
        <!--<div class="payLaDouRow flex_l" *ngIf="config.isShowPaySystem">-->
        <!--<img  class="payLaDouRow_icon" src="../../assets/images/icon_hui.png"/>-->
        <!--<span>支付每满<span class="payLaDouRow_light">¥1000</span>送<span class="payLaDouRow_light">50</span>啦豆</span>-->
        <!--<div class="payLaDouRow_go" (click)="buyLaCoin(2)">立即充值</div>-->
        <!--</div>-->

        <!--&lt;!&ndash;会员&ndash;&gt;-->
        <!--<div class="vipRow" (click)="isOpenMemberImageClick($event)" *ngIf="isShowMember_info&&productInfo">-->
        <!--<img class="vipRow_icon" src="../../assets/images/icon_huiyuan.png" />-->
        <!--<div class="vipRow_r">-->
        <!--<div class="vipRow_r_row1 flex_s">-->
        <!--<div class="flex_l product_title">{{productInfo.name}}<div class="vipRow_r_row1_data vipRow_r_row1_data2">有效期：365天 （购买即生效<span *ngIf="memberOutCount>0&&memberOutCount<=60">，赠送<span style="color: #FF3E3E">{{memberOutCount}}</span>天</span>)</div></div>-->
        <!--<div class="flex_r product_price">¥{{productInfo.price}}/<span class="product_price_h1">年</span> </div>-->
        <!--</div>-->
        <!--<div class="vipRow_r_row2 flex_s">-->
        <!--<div class="product_detail">「工作啦」会员是您使用「工作啦-校园招聘」进行校招的必备产品，成为会员您将享受一站申请全网高校双选会/宣讲会、简历查看/下载等会员权益，同时解锁购买更多校招工具（如“极速电话联系”、“职位推送”、“校招活动邀请”、“职位刷新”、“空宣推广”等）的权限。</div>-->
        <!--<div class="product_detail_r flex_r" *ngIf="myDepartment.member_info" (click)="xufeiStateClick($event)">-->
        <!--<img class="vipSelete_icon" *ngIf="isPayMember" src="../../assets/images/icon_bixuan.png" />-->
        <!--<div class="vipSelete_icon_none" *ngIf="!isPayMember"></div>-->
        <!--会员续费-->
        <!--</div>-->
        <!--<div class="product_detail_r flex_r" *ngIf="!myDepartment.member_info" (click)="xufeiStateClick($event)">-->
        <!--<span style="color:#FF3E3E;font-size: 12px;margin-right: 10px ">* 必选</span>-->
        <!--<img class="vipSelete_icon" *ngIf="isPayMember" src="../../assets/images/icon_bixuan.png" />-->
        <!--<div class="vipSelete_icon_none" *ngIf="!isPayMember"></div>-->
        <!--购买会员-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="productRow2_open flex_c"*ngIf="!isOpenMemberImage"> 查看介绍 <img  src="../../assets/images/icon_xiangxia.png"/></div>-->
        <!--<img *ngIf="isOpenMemberImage && productInfo.explain_ico" style="height: auto;width: 100%;margin-top: 15px" src="{{productInfo.explain_ico}}}">-->
        <!--<div class="productRow2_open flex_c productRow2_open_close" *ngIf="isOpenMemberImage"> 收起 <img  src="../../assets/images/icon_xiangshang.png"/></div>-->
        <!--</div>-->
        <!--&lt;!&ndash;单品列表&ndash;&gt;-->
        <!--<div class="productRow" *ngFor="let n of productList;let i = index;">-->
        <!--<img class="hot" *ngIf="n.hot"  src="../../assets/images/cm_hot.png" />-->
        <!--<div class="productRow1 flex_l">-->
        <!--<img class="productRow1_icon" src="{{n.mico}}" />-->
        <!--<div class="productRow1_col1">-->
        <!--<div class="productRow1_col1_row1 flex_l">{{ n.name}} <div class="vipRow_r_row1_data" *ngIf="n.product_infos.length>0">规格:{{n.product_infos[0].count}}{{n.product_infos[0].unit}}/包 <span class="vipRow_r_row1_data2">有效时间365天 （购买即生效<span *ngIf="memberOutCount>0&&memberOutCount<=60">，赠送<span style="color: #FF3E3E">{{memberOutCount}}</span>天</span>)</span></div></div>-->
        <!--<div class="product_detail marginTop4 widthFull">{{n.description}}</div>-->
        <!--</div>-->
        <!--<div class="productRow1_col2">-->
        <!--<div class="productRow1_col2_row1 flex_r">-->
        <!--<span class="productRow1_col2_row1_span">单价：</span>-->
        <!--<span class="productRow1_col2_row1_price text_r">{{n.price}}啦豆/包</span>-->
        <!--</div>-->
        <!--<div class="productRow1_col2_row2 flex_r">-->
        <!--<span class="productRow1_col2_row1_span">数量：</span>-->
        <!--<div class="productRow1_col2_row1_price flex_r">-->
        <!--<div class="addCount" (click)="addCountBtnChange('delete',n)">-</div>-->
        <!--<input class="addCount_input" type="number" (ngModelChange)="addCountInputChange(n)" onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"  [(ngModel)]="n.count" />-->
        <!--<div class="addCount" (click)="addCountBtnChange('add',n)">+</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="productRow2_open flex_c" *ngIf="!n.isOpenBottom" (click)="openDetailBottomInfo(n)"> 查看介绍 <img  src="../../assets/images/icon_xiangxia.png"/></div>-->
        <!--<div class="productRow2" *ngIf="n.isOpenBottom">{{n.problem}}</div>-->
        <!--<div class="productRow3" *ngIf="n.isOpenBottom">-->
        <!--<span>{{n.answer}}</span>-->
        <!--<br/>-->
        <!--<span class="color_red">{{n.tips}}</span>-->
        <!--</div>-->
        <!--<div class="productRow2_open flex_c productRow2_open_close" *ngIf="n.isOpenBottom" (click)="openDetailBottomInfo(n)"> 收起 <img src="../../assets/images/icon_xiangshang.png" /></div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

    </div>
</div>

<!--<div class="bottomCss animated " [ngClass]="{'bounceInUp':isShowbottom,'':!isShowbottom}" id="bottom" *ngIf="productList.length>0&&isShowbottom&&((MyLaCoinCount+ladouForzen.ladou)>0)" >-->
<!--<div *ngIf="countByLaCoin()>=1000 && config.isShowPaySystem"class="giveLaRow">支付成功，即送<span>{{countGiveLa()}}</span>啦豆</div>-->
<!--<div class="bottomContent flex_s" >-->
<!--<div class="alert_Order_info" id="alert_Order_info">-->
<!--<div class="alert_Order_info_box">-->
<!--<li class="alert_Order_info_line flex_l alert_Order_info_title" style="background-color: #F1F4FF;color: #333333">-->
<!--<div class="alert_Order_info_item1">单品名称</div>-->
<!--<div class="alert_Order_info_item2">数量</div>-->
<!--<div class="alert_Order_info_item4">权益明细</div>-->
<!--<div class="alert_Order_info_item3 text_r">小计(啦豆)</div>-->
<!--</li>-->
<!--<li class="alert_Order_info_line flex_l" *ngFor="let n of alert_OrderAlertInfoList;let i = index;">-->
<!--<div class="alert_Order_info_item1">{{n.name}}</div>-->
<!--<div class="alert_Order_info_item2">{{n.count}}</div>-->
<!--<div class="alert_Order_info_item4">{{n.quanyi}}</div>-->
<!--<div class="alert_Order_info_item3 text_r">{{n.price}}</div>-->
<!--</li>-->
<!--<div style="width: 100%;height: 15px" class="flex_c">-->
<!--<img style="width: 20px;height: 15px;margin-top: 15px" src="../../assets/images/daosanjiao.png" />-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="bottomContent_l">-->
<!--<div class="bottom_row1 flex_l" >-->
<!--<span>已选工具</span>-->
<!--<div class="flex_l" *ngIf="productInfo&&isPayMember">-->
<!--<div class="bottom_row1_div">{{productInfo.name}}</div>-->
<!--</div>-->
<!--<div class="flex_l" *ngFor="let n of productList;let i = index;">-->
<!--<div class="bottom_row1_div" *ngIf="n.count>0">{{n.name}}*{{n.count}}</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="bottom_row2 flex_l">-->
<!--<span class="bottom_row2_l">小计：</span>-->
<!--<div class="bottom_row2_r" style="width: 150px;">{{countTotalPrices()<0?0:countTotalPrices()}} (啦豆) </div><span class="bottom_row_payInfo" (mouseover)="showAlert_Order_info()" (mouseout)="dimissAlert_Order_info()">查看明细</span>-->
<!--</div>-->
<!--<div class="bottom_row2 flex_l">-->
<!--<span class="bottom_row2_l">啦豆余额抵用：</span>-->
<!--<div class="bottom_row2_r"><span *ngIf="countLastLaCoin()>0">-</span>{{countLastLaCoin()}} (啦豆) </div>-->
<!--<span class="bottom_row2_chips flex_l" *ngIf="ladouForzen.ladou>0">*您有未支付订单被冻结{{ladouForzen.ladou}}（啦豆）,取消订单后可使用冻结中的啦豆 <span (click)="gotoOrderManager($event,'to_pay')" class="lookOrder">查看订单</span> <img class="lookOrder_icon" src="../../assets/images/icon_chakan.png" /></span>-->
<!--</div>-->
<!--<div class="bottom_row2 flex_l">-->
<!--<span class="bottom_row2_l">还需支付：</span>-->
<!--<div class="bottom_row2_r">  {{countByLaCoin()}} (啦豆)  </div>-->
<!--</div>-->
<!--<div class="bottom_row2 flex_l" *ngIf="countByLaCoin()>0" >-->
<!--<span class="bottom_row2_l" style="width: auto;">啦豆余额不足,</span>-->
<!--<div class="bottom_row2_r"  *ngIf="config.isShowPaySystem">请支付剩余金额：<span class="payCount">¥{{countByLaCoin()}}</span>   (1啦豆=1人民币）</div>-->
<!--<div class="bottom_row2_r"  *ngIf="!config.isShowPaySystem">请联系校招顾问协助购买</div>-->
<!--</div>-->
<!--</div>-->
<!--<div class="bottomContent_r" *ngIf="countByLaCoin()==0">-->
<!--<div class="bordeRadius" (click)="buy()">立即购买</div>-->
<!--</div>-->
<!--<div class="bottomContent_r" *ngIf="countByLaCoin()>0">-->
<!--<div class="bordeRadius" style="width: 150px"  (click)="connetXiaoZhao(2)">联系校招顾问</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->

<p-dialog id="aboutLaCoin" [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':500,'height':320,'minHeight':320}" [modal]="true" [showHeader]="false"
    [(visible)]="aboutLaCoinAlert">
    <div style="width: 500px;height: 320px;background-color: white;overflow: hidden;">
        <p class="aboutLaCoin">1.啦豆是什么?</p>
        <p class="aboutLaCoinContent">啦豆是「工作啦」校园招聘平台专属的消费积分，用户需要充值获取，目前兑换比例为1RMB=1啦豆。</p>
        <p class="aboutLaCoin">2.啦豆如何使用？</p>
        <p class="aboutLaCoinContent">充值啦豆成功后，啦豆余额可以在【校招工具】直接购买校招工具和服务，也可以在具体服务提示中按照提示付费。</p>
        <p class="aboutLaCoin">3.啦豆如何开发票？</p>
        <p class="aboutLaCoinContent">充值购买啦豆、直接购买极速电话联系等所花费的金额，可以在【校招工具】-开发票中，申请开具电子发票。</p>
        <p class="aboutLaCoin">4.啦豆是否会过期？</p>
        <p class="aboutLaCoinContent">啦豆跟用户的账号绑定，长期有效，不会过期。</p>
    </div>
</p-dialog>
<p-dialog [closeOnEscape]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isShowConnetSuccess" [modal]=true [showHeader]="false" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':460,'height':530,'minHeight':530}">
    <div class="ConnetSuccess" [ngClass]="{'ConnetSuccess':isShowConnetSuccessStep==1,'ConnetSuccess2':isShowConnetSuccessStep==2}" (click)="dismissConnet()">

        <div class="connetSuccess_top" *ngIf="isShowConnetSuccessStep==1">
            <div class="connetSuccess_top_h1">您的信息已经收到</div>
            <div class="connetSuccess_top_h2">您的专属校招顾问会尽快与您取得联系，</div>
            <div class="connetSuccess_top_h2">您也可以主动添加校招顾问进行咨询，</div>
        </div>
        <div class="guWenBox" *ngIf="config.departmentSelf">
            <img class="zhuanshu" src="../../assets/images/zhuanshu.png" />
            <div class="flex_l marginTop20">

                <img class="guwen_head_icon" *ngIf="config.departmentSelf.avatar" src="{{config.departmentSelf.avatar}}" />
                <span class="guwen_Name" *ngIf="config.departmentSelf.name">{{config.departmentSelf.name}}</span>
                <img class="guwen_Yello" src="../../assets/images/huangguan.png" />
            </div>
            <div class="guwen_cell flex_l marginTop20" (click)="copyText(config.departmentSelf.wx)" *ngIf="config.departmentSelf.wx">
                <img class="guwen_cell_icon" src="../../assets/images/guwen_wx.png" />
                <div class="guwen_cell_title">微信：</div>
                {{config.departmentSelf.wx}}
                <img class="guwen_cell_copy" src="../../assets/images/guwen_copy.png" />
            </div>
            <div class="guwen_cell flex_l marginTop8" (click)="copyText(config.departmentSelf.email)">
                <img class="guwen_cell_icon" src="../../assets/images/guwen_email.png" />
                <div class="guwen_cell_title">邮箱：</div>
                {{config.departmentSelf.email}}
                <img class="guwen_cell_copy" src="../../assets/images/guwen_copy.png" />
            </div>
            <div class="guwen_cell flex_l marginTop8" (click)="copyText(config.departmentSelf.mobile)">
                <img class="guwen_cell_icon" src="../../assets/images/guwen_phone.png" />
                <div class="guwen_cell_title">手机号：</div>
                {{config.departmentSelf.mobile}}
                <img class="guwen_cell_copy" *ngIf="config.departmentSelf.mobile" src="../../assets/images/guwen_copy.png" />
            </div>
            <img src="{{config.departmentSelf.qr_code}}" class="guwen_cell_code" />

        </div>
    </div>

</p-dialog>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>