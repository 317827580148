<!-- 添加公司实力 -->
<!-- 遮罩层公共样式 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px','width':'700px'}" [(visible)]="isShow" (onHide)="dismiss(false)" [modal]=true>
    <div class="model-cont">
        <!-- 上层提示部分 -->
        <div class="model-head clear">
            <div class="left">公司实力</div>
            <div class="clos" (click)="dismiss(false)"><img src="/assets/images/rights/contactMeClos.png"></div>
        </div>
        <!-- 中间内容部分 -->
        <div class="label-block">
            <!--可选标签  -->
            <div class="label-title-block">
                <div class="lable">已选标签</div>
                <div class="rightVal">{{index}}/9</div>
            </div>
            <!-- 选择标签 -->
            <div class="select-choose-block clear" *ngIf="labelAll.length>0">
                <div class="choose-list">
                    <div class="choose-item" *ngFor="let label of labelAll">
                        <div class="choose-text">{{label.text}}</div>
                        <div class="choose-delete" (click)="delectSkill(label)">x</div>
                    </div>
                </div>
            </div>
            <!--推荐标签  -->
            <div class="label-title-block">推荐标签</div>
            <!-- 选择标签 -->
            <div class="label-list clear">
                <div class="label-item" [class.isActive]="labels.isActive" *ngFor="let labels of config.strength" (click)="chooseConfigLabel(labels)">
                    <div>{{labels.text}}</div>
                </div>
            </div>
        </div>
        <br>
        <!-- 底部按钮 -->
        <div class="model-foot blueStreamer" (click)="getLabel()">保存</div>
    </div>
</p-dialog>