<!-- 页头部分 goBack()-->
<!--<div class="contentRight-header">-->
  <!--<img *ngIf="!config.isByCollege" (click)="opera.goNewRouter('tool-campus')" src='assets/images/router-old.png'/>校招工具</div>-->
<!-- 主体内容部分 -->

<app-navigation-route ></app-navigation-route>


<div class="homepage-block">


  <!--<app-model-pay-wechat #payWechat [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-pay-wechat>-->

  <!-- HTML -->
  <div class="container">


    <div class="box">

      <div class="college-block-head">
        {{productDetail?.name}}
      </div>
      <div style="width: 100%;height: 35px"></div>

      <div style="width: 950px;margin-left: calc(50% - 480px);height: 120px" *ngIf="config.departmentSelf">
        <div class="guwen_bg" >
          <div class="guwen_bg_gray">
            <img class="guwen_info_huizhang" src="../../assets/images/kefu_hui.png">
            <img class="guwen_head" src="{{config.departmentSelf.avatar}}">
            <div class="guwen_infoDiv">
              <ul>
                <li><img style="width: 120px;height: 20px" src="../../assets/images/guwen_title.png"></li>
                <li><span class="guwen_info_name">{{config.departmentSelf.name}}</span></li>
                <li>
                  <img style="width: 17px;height: 17px" src="../../assets/images/kefu_wx.png">
                  <span class="guwen_info_title">微信：</span>
                  <span class="guwen_info_wx">{{config.departmentSelf.wx}}</span>
                </li>
                <li style="width: 260px">
                  <img style="width: 17px;height: 17px" src="../../assets/images/kefu_email.png">
                  <span class="guwen_info_title">邮箱：</span>
                  <span class="guwen_info_wx">{{config.departmentSelf.email}}</span>
                </li>
              </ul>
            </div>
            <img class="guwen_head_code" style="margin-left: 5px;" src="{{config.departmentSelf.qr_code}}">
            <div class="guwen_info_Line"></div>
            <!--<p class="guwen_info_rightText">校招工具相关问题,请扫码咨询！</p><br/>-->
            <div class="guwen_info_rightText">
              <p >校招工具相关问题,</p>
              <p >请扫码咨询！<span>{{config.departmentSelf_ex}}</span></p>
            </div>


          </div>
        </div>

      </div>

      <div class="contentBox">

        <div class="_l">
          <img src='{{productDetail?.ico}}'>
        </div>
        <div class="_r">
          <div class="topNameView">
            <img class="topNameView_img" src="{{productDetail?.product_infos[0].ico}}">
            <span class="topNameView_title">{{productDetail?.name}}
              <span class="title_chips">（{{productPace }}{{productDetail?.product_infos[0].unit}}/包）</span>
              </span>
            <img class="hot" src="../../assets/images/hot.png">
          </div>

          <div class="productListView">

            <div class="r_row1 marginTop20">
              <span class="r_row1_span1">单价:</span>
              <span class="r_row1_span2">{{productDetail_cost}}啦豆/包</span>
              <span class="member_name2_detail" *ngIf="productDetail?.tips">{{productDetail?.tips}}</span>
            </div>
            <div class="r_row1">
              <span class="r_row1_span1">数量:</span>
              <p (click)="countBtnClick(-1)" class="addBtn marginLeft30">-</p>
              <input value="{{count}}"  oninput = "value=value.replace(/[^\d]/g,'')" (ngModelChange)="inputChange($event)" [(ngModel)]="count" class="inputCount" />
              <p (click)="countBtnClick(1)" class="addBtn">+</p>
            </div>
            <div class="r_row2">
              <!--<span class="r_row1_span1">卡券:</span>-->
     <!---->
              <!--<div *ngIf="cardList.length==0" style="color:#999999;margin-left: 30px;font-size: 16px">暂无</div>-->
              <!--<div class="cardViewBg" *ngIf="cardList.length>0">-->

                <!--<div class="swiper-container " >-->
                  <!--<div class="swiper-wrapper cardViewBg" >-->
                    <!--<div class="swiper-slide cardBg" (click)="cardRowClick(n)" [ngClass]="{cardBg:currentCard==n.index+'',cardBg_none:currentCard!=n.index+''}" *ngFor="let n of cardList1">-->
                      <!---->
                      <!--<div class="cardBgTop">-->
                        <!--<span [ngClass]="{cardBgTop_title1_s:currentCard==n.index+'',cardBgTop_title1_none:currentCard!=n.index+''}">{{n.gift_amount}}</span>-->
                        <!--<span  [ngClass]="{cardBgTop_title2_s:currentCard==n.index+'',cardBgTop_title2_none:currentCard!=n.index+''}">啦豆</span>-->
                        <!--<span  [ngClass]="{cardBgTop_title3_s:currentCard==n.index+'',cardBgTop_title3_none:currentCard!=n.index+''}">满{{n.limited_amount}}可用</span>-->
                      <!--</div>-->
                      <!--<div class="cardBgBottom">-->
                        <!--<span class="manjian_s" [ngClass]="{manjian_s:currentCard==n.index+'',manjian_none:currentCard!=n.index+''}">满减</span>-->
                        <!--<span class="manjian1_s">{{n.title}}</span>-->
                        <!--<img *ngIf="currentCard==n.index+''" class="manjiansanjian_s" src="../../assets/images/sanjiao.png">-->
                      <!--</div>-->

                    <!--</div>-->
                  <!--</div>-->
                <!--</div>-->
              <!--</div>-->
            </div>

          </div>

        </div>

      </div>



      <div class="bottomVIew">

        <div style="width: 100%;height: 15px"></div>

        <div class="cellBG">
          <div class="rightCell">
            <span class="rightCell_title1">小计：</span>
            <span class="rightCell_title2">{{count * productDetail_cost}}啦豆</span>
          </div>
        </div>
        <div class="cellBG">
          <div class="rightCell">
            <span class="rightCell_title1">抵用：</span>
            <span class="rightCell_title2">{{youhui()}}啦豆</span>
          </div>
        </div>

        <div class="cellBG">
          <div class="rightCell">
            <span class="rightCell_title1" style="margin-top: 15px;">总计：</span>
            <span class="rightCell_title3">{{countLabi()}}啦豆</span>
          </div>
        </div>
        <div style="width: 100%;height: 35px"></div>
        <div class="cellBG">
          <button class="buyBtn" (click)="pay()">立即购买</button>
          <button class="buyBtn_ladou gradient" (click)="buyLadou()"><span style="font-size: 12px">买啦豆,</span><span style="font-size: 16px">优惠购买</span></button>
        </div>
        <div class="xieyi">付费即表示同意<span class="xieyi2" (click)="zengzhi()" >《增值服务协议》</span></div>



      </div>

    </div>
  </div>
</div>
