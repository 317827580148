<!-- 用户确认操作弹出框 -->
<div class="modal-dialog">
  <div   class="modal-content" style="  margin: 210px auto 0;
  width: 640px;
  height:400px;background-color: white;overflow: hidden">

    <div class="centerBox flex_s">
      <img class="leftIcon"  src="../../assets/images/http_error1.png"/>

      <div class="rightBox">
        <!--<p class="title1">404</p><p class="title2">抱歉，你无权访问该页面</p>-->
        <p class="title1">500</p><p class="title2">内部服务器错误</p>

      </div>

    </div>

    <button style="width: 120px;height: 36px;border: none" type="button" class="btn btn-default" (click) = "goRouter('tool-campus')" [mat-dialog-close] >我知道了</button>
    <!--<button [mat-dialog-close] style="border: none">-->
      <!--<div (click) = "goRouter('tool-campus')"  style="position: absolute;left: 50%;margin-left: -56px;bottom: 179px;width: 112px;height: 19px"></div>-->
    <!--</button>-->
  </div>

</div>

