import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import {ConfirmModel} from "../confirm/confirm.component";
import {DataApi, Opera,Local} from "../../provider";
import {ToastrService} from "ngx-toastr";
import { Events } from '../../provider/eventService';

@Component({
  selector: 'app-logout-account-model',
  templateUrl: './logout-account-model.component.html',
  styleUrls: ['./logout-account-model.component.css']
})
export class LogoutAccountModelComponent implements OnInit {

  //询问注销账号的弹窗，注册步骤的时候用
  //这里注销账号是将账号从后台数据库把账号删除
  title: string;
  message: any;
  trueText:string;
  falseText:string;
  public model= {
    phone:'',
    code:'',
    imageCode:""
  }
  public vcodeImg:any;//图片验证码（用户填写）
  public imgCode:any;//图形验证码
  public imgCodeId:any;//图形验证码id

  public sendEmailText2='获取验证码';
  constructor(

    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel,
    public dataApi: DataApi,
    public toastr: ToastrService,
    public opera:Opera,
    public events: Events,
    public dialog: MatDialog,
    public local:Local,

  ) { }

  ngOnInit() {
    this.getCode();
    let that = this;
    // if(this.local.get('loginUser'))
    // {
    //   try {
    //     let loginUser =  this.local.get('loginUser')
    //     let loginUserJson = JSON.parse(loginUser);
    //     that.model.phone = loginUserJson.mobile;
    //   }catch(e)
    //   {
    //   }
    // }
    this.dataApi.getUser().then((res:any)=>{
      that.model.phone = res.mobile;

    }).catch((error)=>{
      this.toastr.error(error);
    });
  }
  sendPhoneCode(){

    if(this.sendEmailText2!='获取验证码')
    {
      this.toastr.warning('请勿重复发送')

      return;
    }

    if(this.model.phone=="")
    {
      this.toastr.warning('请填写手机号')
      return;
    }
    if(this.model.imageCode=="")
    {
      this.toastr.warning('请填写图片验证码')
      return;
    }


    let phoneModel={
      format_type:0,//0:手机号，1：手机号&固话；2.邮箱
      data:this.model.phone
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(res.value){
        let _params = {
          "mobile": this.model.phone,
          "sms_type": "login",
          "vcode_verify": {
            "vcodeid": this.imgCodeId,
            "vcode_text":this.model.imageCode
          }
        };
        this.dataApi.postMobileSmsVcode(_params).then((res:any)=>{
          // this.hideClearButton();
          this.opera.loadiss();
          this.toastr.success('验证码已发送，请注意查收');
          let count = 60;
          this.sendEmailText2 = count + 's';
          let timer = setInterval(()=>{
            count -= 1;
            this.sendEmailText2 = count + 's';
            if (count === 0) {
              clearInterval(timer);
              this.sendEmailText2 = '获取验证码';
            }
          }, 1000);
        }).catch((err)=>{
          console.log("发送邮箱失败",err);
          this.toastr.error(err);return;
        })

      }
      else
      {
        this.toastr.warning('手机号格式错误');

      }
    }).catch((err)=>{
      console.log("发送邮箱失败",err);
      this.toastr.error(err);return;
    })

  }
  // 获取图形验证码
  getCode(){
    this.dataApi.getImgCode().then((res:any)=>{
      //console.log("获取验证码");
      // this.toastr.success('获取成功');
      // this.imgCode=this.sanitizer.bypassSecurityTrustResourceUrl(res.vcode_image);//验证码图片base64
      this.imgCode='data:image/png;base64,'+res.vcode_image;//验证码图片base64
      this.imgCodeId=res.vcodeid;
      //console.log(res);
    }).catch((err)=>{
      //console.log(err);
    })
  }
  //
  commit(){
    if(this.model.phone=="") {
      this.toastr.warning('请填写手机号');
      return;
    }
    if(this.model.imageCode=="") {
      this.toastr.warning('请填写图片验证码');
      return;
    }
    if(this.model.code=="") {
      this.toastr.warning('请填写短信验证码');
      return;
    }
    let data = {
      mobile:this.model.phone,
      vcode_text:this.model.code,
    };

    // cancelRegister
    this.dataApi.cancelRegister(data).then((res:any)=>{
      this.toastr.warning('注销成功')
      let that = this;
      setTimeout(()=>{
        that.events.publish('user:created'); //发送退出登录选项
        that.dialog.closeAll();
      },400)
      //console.log(res);
      console.log('开启注销')
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    })
  }

}
