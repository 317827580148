import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'excludeEducation'
})
export class ExcludeEducationPipe implements PipeTransform {
  transform(items: any[], excludeCode: string): any[] {
    return items.filter(item => item.code !== excludeCode);
  }
}
