import {Component, OnInit, Input, Output, ViewChild} from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {filter} from "rxjs/operators";
import {ModelDeliveryResumeComponent} from "../model-delivery-resume/model-delivery-resume.component";
import {ModelCollectionResumeComponent} from "../model-collection-resume/model-collection-resume.component";

declare var layui;
declare let Swiper:any;

@Component({
  selector: 'app-position-resume',
  templateUrl: './position-resume.component.html',
  styleUrls: ['./position-resume.component.css']
})
export class PositionResumeComponent implements OnInit {
  @ViewChild('deliveryResume', { static: true }) deliveryResume:ModelDeliveryResumeComponent;
  @ViewChild('deliveryResume_like', { static: true }) deliveryResume_like:ModelCollectionResumeComponent;

  public config: Config;
  @Output() modelShow = true;//是否打开弹窗(简历收件箱)
  @Output() modelShowStatus = false;//是否打开弹窗(简历收藏)
  @Output() ApproShow = false;//是否打开弹窗(简历收藏)
  @Output() InterviewShow = false;//是否打开弹窗(简历收藏)
  @Output() status = 1;//当前认证状态
  public type="delivery";//当前导航页
  public bannerList=[];//当前导航页
  private  positionId = {};
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  public majorOne='';//专业类别一级

  constructor(
    public dataApi: DataApi,
    public local:Local,
    private sanitizer: DomSanitizer,
    private router: Router,
    public opera:Opera,
    private activatedRoute: ActivatedRoute,


    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event:NavigationEnd) => {
        let url =event.url.split("?");
        console.log(url[0]);
        if (url[0] === '/home/position-resume') {
          /*在这写需要执行初始化的方法*/
          
          this.activatedRoute.queryParams.subscribe(queryParams => {
           // this.deliveryResume.getNewList();
            console.log('queryParams.positionid',queryParams.positionid)
            if(queryParams.positionid){
              this.positionId = queryParams.positionid;
              this.deliveryResume.getSendResumeList();
            }
          });

          this.opera.initConfig('nav-resume-manager','position-resume');
          this.deliveryResume.initScrooll();
          if(url[1]){
            this.deliveryResume.initData();
          }
        }

      });
  }



  ngOnInit() {
     // this.opera.initConfig('home','position-resume');
    let hash = location.hash;
    if(hash.indexOf('position-resume-like') != -1){
      this.opera.initConfig('nav-resume-manager','position-resume-like');
      this.goCollectionResume();

    }else{
      this.opera.initConfig('nav-resume-manager','position-resume');
    }

    // this.activatedRoute.queryParams.subscribe(queryParams => {
    //   console.log('queryParams',queryParams)
    //   if(queryParams.positionid && queryParams.positionStatus){
    //     this.isPositionParams.positionid = queryParams.positionid;
    //     this.isPositionParams.positionStatus = queryParams.positionStatus;
    //   }
    // })

    //this.deliveryResume.ngOnInit();

    //this.getCardbanner();

  }

  //去简历收件箱
  goPositionResume(){
    this.modelShow = true;
    this.modelShowStatus = false;
    this.ApproShow = false;
    this.InterviewShow = false;
    this.type = "delivery";
    this.deliveryResume.initScroollZero();

  }
  //去简历收藏
  goCollectionResume(){
    this.modelShowStatus = true;
    this.modelShow = false;
    this.ApproShow = false;
    this.InterviewShow = false;
    this.type = "collection";
    // this.deliveryResume_like.initScrooll();
    this.deliveryResume.initScroollZero();

  }

  //去合适的
  goAppropriate(e){
    this.ApproShow = true;
    this.modelShowStatus = false;
    this.modelShow = false;
    this.InterviewShow = false;
    this.type = "appropriate";
    this.deliveryResume.initScroollZero();

  }

  // 面试管理
  goPositionNotice(){
    this.InterviewShow = true;
    this.modelShowStatus = false;
    this.modelShow = false;
    this.ApproShow = false;
    this.type = "positionNotice";
    this.deliveryResume.initScroollZero();

  }
  //获取card广告banner
  getCardbanner() {

    let that = this;
    this.dataApi.getCardBannerdata('jianli').then((res:any) => {
      if (res) {
        this.bannerList = res;
        setTimeout(()=>{
          layui.use(['carousel', 'form'], function() {
            var carousel = layui.carousel
              , form = layui.form;
            //建造实例
            carousel.render({
              elem: '#test1'
              ,width: '100%' //设置容器宽度
              ,arrow: 'always', //始终显示箭头
              height:'160px',
              interval:3000
              //,anim: 'updown' //切换动画方式
            });
            //找到两个按钮，设置样式，因为全局样式又一个Buttom里有一个样式会导致按钮错乱
            let leftbtn =  document.getElementById("lay-type-left");
            let rightbtn =  document.getElementById("lay-type-add");
            if(leftbtn) {
              leftbtn.style.padding ='0';
            }
            if(rightbtn) {
              rightbtn.style.padding ='0';
            }
          })
        },200)

      }
    }).catch((err) => {
      console.log(err);
    })
  }

  bannerClick(n) {
    console.log('bannerClick',n)
    if(n.links)
    {
      window.open(n.links,'_blank');
      return;
    }
  }
  ngAfterViewInit(){
    console.log('ngAfterViewInit')
  }


}
