import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { DataApi,Config,Local,Constants,Opera} from '../../provider/index';


@Component({
  selector: 'app-company-status-one',
  templateUrl: './company-status-one.component.html',
  styleUrls: ['./company-status-one.component.css']
})
export class CompanyStatusOneComponent implements OnInit {
  public power:boolean=true;
  public config: Config;
  publicData;
  productInfo;
  productDef=0;
  constructor(
    private router: Router,
    public dataApi: DataApi,
    private local: Local,
    public opera: Opera
  ) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.opera.initConfig('member','company-status-one');
    this.opera.checkPlatformIdentity();
    this.getProductList();
  }
  powerTab(){
    this.power=true;
  }
  searchTab(){
    this.power=false;
  }

  // 获取产品信息
  getProductList(){
    this.dataApi.getProductList().then((res:any)=>{
      if(res)
      {
        this.publicData = res
        console.log("this.productInfo", this.publicData);

        console.log(this.publicData);
        this.productInfo = this.publicData.list[0];
      }

    }).catch((err)=>{
      console.log(err);
    })
  }

  // // 会员权益详情
  // getProductInfo(){
  //   this.dataApi.getProductInfo(this.productInfo['code']).then((res:any)=>{
  //     if(res){
  //       this.productMain = res;
  //     }
  //     console.log(this.productMain);
  //   }).catch((err)=>{
  //     console.log(err);
  //   })
  // }

  // 选择产品
  productDefs(type){
    this.productDef = type;
    this.productInfo = this.publicData.list[type];
  }

  // 跳转支付页面
  goSearch(){
    this.config.productCode = this.productInfo.code;
    this.local.set(Constants.productInfo, JSON.stringify(this.productInfo.code));
    console.log(this.local.get(Constants.productInfo));

    this.router.navigate(["home/company-pay"]);
  }
}
