import { Component, OnInit, Input, Output,ViewChild } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
//新增高校
import { PushCollegeModelComponent } from "../push-college-model/push-college-model.component";
@Component({
  selector: 'app-college-details-news',
  templateUrl: './college-details-news.component.html',
  styleUrls: ['./college-details-news.component.css']
})
export class CollegeDetailsNewsComponent implements OnInit {
  public config: Config;
  // 筛选条件
  public model={
    columnid:'',
     offset:0,
     limit:9
  }
  public step=0;
  public menu:any;//公众号菜单
  public menuList=[];//二级菜单列表
  public articleList:any;//二级菜单列表
  public resumeList=[];//文章列表
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public collegeid="";//高校id
  public college:any;//高校详情
  public isInit=false;
  //高校信息
  public addCollegeList = [];
  //是否打开弹窗(非会员新增高校)
  @Output() isShowPushCollegeModel = false;
  @ViewChild('pushCollegeModel', { static: true })
  pushCollegeModel: PushCollegeModelComponent;

  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {
    this.opera.initConfig('college','college-campustalk');
    this.collegeid=this.local.get(Constants.collegeid);//赋值高校id
    this.getCollegeById();//获取高校详情
    this.getCollegeMenu();//获取公众号菜单
  }

  //进入文章详情
  goDetails(at){
    // this.navCtrl.push('CollegeJobDetails',{item:at});
    this.local.set(Constants.articleid,at);
    this.router.navigateByUrl('home/college-details-newstxt');
  }

  //---------------获取公众号菜单
  getCollegeMenu(){
    this.opera.loading();
    this.dataApi.getCollegeMenu(this.collegeid).then((res:any)=>{
       this.menu = res;
       //console.log(this.menu);
       if(this.menu){
        for(let data of this.menu){
          if(data.button_type=='menu'){
              //console.log("获取到公众号菜单");
              //console.log(this.menu);
              //console.log(data.submenu);
              this.menuList=data.submenu;
              this.getResumeList();//获取文章列表
          }else if(data.button_type=='home'){
            this.collegeid=data.url;
          }
        }
      }
       this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
       //console.log(err);
    })
  }

  //点击筛选文章
  getList(i){
    if(this.step==i){
      return;
    }
    this.step=i;
    this.countList=0;
    this.getResumeList();//获取文章列表
  }

  //获取文章列表
  getResumeList(){
    this.model.columnid=this.menuList[this.step].url;
    this.opera.loading();
    this.dataApi.getCollegeJobList(this.model).then((res:any)=>{
     //console.log("文章列表------------------------------------collegeJob");
     //console.log(res);
     this.resumeList=res.list;
     this.countList=res.count;
     this.pageCount =Math.ceil(res.count/9);
     this.opera.loadiss();
    }).catch((err)=>{
      //console.log(err);
      this.opera.loadiss();
    })
  }

  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getResumeList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }
  }

  //通过高校id获取高校详情
  getCollegeById(){
    this.opera.loading();
    this.dataApi.getCollageDetails(this.collegeid).then((res:any)=>{
      //console.log("获取到高校信息---");
      //console.log(res);
      this.isInit = true;
      this.college = JSON.parse(JSON.stringify(res));
      this.opera.loadiss();
    }).catch((err)=>{
      this.toastr.error(err);
      this.isInit = true;
      this.opera.loadiss();
    });
  }


     //去教学机构
     goOrg() {
      let that = this;
      this.opera.checkMember((isPay) => {
        if (isPay) {
          that.local.set(Constants.collegeid, that.college.collegeid);
          that.router.navigateByUrl("home/college-details-org");
        }else {
          //that.opera.checkMemberAlertWithType("goBuy")
          //非会员验证是否是合作3所高校
          let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
          if (finItem) {
            that.local.set(Constants.collegeid, that.college.collegeid);
            that.router.navigateByUrl("home/college-details-org");
          } else {
            this.pushCollege();
          }
        }
      })
  
    }
    //去双选会
    goMilk() {
  
      let that = this;
      this.opera.checkMember((isPay) => {
        if (isPay) {
          this.router.navigateByUrl("home/college-details-milk");
        } else {
          // that.opera.checkMemberAlertWithType("goBuy")
          //非会员验证是否是合作3所高校
          let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
          if (finItem) {
            this.router.navigateByUrl("home/college-details-milk");
          } else {
            this.pushCollege();
          }
        }
      })
  
    }
    //去宣讲会
    goCampus() {
      let that = this;
      this.opera.checkMember((isPay) => {
        if (isPay) {
          that.router.navigateByUrl("home/college-details-campus");
        } else {
          //that.opera.checkMemberAlertWithType("goCampus")
          //非会员验证是否是合作3所高校
          let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
          if (finItem) {
            that.router.navigateByUrl("home/college-details-campus");
          } else {
            this.pushCollege();
          }
        }
      })
    }
    //去新闻公告
    goNews() {
  
      let that = this;
      this.opera.checkMember((isPay) => {
        if (isPay) {
          that.router.navigateByUrl("home/college-details-news");
        }else {
          //that.opera.checkMemberAlertWithType("goBuy")
          //非会员验证是否是合作3所高校
          let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
          if (finItem) {
            that.router.navigateByUrl("home/college-details-news");
          } else {
            this.pushCollege();
          }
        }
      })
    }
  
    //去生源速览
    goStudent() {
      this.local.remove('college-details-student-model');
      this.local.remove('college-details-student-modelp');
      let that = this;
      this.opera.checkMember((isPay) => {
        if (isPay) {
          that.router.navigateByUrl("home/college-details-student");
        } else {
          //that.opera.checkMemberAlertWithType("goBuy")
          //非会员验证是否是合作3所高校
          let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
          if (finItem) {
            that.router.navigateByUrl("home/college-details-student");
          } else {
            this.pushCollege();
          }
        }
      })
    }
    //获取合作高校
    mycollegeList() {
      this.dataApi.mycollegeList().then((res: any) => {
        if (res) {
          this.addCollegeList = res;
        }
      }).catch((err) => {
        console.log(err);
      })
    }
  
      //新增高校
    pushCollege() {
        this.isShowPushCollegeModel = true;
        this.pushCollegeModel.initData();
    }
    
    pushCollegeModelHidden(event) {
        this.isShowPushCollegeModel = false;
        this.mycollegeList();
    }
}
