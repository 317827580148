import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import {productManager} from "../../provider/productManager";
import {VoiceCallModelComponent} from "../voice-call-model/voice-call-model.component";
import {ModelSendMsgComponent} from "../model-send-msg/model-send-msg.component";

declare let Swiper:any;
declare var layui;

@Component({
  selector: 'app-air-campustalk-reord',
  templateUrl: './air-campustalk-reord.component.html',
  styleUrls: ['./air-campustalk-reord.component.css']
})
export class AirCampustalkReordComponent implements OnInit {

  constructor(public dataApi: DataApi,
              public local:Local,
              private sanitizer: DomSanitizer,
              private router: Router,
              public opera:Opera,
              private activatedRoute: ActivatedRoute,
              public toastr: ToastrService) { }
  public ActivityList = [];
  public bannerList = [];
  public model={
    keywords:'',//关键词
    major:'',//专业
    education:'',//学历
    status:'air_campustalk',//状态
    positionid:'',//职位id
    limit:10,
    offset:0,
    mc_type:''
  };

  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  ngOnInit() {
    this.opera.initConfig('nav-resume-manager','airCampHistoryReord');
    this.getCardbanner();
    this.getActivityrecord();
  }
  changeStatus(state){
    this.model.status = state;
    this.model.offset = 0;
    this.getActivityrecord();
  }
  getActivityrecord(){
    this.model.mc_type = this.model.status;
    this.dataApi.getActivityrecord(this.model).then((res:any) => {
      if (res) {
        this.ActivityList = res.list;
        this.countList=res.count;
        this.pageCount =Math.ceil(res.count/10);
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  //获取card广告banner
  getCardbanner() {
    let that = this;
    this.dataApi.getCardBannerdata('jianli').then((res:any) => {
      if (res) {
        console.log('获取广告Banner', res);
        this.bannerList = res;
        setTimeout(()=>{
          layui.use(['carousel', 'form'], function() {
            var carousel = layui.carousel
              , form = layui.form;
            //建造实例
            carousel.render({
              elem: '#test1'
              ,width: '100%' //设置容器宽度
              ,arrow: 'always', //始终显示箭头
              height:'160px',
              interval:3000
              //,anim: 'updown' //切换动画方式
            });
            //找到两个按钮，设置样式，因为全局样式又一个Buttom里有一个样式会导致按钮错乱
            let leftbtn =  document.getElementById("lay-type-left");
            let rightbtn =  document.getElementById("lay-type-add");
            if(leftbtn) {
              leftbtn.style.padding ='0';
            }
            if(rightbtn) {
              rightbtn.style.padding ='0';
            }
          })
        },200)
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  bannerClick(n) {
    console.log('bannerClick',n)
    if(n.links) {
      window.open(n.links,'_blank');
      return;
    }
  }
  cellClick(n){
    this.local.set('activityRecordid',n.recordid)
    this.local.set('activityRecordid_Name',n.name)
    this.local.set('activityRecordid_Nav',"airCampHistoryReord")
    this.router.navigate(['home/activityInviteRecordDetail'], );
  }
  //获取分页子组件返回数据
  getChildEvent(index){
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*10;
      this.getActivityrecord();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*10;
        this.getActivityrecord();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*10;
        this.getActivityrecord();//获取简历投递列表
      }
    }
  }

  nav01(){
    this.router.navigate(['home/position-resume']);
  }

  nav02(){
    this.router.navigate(['home/position-resume-like']);
  }

  nav03(){
    this.router.navigate(['home/voiceCallRecord']);
  }

  nav04(){
    this.router.navigate(['home/positionPushRecord']);
  }

  nav05(){
    this.router.navigate(['home/activityInviteRecord']);
  }

  nav06(){
    this.router.navigate(['home/airCampHistoryReord']);
  }
}
