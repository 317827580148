import {Component, Inject, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Opera} from "../../provider";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";


export interface ConfirmModel {
  type: "";
  title:string;
  message:any;
  trueText:string;
  falseText:string;
  allData:any;
}

@Component({
  selector: 'app-check-member-model',
  templateUrl: './check-member-model.component.html',
  styleUrls: ['./check-member-model.component.css']
})
export class CheckMemberModelComponent implements OnInit {


  title: string;
  message: any;
  trueText:string;
  falseText:string;
  allData:any;
  type:any;
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel) {
    this.type=data.type;
    this.message=data.message;
    this.trueText=data.trueText;
    this.falseText=data.falseText;
  }

  ngOnInit() {

  }
  goRouter(n){
    this.router.navigate(['home/'+n]);
  }
  goRouter1(n){
    this.router.navigate(['/'+n]);
  }

}
