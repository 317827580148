import { Component,EventEmitter,Input, Output,ViewChild} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Opera} from '../../provider/index';
import { ImageCropperComponent, CropperSettings, Bounds} from 'ngx-img-cropper';
declare var Cropper;
@Component({
  selector: 'app-model-cropper',
  templateUrl: './model-cropper.component.html',
  styleUrls: ['./model-cropper.component.css']
})
export class ModelCropperComponent{
  @ViewChild('cropper', { static: true }) ImageCropper: ImageCropperComponent;
  @Input() isShow: false;//是否显示弹窗页面
  public data: any;
  public cropperSettings: CropperSettings;
  public newImg:any;//声明img
  croppedWidth:number;
  croppedHeight:number;
  constructor(public toastr: ToastrService,
    public opera:Opera) {
      this.cropperSettings = new CropperSettings();
      this.cropperSettings.width = 200;
      this.cropperSettings.height = 200;
      this.cropperSettings.croppedWidth = 200;
      this.cropperSettings.croppedHeight = 200;
      this.cropperSettings.canvasWidth = 200;
      this.cropperSettings.canvasHeight = 200;
      this.cropperSettings.minWidth = 10;
      this.cropperSettings.minHeight = 10;
      // this.cropperSettings.rounded = false;
      this.cropperSettings.keepAspect = false;
      this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
      this.cropperSettings.cropperDrawSettings.strokeWidth = 2;

      this.cropperSettings.noFileInput = true;
      // this.cropperSettings.cropOnResize = true;
      this.cropperSettings.fileType = 'image/jpeg';
      this.cropperSettings.rounded = true;
      // this.cropperSettings.keepAspect = true;
      this.data = {};
    }
      //初始化函数
      initData(data){
        this.newImg = new Image();
        this.newImg.src = data;
        this.ImageCropper.setImage(this.newImg);   
      }

      @Output() onVoted = new EventEmitter();
      dismiss(result: any) {
        //console.log("传值检查");
        //console.log(result);
        this.onVoted.emit(result);
      }
   
      //确定裁剪
      trueCrop(){
        this.opera.loading();
        let croppedCanvas;  
        let roundedCanvas; 
        this.opera.loadiss();
        // console.log("打印data");
        // console.log(this.data.image);
        this.dismiss(this.data.image);
      }

}
