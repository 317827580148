<div class="hot-block">
  <img style="position: absolute;top: 20px;left: 20px"  (click)="goWork()" src='assets/images/router-old.png'/>
<div class="content">
  <div class="con-l">
    <div  class="con-l-list">
        <div class="rank-top">
          <div class="item">
            <img *ngIf="comList[1].logo" class="logo" src="{{comList[1]?.logo}}" />
            <img *ngIf="!comList[1].logo" class="logo" src="../../assets/images/work/logo_null.png" />
            <div class="name">{{comList[1]?.department_name}}</div>
            <div class="desc">{{comList[1]?.employer_type_text}}|{{comList[1]?.scale_text}}|{{comList[1]?.industry_text}}</div>
            <div class="hot">{{comList[1]?.score}}</div>
            <img class="rank" src="../../assets/images/work/rank-2.png" />
          </div>
          <div class="item" style="margin-top: -10px">
            <img *ngIf="comList[0].logo" class="logo" src="{{comList[0]?.logo}}" />
            <img *ngIf="!comList[0].logo" class="logo" src="../../assets/images/work/logo_null.png" />
            <div class="name">{{comList[0]?.department_name}}</div>
            <div class="desc">{{comList[0]?.employer_type_text}}|{{comList[0]?.scale_text}}|{{comList[0]?.industry_text}}</div>
            <div class="hot">{{comList[0]?.score}}</div>
            <img class="rank" src="../../assets/images/work/rank-1.png" />
          </div>
          <div class="item">
            <img *ngIf="comList[2].logo" class="logo" src="{{comList[2]?.logo}}" />
            <img *ngIf="!comList[2].logo" class="logo" src="../../assets/images/work/logo_null.png" />
            <div class="name">{{comList[2]?.department_name}}</div>
            <div class="desc">{{comList[2]?.employer_type_text}}|{{comList[2]?.scale_text}}|{{comList[2]?.industry_text}}</div>
            <div class="hot">{{comList[2]?.score}}</div>
            <img class="rank" src="../../assets/images/work/rank-3.png" />
          </div>
        </div>
        <img style="margin-top: 20px;width: 789px;height: 1px" src="../../assets/images/work/space.png">
        <div class="tit-top">
          <div style="width: 57px;text-align: center">排名</div>
          <div style="width: 50%;margin-left: 20px;text-align: center">企业</div>
          <img style="margin-left: 50px" src="../../assets/images/work/hot_tit.png" />
        </div>
        <div class="rank-list">
          <div class="item"  *ngFor="let n of comList;let i = index;">
            <div *ngIf="n.sort != 1 && n.sort != 2 && n.sort != 3" style="width:57px;text-align: center" class="rank">{{n?.sort}}</div>
            <img *ngIf="n.sort == 1" class="rank-logo" src="../../assets/images/work/sort1.png">
            <img *ngIf="n.sort == 2" class="rank-logo" src="../../assets/images/work/sort2.png">
            <img *ngIf="n.sort == 3" class="rank-logo" src="../../assets/images/work/sort3.png">
            <div style="width: 65%" class="info">
              <img *ngIf="n.logo" class="logo" src="{{n?.logo}}" />
              <img *ngIf="!n.logo" class="logo" src="../../assets/images/work/logo_null.png" />
              <div class="" style=" margin-left: 15px;">
                <div class="name">{{n?.department_name}}</div>
                <div class="desc">{{n?.employer_type_text}}|{{n?.scale_text}}|{{n?.industry_text}}</div>
              </div>
            </div>
            <div style="width: 20%" class="score">{{n?.score}}<img src="../../assets/images/work/hot_s.png" /></div>
          </div>
        </div>
    </div>


  </div>
  <div class="con-r">
    <div style="width: 100%;text-align: center;margin: 20px 0">
      <img style="width: 167px;height: 21px;text-align: center" src="../../assets/images/work/hot_t.png" />
    </div>
    <div class="hot-qus">&nbsp;&nbsp;&nbsp;&nbsp;1.使用校招工具，获得更多浏览和投递</div>
    <div class="con">
      <img (click) = "goBuy('voice_call')" src="../../assets/images/work/banner_r1.png">
      <div class="space"></div>
      <img (click) = "goBuy('sms_bulkinvitation')" src="../../assets/images/work/banner_r2.png">
      <div class="space"></div>
      <img (click) = "goBuy('activity_invitation')" src="../../assets/images/work/banner_r3.png">
      <div  class="space"></div>
      <img (click) = "goBuy('position_refresh')" src="../../assets/images/work/banner_r4.png">
    </div>
    <div style="margin-top: 20px" class="hot-qus">&nbsp;&nbsp;&nbsp;&nbsp;2.分享小程序码，获得更多浏览和投递</div>
    <div class="text">
      <p>   1.把小程序码分享到学生社群，学生直接扫码投递</p>
      <p>   2.作为校招宣传，用于微信推文/朋友圈海报/展架物料等</p>
    </div>
    <div  (click) = "viewMiniPro()"  class="qr-code">
      <img src="../../assets/images/make_qrcode.png" />
    </div>
  </div>
</div>
</div>
<p-dialog id="aboutLaCoin" [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" 
[style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':300,'height':300,'minHeight':300}"  [modal]="true" [showHeader]="false" [(visible)]="isShowQrcode">
  <div style="width: 300px;height: 300px;background-color: white;overflow: hidden;">
    <img style="width: 300px;height: 300px;" src="{{myDepartment.miniprogram_qrcode}}">
  </div>
</p-dialog>
