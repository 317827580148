<!-- 页头部分 -->
<!-- <div class="contentRight-header">企业中心 - 发票</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <div class="college-block">
    <!-- 页头部分 -->
    <div class="college-block-head collegeBlockMargin flex_row_between">
      <!-- <p>发票</p> -->
      <div class="college-tab">
        <em (click)="opera.goNewRouter('company-status-two')"><img src='assets/images/router-old.png'/>返回</em>
        <span (click)="receiptTab(1)" [class.bold]="type == 1">可开票订单</span>
        <span (click)="receiptTab(2)" [class.bold]="type == 2">已开票订单</span>
      </div>
      <span (click)="receiptNotice()">发票须知</span>
    </div>
    <!-- 可开票订单 -->
    <div class="receiptBox" *ngIf="type == 1">
      <p class="receiptHeader">可开票订单</p>
      <ul class="receiptList flex_row_left">
        <li *ngFor="let data of dataList" (click)="goApply(data)">
          <h5 class="flex_row_between">{{data.account_name || "测试"}}
            <span *ngIf="data.invoice_status=='uninvoiced'" class="uninvoiced">未开票</span>
            <span *ngIf="data.invoice_status=='invoicing'" class="invoicing">开票中</span>
            <span *ngIf="data.invoice_status=='invoiced'" class="invoiced">已开票</span>
            <span *ngIf="data.invoice_status=='invalid'" class="invalid">已作废</span>
          </h5>
          <p><b>￥{{data.amount}}</b><em>电子发票</em></p>
          <p class="dottedLine"></p>
          <p class="flex_row_between">
            <span>工作啦</span>
            <span>{{data.add_time | date:'yyyy.MM.dd'}}</span>
          </p>
        </li>
      </ul>
    </div>
    <!-- 已开票订单 -->
    <div class="receiptBox" *ngIf="type == 2">
      <p class="receiptHeader">已开票订单</p>
      <ul class="receiptList flex_row_left">
        <li *ngFor="let data of dataList1" (click)="goSuc(data)">
          <h5 class="flex_row_between">{{data.account_name ||  "测试"}}
            <span *ngIf="data.invoice_status=='uninvoiced'" class="uninvoiced">开票中</span>
            <span *ngIf="data.invoice_status=='invoicing'" class="invoicing">开票中</span>
            <span *ngIf="data.invoice_status=='invoiced'" class="invoiced">已开票</span>
            <span *ngIf="data.invoice_status=='invalid'" class="invalid">已作废</span>
          </h5>
          <p><b>￥{{data.amount}}</b><em>电子发票</em></p>
            <p class="dottedLine"></p>
          <p class="flex_row_between">
            <span>工作啦</span>
            <span>{{data.add_time | date:'yyyy.MM.dd'}}</span>
          </p>
        </li>
      </ul>
    </div>
    <!-- 分页部分 -->
    <div class='paging-block' [class.isPageHeight]="countList>0">
      <router-outlet></router-outlet>
      <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
    </div>
  </div>
</div>