<div class="nav">

    <ul>
        <li *ngFor="let n of RouteList;let i = index;" class="flex_l" (click)="routeClick(n,i)">
            <img style="width: 16px;height: 16px;margin-top: 0px" *ngIf="i==0" src="../../assets/images/back_blue.png">
            <span style="margin-right: 6px" *ngIf="i!=0">{{"|"}}</span>
            <span class="nav_title">{{''+routeTitle(n)}}</span>
            <!--<span class="nav_title_current" *ngIf="i==RouteList.length-1">{{''+routeTitle(n)}}</span>-->
        </li>
    </ul>

</div>