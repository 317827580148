import { Component, OnInit } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config } from '../../provider/index';
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-college-authentication-suc',
  templateUrl: './college-authentication-suc.component.html',
  styleUrls: ['./college-authentication-suc.component.css']
})
export class CollegeAuthenticationSucComponent implements OnInit {
  public sucEndTime = "";
  public config: Config;

  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public toastr: ToastrService

) {
    this.config = Config.getInstance();//实例化config
    console.log("this.config",this.config);

  }

  ngOnInit() {
    // console.log("ngOnInit",this.config.department);
    this.getDepartment();

    this.opera.initConfig('cloud','company-authentication');
    this.router.navigateByUrl("home/tool-campus");

    // this.sucEndTime = this.formatDate(new Date(this.config.department.authentication_endtime))

  }

  formatDate(now) {
    var year=now.getFullYear();
    var month=now.getMonth()+1;
    var date=now.getDate();
    return year+"年"+month+"月"+date+"日";
  }
  //获取企业信息
  getDepartment(){
    this.opera.loading();
    this.dataApi.getDepartment().then((res:any)=> {
      this.opera.loadiss();
      if(res)
      {
        this.config.sucEndTime = this.formatDate(new Date(res.authentication_endtime))
      }
    }).catch((error) => {
      this.opera.loadiss();
      console.log(error);
      this.toastr.error(error);
    });
  }


}
