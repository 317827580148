<!-- 用户确认操作弹出框 -->
<div class="modal-dialog">
  <div  class="modal-content" *ngIf="!isShowClose" [ngClass]="{'modal-content2':data.buyType == 'buyProduct_labi'}">
    <div class="payTitle">
      订单详情<img (click)="closeOrder()" src="../../assets/images/chips_colse.png"/>
    </div>
    <div class="payBox1">
      <div class="box1Cell flex_l titleColor">
        <div class="box1Cell_col1">商品名称</div>
        <div class="box1Cell_col2">数量</div>
        <div class="box1Cell_col3">权益明细</div>
        <!--<div class="box1Cell_col4">小计(啦豆)</div>-->
      </div>
      <div class="box1Cell flex_l" *ngFor="let n of productList;let i = index;" [ngClass]="{'giveColor':n.give,'':!n.give}">
        <div class="box1Cell_col1">{{n.name}}</div>
        <div class="box1Cell_col2">{{n.give?n.count :n.price}}</div>
        <div class="box1Cell_col3">{{n.quanyi}}</div>
        <!--<div class="box1Cell_col4" *ngIf="n.give"><span class="giveColor_gray">{{n.price*n.count}}</span><span class="giveColor">0</span></div>-->
        <!--<div class="box1Cell_col4" *ngIf="!n.give">{{n.type=='labi'?n.count:n.price*n.count}}</div>-->
      </div>
    </div>
    <div class="payBox2">
      <div class="box2Cell flex_r" *ngFor="let n of detailList;let i = index;">
        <div class="box2Cell_col1">{{n.name}}</div>
        <div class="box2Cell_col2" [ngClass]="{'allprice':n.type=='zhifu','':n.type !='zhifu'}">{{n.value}}
          <!--<span style="font-size: 14px;color: #999999;font-weight: normal" *ngIf="zhekou!='0'&&n.type=='zhifu'">({{zhekou}} 折)</span>-->
        </div>
      </div>
    </div>
    <div class="payBox3_top">
      <div>产品包有效期均为365天（购买即生效)</div>
      <div>啦豆长期有效</div>
    </div>
    <div class="payBox3" *ngIf="data.buyType != 'buyProduct_labi'">
      <div class="payBox3_title" *ngIf="AllPayCount<=3000&&!isUrmWeChatPay">选择支付方式</div>
      <div class="fapiao flex_l" (mouseover)="showinfo()" (mouseout)="dimissinfo()">支付成功后可申请开具增值税电子普通发票 <img src="../../assets/images/singleMark.png"/>
        <div id="fapiao_info" class="fapiaoBox">
          <div class="fapiaoTop flex_c"><img src="../../assets/images/daosanjiao2.png"/></div>
          <div class="fapiaoBottom">根据国家税务局规定，增值税电子普通发票的法律效力， 基本用途，基本使用规定与纸质增值税普通发票相通。如 需纸质报销，可将电子文档进行打印</div>
        </div>
      </div>
      <div class="codeBg flex_c" *ngIf="payCode" >
        <qr-code [value]="payCode" [size]="130" *ngIf="!isUrmWeChatPay"></qr-code>
        <div style="width: 130px;height: 130px" id="qrcode" *ngIf="isUrmWeChatPay">
        </div>
      </div>
      <iframe class="iframeBox" frameborder="no"  border="0" marginwidth="0" marginheight="0" scrolling="no"
              [src]="payUrl" *ngIf="payUrl" style="width: 130px;height: 130px">
      </iframe>
      <img class="RefreshCode" *ngIf="isShowRush" (click)="refresh()" src="../../assets/images/pay-refresh.png">

      <div class="payList flex_c" *ngIf="!isUrmWeChatPay">
        <div *ngFor="let n of PayChannel;let i = index;">
          <div class="payList_div" *ngIf="n.alias=='alipay_pc_direct'" [ngClass]="{'payList_div_s':n.selete,'payList_div':!n.selete}"  (click)="payChannelClick(n)" > <img src="{{n.ico}}" class="pay_icon" />{{n.name}}
          <img *ngIf="n.selete" class="seleteImage" src="../../assets/images/icon_xuanzhong.png"  />
          </div>
          <div class="payList_div" *ngIf="n.alias=='wx_pub_qr' && AllPayCount<=3000" [ngClass]="{'payList_div_s':n.selete,'payList_div':!n.selete}"  (click)="payChannelClick(n)" > <img src="{{n.ico}}" class="pay_icon" />{{n.name}}
            <img *ngIf="n.selete" class="seleteImage" src="../../assets/images/icon_xuanzhong.png"  />
          </div>
        </div>
      </div>

      <div *ngIf="isUrmWeChatPay" style="text-align: center;margin-top: 10px">{{urmPayTypeText}}</div>
      <div class="xieyi" >支付即代表同意<span (click)="gotoXieyi()">《增值服务协议》</span></div>
    </div>
    <div class="bottomPayBg flex_r" *ngIf="data.buyType == 'buyProduct_labi'&& httpData">
      <div class="cancelPay" (click)="closeOrder()">取消</div>
      <div class="commitPay" (click)="laPay()">确认购买</div>
      <div class="xieyi" style="text-align: right;margin-top: 6px;margin-right: 10px">支付即代表同意<span (click)="gotoXieyi()">《增值服务协议》</span></div>
    </div>
  </div>
  <div  class="modal-content-close animated shake" *ngIf="isShowClose">
    <div class="closeTitle">是否放弃本次付款</div>
    <div class="closeBtnView flex_c">
      <div class="fangqi" (click)="closeModelClick(2)">放弃</div>
      <div class="jixu" (click)="closeModelClick(1)">继续付款</div>
    </div>
  </div>

</div>
