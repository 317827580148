<div class="login-block" *ngIf="!qrLogin">
    <div class="topLogo">
        <img src="/assets/images/v3/loginLogo.png"> 「工作啦-大学生直聘」，在线直聘大学生！
    </div>
    <div class="login-content">
        <div class="login-content-top">
            <div class="left">
                <div class="layui-carousel swiper-container_banner" id="test1">
                    <div carousel-item style="width:100%;height: 414px;cursor: pointer">
                        <div *ngFor="let item of bannerListImg">
                            <img class="swiperImage" *ngIf='item.image_url' (click)="goto_gwl(item.links)" [src]="item.image_url">
                        </div>
                    </div>
                </div>
            </div>


            <div class="right">
                <div class="logo"><img src="/assets/images/v3/loginLogo.png"></div>
                <div *ngIf="!showBindWXFrom">
                    <div class="loginTxt-header">
                        <div [class.sel-btn]="loginType=='scan'" (click)="changeLoginType('scan')">扫码登录<span *ngIf="loginType=='scan'"></span></div>
                        <div [class.sel-btn]="loginType=='phone'" (click)="changeLoginType('phone')">手机登录/注册<span *ngIf="loginType=='phone'"></span></div>
                        <!-- <div [class.sel-btn]="loginType=='email'" (click)="changeLoginType('email')">邮箱登录/注册<span *ngIf="loginType=='email'"></span></div> -->
                    </div>
                    <!-- 主体部分  Scan-->
                    <div class="loginTxt-content" *ngIf='loginType=="scan"'>
                        <div style="width: 300px;height: 300px;margin: 0 auto;">
                            <div class="tip">扫描下方二维码，使用微信扫码登录 </div>
                            <div id="login_container">
                            </div>
                        </div>

                    </div>
                    <!-- 主体部分  Phone-->
                    <div class="loginTxt-content" *ngIf='loginType=="phone"'>
                        <!-- 账号 -->
                        <div class="login-list">
                            <div class="inputCell">
                                <input [(ngModel)]="loginName" type="text" placeholder="请输入手机号码" oninput="if(value.length>11)value=value.slice(0,11)" />
                            </div>
                            <div style="width: 30px"></div>
                        </div>
                        <!-- 图形验证码 -->
                        <div class="login-ImgCode login-list">
                            <div class="inputCell active">
                                <input [(ngModel)]="vcodeImg" type="text" placeholder="请输入验证码" />
                            </div>
                            <div class="rightTuWen" (click)="getCode()"><img *ngIf="imgCode" [src]="imgCode"><img *ngIf="!imgCode" src="assets/images/login-img-code.png"></div>
                        </div>
                        <!-- 手机验证码 -->
                        <div class="login-code login-list">
                            <div class="inputCell active">
                                <input [(ngModel)]="vcodeText" type="text" (keyup)="inputKeyup($event)" placeholder="请输入手机验证码" />
                            </div>
                            <div class="rightBtn" *ngIf="!isSendOver && !countDown" (click)="postMobileSmsVcode()">获取验证码</div>
                            <div class="rightBtn" *ngIf="countDown">{{countDown}}</div>
                            <div class="rightBtn" *ngIf="isSendOver && !countDown" (click)="postMobileSmsVcode()">重新发送</div>
                        </div>
                    </div>
                    <!-- 主体部分  email-->
                    <div class="loginTxt-content" *ngIf='loginType=="email"'>
                        <!-- 账号 -->
                        <div class="login-list">
                            <div class="inputCell">
                                <input [(ngModel)]="loginEmail" type="text" placeholder="请输入邮箱" />
                            </div>

                            <div style="width: 30px"></div>
                        </div>
                        <!-- 密码 -->
                        <div class="login-list">
                            <div class="inputCell">
                                <input [(ngModel)]="loginEmailPsw" type="password" placeholder="请输入密码" />
                            </div>
                            <div style="width: 110px"></div>
                        </div>
                        <!-- 手机验证码 -->
                        <div class="login-ImgCode login-list">
                            <div class="inputCell active">
                                <input [(ngModel)]="vcodeImg" type="text" placeholder="请输入验证码" />
                            </div>
                            <div class="rightTuWen" (click)="getCode()"><img *ngIf="imgCode" [src]="imgCode"><img *ngIf="!imgCode" src="assets/images/login-img-code.png"></div>
                        </div>
                    </div>
                    <!-- 尾部 -->
                    <div class="login-foot" *ngIf='loginType!="scan"'>
                        <div class="pass-box">
                            <div class="pass-box-left">
                                <div *ngIf='loginType=="phone"' (click)="isRememberAccount=!isRememberAccount" class="loginFoot-head">
                                    <div class="choice" [class.isRemenber]="isRememberAccount"><span *ngIf="isRememberAccount"></span></div>
                                    记住账号
                                </div>
                                <div *ngIf='loginType=="email"' (click)="isRememberAccount1=!isRememberAccount1" class="loginFoot-head">
                                    <div class="choice" [class.isRemenber]="isRememberAccount1"><span *ngIf="isRememberAccount1"></span></div>
                                    记住邮箱
                                </div>
                            </div>
                            <div class="pass-box-right">
                                <span style="text-decoration:underline;cursor: pointer;color: #3063FF;" *ngIf='loginType=="email"' (click)="gotoFindPsw()">忘记密码</span>
                                <span style="color: #3063FF;cursor: pointer;" *ngIf='loginType=="phone"' (click)="gotoUserXieyi('none')">《用户协议》</span>
                            </div>
                        </div>
                        <div class="loginFoot-txt">
                            <div class="btn blueStreamer" (click)="postMobileSmsCode()">{{loginType=='phone'?'登录或注册':'登录或注册'}}</div>
                        </div>
                    </div>
                </div>

                <div *ngIf="showBindWXFrom">
                    <img (click)="wxBack()" class="bingWX_Back" src="../../assets/images/left.png">
                    <p class="bingWX_title">微信绑定工作啦账号</p>
                    <!-- 账号 -->
                    <div class="loginTxt-content bingWX_Top">
                        <div class="login-list">
                            <img src="assets/images/login/login-user.png" alt=""><input [(ngModel)]="loginName" type="text" placeholder="请输入手机号码" oninput="if(value.length>11)value=value.slice(0,11)" />
                            <div style="width: 110px"></div>
                        </div>
                        <!-- 图形验证码 -->
                        <div class="login-ImgCode login-list">
                            <img src="assets/images/login/login-imgCode.png" alt=""><input [(ngModel)]="vcodeImg" type="text" placeholder="请输入验证码" />
                            <div (click)="getCode()"><img *ngIf="imgCode" [src]="imgCode"><img *ngIf="!imgCode" src="assets/images/login-img-code.png"></div>
                        </div>
                        <!-- 手机验证码 -->
                        <div class="login-code login-list">
                            <img src="assets/images/login/login-code.png" alt=""><input [(ngModel)]="vcodeText" type="text" (keyup)="inputKeyup($event)" placeholder="请输入手机验证码" />
                            <div class="bingWX_getCode" *ngIf="!isSendOver && !countDown" (click)="postMobileSmsVcode()">获取验证码</div>
                            <div class="bingWX_getCode" *ngIf="countDown">{{countDown}}</div>
                            <div class="bingWX_getCode" *ngIf="isSendOver && !countDown" (click)="postMobileSmsVcode()">重新发送</div>
                        </div>
                        <button class="bingWX_Btn" (click)="bingWXClick()">绑定</button>
                    </div>
                </div>
            </div>
        </div>

        <!--数据看板-->
        <div class="kanbanContainer">
            <div class="logo">
                <img src="/assets/images/rights/kanbanLogo.png">
            </div>
            <div class="cell">
                <div class="name">校园招聘数据看板</div>
                <div class="time">更新时间：<span>{{kanbanData.updateTime}}</span></div>
            </div>
            <div class="rightMain">
                <div class="item">
                    <div class="title">求职者人数</div>
                    <div class="number"><span>{{kanbanData.value2}}</span>人</div>
                </div>
                <div class="item">
                    <div class="title">总用人单位</div>
                    <div class="number"><span>{{kanbanData.value3}}</span>家</div>
                </div>
                <div class="item">
                    <div class="title">总发布职位</div>
                    <div class="number"><span>{{kanbanData.value4}}</span>个</div>
                </div>
                <div class="item">
                    <div class="title">总岗位数</div>
                    <div class="number"><span>{{kanbanData.value5}}</span>个</div>
                </div>
            </div>
        </div>
    </div>

</div>

<p-dialog [showHeader]="false" [closeOnEscape]="false" [(visible)]="updateLoginEmailPsw" [style]="{'width':650,'height':330,'minHeight':330}" [modal]=true>
    <p style="text-align: center;margin-top: 40px;color: red">当前密码安全等级较低，请立即修改新密码</p>
    <div class="sign-list">
        <span>新密码：</span><input type={{seePswType2}} [(ngModel)]="updatePswData.newPsw" placeholder="请填写新密码(数字加字母组合)">
        <img *ngIf="seePswType2=='text'" (click)="changeSeePsw(2,'password')" style="width: 25px;height: 25px;margin-left: 10px" src="assets/images/no_seePsw.png">
        <img *ngIf="seePswType2=='password'" (click)="changeSeePsw(2,'text')" style="width: 25px;height: 25px;margin-left: 10px" src="assets/images/seePsw.png">
    </div>
    <div class="sign-list">
        <span>确认密码：</span><input type={{seePswType3}} [(ngModel)]="updatePswData.okPsw" placeholder="请填写确认密码(数字加字母组合)">
        <img *ngIf="seePswType3=='text'" (click)="changeSeePsw(3,'password')" style="width: 25px;height: 25px;margin-left: 10px" src="assets/images/no_seePsw.png">
        <img *ngIf="seePswType3=='password'" (click)="changeSeePsw(3,'text')" style="width: 25px;height: 25px;margin-left: 10px" src="assets/images/seePsw.png">
    </div>
    <button class="updateEmailBtnClick" (click)="savePsw()">保存</button>
</p-dialog>
<!--<p-dialog  [width]="300" [height]="400" [minHeight]="400" [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="false" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"  [modal]="true" [showHeader]="false" [(visible)]="showWXCode">-->
<!--<div style="width: 300px;height: 400px;overflow: hidden;" id="login_container">-->
<!--</div>-->
<!--</p-dialog>-->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="config.chrome_core" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':560,'height':280,'minHeight':280}"
    [modal]=true>
    <div class="system_out_bg">
        <div style="height: 90px;margin: 95px 77px">
            <img class="system_out_diannao" src="../../assets/images/diannao.png" />
            <li style="margin-left: 30px"> <img style="width: 16px;height: 16px;margin-right: 5px;margin-top: -5px;" src="../../assets/images/chips_yellow.png" /> <span style="font-size:16px;color: #5E4FFF ">系统提示</span></li>
            <li style="margin-left: 30px;margin-top: 5px;"><span style="color: #666666;font-size: 16px;">您使用的浏览器不兼容，可能会影响您的使用，推荐使用谷歌浏览器</span></li>
        </div>
    </div>
</p-dialog>

<div class="screenModel" *ngIf="config.isScreenSmallBox">
    <div class="screenModelTitle flex_l"><img src="../../assets/images/orange_warning.png" />分辨率过低</div>
    <img class="screenModelClose" (click)="closescreenModel()" src="../../assets/images/close_default.png" />
    <div class="screenModel_content">你当前的分辨率小于1680*1050，会影响使用体验，建议你切换至合适的屏幕分辨率 （部分页面支持“CTRL和减号键”进行尺寸调整）</div>
</div>
<!-- 
<div class="topTip">
    <div class="center">
        <marquee loop="loop" scrollamount="5" class="notice-list">
            尊敬的用户：随着工作啦用户数量持续增加，为了更好地满足用户使用需求，平台已于2021年11月29日晚11时—11月30日6时进行服务器及网络资源的扩容升级。扩容升级后，如您操作出现软件和网络方面的问题，请及时与您的校招顾问或客服取得联系，客服电话：4006611396。
        </marquee>
    </div>
</div> -->