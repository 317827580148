import {Component, OnInit, Output,ViewChild} from '@angular/core';
import {DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";

@Component({
  selector: 'app-my-single-product-list',
  templateUrl: './my-single-product-list.component.html',
  styleUrls: ['./my-single-product-list.component.css']
})
export class MySingleProductListComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true }) 
  productModel: ProductModelComponent;

  public currentAlias = '';
  public ArrList = [];
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 1;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService
  ) { }

  ngOnInit() {
    let str = this.local.get("mySingleProductList");
    if (str) {
      let json = JSON.parse(str)
      this.currentAlias = json.alias;
      this.getList();
      let topNav = this.local.get('nav_tool');
      this.opera.initConfig('tool', topNav);
    }
  }
  getList(){
    let str = this.local.get("mySingleProductList");
    if (str) {
      let json = JSON.parse(str)
      this.currentAlias = json.alias;

      this.dataApi.getcomponentList({ skip:(this.pageNumber-1)*10,
        limit:'10',alias:this.currentAlias}).then((res:any)=>{
        console.log('getcomponentList',res.list);
        let arr = [];
        this.countList = res.count;
        this.pageCount = Math.ceil(this.countList/10)
        res.list.map((item)=>{
          item.ico = json.ico;
          item.old = item.total - item.left
          item.startTimeStr = this.RiQi(Date.parse(item.begintime))
          item.endTimeStr = this.RiQi(Date.parse(item.endtime))
          if(this.opera.isAddvert(item.alias)){
            item.old = (item.total - item.left)/7
            item.left = (item.left/7)+'周'
          }
          arr.push(item);
        })
        this.ArrList = arr;
      }).catch((err)=>{
        console.log(err);
      })

    }
  }

  //时间戳转时间
  RiQi(sj)
  {
    var now = new Date(sj);
    var   year=now.getFullYear();
    var   month=now.getMonth()+1;
    var   date=now.getDate();
    var   hour=now.getHours();
    var   minute=now.getMinutes();
    var   second=now.getSeconds();
    return   year+"/"+month+"/"+date;
  }
  //去详情
  gotoDetail(n){
    this.local.set('productUseHistory',this.currentAlias);
    this.local.set('productUseHistoryLeft',n.left);
    this.router.navigate(['home/productUseHistory']);
  }

  //获取分页子组件返回数据
  getChildEvent(index){
    // this.pageNumber = index;
    if(index > 0){
      this.pageNumber = index;
      this.getList();
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.getList();
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.getList();
      }
    }
  }

  productModelHidden(event){    
    this.isproductShowModel = false;
  }

  //点击产品
  product(item,type,event){
    console.log(item);
    event.stopPropagation();
    this.productModel.initData(item.alias,type);
    this.isproductShowModel = true;
  }
}
