import { Component, OnInit,Input, Output,ViewChild } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from './../confirm/confirm.component';
import { ModelSelectTwoComponent } from '../model-select-two/model-select-two.component';
import { ModelCropperComponent } from '../model-cropper/model-cropper.component';
import {regexManager} from "../../provider/regexManager";
import {NoneVipAlertComponent} from "../none-vip-alert/none-vip-alert.component";
import {LogoutAccountModelComponent} from "../logout-account-model/logout-account-model.component";
declare var window;
@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.css']
})
export class GuideComponent{
  @ViewChild('guideIndustry', { static: true }) child: ModelSelectTwoComponent;
  @ViewChild('crop', { static: true }) child1: ModelCropperComponent;
  @Output() modelTwoShow = false;//是否打开弹窗
  @Output() modelShow = false;//是否打开弹窗
  public config: Config;
  //对象初始化
  public model = {
    linkman : '', //联系人
    post : '', //职务
    gender:'01',//性别
    name: '', //公司全称
    common_name : '', //公司简称
    industry : '', //行业
    employer_type:'',// 企业性质
    email : '', //工作邮箱
    wechat:'',//微信号
    employer_category:'0101',
    contact_info_switch:{
      is_public_mobile:true,
      is_public_wx:true,
      is_public_email:true
    }
  };
  public industryListOne=[];//一级行业列表
  public industryListTwo=[];//二级行业列表
  public industryOne='';//行业类别一级
  public isSend=false;//是否点击发送按钮
  //头像默认值
  public userAvatar = '';
  public guideStep=false;

  //企业注册地字典
  public globalRegionAllArr = [];
  public globalRegionArr1 = [];
  public globalRegionArr2 = [];
  public globalRegionValue = '01';

  public loginPhone = ""

  constructor(public dataApi: DataApi,
              public local:Local,
              public opera:Opera,
              private location: PlatformLocation,
              private router: Router,
              public dialog: MatDialog,
              public regexManager:regexManager,
              public toastr: ToastrService) {

    this.opera.initConfig('login','');
    this.config = Config.getInstance();//实例化config

    this.config.isGuide=true;

    //获取企业类别字典
    this.dataApi.getDictDate('employer_type').then((res:any)=>{
      this.config.employer_type=res;
    }).catch((error)=>{});

    this.getIndustry();//获取所属行业
    this.getGlobal_region();//获取企业注册地

    if(this.local.get(Constants.byCollegeDetails)){
      this.guideStep=true;
    }

    this.initDepartment();
    window.onpopstate = (event) => {
      console.log('onpopstate',event);
      window.location.reload()
    };
  }



  //获取所属行业
  getIndustry(){
    if(this.local.get(Constants.industry)){
      //console.log('获取行业类型本地存储');
      //console.log(this.dataApi.getIndustryCode());
      this.config.industry = this.dataApi.getIndustryCode();
    }else{
      //获取行业类型字典
      this.dataApi.getDictDate('industry').then((res:any)=>{
        //console.log('获取行业类型线上数据');
        //console.log(res);
        this.dataApi.setIndustryCode(res);
      }).catch((error)=>{
        //console.log(error);
      });
    }
  }

  //选择行业类别
  chooseIndustry(){
    this.modelShow = true;
    //调用子组件函数
    this.child.initData(this.model.industry,this.config.industry,'选择行业类别');
    this.opera.scrollTop();//页面滚动函数
  }
  //接收子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
    if(event){
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      this.model.industry = event.code;
    }
  }

//验证企业全称
  checkName(){
    if(this.model.name){
      let filter2=/^[a-zA-Z\uff08\uff09\u4e00-\u9fa5]+$/;

      // if(!filter2.test(this.model.name)){
      //   this.toastr.warning("企业全称只能输入汉字和字母");
      //   return;
      // }
      // model.name
      let title = '您申请的公司（'+this. model.name+') 全称已存在，是否继续注册？（注册后可正常使用）'
      this.opera.loading();//加载动画
      this.dataApi.testDepartmentName({name:this.model.name}).then((res:any)=>{
        this.opera.loadiss();//停止加载动画
        //console.log(res);
        if(res.value>0){
          this.opera.scrollTop();//页面滚动函数
          this.dialog.open(ConfirmComponent, {
            data: {
              title: '温馨提示',
              message: title,
              trueText:'确定',
              falseText:'取消'
            }
          }).afterClosed().subscribe((data) => {
            //console.log('检测是否存在返回值');
            //console.log(data);
            //  //console.log(typeof(data));
            this.opera.scrollTrue();
            if(!data){
              this.model.name=''
            }
          });
        }
      }).catch((err)=>{
        this.opera.loadiss();//停止加载动画
        //console.log(err);
      })
    }
  }
//验证企业简称
  checkCommonName(){
    if(this.model.common_name){
      let filter2=/^[a-zA-Z\uff08\uff09\u4e00-\u9fa5]+$/;
      if(!filter2.test(this.model.common_name)){
        this.toastr.warning("企业简称只能输入汉字和字母");
        return;
      }
    }
  }
//验证姓名
  checkLinkman(){
    if(this.model.linkman){
      let filter2=/^[a-zA-Z\u4e00-\u9fa5]+$/;
      if(!filter2.test(this.model.linkman)){
        this.toastr.warning("姓名只能输入汉字和字母");
        return;
      }
    }
  }
//验证职位
  checkPost(){
    if(this.model.post){
      let filter2=/^[a-zA-Z\u4e00-\u9fa5]+$/;
      if(!filter2.test(this.model.post)){
        this.toastr.warning("职务只能输入汉字和字母");
        return;
      }
    }
  }
//验证邮箱
  checkEmail(){


    if(this.model.email){
      let phoneModel={
        format_type:2,//0:手机号，1：手机号&固话；2.邮箱
        data:this.model.email
      }
      this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
        if(!res.value){
          this.toastr.warning('邮件格式错误，请重新填写！');
          return;
        }
      }).catch((err)=>{
        //console.log(err);
        this.toastr.error('邮件格式错误，请重新填写！');
      })
    }
  }

//创建引导(创建企业)
  setOneStep(){
    let filter2=/^[a-zA-Z\uff08\uff09\u4e00-\u9fa5]+$/;
    if(!filter2.test(this.model.name)){
      this.toastr.warning("企业全称只能输入汉字和字母");
      return;
    }
    if(!filter2.test(this.model.common_name)){
      this.toastr.warning("企业简称只能输入汉字和字母");
      return;
    }
    if(!this.model.wechat){
      this.toastr.warning("请输入微信号");
      return;
    }
    if(this.model.wechat){
      let filter3 = /^[^\u4e00-\u9fa5]*$/;
      if(!filter3.test(this.model.wechat)){
        this.toastr.warning("请输入正确的微信号");
        return;
      }
    }

    this.isSend=true;
    //---必填字段检查
    if(!this.model.common_name||!this.model.employer_category ||!this.model.email||!this.model.employer_type||!this.model.industry||!this.model.linkman||!this.model.name||!this.model.post){
      this.toastr.warning("请完善必填项信息");
      return;
    }
    this.opera.loading();//加载动画
    if(this.local.get('resetOneStep')){//返回重新填写信息
      this.dataApi.resetOneStep(this.model).then((res:any)=>{
        //console.log(res);
        this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
          //console.log(res);
          if(res){
            this.dataApi.setLocalAccountId(res.accountid);//将accounid存到本地、全局变量
            this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
            this.opera.loadiss();//停止加载动画
            //------跳转企业认证页面
            this.router.navigateByUrl('guide-company');
          }else{
            this.opera.loadiss();//停止加载动画
            setTimeout(() => {
              this.toastr.error('保存失败,请重新提交');
            },600);
          }

        }).catch((error)=>{
          //console.log(error);
          this.opera.loadiss();//停止加载动画
          setTimeout(() => {
            this.toastr.error(error);
          },600);
        });


      }).catch((error) => {
        this.opera.loadiss();//停止加载动画
        setTimeout(() => {
          this.toastr.error(error);
        },600);

      });

    }else{
      this.dataApi.setOneStep(this.model).then((res:any)=>{
        //console.log(res);
        this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
          //console.log(res);
          if(res){
            this.dataApi.setLocalAccountId(res.accountid);//将accounid存到本地、全局变量
            this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
            this.opera.loadiss();//停止加载动画
            //------跳转企业认证页面
            this.router.navigateByUrl('guide-company');
          }else{
            this.opera.loadiss();//停止加载动画
            setTimeout(() => {
              this.toastr.error('保存失败,请重新提交');
            },600);
          }

        }).catch((error)=>{
          //console.log(error);
          this.opera.loadiss();//停止加载动画
          setTimeout(() => {
            this.toastr.error(error);
          },600);
        });


      }).catch((error) => {
        this.opera.loadiss();//停止加载动画
        setTimeout(() => {
          this.toastr.error(error);
        },600);

      });
    }
  }

//上传logo
  previewPic(event) {
    let imgData  = event.target.files[0]; //获取file对象
    if (parseInt(imgData.size)/1024 > 1024*5) {
      this.toastr.warning('该图片大小超出了5M，请重新上传');
      return ;
    }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.imageCropper(reader.result);
      };

    }
  }

//裁剪插件
  imageCropper(imageData){
    this.modelTwoShow=true;
    this.child1.initData(imageData);
    // this.opera.scrollTop();//页面滚动函数
  }

//接收子组件返回数据
  ModelTwodismiss(event){
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    if(event){
      this.uploadDepImg(event);
    }
  }

//上传
  uploadDepImg(imageData){
    imageData=this.dataApi.formatImage(imageData);

    this.opera.loading();//加载动画
    this.dataApi.updateUserAvatar({value: imageData}).then((res:any)=>{
      if(res)
      {
        this.userAvatar = res.value;
        this.opera.loadiss();//停止加载动画
        setTimeout(() => {
          this.toastr.success('上传成功');
        },600);
      }
    }).catch((error)=>{
      this.opera.loadiss();//停止加载动画
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    })
  }
  //企业注册地修改
  employer_categoryClick(type){
    console.log('type',type);
    this.model.employer_category = type;
  }
  getGlobal_region(){
    this.dataApi.getDictDate('global_region').then((res:any)=>{
      this.globalRegionAllArr = res;
      this.globalRegionAllArr.map((item,index)=>{
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];
        if(item.code.length=='2')
        {
        this.globalRegionArr1.push(item)
        }
        else
        {
          // this.globalRegionArr1.push(item);
          if(this.model.employer_category)
          {
            if(this.globalRegionValue==item.code.slice(0, 2))
            {
              this.globalRegionArr2.push(item)
            }
          }
        }
      });
       // console.log('getGlobal_region',res,this.globalRegionArr2,this.model.employer_category)
    }).catch((error)=>{
      //console.log(error);
    });
  }
  //注册地址一级变动
  employer_categoryChange(e){
    this.globalRegionValue = e;
    let arr = [];
    this.globalRegionAllArr.map((item,index)=>{
      let code = item.code.slice(0, 2);
      if(code==this.globalRegionValue&&item.code.length==4)
      {
        arr.push(item);
      }
    });
    this.globalRegionArr2 = arr;
    this.model.employer_category = "";
    // console.log('globalRegionValue',e,this.globalRegionValue,this.globalRegionArr1,this.globalRegionArr2)
  };
  //注销账号
  logoutAccountClick(){
    this.dialog.open(LogoutAccountModelComponent, {
      data: {}
    }).afterClosed().subscribe((isConfirmed) => {
      console.log('isConfirmed',isConfirmed)
      if(isConfirmed){
      }
    });
  }
  initDepartment(){
    this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
      if(res){
        if(res.name)
        {
          this.model.name = res.name;
        }
        if(res.common_name)
        {
          this.model.common_name = res.common_name;
        }
        if(res.industry)
        {
          this.model.industry = res.industry;
        }
        if(res.employer_type)
        {
          this.model.employer_type = res.employer_type;
        }
        if(res.linkman)
        {
          this.model.linkman = res.linkman;
        }
        if(res.post)
        {
          this.model.post = res.post;
        }
        if(res.wechat)
        {
          this.model.wechat = res.wechat;
        }
        if(res.email)
        {
          this.model.email = res.email;
        }
        if(res.employer_category)
        {
          this.globalRegionValue = res.employer_category.slice(0, 2);
          this.model.employer_category = res.employer_category;
          this.getGlobal_region();
        }
        if(res.contact_info_list){
          this.model.contact_info_switch.is_public_email = res.contact_info_list.email.is_public
          this.model.contact_info_switch.is_public_mobile =res.contact_info_list.mobile.is_public
          this.model.contact_info_switch.is_public_wx = res.contact_info_list.wx.is_public
        }
        console.log('this.globalRegionValue',this.globalRegionValue);
        //industry
      }
    }).catch((error)=>{
    });
    this.dataApi.getUser().then((res:any)=>{//通过tooken获取企业信息
      if(res){
        if(res.avatar)
        {
          this.userAvatar = res.avatar;
        }
        if(res.gender)
        {
          this.model.gender = res.gender;
        }
        if(res.mobile){
          this.loginPhone = res.mobile
        }
      }
    }).catch((error)=>{
    });

  }

}
