import { Component, OnInit ,Input, Output,ViewChild,ElementRef } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute,Router } from  '@angular/router';
import { ModelStillMilkComponent } from '../model-still-milk/model-still-milk.component';
import { ModelPositionMoreComponent } from '../model-position-more/model-position-more.component';
import { regexManager } from "../../provider/regexManager";

declare let laydate;
// declare let UE;
declare var window;
declare var layui;
declare var layer;
@Component({
  selector: 'app-college-campus-sign',
  templateUrl: './college-campus-sign.component.html',
  styleUrls: ['./college-campus-sign.component.css']
})
export class CollegeCampusSignComponent implements OnInit {
  public config: Config;
  @ViewChild('neditor', { static: true }) editorElem: ElementRef;
  @ViewChild('child', { static: true }) child: ModelStillMilkComponent;
  @ViewChild('child1', { static: true }) child1: ModelPositionMoreComponent;
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelTwoShow = false;//是否打开弹窗
  public item:any;
  public isShowText=false;//是否完全展示
  public myDepartment:any;//企业信息

  //是否注销
  public isZhuXiaoAlert = false;
  public isZhuXiaoAlert_title = "";

  //是否显示高校的宣讲会提示，10秒后消失
  public isShowCampChipsWithCollege = false;
  public isHaveCampChipsWithCollege = false;
  public isHaveCampChipsWithContent = "";

  public ChipsWithCollegeIndex = 11
  public ChipsWithCollegeTimer;
  public ChipsWithCollegeBtn = ''

  public model={
    campustalk_type: 'college',//宣讲会类型 college 校级 faculty 院级,
    contacts:'',//联系人
    cover:'',//背景图
    name:'',//宣讲主题
    intro:'',//宣讲说明
    attendance:'',//参会人数
    phone:'',
    start_time:"",
    end_time:"",
    positionids:[],//职位id
    facultyid:'',//院系id
    attachment:[],//附件
    campusid:'',//校区id
    major_list:[],//院系id&专业
    scale:'200',//人数规模
    major:[],
    is_invite:false,
    remark:'',//备注
    collegeid:"",
    proxy_document:'',//招聘委托书
    business_licence:'',//企业营业执照
    id_card_front:'',//身份证正面
    register_number:'',//营业执照注册号
    brief:'',//申请简述
    office_phone:'',//固定电话
    school_permit:"",
    authid:null // 资质审核申请记录
  }
  public completeDate = "";//日期
  public isSend = false;//是否点击了发送按钮
  public college:any;//高校信息
  public applyDetails:any;//申请数据
  public orgList=[];//院系列表
  public orgListM=[];//院系列表
  public positionList=[];//选中职位列表
  public majorList=[];//专业列表（院级）
  public collegeMajorList=[];//专业列表（校级）
  public toolConfig: any;//编辑器配置
  public campusList=[];//校区列表
  public audit_switch = { // 高校开关列表
    department_receipt:'',
    department_receipt_url:'',
    // authcation_uncheck:true, // true 可以申请多个，false 不可以申请多个，高校合作查是否多个相同的企业申请高校合作
  };
  public secureMaterail:any;//招聘委托书下载链接
  public authcation_office_phone=false;
  public collegeName="";
  public department:any;//公司信息

  public CollegeIdentityModel={
    status:0,
    no_apply_30day:false,
    remark:'',
    audit_time:""
  };//高校云合作

  public showTab1=true;
  public showTab2=false;
  public ctid = null;
  public CollegeDetail = null;

  public isShowBackSave= false;//返回的时候是否显示返回保存弹窗

  public isEdit= false;//是否可编辑，用于提交后2小时内可以修改

  // 资质审核记录ID
  public authenticationApplyID = "";

  public campustalkApplyAllowd = true; //获取是否允许申请双选会,高校开关

  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public opera:Opera,
    public formatDate: FormatDate,
    public regexManager:regexManager,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    activatedRoute.queryParams.subscribe(queryParams => {
      if(queryParams.mrid){
        this.ctid  = queryParams.ctid;
      }
    })
      //----通过高校id获取高校详情
      this.getCollegeById(this.local.get(Constants.collegeid));//获取高校详情
      this.completeDate = this.formatDate.formatDate(this.formatDate.addDays(new Date(),1));
      //----获取宣讲会申请数据
      if(this.local.get(Constants.campusApply)){
        this.setApplyDetails();
      }else{
        //初始化时间
        // //console.log(this.completeDate);
        // this.model.start_time = this.completeDate+" 14:00:00";//默认填充为下午两点
        // this.model.end_time = this.completeDate+" 16:00:00";//默认填充为下午四点
      }
      this.toolConfig= {//编辑器配置
        elementPathEnabled: false, //删除元素路径
        initialFrameHeight: 500
      };
    }

  ngOnInit() {
    this.opera.initConfig('nav-activity','college-campustalk1');
    let that = this;
    var startDate = laydate.render({
      elem:'#start-time',
      type: 'datetime',
      done: function(value, date){
        that.model.start_time = value;
        endDate.config.min = date;//重点
        date.month = date.month - 1;
        endDate.config.min = {
          year: date.year,
          month: date.month,
          date: date.date,
          hours: date.hours,
          minutes: date.minutes,
          seconds: date.seconds
        };
        endDate.config.max = {
          year: date.year,
          month: date.month,
          date: date.date,
          hours: 23,
          minutes: 59,
          seconds: 59
        };
        that.model.end_time = ''
        console.log(that.model.start_time,startDate.config.min,date,endDate)
      }
    })
    var endDate = laydate.render({
      elem:'#end-time',
      type: 'datetime',
      done: function(value, date){
        that.model.end_time = value;
        console.log(that.model.end_time)
      }
    })
    this.model.collegeid = this.local.get(Constants.collegeid);
    this.getDepartment();//获取企业信息
    this.getMajorList();//获取专业列表信息
    this.getCollageDetails();//获取高校信息
    this.singleIdentity();//获取高校认证信息

    this.getCheckDepartmentcooperation(this.model.collegeid);
    this.getIdentityMaterial();//获取认证上传的材料标准
    this.getCampustalkApplyAllowd();
    //获取hr信息
    if(this.dataApi.getLocalLoginUser()){
      //console.log("获取公司HR联系电话");
      if(this.dataApi.getLocalLoginUser().mobile){
        this.model.phone = this.dataApi.getLocalLoginUser().mobile;//联系电话
      }
    }
    this.getPositionList();//获取职位数据

  }

  //获取校区列表
  getCollegeCampusList(){
    this.dataApi.getCollegeCampusList(this.local.get(Constants.collegeid)).then((res:any)=>{
      console.log("获取校区列表");
      console.log(res);
      if(res&&res.length>1){
        this.campusList=res;
      }
    }).catch((err)=>{
      this.toastr.error(err);
    })
  }

  // 获取高校信息
  getCollageDetails(){
    this.opera.loading();
    if(!this.local.get(Constants.collegeid))
    {
      return;
    }
    this.dataApi.getCollageDetails(this.local.get(Constants.collegeid)).then((res:any)=>{
      console.log("获取到高校信息",res);
      this.opera.loadiss();
      if(res){
        this.collegeName = res.name;
        this.audit_switch = res.audit_switch;
        this.CollegeDetail = res;

        if(res.audit_switch.faculty_authcation){
          // this.model.facultyid=this.local.get(Constants.facultyid);
          this.getOrgList();
        }

        console.log(this.audit_switch);
        if(res.audit_switch.authcation_office_phone){
          this.authcation_office_phone=true;
          console.log('填写固话');
        }else{
          this.authcation_office_phone=false;
        }

      }

    }).catch((error)=>{
      // console.log(error);
      this.opera.goChangeRouter('college-resume');
      this.toastr.error(error);
      this.opera.loadiss();
    })
  }

  //初始化赋值申请数据
  setApplyDetails(){
    this.applyDetails=JSON.parse(this.local.get(Constants.campusApply));
    console.log("修改宣讲会申请----", this.applyDetails);
    // console.log(this.applyDetails);
    this.model.contacts=this.applyDetails.contacts;
    this.model.name=this.applyDetails.name;
    this.model.intro=this.applyDetails.intro;
    this.model.attendance=this.applyDetails.attendance;
    this.model.phone=this.applyDetails.phone;
    this.model.scale=this.applyDetails.scale;
    this.model.cover=this.applyDetails.cover;
    this.model.attachment=this.applyDetails.attachment;
    this.model.start_time = this.applyDetails.start_time.replace('T', " ").split("+")[0];
    this.model.end_time = this.applyDetails.end_time.replace('T', " ").split("+")[0];
    this.model.campusid = this.applyDetails.campusid;
    // this.model.start_time = this.applyDetails.start_time;
    if(this.applyDetails.campustalk_type){
      this.model.campustalk_type=this.applyDetails.campustalk_type;
      this.majorValue();//专业赋值
    }else{
      this.model.campustalk_type='college';
    }
    if(this.applyDetails.facultyid){
      this.model.facultyid=this.applyDetails.facultyid;
    }
    if(this.applyDetails.positionids){
      this.model.positionids=this.applyDetails.positionids;
    }
    if(this.applyDetails.major){
      this.model.major=this.applyDetails.major;
    }
    this.model.is_invite=this.applyDetails.is_invite;
  }


  //赋值专业列表
  majorValue(){
    let ceshiMajor=[];
    if(this.applyDetails.major_list){
      this.model.major_list=this.applyDetails.major_list;
      for(let n of this.model.major_list){
        if(n.majors){
         for(let m of n.majors){
          ceshiMajor.push(m);
         }
        }
      }
    }
    if(this.model.campustalk_type=='college'){
      this.collegeMajorList=ceshiMajor;
    }else{
      this.opera.loading();
      this.dataApi.getCollegePart(this.local.get(Constants.collegeid)).then((res:any)=>{
        this.orgList = res;
        console.log('getCollegePart高校院系列表',this.orgList)
        for(let val of this.orgList){//循环遍历出所有专业
          if(val.facultyid==this.model.facultyid){
            this.majorList=val.major_list;
            for(let n of this.majorList){
               n.isActive=false;
            }
          }
        }
        //循环遍历出选中项
        for(let m of this.majorList){
           for(let c of ceshiMajor){
              if(m.majorid==c.majorid){
                m.isActive=true;
              }
           }
        }
        this.opera.loadiss();
      }).catch((err)=>{
        this.opera.loadiss();
        //console.log(err);
      })
    }
  }

  //选择院系后更新专业列表
  newMajor(){
    this.majorList=[];
    for(let val of this.orgList){
      if(val.facultyid==this.model.facultyid){
        this.majorList=val.major_list;
        for(let n of this.majorList){
           n.isActive=false;
        }
      }
    }
  }
  //切换选中状态
  newMajorList(n){
    // console.log("院级宣讲会");
    // console.log(n);
    if(n.isActive){
      n.isActive=!n.isActive;
    }else{
      let m=0;
      for(let a of this.majorList){
        if(a.isActive){
          m++;
        }
      }
      if(m>9){
        this.toastr.warning("意向专业最多添加10个");
        return;
      }
      n.isActive=!n.isActive;
    }

  }
  //获取列表
  getOrgList(){
    this.opera.loading();
    this.dataApi.getCollegePart(this.local.get(Constants.collegeid)).then((res:any)=>{
      // console.log(res);
      this.orgList = res;
      if(this.model.facultyid){
        this.newMajor();
      }
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
    })
  }

  //获取院系列表（根据校区筛选）
  getCampusOrgList(){
    this.opera.loading();
    this.model.major_list=[];
    this.collegeMajorList=[];
    this.model.facultyid='';
    this.dataApi.getCollegeCampusPart(this.local.get(Constants.collegeid),{campusid:this.model.campusid}).then((res:any)=>{
      this.orgList = res;
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
    })
  }

   //获取职位列表数据
   getPositionList(){
    //console.log("在期职位本地存储----");
    //console.log(this.dataApi.getLocalpositionList());
      this.getNewList();
  }

  //检测是否选择校区
  checkOrgList(){
    console.log('orgList',this.orgList)
    if(this.orgList.length==0){
      this.toastr.info("请选择院系/专业");
      return;
    }
  }

  //赋值职位默认值
  positionIdEval(){
    this.positionList=[];
    this.model.positionids=[];
    for(let m of this.config.positionList){
      if(!m.need_perfect){
        this.positionList.push(m);
        this.model.positionids.push(m.positionid);
      }
    }
  }

  //-------获取线上数据
  getNewList(){
    let model={
      limit:100,
      offset:0,
      status:'release',
    }
    this.dataApi.getPositionList(model).then((res:any)=>{
       //console.log("在期职位获取线上数据-----college-resume");
       //console.log(res);
      if(res)
      {
        this.config.positionList =  res.list;
        if(this.model.positionids.length>0){
          for(let m of this.config.positionList){
            for(let n of this.model.positionids){
              if(m.positionid==n){
                this.positionList.push(m);
                break;
              }
            }
          }
        }else{
          this.positionIdEval();//赋值全部职位id
        }
        this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
      }

     }).catch((error) => {
       this.toastr.error(error);
     });
  }

  //验证联系人格式
  checkContact(){
    let filter=/^[a-zA-Z\u4e00-\u9fa5]+$/;
    if(!filter.test(this.model.contacts)){
      this.toastr.warning("联系人姓名只能输入汉字或字母");
    }
  }

  //验证宣讲会主题格式
  checkName(){
    let filter=/^[0-9a-zA-Z\u4e00-\u9fa5]+$/;
    if(!filter.test(this.model.name)){
      this.toastr.warning("宣讲会主题不得输入特殊字符");
    }
  }

  //验证宣讲会期望人数
  checkNum(){
    let filter=/^[0-9]+$/;
    if(!filter.test(this.model.scale)){
      this.toastr.warning("宣讲会期望人数只能为数字");
    }
  }


  //验证手机号码格式
  checkPhone(){
    let phoneModel={
      format_type:1,//0:手机号，1：手机号&固话；2.邮箱
      data:this.model.phone
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(!res.value){
        this.toastr.warning("联系电话格式错误");
        return;
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    })
  }

  //通过高校id获取高校详情
  getCollegeById(id){
    if(!id)
    {
      this.toastr.warning('获取高校信息错误')
      return;
    }
    this.dataApi.getCollageDetails(id).then((res:any)=>{
      console.log("获取到高校信息",res);
      console.log(res);
      if(res)
      {
        this.college = JSON.parse(JSON.stringify(res));
        if(!this.college.campustalk_switch){
          this.toastr.warning("当前所选高校暂未开启宣讲会，请重新选择高校申请");
          this.router.navigateByUrl('/home/college-campustalk1');
        }
        if(this.college.audit_switch.campus_selection){//是否开启宣讲会校区选择
          this.getCollegeCampusList();//拉取校区列表
        }else{

        }
        this.getOrgList();//获取院系列表
        this.local.set(Constants.collegeid,this.college.collegeid);
      }

    }).catch((err)=>{
      this.toastr.error(err);
    });
  }

  //选择意向专业
  goMajor(){
    console.log('选择意向专业',this.orgList)
    if(this.orgList.length==0){
     this.toastr.info("请选择院系/专业");
     return;
    }
    this.modelShow = true;
    this.opera.scrollTop();
    //调用子组件函数
    //调用子组件函数
    this.child.initData(this.model.major_list,this.orgList,10);
    // this.choosePosition();
  }

  //接收子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
    if(event){
      this.collegeMajorList=[];
      // console.log("接收子组件返回数据");
      // console.log(event);
      this.model.major_list = event;
      for(let n of event){
         for(let m of n.majors){
           this.collegeMajorList.push(m);
         }
      }
    }

  }

  //选择职位
  choosePosition(){
    this.modelTwoShow = true;
    this.opera.scrollTop();
    this.child1.initData(this.model.positionids,'');
  }

  ModelTwodismiss(event){
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    if(event){
      // console.log("接收子组件返回数据");
      // console.log(event);
      this.positionList=[];
      this.model.positionids = event;
      for(let m of this.config.positionList){
        for(let n of this.model.positionids){
           if(m.positionid==n){
             this.positionList.push(m);
             break;
           }
        }
      }

    }
  }

  //上传logo
previewPic(event) {
  let imgData  = event.target.files[0]; //获取file对象
  if (window.FileReader) {
    var reader = new FileReader();
    reader.readAsDataURL(imgData);
    var that = this;
    reader.onloadend = function (e : any) {
      that.imageCropper(reader.result);
    };

  }
 }
 //上传
 imageCropper(imageData){
    imageData=this.dataApi.formatImage(imageData);
    this.opera.loading();//加载动画
    this.dataApi.uploadDepartmentImg({value: imageData}).then((res:any)=>{
      this.model.cover = res.value;
      this.opera.loadiss();//停止加载动画
      this.dataApi.setLocalDepartment(this.myDepartment);
      setTimeout(() => {
        this.toastr.success('上传成功');
      },600);
    }).catch((error)=>{
     this.opera.loadiss();//停止加载动画
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    })
 }

  //提交
  goSign(){
    if(!this.campustalkApplyAllowd){
      this.toastr.warning("30天内无法再次申请");
      return
    }
    this.isSend = true;
    if(this.model.campustalk_type=='college'){
      this.model.facultyid='';
    }else{
      if(!this.model.facultyid){
        this.toastr.warning("请完善宣讲会信息");
        console.log("请完善宣讲会信息",this.orgList)
        return;
      }
      this.model.major_list=[];
      let major=[];
      for(let val of this.majorList){
         if(val.isActive){
           major.push({code:val.code,text:val.name,majorid:val.majorid});
         }
      }
      for(let m of this.orgList){
        if(m.facultyid==this.model.facultyid){
          let model={
           facultyid:m.facultyid,
           faculty_name:m.name,
           majors:major
          }
          this.model.major_list.push(model);
        }
      }
    }
    // this.model.major_list = this.model.major_list;
    let d = new Date().getTime();//当前时间
    let d1=new Date(this.model.start_time).getTime();
    let d2 = new Date(this.model.end_time).getTime();
    console.log('检查参数');
    console.log(this.model);
    //console.log(d1);
    //console.log(d2);
    if(this.model.positionids.length==0 && this.model.campustalk_type=='college'){
      this.toastr.warning("请选择招聘职位");
      return;
    }

    if(this.campusList.length>0){
      if(!this.model.campusid){
        this.toastr.warning("请选择校区");
        return;
      }
    }

    let filter2=/^[a-zA-Z\u4e00-\u9fa5]+$/;
    if(!this.model.contacts||!this.model.attendance||!this.model.scale||!this.model.phone||!this.model.start_time||!this.model.end_time||!this.model.name||!this.model.intro){
      this.toastr.warning("请先完善宣讲会信息");
      return;
    }else if(d1<d){
      this.toastr.warning("宣讲会开始时间不得早于当前时间");
      return;
    }else if(d2<=d1){
      this.toastr.warning("宣讲会结束时间不得小于开始时间");
       return;
    }
    let filter=/^[0-9]+$/;
    if(!filter.test(this.model.scale)){
      this.toastr.warning("宣讲会期望人数只能为数字");
      return
    }
    if(this.audit_switch.department_receipt && this.model.attachment.length <= 0){
      this.toastr.warning("请至少上传一张附件!");
      return;
    }
    if(this.authenticationApplyID){
      this.model.authid = this.authenticationApplyID
    }

    this.isSend = false;
    this.opera.loading();//加载动画
    if(this.applyDetails){
      this.dataApi.updateCampusSign(this.applyDetails.ctid,this.model).then((res:any)=>{
        this.opera.loadiss();//停止加载动画
        this.local.remove(Constants.campusApply);
        this.toastr.success("修改成功");
        this.router.navigate(['home/position-campus']);//返回我的宣讲会列表
      }).catch((error)=>{
        this.opera.loadiss();//停止加载动画
        this.toastr.error(error);
        //console.log(error);
      })
    }else{
      this.dataApi.getCampustalkSign(this.college.collegeid,this.model).then((res:any)=>{
        this.opera.loadiss();//停止加载动画
        this.toastr.success("报名成功");
        this.router.navigate(['home/position-campus']);//返回我的宣讲会列表
      }).catch((error)=>{
        this.opera.loadiss();//停止加载动画
        this.toastr.error(error);
        //console.log(error);
      })
    }
  }

  //获取专业列表信息
  getMajorList(){
    if(this.dataApi.getMajorCode()){
      this.config.major = this.dataApi.getMajorCode();
    }else{
      this.dataApi.getDictDate('major').then((res:any)=>{
        //console.log("获取专业列表-----college-resume");
        if(res) {
          this.config.major = res;
          this.dataApi.setMajorCode(res);
        }
      }).catch((error)=>{
        //console.log(error);
      });
    }
  }


//获取企业信息
getDepartment(){
  this.dataApi.getDepartment().then((res:any)=>{
    //console.log("企业信息获取线上数据");
    //console.log(res);
    if(res)
    {
      this.dataApi.setLocalDepartment(res);
      this.myDepartment = res;
    }
  }).catch((error) => {
    //console.log(error);
    this.toastr.error(error);
  });
 }

   //附件图片
   previewMilkPic(event){
    if(this.model.attachment && this.model.attachment.length>2){
      this.toastr.warning("附件片最多上传3张");
      return;
    }
    let imgData  = event.target.files[0]; //获取file对象
     if (parseInt(imgData.size)/1024 > 1024*5) {
       this.toastr.warning('该附件大小超出了5M，请重新上传');
       return ;
     }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.uploadDepImg(reader.result,imgData.name);
      };
    }
  }

  //上传企业展示图片
  uploadDepImg(data,name){

    let imageData=data;
    imageData = this.opera.formatFileData(imageData)
    console.log('uploadDepImg',name,data)
    let filedata = {
      extension: name,
      filename: name,
      base64: imageData
    }
    this.opera.loading();
    // let loader = this.tip.presentLoading('图片保存中，请稍等...');
    this.dataApi.uploadDepartmentFile(filedata).then((res:any)=>{
      // loader.dismiss();
      this.opera.loadiss();
      this.model.attachment.push(res.link);
    }).catch((error)=>{
      // loader.dismiss();
      this.opera.loadiss();
      this.toastr.error("上传文件错误");
    })
  }

  //删除附件图片
  deleteImg(img){
    this.model.attachment.forEach((val)=>{
      if(val == img){
        let num = this.model.attachment.indexOf(val);
        this.model.attachment.splice(num,1);
      }
    })
  }
  //单个高校认证信息
  singleIdentity(){
    this.dataApi.lastCollegeAuthenticationIdentity(this.local.get(Constants.collegeid)).then((res:any)=>{
      if(res)
      {
        this.model.business_licence = res.business_license,//企业营业执照
          this.model.id_card_front =res.id_card_front;//身份证正面
        this.model.register_number = res.register_number;//营业执照注册号
        this.CollegeIdentityModel = res;
        if(res.status==5){
          this.showTab1 = false;
          this.showTab2 = false;
          return
        }
        if(res.brief){
          this.model.brief = res.brief;
        }
        if(res.office_phone){
          this.model.office_phone = res.office_phone;
        }
        if(res.status==1){
          this.stopTimer();
        }
        if(res.status==2||res.status==1)
        {
          this.showTab1 = false;
          this.showTab2 = true;
        }
        else
        {
          this.showTab1 = true;
          this.showTab2 = false;
          if(res.status==3 &&res.no_apply_30day)
          {
            this.model.facultyid = res.facultyid
            this.showTab1 = false;
            this.showTab2 = false;

            let currentdata = new Date();
            let data = new Date(this.CollegeIdentityModel.audit_time);
            data.setDate(data.getDate() + 30);
            if(currentdata>data)
            {
              console.log("用户勾选了30天可以申请",);
              this.showTab1 = true;
              this.showTab2 = false;
              this.CollegeIdentityModel.no_apply_30day = false;
            }
            return;
          }
        }
        if(this.local.get(Constants.campusApply))
        {
          this.getCampusInfo();
        }else {
          this.getCampApplyTips()
        }
      }
      else
      {
        this.CollegeIdentityModel.status= 0
        this.getCampApplyTips()

      }

      console.log("单个高校认证信息",res);
    }).catch((err)=>{
      this.getCampApplyTips()

    });
  }
  //获取认证上传的材料标准
  getIdentityMaterial(){
    let model={collegeid:this.local.get(Constants.collegeid)};
    this.dataApi.getMaterial('authentication',model).then((res:any)=>{
      console.log("获取认证材料成功");
      console.log(res);
      if(res)
      {
        if(res[0]){
          for(let list of res[0].material_infos){
            if(list.material_field=='proxy_document'){
              this.secureMaterail=list.material_example;
            }
          }
        }
      }

    }).catch((err)=>{
      //console.log("获取认证材料失败");
      //console.log(err);
    })
  }
  uploadDepartmentIdentity(){
    if(!this.model.business_licence){
      this.toastr.warning('请上传营业执照图片');
      return;
    }
    if(!this.model.id_card_front){
      this.toastr.warning('请上传身份证正面');
      return;
    }
    if(!this.model.register_number){
      this.toastr.warning('请填写统一社会信用代码');
      return;
    }

    if(this.regexManager.isRegister_number(this.model.register_number)) {
    } else {
      this.toastr.warning('统一社会信用代码不规范');
      return;
    }
    if(this.CollegeDetail.audit_switch.faculty_authcation){
      if(!this.model.facultyid){
        this.toastr.info("请选择当前申请学院");
        return;
      }
    }
    if(this.authcation_office_phone && !this.model.office_phone){
      this.toastr.warning('请填写办公电话');
      return;
    }
    if(this.secureMaterail){
      if(!this.model.proxy_document){
        this.toastr.info("请上传招聘委托书");
        return;
      }
    }
    // industry "0604" 教育机构//必须上传办学许可证
    if(this.myDepartment.industry=='0604'||this.myDepartment.industry=='0606'||this.myDepartment.industry=='0606') {
      if(!this.model.school_permit){
        this.toastr.info("请上传办学许可证");
        return;
      }
    }

    this.opera.loading();//加载动画
    this.dataApi.uploadDepartmentIdentity(this.model).then((res:any)=>{
      this.opera.loadiss();//停止加载动画
      if(res.value){
        this.authenticationApplyID = res.value
      }
      setTimeout(() => {
        this.CollegeIdentityModel.status=1
        this.toastr.info("资质审核已提交，请申请宣讲会️","提示")
        this.showTab1 = false;
        this.showTab2 = true;
        // this.getCollegeById(this.model.collegeid )//通过高校id获取高校详情

        //跳转页面
      },600);
    }).catch((error) => {
      ////console.log(error);
      this.toastr.error(error);
      this.opera.loadiss();//停止加载动画
    });
  }
  //上传图片
  CompanypreviewPic(event,name){
    console.log(event);
    console.log(name);
    let imgData  = event.target.files[0]; //获取file对象
    if (parseInt(imgData.size)/1024 > 1024*5) {
      this.toastr.warning('该图片大小超出了5M，请重新上传');
      return ;
    }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.uploadPic(reader.result,name);
      };
    }
  }
  //上传到服务器
  uploadPic(data,name){
    let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpg;base64,/g,'');
    this.opera.loading();//加载动画
    this.dataApi.uploadAuthImg({
      value: imageData
    }).then((res:any)=>{
      if(res){
        if(res.value){//------------上传之后赋值
          this.model[name] = res.value;
        }
      }
      this.opera.loadiss();//停止加载动画
    }).catch((error) => {
      ////console.log(error);
      this.opera.loadiss();//停止加载动画
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    });
  }

  // 企业获取宣讲会详情
  getCampusInfo(){
    this.applyDetails=JSON.parse(this.local.get(Constants.campusApply));
    this.dataApi.getCampusInfo( this.applyDetails.ctid).then((res:any)=>{
      this.applyDetails = res
      this.model.facultyid = res.facultyid;
      console.log("企业获取宣讲会详情",res);

      if(this.CollegeIdentityModel.status==3) {
        this.showTab1 = true;
        this.showTab2 = false;
        if(this.CollegeIdentityModel.status==3&&res.no_apply_30day) {
          this.showTab1 = false;
          this.showTab2 = false;

          let currentdata = new Date();
          let data = new Date(this.CollegeIdentityModel.audit_time);
          data.setDate(data.getDate() + 30);
          if(currentdata>data) {
            console.log("用户勾选了30天可以申请",);
            this.showTab1 = true;
            this.showTab2 = false;
            this.CollegeIdentityModel.no_apply_30day = false;
          } else {
            console.log("用户勾选了30天不可以申请",);
          }
        }
      }
      else if(res.audit_status=="to_audit") {
        this.showTab1 = false;
        this.showTab2 = false;
        this.stopTimer()
      }
      else if(res.audit_status=="no_pass") {
        this.showTab1 = false;
        this.showTab2 = true;
      }
      else if(res.audit_status=="passed") {
        this.showTab1 = false;
        this.showTab2 = false;
      }

      if(this.CollegeIdentityModel.status==3||res.audit_status=='no_pass') {
        this.isShowBackSave = true;
      } else {
        this.isShowBackSave = false;
      }
      //如果
      if(this.CollegeIdentityModel.status==2&&res.audit_status=="to_audit") {
        let signDate = new Date(res.department_modify_time)
        let now = new Date();
        let twoHourTime =  1000*60*60*2;
        if((now.getTime()-signDate.getTime())<=twoHourTime) {
          console.log('时间判断');
          this.isEdit = true;
          // this.toastr.warning('')
        }
      }

      this.getCampApplyTips();

    }).catch((error)=>{
      this.toastr.error(error);
      this.opera.loadiss();
    })
  }
  //college-campustalk1
  gotoOthercampustalk(){
    this.router.navigate(['home/college-campustalk1']);//返回我的宣讲会列表
  }
  getCheckDepartmentcooperation(collegeId){
    if(!this.local.get(Constants.collegeid)) {
      return;
    }
    this.dataApi.checkDepartmentcooperation(this.local.get(Constants.collegeid)).then((res:any)=>{
      if(res) {
        let account = "";
        for (let n of res) {
          if(!n.mobile) {
            n.mobile = "未知企业";
          }
          if(account=="") {
            account = this.phoneFormat(n.mobile)
          } else {
            account = account+"," + this.phoneFormat(n.mobile);
          }
        }
        this.isZhuXiaoAlert_title = account;
        if( this.isZhuXiaoAlert_title.length>0) {
          this.isZhuXiaoAlert = true;
        }
      }
    }).catch((error)=>{
    });
  }

  //两个小时内的修改信息弹窗
  commitUpdateAlert(){
    let that = this;
    layui.use('layer', function(){
      var layer = layui.layer;
      var index = layer.confirm('确定要修改信息吗？', {
        btn: ['确定','取消'] //按钮
      }, function(){
        layer.close(index);
        that.showTab1 =false ;
        that.showTab2 = true;
        that.isEdit = false;
      }, function(){
      });
    });
  }
  //注销账号
  zhuxiaoClick(){
    this.isZhuXiaoAlert = false;
    this.router.navigate(['home/account-logout-l']);//返回我的双选会列表
  }
  // 关闭注销按钮
  closeZhuXiao(){
    this.isZhuXiaoAlert = false
  }
  //开始高校的提示倒计时
  startTimeChips() {
    let that = this;
    this.ChipsWithCollegeTimer =  setInterval(()=>{
      that.ChipsWithCollegeIndex-=1;
      if(that.ChipsWithCollegeIndex==0){
        that.ChipsWithCollegeBtn  = "确定"
        if(that.ChipsWithCollegeTimer){
          clearInterval(that.ChipsWithCollegeTimer)
        }
        return;
      }
      that.ChipsWithCollegeBtn = that.ChipsWithCollegeIndex+ "s"
    },1000)
  }
  stopTimer(){
    if(this.ChipsWithCollegeTimer){
      clearInterval(this.ChipsWithCollegeTimer)
    }
    this.ChipsWithCollegeBtn = "确定"
  }
  //高校的提示 确定
  collegeChipsClick(){
    if(this.ChipsWithCollegeBtn== "确定") {
      this.isShowCampChipsWithCollege = false
    }
  }
  //获取双选会申请的提示信息
  getCampApplyTips(){
    this.dataApi.getCampApplyTips(this.local.get(Constants.collegeid)).then((res:any)=>{
       console.log('getCampApplyTips',res, this.CollegeIdentityModel);
       if(res){
         if(res.tip_switch){
           this.isShowCampChipsWithCollege = res.tip_switch;
           this.isHaveCampChipsWithCollege = true;
           this.isHaveCampChipsWithContent = res.tip;
           this.startTimeChips();
           if(this.CollegeIdentityModel){
             if(this.CollegeIdentityModel.status==1){
               this.stopTimer()
             }
           }
           if(this.applyDetails){
             if(this.applyDetails.audit_status=="to_audit"){
               this.stopTimer()
             }
           }

         }
       }
    }).catch((error)=>{
    });
  }
  phoneFormat(tel){
    if(!tel){
      return ''
    }
    tel = "" + tel;
    var tel1 =tel.replace(tel.substring(3,7), "****")
    return tel1
  }
  formatFileName(url){
    if(!url){
      return '附件'
    }
    try {
      let arr = url.split('/');
      let name = arr[arr.length-1];
      return name
    }catch (e) {
      return '附件'
    }
  }
  deleteFlie(url){
    let newArr = [];
    this.model.attachment.map((item)=>{
      if(item!=url){
        newArr.push(item)
      }
    });
    this.model.attachment = newArr
  }
  // 获取是否允许申请双选会，高校开关
  getCampustalkApplyAllowd(){
    if(!this.local.get(Constants.collegeid)){
      return
    }
    this.dataApi.getCampustalkApplyAllowd({collegeid:this.local.get(Constants.collegeid)}).then((res:any)=>{
      console.log('getCampustalkApplyAllowd',res);
      if(res){
        this.campustalkApplyAllowd = res.value
      }
    }).catch((error)=>{
    });
  }

}
