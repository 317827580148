import { Component, Input, Output,OnInit } from '@angular/core';
import { DataApi,Local,Opera,Constants,Config } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-college-milk-space',
  templateUrl: './college-milk-space.component.html',
  styleUrls: ['./college-milk-space.component.css']
})
export class CollegeMilkSpaceComponent implements OnInit {
  @Input()  isShow: boolean;//是否显示弹窗页面
  // 筛选条件--搜索
  public model={
    online:false,
    major:'',//专业
    education:'',//学历
    offset:0,
    limit:12
  }
  public config: Config;
  public educationList=[];//学历列表
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  public majorOne='';//专业类别一级
  public resumeList = [];//简历列表
  public countList = 0;//列表数量
  public milkId='';
  public milk:any;//双选会详情
  public isMilkEnd=false;//双选会是否已经结束
  public isMilkStart=false;//双选会是否已经开始
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event:NavigationEnd) => {
      if (event.url === '/home/college-milk-space') {
        console.log(111);
        this.milkId=this.local.get(Constants.mrid);
        this.opera.initConfig('college','college-milkround');
        this. getMilkDetails();//获取双选会详情
        this.getMajorList();//获取专业列表信息
        this.getEducationList();//获取学历列表信息
        this.getResumeList();//获取简历列表
      }
    });
    }

  ngOnInit() {
  }

  //-----跳转简历详情
  goDetails(n){
    console.log(n.seat_number);
    if(this.milk.status&&this.milk.status=='passed'){
      this.router.navigate(['home/college-resumedetails'], {
        queryParams: {
          resumeid:n.resume_number,
          positionid:'',
          seat_number:n.seat_number
        }
      });
    }else{
      this.toastr.warning("很抱歉，您未通过当前双选会的审核，暂时无法使用此功能");
    }
 }

 //收藏
 goCollect(e,val){
  e.stopPropagation();
  this.opera.manageResumeArchived(val);
}

  //获取双选会详情
  getMilkDetails(){
    this.dataApi.getMilkDetails(this.milkId).then((res:any)=>{
      //console.log(res);
      this.milk=res;
      let timeNow=new Date().getTime();
      let time=new Date(this.milk.start_time).getTime();
      let timeOne=new Date(this.milk.end_time).getTime();
      if(timeNow-time>0){
        this.isMilkStart=true;
        if(timeOne-timeNow<0){
          this.isMilkEnd = true;//双选会已结束
        }
      }else{
        this.isMilkStart=false;
      }
   }).catch((error)=>{
     //console.log(error);
   })
  }
  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----college-resume');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*12;
      this.getResumeList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*12;
        this.getResumeList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*12;
        this.getResumeList();//获取简历投递列表
      }
    }

  }
  //获取列表
  getResumeList(){
    this.opera.loading();
    this.dataApi.getMilkResumeList(this.milkId,this.model).then((res:any)=>{
      //console.log("进入会场获取简历列表---");
      //console.log(res);
      this.resumeList = res.list;
      this.countList=res.count;
      this.pageCount =Math.ceil(res.count/12);
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
    })
  }

  //监听majorOne
  majorOneChange(){
    // //console.log("第一级专业值");
    // //console.log(this.majorOne);
    // //console.log("专业列表");
    // //console.log(this.config.major);
    this.majorListTwo=[];
    this.model.major='';
    for(let n of this.config.major){
      if(n.parentcode==this.majorOne){
        this.majorListTwo.push(n);
      }
    }
    this.model.major='';
    // //console.log("第二级专业列表");
    // //console.log(this.majorListTwo);
  }


  //提示先选一级专业
 isShowTwo(){
  if(!this.majorOne){
    this.toastr.warning("请先选择一级专业");
    return;
  }
 }

  //获取学历列表信息
  getEducationList(){
    if(this.dataApi.getEducationCode()){
      //console.log("获取学历列表本地存储-----college-resume");
      //console.log(this.dataApi.getEducationCode());
      this.educationList = this.dataApi.getEducationCode();
    }else{
      this.dataApi.getDictDate('education').then((res:any)=>{
        //console.log("获取学历列表-----college-resume");
        //console.log(res);
        this.educationList = res;
        this.dataApi.setEducationCode(res);
      }).catch((error)=>{
        //console.log(error);
      });
    }
  }

  //获取一级专业列表
  getMajorListOne(){
    for(let n of this.config.major){
      if(n.level==2){
        this.majorListOne.push(n);
      }
    }
  }

  //获取专业列表信息
  getMajorList(){
    if(this.dataApi.getMajorCode()){
      this.config.major = this.dataApi.getMajorCode();
      this.getMajorListOne();//获取一级专业列表
    }else{
      this.dataApi.getDictDate('major').then((res:any)=>{
        //console.log("获取专业列表-----college-resume");
        //console.log(res);
        this.config.major = res;
        this.getMajorListOne();//获取一级专业列表
        this.dataApi.setMajorCode(res);
      }).catch((error)=>{
        //console.log(error);
      });
    }
  }

}
