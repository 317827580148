import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { Config,Opera,DataApi} from '../../provider/index';

@Component({
  selector: 'app-company-status-three',
  templateUrl: './company-status-three.component.html',
  styleUrls: ['./company-status-three.component.css']
})
export class CompanyStatusThreeComponent implements OnInit {
  public config: Config;
  public power:boolean=true;
  constructor(
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService,
    public dataApi: DataApi
  ) {
    this.config = Config.getInstance();//实例化config
  }
  ngOnInit() {
    this.opera.initConfig('member','member-center');
  }
  
}