<!-- v3.0 -->
<div class="schoolCloud">
    <div class="schoolHeader">
        <div class="center">
            <div class="goback" (click)="opera.goNewRouter('college-campustalk1')"><img src="assets/images/v3/headerReturnIcon.png">高校详情</div>
            <div class="top">
                <div class="logo">
                    <img *ngIf="college.logo" [src]="college.logo">
                    <img *ngIf="!college.logo" src='assets/images/none.png' />
                </div>
                <div class="text">
                    <div class="name">{{college.name}}</div>
                    <div class="time">{{college.school_type_text}}｜{{college.school_nature_text}}｜{{college.establish_time | date:"yyyy年MM月dd日"}}创办</div>
                    <div class="list">
                        <div class="item">学校代码：{{college.code?college.code:'暂无'}}</div>
                        <div class="item">联系邮箱：{{college.email?college.email:'暂无'}}</div>
                        <div class="item">学校地址：{{college.region_text?college.region_text:'暂无'}}</div>
                        <div class="item">就业电话：{{college.work_info.office_tel?college.work_info.office_tel:'暂无'}}</div>
                    </div>
                </div>
            </div>
            <div class="navList">
                <div class="item" (click)="opera.goNewRouter('college-details')">高校简介</div>
                <div class="item" (click)="goOrg()">教学机构</div>
                <div class="item active" (click)="goMilk()">双选会<span></span></div>
                <div class="item" (click)="goCampus()">宣讲会</div>
                <div class="item" (click)="goStudent()">生源速览</div>
                <div class="item" (click)="goNews()">新闻公告</div>
            </div>
        </div>

    </div>
    <div class="milkroundList">
        <div class="item" *ngFor="let n of milkRoundList;let i = index;">
            <div class="box">
                <div class="logo"><img src="{{n.logo}}"></div>
                <div class="main">
                    <div class="name">
                        <span class="session" *ngIf="n.is_welfare">专场</span>
                        <span class="sky" *ngIf="n.milkround_type == 2">空中双选会</span> {{n.name}}
                    </div>
                    <ul>
                        <li><img src="/assets/images/rights/college-list-icon01.png">{{n.college_name}}</li>
                        <li><img src="/assets/images/rights/college-list-icon02.png">举办日期 {{n.start_time| date:"yyyy年MM月dd日 HH:mm"}}</li>
                        <li><img src="/assets/images/rights/college-list-icon03.png">{{n.milkround_type == 2?"线上":n.place_name?n.place_name:'暂无'}}
                            <span *ngIf="n.milkround_type == 2 && n.is_online_interview"><img src="/assets/images/rights/video.png">视频双选会</span>
                        </li>
                    </ul>
                    <!-- 报名状态  -->
                    <div class="state">
                        <div class="left">
                            <!-- 报名阶段  -->
                            <div class="state-item" *ngIf="n.isAppStart && !n.isAppEnd && !n.isStart">
                                <div class="icon">报名阶段</div>
                                <div class="time"><img src="/assets/images/rights/time01.png">报名截止：{{n.apply_end_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                            </div>
                            <!-- 进行中  -->
                            <div class="state-item conduct" *ngIf="n.isStart && !n.isEnd">
                                <div class="icon"><img src="/assets/images/rights/conduct-icon.png">进行中</div>
                                <div class="time"><img src="/assets/images/rights/time02.png">活动结束：{{n.end_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                            </div>
                            <!-- 已结束  -->
                            <div class="state-item end" *ngIf="n.isEnd">
                                <div class="icon">已结束</div>
                                <div class="time"><img src="/assets/images/rights/time03.png">活动结束：{{n.end_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- 页头部分 -->
<!-- <div class="contentRight-header"><img (click)="opera.goNewRouter('college-details')" src='assets/images/router-old.png'/>高校云<span *ngIf="!config.isByCollege"></span> - 学校详情 - 双选会列表</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block" style="display: none;">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-college-milksign [number]="milkNumber" [mridList]="mridList" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-milksign>
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <div class="college-block" style="margin-bottom:20px;">
        <!-- 页头部分 -->
        <div class="college-block-head goBackHeader"><img class="goBack" (click)="opera.goNewRouter('college-details')" src='assets/images/router-old.png' />双选会列表</div>
        <!-- 详情模块 -->
        <div class="college-head-details clear" *ngIf="college">
            <!-- 左侧文字 -->
            <ul class="left">
                <li><span>学校名称：</span>{{college.name}}<i *ngIf="!college.name">暂无</i></li>
                <li><span>创办时间：</span>{{college.establish_time | date:"yyyy-MM-dd"}}<i *ngIf="!college.establish_time">暂无</i></li>
                <li><span>学校性质：</span>{{college.school_nature_text}}<i *ngIf="!college.school_nature_text">暂无</i></li>
                <li><span>联系邮箱：</span>{{college.email}}<i *ngIf="!college.email">暂无</i></li>
                <li><span>学校代码：</span>{{college.code}}<i *ngIf="!college.code">暂无</i></li>
                <li><span>所在城市：</span>{{college.region_text}}<i *ngIf="!college.region_text">暂无</i></li>
                <!-- <li><span>就业电话：</span>{{college.phone}}</li> -->
            </ul>
            <!-- 右侧logo -->
            <div class="right">
                <img *ngIf="college.logo" [src]="college.logo" alt="高校logo">
                <img *ngIf="!college.logo" src="assets/images/none.png" alt="高校logo">
            </div>
        </div>
    </div>
    <div class="college-block">
        <!-- 中间部分 -->
        <!-- 没有内容存在时 -->
        <div *ngIf="countList==0" class="none-block">
            <img src="assets/images/resume-search-fail.png"><br/> 暂未找到双选会信息，请稍后重试
        </div>
        <!-- 循环列表 -->
        <div class="college-list-cont" *ngIf="countList>0">
            <ul>
                <li>双选会主题</li>
                <li>学校名称</li>
                <li>双选会状态</li>
                <li>报名截止时间</li>
                <li>双选会开始时间</li>
                <li>双选会结束时间</li>
                <li>所在城市</li>
                <li>举办场地</li>
                <li>就业办电话</li>
                <li>报名状态</li>
                <li>操作</li>
            </ul>
            <!-- *ngFor="let n of collegeList;let i = index;" -->
            <div>
                <ul class="college-li" *ngFor="let n of milkRoundList;let i = index;">
                    <li><span *ngIf='n.name'>{{n.name}}</span><span *ngIf='!n.name'>暂无</span></li>
                    <li><span *ngIf='n.college_name'>{{n.college_name}}</span><span *ngIf='!n.college_name'>暂无</span></li>
                    <li *ngIf="!n.signOpTime">
                        <span *ngIf="!n.isStart">即将开始</span>
                        <span *ngIf="n.isEnd">已经结束</span>
                        <span *ngIf="n.isStart && !n.isEnd">正在进行</span>
                    </li>
                    <li *ngIf="n.signOpTime">
                        <span *ngIf="!n.isAppStart">等待报名</span>
                        <span *ngIf="n.isAppStart && !n.isAppEnd">正在报名</span>
                        <span *ngIf="n.isAppEnd && !n.isStart">即将开始</span>
                        <span *ngIf="n.isStart && !n.isEnd">正在进行</span>
                        <span *ngIf="n.isEnd">已经结束</span>
                    </li>
                    <li><span *ngIf='n.apply_end_time && n.apply_end_time != "" && n.apply_end_time != "0001-01-01T00:00:00+08:00" && n.apply_end_time != "0001-01-01T00:00:00"'>{{n.apply_end_time | date:"yyyy-MM-dd HH:mm" }}</span><span *ngIf='!n.apply_end_time || n.apply_end_time == "" || n.apply_end_time == "0001-01-01T00:00:00" || n.apply_end_time == "0001-01-01T00:00:00+08:00"'>暂无</span></li>
                    <li><span *ngIf='n.start_time'>{{n.start_time | date:"yyyy-MM-dd HH:mm" }}</span><span *ngIf='!n.start_time'>暂无</span></li>
                    <li><span *ngIf='n.end_time'>{{n.end_time | date:"yyyy-MM-dd HH:mm"}}</span><span *ngIf='!n.end_time'>暂无</span></li>
                    <li><span *ngIf='n.region_text'>{{n.region_text}}</span><span *ngIf='!n.region_text'>暂无</span></li>
                    <li><span *ngIf='n.place_name'>{{n.place_name}}</span><span *ngIf='!n.place_name'>暂无</span></li>
                    <li><span *ngIf='n.phone'>{{n.phone}}</span><span *ngIf='!n.phone'>暂无</span></li>
                    <li>
                        <span *ngIf="!n.status">未报名</span>
                        <span *ngIf="n.status=='to_audit'">报名中</span>
                        <span *ngIf="n.status=='passed'">报名成功</span>
                        <span *ngIf="n.status=='not_passed'">报名失败</span>
                    </li>
                    <li *ngIf="!n.signOpTime">
                        <span (click)="goDeatils(n.mrid)">查看</span>
                        <span *ngIf="!n.status && !n.isStart" (click)="goSign(n)">我要报名</span>
                        <span *ngIf="n.status == 'passed' && !n.isEnd && n.poster_type"><a  (click)="downloadPoster(n.applyid)">下载海报</a></span>
                        <!--<span class="snotice" (click)="singleTips(n)" *ngIf="n.status == 'passed' && !n.isEnd"><em>立即通知该校学生</em></span>-->
                        <!-- <span *ngIf="n.isStart" (click)="goSpace(n.mrid)">进入会场</span> -->
                        <!-- <span>收藏</span>
                  <span>分享</span> -->
                    </li>
                    <li *ngIf="n.signOpTime">
                        <span (click)="goDeatils(n.mrid)">查看</span>
                        <span *ngIf="!n.status && n.isAppStart && !n.isAppEnd" (click)="goSign(n)">我要报名</span>
                        <span *ngIf="n.status == 'passed' && !n.isEnd && n.poster_type"><a  (click)="downloadPoster(n.applyid)">下载海报</a></span>
                        <!--<span class="snotice" (click)="singleTips(n)" *ngIf="n.status == 'passed' && !n.isEnd"><em>立即通知该校学生</em></span>-->
                        <!-- <span *ngIf="n.isStart" (click)="goSpace(n.mrid)">进入会场</span> -->
                        <!-- <span>收藏</span>
                  <span>分享</span> -->
                    </li>
                </ul>
            </div>
        </div>
        <!-- 分页部分 -->
        <div class='paging-block' [class.isPageHeight]="countList>0">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>
    </div>
</div>


<!-- 新增高校 -->
<app-push-college-model #pushCollegeModel [isShow]="isShowPushCollegeModel" (modelHidden)="pushCollegeModelHidden($event)"></app-push-college-model>