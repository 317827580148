import { Component,EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataApi,Constants,Config,Local,Opera} from '../../provider/index';
declare var window;
@Component({
  selector: 'app-model-company-identity',
  templateUrl: './model-company-identity.component.html',
  styleUrls: ['./model-company-identity.component.css']
})
export class ModelCompanyIdentityComponent{
  @Input()  isShow: false;//是否显示弹窗页面
  @Input()  id: string;//高校id
  @Output() onVoted = new EventEmitter<boolean>();
  public config: Config;
  public model={
    collegeid:'',
    business_licence:'',//企业营业执照
    id_card_front:'',//身份证正面
    register_number:''//营业执照注册号
  }
  constructor(public toastr: ToastrService,
    public local:Local,
    public opera:Opera,
    public dataApi: DataApi) {
      this.config = Config.getInstance();//实例化config
  }

  //提交认证
  uploadDepartmentIdentity(){
    // let filter = /^[0-9a-zA-Z]{18}$/;
    if(!this.model.business_licence){
      this.toastr.warning('请上传营业执照图片');
      return;
    }
    if(!this.model.id_card_front){
      this.toastr.warning('请上传身份证正面');
      return;
    }
    if(!this.model.register_number){
      this.toastr.warning('请填写统一社会信用代码');
      return;
    }
    // else{
    //   let filterValue=this.model.register_number.trim();//删除左右两端的空格
    //   if(!filter.test(filterValue)){
    //     this.toastr.warning('统一社会信用代码格式错误');
    //     return;
    //   }
    // }
    this.opera.loading();//加载动画
    this.model.collegeid=this.id;
    this.dataApi.uploadDepartmentIdentity(this.model).then((res:any)=>{    
      this.opera.loadiss();//停止加载动画
      setTimeout(() => {              
        this.toastr.warning('提交成功');
        this.lastAuthenticationIdentity();//更新企业认证信息本地存储
        //跳转页面
        this.dismiss(true);
      },600);
    }).catch((error) => {
      //console.log(error); 
      this.opera.loadiss();//停止加载动画
      //跳转页面
      this.dismiss(false);
      // setTimeout(() => {              
      //   this.toastr.warning(error);  
      // },600);
    });
  }

  //获取上次认证信息
  lastAuthenticationIdentity(){
    this.opera.loading();
    this.dataApi.lastAuthenticationIdentity().then((res:any)=>{
      //console.log(res);
      if(res){
        this.config.lastCollegeIdentity=res;
        this.dataApi.setLastCollegeIdentity(this.config.lastCollegeIdentity);
      }
      this.opera.loadiss();
  }).catch((error)=>{
      //console.log(error);
      this.opera.loadiss();
  })
}

//身份证照片
previewPic(event){
  let imgData  = event.target.files[0]; //获取file对象
  if (window.FileReader) {    
    var reader = new FileReader();    
    reader.readAsDataURL(imgData);
    var that = this;
    reader.onloadend = function (e : any) {
      that.uploadDepImg(reader.result);
    };  
  
  }
}
//上传身份证照片
uploadDepImg(data){
    let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
    this.opera.loading();//加载动画
    this.dataApi.uploadAuthImg({
      value: imageData
    }).then((res:any)=>{    
      //console.log(res);
      if(res){
        if(res.value){//------------上传之后赋值
          this.model.id_card_front = res.value;
        }       
      }
      this.opera.loadiss();//停止加载动画
    }).catch((error) => {
      //console.log(error);     
      this.opera.loadiss();//停止加载动画
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    });            
}
//上传企业营业执照
previewBusiness(event){
  let imgData  = event.target.files[0]; //获取file对象
  if (window.FileReader) {    
    var reader = new FileReader();    
    reader.readAsDataURL(imgData);
    var that = this;
    reader.onloadend = function (e : any) {
      that.uploadBusinessImg(reader.result);
    };  
  
  }
}
//上传企业营业执照
uploadBusinessImg(data){
  let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
  imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
  this.opera.loading();//加载动画
  this.dataApi.uploadAuthImg({
    value: imageData
  }).then((res:any)=>{    
    //console.log(res);
    if(res){
      if(res.value){//------------上传之后赋值
        this.model.business_licence = res.value;
      }       
    }
    this.opera.loadiss();//停止加载动画
  }).catch((error) => {
    //console.log(error);     
    this.opera.loadiss();//停止加载动画
    this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
  });            
}

  dismiss(result: any) {
    //console.log("传值检查");
    //console.log(result);
    this.onVoted.emit(result);
  }

}
