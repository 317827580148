import {Component, Inject, OnInit} from '@angular/core';
import {Config, DataApi, Opera} from "../../provider";
import {Router} from "@angular/router";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {stringify} from "querystring";
import { DomSanitizer } from '@angular/platform-browser';

declare let  QRCode;
declare let pingpp;
export interface payModel {
  title:string;
  callback:any,
  Charge:any,
  buyType:string,
  pay_orderid:string,
  channel:string,
  labiData:any,
  buyProductData:any,
  numberData:any,
  isOrderPage:false,
  order:any
}

@Component({
  selector: 'app-model-pay-code',
  templateUrl: './model-pay-code.component.html',
  styleUrls: ['./model-pay-code.component.css']
})
export class ModelPayCodeComponent implements OnInit {

  title: string; //标题
  public config: Config; //配置文件
  public callback:any; //支付回钓
  public Charge:any; //支付对象
  public payCode:any;//二维码链接
  public buyType = "";//购买产品的类型，会员vip，啦豆labi，单品product 等vipProduct
  public pay_orderid = "";//订单支付的ID
  public channel = ""; //支付渠道
  public PayChannel = []; //支付列表
  public labiData:any; //购买啦豆传过来的数据

  public productList = []; //产品列表

  public detailList = [];  // 需支付数据列表

  // public timer = null; //60秒刷新定时器
  // this.config.payTimer//60秒刷新定时器

  public second = 60; //60秒刷新二维码

  public isRefresh = false;//是否显示刷新按钮

  public httpData:any; //啦豆购买HTTP请求的数据

  public isShowClose = false; //是否显示关闭按钮

  public isHTTPLoading = false; //网络请求状态

  //记录一下要支付的金额，为了判断如果大于3000 则屏蔽微信支付
  public AllPayCount = 0;

  //是否打折扣
  public zhekou = "0";

  public isOrderPage = false;

  public payUrl:any;

  public payHttpUrl = ""
  //检测是否是URM订单微信支付订单
  public isUrmWeChatPay = false
  public urmPayTypeText = ''

  public CheckPayCount = 0
  public CheckPayMAXCount = 300
  public isShowRush = false

  constructor(
    public toastr: ToastrService,
    public dataApi: DataApi,
    private router: Router,
    public opera: Opera,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: payModel
  ) {
    this.config = Config.getInstance();//实例化config

    this.isShowClose = false;
    let that = this;
    let dataModel = data;
    console.log('开始检测支付系统',data,this.payHttpUrl);

    // this.payHttpUrl = "http://urm.goworkla.cn/page/wappay?channel=ali_wap&orderid="
    console.log('开始检测支付系统',data,this.payHttpUrl);
    if(data.order) {
      if(data.order.urm_create){
        this.isUrmWeChatPay = true
      }
      if(this.config.api == "https://webapi.goworkla.cn/") {
        if(data.order.channel == "alipay_wap"){
          this.payHttpUrl = "http://urm.goworkla.cn/page/wappay?channel=alipay_wap&orderid="
        }else {
          this.payHttpUrl = "http://urm.goworkla.cn/page/wappay?channel=wx_pub&orderid="
        }
      }
      else {
        if(data.order.channel == "alipay_wap"){
          this.payHttpUrl = "http://urm.test.goworkla.cn/page/wappay?channel=alipay_wap&orderid="
        }else {
          this.payHttpUrl = "http://urm.test.goworkla.cn/page/wappay?channel=wx_pub&orderid="
        }
      }
    }

    if(data.callback)
    {
      this.callback = data.callback;
    }
    this.buyType = data.buyType;
    this.pay_orderid = data.pay_orderid;
    this.channel = this.config.alipay_key;
    if(data.buyType != 'buyProduct_labi')
    {
      // 如果是URM订单 默认选择支付方式
      if(this.isUrmWeChatPay && data.order.channel == 'wx_pub') {
          this.payCode = that.payHttpUrl+ this.pay_orderid
          setTimeout(()=>{
            console.log('URM微信支付',that.payHttpUrl+ this.pay_orderid)
            var qrcode = new QRCode('qrcode', {
              text:that.payHttpUrl+ this.pay_orderid,
              width: 130,
              height: 130,
              typeNumber:-1,
              colorDark : '#000000',
              colorLight : '#ffffff',
            });
          },200)
        this.urmPayTypeText = "微信扫码支付"
        this.config.payTimer = setInterval(()=>{
          this.CheckPayCount+=1
          if(this.CheckPayCount>this.CheckPayMAXCount){
            this.isShowRush = true;
            clearInterval(this.config.payTimer)
            return
          }
          that.dataApi.getPayStatusNew(this.pay_orderid).then((res:any)=>{
            if(res.value){
              clearInterval( this.config.payTimer);
              if(this.callback)
              {
                this.formarSuccess();
                this.callback('支付成功')
                return;
              }
            }
          }).catch((err)=>{
          })
        },1000)
      } else {
          if(this.isUrmWeChatPay && data.order.channel == 'alipay_wap') {
            this.payCode = that.payHttpUrl+ this.pay_orderid
            setTimeout(()=>{
              console.log('URM支付宝支付',that.payHttpUrl+ this.pay_orderid)
              var qrcode = new QRCode('qrcode', {
                text:that.payHttpUrl+ this.pay_orderid,
                width: 130,
                height: 130,
                typeNumber:-1,
                colorDark : '#000000',
                colorLight : '#ffffff',
              });
            },200)
            this.urmPayTypeText = "支付宝扫码支付"
            this.config.payTimer = setInterval(()=>{
              this.CheckPayCount+=1
              if(this.CheckPayCount>this.CheckPayMAXCount){
                this.isShowRush = true;
                clearInterval(this.config.payTimer)
                return
              }
              that.dataApi.getPayStatusNew(this.pay_orderid).then((res:any)=>{
                if(res.value){
                  clearInterval( this.config.payTimer);
                  if(this.callback) {
                    this.formarSuccess();
                    this.callback('支付成功')
                    return;
                  }
                }
              }).catch((err)=>{
              })
            },1000)
          } else {
            let model = {channel:this.config.alipay_key}  //alipay_pc_direct}
            this.dataApi.paymentOrderPayGetCharge(data.pay_orderid,model).then((res:any)=>{
              if(res)
              {
                console.log('paymentOrderPayGetCharge',res);
                //wx_pub_qr alipay_qr
                this.Charge = res;
                if(this.channel == "wx_pub_qr")
                {
                  this.payCode=res.credential.wx_pub_qr;
                }
                else if (this.channel == this.config.alipay_key) {
                  this.urmPayTypeText = "支付宝扫码支付"

                  this.opera._hmtPush("校招工具购买", "订单弹窗", "支付宝支付");
                  this.payCode = "";
                  pingpp.setUrlReturnCallback(function (err, url) {
                    // 自行处理跳转或者另外打开支付页面地址(url)
                    that.payUrl = that.sanitizer.bypassSecurityTrustResourceUrl(url);
                    console.log('payUrl',url);

                  }, ['alipay_pc_direct', 'alipay_wap']);
                  pingpp.createPayment(res, function (result, error) {
                  });
                }
                console.log('payCode',this.payCode,this.channel);
                this.config.payTimer = setInterval(()=>{
                  this.CheckPayCount+=1
                  if(this.CheckPayCount>this.CheckPayMAXCount){
                    this.isShowRush = true;
                    clearInterval(this.config.payTimer)
                    return
                  }
                  that.dataApi.getPayStatusNew(this.pay_orderid).then((res:any)=>{
                    if(res.value){
                      clearInterval( this.config.payTimer);
                      if(this.callback) {
                        this.formarSuccess();
                        this.callback('支付成功')
                        return;
                      }
                    }
                  }).catch((err)=>{
                  })
                },1000)

              }
            }).catch((err)=>{
              console.log(err);
              this.toastr.error(err)
            })

          }


        this.getPayChannel();
      }

    }
    if(data.buyType=='labi')
    {
      this.labiData = dataModel.labiData;
      // this.labiData.laCoin.count = 10000;
      let data = {name:'啦豆',time:'长期',price:this.labiData.laCoin.count,count:this.labiData.laCoin.count,type:'labi',gave:false,quanyi:'/'}
      let gave_data = {name:'啦豆 (充值赠送)',time:'长期',price:1,type:'labi_give',count:this.labiData.laCoin.give,give:true,quanyi:'/'}

      let chongzhi = {name:'充值:',value:this.labiData.laCoin.count,type:'chongzhi'};
      let give = {name:'赠送:',value:this.labiData.laCoin.give+'(啦豆)',type:'give'};
      let kaQuan = {name:'卡劵:',value:this.labiData.currentCard?this.labiData.currentCard.title:'暂无卡劵',type:'card'};
      let kaQuanGive = 0;
      if(this.labiData.currentCard)
      {
        kaQuanGive =  this.labiData.currentCard.gift_amount;
        give = {name:'赠送:',value:0+'(啦豆)',type:'give'};
        this.labiData.laCoin.give = 0;
        gave_data = {name:'卡劵满赠',time:'长期',price:1,type:'labi',count:this.labiData.currentCard.gift_amount,give:true,quanyi:'/'}
      }

      let daozhang = {name:'到账啦豆:',value:this.labiData.laCoin.count + this.labiData.laCoin.give + kaQuanGive,type:'daozhang'};
      let zhifu = {name:'需支付:',value:'￥'+this.labiData.laCoin.count,type:'zhifu'};
      this.AllPayCount = this.labiData.laCoin.count;
      this.detailList.push(chongzhi,give,kaQuan,daozhang,zhifu)
      this.productList.push(data);
      if(gave_data)
      {
        this.productList.push(gave_data);
      }
      console.log('购买啦豆productList',this.productList);
    }
    else if(data.buyType == 'buyProduct_labi')
    {
      //啦币购买单品   不需要展示支付二维码
      //buyProductData.member
      if(data.buyProductData.httpData)
      {
        this.httpData = data.buyProductData.httpData;
      }
      let arr = [];
      if(data.buyProductData.member)
      {
        arr.push({name:data.buyProductData.member.name,time:'365天 （购买即生效）',price:1,type:'member',count:data.buyProductData.member.price,give:false,quanyi:'365天'})
      }
      data.buyProductData.productList.map((item)=>{
        if(item.count>0)
        {
          let isGive = false;
          try {
            if(item.product_category =="ladou_gift")
            {
              isGive = true;
            }
          }catch(e){
          }
          arr.push({name:item.name,time:'365天 （购买即生效）',price:item.count,type:'product',count:item.price,give:isGive,quanyi:item.quanyi})
        }
      });
      this.productList = arr;
      let xiaoji = {name:'小计:',value:data.numberData.xiaoji,type:'xiaoji'};
      let ladouyuediyong = {name:'啦豆余额抵用:',value:data.numberData.ladouyuediyong,type:'ladouyuediyong'};
      let haixuzhifu = {name:'还需支付:',value:"¥"+data.numberData.haixuzhifu,type:'zhifu'};
      this.detailList.push(xiaoji,ladouyuediyong,haixuzhifu);
    }
    else if(data.buyType == 'buyProduct_code')
    {
      let arr = [];
      if(data.buyProductData.member)
      {
        arr.push({name:data.buyProductData.member.name,time:'365天',price:1,type:'member',count:data.buyProductData.member.price,give:false,quanyi:'365天'})
      }
      data.buyProductData.productList.map((item)=>{
        if(item.count>0)
        {
          let isGive = false;
          try {
            if(item.product_category =="ladou_gift")
            {
              isGive = true;
            }
          }catch(e){
          }

          arr.push({name:item.name,time:'365天',price:item.count,type:'product',count:item.price,give:isGive,quanyi:item.quanyi})
        }
      });
      this.productList = arr;
      let xiaoji = {name:'小计:',value:data.numberData.xiaoji,type:'xiaoji'};
      let ladouyuediyong = {name:'啦豆余额抵用:',value:data.numberData.ladouyuediyong,type:'ladouyuediyong'};
      let haixuzhifu = {name:'还需支付:',value:"¥"+ data.numberData.haixuzhifu,type:'haixuzhifu'};
      this.AllPayCount =data.numberData.haixuzhifu;
      let money = {name:'啦豆余额不足，请支付剩余金额:',value:"¥ "+data.numberData.haixuzhifu,type:'zhifu'};
      this.detailList.push(xiaoji,ladouyuediyong,haixuzhifu,money)
    }
    try {
      //URL订单是否传来折扣
      if(data.numberData.zhekou)
      {
        this.zhekou = data.numberData.zhekou;
      }
      if(data.isOrderPage)
      {
        this.isOrderPage = data.isOrderPage;
        console.log('isOrderPage',data.isOrderPage)
      }
    }catch (e) {
    }

    try {
      if(data.isOrderPage)
      {
        this.isOrderPage = data.isOrderPage;
        console.log('isOrderPage',data.isOrderPage)
      }
    }catch (e) {
    }
  }

  ngOnInit() {}
  testClick(){
    if(this.callback)
    {
      this.callback('支付成功')
    }
  }
  //检查付款状态
  getPayStatus(chargeid){
    this.dataApi.getPayStatus(chargeid).then((res:any)=>{
      console.log(res);
    }).catch((err)=>{
      this.toastr.error(err);
    })
  }
  //获取支付渠道
  getPayChannel(){
    let that = this;
    this.dataApi.getPayChannel().then((res:any)=>{
      if(res){
        console.log(res);
        this.PayChannel = res;
        this.PayChannel = res.map((item)=>{
          item.selete = false;
          if(item.alias == this.config.alipay_key)
          {
            that.channel = item.alias;
            item.selete= true;
          }
          return item;
        })

        console.log('获取支付渠道',  this.PayChannel);
      }
    }).catch((err)=>{
      console.log(err);
      this.toastr.error("获取支付渠道出错，请稍后重试");
    })
  }
  //更换支付方式
  payChannelClick(n){
    console.log('更换支付方式',n,this.isUrmWeChatPay);
    this.CheckPayCount = 0
    this.isShowRush = false
    let that = this;
    let model = {channel:n.alias}//alipay_pc_direct}
    this.PayChannel = this.PayChannel.map((item)=>{

      if(item.alias==n.alias)
      {
        item.selete=true;
      }
      else
      {
        item.selete = false;
      }

      return item;
    })
    // this.opera.loading()
    if(n.alias == "wx_pub_qr" && this.isUrmWeChatPay)
    {
      this.payCode= that.payHttpUrl
      that.payUrl =null;
      if(this.isUrmWeChatPay) {
        this.payCode = that.payHttpUrl+ this.pay_orderid
        setTimeout(()=>{
          console.log('微信支付',that.payHttpUrl+ this.pay_orderid)
          var qrcode = new QRCode('qrcode', {
            text:that.payHttpUrl+ this.pay_orderid,
            width: 130,
            height: 130,
            typeNumber:-1,
            colorDark : '#000000',
            colorLight : '#ffffff',
          });
        },300)
      }

      this.opera._hmtPush("校招工具购买","订单弹窗","微信支付");
      return
    }


    this.dataApi.paymentOrderPayGetCharge(this.pay_orderid,model).then((res:any)=>{
      // this.opera.loadiss()

      if(res)
      {
        this.Charge = res;
        if(n.alias == "wx_pub_qr")
        {
          this.payCode=res.credential.wx_pub_qr;
          that.payUrl =null;
          if(this.isUrmWeChatPay) {
            this.payCode = that.payHttpUrl+ this.pay_orderid
            setTimeout(()=>{
              console.log('微信支付',that.payHttpUrl+ this.pay_orderid)
              var qrcode = new QRCode('qrcode', {
                text:that.payHttpUrl+ this.pay_orderid,
                width: 130,
                height: 130,
                typeNumber:-1,
                colorDark : '#000000',
                colorLight : '#ffffff',
              });
            },300)
          }
          this.opera._hmtPush("校招工具购买","订单弹窗","微信支付");
        }
        else if (n.alias == this.config.alipay_key)
        {
          this.opera._hmtPush("校招工具购买","订单弹窗","支付宝支付");
          this.payCode = "";
          pingpp.setUrlReturnCallback(function (err, url) {
            // 自行处理跳转或者另外打开支付页面地址(url)
            that.payUrl = that.sanitizer.bypassSecurityTrustResourceUrl(url);
            console.log('支付宝测试支付请点击：',url);
          }, ['alipay_pc_direct', 'alipay_wap']);
           pingpp.createPayment(res, function (result, error) {
          });
        }
        this.startTime();

      }
    }).catch((err)=>{
      console.log(err);
      this.opera.loadiss()
      if(err)
      {
        this.toastr.error(err)
      }
      else
      {
        this.toastr.error('更换支付方式错误')
      }
      this.PayChannel = this.PayChannel.map((item)=>{
        if(item.alias==n.alias)
        {
          item.selete= false;
        }
        else
        {
          item.selete = true;
        }
        return item;
      })
      console.log('更换支付方式错误',this.PayChannel);
    })
  }
  //停止支付
  closeOrder(){
    this.opera._hmtPush("校招工具购买","订单弹窗","关闭");
    console.log('isOrderPage',this.isOrderPage)
    let that = this;
    if(this.isOrderPage)
    {
      if(this.config.payTimer)
      {
        clearInterval( this.config.payTimer);
      }
      this.callback(false);
      return;
    }
    setTimeout(()=>{
      that.isShowClose = true;
    },300)
  }

  closeModelClick(index){
    if(index==1)
    {
      this.isShowClose = false;
      this.opera._hmtPush("校招工具购买","放弃支付弹窗","继续付款");

    }
    else
    {
      this.opera._hmtPush("校招工具购买","放弃支付弹窗","放弃付款");

      if( this.config.payTimer)
      {
        clearInterval( this.config.payTimer);
      }
      this.callback(false);
    }
  }
  //啦豆购买
  laPay(){
    console.log('httpData',this.httpData)
    if(this.isHTTPLoading)
    {
      this.toastr.warning('网络请求中')
      return;
    }
    this.dataApi.paymentV2CreatorderV2(this.pay_orderid).then((res:any) => {
      this.opera.loadiss();
      console.log('online_packages', res,);
      this.callback('购买成功');
      this.isHTTPLoading = false;
      this.config.isOpenExperienceMember = false
      this.config.isMember_trial = false
      this.updateDepartment()
    }).catch((err) => {
      console.log(err);
      this.isHTTPLoading = false;
      this.opera.loadiss();
      this.toastr.error(err)
    })
  }
  //增值协议
  gotoXieyi(){
    // this.callback(false)
    // this.router.navigateByUrl('/home/zengzhi');
    window.open("http://images.goworkla.cn/emailpage/payProtocol/index.html",'_blank');
  }
  startTime(){
        this.second = 60;
        if(this.config.payTimer)
        {
          clearInterval( this.config.payTimer);
        }
        this.config.payTimer = setInterval(()=>{

          this.CheckPayCount+=1
          if(this.CheckPayCount>this.CheckPayMAXCount){
            this.CheckPayCount+=1
            if(this.CheckPayCount>this.CheckPayMAXCount){
              this.isShowRush = true;
              clearInterval(this.config.payTimer)
              return
            }
            return
          }
          this.dataApi.getPayStatusNew(this.pay_orderid).then((res:any)=>{
            if(res.value){
              clearInterval( this.config.payTimer);
              this.config.isOpenExperienceMember = false
              this.config.isMember_trial = false

              if(this.callback)
              {
                this.formarSuccess();
                this.callback('支付成功')
                return;
              }
            }
          }).catch((err)=>{
          })
        },1000)
  }

  formarSuccess(){
    let str = "";
    if(this.data.buyType=="buyProduct_labi")
    {
      // 啦豆兑换产品
      this.opera._hmtPush("支付成功","啦豆兑换产品成功","");
    }
    else  if(this.data.buyType=="labi")
    {
      // 购买啦豆
      this.opera._hmtPush("支付成功","购买啦豆支付成功",this.channel == "wx_pub_qr"?"微信支付":"支付宝支付");
    }
    else
    {
      //二维码支付购买产品
      this.opera._hmtPush("支付成功","二维码支付成功",this.channel == "wx_pub_qr"?"微信支付":"支付宝支付");
    }
    this.updateDepartment()
    this.config.isOpenExperienceMember = false
    this.config.isMember_trial = false

  }
  updateDepartment(){
    setTimeout(()=>{
      this.dataApi.getDepartment().then((dep) => {//通过tooken获取企业信息
        if(dep)
        {
          console.log('支付成功后更新department')
          this.dataApi.setLocalDepartment(dep);
          this.config.department = dep;
          if(dep.member_info) {
            if(dep.member_info.product_code == "member_trial") {
              // this.config.isMember_trial = true
            }
            else {
              this.config.isOpenExperienceMember = false
            }
          }
        }
      }).catch(() => {
      })
    },2000)

  }
  refresh(){

    this.CheckPayCount = 0
    this.isShowRush = false
    this.startTime()
  }
  showinfo(){
    let talentChC = document.getElementById('fapiao_info');
    talentChC.style.display = "block";
  }
  dimissinfo(){
    let talentChC = document.getElementById('fapiao_info');
    talentChC.style.display = "none";
  }

}


