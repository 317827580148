import { Component, OnInit } from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';

declare var window;

@Component({
  selector: 'app-account-logout',
  templateUrl: './account-logout.component.html',
  styleUrls: ['./account-logout.component.css']
})
export class AccountLogoutComponent implements OnInit {
  public config: Config;

  public number = 60;
  public timer;
  public isSendSms = false;
  public sendBtnTitle = "获取验证码";
  public isSend = false;

  public isLogin = true;
  public tab = '1';
  public model = {
    phone:'',
    sms:"",
    department_Name:"",
    xinyong:"",
    zhizhao:"",
    shuoming:"",
  }
  public emailModel = {
    email:'',
    vcode_text:'',
  }
  public mobile_actived = true;
  public email_actived = true;
  //是否注销的弹窗
  public  isZhuxiaoAlert = false;
  //是否是付费用户弹窗
  public  isBuyUserAlert = false;
  public  department:any;
  //是否通过高校云认证，没认证不能注销企业的其他账号
  public  is_certified = false;

  public zhuxiaoList = [];
  //当前注销的对象 记录一下变量，弹窗确定的时候用
  public currentItem:any;

  public guwen:any;

  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public dialog: MatDialog
  ) {
    this.config = Config.getInstance();//实例化config(必须)

    this.dataApi.getDepartment().then((res:any)=>{
      this.department = res;
      //是否通过高校云认证
      this.is_certified = this.department.is_certified;
      this.model.department_Name = this.department.name;
      this.dataApi.getDepartmenyDetailWithNumber(this.department.numberid).then((res:any)=>{
        this.model.xinyong =res.register_number;
      }).catch((err)=>{
        console.log(err);
      })
      this.dataApi.setLocalDepartment(res);
    }).catch((error) => {
      console.log(error);
    });
    this.getCollegeStatus();
  }

  //获取高校认证状态
  getCollegeStatus() {
    this.dataApi.getPlatformApply().then((res:any) => {
      console.log("获取企业平台认证信息",res);
      if (res) {
        this.config.lastIdentity=res;
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  ngOnInit() {
    this.opera.initConfig('company','company-account');
    this.getUser();
    window.scrollTo(0,0);
    this.getGuWen();
  }
  tabClick(type){
    //清空账号列表
    this.zhuxiaoList = [];
    //清空短信数据
    this.model.sms='';
    this.tab = type;
    if(this.tab=='2') {
      this.logoutOther();
    }
  }

  // 发送手机验证码
  sendSmsClick(){
    if(this.model.phone.length==0) {
      this.toastr.error('请输入手机号')
      return;
    }
    if(this.number<60) {
      this.toastr.error('60秒内不能重复发送')
      return;
    }
    let data  = {
      value:this.model.phone,
    }
    if(this.isSend) {
      this.toastr.error('正在发送中')
      return;
    }
    this.isSend = true;
    this.dataApi.postMobileSmsVcodeApp(data).then((res:any)=>{
      this.toastr.success('发送成功')
      this.number = 59;
      this.isSend = false;
      this.startTime();
    }).catch((err)=>{
      console.log(err);
      this.isSend = false;
      this.toastr.error(err.replace("请求参数错误:",""))
    })
  }

  
  //cell上的注销按钮，执行弹窗提示
  zhuxiaoClick(n){
    if(this.tab=='2') {
      if(this.model.zhizhao=='') {
        this.toastr.warning('请上传营业执照')
        return;
      }
      if(this.model.shuoming=='') {
        this.toastr.warning('请上传账号注销说明')
        return;
      }
    }
    this.currentItem = n;
    this.isZhuxiaoAlert = true;
  }

  //点击提交。获取账号列表
  commit() {
    if (this.tab == '1') {
      this.logoutCurrent();
    } else {
      this.logoutOther();
    }
  }

  //注销的弹窗
  commit_zhuxiao(){
    this.isZhuxiaoAlert = false;
    //注销当前账号的请求执行
    if(this.tab=='1') {
      let data = {
        mobile:this.model.phone,
        vcode_text:this.model.sms,
      }
      this.dataApi.accountOutCurrent(data).then((res:any)=>{
        console.log('注销',res,data);
        this.toastr.success('注销成功')
        this.dataApi.logout();
        this.router.navigateByUrl("login");

      }).catch((err)=>{
        if(err == "会员账号"){
          this.isBuyUserAlert = true;
        } else {
          this.toastr.error(err)
        }
      })
    }else {
      let data = {
        accountid:this.currentItem.accountid,
        register_number:this.model.xinyong,
        remark: this.model.shuoming,
        business_licence: this.model.zhizhao
      };
      this.dataApi.accountOutOther(data).then((res:any)=>{
        console.log('注销其他用户',res,data);
        this.toastr.success('已申请注销，请等待审核')
      }).catch((err)=>{
        if(err == "会员账号"){
          this.isBuyUserAlert = true;
        } else {
          this.toastr.error(err)
        }
      })
    }
  }
  //取消注销弹窗
  cancel_zhuxiao(){
    this.isZhuxiaoAlert = false;
  }

  //手机号注销当前的账号请求
  logoutCurrent(){
    if(this.model.phone.length==0||this.model.sms.length==0) {
      this.toastr.error('账号或验证码不能为空')
      return;
    }
    let data = {
      mobile:this.model.phone,
      vcode_text:this.model.sms,
    }
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '确定注销吗？',
        trueText:'确定',
        falseText:'取消'
      }
    }).afterClosed().subscribe(async (isConfirmed) => {
      if(isConfirmed){
        this.dataApi.accountOutCurrent(data).then((res:any)=>{
          if(res.value){
            this.toastr.success('注销成功');
            this.setRoot();
          }
        }).catch((err)=>{
          this.toastr.error(err)
        })
      }
    });
    

  }
  //注销其他的账号请求
  logoutOther(){
    //getAccountOutOtherList
    this.dataApi.getAccountOutOtherList(this.model.xinyong).then((res:any)=>{
      if(res &&this.tab=='2') {
        this.zhuxiaoList =res;
        if(res.length==0) {
          this.toastr.warning('该公司无其他账号')
        }
      }
    }).catch((err)=>{
      console.log('getAccountOutOtherList',err);
      this.toastr.error(err)
    })

  }
  // 获取图片
  getPicData(event,name){
    let imgData  = event.target.files[0]; //获取file对象
    let imageName =  event.target.files[0].name;
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.uploadAuthImg(reader.result,name,imageName);
      };
    }
  }
  // 上传图片
  uploadAuthImg(data,name,imageName){
    let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
    this.dataApi.uploadAuthImg({
      value: imageData
    }).then((res:any)=>{
      //文件扩展名的正则表达式
      var patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
      //获得文件扩展名
      var fileExtension = (imageName).match(patternFileExtension);
      // loader.dismiss();
      if(name=="zhizhao"){
        this.model.zhizhao=res.value;
      }else if(name == "shuoming"){
        this.model.shuoming=res.value;
      }
    }).catch((error)=>{
      // loader.dismiss();
      this.toastr.error('上传图片错误');
    })
  }
  startTime() {
    let that = this;
    that.timer =  setInterval(()=>{
      that.number=that.number-1;
      this.sendBtnTitle = that.number+"s"
      if(that.number<=0) {
        clearInterval(that.timer)
        that.number = 60;
        that.sendBtnTitle = '获取验证码'
      }
    },1000)
  }
  gotoHistory(){
    this.router.navigateByUrl("/home/accountLogoutHistory");
  }
  //获取用户信息
  getUser(){
    this.dataApi.getUser().then((res:any)=>{
      console.log("线上数据hr信息,",res);
      if(res){
        this.model.phone = res.mobile;
        this.emailModel.email = res.login_email;
        this.mobile_actived = res.mobile_actived;
        this.email_actived = res.email_actived;
        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
      }
    }).catch((error)=>{
    });
  }
  //获取校招顾问指定人=>黄春燕
 getGuWen(){
   this.dataApi.getGuWebByName('黄春燕').then((res:any)=>{
     console.log("获取校招顾问,",res);
     if(res){
       this.guwen = res;
     }
   }).catch((error)=>{
   });
 }
 //去高校云认证页面
  gotoAuthentication(state){
    this.router.navigateByUrl("guide-audit");
  }

  // 发送邮箱验证码 phoneVerification
  async sendEmailClick(){
    if(!this.email_actived){
      this.toastr.error('请激活邮箱');
      return;
    }
    if(this.number<60) {
      this.toastr.error('60秒内不能重复发送')
      return;
    }
    let data  = {
      email:this.emailModel.email,
    }
    if(this.isSend) {
      this.toastr.error('正在发送中')
      return;
    }
    this.isSend = true;
    this.dataApi.sendEmail(data).then((res:any)=>{
      this.toastr.success('发送成功')
      this.number = 59;
      this.isSend = false;
      this.startTime();
    }).catch((err)=>{
      console.log(err);
      this.isSend = false;
      this.toastr.error(err.replace("请求参数错误:",""))
    })
  }

  // 邮箱注销
  commitEml(){
    if(!this.emailModel.vcode_text){
      this.toastr.error('请输入邮箱验证码');
      return;
    }
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '确定注销吗？',
        trueText:'确定',
        falseText:'取消'
      }
    }).afterClosed().subscribe(async (isConfirmed) => {
      if(isConfirmed){
        this.dataApi.accountOutEmail(this.emailModel).then((res:any)=>{
          if(res.value){
            this.toastr.success('注销成功');
            this.setRoot();
          }
        }).catch((err)=>{
          console.log(err);
        })
      }
    });
  }

  setRoot(){
    this.dataApi.logout();
    this.router.navigateByUrl("login");
  }

}
