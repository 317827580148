<!-- 页头部分 -->
<!-- <div class="contentRight-header">职位管理</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <div class="positionMain">
        <div class="header">

            <!-- tab -->
            <div class="tab">
                <div class="item fn" [class.active]="model.status=='release'" (click)="chooseStatus('release')">
                    在招职位
                    <span></span>
                </div>
                <div class="item" [class.active]="model.status=='stop'" (click)="chooseStatus('stop')">
                    已下线职位
                    <span></span>
                </div>
                <div class="item" [class.active]="model.status=='delete'" (click)="chooseStatus('delete')">
                    已删除职位
                    <span></span>
                </div>
            </div>
            <!-- 非会员Tip -->
            <div class="noVip" *ngIf="!isShowMemberMoudel">
                <div class="text">非会员发布的职位展示效果有限，加入会员，让更多人才看到您的职位。</div>
                <div class="joinBtn" (click)="product('','contactMe')">加入会员</div>
            </div>
            <!-- 查询 -->
            <div class="query">
                <div class="left">
                    <div class="push" (click)="goPublish()"><img src="/assets/images/rights/authentication02.png">发布职位</div>
                    <div class="input">
                        <img src="/assets/images/rights/search.png">
                        <input type="text" placeholder="请输入职位名称" [(ngModel)]="model.name" (keyup)="inputKeyup($event)">
                    </div>
                    <div class="search blueStreamer" (click)="getPositionList()">搜索</div>
                </div>
                <div class="right">
                    <div class="item active" (click)="rushAllPosition()">
                        <span>委托自动刷新</span>
                        <img src="/assets/images/v3/batchRefresh.png">
                    </div>
                    <div class="item" (click)="openrushAllPositions()">
                        <span>一键刷新职位</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="positionMain-list">
            <!-- 职位列表 -->
            <div class="list" *ngIf="countList>0">
                <div class="item" *ngFor="let n of positionList;let i = index;">
                    <div class="center">
                        <div class="introduction">
                            <div class="_lf">
                                <div class="name" (click)="goDeatils(n.positionid)">
                                    {{n.name}}
                                    <img class="inline-block-img" title="招聘热度极高" *ngIf="n.sign=='3'" src="/assets/images/rights/hot.png">
                                    <img class="inline-block-img" *ngIf="n.sign=='2'" title="招聘热度较高" src="/assets/images/rights/hot02.png">
                                    <img class="inline-block-img" *ngIf="n.sign=='1'" title="招聘热度极高" src="/assets/images/rights/hot02.png">
                                    <span class="refreshState blueStreamer" *ngIf="n.refresh_timing_state == 2" (click)="showTiming(n,$event)">待刷新</span>
                                    <span class="refreshState blueStreamer" *ngIf="n.refresh_timing_state == 1" (click)="showTiming(n,$event)">刷新中</span>
                                    <!-- <span class="edit" *ngIf="model.status=='release'" (click)="goDeatils(n.positionid)"><img src="/assets/images/v3/address-edit.png">编辑</span> -->
                                </div>
                                <div class="cell">{{n.work_place_text}} | {{n.post_nature_text}} | {{n.position_salary_text}} | {{n.education_text}} | {{n.intent_count}}人</div>
                                <div class="look"><img class="inline-block-img" src="/assets/images/rights/look.png">浏览量 {{n.browsecount ? n.browsecount : '0'}}</div>
                            </div>
                            <div class="_rt">
                                <div class="salary">
                                    <div class="div1 active" *ngIf="n.top_state == 2 && model.status=='release'" (click)="openTime(n)">「待置顶」</div>
                                    <!-- <div class="div1 active" *ngIf="n.top_state == 2 && model.status=='release'" (click)="topping(n,'ismodify')"><img src="/assets/images/rights/modifyTop.png ">「待置顶」</div> -->
                                    <div class="div1" *ngIf="n.top_state == 1 && model.status=='release'" (click)="openTime(n)"><img src="/assets/images/rights/topIcon.png ">「置顶中」</div>
                                    <div class="div2">{{n.position_salary_text}}</div>
                                </div>
                                <div class="refreshTime" *ngIf="n.refreshTime&&!n.isEffect"><img class="inline-block-img" src="/assets/images/rights/time.png">刷新时间 {{n.refreshTime | date:'yyyy/MM/dd HH:mm:ss'}}</div>
                            </div>
                        </div>
                        <!-- 到期提示、专业要求 -->
                        <div class="tipBox">
                            <div class="expire" *ngIf="n.isEffect">
                                <img class="inline-block-img" src="/assets/images/rights/expireTip.png">{{n.EffectTime}}
                            </div>
                            <div class="major" *ngIf="n.majors.length==0">
                                <img class="inline-block-img" src="/assets/images/rights/expireTip.png"> 您还未设置专业要求，设置后匹配学生更精准
                            </div>
                        </div>
                        <div class="operation">
                            <div class="Deliver" (click)="cellLikeClick(n)">
                                <div class="lfIcon"></div>
                                投递量 <span>{{n.deliverycount ? n.deliverycount : '0'}}</span>
                                <div class="rtIcon"></div>
                            </div>
                            <div class="rightBtn">
                                <div class="btn" *ngIf="model.status=='release'&& !n.is_top" (click)="topping(n)"><img class="inline-block-img" src="/assets/images/rights/icon04.png">置顶</div>
                                <div class="btn" *ngIf="model.status=='release'" (click)="goPopup(n)"><img class="inline-block-img" src="/assets/images/rights/icon02.png">刷新</div>
                                <div class="btn" *ngIf="model.status=='release'" (click)="poster(n)"><img class="inline-block-img" src="/assets/images/v3/posterIcon.png">分享</div>
                                <div class="btn" *ngIf="model.status=='release'" (click)="goExpired(n)"><img class="inline-block-img" src="/assets/images/rights/icon01.png">下线</div>

                                <div class="btn active" *ngIf="model.status=='stop'" (click)="goResend(n)"><img class="inline-block-img" src="/assets/images/rights/repeatUp.png">重新发布</div>
                                <div class="btn" *ngIf="model.status=='stop'" (click)="goDeatils(n.positionid)"><img class="inline-block-img" src="/assets/images/rights/icon03.png">编辑</div>
                                <div class="btn" *ngIf="model.status=='stop'" (click)="goDelete(n)"><img class="inline-block-img" src="/assets/images/rights/icon01.png">删除</div>
                            </div>
                        </div>
                        <div class="lin"></div>
                        <div class="positionBottom">
                            <div class="positionList-bottom-item">
                                <div class="val">{{n.value_department}}<span>家</span></div>
                                <div class="lable">发布此类职位的用人单位</div>
                            </div>
                            <div class="positionList-bottom-item">
                                <div class="val">{{n.value_talent}}<span>人</span></div>
                                <div class="lable">有此类求职意向的毕业生</div>
                            </div>
                            <div class="positionList-bottom-item">
                                <div class="val">1:{{n.value_ratio}}</div>
                                <div class="lable">人才供需比</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 没有内容存在时 -->
        <div *ngIf="countList==0" class="none-block none-position">
            <img src="/assets/images/rights/empty.png" (click)="goPublish()"><br />
            <p (click)="goPublish()">发布职位</p>
            <p class="p2">发布职位，全平台百万学生可见</p>
        </div>

        <!-- 分页部分 -->
        <div class='paging-block' [class.isPageHeight]="countList>0">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>

    </div>
</div>

<!-- 单个职位刷新 -->
<p-dialog id="bigPackage" [contentStyle]="{'padding':'0','border-radius':'10px'}" [dismissableMask]="true" [modal]="true" [showHeader]="false" [(visible)]="isShowModel">
    <div class="big-package">
        <div class="rightBg"><img src="/assets/images/v3/rightBg.png"></div>
        <div class="title">
            <div class="icon"><img src="/assets/images/v3/RefreshIcon.png"></div>
            <div class="tipText">
                <div class="name">职位刷新</div>
                <div class="text">瞬间提升职位曝光</div>
            </div>
        </div>
        <div class="p1" *ngIf="positionObj && positionObj.name">确认刷新职位<span>{{positionObj.name}}</span></div>
        <div class="p2">需消耗资源数<span>1</span>，(当前剩余资源<span>{{position_refresh ? position_refresh.left : '0'}}</span>)</div>
        <div (click)="goRefresh()" class="fre-btn blueStreamer">立即刷新</div>
    </div>
</p-dialog>

<!-- 一键职位刷新 -->
<p-dialog [contentStyle]="{'padding':'0','border-radius':'10px'}" [dismissableMask]="true" [modal]="true" [showHeader]="false" [(visible)]="rushAllPositionsShow">
    <div class="big-package">
        <div class="rightBg"><img src="/assets/images/v3/rightBg.png"></div>
        <div class="closIcon" (click)="rushAllPositionsShow = false"><img src="/assets/images/v3/alertClos.png"></div>
        <div class="title">
            <div class="icon"><img src="/assets/images/v3/RefreshIcon.png"></div>
            <div class="tipText">
                <div class="name">一键职位刷新</div>
                <div class="text">同时刷新您的所有在招职位，瞬间提升职位曝光量</div>
            </div>
        </div>
        <div class="rushAllIcon"><img src="/assets/images/v3/rushAllIcon.png"></div>
        <div class="p2">需消耗资源数<span>{{allPositionCount}}</span>，(当前剩余资源<span>{{position_refresh ? position_refresh.left : '0'}}</span>)</div>
        <div (click)="rushAllPositions()" class="fre-btn blueStreamer">立即刷新</div>
        <div class="p3" (click)="product('position_top','contactMe')">购买资源</div>
    </div>
</p-dialog>


<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isShowPublishModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':582,'height':288,'minHeight':288}"
    [modal]=true>
    <div class="alertPhone_Bg">.
        <div class="title"><img src="../../assets/images/position/suc_icon.png" />发布成功</div>
        <div class="tip">提升曝光,及时影响更多活跃人才</div>
        <div (click)="publishSuc()" class="btn">立即职位刷新</div>
    </div>
</p-dialog>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isShowPublishVip" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':429,'height':467,'minHeight':467}"
    [modal]=true>
    <div class="modal-content" style="  margin: 210px auto 0;
  width: 429px;
  height:467px;
  background: url(../../assets/images/vip/no_vip_alert.png) 100% 100% no-repeat;">
        <button [mat-dialog-close] style="border: none">
            <img style="position: absolute;top: 0px;right: 0px;width: 20px;height: 20px"
                src="../../assets/images/closeX.png" />
        </button>
        <div style="text-align: center;margin-top: 86px;font-size: 23px;color: #C9B189">成为基础会员<br>即刻查看全网所有高校毕业生简历</div>
        <button style="width: 148px;height: 39px;position: absolute;left: 50%;margin-left:-74px;top: 213px;border: none" type="button" class="btn btn-default" (click)="goRouter('tool-campus')" [mat-dialog-close]></button>
        <button [mat-dialog-close] style="border: none">
            <div (click)="goRouter('tool-campus')"
                style="position: absolute;left: 50%;margin-left: -56px;bottom: 179px;width: 112px;height: 19px"></div>
        </button>
    </div>
</p-dialog>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>

<!-- 选择职位 -->
<p-dialog [showHeader]="false" [dismissableMask]="false" [contentStyle]="{'padding':'0','border-radius':'6px'}" [(visible)]="isPosotionListModel" [modal]=true>
    <div class="isPosotionListModel">
        <div class="ul">
            <div class="li" *ngFor="let item of dropList;let i = index;" [class.active]='item.active' (click)="liClick(item)">{{item.name}}</div>
        </div>
        <div class="saveBtn blueStreamer" (click)="okSave()">确定</div>
    </div>
</p-dialog>

<!-- 委托刷新弹窗 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="isShowTiming" (onHide)="hidenTimeing()" [modal]=true>
    <div class="timingBox">
        <div class="rightBg"><img src="/assets/images/v3/rightBg.png"></div>
        <div class="closIcon" (click)="isShowTiming = false"><img src="/assets/images/v3/alertClos.png"></div>
        <div class="Main" *ngIf="!isShowSuces">
            <div class="title">
                <div class="icon"><img src="/assets/images/v3/RefreshIcon.png"></div>
                <div class="tipText">
                    <div class="name">委托自动刷新</div>
                    <div class="text">职位将按照您的设定自动进行刷新，职位刷新一旦设置完成， 不支持修改、取消。</div>
                </div>
            </div>
            <div class="module">
                <div class="lable">刷新日期：</div>
                <div class="val" id="textClear">
                    <input type="text" id="test60" placeholder="请选择起止日期时间">
                </div>
            </div>
            <div class="module">
                <div class="lable">每日首次刷新时间：</div>
                <div class="val">
                    <input type="text" id="test4" style="width:100%" placeholder="选择时间">
                </div>
            </div>
            <div class="module">
                <div class="lable">每日刷新次数：</div>
                <div class="val">
                    <select class="filtrate_row_select_gray " [(ngModel)]="upData.daily_refresh_count" (ngModelChange)="refreshCont()" autocomplete="off">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                    </select>
                </div>
            </div>
            <div class="p2p2">*刷新时间每次间隔2小时</div>
            <!-- <div class="module">
                <div class="lable">刷新职位：</div>
                <div class="val" (click)="drop_down()">
                    <div class="lab" *ngFor="let item of dropList;let i = index;">
                        <div class="box" *ngIf="item.active">{{item.name}}<span (click)="delClick(item,$event)">×</span></div>
                    </div>
                </div>
            </div> -->
            <div class="positionListModule">
                <div class="top">
                    <div class="name">选择职位</div>
                    <div class="orBtn" (click)="orBtn(item)">
                        <div class="choice" [class.active]='orShow'><span *ngIf="orShow"></span></div>
                        全选
                    </div>
                </div>
                <div class="list">
                    <div class="item" *ngFor="let item of dropList;let i = index;" (click)="choiceClick(item)">
                        <div class="choiceIcon" [class.active]='item.active'><span *ngIf="item.active"></span></div>
                        <div class="positionName" [class.active]='item.active'>{{item.name}}</div>
                    </div>
                </div>
            </div>
            <div class="module">
                <div class="lable"></div>
                <div class="val fn">
                    <div class="tip1">需消耗刷新次数<span>{{refreshContspot}}</span>次</div>
                    <div class="tip1" *ngIf='isShowimmediately'>刷新次数余额不足，<span class="recharge" (click)="product('position_refresh','recharge')">请联系校招顾问充值</span></div>
                </div>
            </div>
            <div class="saveBtn blueStreamer" (click)="immediately()" *ngIf='!isShowimmediately'>立即刷新</div>
            <div class="p3" (click)="product('position_refresh','contactMe')">购买资源</div>
        </div>
        <div class="isShowSuces" *ngIf="isShowSuces">
            <div class="titles">
                <img src="/assets/images/rights/refreshSuccess.png">
                <span>使用成功</span>
            </div>
            <div class="product">
                <img src="/assets/images/rights/item02.png">
                <span>职位刷新</span>
            </div>
            <div class="module">
                <div class="left">刷新职位：</div>
                <div class="val">
                    <div class="lab" *ngFor="let item of dropList;let i = index;">
                        <div class="box" *ngIf="item.active">{{item.name}}</div>
                    </div>
                </div>
            </div>
            <div class="module">
                <div class="left">刷新日期：</div>
                <div class="val">
                    <div class="lab" *ngFor="let item of daysList;let i = index;">
                        <div class="box">{{item}}</div>
                    </div>
                </div>
            </div>
            <div class="module">
                <div class="left">刷新开始时间：</div>
                <div class="val">{{upData.time}}</div>
            </div>
            <div class="module">
                <div class="left">每日刷新次数：</div>
                <div class="val">{{upData.daily_refresh_count}}</div>
            </div>
            <div class="contBox">
                <div class="item">
                    本次使用刷新次数<span>{{refreshContspot}}次</span>
                </div>
                <div class="item">
                    剩余刷新次数<span>{{position_refreshCont}}次</span>
                </div>
            </div>
            <div class="saveBtn blueStreamer" (click)="closeisShowTiming()">确定</div>
        </div>
    </div>
</p-dialog>

<!-- 委托刷新二次确认弹窗 -->
<p-dialog [contentStyle]="{'padding':'0','border-radius':'10px'}" [dismissableMask]="true" [modal]="true" [showHeader]="false" [(visible)]="confirm_entrust_tip">
    <div class="big-package fn">
        <div class="rightBg"><img src="/assets/images/v3/rightBg.png"></div>
        <div class="closIcon" (click)="confirm_entrust_tip = false"><img src="/assets/images/v3/alertClos.png"></div>
        <div class="title">
            <div class="icon"><img src="/assets/images/v3/RefreshIcon.png"></div>
            <div class="tipText">
                <div class="name">委托自动刷新</div>
                <div class="text">您确定在 {{upData.begin_date}}至{{upData.end_date}}，每天{{upData.time}}点刷新职位，每日刷新<span>{{upData.daily_refresh_count}}</span>次吗？</div>
            </div>
        </div>
        <div class="btns">
            <div class="btn blueStreamer" (click)="entrustSave()">确定</div>
            <div class="btn active" (click)="confirm_entrust_tip = false">取消</div>
        </div>
    </div>
</p-dialog>

<!-- 刷新纪录 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="showtimingBox" [modal]=true>
    <div class="showtimingBox showTopingBox">
        <div class="closIcon" (click)="clostopping()"><img src="/assets/images/v3/alertClos.png"></div>
        <div class="title">
            <div class="icon"><img src="/assets/images/v3/RefreshIcon.png"></div>
            <div class="tipText">
                <div class="name">职位自动刷新中</div>
                <div class="text">职位将按照您的设定自动进行刷新</div>
            </div>
        </div>
        <div class="cell" *ngIf="dateList_refed.length">
            <div class="lable">已刷新</div>
            <div class="times">
                <div class="item active" *ngFor="let itt of dateList_refed;let i = index;">
                    <img src="/assets/images/v3/authentication02.png"> {{itt.date}}({{itt.count}})
                </div>
            </div>
        </div>
        <div class="cell" *ngIf="dateList_noref.length">
            <div class="lable">待刷新排期</div>
            <div class="times">
                <div class="item" *ngFor="let itt of dateList_noref;let i = index;">
                    <img src="/assets/images/rights/time01.png"> {{itt.date}}({{itt.count}})
                </div>
            </div>
        </div>
        <div class="fre-btn blueStreamer" (click)="clostopping()">确定</div>
    </div>
</p-dialog>

<!-- 职位置顶 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="showToping" [modal]=true>
    <div class="showTopingBox">
        <div class="closIcon" (click)="clostopping()"><img src="/assets/images/v3/alertClos.png"></div>
        <div class="title">
            <div class="icon"><img src="/assets/images/v3/topingIcon.png"></div>
            <div class="tipText">
                <div class="name">职位置顶</div>
                <div class="text">高强度曝光，学生第一个就看到</div>
            </div>
        </div>
        <div class="cell">
            <div class="lable">确认置顶职位</div>
            <div class="val active">{{topingData.name}}</div>
        </div>
        <div class="cell">
            <div class="lable">置顶日期</div>
            <div class="val" *ngIf="showToping">
                <div class="item"><input type="text" id="test61" [(ngModel)]="topingData.start_time" placeholder="置顶开始日期"></div>
                <div class="item"><input type="text" id="test62" disabled="{{!topingData.start_time}}" [(ngModel)]="topingData.end_time" placeholder="置顶结束日期"></div>
            </div>
        </div>
        <div class="p2" *ngIf="!ismodify">需消耗资源数<span>{{TopNumber}}</span>，(当前剩余资源<span>{{topingbalance}}</span>)</div>
        <div class="fre-btn blueStreamer" [class.actives]='ismodify' (click)="toppingClick()" *ngIf="topingbalance>=TopNumber">{{ismodify?'修改置顶':'置顶'}}</div>
        <div class="fre-btn grey" *ngIf="topingbalance<TopNumber">置顶</div>
        <div class="p3" (click)="product('position_top','contactMe')">购买资源</div>
    </div>
</p-dialog>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="isShowTimes" [modal]=true>
    <div class="showTopingBox">
        <div class="closIcon" (click)="clostopping()"><img src="/assets/images/v3/alertClos.png"></div>
        <div class="title">
            <div class="icon"><img src="/assets/images/v3/topingIcon.png"></div>
            <div class="tipText">
                <div class="name">职位自动置顶中</div>
                <div class="text">职位将按照您的设定自动进行置顶</div>
            </div>
        </div>
        <div class="cell">
            <div class="lable">已置顶</div>
            <div class="times" *ngIf="timesData.dateList_topped">
                <div class="item active" *ngFor="let itt of timesData.dateList_topped;let i = index;">
                    <img src="/assets/images/v3/authentication02.png"> {{itt}}
                </div>
            </div>
        </div>
        <div class="cell">
            <div class="lable">待置顶排期</div>
            <div class="times">
                <div class="item" *ngFor="let itt of timesData.dateList_notop;let i = index;">
                    <img src="/assets/images/rights/time01.png"> {{itt}}
                </div>
            </div>
        </div>
        <div class="fre-btn blueStreamer" (click)="clostopping()">确定</div>
    </div>
</p-dialog>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="showTopingTip" [modal]=true>
    <div class="showTopingBox">
        <div class="closIcon" (click)="clostopping()"><img src="/assets/images/v3/alertClos.png"></div>
        <div class="title">
            <div class="icon"><img src="/assets/images/v3/topingIcon.png"></div>
            <div class="tipText">
                <div class="name">职位置顶</div>
                <div class="text">高强度曝光，学生第一个就看到</div>
            </div>
        </div>
        <div class="p2">在{{showTopingTiptext}}已存在该职位类别的置顶，请选择其他日期进行置顶。</div>
        <div class="fre-btn blueStreamer active" (click)="clostopping()">确定</div>
    </div>
</p-dialog>

<!-- 职位下线提示 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="OfflineShow" [modal]=true>
    <div class="showTopingBox OfflineShow">
        <div class="closIcon" (click)="clostopping()"><img src="/assets/images/v3/alertClos.png"></div>
        <div class="title">
            <div class="tipText">
                <div class="name">下线职位</div>
            </div>
        </div>
        <div class="mainImg"><img src="/assets/images/v3/Offline.png"></div>
        <div class="p2">{{tipText}}</div>
        <div class="btns">
            <div class="btn blueStreamer" (click)="downPosition()">确定</div>
            <div class="btn active" (click)="OfflineShow = false">取消</div>
        </div>
    </div>
</p-dialog>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="posterShow" [modal]=true>
    <div class="showTopingBox poster">
        <div class="closIcon" (click)="closposter()"><img src="/assets/images/v3/alertClos.png"></div>
        <div class="title">
            <div class="tipText">
                <div class="name">分享职位</div>
            </div>
        </div>
        <p-carousel [value]="cars" [showIndicators]="false" (onPage)="onPageChange($event)">
            <ng-template let-car pTemplate="item">
                <div class="img">
                    <div class="item">
                        <img src="{{car.url}}">
                        <div class="bottomBox" *ngIf="posterItem && loginUser">
                            <div class="top">
                                <div class="lf">
                                    <div class="name">{{posterItem.name}}</div>
                                    <div class="text">
                                        {{posterItem.work_place_text}} | {{posterItem.post_nature_text}} | {{posterItem.position_salary_text}} | {{posterItem.education_text}} | {{posterItem.intent_count}}人
                                    </div>
                                </div>
                                <div class="salary">{{posterItem.position_salary_text}}</div>
                            </div>
                            <div class="bottom">
                                <div class="lf">
                                    <div class="logo">
                                        <img src="{{loginUser.avatar}}">
                                    </div>
                                    <div class="_na">
                                        <div class="name">{{loginUser.name}}</div>
                                        <div class="text">{{loginUser.post}}</div>
                                    </div>
                                </div>
                                <div class="rt">
                                    <img src="{{posterItem.miniprogram_qrcode}}">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-carousel>

        <div class="p3" style="margin-top: 30px;" (click)="downloadPoster()">下载海报</div>
    </div>
</p-dialog>