 <!-- 页头部分 -->
<!-- <div class="contentRight-header">企业中心 - 发票</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <div class="college-block">
    <!-- 页头部分 -->
    <div class="college-block-head collegeBlockMargin flex_row_between">
      <!-- <p>发票</p> -->
      <div class="college-tab">
        <em (click)="opera.goNewRouter('company-status-two')"><img src='assets/images/router-old.png'/>使用记录</em>
      </div>
    </div>
    <!-- 操作部分 <img src="assets/images/select-img.png">-->
    <div class="college-block-opera clear">
      <div class="left">
        <span>产品名称</span>
        <button [class.purple]="model.recharge_category=='sms_activity'" (click)="chooseType('sms_activity')">活动通知包</button>
        <button [class.purple]="model.recharge_category=='sms_quick'" (click)="chooseType('sms_quick')">极速短信联系</button>
<!--        <button [class.purple]="model.recharge_category=='phone_quick'" (click)="chooseType('phone_quick')">极速电话联系</button>-->
        <span>发送日期</span>
        <input type="text" placeholder="请选择发送日期" [(ngModel)]="sendTime" id="send-time">
      </div>
      <div class="right">
      </div>
    </div>
    <div class="action">
      <button class="purple" (click)="getRecordListData()">筛选</button>
      <button class="purple" (click)="reset()">重置</button>
    </div>
    <!-- 没有内容存在时 -->
    <div *ngIf="countList==0" class="none-block">
      <img src="assets/images/resume-search-fail.png"><br/>
      暂未找到使用记录
    </div>
    <!-- 循环列表 -->
    <div class="college-list-cont" *ngIf="countList>0">
      <ul>
        <li>序号</li>
        <li>短信内容</li>
        <li>发送时间</li>
        <li>学生姓名</li>
        <li>预发人数</li>
        <li>成功人数</li>
        <li>实际扣除</li>
        <li>余量</li>
      </ul>
      <!-- *ngFor="let n of collegeList;let i = index;" -->
      <div>
        <ul class="college-li" *ngFor="let n of recordList;let i = index;">
          <li><span >{{i+1}}</span>
          <li><span *ngIf='n.sms_content'>{{n.sms_content}}</span><span *ngIf='!n.sms_content'>暂无</span></li>
          <li><span *ngIf='n.log_time'>{{n.log_time | date:'yyyy-MM-dd HH:mm:ss'}}</span><span *ngIf='!n.log_time'>暂无</span></li>
          <li><span *ngIf='n.sms_to_name'>{{n.sms_to_name}}</span><span *ngIf='!n.sms_to_name'>暂无</span></li>
          <li><span *ngIf='n.expect_count'>{{n.expect_count}}</span><span *ngIf='!n.expect_count'>暂无</span></li>
          <li><span *ngIf='n.success_count'>{{n.success_count}}</span><span *ngIf='!n.success_count'>暂无</span></li>
          <li><span *ngIf='n.consume_count'>{{n.consume_count}}</span><span *ngIf='!n.consume_count'>暂无</span></li>
          <li><span >{{n.left ? n.left : '0'}}</span></li>
        </ul>
      </div>
    </div>
    <!-- 分页部分 -->
    <div class='paging-block' [class.isPageHeight]="countList>0">
      <router-outlet></router-outlet>
      <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
    </div>
  </div>
</div>
