<div class="homepage-block">
    <router-outlet></router-outlet>
    <!-- V3.0新版本 -->
    <div class="toolList">
        <div class="secondNav">
            <div class="item active">我的工具<span></span></div>
            <div class="item" (click)="myOrder()">我的订单</div>
            <div class="item" (click)="myInvoice()">我的发票</div>
            <div class="item" (click)="myLadou()">啦豆明细</div>
        </div>
        <div class="rightBtn">
            <div class="btn" (click)="gotoPayHistory()"><img src="/assets/images/v3/myTollIcon01.png" />购买记录</div>
            <div class="btn" (click)="gotoInvoice()"><img src="/assets/images/v3/myTollIcon02.png" />开发票</div>
        </div>
        <div class="main">
            <div class="item" *ngFor="let n of productList;let i = index;" (click)='gotoDetail(n)'>
                <div class="left">
                    <div class="icon"><img src="{{n.ico}}"></div>
                    <div>
                        <div class="name">{{n.name}}</div>
                        <div class="text">{{n.intro}}</div>
                    </div>
                </div>
                <div class="right">
                    <div class="Used">累计使用：{{n.total-n.left}}</div>
                    <div class="balance">
                        <div class="number">{{n.left}}</div>
                        <div class="Company">余额（{{n.unit}}）</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 空状态 -->
        <div class="none-block-empty" *ngIf="productList.length == 0">
            <img src="assets/images/rights/empty.png">
            <p>暂无记录</p>
        </div>
    </div>

</div>

<p-dialog id="aboutLaCoin" [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':500,'height':320,'minHeight':320}" [modal]="true" [showHeader]="false"
    [(visible)]="aboutLaCoinAlert">
    <div style="width: 500px;height: 320px;background-color: white;overflow: hidden;">
        <p class="aboutLaCoin">1.啦豆是什么?</p>
        <p class="aboutLaCoinContent">啦豆是「工作啦」校园招聘平台专属的消费积分，用户需要充值获取，目前兑换比例为1RMB=1啦豆。</p>
        <p class="aboutLaCoin">2.啦豆如何使用？</p>
        <p class="aboutLaCoinContent">充值啦豆成功后，啦豆余额可以在【校招工具】直接购买校招工具和服务，也可以在具体服务提示中按照提示付费。</p>
        <p class="aboutLaCoin">3.啦豆如何开发票？</p>
        <p class="aboutLaCoinContent">充值购买啦豆、直接购买极速电话联系等所花费的金额，可以在【校招工具】-开发票中，申请开具电子发票。</p>
        <p class="aboutLaCoin">4.啦豆是否会过期？</p>
        <p class="aboutLaCoinContent">啦豆跟用户的账号绑定，长期有效，不会过期。</p>
    </div>
</p-dialog>