<div *ngIf="count > 0">
  <div class="page-block">
    <!--<ul class="page-number">-->
      <!--<li>第<b>{{activeCount}}</b>页</li>-->
    <!--</ul>-->
    <!-- 数量小于6 -->
    <!-- <ul *ngIf="6 > count">
      <li (click)="paging(-1)"><img src="assets/images/page-pre.png"></li>
      <li (click)="paging(n)" [class.isHidden]="n>count" [class.isActive]="n==activeCount" *ngFor="let n of pageList">{{n}}</li>
      <li (click)="paging(-2)"><img src="assets/images/page-next.png"></li>
    </ul> -->
    <!-- 数量大于6 -->
    <ul>
      <li (click)="paging(-1)"><img src="assets/images/page-pre.png"></li>
      <!-- <li (click)="paging(n)" *ngFor="let n of pageMoreList" [class.isActive]="n==activeCount">{{n}}</li>
      <li>...</li>
      <li (click)="paging(count)" [class.isActive]="count==activeCount">{{count}}</li> -->
      <li (click)="paging(n)" [class.isActive]="n==activeCount" *ngFor="let n of pageslist">{{n}}</li>
      <li (click)="paging(-2)"><img src="assets/images/page-next.png"></li>
    </ul>
  </div>
</div>
