<app-navigation-route #appNavigation ></app-navigation-route>


<div class="box">


  <div class="title">「工作啦」增值服务协议</div>

  <div class="content">
    欢迎您使用「工作啦」增值服务！<br/>为使用「工作啦」增值服务（简称为：本服务），您应当阅读并遵守《增值服务协议》（简称为：本协议）。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。除非您已阅读并接受本协议所有条款，否则您无权使用本服务。您购买或使用「工作啦」软件内的任何收费服务，即视为您已阅读并同意本协议的约束。
  </div>
  <div class="title2">一.协议条款的确认和接受</div>
  <div class="content">1.1 在用户决定使用相关增值服务之前，请仔细阅读本收费协议条款。用户必须在完全同意以下条款的前提下，才能进行购买程序。「工作啦」将根据以下收费协议条款为用户提供增值服务。用户在享受「工作啦」增值服务时必须完全、严格遵守本协议条款。</div>
  <div class="content">1.2 协议适用主体范围<br/>「工作啦」提供的网络服务采用收费与免费结合的方式，用户须先成为「工作啦」普通注册用户之后，才能继续进行购买服务。通过购买程序支付费用后，用户就可以轻松享受「工作啦」增值服务。在开始购买程序之前，请用户首先同意并接受以下服务条款。</div>
  <div class="content">1.3 本服务内容<br/>本协议为您与「工作啦」《用户协议及隐私说明》的补充协议，是其不可分割的组成部分，与其构成统一整体。本收费协议中未约定事项以《用户协议及隐私说明》中的约定为准。本协议与前述协议存在冲突的，以本协议为准。本协议内容同时包括「工作啦」可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，用户同样应当遵守。</div>
  <div class="content">1.4 「工作啦」根据互联网的发展和中华人民共和国有关法律、法规的变化，不断地完善服务质量并依此修改服务条款。「工作啦」有权就本协议随时更新，并在软件内或通过您留下的联系方式通知您。用户的权利以及义务的表述、购买程序及收费标准，均以最新的服务条款为准。用户如果不同意该条款的修改并不影响已发生责任及义务的承担；如果用户在条款变更后继续访问或使用「工作啦」，则视为用户已经接受对本协议的修改。</div>


  <div class="title2">二、服务说明</div>
  <div class="content">增值服务，指按照「工作啦」的指定方式支付一定服务费用之后，用户可以享有由「工作啦」或第三方提供的在「工作啦」网站上校招工具、社交方面特权等服务，简称为：本服务<br/>「工作啦」可能会根据不同的产品及服务类型，推出不同的增值服务类型，目前，「工作啦」提供极速电话联系、校招活动邀请、职位推广、职位刷新等不同种类的增值服务。同时，「工作啦」也可能会根据用户的需求、产品及服务类型的变化等，对现有增值服务种类进行调整以及不断推出新的增值服务种类。具体增值服务种类及服务内容及费用标准以相关服务页面公布、实际提供的内容为准。用户可以自行根据需要选择相应服务。<br/>用户可通过登录「工作啦」，免费查询其消费的服务的详情（包括其购买的增值服务名称、购买时间、标示价格、即时服务状态等）。</div>

  <div class="title2">三、收费标准及服务开通</div>
  <div class="content">3.1  收费标准</div>
  <div class="content">3.1.1－本服务是「工作啦」提供的增值服务，用户须在按照本服务的收费标准支付相应费用后，方可使用本服务。</div>
  <div class="content">3.1.2－收费费用标准：服务费用的具体资费政策以「工作啦」软件内届时公布的内容为准，「工作啦」有权随时根据客观情况修改本服务的资费政策（包括但不限于服务价格）、订购方式，并在「工作啦」软件内予以公布。如用户不同意按照届时「工作啦」制定（修改）并公布的资费政策（包括但不限于服务价格）支付服务费用或不同意按照届时「工作啦」制定（修改）并公布的订购方式订购本服务，用户将不能获得本服务任何部分或全部，视为增值服务终止。但用户已经付费取得的服务内容、服务时长等，可以在原有范围内使用，如因服务内容变更导致无法继续使用的，用户可以申请退还未使用部分的费用。</div>

  <div class="content">3.2 服务开通</div>
  <div class="content">3.2.1重要约定：用户通过网银支付、手机支付或第三方支付等方式购买「工作啦」付费服务。用户充值一旦成功，充值即确定完成，「工作啦」将不提供任何更改、修正服务。</div>
  <div class="content">3.2.2－非经「工作啦」许可，用户不得以以下方式开通服务。<br/>（1）以营利、经营等非个人使用的目的为自己或他人开通本服务；<br/>（2）通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式为自己或他人开通本服务；<br/>（3）通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本服务；<br/>（4）通过非「工作啦」指定的方式为自己或他人开通本服务；<br/>（5）通过侵犯「工作啦」或他人合法权益的方式为自己或他人开通本服务，如通过第三方平台由他人代充代付；<br/>（6）通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本服务</div>

  <div class="content">3.3 服务期限</div>
  <div class="content">本服务中相关校招工具可能存在相关期限，如有期限限制以用户自行选择并支付相应服务费用的期限为准，用户也可以登陆用户也可以登陆「工作啦」校招工具的我的工具页面查询。</div>

  <div class="content">3.4退款</div>
  <div class="content">本服务中相关校招工具可能存在相关期限，如有期限限制以用户自行选择并支付相应服务费用的期限为准，用户也可以登陆用户也可以登陆「工作啦」校招工具的我的工具页面查询。</div>

  <div class="content">3.5 风险提示</div>
  <div class="content">3.5.1－在使用「工作啦」充值系统时，用户必须仔细确认自己的帐号及充值金额，若因为用户自身输入账号错误、操作不当或不了解充值计费方式等因素造成充错账号、错选充值种类等情形而损害自身权益，不得因此要求「工作啦」作任何补偿或赔偿。</div>
  <div class="content">3.5.2－若用户以非法的方式，或使用非「工作啦」所指定的充值方式进行充值，「工作啦」不保证该充值顺利或者正确完成。若因此造成用户权益受损时，「工作啦」不会作任何补偿或赔偿，「工作啦」同时保留随时冻结或终止该用户账号资格及使用各项充值服务的权利。</div>


  <div class="title2">四、用户权利及义务</div>
  <div class="content">4.1 重要约定：用户不得通过未经「工作啦」授权许可的任何第三方平台或渠道（如淘宝、京东等平台上的店铺）购买「工作啦」啦豆等「工作啦」提供的增值服务或产品；不得将账号提供给第三方进行代充代付等活动。因违反前述约定导致用户隐私泄露的，用户应当自行承担相应后果；如给「工作啦」造成损失的，「工作啦」可以要求用户承担违约责任并赔偿损失。</div>
  <div class="content">4.2 用户同意不会采用收费或免费的方式，全部或部分地在任何公开场合展示用户购买的增值服务内容，除非用户的上述行为不会构成侵权。</div>
  <div class="content">4.3 用户在使用本服务时不得利用本服务从事以下行为，包括但不限于：<br/>
    （1）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容；<br/>（2）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；<br/>（3）虚构事实、隐瞒真相以误导、欺骗他人；<br/>（4）发表、传送、传播广告信息及垃圾信息；<br/>（5）从事其他违反法律法规、政策及公序良俗、社会公德等的行为。<br/>（6）通过任何方式搜集本服务中其他用户的用户名、电子邮件等相关信息，并以发送垃圾邮件、连锁邮件、垃圾短信、即时消息等方式干扰、骚扰其他用户；<br/>（7）通过本服务发布包含广告、宣传、促销等内容的信息。</div>
  <div class="content">4.5对自己行为负责<br/>用户充分了解并同意，用户必须为自己注册帐号下的一切行为负责，包括但不限于用户所发表的任何内容以及由此产生的任何后果。用户应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。 若因用户违反「工作啦」《用户协议及隐私说明》或本协议内容，「工作啦」因此冻结账号及终止账号使用资格者，不得因此要求「工作啦」作任何补偿或赔偿。</div>
  <div class="content">4.6 用户应自行负责妥善且正确地保管、使用、维护其在「工作啦」申请取得的账户及账户密码。用户应对其账户密码采取必要和有效的保密措施。非「工作啦」原因致使用户账户密码泄漏以及因用户保管、使用、维护不当造成损失的，「工作啦」无须承担与此有关的任何责任。</div>
  <div class="content">4.7 用户明确了解并同意，本协议第3.2条款约定的付费方式为代收费运营商托收的付款方式，用户通过此种付费方式付费可能存在一定的商业风险（包括但不限于不法分子利用用户账户或银行卡等进行违法活动），该等风险均会给用户造成相应的经济损失。为此，用户同意「工作啦」在充分履行其在本协议项下全部义务及勤勉谨慎义务的前提下不对用户的前述风险和损失承担任何责任；并且，「工作啦」不承担向不法分子追究侵权责任或者代其向用户承担损失的任何责任和义务。</div>

  <div class="title2">五、变更及免责条款</div>
  <div class="content">5.1 「工作啦」有权随时修改本协议的任何条款，一旦本协议的内容发生变动，「工作啦」将会在网站上公布修改之后的协议内容，若用户不同意上述修改，则可以选择停止使用「工作啦」服务。「工作啦」也可选择通过其他适当方式（比如系统通知）向用户通知修改内容。如果不同意「工作啦」对本协议相关条款所做的修改，用户有权停止使用「工作啦」服务。如果用户继续使用「工作啦」服务，则视为用户接受「工作啦」对本协议相关条款所做的修改。</div>
  <div class="content">5.2用户充分了解并同意，由于互联网服务的特殊性，「工作啦」可能会按照相关法规、双方约定或在其他必要时，中止或终止向用户提供本服务，届时，「工作啦」会依法保护用户的合法权益。但对于用户已经购买但尚未使用的虚拟服务（啦豆），「工作啦」将以法定货币方式或用户接受的其他方式退还用户。「工作啦」将在终止服务公告中明确虚拟货币申请退还的期限和具体的退还政策。</div>

  <div class="title2">六、服务的中断和终止</div>
  <div class="content">6.1 因发生「工作啦」不可抗拒的事由，如政府行为、不可抗力，导致服务无法继续，「工作啦」将尽快通知用户，但不承担由此对用户造成的任何损失并不退还任何费用。</div>
  <div class="content">6.2 如用户严重违反本服务条款中的内容，「工作啦」有权在不通知用户的情况下立即终止用户已购买的部分或所有服务，以及暂时或永久取消用户的「工作啦」账户和使用权限，但不退还任何已缴纳的服务费用。</div>
  <div class="content">6.3 「工作啦」未行使或延迟行使其在本协议项下的权利并不构成对这些权利的放弃，而单一或部分行使其在本协议项下的任何权利并不排斥其任何其它权利的行使。「工作啦」随时有权要求用户继续履行义务并承担相应的违约责任。</div>

  <div class="title2">七、违约责任</div>
  <div class="content">7.1 如因「工作啦」违反有关法律、法规或本协议项下的任何条款而给用户造成损失，「工作啦」同意承担由此造成的损害赔偿责任。但「工作啦」的赔偿责任亦以用户就使用服务所支付「工作啦」的价款为限。</div>
  <div class="content">7.2 用户若违反协议或相关法令，导致「工作啦」、或其关系企业、受雇人、受托人、代理人及其它相关履行辅助人因此而受到损害或支出费用（包括但不限于由法律诉讼、行政程序等所支出的诉讼费用、律师费用、实际遭受损失的费用等），用户应负担损害赔偿责任。</div>
  <div class="content">7.3对第三方损害的处理用户有违反本协议任何行为，导致任何第三方损害的，用户应当独立承担责任；「工作啦」因此遭受损失的，用户也应当一并赔偿。</div>

  <div class="title2">八、其他条款</div>
  <div class="content">8.1 本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。如本服务条款与中华人民共和国法律相抵触时，则该等条款将按法律规定重新修订，而其它条款则依旧有效并具有法律效力。</div>
  <div class="content">8.2 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向「工作啦」运营商河南英才归来科技有限公司所在地的人民法院提起诉讼。</div>
  <div class="content">8.3 本协议项下所有的通知均可通过软件内通知、电子邮件、短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。</div>
</div>
