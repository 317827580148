import {Component, OnInit, Input, Output, ViewChild, EventEmitter} from '@angular/core';
import { DataApi,Local,Opera,Constants,Config } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {NavigationEnd, Router} from '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { ModelPositionNoticeComponent } from '../model-position-notice/model-position-notice.component';
import {filter} from "rxjs/operators";
import {ResumeLabelModelComponent} from "../resume-label-model/resume-label-model.component";
import { Events } from '../../provider/eventService';
import {NoneVipAlertComponent} from "../none-vip-alert/none-vip-alert.component";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";

declare var layui;
declare let Swiper:any;
@Component({
  selector: 'app-collection-resume-component',
  templateUrl: './collection-resume.component.html',
  styleUrls: ['./collection-resume.component.css']
})
export class CollectionResumeComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true }) 
  productModel: ProductModelComponent;

  @ViewChild('noticeDelivery') child: ModelPositionNoticeComponent;
  @ViewChild('resumeLabelModel', { static: true }) resumeLabelModel: ResumeLabelModelComponent;

  @Output() addResumeLabelmodelShow = false;//是否打开弹窗
  @Output() modelShow = false;//是否打开弹窗
  @Output() goAppropriate = new EventEmitter();
  public config: Config;
  public positionList=[];//职位列表
  public educationList=[];//学历列表
  public keywordList=[];//搜索关键词匹配列表
  // 筛选条件--搜索
  public model={
    keywords:'',//关键词
    major:'',//专业
    education:'',//学历
    industry:'',
    work_experience:'',
    limit:10,
    offset:0,
    lable:'',
    positionid:''
  };

  public bannerList = []
  public status={
    feedback_status:''//反馈状态 posted：已投递viewed：已被查看interested：合适 improper：不合适
  }
  public searchModel = '';//搜索关键词
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  public majorOne='';//专业类别一级
  public resumeList = [];//简历列表
  public LabelList = [];//标签列表
  public countList = 0;//列表数量

  public isShowMemberMoudel = true;
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  // public isPsitionList=false;
  public isShowFilter = false;
  @Input()  isShow: false;//是否显示弹窗页面
  constructor(public dataApi: DataApi,
              public local:Local,
              private router: Router,
              public opera:Opera,
              public events: Events,
              public dialog: MatDialog,
              public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event:NavigationEnd) => {
      if (event.url === '/home/position-resume-like') {
        this.opera.initConfig('nav-resume-manager','position-resume-like');
        this.getSendResumeList();//获取简历投递列表
      }
    });
  }

  ngOnInit() {
    this.opera.vipState((isPay)=>{
      this.isShowMemberMoudel =  isPay;
    })
    this.getPositionList();//获取职位列表数据
    this.getCollegeCode();//获取高校字典
    this.getMajorList();//获取专业列表信息
    this.getEducationList();//获取学历列表信息
    this.getCardbanner();
    this.getMyresumeLabelList();

    //收到简历详情修改简历标签的通知
    this.events.subscribe('updateResumeLabelNot', () => {
      this.getSendResumeList();//获取简历投递列表
      this.getMyresumeLabelList();

    });
    this.events.subscribe('updateResumeShouCangNot', () => {
      this.getSendResumeList();//获取简历投递列表
    });

  }

  //点击产品
  product(item,type){
    this.productModel.initData(item.alias,type);
    this.isproductShowModel = true;
  }

  productModelHidden(event){    
    this.isproductShowModel = false;
  }   
  
  //组件相应的视图初始化之后
  ngAfterViewInit(){
  }
  //去面试邀请
  goNotice(id,talentid){
    this.modelShow=true;
    this.child.initData(id,true,talentid);
  }
  //接收子组件返回参数
  Modeldismiss(data){
    this.modelShow = false;
    if(data){
      //console.log("面试邀请返回信息-----model-delivery-resume");
      //console.log(data);
    }
  }

  //反馈状态
  setResumeStatus(id,status){
    this.status.feedback_status = status;
    this.dataApi.setSendResumeStatus(id,status).then((res:any)=>{
      this.toastr.success("状态修改成功");
      for(let n of this.resumeList){
        if(n.deliveryid==id){
          n.feedback_status=status;
        }
      }
      if(status == 'interested'){
        setTimeout(() => {
          this.goAppropriate.emit()
        },1000)
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    })
  }

  //设置为不合适提醒
  setResumeImproper(id,name){
    this.opera.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '简历处理为不合适',
        message: '简历处理为不合适后，15天内将不再接收 TA 的消息。如需修改，可到简历列表中重新处理。确定将【'+name+'的简历】 处理为不合适吗？',
        trueText:'确定',
        falseText:'再考虑一下'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      //console.log('返回值');
      //console.log(isConfirmed);
      this.opera.scrollTrue();
      if(isConfirmed){
        this.setResumeStatus(id,'improper')
      }
    });
  }
  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----college-resume');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*10;
      this.getSendResumeList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*10;
        this.getSendResumeList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*10;
        this.getSendResumeList();//获取简历投递列表
      }
    }
  }

  //获取简历投递列表
  getSendResumeList(){
    this.keywordList=[];
    this.dataApi.getResumeArchivedList(this.model).then((res:any)=>{
      console.log("获取简历投递列表-收藏的",res);
      if(res)
      {
        this.resumeList = res.list;
        this.countList=res.count;
        this.pageCount =Math.ceil(res.count/10);
        for (let val of  this.resumeList )
        {
          if(val.lable==null)
          {
            val.lable=[];
          }
          let arr = [];
          val.yixiang = "";
          val.youshiArr = [];
          if(val.pro_skills!=null)
          {
            for (let item1 of val.pro_skills)
            {
              val.yixiang += item1.text+"    "
              if(item1.contrast_list){
                for (let youshi of item1.contrast_list)
                {
                  val.youshiArr.push(youshi.text);
                }
              }
            }
          }
        }
      }
    }).catch((err)=>{
      //console.log(err);
    })
  }
  //监听majorOne
  majorOneChange(){
    // //console.log("第一级专业值");
    // //console.log(this.majorOne);
    // //console.log("专业列表");
    // //console.log(this.config.major);
    this.majorListTwo=[];
    for(let n of this.config.major){
      if(n.parentcode==this.majorOne){
        this.majorListTwo.push(n);
      }
    }
    // //console.log("第二级专业列表");
    // //console.log(this.majorListTwo);
  }


  //提示先选一级专业
  isShowTwo(){
    if(!this.majorOne){
      this.toastr.warning("请先选择一级专业");
      return;
    }
  }

  //获取学历列表信息
  getEducationList(){
    if(this.dataApi.getEducationCode()){
      this.educationList = this.dataApi.getEducationCode();
    }else{
      this.dataApi.getDictDate('education').then((res:any)=>{
        if(res) {
          this.educationList = res;
          this.dataApi.setEducationCode(res);
        }
      }).catch((error)=>{
        //console.log(error);
      });
    }
  }

  //获取一级专业列表
  getMajorListOne(){
    for(let n of this.config.major){
      if(n.level==2){
        this.majorListOne.push(n);
      }
    }
  }

  //获取专业列表信息
  getMajorList(){
    if(this.dataApi.getMajorCode()){
      this.config.major = this.dataApi.getMajorCode();
      this.getMajorListOne();//获取一级专业列表
    }else{
      this.dataApi.getDictDate('major').then((res:any)=>{
        //console.log("获取专业列表-----college-resume");
        if(res)
        {
          this.config.major = res;
          this.getMajorListOne();//获取一级专业列表
          this.dataApi.setMajorCode(res);
        }
      }).catch((error)=>{
        //console.log(error);
      });
    }
  }

  //获取高校字典
  getCollegeCode(){
    // 获取高校字典数据
    if(this.config.school.length<1){
      this.dataApi.getDictDate('school').then((res:any)=>{
        if(res) {
          this.config.school=res;
        }
      }).catch((err)=>{
        // this.toastr.error("网络错误,请稍后重试");
        //console.log(err);
      })
    }
  }

  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      that.goSearch(that.model.keywords);
    }else{
      that.getItems();
    }
  }

  //匹配高校名称字典
  getItems(){
    this.keywordList=[];
    if(!this.model.keywords){return;}
    for(let s of this.config.school){
      if(s.text.indexOf(this.model.keywords) >= 0){
        this.keywordList.push(s);
      }
    }
  }

  //筛选
  goSearch(text){
    this.model.keywords = text;
    this.getSendResumeList();//获取简历列表
    this.keywordList=[];
  }

  //获取职位列表数据
  getPositionList(){
    //console.log("在期职位本地存储----");
    //console.log(this.dataApi.getLocalpositionList());
    if(this.dataApi.getLocalpositionList()){
      this.config.positionList=this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
      this.positionList =  this.config.positionList;
    }else{
      this.getNewList();
    }
  }

  //-------获取线上数据
  getNewList(){
    let model={
      limit:100,
      offset:0,
      status:'release',
    }
    this.dataApi.getPositionList(model).then((res:any)=>{
      if(res) {
        this.positionList =  res.list;
        this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  //-----跳转简历详情
  goDetails(n){
    let that = this;
    that.config.isNavActive='college';
    that.config.isNavChildActive='college-resume';
    that.router.navigate(['home/college-resumedetails'], {
      queryParams: {
        resumeid:n.resume_number,
      }
    });
  }

  //立即沟通
  goChat(n,id){
    this.opera.goChat(n,id);
  }
  //收藏
  goCollect(val){
    let data = val;
    let that = this;
    val = this.opera.manageResumeArchived(val,()=>{
      console.log('执行标签弹窗',data);
      that.resumeLabelModel.init(data);
      that.addResumeLabelmodelShow = true;
    });
  }
  //获取card广告banner
  getCardbanner() {
    let that = this;
    this.dataApi.getCardBannerdata('jianli').then((res:any) => {
      if (res) {
        console.log('获取广告Banner', res);
        this.bannerList = res;
        setTimeout(()=>{
          layui.use(['carousel', 'form'], function() {
            var carousel = layui.carousel
              , form = layui.form;
            //建造实例
            carousel.render({
              elem: '#test1'
              ,width: '100%' //设置容器宽度
              ,arrow: 'always', //始终显示箭头
              height:'160px',
              interval:3000
              //,anim: 'updown' //切换动画方式
            });
            //找到两个按钮，设置样式，因为全局样式又一个Buttom里有一个样式会导致按钮错乱
            let leftbtn =  document.getElementById("lay-type-left");
            let rightbtn =  document.getElementById("lay-type-add");
            if(leftbtn) {
              leftbtn.style.padding ='0';
            }
            if(rightbtn) {
              rightbtn.style.padding ='0';
            }
          })
        },200)
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  bannerClick(n) {
    console.log('bannerClick',n)
    if(n.links)
    {
      window.open(n.links,'_blank');
      return;
    }
  }
  shouCang(e,item){
    e.stopPropagation();
    this.goCollect(item)
  }
  addResumeLabelmodeHidden(resume){
    this.addResumeLabelmodelShow = false;
    if(resume)
    {
      console.log('resume',resume,this.resumeList)
    }
    this.getMyresumeLabelList();

    setTimeout(()=>{
      this.getSendResumeList();
      this.getMyresumeLabelList()
    },1500);

  }
  editLabelClick(e,n){
    e.stopPropagation();
    this.resumeLabelModel.init(n);
    this.addResumeLabelmodelShow = true;
  }
  getMyresumeLabelList(){
    let data = {
      offset:'0',
      limit:'40',
    }
    this.dataApi.getMyresumeLabelList(data).then((res:any)=>{
      if(res)
      {
        this.LabelList = res.list;
      }
    }).catch((err)=>{

    })

  }
  nav01(){
    this.router.navigate(['home/position-resume']);
  }

  nav02(){
    this.router.navigate(['home/position-resume-like']);
  }

  nav03(){
    this.router.navigate(['home/voiceCallRecord']);
  }

  nav04(){
    this.router.navigate(['home/positionPushRecord']);
  }

  nav05(){
    this.router.navigate(['home/activityInviteRecord']);
  }

  nav06(){
    this.router.navigate(['home/airCampHistoryReord']);
  }


}
