import { Component,EventEmitter, Input, Output,ViewChild,ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataApi,Constants,Config,Local} from '../../provider/index';

@Component({
  selector: 'app-model-company-label',
  templateUrl: './model-company-label.component.html',
  styleUrls: ['./model-company-label.component.css']
})
export class ModelCompanyLabelComponent{
  public config: Config;
  @Input() isShow: false;//是否显示弹窗页面
  public labelAll = [];//标签--已选
  public index = 0;
  public userLabel:any;
  constructor(public toastr: ToastrService,
    public local:Local,
    public dataApi: DataApi) {
      this.config = Config.getInstance();
      this.getLabelCode();//获取企业标签字典
    }
    @Output() onVoted = new EventEmitter();
    dismiss(result: any) {
      //console.log("传值检查");
      //console.log(result);
      this.labelAll = [];
      this.index = 0;
      this.onVoted.emit(result);

    }

    //初始化函数
    initData(data){
      this.labelAll=data;
      for(let val of this.config.label){
        val.isActive=false;
        if(this.labelAll.length>0){
          this.index=this.labelAll.length;
          for(let n of this.labelAll){
            if(val.text==n.text){
              if(n.is_using){
                val.isActive=true;
              }
            }
          }
        }
      }
    }

  //获取企业标签本地存储
  getLabelCode(){
    if(this.config.label.length==0){
      //console.log("企业推荐标签获取线上数据");
      // let loader = this.tip.presentLoading('数据加载中...');
      this.dataApi.getDictDate('lable').then((res:any)=>{
        this.config.label=res;
        // loader.dismiss();
      }).catch((error)=>{
        //console.log(error);
        // loader.dismiss();
      });

    }
  }

  //添加企业标签
  addLabel(user){
    if(this.index>8){
      this.toastr.warning('标签数量大于9');
      return;
    }
    // //console.log(Boolean(user));
    //判断用户是否输入内容
    if(user){
      let isExit = false;//是否重复

      //获取当前日期函数
      const formatDate = ( time: any ) => {
        // 格式化日期，获取今天的日期
        const Dates = new Date( time );
        const year: number = Dates.getFullYear();
        const month: any = ( Dates.getMonth() + 1 ) < 10 ? '0' + ( Dates.getMonth() + 1 ) : ( Dates.getMonth() + 1 );
        const day: any = Dates.getDate() < 10 ? '0' + Dates.getDate() : Dates.getDate();
        const hours: any=Dates.getHours() <10 ? '0' + Dates.getHours() : Dates.getHours();
        const minutes: any=Dates.getMinutes() <10 ? '0' + Dates.getMinutes() : Dates.getMinutes();
        const seconds: any=Dates.getSeconds() <10 ? '0' + Dates.getSeconds() : Dates.getSeconds();
        return year + '-' + month + '-' + day +'T'+ hours +':' + minutes +':' + seconds +'Z';
      };

      this.labelAll.forEach((val)=>{
        if(user === val.text){
          if(val.is_using){
            isExit = true;
          }
        }
      });

      if(isExit === false){
        let postObj = {
          text:'',
          add_time:'',
          is_using:false
        };
        postObj.text = user;
        postObj.add_time = formatDate(new Date());
        if(this.index>8){
          postObj.is_using = false;
        }else{
          postObj.is_using = true;
          this.index++;
        }
        this.labelAll.push(postObj);
      }else if(isExit){
        this.toastr.warning('此标签已存在');
      }
    }

  }

 //删除企业标签
 delectSkill(label){
  for(let i of this.config.label){
    if(i.text==label.text){
      i.isActive = false;
    }
  }
  for(let val of this.labelAll){
    if(label==val){
      let n=this.labelAll.indexOf(val);
      this.labelAll.splice(n,1);//----删除元素
      this.index--;
      return;
    }
  }
}

//获取未选中标签列表
initLabel(){
  let index = 0;
  if(this.labelAll.length>0){
    this.labelAll.forEach((val)=>{
      if(val.is_using){
        index++;
      }
      // this.labels.push({
      //   add_time:val.add_time,
      //   is_using:val.is_using,
      //   text:val.text
      // });

    })
    this.index = index;
  }

}

// //选中标签------字典
chooseConfigLabel(label){
  if(this.index>8){
    this.toastr.warning('标签数量大于9');
    return;
 }
  label.isActive=true;
  // //console.log("选中的标签------label");
  // //console.log(label);
  this.addLabel(label.text);
}

//选中标签
chooseLabel(choose){
 	if(choose.is_using){
    choose.is_using = false;
    this.index--;
 	}else{
    let index = 0;
    this.labelAll.forEach((val)=>{
      if(val.is_using){
        index++;
      }
    });
    //console.log(index);
    if(index > 8){
      this.toastr.warning('标签数量大于9');
      return;
    }
    choose.is_using = true;
    this.index++;
 	}

}

//保存标签
  getLabel(){
    let index = 0;
    this.labelAll.forEach((val)=>{
    	if(val.is_using){
    		index++;
    	}
    });
    if(index > 9){
    	this.toastr.warning('标签数量大于9');
    	return;
    }else{
      // let loader = this.tip.presentLoading('');
      this.dataApi.uploadDepartmentLable(this.labelAll).then((res:any)=>{
        //console.log(res);
        this.dismiss(this.labelAll);
        // this.labelAll = data;
        // loader.dismiss();
        // this.viewCtrl.dismiss(this.labelAll);
      }).catch((error) => {
        //console.log(error);
        this.toastr.error(error);
        // loader.dismiss();
      });

    }
  }

}
