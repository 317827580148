<!-- 页头部分 -->
<!-- <div class="contentRight-header">我的资料 - 公司管理</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <img (click)="opera.scrollTop1()" src="../../assets/images/go_top.png" style="width: 60px;height: 60px;position: fixed;bottom: 100px;right: 50px;cursor: pointer" />
    <router-outlet></router-outlet>
    <app-model-select-two #industry [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-select-two>
    <app-model-company-label #label [isShow]="modelTwoShow" (onVoted)="ModelTwodismiss($event)">
    </app-model-company-label>
    <app-model-company-product #product [isShow]="modelThreeShow" (onVoted)="ModelThreedismiss($event)">
    </app-model-company-product>
    <app-model-company-courses #courses [isShow]="modelFourShow" (onVoted)="ModelFourdismiss($event)">
    </app-model-company-courses>
    <app-model-company-strength #strength [isShow]="modelFiveShow" (onVoted)="ModelFivedismiss($event)">
    </app-model-company-strength>
    <app-model-cropper #crop [isShow]="modelSixShow" (onVoted)="ModelSixdismiss($event)"></app-model-cropper>
    <!-- <app-model-company-name #name [isShow]="modelSevenShow" [type]="modelSevenType" (onVoted)="ModelSevendismiss($event)"></app-model-company-name> -->
    <!--v3.0-->
    <div class="secondNav">
        <div class="leftNav">
            <div class="item active">公司管理<span></span></div>
            <div class="item" (click)="goAccount()">账号管理</div>
        </div>
        <div class="videoCode" *ngIf="myDepartment?.miniprogram_qrcode" (click)="viewMiniPro()"><img src="/assets/images/v3/videoCode.png">生成企业小程序码</div>
    </div>
    <div class="companyHomepage" *ngIf="myDepartment">

        <div class="main">
            <div class="title">公司信息</div>
            <div class="basic">
                <div class="logo">
                    <div class="box">
                        <label *ngIf="myDepartment?.logo" class="uploadImg-label department-logo" for="uploadDepatmentLogoImg">
                            <img [src]="myDepartment?.logo" *ngIf="myDepartment?.logo">
                        </label>
                        <label *ngIf="!myDepartment?.logo" class="uploadImg-label" for="uploadDepatmentLogoImg">
                            <img class="companyPush" src="/assets/images/v3/companyPush.png">
                            <p>LOGO</p>
                        </label>
                        <input class="uploadImg" id="uploadDepatmentLogoImg" (change)="previewPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                    </div>
                    <p>点击上传公司LOGO</p>
                    <p>像素：200*200px,格式：jpg,png</p>
                </div>
                <div class="call">
                    <div class="item">
                        <div class="n-title">公司全称<span>（必填）</span></div>
                        <div class="val">
                            <div class="name">{{myDepartment.name}}</div>
                            <div class="btn" (click)="seleteUpdateNameType()"><img src="/assets/images/v3/companyedit.png">编辑</div>
                        </div>
                    </div>

                    <div class="item">
                        <div class="n-title">公司简称<span>（必填）</span></div>
                        <div class="val">
                            <div class="name">{{myDepartment.common_name}}</div>
                            <div class="btn" (click)="seleteUpdateShortNameType()"><img src="/assets/images/v3/companyedit.png">编辑</div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="title02">上传招聘短视频<img src="/assets/images/v3/videoTitle.png"></div>
            <div class="video">
                <div class="box">
                    <!--短视频上传-->
                    <div class="item active">
                        <div class="img-list videoBox" *ngIf="myDepartment.video_link">
                            <img class="delVideo" (click)="delVideo()" src="assets/images/delVideo.png">
                            <video width="100%" height="100%" controls="controls" id="video_link" *ngIf="isShowVideo" [src]="myDepartment.video_link" type="auto"></video>
                        </div>
                        <div class="img-list videoBox" *ngIf="!myDepartment.video_link">
                            <div class="isvip" *ngIf="!employerVip" (click)="govieBuy()"></div>
                            <label class="uploadImg-label" for="uploadDepartmentvideo">
                                <img class="videoIcon" src="assets/images/v3/videoIcon.png">
                            </label>
                            <input class="uploadImg" id="uploadDepartmentvideo" (change)="departmentVideo($event)" type="file" accept="video/*">
                        </div>
                        <p>点击上传视频</p>
                        <p>*视频时长:不超过3分钟</p>
                        <p>像素:720*1280px</p>
                    </div>
                    <!--短视频封面上传-->
                    <div class="item">
                        <div class="img-list videoBox" *ngIf="myDepartment.video_cover_link">
                            <img class="delVideo" (click)="delVideoCover()" src="assets/images/delVideo.png">
                            <img class="video_cover_linkimg" [src]="myDepartment.video_cover_link">
                        </div>
                        <div class="img-list videoBox" *ngIf="!myDepartment.video_cover_link">
                            <div class="isvip" *ngIf="!employerVip" (click)="govieBuy()"></div>
                            <label class="uploadImg-label" for="uploadDepartmentvideoCover">
                                <img class="companyPush" src="assets/images/v3/companyPush.png">
                            </label>
                            <input class="uploadImg" id="uploadDepartmentvideoCover" (change)="departmentVideoCover($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                        </div>
                        <p>点击上传视频封面</p>
                        <p>*像素:112*166px 格式:jpg.png</p>
                    </div>
                </div>
            </div>
            <div class="formContainer">
                <!--所属行业-->
                <div class="module">
                    <div class="left">所属行业<span>（必填）</span></div>
                    <div class="right">
                        <div class="sanjiao"><img src="/assets/images/v3/cardDown02.png"></div>
                        <div class="val fn" *ngIf="!model.industry" (click)="chooseIndustry()">请选择选择所属行业</div>
                        <div class="val fn active" *ngIf="model.industry" (click)="chooseIndustry()">{{ model.industry | codetransform:config.industry}}</div>
                    </div>
                </div>
                <!--公司性质-->
                <div class="module">
                    <div class="left">公司性质<span>（必填）</span></div>
                    <div class="right">
                        <div class="val">
                            <select class="activeBg" autocomplete="off" [(ngModel)]="model.employer_type" [disabled]="model.employer_type?true:false">
                                <option class="place-text" value='' disabled hidden selected="selected">选择公司性质</option>
                                <option *ngFor="let type of config.employer_type" [value]="type.code">{{type.text}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <!--注册资金-->
                <div class="module">
                    <div class="left">注册资金<span>（必填）</span></div>
                    <div class="right">
                        <div class="val capital">
                            <input type="text" (ngModelChange)="register_capitalChange($event)" [(ngModel)]="model.register_capital" maxlength="40" onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')" placeholder="请填写公司注册资金">
                            <select [(ngModel)]="register_capital_type" autocomplete="off" class="zijinType activeBg">
                                <option value="万元">万元</option>
                                <option value="亿元">亿元</option>
                            </select>
                        </div>
                    </div>
                </div>
                <!--公司地址-->
                <div class="module">
                    <div class="left">公司地址<span>（必填）</span></div>
                    <div class="right">
                        <div class="val address">
                            <select class="activeBg" autocomplete="off" [(ngModel)]="workPlace">
                                <option class="place-text" value='' disabled hidden selected="selected">选择公司地址</option>
                                <option  *ngFor="let address of myDepartment.locations" [value]="address.lid">
                                    {{address.region_text}}&nbsp;&nbsp;{{address.address}}</option>
                            </select>
                            <div class="btn" (click)="goAddress()"><img src="/assets/images/v3/address-edit.png">管理地址</div>
                        </div>
                    </div>
                </div>
                <!--成立年份-->
                <div class="module">
                    <div class="left">成立年份<span>（必填）</span></div>
                    <div class="right">
                        <div class="val">
                            <input type="number" [(ngModel)]="model.establish_year" (ngModelChange)="establish_yearChange($event)" placeholder="请输入成立年份">
                            <div class="danwei">年</div>
                        </div>
                    </div>
                </div>
                <!--员工规模-->
                <div class="module">
                    <div class="left">员工规模<span>（必填）</span></div>
                    <div class="right">
                        <div class="val">
                            <select class="activeBg" autocomplete="off" [(ngModel)]="model.scale">
                                <option class="place-text" value='' disabled hidden selected="selected">选择员工人数</option>
                                <option *ngFor="let scale of config.scale" [value]="scale.code">{{scale.text}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <!--公司简介-->
                <div class="module">
                    <div class="left">公司简介<span>（必填）</span></div>
                    <div class="right">
                        <div class="textarea">
                            <textarea [(ngModel)]="model.intro" minlength="50" maxlength="500" placeholder="请填写公司简介（50-500字）"></textarea>
                        </div>
                    </div>
                </div>
                <!--是否上市-->
                <div class="module">
                    <div class="left">是否上市</div>
                    <div class="right">
                        <div class="val">
                            <select class="activeBg" autocomplete="off" [(ngModel)]="is_quoted_company" (change)="listChange()">
                                <option *ngFor="let scale of whetherlist" [value]="scale.code">{{scale.text}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- 股票代码 -->
                <div class="module" *ngIf="model.is_quoted_company">
                    <div class="left">股票代码<span>（必填）</span></div>
                    <div class="right">
                        <div class="val">
                            <input type="text" [(ngModel)]="model.stock_code" placeholder="请填写公司股票代码">
                        </div>
                    </div>
                </div>
                <!--公司实力-->
                <div class="module">
                    <div class="left">公司实力</div>
                    <div class="right active">
                        <div class="item" *ngFor="let label of model.strengths">{{label.text}}</div>
                        <div class="push" (click)="goStrengths()"><img src="/assets/images/v3/address-edit.png">编辑</div>
                    </div>
                </div>
                <!--福利待遇-->
                <div class="module">
                    <div class="left">福利待遇</div>
                    <div class="right active">
                        <div class="item" *ngFor="let label of model.labels">{{label.text}}</div>
                        <div class="push" (click)="goLabel()"><img src="/assets/images/v3/address-edit.png">编辑</div>
                    </div>
                </div>
                <!--微信公众号-->
                <div class="module">
                    <div class="left">微信公众号</div>
                    <div class="right">
                        <div class="val">
                            <input type="text" [(ngModel)]="model.official_account" placeholder="请填写公司微信公众号">
                        </div>
                    </div>
                </div>
                <!--公司网址-->
                <div class="module">
                    <div class="left">公司网址</div>
                    <div class="right">
                        <div class="val">
                            <input type="text" [(ngModel)]="model.website" placeholder="请填写公司网址">
                        </div>
                    </div>
                </div>
                <!--公司产品-->
                <div class="module">
                    <div class="left">公司产品</div>
                    <div class="listContainer">
                        <div class="item" *ngFor="let n of model.products">
                            <div class="top">
                                <div class="name">{{n.name}}</div>
                                <div class="btn active" (click)="goEditorProduct(n)"><img src="/assets/images/v3/address-edit.png">编辑</div>
                                <!-- <div class="btn" (click)="deleteProduct(n)"><img src="/assets/images/v3/address-del.png">删除</div> -->
                            </div>
                            <div class="introduce">{{n.description}}</div>
                            <div class="imgs" *ngIf="n.image_url">
                                <img [src]="n.image_url">
                            </div>
                        </div>
                        <div class="pushBtn" (click)="goProduct()"><img src="/assets/images/v3/pushposition.png">添加产品</div>
                    </div>
                </div>
                <!--发展历程-->
                <div class="module">
                    <div class="left">发展历程</div>
                    <div class="listContainer">
                        <div class="item" *ngFor="let n of model.courses">
                            <div class="top">
                                <div class="name">{{n.name}}</div>
                                <div class="btn active" (click)="goEditorCourses(n)"><img src="/assets/images/v3/address-edit.png">编辑</div>
                                <!-- <div class="btn" (click)="deleteProduct(n)"><img src="/assets/images/v3/address-del.png">删除</div> -->
                            </div>
                            <div class="time">{{n.occur_time | date:"yyyy-MM-dd"}}</div>
                            <div class="website"><a target="_blank" href="{{n.link}}">{{n.link}}</a></div>
                            <div class="introduce" [innerHtml]="n.details | textformat">{{n.description}}</div>
                        </div>
                        <div class="pushBtn" (click)="goCourses()"><img src="/assets/images/v3/pushposition.png">添加发展历程</div>
                    </div>
                </div>
                <!-- 公司展示 -->
                <div class="imglistmodule">
                    <div class="topNumber">公司展示<span>{{model.image_urls.length}}/10</span></div>
                    <div class="imglist">
                        <div class="item" *ngFor="let img of model.image_urls;let num=index">
                            <img class="_img" [src]="img">
                            <div (click)="deleteImg(img)" class="delete-img"><img src="/assets/images/v3/companyDtl.png"></div>
                        </div>
                        <div class="pushImg" *ngIf="model.image_urls.length<10">
                            <label class="uploadImg-label" for="uploadDepartmentImg">
                                <img src="/assets/images/v3/pushposition.png">
                            </label>
                            <input class="uploadImg" id="uploadDepartmentImg" (change)="previewDepartmentPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                        </div>
                    </div>
                </div>
                <!-- 保存 -->
                <div class="submit blueStreamer" (click)="updateDepartment()">保存</div>
            </div>
        </div>
    </div>
    <div class="bottomBg" *ngIf="myDepartment?.miniprogram_qrcode" (click)="viewMiniPro()"><img src="/assets/images/v3/companyHomepageBg.png"></div>


    <div class="college-block" style="display: none;">
        <div style="position: absolute;left: 965px;top: 0">
            <img style="width: 139px;height: 140px;" *ngIf="myDepartment?.miniprogram_qrcode" (click)="viewMiniPro()" src="../../assets/images/make_qrcode.png" />
            <div style="color: #888888;font-size: 12px;width: 200px;margin-top: 0px;" class="text">
                &nbsp;&nbsp;1、把小程序码分享到学生社</div>
            <div style="color: #888888;font-size: 12px;width: 200px;" class="text">群，学生直接扫码投递</div>
            <div style="color: #888888;font-size: 12px;width: 200px;" class="text">&nbsp;&nbsp;2、作为校招宣传，用于微</div>
            <div style="color: #888888;font-size: 12px;width: 200px;" class="text">信推文/朋友圈海报/展架物料等</div>
        </div>

        <!--<div id="dg" style="z-index: 9999; position: fixed ! important; right: 35px;; bottom: 65px;;width: 139px;height: 140px">-->
        <!--<img style="width: 139px;height: 140px;" *ngIf="myDepartment.miniprogram_qrcode" (click)="viewMiniPro()" src="../../assets/images/make_qrcode.png" />-->
        <!--</div>-->

        <!-- 页头部分 -->
        <!-- 申请部分 *ngIf="myDepartment"-->
        <img class="top-banner" src="../../assets/images/view_mini.png" *ngIf="myDepartment?.miniprogram_qrcode" (click)="viewMiniPro()" />


        <div class="sign-block" *ngIf="myDepartment">
            <!-- 公司全称 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>公司全称：</span>
                <div class="selected-text disabled-text" style="position: relative">{{myDepartment.name}} <img style="width: 18px;height: 18px;position: absolute;right: 14px;top:50%;margin-top: -9px" (click)="seleteUpdateNameType()" src="../../assets/images/position/pul_edit.png" /></div>
                <!--<div class="remark-text">* 如需修改公司全称，请联系客服：{{config.CustomerServicePhone}}</div>-->
            </div>
            <!-- 公司简称 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>公司简称：</span>
                <div class="selected-text disabled-text" style="position: relative">{{myDepartment.common_name}} <img style="width: 18px;height: 18px;position: absolute;right: 14px;top:50%;margin-top: -9px" (click)="seleteUpdateShortNameType()" src="../../assets/images/position/pul_edit.png" /></div>
                <!--<div class="remark-text" style="margin-left: 125px;margin-top: 5px;color: #333333">审核中</div>-->
            </div>

            <!-- 公司LOGO -->
            <!-- <div class="sign-list sign-img">
                <span>公司LOGO：</span>
                <label *ngIf="myDepartment?.logo" class="uploadImg-label department-logo" for="uploadDepatmentLogoImg">
                    <img [src]="myDepartment?.logo" *ngIf="myDepartment?.logo">
                </label>
                <label *ngIf="!myDepartment?.logo" class="uploadImg-label" for="uploadDepatmentLogoImg">
                    <img src="assets/images/add-img.png"><br />点击上传公司LOGO
                </label>
                <input class="uploadImg" id="uploadDepatmentLogoImg" (change)="previewPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                <br /><s style="color: #999999">*像素：200*200px,格式：jpg,png</s>
            </div> -->
            <!-- 公司视频 -->
            <div class="sign-list sign-skill">
                <div class="company-imglist clear videoPadding">
                    <div class="videoModel">

                        <!-- 视频 -->
                        <div class="title"><img src="assets/images/videoHot.png">招聘短视频:</div>
                        <div class="left img-list videoBox" *ngIf="myDepartment.video_link">
                            <img class="delVideo" (click)="delVideo()" src="assets/images/delVideo.png">
                            <video width="100%" height="100%" controls="controls" id="video_link" *ngIf="isShowVideo" [src]="myDepartment.video_link" type="auto"></video>
                        </div>
                        <div class="left img-list videoBox" *ngIf="!myDepartment.video_link">
                            <div class="isvip" *ngIf="!employerVip" (click)="govieBuy()"></div>
                            <label class="uploadImg-label" for="uploadDepartmentvideo">
                                <img src="assets/images/add-img.png"><br />点击添加视频
                            </label>
                            <input class="uploadImg" id="uploadDepartmentvideo" (change)="departmentVideo($event)" type="file" accept="video/*">
                        </div>
                        <p>*视频时长:不超过3分钟</p>
                        <p>像素:720*1280px</p>
                    </div>

                    <div class="videoModel">
                        <!-- 视频封面 -->
                        <div class="title">招聘短视频封面:</div>
                        <div class="left img-list videoBox" *ngIf="myDepartment.video_cover_link">
                            <img class="delVideo" (click)="delVideoCover()" src="assets/images/delVideo.png">
                            <img class="video_cover_linkimg" [src]="myDepartment.video_cover_link">
                        </div>
                        <div class="left img-list videoBox" *ngIf="!myDepartment.video_cover_link">
                            <div class="isvip" *ngIf="!employerVip" (click)="govieBuy()"></div>
                            <label class="uploadImg-label" for="uploadDepartmentvideoCover">
                                <img src="assets/images/add-img.png"><br />点击添加视频封面
                            </label>
                            <input class="uploadImg" id="uploadDepartmentvideoCover" (change)="departmentVideoCover($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                        </div>
                        <p>*像素:112*166px 格式:jpg.png</p>
                    </div>

                </div>
            </div>
            <!-- 所属行业 -->
            <div class="sign-list" (click)="chooseIndustry()">
                <span><b class="remind-red">*</b>所属行业：</span>
                <div *ngIf="!model.industry" class="placholder-text">请选择选择所属行业</div>
                <div *ngIf="model.industry" class="selected-text" style="color: #999999">{{ model.industry | codetransform:config.industry}}
                </div>
                <p *ngIf="!model.industry" class="noneDataChips">*请补全完善本条信息</p>

            </div>
            <!-- 公司性质 -->
            <div class="sign-list select-list">
                <span><b class="remind-red">*</b>公司性质：</span>
                <select autocomplete="off" [(ngModel)]="model.employer_type" [disabled]="model.employer_type?true:false">
                    <option class="place-text" value='' disabled hidden selected="selected">选择公司性质</option>
                    <option *ngFor="let type of config.employer_type" [value]="type.code">{{type.text}}</option>
                </select>
                <p *ngIf="!model.employer_type" class="noneDataChips">*请补全完善本条信息</p>

            </div>
            <!-- 注册资金 -->
            <div class="sign-list">
                <!--<span><b class="remind-red">*</b>注册资金：</span><input type="text" [(ngModel)]="model.register_capital" placeholder="请填写注册资金"><span class="purple" style="text-align: left">万元</span>-->
                <span><b class="remind-red">*</b>注册资金：</span><input style="width: 200px;height: 40px" type="text" (ngModelChange)="register_capitalChange($event)" [(ngModel)]="model.register_capital" maxlength="40" onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                    placeholder="请填写公司注册资金">&nbsp;

                <select style="width: 85px;height: 44px;border-radius: 0" [(ngModel)]="register_capital_type" autocomplete="off" class="zijinType">
                    <option value="万元">万元</option>
                    <option value="亿元">亿元</option>
                </select>
                <p *ngIf="!model.register_capital" class="noneDataChips">*请补全完善本条信息</p>

            </div>
            <!-- 公司地址 -->
            <div class="sign-list select-list sign-address">
                <span><b class="remind-red">*</b>公司地址：</span>
                <select autocomplete="off" [(ngModel)]="workPlace">
                    <option class="place-text" value='' disabled hidden selected="selected">选择公司地址</option>
                    <option *ngFor="let address of myDepartment.locations" [value]="address.lid">
                        {{address.region_text}}&nbsp;&nbsp;{{address.address}}</option>
                </select>
                <button style="padding: 6px 20px" (click)="goAddress()">地址管理</button>
                <p *ngIf="myDepartment.locations.length==0" class="noneDataChips">*请补全完善本条信息</p>

            </div>
            <!-- 成立年份  -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>成立年份：</span>
                <input type="number" [(ngModel)]="model.establish_year" (ngModelChange)="establish_yearChange($event)" placeholder="请输入成立年份">
                <!--<img class="calendar-img" src="../../assets/images/position/calendar.png" alt="打开日历"/>-->
                <span class="calendar-img" style="top:1px;padding-right: 15px;">年</span>
                <p *ngIf="!model.establish_year" class="noneDataChips">*请补全完善本条信息</p>
            </div>
            <!-- 员工人数 -->
            <div class="sign-list select-list">
                <span><b class="remind-red">*</b>员工人数：</span>
                <select autocomplete="off" [(ngModel)]="model.scale">
                    <option class="place-text" value='' disabled hidden selected="selected">选择员工人数</option>
                    <option *ngFor="let scale of config.scale" [value]="scale.code">{{scale.text}}</option>
                </select>
                <p *ngIf="!model.scale" class="noneDataChips">*请补全完善本条信息</p>
            </div>
            <!-- 公司简介 -->
            <div class="sign-list sign-intro">
                <span><b class="remind-red">*</b>公司简介：</span>
                <textarea [(ngModel)]="model.intro" minlength="50" maxlength="500" placeholder="请填写公司简介（50-500字）"></textarea>
                <p *ngIf="!model.intro" class="noneDataChips">*请补全完善本条信息</p>
            </div>
            <!-- 是否上市 -->
            <div class="sign-list sign-skill">
                <span>是否上市：</span>
                <div class="label-list clear">
                    <div class="label-item label-click" [class.purple]="model.is_quoted_company" (click)="model.is_quoted_company=true">
                        <div class="label-text">上市公司</div>
                    </div>
                    <div class="label-item label-click" [class.purple]="!model.is_quoted_company" (click)="model.is_quoted_company=false">
                        <div class="label-text">非上市公司</div>
                    </div>
                </div>
            </div>
            <!-- 股票代码 -->
            <div class="sign-list" *ngIf="model.is_quoted_company">
                <span><b class="remind-red">*</b>股票代码：</span><input type="text" [(ngModel)]="model.stock_code" placeholder="请填写公司股票代码">
            </div>
            <!-- 公司实力 -->
            <div class="sign-list sign-skill">
                <span>公司实力：</span>
                <div class="label-list clear">
                    <div class="label-item purple" *ngFor="let label of model.strengths">
                        <div class="label-text">{{label.text}}</div>
                    </div>
                    <div class="label-item label-click" (click)="goStrengths()">
                        <div class="label-text">+添加</div>
                    </div>
                </div>
            </div>
            <!-- 福利待遇 -->
            <div class="sign-list sign-skill">
                <span>福利待遇：</span>
                <div class="label-list clear">
                    <div class="label-item purple" *ngFor="let label of model.labels">
                        <div class="label-text">{{label.text}}</div>
                    </div>
                    <div class="label-item label-click" (click)="goLabel()">
                        <div class="label-text">+添加</div>
                    </div>
                </div>
            </div>

            <!-- 微信公众号 -->
            <div class="sign-list">
                <span>微信公众号：</span><input type="text" [(ngModel)]="model.official_account" placeholder="请填写公司微信公众号">
            </div>
            <!-- 公司网址 -->
            <div class="sign-list">
                <span>公司网址：</span><input type="text" [(ngModel)]="model.website" placeholder="请填写公司网址">
            </div>
            <!-- 公司产品 -->
            <div class="sign-list sign-skill">
                <span>公司产品：</span>
                <div class="product-block" *ngIf="model.products.length>0">
                    <div class="product-list time-line" *ngFor="let n of model.products">
                        <b><img src="assets/images/line-time.png"></b>
                        <div (click)="goEditorProduct(n)" title="编辑" class="product-top">{{n.name}}<img src="assets/images/conpany-editor.png" alt="编辑产品"></div>
                        <div class="product-txt clear">
                            <div class="left">
                                <img *ngIf="n.image_url" [src]="n.image_url+'?imageView2/1/w/180/h/180'">
                                <img *ngIf="!n.image_url" src="assets/images/resume-search-fail.png">
                            </div>
                            <div class="right">{{n.description}}</div>
                            <!-- <textarea class="right" [(ngModel)]="n.description" disabled></textarea> -->
                        </div>
                    </div>
                    <div class="product-list" style="margin-left: 280px;"><button (click)="goProduct()">+添加</button>
                    </div>
                </div>
                <div class="label-list clear" *ngIf="model.products.length==0">
                    <div class="label-item label-click" (click)="goProduct()">
                        <div style="margin-left: 280px;" class="label-text">+添加</div>
                    </div>
                </div>

            </div>
            <!-- 发展历程 -->
            <div class="sign-list sign-skill">
                <span>发展历程：</span>
                <div class="product-block" *ngIf="model.courses.length>0">
                    <div class="product-list time-line" *ngFor="let n of model.courses">
                        <b><img src="assets/images/line-time.png"></b>
                        <div class="product-top" title="编辑" (click)="goEditorCourses(n)">{{n.occur_time | date:"yyyy-MM-dd"}}
                            <img src="assets/images/conpany-editor.png" alt="编辑产品"></div>
                        <div class="product-title">{{n.name}}</div>
                        <div class="product-link"><a href="{{n.link}}">{{n.link}}</a></div>
                        <div class="product-details" [innerHtml]="n.details | textformat"></div>
                        <!-- <textarea class="product-details" [(ngModel)]="n.details" disabled></textarea> -->
                    </div>
                    <div style="margin-left: 280px;" class="product-list"><button (click)="goCourses()">+添加</button>
                    </div>
                </div>
                <div class="label-list clear" *ngIf="model.courses.length==0">
                    <div class="label-item label-click" (click)="goCourses()">
                        <div style="margin-left: 280px;" class="label-text">+添加</div>
                    </div>
                </div>
            </div>
            <!-- 公司展示 -->
            <div class="sign-list sign-skill">
                <span style="line-height:200px;">公司展示：<br /><i>(限10张)</i></span>
                <div class="company-imglist clear">
                    <div class="left img-list" *ngFor="let img of model.image_urls;let num=index">
                        <img [src]="img+'?imageView2/1/w/240/h/180'">
                        <div (click)="deleteImg(img)" class="delete-img"><img src="assets/images/delect.png"></div>
                    </div>
                    <div class="left img-list">
                        <label class="uploadImg-label" for="uploadDepartmentImg">
                            <img src="assets/images/add-img.png"><br />点击添加图片
                        </label>
                        <input class="uploadImg" id="uploadDepartmentImg" (change)="previewDepartmentPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                    </div>
                </div>

            </div>


            <!-- 保存 -->
            <div class="sign-submit" style="padding-bottom:40px ">
                <button class="purple" (click)="updateDepartment()">保存</button>
            </div>

            <div class="flex_c xieyiBox">
                <div (click)="xieyiClick(1)">《增值服务协议》</div>
                <div (click)="xieyiClick(2)">《用户协议》</div>
            </div>
        </div>
    </div>
</div>

<p-dialog id="aboutLaCoin" [contentStyle]="{'padding':'0',border:'0','border-radius':'6px'}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':300,'height':400,'minHeight':400}" [modal]="true"
    [showHeader]="false" [(visible)]="isShowQrcode">
    <div class="dialog_close_btn" style="top: 17px;right: 17px;" (click)="isShowQrcode = false"><img src="/assets/images/rights/contactMeClos.png"></div>
    <div class="d_f fd_c ai_c" style="width: 300px;height: 400px;background-color: white;overflow: hidden;" *ngIf="myDepartment?.miniprogram_qrcode">
        <img style="width: 198px;height: 198px;;margin-top: 56px" src="{{myDepartment.miniprogram_qrcode}}">
        <div class="c_p" style="padding-top: 28px;font-size: 14px;color: #3063FF;" (click)="downloadQrcode()">点击保存</div>
        <div style="width: 198px;color: #71858B;font-size: 12px;line-height: 16px;padding-top: 19px" class="text">1、把小程序码分享到学生社群，学生直接扫码投递</div>
        <div style="width: 198px;color: #71858B;font-size: 12px;line-height: 16px;" class="text">2、作为校招宣传，用于微信推文/朋友圈海报/展架物料等</div>
    </div>
</p-dialog>

<p-dialog [showHeader]="false" [(visible)]="ShowUpdateModel2" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px','width':'674px'}" [modal]=true>

    <div class="editContainer">
        <div class="clos" (click)="ShowUpdateModel2 = false"><img src="/assets/images/rights/contactMeClos.png"></div>
        <div class="header">修改信息</div>
        <div class="tip"><img src="/assets/images/v3/positionPushTip.png">修改成功后，以往申请过的校招、职位、招聘简章都将变更为待审核状态，需要联系高校重新审核</div>
        <div class="module">
            <div class="lable">公司全称<span>（必填）</span></div>
            <div class="val">
                <input placeholder="请输入公司全称" [(ngModel)]="updateComModel.department_name" maxlength="40" />
            </div>
        </div>
        <div class="module">
            <div class="lable">企业统一社会信用代码<span>（必填）</span></div>
            <div class="val">
                <input placeholder="请输入企业统一社会信用代码" [(ngModel)]="updateComModel.register_number" maxlength="18" />
            </div>
        </div>
        <div class="upBox">
            <div class="item">
                <div class="title">企业营业执照<span>（必填）</span></div>
                <div class="box">
                    <label>
                        <img class="pushImg" *ngIf="!updateComModel.business_licence_url" src="/assets/images/v3/pushposition.png">
                        <img class="_img" *ngIf="updateComModel.business_licence_url"  src={{updateComModel.business_licence_url}}>
                        <input class="uploadImg" id="uploadDepatmentLogoImg1" (change)="getPicData($event,'yingyezhizhao')" type="file" accept="image/jpeg,image/jpg,image/png">
                    </label>
                </div>
                <p>点击上传营业执照</p>
            </div>
            <div class="item">
                <div class="title">资质变更授权书<span>（必填）</span></div>
                <div class="box">
                    <label>
                        <img class="pushImg" *ngIf="!updateComModel.change_letter_url" src="/assets/images/v3/pushposition.png">
                        <img class="_img" *ngIf="updateComModel.change_letter_url"  src={{updateComModel.change_letter_url}}>
                        <input class="uploadImg" id="uploadDepatmentLogoImg2" (change)="getPicData($event,'biangeng')" type="file" accept="image/jpeg,image/jpg,image/png">
                    </label>
                </div>
                <p>请上传清晰的变更函图片</p>
                <p class="active"><a href="assets/资质变更授权书.docx" target="_Blank">点击下载《资质变更授权书》</a></p>
            </div>
        </div>
        <div class="module">
            <div class="lable">备注<span>（必填）</span></div>
            <div class="textarea">
                <textarea placeholder="请输入备注" [(ngModel)]="updateComModel.remark" maxlength="300"></textarea>
            </div>
        </div>
        <div class="serve blueStreamer" (click)="update('2')">修改</div>
    </div>

    <!-- <div class="updateCompanyName">
        <span class="updateCompanyNameSpan"><span style="color: red">*</span>公司全称:</span><input placeholder="请输入公司全称" [(ngModel)]="updateComModel.department_name" class="updateCompanyNameinput" maxlength="40" />
    </div>
    <div class="updateCompanyName">
        <span class="updateCompanyNameSpan"><span style="color: red">*</span>企业统一社会信用代码:</span><input placeholder="请输入企业统一社会信用代码" [(ngModel)]="updateComModel.register_number" class="updateCompanyNameinput" maxlength="18" />
    </div>
    <div class="updateCompanyNameH">
        <span class="updateCompanyNameSpan"><span style="color: red">*</span>企业营业执照:</span>
        <label class="uploadImg-label">
            <img *ngIf="!updateComModel.business_licence_url" style="width: 100px;height: 100px"
                src="assets/images/tadd.png">
            <img *ngIf="updateComModel.business_licence_url" style="width: 100px;height: 100px"
                src={{updateComModel.business_licence_url}}>
            <input class="uploadImg" id="uploadDepatmentLogoImg1" (change)="getPicData($event,'yingyezhizhao')"
                type="file" accept="image/jpeg,image/jpg,image/png">
        </label>
    </div>
    <div class="updateCompanyNameH">
        <span class="updateCompanyNameSpan"><span style="color: red">*</span>资质变更授权书:</span>
        <label class="uploadImg-label">
            <img *ngIf="!updateComModel.change_letter_url" style="width: 100px;height: 100px"
                src="assets/images/tadd.png">
            <img *ngIf="updateComModel.change_letter_url" style="width: 100px;height: 100px"
                src={{updateComModel.change_letter_url}}>
            <input class="uploadImg" id="uploadDepatmentLogoImg2" (change)="getPicData($event,'biangeng')" type="file"
                accept="image/jpeg,image/jpg,image/png">
            <span style="font-size: 14px">请上传清晰的变更函图片</span>
        </label>
    </div>
    <div class="updateCompanyName">
        <a style="color: red;text-decoration:underline;text-align: left;font-size: 15px;height: 40px;margin-left: 150px" href="assets/资质变更授权书.docx" target="_Blank">下载资质变更授权书</a>
    </div>
    <div class="updateCompanyNameH">
        <span class="updateCompanyNameSpan"><span style="color: red">*</span>备注:</span><textarea style="width: 300px;height: 100px" placeholder="请输入备注" [(ngModel)]="updateComModel.remark" class="updateCompanyNameinput" maxlength="300"></textarea>
    </div> -->

</p-dialog>

<p-dialog [closeOnEscape]="false" [(visible)]="ShowUpdateModel3" [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px','width':'674px'}" [style]="{'width':700,'height':500,'minHeight':500}" [modal]=true>
    <div class="editContainer _fn">
        <div class="clos" (click)="ShowUpdateModel3 = false"><img src="/assets/images/rights/contactMeClos.png"></div>
        <div class="header">修改信息</div>
        <div class="module">
            <div class="lable">公司简称<span>（必填）</span></div>
            <div class="val">
                <input placeholder="最多6个字" [maxlength]="6" [(ngModel)]="updateShortComModel.department_name" class="updateCompanyNameinput" maxlength="40" />
            </div>
        </div>
        <div class="upBox _fn">
            <div class="item">
                <div class="title">证明材料<span>（必填）</span></div>
                <div class="itenLitsFlex">
                    <div class="box" *ngFor="let n of updateShortComModel.imageList;let i = index;">
                        <label>
                        <img class="_img" *ngIf="n"  src={{n}}>                       
                    </label>
                    </div>
                    <div class="box">
                        <label>
                        <img class="pushImg" *ngIf="!updateComModel.business_licence_url" src="/assets/images/v3/pushposition.png">
                        <input class="uploadImg" id="uploadDepatmentLogoImg3" (change)="getPicData($event,'updateShortName')" type="file" accept="image/jpeg,image/jpg,image/png">
                    </label>
                    </div>
                </div>
                <p>请上传清晰的证明材料图片（最少一张）</p>
            </div>
        </div>
        <div class="module">
            <div class="lable">修改原因<span>（必填）</span></div>
            <div class="textarea">
                <textarea placeholder="请输入修改原因" [(ngModel)]="updateShortComModel.remark" maxlength="300"></textarea>
            </div>
        </div>
        <div class="serve blueStreamer" (click)="updateshortName()">修改</div>
    </div>


    <!-- <div class="updateCompanyName">
        <span class="updateCompanyNameSpan"><span style="color: red">*</span>公司简称:</span><input placeholder="最多6个字" [maxlength]="6" [(ngModel)]="updateShortComModel.department_name" class="updateCompanyNameinput" maxlength="40" />
    </div>
    <div class="updateCompanyNameH">
        <span class="updateCompanyNameSpan"><span style="color: red">*</span>证明材料:</span>
        <label class="uploadImg-label" style="margin-left: 5px;position: relative" *ngFor="let n of updateShortComModel.imageList;let i = index;">
            <img *ngIf="n" style="width: 100px;height: 100px" src={{n}}>
            <span class="deleteShortImage" (click)="deleteShortImage(i)">删除</span>
        </label>
        <label class="uploadImg-label" *ngIf="updateShortComModel.imageList.length<3">
            <img style="width: 100px;height: 100px" src="assets/images/tadd.png">
            <input class="uploadImg" id="uploadDepatmentLogoImg3" (change)="getPicData($event,'updateShortName')"
                type="file" accept="image/jpeg,image/jpg,image/png">
        </label>
    </div>
    <div style="padding-left: 175px;margin-top: 35px">请上传清晰的证明材料图片（最少一张）</div>
    <div class="updateCompanyNameH" style="margin-top: 15px">
        <span class="updateCompanyNameSpan">
            <span style="color: red">*</span>修改原因:</span>
            <textarea style="width: 300px;height: 100px" placeholder="请输入备注" [(ngModel)]="updateShortComModel.remark" class="updateCompanyNameinput" maxlength="300"></textarea>
    </div>
    <button class="layui-btn upodateBtn" (click)="updateshortName()">修改</button> -->
</p-dialog>


<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>

<!-- <p-dialog [width]="300" [height]="400" [minHeight]="400" [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="isShowVideoModel">
    <div class="isShowVideoModel">
        <div class="text">是否上传视频封面图？</div>
        <div class="btns">
            <label class="uploadImg-label" for="uploadDepartmentvideo">
                否
            </label>
            <label class="uploadImg-label" for="uploadDepartmentvideo">
                是
            </label>

            <input class="uploadImg"  placeholder="否" id="uploadDepartmentvideo" (change)="departmentVideo($event,true)" type="file" accept="video/*">
            <input class="uploadImg"  placeholder="是" (change)="departmentVideo($event,false)" type="file" accept="video/*">
        </div>
    </div>
</p-dialog> -->