import { Component, OnInit } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { Router } from  '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-batch-invitation',
  templateUrl: './batch-invitation.component.html',
  styleUrls: ['./batch-invitation.component.css']
})
export class BatchInvitationComponent implements OnInit {
  public config: Config;
  public posiModel = { // 在期职位model
    status: 'release',
    offset: 0,
    limit: 1000
  }
  public positionList = []; // 在期职位列表
  public model = { // 在期职位model
    positionid: '',
    collegeid:'',
    education:'', // 学历
    gender:'',    // 性别
    majors:[],
    send_count:0      // 发送数量 默认全发
  };

  public collegeList = []; // 在线高校列表
  public val = {  // 在线高校列表model
    offset: 0,
    is_online:true,
    limit: 1000
  };

  collegeMajor; // 推荐专业
  collegeMajors = [] // 推荐专业列表

  smsAccordNum=120;  // 符合条件的学生数量
  canUseSmsNum;  // 可以使用的活动通知包数量
  canSend:boolean = true;  // 能否使用活动通知包


  public majorList = [];
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  public majorOne='';//专业类别一级
  public majorTwo= ''; //专业类别一级

  constructor(
    public opera:Opera,
    private router: Router,
    public toastr: ToastrService,
    public dataApi: DataApi,
    public local:Local,
  ) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.getPositionList();  // 职位列表
    this.getCollegeList();   // 职位以上线高校
    this.getDeproductSurplus();  // 获取剩余包
  }

  // 获取在期职位列表
  getPositionList(){
    this.dataApi.getPositionList(this.posiModel).then((res:any)=>{
      console.log("获取在期职位列表-----");
      //console.log(res);
      this.positionList = res.list;
      this.model.positionid = res.list[0].positionid;
      console.log(this.positionList);
      //刷新职位列表时更新本地存储
      this.config.positionList= res.list;
      this.dataApi.setLocalpositionList(this.config.positionList);//在期职位列表本地存储
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  // 获取所有以上线的高校
  getCollegeList(){
    this.dataApi.getCollegeList(this.val).then((res:any) => {
      console.log("获取高校列表--------------------------------chooseCollegePage");
      console.log(res);
      this.collegeList = res.list;
      this.model.collegeid = res.list[0].collegeid;
      this.getMajorList();
    }).catch((err) => {
      console.log(err);
    })
  }

  // 获取活动通知包单品剩余量
  getDeproductSurplus(){
    this.dataApi.getDeproductSurplus('sms').then((res:any)=>{
      console.log('获取活动通知包单品剩余量');
      console.log(res);
      this.canUseSmsNum = res.value;
      this.canSendOut();
    }).catch((err)=>{
      console.log(err);
      this.canUseSmsNum = 0;
    })
  }

  // 判断能否通知
  canSendOut(){
    console.log(this.model.send_count);

    if(this.model.send_count > this.canUseSmsNum){
      this.canSend = false;
    }else{
      this.canSend = true;
    }
    if(!this.model.send_count){
      this.model.send_count = 0;
    }
  }

  // 删除专业
  majorClose(index){
    this.collegeMajors.splice(index,1);
    this.model.majors.splice(index,1);
    // this.smsDepartment();
  }

  // 添加专业
  majorAdd(){
    for(let n of this.majorListTwo){
      if(n.code==this.majorTwo){
        this.model.majors.push(n.code);
        this.collegeMajors.push(n.text);
      }
    }
    // this.smsDepartment();
  }


  // 切换高校
  switchCollege(){
    console.log(this.model.collegeid);
    this.getMajorList();
    this.model.majors = [];
    this.collegeMajors = [];
  }

  // 根据学校获取专业
  getMajorList(){
    this.dataApi.getDictMajor(this.model.collegeid).then((res:any)=>{
      console.log('取数据');
      this.majorList = res;
      this.getMajorListOne();
    }).catch((err)=>{
      console.log(err);
    })
  }

  //获取一级专业列表
  getMajorListOne(){
    this.majorListOne = [];
    for(let n of this.majorList){
      if(n.level==2){
        this.majorListOne.push(n);
      }
    }
    this.majorOneChange();
    // console.log(this.majorListOne);
  }

  //监听majorOne
  majorOneChange(){
    this.majorListTwo=[];
    this.majorTwo= '';  //专业类别一级
    for(let n of this.majorList){
      if(n.parentcode==this.majorOne){
        this.majorListTwo.push(n);
      }
    }
    // this.advanceModel.major='';
  }

  // 提示先选一级专业
  isShowTwo(){
    if(!this.majorOne){
      this.toastr.warning("请先选择一级专业！");
      return;
    }
  }

  loh(){
    alert('走接口')
  }
}
