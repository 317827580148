<!-- 页头部分 -->
<br>
<!-- 主体内容部分 -->

<div class="homepage-block">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-still-more #child1 [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-still-more>
    <app-model-select-more #child2 [isShow]="modelTwoShow" (onVoted)="ModelTwodismiss($event)"></app-model-select-more>
    <app-model-select-two #child3 [isShow]="modelThreeShow" (onVoted)="ModelThreedismiss($event)"></app-model-select-two>
    <app-model-position-template #child4 [isShow]="modelFourShow" (onVoted)="ModelFourdismiss($event)" (modelHidden)="ModelFourdismiss($event)"></app-model-position-template>

    <!-- V3.0 -->
    <div class="positionPush">
        <div class="headerReturn">
            <div class="center" (click)="opera.goNewRouter('position-manage')">
                <img src="/assets/images/v3/headerReturnIcon.png">{{publishPosition}}
            </div>
        </div>

        <div class="main">
            <div class="center">
                <div class="title">发布职位</div>
                <div class="topTip"><img src="/assets/images/v3/positionPushTip.png">因国家教育部规定，招聘详情中禁止出现“全日制”、“985”、“211”等学历歧视性关键字，若出现该职位将被处理，请知晓
                </div>
                <div class="icon">
                    <div class="item" *ngIf="model.post_nature=='05'"><img src="/assets/images/v3/positionPushIcon01.png">校招</div>
                    <div class="item" *ngIf="model.post_nature=='03'"><img src="/assets/images/v3/positionPushIcon02.png">实习</div>
                    <div class="item" *ngIf="model.post_nature=='06'"><img src="/assets/images/v3/positionPushIcon03.png">见习</div>
                    <div class="item" *ngIf="model.post_nature=='01'"><img src="/assets/images/v3/positionPushIcon04.png">社招</div>
                </div>

                <div class="formContainer">
                    <!--职位类别-->
                    <div class="module">
                        <div class="left">职位类别<span>（必填）</span></div>
                        <div class="right">
                            <div class="sanjiao"><img src="/assets/images/v3/cardDown.png"></div>
                            <div class="val fn" *ngIf="!model.post_type" (click)="goPositionType()">请选择选择职位类别</div>
                            <div class="val fn active" *ngIf="model.post_type" (click)="goPositionType()">
                                {{model.post_type | codetransform:config.post_type}}</div>
                            <div class="requiredText" *ngIf="!positionid">职位发布后职位类别不可修改</div>

                        </div>
                    </div>

                    <!--职位名称-->
                    <div class="module">
                        <div class="left">职位名称<span>（必填）</span></div>
                        <div class="right">
                            <div class="val">
                                <input *ngIf="!positionid" type="text" [(ngModel)]="model.name" maxlength="15" placeholder="请填写职位名称(15字以内)" (blur)="checkName(model.name)">
                                <input *ngIf="positionid" type="text" [(ngModel)]="model.name" disabled>
                            </div>
                            <div class="requiredText" *ngIf="!positionid">职位发布后职位名称不可修改</div>
                        </div>
                    </div>

                    <!--到期时间-->
                    <div class="module">
                        <div class="left">到期时间<span>（必填）</span></div>
                        <div class="right">
                            <div class="sanjiao"><img src="/assets/images/v3/cardDown.png"></div>
                            <div class="val">
                                <input type="text" [disabled]="!positionEndTime" placeholder="请选择职位到期时间" id="effect-time" [(ngModel)]="endTime" (change)="inputChangeSaveData()" />
                            </div>
                            <div class="requiredText" *ngIf="!positionid">职位到期时间最晚为{{positionEndTime | date:"yyyy-MM-dd"}}
                            </div>
                        </div>
                    </div>

                    <!--月薪范围-->
                    <div class="module" *ngIf="model.type !='06' && model.type !='03'">
                        <div class="left">月薪范围<span>（必填）</span></div>
                        <div class="right">
                            <div class="val">
                                <select class="activeBg" autocomplete="off" [(ngModel)]="model.position_salary" (ngModelChange)="inputChangeSaveData()">
                                    <option class="place-text" value='' disabled hidden selected="selected">
                                        选择月薪范围</option>
                                    <option *ngFor="let salary of salaryData02" [value]="salary.code">{{salary.text}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="module" *ngIf="model.type =='03'">
                        <div class="left">报酬范围<span>（必填）</span></div>
                        <div class="right">
                            <div class="val">
                                <select class="activeBg" autocomplete="off" [(ngModel)]="model.position_salary" (ngModelChange)="inputChangeSaveData()">
                                    <option class="place-text" value='' disabled hidden selected="selected">选择报酬范围
                                    </option>
                                    <option *ngFor="let salary of salaryData" [value]="salary.code">{{salary.text}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!--生活补助-->
                    <div class="module" *ngIf="model.type =='06'">
                        <div class="left">生活补助<span>（必填）</span></div>
                        <div class="right">
                            <div class="val">
                                <select class="activeBg" autocomplete="off" [(ngModel)]="model.position_salary" (ngModelChange)="inputChangeSaveData()">
                                    <option class="place-text" value='' disabled hidden selected="selected">选择生活补助
                                    </option>
                                    <option *ngFor="let salary of salaryData" [value]="salary.code">{{salary.text}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!--实习时间-->
                    <div class="module" *ngIf="model.type =='03'">
                        <div class="left">实习时间<span>（必填）</span></div>
                        <div class="right">
                            <div class="val">
                                <input type="number" [(ngModel)]="model.month_count" (ngModelChange)="inputChangeSaveData()">
                                <div class="danwei">个月</div>
                            </div>
                        </div>
                    </div>

                    <!--见习月数-->
                    <div class="module" *ngIf="model.type =='06'">
                        <div class="left">见习月数<span>（必填）</span></div>
                        <div class="right">
                            <div class="val">
                                <input type="number" [(ngModel)]="model.month_count" (ngModelChange)="inputChangeSaveData()">
                                <div class="danwei">个月</div>
                            </div>
                        </div>
                    </div>

                    <!--工作年限-->
                    <div class="module" *ngIf="model.type =='01'">
                        <div class="left">工作年限<span>（必填）</span></div>
                        <div class="right">
                            <div class="val">
                                <select class="activeBg" autocomplete="off" [(ngModel)]="model.work_experience" (ngModelChange)="inputChangeSaveData()">
                                    <option class="place-text" value='' disabled hidden selected="selected">选择工作年限
                                    </option>
                                    <option *ngFor="let salary of work_experienceArr" [value]="salary.code">
                                        {{salary.text}}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!--学历要求-->
                    <div class="module">
                        <div class="left">学历要求<span>（必填）</span></div>
                        <div class="right">
                            <div class="val">
                                <select class="activeBg" autocomplete="off" [(ngModel)]="model.education" (ngModelChange)="inputChangeSaveData()">
                                    <option class="place-text" value='' disabled hidden selected="selected">选择学历要求
                                    </option>
                                    <option *ngFor="let edu of config.education | excludeEducation:'01'" [value]="edu.code">{{edu.text}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!--工作城市-->
                    <div class="module">
                        <div class="left">工作城市<span>（必填）</span></div>
                        <div class="right">
                            <div class="sanjiao"><img src="/assets/images/v3/cardDown.png"></div>
                            <div class="val fn" *ngIf="!model.location.region" (click)="goCityCode()">请选择选择工作城市</div>
                            <div class="val fn active" *ngIf="model.location.region" (click)="goCityCode()">
                                {{model.location.region | codetransform:config.region}}</div>
                        </div>
                    </div>

                    <!--接收人数-->
                    <div class="module" *ngIf="model.type=='03'">
                        <div class="left">接收人数<span>（必填）</span></div>
                        <div class="right">
                            <div class="val">
                                <input type="number" [(ngModel)]="model.intent_count" (blur)="recruits()" oninput="if(value.length>4)value=value.slice(0,4)" placeholder="请填写接收人数(接收人数小于10000人)">
                            </div>
                        </div>
                    </div>

                    <!--拟接收人数-->
                    <div class="module" *ngIf="model.type=='06'">
                        <div class="left">拟接收人数<span>（必填）</span></div>
                        <div class="right">
                            <div class="val">
                                <input type="number" [(ngModel)]="model.intent_count" (blur)="recruits()" oninput="if(value.length>4)value=value.slice(0,4)" placeholder="请填写拟接收人数(拟接收人数小于10000人)">
                            </div>
                        </div>
                    </div>

                    <!--招聘人数-->
                    <div class="module" *ngIf="model.type!='06'&& model.type!='03'">
                        <div class="left">招聘人数<span>（必填）</span></div>
                        <div class="right">
                            <div class="val">
                                <input type="number" [(ngModel)]="model.intent_count" (blur)="recruits()" oninput="if(value.length>4)value=value.slice(0,4)" placeholder="请填写招聘人数(招聘人数小于10000人)">
                            </div>
                        </div>
                    </div>

                    <!--单位地址-->
                    <div class="module" *ngIf="model.type=='06'">
                        <div class="left">单位地址<span>（必填）</span></div>
                        <div class="right">
                            <div class="val">
                                <input [(ngModel)]="model.work_address" placeholder="请填写单位地址" (ngModelChange)="inputChangeSaveData()">
                            </div>
                        </div>
                    </div>

                    <!--专业要求-->
                    <div class="module major">
                        <div class="left">专业要求<span>（必填）</span></div>
                        <div class="right">
                            <div class="item" *ngFor="let m of model.majors">{{m | codetransform:config.major}}</div>
                            <div class="push" (click)="goMajor()"><img src="/assets/images/v3/pushposition.png">添加</div>
                        </div>
                    </div>

                    <!--技能标签-->
                    <div class="module major">
                        <div class="left">技能标签<span>（必填）</span></div>
                        <div class="right">
                            <div class="item" *ngFor="let m of model.skills">{{m | codetransform:config.skill}}</div>
                            <div class="push" (click)="goSkill()"><img src="/assets/images/v3/pushposition.png">添加</div>
                        </div>
                    </div>

                    <!--职位描述-->
                    <div class="module describe">
                        <div class="left">
                            <div>{{model.type=='06'?"见习内容":"职位描述"}}<span>（必填）</span></div>
                            <div class="look" (click)="goTemplate()">查看模版</div>
                        </div>
                        <div class="right">
                            <div class="textarea">
                                <textarea name="responsibility" id="responsibility" [(ngModel)]="model.responsibility" maxlength="1000" (ngModelChange)="inputChangeSaveData()" placeholder="请填写工作内容和要求（1000字以内）"></textarea>
                            </div>
                        </div>
                    </div>

                    <!--晋升路径-->
                    <div class="promotion">
                        <div class="left">晋升路径</div>
                        <div class="valList">
                            <div class="item" *ngFor="let p of path;let i =index;">
                                <div class="lab">路径 {{i+1}}</div>
                                <div class="input">
                                    <input style="width: 346px" type="text" placeholder="请填写职位名称(15字以内)" maxlength="15" [(ngModel)]="p.text" (ngModelChange)="inputChangeSaveData()">
                                    <img class="delete" *ngIf="i!=0" (click)="deletePath(p,i)" src="/assets/images/v3/delete_line.png" />
                                </div>
                            </div>
                            <div class="addPromotion" (click)="addPosition()"><img src="/assets/images/v3/pushposition.png">添加晋升路径</div>
                        </div>

                    </div>

                    <!-- 提交 -->
                    <div class="module" *ngIf="!positionid">
                        <div class="left"></div>
                        <div class="right">
                            <div class="release blueStreamer" (click)="createPosition()">发布</div>
                        </div>
                    </div>
                    <div class="module" *ngIf="position">
                        <div class="left"></div>
                        <div class="right">
                            <div class="btnsBox">
                                <div class="submit blueStreamer" (click)="createPosition()">
                                    {{position.position_status=='stop'?'确定':'发布'}}</div>
                                <div class="submit bordeRadius" style="background: lightgray" (click)="gotoBack()">取消
                                </div>
                                <div class="submit bordeRadius" style="background: #f4c42c" *ngIf="position.position_status=='expired'" (click)="goResend()">重发</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="college-block" style="display: none;">
        <!-- 页头部分 -->
        <div class="college-block-head goBackHeader" style="border-bottom: none"><img class="goBack" (click)="opera.goNewRouter('position-manage')" src='assets/images/router-old.png' />{{publishPosition}}
        </div>
        <!-- 填写部分 -->
        <div class="sign-block">

            <div class="education_chips">
                <img src="../../assets/images/guide-false.png" />
                <span>因国家教育部规定，招聘详情中禁止出现“全日制”、“985”、“211”等学历歧视性关键字，若出现该职位将被处理，请知晓</span>
            </div>

            <div class="sign-list">
                <span>工作性质：</span>
                <div class="selected-text disabled-text" *ngIf="model.post_nature=='01'">社招</div>
                <div class="selected-text disabled-text" *ngIf="model.post_nature=='03'">实习</div>
                <div class="selected-text disabled-text" *ngIf="model.post_nature=='05'">校招</div>
                <div class="selected-text disabled-text" *ngIf="model.post_nature=='06'">见习</div>
            </div>

            <!-- 职位类别 -->
            <div class="sign-list flex_l" *ngIf="!positionid">
                <span>职位类别：</span>
                <div *ngIf="!model.post_type" (click)="goPositionType()" class="placholder-text">请选择选择职位类别</div>
                <div *ngIf="model.post_type" class="selected-text" (click)="goPositionType()">{{model.post_type | codetransform:config.post_type}}
                </div>
                <b *ngIf="isSend&&!model.post_type"><img src="assets/images/guide-false.png"
                        alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <div class="remark-text" *ngIf="!positionid">*职位发布后职位类别不可修改</div>

            <div class="sign-list" *ngIf="positionid">
                <span>职位类别：</span>
                <div class="selected-text disabled-text">{{model.post_type | codetransform:config.post_type}}</div>
            </div>
            <div class="remark-text" *ngIf="positionid">*职位发布后职位类别不可修改</div>

            <!-- 职位名称 -->
            <div class="sign-list">
                <span>职位名称：</span>
                <input *ngIf="!positionid" type="text" [(ngModel)]="model.name" maxlength="15" placeholder="请填写职位名称(15字以内)" (blur)="checkName(model.name)">
                <input *ngIf="positionid" type="text" [(ngModel)]="model.name" disabled><img src="../../assets/images/position/pul_edit.png" *ngIf="!positionid" (click)="modifyName()" class="calendar-img" />
                <b *ngIf="isSend&&!model.name"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <div class="remark-text">*职位发布后职位名称不可修改</div>

            <!-- 到期时间 -->
            <div class="sign-list">
                <span>到期时间：</span><input type="text" [disabled]="!positionEndTime" placeholder="请选择职位到期时间" id="effect-time" [(ngModel)]="endTime" (change)="inputChangeSaveData()" />
                <b *ngIf="isSend&&!endTime"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <div *ngIf="publishPosition=='发布职位' && positionEndTime" class="remark-text">*职位到期时间最晚为{{positionEndTime | date:"yyyy-MM-dd"}}
            </div>

            <!-- 工作性质 -->
            <!--<div class="sign-list select-list">-->
            <!--<span>工作性质：</span>-->
            <!--<select autocomplete="off" [(ngModel)]="model.post_nature">-->
            <!--<option class="place-text" value='' disabled hidden selected="selected">选择工作性质</option>-->
            <!--<option value="01">全职</option>-->
            <!--<option value="03">实习</option>-->
            <!--</select>-->
            <!--</div>-->

            <!-- 月薪范围 -->
            <div class="sign-list select-list" *ngIf="model.type !='06'">
                <span>{{model.type=='03'?'报酬范围':"月薪范围"}}：</span>
                <select autocomplete="off" [(ngModel)]="model.position_salary" (ngModelChange)="inputChangeSaveData()">
                    <option class="place-text" value='' disabled hidden selected="selected">
                        选择{{model.type=='03'?'报酬范围':"月薪范围"}}</option>
                    <option *ngFor="let salary of salaryData" [value]="salary.code">{{salary.text}}</option>
                </select>
                <b *ngIf="isSend&&!model.position_salary"><img src="assets/images/guide-false.png"
                        alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>

            <div class="sign-list select-list" *ngIf="model.type =='06'">
                <span>生活补助：</span>
                <select autocomplete="off" [(ngModel)]="model.position_salary" (ngModelChange)="inputChangeSaveData()">
                    <option class="place-text" value='' disabled hidden selected="selected">选择生活补助</option>
                    <option *ngFor="let salary of salaryData" [value]="salary.code">{{salary.text}}</option>
                </select>
                <b *ngIf="isSend&&!model.position_salary"><img src="assets/images/guide-false.png"
                        alt="必填项提示">&nbsp;&nbsp;必填项</b>

            </div>

            <div class="sign-list select-list" *ngIf="model.type =='03'">
                <span>实习时间：</span><input type="number" [(ngModel)]="model.month_count" style="width: 365px;" class="inputRspace" (ngModelChange)="inputChangeSaveData()">个月
                <b *ngIf="isSend&&!model.month_count"><img src="assets/images/guide-false.png"
                        alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>

            <div class="sign-list select-list" *ngIf="model.type =='06'">
                <span>见习月数：</span><input type="number" [(ngModel)]="model.month_count" style="width: 365px;" class="inputRspace" (ngModelChange)="inputChangeSaveData()">个月
                <b *ngIf="isSend&&(!model.month_count||model.month_count=='0' )"><img
                        src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>

            <div class="sign-list select-list" *ngIf="model.type =='01'">
                <span>工作年限：</span>
                <select autocomplete="off" [(ngModel)]="model.work_experience" (ngModelChange)="inputChangeSaveData()">
                    <option class="place-text" value='' disabled hidden selected="selected">选择工作年限</option>
                    <option *ngFor="let salary of work_experienceArr" [value]="salary.code">{{salary.text}}</option>
                </select>
                <b *ngIf="isSend&&!model.work_experience"><img src="assets/images/guide-false.png"
                        alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>

            <!-- 学历要求 -->
            <div class="sign-list select-list">
                <span>学历要求：</span>
                <select autocomplete="off" [(ngModel)]="model.education" (ngModelChange)="inputChangeSaveData()">
                    <option class="place-text" value='' disabled hidden selected="selected">选择学历要求</option>
                    <option *ngFor="let edu of config.education | excludeEducation:'01'" [value]="edu.code">{{edu.text}}</option>
                </select>
                <b *ngIf="isSend&&!model.education"><img src="assets/images/guide-false.png"
                        alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>

            <!-- 工作城市 -->
            <div class="sign-list">
                <span>工作城市：</span>
                <div *ngIf="!model.location.region" (click)="goCityCode()" class="placholder-text">请选择选择工作城市</div>
                <div *ngIf="model.location.region" (click)="goCityCode()" class="selected-text">{{model.location.region | codetransform:config.region}}</div>
                <b *ngIf="isSend&&!model.location.region"><img src="assets/images/guide-false.png"
                        alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 招聘人数 -->
            <div class="sign-list" *ngIf="model.type=='03'">
                <span>接收人数：</span><input type="number" [(ngModel)]="model.intent_count" (blur)="recruits()" oninput="if(value.length>4)value=value.slice(0,4)" placeholder="请填写接收人数(接收人数小于10000人)">
                <b *ngIf="isSend&&!model.intent_count"><img src="assets/images/guide-false.png"
                        alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <div class="sign-list" *ngIf="model.type=='06'">
                <span>拟接收人数：</span><input type="number" [(ngModel)]="model.intent_count" (blur)="recruits()" oninput="if(value.length>4)value=value.slice(0,4)" placeholder="请填写拟接收人数(拟接收人数小于10000人)">
                <b *ngIf="isSend&&!model.intent_count"><img src="assets/images/guide-false.png"
                        alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <div class="sign-list" *ngIf="model.type!='06'&& model.type!='03'">
                <span>招聘人数：</span><input type="number" [(ngModel)]="model.intent_count" (blur)="recruits()" oninput="if(value.length>4)value=value.slice(0,4)" placeholder="请填写招聘人数(招聘人数小于10000人)">
                <b *ngIf="isSend&&!model.intent_count"><img src="assets/images/guide-false.png"
                        alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>

            <!--//单位地址-->
            <div class="sign-list" *ngIf="model.type=='06'">
                <span>单位地址：</span><input [(ngModel)]="model.work_address" placeholder="请填写单位地址" (ngModelChange)="inputChangeSaveData()">
                <b *ngIf="isSend&&!model.work_address"><img src="assets/images/guide-false.png"
                        alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>

            <!-- 专业要求 -->
            <div class="sign-list" style="display: flex">
                <span>专业要求：</span>
                <input *ngIf="model.majors.length==0" type="text" placeholder="请选择专业" />
                <img *ngIf="model.majors.length==0" class="calendar-img" (click)="goMajor()" src="../../assets/images/position/plus-circle.png" alt="添加专业" />
                <!--            <div class="label-list clear" *ngIf="model.majors.length==0">
                <div class="label-item purple">
                    <div class="label-text">不限</div>
                </div>
                <div class="label-item label-click"  (click)="goMajor()">
                    <div class="label-text">+添加</div>
                </div>
            </div>-->
                <div *ngIf="model.majors.length>0" class="selected-text1">
                    <div class="selected-text-item" *ngFor="let m of model.majors" (click)="goMajor()">{{m | codetransform:config.major}} X</div>
                </div>
                <!--            <div class="label-list clear" *ngIf="model.majors.length>0">
                <div class="label-item purple" *ngFor="let m of model.majors">
                   <div class="label-text">{{m | codetransform:config.major}}</div>
                </div>
                <div class="label-item label-click"  (click)="goMajor()">
                    <div class="label-text">+添加</div>
                </div>
            </div> -->
                <!-- <div *ngIf="model.majors.length==0" class="placholder-text">不限</div>
            <div *ngIf="model.majors.length>0" class="selected-text"><span *ngFor="let m of model.majors">{{m | codetransform:config.major}}&nbsp;&nbsp;</span></div> -->
                <b *ngIf="isSend&&model.majors.length==0" style="margin-top: 5px;"><img
                        src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>

            </div>
            <!--技能标签 -->
            <div class="sign-list" style="display: flex">
                <span>技能标签：</span>
                <input *ngIf="model.skills.length==0" type="text" placeholder="不限" /><img *ngIf="model.skills.length==0" class="calendar-img" (click)="goSkill()" src="../../assets/images/position/plus-circle.png" alt="添加标签" />
                <div *ngIf="model.skills.length>0" class="selected-text1">
                    <div class="selected-text-item" *ngFor="let m of model.skills" (click)="goSkill()">{{m | codetransform:config.skill}} X</div>
                </div>
            </div>
            <div class="remark-text" style="margin-left: 290px" *ngIf="isWidthSkill">*根据所选职位类别已为您设置技能标签，点击添加按钮可修改</div>
            <!-- 职位描述 -->
            <div class="sign-list sign-intro">
                <span>{{model.type=='06'?"见习内容":"职位描述"}}：</span>
                <textarea name="responsibility" id="responsibility" [(ngModel)]="model.responsibility" maxlength="1000" (ngModelChange)="inputChangeSaveData()" placeholder="请填写工作内容和要求（1000字以内）">
            </textarea>
                <div class="view-modle" (click)="goTemplate()"><img src="../../assets/images/position/file_search.png" />查看模板</div>
                <b *ngIf="isSend&&!model.responsibility" style="margin-left: 60px;margin-top: -20px;"><img
                        src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>

            </div>
            <!-- 晋升路径 -->
            <div class="sign-list sign-path">
                <span>晋升路径(选填)<i></i>：</span>
                <div class="path-block">
                    <div class="path-list" *ngFor="let p of path;let i =index;">
                        <span>路径{{i+1}}：</span>
                        <input style="width: 346px" type="text" placeholder="请填写职位名称(15字以内)" maxlength="15" [(ngModel)]="p.text" (ngModelChange)="inputChangeSaveData()">
                        <img class="path-delete" *ngIf="i!=0" (click)="deletePath(p,i)" src="../../assets/images/delete_line.png" />
                    </div>
                    <div class="path-add" (click)="addPosition()"><img src="../../assets/images/position/plus-circle.png">增加</div>
                </div>
            </div>
            <!-- 提交 -->
            <div class="" style="padding: 20px 0" *ngIf="!positionid">
                <div class="sign-submit1 bordeRadius" (click)="createPosition()">发布</div>
            </div>
            <div style="padding: 20px 0;display: flex;width: 360px;margin: 0 auto;justify-content: space-between" *ngIf="position">
                <div class="sign-submit1 bordeRadius" (click)="createPosition()">
                    {{position.position_status=='stop'?'确定':'发布'}}</div>
                <div class="sign-submit1 bordeRadius" style="background: lightgray" (click)="gotoBack()">取消</div>
                <div class="sign-submit1 bordeRadius" style="background: #f4c42c" *ngIf="position.position_status=='expired'" (click)="goResend()">重发</div>
            </div>
        </div>
    </div>

</div>

<!-- 返回上一页 -->


<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px'}" [(visible)]="isShowPublishModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':582,'height':288,'minHeight':288}"
    [modal]=true>
    <div class="alertPhone_Bg">
        <div class="title"><img src="/assets/images/position/suc_icon.png" />发布成功</div>
        <div class="tip">提升曝光,及时影响更多活跃人才</div>
        <div (click)="publishSuc()" class="btn blueStreamer">立即职位刷新</div>
    </div>
</p-dialog>

<p-dialog [closeOnEscape]="false" [showHeader]="false" [dismissableMask]="false" [contentStyle]="{'padding':'0',border:0,'border-radius':'10px'}" [(visible)]="showSeleteType" [modal]=true>
    <div class="selteTypeModel">
        <div class="center">
            <div class="selteTypeModelTitle flex_s">
                <div class="titleName">
                    <span>发布职位</span>
                    <p>请选择发布职位的工作性质</p>
                </div>
                <img (click)="closeSeleteType()" src="/assets/images/rights/contactMeClos.png" />
            </div>
            <div class="flex_s selteTypeView">
                <div [ngClass]="{'selteTypeDefault':model.type=='05','selteTypeDefault_none':model.type!='05'}" (click)="seletePositonType('05','05')">
                    <img src="/assets/images/v3/positionPush01.png">
                    <p class="te">校招</p>
                </div>
                <div [ngClass]="{'selteTypeDefault':model.type=='03','selteTypeDefault_none':model.type!='03'}" (click)="seletePositonType('03','03')">
                    <img src="/assets/images/v3/positionPush02.png">
                    <p class="te">实习</p>
                </div>
                <div [ngClass]="{'selteTypeDefault':model.type=='06','selteTypeDefault_none':model.type!='06'}" (click)="seletePositonType('06','06')">
                    <img src="/assets/images/v3/positionPush03.png">
                    <p class="te">见习</p>
                    <p class="state" *ngIf="probationApply.stateText">{{probationApply.stateText}}</p>
                </div>
                <div [ngClass]="{'selteTypeDefault':model.type=='01','selteTypeDefault_none':model.type!='01'}" (click)="seletePositonType('01','01')">
                    <img src="/assets/images/v3/positionPush04.png">
                    <p class="te">社招</p>
                </div>
            </div>
            <div class="comitselteTypeModel blueStreamer" (click)="seletePositonTypeCommit()">确定</div>
        </div>

        <!--<div class="comitselteTypeModel_chips" *ngIf="model.type == '05'">校招面向的是2020-2022年毕业的大学生</div>-->
        <!--<div class="comitselteTypeModel_chips" *ngIf="model.type == '03'">实习面向的是2021年毕业的在校大学生</div>-->
        <!--<div class="comitselteTypeModel_chips" *ngIf="model.type == '01'">社招面向的是2020年前毕业的大学生，社招岗位不对应届生展示</div>-->
    </div>
</p-dialog>

<p-dialog [closeOnEscape]="false" [showHeader]="false" [dismissableMask]="false" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px','width':'674px'}" [(visible)]="showjianxiModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':572,'height':350,'minHeight':350}"
    [modal]=true>
    <div class="jianxiModel">
        <div class="selteTypeModelTitle flex_s"><span>请上传您经过社保局认可的见习资质</span>
            <img (click)="closejianxiModel()" src="/assets/images/rights/contactMeClos.png" />
        </div>
        <div class="upaloadErr" *ngIf="probationApply.remark">拒绝原因：{{probationApply.remark}}</div>
        <div class="flex_l uploadCell">
            <div class="uploadTitle">确认文件：</div>
            <div>
                <label class="uploadImg-label" for="uploadMilkImg">
                    <img style="width: 100px;height: 100px" *ngIf="!uploadApply.file"
                        src="../../assets/images/tadd.png" />
                    <!--<br/>点击添加图片-->
                    <div style="width: 400px;cursor: pointer;word-break: break-all" *ngIf="uploadApply.file">
                        {{uploadApply.file}}</div>
                </label>
                <input class="uploadImg" id="uploadMilkImg" (change)="previewMilkPic($event)" type="file" accept="application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
            </div>
        </div>
        <div class="flex_l uploadCell">
            <div class="uploadTitle">上传公示链接：</div>
            <input [(ngModel)]="uploadApply.link" class="uploadLink" placeholder="请填写公示链接" />
        </div>
        <p class="uploadChips">公示链接：可查询见习企业名单的政府公示网站链接</p>
        <div class="serve blueStreamer" (click)="applyJianxi()">提交申请</div>
    </div>
</p-dialog>

<p-dialog [closeOnEscape]="false" [showHeader]="false" [dismissableMask]="false" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px'}" [(visible)]="isShowLocalPosition" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':429,'height':200,'minHeight':200}"
    [modal]=true>
    <div class="modal-content localPostion">
        <div class="localPostion_title">您草稿箱中有编辑中的职位，是否使用草稿
        </div>
        <div class="flex_c localPostionBtn">
            <div class="blueStreamer" (click)="userLocalData(true)">使用草稿</div>
            <div class="blueStreamer" (click)="userLocalData(false)">重新填写</div>
        </div>
    </div>
</p-dialog>