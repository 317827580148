import { Component, OnInit } from '@angular/core';
import {DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
declare let window;
@Component({
  selector: 'app-account-logout-history',
  templateUrl: './account-logout-history.component.html',
  styleUrls: ['./account-logout-history.component.css']
})
export class AccountLogoutHistoryComponent implements OnInit {

  public zhuxiaoList = [];
  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService
  ) { }
  ngOnInit() {
    this.getList();
    window.scrollTo(0, 0);
  }
  //获取注销历史
  getList(){
    this.dataApi.getAccountlogoutHistoryList().then((res:any)=>{
      if(res) {
        this.zhuxiaoList = res;
      }
    }).catch((err)=>{
      console.log(err);
    })
  }
  getState(state){
    if(state=="to_audit") {
      return '审核中';
    } else if(state=="no_apply") {
      return '未申请';
    } else if(state=="passed") {
      return '注销成功';
    } else if(state=="not_passed") {
      return '注销失败';
    } else if(state=="no_cooperation") {
      return '未合作';
    } else if(state=="black") {
      return '拉黑';
    }
  }
}
