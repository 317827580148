import { Component, OnInit } from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {Config, Constants, DataApi, Local, Opera} from "../../provider";
import {ConfirmComponent} from "../confirm/confirm.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-find-account',
  templateUrl: './find-account.component.html',
  styleUrls: ['./find-account.component.css']
})
export class FindAccountComponent implements OnInit {

  public config: Config;
  public isLogin = false

  constructor(
    public toastr: ToastrService,
    private router: Router,
    public dataApi: DataApi,
    public local:Local,
    public dialog: MatDialog,
    public opera:Opera,

  ) {
    this.config = Config.getInstance();//实例化config

  }

  ngOnInit() {
    let token =  localStorage.getItem(Constants.accesstoken)
    if(token){
      this.isLogin = true;
    }

    setTimeout(()=>{
      this.config.isNavActive = 'login'

    },200)

  }
  //退出
  goLogin(){
    if(!this.isLogin){
      this.setRoot();
      return
    }
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '您是否要退出登录？',
        trueText:'确定',
        falseText:'取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      if(isConfirmed){
        this.setRoot();//不重新登录无法接收消息，踢到登录页
      }
    });
  }

  //接收错误信息返回登录
  setRoot(){
    this.dataApi.logout();
    this.router.navigateByUrl("login");
  }
  goBack(){
    if(this.isLogin){
      this.router.navigateByUrl("guide");
    }else {
      this.router.navigateByUrl("app-find-psw");

    }
  }

}
