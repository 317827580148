<div class="card_guwen flex_s" *ngIf="config.departmentSelf">
  <div class="card_guwen_l">
    <img class="card_icon" src="{{config.departmentSelf.avatar}}">
    <div class="card_title1">工作啦校招顾问</div>
    <div class="card_title2">{{config.departmentSelf.name}}</div>
    <div class="card_title3 flex_l" style="margin-top: 19px"><img src="../../assets/images/kefu_wx.png">微信：{{config.departmentSelf.wx}}</div>
    <div class="card_title3 flex_l"><img src="../../assets/images/kefu_email.png">邮箱：{{config.departmentSelf.email}}</div>
  </div>
  <img class="card_guwen_r" src="{{config.departmentSelf.qr_code}}">
</div>
