<!-- 引导页面-->
<!--<div class="contentRight-header" (click)="goBackStep()"><img  src='assets/images/router-old.png'/>返回</div>-->
<!--<div class="contentRight-header" *ngIf="auditStatus == 2" (click)="goBackStep()"><img  src='assets/images/router-old.png'/>返回首页</div>-->

<div class="guide-page">
    <router-outlet></router-outlet>
    <div class="info-block" style="width: 880px">
        <div *ngIf="auditStatus == 1" id="step3" class="step3_content">
            <img class="step3_icon" src="../../assets/images/shenhe.png">
            <p class="step3_title1">认证中...</p>
            <p class="step3_title2">预计1-3个工作日</p>
            <div class="card_guwen flex_s" *ngIf="config.departmentSelf">
                <div class="card_guwen_l">
                    <img class="card_icon" src="{{config.departmentSelf.avatar}}">
                    <div class="card_title1">工作啦校招顾问</div>
                    <div class="card_title2">{{config.departmentSelf.name}}</div>
                    <div class="card_title3 flex_l" style="margin-top: 19px"><img src="../../assets/images/kefu_wx.png">微信：{{config.departmentSelf.wx}}</div>
                    <div class="card_title3 flex_l"><img src="../../assets/images/kefu_email.png">邮箱：{{config.departmentSelf.email}}</div>
                </div>
                <img class="card_guwen_r" src="{{config.departmentSelf.qr_code}}">
            </div>
        </div>
        <div *ngIf="auditStatus == 2" id="step4" class="step3_content">
            <img class="step3_icon" src="../../assets/images/auth_user.png">
            <p class="step3_title1" style="font-size: 16px">恭喜您通过「工作啦」认证</p>
            <p class="step3_title2" style="margin-top: 14px;color: #666666;font-size: 14px;cursor: pointer">
                「工作啦」校招之旅，一站预约全网高校双选会、宣讲会，一站直面超70万毕业生简历。</p>
            <p class="step3_title2" style="margin-top: 14px;color: #666666;font-size: 14px;cursor: pointer" (click)="goBackStep()"><span style="padding: 5px 20px;text-align: center;background-color: #5E4FFF;color: white">点击开启</span></p>

            <p class="step3_title2" style="margin-top: 30px;color: #CCCCCC;font-size: 14px">
                如有任何问题，欢迎咨询「工作啦」VIP客服，我们将竭诚为您服务！
            </p>
            <div class="card_guwen flex_s" style="margin-top: 8px;margin: 8px auto" *ngIf="config.departmentSelf">
                <div class="card_guwen_l">
                    <img class="card_icon" src="{{config.departmentSelf.avatar}}">
                    <div class="card_title1">工作啦校招顾问</div>
                    <div class="card_title2">{{config.departmentSelf.name}}</div>
                    <div class="card_title3 flex_l" style="margin-top: 19px"><img src="../../assets/images/kefu_wx.png">微信：{{config.departmentSelf.wx}}</div>
                    <div class="card_title3 flex_l"><img src="../../assets/images/kefu_email.png">邮箱：{{config.departmentSelf.email}}</div>
                </div>
                <img class="card_guwen_r" src="{{config.departmentSelf.qr_code}}">
            </div>
        </div>

        <!-- 新版 -->
        <div id="step2" *ngIf="auditStatus == 0 ||auditStatus == 3||auditStatus == 4" class="step2_content step2_content_new">
            <div class="step2_title">
                用户认证
            </div>
            <div class="marked_words">
                为了保障大学生求职安全，您必须完成工作啦认证，方可在平台进行招聘。 请确保提交资料的准确性，否则将影响您的审核结果。</div>
            <div class="d_f ai_fs no_pass_box" *ngIf="isShowFailModel&&config.lastIdentity&&config.lastIdentity.status==3">
                <img class="no_pass_icon" src="/assets/images/v3/positionPushTip.png" />
                <div>
                    <div class="no_pass_title">审核未通过</div>
                    <div class="no_pass_cause">未通过原因：<span *ngFor="let n of config.lastIdentity.remarks;let i = index;">{{i?',':''}}{{n}}</span></div>
                    <div class="no_pass_explain">如果您在认证中遇到问题，可致电：4006611396</div>
                </div>
            </div>
            <div class="step_cell flex_l marginTop40">
                <span class="step_cell_title">公司名称</span>
                <input class="step_cell_input" [(ngModel)]="auth_model.department_name" (ngModelChange)="department_nameChange($event)" placeholder="填写公司名称">
            </div>

            <div class="chip_cell">请确保名称与营业执照保持一致</div>

            <div class="step_cell flex_l marginTop24">
                <span class="step_cell_title">单位类型</span>
                <select class="activeBg _step_cell_selete" [(ngModel)]="auth_model.organization_type" [disabled]="auth_model.department_name.indexOf('保险')>=0" (ngModelChange)="department_organizationChange($event)">
                    <option class="place-text" value='' disabled selected="selected">选择单位类型</option>
                    <option *ngFor="let type of config.organization_type" [value]="type.code">{{type.text}}</option>
                </select>
            </div>
            <a *ngIf="auth_model.organization_type=='99'" style="color: #5E4FFF;margin-top: 15px; margin-left: 210px" href="../../assets/保险行业招聘委托书.docx">下载《保险行业招聘委托书》</a>

            <div *ngIf="material_current">

                <div *ngIf="materail">
                    <div *ngFor="let list of materail">
                        <div *ngIf="list.item_code== auth_model.organization_type">
                            <div *ngFor="let num of list.field_infos">

                                <div class="step_cell flex_l marginTop24" *ngIf="num.field=='register_number'&& num.required">
                                    <span class="step_cell_title">{{num.name}}：</span>
                                    <input class="step_cell_input" [(ngModel)]="auth_model.register_number" placeholder="请输入{{num.name}}">
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div *ngFor="let m of material_current.material_infos;let num=index">
                    <div class="step_cell_long flex_l marginTop24" style="position: relative;height: 290px;">
                        <!-- <span class="step_cell_title_long" *ngIf="num==0">单位资质上传：</span> -->
                        <div class="step_cell_title_long_new">
                            {{m.material_name}}上传
                            <!-- <span *ngIf="m.material_url" (click)="downfile(m.material_url)">点击下载《{{m.material_name}}》</span> -->
                        </div>
                        <div class="uploadBox uploadBox_new">
                            <div class="uploadBox_l" style="width: 232px;">
                                <label style="cursor: pointer" class="uploadImg-label" [for]="m.material_field" *ngIf="!auth_model[m.material_field]">
                                    <img *ngIf="m.material_example" class="uploadBox_image"
                                        [src]="m.material_example"><br *ngIf="m.material_example" />
                                    <img class="uploadBox_image uploadBox_image_new" *ngIf="!m.material_example"
                                        src="assets/images/business_license.png">
                                    <!-- <img class="uploadBox_image_content" style="position: absolute" *ngIf="!m.material_example"
                    src="assets/images/auth-bgcompany.png"><br *ngIf="!m.material_example" /> -->
                                    <div class="uploadBox_title uploadBox_title_new">上传 {{m.material_name}}</div>
                                </label>
                                <label style="cursor: pointer" class="uploadImg-label" [for]="m.material_field" *ngIf="auth_model[m.material_field]">
                                    <img class="uploadBox_image uploadBox_image_new"
                                        [src]="auth_model[m.material_field]"><br />
                                    <div class="uploadBox_title">修改 {{m.material_name}}</div>
                                </label>
                                <input class="uploadImg" [id]="m.material_field" (change)="previewDepartmentPic($event,m.material_field)" type="file" accept="image/jpeg,image/jpg,image/png">
                            </div>
                            <div class="uploadBox_r" *ngIf="num==0">
                                <!-- <div class="uploadBox_r_title flex_l"><img src="../../assets/images/gantanhao.png">资质说明</div> -->
                                <div class="uploadBox_r_text uploadBox_r_text_new">
                                    <span>1. 上传单位证件照照片，信息需清晰完整，请确保单位名称与提交的审核单位一致；</span> <br />
                                    <span>2. 图片上传格式:PNG、JPG格式，小于5M；</span> <br />
                                    <span>3. 如为复印件、黑白扫描件、需加盖单位公章；</span> <br />
                                    <span>4. 不可使用翻拍照片提交审核；</span><br />
                                    <span>5. 上传图片不可有和与工作啦无关的标识或水印；</span> <br />
                                    <span>6. 证件仅用于审核，不会向第三方透漏，请放心上传！</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div class="step_cell flex_l marginTop24">
                <span class="step_cell_title">姓名</span>
                <input class="step_cell_input" [(ngModel)]="auth_model.operator_name" placeholder="请输入姓名">
            </div>

            <div class="chip_cell">请确保和身份证姓名一致</div>
            <div class="step_cell flex_l marginTop24">
                <span class="step_cell_title">身份证号码</span>
                <input class="step_cell_input" [(ngModel)]="auth_model.id_number" placeholder="请输入身份证号码">
            </div>

            <div class="step_cell_long flex_l marginTop24" style="position: relative;height: 290px;">
                <div class="step_cell_title_long_new">
                    手持身份证上传
                </div>
                <div class="uploadBox uploadBox_new fn">
                    <!-- 国徽面 -->
                    <div class="uploadBox_l fn">
                        <label style="cursor: pointer" class="uploadImg-label" for="id_card" *ngIf="!auth_model.id_card">
                            <img class="uploadBox_image uploadBox_image_new"
                                src="assets/images/business_license.png"><br />
                            <div class="uploadBox_title">请上传手持身份证</div>
                        </label>
                        <label style="cursor: pointer" class="uploadImg-label" for="id_card" *ngIf="auth_model.id_card">
                            <img class="uploadBox_image uploadBox_image_new" [src]="auth_model.id_card"><br />
                            <div class="uploadBox_title">修改手持身份证</div>
                        </label>
                        <input class="uploadImg" id="id_card" (change)="previewDepartmentPic($event,'id_card')" type="file" accept="image/jpeg,image/jpg,image/png">
                    </div>
                    <!-- 反面 -->
                    <!-- <div class="uploadBox_l fn">
                        <label style="cursor: pointer" class="uploadImg-label" for="id_card_back" *ngIf="!auth_model.id_card_back">
                            <img class="uploadBox_image uploadBox_image_new"
                                src="assets/images/business_license.png"><br />
                            <div class="uploadBox_title">请上传身份证人像面</div>
                        </label>
                        <label style="cursor: pointer" class="uploadImg-label" for="id_card_back" *ngIf="auth_model.id_card_back">
                            <img class="uploadBox_image uploadBox_image_new" [src]="auth_model.id_card_back"><br />
                            <div class="uploadBox_title">修改身份证人像面</div>
                        </label>
                        <input class="uploadImg" id="id_card_back" (change)="previewDepartmentPic($event,'id_card_back')" type="file" accept="image/jpeg,image/jpg,image/png">
                    </div> -->
                    <div class="uploadBox_r fn">
                        <div class="uploadBox_r_text uploadBox_r_text_new">
                            <span>1.上传身份证照片需与填写的招聘人姓名、身份证号码保持一致;</span> <br />
                            <span>2.上传身份证照片的身份证信息需清晰可见:</span> <br />
                            <span>3.请提供真实有效的身份证照片;</span> <br />
                            <span>4.上传身份证照片不可标记与工作啦无关的水印或备注;</span><br />
                            <span>5.图片上传格式:PNG，JPG格式,文件不能超过5M;</span> <br />
                            <span>6.证件仅用于审核,不会向第三方透露,请放心上传!</span>
                        </div>
                    </div>
                </div>

            </div>



            <div class="flex_c virtyLook marginTop40">
                <!--<img  *ngIf="!isConfirm" src="../../assets/images/check_auth_none.png">-->
                <!--<img  *ngIf="isConfirm" src="../../assets/images/check_auth.png">-->
                <!--我已查看过并同意 <span style="color: #5E4FFF" (click)="gotoXieyi($event)">《高校云认证协议》</span>-->
            </div>

            <div class="commit_auth flex_c" id="commit_auth_new" (click)="commitAuthClick()">提交</div>


        </div>

    </div>
    <!-- 尾部 -->
    <div class="guide-foot">
        <div class="flex_l logoutAccout" *ngIf="isShowFailModel&&config.lastIdentity&&config.lastIdentity.status==0" (click)="logoutAccountClick()">注销账号</div>

        <span>客服热线：{{config.CustomerServicePhone}}</span> &nbsp;&nbsp;&nbsp;&nbsp;{{config.websiteRecord}}
    </div>
</div>


<p-dialog [showHeader]="false" [dismissableMask]="false" [contentStyle]="{'padding':'0',border:0}" [(visible)]="showFailChipsModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':410,'height':300,'minHeight':300}"
    [modal]=true>
    <div style="width: 410px;height: 300px;background-color: white;overflow: hidden;border-radius: 5px">

        <div class="failM_Title flex_l"><img src="/assets/images/orange_warning.png" />请您确保以下内容已修改</div>
        <div class="failM_Err_title">未通过原因</div>
        <div class="failM_Err" *ngIf="config.lastIdentity">
            <div class="flex_l" *ngFor="let n of config.lastIdentity.remarks;let i = index;">{{i+1}}. {{n}}</div>
        </div>

        <div class="failBtnBOX flex_s">
            <div class="failBtn1" (click)="closeFailModel()">暂不提交，继续修改</div>
            <div (click)="FailChipsCommit()" [ngClass]="{'failBtn2':showFailChipsCommittext=='修改完毕，确定提交','failBtn3':showFailChipsCommittext!='修改完毕，确定提交'}">
                {{showFailChipsCommittext}}</div>
        </div>

    </div>
</p-dialog>
<div class="errModel_small flex_l" *ngIf="!isShowFailModel&&config.lastIdentity&&config.lastIdentity.status==3" (click)="closeFailErrModel()">
    <img class="errModel_small_img1" src="/assets/images/red_warning_auth.png" /> 未通过原因
    <img class="errModel_small_img2" src="/assets/images/red_right.png" />
</div>

<div class="topNav">
    <div class="center">
        <div class="flex_l">
            <span (click)="gotoMain()" class="backMain flex_l"><img
                    src="/assets/images/v3/headerReturnIcon.png" />返回首页</span>
        </div>
    </div>
</div>