
import {Config} from './config';
import {Injectable} from "@angular/core";

@Injectable()
export class activityManager {
  public config: Config;

  // 项目内有时间限制活动的工具类 集合

  //热力值的活动显示
  public isShowHotValue(){
    return false;
  }



}
