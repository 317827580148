import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';//登陆
import { HomeComponent } from './home/home.component';//首页
import { GuideComponent } from './guide/guide.component';//引导
import { GuideCompanyComponent } from './guide-company/guide-company.component';
import { GuideAuditComponent } from './guide-audit/guide-audit.component';
import { IdentityComponent } from './identity/identity.component';//认证-引导
import { IdentityStatusComponent } from './identity-status/identity-status.component';//认证状态--引导
import { HomepageComponent } from './homepage/homepage.component';//首页内容
import { CollegeResumeComponent } from './college-resume/college-resume.component';//校园招聘-在线招聘
import { CollegeCampustalkComponent } from './college-campustalk/college-campustalk.component';//校园+
import { CollegeMilkroundComponent } from './college-milkround/college-milkround.component';//双选会
import { ChatContactComponent } from './chat-contact/chat-contact.component';//在线直聊
import { ChatCollectionComponent } from './chat-collection/chat-collection.component';//对我感兴趣
import { ChatLookComponent } from './chat-look/chat-look.component';//看过我
import { CollegeResumedetailsComponent } from './college-resumedetails/college-resumedetails.component';//简历详情
import { CollegeDetailsComponent } from './college-details/college-details.component';//高校审核
import { ModelCollegeIdentityComponent } from './model-college-identity/model-college-identity.component';//高校校招审核
import { CollegeCampusSignComponent } from './college-campus-sign/college-campus-sign.component';//宣讲会报名
import { CollegeMilkdetailsComponent } from './college-milkdetails/college-milkdetails.component';//双选会详情
import { CompanyIdentityComponent } from './company-identity/company-identity.component';//企业认证
import { PositionResumeComponent } from './position-resume/position-resume.component';//简历管理
import { CollectionResumeComponent } from './collection-resume/collection-resume.component';//简历管理
import { PositionNoticeComponent } from './position-notice/position-notice.component';//面试管理
import { PositionHiredComponent } from './position-hired/position-hired.component';//签约中心
import { PositionCampusComponent } from './position-campus/position-campus.component';//我的宣讲会
import { PositionMilkComponent } from './position-milk/position-milk.component';//我的双选会
import { CompanyDetailsComponent } from './company-details/company-details.component';//公司信息
import { CompanyAccountComponent } from './company-account/company-account.component';//账号管理
import { CompanyDetailsAddressComponent } from './company-details-address/company-details-address.component';//地址管理
import { CollegeMilkSpaceComponent } from './college-milk-space/college-milk-space.component';//招聘会会场页面
import { CollegeMilkAirSpaceComponent } from './college-milk-air-space/college-milk-air-space.component';//空中双选会会场页面
import { CollegeDetailsOrgComponent } from './college-details-org/college-details-org.component';//教学机构
import { CollegeDetailsMajorComponent } from './college-details-major/college-details-major.component';//学院专业列表
import { CollegeDetailsMilkComponent } from './college-details-milk/college-details-milk.component';//高校招聘会列表
import { CollegeDetailsCampusComponent } from './college-details-campus/college-details-campus.component';//高校宣讲会列表
import { CollegeDetailsNewsComponent } from './college-details-news/college-details-news.component';//新闻公告
import { CollegeDetailsNewstxtComponent } from './college-details-newstxt/college-details-newstxt.component';//新闻详情
import { CollegeDetailsStudentComponent } from './college-details-student/college-details-student.component';//生源速览
import { PositionManageComponent } from './position-manage/position-manage.component';//职位管理
import { PositionPublishComponent } from './position-publish/position-publish.component';//发布职位
import { NotFoundComponent } from './not-found/not-found.component';//404
import { CompanyIdentityDetailsComponent } from './company-identity-details/company-identity-details.component';//提交认证资料页面
import { CollegeMilkSignComponent } from './college-milk-sign/college-milk-sign.component';//双选会报名页面
import { PositionRulesComponent } from './position-rules/position-rules.component';//招聘简章
import { PositionRulesDetailsComponent } from './position-rules-details/position-rules-details.component';
import { PositionRulesEditorComponent } from './position-rules-editor/position-rules-editor.component';
import { CompanyAuthenticationComponent } from './company-authentication/company-authentication.component';//提交企业平台认证资料页面
import { CompanyPayComponent } from './company-pay/company-pay.component';//支付页面
import { CompanyReceiptComponent } from './company-receipt/company-receipt.component';//发票列表页面
import { CompanyRecordComponent } from './company-record/company-record.component';//使用记录列表页面
import { CompanyReceiptSendComponent } from './company-receipt-send/company-receipt-send.component';//开发票页面
import { CompanyReceiptSucComponent } from './company-receipt-suc/company-receipt-suc.component';//开发票页面
import { CompanyStatusOneComponent } from './company-status-one/company-status-one.component';//认证审核中
import { CompanyStatusTwoComponent } from './company-status-two/company-status-two.component';//认证通过
import { CompanyStatusThreeComponent } from './company-status-three/company-status-three.component';//未通过
import { CompanyStatusFourComponent } from './company-status-four/company-status-four.component';//审核失败
import { CollegeDetailsPreachComponent } from './college-details-preach/college-details-preach.component'; // 宣讲会详情
import { CollegeAuthenticationSucComponent } from './college-authentication-suc/college-authentication-suc.component';
import { CollegeAuthenticationWaitComponent } from './college-authentication-wait/college-authentication-wait.component';
//import { MemberCenterComponent } from './member-center/member-center.component'; // 会员中心
import { MemberProductDetailComponent } from './member-product-detail/member-product-detail.component'; // 单品详情
import { MemberProductListComponent } from './member-product-list/member-product-list.component'; // 单品详情
import { BatchInvitationComponent } from './batch-invitation/batch-invitation.component'; // 批量邀投包使用页面
import { FindPswComponent } from './find-psw/find-psw.component';
import { WorkSpaseComponent } from './work-spase/work-spase.component'
import { HotValueComponent } from './hotvalue-rank/hotvalue-rank.component'
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { NotificationPendingComponent } from './notification-pending/notification-pending.component';
import { CampusToolComponent } from './campus-tool/campus-tool.component';
import { MyToolComponent } from './my-tool/my-tool.component';
import { BuyLaCoinComponent } from './buy-la-coin/buy-la-coin.component';
import { BuyPayProductComponent } from './buy-pay-product/buy-pay-product.component';
import { MyCardComponent } from './my-card/my-card.component';
import { PayHistoryComponent } from './pay-history/pay-history.component';
import { PayInvoiceComponent } from './pay-invoice/pay-invoice.component';
import { ProductUseHistoryComponent } from './product-use-history/product-use-history.component';
import { BuyPayProductExtendComponent } from './buy-pay-product-extend/buy-pay-product-extend.component';
import { MySingleProductListComponent } from './my-single-product-list/my-single-product-list.component';
import { ActivityInvitationComponent } from './activity-invitation/activity-invitation.component';
import { PositionPushsComponent } from './position-pushs/position-pushs.component';
import { BuyExplainComponent } from './buy-explain/buy-explain.component';
import { SearchPeopleComponent } from './search-people/search-people.component';
import { ChatSingleComponent } from './chat-single/chat-single.component';
import { AccountLogoutComponent } from './account-logout/account-logout.component';
import { NavigationRouteComponent } from './navigation-route/navigation-route.component';
import { AccountLogoutHistoryComponent } from './account-logout-history/account-logout-history.component';
import { VoiceCallRecordComponent } from './voice-call-record/voice-call-record.component';
import { PositionPushRecordComponent } from './position-push-record/position-push-record.component';
import { ActivityInviteRecordComponent } from './activity-invite-record/activity-invite-record.component';
import { ActivityInviteRecordDetailComponent } from './activity-invite-record-detail/activity-invite-record-detail.component';
import { ZengzhixieyiPageComponent } from './zengzhixieyi-page/zengzhixieyi-page.component';
import { AirCampusDetailComponent } from './air-campus-detail/air-campus-detail.component';
import { CreatAirCampusComponent } from './creat-air-campus/creat-air-campus.component';
import { AirCampHistoryComponent } from './air-camp-history/air-camp-history.component';
import { AirCampHistoryDetailComponent } from './air-camp-history-detail/air-camp-history-detail.component';
import { LogoutAccountModelComponent } from "./logout-account-model/logout-account-model.component";
import { AirCampustalkReordComponent } from "./air-campustalk-reord/air-campustalk-reord.component";
import { PaymentOrderListComponent } from "./payment-order-list/payment-order-list.component";
import { AdvertListPageComponent } from "./advert-list-page/advert-list-page.component";
import { CollectFormComponent } from "./collect-form/collect-form.component";
import { AirCampListComponent } from "./air-camp-list/air-camp-list.component";
import { AirLiveExplainComponent } from "./air-live-explain/air-live-explain.component";
import { CollegeMilkDayComponent } from "./college-milk-day/college-milk-day.component";
import { FindAccountComponent } from './find-account/find-account.component';
import { InterviewComponent } from './interview/interview.component';
import { RightsComponent } from './rights/rights.component';
import { AppGuard } from './app.guard';
import { AuthenticationComponent } from './authentication/authentication.component';

const routes: Routes = [
  //添加默认路由
  //path:用于匹配浏览器地址栏中url的字符串；component:导航到此路由时，路由器应该创建那个组件
  { path: 'login', component: LoginComponent },
  { path: 'guide', component: GuideComponent },
  { path: 'guide-company', component: GuideCompanyComponent },
  { path: 'guide-audit', component: GuideAuditComponent },
  { path: 'identity', component: IdentityComponent },
  { path: 'identity-status', component: IdentityStatusComponent },
  { path: 'app-find-psw', component: FindPswComponent },
  { path: 'hotValue', component: HotValueComponent },
  { path: 'account-logout', component: AccountLogoutComponent },
  { path: 'find-account', component: FindAccountComponent },
  { path: 'authentication', component: AuthenticationComponent },

  {
    path: 'home', component: HomeComponent,
    children: [
      { path: 'homepage', component: HomepageComponent },
      { path: 'college-resume', component: CollegeResumeComponent, data: { keep: true } },
      { path: 'college-campustalk', component: CollegeCampustalkComponent },
      { path: 'college-campustalk1', component: CollegeCampustalkComponent },
      { path: 'college-milkround', component: CollegeMilkroundComponent },
      { path: 'chat-contact', component: ChatContactComponent },
      { path: 'chat-collection', component: ChatCollectionComponent },
      { path: 'chat-look', component: ChatLookComponent, data: { keep: true } },
      { path: 'college-resumedetails', component: CollegeResumedetailsComponent },
      { path: 'college-resumedetails_resume', component: CollegeResumedetailsComponent },
      { path: 'college-details', component: CollegeDetailsComponent },
      { path: 'model-college-identity', component: ModelCollegeIdentityComponent },
      { path: 'college-campus-sign', component: CollegeCampusSignComponent },
      { path: 'college-milk-sign', component: CollegeMilkSignComponent },
      { path: 'college-milkdetails', component: CollegeMilkdetailsComponent },
      { path: 'company-identity', component: CompanyIdentityComponent },
      { path: 'position-resume', component: PositionResumeComponent, data: { keep: true } },
      { path: 'position-resume-like', component: CollectionResumeComponent, data: { keep: true } },
      { path: 'position-notice', component: PositionNoticeComponent },
      { path: 'position-hired', component: PositionHiredComponent },
      { path: 'position-campus', component: PositionCampusComponent },
      { path: 'position-milk', component: PositionMilkComponent },
      { path: 'company-details', component: CompanyDetailsComponent },
      { path: 'company-account', component: CompanyAccountComponent },
      { path: 'position-manage', component: PositionManageComponent, data: { keep: true } },
      { path: 'position-rules', component: PositionRulesComponent },
      { path: 'position-rules-details', component: PositionRulesDetailsComponent },
      { path: 'position-rules-editor', component: PositionRulesEditorComponent },
      { path: 'position-publish', component: PositionPublishComponent },
      { path: 'company-details-address', component: CompanyDetailsAddressComponent },
      { path: 'college-milk-space', component: CollegeMilkSpaceComponent },
      { path: 'college-milk-air-space', component: CollegeMilkAirSpaceComponent, data: { keep: true } },
      { path: 'college-details-org', component: CollegeDetailsOrgComponent },
      { path: 'college-details-major', component: CollegeDetailsMajorComponent },
      { path: 'college-details-milk', component: CollegeDetailsMilkComponent },
      { path: 'college-details-campus', component: CollegeDetailsCampusComponent },
      { path: 'college-details-news', component: CollegeDetailsNewsComponent },
      { path: 'college-details-newstxt', component: CollegeDetailsNewstxtComponent },
      { path: 'college-details-student', component: CollegeDetailsStudentComponent },
      { path: 'college-details-preach', component: CollegeDetailsPreachComponent },
      { path: 'company-identity-details', component: CompanyIdentityDetailsComponent },
      { path: 'company-authentication', component: CompanyAuthenticationComponent },
      { path: 'company-authentication-wait', component: CollegeAuthenticationWaitComponent },
      { path: 'company-authentication-suc', component: CollegeAuthenticationSucComponent },
      { path: 'company-pay', component: CompanyPayComponent },
      { path: 'company-receipt', component: CompanyReceiptComponent },
      { path: 'company-record', component: CompanyRecordComponent },
      { path: 'company-receipt-send', component: CompanyReceiptSendComponent },
      { path: 'company-receipt-suc', component: CompanyReceiptSucComponent },
      { path: 'company-status-one', component: CompanyStatusOneComponent },
      { path: 'company-status-two', component: CompanyStatusTwoComponent },
      { path: 'company-status-three', component: CompanyStatusThreeComponent },
      { path: 'company-status-four', component: CompanyStatusFourComponent },
      //{path:'member-center',component:MemberCenterComponent},
      { path: 'member-product-detail', component: MemberProductDetailComponent },
      { path: 'member-product-list', component: MemberProductListComponent },
      { path: 'batch-invitation', component: BatchInvitationComponent },
      { path: '', redirectTo: './college-resume', pathMatch: 'full' },
      { path: 'workSpase', component: WorkSpaseComponent },
      { path: 'notification-pending', component: NotificationPendingComponent },
      { path: 'notification-center', component: NotificationCenterComponent },
      { path: 'tool-campus', component: CampusToolComponent },
      { path: 'tool-my', component: MyToolComponent },
      { path: 'buyLaCoin', component: BuyLaCoinComponent },
      { path: 'buyProduct', component: BuyPayProductComponent },
      { path: 'myCard', component: MyCardComponent },
      { path: 'payHistory', component: PayHistoryComponent },
      { path: 'payInvoice', component: PayInvoiceComponent },
      { path: 'productUseHistory', component: ProductUseHistoryComponent },
      { path: 'buyProductExtend', component: BuyPayProductExtendComponent },
      { path: 'mySingleProductList', component: MySingleProductListComponent },
      { path: 'interview', component: InterviewComponent, canDeactivate: [AppGuard] },
      { path: 'rights', component: RightsComponent },
      { path: 'activityInvitation', component: ActivityInvitationComponent },
      { path: 'positionPushs', component: PositionPushsComponent },
      { path: 'buyExplain', component: BuyExplainComponent },
      { path: 'searchPeople', component: SearchPeopleComponent, data: { keep: true } },
      { path: 'chatSingle', component: ChatSingleComponent, data: { keep: true } },
      { path: 'account-logout-l', component: AccountLogoutComponent },
      { path: 'accountLogoutHistory', component: AccountLogoutHistoryComponent },
      { path: 'voiceCallRecord', component: VoiceCallRecordComponent },
      { path: 'positionPushRecord', component: PositionPushRecordComponent },
      { path: 'activityInviteRecord', component: ActivityInviteRecordComponent },
      { path: 'activityInviteRecordDetail', component: ActivityInviteRecordDetailComponent },
      { path: 'zengzhi', component: ZengzhixieyiPageComponent },
      { path: 'airCampusDetail', component: AirCampusDetailComponent },
      { path: 'creatAirCampus', component: CreatAirCampusComponent },
      { path: 'airCampHistory', component: AirCampHistoryComponent },
      { path: 'airCampHistoryDetail', component: AirCampHistoryDetailComponent },
      { path: 'airCampHistoryReord', component: AirCampustalkReordComponent },
      { path: 'paymentorderlist', component: PaymentOrderListComponent },
      { path: 'advertList', component: AdvertListPageComponent },
      { path: 'collectForm', component: CollectFormComponent },
      { path: 'airCampList', component: AirCampListComponent },
      { path: 'airCampexplain', component: AirLiveExplainComponent },
      { path: 'milkdDay', component: CollegeMilkDayComponent },



      // {path:'seller/:id',component:SellerComponent}
    ]
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
