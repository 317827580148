import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'clearSpace'})
export class clearSpace implements PipeTransform {//implements--是一个类，实现一个接口用的关键字，它是用来实现接口中定义的抽象方法
  transform(value): string {
    if(value){
      let text = value.replace(new RegExp(/\s/g), '');//匹配任何空白字符，包括空格、制表符、换页符等等
      return text;
    }
  }
}
