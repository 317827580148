import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { DataApi, Local, Constants, Config, Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { scrollTool } from "../../provider/scrollTool";
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { NoneVipAlertComponent } from "../../app/none-vip-alert/none-vip-alert.component";
import * as moment from 'moment';
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
//新增高校
import { PushCollegeModelComponent } from "../push-college-model/push-college-model.component";
declare let laydate;
declare var layui;
declare let Swiper: any;

@Component({
  selector: 'app-college-milkround',
  templateUrl: './college-milkround.component.html',
  styleUrls: ['./college-milkround.component.css']
})
export class CollegeMilkroundComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true })
  productModel: ProductModelComponent;

  //是否打开弹窗(非会员新增高校)
  @Output() isShowPushCollegeModel = false;
  @ViewChild('pushCollegeModel', { static: true })
  pushCollegeModel: PushCollegeModelComponent;

  public config: Config;
  public keywordList = [];//搜索关键词匹配列表
  public hotCity = [];//热门城市
  public milkRoundList = [];//双选会列表
  public historyMilkRoundList = [];//历史双选会列表
  public bannerList = [];
  public productList = [];
  public searchModel = '';//搜索关键词
  public posterUrl = '' // 双选会海报下载链接
  public QrCodeURL = "";// 通过后可分享的二维码双选会信息
  public dateSattus = 'all';//日期状态
  public showMilkCode = false;
  public showConfirmMilkroundModel = false;
  public currentMilkround = null;
  public isAir = false;
  public addCollegeList = []; // 非会员合作的高校
  showTips: boolean = true;

  milkInfo // 双选会详情

  // 筛选条件
  public model = {
    region: '',//城市
    start_time: null,
    end_time: null,
    college_name: '',
    offset: 0,
    limit: 10,
    school_type: '',
    milkround_type: "",
  }
  public countList = 0;//列表数量
  public activity_invitation = null;
  public laypage;
  public isShowMemberMoudel = true;
  public activeName = '02';
  public revokeModule = false;
  public revokeApplyid = '';

  @Output() modelShow = false;//是否打开弹窗
  @Output() mridList = [];//招聘会id列表
  @Output() milkNumber = 1;//当前申请的招聘会场次
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  // public isShowFilter = false;
  constructor(
    public dataApi: DataApi,
    public local: Local,
    private router: Router,
    public dialog: MatDialog,
    public opera: Opera,
    private activatedRoute: ActivatedRoute,
    public scrolltool: scrollTool,
    public toastr: ToastrService
  ) {
    this.config = Config.getInstance();//实例化config
    activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.collegename) {
        this.model.college_name = queryParams.collegename;
      }
    })
  }
  //组件相应的视图初始化之后
  ngAfterViewInit() {
    let that = this;
    layui.use('carousel', function () {
      //建造实例
      var carousel = layui.carousel;
      var options = {
        elem: '#test1Btn618'
        , width: '100%' //设置容器宽度
        , height: '160px'
        , arrow: 'none' //始终显示箭头
        , anim: 'default' //切换动画方式
      }
      var ins = carousel.render(options);
      //重置轮播
      ins.reload(options);
    });
  }

  async ngOnInit() {

    let that = this;
    this.opera.vipState((isPay) => {
      this.isShowMemberMoudel = isPay;
    })

    this.mycollegeList();

    laydate.render({
      elem: '#startTime',
      // type: 'datetime',
      done: function (value, date) {
        that.model.start_time = value;
        if (that.model.end_time) {
          that.checkTime();//检测时间
        }
      }
    })
    laydate.render({
      elem: '#endTime',
      // type: 'datetime',
      done: function (value, date) {
        that.model.end_time = value;
        that.checkTime();//检测时间
      }
    })
    layui.use('laypage', function () {
      that.laypage = layui.laypage;
    });

    this.getHotCity();//获取热门城市
    this.getCollegeCode();//获取高校字典
    this.getProductList();
    this.getDepartment();
    this.getCardbanner();
    await this.getSchoolType();

    if (this.local.get('milkround_type_search')) {
      this.model.milkround_type = this.local.get('milkround_type_search')
    }
    if (this.local.get('milkround_dateSattus_search')) {
      this.dateSattus = this.local.get('milkround_dateSattus_search')
    }
    if (this.local.get('milkround_dateSattus_search_start')) {
      this.model.start_time = this.local.get('milkround_dateSattus_search_start')
    }
    if (this.local.get('milkround_dateSattus_search_end')) {
      this.model.end_time = this.local.get('milkround_dateSattus_search_end')
    }
    if (this.local.get('milkround_college_name_search')) {
      this.model.college_name = this.local.get('milkround_college_name_search')
    }
    if (this.local.get('milkround_school_type_search')) {
      this.model.school_type = this.local.get('milkround_school_type_search')
    }
    if (this.local.get('milkround_college_name_search')) {
      this.model.college_name = this.local.get('milkround_college_name_search')
    }



    if (JSON.parse(this.local.get('isAir')) == 2) {
      this.isAir = true;
      this.opera.initConfig('nav-activity-air', 'college-milkround');
    } else {
      this.isAir = false;
      this.opera.initConfig('nav-activity', 'college-milkround');
    }
    this.pageNumber = this.scrolltool.getVal('college_milkround_Page');
    if (this.pageNumber > 0) {
      this.model.offset = (this.pageNumber - 1) * 10;
    }

    await this.getMilkList();
    this.scrolltool.initScrooll_cell_With_scrollY(this.scrolltool.getVal('college_milkround_cell_scrollY'));
  }

  //其他双选会
  othergetMilkList() {
    this.activeName = '02';
    this.scrolltool.setVal('college_milkround_Page', 1);
    this.pageNumber = 1;
    this.model.offset = (this.pageNumber - 1) * 10;
    // 搜索缓存学校名称
    if (this.model.college_name) {
      this.local.set('milkround_college_name_search', this.model.college_name);
    }
    if (!this.model.college_name) {
      this.local.remove('milkround_college_name_search');
    }
    this.getMilkList();//获取双选会列表
  }

  //我的双选会
  mygetMilkList() {
    this.activeName = '01';
    this.getMyMilkroundHistory();
  }

  //点击产品
  product(item, type) {
    this.productModel.initData(item.alias, type);
    this.isproductShowModel = true;
  }

  productModelHidden(event) {
    this.isproductShowModel = false;
  }

  //去我报名的双选会
  goSignList() {
    if (this.isAir) {
      this.router.navigate(['/home/position-milk'], {
        queryParams: {
          isAir: true
        }
      })
    } else {
      this.router.navigateByUrl("/home/position-milk");
    }

  }

  chooseType(status) {
    this.model.offset = 0;
    this.pageNumber = 1;
    this.model.milkround_type = status;
    this.scrolltool.setVal('college_milkround_Page', this.pageNumber);
    this.local.set('milkround_type_search', status)
    this.getMilkList();
  }
  milkround_typeSearch() {
    this.chooseType(this.model.milkround_type);
  }

  chooseDate(status) {
    this.dateSattus = status;
    this.local.set('milkround_dateSattus_search', status)
    this.model.offset = 0;
    this.pageNumber = 1;
    this.scrolltool.setVal('college_milkround_Page', this.pageNumber);

    // window.scrollBy(0, 230)
    this.scrollToOtherTop();
    if (status == 'now') {
      var date = new Date(), y = date.getFullYear(), m = date.getMonth();
      var firstDay = new Date(y, m, 1);
      var lastDay = new Date(y, m + 1, 0);
      this.model.start_time = firstDay.getFullYear() + '-' + (firstDay.getMonth() + 1) + '-' + firstDay.getDate();
      this.model.end_time = lastDay.getFullYear() + '-' + (lastDay.getMonth() + 1) + '-' + lastDay.getDate();
      this.local.set('milkround_dateSattus_search_start', this.model.start_time)
      this.local.set('milkround_dateSattus_search_end', this.model.end_time)
      this.getMilkList();
    } else if (status == 'next') {
      var date = new Date(), y = date.getFullYear(), m = date.getMonth();
      var firstDay = new Date(y, m + 1, 1);
      var lastDay = new Date(y, m + 2, 0);
      this.model.start_time = firstDay.getFullYear() + '-' + (firstDay.getMonth() + 1) + '-' + firstDay.getDate();
      this.model.end_time = lastDay.getFullYear() + '-' + (lastDay.getMonth() + 1) + '-' + lastDay.getDate();
      this.local.set('milkround_dateSattus_search_start', this.model.start_time)
      this.local.set('milkround_dateSattus_search_end', this.model.end_time)
      this.getMilkList();
    } else if (status == 'all') {
      this.model.start_time = '';
      this.model.end_time = '';
      this.local.set('milkround_dateSattus_search_start', '')
      this.local.set('milkround_dateSattus_search_end', '')
      this.getMilkList();
    }
  }

  dateSattusSearch() {
    this.chooseDate(this.dateSattus);
  }

  //接收返回参数
  Modeldismiss(event) {
    this.modelShow = false;
    this.opera.scrollTrue();
    if (event) {
      for (let n of this.milkRoundList) {
        for (let m of this.mridList) {
          if (n.mrid == m) {
            n.status = 'to_audit';
          }
        }
      }
    }
    this.mridList = []; // 清空
  }

  //去报名
  goSign(n) {
    this.scrolltool.setVal('college_milkround_Page', this.pageNumber);
    let that = this;
    if (n.isAppEnd && n.milkround_type == 1) {
      this.toastr.error('报名已截止')
      return
    }
    this.opera._hmtPush('双选会', '双选会信息', '我要报名')
    //如果是公益双选会，不验证会员
    if (n.is_welfare) {
      this.goMilkSign(n);//报名
      return;
    }

    this.opera.checkMember((isPay) => {
      if (isPay) {
        if (this.config.isMember_trial) {
          that.opera.checkMemberAlertWithType("goMilk_milkround")
          return;
        }
        this.opera.checkCompanyIsBack(n.collegeid, () => {
          this.goMilkSign(n);//报名
        })
      } else {
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == n.collegeid);
        if (finItem) {
          if (this.config.isMember_trial) {
            that.opera.checkMemberAlertWithType("goMilk_milkround")
            return;
          }
          this.opera.checkCompanyIsBack(n.collegeid, () => {
            this.goMilkSign(n);//报名
          })
        } else {
          this.pushCollege();
        }
        //that.opera.checkMemberAlertWithType("goBuy_signMilk")
      }
    })

  }

  //去双选会报名页
  goMilkSign(n) {
    console.log(n);
    if (n.milkround_type == 1) {
      if (n.scale <= n.department_participate_count) {
        this.toastr.warning('坐席已满，无法报名！');
        return
      }
    }
    if (n.mrid) {
      this.local.set(Constants.collegeid, n.collegeid);
      console.log("去双选会报名页", n);
      this.local.set(Constants.mrid, n.mrid);
      if (n.applyid) {
        this.local.set(Constants.applyid, n.applyid);
      } else {
        this.local.remove(Constants.applyid);
      }

      // this.local.remove(Constants.applyid);
      if (n.milkround_type == 2) {
        this.router.navigate(['home/college-milk-sign'], {
          queryParams: {
            milkname: n.name,
            isAir: true
          }
        });
      } else {
        this.router.navigate(['home/college-milk-sign'], {
          queryParams: {
            milkname: n.name
          }
        });
      }
    }
  }

  //进入会场(不需要通过认证)
  goSpace(id) {
    this.router.navigateByUrl('home/college-milk-space');
    this.local.set(Constants.mrid, id);//覆盖本地存储招聘会id
  }

  //获取企业信息
  getDepartment() {
    this.dataApi.getcomponentLeft({}).then((res: any) => {
      res.map((item) => {
        if (item.alias == 'activity_invitation') {
          this.activity_invitation = item;
        }
      })
    }).catch((err) => {
      console.log(err);
    })
  }

  getProductList() {
    //product_category:"recharge",recharge_category:''
    this.opera.loading();
    this.dataApi.departmentList({ product_category: "recharge" }).then((res: any) => {
      this.opera.loadiss();
      if (res) {
        console.log('获取产品列表', res)
        this.productList = res.list;
      }
    }).catch((err) => {
      console.log(err);
      this.opera.loadiss();
    })
  }

  goBuy(n) {
    this.config.userInitiativePayName = n
    if (!this.config.isShowPaySystem) {
      this.config.payVipHistoryPage = null
    }
    this.productList.map((item, index) => {
      if (item.recharge_category == n) {
        this.local.set("recharge_category_code", JSON.stringify(item.code));
        // this.router.navigate(['home/buyProduct']);
        this.router.navigate(['home/tool-campus']);
        return
      }
    })
  }

  //去双选会详情
  goDeatils(item) {
    console.log('双选会详情', item, this.pageNumber);
    this.scrolltool.setVal('college_milkround_cell_scrollY', window.top.scrollY);
    this.scrolltool.setVal('college_milkround_Page', this.pageNumber);
    //this.model.offset = (this.pageNumber-1)*10;
    let that = this;
    if (item.milkround_type == 2) {
      that.router.navigate(['home/college-milkdetails'], {
        queryParams: {
          id: item.mrid,
          isAir: true
        }
      })
    } else {
      that.router.navigate(['home/college-milkdetails'], {
        queryParams: {
          id: item.mrid
        }
      })
    }

  }

  goSearch(text) {
    this.model.offset = 0;
    this.pageNumber = 1;
    this.model.college_name = text;
    // this.local.set('milkround_college_name_search', text);
    this.scrolltool.setVal('college_milkround_Page', this.pageNumber);

    this.scrollToOtherTop();
    this.getMilkList();//获取双选会列表
    this.keywordList = [];
  }

  searchClick() {
    this.model.offset = 0;
    this.pageNumber = 1;
    this.local.set('milkround_school_type_search', this.model.school_type)
    this.scrolltool.setVal('college_milkround_Page', this.pageNumber);
    this.getMilkList();//获取双选会列表
  }

  //获取高校字典
  getCollegeCode() {
    // 获取高校字典数据
    if (this.config.school) {
      if (this.config.school.length < 1) {
        this.dataApi.getDictDate('school').then((res: any) => {
          // //console.log(res);
          if (res) {
            this.config.school = res;
          }
        }).catch((err) => {
          // this.toastr.error("网络错误,请稍后重试");
          //console.log(err);
        })
      }
    }
  }

  //匹配关键字列表
  getItems() {
    this.keywordList = [];
    if (!this.model.college_name) { return; }
    for (let s of this.config.school) {
      if (s.text.indexOf(this.model.college_name) >= 0) {
        this.keywordList.push(s);
      }
    }
  }

  //监听键盘事件
  inputKeyup(event) {
    let that = this;
    var keycode = window.event ? event.keyCode : event.which;
    if (keycode == 13) {
      that.goSearch(that.model.college_name);
    } else {
      that.getItems();
    }
  }

  //判断时间参数是否完整
  checkTime() {
    if (this.model.start_time && this.model.end_time) {
      this.getMilkList();//获取双选会列表
    } else if (!this.model.start_time) {
      this.toastr.warning("请选择开始时间");
      return;
    }
  }

  //获取双选会列表
  async getMilkList() {
    let that = this
    this.opera.loading();
    try {
      var res = await this.dataApi.getDepartmentMilkListWithMy(this.model);
      if (res) {
        this.opera.loadiss();
        // console.log("获取双选会列表记录",res,this.model);
        this.milkRoundList = res.list;
        this.countList = res.count;
        this.pageCount = Math.ceil(res.count / 9);
        this.formatList(this.milkRoundList)

        this.laypage.render({
          elem: 'page' //注意，这里的 test1 是 ID，不用加 # 号
          , limit: 10, theme: '#5e4fff'
          , curr: this.pageNumber
          , count: res.count //数据总数，从服务端得到
          , jump: function (obj, first) {
            //obj包含了当前分页的所有参数，比如：
            console.log(obj.curr); //得到当前页，以便向服务端请求对应页的数据。
            //首次不执行
            that.pageNumber = obj.curr;
            that.model.offset = (that.pageNumber - 1) * 10;
            if (!first) {
              that.getMilkList()
              //do something
            }
          }
        });
      }
    } catch (err) {
      this.opera.loadiss();
    }
  }

  //获取热门城市
  getHotCity() {
    if (this.hotCity.length < 1) {
      this.dataApi.getCollegeHotCity().then((res: any) => {
        if (res) {
          this.hotCity = res;
        }
      }).catch((err) => {
        //console.log(err);
      })
    }
  }


  //获取分页子组件返回数据
  getChildEvent(index) {
    // console.log('子组件返回分页数-----college-campustalk',index,this.pageNumber);
    console.log(index);
    if (index > 0) {
      this.pageNumber = index;
      this.model.offset = (this.pageNumber - 1) * 10;
      this.getMilkList();//获取列表
    } else if (index == -1) {//上一页
      if (this.pageNumber == 1) {
        this.toastr.warning("当前为第一页");
      } else {
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber - 1) * 10;
        this.getMilkList();//获取列表
      }
    } else if (index == -2) {//下一页
      if (this.pageNumber == this.pageCount) {
        this.toastr.warning("当前为最后一页");
      } else {
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber - 1) * 10;
        this.getMilkList();//获取列表
      }
    }
    this.scrolltool.setVal('college_milkround_Page', this.pageNumber);

  }


  // 获取双选会海报下载链接
  downloadPoster(applyid) {
    this.opera.loading();
    this.dataApi.getMilkPoster(applyid).then((res: any) => {
      this.opera.loadiss();
      if (res.value) {
        let b = window.open();
        setTimeout(function () {
          b.location.href = res.value;
        }, 500);
      }
    }).catch((err) => {
      console.log(err);
      this.toastr.error(err)
      this.opera.loadiss();
    })
  }

  downloadIamge(imgsrc, name) {//下载图片地址和图片名
    let image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function () {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      let a = document.createElement("a"); // 生成一个a元素
      let event = new MouseEvent("click"); // 创建一个单击事件
      a.download = name || "photo"; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = imgsrc;
  }

  /**
   * 确认参会按钮
   * @param n
   */
  okCanhui(n) {
    let that = this;
    this.opera.checkCompanyIsBack(n.collegeid, () => {
      console.log('公益双选会', n.is_welfare);
      //公益双选会
      if (n.is_welfare) {
        if (n.milkround_type == 2) {
          that.router.navigateByUrl('home/college-milk-air-space');
          that.local.set(Constants.mrid, n.mrid);//覆盖本地存储招聘会id
        } else {
          that.QrCodeURL = "";
          that.showConfirmMilkroundModel = true;
          that.currentMilkround = n
        }
        return;
      }

      this.opera.checkMember((isPay) => {
        if (isPay) {
          if (n.milkround_type == 2) {
            that.router.navigateByUrl('home/college-milk-air-space');
            that.local.set(Constants.mrid, n.mrid);//覆盖本地存储招聘会id
          } else {
            that.QrCodeURL = "";
            that.showConfirmMilkroundModel = true;
            that.currentMilkround = n
          }
        } else {
          //that.opera.checkMemberAlertWithType("goMilk_milkround")
          let finItem = this.addCollegeList.find(item => item.collegeid == n.collegeid);
          if (finItem) {
            if (n.milkround_type == 2) {
              that.router.navigateByUrl('home/college-milk-air-space');
              that.local.set(Constants.mrid, n.mrid);//覆盖本地存储招聘会id
            } else {
              that.QrCodeURL = "";
              that.showConfirmMilkroundModel = true;
              that.currentMilkround = n
            }
          } else {
            this.pushCollege();
          }
        }
      })
    })
  }


  //已经参会
  ConfirmMilkroundCommit(type) {
    let data = {
      mrid: this.currentMilkround.mrid,
      type: type,
    };
    this.dataApi.confirmMilkround(data, this.currentMilkround.mrid, type).then((res: any) => {

      console.log("确认参会", res);
      this.showConfirmMilkroundModel = false;
      if (type == "no_join") {
        this.toastr.error("请合理安排时间，如经常不参加双选会将对您企业的信用造成损失")
      }
      else {
        this.toastr.success("已确认参加成功")
      }
      for (let index = 0; index < this.milkRoundList.length; index++) {
        let item = this.milkRoundList[index];
        if (item.mrid == this.currentMilkround.mrid) {
          item.feedback_status = type;
        }
      }
      if (type == "join") {
        this.currentMilkround.feedback_status = 'join';
        this.QrCodeURL = res.value;
        this.showMilkCode = true;
      }

    }).catch((err) => {
      console.log("确认参会失败", err);
      this.toastr.error(err)
    })
  }

  ConfirmMilkroundCommit2(item) {
    console.log('item', item)
    let type = 'join'
    let data = {
      mrid: item.mrid,
      type: type,
    };
    this.dataApi.getMilkroundFeedBackPost(item.mrid).then((res: any) => {
      this.toastr.success("已确认参加成功")
      if (res.value) {
        this.QrCodeURL = res.value;
        this.showMilkCode = true;
      }
    }).catch((err) => {
      console.log("确认参会失败", err);
    })
  }

  //关闭确认参会按钮的二维码
  closeModel() {
    this.QrCodeURL = "";
    this.showMilkCode = false;

  }
  //新版通知学生到场
  activityInvitation(n) {
    this.opera.checkCompanyIsBack(n.collegeid, () => {
      this.local.set('activityInvitationData', JSON.stringify(n));
      this.local.set('activityInvitationDataType', 'milkround');
      this.router.navigateByUrl('/home/activityInvitation');// 通知学生到场
    })
  }
  //获取card广告banner
  getCardbanner() {

    let that = this;
    this.dataApi.getCardBannerdata('shuangxuanhui').then((res: any) => {
      if (res) {
        console.log('获取广告Banner', res);
        this.bannerList = res;
        setTimeout(() => {
          layui.use(['carousel', 'form'], function () {
            var carousel = layui.carousel
              , form = layui.form;
            //建造实例
            carousel.render({
              elem: '#test1'
              , width: '100%' //设置容器宽度
              , arrow: 'always', //始终显示箭头
              height: '160px',
              interval: 3000
            });
            //找到两个按钮，设置样式，因为全局样式又一个Buttom里有一个样式会导致按钮错乱
            let leftbtn = document.getElementById("lay-type-left");
            let rightbtn = document.getElementById("lay-type-add");
            if (leftbtn) {
              leftbtn.style.padding = '0';
            }
            if (rightbtn) {
              rightbtn.style.padding = '0';
            }
          })
        }, 200)
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  bannerClick(n) {
    console.log('bannerClick', n)
    if (n.links) {
      window.open(n.links, '_blank');
      return;
    }
  }
  //滚动页面到其他双选会的顶部
  scrollToOtherTop() {
    // window.scroll({ top: 350 + this.historyMilkRoundList.length * 110, left: 0, behavior: 'smooth' });
  }

  getMyMilkroundHistory() {
    this.dataApi.getMyMilkroundHistory().then((res: any) => {
      console.log('getMyMilkroundHistory', res);
      if (res) {
        this.historyMilkRoundList = res
        // if(this.isAir){
        //   this.historyMilkRoundList = res.filter(item => item.milkround_type == 2)
        // }else{
        //   this.historyMilkRoundList = res.filter(item => item.milkround_type != 2)
        // }
        console.log(this.historyMilkRoundList, 6545646);
        if (this.showTips) {
          for (let item of this.historyMilkRoundList) {
            let nowDate = new Date();
            let startDate = new Date(item.start_time);
            let endDate = new Date(item.end_time);
            let applyOpDate = new Date(item.apply_start_time); // 报名开始时间
            let applyEndDate = new Date(item.apply_end_time); // 报名结束时间
            let nowTime = nowDate.getTime();  // 当前时间戳
            let startTime = startDate.getTime();  // 开始时间戳
            let endTime = endDate.getTime();  // 结束时间戳
            let applyOpTime = applyOpDate.getTime();  // 报名开始时间戳
            let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳
            if (nowTime - endTime > 0) {
              item.isEnd = true; // 会议已结束
            } else {
              item.isEnd = false; // 会议未结束
            }


          }
        }
        for (let val of this.historyMilkRoundList) {
          val.status = val.audit_status;
          if (val.zone_name == null) {
            val.zone_name = "";
          }

          let nowDate = new Date();
          let startDate = new Date(val.start_time);
          let endDate = new Date(val.end_time);
          let applyOpDate = new Date(val.apply_start_time); // 报名开始时间
          let applyEndDate = new Date(val.apply_end_time); // 报名结束时间
          let nowTime = nowDate.getTime();  // 当前时间戳
          let startTime = startDate.getTime();  // 开始时间戳
          let endTime = endDate.getTime();  // 结束时间戳
          let applyOpTime = applyOpDate.getTime();  // 报名开始时间戳
          let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳
          val.signOpTime = false;
          if (applyOpTime == -62135625943000 || applyOpTime == -62135625600000 || val.apply_start_time == '0001-01-01T00:00:00' || val.apply_start_time == '0001-01-01T00:00:00+08:00' || val.apply_start_time == '') {
            val.signOpTime = false; // 未设置报名开始时间
          } else {
            val.signOpTime = true;
          }
          if (applyEndTime == -62135625943000 || applyEndTime == -62135625600000 || val.apply_end_time == '0001-01-01T00:00:00' || val.apply_end_time == '0001-01-01T00:00:00+08:00' || val.apply_end_time == '') {
            val.signEdTime = false; // 未设置报名结束时间
          } else {
            val.signEdTime = true;
          }
          if (nowTime - startTime > 0) {
            val.isApplySign = false;
            val.isStart = true; // 会议已开始
          } else {
            val.isStart = false;  // 会议未开始
            val.isApplySign = true;
          }
          //增加是否是活动判断
          if (val.is_activity) {
            if (endTime - nowTime > 0) {
              val.isApplySign = true;
            } else {
              val.isApplySign = false;
            }
          }
          if (nowTime - endTime > 0) {
            val.isEnd = true; // 会议已结束
          } else {
            val.isEnd = false; // 会议未结束
          }
          if (val.signOpTime) { // 有报名时间和报名结束时间
            if (nowTime - applyOpTime > 0) {
              val.isAppStart = true; // 报名已开始
            } else {
              val.isAppStart = false; // 报名未开始
            }
            if (nowTime - applyEndTime > 0) {
              val.isAppEnd = true; // 报名已结束
            } else {
              val.isAppEnd = false; // 报名未结束
            }
          }

          // loadState
          //现在 时间 小于开始时间  = 报名未开始
          if (nowTime < applyOpTime) {
            val.loadState = 1;
          }
          else if (applyOpTime < nowTime && nowTime < applyEndTime) {
            //现在时间大于 报名开始时间 && 现在时间小于报名结束时间
            //报名中
            val.loadState = 2;
          }
          else if (nowTime > applyEndTime) {
            //报名已经截止
            val.loadState = 3;
            if (startTime < nowTime && nowTime < endTime) {
              //现在时间 大于开始时间 && 开始时间小于结束时间 == 进行中
              val.loadState = 4;
            }
            else if (nowTime > endTime) {
              //已经结束
              val.loadState = 5;
            }
          }
          // console.log("name",val.name);
          // console.log("报名是否结束",val.isAppEnd);
          // console.log("会议是否结束",val.isEnd);
          // console.log("会议是否开始",val.isStart);
          // console.log("报名是否开始",val.isAppStart);

          //前面的状态
          // 1 可申请 state =null
          // 2 已申请state != null

          // 1报名已结束 val.isAppEnd=true
          // 正在进行 val.isStart && !val.isEnd
          // 已经结束 val.isEnd=true

          //操作按钮
          // 报名失败  只有认证和报名失败才显示  n.status=='not_passed'|| n.cooperation_status==3

          //报名失败按钮
          if (val.status == 'not_passed' || val.cooperation_status == 3) {
            val.showFaileBtn = true;
            val.FaileBtnText = "报名失败";
          }
          else {
            val.showFaileBtn = false;
            val.FaileBtnText = "";
          }

          //审核中 双选会审核中+合作审核中/合作通过 +报名未截止
          if (val.status == 'to_audit' && !val.isStart && (val.cooperation_status == 1 || val.cooperation_status == 2)) {
            val.showShenHeBtn = true;
            val.showShenHeBtnText = "审核中";
          }
          else if (val.status == "passed" && !val.isStart && val.cooperation_status == 1) {
            val.showShenHeBtn = true;
            val.showShenHeBtnText = "审核中";
          }
          else {
            val.showShenHeBtn = false;
            val.showShenHeBtnText = "";
          }

          //展位号+通知学生   合作通过 + 双选会通过 + 会议未结束 + 会议已开始
          if (val.status == 'passed' && val.cooperation_status == 2) {
            val.showSuccessBtn = true;
          }

          //我要报名  未申请双选会
          if (val.status == null) {
            val.showBaoMingBtn = true;
          }

          //如果已经显示了报名失败的按钮，则强制不显示《我要报名》的按钮
          if (val.showFaileBtn) {
            val.showBaoMingBtn = false;
          }

          //如果已经显示了《审核中》的按钮，则强制不显示《我要报名》的按钮
          if (val.showShenHeBtn) {
            val.showBaoMingBtn = false;
          }

          //合作通过+ 未报名双选会 +报名未结束
          if (val.status == null && val.cooperation_status == 2 && !val.isAppEnd) {
            val.showBaoMingBtn = true;
          }
          else if (val.status == null && val.cooperation_status == 0 && !val.isAppEnd) {
            val.showBaoMingBtn = true;
          }
          else if (val.status == "to_audit" && val.cooperation_status == 0 && !val.isAppEnd) {
            val.showBaoMingBtn = true;
          }
          else if (val.status == null && val.cooperation_status == 1 && !val.isAppEnd) {
            val.showBaoMingBtn = true;
          }
          else {
            val.showBaoMingBtn = false;
          }

          //报名开始 +双选会审核中/双选会未通过
          if (val.milkround_type == 1) {
            if (val.isStart && (val.status == "to_audit" || val.status == "not_passed")) {
              val.showBaoMingBtn = false;
              val.showShenHeBtn = false;
              val.showFaileBtn = true;
            }
          }
          if (val.milkround_type == 2) {
            if (val.isStart && (val.status == "to_audit" || val.status == "not_passed")) {
              if (val.status == "to_audit") {
                val.showBaoMingBtn = false;
                val.showShenHeBtn = true;
                val.showFaileBtn = false;
              } else if (val.status == "not_passed") {
                val.showBaoMingBtn = false;
                val.showShenHeBtn = false;
                val.showFaileBtn = true;
              }
              if (val.cooperation_status == 3) {
                val.showBaoMingBtn = false;
                val.showShenHeBtn = false;
                val.showFaileBtn = true;
              }
            }
          }

          //如果合作失败/并且没有申请过双选会=我要报名
          if (!val.status) {
            if (val.cooperation_status == 3) {
              val.showShenHeBtn = false;
              val.showFaileBtn = false;
              val.showBaoMingBtn = true;
            }
          }
          val.zone_name_string = '';
          if (val.zone_name) {
            val.zone_name_string = val.zone_name;
            if (val.seat_number) {
              val.zone_name_string = val.zone_name + '-';
            }
          }

          //feedback_status
          //no_feedback 未反馈
          //no_join  不参会
          //join 已参会

        }


      }
    }).catch((err) => {
      console.log("getMyMilkroundHistory", err);
    })
  }

  formatList(arr) {
    if (this.showTips) {
      for (let item of arr) {
        let nowDate = new Date();
        let startDate = new Date(item.start_time);
        let endDate = new Date(item.end_time);
        let applyOpDate = new Date(item.apply_start_time); // 报名开始时间
        let applyEndDate = new Date(item.apply_end_time); // 报名结束时间
        let nowTime = nowDate.getTime();  // 当前时间戳
        let startTime = startDate.getTime();  // 开始时间戳
        let endTime = endDate.getTime();  // 结束时间戳
        let applyOpTime = applyOpDate.getTime();  // 报名开始时间戳
        let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳
        if (nowTime - endTime > 0) {
          item.isEnd = true; // 会议已结束
        } else {
          item.isEnd = false; // 会议未结束
        }
      }
    }
    for (let val of arr) {
      let nowDate = new Date();
      let startDate = new Date(val.start_time);
      let endDate = new Date(val.end_time);
      let applyOpDate = new Date(val.apply_start_time); // 报名开始时间
      let applyEndDate = new Date(val.apply_end_time); // 报名结束时间
      let nowTime = nowDate.getTime();  // 当前时间戳
      let startTime = startDate.getTime();  // 开始时间戳
      let endTime = endDate.getTime();  // 结束时间戳
      let applyOpTime = applyOpDate.getTime();  // 报名开始时间戳
      let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳
      val.signOpTime = false;
      if (applyOpTime == -62135625943000 || applyOpTime == -62135625600000 || val.apply_start_time == '0001-01-01T00:00:00' || val.apply_start_time == '0001-01-01T00:00:00+08:00' || val.apply_start_time == '') {
        val.signOpTime = false; // 未设置报名开始时间
      } else {
        val.signOpTime = true;
      }
      if (applyEndTime == -62135625943000 || applyEndTime == -62135625600000 || val.apply_end_time == '0001-01-01T00:00:00' || val.apply_end_time == '0001-01-01T00:00:00+08:00' || val.apply_end_time == '') {
        val.signEdTime = false; // 未设置报名结束时间
      } else {
        val.signEdTime = true;
      }
      if (nowTime - startTime > 0) {
        val.isApplySign = false;
        val.isStart = true; // 会议已开始
      } else {
        val.isStart = false;  // 会议未开始
        val.isApplySign = true;
      }
      //增加是否是活动判断
      if (val.is_activity) {
        if (endTime - nowTime > 0) {
          val.isApplySign = true;
        } else {
          val.isApplySign = false;
        }
      }
      if (nowTime - endTime > 0) {
        val.isEnd = true; // 会议已结束
      } else {
        val.isEnd = false; // 会议未结束
      }
      if (val.signOpTime) { // 有报名时间和报名结束时间
        if (nowTime - applyOpTime > 0) {
          val.isAppStart = true; // 报名已开始
        } else {
          val.isAppStart = false; // 报名未开始
        }
        if (nowTime - applyEndTime > 0) {
          val.isAppEnd = true; // 报名已结束
        } else {
          val.isAppEnd = false; // 报名未结束
        }
      }
      // if(val.status ==null){
      //   val.status = 0
      // }

      // loadState
      //现在 时间 小于开始时间  = 报名未开始
      if (nowTime < applyOpTime) {
        val.loadState = 1;
      }
      else if (applyOpTime < nowTime && nowTime < applyEndTime) {
        //现在时间大于 报名开始时间 && 现在时间小于报名结束时间
        //报名中
        val.loadState = 2;
      }
      else if (nowTime > applyEndTime) {
        //报名已经截止
        val.loadState = 3;
        if (startTime < nowTime && nowTime < endTime) {
          //现在时间 大于开始时间 && 开始时间小于结束时间 == 进行中
          val.loadState = 4;
        }
        else if (nowTime > endTime) {
          //已经结束
          val.loadState = 5;
        }
      }
      // console.log("name",val.name);
      // console.log("报名是否结束",val.isAppEnd);
      // console.log("会议是否结束",val.isEnd);
      // console.log("会议是否开始",val.isStart);
      // console.log("报名是否开始",val.isAppStart);

      //前面的状态
      // 1 可申请 state =null
      // 2 已申请state != null

      // 1报名已结束 val.isAppEnd=true
      // 正在进行 val.isStart && !val.isEnd
      // 已经结束 val.isEnd=true

      //操作按钮
      // 报名失败  只有认证和报名失败才显示  n.status=='not_passed'|| n.cooperation_status==3

      //报名失败按钮
      if (val.status == 'not_passed' || val.cooperation_status == 3) {
        val.showFaileBtn = true;
        val.FaileBtnText = "报名失败";
      }
      else {
        val.showFaileBtn = false;
        val.FaileBtnText = "";
      }

      //审核中 双选会审核中+合作审核中/合作通过 +报名未截止
      if (val.status == 'to_audit' && !val.isStart && (val.cooperation_status == 1 || val.cooperation_status == 2)) {
        val.showShenHeBtn = true;
        val.showShenHeBtnText = "审核中";
      }
      else if (val.status == "passed" && !val.isStart && val.cooperation_status == 1) {
        val.showShenHeBtn = true;
        val.showShenHeBtnText = "审核中";
      }
      else {
        val.showShenHeBtn = false;
        val.showShenHeBtnText = "";
      }

      //展位号+通知学生   合作通过 + 双选会通过 + 会议未结束 + 会议已开始
      if (val.status == 'passed' && val.cooperation_status == 2) {
        val.showSuccessBtn = true;
      }

      //我要报名  未申请双选会
      if (val.status == null) {
        val.showBaoMingBtn = true;
      }

      //如果已经显示了报名失败的按钮，则强制不显示《我要报名》的按钮
      if (val.showFaileBtn) {
        val.showBaoMingBtn = false;
      }

      //如果已经显示了《审核中》的按钮，则强制不显示《我要报名》的按钮
      if (val.showShenHeBtn) {
        val.showBaoMingBtn = false;
      }

      //合作通过+ 未报名双选会 +报名未结束
      if (val.status == null && val.cooperation_status == 2 && !val.isAppEnd) {
        val.showBaoMingBtn = true;
      }
      else if (val.status == null && val.cooperation_status == 0 && !val.isAppEnd) {
        val.showBaoMingBtn = true;
      }
      else if (val.status == "to_audit" && val.cooperation_status == 0 && !val.isAppEnd) {
        val.showBaoMingBtn = true;
      }
      else if (val.status == null && val.cooperation_status == 1 && !val.isAppEnd) {
        val.showBaoMingBtn = true;
      }
      else {
        val.showBaoMingBtn = false;
      }

      //报名开始 +双选会审核中/双选会未通过
      if (val.milkround_type == 1) {
        if (val.isStart && (val.status == "to_audit" || val.status == "not_passed")) {
          val.showBaoMingBtn = false;
          val.showShenHeBtn = false;
          val.showFaileBtn = true;
        }
      }
      if (val.milkround_type == 2) {
        if (val.isStart && (val.status == "to_audit" || val.status == "not_passed")) {
          if (val.status == "to_audit") {
            val.showBaoMingBtn = false;
            val.showShenHeBtn = true;
            val.showFaileBtn = false;
          } else if (val.status == "not_passed") {
            val.showBaoMingBtn = false;
            val.showShenHeBtn = false;
            val.showFaileBtn = true;
          }
          if (val.cooperation_status == 3) {
            val.showBaoMingBtn = false;
            val.showShenHeBtn = false;
            val.showFaileBtn = true;
          }
        }
      }

      //如果合作失败/并且没有申请过双选会+ 报名未结束 =我要报名
      if (!val.status) {
        if (val.cooperation_status == 3 && !val.isAppEnd && val.milkround_type == 1) {
          val.showShenHeBtn = false;
          val.showFaileBtn = false;
          val.showBaoMingBtn = true;
          // console.log('报名是否结束',val.name,val.isAppEnd)

        }
      }
      val.zone_name_string = '';
      if (val.zone_name) {
        val.zone_name_string = val.zone_name;
        if (val.seat_number) {
          val.zone_name_string = val.zone_name + '-';
        }
      }

      //feedback_status
      //no_feedback 未反馈
      //no_join  不参会
      //join 已参会

    }
  }

  //获取高校性质school_type
  async getSchoolType() {
    const res: any = await this.dataApi.getDictDate('school_type');
    if (res) {
      this.config.school_type = res;
    }
  }

  //进入面试间
  goRoom(item) {
    //开始时间提前十分可以入场
    let online_interview_start_time = moment(item.online_interview_start_time).subtract(10, 'm').format();
    //是否开始
    if (!moment(online_interview_start_time).isBefore(new Date())) {
      this.toastr.warning("未到面试间时间");
      return
    }

    //是否结束
    if (!moment(new Date()).isBefore(item.online_interview_end_time)) {
      this.toastr.warning("面试间已到期");
      return
    }

    this.router.navigateByUrl('/home/interview?roomid=' + item.roomid);
    this.local.set('roomid', item.roomid);//覆盖本地存储招聘会id
  }


  //新增高校
  pushCollege() {
    this.isShowPushCollegeModel = true;
    this.pushCollegeModel.initData();
  }

  pushCollegeModelHidden(event) {
    this.isShowPushCollegeModel = false;
    this.mycollegeList();
  }

  //获取合作高校
  mycollegeList() {
    this.dataApi.mycollegeList().then((res: any) => {
      if (res) {
        this.addCollegeList = res;
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  gocampustalk() {
    this.router.navigate(['home/college-campustalk1']);
  };

  // 撤回
  openRevoke(applyid) {
    this.revokeApplyid = applyid;
    this.revokeModule = true;
  }

  downMilk() {
    this.dataApi.revokeMilk(this.revokeApplyid).then((res: any) => {
      this.getMyMilkroundHistory();
      this.revokeModule = false;
    }).catch((err) => {
      console.log("downMilk", err);
    });
  }

  timeBefore(endTime) {
    return moment(new Date()).isBefore(endTime)
  }
}
