import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ConfirmComponent } from '../app/confirm/confirm.component';
import { ConfirmsComponent } from '../app/confirms/confirms.component';
import { CloudtipComponent } from '../app/cloudtip/cloudtip.component';

import { MatDialog } from '@angular/material/dialog';
import { Config } from './config';
import { Constants } from './constants';
import { DataApi } from './dataApi';
import { Local } from './local';
import { MyMMessage } from './myMMessage';

import { DomSanitizer } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router, RoutesRecognized
} from '@angular/router';
import { NoneVipAlertComponent } from "../app/none-vip-alert/none-vip-alert.component";
import { CheckMemberModelComponent } from "../app/check-member-model/check-member-model.component";

declare let window;
@Injectable()
export class Opera {

  //记录是一级导航的的路由，检测到路由跳转时辨别是否是主菜单，主菜单的路由不用处理，直接保存的本地，
  public MainRouteList = ['/home/tool-campus', '/home/tool-my', '/home/workSpase', "/home/position-manage", "/home/college-resume", "home/chat-collection", "/home/searchPeople", "/home/position-resume",
    "/home/position-resume-like", "/home/college-milkround", "/home/college-campustalk1", "/home/college-campustalk", "/company-authentication-wait", "/home/notification-center", "/home/company-details"
    , "/home/company-account", "/login", "/home/company-authentication-suc", "/home/chat-collection", "/home/voiceCallRecord", "/home/positionPushRecord", "/home/activityInviteRecord", "/home/airCampusDetail",
    "/home/airCampHistory", "/home/buyLaCoin", "/home/paymentorderlist", "/home/payInvoice", "/home/airCampList", "/home/rights"
  ];

  private config: Config;
  public resumeDetails: any;//简历详情
  public position: any;//职位详情
  public college: any;//高校详情
  constructor(
    private dataApi: DataApi,
    private local: Local,
    public dialog: MatDialog,
    private router: Router,
    private sanitizer: DomSanitizer,
    private location: Location,
    // public cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    public mimc: MyMMessage,

    public toastr: ToastrService) {
    //获取单例config
    this.config = Config.getInstance();

    //监听路由变化
    router.events.subscribe((Event) => {
      if (Event instanceof NavigationStart) {
        // console.log('监听路由变化开始',Event.url);
        this.NavigationStart(Event.url)
      } else if (Event instanceof NavigationEnd) {
        // console.log('监听路由变化',Event);
      } else if (Event instanceof NavigationCancel) {
        //
      } else if (Event instanceof NavigationError) {
        //
      } else if (Event instanceof RoutesRecognized) {
        //
      }
    });
    //屏蔽线上的conslo输入
    this.setLineConslog();
    //获取企业信息
    this.getDepartment();
  }

  //提醒发布职位
  remindPublish(text) {
    this.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '至少有一个在期职位才可' + text,
        trueText: '发布职位',
        falseText: '再等等'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      this.scrollTrue();
      if (isConfirmed) {
        this.local.remove(Constants.positionid);
        this.router.navigate(['home/position-publish']);
      }
    });
  }

  //更新企业信息本地存储
  getDepartment() {
    let token = this.local.get(Constants.accesstoken);
    let accountid = this.local.get(Constants.accountid);
    if (!token || !accountid) {
      return;
    }
    this.loading();
    this.dataApi.getDepartment().then((res: any) => {
      this.loadiss();
      if (res) {
        this.dataApi.setLocalDepartment(res);
        this.config.department = res;
      }
    }).catch((error) => {
      this.loadiss();
      console.log(error);
      //this.toastr.error(error);
    });
  }
  //检查用户是否付费用户
  checkMemberPay() {
    this.local.set('isVip', false);
    this.dataApi.getEmployment().then((res: any) => {
      if (res) {
        if (res.member_info) {
          this.local.set('isVip', true)
        } else {
          this.local.set('isVip', false)
        }
      }
    }).catch((error) => {
      console.log(error);
      this.local.set('isVip', false)
      //this.toastr.error(error);
    });
  }

  //提醒企业认证结果
  remindIdentityResult(status) {
    let name, text;
    if (status == '2') {
      this.getDepartment();
      name = "认证成功";
      text = "您的企业认证已通过快去看看吧";
    } else if (status == '3') {
      name = "认证失败";
      text = "您的企业认证未通过快去看看吧";
    }
    this.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: name,
        message: text,
        trueText: '去看看',
        falseText: '再等等'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      this.scrollTrue();
      if (isConfirmed) {
        this.config.lastIdentity.status = status;
        this.dataApi.setLastIdentity(this.config.lastIdentity);
        //跳转认证页面
        this.goplatformIdentity();
      }
    });
  }

  //检测企业认证信息
  checkPlatformIdentity() {
    this.dataApi.getPlatformApply().then((res: any) => {
      if (res) {
        this.config.lastIdentity = res;
        if (res.status == '2') {
          if (this.config.department) {
            this.config.department.is_certified = true;
            this.dataApi.setLocalDepartment(this.config.department);
          }
        }
        this.config.lastIdentity = res;
        this.dataApi.setLastIdentity(this.config.lastIdentity);
      }
    })
  }

  //验证企业认证信息跳转
  goplatformIdentity() {
    this.dataApi.getPlatformApply().then((res: any) => {
      if (res) {
        this.config.lastIdentity = res;
        this.goIdentityPage(res);
      }
    })
  }

  //跳转不同页面判断
  goIdentityPage(res) {
    if (res) {
      this.config.lastIdentity = res;
      this.dataApi.setLastIdentity(this.config.lastIdentity);
      if (res.status == 2) {//已通过
        this.config.department.is_certified = true;
        this.dataApi.setLocalDepartment(this.config.department);
        this.router.navigateByUrl('/home/company-authentication-suc');//审核通过
      } else if (res.status == 1) {//审核中
        this.router.navigateByUrl('/home/company-authentication-wait');
      } else if (res.status == 3) {//审核失败
        this.router.navigateByUrl('/home/company-authentication');//审核失败页面
      } else {//未提交
        this.router.navigateByUrl('/home/company-authentication');//介绍企业认证页面
      }
    }
  }

  //路由存储逻辑处理
  setRouterLocal(url) {
    let localUrl = this.local.get(Constants.newUrl);
    let oldUrl = this.local.get(Constants.oldUrl);
    if (!localUrl && !oldUrl) {//未存储路由
      this.local.set(Constants.newUrl, url);
    } else if (localUrl && !oldUrl) {//未填充上一级路由
      this.local.set(Constants.oldUrl, localUrl);
      this.local.set(Constants.newUrl, url);
    } else if (localUrl && oldUrl) {//当前路由及上级路由均存在
      if (url == localUrl) {//防止重复存储上级路由，重复时不覆盖上级路由
        this.local.set(Constants.newUrl, url);
        return;
      } else {//未重复时正常覆盖
        this.local.set(Constants.oldUrl, localUrl);
        this.local.set(Constants.newUrl, url);
      }
    }
  }

  //切换路由函数(返回操作)
  goChangeRouter(url) {
    console.log('返回弹窗，返回上一步后')
    if (!this.local.get(Constants.accesstoken)) {
      return;
    }
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '返回上一步后，当前页面填写内容将不会保存。',
        trueText: '继续返回',
        falseText: '取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      //console.log('检测是否存在返回值');
      //console.log(isConfirmed);
      if (isConfirmed) {
        let oldUrl = this.local.get(Constants.oldUrl);
        let localUrl = this.local.get(Constants.newUrl);
        let newUrl = '/home/' + url;
        if (!oldUrl) {//未存储路由
          this.router.navigateByUrl('/home/' + url);
        } else if (oldUrl) {
          let n = localUrl.indexOf('?');
          let m = oldUrl.indexOf('?');
          if (n > -1) {
            localUrl = localUrl.slice(0, n);
          }
          if (m > 1) {
            oldUrl = oldUrl.slice(0, m);
          }
          if (oldUrl == localUrl) {
            this.router.navigateByUrl('/home/' + url);
          } else {
            this.router.navigateByUrl(this.local.get(Constants.oldUrl));
          }
        }
      }
    });

  }

  //跳转路由操作（部分为定向跳转不再按照上一路由存储）
  goNewRouter(url) {
    let newUrl = '/home/' + url;
    this.router.navigateByUrl(newUrl);
  }

  // 路由返回
  goBack() {
    //window.history.go(-1);
    this.location.back();
    // console.log(this.location.back());
  }
  // 返回上一个页面
  goPre() {
    this.router.navigateByUrl(this.local.get(Constants.oldUrl));
  }

  //加载动画
  loading() {
    setTimeout(() => {
      this.config.loading = true;
      this.scrollTop();
    });
  }

  // 取消动画
  loadiss() {
    setTimeout(() => {
      this.config.loading = false;
      this.scrollTrue();
    });
  }

  //页面初始化时，赋值全局变量
  initConfig(data, data1) {
    console.log("页面初始化时，赋值全局变量", data, data1, this.config.isLogin, this.config.isGuide);
    setTimeout(() => {
      if (this.config.isLogin || this.config.isGuide) {
        this.config.isLogin = false;
        this.config.isGuide = false;
        console.log('this.config.isGuide', this.config.isGuide)
        if (this.local.get(Constants.accesstoken)) {
          console.log('乱入弹窗')
          // this.getUser();//获取用户信息
        }
      }
      this.config.isNavActive = data;
      this.config.isNavChildActive = data1;
      if (data == 'home') {
        this.config.childNavs = [
          { text: '推荐人才', nav: 'college-resume' },
          { text: '对我感兴趣', nav: 'chat-collection' },
        ]
      } else if (data == 'college') {
        this.config.isGoCollege = true;
        this.config.isGoPosition = false;
        this.config.isGoChat = false;
        this.config.isGoCompany = false;
        this.config.childNavs = [
          { text: '高校云', nav: 'college-campustalk' },
        ]
      } else if (data == 'member') {
        this.config.childNavs = [
          { text: '会员中心', nav: 'member-center' }
        ]
        //else if(data=='cloud'){
        //           this.config.childNavs = [
        //             {text:'免费高校云认证',nav:'company-authentication'}
        //           ]
        //         }
      } else if (data == 'position') {
        this.config.isGoPosition = true;
        this.config.isGoCollege = false;
        this.config.isGoChat = false;
        this.config.isGoCompany = false;
        this.config.childNavs = [
          { text: '职位管理', nav: 'position-manage' },
        ]
      } else if (data == 'rights') {
        this.config.isGoPosition = false;
        this.config.isGoCollege = false;
        this.config.isGoChat = false;
        this.config.isGoCompany = false;
        this.config.childNavs = [
          { text: '权益管理', nav: 'rights' },
        ]
      } else if (data == 'chat') {
        this.config.isGoChat = true;
        this.config.isGoCollege = false;
        this.config.isGoPosition = false;
        this.config.isGoCompany = false;
        this.config.childNavs = [
          { text: '消息提醒', nav: 'chat-contact' },
        ]
      } else if (data == 'company') {
        this.config.isGoCompany = true;
        this.config.isGoCollege = false;
        this.config.isGoPosition = false;
        this.config.isGoChat = false;
        this.config.childNavs = [
          { text: '公司管理', nav: 'company-details' },
          { text: '账号管理', nav: 'company-account' },
        ]
      } else if (data == 'workspase') {
        this.config.isGoCompany = true;
        this.config.isGoCollege = false;
        this.config.isGoPosition = false;
        this.config.childNavs = [
          { text: '工作台', nav: 'workSpase' },
        ]
      } else if (data == 'navSearch') {
        this.config.isGoCompany = true;
        this.config.isGoCollege = false;
        this.config.isGoPosition = false;
        this.config.childNavs = [
          { text: '搜索人才', nav: 'searchPeople' },
        ]
      } else if (data == 'nav-resume-manager') {
        this.config.isGoCompany = true;
        this.config.isGoCollege = false;
        this.config.isGoPosition = false;
        this.config.childNavs = [
          { text: '收到的', nav: 'position-resume' },
          { text: '收藏的', nav: 'position-resume-like' },
          { text: '极速电话联系记录', nav: 'voiceCallRecord' },
          { text: '职位推送记录', nav: 'positionPushRecord' },
          { text: '校招活动邀请记录', nav: 'activityInviteRecord' },
          { text: '空中宣讲会推广记录', nav: 'airCampHistoryReord' },
        ]
      }
      else if (data == 'nav-activity') {
        this.config.isGoCompany = true;
        this.config.isGoCollege = false;
        this.config.isGoPosition = false;
        this.config.childNavs = [
          { text: '双选会报名', nav: 'college-milkround' },
          { text: '宣讲会报名', nav: 'college-campustalk1' },
        ]
      }
      else if (data == 'nav-activity-air') {
        this.config.isGoCompany = true;
        this.config.isGoCollege = false;
        this.config.isGoPosition = false;
        if (data1 == 'college-milkround') {
          this.config.childNavs = [
            { text: '空中双选会报名', nav: 'college-milkround' },
          ]
        }
        if (data1 == 'college-milk-air-space') {
          this.config.childNavs = [
            { text: '空中双选会会场', nav: 'college-milk-air-space' },
          ]
        }
      }
      else if (data == 'notification') {
        this.config.isGoCompany = true;
        this.config.isGoCollege = false;
        this.config.isGoPosition = false;
        this.config.childNavs = [
          { text: '通知中心', nav: 'notification-center' },
        ]
      }
      else if (data == 'tool') {
        this.config.isGoCompany = true;
        this.config.isGoCollege = false;
        this.config.isGoPosition = false;
        this.config.childNavs = [
          // { text: this.config.isShowPaySystem ? '校招工具(必备)' : '校招方案', nav: 'tool-campus' },
          { text: '我的工具', nav: 'tool-my' },
          { text: '我的订单', nav: 'paymentorderlist' },
          { text: '我的发票', nav: 'payInvoice' },
          { text: '啦豆明细', nav: 'buyLaCoin' },
        ]
        this.local.set('nav_tool', data1);
      }
      else if (data == 'aircampus') {
        this.config.isGoCompany = true;
        this.config.isGoCollege = false;
        this.config.isGoPosition = false;
        this.config.childNavs = [
          { text: '空中宣讲会', nav: 'airCampList' },
          { text: '往期空中宣讲会', nav: 'airCampHistory' },

        ]
        this.local.set('nav_tool', data1);
      } else {
        this.config.childNavs = []
      }
      this.scrollTrue();
    });

    //滚动到顶部
    window.scrollTo(0, 0);
  }

  // 跳转登录
  fromEmployer() {
    console.log('fromEmployer');
    let path = this.location.path();
    let token = '?token=';
    let accid = '&accountid=';
    // 如果传过来的route 是注册，则跳转到注册页面

    if (path.includes('route=guide') && path.includes(token)) {
      let access_token = path.substr(path.indexOf(token) + 7, 32);
      this.dataApi.setLocalToken(access_token);
      this.router.navigateByUrl('guide');
      console.log('fromEmployer', path)
      return
    }
    if (path.includes(token) && path.includes(accid)) {
      let access_token = path.substr(path.indexOf(token) + 7, 32);
      let accountid = path.substr(path.indexOf(accid) + 11, 24);
      console.log(access_token);
      console.log(accountid);
      this.dataApi.setLocalToken(access_token);
      this.dataApi.setLocalAccountId(accountid);//将accounid存到本地、全局变量

      // this.dataApi.setLocalAccountId(accountid);//将accounid存到本地、全局变量
      this.dataApi.getDepartment().then((res: any) => {//通过tooken获取企业信息
        if (res) {//--------------------------------------企业信息存在（跳转首页）
          this.dataApi.setLocalAccountId(res.accountid);//将accounid存到本地、全局变量
          this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
          this.mimc.init(res.jm_username); //登陆mimc
          this.config.department = res;
          this.getUsers();
          let routeN = '&route='
          let route = path.substr(path.indexOf(routeN) + 7)
          // console.log(route);
          console.log('fromEmployer', res, path, route);

          if (path.includes(routeN) && route) {
            if (this.config.department) {
              this.router.navigateByUrl(`home/${route}`);
              setTimeout(() => {
                this.config.collectionTips = true;
              }, 1000);
            } else {
              this.router.navigateByUrl('home/workSpase');
            }
          } else if (path.includes(routeN) && !route) {
            this.getBoothList();
          } else {
            this.router.navigateByUrl('home/workSpase');
          }
        } else {//-------------------------------------企业信息不存在（进入引导）
          setTimeout(() => {
            this.toastr.info('请完善基本信息');
          }, 600);
          console.log('app-ment2-login', path)
          this.router.navigateByUrl('login');
        }
      }).catch((error) => {
        console.log(error);
      })
    }
  }

  // 侧面图
  getBoothList() {
    this.dataApi.getBoothList().then((res: any) => {
      console.log('广告-----------------');
      console.log(res);
      if (res) {
        for (let val of res) {
          //侧面图
          if (val.alias == "leftnav") {
            this.goActivityDetails(val);
          }
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  //点击跳转活动详情（双选会/宣讲会）
  goActivityDetails(data) {
    console.log(data);
    // console.log('123123123132132131232');
    if (data.activity_type == "milkround") {
      this.dataApi.getMilkDetails(data.activityid).then((rres) => {
        console.log("获取到会议信息--------------------------CollegeHome");
        console.log(rres);
        this.dataApi.lastCollegeAuthenticationIdentity(rres.collegeid).then((res: any) => {
          console.log("获取到高校信息--------------------------CollegeHome");
          console.log(res);
          this.dataApi.getPlatformApply().then((tres) => {
            console.log('最后一次认证信息');
            console.log(tres);
            this.config.lastIdentity = tres;
            console.log(this.config.lastIdentity);
            if (this.config.lastIdentity) {
              if (this.config.lastIdentity.status == 2) {
                if (res.status == 2 || res.is_activity) {
                  this.local.set(Constants.mrid, data.activityid);
                  this.router.navigateByUrl('/home/college-milkdetails');
                  setTimeout(() => {
                    this.config.collectionTips = true;
                  }, 1000);
                } else {
                  this.toastr.warning('请先通过该校合作申请');
                  this.router.navigateByUrl('home/college-campustalk');
                }
              } else {
                this.toastr.warning('请先通过工作啦认证');
                this.router.navigateByUrl('home/company-authentication');
              }
            }
          })
        }).catch((err) => {
          console.log(err);
        })
      }).catch((err) => {
        // console.log(err);
        this.toastr.warning('双选会已删除或不存在')
      })
    } else if (data.activity_type == "campustalk") {
      // this.navCtrl.push('CampusInfo',{ctid:data.activityid});
    }
  }

  // 是否拥有权益
  isEquity(name) {
    let aliasList = [];
    if (this.config.department && this.config.department.quota.product_component_info) {
      for (let i = 0; i < this.config.department.quota.product_component_info.length; i++) {
        aliasList.push(this.config.department.quota.product_component_info[i].alias)
      }
    }
    return (aliasList.indexOf(name) != -1);
  }

  // 云认证弹窗
  remindCould() {
    if (this.config.lastIdentity) {
      console.log("lastIdentity", this.config.lastIdentity, this.config.positionList)
      // if(this.config.positionList['list'].length < 1){
      if (this.config.positionList) {
        if (this.config.positionList.length < 1) {
          let model = {
            limit: 100,
            offset: 0,/*  */
            status: 'release'
          };
          this.dataApi.getPositionList(model).then((res: any) => {
            this.config.positionList = res;
          }).catch((error) => {
          });
        }
      }
      console.log("lastIdentity", this.config.lastIdentity)
      if (this.config.lastIdentity && this.config.lastIdentity.status == 0) {
        this.scrollTop();//页面滚动函数
        this.dialog.open(CloudtipComponent, {
          data: {
            title: '工作啦认证',
            message: '',
            trueText: '去认证',
            falseText: '等一等'
          }
        }).afterClosed().subscribe((data) => {
          this.scrollTrue();
          console.log(data);
          if (data == true) {

            if (this.config.positionList.length == 0) {
              this.toastr.warning('请至少发布一个上线的职位，再进行工作啦认证');
              this.router.navigateByUrl("home/position-manage");//发布职位
            } else {
              this.router.navigateByUrl("home/company-authentication");//介绍企业认证页面
            }
          } else if (data == false) {
            console.log('关闭')
          }
        });
      } else if (this.config.lastIdentity['status'] == 1) {
        console.log(3);
        this.toastr.warning('认证审核中');
      } else if (this.config.lastIdentity['status'] == 3) {
        console.log(4);
        this.toastr.warning('认证失败，请查看原因并重新提交');
        setTimeout(() => {
          if (this.config.positionList.length == 0) {
            this.toastr.warning('请至少发布一个上线的职位，再查看原因并重新提交认证');
            this.router.navigateByUrl("home/position-manage");//发布职位
          } else {
            this.router.navigateByUrl("home/company-authentication");//介绍企业认证页面
          }
        }, 1200);
      }
    }
  }


  // 会员弹窗
  memberAlert() {
    this.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmsComponent, {
      data: {
        title: '开通会员',
        message: `<h3>开通会员，尊享更多权益</h3><img src="assets/images/vipAlert.png" alt="">`,
        trueText: '点击开通',
        falseText: ''
      }
    }).afterClosed().subscribe((data) => {
      this.scrollTrue();
      if (data) {
        this.router.navigateByUrl("home/tool-campus");//介绍企业认证页面
      }
    });
  }

  getUsers() {
    this.dataApi.getUser().then((res: any) => {
      if (res) {
        setTimeout(() => { this.config.loginUser = res; this.dataApi.setLocalLoginUser(res); })
      }
    }).catch((error) => {
      //this.toastr.error(error);
    });
  }



  //获取用户信息(页头显示hr姓名)
  getUser() {
    if (this.dataApi.getLocalLoginUser()) {
      //console.log("本地存储hr信息------------------------aboutsettinguser");
      //console.log(this.dataApi.getLocalLoginUser());
      setTimeout(() => { this.config.loginUser = this.dataApi.getLocalLoginUser(); })
    } else {
      this.dataApi.getUser().then((res: any) => {
        //console.log("线上数据hr信息------------------------aboutsettinguser");
        //console.log(res);
        if (res) {
          setTimeout(() => { this.config.loginUser = res; })
        }
      }).catch((error) => {
        //this.toastr.error(error);
      });
    }
  }



  //打开弹窗时，页面滚动到头部
  scrollTop(): void {
    // let body1=document.getElementsByTagName('html')[0];
    // body1.scrollTop=50;
    let body = document.getElementsByTagName('body')[0];
    body.setAttribute('style', 'padding-right: 17px;overflow: hidden');
    // body.setAttribute('style', 'overflow: hidden,padding-right: 17px');
  }
  //打开弹窗时，页面滚动到头部
  scrollTop1(): void {
    let body1 = document.getElementsByTagName('html')[0];
    body1.scrollTop = 0;
    // let body=document.getElementsByTagName('body')[0];
    // body.setAttribute('style', 'padding-right: 17px;overflow: hidden');
    // body.setAttribute('style', 'overflow: hidden,padding-right: 17px');
  }
  //允许页面滚动
  scrollTrue() {
    let body = document.getElementsByTagName('body')[0];
    body.setAttribute('style', 'overflow: auto');
    // body.setAttribute('style', 'padding-right: 17px');

  }


  //职位下线时更新在期职位本地存储
  setPositionListByExpired(val) {
    let index;
    this.config.positionList = this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
    if (this.config.positionList) {
      for (let n of this.config.positionList) {
        if (n.positionid == val) {
          index = this.config.positionList.indexOf(val);
        }
      }
    }

    this.config.positionList.splice(index, 1);
    this.dataApi.setLocalpositionList(this.config.positionList);
    let d = this.dataApi.getLocalDepartment();
    d.quota.position++;
    console.log('职位下线后企业可发布职位数量');
    console.log(d.quota.position);
    this.dataApi.setLocalDepartment(d);//更新企业可发布职位数量本地存储
  }
  //职位删除时时更新在期职位本地存储
  setPositionListByDelete(val) {
    let index;
    this.config.positionList = this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
    if (this.config.positionList) {
      for (let n of this.config.positionList) {
        if (n.positionid == val) {
          index = this.config.positionList.indexOf(val);
        }
      }
    }

    this.config.positionList.splice(index, 1);
    this.dataApi.setLocalpositionList(this.config.positionList);
    let d = this.dataApi.getLocalDepartment();
    d.quota.position++;
    console.log('职位删除后企业可发布职位数量');
    console.log(d.quota.position);
    this.dataApi.setLocalDepartment(d);//更新企业可发布职位数量本地存储
  }

  //发布职位&职位重发时更新在期职位本地存储
  setPositionListByRelease() {
    this.loading();
    let model = { limit: 100, offset: 0, status: 'release' };
    this.dataApi.getPositionList(model).then((res: any) => {
      //console.log("在期职位获取线上数据-----opera");
      //console.log(res);
      this.loadiss();
      if (res) {
        this.dataApi.setLocalpositionList(res.list);
        let d = this.dataApi.getLocalDepartment();
        d.quota.position = d.quota.position_total - res.count;
        console.log('发布职位&职位重发后企业可发布职位数量');
        console.log(d.quota.position);
        this.dataApi.setLocalDepartment(d);//更新企业可发布职位数量本地存储
      }

    }).catch((error) => {
      this.loadiss();
      this.toastr.error(error);
    });
  }

  //获取职位详情
  getPosition(data) {
    let isRelease = false;
    for (let val of this.config.positionList) {
      if (data == val.positionid) {
        this.position = val;
        isRelease = true;
        return;
      }
    }
    if (!isRelease) {
      this.getPositionList();//职位过期时
    }
  }

  //获取职位列表数据(第一次沟通需要发送职位)
  getPositionList() {
    //console.log("在期职位本地存储-----opera");
    //console.log(this.config.positionList);
    if (this.config.positionList.length > 0) {
      this.position = this.config.positionList[0];
    } else {
      let model = {
        limit: 100,
        offset: 0,
        status: 'release'
      }
      this.dataApi.getPositionList(model).then((res: any) => {
        if (res) {
          this.dataApi.setLocalpositionList(res.list);
          this.position = this.config.positionList[0];
        }
      }).catch((error) => {
        //this.toastr.error(error);
      });
    }
  }

  //判断当前是否存在会话列表
  checkChatList() {
    if (!this.config.chatsList) {
      this.dataApi.getImDialogueList({}).then((res: any) => {
        //console.log("获取会话列表--------opera");
        //console.log(res);
        this.dataApi.setLocalChatsList(res);//会话列表本地存储
      }).catch((error) => {
        console.log(error);
        //this.toastr.error(error);
      });
    }
  }

  //立即沟通功能封装
  goChat(details, position) {
    console.log("立即沟通检查参数（详情&职位id）------opera");
    console.log(details);
    console.log(position);
    this.config.positionList = this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
    this.config.chatsList = this.dataApi.getLocalChatsList();//赋值会话列表本地存储
    this.checkChatList();//检查会话列表
    if (position) {//是否有职位id
      this.getPosition(position);//获取职位详情
    } else {
      this.getPositionList();
    }
    this.resumeDetails = details;
    //判断权限
    if (this.resumeDetails.jm_username) {//判断是否需要扣除资源(会话权限,与下面的遍历可能有冲突,暂时不影响,后续优化)
      console.log("不需要获取极光资源------opera");
      this.goContactChat();
    } else {
      console.log("需要获取极光资源-------opera", this.resumeDetails);
      this.dataApi.getChatIm({ accountid: this.resumeDetails.talentid, content: this.resumeDetails.collegeid }).then((res: any) => {
        console.log("极光资源获取成功---------opera", res);
        //console.log(res);
        this.resumeDetails.jm_username = res.jm_username;
        this.goContactChat();
      }).catch((error: any) => {
        console.log("极光资源获取失败--------opera", error);
        console.log(error);
        if (error.Errcode == '2001') { // 配额不够未认证
          if (this.config.lastIdentity && this.config.lastIdentity.status == 0) {
            this.remindCould();
          } else {
            if (this.config.department.member_info) {
              this.toastr.warning('直聊人数已达上限，请联系小秘书');
            } else {
              // this.toastr.success('立即购买会员，立享更多权益');
              // this.memberAlert();
            }
          }
        } else if (error.Errcode == '2002') { // 未通过认证
          this.remindCould();
        } else if (error.Errcode == '2005') { // 配额不够已认证
          if (this.config.department.member_info) {
            this.toastr.warning('直聊人数已达上限，请联系小秘书');
          } else {
            // this.toastr.success('立即购买会员，立享更多权益');
            // this.memberAlert();
          }
        } else {
          this.toastr.warning(error);
        }
      });
    }
  }

  //50次超限企业认证
  goCallChatEidentity() {
    this.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '当前还可沟通0人，完成企业认证后可增加至100人',
        trueText: '立即认证',
        falseText: '暂不沟通'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      //console.log('返回值');
      //console.log(isConfirmed);
      this.scrollTrue();
      if (isConfirmed) {
        //跳转认证页面
        this.goplatformIdentity();
      }
    });
  }

  //提醒校招和企业认证
  remindCollegeIdentity(text, id, identity) {
    this.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '高校合作通过后，才可' + text,
        trueText: '申请合作',
        falseText: ''
      }
    }).afterClosed().subscribe((isConfirmed) => {
      console.log('返回值');
      console.log(isConfirmed);
      this.scrollTrue();
      if (isConfirmed) {
        this.local.set(Constants.collegeid, id);
        this.router.navigateByUrl("/home/company-identity-details");
      }
    });
  }


  // 高校云认证  ?: 参数非必传
  freeAttestation(text, id?: any, identity?: any) {
    this.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: text,
        trueText: '去认证',
        falseText: ''
      }
    }).afterClosed().subscribe((isConfirmed) => {
      console.log('返回值');
      console.log(isConfirmed);
      this.scrollTrue();
      if (isConfirmed) {
        this.router.navigateByUrl("/home/company-authentication");
      }
    });
  }

  //提醒企业认证
  remindDepartmentIdentity(id, identity) {
    this.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '一次认证，直通全网高校',
        trueText: '去认证',
        falseText: '再等等'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      //console.log('返回值');
      //console.log(isConfirmed);
      this.scrollTrue();
      if (isConfirmed) {
        //跳转认证页面
        this.goplatformIdentity();
      }
    });
  }

  //去沟通页面
  goContactChat() {
    if (this.config.chatsList) {
      let chatsList = this.config.chatsList.list;
      //console.log("判断当前有无会话列表------opera");
      //console.log(chatsList);
      if (chatsList) {//判断当前有无会话列表
        if (chatsList.length > 0) {
          let isInDialog = false;//当前人才是否已在会话列表中
          chatsList.forEach((val) => {
            if (val.dialogue_accountid == this.resumeDetails.talentid) {
              isInDialog = true;
              this.local.remove(Constants.chatPosition);//移除职位参数
              this.local.set(Constants.chatsInfo, JSON.stringify(val));//聊天记录本地存储参数
              this.router.navigate(['home/chat-contact']);
            }
          });
          if (!isInDialog) {
            this.goContactChatHandle();//创建会话
          }
        } else {
          this.goContactChatHandle();//创建会话
        }
      } else {
        this.goContactChatHandle();//创建会话
      }
    }

  }

  //--------去小秘书聊天窗口
  goChats(name) {
    if (this.config.chatsList) {
      console.log(this.config.chatsList);
      for (let val of this.config.chatsList.list) {
        if (val.dialogue_jmusername == name) {
          this.local.remove(Constants.chatPosition);//移除职位参数
          this.local.set(Constants.chatsInfo, JSON.stringify(val));//聊天记录本地存储参数
          this.router.navigate(['home/chat-contact']);
        }
      }
    } else {
      this.getNewChatList(name);
    }
  }

  //----更新会话列表(线上数据)
  getNewChatList(name) {
    //获取数据
    this.dataApi.getByJmChatList(name).then((res: any) => {
      console.log("获取会话信息-------------------opera");
      console.log(res);
      if (res) {
        this.local.remove(Constants.chatPosition);//移除职位参数
        this.local.set(Constants.chatsInfo, JSON.stringify(res));//聊天记录本地存储参数
        this.router.navigate(['home/chat-contact']);
      }
    }).catch((error) => {
      console.log(error);
      //this.toastr.error(error);
    });
  }


  //去沟通页面前携带参数处理(初次沟通的情况)
  goContactChatHandle() {
    //console.log("新会话去沟通页面之前检查职位参数--opera");
    //console.log(this.position);
    let userInfo = {
      personal_avater: this.resumeDetails.logo,
      resume_number: this.resumeDetails.resume_number,
      resumeid: this.resumeDetails.resumeid,
      talent_name: this.resumeDetails.talent_name,
      talentid: this.resumeDetails.talentid,
      jm_username: this.resumeDetails.jm_username
    }
    //console.log("新会话去沟通页面之前检查参数--opera");
    //console.log(userInfo);
    if (this.config.positionList.length == 0) {
      this.remindPublish('在线直聊');
    } else {
      this.config.isNavActive == 'college';
      this.config.isNavChildActive == 'college-resume';
      this.local.set(Constants.chatPosition, JSON.stringify(this.position));//移除职位参数
      this.local.set(Constants.userInfo, JSON.stringify(userInfo));//聊天记录本地存储参数
      this.router.navigate(['home/chat-contact']);
    }
  }
  //收藏简历
  manageResumeArchived(val, callback?: any) {
    this.resumeDetails = val;
    if (!this.resumeDetails.isfollow_reusme) {
      this.dataApi.manageResumeArchived({ resumeid: this.resumeDetails.resumeid }).then((res: any) => {
        ////console.log(res);
        this.resumeDetails.isfollow_reusme = true;
        setTimeout(() => {
          this.toastr.success('收藏成功');
        }, 600);
        if (callback) { callback() }
        return this.resumeDetails;
      }).catch((error) => {
        console.log(error);
        setTimeout(() => {
          this.toastr.error(error);
        }, 600);
        return this.resumeDetails;
      });
    } else {
      this.dataApi.manageResumeArchivedDelete(this.resumeDetails.resumeid).then((res: any) => {
        ////console.log(res);
        this.resumeDetails.isfollow_reusme = false;
        setTimeout(() => {
          this.toastr.success('取消收藏成功');
        }, 600);
        return this.resumeDetails;
      }).catch((error) => {
        console.log(error);
        //loader.dismiss();
        setTimeout(() => {
          this.toastr.error(error);
        }, 600);
        return this.resumeDetails;
      });
    }
  }


  //订阅高校的公众号
  CollegeRead(val) {
    this.college = val;
    if (this.college.is_subscribe) {
      this.unCollegeAccount();
    } else {
      this.getCollegeAccount();
    }
  }

  //订阅高校的公众号
  getCollegeAccount() {
    this.dataApi.getCollegeAccount(this.college.collegeid).then((res: any) => {
      this.college.is_subscribe = true;
      this.toastr.success("订阅成功");
      return this.college;
    }).catch((err) => {
      this.toastr.error("订阅操作失败，请稍后重试");
      return this.college;
    })
  }
  //取消订阅高校的公众号
  unCollegeAccount() {
    this.dataApi.unCollegeAccount(this.college.collegeid).then((res: any) => {
      this.toastr.success("取消订阅成功");
      return this.college;
    }).catch((err) => {
      this.toastr.error("订阅操作失败，请稍后重试");
      return this.college;
    })
  }

  //获取企业认证信息(app.ts获取企业信息，此处只需判断是否需要获取企业认证信息)
  checkIdentity() {
    if (this.config.department && this.config.department.is_certified) {
      return true;
    } else {
      this.dataApi.getPlatformApply().then((res: any) => {
        //console.log("获取到企业认证信息------------------------opera");
        //console.log(res);
        if (res) {
          this.config.lastIdentity = res;
          this.dataApi.setLastIdentity(this.config.lastIdentity);
          if (res.status == 2) {
            this.config.department.is_certified = true;
            this.dataApi.setLocalDepartment(this.config.department);
            return true;
          } else {
            return false;
          }
        }
      }).catch((error) => {
        console.log(error);
        //this.toastr.error(error);
        return false;
      })
    }
  }

  isIE() {
    let userAgent = window.navigator.userAgent; //取得浏览器的userAgent字符串
    //console.log("浏览器类型及版本");
    //console.log(userAgent);
    //console.log(window.navigator.appName);
    let isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE浏览器
    let is360 = userAgent.toLowerCase().indexOf("360se") > -1;//判断是否360浏览器
    let isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
    let isChrome = userAgent.indexOf("Chrome") > -1; //判断是否Chrome浏览器
    if (((/Trident\/7\./).test(userAgent))) {//判断是否IE11浏览器
      //  alert('IE11');
    }
    if (isIE) {
      // alert("IE");
    }
    // if (is360) {
    //   alert('360');
    // }
    // if (isFF) {
    //   alert('Firefox');
    // }
    // if (isChrome) {
    //     alert('Chrome');
    // }
  }

  //跳转提交认证资料页面
  goIdentityDetails(id) {
    this.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '经高校合作通过后，才可使用此功能',
        trueText: '去申请',
        falseText: '再等等'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      //console.log('返回值');
      //console.log(isConfirmed);
      this.scrollTrue();
      if (isConfirmed) {
        //跳转认证页面
        this.local.set(Constants.collegeid, id);
        this.router.navigateByUrl("/home/company-identity-details");
      }
    });
  }

  //跳转页面
  goCollegePage(page, id, mrId, resumeId, rs) {
    this.getCollege(id);//获取高校信息
    if (page == 'milk') {//跳转双选会详情
      this.local.set(Constants.mrid, mrId);
      this.local.set(Constants.typeUrl, '/home/college-milkdetails');
    } else if (page == 'campus') {//跳转高校宣讲会
      this.local.set(Constants.typeUrl, '/home/college-details-campus');
    } else if (page == 'collegeMilk') {//跳转高校双选会列表
      this.local.set(Constants.typeUrl, '/home/college-details-milk');
    } else if (page == 'positionPublish') {//发布职位
      this.local.remove(Constants.positionid);
      this.local.set(Constants.typeUrl, '/home/position-manage');
    } else if (page == 'resume') {//简历详情
      this.local.set(Constants.resumeid, resumeId);
      this.local.set(Constants.typeUrl, '/home/college-resumedetails');
    } else if (page == 'login') {//企业登陆
      this.local.set(Constants.typeUrl, '/home/college-resume');
      if (rs) {
        this.local.set(Constants.loginRs, rs);
      }
    }

  }

  //提醒
  sendTips(text) {
    this.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: text,
        trueText: '知道了',
        falseText: ''
      }
    }).afterClosed().subscribe(() => {
      this.scrollTrue();
    });
  }


  sendTipss(text) {
    this.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmsComponent, {
      data: {
        title: '提示',
        message: text,
        trueText: '知道了',
        falseText: ''
      }
    }).afterClosed().subscribe(() => {
      this.scrollTrue();
    });
  }

  // 自定义弹窗
  customTips(title, message, trueText, falseText, fn1, fn2) {
    this.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmsComponent, {
      data: {
        title: title,
        message: message,
        trueText: trueText || '知道了',
        falseText: falseText
      }
    }).afterClosed().subscribe((data) => {
      this.scrollTrue();
      if (data != undefined) {
        if (data) {
          if (fn1) {
            fn1()
          }
        } else {
          if (fn2) {
            fn2()
          }
        }
      }
    });
  }

  //获取高校信息并保存
  getCollege(id) {
    if (!id) {
      return;
    }
    this.local.set(Constants.collegeid, id);
    this.loading();
    this.dataApi.getCollageDetails(id).then((res: any) => {
      console.log("获取到高校信息---------");
      console.log(res);
      this.loadiss();
      if (res) {
        setTimeout(() => {
          this.config.isByCollege = true;
        });
        this.local.set(Constants.byCollegeDetails, JSON.stringify(res));
        this.config.byCollegeLogo = res.logo;
        this.config.byCollegeName = res.name;
      }
    }).catch((err) => {
      this.toastr.error(err);
      this.loadiss();
    });
  }

  //富文本过滤
  assembleHTML(strHTML: any) {
    return this.sanitizer.bypassSecurityTrustHtml(strHTML);
  }
  //屏蔽线上发布console的输出
  setLineConslog() {
    if (window.location.href) {
      if (window.location.href.indexOf("https://employer.goworkla.cn") >= 0) {
        //屏蔽线上发布console的输出
        window.console.log = function () { };
      }
    }
  }
  //是否是主页面
  isMainLevel(router) {
    if (!router) {
      return '路由错误';
    }
    let arr = router.split("?");
    // console.log('isMainLevel',arr);
    if (this.MainRouteList.indexOf(arr[0]) >= 0) {
      return true;
    }
    return false;
  }
  NavigationStart(url) {
    // console.log('isMainLevel',this.isMainLevel(url));

    //判断改变的路由是不是一级路由
    let isMain = this.isMainLevel(url)

    //本地路由转JSON
    let localJsonStr = this.local.get('routeList');
    let localJsonSList = JSON.parse(localJsonStr);
    //如果本地Local没有 赋初始值
    if (!localJsonSList) { localJsonSList = []; }

    //一级路由 [url] 只保留路由一级
    if (isMain) {
      localJsonSList = [url];
    } else {
      //多级路由页面 判断本地路由是否包含当前路由
      //如果包含，截取当前路由
      // 例如：[1,2,3,2,3,4,1,3,4] 如果4是当前的路由，截取后是[1,2,3,2,3,4];以第一次出现的路由结尾
      let turl_arr = url.split("?");
      let turl = turl_arr[0];

      let isHave = false;
      let isHave_index = 0;
      for (let index = 0; index < localJsonSList.length; index++) {
        let r = localJsonSList[index];
        if (r.indexOf(turl) >= 0) {
          isHave_index = index;
          isHave = true;
          break;
        }
      }
      let n = "/home/college-resumedetails?resumeid=LXM2SJ4M&positionid=";
      let m = "/home/college-resumedetails";
      console.log('turl', turl, localJsonSList, isHave, localJsonSList.indexOf(url) + 1, isHave_index);

      if (isHave) {
        localJsonSList = localJsonSList.slice(0, isHave_index + 1)
      } else {
        //如果不包含，把当前路由放到最后面
        localJsonSList = [...localJsonSList, url]
      }
    }
    this.local.set('routeList', JSON.stringify(localJsonSList))
  }

  //是否显示付费成功+认证成功功能
  isShowPaySuccessModule() {
    if (this.config.lastIdentity) {
      if (JSON.parse(this.local.get(Constants.department)).member_info) {
        let nowData = new Date();
        let overData = new Date(JSON.parse(this.local.get(Constants.department)).member_info.endtime);

        let department = JSON.parse(this.local.get(Constants.department));
        if (department.authentication_endtime) {
          let authentication_endtimeData = new Date(department.authentication_endtime);
          if (nowData > authentication_endtimeData) {
            return false;
          }
        }
        return nowData <= overData && this.config.lastIdentity.status == 2;
      } else {
        return false
      }
    } else {
      return false;
    }
  }
  //是否显示付费成功+认证成功功能 同上
  isShowPaySuccessModule1() {
    if (this.config.lastIdentity) {
      if (JSON.parse(this.local.get(Constants.department))) {
        let nowData = new Date();
        let department = JSON.parse(this.local.get(Constants.department));
        if (department.authentication_endtime) {
          let authentication_endtimeData = new Date(department.authentication_endtime);
          if (nowData > authentication_endtimeData) {
            return false;
          }
        }
        if (!department.member_info) {
          return false
        }
        if (department.member_info) {
          if (department.member_info.product_code == 'member_welfare') {
            return false
          }
          let endtime = new Date(department.member_info.endtime);
          if (nowData >= endtime) {
            return false
          }
        }
        return this.config.lastIdentity.status == 2;
      } else {
        return false
      }
    }
    else {
      return false;
    }
  }

  //项目内使用单品前的验证
  //callback 回掉，是否购买会员
  checkMember(callback?: any, isAlert?: any, isPosition?: any) {
    let alert = true;
    if (isAlert != undefined) {
      alert = isAlert
    }
    //检测用户会员状况
    this.dataApi.getEmployment().then((res: any) => {
      if (res) {
        this.dataApi.setLocalDepartment(res);
        let department = res;
        let nowData = new Date();
        if (department.member_info) {
          if (department.member_info.product_code == 'member_welfare') {
            if (!isPosition) {
              department.member_info = null;
              this.config.isMember_welfare = true
            }
          }
        }

        console.log('checkMember', department, isPosition);
        if (department.member_info) {
          if (department.member_info.product_code == 'member_trial') {
            this.config.isMember_trial = true;
            console.log('isPosition', isPosition)
          }
          let endtime = new Date(department.member_info.endtime);
          if (nowData >= endtime) {
            // console.log('用户买了会员,用户会员过期')
            this.config.isMember_trial = false
            callback(false);
          }

          if (endtime > nowData) {
            // console.log('用户买了会员,用户会员未过期')
            this.dataApi.getPlatformApply().then((res: any) => {
              if (res) {
                // console.log('用户买了会员,用户会员未过期getPlatformApply',res)
                if (res.status == 0) {
                  if (alert) {
                    this.checkMemberAlertWithType("no_audit")
                  }
                } else if (res.status == 1) {
                  if (alert) {
                    this.checkMemberAlertWithType("audit")
                  }
                } else if (res.status == 3) {
                  if (alert) {
                    this.checkMemberAlertWithType("fail")
                  }
                }
                // else if(department.authentication_endtime && res.status == 2) {
                //     let authentication_endtimeData = new Date(department.authentication_endtime);
                //     if(nowData>authentication_endtimeData) {
                //       if(alert){
                //         this.toastr.warning('当前用户工作认证已过期')
                //         this.checkMemberAlertWithType("au_timeOut");
                //       }
                //       return;
                //     }else {
                //       callback(true)
                //     }
                // }
                else if (res.status == 2) {
                  callback(true)
                }
              }
            }).catch((error) => {
              console.log(error);
            });
          }
        }
        else {
          console.log('用户没有买会员');
          callback(false)
        }
      }
    }).catch((error) => {
      // callback(false)
    });
  }
  checkMemberNoneAlert(callback?: any) {
    this.dataApi.getPlatformApply().then((res: any) => {
      if (res) {
        console.log('用户买了会员,检测会员是否到期-1', res);
        if (res.status == 0) {
          // this.checkMemberAlertWithType("no_audit")
        } else if (res.status == 1) {
          // this.checkMemberAlertWithType("audit")
        } else if (res.status == 2) {
          //检测用户会员状况
          this.dataApi.getDepartment().then((res: any) => {
            if (res) {
              this.dataApi.setLocalDepartment(res);
              let department = res;
              let nowData = new Date();
              if (department.authentication_endtime) {
                let authentication_endtimeData = new Date(department.authentication_endtime);
                if (nowData > authentication_endtimeData) {
                  // this.toastr.warning('当前用户工作认证已过期')
                  // this.checkMemberAlertWithType("au_timeOut");
                  return;
                }
              }
              if (department.member_info) {
                let endtime = new Date(department.member_info.endtime);
                if (nowData >= endtime) {
                  console.log('用户买了会员,用户会员过期');
                  callback(false);
                }
                if (endtime > nowData) {
                  console.log('用户买了会员,用户会员未过期');
                  callback(true)
                }
              }
              else {
                console.log('用户没有买会员');
                callback(false)
              }

            }
          }).catch((error) => {
            callback(false)
          });
        } else if (res.status == 3) {
          // this.checkMemberAlertWithType("fail")
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  vipState(callback?: any) {
    //getDepartment
    this.dataApi.getDepartment().then((res: any) => {
      let department = res;
      if (department.member_info) {
        let nowData = new Date();
        //活动截止时间
        let overData = new Date(department.member_info.endtime);
        //90天到期提醒
        let spaceBe = 90 * 24 * 60 * 60 * 1000;
        if ((overData.getTime() - nowData.getTime()) > spaceBe) {
          callback(true)
        } else if (nowData.getTime() > overData.getTime()) {
          callback(false)
        } else {
          callback(true)
        }
      }else{
        callback(false)
      }
    })
  }

  //callback 回掉，是否购买会员
  checkMemberTalent(callback?: any) {
    //checkMemberPayWithId
    this.dataApi.getPlatformApply().then((res: any) => {
      if (res) {
        console.log('用户买了会员,检测会员是否到期-1', res);
        if (res.status == 0) {
          this.checkMemberAlertWithType("no_audit")
        } else if (res.status == 1) {
          this.checkMemberAlertWithType("audit")
        } else if (res.status == 2) {
          //检测用户会员状况
          if (this.config.lastIdentity.status == 2) {
          }
          this.dataApi.getDepartment().then((res: any) => {
            if (res) {
              this.dataApi.setLocalDepartment(res);
              let department = res;
              let nowData = new Date();
              if (department.authentication_endtime) {
                let authentication_endtimeData = new Date(department.authentication_endtime);
                if (nowData > authentication_endtimeData) {
                  this.toastr.warning('当前用户工作认证已过期')
                  this.checkMemberAlertWithType("au_timeOut");
                  return;
                }
              }
              callback(true)
            }
          }).catch((error) => {
            callback(false)
          });

        } else if (res.status == 3) {
          this.checkMemberAlertWithType("fail")
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  //会员/认证弹窗 type 类型
  checkMemberAlertWithType(type) {
    console.log('checkMemberAlertWithType', type)
    //no_audit audit fail no_audit au_timeOut
    if (!this.config.isShowPaySystem) {
      if (type == 'goBuy') {
        this.router.navigate(['home/tool-campus']);
        // return
      }
      if (type == 'goCloud') {
        this.router.navigate(['home/tool-campus']);
        return
      }
      if (type == 'goCampus') {
        this.router.navigate(['home/tool-campus']);
        return
      }
      if (type == 'resume_delivery') {
        this.router.navigate(['home/tool-campus']);
        return
      }
      if (type == 'goResume') {
        this.router.navigate(['home/tool-campus']);
        return
      }
      if (type == 'goMilk') {
        this.router.navigate(['home/tool-campus']);
        return
      }
      if (type == 'goMilk_milkround') {
        this.router.navigate(['home/tool-campus']);
        return
      }
    }
    this.dialog.open(NoneVipAlertComponent, {
      data: {
        type: type,
        message: this.config
      }
    }).afterClosed().subscribe((isConfirmed) => {
      this.scrollTrue();
      console.log('isConfirmed', isConfirmed)
      if (isConfirmed) {
      }
    });
  }
  //未调用
  checkMemberAlertWithTypeCustom(payVipHistoryPage) {
    this.dialog.closeAll();
    this.dialog.open(NoneVipAlertComponent, {
      data: {
        payVipHistoryPage: payVipHistoryPage
      }
    }).afterClosed().subscribe((isConfirmed) => {
      this.scrollTrue();
    });
  }

  //检查当前企业是否被高校拉黑，
  checkCompanyIsBack(collegeid, callback) {
    this.dataApi.checkCollegeBlack(collegeid).then((res: any) => {
      console.log('checkCollegeBlack', res.value)
      if (res.value) {
        //认证
        this.checkMemberAlertWithType("collegeblack");
        callback()
      } else {
        callback()
      }
    }).catch((error) => {
      callback()
    });
  }

  checkAuth(callback?: any) {
    this.dataApi.getPlatformApply().then((res: any) => {
      if (res) {
        console.log('用户买了会员,检测会员是否到期-1', res);
        if (res.status == 0) {
          this.checkMemberAlertWithType("no_audit")
        } else if (res.status == 1) {
          this.checkMemberAlertWithType("audit")
        } else if (res.status == 2) {
          callback(true)
        } else if (res.status == 3) {
          this.checkMemberAlertWithType("fail")
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  updateParOrderCount() {
    this.dataApi.getPaymentOrderPayList({
      skip: 0,
      limit: '10', order_state: 'to_pay'
    }).then((res: any) => {//通过tooken获取企业信息
      this.config.ordertoPayCount = res.count;
    }).catch((error) => {
    });
  }
  //数据埋点
  _hmtPush(type, active, label) {
    let url = window.location.href;
    if (url.indexOf("https") >= 0) {
      try {
        window._hmt && window._trackEvent._hmt.push(['_trackEvent', type, active, label]);
      } catch (e) {
        console.log('_hmtPush', e);
      }
    }
  }
  //是否是广告
  isAddvert(name) {
    if (name.indexOf('gwl') >= 0) {
      return true
    } else {
      return false
    }
  }
  educationFormat(education) {
    let t_education = education;
    if (t_education == '本科生毕业') {
      t_education = "本科"
    }
    if (t_education == '专科生毕业') {
      t_education = "专科"
    }
    if (t_education == '大专生毕业') {
      t_education = "大专"
    }
    if (t_education == '硕士生毕业') {
      t_education = "硕士"
    }
    if (t_education == '中专生毕业') {
      t_education = "中专"
    }
    if (t_education == '博士生毕业') {
      t_education = "博士"
    }
    if (t_education == '硕士生毕业') {
      t_education = "硕士"
    }
    return t_education
  }
  formatFileData(imageData) {
    // png
    if (imageData.indexOf('data:image/png;base64') >= 0) {
      imageData = imageData.replace('data:image/png;base64,', '');
    }
    //jpg
    if (imageData.indexOf('data:image/jpeg;base64') >= 0) {
      imageData = imageData.replace(/^(data:image)\/jpeg;base64,/g, '');
    }
    //word
    if (imageData.indexOf('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64') >= 0) {
      imageData = imageData.replace('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,', '');
    }
    //xls
    if (imageData.indexOf('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64') >= 0) {
      imageData = imageData.replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,', '');
    }
    //txt
    if (imageData.indexOf('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64') >= 0) {
      imageData = imageData.replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,', '');
    }
    //pptx
    if (imageData.indexOf('data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64') >= 0) {
      imageData = imageData.replace('data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,', '');
    }
    //zip
    if (imageData.indexOf('data:application/zip;base64') >= 0) {
      imageData = imageData.replace('data:application/zip;base64,', '');
    }
    if (imageData.indexOf('data:application/msword;base64') >= 0) {
      imageData = imageData.replace('data:application/msword;base64,', '');
    }

    if (imageData.indexOf('data:application/pdf;base64') >= 0) {
      imageData = imageData.replace('data:application/pdf;base64,', '');
    }

    if (imageData.indexOf('data:application/x-zip-compressed;base64') >= 0) {
      imageData = imageData.replace('data:application/x-zip-compressed;base64,', '');
    }

    if (imageData.indexOf('data:text/plain;base64') >= 0) {
      imageData = imageData.replace('data:text/plain;base64,', '');
    }
    if (imageData.indexOf('data:application/vnd.ms-excel;base64') >= 0) {
      imageData = imageData.replace('data:application/vnd.ms-excel;base64,', '');
    }
    if (imageData.indexOf('data:application/octet-stream;base64') >= 0) {
      imageData = imageData.replace('data:application/octet-stream;base64,', '');
    }
    if (imageData.indexOf('data:application/wps-writer;base64') >= 0) {
      imageData = imageData.replace('data:application/wps-writer;base64,', '');
    }
    return imageData
  }
  urmPayStatisticPublic(category) {
    //[Description("到期会员点击续费")]
    //         renew,
    //         [Description("点击获取校招解决方案")]
    //         not_member,
    //         //主动成交机会
    //         [Description("购买职位刷新")]
    //         active_purchase_position_refresh,
    //         [Description("购买极速电话联系")]
    //         active_purchase_voice_call,
    //         [Description("购买职位推送")]
    //         active_purchase_sms_bulkinvitation,
    //         [Description("购买校招活动邀请")]
    //         active_purchase_activity_invitaion,
    //         [Description("购买空宣推广")]
    //         active_purchase_notice_student_sms,
    //         /// <summary>
    //         /// 被动成交机会
    //         /// </summary>
    //         [Description("职位刷新不足")]
    //         passive_purchase_position_refresh,
    //         [Description("极速电话联系不足")]
    //         passive_purchase_voice_call,
    //         [Description("职位推送不足")]
    //         passive_purchase_sms_bulkinvitation,
    //         [Description("校招活动邀请不足")]
    //         passive_purchase_activity_invitaion,
    //         [Description("空宣推广不足")]
    //         passive_purchase_notice_student_sms,
    if (category) {
      let urmPayStatistic = {
        departmentid: this.config.department.accountid,
        category: category
      }
      this.dataApi.urmPayStatistic(urmPayStatistic).then((res: any) => {
        this.config.payVipHistoryPage = null
        this.config.userInitiativePayName = null
      }).catch((error) => {
      });
    }

  }
  gotoPositionPush() {
    let that = this;
    this.router.navigate(['home/position-publish']);
    // this.checkMember((isPay)=>{
    //   if(isPay) {
    //     this.router.navigate(['home/position-publish']);
    //   } else {
    //     that.checkMemberAlertWithType("goBuy_pushPosition");
    //   }
    // },true,true)
  }

  copySecureMaterailClick(text) {
    var aux = document.createElement("input");
    var _content = text;
    aux.setAttribute("value", _content);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("Copy");
    document.body.removeChild(aux);
    this.toastr.success('复制成功！请打开新浏览器页面地址栏粘贴下载')
  }
  
}
