import { Component,EventEmitter, Input, Output,ViewChild,ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {DataApi} from "../../provider";

@Component({
  selector: 'app-model-select-more',
  templateUrl: './model-select-more.component.html',
  styleUrls: ['./model-select-more.component.css']
})
export class ModelSelectMoreComponent{
  @ViewChild("secend", { static: true }) secend: ElementRef;
  @Input() isShow: false;//是否显示弹窗页面
  public title: string;//弹出框标题
  //【必填】当前选中值
  public initValue = "";
  //页面数据数组
  public dataList = [];
  //当前选中的列表值
  public oneActive = {code: "", parentcode: "", text: "", level: ''};
  public twoActive = {code: "", parentcode: "", text: "", level: ''};
  public threeActive = {code: "", parentcode: "", text: "", level: ''};
  public searchText = ""
  public searchResultArr = []

  //各级列表数据
  public firstLevel = [];
  public secendLevel = [{isShow:false}];
  public childLevel = [];
  constructor(
    public dataApi: DataApi,
    public toastr: ToastrService
  ) { }

  //初始化数据
  initData(data,data1,title){
    console.log('初始化数据初始化数据');
    this.initValue = data;
    this.dataList = data1;
    this.title = title;
    this.searchText = ''
    this.searchResultArr = [];
    let parentData = [];//静态化父级数组

    if(this.initValue){
      //编辑的情况
      //(倒序循环)首先循环出来的是第三级
      this.dataList.forEach((val)=>{
        if(val.parentcode == '0') {
          parentData.push(val);
        }
        if(val.code == this.initValue){
          this.threeActive = val;
        }
      })

      this.dataList.forEach((val)=>{
        if(val.code == this.threeActive.parentcode){
          this.twoActive = val;
        }
      })

      this.dataList.forEach((val)=>{
        if(val.code == this.twoActive.parentcode){
          this.oneActive = val;
        }
      })

      //填充第一、二、三级数据
      this.firstLevel = parentData;
      this.getFirstLevel(this.oneActive);
      this.getSecendLevel(this.twoActive);

    }else{
      //新建的情况
      this.dataList.forEach((val) => {
      if (val.parentcode == '0') {
          parentData.push(val);
        }
      });

      //填充第一、二级数据
      this.firstLevel = parentData;
      this.getFirstLevel(this.firstLevel[0]);

    }
  }


  //点击筛选第一级
  getFirstLevel(item){
    //给选中行赋值
    this.oneActive = item;
    //遍历下一级数据
    this.secendLevel = [];
    if (item) {
      this.dataList.forEach((val)=>{
        if(val.parentcode === item.code){
          val.isShow = false;
          this.secendLevel.push(val);
        }
      });
    }
  }


  //点击筛选第二级
  getSecendLevel(item){
    //给选中行赋值
    this.twoActive = item;
    // //console.log(item);
    //遍历下一级数据
    this.childLevel = [];
    if (item) {
      this.dataList.forEach((val)=>{
        if(val.parentcode === item.code){
          this.childLevel.push(val);
        }
      });
      this.scrollToBottom(item.code,this.childLevel.length);
    }
    if(!item.isShow){
      this.secendLevel.forEach((val)=>{
        val.isShow = false;
      });
      item.isShow = true;
    }else{
      item.isShow = false;
    }
  }

  //点击筛选第三级
  getChildLevel(e,item){
    e.stopPropagation();
    this.threeActive = item;
    this.dismiss(item);
  }

  @Output() onVoted = new EventEmitter();
  dismiss(result: any) {
    //console.log("传值检查");
    this.onVoted.emit(result);
  }

  //页面滚动到底部
  scrollToBottom(m,n) {
    //获取点选元素到顶部的距离
    //子元素数量
    //计算滑动距离
    let pm=Number(m.substr(2, 2))*50;
    let cm=n*50;
    if(pm>300){
      setTimeout(() => {
       this.secend.nativeElement.scrollTo(0,cm,200);
      })
     }
  }
  searchClick(){
    console.log('searchText22',this.searchText)
    this.dataApi.getSearchListDic('post_type',{level:3,name:this.searchText}).then((res:any)=>{
      console.log('searchText',res,this.searchText);
      if(res){
        let arr = []
        res.map((item) =>{
          if(item.level == 3 ){
            arr.push(item)
          }
        })
        this.searchResultArr = arr
        if(arr.length==0){
          this.toastr.warning('暂无搜索结果')
        }
      }
    }).catch((error)=>{
    });
  }
  searchChange(){
    // console.log('searchText',this.searchText);
    // this.searchResultArr = []
    this.searchClick();
  }
}
