<!-- 页头部分 -->
<!-- <div class="contentRight-header flex_row_left"><img class="goBack" (click)="opera.goNewRouter('college-milkround')" src='assets/images/router-old.png'/>高校云 - 我的双选会</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>

    <div class="college-block">

        <!--V3.0-->
        <div class="position-milkd">
            <div class="headerReturn">
                <div class="center" (click)="opera.goNewRouter('college-milkround')">
                    <img src="/assets/images/v3/headerReturnIcon.png"> 双选会报名记录列表
                </div>
            </div>
            <div class="back-head">
                <div class="search">
                    <div class="cell">
                        <select name="city" class="activeBg" id="city" [(ngModel)]="model.status" (ngModelChange)="getMilkList()" autocomplete="off">
                            <option class="place-text" value='' disabled hidden selected="selected">选择审核状态</option>
                            <option class="place-text" value=''>不限</option>
                            <option value='1'>审核中</option>
                            <option value='2'>已通过</option>
                            <option value='3'>已拒绝</option>
                        </select>
                    </div>
                    <div style="display: flex;">
                        <div class="input">
                            <img src="/assets/images/rights/search.png">
                            <input type="text" placeholder="请输入学校名称" [(ngModel)]="model.college_name" (keyup)="inputKeyup($event)">
                        </div>
                        <div class="searchBtn blueStreamer" (click)="goSearch(model.college_name)">搜索</div>
                    </div>
                </div>
            </div>
            <!-- 循环列表 -->
            <div class="list my-college-list">
                <div class="item" *ngFor="let n of milkRoundList;let i = index;">
                    <div class="box">
                        <div class="logo"><img src="{{n.logo}}"></div>
                        <div class="main">
                            <div class="name" (click)="goDeatils(n)">
                                <span class="session" *ngIf="n.is_welfare">专场</span>
                                <span class="sky" *ngIf="n.milkround_type == 2">空中双选会</span> {{n.name}}
                            </div>
                            <div class="ul">
                                <div class="li"><img src="/assets/images/rights/college-list-icon01.png">{{n.college_name}}</div>
                                <div class="li"><img src="/assets/images/rights/college-list-icon02.png">举办日期 {{n.start_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                                <div class="li"><img src="/assets/images/rights/college-list-icon03.png"> {{n.milkround_type == 2?"线上":n.place_name?n.place_name:'暂无'}}
                                    <span *ngIf="n.milkround_type == 2 && n.is_online_interview"><img src="/assets/images/rights/video.png">视频双选会</span>
                                </div>
                            </div>
                            <!-- 报名状态  -->
                            <div class="state">
                                <div class="left">
                                    <!-- 报名阶段  -->
                                    <div class="state-item" *ngIf="n.loadState==2">
                                        <div class="icon">报名阶段</div>
                                        <div class="time"><img src="/assets/images/rights/time01.png">报名截止：{{n.apply_end_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                                    </div>
                                    <!-- 进行中  -->
                                    <div class="state-item conduct" *ngIf="n.loadState==4">
                                        <div class="icon"><img src="/assets/images/rights/conduct-icon.png">进行中</div>
                                        <div class="time"><img src="/assets/images/rights/time02.png">活动结束：{{n.end_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                                    </div>
                                    <!-- 已结束  -->
                                    <div class="state-item end" *ngIf="n.loadState==5">
                                        <div class="icon">已结束</div>
                                        <div class="time"><img src="/assets/images/rights/time03.png">活动结束：{{n.end_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                                    </div>
                                </div>
                                <div class="right">
                                    <div class="ste01" (click)="downloadPoster(n.applyid)" *ngIf="n.status == 'passed' && !n.isEnd && n.poster_type">下载海报</div>
                                    <div class="ste02" (click)="goSign(n)" *ngIf="n.showShenHeBtn">报名审核中</div>
                                    <div class="ste03" (click)="goSign(n)" *ngIf="n.showFaileBtn">审核未通过</div>
                                    <div class="ste03" *ngIf="n.audit_status == 'revoke'">已撤回申请</div>
                                </div>

                            </div>
                            <!-- 操作区  -->
                            <div class="operation">
                                <div class="interViewconfirm" (click)="okCanhui(n)" *ngIf="n.showSuccessBtn &&n.feedback_status=='no_feedback' && n.milkround_type == 2"><img src="/assets/images/rights/room.png">进入会场</div>
                                <div class="interViewconfirm" (click)="goRoom(n)" *ngIf="n.showSuccessBtn  && n.feedback_status=='no_feedback' && n.milkround_type == 2 && n.is_online_interview"><img src="/assets/images/rights/conference.png">进入面试间</div>
                                <div class="refuse" *ngIf="n.showSuccessBtn&&n.feedback_status=='no_join'">已拒绝</div>
                                <div class="confirm" (click)="okCanhui(n)" *ngIf="n.showSuccessBtn &&n.feedback_status=='no_feedback' && n.milkround_type == 1">确认参会</div>
                                <div class="notice" (click)="singleTips(n)" *ngIf="n.showSuccessBtn&&n.feedback_status=='join' && n.curr_total_student>=1000 && opera.isShowPaySuccessModule1() && n.milkround_type != 2 && (n.loadState==2 || n.loadState==4)">通知学生到展位</div>
                                <div class="confirm_yes" (click)="ConfirmMilkroundCommit2(n)" *ngIf="n.showSuccessBtn && n.feedback_status=='join'&&n.milkround_type != 2">已确认</div>
                                <div class="BoothNo" *ngIf="n.showSuccessBtn && n.feedback_status=='join'&&n.milkround_type != 2">
                                    {{n.seat_number==0?"展位号暂未分配":'展位号：'+n.zone_name_string+n.seat_number}}</div>
                                <div class="withdraw" *ngIf="n.showShenHeBtn" (click)="openRevoke(n.applyid)">撤回申请</div>
                                <div class="interViewconfirm" *ngIf="n.audit_status == 'revoke' && timeBefore(n.apply_end_time)" (click)="goSign(n)">重新申请</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 空状态 -->
        <div class="none-block-empty" *ngIf="countList == 0">
            <img src="assets/images/rights/empty.png">
            <p>暂未找到双选会申请记录</p>
        </div>

        <!-- 分页部分 -->
        <div class='paging-block' [class.isPageHeight]="countList>0">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>
    </div>


</div>

<p-dialog [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':300,'height':532,'minHeight':532}" [modal]="true" [showHeader]="false" [(visible)]="showMilkCode">
    <div style="width: 300px;height: 532px;background-color: white;overflow: hidden;">
        <img (click)="closeModel()" src="{{QrCodeURL}}" style="width: 300px;height: 532px;float: right;margin-top: 0px;margin-right: 0px;background-color: #00a0e9">
    </div>
</p-dialog>

<p-dialog [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':600,'height':300,'minHeight':300}" [modal]="true" [showHeader]="false" [(visible)]="showConfirmMilkroundModel">
    <div style="width: 600px;background-color: white;overflow: hidden;">

        <p style="font-size: 23px;text-align: center;margin-top: 40px;">双选会确认函</p>

        <p style="font-size: 20px;width: 300px;margin-left: 150px;text-align: center;margin-top: 30px;" *ngIf="currentMilkround && currentMilkround.name">
            恭喜您，您申请的双选会"{{currentMilkround.name}}"已通过高校审核，请确认是否参加</p>
        <button (click)="ConfirmMilkroundCommit('no_join')" style="float: left;margin-left: 120px;width: 100px;background-color: gray;color: white;margin-top: 40px">不参加</button>
        <button (click)="ConfirmMilkroundCommit('join')" style="float: right;margin-right: 120px;width: 100px;background-color: #5e4fff;color: white;margin-top: 40px">参加</button>

    </div>
</p-dialog>

<!-- 新增高校 -->
<app-push-college-model #pushCollegeModel [isShow]="isShowPushCollegeModel" (modelHidden)="pushCollegeModelHidden($event)"></app-push-college-model>

<!-- 撤回双选会申请 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="revokeModule" [modal]=true>
    <div class="showTopingBox active">
        <div class="closIcon" (click)="revokeModule = false"><img src="/assets/images/v3/alertClos.png"></div>
        <div class="title">
            <div class="tipText">
                <div class="name">撤回报名</div>
            </div>
        </div>
        <div class="p2">确定撤回该双选会报名吗？</div>
        <div class="btns">
            <div class="btn active" (click)="revokeModule = false">取消</div>
            <div class="btn blueStreamer" (click)="downMilk()">确定</div>
        </div>
    </div>
</p-dialog>