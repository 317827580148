<!-- 页头部分 -->
<!-- <div class="contentRight-header">人才 - 在线直聊</div> -->
<!-- 主体内容部分 -->
<div class="homepage-block">
    <img (click)="opera.scrollTop1()" src="/assets/images/go_top.png" style="width: 60px;height: 60px;position: fixed;bottom: 100px;right: 50px;cursor: pointer" />
    <!-- <router-outlet></router-outlet>-->
    <app-model-send-msg #sengMsgModel [SengMsgModel]='SengMsgModel' [tconfig]='config' (onVoted)="Modeldismiss($event)" [isShow]="modelShowSengMsg"></app-model-send-msg>
    <app-voice-call-model #voiceCallModel [isShow]="isShowVoiceCallModel" (modelHidden)="voiceCallModelHidden($event)"></app-voice-call-model>

    <!-- 头部 -->
    <div class="con-l">
        <div class="secondNav">
            <div class="item active">推荐人才<span></span></div>
            <div class="item" (click)="goInterested()">对我感兴趣</div>
        </div>
        <div class="filt_ flex_l">
            <select class="sel-pos downLoad_right" name="position" id="position" [(ngModel)]="advanceModel.positionid" (ngModelChange)="getRecommendList()" autocomplete="off">
                <option class="place-text" value='' selected="selected">职位不限</option>
                <option *ngFor="let a of positionList" [value]="a.positionid">{{a.name}}</option>
            </select>
        </div>

        <div class="sel-res">
            <img src="/assets/images/positionPushs_icon.png" />
            <div class="num">
                <img src="/assets/images/positionpush_title.png" />
                <div>符合您筛选条件的人数为：<span style="color: #F4B517">{{countList>=2000?"2000+":countList}}</span> 人</div>
            </div>
            <div class="push blueStreamer" (click)="useBatchFn()">立即推送</div>
        </div>

        <div *ngIf="countList>0" class="college-resume-cont">
            <div class="resume-list">
                <!-- 新版 -->
                <div class="resume-list-item" *ngFor="let n of resumeList;let i = index;" (click)="goDetails(n,n.ignore,i)">

                    <div class="main">
                        <div class="introduce">
                            <div class="logo"><img *ngIf="n.logo" [src]="n.logo+'&imageView2/1/w/55/h/55'"></div>
                            <div class="text">
                                <div class="name">
                                    {{n.talent_name | clearSpace}}
                                    <span *ngIf="n.isview_resume">已查看</span>
                                    <span class="new_telent" *ngIf="n.new_talent">新用户</span>
                                    <span class="new_telent" *ngIf="n.is_called">已联系</span>
                                    <span class="new_telent" *ngIf="n.is_push">已推送</span>
                                </div>
                                <div class="college" *ngIf='n.graduate_university'>{{n.graduate_university}}</div>
                                <div class="major">{{n.major_text}} | {{n.education_text}} | {{n.year==0?"往届":n.year+'届'}}</div>
                            </div>
                        </div>
                        <div class="lable">
                            <span *ngFor="let youshi of n.youshiArr | slice:0:4;let i = index;">{{youshi}}</span>
                        </div>
                        <div class="intention">
                            <span>求职意向：</span>{{n.yixiang}}
                        </div>
                        <div class="rightBtn">
                            <div class="item active" *ngIf='n.ignore==false' (click)="ShowPhoneModel(n,$event)"><img src="/assets/images/v3/speedIcon.png">极速电话联系</div>
                            <div class="item" *ngIf='n.ignore==false' (click)="alertSengMSG(n,$event)" title="可以将职位定向推送给该学生"><img src="/assets/images/v3/pushIcon.png">职位推送</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 空状态 -->
        <div class="none-block-empty" *ngIf="countList == 0">
            <img src="assets/images/rights/empty.png">
            <p>暂未找到简历，换个关键词试试吧</p>
        </div>

        <!-- 分页部分 -->
        <div class="bottomBox">
            <div class="flex_row_left selsectBox" *ngIf="useBatch">
                <div class="seslectAll flex_row_left">
                    <button class="purple" (click)="selectAllFn()">全选本页</button>
                </div>
                <div class="selsectReverse flex_row_left">
                    <button class="purple" (click)="selectRevFn()">反选本页</button>
                </div>
            </div>
            <div>&nbsp;</div>
            <div class='paging-block' [class.isPageHeight]="countList>0">
                <router-outlet></router-outlet>
                <select class="paging-block-filtrate_row_select_gray" placeholder="请选择" [(ngModel)]="eachPageCount" (ngModelChange)="getpageCount($event)">
                  <option value="10">10条/页</option>
                  <option value="20">20条/页</option>
                  <option value="50">50条/页</option>
                </select>
                <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
            </div>
        </div>
    </div>

</div>

<p-dialog [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="false" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':500,'height':300,'minHeight':300}" [modal]="true" [showHeader]="false" [(visible)]="showModel">
    <div style="width: 500px;height: 300px;background-color: white;overflow: hidden;">

        <div style="width: 500px;float: left">
            <img (click)="closeModel()" src="../../assets/images/closeX.png" style="width: 20px;height: 20px;float: right;margin-top: 15px;margin-right: 15px;">
        </div>

        <div style="width: 500px;float: left;margin-top: 80px">
            <p style="margin-left: 15px;margin-right: 15px;text-align: center;width: 500px;font-size: 18px">恭喜您！工作啦认证已通过，您可以</p>
        </div>

        <div style="width: 470px;height: 40px;float: left;margin-top: 80px;margin-left: 15px">

            <div style="width: 30%;height: 40px;background-color: #5e4fff;float: left" (click)="closeModel()">
                <p style="width: 100%;height: 40px;line-height: 40px;color: white;text-align: center">查看推荐毕业生</p>
            </div>

            <div style="width: 30%;height: 40px;margin-left: 5%;background-color: #5e4fff;;float: left" (click)="ModelgoToBaoMing(1)">
                <p style="width: 100%;height: 40px;line-height: 40px;color: white;text-align: center">报名双选会</p>
            </div>

            <div style="width: 30%;height: 40px;background-color: #5e4fff;;float: right" (click)="ModelgoToBaoMing(2)">
                <p style="width: 100%;height: 40px;line-height: 40px;color: white;text-align: center">预约宣讲会</p>
            </div>
        </div>

    </div>

</p-dialog>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>