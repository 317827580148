<div class="homepage-block">
    <router-outlet></router-outlet>
    <app-voice-call-model #voiceCallModel [isShow]="isShowVoiceCallModel" (modelHidden)="voiceCallModelHidden($event)"></app-voice-call-model>
    <app-model-send-msg #sengMsgModel [SengMsgModel]='SengMsgModel' [tconfig]='config' (onVoted)="Modeldismiss($event)" [isShow]="isShowPositionPushModel"></app-model-send-msg>
    <div class="airCampus_detail">
        <div class="airCampus_detail_card">
            <div class="d_f airCampus_detail_back_cell" (click)="opera.goNewRouter('airCampList')"><img src="../../assets/images/v3/headerReturnIcon.png" />空中宣讲会会场
            </div>
        </div>
        <div class="airCampus_detail_card" style="margin-top: 20px;">
            <div class="d_f jc_sb airCampus_detail_title_cell">
                <div class="airCampus_detail_title">{{airCampustalk?.title}}</div>
                <div class="d_f" *ngIf="!isLiveAir">
                    <div class="d_f airCampus_detail_title_btn" *ngIf="airCampustalk?.audit_status==2" (click)="downLineClick()"><img src="../../assets/images/v3/down.png" />下线</div>
                    <div class="d_f airCampus_detail_title_btn" *ngIf="airCampustalk?.audit_status==3||airCampustalk?.audit_status==2" (click)="editAirCampClick()"><img src="../../assets/images/v3/edit.png" />编辑</div>
                    <div class="d_f airCampus_detail_title_btn" *ngIf="airCampustalk?.audit_status==2" (click)="shareAirCampClick()"><img src="../../assets/images/v3/share.png" />分享</div>
                </div>
            </div>
            <div class="d_f ai_fs">
                <div>
                    <div class="airCampus_detail_video_content">
                        <div id="mod_player"></div>
                    </div>
                    <div class="d_f airCampus_detail_video_playcount">
                        <div class="video_playcount_num">{{airCampustalk?.video_play_count || 0}}次观看</div>
                        <img class="video_playcount_promote_icon" src="../../assets/images/v3/cap.png" />
                        <div class="video_playcount_promote_btn" (click)="tjTalentMode()">推广更多学生观看</div>
                        <img class="video_playcount_promote_more" src="../../assets/images/v3/goperfect.png" />
                    </div>
                </div>
                <div class="f_1 d_f fd_c airCampus_detail_video_descbox">
                    <div class="f_1 custom_textfold_box video_descbox_content" [ngClass]="{expansion:!descFold}">
                        <div class="custom_textfold">
                            <span class="custom_textfold_btn expansion_btn" (click)="descFold = false">更多</span>
                            <span>{{airCampustalk?.desc}}</span>
                            <div class="custom_textfold_btn fold_btn" (click)="descFold = true">收起</div>
                        </div>
                    </div>
                    <div class="d_f">
                        <div class="f_1">
                            <div class="video_descbox_push_title">已推广高校就业信息网</div>
                            <div class="d_f video_descbox_push_info">
                                <div class="strong">{{ApplyCollegeData.passed.length}}</div>
                                <div>所</div>
                                <div class="bright" (click)="tjSchoolMode()">推广到更多高校</div>
                                <img class="icon" src="../../assets/images/v3/goperfect.png" />
                            </div>
                        </div>
                        <div class="d_f">
                            <div class="d_f jc_c video_descbox_push_student" (click)="tjTalentMode()"><img src="../../assets/images/v3/cap2.png" />推广到学生</div>
                            <div class="d_f jc_c video_descbox_push_colleges" (click)="tjSchoolMode()"><img src="../../assets/images/v3/school.png" />推广到高校就业网</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="airCampus_detail_suntitle">招聘职位</div>
            <div class="d_f fw_w" style="margin-right: -10px;">
                <div class="airCampus_detail_label_position" *ngFor="let n of airCampustalk?.positions">{{n.name}}</div>
            </div>
            <div class="airCampus_detail_suntitle">意向专业</div>
            <div class="d_f fw_w" style="margin-right: -10px;">
                <div class="airCampus_detail_label_major" *ngFor="let n of airCampustalk?.majors">{{n.text}}</div>
                <div class="airCampus_detail_label_major" *ngIf="airCampustalk?.majors.length==0">不限</div>
            </div>
            <div class="shenheFile" *ngIf="airCampustalk?.audit_status==3">
                <img class="shenheFileIcon" src="../../assets/images/air_fail.png" />
                <p class="shenheFile_h1">很可惜，您发布的空中宣讲会未通过审核！</p>
                <p class="shenheFile_h2" *ngIf="airCampustalk?.audit_status==3&&reasons.length>0">未通过原因：</p>
                <p class="shenheFile_h2" *ngIf="airCampustalk?.audit_status==3&&reasons.length>0">
                    <span *ngFor="let n of reasons" class="flex_c">{{n.title}}:&nbsp;{{n.desc}}</span>
                </p>
                <button class="resetCommit bordeRadius" (click)="editAirCampClick()">重新提交</button>
            </div>
            <div class="shenheding" *ngIf="airCampustalk?.audit_status==1">
                <img class="shenhedingImage" src="../../assets/images/airloading.png" />
                <p class="shenheding_h1">空中宣讲会审核中…</p>
            </div>
            <div class="custom_tabs airCampus_detail_tab">
                <div (click)="tabClick('1')" class="custom_tab" [ngClass]="{active:currentTab=='1'}">学生简历</div>
                <div (click)="tabClick('2')" class="custom_tab" [ngClass]="{active:currentTab=='2'}">互动</div>
            </div>
        </div>
        <div class="" *ngIf="airCampustalk?.audit_status==2">
            <div class="" *ngIf="currentTab=='1'">
                <div class="custom_tabs airCampus_detail_subtab">
                    <div (click)="talentTabClick('1')" class="custom_tab" [ngClass]="{active:talentTab=='1'}">全部学生</div>
                    <div (click)="talentTabClick('2')" class="custom_tab" [ngClass]="{active:talentTab=='2'}">查看过我的学生
                    </div>
                    <div (click)="talentTabClick('3')" class="custom_tab" [ngClass]="{active:talentTab=='3'}">投递过我的学生
                    </div>
                </div>
                <div class="d_f fw_w airCampus_detail_position_box">
                    <div (click)="goDetails(n,n.ignore)" class="airCampus_detail_position" *ngFor="let n of resumeList;let i = index;">
                        <div class="d_f active">
                            <img class="airCampus_detail_position_logo" *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}" />
                            <img class="airCampus_detail_position_logo" *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png" />
                            <img class="airCampus_detail_position_logo" *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png" />
                            <img class="airCampus_detail_position_logo" *ngIf="!n.logo&&!n.gender" src="assets/images/male.png" />
                            <div class="info">
                                <div class="airCampus_detail_position_name">{{n.talent_name | clearSpace}}</div>
                                <div class="airCampus_detail_position_school">{{n.graduate_university}}</div>
                                <div class="d_f airCampus_detail_position_explain">
                                    <div>{{n.major_text}}</div>
                                    <div>{{n.education_text}}</div>
                                    <div>{{n.year==0?"往届":n.year+'届'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="d_f airCampus_detail_position_skill">
                            <div *ngFor="let youshi of n.youshiArr | slice:0:4;let i = index;" class="skill_item">
                                {{youshi}}</div>
                        </div>
                        <div class="d_f jc_sb" *ngIf="talentTab!='3'">
                            <div class="airCampus_detail_position_btn call_btn" *ngIf="!n.is_delivery" (click)="voiceCallClick($event,n)">
                                <img src="../../assets/images/v3/speedIcon.png" />
                                <div>电话联系</div>
                            </div>
                            <div class="airCampus_detail_position_btn push_btn" *ngIf="!n.is_delivery" title="可以将职位定向推送给该学生" (click)="positionPushClick($event,n)">
                                <img src="../../assets/images/v3/speedPush.png" />
                                <div>职位推送</div>
                            </div>
                            <div class="airCampus_detail_position_btn" *ngIf="n.is_delivery" title="查看简历" (click)="positionPushClick($event,n)">
                                <div>查看简历</div>
                            </div>
                        </div>
                        <div *ngIf="talentTab=='3'">
                            <div class="airCampus_detail_position_btn" *ngIf="opera.isShowPaySuccessModule()" title="查看简历" (click)="positionPushClick($event,n)">
                                <div>查看简历</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--</div>-->

                <div class="noneTalent" *ngIf="AllresumeCount==0">
                    <img src="../../assets/images/air_noneTalent.png" />
                    <div class="noneTalent_h1">无学生</div>
                    <div class="noneTalent_h2" (click)="tjTalentMode()" *ngIf="!isLiveAir">推广到学生</div>
                </div>
                <div id="resumePage" (show)="AllresumeCount>0" style="float: right;margin-right: 0.5%"></div>
            </div>

            <div class="tabContent" *ngIf="currentTab=='2'">
                <div class="subTab flex_l">
                    <div [ngClass]="{subTab_div_s:questionTab=='1',subTab_div:questionTab!='1'}" (click)="questionTabTabClick('1')">未回答</div>
                    <div [ngClass]="{subTab_div_s:questionTab=='2',subTab_div:questionTab!='2'}" (click)="questionTabTabClick('2')">已回答</div>
                    <div class="addNewquestion flex_c" (click)="addNewquestionModelClick('add',null)"><img src="../../assets/images/addwen.png" />新增问答</div>
                </div>

                <div class="questionMenu flex_l" *ngIf="questionTab=='2'&&AllquestionCount>0">
                    <div class="flex_l" (click)="allSeletequestionClick()">
                        <img *ngIf="!isALlSeletequestion" src="../../assets/images/air_none.png" />
                        <img *ngIf="isALlSeletequestion" src="../../assets/images/air_check.png" /> 全选
                    </div>
                    <div *ngIf="seleteQuestionList.length>0" (click)="questionMenuClick('open')">批量公开</div>
                    <div *ngIf="seleteQuestionList.length>0" (click)="questionMenuClick('hiden')">批量隐藏</div>
                </div>
                <div class="questionCell flex_s" *ngFor="let n of questionList" (click)="questionCellClick(n)">
                    <img class="question_check" *ngIf="n.selete" src="../../assets/images/check_qusition.png" />
                    <div class="questionCell_l">
                        <div class="questionCell_h1 flex_l" style="margin-bottom: 7px"><img src="../../assets/images/wen.png" /><span> {{n.question}}</span></div>
                        <div class="questionCell_h1 flex_l" *ngIf="questionTab=='2'"><img src="../../assets/images/da.png" /><span> {{n.answer}}</span></div>
                    </div>
                    <img class="questionPublic" *ngIf="n.is_public && questionTab=='2'" src="../../assets/images/qusition_open.png" />
                    <img class="questionPublic" *ngIf="!n.is_public && questionTab=='2'" src="../../assets/images/qusition_hiden.png" />
                    <div class="backquestion" *ngIf="questionTab=='1'" (click)="addNewquestionModelClick('answer',n)">
                        回答问题</div>
                </div>
                <div class="noneTalent" *ngIf="AllquestionCount==0">
                    <img src="../../assets/images/air_noneTalent.png" />
                    <div class="noneTalent_h1">您还未和学生互动</div>
                    <div class="noneTalent_h2" (click)="addNewquestionModelClick('add',null)">创建互动</div>
                </div>
                <div id="test1" (show)="AllquestionCount>0" style="float: right;margin-right: 0.5%"></div>
            </div>
        </div>
    </div>

</div>

<!--推广到学生-->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px'}" [(visible)]="TJTalentModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':860,'height':700,'minHeight':700}"
    [modal]=true>
    <div class="t_box extensionStudent">
        <img (click)="closeModel()" class="colseModel" src="/assets/images/rights/contactMeClos.png">
        <div class="top">
            <div class="icon"><img src="/assets/images/v3/extensionStudent.png"></div>
            <div>
                <div class="name">推广到学生</div>
            </div>
        </div>

        <!--高校-->
        <div class="module">
            <div class="left">高校</div>
            <div class="right">
                <div class="val">
                    <select name="" class="activeBg" [(ngModel)]="TJTalentModelData.collegeid" (ngModelChange)="seletecollegeChange()">
                        <option value="">选择高校</option>
                        <option *ngFor="let n of allCollege" value="{{n.collegeid}}">{{n.name}}</option>
                    </select>
                </div>
                <div class="selecetCollege">
                    <span *ngFor="let n of seleteCollegeids">{{n.name}}
                        <img (click)="deleteCollegeChange(n.collegeid)" src="/assets/images/v3/speedClos.png">
                    </span>
                </div>
            </div>
        </div>

        <!--专业-->
        <div class="module">
            <div class="left">专业</div>
            <div class="right">
                <div class="val fn">
                    <select class="activeBg" name="" [(ngModel)]="TJTalentModelData.majorOne" (ngModelChange)="seleteyuanxiChange()">
                        <option value="">选择专业类别</option>
                        <option value="{{n.code}}" *ngFor="let n of yuanxi1" >{{n.text}}</option>
                    </select>
                    <select class="activeBg active" name="" [(ngModel)]="TJTalentModelData.majorTwo" (ngModelChange)="seleteyuanxiChange2()">
                        <option value="" disabled>选择专业</option>
                        <option value="{{n.code}}" *ngFor="let n of yuanxi3">{{n.text}}</option>
                     </select>
                    <div class="limit">({{TJTalentModelData.majorList.length}}/5)</div>
                </div>
                <div class="selecetCollege">
                    <span *ngFor="let n of TJTalentModelData.majorList">{{n.text}}
                        <img (click)="deletemajorChange(n.code)" src="/assets/images/v3/speedClos.png">
                    </span>
                </div>
            </div>
        </div>

        <!--学历-->
        <div class="module">
            <div class="left">学历</div>
            <div class="right">
                <div class="val">
                    <select name="" class="activeBg" [(ngModel)]="TJTalentModelData.education" (ngModelChange)="seleteEducationChange()">
                        <option value="">选择学历</option>
                        <option value="{{n.code}}" *ngFor="let n of config.education">{{n.text}}</option>
                    </select>
                    <div class="limit">({{TJTalentModelData.educationList.length}}/5)</div>
                </div>
                <div class="selecetCollege">
                    <span *ngFor="let n of TJTalentModelData.educationList">{{n.text}}
                        <img (click)="deleteEducationChange(n.code)" src="/assets/images/v3/speedClos.png">
                    </span>
                </div>
            </div>
        </div>

        <!--性别-->
        <div class="module">
            <div class="left">性别</div>
            <div class="right">
                <div class="val">
                    <select name="gender" class="activeBg" [(ngModel)]="TJTalentModelData.sex" (ngModelChange)="seleteSexChange()">
                        <option value='' >不限</option>
                        <option value='01'>男</option>
                        <option value='02'>女</option>
                    </select>
                </div>
            </div>
        </div>

        <!--户口所在地-->
        <div class="module">
            <div class="left">户口所在地</div>
            <div class="right">
                <div class="val fn">
                    <select class="activeBg" name="" [(ngModel)]="TJTalentModelData.areaOne" (ngModelChange)="seleteareaChange()">
                        <option value="" >请选择所在省份</option>
                        <option value="{{n.code}}" *ngFor="let n of area1">{{n.text}}</option>
                    </select>
                    <select class="activeBg active" name="" [(ngModel)]="TJTalentModelData.areaTwo" (ngModelChange)="seleteareaChange2()">
                        <option value="" disabled>选择地区</option>
                        <option value="{{n.code}}" *ngFor="let n of area3">{{n.text}}</option>
                    </select>
                    <div class="limit">({{TJTalentModelData.majorList.length}}/5)</div>
                </div>
                <div class="selecetCollege">
                    <span *ngFor="let n of TJTalentModelData.areaList">{{n.text}}
                        <img (click)="deleteAareaChange(n.code)" src="/assets/images/v3/speedClos.png">
                    </span>
                </div>
            </div>
        </div>

        <div class="module">
            <div class="left"></div>
            <div class="right">
                <div class="_number">
                    <div class="accordWidth">
                        <div class="_container">
                            <div class="_name">符合以上条件的学生数</div>
                            <div class="numb"><span>{{TJTalentModelData.filtCount}}</span>人</div>
                            <div class="text">如需邀请更多学生，请适当放宽筛选条件</div>
                        </div>
                    </div>
                    <div class="thisTime">
                        <div class="_name">本次推送通知的学生数</div>
                        <div class="_val">
                            <input type="number" oninput="value=value.replace(/[^\d]/g,'')" [(ngModel)]="TJTalentModelData.resuleCount" />
                            <span>人次</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--通知内容-->
        <div class="module">
            <div class="left"></div>
            <div class="notice">
                <div class="content">
                    <div class="_title">通知内容</div>
                    <div class="contentText">{{TJTalentModelData.SmsContent}}</div>
                    <img src="/assets/images/v3/contentText.png">
                </div>
                <div class="pushBtn blueStreamer" (click)="goTJTalent()">立即推送</div>
                <div class="p">需消耗资源数<span>{{TJTalentModelData.resuleCount}}</span>，(当前剩余资源<span>{{MyActivityCount-TJTalentModelData.resuleCount}}</span>)</div>
                <p class="p3" (click)="product('notice_student_sms','contactMe')">购买资源</p>
            </div>
        </div>


    </div>

</p-dialog>

<!--推广到高校就业网-->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px'}" [(visible)]="TJSchoolModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':860,'height':700,'minHeight':700}"
    [modal]=true>
    <div class="t_box extensionSchool">
        <img (click)="closeModel()" class="colseModel" src="/assets/images/rights/contactMeClos.png">
        <div class="top">
            <div class="icon"><img src="/assets/images/v3/extensionSchool.png"></div>
            <div>
                <div class="name">推广到高校就业网</div>
                <div class="text">本工具只提供申请通道，待高校老师审核通过后，即可在其高校就业网展示</div>
            </div>
        </div>
        <div class="sm_title flex_l"><img src="/assets/images/v3/extensionSchool01.png" />已推广到高校就业网<span><b>{{ApplyCollegeData.passed.length}}</b>所</span></div>
        <div class="school_box flex_l">
            <div class="school_Item1" *ngFor="let n of ApplyCollegeData.passed;let i = index;">{{n.college_name}}</div>
        </div>

        <div class="sm_title flex_l"><img src="/assets/images/v3/extensionSchool02.png" />审核中的高校就业网：</div>
        <div class="school_box flex_l ">
            <div class="school_Item1" *ngFor="let n of ApplyCollegeData.to_audit;let i = index;">{{n.college_name}}
            </div>
        </div>

        <div class="sm_title flex_l"><img src="/assets/images/v3/extensionSchool03.png" />未通过审核的高校：</div>
        <div class="school_box flex_l ">
            <div class="failBox" *ngFor="let n of ApplyCollegeData.not_passed;let i = index;">
                <div class="failBox_h1 flex_l">{{n.college_name}}</div>
                <div class="failBox_h2 flex_l" *ngIf="!n.isApply"><img src="../../assets/images/jingshi.png" />{{n.ApplyStr}}</div>
                <div class="failBox_h3 flex_c" *ngIf="n.isApply" (click)="resetCommitCollege(n)"><img src="../../assets/images/resetCommit.png" />重新提交</div>
            </div>
        </div>

        <div class="sm_title flex_l active">推荐到更多高校就业网：
            <div class="allSchool flex_r" (click)="AllQuanClick()">
                <div class="choice" [class.active]='isAllSeleteCollege'><span *ngIf="isAllSeleteCollege"></span></div>全选
            </div>
        </div>

        <div class="school_box flex_l">
            <div class="moreSchoolBox flex_l" (click)="AllApplyCollegeClick(n)" *ngFor="let n of ApplyCollegeData.all;let i = index;">
                <!-- <img *ngIf="!n.selete&&n.audit_status=='0'" src="../../assets/images/air_none.png" />
                <img *ngIf="n.selete&&n.audit_status=='0'" src="../../assets/images/air_check.png" /> -->
                <div *ngIf="n.audit_status=='0'" class="choice" [class.active]="n.selete&&n.audit_status=='0'"><span *ngIf="n.selete&&n.audit_status=='0'"></span></div>

                <div *ngIf="n.audit_status!='0'" class="choice"></div>
                {{n.college_name}}
            </div>
        </div>

        <div class="sm_TuiJianBtn blueStreamer" (click)="tiCollegeClick()">立即推广</div>

    </div>
</p-dialog>


<!--新增问答弹窗-->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="addNewquestionModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':500,'height':320,'minHeight':320}"
    (onHide)="addNewquestionBoxHiden()" [modal]=true>
    <div class="addNewquestionBox">
        <div class="addNewq_row1 flex_l" *ngIf="currentAnswerTalent"><img src="../../assets/images/wen.png" /><input disabled [(ngModel)]="addNewquestionData.question" placeholder="请输入问题" /></div>
        <div class="addNewq_row1 flex_l" *ngIf="!currentAnswerTalent"><img src="../../assets/images/wen.png" /><input [(ngModel)]="addNewquestionData.question" placeholder="请输入问题" /></div>

        <div class="addNewq_row2 flex_l"><img src="../../assets/images/da.png" /><textarea [(ngModel)]="addNewquestionData.answer" placeholder="请输入回答"></textarea></div>
        <div class="addNewquestionComi" (click)="addNewquestionClick()">确定</div>
    </div>
</p-dialog>

<!--新增问答弹窗-->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="downLineModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':460,'height':260,'minHeight':260}" [modal]=true>

    <div class="downLineModel">
        <img class="downLineIcon" src="../../assets/images/downLineIcon.png" />
        <div class="downLine_h1">此功能会将您发布的空中宣讲会全网下线，确定下线吗？</div>

        <div class="downLineBtn flex_c">
            <div (click)="downLineModekClick(false)">取消</div>
            <div class="purple" style="color: white;background-color: #5E4FFF" (click)="downLineModekClick(true)">确定
            </div>
        </div>
    </div>
</p-dialog>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>