import { Component,EventEmitter,OnInit,Input, Output } from '@angular/core';
import {QRCodeComponent} from 'angular2-qrcode';
import { ToastrService } from 'ngx-toastr';
import { DataApi,Opera} from '../../provider/index';
@Component({
  selector: 'app-model-pay-wechat',
  templateUrl: './model-pay-wechat.component.html',
  styleUrls: ['./model-pay-wechat.component.css']
})
export class ModelPayWechatComponent{
  @Input() isShow: false;//是否显示弹窗页面
  public payCode:any;//二维码链接
  public second=0;//秒数
  public isRefresh=false;//是否显示刷新按钮
  public payCharge:any;
  public  timer;
  constructor(public toastr: ToastrService,
    public dataApi: DataApi,
    public opera:Opera) {}
    //初始化数据
    initData(charge){
      this.second=0;
      this.isRefresh=false;
      this.payCharge=charge;
      this.payCode=charge.credential.wx_pub_qr;
      console.log("获取到扫码链接charge");//
      console.log(this.payCharge);
      console.log(this.payCode);
      let that = this;
      this.timer = setInterval(()=>{
       this.second++;
       if(this.second==60){
        clearInterval(that.timer);
        this.second=0;
        this.isRefresh=true;
       }
       if(this.second>0&&this.second%5==0){//5秒后开始调用轮询
        console.log("调用轮询"+this.second/5+'次');
        this.dataApi.getPayStatusNew(charge.id).then((res:any)=>{
          console.log("循环获取付款状态");
          console.log(res);
          if(res.value){
            clearInterval(that.timer);
            this.toastr.success("付款成功");
            this.dismiss(true);
          }
        }).catch((err)=>{
          this.toastr.error(err);
          if(that.timer)
          {
            clearInterval(that.timer);
          }
        })
       }
      }, 1000);
    }

    //点击刷新
    goRefresh(){
      this.opera.loading();
      this.initData(this.payCharge);
      setTimeout(() => {
        this.opera.loadiss();
       },600);
    }

    //检查付款状态
    getPayStatus(chargeid){
      this.dataApi.getPayStatus(chargeid).then((res:any)=>{
        console.log("循环获取付款状态");
        console.log(res);
      }).catch((err)=>{
        this.toastr.error(err);
      })
    }

    @Output() onVoted = new EventEmitter();
    dismiss(result: any) {
      //console.log("传值检查");
      this.onVoted.emit(result);
      if(this.timer)
      {
        clearInterval(this.timer);
      }
    }

}
