<div class="homepage-block">


    <div class="company-block">

        <div class="header">
            <div class="secondNav">
                <div class="item " (click)="nav01()">收到的</div>
                <div class="item " (click)="nav02()">收藏的</div>
                <div class="item " (click)="nav03()">极速电话联系记录</div>
                <div class="item " (click)="nav04()">职位推送记录</div>
                <div class="item active">校招活动邀请记录<span></span></div>
                <div class="item" (click)="nav06()">空中宣讲会推广记录</div>
            </div>
            <div class="banner">
                <div class="layui-carousel" id="test1" *ngIf="bannerList.length>0">
                    <div carousel-item>
                        <div *ngFor="let n of bannerList;let i = index;">
                            <img class="swiper-slide bannerImage" (click)="bannerClick(n)" id={{i}} src="{{n.image_url}}" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="filtrateView">
                <div class="filtrate_row">
                    <button [class.purple]="model.status=='milkround'" class="bordeRadius" (click)="changeStatus('milkround')">双选会</button>
                    <button [class.purple]="model.status=='campustalk'" class="bordeRadius" (click)="changeStatus('campustalk')">宣讲会</button>
                </div>
            </div>
        </div>

        <div class="viewList">
            <div class="cell" *ngFor="let n of ActivityList;let i = index;" id={{i}}>
                <div class="time">{{n.date|date:'yyyy年MM月dd日'}}</div>
                <div class="itemBox">
                    <div class="item" *ngFor="let item of n.list" (click)="cellClick(item)">
                        <div class="top">
                            <div class="logo"><img src="{{item.logo}}"></div>
                            <div class="main">
                                <div class="name">{{item.name}}</div>
                                <div class="ul">
                                    <div class="li"><img src="/assets/images/rights/college-list-icon01.png">{{item.college_name}}</div>
                                    <div class="li"><img src="/assets/images/rights/college-list-icon02.png">举办日期 {{item.start_time| date:"yyyy年MM月dd日 HH:mm"}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="bottom">
                            <img src="/assets/images/v3/studentIcon.png"> 共邀请
                            <span>{{item.invite_count}}人次</span>
                            <img src="/assets/images/v3/rightIcon.png">
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <!-- 空状态 -->
        <div class="none-block-empty" *ngIf="countList == 0">
            <img src="assets/images/rights/empty.png">
            <p>暂未找到记录</p>
        </div>

        <!-- 分页 -->
        <div class='paging-block' [class.isPageHeight]="countList>9">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>

    </div>

</div>