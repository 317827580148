import { Component, TemplateRef, Inject } from '@angular/core';
// import { DialogComponent, DialogService } from 'ngx-bootstrap-modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface ConfirmsModel {
  title:string;
  message:any;
  trueText:string;
  falseText:string;
  letB:string;
}
@Component({
  selector: 'app-confirms',
  templateUrl: './confirms.component.html',
  styleUrls: ['./confirms.component.css']
})
export class ConfirmsComponent{
  // modalRef: BsModalRef;
  title: string;
  message: any;
  trueText:string;
  falseText:string;
  letB:string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmsModel) {
    this.title=data.title;
    this.message=data.message;
    this.trueText=data.trueText;
    this.falseText=data.falseText;
    this.letB=data.letB;
  }
  
}

