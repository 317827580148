<!-- 页头部分 -->
<!-- <div class="contentRight-header"><img (click)="opera.goNewRouter('company-details')" src='assets/images/router-old.png'/>我的资料 - 公司管理 - 地址管理</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <router-outlet></router-outlet>
    <app-model-address-add #address [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-address-add>
    <!-- v3.0 -->
    <div class="addressList">
        <div class="headerReturn">
            <div class="center" (click)="opera.goNewRouter('company-details')">
                <img src="/assets/images/v3/headerReturnIcon.png">公司地址列表
            </div>
        </div>
        <div class="main">
            <div class="addBtn" (click)="goAddAddress()">
                <img src="/assets/images/v3/pushposition.png">添加地址
            </div>
            <div class="line"></div>
            <div class="list">
                <div class="item" *ngFor="let n of locations;let i = index;">
                    <div class="_left">
                        <span *ngIf="n.is_default" class="active">默认</span>
                        <span>{{n.region.slice(0,2) | codetransform:config.region}}</span>
                        <span>{{n.region.slice(0,4) | codetransform:config.region}}</span>
                        <span>{{n.region | codetransform:config.region}}</span>
                        <span *ngIf='n.address'>{{n.address}}</span>
                        <span *ngIf='!n.address'>暂无</span>
                    </div>
                    <div class="_right">
                        <span *ngIf="!n.is_default" (click)="myisactive(n)">设为默认地址</span>
                        <span *ngIf="n.is_default" (click)="myisactive(n)">取消默认</span>
                        <span (click)="goAddress(n.lid)">编辑</span>
                        <span (click)="addressDelete(n.lid,i)">删除</span>
                    </div>
                </div>
            </div>
            <!-- 没有内容存在时 -->
            <div class="none-block" *ngIf="locations.length==0">
                <img src="assets/images/resume-search-fail.png"><br/> 暂未添加企业地址，请及时添加
            </div>
        </div>
    </div>

</div>