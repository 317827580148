<app-navigation-route></app-navigation-route>
<div class="box">
  <div class="tabBg">
    <div class="tabBg_span_s" >注销历史</div>
  </div>
  <div *ngFor="let n of zhuxiaoList;let i = index;" class="zhuxiao_cell">
    <img class="zhuxiao_cell_img" src="{{n.logo}}" />
    <ul style="margin-top: 10px;">
      <li style="font-size:18px;color: #666666;">{{n.account_name}}</li>
      <li style="font-size:16px;color: #999999;">账号类型：企业端 &nbsp;&nbsp;&nbsp;&nbsp;手机号：{{n.mobile}}&nbsp;姓名：{{n.linkman}}</li>
      <li style="font-size:16px;color: #999999;margin-top: 10px"><img style="width:14px;height: 14px;margin-top: -3px;" src="../../assets/images/zhuxiao_icon.png" /> 最后登录时间: {{n.last_online_time | date:'yyyy-MM-dd HH:mm:ss'}}</li>
    </ul>
    <div style="float: right;width:160px;height: 30px;color: #5E4FFF;margin-top: -60px;font-size: 16px;
    text-align: right;
    line-height: 30px;
    margin-right: 20px">{{ getState(n.audit_status)}}</div>
    <div style="float: right;height: 30px;color: #999999;margin-top: -40px;font-size: 16px;
    text-align: right;
    line-height: 30px;
    margin-right: 20px">注销时间:{{n.apply_time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
  </div>
  <div style="width: 100%;height: 400px;background-color: white" *ngIf="zhuxiaoList.length==0">
    <p style="text-align: center;margin-top: 120px;font-size: 16px;color: #999999">暂无注销历史</p>
  </div>
</div>
