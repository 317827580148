import { Component, OnInit, Input, Output, ViewChild, ChangeDetectorRef, ElementRef, Renderer2 } from '@angular/core';
import { Events,Subscription } from '../../provider/eventService';
import { Location } from '@angular/common';
import { DataApi,Local,Constants,Config,Opera,MyMMessage } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { ModelPositionNoticeComponent } from '../model-position-notice/model-position-notice.component';
import { ModelPositionHiredComponent } from '../model-position-hired/model-position-hired.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import {ModelSendMsgComponent} from "../model-send-msg/model-send-msg.component";
import {filter} from "rxjs/operators";
declare var autosize;
declare var JMessage;
declare var window;
@Component({
  selector: 'app-chat-contact',
  templateUrl: './chat-contact.component.html',
  styleUrls: ['./chat-contact.component.css']
})
export class ChatContactComponent implements OnInit {
    @ViewChild('scrollMe') msgContent: ElementRef;
    @ViewChild('notice', { static: true }) child: ModelPositionNoticeComponent;
    @ViewChild('hiredChat', { static: true }) child1: ModelPositionHiredComponent;
  @ViewChild('sengMsgModel', { static: true }) sengMsgModel: ModelSendMsgComponent;

  @Output() modelShow = false;//是否打开弹窗
    @Output() modelTwoShow = false;//是否打开弹窗
    @Output() modelThreeShow = false;//是否打开弹窗
    @Output() modelFourShow = false;//是否打开弹窗
    @Output() modelFiveShow = false;//是否打开弹窗
    @Output() modelFirstChatShow = false; // 首次聊天选择职位弹窗
  @Output() SengMsgModel = {
    userName: '',
    userPic: '',
    talentid: '',
    graduation_school: '',//学校
    major_text: '',//专业
    gender: '',
  };//打开单品发送极速短信用户对象
  private chatevent: Subscription;
    public urlName='';//上个页面名称
    public commonText='';//常用语初始值
    public config: Config;
    public isShowMore = false;//是否显示更多
    public contentMsg = '';//当前输入框的初始值
    public myDepartment:any;//当前登录的企业信息(department)
    public myUser:any;//当前登录的用户信息(user)

  @Output() modelShowSengMsg = false;//是否打开单品发送极速短信

  public msgList = [];//聊天记录
    public isInit = false;//是否加载完成
    public nowPosition ='';//当前沟通职位

    //【页面初始化包含的携带信息】
    public position:any;//接收的当前职位数据
    public userInfo:any;//接收人才基本信息数据(搜索列表)
    public noticeInfo = {
      feedback_status:'',
      interviewid :''
    };//上一次的面试信息(列表页面的人才信息)  noticeInfo 和 userInfo 只能传一个

    public chatsInfo:any;//会话列表中的信息

    public userJmUsername = ''; //对方的极光ID
    public ResumeNumberId = ''; //对方的简历数字ID

    // public model = { offset:0,limit:10 };//查询聊天记录的数量
    //public limit = 10;
    public MT:any;//获取聊天记录的起始时间
    public isMiEnd=false;
    public listModel = {//会话列表参数
      positionid:'',//职位id
      account_type:'',//联系人类别
      is_read:null,//是否已读
    }
    public isSeach = false;//是否查询聊天记录
    public isRead="";//是否已读筛选项
    public el
      : HTMLElement;
    public em: HTMLElement;
    public isApplySeach=true;//是否允许查询聊天记录
    public contactId="";//会话ID
    public listCount=0;//聊天记录总数
    public isConcact=false;
    public isFirstChat=false;//是否是第一次聊天（聊天后需刷新会话列表）
    public isTwoChat=false;//是否是第二次聊天（聊天后需获取聊天记录）
    public talentid="";//人才的id
    public matching="";//匹配度
    public jmModel={//聊天记录参数
            type: 'single',
            username: '',
            from: 0,
            limit: 10
    }
    public menu:any;
    public accountDB:any;
    public account:any;
    public showFooter=false;
    public isShowManage=false;
    public seat_number:any;//展位号
    public isDlid=false;//聊天记录是否是通过dild拉取的
    public unreadCount=0;
    public isSendDepartment=false;//消息发送方是否是企业
    isAdoptC:boolean;

    constructor( public events : Events,
    public dataApi: DataApi,
    public local: Local,
    private element:ElementRef,
    public render:Renderer2,
    public cd: ChangeDetectorRef,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    public opera:Opera,
    public dialog: MatDialog,
    public mimc:MyMMessage,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
      activatedRoute.queryParams.subscribe(queryParams => {
        if(queryParams.is_read){
          this.isRead = queryParams.is_read;
          this.getByRead();//相当于更改下拉框的筛选值--未读
        }
      })
     }

  ngOnInit() {
    this.opera.initConfig('chat','chat-contact');
    this.getDepartment();//获取企业信息
    this.getUser();//获取当前登录用户的信息
    this.getCommonCode();//获取常用语字典
    this.getCommonJuniorCode();//获取小秘书常用语字典
    this.getPositionList();//获取在期职位列表
    if(this.local.get(Constants.seat_number)){//展位号
      this.seat_number=JSON.parse(this.local.get(Constants.seat_number));
      this.local.remove(Constants.seat_number);
      this.sendSeatNumber("您好，我公司的展位号是#"+this.seat_number+"#，邀请您前来现场面试！");
    }

    if(this.local.get(Constants.userInfo)){//初次沟通
      //console.log("初次对话------------------------------chat-contact");
      //console.log(JSON.parse(this.local.get(Constants.userInfo)));
      //console.log(this.local.get(Constants.seat_number));
      //console.log(JSON.parse(this.local.get(Constants.chatPosition)));
      this.userInfo=JSON.parse(this.local.get(Constants.userInfo));
      if(!this.userInfo.name)
      {
        this.userInfo.name =  this.userInfo.talent_name;
      }
      this.talentid = this.userInfo.talentid;
      console.log("初次沟通",this.userInfo);
      this.local.remove(Constants.userInfo);
      this.position = JSON.parse(this.local.get(Constants.chatPosition));
      this.local.remove(Constants.chatPosition);
      this.userJmUsername =this.userInfo.jm_username;//极光号
      this.ResumeNumberId=this.userInfo.resume_number;//简历id
      // 原代码
      // if(!this.local.get(Constants.seat_number)){
      //   this.autoSendMsgPosition(this.position);//发送职位邀请
      //   setTimeout(() => {//-------延迟执行文字消息发送
      //     this.autoSendMsg();//文字邀请发送成功后需要重新刷新下父级列表
      //     this.isTwoChat=true;
      //   },3000);
      // }else{
      //   this.isFirstChat=true;
      // }

      // 新加弹窗代码
      this.modelFirstChatShow = true;
      if(!this.local.get(Constants.seat_number)){
        // this.chatsInfo = true;
        this.isFirstChat = true;

        this.opera.scrollTop();
      }else{
        this.isFirstChat=true;
      }
    }

    if(this.local.get(Constants.chatsInfo)){//继续沟通
        //console.log("非初次对话------------------------------chat-contact");
        this.chatsInfo=JSON.parse(this.local.get(Constants.chatsInfo));
        this.local.remove(Constants.chatsInfo);
        this.unreadCount=this.chatsInfo.unread_count;
        console.log("chatsInfo参数-----ContactChat",this.chatsInfo);
        // console.log(this.chatsInfo);
        // console.log(this.config.unReadNum);
        // console.log(this.config.isAskResume);
        // console.log("未读消息0："+this.unreadCount);
        this.UnReadCountHandle();//有未读数处理函数
        this.operaChatsInfo();
        this.isAdoptC = this.isAdopt(this.chatsInfo.dialogue_user.collegeid) // 是否通过校招
    }

    //【收到新消息广播】
    this.chatevent = this.events.subscribe('tabsTo:chatM', (data) => {
      console.log('会话页面收到即时消息广播');
      console.log(data.from);
      console.log(this.userJmUsername);
      if(data.from==this.myDepartment.jm_username){
        this.isSendDepartment=true;
        if(data.to==this.userJmUsername){
          this.setContactMsg(data);
          this.scrollToBottom();  //滚动到页面底部
        }else{
          this.getLastChatMsg(data);
        }
      }else{
        this.isSendDepartment=false;
      }
      if(data.from == this.userJmUsername){//--------有未读消息
        console.log("未读消息1："+this.unreadCount);
        this.unreadCount++;//接收到新消息时改变未读消息数量（新消息一条一条的发送）
        console.log("未读消息2："+this.unreadCount);
        this.UnReadCountHandle();//有未读数处理函数
        //屏蔽及面试接受情况处理
        if(data.type == "reply"){
          if(data.content == "0"){
            this.noticeInfo.feedback_status = "rejected";//拒绝
          }else if(data.content == "1"){
            this.noticeInfo.feedback_status = "accept";//接受
          }else if(data.content == "2"){
            this.noticeInfo.feedback_status = "talent_canceled";//取消
          }
          if(!this.cd['destroyed']) {
            this.cd.detectChanges();//刷新数据
          }
        }else if(data.type == "shield"){
          if(data.content == "true"){
            this.chatsInfo.is_shielded = true;//屏蔽
          }else if(data.content == "false"){
            this.chatsInfo.is_shielded = false;//取消屏蔽
          }
          if(!this.cd['destroyed']) {
            this.cd.detectChanges();//刷新数据
          }
        }else if(data.type == "multitext"){
          try{
            data.content = JSON.parse(data.content);
          }catch(error){
            console.log("多媒体消息解析失败"+error);
            data.content = data.content;
          }
        }
        this.setContactMsg(data);//同步本地list
        this.scrollToBottom();  //滚动到页面底部
      }
    });

    //【收到发送面试邀请消息的广播】
    this.events.subscribe('noticeTo:contact', (data) => {
       if(this.userJmUsername==data){
        this.getMessageHistoryList();
       }
    })
    this.getNewChatsList();//获取检查会话列表
  }

  //获取mimc 聊天记录
  async getMessageHistoryList(){
    this.MT = String((new Date()).valueOf());
    this.isMiEnd=false;
    this.msgList=[];
    let data = await this.mimc.getMessageList(this.userJmUsername,10,this.MT);
    console.log(data);
    this.isInit=true;
    if(data.length>0){
      this.chatListHandle(data);//聊天记录---格式化
      this.MT = String((new Date(data[0].createTime)).valueOf());
      if(data.length<10){
        this.isMiEnd=true;
      }
    }else{
      this.getMessageList();
    }

  }

  //获取聊天记录
  getMessageList(){
    console.log(this.chatsInfo);
    if(this.chatsInfo){
      this.getChatList();
    }else{
      //获取数据
      this.dataApi.getByJmChatList(this.userJmUsername).then((res:any)=>{
        console.log("获取会话信息-------------------contactMchat");
        this.chatsInfo = res
        this.getChatList();
      }).catch((error)=>{
        console.log(error);
        this.toastr.info(error);
      });
    }
  }

  // 路由返回
  goBack(): void {
    this.location.back();
    // console.log(this.location.back());
  }

  // 根据dild获取到聊天记录
  getChatList(){
    this.isDlid=true;
    this.dataApi.getChatList(this.chatsInfo.dlid,{limit:10,offset:0}).then((res:any)=>{
      console.log('根据dild获取到聊天记录');
      console.log(res);
      if(res){
          if(res.list){
            if(res.list.length>0){
              this.chatDlidListHandle(res.list);//聊天记录---本地存储处理
            }
          }
        }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  //处理接收到的chatInfo参数
  operaChatsInfo(){
    this.menu=this.chatsInfo.menu;
    this.talentid=this.chatsInfo.dialogue_accountid;
    this.userJmUsername=this.chatsInfo.dialogue_jmusername;
    //人才会话
    if(this.chatsInfo.dialogue_account_type=='talent'){
      if(this.chatsInfo.dialogue_user.resume_number){
        this.ResumeNumberId=this.chatsInfo.dialogue_user.resume_number;
      }
    }else{//公众号会话
      if((this.chatsInfo.dialogue_account_type=='official_account')&&(this.chatsInfo.dialogue_jmusername!='JuniorSecretaryD')){
        this.getCollegeMenu();
      }
    }
    this.getMessageHistoryList();//获取mimc聊天记录
    //赋值对方账号
    if(this.chatsInfo.dialogue_jmusername){
      this.userJmUsername = this.chatsInfo.dialogue_jmusername;//如果会话列表中有,以会话列表中的为准
    }
    console.log("处理接收到的chatInfo参数");
    console.log(this.chatsInfo);
    if(this.chatsInfo.dialogue_user){
      this.userInfo = this.chatsInfo.dialogue_user;//重新赋值人才基本信息
      //赋值面试信息
      if(this.chatsInfo.interviewid){//不为空则代表有上次面试的信息
        this.noticeInfo.interviewid = this.chatsInfo.interviewid;//填充上次面试的信息
      }else{
        //如果没有上一次的面试信息,则使用会话列表里面包含的职位信息(每个对话必然有对应的职位)
        //如果是初次沟通,携带过来的有职位
        this.position = {
          positionid:this.chatsInfo.positionid,
          name:this.chatsInfo.post_type_text
        }
      }
      if(!this.userInfo.jm_username){
        this.userInfo.jm_username = this.chatsInfo.dialogue_jmusername;
      }
    }
    console.log("赋值面试信息");
    console.log(this.noticeInfo);
  }

  //未读数处理
  UnReadCountHandle(){
    if(this.unreadCount){//当前会话有收到新消息
      console.log("当前会话接收新消息数量："+this.unreadCount);
      this.events.publish('chatTo:tabs',this.unreadCount); //给tabs页面发送广播（清空未读消息数量）
      //设置当前聊天记录为已读
      this.dataApi.dlidRead(this.chatsInfo.dlid).then((res:any)=>{
        console.log("设置消息已读成功");
      }).catch((err)=>{
        console.log("设置消息已读失败");
      });
      for(let val of this.config.chatsList.list){
        if(val.dialogue_jmusername==this.userJmUsername){
          val.unread_count=0;
        }
      }
      this.unreadCount=0;
    }
  }

    //去会话列表详情
    goContactChat(_chats){
      console.log(_chats);
      for(let n of this.config.chatsList.list){
          if(n.dlid==_chats.dlid){
            n.isGoChat=true;
          }else{
            n.isGoChat=false;
          }
      }
      this.isApplySeach=true;//允许查询聊天记录
      this.chatsInfo=_chats;
      console.log("点击会话列表-----------chatcontact",_chats,this.chatsInfo);

      this.menu=this.chatsInfo.menu;
      this.contactId=this.chatsInfo.dlid;//更新会话ID
      this.userJmUsername =_chats.dialogue_jmusername;
      this.userInfo = this.chatsInfo.dialogue_user;
      this.talentid=this.chatsInfo.dialogue_accountid;
      //-------------------------------判断聊天对象类别
      if(this.chatsInfo.dialogue_user.resume_number){//--------人才消息
        //重新赋值人才基本信息userInfo
        console.log("点击左侧选择聊天时打印人才信息");
        //console.log(_chats);
        //极光号
        if(!this.userInfo.jm_username){
          this.userInfo.jm_username = this.chatsInfo.dialogue_jmusername;
        }
        //简历号
        if(_chats.talent.resume_number){
          this.ResumeNumberId=this.chatsInfo.dialogue_user.resume_number;
        }
        //面试信息
        if(this.chatsInfo.dialogue_user.interview){//不为空则代表有上次面试的信息
          this.noticeInfo = this.chatsInfo.dialogue_user.interview;//填充上次面试的信息
        }else{
          //如果没有上一次的面试信息,则使用会话列表里面包含的职位信息(每个对话必然有对应的职位)
          //如果是初次沟通,携带过来的有职位
          this.noticeInfo = null;
        }
      }else{//--------小秘书及高校公众号消息
        this.userInfo={
          talent_name:this.chatsInfo.dialogue_user.name,
          logo:this.chatsInfo.dialogue_user.avatar
        }
        if((_chats.oaid)&&(this.chatsInfo.dialogue_jmusername!='JuniorSecretaryD')){
          this.getCollegeMenu();
        }
      }
      //-----------------------------------------------------判断未读数
      if(this.chatsInfo.unread_count){
        //console.log("打印chatsInfo-----contactChat");
        //console.log(this.chatsInfo);
        //console.log("有未读消息时更新聊天记录---获取线上数据");
        this.getMessageList();
         //设置当前聊天记录为已读
         this.dataApi.dlidRead(this.chatsInfo.dlid).then((res:any)=>{
          this.config.unReadNum=this.config.unReadNum-this.chatsInfo.unread_count;
          //console.log("检查未读数量---点击左侧会话列表");
          //console.log(this.chatsInfo.unread_count);
          //console.log(this.config.unReadNum);
          //清空会话列表未读数
          _chats.unread_count=0;
          //当前页面清零
          this.chatsInfo.unread_count = 0;
          let n=this.config.chatsList.list.indexOf(_chats);
          this.config.chatsList.list[n].unread_count=0;
          this.dataApi.setLocalChatsList(this.config.chatsList);
        }).catch((err)=>{
          this.toastr.error(err);
        });
      }else{
        this.getMessageHistoryList();
      }

    }

    //更改下拉框的选项值
    getByRead(){
      if(this.isRead=="false"){
        this.listModel.is_read=false;
      }else if(this.isRead=="true"){
        this.listModel.is_read=true;
      }else{
        this.listModel.is_read=null;
      }
      this.getChatFiflterList();//筛选会话列表
    }
    //获取会话列表（用于筛选，不存入本地存储）
    getChatFiflterList(){
      this.chatsInfo=null;
      this.userInfo=null;
      this.userJmUsername='';
      this.msgList=[];
      this.opera.loading();
      this.dataApi.getImDialogueAllList(this.listModel).then((res:any)=>{
        console.log("会话列表筛选--------contactchat",res);
        //console.log(res);
        this.config.chatsList=res;
        this.opera.loadiss();
      }).catch((error)=>{
        //console.log(error);
        this.toastr.error(error);
        this.opera.loadiss();
      });
    }

    //获取会话列表
    getImDialogueList(){
      this.opera.loading();
      this.dataApi.getImDialogueAllList(this.listModel).then((res:any)=>{
        console.log("获取会话列表数据0--------contactchat",res);
        // console.log(res);
        this.dataApi.setLocalChatsList(res);//会话列表本地存储
        this.config.chatsList = res;
        this.opera.loadiss();
      }).catch((error)=>{
        //console.log(error);
        this.toastr.error(error);
        this.opera.loadiss();
      });
    }

    //----获取会话列表数据（判断本地存储）
    getNewChatsList(){
      this.config.chatsList=this.dataApi.getLocalChatsList();//赋值会话列表本地存储
      //-----------------进去会话页面检查本地存储
      console.log('会话页面检查本地存储----chat-contact',this.config.chatsList);
      // console.log(this.config.chatsList);
      //console.log(this.config.chatsMsgList);
      if(!this.config.chatsList){//----本地存储没有值
        //console.log('会话列表本地存储没有值（获取会话列表）-----------------contactchat');
        this.getImDialogueList();
      }else{
        if(this.config.chatsList.list){
          if(this.config.chatsList.list.length>0){
            for(let n of this.config.chatsList.list){
              n.isGoChat=false;
              if(this.userJmUsername == n.dialogue_jmusername){
                n.isGoChat=true;
              }
            }
          }else{
            //console.log('会话列表本地存储没有值（获取会话列表）1-----------------contactchat');
            this.getImDialogueList();
          }
        }
      }
    }

    //获取单个会话
    getSingleDlid(){
      //获取数据
      this.dataApi.getByJmChatList(this.userJmUsername).then((res:any)=>{
        console.log("获取会话信息-------------------contactMchat");
        console.log(res);
        this.isAdoptC = this.isAdopt(res.dialogue_user.collegeid) // 是否通过校招
        this.config.chatsList.list.unshift(res);
      }).catch((error)=>{
        console.log(error);
        this.toastr.info(error);
      });
    }


    //更新本地存储----发送新消息时
    setContactMsg(model){
      let isNoneTime = false;
      //判断是否要显示时间
      let nowDate = new Date();
      let valDate;
      if(this.msgList.length>0){
        let val=this.msgList[this.msgList.length-1];
        valDate = new Date(val.createTime);
        let MIN = (nowDate.getTime()-valDate.getTime())/1000;
        if(MIN<300){
          isNoneTime = false;
        }else{
          isNoneTime = true;
        }
        console.log(MIN);
        if(this.isTwoChat){
          this.isTwoChat=false;
          isNoneTime=false;
        }
      }else{
        isNoneTime = true;
      }

      //本地存储数据
      let text ={
        type:model.type,
        content:model.content,
        from:model.from,//判断消息的发送方
        createTime:nowDate.getTime(),
        isNoneTime:isNoneTime
      };
      this.msgList.push(text);//---更新到页面上
      console.log("发送新消息时更新msgList---text");
      console.log(text);
      console.log(this.msgList);
      if(!this.cd['destroyed']) {
        this.cd.detectChanges();//刷新数据
      }
    }

    //设置为不合适(操作当前会话)
    setIMinappropriate(){
      this.chatsInfo.inappropriate = !this.chatsInfo.inappropriate;
      this.dataApi.setIMinappropriate(this.chatsInfo.dlid).then((res:any)=>{
        let model={
          type: "inappropriate",//消息类型
          from:this.config.department.jm_username,
          content:String(this.chatsInfo.inappropriate),//消息内容
        }
        this.postChatMsg(model);//发送到服务器
        this.toastr.success('操作成功');
      }).catch((error) => {
        //console.log(error);
        this.toastr.error('操作失败，请稍后重试');
      });
    }

    //屏蔽会话列表(操作当前会话)
    setIMshield(){
      this.chatsInfo.is_active_shield = !this.chatsInfo.is_active_shield;
      this.dataApi.setIMshield(this.chatsInfo.dlid).then((res:any)=>{
        console.log("屏蔽消息发送到MIMC");
        // this.mimc.send(this.userJmUsername,"shield",String(this.chatsInfo.is_active_shield));
        let model={
          type: "shield",//消息类型
          from:this.config.department.jm_username,
          content:String(this.chatsInfo.is_active_shield),//消息内容
        }
        this.postChatMsg(model);//发送到服务器
        this.toastr.success('操作成功');
      }).catch((error) => {
        //console.log(error);
        this.toastr.error('操作失败，请稍后重试');
      });
    }

    //去职位详情
    goPositionDetails(_item) {
      this.local.remove(Constants.positionid);
      this.router.navigate(['home/position-publish'],{
        queryParams:{
          id:_item.positionid
        }
      });
    }


    //获取企业信息
    getDepartment(){
      if(this.dataApi.getLocalDepartment()){
        this.myDepartment = this.dataApi.getLocalDepartment();
        //console.log("企业信息本地存储");
        //console.log(this.myDepartment);
      }else{
        this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
        //console.log("企业信息线上数据");
        //console.log(res);
        if(res){
          this.myDepartment = res;
          this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
        }
      }).catch((error)=>{
        this.toastr.error(error);
      });
     }

    }

    //获取当前登录用户的信息(user信息)
    getUser(){
      //获取当前登录用户的信息
      if(this.dataApi.getLocalLoginUser()){
        this.myUser = this.dataApi.getLocalLoginUser();
        //console.log("打印this.myUser");
        //console.log(this.myUser);
      }else{
        this.dataApi.getUser().then((res:any)=>{//通过tooken获取企业信息
          //console.log("当前用户信息："+res);
          if(res){
            this.myUser = res;
            // if(this.myUser.avatar){
            //   this.myUser.avatar = this.myUser.avatar+'?t=' + Math.random();
            // }
            //console.log(res);
            this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
          }
        }).catch((error)=>{
          this.toastr.error(error);
        });
      }

    }

    //获取常用语字典
    getCommonCode(){
       //获取常用语字典
      if(this.local.get(Constants.common)){
        this.config.common = this.dataApi.getCommonCode();
        //console.log("常用语获取本地存储");
        //console.log(this.config.common);
      }else{
        //获取常用语字典
        this.dataApi.getDictDate('common_language_department').then((res:any)=>{
          //console.log("常用语获取线上数据");
          //console.log(res);
          this.dataApi.setCommonCode(res);
        }).catch((error)=>{
          //console.log(error);
        });
      }
    }

    //获取小秘书常用语字典
    getCommonJuniorCode(){
      if(this.local.get(Constants.commonJun)){
        this.config.commonJun = this.dataApi.getCommonJunCode();
        //console.log("常用语获取本地存储");
        //console.log(this.config.commonJun);
      }else{
        //获取常用语字典
        this.dataApi.getDictDate('faq_d').then((res:any)=>{
          //console.log("常用语获取线上数据");
          //console.log(res);
          this.dataApi.setCommonJunCode(res);
        }).catch((error)=>{
          //console.log(error);
        });
      }
    }

  //获取在期职位列表
  getPositionList(){
    this.config.positionList=this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
    if(this.config.positionList.length==0){
      this.dataApi.getPositionList({limit:100,offset:0,status:'release'}).then((res:any)=>{
         this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
       }).catch((error) => {
         //console.log(error);
       });
    }
  }

  //【初次】发送职位信息(新建会话)
  autoSendMsgPosition(_position){
    this.isInit=true;
    let model = {
      type: "position",//消息类型
      from:this.config.department.jm_username,
      content: {
        name:  _position.name,//职位名称
        positionid: _position.positionid,//职位id
        post_type: _position.post_type,//职位编号
        numberid: _position.numberid,//职位数字id
        post_type_text: _position.name,//职位名称
        is_urgent: _position.is_urgent,//是否急聘
        position_salary: _position.position_salary,//薪资
        position_salary_text: _position.position_salary_text,//薪资
        work_place: _position.work_place,//城市编号
        work_place_text: _position.work_place_text,//城市名称
        education: _position.education,//学历编号
        education_text: _position.education_text,//学历名称
        skills:_position.skills,//技能
        post_nature:_position.post_nature,//工作类型编号
        post_nature_text:_position.post_nature_text,
        matching:this.matching
      }
    }
    this.nowPosition = _position.name;//发送职位消息时更新当前沟通职位名称
    //保存到本地存储,第一次发送不需要存储到本地缓存(在第二条文字消息,统一做本地静态化处理)
    this.isFirstChat=true;
    this.postChatMsg(model);//发送到服务器
  }


  //【初次】发送文本消息(新建会话)
  autoSendMsg(){
    let model = {
      type: "ask_for_resume",//消息类型
      from:this.config.department.jm_username,
      content: '你好，如果方便，希望可以发份简历进一步沟通，谢谢！',//消息内容
    }
    //第一次发送不需要存储到本地缓存(在第二条文字消息,统一做本地静态化处理)
    this.postChatMsg(model);//发送到服务器
  }

  //【展位号】发送展位号
  sendSeatNumber(_contentMsg){
    let model = {
      type: "text",//消息类型
      from:this.config.department.jm_username,
      content: _contentMsg//消息内容
    }
    this.postChatMsg(model);//发送到服务器
    this.contentMsg = "";
  }


  //【文字】发送文本消息
  sendMsg(_contentMsg){
    if(!_contentMsg.trim()){//trim()函数：删除字符串尾多余的函数
      this.toastr.info("消息内容不能为空");
      return;
    }else{
      this.el = this.element.nativeElement.querySelector('textarea');
      this.render.setStyle(this.el, 'height', 38 + 'px');
    }
    let model = {
      type: "text",//消息类型
      from:this.config.department.jm_username,
      content: _contentMsg,//消息内容
    }
    this.postChatMsg(model);//发送到服务器、极光
    this.contentMsg = "";
  }


  //【职位】发送职位消息
  sendMsgPosition(_position){
    let model = {
      type: "position",//消息类型
      from:this.config.department.jm_username,
      content: {
        name:  _position.name,//职位名称
        positionid: _position.positionid,//职位id
        post_type: _position.post_type,//职位编号
        numberid: _position.numberid,//职位数字id
        post_type_text: _position.name,//职位名称
        is_urgent: _position.is_urgent,//是否急聘
        position_salary: _position.position_salary,//薪资
        position_salary_text: _position.position_salary_text,//薪资
        work_place: _position.work_place,//城市编号
        work_place_text: _position.work_place_text,//城市名称
        education: _position.education,//学历编号
        education_text: _position.education_text,//学历名称
        skills:_position.skills,//技能
        post_nature:_position.post_nature,//工作类型编号
        post_nature_text:_position.post_nature_text,
        matching:_position.matching
      }
    }
    this.nowPosition = _position.name;//发送职位消息时更新当前沟通职位名称
    this.postChatMsg(model);//发送到服务器
  }


  //【图片】发送图片消息
  sendMsgImg(_url){
    let model = {
      type: "image",//消息类型
      from:this.config.department.jm_username,
      content: _url,//消息内容
    }
    this.postChatMsg(model);//发送到服务器
  }

  //【微信】发送微信号
  sendMsgWechat(){
    let nowDate = new Date();
    let isNoneTime = false;
    if(!this.myUser.weixin){
      if(this.config.loginUser.weixin){
        this.myUser = this.config.loginUser;
      }else{
        this.toastr.warning("请填写微信号");
        this.router.navigateByUrl("/home/company-account");
        return;
      }
    }
    let model = {
      type: "wechat",//消息类型
      from:this.config.department.jm_username,
      content: this.config.loginUser.weixin,//消息内容
    }
    this.postChatMsg(model);//发送到服务器
  }

  //【邀请面试】发送邀请面试
  sendMsgInvitation(_value){
    let model = {
      type: "invitation",//消息类型
      from:this.config.department.jm_username,
      content:_value,//消息内容
    }
    this.postChatMsg(model);//发送到服务器
  }

    //将消息提交到mimc(不再发送到服务器)
    postChatMsg(_data){
      //屏蔽后发的消息不再线上存储
      if(this.chatsInfo){//------从会话列表进入时 || this.chatsInfo.is_active_shield
        if(this.chatsInfo.is_shielded&&_data.type!='shield'){//-----被屏蔽时
          this.toastr.info("您已被对方屏蔽");
          console.log("屏蔽后发送的消息");
          return;
        }else{//------取消屏蔽时
          this.mimc.send(this.userJmUsername,_data.type,_data.content);
          if(_data.type=='position'){
            this.sendIm(_data);
           }
          this.isConcact=true;
          if(this.isFirstChat){//-------第一次发送消息之后刷新会话列表
            console.log("刷新会话列表-----第一次发送消息之后---0");
            //this.getChatMsgList();
            this.getSingleDlid();
            this.isFirstChat = false;
          }else{
            console.log("消息发送到服务器成功----更新会话列表本地存储");
            this.getLastChatMsg(_data);//更新会话列表本地存储数据
            this.setContactMsg(_data);//更新本地存储
          }
        }
      }else{//--------不从会话列表进入时
        //  this.postMsgServe(_data);
         this.mimc.send(this.userJmUsername,_data.type,_data.content);
         if(_data.type=='position'){
          this.sendIm(_data);
         }
         if(this.isFirstChat&&_data.type=='position'){//-------第一次发送消息之后刷新会话列表
          console.log("刷新会话列表-----第一次发送消息之后");
          this.config.loading = true;
          setTimeout(() => {//-------延迟执行文字消息发送
            this.config.loading = false;
            // this.getChatMsgList();
            this.getSingleDlid();
            this.isFirstChat = false;

            this.getMessageHistoryList();//第一次聊天之后重新拉取聊天记录
          },3000);
         }else if(this.isFirstChat&&_data.type=='ask_for_resume'){
          this.config.loading = true;
          setTimeout(() => {//-------延迟执行文字消息发送
            this.config.loading = false;
            this.getMessageHistoryList();//第一次聊天之后重新拉取聊天记录
          },3000);
         }else{
            console.log("消息发送到服务器成功----更新会话列表本地存储");
            this.getLastChatMsg(_data);//更新会话列表本地存储数据
            this.setContactMsg(_data);//更新本地存储
         }
      }
      this.scrollToBottom();//滚动到底部
    }

    //与服务器同步职位消息
    sendIm(_data){
      let _content = JSON.stringify(_data.content);
      this.dataApi.sendChat({
        jmusername:this.userJmUsername,//对方的极光ID
        message_type:_data.type,//消息类型
        content:_content//消息内容
      }).then((res:any)=>{
        console.log("职位消息发送到服务器");
      }).catch((error)=>{});
    }

    //接收错误信息返回登录
    setRoot(){
      this.dataApi.logout();
      this.router.navigateByUrl("login");
    }


    //更新会话列表本地存储----最后一条消息&会话顺序
    getLastChatMsg(data){
      console.log("会话列表排序----");
      console.log('消息发送方'+data.from+'企业极光号'+this.myDepartment.jm_username);
      console.log(data);
      if(this.config.chatsList){//当前有会话列表数据
        for(let val of this.config.chatsList.list){
          if(!this.isSendDepartment&&(val.dialogue_jmusername==this.userJmUsername)){
            if(data.type=='invitation'){//---------发送面试邀请时更新面试邀请ID
              val.interviewid=data.content.id;
            }
            val.last_message_type=data.type,
            val.last_message=data.content,
            val.last_message_time=new Date();//赋值最后一条消息发送时间
            this.newListContact(val.dialogue_jmusername);
          }else if(this.isSendDepartment&&(val.dialogue_jmusername==data.to)){
            if(data.type=='invitation'){//---------发送面试邀请时更新面试邀请ID
              val.interviewid=data.content.id;
            }
            val.last_message_type=data.type,
            val.last_message=data.content,
            val.last_message_time=new Date();//赋值最后一条消息发送时间
            this.newListContact(val.dialogue_jmusername);
          }
        }
      }//-------当前无会话列表数据时（发送消息时获取会话列表数据）
      if(!this.cd['destroyed']) {
        this.cd.detectChanges();//刷新数据
      }
      this.dataApi.setLocalChatsList(this.config.chatsList);
    }

    //更新会话列表顺序----聊天之后
    newListContact(data){
      let isTopNum=0;//-------置顶的会话数量
      let newList:any;//------接收新消息的会话
      let num=0;//接收新消息的会话的下标
      for(let val of this.config.chatsList.list){
        if(val.is_top){
          isTopNum+=1;
        }
        if(val.dialogue_jmusername == data){//更新本地列表内容
          val.isGoChat=true;
          num = this.config.chatsList.list.indexOf(val);
          newList=val;
        }
      }
      if(num==isTopNum){//----------当前会话在置顶会话后一位
        return;
      }else if(num==0){//----------当前会话在第一位
        return;
      }else if(num<isTopNum){//当前会话为置顶会话消息且不在第一位
        this.config.chatsList.list.splice(num,1);
        this.config.chatsList.list.unshift(newList);
        this.dataApi.setLocalChatsList(this.config.chatsList);
      }else{
      this.config.chatsList.list.splice(num,1);
      this.config.chatsList.list.splice(isTopNum,0,newList);
      this.dataApi.setLocalChatsList(this.config.chatsList);
      }
      if(!this.cd['destroyed']) {
        this.cd.detectChanges();//刷新数据
      }
    }

    setTopChat(data){
      let newList:any;//------接收新消息的会话
      let num=0;//接收新消息的会话的下标
      for(let val of this.config.chatsList.list){
        if(val.dialogue_jmusername == data){//更新本地列表内容
          val.is_top=true;
          num = this.config.chatsList.list.indexOf(val);
          newList=val;
        }
      }
      this.config.chatsList.list.splice(num,1);
      this.config.chatsList.list.splice(0,0,newList);
      this.dataApi.setLocalChatsList(this.config.chatsList);
      if(!this.cd['destroyed']) {
        this.cd.detectChanges();//刷新数据
      }
    }

    //发送前选择选择图片
    previewPic(event) {
      let imgData  = event.target.files[0]; //获取file对象
      if (window.FileReader) {
          var reader = new FileReader();
          reader.readAsDataURL(imgData);
          var that = this;
          reader.onloadend = function (e : any) {
            that.goCamera(reader.result);
          };
      }
    }

    //上传图片
    goCamera(data){
      let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
      imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
      this.dataApi.uploadChatImg({
          value: imageData
      }).then((res:any)=>{
          //console.log("发送图片成功------------------contactChat");
          //console.log(res);
          this.sendMsgImg(res.value);

      }).catch((error) => {
          //console.log(error);
          this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
      });
    }

    //选择职位
    goModalPositionChoose(){
      this.modelTwoShow=true;
      this.opera.scrollTop();
    }
    //接收子组件返回数据
    ModelTwodismiss(event){
      this.modelTwoShow = false;
      this.opera.scrollTrue();
      if(event){
        // //console.log("接收子组件返回数据");
        // //console.log(event);
        this.sendMsgPosition(event);
      }
    }


    //选择常用语
    goModalCommonLanguage(){
      this.modelShow=true;
      this.opera.scrollTop();
      if(this.userJmUsername=="JuniorSecretaryD"){
        this.commonText='常见问题';
      }else{
        this.commonText='常用语';
      }
    }
    //接收子组件返回数据
    Modeldismiss(event){
      this.modelShow = false;
      this.opera.scrollTrue();
      if(event){
        // //console.log("接收子组件返回数据");
        // //console.log(event);
        this.contentMsg = event;
      }
    }

    //聊天记录json格式化
    chatListHandle(_list){
      let list=[];
      for(let val of _list){
        console.log("聊天记录json格式化",val)


        //处理双双选会确认
        if(val.type=='milkround_feedback')
        {
          val.content = JSON.parse(val.content);
          console.log('处理双双选会确认',val)
          val.state = 0;
          let data = {};
          this.dataApi.virtyMilkroundFeedBack(val.content._id).then((res:any)=>{
            console.log("验证是否二次确认",res);
            if(res.value=="no_join")
            {
              val.state = 2
            }
            if(res.value=="join")
            {
              val.state = 1
            }

          }).catch((err)=>{
            console.log("验证是否二次确认err",err);

            this.toastr.error(err)
          })
        }



        let index = _list.indexOf(val);
        if((index<_list.length)&&(index>0)){
          let valDate = new Date(val.createTime);
          let nowDate = new Date(_list[index-1].createTime);
          let MIN = (valDate.getTime()-nowDate.getTime())/1000/60;
          if(MIN<5){
            val.isNoneTime = false;
          }else{
            val.isNoneTime = true;
          }
        }else{
          val.isNoneTime = true;
        }
        //val.type == "multitext"
        if(val.type == "position"||val.type == "invitation"||val.type == "offer"||val.type == "talent"||val.type == "multitext"){
          try{
            val.content = JSON.parse(val.content);
          }catch(error){
            console.log("多媒体消息解析失败"+error);
            val.content = val.content;
          }
        }
        if(this.msgList.length>9){
          list.unshift(val);
        }else{
          this.msgList.push(val);
        }

        //再次循环请求 双选会的请求状态


        // this.msgList.push({type:'milkround_feedback',content:'aasdasda',state:0})



       }//----------循环结束
       if(list.length>0){
        for(let val of list){
          this.msgList.unshift(val);
        }
      }
      console.log("格式化后的this.msgList");
      console.log(this.msgList);
      if(this.msgList.length<11){
        this.scrollToBottom();
      }
      if(!this.cd['destroyed']) {
        this.cd.detectChanges();//刷新数据
      }
    }


    //格式化聊天记录---dlid
    chatDlidListHandle(_list){
      this.msgList=[];
      for(let val of _list){
        let index = _list.indexOf(val);
        if(index<_list.length-1){//-------倒序
          let valDate = new Date(val.send_time);
          let nowDate = new Date(_list[index+1].send_time);
          let MIN = (valDate.getTime()-nowDate.getTime())/1000/60;
          if(MIN<5){
            val.isNoneTime = false;
          }else{
            val.isNoneTime = true;
          }
        }else{
            val.isNoneTime = true;
        }
        //字符串转对象
        if(val.message_type == "position"||val.message_type == "invitation"||val.message_type == "offer"||val.message_type == "talent"||val.message_type == "multitext"){
          try{
            val.content = JSON.parse(val.content);
          }catch(error){
            console.log("多媒体消息解析失败"+error);
            val.content = val.content;
          }
        }

        //处理双双选会确认
        if(val.type=='milkround_feedback')
        {
          val.content = JSON.parse(val.content);
          console.log('处理双双选会确认',val)
          val.state = 0;
          let data = {};
          this.dataApi.virtyMilkroundFeedBack(val.content._id).then((res:any)=>{
            console.log("验证是否二次确认",res);
            if(res.value=="no_join")
            {
              val.state = 2
            }
            if(res.value=="join")
            {
              val.state = 1
            }

          }).catch((err)=>{
            console.log("验证是否二次确认err",err);
            this.toastr.error(err)
          })
        }



        let m = {
          type:val.message_type,
          content:val.content,
          from:val.sender_jmusername,
          createTime:val.send_time,
          isNoneTime:val.isNoneTime
        }
        this.msgList.unshift(m);
      }//----------循环结束
        console.log("格式化后的this.msgList---dlid");
        console.log(this.msgList);
        if(this.msgList.length<11){
          this.scrollToBottom();
        }
        if(!this.cd['destroyed']) {
          this.cd.detectChanges();//刷新数据
        }
    }

    //发送录用通知
    SendOfferMsg(){
      this.dataApi.checkInterviewEnd(this.talentid).then((res:any)=>{
        console.log("检测是否存在面试邀请");
        console.log(res);
        if(res.value==0){
          this.sendOfferAlert();//弹窗提醒
        }else{
          this.goModalResumeNotice();//面试邀请流程
        }
      }).catch((err)=>{
        console.log(err);
      })
    }

    //录用弹窗提醒
    sendOfferAlert(){
      this.dialog.open(ConfirmComponent, {
        data: {
          title: '提示',
          message: '您与该学生暂未达成面试，如立即发送录用通知，将默认您已通过其他渠道完成面试。',
          trueText:'立即发送',
          falseText:'考虑一下'
        }
      }).afterClosed().subscribe((isConfirmed) => {
         //console.log('返回值');
         //console.log(isConfirmed);
         this.opera.scrollTrue();
         if(isConfirmed){
           //打开录用通知弹窗
           let model={
            userJmUsername:this.userJmUsername,
            talentid:this.talentid
           }
           this.local.set(Constants.isByOffer,JSON.stringify(model));
           this.goHire();
         }
      });
    }


    //打开面试通知
    goModalResumeNotice(){
      //console.log("打印上次面试信息");
      //console.log(this.noticeInfo);
      console.log("this.config.department",this.config.department);
      // if(this.config.department.member_info){
          if(this.config.department.quota.product_component_info.length > 0){


            let smsData = null;
            let itemsmsCount = 0;
            for (let a = 0;a<this.config.department.quota.product_component_info.length;a++)
            {
              let item = this.config.department.quota.product_component_info[a];
              if(item.alias=="interview_notice")
              {
                itemsmsCount +=item.left;
                smsData = item;
                smsData.left = itemsmsCount;
              }
            }
            if(smsData == null)
            {
              this.toastr.warning('权益不足，详情请咨询小秘书1');
            }
            else
            {
              if(smsData.left<0)
              {
                this.toastr.warning('短信余额不足，详情请咨询小秘书');

              }
              else
              {

                this.modelThreeShow=true;
                this.opera.scrollTop();
                let interviewid;
                if(this.noticeInfo){
                  interviewid=this.noticeInfo.interviewid;
                }else{
                  interviewid='';
                }
                this.child.initData(interviewid,true,this.talentid);

              }


            }


          }else{
            // this.toastr.warning('使用数值已达上限');
            this.toastr.warning('权益不足，详情请咨询小秘书2');

          }

      // }else{
      //   this.toastr.warning('权益不足，详情请咨询小秘书2');
      // }

    }


    // 判断是否通过该校校招
    isAdopt(collegeid){
      let collegeList = []
      for(let i = 0;i < this.config.department.auth.length; i ++){
        if(this.config.department.auth[i].status == 2){
          collegeList.push(this.config.department.auth[i].collegeid)
        }
      }
      if(collegeList.indexOf(collegeid) != -1){
        return true
      }else{
        return false
      }
    }

    // 简历到邮箱
    MemberObtainPage(){
      console.log("this.config.department",this.config.department);
      if(this.config.department.member_info){ // 简历到邮箱
        console.log(this.opera.isEquity('resumt_to_email'));
        if(this.opera.isEquity('resume_to_email')){
          this.toastr.success('简历已同步发送到您的邮箱');
        }else{
          this.opera.memberAlert();
          // this.toastr.warning('权益不足，详情请咨询小秘书！')
        }
      }else{
        // if(!this.config.isIOS){
        //   this.navCtrl.push('MemberCenterPage');
        // }else{
        //   this.toastr.warning('权益不足，详情请咨询小秘书！')
        // }
        this.opera.memberAlert();
      }
    }

    //接收子组件返回参数
    ModelThreedismiss(data){
      this.modelThreeShow = false;
      this.opera.scrollTrue();
      if(data){
        console.log("面试邀请返回信息------------------contactChat");
        console.log(data);
        if(data=='hired'){
          //打开录用通知发送弹窗
          this.local.remove(Constants.isByOffer);
          this.goHire();
          return;
        }
        if(data.interviewid){//发送面试通知
          this.sendMsgInvitation({id:data.interviewid,status:data.feedback_status,
            time:data.interview.time,
            location:data.interview.location.region_text+','+data.interview.location.address});
        }
        if(data.interview){//重新给当前会话赋值最近一次面试通知
          this.noticeInfo = data.interview;//重置当前界面最近一次面试通知数
          this.noticeInfo.interviewid = data.interviewid;//面试通知ID
          this.chatsInfo.dialogue_user.resume_number.interview = data.interview;//重置上个页面最近一次面试通知数据
          this.noticeInfo.feedback_status = data.feedback_status;//面试邀请状态
        }
      }else{
        //console.log("每次从邀请面试返回进入页面获取聊天记录-------------------------contactChat");
        this.getMessageHistoryList();
      }
    }

    //打开签约通知
    goHire(){
      this.modelFiveShow=true;
      this.opera.scrollTop();
      let interviewid;
      if(this.noticeInfo){
        interviewid=this.noticeInfo.interviewid;
      }
      this.child1.initData(false,false,interviewid);//测试数据
    }

    //接收子组件返回数据
    ModelFivedismiss(event){
      this.modelFiveShow = false;
      this.opera.scrollTrue();
      if(event){
        //填充本地聊天记录
        this.getMessageHistoryList();
      }
    }

    //页面滚动到底部
    scrollToBottom() {
      if(this.msgContent){
        this.msgContent.nativeElement.scrollTop = this.msgContent.nativeElement.scrollHeight;
      }
    }

    //点击人才头像进入简历详情
    goTalentDetails(data){
      //console.log("进入简历详情-----------------------contactChat")
      //console.log(data);
      // if(data){
      //   this.navCtrl.push('HomeDetails',{item :{resume_number:data}});
      // }

    }

    //多媒体消息跳转判断
    goMultitext(msg){
      if(!msg.content.message_type){
        return;
      }
      //console.log("公众号多媒体消息执行跳转---------");
      //console.log(msg);
      if(msg.content.message_type=='milkround'){
        if(this.config.lastIdentity && this.config.lastIdentity.status == 2){
          this.local.set(Constants.mrid,msg.content._id);
          this.router.navigateByUrl('/home/college-milkdetails');
        }else{
          let college;
          this.dataApi.getMilkDetails(msg.content._id).then((rres)=>{
            console.log(rres);
              this.dataApi.getCollageDetails(rres.collegeid).then((res:any) => {
              console.log("获取到高校信息--------------------------CollegeHome");
              college = JSON.parse(JSON.stringify(res));
              if(college.status == 2 || college['is_activity']){
                this.local.set(Constants.mrid,msg.content._id);
                this.router.navigateByUrl('/home/college-milkdetails');
              }else{
                this.opera.remindCould();
              }
            }).catch((err) => {
              console.log(err);
              this.opera.remindCould();
            })
          }).catch((err)=>{
            // console.log(err);
            this.toastr.warning('双选会已删除或不存在');
          })
        }
      }else if(msg.content.message_type=='campustalk'){
        //  去学校列表
        this.router.navigateByUrl('/home/college-campustalk');
      }else if(msg.content.message_type=='article'){
        // 去文章详情
        this.local.set(Constants.articleid,msg.content._id);
        this.router.navigateByUrl('home/college-details-newstxt');
      }else if(msg.content.message_type=='authentication'){//去查看企业认证
        this.opera.goplatformIdentity();
      }else if(msg.content.message_type=='authentication_invitation'){
        if(this.config.lastIdentity && this.config.lastIdentity.status == 2){
          this.local.set(Constants.collegeid,msg.content._id);
          this.local.remove(Constants.facultyid);
          this.router.navigateByUrl('/home/company-identity-details');
        }else{
          this.opera.remindCould();
        }
      }
    }

    //跳转高校主页
    goCollege(data,i){
      // //console.log("--------------0");
      // //console.log(data);
      if(data.button_type=='menu'){
         this.menu[i].isShow=!this.menu[i].isShow;
        //  //console.log("--------------1");
        //  //console.log(data);
      }else if(data.button_type=='home'){
        //console.log("--------------2");
        // this.navCtrl.push('CollegeHome',{item:this.menu[i].url});
      }


    }

    //---------------获取公众号菜单
    getCollegeMenu(){
      this.dataApi.getCollegeMenu(this.chatsInfo.dialogue_accountid).then((res:any)=>{
         this.menu=res;
         for(let val of this.menu){
           val.isShow=false;
         }
         //console.log(this.menu);
      }).catch((err)=>{
         //console.log(err);
      })
    }

    //进入就业信息页面
    goCollegeJob(item,n){
      // this.navCtrl.push('CollegeJob',{menu:this.menu,index:n});
    }

  //置顶当前会话
  setIMtop(){
    this.chatsInfo.is_top = !this.chatsInfo.is_top;
    this.dataApi.setIMtop(this.chatsInfo.dlid).then((res:any)=>{
      //console.log(res);
      this.toastr.success('操作成功');
      if(this.chatsInfo.is_top){
        this.setTopChat(this.chatsInfo.dialogue_jmusername);
      }
      // this.getImDialogueList();//获取会话列表
    }).catch((error) => {
      //console.log(error);
      this.toastr.error('操作失败，请稍后重试');
    });
  }
  //举报聊天会话
  setIMtipoff(data){
    this.dataApi.setIMtipoff(this.chatsInfo.dlid,{
      dlid:this.chatsInfo.dlid,
      reason:data
    }).then((res:any)=>{
      //console.log(res);
      this.toastr.success('操作成功');
    }).catch((error) => {
      //console.log(error);
      this.toastr.error('操作失败，请稍后重试');
    });
  }
  //------举报原因弹出框
  chooseMsgImg(){
    this.modelFourShow=true;
    this.opera.scrollTop();
  }

  //接收子组件返回数据
  ModelFourdismiss(event){
    this.modelFourShow = false;
    this.opera.scrollTrue();
    if(event){
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      this.setIMtipoff(event);
    }
  }

  //滚动加载聊天记录事件
  async contentScroll(event){
    if(this.msgContent.nativeElement.scrollTop <= 0){
      console.log('上拉刷新---------------------------------------------contactchat');
      if(this.isMiEnd){
          this.toastr.info("聊天记录已经加载完毕");
          return;
      }
      let data= await this.mimc.getMessageList(this.userJmUsername,10,this.MT);
      if(data.length>0){
        this.MT = String((new Date(data[0].createTime)).valueOf());
        this.chatListHandle(data);//聊天记录---格式化
      }
      this.isInit=true;
      if(data.length<10){
        this.isMiEnd=true;
        this.toastr.info("聊天记录加载完毕");
      }
    }
  }

  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    // //console.log("打印键盘code");
    // //console.log(keycode,"keycode");
    if(event.ctrlKey && event.keyCode==13) {
      that.contentMsg =that.contentMsg +'\n\r';
      return;
    }
    if(keycode==13){
      that.sendMsg(that.contentMsg);
    }
  }

  //-----跳转简历详情
  goResumeDetails(n){
    // this.router.navigateByUrl('homepage');
    // this.router.navigate(['homepage'],{relativeTo:this.activatedRoute});
    this.config.isNavActive=='college';
    this.config.isNavChildActive=='college-resume';
    // this.router.navigateByUrl('home/college-resumedetails/'+n);
    // this.router.navigate(['home/college-resumedetails'], {
    //   queryParams: {
    //     resumeid:n
    //   }
    // });
    window.open('/#/home/college-resumedetails?resumeid='+n,'_blank')
  }

  //talent消息类型进入简历详情
  goTanlentDetails(content){
  this.config.isNavActive=='college';
    this.config.isNavChildActive=='college-resume';
    // this.router.navigateByUrl('home/college-resumedetails/'+n);
    this.router.navigate(['home/college-resumedetails'], {
      queryParams: {
        resumeid:content.resume_number
      }
    });
  }

  //试图销毁时,取消事件订阅
  ngOnDestory() {
     this.chatevent.unsubscribe();  //收到新消息通知
  }




  // 初次聊天选择框子组件返回数据
  modelFirstChatDismiss(event){
    this.modelFirstChatShow = false;
    this.opera.scrollTrue();
    if(event){
      this.sendMsgPosition(event);
      setTimeout(() => {//-------延迟执行文字消息发送
        this.autoSendMsg();//文字邀请发送成功后需要重新刷新下父级列表
        this.getMessageList();
        this.isTwoChat=true;
      },1000);
    }
    else
    {
      setTimeout(() => {//-------延迟执行文字消息发送
        this.autoSendMsg();//文字邀请发送成功后需要重新刷新下父级列表
        this.getMessageList();
        this.getSingleDlid();
        this.isFirstChat = false;
        this.isTwoChat=true;
      },1000);

    }
    console.log("this.chatsInfo",this.chatsInfo)

  }
  //极速短信联系
  sengMsgAlert(){


      console.log("userInfo",this.userInfo)
    this.sengMsgModel.initData(1, []);
    this.SengMsgModel.userName =this.userInfo.name;
    this.SengMsgModel.userPic = this.userInfo.avatar==null?"":this.userInfo.avatar;
    this.SengMsgModel.graduation_school = this.userInfo.college_name;
    this.SengMsgModel.major_text =this.userInfo.education_text;
    this.SengMsgModel.talentid = this.talentid;
    this.SengMsgModel.gender = this.userInfo.gender;
    this.modelShowSengMsg = true;
    // avatar: "https://img.goworkla.cn/user/5baa48fd3c8fbc20784865a2/avater.jpg?t=131823601168779950"
    // collegeid: "5a990e7fd041fd54bc4c3058"
    // company: null
    // education: "03"
    // education_text: "本科"
    // gender: "02"
    // gender_text: "女"
    // jm_username: "T_J122FL1M"
    // logo: "https://img.goworkla.cn/user/5baa48fd3c8fbc20784865a2/avater.jpg?t=131823601168779950"
    // name: "董青"
    // post: null
    // region: "1601"
    // region_text: "郑州市"
    // resume_number: "N7U2DUGK"

  }
  SendMsgdismiss(event) {
    this.modelShowSengMsg = false;
    console.log("Modeldismiss",)
  }

  //已经参会
  ConfirmMilkroundCommit(type,msg){
    let data = {
      mrid:msg.content._id,
      type:type,
    };
    this.dataApi.confirmMilkround(data,msg.content._id,type).then((res:any)=>{
      msg.state = type=="join"?1:2;

      console.log("确认参会",res);
      if(type=="no_join")
      {
        this.toastr.success("请合理安排时间，如经常不参加双选会将对您企业的信用造成损失")
      }
      else
      {
        this.toastr.success("已确认参加成功")

      }

    }).catch((err)=>{
      console.log("确认参会失败",err);
      this.toastr.error(err)
    })
  }



}
