import { Component,EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Config,DataApi,Local,Constants } from '../../provider/index';
import { Router } from  '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-model-position-more',
  templateUrl: './model-position-more.component.html',
  styleUrls: ['./model-position-more.component.css']
})
export class ModelPositionMoreComponent{

  public config: Config;
  @Input() isShow: false;//是否显示弹窗页面
  @Input() initValue: any;
  //页面数据列表
  public dataList = [];
  public positionid=[];//职位id
  public isdays = false; //是否限制30天以上职位
  constructor(
    public toastr: ToastrService,
    dataApi:DataApi,
    public local:Local,
    private router: Router) {
      this.config = Config.getInstance();//实例化config
      this.config.positionList=dataApi.getLocalpositionList();//赋值在期职位列表本地存储
      this.dataList=dataApi.getLocalpositionList();
      let newArr = []
      for(let val of this.dataList){
        val.isActive=false;
        if(!val.need_perfect){
          newArr.push(val)
        }
      }
    this.dataList = newArr
      //need_perfect
      console.log('dataListdataList',this.dataList)
  }

  //初始化赋值
  initData(positionids,isdays){
    isdays?this.isdays = true:this.isdays = false;    
    // console.log('职位多选页面接收数据');
    // console.log(positionids);
    this.positionid=positionids;
    for(let val of this.dataList){
      for(let m of this.positionid){
         if(m==val.positionid){
          val.isActive=true;
          break;
         }
      }

    }
  }

  //发布职位
  goPublish(){
    this.local.remove(Constants.positionid);
    console.log(this.router);
    console.log('this.router',this.router.routerState.snapshot.url);
    //参加视频双选会发布职位
    if(this.router.routerState.snapshot.url.indexOf('/home/college-milk-sign') != -1){
      let url = 'college-milk-sign';
      this.router.navigate(['home/position-publish'],{
        queryParams:{
          ruturnPage:url
        }
      });
    }else{
      this.router.navigate(['home/position-publish']);
    }
    
  }

  //确认选择
  confirm(_item){
    console.log(this.isdays)
    //双选会限制30天以上职位
    let tody = moment().format('YYYY-MM-DD');
    let effect_time = moment(_item.effect_time).format('YYYY-MM-DD');
    let days = moment(effect_time).diff(moment(tody),'days');
    if(days<30){
      this.toastr.warning("当前职位有效期小于30天，请选择其他职位进行报名或修改该职位有效期进行报名");
      return
    }
    
    _item.isActive=!_item.isActive;
  }

  //保存
  keepValue(){
    this.positionid=[];
    for(let val of this.dataList){
     if(val.isActive){
       this.positionid.push(val.positionid);
     }
    }
    //console.log("职位多选页面");
    console.log(this.positionid);
    this.dismiss(this.positionid);
  }


  @Output() onVoted = new EventEmitter();
  dismiss(result: any) {
     //console.log("传值检查");
     //console.log(result);
     this.onVoted.emit(result);
  }

}
