<app-navigation-route></app-navigation-route>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <div class="container">
        <div class="box">

            <div class="card" *ngFor="let n of ArrList" (click)="gotoDetail(n)">
                <div class="left">
                    <div class="icon"><img src="{{n.ico}}"></div>
                    <div class="name">
                        <div class="title">{{n.name}}</div>
                        <div class="time">有效期至: {{n.endTimeStr | date:'yyyy年MM月dd日'}}</div>
                    </div>
                    <div class="line"></div>
                    <div class="balance">
                        <div class="number">{{n.old + n.left}}</div>
                        <div class="unit">余额（{{n.left}}）</div>
                    </div>
                </div>
                <div class="right">
                    <div class="time">购买时间：{{n.startTimeStr | date:'yyyy年MM月dd日'}}</div>
                    <div class="btn joinHover" (click)="product(n,'contactMe',$event)">购买资源</div>
                </div>
            </div>


            <div class='paging-block' [class.isPageHeight]="countList>0">
                <router-outlet></router-outlet>
                <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
            </div>
        </div>
    </div>
</div>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>