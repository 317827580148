import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { DataApi,Local,Opera,Constants,Config} from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {NavigationEnd,Router} from  '@angular/router';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ModelSelectTwoComponent } from '../model-select-two/model-select-two.component';
import { ModelSendMsgComponent } from '../model-send-msg/model-send-msg.component';
declare var layui;
import {SelectCheckboxComponent} from "../select-checkbox/select-checkbox.component";
import {scrollTool} from "../../provider/scrollTool";
import {regexManager} from "../../provider/regexManager";
import {NoneVipAlertComponent} from "../none-vip-alert/none-vip-alert.component";
import { Events } from '../../provider/eventService';
import {productManager} from "../../provider/productManager";
import {VoiceCallModelComponent} from "../voice-call-model/voice-call-model.component";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
declare let window;
@Component({
  selector: 'app-college-resume',
  templateUrl: './college-resume.component.html',
  styleUrls: ['./college-resume.component.css']
})
export class CollegeResumeComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true }) 
  productModel: ProductModelComponent;

  @ViewChild('initSelect') obj: SelectCheckboxComponent;
  @ViewChild('industry') child: ModelSelectTwoComponent;
  @ViewChild('sengMsgModel', { static: true }) sengMsgModel: ModelSendMsgComponent;

  @Output() isShowVoiceCallModel = false;//是否打开弹窗(简历收件箱)
  @ViewChild('voiceCallModel', { static: true }) voiceCallModel: VoiceCallModelComponent;

  public config: Config;
  public positionList=[];//职位列表
  public keywordList=[];//搜索关键词匹配列表
  public title = "请选择高校";
  public title1 = "请选择专业";
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelShowSengMsg = false;//是否打开单品发送极速短信
  @Output() SengMsgModel ={
    userName:'',
    userPic:'',
    talentid:'',
    graduation_school:'',//学校
    major_text:'',//专业
    gender:'',
    address:'',
    positionid:''
  };//打开单品发送极速短信用户对象

  // 筛选条件--搜索
  public model={
    positionid:'',
    college_name:'',//学校名称
    gender:'',
    city:'',
    limit:9,
    offset:0
  }
  public resumeList = [];//简历列表
  public productList = [];//产品列表
  public countList = 0;//列表数量
  public endTime:string;//高级搜索结束时间
  // public searchStatus=false;//判断是否开通高级搜索
  public searchStatus=true;//判断是否开通高级搜索
  public educationList=[];//学历列表
  public scrollTop = 280;
  public positionresumelistindex = 0;
  public val = {
    status: 2
  };
  public mod = {
    city: '',//城市
    school_type: '',//分类
    school_nature: '',//性质
    labels: '',//特色
    status: '',//校招状态
    is_activity:true,
    is_online:true,
    offset: 0,
    limit: 1000
  };
  public searchModel={
    collegeid:'',//学校id
    positionid:'',//职位id
    offset: 0,
    limit: 10
  }
  public advanceModel={
    is_curr_session:'',
    positionid:'',//职位id
    salary:'',//月薪范围(字典)
    collegeid:'',
    education:'',//学历要求
    work_city:'',
    major:'',//专业要求
    school_label:'',//高校标签
    gender:'',//性别
    is_online:'',//是否在线
    native_place:'', //籍贯
    limit: 10,
    offset: 0,
    provice:""
  };
  public majorList = [];
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  public majorOne='';//专业类别一级
  public jiebie='';//专业类别一级
  public collegeList = [];//认证的高校列表
  public collegeCount: 0;//列表总数
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  // public isPsitionList=false;
  public eachPageCount = 10;//每页显示数量
  public isShowFilter = false;
  public hotCity=[];//热门城市
  public hotProvince=[];//热门省
  public HotCityAndProvinceArr=[];//热门省/市合并

  public moreSearch:boolean = true;
  public alertModel:boolean = false;

  public  showModel = false;//是否显示高校云认证弹窗烦人

  public useBatch:boolean = false;  // 显隐选择按钮
  public selectAll:boolean = false; // 全选是否


  public sms_bulkinvitation = null;
  public voice_call = null;
  public resumeDetails:any;
  public myDepartment:any;//当前登录的企业信息(department)

  public phoneModel={
    myPhone:'', //企业拔打电话的手机
    myTel:'',
    myTel_qu:'',
    isOkmyPhone:false,
    NetPhone:'',
    positionid:'',
    getPhoneType:'1',
    PhoneModelType: 0,
    MyPhoneTime:0,
    isShowPhoneModel:false,
    jiduCode:'',
    positionPushCode:'',
  }

  constructor(
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public dialog: MatDialog,
    public toastr: ToastrService,
    public scrolltool:scrollTool,
    public regexManager:regexManager,
    public events : Events,
  public productManager:productManager
  )
  {
    this.config = Config.getInstance();  // 实例化config
    this.config.batchList = [];
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event:NavigationEnd) => {

      // console.log('college-resume???',event)
          if (event.url === '/home/college-resume') {
            /*在这写需要执行初始化的方法*/
            this.opera.initConfig('home','college-resume');
            if(this.local.get(Constants.oldUrl) == '/home/position-manage'){
              this.getNewList();//获取职位列表数据
            }
          }
          if(event.url =="/home/position-manage"){
            this.opera.initConfig('position','position-manage');

          }
          this.getDepartment();
      this.scrolltool.initScrooll_cell_With_scrollY(this.scrolltool.college_resume_cell_scrollY);
    });
  }

  ngOnInit() {

    if(this.local.get(Constants.oldUrl) != '/home/position-manage'){
      this.getNewList();//获取职位列表数据
    }

    if(this.local.get('eachPageCount')){
      this.eachPageCount = parseInt(this.local.get('eachPageCount'));
    }

    this.searchModel.limit = this.eachPageCount;
    this.advanceModel.limit = this.eachPageCount;
    this.getCollegeCode();//获取高校字典

    this.getHotProvice();//获取热门省

    this.getCollegeList();//获取认证学校

    this.getMajorList();//获取专业列表信息

    //获取产品列表
    this.getProductList();

    this.getMyresumeLabelList();

    this.opera.checkMemberPay();

    // this.getSchoolLabel();//获取高校标签
    // this.getIndustry();//获取所属行业
    // this.getEducationList();//获取学历列表信息
    // this.getHotCity();//获取热门城市
    // this.getEmployment();//判断是否开通高级搜索
    //this.getRecommendMoreList();//获取职位推荐简历
  }

  productModelHidden(event){    
    this.isproductShowModel = false;
  }

  //点击产品
  product(alias,type){
    this.productModel.initData(alias,type);
    this.isproductShowModel = true;
  }

  //修改每页显示的条数
  getpageCount(event){
    this.local.set('eachPageCount',event)
    console.log(event);
    this.searchModel.offset = 0;
    this.searchModel.limit = event;
    this.advanceModel.offset = 0;
    this.advanceModel.limit = event;
    if(this.pageNumber>1){
      this.getChildEvent(1);
    }else{
      this.getRecommendMoreList();//获取简历投递列表
    }
    
  }


  redCollList(event){
    console.log(111);
    console.log(event);
    this.advanceModel.collegeid = '';
    event.map((item)=>{
      if(item.collegeid){
        if(this.advanceModel.collegeid == ''){
          this.advanceModel.collegeid = item.collegeid
        }else{
          this.advanceModel.collegeid = this.advanceModel.collegeid  + ',' + item.collegeid
        }
      }
    })
    console.log(this.advanceModel.collegeid)
    this.getRecommendList();
  }
  redTwoList(event){
    console.log(event);
    this.advanceModel.major = '';
    event.map((item)=>{
      if(item.code){
        if(this.advanceModel.major == ''){
          this.advanceModel.major = item.code
        }else{
          this.advanceModel.major = this.advanceModel.major  + ',' + item.code
        }
      }
    })
    console.log(this.advanceModel.major)
    this.getRecommendList();
  }
  //获取高校认证列表
  getCollegeList() {
      this.dataApi.getCollegeList(this.mod).then((res:any) => {
        console.log(res);
        if(res)
        {
          this.collegeList = res.list;
          this.collegeList.map((item,index)=>{
            item.id = index
          })
        }

        // this.listCount=res.count;
      }).catch((err) => {
        console.log(err);
      })
  }
  //获取热门城市
  getHotCity(){
    if(this.hotCity.length<1){
      this.dataApi.getCollegeHotCityWithProvince("?province_code="+this.advanceModel.provice).then((res:any)=>{
        this.hotCity=res;
      }).catch((err)=>{
      })
    }
  }
  //获取分页子组件返回数据
  getChildEvent(index){
    console.log('子组件返回分页数-----college-resume',index);
    console.log(index);
    if(!this.moreSearch){
      if(index > 0){
        this.pageNumber = index;
        this.searchModel.offset = (this.pageNumber-1)*this.eachPageCount;
        this.getRecommendMoreList();//获取职位推荐简历
      }else if(index == -1){//上一页
        if(this.pageNumber == 1){
          this.toastr.warning("当前为第一页");
        }else{
          this.pageNumber = this.pageNumber - 1;
          this.searchModel.offset = (this.pageNumber-1)*this.eachPageCount;
          this.getRecommendMoreList();//获取职位推荐简历
        }
      }else if(index == -2){//下一页
        if(this.pageNumber == this.pageCount){
          this.toastr.warning("当前为最后一页");
        }else{
          this.pageNumber = this.pageNumber + 1;
          this.searchModel.offset = (this.pageNumber-1)*this.eachPageCount;
          this.getRecommendMoreList();//获取职位推荐简历
        }
      }
    }else{
      if(index > 0){
        this.pageNumber = index;
        this.advanceModel.offset = (this.pageNumber-1)*this.eachPageCount;
        this.getRecommendMoreList();//获取职位推荐简历
      }else if(index == -1){//上一页
        if(this.pageNumber == 1){
          this.toastr.warning("当前为第一页");
        }else{
          this.pageNumber = this.pageNumber - 1;
          this.advanceModel.offset = (this.pageNumber-1)*this.eachPageCount;
          this.getRecommendMoreList();//获取职位推荐简历
        }
      }else if(index == -2){//下一页
        if(this.pageNumber == this.pageCount){
          this.toastr.warning("当前为最后一页");
        }else{
          this.pageNumber = this.pageNumber + 1;
          this.advanceModel.offset = (this.pageNumber-1)*this.eachPageCount;
          this.getRecommendMoreList();//获取职位推荐简历
        }
      }
    }
  }

  // 高级筛选器
  moreSearchs(){
    if(this.config.department.member_info){
      this.moreSearch = !this.moreSearch;
      this.getRecommendList();
    }else{
      this.opera.memberAlert();
    }
  }

  //获取推荐简历列表 offset自定义
  getRecommendMoreList(){
    console.log('this.searchModel',this.searchModel);
    this.opera.loading();//加载动画
    if(!this.moreSearch){
      this.dataApi.getSearch(this.searchModel).then((res:any) => {
        this.opera.loadiss();//停止加载动画

        if(res)
        {
          this.countList = res.count;
          this.pageCount =Math.ceil(res.count/this.eachPageCount);
          this.resumeList = res.list;

          for (let val of  this.resumeList )
          {
            let arr = [];
            val.yixiang = "";
            val.youshiArr = [];
            for (let item1 of val.pro_skills)
            {
              val.yixiang += item1.text+"    "
              if(item1.contrast_list){
                for (let youshi of item1.contrast_list)
                {
                  val.youshiArr.push(youshi.text);
                }
              }
            }
          }
          console.log("resumeList",res.list);
          this.checkAll();
        }

      }).catch((err) => {
        this.opera.loadiss();//停止加载动画
        this.toastr.error(err)
        console.log(err);
      })
    }else{
      this.dataApi.getAdvancedSearch(this.advanceModel).then((res:any) => {
        this.opera.loadiss();//停止加载动画

        if(res)
        {

          this.countList = res.count;
          this.pageCount =Math.ceil(res.count/this.eachPageCount);
          this.resumeList = res.list;
          for (let val of  this.resumeList )
          {
            let arr = [];
            val.yixiang = "";
            val.youshiArr = [];
            for (let item1 of val.pro_skills)
            {
              val.yixiang += item1.text+"    "
              if(item1.contrast_list){
                for (let youshi of item1.contrast_list)
                {
                  val.youshiArr.push(youshi.text);
                }
              }
            }
          }
          this.checkAll();
        }
        this.opera.loadiss();//停止加载动画
      }).catch((err) => {
        console.log(err);
        this.toastr.error(err)
        this.opera.loadiss();//停止加载动画
      })
    }
  }

  // 选择学校
  chengeCollegeList(value){
  }

  //获取推荐简历列表 offset=0
    getRecommendList(){
    this.pageNumber = 1;
    this.opera.loading();//加载动画
    console.log(this.searchModel,'学校id')
    if(!this.moreSearch){
      this.searchModel.offset = 0;
      this.dataApi.getSearch(this.searchModel).then((res:any) => {
        this.opera.loadiss();//停止加载动画

        if(res)
        {
          this.countList = res.count;
          this.pageCount =Math.ceil(res.count/10);
          this.resumeList = res.list;
          for (let val of  this.resumeList )
          {
            let arr = [];
            val.yixiang = "";
            val.youshiArr = [];
            for (let item1 of val.pro_skills)
            {
              val.yixiang += item1.text+"    "
              if(item1.contrast_list){
                for (let youshi of item1.contrast_list)
                {
                  val.youshiArr.push(youshi.text);
                }
              }
            }
          }
        }

        this.opera.loadiss();//停止加载动画
      }).catch((err) => {
        this.opera.loadiss();//停止加载动画
        console.log(err);
      })
    }else{
      // if(this.advanceModel.post_type.length!=0 || this.advanceModel.salary.length!=0 || this.advanceModel.collegeid.length!=0 || this.advanceModel.education.length!=0 || this.advanceModel.work_city.length!=0 || this.advanceModel.major.length!=0 || this.advanceModel.school_label.length!=0 || this.advanceModel.gender.length!=0 || this.advanceModel.is_online.length!=0){
      this.advanceModel.offset = 0;
      this.dataApi.getAdvancedSearch(this.advanceModel).then((res:any) => {
        this.opera.loadiss();//停止加载动画

        if(res)
        {
          this.countList = res.count;
          this.pageCount =Math.ceil(res.count/10);
          this.resumeList = res.list;
          console.log('getRecommendList',res.list);
          for (let val of  this.resumeList )
          {
            let arr = [];
            val.yixiang = "";
            val.youshiArr = [];
            for (let item1 of val.pro_skills)
            {
              val.yixiang += item1.text+"    "
              if(item1.contrast_list){
                for (let youshi of item1.contrast_list)
                {
                  val.youshiArr.push(youshi.text);
                }
              }
            }
          }
        }

        this.opera.loadiss();//停止加载动画
      }).catch((err) => {
        console.log(err);
        this.opera.loadiss();//停止加载动画
      })
    }
  }

  //获取高校字典
  getCollegeCode(){
    // 获取高校字典数据
    if(this.config.school.length<1){
      this.dataApi.getDictDate('school').then((res:any)=>{
        // //console.log(res);
        this.config.school=res;
      }).catch((err)=>{
        // this.toastr.error("网络错误,请稍后重试");
        //console.log(err);
      })
    }
  }

  //筛选
  goSearch(text){
    this.model.college_name = text;
    this.getRecommendList();//获取简历列表
    this.keywordList=[];
  }

  //匹配关键字列表
  getItems(){
    this.keywordList=[];
    if(!this.model.college_name){return;}
    for(let s of this.config.school){
      if(s.text.indexOf(this.model.college_name) >= 0){
         this.keywordList.push(s);
      }
    }
  }

  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      that.goSearch(that.model.college_name);
    }else{
      that.getItems();
    }
  }

   //获取职位列表数据
  getPositionList(){
    //console.log("在期职位本地存储-----college-resume");
    //console.log(this.dataApi.getLocalpositionList());
    this.config.positionList=this.dataApi.getLocalpositionList();
    if(this.dataApi.getLocalpositionList()){
      if(this.config.positionList.length==0){
        this.local.remove(Constants.positionid);
        this.showModel = false;
        // this.toastr.warning("当前没有在期职位，请发布职位");

        this.local.remove(Constants.positionList);//职位列表
        let thit = this;
        setTimeout(()=>{
          thit.router.navigate(['home/position-publish']);
        },1200)
        return;
      }
      this.config.positionList=this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
      this.positionList =  this.config.positionList;
      this.model.positionid = this.config.positionList[0].positionid;
      this.searchModel.positionid = this.config.positionList[0].positionid;
      this.getRecommendList();//获取推荐简历
    }else{
      this.getNewList();
    }
  }

  //-------获取线上数据
  getNewList(){
    let model={
      limit:100,
      offset:0,
      status:'release',
    }
    this.opera.loading();
    this.dataApi.getPositionList(model).then((res:any)=>{
       console.log("在期职位获取线上数据-----college-resume",res);
      this.opera.loadiss();
      if(res)
      {
        if(res.list.length==0) {
          this.showModel = false;

          this.opera.loadiss();
          this.local.remove(Constants.positionid);
          // this.toastr.warning("当前没有在期职位，请发布职位");
          //
          // let thit = this;
          // setTimeout(() => {
          //   thit.router.navigate(['home/position-publish']);
          // }, 1200)
          // return;
        }
        if(res.list.length>0){
          this.positionList =  res.list;
          this.model.positionid = res.list[0].positionid;
          this.advanceModel.positionid = res.list[0].positionid;
          this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
          this.getRecommendList();//获取推荐简历
        }

      }

     }).catch((error) => {
       this.toastr.error(error);
       this.opera.loadiss();
     });
  }

  //-----跳转简历详情
  goDetails(n,is_ignore,index){
    if(is_ignore == false){
      n.isview_resume = true;
      this.scrolltool.college_resume_cell_index = index;
      this.scrolltool.college_resume_cell_scrollY = window.top.scrollY;
      this.opera._hmtPush("推荐人才","查看简历","")
      this.router.navigate(['home/college-resumedetails'], {
        queryParams: {
          resumeid:n.resume_number,
          positionid:this.model.positionid,
          match:n.matching
        }
      });
    }
  }

  //是否查看
  setResumeStatus(item,status){
    this.dataApi.setSendResumeStatus(item.deliveryid,status).then((res:any)=>{
      for(let n of this.resumeList){
        if(n.deliveryid==n.deliveryid){
          n.feedback_status=status;
        }
      }
      item.isview_resume = true;
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    })
  }


  //收藏
  goCollect(val){
    val = this.opera.manageResumeArchived(val);
  }

  // 根据学校获取学科
  getMajorList(){
    this.opera.loading();//加载动画
    this.dataApi.getagregationCount({}).then((res:any)=>{
      this.opera.loadiss();

      if(res)
      {
        // console.log('我要的取数据',res.facetgroups);
        this.majorList = res.facetgroups;
        res.facetgroups.map((item)=>{
          if(item.level == 1){
            this.majorListOne.push(item)
          }
        })
        this.getRecommendList();
      }
      // console.log(res);
      // console.log(this.majorList);
    }).catch((err)=>{
      console.log(err);
      this.opera.loadiss();
    })
  }

  //获取一级专业列表
  getMajorListOne(){
    for(let n of this.majorList){
      if(n.level==2){
        this.majorListOne.push(n);
      }
    }
  }

   //监听majorOne
   majorOneChange(){
    this.obj.initSelect();
    this.majorListTwo = [];
    this.majorList.map((item,index)=>{
      if(item.level == 2 && item.parentcode == this.majorOne){
        item.id = index;
        item.name = item.text;
        this.majorListTwo.push(item)
      }
    });
    this.advanceModel.major='';
    this.getRecommendList()
  }

  /**
   * 点击极速短信联系-弹出发送短信的弹窗
   */
  alertSengMSG(n,event){
    event.stopPropagation();
    let that = this;
    this.opera._hmtPush("推荐人才","职位推送","")
    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.productManager.getProductUsableCount(this.config.sms_bulkinvitation,(count,isVip)=>{
          if(count==0||!isVip)
          {
            // this.config.payVipHistoryPage = {
            //   page:'推荐人才',
            //   isVip:isVip,
            //   pro:'职位推送'
            // };
            // that.local.set("single_category_code",this.config.ProductListCode.sms_bulkinvitation_code);
            // that.router.navigate(['home/tool-campus']);
            this.product('sms_bulkinvitation','recharge');
          } else {
            this.sengMsgModel.initData(1,[]);
            this.SengMsgModel.userName = n.talent_name;
            this.SengMsgModel.userPic = n.logo==null?'': n.logo;
            this.SengMsgModel.graduation_school = n.graduation_school;
            this.SengMsgModel.major_text = n.major_text;
            this.SengMsgModel.talentid = n.talentid;
            this.SengMsgModel.gender = n.gender;
            this.SengMsgModel.address = n.address_text;
            this.SengMsgModel.positionid = this.advanceModel.positionid;
            this.modelShowSengMsg =true;
          }
        })
      } else {
        //that.opera.checkMemberAlertWithType("noneVip_sms_bulkinvitation")
        this.product('sms_bulkinvitation','recharge');
      }
    })
  }


  Modeldismiss(event) {
    this.modelShowSengMsg = false;
    this.config.batchList = [];
    this.useBatch = false;
    this.getDepartment();
  }


  // 切换批量邀投开关
  useBatchFn(){
    if(this.positionList.length==0){
      this.toastr.warning('发布职位后可使用批量职位推送')
      this.router.navigateByUrl('/home/position-manage');// 职位推送
      return
    }
    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.router.navigateByUrl('/home/positionPushs');// 职位推送
      } else {
        //this.opera.checkMemberAlertWithType("noneVip_sms_bulkinvitation")
        this.product('sms_bulkinvitation','recharge');
      }
    })
  }

  // 本页全选
  selectAllFn(){
    console.log(this.resumeList);
    if(!this.selectAll){
      for(let i of this.resumeList){
        if(this.config.batchList.indexOf(i.talentid) == -1){
          this.config.batchList.push(i.talentid);
        }
      }
      this.selectAll = true;
    }else{
      for(let i of this.resumeList){
        this.config.batchList.splice(this.config.batchList.indexOf(i.talentid),1);
      }
      this.selectAll = false;
    }
    console.log(this.config.batchList);
  }

  // 检测是否全选
  checkAll(){
    for(let i of this.resumeList){
      if(this.config.batchList.indexOf(i.talentid) == -1){
        this.selectAll = false;
        break;
      }else{
        this.selectAll = true;
      }
    }
    console.log(this.selectAll);
  }

  // 本页反选
  selectRevFn(){
    console.log(this.config.batchList);
    for(let i of this.resumeList){
      if(this.config.batchList.indexOf(i.talentid) == -1){
        this.config.batchList.push(i.talentid);
      }else{
        this.config.batchList.splice(this.config.batchList.indexOf(i.talentid),1);
      }
    }
    this.checkAll();
  }

  // 单个学生选择
  selectStudentFn(n,event){
    event.stopPropagation();
    if(this.config.batchList.indexOf(n.talentid) == -1){
      this.config.batchList.push(n.talentid);
    }else{
      this.config.batchList.splice(this.config.batchList.indexOf(n.talentid),1);
    }
    console.log(this.config.batchList.indexOf(n.talentid));
    this.checkAll();
  }

  // 活动通知包触发
  singleTips(){
    this.modelShowSengMsg =true;
    this.sengMsgModel.initData(2,this.config.batchList);

  }

  // 7/10 获取热门的省(有学生的省。高级筛选功能 )
  getHotProvice(){
    this.dataApi.getCollegeHotCityWithProvinceAndCity("?dict_type=native_place&parentcode=0").then((res:any)=>{
      if(res){
        this.HotCityAndProvinceArr = res;
        let Province = [];
        for (let index=0;index<res.length;index++)
        {
          let item = res[index];

          if(item.parentcode=='0')
          {
            Province.push(item)
          }
        }
        this.hotProvince=Province;
      }
    }).catch((err)=>{
    })
  }
  seleteProvince(){
    console.log("省",this.advanceModel.provice)
    if(this.advanceModel.provice=="")
    {
      this.advanceModel.native_place = "";
      this.getRecommendList();
    }

    let Province = [];
    for (let index=0;index<this.HotCityAndProvinceArr.length;index++)
    {
      let item = this.HotCityAndProvinceArr[index];

      if(item.parentcode==this.advanceModel.provice)
      {
        Province.push(item)
      }

    }
    this.hotCity=Province;


  }

  closeModel(){
    this.showModel = false;
  }

  //type 1 报名双选会 2预约宣讲会
  ModelgoToBaoMing(type){
    this.showModel = false;
    var that = this;
    setTimeout(()=>{
      if(type==1) {
        that.router.navigateByUrl("home/college-milkround"); // 报名双选会
      } else {
        that.router.navigateByUrl("home/college-campustalk1"); // 会员页面
      }
    },400)
  }

  //显示手机号弹窗
  ShowPhoneModel(n,event){
    event.stopPropagation();
    this.opera._hmtPush("推荐人才","极速电话联系","")
    let that = this;
    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.productManager.getProductUsableCount(this.config.voice_call,(count,isVip)=>{
          if(count==0||!isVip) {

            // this.config.payVipHistoryPage = {
            //   page:'推荐人才',
            //   isVip:isVip,
            //   pro:'极速电话联系'
            // };
            // that.local.set("single_category_code", this.phoneModel.jiduCode);
            // that.router.navigate(['home/tool-campus']);
            this.product('voice_call','recharge');
          } else {
            if(this.advanceModel.positionid){
              n.positionid = this.advanceModel.positionid;
            }else {
              n.positionid = "";
            }
            this.voiceCallModel.initData(n);
            this.isShowVoiceCallModel = true;
          }
        })
      } else {
        // that.opera.checkMemberAlertWithType("noneVip_voice_call")
        this.product('voice_call','recharge');
      }
    })
  }


  //获取企业信息
  getDepartment(){
    this.dataApi.getcomponentLeft({}).then((res:any)=>{
      let arr = [];
      if(res)
      {
        res.map((item)=>{
          if(item.alias == 'sms_bulkinvitation')
          {
            this.sms_bulkinvitation = item;
          }
          if(item.alias == 'voice_call')
          {
            this.voice_call = item;
          }
        })
      }

    }).catch((err)=>{
      console.log(err);
    })
      this.dataApi.getEmployment().then((res:any)=>{//通过tooken获取企业信息
        if(res){
          this.myDepartment = res;
          this.initPhoneData(this.myDepartment);
          this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
        }
      }).catch((error)=>{
        this.toastr.error(error);
      });
  }
  //处理初始化电话联系quota
  initPhoneData(deparment){
    this.productManager.getProductUsableCount(this.config.voice_call,(count)=>{
      this.phoneModel.MyPhoneTime = count;
    })
  }
  dissMissPhone(){

  }

  goBuy(n){
    this.config.userInitiativePayName = n

    this.opera.checkMember((isPay)=>{
      if(isPay) {
        if(n=="voice_call") {
          this.opera._hmtPush("推荐人才","极速电话联系购买","")
        } else {
          this.opera._hmtPush("推荐人才","职位推送购买","")
        }
        if(!this.config.isShowPaySystem) {
          this.config.payVipHistoryPage = null
        }
        this.productList.map((item,index)=>{
          if(item.recharge_category == n){
            this.local.set("recharge_category_code", JSON.stringify(item.code));
            // this.router.navigate(['home/buyProduct']);
            this.router.navigate(['home/tool-campus']);
            return
          }
        })
      } else {
        this.opera.checkMemberAlertWithType("noneVip_voice_call")
      }
    })


  }
  getProductList(){
    //product_category:"recharge",recharge_category:''
    this.opera.loading();
    this.dataApi.departmentList({product_category:"recharge"}).then((res:any)=>{
      this.opera.loadiss();
      if(res){
        this.productList = res.list;
        for (let item of res.list)
        {
          if(item.recharge_category == "voice_call")
          {
            this.phoneModel.jiduCode = item.code;
          }
          if(item.recharge_category == "sms_bulkinvitation")
          {
            this.phoneModel.positionPushCode = item.code;
          }
        }
      }
    }).catch((err)=>{
      console.log(err);
      this.opera.loadiss();
    })
  };
  text(){
    // this.msgContent.nativeElement.scroll(0,this.msgContent.nativeElement.scrollTop +120);
    window.scrollTo(0,this.scrollTop+=120);
  }
  initScrooll() {
    console.log('this.positionresumelistindex',this.positionresumelistindex)
    window.scrollTo(0,this.positionresumelistindex<=3?0:this.scrollTop + (120*this.positionresumelistindex));
  }
  initScroollZero()
  {
    this.positionresumelistindex=0;
    window.scrollTo(0,0);
  }
  getMyresumeLabelList(){
    let data = {
      offset:'0',
      limit:'40',
    }
    this.dataApi.getMyresumeLabelList(data).then((res:any)=>{
      if(res)
      {
        console.log('我的标签',res)
      }
    }).catch(()=>{
    })

  }
  //极速电话联系弹窗消失
  voiceCallModelHidden(e){
    this.isShowVoiceCallModel = false;
  }

  //对我感兴趣
  goInterested(){
    this.router.navigate(['home/chat-collection']);
  }
}
