<!-- 引导页面-->
<div class="page_guide">
  <router-outlet></router-outlet>
  <app-model-select-two #guideIndustry [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-select-two>
  <app-model-cropper #crop [isShow]="modelTwoShow" (onVoted)="ModelTwodismiss($event)"></app-model-cropper>
  <div class="d_f page_guide_head">
    <img class="page_guide_head_img" src="assets/images/v3/loginLogo.png">
    <div class="page_guide_head_text">招大学生，就用「工作啦」</div>
  </div>
  <div class="page_guide_main">
    <div class="d_f page_guide_step">
      <img class="page_guide_step_img" src="assets/images/v3/index1.png">
      <div class="page_guide_step_text">编辑招聘信息</div>
    </div>
    <div class="page_guide_title">填写公司信息</div>
    <!-- 公司全称 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">公司全称<span class="remind_red">（必填 ）</span></div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input">
          <input class="w_100 input" type="text" [(ngModel)]="model.name" maxlength="40" placeholder="请填写公司全称"
            (blur)="checkName()">
        </div>
        <div class="page_guide_cell_explain">一经填写不可修改，请与营业执照保持一致</div>
      </div>
    </div>
    <!-- 公司简称 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">公司简称<span class="remind_red">（必填 ）</span></div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input">
          <input class="w_100 input" type="text" [(ngModel)]="model.common_name" maxlength="6" placeholder="请填写公司对外的简称"
            (blur)="checkCommonName()">
        </div>
        <div class="page_guide_cell_explain">一经填写不可修改，请谨慎填写</div>
      </div>
    </div>
    <!-- 所属行业 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">所属行业<span class="remind_red">（必填 ）</span></div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input icon" (click)="chooseIndustry()">
          <div *ngIf="!model.industry" class="w_100 d_f input placholder">请选择选择所属行业</div>
          <div *ngIf="model.industry" class="w_100 d_f input">{{ model.industry | codetransform:config.industry}}</div>
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
    </div>
    <!-- 公司性质 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">公司性质<span class="remind_red">（必填 ）</span></div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input icon">
          <select class="w_100 input" [class]="!model.employer_type?'placholder':''" name="employer_type"
            id="employer_type" [(ngModel)]="model.employer_type" autocomplete="off">
            <option value='' disabled hidden selected="selected">请选择公司性质</option>
            <option *ngFor="let a of config.employer_type" [value]="a.code">{{a.text}}</option>
          </select>
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
    </div>
    <!-- 企业注册地 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">注册地<span class="remind_red">（必填 ）</span></div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="d_f">
          <div class="f_1 page_guide_cell_input icon">
            <select class="w_100 input" name="employer_type" id="employer_category"
              (ngModelChange)="employer_categoryChange($event)" [(ngModel)]="globalRegionValue" autocomplete="off">
              <option class="place-text" value='' disabled hidden selected="selected">请选择公司性质</option>
              <option *ngFor="let a of globalRegionArr1" [value]="a.code">{{a.text}}</option>
            </select>
          </div>
          <div class="f_1 page_guide_cell_input icon">
            <select class="w_100 input" [class]="!model.employer_category?'placholder':''" name="employer_type" style="margin-left: 20px" id="employer_category2"
              [(ngModel)]="model.employer_category" autocomplete="off">
              <option class="place-text" value='' disabled hidden selected="selected">请选择</option>
              <option *ngFor="let a of globalRegionArr2" [value]="a.code">{{a.text}}</option>
            </select>
          </div>
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
    </div>
    <div class="page_guide_title">填写招聘负责人信息</div>
    <div class="page_guide_upload">
      <div class="page_guide_upload_title">上传头像</div>
      <label class="page_guide_upload_box" *ngIf="!userAvatar" for="uploadLoginAvatar">
        <img class="page_guide_upload_addbtn" src="assets/images/v3/companyPush.png">
      </label>
      <label class="page_guide_upload_box" *ngIf="userAvatar" for="uploadLoginAvatar">
        <img class="page_guide_upload_img" [src]="userAvatar">
      </label>
      <input class="uploadImg" id="uploadLoginAvatar" (change)="previewPic($event)" type="file"
        accept="image/jpeg,image/jpg,image/png">
      <div class="page_guide_upload_explain">点击上传用户头像</div>
      <div class="page_guide_upload_explain">像素：200*200px,格式：jpg,png</div>
    </div>
    <!-- 姓名 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">姓名<span class="remind_red">（必填 ）</span></div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input">
          <input class="w_100 input" type="text" [(ngModel)]="model.linkman" placeholder="请填写招聘负责人姓名" maxlength="8"
            (blur)="checkLinkman()">
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
    </div>
    <!-- 性别 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">性别</div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input icon">
          <select class="w_100 input" autocomplete="off" [(ngModel)]="model.gender">
            <option class="place-text" value='' disabled hidden selected="selected">选择性别</option>
            <option value="01">男</option>
            <option value="02">女</option>
          </select>
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
    </div>
    <!-- 职务 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">职务<span class="remind_red">（必填 ）</span></div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input">
          <input class="w_100 input" type="text" [(ngModel)]="model.post" placeholder="请填写职务" (blur)="checkPost()">
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
    </div>
    <!-- 工作邮箱 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">工作邮箱<span class="remind_red">（必填 ）</span></div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input">
          <input class="w_100 input" type="text" [(ngModel)]="model.email" placeholder="请填写工作邮箱" (blur)="checkEmail()">
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
      <div class="d_f jc_sb page_guide_cell_radiobox">
        <div class="radio" [ngClass]="{'checked':model.contact_info_switch.is_public_email}"
          (click)="model.contact_info_switch.is_public_email=true">公开</div>
        <div class="radio" [ngClass]="{'checked':!model.contact_info_switch.is_public_email}"
          (click)="model.contact_info_switch.is_public_email=false">不公开</div>
      </div>
    </div>
    <!-- 微信号 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">微信号<span class="remind_red">（必填 ）</span></div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input">
          <input class="w_100 input" type="text" [(ngModel)]="model.wechat" placeholder="请填写微信号">
        </div>
        <div class="page_guide_cell_explain">用于学生投递简历后添加您进行线上面试</div>
      </div>
      <div class="d_f jc_sb page_guide_cell_radiobox">
        <div class="radio" [ngClass]="{'checked':model.contact_info_switch.is_public_wx}"
          (click)="model.contact_info_switch.is_public_wx=true">公开</div>
        <div class="radio" [ngClass]="{'checked':!model.contact_info_switch.is_public_wx}"
          (click)="model.contact_info_switch.is_public_wx=false">不公开</div>
      </div>
    </div>
    <!-- 绑定手机 -->
    <div class="d_f ai_fs page_guide_cell">
      <div class="d_f page_guide_cell_title">绑定手机<span class="remind_red">（必填 ）</span></div>
      <div class="f_1 page_guide_cell_inputbox">
        <div class="page_guide_cell_input">
          <input class="w_100 input" type="text" disabled [ngModel]="loginPhone" placeholder="请填写手机号">
        </div>
        <div class="page_guide_cell_explain"></div>
      </div>
      <div class="d_f jc_sb page_guide_cell_radiobox">
        <div class="radio" [ngClass]="{'checked':model.contact_info_switch.is_public_mobile}"
          (click)="model.contact_info_switch.is_public_mobile=true">公开</div>
        <div class="radio" [ngClass]="{'checked':!model.contact_info_switch.is_public_mobile}"
          (click)="model.contact_info_switch.is_public_mobile=false">不公开</div>
      </div>
    </div>
    <!--按钮-->
    <div class="ta_c">
      <button class="page_guide_button" (click)="setOneStep()">提交</button>
    </div>
  </div>
  <!-- 尾部 -->
  <div class="d_f page_guide_footer">
    <div class="f_1 page_guide_footer_logout" (click)="logoutAccountClick()">注销账号</div>
    <div class="d_f jc_sb" style="width:1100px">
      <div class="page_guide_footer_service">客服热线：{{config.CustomerServicePhone}}</div>
      <div class="page_guide_footer_record">{{config.websiteRecord}}</div>
    </div>
    <div class="f_1"></div>
  </div>
</div>