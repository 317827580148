import { Component } from '@angular/core';
import { Router,ActivatedRoute,NavigationEnd } from  '@angular/router';
import { Events } from '../provider/eventService';
import { ToastrService } from 'ngx-toastr';
import { DataApi,Local,Constants,Config,Opera,MyMMessage } from '../provider/index';
import { ConfirmComponent } from './confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { timeout } from 'rxjs/operators';
import {Location} from "@angular/common";
import { Observable } from 'rxjs';

declare var window;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public config: Config;
  public isStop=false;//是否停止定时器
  navOn = 0;
  public top_header = false;
  constructor( private router: Router,
               public mimc:MyMMessage,
               public local: Local,
               public events: Events,
               public dataApi: DataApi,
               public toastr: ToastrService,
               public opera:Opera,
               private activatedRoute: ActivatedRoute,
               private location: Location,
               public dialog: MatDialog)
  {
    this.config = Config.getInstance();//实例化config
    this.events.subscribe('updateDepartmentAuthentication', (data) => {
      this.dataApi.getDepartment().then((res:any)=>{
        this.dataApi.setLocalDepartment(res);
        this.config = Config.getInstance();
      }).catch((error)=>{
      });
    });
    // 接收api.ts通知错误退出登录广播
    this.events.subscribe('user:created', () => {
      this.config.isNavActive = 'login';
      // this.events.unsubscribe('user:created');//取消订阅api.ts通知退出登陆事件
      this.setRoot();//不重新登录无法接收消息，踢到登录页
   });
   //订阅拉黑报错
   this.events.subscribe('user:pullblack', () => {
      this.toastr.error("账号被拉黑，暂不支持使用");
      this.setRoot();
      this.dialog.open(ConfirmComponent, {
        data: {
          title: '提示',
          message: `因账号存在发布虚假招聘信息、以招聘名义招生等违规招聘行为被学生或高校投诉，您的账号已被冻结，目前仅可使用“企业介绍”及“职位发布”功能。7个工作日未解封或未接到工作人员电话，可发申诉信息致service@gowork.la。`,
          trueText:'确定',
          falseText:'取消'
        }
      }).afterClosed().subscribe((isConfirmed) => {
        this.dialog.closeAll()
      });
   });
   this.checkIsLogin();
   this.config.is618  = false
  }
  goUpLevel(){
    window.open(this.config.member_shengji_url,'_blank');
    return;
  }
  //去账号管理
  goAccount(){
    this.router.navigateByUrl('/home/company-account');
  }
  //去公司主页
  goComHome(){
    this.router.navigateByUrl('/home/company-details');
  }
  //去通知页
  goNotification(){
    this.router.navigateByUrl('/home/notification-center');
  }
  // 收藏网站
  addFavorite(url, title) {
    // 浏览器用于 HTTP 请求的用户代理头的值，可判断浏览器种类
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("360se") > -1) {
      alert("由于360浏览器功能限制，请按 Ctrl+D 手动收藏！");
    } else if (userAgent.indexOf("msie 8") > -1) {
      window.external.AddToFavoritesBar(url, title); // IE8
    } else if (document.all) { // 用于判断IE
      try {
          window.external.addFavorite(url, title);
      } catch (e) {
          alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
      }
    } else if (window.sidebar) {
      // Firefox已经取消window.sidebar.addPanel 处理方法见后面
      alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
    } else {
      alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
    }
  }

  // 跳转页面
  goPage(navUrl){
    console.log(navUrl);
    if(navUrl == 'chat-look' || navUrl == 'chat-collection'){
      this.router.navigateByUrl('/home/' + navUrl);
    }else if(navUrl == 'company-authentication'){
      this.dataApi.getPlatformApply().then((res:any)=>{
        this.config.lastIdentity=res;
        console.log("lastIdentity,");
        if(res.status == 2){
          this.router.navigateByUrl('/home/company-authentication-suc');
        }else if(res.status == 1){
          this.router.navigateByUrl('/home/company-authentication-wait');
        }else if(res.status == 3){
          this.router.navigateByUrl('/home/company-authentication');
        }else{
          this.router.navigateByUrl('/home/company-authentication');
        }
      }).catch((err)=>{
        this.router.navigateByUrl('/home/company-authentication');
      })
    }else if(navUrl == 'member-center'){
      console.log(navUrl);
      this.router.navigateByUrl('/home/tool-campus');
    }else{
      this.router.navigateByUrl('/home/' + navUrl);
    }
  }
  //退出
  goLogin(){
    this.opera.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '您是否要退出登录？',
        trueText:'确定',
        falseText:'取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      this.opera.scrollTrue();
      if(isConfirmed){
        this.setRoot();//不重新登录无法接收消息，踢到登录页
      }
    });
  }

  //接收错误信息返回登录
  setRoot(){
    this.dataApi.logout();
    this.router.navigateByUrl("login");
  }

  //获取企业信息
  getDepartment(){
    this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
      if(res){
        //是否冻结判断
        this.config.department = res;
        if(res.is_frozen==true){
          this.config.is_frozen=true
          //判断当前是否在冻结的默认页面
          if(this.router.url=='/home/company-details'){
            if(this.config.is_frozen==true){
              this.dialog.open(ConfirmComponent, {
                data: {
                  title: '冻结提示',
                  message: `因账号存在发布虚假招聘信息、以招聘名义招生等违规招聘行为被学生或高校投诉，您的账号已被冻结，目前仅可使用“企业介绍”及“职位发布”功能。7个工作日未解封或未接到工作人员电话，可发申诉信息致service@gowork.la`,
                  trueText:'确定',
                  falseText:'取消'
                }
              })
            }
          }else{
            this.router.navigateByUrl('/home/company-details');
          }
        }else{
          this.config.is_frozen=false;
        }
        if(res.logo){
          let fops = '&imageView2/1/w/100/h/100';
          res.logo = res.logo+fops;
        }
        if(!res.intro) {
          this.dataApi.getPlatformApply().then((res:any)=>{
            if(res&&res.status!=0) {
              this.toastr.warning('请先完善企业信息')
              setTimeout(()=>{
                this.router.navigateByUrl('/home/company-details');
              },10)
            } else {
              setTimeout(()=>{
                this.router.navigateByUrl('guide-company');
              },10)
            }
          }).catch((error) => {
            console.log(error);
          });
        }
        this.dataApi.setLocalAccountId(res.accountid);//将accounid存到本地、全局变量
        this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
        this.mimc.init(res.jm_username);//登陆mimc
      } else {
         this.router.navigateByUrl('/login');
      }
    }).catch((error)=>{
    });
  }

  //获取当前登录用户的信息(user信息)
  getUser(){
    this.dataApi.getUser().then((res:any)=>{//通过tooken获取企业信息
      if(res){
        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
      }
    }).catch((error)=>{
    });
  }
  getMemberInfo(){
    this.dataApi.getMemberInfo().then((res:any)=>{
      console.log(res)
      if(res) {
        this.config.memberInfo = res
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }
  //获取企业认证信息
  getPlatformIdentity(){
    this.dataApi.getPlatformApply().then((res:any)=>{
      if(res){
         if(res.status=='2'){
           if(this.config.department) {
             this.config.department.is_certified=true;
             this.dataApi.setLocalDepartment(this.config.department);
           }
         }
         this.config.lastIdentity=res;
         this.dataApi.setLastIdentity(this.config.lastIdentity);
      }
     }).catch((error)=>{
     })
  }
  //获取高校最后一次认证信息
  lastAuthenticationIdentity(){
    this.opera.loading();
    this.dataApi.lastAuthenticationIdentity().then((res:any)=>{
      this.opera.loadiss();
      if(res) {
        this.config.lastCollegeIdentity=res;
        this.dataApi.setLastCollegeIdentity(this.config.lastCollegeIdentity);
      }
    }).catch((error)=>{
      ////console.log(error);
      this.opera.loadiss();
    })
  }
  //获取地区字典
  getRegion(){
    this.dataApi.getDictDate('region').then((res:any)=>{
      if(res) {
        this.dataApi.setRegionCode(res);
      }
    }).catch((error)=>{
    });
  }
   //-------获取线上数据
   getNewList(){
    this.dataApi.getPositionList({limit:100,offset:0,status:'release'}).then((res:any)=>{
      if(res) {
        this.dataApi.setLocalpositionList(res.list);
      }
     }).catch((error) => {
     });
   }

  //初始化全局信息
  checkIsLogin() {
      //'获取本地accesstoken和accountid
      this.config.token = this.local.get(Constants.accesstoken);
      this.config.accountid = this.local.get(Constants.accountid);
      let hash = location.hash;
      let arr = hash.split('?');
      let accountid = "";
      let token = "";
      if(arr.length>1) {
        let urlStr = arr[1];
        let itemAr = urlStr.split('&');
        for (let item of itemAr) {
          let dataArr = item.split('=');
          if(dataArr.length>=2) {
            if(dataArr[0]=='accountid') {
              accountid = dataArr[1];
            }
            if(dataArr[0]=='token') {
              token = dataArr[1];
            }
          }
        }
        if(accountid && token){
          this.config.token = token;
          this.config.accountid = accountid;
          this.local.set(Constants.accesstoken,token);
          this.local.set(Constants.accountid,accountid)
        }
      }

    //获取登录账号，方便出错登录
      this.config.loginName = this.local.get(Constants.loginName);
      //console.log("this.config.accountid",this.config.token,this.config.accountid)
      //检测是否已经登录
      if(this.config.token && this.config.accountid){
        let path = this.location.path();
        if(path.includes('source=mansite')){
          return
        }
        this.getDepartment();//获取企业信息
        this.getUser();//获取当前登录用户的信息(user信息)
        this.getPlatformIdentity();//获取企业认证信息
        this.getNewList();//获取职位列表
        this.getRegion();//获取地区字典
        this.lastAuthenticationIdentity();//高校最后一次认证信息
        this.getMemberInfo();

      } else{
        console.log("未登录");
      }
  }
  //发票点击事件
  orderClick(index){
    if(index==1) {
      if(this.config.ordertoPayCount!=0) {
        this.local.set('order_state','to_pay');
      }
      this.router.navigate(['home/paymentorderlist']);
    } else {
      this.router.navigate(['home/payInvoice']);
    }
  }
  gotoRenzheng(){
    this.router.navigateByUrl('guide-audit');
  }
}
