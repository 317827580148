<!-- 主体内容部分 -->
<div class="homepage-block">
    <router-outlet></router-outlet>
    <div class="college-block">
        <div class="secondNav">
            <div class="item " (click)="myTool()">我的工具</div>
            <div class="item active">我的订单<span></span></div>
            <div class="item" (click)="myInvoice()">我的发票</div>
            <div class="item" (click)="myLadou()">啦豆明细</div>
        </div>
        <!--<div class="centerBox"  *ngIf="config.lastIdentity&&config.lastIdentity.status==0">-->
        <!--<div class="identityBox">-->
        <!--<img class="step3_icon" src="../../assets/images/shenhe.png">-->
        <!--<p class="step3_title1">您暂未工作啦认证</p>-->
        <!--<div class="resetComit flex_c" (click)="gotoidentity()"><div>去认证</div></div>-->
        <!--<app-gu-wen-card-view></app-gu-wen-card-view>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="centerBox"  *ngIf="config.lastIdentity&&config.lastIdentity.status==1">-->
        <!--<div class="identityBox">-->
        <!--<img class="step3_icon" src="../../assets/images/shenhe.png">-->
        <!--<p class="step3_title1">认证中...</p>-->
        <!--<app-gu-wen-card-view></app-gu-wen-card-view>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="centerBox"  *ngIf="config.lastIdentity&&config.lastIdentity.status==3">-->
        <!--<div class="identityBox">-->
        <!--<img class="step3_icon" src="../../assets/images/shenhe.png">-->
        <!--<p class="step3_title1">认证失败</p>-->
        <!--<p class="step3_title2 color_red">{{config.lastIdentity.remark}}</p>-->
        <!--<div class="resetComit flex_c" (click)="gotoidentity()"><div>重新申请</div></div>-->
        <!--<app-gu-wen-card-view></app-gu-wen-card-view>-->
        <!--</div>-->
        <!--</div>-->

        <div class="centerBox">
            <div class="custom_tabs">
                <div (click)="navClick('')" class="custom_tab" [ngClass]="{active:currentNav==''}">全部订单</div>
                <div (click)="navClick('to_pay')" class="custom_tab" [ngClass]="{active:currentNav=='to_pay'}">待支付</div>
                <div (click)="navClick('done')" class="custom_tab" [ngClass]="{active:currentNav=='done'}">已完成</div>
                <div (click)="navClick('canceled')" class="custom_tab" [ngClass]="{active:currentNav=='canceled'}">已失效</div>
            </div>

            <div class="listBox flex_l" *ngIf="payList.length>0">
                <div class="o_cell" *ngFor="let n of payList;let i = index;">
                    <div class="o_cell_title flex_s">
                        <div class="flex_l">
                            <img class="tehui" src="../../assets/images/bq_tehui.png" *ngIf="n.urm_create" />
                            <div class="order_state_text" [ngClass]="{'orderSuccessColor':n.order_state=='executed'||n.order_state=='paid','orderPaying':n.order_state=='to_pay'}">{{n.order_state_text}}</div>
                            <div class="orderNumber" *ngIf="n.pay_orderid">订单号：{{n.pay_orderid}}</div>

                        </div>
                        <div class="flex_r">
                            <div class="orderLookDetail flex_c" *ngIf="n.isShowLookDetail" (click)="openDetail(n,i)">{{n.isOpen?'收起':'展开'}}详情
                                <img *ngIf="!n.isOpen" src="../../assets/images/v3/arrow_down.png" />
                                <img *ngIf="n.isOpen" src="../../assets/images/v3/arrow_up.png" />
                            </div>
                            <div class="repeatCommit" *ngIf="(n.order_state=='done'||n.order_state=='canceled')&&!n.urm_create && config.isShowPaySystem" (click)="zailaiPay(n)"> {{n.order_state==4?"再来一单":"再次提交"}}</div>
                        </div>
                    </div>
                    <div class="d_f jc_sb o_cell_subDetail" *ngIf="n.order_category!= 'recharge'">
                        <div class="orderNumber">{{n.order_navData}}</div>
                        <div class="o_cell_subDetail_l" *ngIf="!n.isOpen">
                            <span *ngFor="let item of n.product_package_list">{{item.product_name}}*{{item.count}}</span>
                        </div>
                        <div class="o_cell_subDetail_r">
                            {{n.item_r_title}}
                        </div>
                    </div>
                    <div class="o_cell_bottom" *ngIf="n.isOpen">
                        <div class="procuctListBox ">
                            <div class="flex_l procuctListCell p_ListBox_title">
                                <div class="p_ListBox_col1">权益名称</div>
                                <!--<div class="p_ListBox_col2">商品单价</div>-->
                                <div class="p_ListBox_col3">权益明细</div>
                                <div class="p_ListBox_col2">数量</div>
                                <!--<div class="p_ListBox_col2 text_r">小计（啦豆）</div>-->
                            </div>
                            <div class="flex_l procuctListCell" *ngFor="let item of n.product_package_list">
                                <div class="p_ListBox_col1">{{item.product_name}} <span class="dongjie" [ngClass]="{'cancel_gray':n.order_state=='canceled'}" *ngIf="item.subTitle">{{item.subTitle}}</span></div>
                                <!--<div class="p_ListBox_col2">{{item.product_category=='ladou_gift'|| item.product_category=='ladou_gift_coupon'?"-":item.cost+item.unit}} <span *ngIf="item.product_category=='ladou'">元</span></div>-->
                                <div class="p_ListBox_col3">{{item.quanyi}}</div>
                                <div class="p_ListBox_col2">{{item.product_category=='ladou_gift'|| item.product_category=='ladou_gift_coupon'?item.total_cost :item.count}}</div>
                                <!--<div class="p_ListBox_col2 text_r">-->
                                <!--<span *ngIf="!item.isGive">{{item.total_cost}}</span>-->
                                <!--<span *ngIf="item.isGive"><span class="deleteLine">{{item.total_cost}}</span> 0 </span>-->
                                <!--</div>-->
                            </div>
                        </div>
                        <div class="procuctBottomBox2" *ngIf="n.isOpen">
                            <div class="bottomHeji" id="totalText" *ngIf="!n.urm_create">{{n.totalText}}<span class="jiageColor" *ngIf="n.totalText2">{{n.totalText2}}</span> </div>
                        </div>
                        <div class="d_f jc_sb procuctBottomBox4">
                            <div class="ta_l" *ngIf="n.urm_create &&n.channel_category=='offline'">
                                <div class="flex_l">对公账户:<span class="val">河南英才归来科技有限公司</span></div>
                                <div class="flex_l">开户行:<span class="val">上海浦东发展银行郑州花园路支行</span></div>
                                <div class="flex_l">账号:<span class="val">7611 0154 8000 0831 1</span></div>
                            </div>
                            <div *ngIf="!(n.urm_create &&n.channel_category=='offline')"></div>
                            <div class="ta_r">
                                <div *ngIf="n.urm_create">需支付:<span class="bright strong">￥{{n.amount_payable}}</span></div>
                                <div *ngIf="n.urm_create&&n.channel_category=='offline'">支付方式:<span class="val">对公转账</span></div>
                                <div *ngIf="n.isHaveProduct">产品包有效期均:<span class="val strong">365天<span *ngIf="!n.urm_create">（购买即生效)</span></span>
                                </div>
                                <div *ngIf="n.isHavelabi">啦豆长期有效</div>
                            </div>
                        </div>
                        <div class="bottomBtnBg" *ngIf="(n.order_state=='to_pay'|| n.order_state=='canceled')&&n.channel_category!='offline'">
                            <span class="dongjie" [ngClass]="{dongjie_cancel:n.order_state=='canceled'}" style="width: 500px;position: relative" *ngIf="(n.order_state=='to_pay'|| n.order_state=='canceled')&& n.amount_forzen>0">{{n.order_state=='to_pay'?"此订单冻结"+n.amount_forzen+"（啦豆） 取消订单后返还冻结中的啦豆":"冻结啦豆："+n.amount_forzen+"（已返还）"}}</span>
                            <div class="flex_r" *ngIf="n.order_state=='to_pay'">
                                <div class="bottomBtnBg_b btnCancel bordeRadius" *ngIf="!n.urm_create &&auditStatus==2" (click)="cancelOrder(n)">取消订单</div>
                                <div class="bottomBtnBg_b btnPay bordeRadius" (click)="continueOrder(n)">立即付款</div>
                                <!--<div class="bottomBtnBg_b lianxiKefu bordeRadius" *ngIf="auditStatus!=2" (click)="kefuClick()">联系客服</div>-->
                            </div>
                            <!--<div *ngIf="auditStatus!=2" class="kefuColor">审核通过后即可完成付款，联系客服极速过审</div>-->

                        </div>
                    </div>
                </div>
            </div>

            <!-- 空状态 -->
            <div class="none-block-empty" *ngIf="payList.length == 0">
                <img src="assets/images/rights/empty.png">
                <p>暂无订单</p>
            </div>

            <div class='paging-block' [class.isPageHeight]="countList>0">
                <router-outlet></router-outlet>
                <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
            </div>

        </div>
    </div>
</div>