<!-- 三级单选 -->
<!-- 遮罩层公共样式 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px','width':'700px'}" [(visible)]="isShow" (onHide)="dismiss(false)" [modal]=true>
    <div class="model-cont">
        <div class="model-head clear">
            <div class="left">{{title}}</div>
            <div class="clos" (click)="dismiss(false)"><img src="/assets/images/rights/contactMeClos.png"></div>
            <div style="clear: both;"></div>
            <div class="sea-box">
                <input type="text" placeholder="输入关键词搜索" [(ngModel)]="searchText" (ngModelChange)="searchChange()">
                <div class="searchBox" *ngIf="searchResultArr.length>0 && searchText">
                    <div *ngFor="let item of searchResultArr" (click)="getChildLevel($event,item)">{{item.text}}</div>
                </div>
            </div>
        </div>

        <div class="select-more-block  clear">
            <div id="first-level" class="first-level" style="overflow-y: scroll;">
                <div class="list-block" *ngFor="let item of firstLevel" (click)="getFirstLevel(item)" [class.activeAddress]="item.code==oneActive.code">
                    {{ item.text }}
                </div>
            </div>
            <div #secend id="secend-level" class="secend-level" style="overflow-y: scroll;">
                <div class="list-block" *ngFor="let item of secendLevel" (click)="getSecendLevel(item)" [class.activeAddress]="item.code==twoActive.code">
                    <div class="list-text">{{ item.text }}</div>

                    <div class="child-level" [class.active]="item.isShow!='0' && item.isShow">
                        <div class="child-list" *ngFor="let child of childLevel" [class.activeChild]="child.code==threeActive.code" (click)="getChildLevel($event,child)">{{child.text}}</div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</p-dialog>

<!-- <div class="model-block select-block" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>
    <div class="model-cont">
        <div class="model-head clear">
            <div class="left">{{title}}</div>
            <button class="right" (click)="dismiss(false)">×</button>
            <div id="search" class="flex_l">
                <input type="text" placeholder="输入关键词搜索" [(ngModel)]="searchText" (ngModelChange)="searchChange()">
                <div class="searchBtn flex_c" (click)="searchClick()">
                    <img src="assets/images/search.png">
                </div>
            </div>
        </div>
        <div class="searchBox" *ngIf="searchResultArr.length>0 && searchText">
            <div *ngFor="let item of searchResultArr" (click)="getChildLevel($event,item)">{{item.text}}</div>
        </div>
        <div class="select-more-block  clear">
            <div id="first-level" class="first-level" style="overflow-y: scroll;">
                <div class="list-block" *ngFor="let item of firstLevel" (click)="getFirstLevel(item)" [class.activeAddress]="item.code==oneActive.code">
                    {{ item.text }}
                </div>
            </div>
            <div #secend id="secend-level" class="secend-level" style="overflow-y: scroll;">
                <div class="list-block" *ngFor="let item of secendLevel" (click)="getSecendLevel(item)" [class.activeAddress]="item.code==twoActive.code">
                    <div class="list-text">{{ item.text }}</div>

                    <div class="child-level" [class.active]="item.isShow!='0' && item.isShow">
                        <div class="child-list" *ngFor="let child of childLevel" [class.activeChild]="child.code==threeActive.code" (click)="getChildLevel($event,child)">{{child.text}}</div>
                    </div>

                </div>
            </div>

        </div>
        <div class="select-submit"><button class="purple" (click)="dismiss(threeActive)">保存</button></div>
    </div>
</div> -->