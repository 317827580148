import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { DataApi,Config,Local,Constants,Opera} from '../../provider/index';
declare let Swiper:any;

@Component({
  selector: 'app-company-status-two',
  templateUrl: './company-status-two.component.html',
  styleUrls: ['./company-status-two.component.css']
})
export class CompanyStatusTwoComponent implements OnInit {
  public config: Config;
  public power:boolean=true;
  public isOpenWeb=false;
  public member_info = {
    product_code:'',
    endtime:'',
    first_anniversary:Boolean
  };
  public product_infos=[];
  myUser = {
    name:'',
    avatar:''
  }
  myDepartment = {
    common_name:''
  }
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.opera.initConfig('member','member-center');
    this.getDepartment();//获取企业信息
    this.getUser();//获取hr信息
    new Swiper('.swiper-container.singleBox', {
      slidesPerView: 'auto',
      freeModeMomentumVelocityRatio : .3,
      freeModeSticky : true,
      // spaceBetween: 125,
      freeMode: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
    new Swiper('.swiper-container.memberBox', {
      slidesPerView: 3,
      freeModeMomentumVelocityRatio : .3,
      freeModeSticky : true,
      // spaceBetween: 125,
      freeMode: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  //获取账户信息
  getUser(){
    if(this.dataApi.getLocalLoginUser()){
      // console.log("企业hr信息本地存储");
      // console.log(this.dataApi.getLocalLoginUser());
      this.config.loginUser=this.dataApi.getLocalLoginUser();
      this.myUser = this.dataApi.getLocalLoginUser();
    }else{
      this.dataApi.getUser().then((res:any)=>{
        // console.log("线上数据hr信息");
        // console.log(res);
        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
        this.myUser = res;
      }).catch((error)=>{
        this.toastr.error(error);
      });
    }
  }

  //获取企业信息
  getDepartment(){
    this.opera.loading();
    this.dataApi.getDepartment().then((res:any)=> {
      this.opera.loadiss();
      console.log("企业信息获取线上数据",res);
      this.dataApi.setLocalDepartment(res);
      this.myDepartment = res;
      // console.log(this.myDepartment);

      //计算短信包的总余额
      if(this.config.department.quota.product_component_info&&this.config.department.quota.product_component_info !=null)
      {
        let arr  = this.config.department.quota.product_component_info;
        let newArr = [];
        let smsCount = 0;
        let smsData =null;

        let huodongsms = 0;
        let huodongsmsData =null;

        let smsCount1 = 0;
        let smsOld =null;

        //极速电话联系
        let speedtelephoneNum = 0;
        let speedtelephoneData =null;

        let bulkinvitationNum = 0;
        let bulkinvitationData = null;


        for (let a = 0;a<arr.length;a++)
        {
          let item = arr[a];
          if(item.alias=="sms_quick")
          {
            smsCount +=item.left;
            smsData = item;
          }
          else if(item.alias == this.config.sms_activity)
          {
            huodongsms +=item.left;
            huodongsmsData = item;
          }
          else if(item.alias == "sms")
          {
            smsCount1 +=item.left;
            smsOld = item;
          }
          else if(item.alias == this.config.voice_call)
          {
            speedtelephoneNum +=item.left;
            speedtelephoneData = item;
          }
          else if(item.alias == "sms_bulkinvitation")
          {
            bulkinvitationNum +=item.left;
            bulkinvitationData = item;
          }
          else
          {
            newArr.push(item);
          }
        }
        if(huodongsmsData!=null)
        {
          huodongsmsData.left = huodongsms;
          newArr.push(huodongsmsData);
        }

        if(smsData!=null){
          console.log("smsCount",smsCount);
          smsData.left = smsCount;
          newArr.push(smsData);
        }
        if(smsOld!=null){
          smsOld.left = smsCount1;
          console.log("老版sms",smsOld);
          newArr.push(smsOld);
        }
        if(speedtelephoneData!=null){
          speedtelephoneData.left = speedtelephoneNum;
          newArr.push(speedtelephoneData);
        }
        if(bulkinvitationData!=null){
          bulkinvitationData.left = bulkinvitationNum;
          newArr.push(bulkinvitationData);
        }
        this.config.department.quota.product_component_info = newArr;
      }


      this.member_info = this.config.department.member_info;
      // console.log("this.member_info", this.member_info);
      this.getProductInfo();
    }).catch((error) => {
      this.opera.loadiss();
      console.log(error);
      this.toastr.error(error);
    });
  }

  getProductInfo(){
    if(this.member_info==null)
    {
      return;
    }
    // console.log(this.member_info['product_code']);
    this.dataApi.getProductInfo(this.member_info['product_code']).then((res:any)=>{
      // console.log(res);
      this.product_infos=res.product_infos;
      // console.log('this.product_infos',this.product_infos);
    }).catch((err)=>{
      console.log(err);
    })
  }


  // 去详情页
  goDetail(type,ttype){
    // 职位 position
    // 简历 resume
    // 邀请应聘 interview_notice
    // 急聘 urgency_recruitment
    // 短信 sms
    // 邮件 email
    // 集团号 group
    // 多用户 multi_accounts
    // 广告 advert
    // 服务期 service_period
    // 个性化页面 specific_page
    // 沟通数 dialogue
    this.config.recharge_category = type;
    this.config.recharge_type = ttype;
    this.local.set("recharge_category", JSON.stringify(type));
    this.local.set("recharge_type", ttype);
    console.log(this.local.get(Constants.recharge_category));
    console.log(this.local.get(Constants.recharge_type));
  }

  // 去会员列表
  goMemberList(Type){
    this.config.gotoVipListType = Type
  }

}
