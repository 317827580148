<!-- 页头部分 -->
<!-- <div *ngIf="meeting" class="contentRight-header">高校云 - 宣讲会预约</div>
<div *ngIf="!meeting" class="contentRight-header">高校云</div> -->
<br>
<!--<div class="backTopBox"></div>-->

<!-- 主体内容部分 -->
<div class="homepage-block">
    <img (click)="opera.scrollTop1()" src="../../assets/images/go_top.png" style="width: 60px;height: 60px;position: fixed;bottom: 100px;right: 50px;cursor: pointer" />
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-college-identity [name]="collegeName" [orgList]="orgList" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-identity>
    <div class="con-l">
        <div class="secondNav" *ngIf="meeting">
            <div class="item " (click)="goMilkround()">双选会报名</div>
            <div class="item active">宣讲会报名<span></span></div>
        </div>
        <div class="college-block" style="background-color: transparent">
            <div style="width: 100%;height: 40px;margin-top: 20px;" *ngIf="meeting">
                <span style="height: 40px;line-height: 40px;font-weight: bold;color: #444444;font-size: 14px;float: left">我的申请记录</span>
                <span (click)="goMeetList()" style="height: 40px;line-height: 40px;color: #5E4FFF;font-size: 14px;float: right;cursor: pointer">查看全部申请记录
                    >></span>
            </div>

            <div *ngIf="campusListHistory.length==0&&meeting" class="emptyImg">
                <img src="/assets/images/rights/empty.png">
                <p>您的企业暂未报名就业活动</p>
            </div>

            <!--我的申请记录-->
            <div class="college-list-cont" *ngIf="campusListHistory.length>0 && meeting">
                <div class="listCell_history" *ngFor="let n of campusListHistory;let i = index;">
                    <div class="listCellContent" (click)="goPreach(n.ctid)">

                        <div style="width: 90px;height: 100%;float: left">
                            <img src="{{n.logo}}" style="width:60px;height: 60px;margin-left: 15px;margin-top: 15px;border-radius: 30px">
                        </div>

                        <div style="height:60px;width: 25%;margin-top: 20px;float: left;overflow:hidden">
                            <div style="height: 25px;line-height: 25px;font-size: 18px;color: black;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width: 360px;">
                                {{n.name}}</div>
                            <!--<span *ngIf='n.status==3' style="height: 25px;line-height: 25px;margin-top: 15px;margin-left: 10px;font-size: 17px;color: white;background-color: #6977eb;width: 70px;padding-left: 15px;padding-right: 15px">已合作</span>-->
                            <!--<span *ngIf='n.status!=3' style="height: 25px;line-height: 25px;margin-top: 15px;margin-left: 10px;font-size: 17px;color: white;background-color: #fa5d5e;width: 70px;padding-left: 15px;padding-right: 15px">未合作</span>-->
                            <p class="milkRoundAddressAndTime" style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis">
                                <img src="../../assets/images/addressIcon.png" style="width: 12px;height: 16px;margin-top: -3px"> {{(!n.place_name?"地点暂无":n.place_name)}}
                            </p>
                        </div>

                        <div style="width: 20%;height: 100%;float: left; display: flex; flex-direction: row;align-items: center;justify-content:center">
                            <span>{{'时间:'+(n.start_time | date:"yyyy-MM-dd HH:mm"+'&nbsp;-&nbsp;'+(n.end_time |
                                date:"yyyy-MM-dd HH:mm"))}}</span>
                        </div>

                        <div style="width: 15%;height: 100%;float: left; display: flex; flex-direction: row;align-items: center;justify-content:center">
                            <span *ngIf='n.college_name'>{{n.college_name==null?"":n.college_name}}</span>
                        </div>

                        <div style="width: 25%;height: 100%;float: left; display: flex; flex-direction: row;align-items: center;justify-content:center">
                            <button class="cellButton bordeRadius" (click)="goUpdata(n.collegeid,n,$event)" *ngIf="(n.audit_status=='to_audit'|| n.cooperation_status=='1')&& !n.fail">审核中</button>
                            <button class="cellButton shibai" (click)="openRevoke($event,n.ctid)" *ngIf="(n.audit_status=='to_audit'|| n.cooperation_status=='1')&& !n.fail">撤回</button>
                            <button class="cellButton" (click)="goUpdata(n.collegeid,n,$event)" *ngIf="n.audit_status == 'revoke'">重新预约</button>

                            <button class="cellButton bordeRadius" (click)="goUpdata(n.collegeid,n,$event)" *ngIf="n.fail">预约失败</button>
                            <button class="cellButton bordeRadius" *ngIf="n.audit_status=='passed'&& n.cooperation_status=='2'">预约成功</button>
                            <button class="cellButton" *ngIf="n.audit_status=='passed'&& n.cooperation_status=='2'" (click)="showJoinCerModel($event,n)">获取参会凭证</button>
                            <button class="cellButton tongzhi bordeRadius" (click)="singleTips(n)" *ngIf="n.audit_status=='passed'&& n.cooperation_status=='2' &&n.curr_total_student>=1000&&opera.isShowPaySuccessModule1()&&!n.isEnd">通知学生到场</button>
                        </div>

                    </div>
                </div>
            </div>


            <div style="width: 100%;height: 40px;margin-top: 20px;" *ngIf="meeting">
                <span style="height: 40px;line-height: 40px;font-weight: bold;color: #444444;font-size: 14px;float: left">预约宣讲会</span>
            </div>
            <!-- 新版 -->
            <div>
                <!-- 操作部分 -->
                <div style="background-color: #ffffff" class="college-block-opera-new">
                    <div style="width: 95%; margin: 0 auto;">
                        <p class="college-title-new">高校云</p>
                        <!--<button *ngIf="meeting" class="purple" (click)="goMeetList()">宣讲会预约记录</button>-->
                        <!-- <button *ngIf="!meeting" class="purple" (click)="goSignList()">高校合作记录</button> -->
                        <div>
                            <select name="city" class="downLoad_right" id="city" [(ngModel)]="model.city" (ngModelChange)="getCollegeList()" autocomplete="off">
                                <option class="place-text" value='' disabled hidden selected="selected">选择所在城市</option>
                                <option class="place-text" value=''>不限城市</option>
                                <option *ngFor="let city of hotCity" [value]="city.code">{{city.text}}</option>
                            </select>

                            <select name="city" class="downLoad_right" id="collegeType" [(ngModel)]="model.school_type" (ngModelChange)="getCollegeList()" autocomplete="off">
                                <option class="place-text" value='' disabled hidden selected="selected">学校类型</option>
                                <option class="place-text" value=''>不限学校类型</option>
                                <option *ngFor="let city of config.school_type" [value]="city.code">{{city.text}}
                                </option>
                            </select>
                            <div class="downLoad_left" *ngIf="!meeting" (click)="goSignList()">
                                <p style="padding: 0px 5px;">查看高校合作记录</p>
                                <img src="../../assets/images/rights/more.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 空状态 -->
                <div class="none-block-empty" *ngIf="countList == 0">
                    <img src="assets/images/rights/empty.png">
                    <p>暂未找到相关学校，换个关键词试试吧</p>
                </div>
                <!-- 循环列表 -->
                <div class="college-list-cont" *ngIf="countList>0">
                    <div *ngFor="let n of collegeList;let i = index;" class="listCell listCell-new">
                        <div style="width: 10%;">
                            <img src="{{n.logo}}" style="width: 50px;height: 50px;margin-left: 30px;border-radius: 50%">
                        </div>

                        <div style="width: 70%;overflow:hidden;cursor: pointer">
                            <span class="list-names" (click)="gotoPage(3,n)">{{n.name}}</span>
                            <p class="milkRoundAddressAndTime">
                                <img *ngIf="n.region_text" src="../../assets/images/categoryIcon.png" style="width: 20px;height: 20px;margin-top: -3px">
                                <span>{{(!n.school_type_text?"":n.school_type_text)}}</span>
                                <img *ngIf="n.region_text" src="../../assets/images/addressIcon_new.png" style="width:20px;height:20px;margin-top: -3px">
                                <span>{{(!n.region_text?"":n.region_text)}}</span>
                                <img *ngIf="n.work_info" src="../../assets/images/phoneIcon.png" style="width: 20px;height: 20px;margin-top: -3px"> {{(!n.work_info?"":n.work_info.office_tel)}}
                            </p>
                        </div>
                        <div *ngIf="!meeting" class="meeting-format">
                            <p>
                                <img (click)="gotoPage(1,n)" src="../../assets/images/have_shuangxuan.png" style="height:28px;cursor: pointer">
                                <span>双选会</span>
                            </p>
                            <!-- <p>
                                <img (click)="gotoPage(1,n)" src="../../assets/images/not_shuangxuan.png"
                                    style="height: 31px;cursor: pointer">
                                <span>双选会</span>
                            </p> -->
                            <p *ngIf='n.campustalk_switch'>
                                <img (click)="gotoPage(2,n)" src="../../assets/images/have_xuanjiang.png" style="height:28px;cursor: pointer">
                                <span>宣讲会</span>
                            </p>
                            <p *ngIf='!n.campustalk_switch'>
                                <img src="../../assets/images/not_xuanjiang.png" style="height:28px;cursor: pointer;">
                                <span style="color: #C4C4C4;">宣讲会</span>
                            </p>
                        </div>
                        <div style="width: 30%; display: flex; flex-direction: row;align-items: center;justify-content:center" *ngIf="meeting">
                            <span *ngIf="n.campustalk_switch" class="bordeRadius yuyue" (click)="goSigin(n)">预约宣讲会</span>
                        </div>
                    </div>
                </div>

                <!-- 分页部分 -->
                <div class='paging-block flex_r' style="width: 1100px;margin: 20px 0px;" [class.isPageHeight]="countList>0">
                    <router-outlet></router-outlet>
                    <!--<app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>-->
                    <div id="test1"></div>
                </div>
            </div>

            <!-- 旧版 -->
            <div style="display: none;">
                <!-- 操作部分 -->
                <div style="background-color: #ffffff" class="college-block-opera clear">
                    <div class="left">
                        <!--<button *ngIf="meeting" class="purple" (click)="goMeetList()">宣讲会预约记录</button>-->
                        <button *ngIf="!meeting" class="purple" (click)="goSignList()">高校合作记录</button>
                        <div style="display: inline-block;margin-left: 20px">
                            <span>筛选</span>
                            <select name="city" class="downLoad_right" id="city" [(ngModel)]="model.city" (ngModelChange)="getCollegeList()" autocomplete="off">
                                <option class="place-text" value='' disabled hidden selected="selected">选择所在城市</option>
                                <option class="place-text" value=''>不限</option>
                                <option *ngFor="let city of hotCity" [value]="city.code">{{city.text}}</option>
                            </select>

                            <select name="city" class="downLoad_right" id="collegeType" [(ngModel)]="model.school_type" (ngModelChange)="getCollegeList()" autocomplete="off">
                                <option class="place-text" value='' disabled hidden selected="selected">学校类型</option>
                                <option class="place-text" value=''>不限</option>
                                <option *ngFor="let city of config.school_type" [value]="city.code">{{city.text}}
                                </option>
                            </select>

                        </div>
                    </div>
                </div>
                <!-- 没有内容存在时 -->
                <div *ngIf="countList==0" class="none-block">
                    <img src="assets/images/resume-search-fail.png"><br /> 暂未找到相关学校，换个关键词试试吧
                </div>
                <!-- 循环列表 -->
                <div class="college-list-cont" *ngIf="countList>0">
                    <div *ngFor="let n of collegeList;let i = index;" class="listCell">
                        <div style="width: 10%;">
                            <img src="{{n.logo}}" style="width:50px;height: 50px;margin-left: 30px;border-radius: 50%">
                        </div>

                        <div style="width: 30%;overflow:hidden;cursor: pointer" (click)="goDetails(n)">
                            <span style="height: 25px;line-height: 25px;margin-top: 15px;font-size: 16px;color: black">{{n.name}}</span>
                            <p class="milkRoundAddressAndTime">
                                <img src="../../assets/images/addressIcon.png" style="width: 12px;height: 16px;margin-top: -3px"> {{(!n.region_text?"地点暂无":n.region_text)}}

                            </p>
                        </div>
                        <div style="width: 30%;display: flex; flex-direction: row;align-items: center;justify-content:center" *ngIf="!meeting">
                            <img (click)="gotoPage(1,n)" src="../../assets/images/shuangxuan.png" style="height: 31px;padding-left: 15px;padding-right: 5px;cursor: pointer">
                            <img (click)="gotoPage(2,n)" *ngIf='n.campustalk_switch' src="../../assets/images/xuanjiang.png" style="height: 31px;padding-left: 15px;padding-right: 15px;cursor: pointer">
                            <img *ngIf='!n.campustalk_switch' src="../../assets/images/no_xuanjiang.png" style="height: 31px;padding-left: 15px;padding-right: 15px;cursor: pointer">
                            <img (click)="gotoPage(3,n)" src="../../assets/images/collegeIcon.png" style="height: 31px;padding-left: 15px;padding-right: 15px;cursor: pointer;">
                        </div>

                        <div style="width: 10%; display: flex; flex-direction: row;align-items: center;justify-content:center;min-width: 100px">
                            <span>{{n.school_type_text==null?"":n.school_type_text+""}}</span>
                        </div>
                        <div style="width: 10%; display: flex; flex-direction: row;align-items: center;justify-content:center;min-width: 100px">
                            <span *ngIf='n.work_info&&n.work_info.office_tel'>{{n.work_info.office_tel==null?"":n.work_info.office_tel}}</span>
                        </div>

                        <div style="width: 30%; display: flex; flex-direction: row;align-items: center;justify-content:center" *ngIf="meeting">
                            <span *ngIf="n.campustalk_switch" class="bordeRadius yuyue" (click)="goSigin(n)">预约宣讲会</span>
                        </div>
                    </div>
                </div>

                <!-- 分页部分 -->
                <!-- <div class='paging-block flex_r' [class.isPageHeight]="countList>0">
                    <router-outlet></router-outlet> -->
                <!--<app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>-->
                <!-- <div id="test1"></div>
                </div> -->
            </div>

        </div>
    </div>
    <!-- <div class="con-r" *ngIf="opera.isShowPaySuccessModule()">
        <div class="con-r-l">
            <img src="../../assets/images/talent/my_phone.png">
            <div class="info">
                <div class="top">校招活动邀请</div>
                <div class="bot">余量（次）：{{activity_invitation ? activity_invitation.left : '0'}}</div>
            </div>
        </div>
        <div (click)="product('','contactMe')" class="con-r-r bordeRadius">立即购买</div>
    </div> -->

</div>


<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isShowJoinCerModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':300,'height':532,'minHeight':300}"
    [modal]=true>
    <div class="JoinCerModel">
        <img class="title" src="../../assets/images/campjointitle.png">
        <div class="line"></div>
        <div class="subtitle">宣·讲·会·参·会·企·业·凭·证</div>
        <div class="info" *ngIf="JoinCerModel">
            <div class="camptitle">{{JoinCerModel.name}}</div>
            <div class="campCell">
                <img class="leftIcon" src="../../assets/images/camp_comname.png" />
                <div class="rightText">
                    企业名称: {{JoinCerModel.department_name}}
                </div>
            </div>
            <div class="campCell">
                <img class="leftIcon" src="../../assets/images/camp_time.png" />
                <div class="rightText">
                    宣讲时间: {{JoinCerModel.start_time | date:"yyyy-MM-dd HH:mm"}}
                </div>
            </div>
            <div class="campCell">
                <img class="leftIcon" src="../../assets/images/camp_address.png" />
                <div class="rightText">
                    宣讲地点: {{JoinCerModel.place_name}}
                </div>
            </div>
        </div>
    </div>
</p-dialog>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>

<!-- 新增高校 -->
<app-push-college-model #pushCollegeModel [isShow]="isShowPushCollegeModel" (modelHidden)="pushCollegeModelHidden($event)"></app-push-college-model>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="revokeModule" [modal]=true>
    <div class="showTopingBox active">
        <div class="closIcon" (click)="revokeModule = false"><img src="/assets/images/v3/alertClos.png"></div>
        <div class="title">
            <div class="tipText">
                <div class="name">撤回报名</div>
            </div>
        </div>
        <div class="p2">确定撤回该宣讲会报名吗？</div>
        <div class="btns">
            <div class="btn active" (click)="revokeModule = false">取消</div>
            <div class="btn blueStreamer" (click)="downMilk()">确定</div>
        </div>
    </div>
</p-dialog>