import {Component, OnInit, Input, Output, ViewChild} from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import {ModelSendMsgComponent} from "../model-send-msg/model-send-msg.component";
import {NoneVipAlertComponent} from "../none-vip-alert/none-vip-alert.component";
import { MatDialog } from "@angular/material/dialog";
import {productManager} from "../../provider/productManager";
//新增高校
import { PushCollegeModelComponent } from "../push-college-model/push-college-model.component";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
import {VoiceCallModelComponent} from "../voice-call-model/voice-call-model.component";
@Component({
  selector: 'app-college-details-student',
  templateUrl: './college-details-student.component.html',
  styleUrls: ['./college-details-student.component.css']
})
export class CollegeDetailsStudentComponent implements OnInit {
  public config: Config;
  // 筛选条件
  public model={
      collegeid:'',
      education:'',
      major:'',
      offset:0,
      limit:9,
      majorOne:''
  }
  @ViewChild('sengMsgModel', { static: true }) sengMsgModel: ModelSendMsgComponent;


  public resumeList=[];//文章列表
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  @Output() modelShow = false;//是否打开弹窗
  @Output() collegeName = '';//高校名称
  @Output() collegeIdentity:any;//高校认证信息
  @Output() orgList=[];//院系列表
  @Output() modelShowSengMsg = false;//是否打开单品发送极速短信
  @Output() SengMsgModel ={
    userName:'',
    userPic:'',
    talentid:'',
    graduation_school:'',//学校
    major_text:'',//专业
    gender:'',
  };//打开单品发送极速短信用户对象

  public college:any;//高校详情
  public isInit=false;
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  // public majorOne='';//专业类别一级
  public educationList=[];//学历列表

  public sendMsgArr = [];//批量摇头的用户列表
  public useBatch: boolean = false;  // 显隐选择按钮
  public selectAll: boolean = false; // 全选是否
  //高校信息
  public addCollegeList = [];
  //是否打开弹窗(非会员新增高校)
  @Output() isShowPushCollegeModel = false;
  @ViewChild('pushCollegeModel', { static: true })
  pushCollegeModel: PushCollegeModelComponent;

   //是否打开产品介绍/请校招顾问联系我 弹窗
   @Output() isproductShowModel = false;
   @ViewChild('productModel', { static: true }) 
   productModel: ProductModelComponent;


   @Output() isShowVoiceCallModel = false;//是否打开弹窗(简历收件箱)
   @ViewChild('voiceCallModel', { static: true }) voiceCallModel: VoiceCallModelComponent;
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
              public dialog: MatDialog,
              public productManager:productManager,

              public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {
    this.opera.initConfig('college','college-campustalk');
    this.model.collegeid=this.local.get(Constants.collegeid);//赋值高校id

    this.getCollegeById();//获取高校详情
    this.getResumeList();//获取生源速览
    this.getMajorList();//获取专业列表信息
    this.getEducationList();//获取学历列表信息

    let model =  this.local.get('college-details-student-model');

    if(model)
    {
      let modelp=  this.local.get('college-details-student-modelp');
      setTimeout(()=>{
        this.model = JSON.parse(model);
        this.getResumeList();//获取生源速览
        this.pageNumber = parseInt(modelp) ;
      },300)

    }
  }
  //-----跳转简历详情
  goDetails(n){
    console.log('跳转简历详情',n);
    // if(!this.opera.isShowPaySuccessModule()){
    //   this.opera.checkUserPayStateAndauthenticationStateAlert()
    //   return;
    // }
    //
    // this.router.navigate(['home/college-resumedetails'], {
    //   queryParams: {
    //     resumeid:n,
    //     positionid:''
    //   }
    // });
    this.local.set('college-details-student-model',JSON.stringify(this.model));
    this.local.set('college-details-student-modelp',JSON.stringify(this.pageNumber));
    this.dataApi.getDepartment().then((res:any)=>{
      if(res.member_info)
      {
        this.dataApi.getPlatformApply().then((res:any)=>{
          if(res)
          {
            if(res.status == 0){
              this.dialog.open(NoneVipAlertComponent, {
                data: {
                  type:'no_audit',
                  message:this.config
                }
              }).afterClosed().subscribe((isConfirmed) => {
                this.opera.scrollTrue();
                console.log('isConfirmed',isConfirmed)
                if(isConfirmed){
                }
              });
            }else if(res.status == 1){
              this.dialog.open(NoneVipAlertComponent, {
                data: {
                  type:'audit',
                  message:this.config
                }
              }).afterClosed().subscribe((isConfirmed) => {
                this.opera.scrollTrue();
                console.log('isConfirmed',isConfirmed)
                if(isConfirmed){
                }
              });
            }else if(res.status == 2){
              if(n.ignore == false){
                n.isview_resume = true;

                this.router.navigate(['home/college-resumedetails'], {
                  queryParams: {
                    resumeid:n.resume_number,
                    positionid:'',
                    match:n.matching
                  }
                });
              }
            }else if(res.status == 3){
              this.dialog.open(NoneVipAlertComponent, {
                data: {
                  type:'fail',
                  message:this.config
                }
              }).afterClosed().subscribe((isConfirmed) => {
                this.opera.scrollTrue();
                console.log('isConfirmed',isConfirmed)
                if(isConfirmed){
                }
              });
            }
            return;
          }
        }).catch((error) => {
          console.log(error);
          //this.toastr.error(error);
          return;
        });
      }else{
        this.dialog.open(NoneVipAlertComponent, {
          data: {
            type:'goBuy',
          }
        }).afterClosed().subscribe((isConfirmed) => {
          this.opera.scrollTrue();
          console.log('isConfirmed',isConfirmed)
          if(isConfirmed){
          }
        });
        return
      }
    }).catch((error) => {
      console.log(error);
      return;
    });

  }

 //立即沟通
 goChat(item,id){
  if(this.config.lastIdentity.status == 2){
    if(!item.apply_college_auth && !item.jm_username && !this.config.department.member_info){
      this.toastr.warning('该学生暂不支持沟通，立即开通会员')
      setTimeout(() => {
        this.router.navigateByUrl("home/tool-campus"); // 会员页面
      }, 1500);
    }else{
      this.opera.goChat(item,id);
    }
  }else{
    let collegeList = []
    for(let i = 0;i < this.config.department.auth.length; i ++){
      if(this.config.department.auth[i].status == 2){
        collegeList.push(this.config.department.auth[i].collegeid)
      }
    }
    if(collegeList.indexOf(item.collegeid) != -1){
      this.opera.goChat(item,id);
    }else{
      if(this.config.lastIdentity.status == 1){
        this.toastr.warning('认证审核中')
      }else if(this.config.lastIdentity.status == 3){
        this.toastr.warning('认证失败，请查看原因并重新提交')
        setTimeout(()=>{
          this.router.navigateByUrl("home/tool-campus"); // 会员页面
        },1500)
      }else{
        this.opera.remindCould()
      }
    }
  }
 }
  //显示手机号弹窗
  ShowPhoneModel(n,event){
    event.stopPropagation();
    this.opera.checkMember((isPay)=>{
      if(isPay) {
        this.productManager.getProductUsableCount(this.config.voice_call,(count,isVip)=>{
          if(count==0||!isVip) {
            this.product('voice_call','recharge');
          } else {
            this.voiceCallModel.initData(n);
            this.isShowVoiceCallModel = true;
          }
        })
      } else {
        this.product('voice_call','recharge');
      }
    })
  }
  //极速电话联系弹窗消失
  voiceCallModelHidden(e){
    this.isShowVoiceCallModel = false;
  }
 //收藏
 goCollect(val,event){
  event.stopPropagation();
  val = this.opera.manageResumeArchived(val);
 }

  //生源速览
  getResumeList(){
    this.opera.loading();

    let model={
      collegeid:this.model.collegeid,
      education:this.model.education,
      major:this.model.major,
      offset:this.model.offset,
      limit:this.model.limit
    }

    if(this.model.major=="9999999")
    {
      model.major = this.model.majorOne;
    }
    this.dataApi.getSearchResumeList(model).then((res:any)=>{
      console.log("生源速览",this.model,model);
     //console.log(res);
     this.resumeList = res.list;
     this.countList = res.count;
     this.pageCount = Math.ceil(res.count/9);
     this.opera.loadiss();
    }).catch((err)=>{
      //console.log(err);
      this.opera.loadiss();
    })
  }

  //获取分页子组件返回数据
  getChildEvent(index){
    console.log('子组件返回分页数-----',index,this.model);
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getResumeList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }
  }

  //监听majorOne
  majorOneChange(){
    // //console.log("第一级专业值");
    // //console.log(this.majorOne);
    // //console.log("专业列表");
    // //console.log(this.config.major);
    this.majorListTwo=[];
    this.model.major='';
    for(let n of this.config.major){
      if(n.parentcode==this.model.majorOne){
        this.majorListTwo.push(n);
      }
    }
    // //console.log("第二级专业列表");
    // //console.log(this.majorListTwo);
  }


  //提示先选一级专业
isShowTwo(){
  if(!this.model.majorOne){
    this.toastr.warning("请先选择一级专业");
    return;
  }
 }

 //获取学历列表信息
 getEducationList(){
  if(this.dataApi.getEducationCode()){
    //console.log("获取学历列表本地存储-----college-resume");
    //console.log(this.dataApi.getEducationCode());
    this.educationList = this.dataApi.getEducationCode();
  }else{
    this.dataApi.getDictDate('education').then((res:any)=>{
      //console.log("获取学历列表-----college-resume");
      //console.log(res);
      this.educationList = res;
      this.dataApi.setEducationCode(res);
    }).catch((error)=>{
      //console.log(error);
    });
  }
}

//获取一级专业列表
getMajorListOne(){
  for(let n of this.config.major){
    if(n.level==1){
      this.majorListOne.push(n);
    }
  }
  let model =  this.local.get('college-details-student-model');

  if(model)
  {
   let JSONmodel = JSON.parse(model);
    this.majorListTwo=[];
    for(let n of this.config.major){
      if(n.parentcode==JSONmodel.majorOne){
        this.majorListTwo.push(n);
      }
    }
  }


}

//获取专业列表信息
getMajorList(){
  if(this.dataApi.getMajorCode()){
    this.config.major = this.dataApi.getMajorCode();
    this.getMajorListOne();//获取一级专业列表
  }else{
    this.dataApi.getDictDate('major').then((res:any)=>{
      //console.log("获取专业列表-----college-resume");
      //console.log(res);
      this.config.major = res;
      this.getMajorListOne();//获取一级专业列表
      this.dataApi.setMajorCode(res);
    }).catch((error)=>{
      //console.log(error);
    });
  }
}

  //通过高校id获取高校详情
  getCollegeById(){
    this.dataApi.getCollageDetails(this.model.collegeid).then((res:any)=>{
      this.isInit = true;
      this.college = res;
      this.collegeName = this.college.name;
      if(res['audit_switch']['faculty_authcation']){
        this.getOrgList(res.collegeid);
      }
    }).catch((err)=>{
      this.toastr.error(err);
      this.isInit = true;
    });
  }

  //获取列表
  getOrgList(id){
    this.opera.loading();
    this.dataApi.getCollegePart(id).then((res:any)=>{
      this.orgList = res;
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      this.toastr.error("获取院系列表失败，请稍后重试");
    })
  }

  //立即申请合作
  goCollegeIdentity(){
    if(this.college.audit_switch.faculty_authcation){//是否打开院系认证审核开关
      this.collegeName = '';
    }
    this.modelShow = true;//打开校招申请确认弹窗
    this.opera.scrollTop();//页面滚动函数
  }

    //获取申请校招返回值
    Modeldismiss(event){
      ////console.log('子组件返回结果-----college-details');
      ////console.log(event);
      this.opera.scrollTrue();
      if(event){
        if(event === true){
          this.local.remove(Constants.facultyid);
        }else{
          this.local.set(Constants.facultyid,event);
        }
        //跳转认证页面
        this.local.set(Constants.collegeid,this.college.collegeid);
        this.router.navigateByUrl("/home/company-identity-details");
        this.modelShow = false;
      }else{
        this.modelShow = false;
      }

    }

    //点击产品
  product(alias,type){
    this.productModel.initData(alias,type);
    this.isproductShowModel = true;
  }
  /**
   * 生源速览极速短信弹窗
   */
  alertSendMsg(n,event){
    event.stopPropagation();
    let that = this;
    this.productManager.getProductUsableCount(this.config.sms_bulkinvitation,(count,isVip)=>{

      if(count==0||!isVip){
        // this.config.payVipHistoryPage = {
        //   page:'生源速览',
        //   isVip:isVip,
        //   pro:'职位推送'
        // };
        // that.local.set("single_category_code", this.config.ProductListCode.sms_bulkinvitation_code);
        // that.router.navigate(['home/tool-campus']);

        this.product('sms_bulkinvitation','recharge');
      }else{
        this.sengMsgModel.initData(1,[]);
        this.SengMsgModel.userName = n.talent_name;
        this.SengMsgModel.userPic = n.logo==null?'': n.logo;
        this.SengMsgModel.graduation_school = n.graduation_school;
        this.SengMsgModel.major_text = n.major_text;
        this.SengMsgModel.talentid = n.talentid;
        this.SengMsgModel.gender = n.gender;
        this.modelShowSengMsg =true;
      }
    })




  }
  SendMsgdismiss(event){
    this.modelShowSengMsg =false;
    this.sendMsgArr=[];
    this.useBatch=false;
    console.log("Modeldismiss",)
  }

  // 切换批量邀投开关
  useBatchFn() {
    if (this.sendMsgArr.length == 0) {
      this.useBatch = !this.useBatch;
    } else {
      this.singleTips();
    }
  }

  // 活动通知包触发
  singleTips() {
    this.modelShowSengMsg = true;
    this.sengMsgModel.initData(2, this.sendMsgArr);
  }

  // 单个学生选择
  selectStudentFn(n) {
    if (this.sendMsgArr.indexOf(n.talentid) == -1) {
      this.sendMsgArr.push(n.talentid);
    } else {
      this.sendMsgArr.splice(this.sendMsgArr.indexOf(n.talentid), 1);
    }
    console.log(this.sendMsgArr.indexOf(n.talentid));
    this.checkAll();
  }

  // 检测是否全选
  checkAll() {
    for (let i of this.resumeList) {
      if (this.sendMsgArr.indexOf(i.talentid) == -1) {
        this.selectAll = false;
        break;
      } else {
        this.selectAll = true;
      }
    }
    console.log(this.selectAll);
  }
  // 本页全选
  selectAllFn(){
    console.log(this.resumeList);
    if(!this.selectAll){
      for(let i of this.resumeList){
        if(this.sendMsgArr.indexOf(i.talentid) == -1){
          this.sendMsgArr.push(i.talentid);
        }
      }
      this.selectAll = true;
    }else{
      for(let i of this.resumeList){
        this.sendMsgArr.splice(this.sendMsgArr.indexOf(i.talentid),1);
      }
      this.selectAll = false;
    }
    console.log(this.sendMsgArr);
  }
  // 本页反选
  selectRevFn(){
    console.log(this.sendMsgArr);
    for(let i of this.resumeList){
      if(this.sendMsgArr.indexOf(i.talentid) == -1){
        this.sendMsgArr.push(i.talentid);
      }else{
        this.sendMsgArr.splice(this.sendMsgArr.indexOf(i.talentid),1);
      }
    }
    this.checkAll();
  }
  //获取合作高校
  mycollegeList() {
    this.dataApi.mycollegeList().then((res: any) => {
      if (res) {
        this.addCollegeList = res;
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  //去教学机构
  goOrg() {
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.local.set(Constants.collegeid, that.college.collegeid);
        that.router.navigateByUrl("home/college-details-org");
      }else {
        //that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.local.set(Constants.collegeid, that.college.collegeid);
          that.router.navigateByUrl("home/college-details-org");
        } else {
          this.pushCollege();
        }
      }
    })

  }
  //去双选会
  goMilk() {

    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        this.router.navigateByUrl("home/college-details-milk");
      } else {
        // that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          this.router.navigateByUrl("home/college-details-milk");
        } else {
          this.pushCollege();
        }
      }
    })

  }
  //去宣讲会
  goCampus() {
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.router.navigateByUrl("home/college-details-campus");
      } else {
        //that.opera.checkMemberAlertWithType("goCampus")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.router.navigateByUrl("home/college-details-campus");
        } else {
          this.pushCollege();
        }
      }
    })
  }
  //去新闻公告
  goNews() {

    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.router.navigateByUrl("home/college-details-news");
      }else {
        //that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.router.navigateByUrl("home/college-details-news");
        } else {
          this.pushCollege();
        }
      }
    })
  }

  //去生源速览
  goStudent() {
    this.local.remove('college-details-student-model');
    this.local.remove('college-details-student-modelp');
    let that = this;
    this.opera.checkMember((isPay) => {
      if (isPay) {
        that.router.navigateByUrl("home/college-details-student");
      } else {
        //that.opera.checkMemberAlertWithType("goBuy")
        //非会员验证是否是合作3所高校
        let finItem = this.addCollegeList.find(item => item.collegeid == that.college.collegeid);
        if (finItem) {
          that.router.navigateByUrl("home/college-details-student");
        } else {
          this.pushCollege();
        }
      }
    })
  }

   //新增高校
  pushCollege() {
    this.isShowPushCollegeModel = true;
    this.pushCollegeModel.initData();
  }

  pushCollegeModelHidden(event) {
    this.isShowPushCollegeModel = false;
    this.mycollegeList();
  }
  productModelHidden(event){    
    this.isproductShowModel = false;
  }
}
