import { Component, OnInit } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {  ActivatedRoute,Router } from  '@angular/router';
import {regexManager} from "../../provider/regexManager";
declare var window;
@Component({
  selector: 'app-company-identity-details',
  templateUrl: './company-identity-details.component.html',
  styleUrls: ['./company-identity-details.component.css']
})
export class CompanyIdentityDetailsComponent implements OnInit {
  public model={
    collegeid:'',
    facultyid:'',//院系id
    proxy_document:'',//招聘委托书
    business_licence:'',//企业营业执照
    id_card_front:'',//身份证正面
    register_number:'',//营业执照注册号
    brief:'',//申请简述
    office_phone:'',//固定电话
    school_permit:""

  }
  public config: Config;
  public collegeName="";
  public orgList=[];//高校院系列表
  public secureMaterail:any;//招聘委托书下载链接
  public authcation_office_phone=false;
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    private activatedRoute: ActivatedRoute,
    public regexManager:regexManager,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {
    this.opera.initConfig('college','college-campustalk');

    this.model.collegeid=this.local.get(Constants.collegeid);
    this.getCollegeById(this.model.collegeid);//获取高校信息
    this.lastAuthenticationIdentity();//获取上次认证信息
    this.getIdentityMaterial();
  }

  //获取认证上传的材料标准
  getIdentityMaterial(){
    let model={collegeid:this.model.collegeid};
    this.dataApi.getMaterial('authentication',model).then((res:any)=>{
      console.log("获取认证材料成功");
      console.log(res);
      if(res[0]){
        for(let list of res[0].material_infos){
          if(list.material_field=='proxy_document'){
            this.secureMaterail=list.material_example;
          }
        }
      }
    }).catch((err)=>{
      //console.log("获取认证材料失败");
      //console.log(err);
    })
  }

  //通过高校id获取院系列表
  getOrgList(){
    this.opera.loading();
    this.dataApi.getCollegePart(this.model.collegeid).then((res:any)=>{
      // console.log("获取院系列表---");
      // console.log(res);
      this.orgList = res;
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
      this.toastr.error("获取院系列表失败，请稍后重试");
    })
  }
  //通过高校id获取高校详情
  getCollegeById(id){
    this.opera.loading();
    this.dataApi.getCollageDetails(id).then((res:any)=>{
      //console.log("获取到高校信息----");
      console.log(res);
      if(res.audit_switch.faculty_authcation){
        this.model.facultyid=this.local.get(Constants.facultyid);
        this.getOrgList();
        this.singleIdentity();
      }else{
        this.collegeName = res.name;
        if(res.audit_switch.proxy_document){
          this.singleIdentity();
        }
      }
      if(res.audit_switch.authcation_office_phone){
        this.authcation_office_phone=true;
        console.log('填写固话');
      }else{
        this.authcation_office_phone=false;
      }
      this.opera.loadiss();
    }).catch((err)=>{
      this.toastr.error(err);
      this.opera.loadiss();
    });
  }

  //验证手机号码格式
  checkPhone(n){
    let phoneModel={
      format_type:3,//0:手机号，1：手机号&固话；2.邮箱
      data:n
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(!res.value){
        this.toastr.warning("办公电话格式错误");
        this.model.office_phone='';
        return;
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    })
  }

  //填充高校认证信息
 lastAuthenticationIdentity(){
  this.config.lastCollegeIdentity=this.dataApi.getLastCollegeIdentity();
  if((!this.config.lastCollegeIdentity)||(this.config.lastCollegeIdentity.status!=2)){
     this.opera.loading();
     this.dataApi.lastAuthenticationIdentity().then((res:any)=>{
      ////console.log("获取上次认证信息---------------------------CollegeIdentityPage");
      ////console.log(res);
      if(res){
        this.model.business_licence = res.business_license;
        this.model.id_card_front = res.id_card_front;
        this.model.register_number = res.register_number;
        this.config.lastCollegeIdentity=res;
        this.dataApi.setLastCollegeIdentity(this.config.lastCollegeIdentity);
      }else{
        this.getApplyPlatformDetails();
      }
      this.opera.loadiss();
    }).catch((error)=>{
     ////console.log(error);
     this.opera.loadiss();
    })
  }else{
    this.model.business_licence = this.config.lastCollegeIdentity.business_license;
    this.model.id_card_front = this.config.lastCollegeIdentity.id_card_front;
    this.model.register_number = this.config.lastCollegeIdentity.register_number;
    this.dataApi.setLastIdentity(this.config.lastCollegeIdentity);
  }
}

//单个高校认证信息
 singleIdentity(){
  this.dataApi.lastCollegeAuthenticationIdentity(this.model.collegeid).then((res:any)=>{
    ////console.log("获取上次认证信息---------------------------CollegeIdentityPage");
    ////console.log(res);
    if(res){
      this.model.business_licence = res.business_license;
      this.model.id_card_front = res.id_card_front;
      this.model.register_number = res.register_number;
      if(res.facultyid){
        this.model.facultyid = res.facultyid;
      }
      if(res.proxy_document){
        this.model.proxy_document = res.proxy_document;
      }
    }
    this.opera.loadiss();
  }).catch((error)=>{
   ////console.log(error);
   this.opera.loadiss();
  })
 }


  //同步高校认证信息
 lastIdentity(){
  this.opera.loading();
  this.dataApi.lastAuthenticationIdentity().then((res:any)=>{
    if(res){
      this.config.lastCollegeIdentity=res;
      this.dataApi.setLastCollegeIdentity(this.config.lastCollegeIdentity);
    }
    this.opera.loadiss();
  }).catch((error)=>{
    ////console.log(error);
    this.opera.loadiss();
  })
}

//上传图片
previewPic(event,name){
  console.log(event);
  console.log(name);
  let imgData  = event.target.files[0]; //获取file对象
  if (window.FileReader) {
    var reader = new FileReader();
    reader.readAsDataURL(imgData);
    var that = this;
    reader.onloadend = function (e : any) {
      that.uploadPic(reader.result,name);
    };
  }
}
//上传到服务器
uploadPic(data,name){
  let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
  imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
  this.opera.loading();//加载动画
  this.dataApi.uploadAuthImg({
    value: imageData
  }).then((res:any)=>{
    ////console.log(res);
    if(res){
      if(res.value){//------------上传之后赋值
        this.model[name] = res.value;
      }
    }
    this.opera.loadiss();//停止加载动画
  }).catch((error) => {
    ////console.log(error);
    this.opera.loadiss();//停止加载动画
    this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
  });
}

//提交认证
uploadDepartmentIdentity(){
  // this.dataApi.checkDepCollegeIdentity(this.model.collegeid).then((res:any)=>{
  //   console.log(res);
  // }).catch((err)=>{
  //   console.log(err);
  // })
  // return;
  // let filter = /^[0-9a-zA-Z]{18}$/;
  if(!this.model.business_licence){
    this.toastr.warning('请上传营业执照图片');
    return;
  }
  if(!this.model.id_card_front){
    this.toastr.warning('请上传身份证正面');
    return;
  }
  if(!this.model.register_number){
    this.toastr.warning('请填写统一社会信用代码');
    return;
  }
  if(this.regexManager.isRegister_number(this.model.register_number))
  {
  }
  else
  {
    this.toastr.warning('统一社会信用代码不规范');
    return;
  }
  // else{
  //   let filterValue=this.model.register_number.trim();//删除左右两端的空格
  //   if(!filter.test(filterValue)){
  //     this.toastr.warning('统一社会信用代码格式错误');
  //     return;
  //   }
  // }
  if(!this.collegeName){
    if(!this.model.facultyid){
       this.toastr.info("请选择当前申请学院");
       return;
    }
  }
  if(this.authcation_office_phone && !this.model.office_phone){
    this.toastr.warning('请填写办公电话');
    return;
  }
  if(this.secureMaterail){
    if(!this.model.proxy_document){
      this.toastr.info("请上传招聘委托书");
      return;
    }
  }

  this.opera.loading();//加载动画
  this.dataApi.uploadDepartmentIdentity(this.model).then((res:any)=>{
    this.opera.loadiss();//停止加载动画
    setTimeout(() => {
      this.toastr.success('提交成功');
      this.lastIdentity();//更新企业认证信息本地存储
      //跳转页面
      this.router.navigateByUrl("/home/company-identity"); //-------返回认证列表
    },600);
  }).catch((error) => {
    ////console.log(error);
    this.toastr.error(error);
    this.opera.loadiss();//停止加载动画
    //跳转页面
    this.router.navigateByUrl("/home/college-campustalk"); //-------返回高校列表
    // setTimeout(() => {
    //   this.toastr.warning(error);
    // },600);
  });
}

  goWork(){
    // this.opera.goplatformIdentity();
    this.router.navigateByUrl('/home/college-campustalk');
  }


  //获取平台认证信息并赋值
  getApplyPlatformDetails(){
    if(!this.config.lastIdentity){
      this.dataApi.getPlatformApply().then((res:any)=>{
        console.log("获取企业平台认证信息");
        console.log(res);
        if(res){
          this.model.register_number=res.register_number;
          this.model.business_licence=res.business_licence;
          this.model.id_card_front=res.id_card;
        }
      }).catch((error)=>{
        console.log("获取企业平台认证信息出错");
        console.log(error);
      })
    }else{
      this.model.register_number=this.config.lastIdentity.register_number;
      this.model.business_licence=this.config.lastIdentity.business_licence;
      this.model.id_card_front=this.config.lastIdentity.id_card;
    }
  }
}
