<!-- 页头部分 -->
<!-- <div class="contentRight-header">企业中心 - 企业认证</div> -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <router-outlet></router-outlet>
    <div class="college-block">
        <div class="waitBox">
          <img style="width: 134px;height: 123px" src="../assets/images/authentication_wait.png" alt="">
          <h3>审核排队人数较多，请耐心等待...</h3>
          <h3><span style="text-decoration:underline">VIP会员</span> 将优先审核</h3>
          <p>预计三个工作日内</p>
          <p style="margin-top: 15px;background-color: #5E4FFF;width: 100px;color:white;font-size: 16px;margin-left: 140px;
    padding: 10px;cursor: pointer" (click)="gotoTool()">成为VIP</p>
        </div>
    </div><!--college-block-->
</div><!--homepage-block-->
