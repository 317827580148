import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-smail-paging',
  templateUrl: './smail-paging.component.html',
  styleUrls: ['./smail-paging.component.css']
})
export class SmailPagingComponent implements OnInit {
  @Input() count: number;//分页总数
  @Input() activeCount: number;//当前激活分页
  // public pageList = [1,2,3,4,5];
  // public pageMoreList = [1,2,3];
  @Output() onVoted = new EventEmitter<number>();
  pageslist = [];
  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {

    // console.group('parent ngOnChanges called.',changes);
    //  console.log('分页器',changes,this.activeCount);
    if(changes.activeCount){
      if(this.activeCount == 0){
        this.activeCount = 1;
        this.onVoted.emit(this.activeCount);
        this.pageslist = this.pages();
      }
    }
    // console.log(changes.count);
    if(changes.count){

      //如果是第一次分页firstChange 不进行回掉，避免回掉重复调用，
      if(changes.count.firstChange)
      {
        this.activeCount = 1;
        this.pageslist = this.pages();
        return;
      }

      this.activeCount = 1;
      this.onVoted.emit(this.activeCount);
      this.pageslist = this.pages();
    }
  }

  pages() {
    const c = this.activeCount
    const t = this.count;
    // console.log('this.activeCount',this.activeCount,this.count);
    if(t >= 10){
      if (c <= 5) {
        return [1, 2, 3, 4, 5, 6, 7, '...', t]  //第一种情况
      } else if (c >= t - 4) {
        return [1, '...', t-6, t-5, t-4, t-3, t-2, t-1, t] //第二种情况
      } else {
        return [1, '...', c-2, c-1, c, c+1, c+2, '...', t]  //第三种情况
      }
    }else{
      let arr = [];
      for(let n = 1;n <= t;n ++){
        arr.push(n);
      }
      return arr;
    }
  }

  paging(page) {
    if(page == -1){
      if(this.activeCount > 1){
        this.activeCount --;
      }
    }else if(page == -2){
      if(this.activeCount < this.count){
        this.activeCount ++;
      }
    }else if(page == '...'){
      return
    }else{
      this.activeCount = page;
    }
    this.pageslist = this.pages();
    this.onVoted.emit(page);
  }
}
