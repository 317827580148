//企业单品、产品的工具管理

import {Config} from './config';
import {Injectable} from "@angular/core";
import {Api} from "./api";
import {Local} from "./local";
import {DataApi} from "./dataApi";
import {ToastrService} from "ngx-toastr";
@Injectable()
export class productManager {

  public config: Config;


  //----------单品的code 201908141526 ----------------

  public activity_invitation_code = '';  //校招活动申请_Code
  public sms_bulkinvitation_code = '';  //职位推送_Code
  public position_refresh_code = '';   //职位刷新_Code
  public voice_call_code = '';  //急速电话联系_Code
  public notice_student_sms_code = '';  //推广短信_Code

  //----------单品的对象信息 ----------------

  public activity_invitation_Data = '';  //校招活动申请_Code
  public sms_bulkinvitation_Data = '';  //职位推送_Code
  public position_refresh_Data = '';   //职位刷新_Code
  public voice_call_Data = '';  //急速电话联系_Code
  public notice_student_sms_Data = '';  ////推广短信_Code

  //----------单品的类别 recharge_category  -----------

  public activity_invitation = 'activity_invitation';   //校招活动邀请
  public sms_bulkinvitation = 'sms_bulkinvitation';  //职位推送
  public position_refresh = 'position_refresh';  //职位刷新
  public voice_call = 'voice_call';   //急速电话联系
  public notice_student_sms = 'notice_student_sms';   //推广短信

  // ------ 工作啦平台的所有 校招工具 类型
  public productList = [];

  public my_productList = [];

  //   我的单品列表对象，单品的剩余数，等
  public my_activity_invitation:any;   //校招活动邀请
  public my_sms_bulkinvitation:any; //职位推送
  public my_position_refresh :any;  //职位刷新
  public my_voice_call:any;  //急速电话联系
  public my_notice_student_sms:any;  //推广短信


  public member_info:any;
  public position_count  = 0;

  constructor(private api: Api,
              public dataApi: DataApi,
              public toastr: ToastrService,

              private local: Local) {
    this.config = Config.getInstance();
  }

  //初始化 获取企业所拥有的单品列表，单品余额，平台所有的单品类型 ,可调用可不调用
  init(){
    this.getGWLProductList(()=>{});
    this.getDepartmentProduct(()=>{});
  }

  //获取企业拥有的的单品列表
  getDepartmentProduct(callback){
    this.dataApi.getEmployment().then((res:any)=> {
      console.log("企业信息获取线上原数据", res);
      if(res)
      {
        if(res.member_info)
        {
          this.member_info = res.member_info;
        }
        this.position_count = res.quota.position
        let arr = res.quota.product_component_info;
        let listArr = [];
        let activity_invitation = null;
        let activity_invitation_left = 0;
        let activity_invitation_total = 0;

        let sms_bulkinvitation = null;
        let sms_bulkinvitation_left = 0;
        let sms_bulkinvitation_total = 0;

        let position_refresh = null;
        let position_refresh_left = 0;
        let position_refresh_total = 0;

        let voice_call = null;
        let voice_call_left = 0;
        let voice_call_total = 0;

        let notice_student_sms = null;
        let notice_student_sms_left = 0;
        let notice_student_sms_total = 0;

        for (let item of arr) {
          if (item.alias == "activity_invitation") {
            //校招活动申请
            activity_invitation = item;
            activity_invitation_left += item.left;
            activity_invitation_total += item.total;
          } else if (item.alias == "sms_bulkinvitation") {
            //职位推送
            sms_bulkinvitation = item;
            sms_bulkinvitation_left += item.left;
            sms_bulkinvitation_total += item.total;
          } else if (item.alias == "position_refresh") {
            //职位刷新
            position_refresh = item;
            position_refresh_left += item.left;
            position_refresh_total += item.total;
          } else if (item.alias == "voice_call") {
            //电话联系
            voice_call = item;
            voice_call_left += item.left;
            voice_call_total += item.total;
          }
          else if(item.alias == "notice_student_sms")
          {
            //短信推广
            notice_student_sms = item;
            notice_student_sms_left += item.left;
            notice_student_sms_total += item.total;
          }
        }
        if (activity_invitation) {
          activity_invitation.left = activity_invitation_left;
          activity_invitation.total = activity_invitation_total;
          listArr.push(activity_invitation);
          this.my_activity_invitation = activity_invitation;

        }
        if (sms_bulkinvitation) {
          sms_bulkinvitation.left = sms_bulkinvitation_left;
          sms_bulkinvitation.total = sms_bulkinvitation_total;
          listArr.push(sms_bulkinvitation);
          this.my_sms_bulkinvitation = sms_bulkinvitation;

        }
        if (position_refresh) {
          position_refresh.left = position_refresh_left;
          position_refresh.total = position_refresh_total;
          listArr.push(position_refresh);
          this.my_position_refresh = position_refresh;
        }
        if (voice_call) {
          voice_call.left = voice_call_left;
          voice_call.total = voice_call_total;
          listArr.push(voice_call);
          this.my_voice_call = voice_call;
        }
        if(notice_student_sms)
        {
          notice_student_sms.left = notice_student_sms_left
          notice_student_sms.total = notice_student_sms_total;
          listArr.push(notice_student_sms);
          this.notice_student_sms = notice_student_sms;
        }
        this.my_productList = listArr;
        this.dataApi.setLocalDepartment(res);

        if(callback)
        {callback()}
      }

    }).catch((error) => {

    });
  }

  //
  updateDepartmentProduct(){

  }
  //获取单品的余额
  getProductUsableCount(type,callback){

    this.dataApi.getEmployment().then((res:any)=>{//通过tooken获取企业信息
      if(res.member_info) {
        let nowData = new Date();
        let overData = new Date(res.member_info.endtime);
        if (nowData.getTime() > overData.getTime()) {
          console.log('会员已经到期了');
          this.dataApi.getcomponentLeft({alias:type}).then((res:any)=>{
            console.log('getcomponentLeft',res);
            if(res) {
              if(res.length>0) {
                let item = res[0];
                callback(item.left,false);
              } else {
                callback(0,false);
              }
            } else {
              callback(0,false);
            }
          }).catch((err)=>{
            console.log(err);
            callback(0,false);
          })

        } else {
          //会员没有到期
          this.dataApi.getcomponentLeft({alias:type}).then((res:any)=>{
            console.log('getcomponentLeft',res);
            if(res) {
              if(res.length>0) {
                let item = res[0];
                callback(item.left,true);
              } else {
                callback(0,true);
              }
            } else {
              callback(0,true);
            }
          }).catch((err)=>{
            console.log(err);
            callback(0,true);
          })
        }
      } else {
        //没有会员
        callback(0,false);
      }
    }).catch((error)=>{
      callback(0,false);
    });
  }
  getGWLProductList(callback){
    console.log('getGWLProductList');
    this.dataApi.departmentList({product_category:"recharge"}).then((res:any)=>{
      if(res){
         console.log('获取产品列表getGWLProductList',res)
        let  activity_invitation = null; //活动邀请
        let  sms_bulkinvitation = null; //职位推送
        let  position_refresh = null;//职位刷新
        let  voice_call = null;//电话联系
        let  notice_student_sms = null; //空中宣讲会产品包
        for (let item of res.list) {
          item.type = '1';
          if (item.recharge_category == "activity_invitation") {
            //校招活动申请
            this.activity_invitation_code = item.code;
            this.activity_invitation_Data = item;
            activity_invitation = item;
          } else if (item.recharge_category == "sms_bulkinvitation") {
            //职位推送
            this.sms_bulkinvitation_code  = item.code;
            this.sms_bulkinvitation_Data  = item;
            sms_bulkinvitation = item;

          } else if (item.recharge_category == "position_refresh") {
            //职位刷新
            this.position_refresh_code  =item.code;
            this.position_refresh_Data  =item;
            position_refresh = item;

          } else if (item.recharge_category == "voice_call") {
            //电话联系
            this.voice_call_code  = item.code;
            this.voice_call_Data  = item;
            voice_call = item;
          }
          else if (item.recharge_category == "notice_student_sms") {
            //电话联系
            this.notice_student_sms_code  = item.code;
            this.notice_student_sms_Data  = item;
            notice_student_sms = item;
          }
        }
        let newArr = [];
        if(voice_call) {
          newArr.push(voice_call)
        }
        if(activity_invitation) {
          newArr.push(activity_invitation)
        }
        if(position_refresh) {
          newArr.push(position_refresh)
        }

        if(notice_student_sms) {
          newArr.push(notice_student_sms)
        }
        if(sms_bulkinvitation) {
          newArr.push(sms_bulkinvitation)
        }

        this.productList = newArr;

        if(callback) {
           callback();
        }
      }
    }).catch((err)=>{
      console.log(err);
    })

  }

}
