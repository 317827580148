<!--<div class="contentRight-header">-->
<!--<img (click)="opera.goPre()" src='assets/images/router-old.png'/>-->
<!--校招工具-->
<!--</div>-->
<!--<app-navigation-route></app-navigation-route>-->

<!-- 主体内容部分 -->
<div class="homepage-block">
    <div class="secondNav">
        <div class="item " (click)="myTool()">我的工具</div>
        <div class="item " (click)="myOrder()">我的订单</div>
        <div class="item " (click)="myInvoice()">我的发票</div>
        <div class="item active">啦豆明细<span></span></div>
    </div>

    <div class="box">

        <!--<div class="historyTitle">-->
        <!---->
        <!--</div>-->
        <div class="custom_card" style="padding: 24px 44px;">
            <div class="pay_history_head">购买记录</div>
            <div class="">
                <table class="layui-table" lay-skin="nob">
                    <thead>
                        <tr>
                            <th style="text-align: left;">购买时间</th>
                            <th style="text-align: left;">购买商品</th>
                            <th style="text-align: center;">啦豆充值</th>
                            <th style="text-align: center;">支付啦豆</th>
                            <th style="text-align: center;">啦豆余额</th>
                            <th style="text-align: center;">操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let n of list;let i = index;" [ngClass]="{cellBg1:i%2==0,cellBg2:i%2==1}">

                            <!--   收入  啦豆  -->
                            <td *ngIf="n.transaction_type==1" style="text-align: left;">{{n.add_time | date:"yyyy-MM-dd HH:mm"}}</td>
                            <td *ngIf="n.transaction_type==1" style="text-align: left;">{{n.item_name}}</td>
                            <td *ngIf="n.transaction_type==1">{{n.item_name.indexOf('赠送')>=0?'/':n.amount_income}}</td>
                            <td *ngIf="n.transaction_type==1">/</td>
                            <td *ngIf="n.transaction_type==1">{{n.balance}}</td>
                            <!--add_time-->
                            <!--//item_name-->
                            <!--//amount_income-->
                            <!--//amount_income-->
                            <!--0-->
                            <!--balance-->

                            <!--   支出   单品  -->
                            <td *ngIf="n.transaction_type==2" style="text-align: left;">{{n.add_time | date:"yyyy-MM-dd HH:mm"}}</td>
                            <td *ngIf="n.transaction_type==2" style="text-align: left;">{{n.item_name}}</td>
                            <td *ngIf="n.transaction_type==2">/</td>
                            <td *ngIf="n.transaction_type==2">{{n.amount_expenditure}}</td>
                            <td *ngIf="n.transaction_type==2">{{n.balance}}</td>
                            <!--add_time-->
                            <!--//item_name-->
                            <!----kong-->
                            <!--支付金额kong-->
                            <!--支付ladou  amount_expenditure-->
                            <!--balance-->


                            <td><a class="custom_btn_text" (click)='gotoPay(n)'>查看订单</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- 空状态 -->
            <div class="none-block-empty" *ngIf="list.length == 0">
                <img src="assets/images/rights/empty.png">
                <p>您还没有购买记录，购买工具提升招聘效率</p>
            </div>
        </div>


        <div class='paging-block' [class.isPageHeight]="countList>0">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>

    </div>

</div>