<!--<div class="contentRight-header">-->
  <!--<img (click)="opera.goPre()" src='assets/images/router-old.png'/>-->
<!--校招工具</div>-->
<app-navigation-route></app-navigation-route>

<!-- 主体内容部分 -->
<div class="homepage-block">

  <div class="box">

    <div class="tabBg">
      <div [ngClass]="{tabBg_span_s:tab=='1',tabBg_span:tab!='1'}" (click)="tabClick('1')">可使用的</div>
      <div [ngClass]="{tabBg_span_s:tab=='2',tabBg_span:tab!='2'}" (click)="tabClick('2')">已使用的</div>
      <div [ngClass]="{tabBg_span_s:tab=='3',tabBg_span:tab!='3'}" (click)="tabClick('3')">已失效的</div>
    </div>

    <div class="cardContent">
      <div class="cardItem" *ngFor="let n of cardList">
        <div class="cardItem_Top">
          <span class="price" [ngClass]="{price:tab=='1',price_gray:tab!='1'}">{{n.gift_amount}}<span style="font-size: 24px;">啦豆</span></span>
          <div class="price_r" *ngIf="n.coupon_category != 'exchange_coupon'">
            <span class="subTitle">满{{n.limited_amount}}可用</span><br/>
            <span  [ngClass]="{manjian:tab=='1',manjian_gray:tab!='1'}">{{n.typeTitle}}</span>
            <span class="manjian_title">{{n.title}}</span>
          </div>
        </div>

        <div class="cardItem_Center">
          <p class="cardItem_Center_span1">适用商品：<span [ngClass]="{bluetext:tab=='1',grayColor:tab!='1'}">{{n.coupon_category == "exchange_coupon"?'兑换':''}}{{n.limited_product_name}}</span></p>
          <p class="cardItem_Center_span2">使用期限：{{n.startTime}} - {{n.endTime}}</p>
          <button  [ngClass]="{cardItem_Center_btn3:tab=='1',cardItem_Center_btn3_gray:tab!='1'}" (click)="userCardClick(n)">{{tab=='2'?'已使用':tab=='1'?'立即使用':'已失效'}}</button>
        </div>
      </div>
    </div>
    <div *ngIf="cardList.length==0" style="width: 100%;text-align: center;background-color: white;padding: 114px 0 300px 0"><img style="width: 320px;height: 162px;" src="../../assets/images/none_cord.png"><p style="color:#999999;font-size: 16px ">暂无卡劵</p></div>


    <div class='paging-block' [class.isPageHeight]="countList>0" *ngIf="countList>8">
      <router-outlet></router-outlet>
      <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
    </div>

    <!--<div style="margin: 0px 0px;height:44px;line-height: 44px;border-bottom: 5px #F7F7F7 solid;font-size: 18px;color: #333333">-->
      <!--<span style="line-height: 44px;height: 44px;margin-left: 15px">卡券说明</span>-->
    <!--</div>-->
    <!--<div class="shuoMing">Ant Design是一个服务于企业级产品的设计体系，基于『确定』和『自然』的设计价值观和模块化的解决方案，让设计者专注于更好的用户体验。Ant Design是一个服务于企业级产品的设计体系，基于『确定』和『自然』的设计价值观和模块化的解决方案，让设计者专注于更好的用户体验。Ant Design是一个服务于企业级产品的设计体系，基于『确定』和『自然』的设计价值观和模块化的解决方案，让设计者专注于更好的用户体验。Ant Design是一个服务于企业级产品的设计体系，基于『确定』和『自然』的设计价值观和模块化的解决方案，让设计者专注于更好的用户体验。Ant Design是一个服务于企业级产品的设计体系，基于『确定』和『自然』的设计价值观和模块化的解决方案，让设计者专注于更好的用户体验。Ant Design是一个服务于企业级产品的设计体系，基于『确定』和『自然』的设计价值观和模块化的解决方案，让设计者专注于更好的用户体验。Ant Design是一个服务于企业级产品的设计体系，基于『确定』和『自然』的设计价值观和模块化的解决方案，让设计者专注于更好的用户体验。</div>-->
  </div>

</div>
<p-dialog [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="false" 
[style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':400,'height':200,'minHeight':200}"  [modal]="true" [showHeader]="false" [(visible)]="showAlertDuihuan">
  <div style="width: 400px;height: 200px;background-color: white;overflow: hidden;">

    <p style="width: 300px;height: 44px;margin-left: 50px;margin-top: 50px;text-align: center;padding: 0 10px" >是否兑换{{currentCard?.gift_amount}}啦豆</p>

    <button style="width: 120px;height: 40px;float: left;margin-left: 50px;margin-top: 30px;border: 1px lightgray solid;" (click)="cancelAler()">取消</button>
    <button style="width: 120px;height: 40px;float: right;margin-right: 50px;margin-top: 30px;border: 1px lightgray solid;background-color: #5e4fff;color: white" (click)="commitAlert()">确定</button>

  </div>

</p-dialog>
