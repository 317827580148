import { Component,EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Opera,DataApi } from '../../provider/index';
import { Router,ActivatedRoute } from  '@angular/router';

@Component({
  selector: 'app-model-college-identity',
  templateUrl: './model-college-identity.component.html',
  styleUrls: ['./model-college-identity.component.css']
})
export class ModelCollegeIdentityComponent{
  @Input()  name: string;//高校名称
  @Input()  orgList:any;//高校院系列表
  @Input()  isShow: false;//是否显示弹窗页面
  @Input()  item:any;//认证状态
  @Input()  collegeid:any;//大学id
  @Input()  audit_time:any;//审核时间
  public facultyid='';//院系id
  constructor(
    public opera:Opera,
    public toastr: ToastrService,
    public dataApi: DataApi,
    private router: Router,
  ) {
      
  }
  @Output() onVoted = new EventEmitter<any>();

  dismiss(result: any) {
    console.log(this.collegeid)
    if(!this.name&&result){
      if(!this.facultyid){
        this.toastr.info('请选择当前申请学院');
        return;
      }
      this.onVoted.emit(this.facultyid);
    }else{
      this.onVoted.emit(result);
    }
  }

  formatDate(now) { 
    var year=now.getFullYear(); 
    var month=now.getMonth()+1; 
    var date=now.getDate(); 
    // var hour=now.getHours(); 
    // var minute=now.getMinutes(); 
    // var second=now.getSeconds(); 
    // return year+"-"+month+"-"+date+" "+hour+":"+minute+":"+second; 
    return year+"年"+month+"月"+date+"日"; 
  } 

  again(result: any){
    console.log(this.audit_time);
    this.dataApi.checkDepCollegeIdentity(this.collegeid).then((res:any)=>{
      if(res.can_apply){
        this.onVoted.emit(result);
      }else{
        let audit_time = new Date(this.audit_time); // 审核时间
        let audit = audit_time.getTime();  // 审核时间戳
        if(audit == -62135625943000 || this.audit_time == ''){
          this.toastr.warning(res.reason);
        }else{
          let newTime = audit + 30*24*60*60*1000;
          this.toastr.warning(`上次申请未通过，30天内不可再次申请，${this.formatDate(new Date(newTime))}才可重新申请！`);
        }
      }
    }).catch((err)=>{
      this.toastr.error(err);
    })
    // this.dataApi.checkDepCollegeIdentity(this.collegeid).then((res:any)=>{
    //   console.log(res);
    //   if(res.can_apply){
    //     // this.onVoted.emit(this.facultyid);  
    //     this.onVoted.emit(result);
    //   }else{
    //     this.toastr.warning(res.reason);
    //   }
    // }).catch((err)=>{
    //   this.toastr.error(err);
    // })
    // this.onVoted.emit(this.facultyid);
    // this.onVoted.emit(result);
  }
  // 工作啦认证
  goWork(){

    // this.opera.goplatformIdentity();
    this.router.navigateByUrl('/home/college-campustalk');
  }
  
}
