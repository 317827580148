import { Component, OnInit } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import {  ActivatedRoute,Router } from  '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-company-receipt-send',
  templateUrl: './company-receipt-send.component.html',
  styleUrls: ['./company-receipt-send.component.css']
})
export class CompanyReceiptSendComponent implements OnInit {
  public data:any;
  public myDepartment:any;
  public model = {
    paymentid:[],// 支付记录id
    invoice_title_type:'enterprise_unit',// 发票抬头类型 enterprise_unit 企业单位 personal 个人
    invoice_title:'',// 发票抬头
    id_number:'',// 身份证号
    register_no:'',// 税务登记号
    bank_name:'',// 基本开户银行名称
    bank_no:'',  // 基本开户账号
    register_address:'',// 注册场所地址
    register_tel:'',// 注册固定电话
    email:'',   // 发票接收邮箱
    remark:'',  // 备注
    address:''
  }
  public payment:any; // 申请开票的订单信息
  public workPlace = '';// 企业地址
  public amount:any; // 开票金额

  constructor(
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.opera.initConfig('member','company-status-one');
    // this.getDepartment();
    this.data = JSON.parse(this.local.get('receiptInfo'));
    console.log(this.data);

    this.model.paymentid.push(this.data.payid);
    this.amount=this.data.amount;
  }

  //去地址列表
  goAddress():void{
    this.router.navigateByUrl("home/company-details-address");
  }

  // 切换类型
  applyType(type){
    this.model.invoice_title_type = type;
    console.log(this.model.invoice_title_type);

  }


    //获取企业数据
    getDepartment(){
      if(this.dataApi.getLocalDepartment()){
        this.myDepartment = this.dataApi.getLocalDepartment();
        //console.log("企业信息本地存储");
        //console.log(this.dataApi.getLocalDepartment());
        // this.initDeparment(this.dataApi.getLocalDepartment());//初始化页面数据
      }else{
        this.dataApi.getDepartment().then((res:any)=>{
          //console.log("企业信息获取线上数据");
          //console.log(res);
        this.dataApi.setLocalDepartment(res);
        // this.initDeparment(res);//初始化页面数据
      }).catch((error) => {
          //console.log(error);
        this.toastr.error(error);
      });
    }
  }


  //初始化当前页面数据
  // initDeparment(_department){
  //   this.myDepartment = _department;
  //   console.log(this.myDepartment);
  //   if(this.myDepartment.official_account){//微信公众号
  //     this.model.email = this.myDepartment.email;
  //   }
  //   // return
  //   if(this.myDepartment.intro){//企业简介
  //     this.model.intro = this.myDepartment.intro;
  //   }
  //   if(this.myDepartment.website){//企业网址
  //     this.model.website = this.myDepartment.website;
  //   }
  //   if(this.myDepartment.labels){//企业标签
  //     this.model.labels = this.myDepartment.labels;
  //   }
  //   if(this.myDepartment.strengths){//公司实力
  //     this.model.strengths = this.myDepartment.strengths;
  //   }
  //   if(this.myDepartment.is_quoted_company){//是否上市
  //     this.model.is_quoted_company = this.myDepartment.is_quoted_company;
  //   }
  //   if(this.myDepartment.stock_code){//股票代码
  //     this.model.stock_code = this.myDepartment.stock_code;
  //   }
  //   if(this.myDepartment.official_account){//微信公众号
  //     this.model.official_account = this.myDepartment.official_account;
  //   }
  //   if(this.myDepartment.official_account){//微信公众号
  //     this.model.official_account = this.myDepartment.official_account;
  //   }
  //   // 给公司地址默认赋值
  //   if(this.myDepartment.locations){
  //     if(this.myDepartment.locations.length>0){
  //       let is_default = false;
  //       this.myDepartment.locations.forEach((val)=>{
  //         if(val.is_default){
  //           is_default = true;
  //           this.workPlace = val.lid;
  //         }
  //       });
  //       if(!is_default){
  //         this.workPlace = this.myDepartment.locations[0].lid;
  //       }
  //     }
  //   }
  // }

  //提交
  goApply(){
    //必填项检查
    if(this.model.invoice_title_type=='personal'){
      if(!this.model.invoice_title){
        this.toastr.warning("请填写姓名");
        return;
      }
      if(!this.model.id_number){
        this.toastr.warning("请填写身份证号");
        return;
      }
      if(!this.model.email){
        this.toastr.warning("请填写邮箱");
        return;
      }
    }else{
      if(!this.model.invoice_title){
        this.toastr.warning("请填写发票抬头");
        return;
      }
      if(!this.model.register_no){
        this.toastr.warning("请填写税号");
        return;
      }
      if(!this.model.bank_name){
        this.toastr.warning("请填写开户银行");
        return;
      }
      if(!this.model.bank_no){
        this.toastr.warning("请填写银行账号");
        return;
      }
      if(!this.model.register_address){
        this.toastr.warning("请输入公司地址");
        return;
      }
      if(!this.model.register_tel){
        this.toastr.warning("请填写公司电话");
        return;
      }
      if(!this.model.email){
        this.toastr.warning("请填写邮箱");
        return;
      }
    }

    // let loader = this.tip.presentLoading('数据加载中...');
    this.dataApi.applyInvoice(this.model).then((res:any)=>{
      console.log('申请开票成功');
      this.toastr.success("申请开票成功");
      this.router.navigateByUrl("home/company-receipt"); // 会员页面
      // this.navCtrl.push('AboutReceiptPage');
      // loader.dismiss();
    }).catch((err)=>{
      // loader.dismiss();
      console.log("申请发票出错");
      // console.log(err);
      this.toastr.error(err);
    })
    //确认信息弹窗

    // let modal = this.modalCtrl.create('ModelReceiptSurePage',{
    //   init:this.model
    // });
    // modal.onDidDismiss(data => {
    //   console.log("确认信息弹窗");
    //   console.log(data);
    //   if(data=="true"){
    //     let loader = this.tip.presentLoading('数据加载中...');
    //   }
    // });
    // modal.present();
  }
}
