<!-- 引导页面-->
<div class="guide-page">
    <!-- 头部 -->
    <div class="guide-head">
      <ul>
        <li>
          <span><img src="assets/images/guide-ok.png"></span>
           <br/>招聘信息
         </li>
         <li>
          <span><img src="assets/images/guide-ok.png"></span>
          <br/>完善信息
        </li>
        <!--<li>-->
          <!--<span><img src="assets/images/guide-ok.png"></span>-->
          <!--<br/>上传证件-->
        <!--</li>-->
        <li>
          <span class="isHeadActive">3</span>
          <br/>微信绑定
        </li>
      </ul>
    </div>


   <!-- 中间部分 -->
   <div class="info-block">
     <div class="info-head" style="position: relative">
       <img *ngIf="weChatCode" src={{weChatCode}} >
       <img src="../../assets/images/rush_pay.png" *ngIf="isShowRefresh" (click)="startTime()" style="width: 60px;height: 60px;left: calc((100% - 60px)/2);position: absolute;top: 60px;cursor: pointer" />
     </div>
     <div class="info-text">绑定登录微信后可扫码一键登录，同时可及时获悉您的高校合作,双选会，宣讲会审核结果通知</div>
   </div>
  <div *ngIf="config.api.indexOf('test')>0" (click)="testjump()">跳过,仅测试可见</div>
   <!-- 尾部 -->
  <div class="guide-foot"><span>客服热线：{{config.CustomerServicePhone}}</span>
    &nbsp;&nbsp;&nbsp;&nbsp;{{config.websiteRecord}}</div>

  </div>

