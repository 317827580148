import { Component, TemplateRef, Inject } from '@angular/core';
// import { DialogComponent, DialogService } from 'ngx-bootstrap-modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Router} from "@angular/router";
export interface ConfirmModel {
  title:string;
  message:any;
  trueText:string;
  falseText:string;
}
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent{
  // modalRef: BsModalRef;
  title: string;
  message: any;
  trueText:string;
  falseText:string;
  public isShowFindAccount = false
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmModel,    private router: Router,              public dialog: MatDialog,

  ) {
    this.title=data.title;
    this.message=data.message;
    this.trueText=data.trueText;
    this.falseText=data.falseText;
    if(this.message.indexOf('是否继续注册')>=0){
      this.isShowFindAccount = true;
    }
  }
  gotoFingAccpunt(){
    this.dialog.closeAll();
    this.router.navigateByUrl('/find-account');

  }

}

