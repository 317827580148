import {Config} from './config';


import {Injectable} from "@angular/core";

@Injectable()
export class regexManager {

  //验证邮箱
  public email_rex = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;

  //验证手机号
  public phone_rex = /^1(2|3|4|5|6|7|8|9)\d{9}$/;

  //座机 000-00000000
  public tel_rex =/0\d{2,3}-\d{7,8}/

  public regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;

  public number = /^[0-9]*$/

  isEmail(email){
    return true;
  }
  isPhone(phone){
    return this.phone_rex.test(phone);
  }
  isNumber(number){
    return this.number.test(number);
  }
  isPhoneOrTel(phone){
    const isPhone02 = /^\d{3,4}-\d{3,4}-\d{3,4}$/; // 4001-550-520
    if(this.phone_rex.test(phone)|| this.tel_rex.test(phone)||isPhone02.test(phone)) {
      return true;
    } else {
      return false;
    }
  }
  clearSpace(text) {
    //匹配任何空白字符，包括空格、制表符、换页符等等
    let value = text.replace(new RegExp(/\s/g), '');
    return value;
  }

  isIdCard(text) {
    return this.regIdCard.test(text);
  }
  //是否是注册信用代码  为7 9 15 18 位
  isRegister_number(register_number){

    let filter7 = /^[0-9a-zA-Z]{7}$/;
    let filter9 = /^[0-9a-zA-Z]{9}$/;
    let filter15 = /^[0-9a-zA-Z]{15}$/;
    let filte183 = /^[0-9a-zA-Z]{18}$/;
    if (filter7.test(register_number)||filter9.test(register_number)|| filter15.test(register_number) || filte183.test(register_number)) {
      return true;
    } else {
      return false;
    }
  }
  //成立年份是否是符合规范 2020 1999 等
  isEstablish_year(year){
    let number = /^[0-9]{4}$/;
    if(!year) {
      return false;
    }
    let newYear = year+'';
    if(newYear.length!=4) {
      return false;
    }
    if(newYear.length>1) {
      let index = newYear.slice(0,1);
      console.log('index',parseInt(index));
        if(parseInt(index)>2||parseInt(index)<0) {
          return false;
        }
    }
    var myDate = new Date();
    var tYear = myDate.getFullYear();
    console.log('tYear',tYear,year)
    if(year>tYear) {
      return false;
    }

    return number.test(year);
  }
}
