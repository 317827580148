<div style="background-color:transparent" class="college-block">

    <app-voice-call-model #voiceCallModel [isShow]="isShowVoiceCallModel" (modelHidden)="voiceCallModelHidden($event)"></app-voice-call-model>
    <app-model-send-msg #sengMsgModel [SengMsgModel]='SengMsgModel' [tconfig]='config' (onVoted)="Modeldismiss($event)" [isShow]="isShowPositionPushModel"></app-model-send-msg>

    <!-- 新版本 -->
    <div class="resume-container">
        <!-- 头部 -->
        <div class="header">
            <div class="secondNav">
                <div class="item " (click)="nav01()">收到的</div>
                <div class="item " (click)="nav02()">收藏的</div>
                <div class="item " (click)="nav03()">极速电话联系记录</div>
                <div class="item active">职位推送记录<span></span></div>
                <div class="item" (click)="nav05()">校招活动邀请记录</div>
                <div class="item" (click)="nav06()">空中宣讲会推广记录</div>
            </div>
            <div class="banner" style="margin-bottom:0">
                <div class="layui-carousel" id="test1" *ngIf="bannerList.length>0">
                    <div carousel-item>
                        <div *ngFor="let n of bannerList;let i = index;">
                            <img class="swiper-slide bannerImage" (click)="bannerClick(n)" id={{i}} src="{{n.image_url}}" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 非会员 -->
            <div class="novipTip" *ngIf="!isShowMemberMoudel">
                <div class="text"> 非会员收到的简历数量有限，加入会员，接收更多人才简历</div>
                <div class="join joinHover" (click)="product('','contactMe')">加入会员</div>
            </div>
            <div class="search">
                <select class="filtrate_row_select activeBg" [(ngModel)]="model.positionid" (ngModelChange)="getRecommendList()">
            <option value="">全部招聘职位</option>
            <option *ngFor="let a of positionList" [value]="a.positionid">{{a.name}} <span *ngIf="a.position_status == 'delete'">(已删除)</span></option>
          </select>
            </div>
        </div>
        <div class="resume-list" *ngIf="countList>0">

            <div class="listCell" *ngFor="let n of resumeList;let i = index;" id={{i}}>
                <div class="cellTime">{{n.date| date:'yyyy-MM-dd'}}</div>
                <div class="itemBox">
                    <div class="resume-list-item" *ngFor="let item of n.list;" (click)="gotoDetail(item)">
                        <div class="rightBtn">
                            <div class="item active" (click)="voiceCallClick($event,item)"><img src="/assets/images/v3/speedIcon.png">极速电话联系</div>
                            <div class="item" (click)="positionPushClick($event,item)" title="可以将职位定向推送给该学生"><img src="/assets/images/v3/pushIcon.png">职位推送</div>
                        </div>
                        <div class="main">
                            <div class="introduce">
                                <div class="logo"><img *ngIf="item.logo" [src]="item.logo+'&imageView2/1/w/55/h/55'"></div>
                                <div class="text">
                                    <div class="name">{{item.talent_name}}<span *ngIf="item.new_talent">新用户</span></div>
                                    <div class="college" *ngIf='item.graduate_university'>{{item.graduate_university}}</div>
                                    <div class="major">{{item.major_text}} | {{item.education_text}} | {{item.year==0?"往届":item.year+'届'}}</div>
                                </div>
                            </div>
                            <div class="lable">
                                <span *ngFor="let youshi of item.skills | slice:0:4;let i = index;">{{youshi.text}}</span>
                            </div>
                            <div class="intention">
                                <span>求职意向：</span>{{item.yixiang}}
                            </div>
                            <div class="bottom">
                                <div class="bottom-item"><img src="/assets/images/rights/positionicon.png">推送职位：<span>{{item.position_invite.position_name}}</span></div>
                                <div class="bottom-item"><img src="/assets/images/rights/time.png">推送时间：{{item.position_invite.add_time |date:'yyyy-MM-dd HH:mm:ss'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 空状态 -->
        <div class="none-block-empty" *ngIf="countList == 0">
            <img src="assets/images/rights/empty.png">
            <p>暂未找到记录</p>
        </div>

        <!-- 分页部分 -->
        <div class='paging-block' [class.isPageHeight]="countList>9">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>

    </div>

    <!-- 旧版本 -->
    <div class="company-block" style="width: 1080px;display: none;">

        <div class="banner">
            <div class="layui-carousel" id="test1" *ngIf="bannerList.length>0">
                <div carousel-item>
                    <div *ngFor="let n of bannerList;let i = index;">
                        <img class="swiper-slide bannerImage" (click)="bannerClick(n)" id={{i}} src="{{n.image_url}}" />
                    </div>
                </div>
            </div>
        </div>

        <div class="filtrateView">
            <div class="filtrate_row">
                <select [(ngModel)]="model.positionid" (ngModelChange)="getRecommendList()">
          <option value="">全部招聘职位</option>
          <option *ngFor="let a of positionList" [value]="a.positionid">{{a.name}} <span *ngIf="a.position_status == 'delete'">(已删除)</span></option>
        </select>
                <!--<button [class.purple]="model.status==''" (click)="changeStatus('')" >全部</button>-->
                <!--<button [class.purple]="model.status=='viewed'"  (click)="changeStatus('viewed')">待查看</button>-->
                <!--<button [class.purple]="model.status=='interested'"  (click)="changeStatus('interested')">感兴趣</button>-->
                <!--<div class="filtrate_more" (click)="changeOpenMore()" [class.show-filter]="isOpenMore">更多筛选条件 <img src="assets/images/down-gray.png"></div>-->

            </div>
        </div>

        <div *ngIf="isOpenMore" class="college-resume-filter" style="margin-top: -15px">
            <span>&nbsp;&nbsp;&nbsp;&nbsp;专业</span>
            <select name="major" id="major" [(ngModel)]="majorOne" (ngModelChange)="majorOneChange()" autocomplete="off">
        <!--<option class="place-text" value='' disabled hidden selected="selected">选择一级专业</option>-->
        <option class="place-text" value='' selected="selected">不限</option>
        <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
      </select>
            <select name="majorTwo" id="majorTwo" [(ngModel)]="model.major" (ngModelChange)="getRecommendList()" (click)="isShowTwo()" autocomplete="off">
        <!--<option class="place-text" value='' disabled hidden selected="selected">选择二级专业</option>-->
        <option class="place-text" value='' selected="selected">不限</option>
        <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
      </select>
            <!-- 选择学历 -->
            <span>&nbsp;&nbsp;&nbsp;&nbsp;学历</span>
            <select name="education" id="education" [(ngModel)]="model.education" (ngModelChange)="getRecommendList()" autocomplete="off">
        <option class="place-text" value='' selected="selected">不限</option>
        <option *ngFor="let a of educationList" [value]="a.code">{{a.text}}</option>
      </select>
            <!-- 重置 -->
            <!-- <button (click)="goInit()">重置</button> -->
        </div>

        <div *ngIf="countList== 0" class="none-block">
            <img src="assets/images/resume-search-fail.png"><br/> 暂未找到记录
        </div>


        <div class="tableView">
            <div style="overflow: hidden" *ngFor="let n of resumeList;let i = index;" id={{i}}>
                <div class="cell_time">{{n.date| date:'yyyy-MM-dd'}}</div>

                <div class="listCellContent" *ngFor="let item of n.list;" (click)="gotoDetail(item)">
                    <div class="row_col1 row_height">
                        <div class="row_col1_userInfo">
                            <img *ngIf="item.logo" class="headPic" src="{{item.logo}}">
                            <img *ngIf="!item.logo&&item.gender=='01'" class="headPic" src="../../assets/images/male.png">
                            <img *ngIf="!item.logo&&item.gender=='02'" class="headPic" src="../../assets/images/female.png">
                            <img *ngIf="!item.logo&&!item.gender" class="headPic" src="../../assets/images/male.png">
                            <ul>
                                <li class="userName">{{item.talent_name}}
                                    <span class="new_telent" *ngIf="item.new_talent">新用户</span>
                                </li>
                                <li><span class="zhiwei">求职意向：{{item.yixiang}}</span></li>
                            </ul>
                        </div>
                        <div style="width: 100%;height: 25px">
                            <span class="youshi" *ngFor="let youshi of item.youshiArr | slice:0:3;let i = index; ">{{youshi}}</span>
                        </div>
                    </div>
                    <div class="row_col2 row_height">
                        <ul class="collegeInfo">
                            <li><img src="../../assets/images/record_list1.png">{{item.graduation_school}}</li>
                            <li><img src="../../assets/images/record_list2.png">{{item.major_text}}</li>
                            <li><img src="../../assets/images/record_list3.png">{{item.education_text}}</li>
                            <li><img src="../../assets/images/jiebieIcon.png">{{item.year==0?"往届":item.year+'届'}}</li>

                        </ul>
                    </div>

                    <div class="row_col4 row_height">
                        <a (click)="voiceCallClick($event,item)" class="act-btn purple bordeRadius"><img src="../../assets/images/talent/phone.png" />极速电话联系</a>
                        <a (click)="positionPushClick($event,item)" title="可以将职位定向推送给该学生" class="act-btn yellow bordeRadius"><img src="../../assets/images/talent/pulish.png" />职位推送</a>
                    </div>

                    <div class="row_col3 row_height">
                        <img style="width:27px;height:100px;position: absolute" src="../../assets/images/record_line.png">
                        <ul class="collegeInfo callText">

                            <li>推送职位： {{item.position_invite.position_name}}</li>
                            <li>推送时间： {{item.position_invite.add_time |date:'yyyy-MM-dd HH:mm:ss'}}</li>
                        </ul>
                    </div>
                </div>


            </div>
        </div>

        <!--分页-->

        <div class='paging-block' [class.isPageHeight]="countList>9">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>


    </div>
</div>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>