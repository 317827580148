import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface ConfirmsModel {
  title:string;
  message:any;
  trueText:string;
  falseText:string;
}
@Component({
  selector: 'app-cloudtip',
  templateUrl: './cloudtip.component.html',
  styleUrls: ['./cloudtip.component.css']
})

export class CloudtipComponent{
  title: string;
  message: any;
  trueText:string;
  falseText:string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmsModel) {
    this.title=data.title;
    this.message=data.message;
    this.trueText=data.trueText;
    this.falseText=data.falseText;
  }
  
}
