import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {ModelPayWechatComponent} from "../model-pay-wechat/model-pay-wechat.component";
declare let window;
declare let pingpp;
@Component({
  selector: 'app-buy-pay-product-extend',
  templateUrl: './buy-pay-product-extend.component.html',
  styleUrls: ['./buy-pay-product-extend.component.css']
})
export class BuyPayProductExtendComponent implements OnInit {

  @ViewChild('payWechat', { static: true }) child: ModelPayWechatComponent;

  public config: Config;
  public  count = 1;
  public payList = [] //支付列表
  public model={
    channel:'alipay_pc_direct', //支付渠道alipay_pc_direct:支付宝pc wx_pub_qr:微信扫码
    product_code:'', // 产品代码
    count:0,
    coupon_reveiceid:''
  }
  public MyLaCoinCount = 0;
  public yingfu = 0;
  @Output() modelShow = false;//是否打开弹窗
  public paydata:any;
  constructor(
    public opera:Opera,
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService,

  ) {
    activatedRoute.queryParams.subscribe(queryParams => {
      console.log('传过来的参数',queryParams)
    });

  }

  ngOnInit() {
    let topNav = this.local.get('nav_tool')
    this.opera.initConfig('tool',topNav);
    this.getPayChannel();

    let pay_data = this.local.get('pay_data');
    if(pay_data)
    {
      pay_data = JSON.parse(pay_data);
      this.paydata = pay_data;
      console.log('pay_data',pay_data);
    }
    else
    {

      this.router.navigateByUrl('/home/tool-my');
    }
    this.getMyLaCoinCount();
  }

  //获取支付渠道
  getPayChannel(){
    this.dataApi.getPayChannel().then((res:any)=>{
      if(res){
        // console.log('获取到支付渠道');
        // console.log(res);
        this.payList=res;
        this.model.channel=res[0].alias;

      }
    }).catch((err)=>{
      // console.log('获取支付渠道出错');
      // console.log(err);
      this.toastr.error("获取支付渠道出错，请稍后重试");
    })
  }
  //接收子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
    if(event){
      this.router.navigateByUrl("home/tool-my"); // 会员
    }else{
      this.toastr.error("支付失败，请重试");
    }
  }
  //支付
  pay(){

    if(!this.model.channel){
      this.toastr.warning("请选择支付渠道");
      return;
    }
    this.opera.loading();
      this.model.count = this.paydata.count;
    this.model.product_code = this.paydata.code;
    this.model.coupon_reveiceid= this.paydata.coupon_receiveid;
    console.log('支付',this.model);

    this.dataApi.buyOrderByLabi(this.model).then((res:any)=>{
      // console.log("支付charge 企业认证");
      // console.log('postPayCharge',res);
      this.opera.loadiss();
      if(this.model.channel=='wx_pub_qr'){
        this.opera.scrollTop();
        this.modelShow = true;
        this.child.initData(res);
        return;
      }
      let that = this;
      pingpp.createPayment(res, function(result,error){
        console.log('调用支付-----pc');
        console.log(result);
        console.log(error);

        if(result=='success'){
          console.log('成功');
          that.local.remove('pay_data')
          that.router.navigateByUrl("home/tool-my"); // 会员
        }else{
          that.toastr.error('支付失败，请重试');
        }
      });
    }).catch((err)=>{
      console.log(err);
      this.opera.loadiss();
    })
  }
  getMyLaCoinCount(){
    //product_category:"recharge",recharge_category:''
    this.dataApi.getMyLaCoinCount({}).then((res:any)=>{
      console.log('获取我的啦豆余额',res);

      if(res){
        this.MyLaCoinCount = res;

      }
    }).catch((err)=>{
      console.log(err);
    })
  }
}
