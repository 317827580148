<!-- 页头部分 -->
<div class="schoolCloud">
    <div class="goback" (click)="opera.goNewRouter('college-details-news')"><img src="assets/images/v3/headerReturnIcon.png">新闻公告 ｜ 新闻详情</div>
    <div class="center" *ngIf="item">
        <div class="name">{{item.title}}</div>
        <div class="text">
            <span *ngIf="item.publisher">发布人：{{item.publisher}}</span>
            <span *ngIf="item.publish_time">发布时间：{{item.publish_time | date:"yyyy-MM-dd HH:mm"}}</span>
            <span *ngIf="item.column_name">所属栏目：{{item.column_name}}</span>
            <span *ngIf="item.source">新闻来源：{{item.source}}</span>
        </div>
        <div class="college-message" [innerHtml]="opera.assembleHTML(item.content)"></div>
    </div>
</div>