<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border':'0','background':'none','borderRadius': '10px'}" [(visible)]="isShow" [style]="{'padding':0,'border':0,'background':'none','box-shadow':'unset','width':592,'height':750,'minHeight':750}"
    (onHide)="_modelHidden()" [modal]=true>
    <!-- 极速电话联系 -->
    <div [ngClass]="{'alertPhone_Bg_small':phoneModel.PhoneModelType==0,'alertPhone_Bg':phoneModel.PhoneModelType==1}">

        <div class="content_bgImage" *ngIf="resumeDetails">
            <div class="clos" (click)="_modelHidden()"><img src="/assets/images/v3/speedClos.png"></div>
            <div class="speedTopIcon">
                <div class="icon"><img src="/assets/images/v3/speedTopIcon.png"></div>
                <div class="rightName">
                    <div class="name">极速电话联系</div>
                    <div class="introduce">中意人才，直接电话抢</div>
                </div>
            </div>
            <!-- 学生信息 -->
            <div class="userCard">
                <div class="userCard_top">
                    <div class="logo">
                        <img class="userCard_top_userPic" *ngIf="resumeDetails.logo" src="{{resumeDetails.logo}}">
                        <img class="userCard_top_userPic" *ngIf="!resumeDetails.logo&&resumeDetails.gender=='01'" src="../../assets/images/male.png">
                        <img class="userCard_top_userPic" *ngIf="!resumeDetails.logo&&resumeDetails.gender=='02'" src="../../assets/images/female.png">
                    </div>

                    <div class="userCard_top_v1">
                        <div class="_name">
                            <span class="userCard_top_userName">{{resumeDetails.talent_name}}</span>
                            <img class="userCard_top_userSex" *ngIf="resumeDetails.gender=='01'" src="../../assets/images/mini_res/man.png">
                            <img class="userCard_top_userSex" *ngIf="resumeDetails.gender=='02'" src="../../assets/images/mini_res/woman.png">
                        </div>
                        <div class="userCard_top_v2">
                            <span class="userCard_top_userSchool"> {{resumeDetails.graduation_school+' | '}} {{resumeDetails.major_text +' | '}} {{resumeDetails.education_text }}
                            </span>
                        </div>
                    </div>

                </div>
                <div class="userCard_bottom">
                    <span *ngFor="let youshi of resumeDetails.youshiArr | slice:0:4;let i = index; " class="userCard_biaoqian">{{youshi}}</span>
                </div>
            </div>
            <!-- 第一步 -->
            <div *ngIf="phoneModel.PhoneModelType==0">
                <div class="formModel">
                    <span class="alertPhone_title">沟通职位</span>
                    <select class="alertPhone_position downLoad_right" [(ngModel)]="phoneModel.positionid" (ngModelChange)="seletePositionChange()">
                        <option class="place-text" value="" disabled>请选择职位</option>
                        <option class="place-text" *ngFor="let a of positionList" [value]="a.positionid">{{a.name}}</option>
                    </select>
                </div>
                <div class="_positionPush" *ngIf="positionList.length == 0">暂无职位<span (click)="_positionPush()">去发布职位</span></div>
                <div class="formModel2">
                    <span class="alertPhone_title">呼出手机</span>
                    <!--<select class="alertPhone_phoneType" [(ngModel)]="phoneModel.getPhoneType">-->
                    <!--<option class="place-text" value='1' >呼出手机</option>-->
                    <!--<option class="place-text" value='2'>呼出座机</option>-->
                    <!--</select>-->
                    <div class="alertPhone_phoneInput">
                        <input [(ngModel)]="phoneModel.myPhone" oninput="value=value.replace(/[^\d]/g,'')" />
                    </div>
                    <!--<input placeholder="区号" class="alertPhone_telquInput" *ngIf="phoneModel.getPhoneType=='2'" [(ngModel)]="phoneModel.myTel_qu" oninput = "value=value.replace(/[^\d]/g,'')" />-->
                    <!--<span *ngIf="phoneModel.getPhoneType=='2'">  -</span>-->
                    <!--<input placeholder="座机号" class="alertPhone_phoneInput" *ngIf="phoneModel.getPhoneType=='2'" [(ngModel)]="phoneModel.myTel" oninput = "value=value.replace(/[^\d]/g,'')" />-->

                </div>
                <div class="userCard_chips">* 将使用该号码呼出</div>
                <button class="jisu_commit blueStreamer" (click)="checkMyPhone()">下一步</button>
                <div class="p3" (click)="product('voice_call','contactMe')">购买资源</div>
            </div>
            <!-- 第二步 -->
            <div *ngIf="phoneModel.PhoneModelType==1">
                <div class="currentPostion" *ngIf="currentPostion">
                    <div class="tip1">您与学生沟通的职位</div>
                    <div class="currentPostion_val">{{currentPostion.name}}</div>
                </div>
                <div class="jisu_phone_v1" *ngIf="!isTimeOut">
                    <div class="box1">
                        <div class="icon"><img src="/assets/images/v3/speedProtect.png"></div>
                        <div class="number">
                            <div class="nub1">已为您生成虚拟号码</div>
                            <div class="nub2">{{phoneModel.NetPhone}}</div>
                        </div>
                        <div class="su"></div>
                        <div class="second"><span>{{timeIndex}}s</span>后号码失效</div>
                    </div>
                    <!-- <img class="jisu_phone_v1_icon" src="../../assets/images/dunpai.png">
                    <span class="jisu_phone_v1_title">{{phoneModel.NetPhone}}</span>
                    <span class="jisu_phone_v1_title_1" title="工作啦为大学生提供号码保护，已隐藏对方的真实号码。">(此号码为虚拟号 <img style="width:9px;height: 9px " src="../../assets/images/department-identity-explainImg.png"> )</span>
                    <span style="margin-left: 5px" *ngIf="timeIndex!=120"><span style="color: #FF5A5A">{{timeIndex}}s</span>后失效</span> -->
                </div>
                <div class="jisu_phone_v1" *ngIf="isTimeOut">
                    <div class="box1">
                        <div class="icon"><img src="/assets/images/v3/speedProtect.png"></div>
                        <div class="number">
                            <div class="nub1">已为您生成虚拟号码</div>
                            <div class="nub2 active">号码已失效</div>
                        </div>
                        <div class="su"></div>
                        <div class="chongxin" (click)="checkMyPhone()">重新生成</div>
                    </div>
                </div>

                <div class="jisu_phone_v2">
                    *请使用<span (click)="updataMyPhone()">{{phoneModel.getPhoneType=='1'?phoneModel.myPhone:phoneModel.myTel_qu+phoneModel.myTel}}【点击修改】</span>拔打，通话结束后，虚拟号随即失效。
                </div>
                <div class="division"></div>
                <div class="chips1">
                    <div class="icon"><img src="/assets/images/v3/speedPush.png"></div>
                    <div class="text">
                        <div class="text1">同时使用极速联系与职位推送</div>
                        <div class="text1">学生的反馈与入职率更高</div>
                    </div>
                </div>

                <div class="phoneModel">
                    <span>招聘电话:</span>
                    <input type="text" class="phoneText" [(ngModel)]="phone" (ngModelChange)="changeinput($event)" placeholder="请输入HR招聘电话" />
                </div>
                <div class="smsContent">
                    <div class="title">推送示例</div>
                    <div class="mian">{{smsContent}}</div>
                </div>
                <div class="pushBtn blueStreamer" (click)="pushSms()">推送</div>
                <div class="xuxiaohao">需消耗<span>1</span>次资源（当前余量<span>{{smsCount}}</span>）</div>
                <div class="pushSuccess">推送成功后，您可在【建立管理】-【职位推送记录】中查看推送记录（有部分延时）</div>
            </div>

        </div>
    </div>
</p-dialog>
<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>