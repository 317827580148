<!-- 页头部分 -->
<!-- <div class="contentRight-header"><img (click)="opera.goNewRouter('college-details')" src='assets/images/router-old.png'/>高校云<span *ngIf="!config.isByCollege"></span> - 学校详情 - 教学机构</div> -->
<div class="schoolCloud">
    <div class="schoolHeader">
        <div class="center">
            <div class="goback" (click)="opera.goNewRouter('college-campustalk1')"><img src="assets/images/v3/headerReturnIcon.png">高校详情</div>
            <div class="top">
                <div class="logo">
                    <img *ngIf="college.logo" [src]="college.logo">
                    <img *ngIf="!college.logo" src='assets/images/none.png' />
                </div>
                <div class="text">
                    <div class="name">{{college.name}}</div>
                    <div class="time">{{college.school_type_text}}｜{{college.school_nature_text}}｜{{college.establish_time | date:"yyyy年MM月dd日"}}创办</div>
                    <div class="list">
                        <div class="item">学校代码：{{college.code?college.code:'暂无'}}</div>
                        <div class="item">联系邮箱：{{college.email?college.email:'暂无'}}</div>
                        <div class="item">学校地址：{{college.region_text?college.region_text:'暂无'}}</div>
                        <div class="item">就业电话：{{college.work_info.office_tel?college.work_info.office_tel:'暂无'}}</div>
                    </div>
                </div>
            </div>
            <div class="navList">
                <div class="item " (click)="opera.goNewRouter('college-details')">高校简介</div>
                <div class="item active">教学机构<span></span></div>
                <div class="item" (click)="goMilk()">双选会</div>
                <div class="item" (click)="goCampus()">宣讲会</div>
                <div class="item" (click)="goStudent()">生源速览</div>
                <div class="item" (click)="goNews()">新闻公告</div>
            </div>
        </div>

    </div>
    <div class="Publicity">
        <div class="center">
            <div class="college-block">
                <!-- 没有内容存在时 -->
                <div *ngIf="countList==0" class="none-block">
                    <img src="/assets/images/rights/empty.png">
                    <p>暂未找到学院信息，请稍后重试</p>
                </div>
                <div *ngIf="countList>0" class="college-resume-cont">
                    <ul class="title">
                        <li>学院名称</li>
                        <li>学院网址</li>
                        <li>学院地址</li>
                        <li>就业联系人</li>
                        <li>联系电话</li>
                        <li>专业数量</li>
                        <li>应届生数量</li>
                        <li>操作</li>
                    </ul>
                    <div>
                        <ul class="resume-li" *ngFor="let n of resumeList;let i = index;">
                            <li><span *ngIf='n.name'>{{n.name}}</span><span *ngIf='!n.name'>暂无</span></li>
                            <li><span *ngIf='n.website'>{{n.website}}</span><span *ngIf='!n.website'>暂无</span></li>
                            <li><span *ngIf='n.address'>{{n.address}}</span><span *ngIf='!n.address'>暂无</span></li>
                            <li><span *ngIf='n.linkman'>{{n.linkman}}</span><span *ngIf='!n.linkman'>暂无</span></li>
                            <li><span *ngIf='n.phone'>{{n.phone}}</span><span *ngIf='!n.phone'>暂无</span></li>
                            <li><span *ngIf='n.major'>{{n.major}}</span><span *ngIf='!n.major'>暂无</span></li>
                            <li><span *ngIf='n.student'>{{n.student}}</span><span *ngIf='!n.student'>暂无</span></li>
                            <li>
                                <span (click)="goDetails(n)">查看</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- 主体内容部分 -->
<div class="homepage-block" style="display: none;">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <div class="college-block" style="margin-bottom:20px;display: none;">
        <!-- 页头部分 -->
        <div class="college-block-head">
            <img (click)="opera.goNewRouter('college-details')" src='assets/images/router-old.png' /> 教学机构
        </div>
        <!-- 详情模块 -->
        <div class="college-head-details clear" *ngIf="college">
            <!-- 左侧文字 -->
            <ul class="left">
                <li><span>学校名称：</span>{{college.name}}<i *ngIf="!college.name">暂无</i></li>
                <li><span>创办时间：</span>{{college.establish_time | date:"yyyy-MM-dd"}}<i *ngIf="!college.establish_time">暂无</i></li>
                <li><span>学校性质：</span>{{college.school_nature_text}}<i *ngIf="!college.school_nature_text">暂无</i></li>
                <li><span>联系邮箱：</span>{{college.email}}<i *ngIf="!college.email">暂无</i></li>
                <li><span>学校代码：</span>{{college.code}}<i *ngIf="!college.code">暂无</i></li>
                <li><span>所在城市：</span>{{college.region_text}}<i *ngIf="!college.region_text">暂无</i></li>
                <!-- <li><span>就业电话：</span>{{college.phone}}</li> -->
            </ul>
            <!-- 右侧logo -->
            <div class="right">
                <img *ngIf="college.logo" [src]="college.logo" alt="高校logo">
                <img *ngIf="!college.logo" src="assets/images/none.png" alt="高校logo">
            </div>
        </div>
    </div>
    <div class="college-block">
        <!-- 中间部分 -->
        <!-- 没有内容存在时 -->
        <div *ngIf="countList==0" class="none-block">
            <img src="assets/images/resume-search-fail.png"><br/> 暂未找到学院信息，请稍后重试
        </div>
        <div *ngIf="countList>0" class="college-resume-cont">
            <ul>
                <li>学院名称</li>
                <li>学院网址</li>
                <li>学院地址</li>
                <li>就业联系人</li>
                <li>联系电话</li>
                <li>专业数量</li>
                <li>应届生数量</li>
                <li>操作</li>
            </ul>
            <div>
                <ul class="resume-li" *ngFor="let n of resumeList;let i = index;">
                    <li><span *ngIf='n.name'>{{n.name}}</span><span *ngIf='!n.name'>暂无</span></li>
                    <li><span *ngIf='n.website'>{{n.website}}</span><span *ngIf='!n.website'>暂无</span></li>
                    <li><span *ngIf='n.address'>{{n.address}}</span><span *ngIf='!n.address'>暂无</span></li>
                    <li><span *ngIf='n.linkman'>{{n.linkman}}</span><span *ngIf='!n.linkman'>暂无</span></li>
                    <li><span *ngIf='n.phone'>{{n.phone}}</span><span *ngIf='!n.phone'>暂无</span></li>
                    <li><span *ngIf='n.major'>{{n.major}}</span><span *ngIf='!n.major'>暂无</span></li>
                    <li><span *ngIf='n.student'>{{n.student}}</span><span *ngIf='!n.student'>暂无</span></li>
                    <li>
                        <span (click)="goDetails(n)">查看</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- 分页部分 -->
    <!-- <div class='paging-block' [class.isPageHeight]="countList>0">
     <router-outlet></router-outlet>
     <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
 </div> -->
</div>


<!-- 新增高校 -->
<app-push-college-model #pushCollegeModel [isShow]="isShowPushCollegeModel" (modelHidden)="pushCollegeModelHidden($event)"></app-push-college-model>