<!-- 二级多选 -->
<!-- 遮罩层公共样式 -->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px','width':'700px'}" [(visible)]="isShow" (onHide)="dismiss(false)" [modal]=true>
    <div class="model-cont">
        <div class="model-head clear">
            <div class="left">意向专业</div>
            <div class="clos" (click)="dismiss(false)"><img src="/assets/images/rights/contactMeClos.png"></div>
        </div>

        <div class="select-more-absolute">
            <div class="title-block">已选：
                <div class="right-block"><span>{{major.length}}</span> / {{initLength}}</div>
            </div>
            <div class="select-choose-block" *ngIf="major.length>0">
                <div class="choose-list">
                    <div class="choose-item" *ngFor="let item of major">
                        <div class="choose-text">{{ item.text }}</div>
                        <div class="choose-delete" (click)="getSecendLevel($event,item)">x</div>
                    </div>
                </div>
            </div>
            <div style="overflow: hidden;">
                <div id="first-level" class="first-level" style="overflow-y: scroll;">
                    <div class="list-block" *ngFor="let item of dataList" (click)="getFirstLevel(item)" [class.activeAddress]="item.facultyid==facultyid">

                        <div *ngIf="faculty.indexOf(item.facultyid)>-1" class="active-pic"><span></span></div>
                        {{ item.name }}
                    </div>
                </div>

                <div id="secend-level" class="secend-level" style="overflow-y: scroll;">
                    <div class="list-block" *ngFor="let item of secendLevel" (click)="getSecendLevel($event,item)" [class.active]="major.indexOf(item)>-1">
                        <div class="list-text" [class.activeChild]="major.indexOf(item)>-1">
                            {{ item.text }}
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- 底部操作部分 -->
        <div class="model-foot">
            <div class="purple blueStreamer" (click)="dismiss(initvalue)">确定</div>
        </div>
    </div>
</p-dialog>