<!-- <app-navigation-route></app-navigation-route> -->

<div class="homepage-block">
    <router-outlet></router-outlet>
    <app-voice-call-model #voiceCallModel [isShow]="isShowVoiceCallModel" (modelHidden)="voiceCallModelHidden($event)"></app-voice-call-model>
    <app-model-send-msg #sengMsgModel [SengMsgModel]='SengMsgModel' [tconfig]='config' (onVoted)="Modeldismiss($event)" [isShow]="isShowPositionPushModel"></app-model-send-msg>

    <div class="airCampus_detail">
        <div class="airCampus_detail_card" (click)="opera.goNewRouter('airCampList')">
            <div class="d_f airCampus_detail_back_cell"><img src="../../assets/images/v3/headerReturnIcon.png" />空中宣讲会会场
            </div>
        </div>
        <div class="airCampus_detail_card" style="margin-top: 20px;">
            <div class="d_f jc_sb airCampus_detail_title_cell">
                <div class="airCampus_detail_title">{{airCampustalk?.title}}</div>
                <div class="d_f" *ngIf="!isLiveAir">
                    <div class="d_f airCampus_detail_title_btn" *ngIf="airCampustalk?.audit_status==2" (click)="downLineClick()">
                        <img src="../../assets/images/v3/down.png" />下线
                    </div>
                </div>
            </div>
            <div class="d_f ai_fs">
                <div>
                    <div *ngIf="airCampustalk?.video_url" class="airCampus_detail_video_content">
                        <div id="mod_player"></div>
                    </div>
                    <div *ngIf="!airCampustalk?.video_url" class="airCampus_detail_video_content">
                        <img class="w_100 h_100" src="../../assets/images/live_state_end.png" />
                        <div class="text">直播宣讲已结束，回放视频上传中</div>
                    </div>
                    <div class="d_f airCampus_detail_video_playcount">
                        <div class="video_playcount_num">{{airCampustalk?.video_play_count || 0}}次观看</div>
                    </div>
                </div>
                <div class="f_1 d_f fd_c airCampus_detail_video_descbox">
                    <div class="f_1">
                        <div class="video_descbox_content">{{airCampustalk?.desc}}</div>
                    </div>
                    <div class="d_f">
                        <div class="f_1">
                            <div class="video_descbox_push_title">已推广高校就业信息网</div>
                            <div class="d_f video_descbox_push_info">
                                <div class="strong">{{ApplyCollegeData.passed.length}}</div>
                                <div>所</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!isLiveAir" class="airCampus_detail_suntitle">招聘职位</div>
            <div *ngIf="!isLiveAir" class="d_f fw_w" style="margin-right: -10px;">
                <div class="airCampus_detail_label_position" *ngFor="let n of airCampustalk?.positions">{{n.name}}</div>
            </div>
            <div *ngIf="!isLiveAir" class="airCampus_detail_suntitle">意向专业</div>
            <div *ngIf="!isLiveAir" class="d_f fw_w" style="margin-right: -10px;">
                <div class="airCampus_detail_label_major" *ngFor="let n of airCampustalk?.majors">{{n.text}}</div>
                <div class="airCampus_detail_label_major" *ngIf="airCampustalk?.majors.length==0">不限</div>
            </div>
            <div class="shenheFile" *ngIf="airCampustalk?.audit_status==3">
                <img class="shenheFileIcon" src="../../assets/images/air_fail.png" />
                <p class="shenheFile_h1">很可惜，您发布的空中宣讲会未通过审核！</p>
                <p class="shenheFile_h2" *ngIf="airCampustalk?.audit_status==3&&reasons.length>0">未通过原因：</p>
                <p class="shenheFile_h2" *ngIf="airCampustalk?.audit_status==3&&reasons.length>0">
                    <span *ngFor="let n of reasons" class="flex_c">{{n.title}}:&nbsp;{{n.desc}}</span>
                </p>
            </div>
            <div class="shenheding" *ngIf="airCampustalk?.audit_status==1">
                <img class="shenhedingImage" src="../../assets/images/airloading.png" />
                <p class="shenheding_h1">空中宣讲会审核中…</p>
            </div>
            <div class="custom_tabs airCampus_detail_tab">
                <div (click)="tabClick('1')" class="custom_tab" [ngClass]="{active:currentTab=='1'}">学生简历</div>
                <div (click)="tabClick('2')" class="custom_tab" [ngClass]="{active:currentTab=='2'}">互动</div>
            </div>
        </div>
        <div class="" *ngIf="airCampustalk?.audit_status==2">
            <div class="" *ngIf="currentTab=='1'">
                <div class="custom_tabs airCampus_detail_subtab">
                    <div (click)="talentTabClick('1')" class="custom_tab" [ngClass]="{active:talentTab=='1'}">全部学生</div>
                    <div (click)="talentTabClick('2')" class="custom_tab" [ngClass]="{active:talentTab=='2'}">查看过我的学生
                    </div>
                    <div (click)="talentTabClick('3')" class="custom_tab" [ngClass]="{active:talentTab=='3'}">投递过我的学生
                    </div>
                </div>
                <div class="d_f fw_w airCampus_detail_position_box">
                    <div (click)="goDetails(n,n.ignore)" class="airCampus_detail_position" *ngFor="let n of resumeList;let i = index;">
                        <div class="positionItem_Top">
                            <img class="airCampus_detail_position_logo" *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}" />
                            <img class="airCampus_detail_position_logo" *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png" />
                            <img class="airCampus_detail_position_logo" *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png" />
                            <img class="airCampus_detail_position_logo" *ngIf="!n.logo&&!n.gender" src="assets/images/male.png" />
                            <div class="info">
                                <div class="airCampus_detail_position_name">{{n.talent_name | clearSpace}}
                                    <span class="new_telent" *ngIf="n.new_talent">新用户</span>
                                </div>
                                <div class="airCampus_detail_position_school">{{n.graduate_university}}</div>
                                <div class="d_f airCampus_detail_position_explain">
                                    <div>{{n.major_text}}</div>
                                    <div>{{n.education_text}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="d_f airCampus_detail_position_skill">
                            <div *ngFor="let youshi of n.youshiArr | slice:0:4;let i = index;" class="skill_item">{{youshi}}</div>
                        </div>
                        <div class="d_f jc_sb" *ngIf="talentTab!='3'">
                            <div class="airCampus_detail_position_btn call_btn" *ngIf="!n.is_delivery" (click)="voiceCallClick($event,n)">
                                <img src="../../assets/images/v3/speedIcon.png" />
                                <div>电话联系</div>
                            </div>
                            <div class="airCampus_detail_position_btn push_btn" *ngIf="!n.is_delivery" title="可以将职位定向推送给该学生" (click)="positionPushClick($event,n)">
                                <img src="../../assets/images/v3/speedPush.png" />
                                <div>职位推送</div>
                            </div>
                            <div class="airCampus_detail_position_btn" *ngIf="n.is_delivery" title="查看简历" (click)="positionPushClick($event,n)">
                                <div>查看简历</div>
                            </div>
                        </div>
                        <div *ngIf="talentTab=='3'">
                            <div class="airCampus_detail_position_btn" *ngIf="opera.isShowPaySuccessModule()" title="查看简历" (click)="positionPushClick($event,n)">
                                <div>查看简历</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="resumePage" (show)="AllresumeCount>0" style="float: right;margin-right: 0.5%"></div>


                <!--</div>-->


                <div class="noneTalent" *ngIf="AllresumeCount==0">
                    <img src="../../assets/images/air_noneTalent.png" />
                    <div class="noneTalent_h1">无学生</div>
                    <!--<div class="noneTalent_h2"  (click)="tjTalentMode()">推广到学生</div>-->
                </div>
            </div>

            <div class="tabContent" *ngIf="currentTab=='2'">
                <div class="subTab flex_l">
                    <div [ngClass]="{subTab_div_s:questionTab=='1',subTab_div:questionTab!='1'}" (click)="questionTabTabClick('1')">
                        未回答</div>
                    <div [ngClass]="{subTab_div_s:questionTab=='2',subTab_div:questionTab!='2'}" (click)="questionTabTabClick('2')">
                        已回答</div>
                </div>

                <div class="questionMenu flex_l" *ngIf="questionTab=='2'&&AllquestionCount>0">
                    <div class="flex_l" (click)="allSeletequestionClick()">
                        <img *ngIf="!isALlSeletequestion" src="../../assets/images/air_none.png" />
                        <img *ngIf="isALlSeletequestion" src="../../assets/images/air_check.png" /> 全选
                    </div>
                    <div *ngIf="seleteQuestionList.length>0" (click)="questionMenuClick('open')">批量公开</div>
                    <div *ngIf="seleteQuestionList.length>0" (click)="questionMenuClick('hiden')">批量隐藏</div>
                </div>
                <div class="questionCell flex_s" *ngFor="let n of questionList">
                    <img class="question_check" *ngIf="n.selete" src="../../assets/images/check_qusition.png" />
                    <div class="questionCell_l" (click)="questionCellClick(n)">
                        <div class="questionCell_h1 flex_l" style="margin-bottom: 7px"><img src="../../assets/images/wen.png" /><span>
                {{n.question}}</span></div>
                        <div class="questionCell_h1 flex_l" *ngIf="questionTab=='2'"><img src="../../assets/images/da.png" /><span>
                {{n.answer}}</span></div>
                    </div>
                    <img class="questionPublic" *ngIf="n.is_public && questionTab=='2'" src="../../assets/images/qusition_open.png" />
                    <img class="questionPublic" *ngIf="!n.is_public && questionTab=='2'" src="../../assets/images/qusition_hiden.png" />
                </div>
                <div id="test1" (show)="AllquestionCount>0" style="float: right;margin-right: 0.5%"></div>
                <div class="noneTalent" *ngIf="AllquestionCount==0">
                    <img src="../../assets/images/air_noneTalent.png" />
                    <div class="noneTalent_h1">您还未和学生互动</div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--//下线-->

<!--新增问答弹窗-->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="downLineModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':460,'height':260,'minHeight':260}" [modal]=true>

    <div class="downLineModel">
        <img class="downLineIcon" src="../../assets/images/downLineIcon.png" />
        <div class="downLine_h1">此功能会将您发布的空中宣讲会全网下线，确定下线吗？</div>

        <div class="downLineBtn flex_c">
            <div (click)="downLineModekClick(false)">取消</div>
            <div class="purple" style="color: white;background-color: #5E4FFF" (click)="downLineModekClick(true)">确定</div>
        </div>
    </div>
</p-dialog>