<div class="company-block">
    <div class="header">
        <div class="secondNav">
            <div class="item " (click)="nav01()">收到的</div>
            <div class="item " (click)="nav02()">收藏的</div>
            <div class="item " (click)="nav03()">极速电话联系记录</div>
            <div class="item " (click)="nav04()">职位推送记录</div>
            <div class="item " (click)="nav05()">校招活动邀请记录</div>
            <div class="item active">空中宣讲会推广记录<span></span></div>
        </div>
        <div class="banner">
            <div class="layui-carousel" id="test1" *ngIf="bannerList.length>0">
                <div carousel-item>
                    <div *ngFor="let n of bannerList;let i = index;">
                        <img class="swiper-slide bannerImage" (click)="bannerClick(n)" id={{i}} src="{{n.image_url}}" />
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="viewList">
        <div class="cell" *ngFor="let n of ActivityList;let i = index;" id={{i}}>
            <div class="time">{{n.date|date:'yyyy年MM月dd日'}}</div>
            <div class="itemBox">
                <div class="item" *ngFor="let item of n.list" (click)="cellClick(item)">
                    <div class="logo"><img src="{{item.air_campustalk_logo}}"></div>
                    <div class="main">
                        <div class="name">{{item.name}}</div>
                        <div class="number">
                            <img class="studentIcon" src="/assets/images/v3/studentIcon.png"> 共邀请
                            <span>{{item.invite_count}}人次</span>
                            <img src="/assets/images/v3/rightIcon.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="tableView" style="display:none">
        <div style="overflow: hidden" *ngFor="let n of ActivityList;let i = index;" id={{i}}>
            <div class="cell_time">{{n.date|date:'yyyy-MM-dd'}}</div>
            <div class="listCellContent" *ngFor="let item of n.list" (click)="cellClick(item)">
                <div class="row_col1 row_height">
                    <div class="row_col1_userInfo">
                        <img class="headPic" src="{{item.air_campustalk_logo}}">
                        <ul>
                            <li class="userName">{{item.name}}</li>
                            <li><span class="zhiwei"><img style="width:14px;height: 14px;margin-top: -2px" src="../../assets/images/time_icon.png"> 开始时间:&nbsp;&nbsp;{{item.start_time |date:'yyyy-MM-dd HH:mm'}}</span></li>
                        </ul>
                    </div>
                </div>
                <div class="row_col2 row_height">
                    <span>举办学校：{{item.college_name}}</span>
                </div>
                <div class="row_col3 row_height">
                    <span>共邀请：{{item.invite_count}}人次</span>
                </div>
            </div>
        </div>
    </div>

    <!-- 空状态 -->
    <div class="none-block-empty" *ngIf="countList == 0">
        <img src="assets/images/rights/empty.png">
        <p>暂未找到记录</p>
    </div>

    <div class='paging-block' [class.isPageHeight]="countList>9">
        <router-outlet></router-outlet>
        <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
    </div>
</div>